import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

function msToMinSec(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
}
function fetchCommodityName(recipeid, rawmaterials) {
  let rawmaterialList = rawmaterials;
  let rawmaterialName = '';
  Object.keys(rawmaterialList).map((item) => {
    if(rawmaterialList[item]._id === recipeid) {
      rawmaterialName = rawmaterialList[item].rawmaterialName;
    }
  });
  return rawmaterialName;
}
function fetchCommodityDensity(recipeid, rawmaterials) {
  let rawmaterialList = rawmaterials;
  let rawmaterialDensity = '';
  Object.keys(rawmaterialList).map((item) => {
    if(rawmaterialList[item]._id === recipeid) {
      rawmaterialDensity = rawmaterialList[item].rawmaterialDensity;
    }
  });
  if (rawmaterialDensity === "") {
    return "-";
  } else {
    return rawmaterialDensity;
  }
}
function fetchCommodityGroup(recipeid, rawmaterials) {
  let rawmaterialList = rawmaterials;
  let rawmaterialGroup = '';
  Object.keys(rawmaterialList).map((item) => {
    if(rawmaterialList[item]._id === recipeid) {
      rawmaterialGroup = rawmaterialList[item].rawmaterialGroup;
    }
  });
  return rawmaterialGroup;
}

class DisplayCommodity extends Component {
  render() {
    const { refid, rawmaterials, recipe, commoditylist } = this.props;
    let commodityRowValue = [];
    let recipeRefID;
    let recipeList;

    if (recipe.recipeName !== undefined) {
      recipeList = commoditylist;

      let foundRecipeArray = Object.entries(recipeList);

      for (const [order, recipeid] of foundRecipeArray) {
        let pos = parseInt(order) + 1;
        let name = fetchCommodityName(recipeid, rawmaterials);
        let density = fetchCommodityDensity(recipeid, rawmaterials);
        let group = fetchCommodityGroup(recipeid, rawmaterials);

        commodityRowValue.push(
          <TableRow key={order}>
            <TableCell>
              <small>{pos}</small>
            </TableCell>
            <TableCell>
              <small>{name}</small>
            </TableCell>
            <TableCell>
              <small>{group}</small>
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
        <React.Fragment>
          {/*<Box style={{ width: "100%" }}>*/}
          {/*  <TableContainer>*/}
          {/*    <Table size="small" aria-label="a dense table">*/}
          {/*      <TableHead>*/}
          {/*        <TableRow>*/}
          {/*          <TableCell>*/}
          {/*            <strong>Nr.</strong>*/}
          {/*          </TableCell>*/}
          {/*          <TableCell>*/}
          {/*            <strong>Name</strong>*/}
          {/*          </TableCell>*/}
          {/*          <TableCell>*/}
          {/*            <strong>Group</strong>*/}
          {/*          </TableCell>*/}
          {/*        </TableRow>*/}
          {/*      </TableHead>*/}
          {/*    </Table>*/}
          {/*  </TableContainer>*/}
          {/*</Box>*/}
          <Paper style={{ width: "100%" }}>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell scope="row" style={{maxWidth: '200px'}}>
                    <strong>Vulcanisation time t90 @  X °C in Y mins</strong>
                  </TableCell>
                  <TableCell align="right">
                    <p><span>150&#8451;</span><span style={{marginLeft: '20px'}}>21.37</span></p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row" style={{maxWidth: '200px'}}>
                    <strong>Vulcanisation time t90 @  X °C in Y mins,</strong>
                  </TableCell>
                  <TableCell align="right">
                    <p><span>160&#8451;</span><span style={{marginLeft: '20px'}}>9.83</span></p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row" style={{maxWidth: '200px'}}>
                    <strong>Vulcanisation time t90 @  X °C in Y mins,</strong>
                  </TableCell>
                  <TableCell align="right">
                    <p><span>170&#8451;</span><span style={{marginLeft: '20px'}}>6.17</span></p>
                  </TableCell>
                </TableRow>
              </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/*<Paper style={{ width: "100%" }}>*/}
          {/*  <TableContainer style={{marginTop: '20px'}}>*/}
          {/*    <Table size="small" aria-label="a dense table">*/}
          {/*      <TableBody>*/}
          {/*        <TableRow>*/}
          {/*          <TableCell  scope="row">*/}
          {/*            <strong>Stress @ 100% in MPa1</strong>*/}
          {/*          </TableCell>*/}
          {/*          <TableCell style={{width: '150px'}} align="right">{recipe.stiffness100 ? recipe.stiffness100.join('.') : void 0}</TableCell>*/}
          {/*        </TableRow>*/}
          {/*        <TableRow>*/}
          {/*          <TableCell  scope="row">*/}
          {/*            <strong>Stress @ 200% in MPa1</strong>*/}
          {/*          </TableCell>*/}
          {/*          <TableCell style={{width: '150px'}} align="right">{recipe.stiffness200 ? recipe.stiffness200.join('.') : void 0}</TableCell>*/}
          {/*        </TableRow>*/}
          {/*        <TableRow>*/}
          {/*          <TableCell  scope="row">*/}
          {/*            <strong>Stress @ 300% in MPa1</strong>*/}
          {/*          </TableCell>*/}
          {/*          <TableCell style={{width: '150px'}} align="right">{recipe.stiffness300 ? recipe.stiffness300.join('.') : void 0}</TableCell>*/}
          {/*        </TableRow>*/}
          {/*      </TableBody>*/}
          {/*    </Table>*/}
          {/*  </TableContainer>*/}
          {/*  <TableRow>*/}
          {/*    <TableCell  scope="row">*/}
          {/*      <strong>1 Produced with Mix Proc 1, Vulcanisation time</strong>*/}
          {/*    </TableCell>*/}
          {/*    <TableCell style={{width: '150px'}} align="right">t90 6.47 min @ 150°C</TableCell>*/}
          {/*  </TableRow>*/}
          {/*</Paper>*/}
        </React.Fragment>
    );
  }
}

export default DisplayCommodity;
