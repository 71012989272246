import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import * as ROLES from "../../constants/roles";

import SwipeableTemporaryDrawer from "./drawer";

import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";

function fetchRawmaterialGroup(grouprefid) {
  switch (grouprefid) {
    case 1:
      return "EPDM";
    case 2:
      return "Carbon black";
    case 3:
      return "Vulkanisationsaktivator";
    case 4:
      return "Weichmacher";
    case 5:
      return "Katalysator";
    case 6:
      return "Vulkanisationsaktivator";
    case 7:
      return "Vernetzungsmittel";
    case 8:
      return "Vulkanisationsbeschleuniger";
    case 9:
      return "Vulkanisationsbeschleuniger";
    case 10:
      return "IR";
    case 11:
      return "Carbon black";
    case 12:
      return "Carbon black";
    case 13:
      return "Alterungsschutzmittel";
    case 14:
      return "Vulkanisationsbeschleuniger";
    case 15:
      return "Vulkanisationsbeschleuniger";
    case 16:
      return "EPDM";
    case 17:
      return "Carbon black";
    case 18:
      return "Weichmacher";
    case 19:
      return "Weichmacher";
    case 20:
      return "Vulkanisationsbeschleuniger";
    case 21:
      return "Vulkanisationsbeschleuniger";
    case 22:
      return "Vulkanisationsaktivator";
    case 23:
      return "weitere";
    case 24:
      return "Zuschlagstoffe";
    case 25:
      return "EPDM";
    case 26:
      return "Vulkanisationsbeschleuniger";
    case 27:
      return "ACM";
    case 28:
      return "ACM";
    case 29:
      return "ACM";
    case 30:
      return "Weichmacher";
    case 31:
      return "Alterungsschutzmittel";
    case 32:
      return "Carbon black";
    case 33:
      return "Weichmacher";
    case 34:
      return "Vulkanisationsstopper";
    case 35:
      return "weitere";
    default:
      break;
  }
}

function fetchRawmaterialName(grouprefid) {
  switch (grouprefid) {
    case 1:
      return "Keltan 2450";
    case 2:
      return "N550";
    case 3:
      return "PEG-4000";
    case 4:
      return "Prozess Oil P 460";
    case 5:
      return "Zinkoxid (ZnO)";
    case 6:
      return "Stearic acid";
    case 7:
      return "Sulfur";
    case 8:
      return "TBBS";
    case 9:
      return "TBzTD-70";
    case 10:
      return "Natsyn 2200";
    case 11:
      return "N110";
    case 12:
      return "N990";
    case 13:
      return "Agerite Resin D";
    case 14:
      return "ALTAX (MBTS)";
    case 15:
      return "Methyl Tuads (TMTD)";
    case 16:
      return "Keltan 4506";
    case 17:
      return "N774";
    case 18:
      return "Sunpar 2280";
    case 19:
      return "Paraffin";
    case 20:
      return "MBT";
    case 21:
      return "DPTH";
    case 22:
      return "TMTD";
    case 23:
      return "TEDC";
    case 24:
      return "Cumar R-16";
    case 25:
      return "Keltan 7506";
    case 26:
      return "TMTM";
    case 27:
      return "Nipol AR-72HF";
    case 28:
      return "Nipol AR-74";
    case 29:
      return "Hy Temp AR-72LF";
    case 30:
      return "Struktol WB222";
    case 31:
      return "Naugard 445";
    case 32:
      return "N330";
    case 33:
      return "TP-759";
    case 34:
      return "Vulkalent E/C";
    case 35:
      return "Hy Temp ZC-50";
    default:
      break;
  }
}

class RecipesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      recipes: [],
      recipesCopy: [],
      columns: [
        {
          title: "ID",
          field: "_id",
          filtering: false,
          render: (rowData) => {
            return (<div>{rowData._id - 1}</div>)
          }
        },
        {
          title: "Name",
          filtering: false,
          render: (rowData) => {
            if(rowData._id === 2) {
              return (
                  <Link to={{ pathname: `/recipes/${rowData._id}` }}>
                    {rowData.recipeName}
                  </Link>
              )
            } else {
              return (
                  <div>
                    {rowData.recipeName}
                  </div>
              )
            }

          },
        },
        { title: "Short Name", field: "recipeShortName", filtering: false },
        { title: "Source", field: "source", filtering: false },
        {
          title: "Base polymer",
          field: "basePolymer",
          filtering: false,
          render: (rowData) => (
            <div>{fetchRawmaterialGroup(rowData.basePolymer)}</div>
          ),
        },
        {
          title: "Base filler",
          field: "baseFiller",
          filtering: false,
          render: (rowData) => (
            <div>{fetchRawmaterialGroup(rowData.baseFiller)}</div>
          ),
        },
        {
          title: "Base Network",
          field: "baseNetwork",
          filtering: false,
          render: (rowData) => (
            <div>{fetchRawmaterialName(rowData.baseNetwork)}</div>
          ),
        },
        {
          title: `Vulcanisation temp in ℃`,
          field: "vulcanisation_tem",
          filtering: false,
          render: (rowData) => {
              if(rowData.vulcanisation_tem.length > 1) {
                let val = rowData.vulcanisation_tem.map((item, i) => {
                  console.log(item);
                  if(item === 150) {
                    return <li style={{listStyle: "none"}} key={i}>{item+': 21.37'}</li>
                  }
                  if(item === 160) {
                    return <li style={{listStyle: "none"}} key={i}>{item+': 9.83'}</li>
                  }
                  if(item === 170) {
                    return <li style={{listStyle: "none"}} key={i}>{item+': 6.17'}</li>
                  }
                });
                return val;
              } else {
                // console.log("hit", rowData.vulcanisation_tem[0] === 160);
                return <div>{rowData.vulcanisation_tem.join(".")}</div>
              }
          },
        },
        {
          title: "Physicals",
          field: "comment_physicals",
          filtering: false,
          render: (rowData) =>{
            console.log(rowData);
            if(rowData._id === 2) {
              return <div><Link to={'/orders/2'}>{rowData.comment_physicals}</Link></div>
            } else {
              return <div>{rowData.comment_physicals}</div>
            }
          }
        },
        {
          title: "Stiffness in MPa",
          cellStyle: {
            minWidth: '170px'
          },
          field: "stiffness100",
          filtering: false,
          render: (rowData) => (
            <ul
              style={{
                listStyle: "none",
                margin: "0",
                padding: "0",
              }}
            >
              <li>
                <strong>100%: </strong>
                {rowData.stiffness100.join(".")}
              </li>
              <li>
                <strong>200%: </strong>
                {rowData.stiffness200.join(".")}
              </li>
              <li>
                <strong>300%: </strong>
                {rowData.stiffness300.join(".")}
              </li>
            </ul>
          ),
        },
        {
          title: "Elongation in %",
          field: "elongation",
          filtering: false,
          cellStyle: {
            minWidth: '170px'
          },
        },
        {
          title: "Rebound",
          field: "rebound",
          filtering: false,
          render: (rowData) => <div>-</div>,
        },
        {
          title: "Hardness in Shore A",
          field: "hardness",
          filtering: false,
          cellStyle: {
            minWidth: '170px'
          },
        },
        { title: "Comments", field: "comments", filtering: false },
      ],
      initialValueSlider1: [1, 11],
      slider1: [1, 11],
      initialValueSlider2: [410, 410],
      slider2: [0, 100],
      initialValueSlider3: [0, 0],
      slider3: [0, 100],
      slider4: [0, 0],
      initialValueSlider5: [0, 100],
      slider5: [0, 100],
    };
    this.onChangeSlider1 = this.onChangeSlider1.bind(this);
    this.onChangeSlider2 = this.onChangeSlider2.bind(this);
    this.onChangeSlider3 = this.onChangeSlider3.bind(this);
    this.onChangeSlider4 = this.onChangeSlider4.bind(this);
    this.onChangeSlider5 = this.onChangeSlider5.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.recipes().on("value", (snapshot) => {
      const recipesObject = snapshot.val();

      const recipesList = Object.keys(recipesObject).map((key) => ({
        ...recipesObject[key],
        uid: key,
      }));

      //get min and max for stiffness
      let stiffnessArr = [];
      recipesList.map((item) => {
        let itemVals = item.stiffness100.concat(
          item.stiffness200,
          item.stiffness300
        );
        itemVals.map((i) => {
          stiffnessArr.push(i);
        });
      });
      //get min and max of stiffness arr
      let minStiffness = Math.min.apply(null, stiffnessArr),
        maxStiffness = Math.max.apply(null, stiffnessArr);
      this.setState({
        slider1: [minStiffness, maxStiffness],
        initialValueSlider1: [minStiffness, maxStiffness],
      });

      this.setState({
        recipes: recipesList,
        recipesCopy: recipesList,
        loading: false,
      });
      //get min and max for elongation
      let elongationArr = [];
      recipesList.map((item) => {
        elongationArr.push(item.elongation);
      });
      let minElongation = Math.min.apply(null, elongationArr),
        maxElongation = Math.max.apply(null, elongationArr);
      this.setState({
        slider2: [minElongation, maxElongation],
        initialValueSlider2: [minElongation, maxElongation],
      });
      //get min and max for tensile
      let tensileArr = [];
      recipesList.map((item) => {
        item.tensile_R.map((i) => {
          tensileArr.push(i);
        });
      });
      let minTensile = Math.min.apply(null, tensileArr),
        maxTensile = Math.max.apply(null, tensileArr);
      this.setState({
        slider3: [minTensile, maxTensile],
        initialValueSlider3: [minTensile, maxTensile],
      });
      //get min and max for hardness
      let hardnessArray = [];
      recipesList.map((item) => {
        hardnessArray.push(item.hardness);
      });
      let minHardness = Math.min.apply(null, hardnessArray),
        maxHardness = Math.max.apply(null, hardnessArray);
      this.setState({
        slider5: [minHardness, maxHardness],
        initialValueSlider5: [minHardness, maxHardness],
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.recipes().off();
  }

  //stiffness sorting
  onChangeSlider1(event, newVal) {
    let updatedRecipeList = [];
    this.state.recipesCopy.map((item) => {
      let stiffnessItem = item.stiffness100.concat(
        item.stiffness200,
        item.stiffness300
      );
      stiffnessItem.map((i) => {
        // check if the values are within the range
        if (i >= newVal[0] && i <= newVal[1]) {
          updatedRecipeList.push(item);
        }
      });
    });
    //remove duplicate values
    const uniqueArray = updatedRecipeList.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return (
        index ===
        updatedRecipeList.findIndex((obj) => {
          return JSON.stringify(obj) === _thing;
        })
      );
    });
    this.setState({ slider1: newVal, recipes: uniqueArray });
  }

  onChangeSlider2(event, newVal) {
    let updatedRecipeList = [];
    this.state.recipesCopy.map((item) => {
      if (item.elongation >= newVal[0] && item.elongation <= newVal[1]) {
        updatedRecipeList.push(item);
      }
    });
    //remove duplicate values
    const uniqueArray = updatedRecipeList.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return (
        index ===
        updatedRecipeList.findIndex((obj) => {
          return JSON.stringify(obj) === _thing;
        })
      );
    });
    this.setState({ slider2: newVal, recipes: uniqueArray });
  }

  onChangeSlider3(event, newVal) {
    let updatedRecipeList = [];
    this.state.recipesCopy.map((item) => {
      item.tensile_R.map((i) => {
        if (i >= newVal[0] && i <= newVal[1]) {
          updatedRecipeList.push(item);
        }
      });
    });
    //remove duplicate values
    const uniqueArray = updatedRecipeList.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return (
        index ===
        updatedRecipeList.findIndex((obj) => {
          return JSON.stringify(obj) === _thing;
        })
      );
    });
    this.setState({ slider3: newVal, recipes: uniqueArray });
  }

  onChangeSlider4(event, newVal) {
    this.setState({ slider4: newVal });
  }

  onChangeSlider5(event, newVal) {
    let updatedRecipeList = [];
    this.state.recipesCopy.map((item) => {
      if (item.hardness >= newVal[0] && item.hardness <= newVal[1]) {
        updatedRecipeList.push(item);
      }
    });
    //remove duplicate values
    const uniqueArray = updatedRecipeList.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return (
        index ===
        updatedRecipeList.findIndex((obj) => {
          return JSON.stringify(obj) === _thing;
        })
      );
    });
    this.setState({ slider5: newVal, recipes: uniqueArray });
  }

  render() {
    const { recipes, loading, columns } = this.state;
    return (
      <div>
        {loading && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              display: "block",
              backgroundColor: "darkgrey",
              padding: "5px 10px",
            }}
          >
            Loading ...
          </div>
        )}

        <SwipeableTemporaryDrawer
          slider1Val={this.state.slider1}
          initialValueSlider1={this.state.initialValueSlider1}
          slider2Val={this.state.slider2}
          initialValueSlider2={this.state.initialValueSlider2}
          slider3Val={this.state.slider3}
          initialValueSlider3={this.state.initialValueSlider3}
          slider4Val={this.state.slider4}
          initialValueSlider5={this.state.initialValueSlider5}
          slider5Val={this.state.slider5}
          onChangeSlider1={this.onChangeSlider1}
          onChangeSlider2={this.onChangeSlider2}
          onChangeSlider3={this.onChangeSlider3}
          onChangeSlider4={this.onChangeSlider4}
          onChangeSlider5={this.onChangeSlider5}
        />
        <RecipesList columns={columns} recipes={recipes} />
      </div>
    );
  }
}

const RecipesList = ({ columns, recipes }) => {
  return (
      <React.Fragment>
        <Container maxWidth="lg">
          <MaterialTable
              title="Recipe Database"
              columns={columns}
              data={recipes}
              localization={{
                pagination: {
                  labelRowsSelect: "Recipes",
                },
                toolbar: {
                  searchTooltip: "Search Recipes",
                  searchPlaceholder: "Search Recipes",
                },
              }}
              options={{
                exportButton: true,
                pageSize: 10,
              }}
          />
        </Container>
      </React.Fragment>
  )

}

export default compose(withFirebase)(RecipesPage);
