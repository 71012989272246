import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import AIButton from "./AIButton";
import SignOutButton from "../SignOut";
import { ReactComponent as Logo } from "./logo.svg";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ListAltIcon from "@material-ui/icons/ListAlt";
import BuildIcon from "@material-ui/icons/Build";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { AuthUserContext } from "../Session";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Header({ history }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    history.listen(() => {
      localStorage.setItem("AIToggle", false);
      setAnchorEl(null);
    })
  }, [])

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{
        paddingTop: "0",
        paddingBottom: "0",
      }}
    >
      <MenuItem
          style={{
            paddingTop: "0",
            paddingBottom: "0",
          }}
      >
        <SignOutButton display={isMenuOpen} />
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Box style={{ display: "flex", alignItems: "center", flex: "1" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Logo />
            <Typography
              component={Link}
              to="/"
              variant="h6"
              style={{
                color: "white",
                textDecoration: "none",
                marginLeft: "20px",
              }}
              noWrap
            >
              MAT AI
            </Typography>
          </Box>
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser ? (
                <div>
                  <AIButton />
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              ) : // <Button component={Link} to={ROUTES.SIGN_IN} color="inherit">
              //   Login
              // </Button>
              null
            }
          </AuthUserContext.Consumer>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <AuthUserContext.Consumer>
          {(authUser) =>
            authUser ? (
              <NavigationAuth
                handleDrawerClose={handleDrawerClose}
                classes={classes}
                authUser={authUser}
              />
            ) : (
              <NavigationNonAuth />
            )
          }
        </AuthUserContext.Consumer>
      </Drawer>
    </div>
  );
}

export default withRouter(Header);

const NavigationAuth = ({ handleDrawerClose, classes, authUser }) => (
  <div>
    <List
      style={{
        paddingTop: "20px",
      }}
    >
      <ListItem button component={Link} to="/" onClick={handleDrawerClose}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to={ROUTES.WORK_ORDERS}
        onClick={handleDrawerClose}
      >
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to={ROUTES.RECIPES}
        onClick={handleDrawerClose}
      >
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Recipe Database" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to={ROUTES.COMMODITIES}
        onClick={handleDrawerClose}
      >
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Compound Database" />
      </ListItem>
      <ListItem button component={Link} to={ROUTES.WORK_ORDERS} disabled={true}>
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText primary="Production" />
      </ListItem>
      <ListItem button disabled={true}>
        <ListItemIcon>
          <AssignmentTurnedInIcon />
        </ListItemIcon>
        <ListItemText primary="QM-Reports" />
      </ListItem>
      <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
    </List>
    {/* <List
      className="listInternal"
      style={{ position: "absolute", bottom: "0" }}
    >
      <ListItem button component={Link} to={ROUTES.ACCOUNT}>
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="My Account" />
      </ListItem>
      <ListItem button component={Link} to={ROUTES.RECIPE_UPLOAD}>
        <ListItemIcon>
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </List> */}
  </div>
);

const NavigationNonAuth = (handleDrawerClose, classes, authUser) => (
  <div>
    <List
      style={{
        paddingTop: "20px",
      }}
    >
      <ListItem button component={Link} to={ROUTES.SIGN_IN}>
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="Sign In" />
      </ListItem>
      <ListItem button component={Link} to={ROUTES.SIGN_UP}>
        <ListItemIcon>
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Up" />
      </ListItem>
    </List>
  </div>
);
