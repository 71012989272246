import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import {StampDataAI, StampDataWithoutAI, StampDataYellowLine} from "../../data/StampData";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stripLinesExample = [];

class DisplayGraphStamp1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.updateChart = this.updateChart.bind(this);
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }
    componentDidMount() {
        this.updateChart();
        setInterval(() => {
            if(!this.state.loaded) {
                this.updateChart();
            }
        }, 5000);
    }

    updateChart() {
        let x = 0;
        if (localStorage.getItem("AIToggle") === "true") {
            if (x === 0) {
                this.setState({loaded: true});
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: StampDataAI,
                    legendText: 'Stamp pressure [bar]',
                    showInLegend: true,
                    axisYIndex: 1,
                    axisYType: "secondary",
                    color: 'red'
                });
                this.chart.options.data.push({
                    type: "line",
                    axisYIndex: 1,
                    dataPoints: StampDataYellowLine,
                    color: '#bdaa1a',
                    legendText: 'Target stamp pressure [bar]',
                    showInLegend: true,
                    axisYType: "secondary",
                });

                this.chart.render();
                x++;
            }
        } else {
            return;
        }
    }
    toggleDataSeries(e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    render() {
        const options = {
            theme: "light2",
            animationEnabled: true,
            zoomEnabled: true,
            exportEnabled: true,
            axisX: {
                title: "Time [s]",
                titleFontColor: "#6D78AD",
                // maximum: 450,
                showInLegend: true
            },
            axisY2: {
                title: "Stamp pressure [bar]",
                showInLegend: true,
                titleFontColor: "#6D78AD",
                lineColor: "#6D78AD",
                labelFontColor: "#6D78AD",
                tickColor: "#6D78AD",
                includeZero: false,
                stripLines: stripLinesExample,
            },
            axisY: [{
                title: "Stamp displacement [mm]",
                titleFontColor: "#6D78AD",
                lineColor: "#6D78AD",
                labelFontColor: "#6D78AD",
                tickColor: "#6D78AD",
                includeZero: false,
                stripLines: stripLinesExample,
                showInLegend: true
            }],
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
            },
            data: [
                {
                    axisYIndex: 0,
                    type: "line",
                    name: "Stamp displacement [mm]",
                    showInLegend: true,
                    dataPoints: StampDataWithoutAI,
                },
            ],
        };

        return (
            <div className="MultipleAxisChart">
                <CanvasJSChart
                    options={options}
                    onRef={(rheoref) => (this.chart = rheoref)}
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}

export default DisplayGraphStamp1;
