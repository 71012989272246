import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class OrderRecipts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPages: 1,
            allPages: []
        };
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    onDocumentLoadSuccess({ numPages }) {
        console.log(numPages, "hit");
        let arr = [];
        for(let i = 0; i < numPages; i++) {
            arr.push(i+1);
        }
        this.setState({allPages: arr});
    }

    render() {
        return (
            <div>
                <div onClick={() => this.props.history.goBack()}>GO BACK</div>
                <div
                    style={{
                        boxShadow: "3px 3px 5px 6px #ccc",
                        width: "fit-content",
                        maxWidth: "1200px",
                        margin: "0 auto",
                    }}
                >
                    <Document
                        file={this.props.file}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        {
                            this.state.allPages.length > 0 ? this.state.allPages.map((item, i) => {
                                console.log(item)
                                return <Page pageNumber={item} scale={1} key={i}  />
                            }) : void 0
                        }
                    </Document>
                </div>
            </div>
        )
    }
}

export default OrderRecipts;
