import React from "react";
import Container from "@material-ui/core/Container";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FilterNoneIcon from "@material-ui/icons/FilterNone";

function valuetext(value) {
    return `${value}`;
}

class MaterialDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            right: false,
            classesToFilter: [],
            groupsToFilter: []
        };
        this.onClickCheckboxClass = this.onClickCheckboxClass.bind(this);
        this.onClickCheckboxGroup = this.onClickCheckboxGroup.bind(this);
    }

    toggleDrawer(anchor, open) {
        this.setState({[anchor]: open})
    }

    onClickCheckboxClass(val) {
        console.log(val);
        this.setState(prevState => ({
            [val]: !prevState[val]
        }), () => {
            if(this.state[val]) {
                let arr = this.state.classesToFilter;
                arr.push(val);
                this.setState({classToFilter: arr}, () => {
                    this.props.onChangeClass(arr)
                });
            } else if(!this.state[val]) {
                let arr = this.state.classesToFilter;
                let index = arr.indexOf(val);
                if(index > -1) {
                    arr.splice(index, 1);
                }
                this.setState({classToFilter: arr}, () => {
                    this.props.onChangeClass(arr)
                });
            }
        });
    }

    onClickCheckboxGroup(val) {
        this.setState(prevState => ({
            [val]: !prevState[val]
        }), () => {
            if(this.state[val]) {
                let arr = this.state.groupsToFilter;
                arr.push(val);
                this.setState({groupsToFilter: arr}, () => {
                    this.props.onChangeGroup(arr)
                });
            } else if(!this.state[val]) {
                let arr = this.state.groupsToFilter;
                let index = arr.indexOf(val);
                if(index > -1) {
                    arr.splice(index, 1);
                }
                this.setState({groupsToFilter: arr}, () => {
                    this.props.onChangeGroup(arr)
                });
            }
        });
    }

    render() {
        const list = (anchor) => (
            <div
                role="presentation"
            >
                <Divider/>
                <List>
                    <ListItem style={{alignItems: "flex-start", flexDirection: "column"}}>
                        <Typography id="discrete-slider" gutterBottom>
                            Density
                        </Typography>
                        <Slider
                            value={this.props.slider5Val}
                            onChange={this.props.onChangeSlider5}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={this.props.initialValueSlider5[0]}
                            max={this.props.initialValueSlider5[1] + 3}
                        />
                    </ListItem>
                </List>
                <List>
                    <ListItem>

                        <FormControl component="fieldset">
                            <FormLabel component="legend">Group</FormLabel>
                            <FormGroup>
                                {
                                    this.props.groupChecklist.map((item, i) => {
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                control={
                                                    <Checkbox
                                                        onChange={() => this.onClickCheckboxGroup(item)}
                                                        name={item}
                                                        checked={this.state[item]}
                                                    />
                                                }
                                                label={item}
                                            />
                                        )
                                    })
                                }
                            </FormGroup>
                            <FormLabel component="legend">Class</FormLabel>
                            <FormGroup>
                                {
                                    this.props.classChecklist.map((item, i) => {
                                        return (
                                            <FormControlLabel
                                                key={i}
                                                control={
                                                    <Checkbox
                                                        onChange={() => this.onClickCheckboxClass(item)}
                                                        name={item}
                                                        checked={this.state[item]}
                                                    />
                                                }
                                                label={item}
                                            />
                                        )
                                    })
                                }
                            </FormGroup>
                            <FormHelperText>(following DIN 53 517)</FormHelperText>
                        </FormControl>
                    </ListItem>
                </List>
            </div>
        );
        return (
            <div>
                <React.Fragment key={"right"}>
                    <Container maxWidth="lg" style={{position: "relative"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => this.toggleDrawer("right", true)}
                            style={{
                                position: "absolute",
                                zIndex: "1",
                                left: "220px",
                                top: "13px",
                            }}
                        >
                            <FilterNoneIcon style={{marginRight: "10px"}}/> Filter
                        </Button>
                    </Container>
                    <SwipeableDrawer
                        anchor={"right"}
                        open={this.state["right"]}
                        onClose={() => this.toggleDrawer("right", false)}
                        onOpen={() => this.toggleDrawer("right", true)}
                    >
                        {list("right")}
                    </SwipeableDrawer>
                </React.Fragment>
            </div>
        );

    }
}

export default MaterialDrawer;
