import React, {Component} from "react";
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ReceiptIcon from "@material-ui/icons/Receipt";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentIcon from "@material-ui/icons/Assignment";

import * as ROUTES from "../../constants/routes";

class DisplayQuickStats extends Component {
    state = {};

    render() {
        const {recipe, production} = this.props;

        // // Fetch Objects from Array position
        // let recipeArray = Recipe[0];
        // let productionArray = production[0];

        // // Assign individual values
        // let recipeName = recipeArray.recipeName;
        // let internalMixer = productionArray.internalMixer;
        // let tempValue = productionArray.setTempValue;
        // let rotationalSpeed = productionArray.setRotationalSpeed;
        // let torque = productionArray.setTorque;
        return (
            <React.Fragment>
                <Divider style={{marginBottom: "22px"}}/>
                <Box>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>Recipe</strong>
                                    </TableCell>
                                    <TableCell align="right"><Link to={'/recipes/2'}>DIK EPDM CB60</Link></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>Mixer</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        {production.internalMixer}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>Temperature (°C):</strong>
                                    </TableCell>
                                    <TableCell align="right">{production.setTempValue}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>Rotational speed (U/min)</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        {production.setRotationalSpeed}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>Torque (N m):</strong>
                                    </TableCell>
                                    <TableCell align="right">{production.setTorque}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box mt={2}>
                    <ButtonGroup
                        variant="contained"
                        fullWidth={true}
                        aria-label="contained primary button group"
                    >
                        <Button
                            color="secondary"
                            startIcon={<ReceiptIcon/>}
                            component={Link}
                            to={ROUTES.PDF_RECIPE}
                        >
                            Recipe
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={<BusinessIcon/>}
                            component={Link}
                            to={ROUTES.PDF_OEM}
                        >
                            OEM
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={<AssignmentIcon/>}
                            component={Link}
                            to={ROUTES.PDF_REPORT}
                        >
                            Report
                        </Button>
                    </ButtonGroup>
                </Box>
            </React.Fragment>
        );
    }
}

export default DisplayQuickStats;
