export const line2 = [
    {
        "x": 0.00000193,
        "y": 9.93792e-8
    },
    {
        "x": 0.00000193,
        "y": 9.93792e-8
    },
    {
        "x": 0.151709,
        "y": 0.007799951
    },
    {
        "x": 0.429838,
        "y": 0.022038649
    },
    {
        "x": 0.707967,
        "y": 0.03619928
    },
    {
        "x": 0.935527,
        "y": 0.047727891
    },
    {
        "x": 1.23894,
        "y": 0.06302007
    },
    {
        "x": 1.51707,
        "y": 0.076959373
    },
    {
        "x": 1.76991,
        "y": 0.089566801
    },
    {
        "x": 2.04804,
        "y": 0.103365325
    },
    {
        "x": 2.2756,
        "y": 0.11460112
    },
    {
        "x": 2.55373,
        "y": 0.12826878
    },
    {
        "x": 2.75601,
        "y": 0.138164635
    },
    {
        "x": 2.95828,
        "y": 0.148022975
    },
    {
        "x": 3.18584,
        "y": 0.159070085
    },
    {
        "x": 3.4134,
        "y": 0.17007126
    },
    {
        "x": 3.69153,
        "y": 0.18345552
    },
    {
        "x": 3.96966,
        "y": 0.196772754
    },
    {
        "x": 4.24779,
        "y": 0.210023822
    },
    {
        "x": 4.52592,
        "y": 0.223209571
    },
    {
        "x": 4.77876,
        "y": 0.235140381
    },
    {
        "x": 5.03161,
        "y": 0.24701899
    },
    {
        "x": 5.28445,
        "y": 0.25884507
    },
    {
        "x": 5.5373,
        "y": 0.270620161
    },
    {
        "x": 5.81543,
        "y": 0.283513807
    },
    {
        "x": 6.06827,
        "y": 0.295182309
    },
    {
        "x": 6.29583,
        "y": 0.305641696
    },
    {
        "x": 6.54867,
        "y": 0.317216394
    },
    {
        "x": 6.8268,
        "y": 0.329892789
    },
    {
        "x": 7.05436,
        "y": 0.340221173
    },
    {
        "x": 7.30721,
        "y": 0.351652363
    },
    {
        "x": 7.61062,
        "y": 0.365307501
    },
    {
        "x": 7.8129,
        "y": 0.37437418
    },
    {
        "x": 8.14159,
        "y": 0.389044451
    },
    {
        "x": 8.36915,
        "y": 0.399156291
    },
    {
        "x": 8.64728,
        "y": 0.411466169
    },
    {
        "x": 8.92541,
        "y": 0.423722693
    },
    {
        "x": 9.25411,
        "y": 0.43813985
    },
    {
        "x": 9.53224,
        "y": 0.450282294
    },
    {
        "x": 9.83565,
        "y": 0.463469982
    },
    {
        "x": 10.1138,
        "y": 0.47550689
    },
    {
        "x": 10.4172,
        "y": 0.488579604
    },
    {
        "x": 10.67,
        "y": 0.499427336
    },
    {
        "x": 10.9482,
        "y": 0.511318557
    },
    {
        "x": 11.201,
        "y": 0.522082402
    },
    {
        "x": 11.4791,
        "y": 0.533878184
    },
    {
        "x": 11.732,
        "y": 0.544564379
    },
    {
        "x": 11.9848,
        "y": 0.555208049
    },
    {
        "x": 12.263,
        "y": 0.566877404
    },
    {
        "x": 12.5158,
        "y": 0.577442062
    },
    {
        "x": 12.7939,
        "y": 0.589021355
    },
    {
        "x": 13.0468,
        "y": 0.599513044
    },
    {
        "x": 13.3502,
        "y": 0.61205216
    },
    {
        "x": 13.603,
        "y": 0.622460883
    },
    {
        "x": 13.8812,
        "y": 0.63387479
    },
    {
        "x": 14.1087,
        "y": 0.643177314
    },
    {
        "x": 14.4121,
        "y": 0.655540147
    },
    {
        "x": 14.7156,
        "y": 0.667858209
    },
    {
        "x": 14.9937,
        "y": 0.67910305
    },
    {
        "x": 15.2718,
        "y": 0.690307946
    },
    {
        "x": 15.4994,
        "y": 0.699448779
    },
    {
        "x": 15.7775,
        "y": 0.71058232
    },
    {
        "x": 16.0051,
        "y": 0.719665426
    },
    {
        "x": 16.3338,
        "y": 0.732738225
    },
    {
        "x": 16.6119,
        "y": 0.743757571
    },
    {
        "x": 16.9406,
        "y": 0.756734068
    },
    {
        "x": 17.1934,
        "y": 0.766679388
    },
    {
        "x": 17.4463,
        "y": 0.776598769
    },
    {
        "x": 17.7244,
        "y": 0.787472472
    },
    {
        "x": 18.0025,
        "y": 0.798310911
    },
    {
        "x": 18.2554,
        "y": 0.808136985
    },
    {
        "x": 18.5335,
        "y": 0.818909325
    },
    {
        "x": 18.7863,
        "y": 0.828672161
    },
    {
        "x": 19.0645,
        "y": 0.839383848
    },
    {
        "x": 19.3173,
        "y": 0.849088753
    },
    {
        "x": 19.5702,
        "y": 0.858770394
    },
    {
        "x": 19.8989,
        "y": 0.871313849
    },
    {
        "x": 20.2023,
        "y": 0.882852252
    },
    {
        "x": 20.4298,
        "y": 0.89147955
    },
    {
        "x": 20.7333,
        "y": 0.902956526
    },
    {
        "x": 21.0114,
        "y": 0.913440868
    },
    {
        "x": 21.2642,
        "y": 0.922945087
    },
    {
        "x": 21.5424,
        "y": 0.933375635
    },
    {
        "x": 21.7952,
        "y": 0.942828181
    },
    {
        "x": 22.1745,
        "y": 0.956965515
    },
    {
        "x": 22.4273,
        "y": 0.966358188
    },
    {
        "x": 22.6549,
        "y": 0.974794493
    },
    {
        "x": 22.9836,
        "y": 0.986945056
    },
    {
        "x": 23.2617,
        "y": 0.997194965
    },
    {
        "x": 23.5904,
        "y": 1.009274658
    },
    {
        "x": 23.8432,
        "y": 1.018539454
    },
    {
        "x": 24.1719,
        "y": 1.030553097
    },
    {
        "x": 24.3995,
        "y": 1.038850194
    },
    {
        "x": 24.5765,
        "y": 1.04529069
    },
    {
        "x": 24.8293,
        "y": 1.054471313
    },
    {
        "x": 25.0822,
        "y": 1.0636346
    },
    {
        "x": 25.4109,
        "y": 1.075513405
    },
    {
        "x": 25.6637,
        "y": 1.084625805
    },
    {
        "x": 25.9166,
        "y": 1.093721657
    },
    {
        "x": 26.22,
        "y": 1.104607544
    },
    {
        "x": 26.5234,
        "y": 1.11546518
    },
    {
        "x": 26.7762,
        "y": 1.124490734
    },
    {
        "x": 27.0796,
        "y": 1.135297633
    },
    {
        "x": 27.3325,
        "y": 1.144285023
    },
    {
        "x": 27.6359,
        "y": 1.155042516
    },
    {
        "x": 27.8888,
        "y": 1.163989273
    },
    {
        "x": 28.1922,
        "y": 1.174698672
    },
    {
        "x": 28.4956,
        "y": 1.185382383
    },
    {
        "x": 28.7737,
        "y": 1.195152956
    },
    {
        "x": 29.0771,
        "y": 1.205788484
    },
    {
        "x": 29.3047,
        "y": 1.213750714
    },
    {
        "x": 29.6587,
        "y": 1.226107703
    },
    {
        "x": 29.9115,
        "y": 1.234912178
    },
    {
        "x": 30.1896,
        "y": 1.244578873
    },
    {
        "x": 30.4425,
        "y": 1.253352631
    },
    {
        "x": 30.7206,
        "y": 1.262982209
    },
    {
        "x": 30.9482,
        "y": 1.27084897
    },
    {
        "x": 31.2516,
        "y": 1.281316089
    },
    {
        "x": 31.4791,
        "y": 1.289150201
    },
    {
        "x": 31.8331,
        "y": 1.301316072
    },
    {
        "x": 32.1113,
        "y": 1.310856429
    },
    {
        "x": 32.3641,
        "y": 1.319510322
    },
    {
        "x": 32.6169,
        "y": 1.328149729
    },
    {
        "x": 32.8951,
        "y": 1.337640659
    },
    {
        "x": 33.1479,
        "y": 1.346250246
    },
    {
        "x": 33.4008,
        "y": 1.354849316
    },
    {
        "x": 33.6283,
        "y": 1.362572993
    },
    {
        "x": 33.9823,
        "y": 1.374569566
    },
    {
        "x": 34.2099,
        "y": 1.382268791
    },
    {
        "x": 34.4627,
        "y": 1.390807977
    },
    {
        "x": 34.7408,
        "y": 1.40018676
    },
    {
        "x": 34.9937,
        "y": 1.408702233
    },
    {
        "x": 35.2971,
        "y": 1.418901446
    },
    {
        "x": 35.5752,
        "y": 1.428234439
    },
    {
        "x": 35.8281,
        "y": 1.436708857
    },
    {
        "x": 36.1062,
        "y": 1.446013757
    },
    {
        "x": 36.3843,
        "y": 1.455304265
    },
    {
        "x": 36.6625,
        "y": 1.464583929
    },
    {
        "x": 36.9153,
        "y": 1.473004226
    },
    {
        "x": 37.1934,
        "y": 1.482254082
    },
    {
        "x": 37.4463,
        "y": 1.490653988
    },
    {
        "x": 37.7244,
        "y": 1.499878138
    },
    {
        "x": 37.952,
        "y": 1.507417478
    },
    {
        "x": 38.2807,
        "y": 1.518290463
    },
    {
        "x": 38.5335,
        "y": 1.526640632
    },
    {
        "x": 38.8116,
        "y": 1.535814484
    },
    {
        "x": 39.0392,
        "y": 1.543313246
    },
    {
        "x": 39.3173,
        "y": 1.552464746
    },
    {
        "x": 39.6207,
        "y": 1.5624351
    },
    {
        "x": 39.8736,
        "y": 1.570735171
    },
    {
        "x": 40.1011,
        "y": 1.578193403
    },
    {
        "x": 40.354,
        "y": 1.586475327
    },
    {
        "x": 40.6321,
        "y": 1.595571709
    },
    {
        "x": 40.885,
        "y": 1.603834167
    },
    {
        "x": 41.1631,
        "y": 1.612909485
    },
    {
        "x": 41.4159,
        "y": 1.621149835
    },
    {
        "x": 41.7446,
        "y": 1.631851169
    },
    {
        "x": 42.0228,
        "y": 1.640897049
    },
    {
        "x": 42.2503,
        "y": 1.648286781
    },
    {
        "x": 42.5537,
        "y": 1.65813146
    },
    {
        "x": 42.8319,
        "y": 1.66714812
    },
    {
        "x": 43.0847,
        "y": 1.675333128
    },
    {
        "x": 43.3123,
        "y": 1.682695477
    },
    {
        "x": 43.5904,
        "y": 1.691682848
    },
    {
        "x": 43.8938,
        "y": 1.701477316
    },
    {
        "x": 44.1467,
        "y": 1.709633284
    },
    {
        "x": 44.4248,
        "y": 1.718593457
    },
    {
        "x": 44.7029,
        "y": 1.727544895
    },
    {
        "x": 45.0063,
        "y": 1.737300916
    },
    {
        "x": 45.2844,
        "y": 1.746234618
    },
    {
        "x": 45.512,
        "y": 1.753539921
    },
    {
        "x": 45.8154,
        "y": 1.76326976
    },
    {
        "x": 46.043,
        "y": 1.770562534
    },
    {
        "x": 46.2453,
        "y": 1.777040253
    },
    {
        "x": 46.4223,
        "y": 1.782704517
    },
    {
        "x": 46.7004,
        "y": 1.791597935
    },
    {
        "x": 47.0038,
        "y": 1.801291945
    },
    {
        "x": 47.2061,
        "y": 1.807750861
    },
    {
        "x": 47.4842,
        "y": 1.816623698
    },
    {
        "x": 47.7623,
        "y": 1.82548952
    },
    {
        "x": 48.0152,
        "y": 1.833546
    },
    {
        "x": 48.3186,
        "y": 1.84320389
    },
    {
        "x": 48.5714,
        "y": 1.851245086
    },
    {
        "x": 48.8243,
        "y": 1.859284135
    },
    {
        "x": 49.0771,
        "y": 1.867314787
    },
    {
        "x": 49.33,
        "y": 1.875343503
    },
    {
        "x": 49.5828,
        "y": 1.883364039
    },
    {
        "x": 49.8357,
        "y": 1.891382845
    },
    {
        "x": 50.1138,
        "y": 1.900195142
    },
    {
        "x": 50.3666,
        "y": 1.908200826
    },
    {
        "x": 50.6195,
        "y": 1.916205096
    },
    {
        "x": 50.9229,
        "y": 1.925801785
    },
    {
        "x": 51.1504,
        "y": 1.932993595
    },
    {
        "x": 51.4286,
        "y": 1.941783464
    },
    {
        "x": 51.6561,
        "y": 1.948967694
    },
    {
        "x": 51.9343,
        "y": 1.957748511
    },
    {
        "x": 52.1618,
        "y": 1.964925516
    },
    {
        "x": 52.3894,
        "y": 1.972102537
    },
    {
        "x": 52.6422,
        "y": 1.980070609
    },
    {
        "x": 52.9204,
        "y": 1.98883501
    },
    {
        "x": 53.1732,
        "y": 1.996795442
    },
    {
        "x": 53.426,
        "y": 2.004752384
    },
    {
        "x": 53.6789,
        "y": 2.012709074
    },
    {
        "x": 53.957,
        "y": 2.021454787
    },
    {
        "x": 54.2099,
        "y": 2.029404641
    },
    {
        "x": 54.3869,
        "y": 2.034966738
    },
    {
        "x": 54.665,
        "y": 2.043702824
    },
    {
        "x": 54.8925,
        "y": 2.050846731
    },
    {
        "x": 55.2212,
        "y": 2.061164413
    },
    {
        "x": 55.4235,
        "y": 2.067512155
    },
    {
        "x": 55.6511,
        "y": 2.074651708
    },
    {
        "x": 55.9545,
        "y": 2.084165751
    },
    {
        "x": 56.1821,
        "y": 2.091300481
    },
    {
        "x": 56.4349,
        "y": 2.099222876
    },
    {
        "x": 56.6625,
        "y": 2.10635354
    },
    {
        "x": 56.9153,
        "y": 2.114271573
    },
    {
        "x": 57.1681,
        "y": 2.122187433
    },
    {
        "x": 57.421,
        "y": 2.130104335
    },
    {
        "x": 57.6738,
        "y": 2.138016101
    },
    {
        "x": 57.9267,
        "y": 2.145929074
    },
    {
        "x": 58.2048,
        "y": 2.154628405
    },
    {
        "x": 58.4071,
        "y": 2.160955277
    },
    {
        "x": 58.6599,
        "y": 2.168860009
    },
    {
        "x": 58.8875,
        "y": 2.175975394
    },
    {
        "x": 59.1656,
        "y": 2.184667865
    },
    {
        "x": 59.4185,
        "y": 2.192571148
    },
    {
        "x": 59.6713,
        "y": 2.20046994
    },
    {
        "x": 59.8989,
        "y": 2.207580248
    },
    {
        "x": 60.2023,
        "y": 2.217057053
    },
    {
        "x": 60.4298,
        "y": 2.224162025
    },
    {
        "x": 60.6827,
        "y": 2.232059255
    },
    {
        "x": 60.9102,
        "y": 2.239162488
    },
    {
        "x": 61.1884,
        "y": 2.24784773
    },
    {
        "x": 61.4159,
        "y": 2.254949398
    },
    {
        "x": 61.6182,
        "y": 2.261263901
    },
    {
        "x": 61.8458,
        "y": 2.268367572
    },
    {
        "x": 62.1239,
        "y": 2.277046717
    },
    {
        "x": 62.3515,
        "y": 2.284149319
    },
    {
        "x": 62.6043,
        "y": 2.292037863
    },
    {
        "x": 62.8571,
        "y": 2.299925992
    },
    {
        "x": 63.11,
        "y": 2.307816898
    },
    {
        "x": 63.3628,
        "y": 2.315704411
    },
    {
        "x": 63.6157,
        "y": 2.323594844
    },
    {
        "x": 63.818,
        "y": 2.329906469
    },
    {
        "x": 64.0961,
        "y": 2.338582934
    },
    {
        "x": 64.3489,
        "y": 2.346470062
    },
    {
        "x": 64.6018,
        "y": 2.354360383
    },
    {
        "x": 64.8293,
        "y": 2.361458358
    },
    {
        "x": 65.0569,
        "y": 2.368559617
    },
    {
        "x": 65.3097,
        "y": 2.376447384
    },
    {
        "x": 65.5879,
        "y": 2.385128057
    },
    {
        "x": 65.8154,
        "y": 2.3922271
    },
    {
        "x": 66.0683,
        "y": 2.400119183
    },
    {
        "x": 66.3464,
        "y": 2.408798282
    },
    {
        "x": 66.4981,
        "y": 2.413532923
    },
    {
        "x": 66.7004,
        "y": 2.41984718
    },
    {
        "x": 66.8774,
        "y": 2.425372129
    },
    {
        "x": 67.1808,
        "y": 2.434843436
    },
    {
        "x": 67.4336,
        "y": 2.442736046
    },
    {
        "x": 67.6865,
        "y": 2.450632662
    },
    {
        "x": 67.914,
        "y": 2.457736988
    },
    {
        "x": 68.1669,
        "y": 2.465635457
    },
    {
        "x": 68.3944,
        "y": 2.472741552
    },
    {
        "x": 68.622,
        "y": 2.479851681
    },
    {
        "x": 68.8748,
        "y": 2.487750169
    },
    {
        "x": 69.153,
        "y": 2.496443691
    },
    {
        "x": 69.3805,
        "y": 2.503554053
    },
    {
        "x": 69.6081,
        "y": 2.510668645
    },
    {
        "x": 69.8104,
        "y": 2.516993343
    },
    {
        "x": 70.0379,
        "y": 2.524107015
    },
    {
        "x": 70.3161,
        "y": 2.532807697
    },
    {
        "x": 70.493,
        "y": 2.538341223
    },
    {
        "x": 70.7965,
        "y": 2.547836733
    },
    {
        "x": 71.024,
        "y": 2.554956055
    },
    {
        "x": 71.3022,
        "y": 2.563663899
    },
    {
        "x": 71.4539,
        "y": 2.568413131
    },
    {
        "x": 71.732,
        "y": 2.577121274
    },
    {
        "x": 71.9343,
        "y": 2.583457342
    },
    {
        "x": 72.1618,
        "y": 2.59058418
    },
    {
        "x": 72.3641,
        "y": 2.596922949
    },
    {
        "x": 72.5411,
        "y": 2.602470061
    },
    {
        "x": 72.7939,
        "y": 2.610394502
    },
    {
        "x": 73.0215,
        "y": 2.617530846
    },
    {
        "x": 73.2743,
        "y": 2.625459426
    },
    {
        "x": 73.4766,
        "y": 2.631805795
    },
    {
        "x": 73.7295,
        "y": 2.63974162
    },
    {
        "x": 73.957,
        "y": 2.646882425
    },
    {
        "x": 74.2351,
        "y": 2.655614124
    },
    {
        "x": 74.4374,
        "y": 2.66196775
    },
    {
        "x": 74.6903,
        "y": 2.669912832
    },
    {
        "x": 74.9684,
        "y": 2.678652561
    },
    {
        "x": 75.196,
        "y": 2.685807609
    },
    {
        "x": 75.4488,
        "y": 2.693757407
    },
    {
        "x": 75.6764,
        "y": 2.700917072
    },
    {
        "x": 75.9039,
        "y": 2.708075837
    },
    {
        "x": 76.1315,
        "y": 2.715240034
    },
    {
        "x": 76.359,
        "y": 2.722403405
    },
    {
        "x": 76.5866,
        "y": 2.729572287
    },
    {
        "x": 76.8394,
        "y": 2.737537726
    },
    {
        "x": 77.0164,
        "y": 2.743116583
    },
    {
        "x": 77.244,
        "y": 2.750292499
    },
    {
        "x": 77.4968,
        "y": 2.758265876
    },
    {
        "x": 77.7244,
        "y": 2.765447128
    },
    {
        "x": 77.9014,
        "y": 2.77103363
    },
    {
        "x": 78.129,
        "y": 2.778219503
    },
    {
        "x": 78.3312,
        "y": 2.78460566
    },
    {
        "x": 78.5841,
        "y": 2.792596077
    },
    {
        "x": 78.8116,
        "y": 2.799786848
    },
    {
        "x": 79.0645,
        "y": 2.807783693
    },
    {
        "x": 79.2415,
        "y": 2.813382589
    },
    {
        "x": 79.4943,
        "y": 2.821382173
    },
    {
        "x": 79.6966,
        "y": 2.827786282
    },
    {
        "x": 79.9494,
        "y": 2.835792266
    },
    {
        "x": 80.1517,
        "y": 2.842201559
    },
    {
        "x": 80.354,
        "y": 2.848613197
    },
    {
        "x": 80.5815,
        "y": 2.855826347
    },
    {
        "x": 80.7838,
        "y": 2.862243046
    },
    {
        "x": 80.9861,
        "y": 2.868662166
    },
    {
        "x": 81.1884,
        "y": 2.87508373
    },
    {
        "x": 81.4159,
        "y": 2.882308162
    },
    {
        "x": 81.6688,
        "y": 2.890342895
    },
    {
        "x": 81.8963,
        "y": 2.89757403
    },
    {
        "x": 82.0986,
        "y": 2.904006888
    },
    {
        "x": 82.3262,
        "y": 2.91124733
    },
    {
        "x": 82.5284,
        "y": 2.917682505
    },
    {
        "x": 82.756,
        "y": 2.924929194
    },
    {
        "x": 83.0089,
        "y": 2.932985361
    },
    {
        "x": 83.2364,
        "y": 2.940235985
    },
    {
        "x": 83.4134,
        "y": 2.945879496
    },
    {
        "x": 83.6157,
        "y": 2.952332234
    },
    {
        "x": 83.818,
        "y": 2.958787721
    },
    {
        "x": 84.0961,
        "y": 2.967666551
    },
    {
        "x": 84.2731,
        "y": 2.97332034
    },
    {
        "x": 84.5512,
        "y": 2.982207869
    },
    {
        "x": 84.7282,
        "y": 2.987867245
    },
    {
        "x": 84.9305,
        "y": 2.994338254
    },
    {
        "x": 85.1833,
        "y": 3.00242869
    },
    {
        "x": 85.3856,
        "y": 3.008906244
    },
    {
        "x": 85.5879,
        "y": 3.015386744
    },
    {
        "x": 85.7396,
        "y": 3.020248263
    },
    {
        "x": 85.8913,
        "y": 3.025111461
    },
    {
        "x": 86.0936,
        "y": 3.031599419
    },
    {
        "x": 86.2705,
        "y": 3.03727525
    },
    {
        "x": 86.4981,
        "y": 3.044581212
    },
    {
        "x": 86.7257,
        "y": 3.051891053
    },
    {
        "x": 86.9785,
        "y": 3.060014829
    },
    {
        "x": 87.2061,
        "y": 3.06733296
    },
    {
        "x": 87.4083,
        "y": 3.073837726
    },
    {
        "x": 87.6359,
        "y": 3.081163389
    },
    {
        "x": 87.8888,
        "y": 3.089308101
    },
    {
        "x": 88.1163,
        "y": 3.096639087
    },
    {
        "x": 88.2933,
        "y": 3.102345583
    },
    {
        "x": 88.4956,
        "y": 3.108870805
    },
    {
        "x": 88.6979,
        "y": 3.1153993
    },
    {
        "x": 88.9507,
        "y": 3.123562133
    },
    {
        "x": 89.153,
        "y": 3.13009807
    },
    {
        "x": 89.3552,
        "y": 3.136634116
    },
    {
        "x": 89.5828,
        "y": 3.143995226
    },
    {
        "x": 89.7851,
        "y": 3.150541671
    },
    {
        "x": 90.0379,
        "y": 3.158727089
    },
    {
        "x": 90.2402,
        "y": 3.165281223
    },
    {
        "x": 90.4678,
        "y": 3.172659155
    },
    {
        "x": 90.6448,
        "y": 3.178399863
    },
    {
        "x": 90.8217,
        "y": 3.184139997
    },
    {
        "x": 91.0746,
        "y": 3.192350878
    },
    {
        "x": 91.2516,
        "y": 3.198100802
    },
    {
        "x": 91.4791,
        "y": 3.205495235
    },
    {
        "x": 91.6814,
        "y": 3.212074387
    },
    {
        "x": 91.8584,
        "y": 3.217833683
    },
    {
        "x": 92.086,
        "y": 3.225243485
    },
    {
        "x": 92.263,
        "y": 3.231009116
    },
    {
        "x": 92.5158,
        "y": 3.239248717
    },
    {
        "x": 92.7181,
        "y": 3.245846483
    },
    {
        "x": 92.9456,
        "y": 3.253270526
    },
    {
        "x": 93.0973,
        "y": 3.258223584
    },
    {
        "x": 93.2996,
        "y": 3.264832012
    },
    {
        "x": 93.4766,
        "y": 3.270617051
    },
    {
        "x": 93.7295,
        "y": 3.278887795
    },
    {
        "x": 93.9317,
        "y": 3.285504722
    },
    {
        "x": 94.134,
        "y": 3.292128727
    },
    {
        "x": 94.3616,
        "y": 3.299585714
    },
    {
        "x": 94.5638,
        "y": 3.306214589
    },
    {
        "x": 94.7661,
        "y": 3.312850607
    },
    {
        "x": 94.9937,
        "y": 3.320321179
    },
    {
        "x": 95.2465,
        "y": 3.328624691
    },
    {
        "x": 95.4235,
        "y": 3.334442111
    },
    {
        "x": 95.6258,
        "y": 3.341094759
    },
    {
        "x": 95.8028,
        "y": 3.346918663
    },
    {
        "x": 96.0556,
        "y": 3.355241929
    },
    {
        "x": 96.2579,
        "y": 3.361907015
    },
    {
        "x": 96.5108,
        "y": 3.37024485
    },
    {
        "x": 96.6372,
        "y": 3.374414485
    },
    {
        "x": 96.89,
        "y": 3.382758504
    },
    {
        "x": 97.067,
        "y": 3.388604423
    },
    {
        "x": 97.3199,
        "y": 3.396962587
    },
    {
        "x": 97.4968,
        "y": 3.402812824
    },
    {
        "x": 97.6991,
        "y": 3.409506932
    },
    {
        "x": 97.9014,
        "y": 3.416205186
    },
    {
        "x": 98.129,
        "y": 3.423746113
    },
    {
        "x": 98.3059,
        "y": 3.429610886
    },
    {
        "x": 98.5082,
        "y": 3.436321682
    },
    {
        "x": 98.6852,
        "y": 3.442196672
    },
    {
        "x": 98.9128,
        "y": 3.449755942
    },
    {
        "x": 99.0645,
        "y": 3.454797335
    },
    {
        "x": 99.292,
        "y": 3.46236226
    },
    {
        "x": 99.4943,
        "y": 3.469093774
    },
    {
        "x": 99.6966,
        "y": 3.47582959
    },
    {
        "x": 99.8483,
        "y": 3.480883451
    },
    {
        "x": 100.076,
        "y": 3.488473814
    },
    {
        "x": 100.278,
        "y": 3.495212082
    },
    {
        "x": 100.48,
        "y": 3.501954704
    },
    {
        "x": 100.683,
        "y": 3.50873511
    },
    {
        "x": 100.86,
        "y": 3.514650705
    },
    {
        "x": 101.113,
        "y": 3.523112203
    },
    {
        "x": 101.29,
        "y": 3.529036031
    },
    {
        "x": 101.492,
        "y": 3.535800722
    },
    {
        "x": 101.669,
        "y": 3.541731861
    },
    {
        "x": 101.922,
        "y": 3.550215664
    },
    {
        "x": 102.124,
        "y": 3.55699435
    },
    {
        "x": 102.301,
        "y": 3.562937794
    },
    {
        "x": 102.528,
        "y": 3.570565259
    },
    {
        "x": 102.781,
        "y": 3.579073111
    },
    {
        "x": 102.958,
        "y": 3.585029495
    },
    {
        "x": 103.186,
        "y": 3.592707304
    },
    {
        "x": 103.338,
        "y": 3.597829092
    },
    {
        "x": 103.59,
        "y": 3.606326226
    },
    {
        "x": 103.767,
        "y": 3.612298753
    },
    {
        "x": 103.919,
        "y": 3.617430545
    },
    {
        "x": 104.071,
        "y": 3.622564971
    },
    {
        "x": 104.248,
        "y": 3.628547202
    },
    {
        "x": 104.475,
        "y": 3.636224587
    },
    {
        "x": 104.703,
        "y": 3.643941762
    },
    {
        "x": 104.88,
        "y": 3.649936865
    },
    {
        "x": 105.107,
        "y": 3.657630813
    },
    {
        "x": 105.31,
        "y": 3.664516379
    },
    {
        "x": 105.537,
        "y": 3.672221697
    },
    {
        "x": 105.714,
        "y": 3.678233993
    },
    {
        "x": 105.917,
        "y": 3.685133982
    },
    {
        "x": 106.144,
        "y": 3.692855484
    },
    {
        "x": 106.321,
        "y": 3.698880441
    },
    {
        "x": 106.523,
        "y": 3.705760921
    },
    {
        "x": 106.726,
        "y": 3.712680352
    },
    {
        "x": 106.903,
        "y": 3.718717563
    },
    {
        "x": 107.13,
        "y": 3.72646569
    },
    {
        "x": 107.332,
        "y": 3.733365702
    },
    {
        "x": 107.56,
        "y": 3.74115974
    },
    {
        "x": 107.737,
        "y": 3.747214706
    },
    {
        "x": 107.99,
        "y": 3.755876141
    },
    {
        "x": 108.192,
        "y": 3.762797185
    },
    {
        "x": 108.42,
        "y": 3.770615039
    },
    {
        "x": 108.546,
        "y": 3.774938162
    },
    {
        "x": 108.774,
        "y": 3.782765911
    },
    {
        "x": 108.976,
        "y": 3.789706366
    },
    {
        "x": 109.178,
        "y": 3.796651861
    },
    {
        "x": 109.355,
        "y": 3.802741917
    },
    {
        "x": 109.558,
        "y": 3.809731347
    },
    {
        "x": 109.785,
        "y": 3.817553192
    },
    {
        "x": 109.962,
        "y": 3.823656629
    },
    {
        "x": 110.19,
        "y": 3.831524468
    },
    {
        "x": 110.392,
        "y": 3.838500553
    },
    {
        "x": 110.619,
        "y": 3.846346152
    },
    {
        "x": 110.746,
        "y": 3.85073838
    },
    {
        "x": 110.999,
        "y": 3.859494342
    },
    {
        "x": 111.15,
        "y": 3.864724107
    },
    {
        "x": 111.378,
        "y": 3.872626211
    },
    {
        "x": 111.58,
        "y": 3.879632745
    },
    {
        "x": 111.783,
        "y": 3.88667923
    },
    {
        "x": 111.96,
        "y": 3.89282753
    },
    {
        "x": 112.187,
        "y": 3.900718543
    },
    {
        "x": 112.364,
        "y": 3.906876065
    },
    {
        "x": 112.617,
        "y": 3.915684539
    },
    {
        "x": 112.769,
        "y": 3.920980583
    },
    {
        "x": 112.971,
        "y": 3.928023402
    },
    {
        "x": 113.123,
        "y": 3.93332646
    },
    {
        "x": 113.35,
        "y": 3.941251786
    },
    {
        "x": 113.552,
        "y": 3.94830996
    },
    {
        "x": 113.78,
        "y": 3.95628306
    },
    {
        "x": 113.957,
        "y": 3.96247743
    },
    {
        "x": 114.109,
        "y": 3.967800194
    },
    {
        "x": 114.311,
        "y": 3.974878604
    },
    {
        "x": 114.539,
        "y": 3.982874607
    },
    {
        "x": 114.766,
        "y": 3.990842418
    },
    {
        "x": 114.943,
        "y": 3.997059979
    },
    {
        "x": 115.12,
        "y": 4.003281733
    },
    {
        "x": 115.348,
        "y": 4.011302392
    },
    {
        "x": 115.525,
        "y": 4.017533777
    },
    {
        "x": 115.702,
        "y": 4.023769386
    },
    {
        "x": 115.879,
        "y": 4.030009229
    },
    {
        "x": 116.081,
        "y": 4.037135589
    },
    {
        "x": 116.283,
        "y": 4.044267489
    },
    {
        "x": 116.485,
        "y": 4.051404942
    },
    {
        "x": 116.662,
        "y": 4.057663622
    },
    {
        "x": 116.865,
        "y": 4.064846929
    },
    {
        "x": 117.042,
        "y": 4.071114816
    },
    {
        "x": 117.295,
        "y": 4.080081471
    },
    {
        "x": 117.497,
        "y": 4.087246947
    },
    {
        "x": 117.75,
        "y": 4.096229477
    },
    {
        "x": 117.952,
        "y": 4.103407663
    },
    {
        "x": 118.129,
        "y": 4.109702115
    },
    {
        "x": 118.357,
        "y": 4.117816646
    },
    {
        "x": 118.584,
        "y": 4.125902789
    },
    {
        "x": 118.786,
        "y": 4.133104446
    },
    {
        "x": 118.963,
        "y": 4.13941951
    },
    {
        "x": 119.115,
        "y": 4.144846126
    },
    {
        "x": 119.317,
        "y": 4.152062845
    },
    {
        "x": 119.52,
        "y": 4.159321086
    },
    {
        "x": 119.722,
        "y": 4.166549353
    },
    {
        "x": 119.899,
        "y": 4.172887783
    },
    {
        "x": 120.126,
        "y": 4.181023239
    },
    {
        "x": 120.329,
        "y": 4.188304769
    },
    {
        "x": 120.556,
        "y": 4.196454129
    },
    {
        "x": 120.759,
        "y": 4.203748122
    },
    {
        "x": 120.986,
        "y": 4.211911453
    },
    {
        "x": 121.138,
        "y": 4.217381783
    },
    {
        "x": 121.29,
        "y": 4.222855438
    },
    {
        "x": 121.416,
        "y": 4.227395334
    },
    {
        "x": 121.618,
        "y": 4.234678361
    },
    {
        "x": 121.82,
        "y": 4.241967289
    },
    {
        "x": 122.048,
        "y": 4.250201501
    },
    {
        "x": 122.2,
        "y": 4.25569517
    },
    {
        "x": 122.427,
        "y": 4.263905793
    },
    {
        "x": 122.63,
        "y": 4.271254698
    },
    {
        "x": 122.832,
        "y": 4.278573381
    },
    {
        "x": 123.009,
        "y": 4.2849912
    },
    {
        "x": 123.236,
        "y": 4.293228692
    },
    {
        "x": 123.439,
        "y": 4.30060168
    },
    {
        "x": 123.616,
        "y": 4.307035301
    },
    {
        "x": 123.793,
        "y": 4.31347355
    },
    {
        "x": 123.97,
        "y": 4.319916433
    },
    {
        "x": 124.197,
        "y": 4.328186138
    },
    {
        "x": 124.425,
        "y": 4.336499979
    },
    {
        "x": 124.576,
        "y": 4.342010335
    },
    {
        "x": 124.804,
        "y": 4.350337054
    },
    {
        "x": 124.981,
        "y": 4.356806574
    },
    {
        "x": 125.209,
        "y": 4.365147107
    },
    {
        "x": 125.411,
        "y": 4.372543044
    },
    {
        "x": 125.588,
        "y": 4.379028687
    },
    {
        "x": 125.765,
        "y": 4.385519049
    },
    {
        "x": 125.917,
        "y": 4.391096466
    },
    {
        "x": 126.144,
        "y": 4.399432401
    },
    {
        "x": 126.271,
        "y": 4.404099522
    },
    {
        "x": 126.473,
        "y": 4.411527857
    },
    {
        "x": 126.65,
        "y": 4.418041941
    },
    {
        "x": 126.877,
        "y": 4.426403142
    },
    {
        "x": 127.08,
        "y": 4.433886997
    },
    {
        "x": 127.282,
        "y": 4.441340238
    },
    {
        "x": 127.484,
        "y": 4.448799728
    },
    {
        "x": 127.686,
        "y": 4.456265479
    },
    {
        "x": 127.889,
        "y": 4.463774509
    },
    {
        "x": 128.04,
        "y": 4.46936416
    },
    {
        "x": 128.243,
        "y": 4.476884267
    },
    {
        "x": 128.445,
        "y": 4.48437365
    },
    {
        "x": 128.673,
        "y": 4.492834605
    },
    {
        "x": 128.85,
        "y": 4.499408538
    },
    {
        "x": 129.027,
        "y": 4.50598734
    },
    {
        "x": 129.229,
        "y": 4.513501311
    },
    {
        "x": 129.456,
        "y": 4.521952824
    },
    {
        "x": 129.633,
        "y": 4.528548358
    },
    {
        "x": 129.81,
        "y": 4.535148796
    },
    {
        "x": 130.038,
        "y": 4.543658295
    },
    {
        "x": 130.215,
        "y": 4.550269985
    },
    {
        "x": 130.417,
        "y": 4.557821553
    },
    {
        "x": 130.594,
        "y": 4.564443809
    },
    {
        "x": 130.796,
        "y": 4.572007458
    },
    {
        "x": 130.999,
        "y": 4.579615053
    },
    {
        "x": 131.226,
        "y": 4.588129804
    },
    {
        "x": 131.353,
        "y": 4.592897132
    },
    {
        "x": 131.555,
        "y": 4.600485095
    },
    {
        "x": 131.732,
        "y": 4.607139295
    },
    {
        "x": 131.96,
        "y": 4.615718174
    },
    {
        "x": 132.137,
        "y": 4.62238382
    },
    {
        "x": 132.339,
        "y": 4.629997071
    },
    {
        "x": 132.516,
        "y": 4.636673466
    },
    {
        "x": 132.693,
        "y": 4.643354893
    },
    {
        "x": 132.87,
        "y": 4.650041359
    },
    {
        "x": 133.072,
        "y": 4.657678408
    },
    {
        "x": 133.274,
        "y": 4.665322043
    },
    {
        "x": 133.477,
        "y": 4.673010163
    },
    {
        "x": 133.654,
        "y": 4.679719043
    },
    {
        "x": 133.831,
        "y": 4.686433004
    },
    {
        "x": 134.033,
        "y": 4.694101483
    },
    {
        "x": 134.21,
        "y": 4.70082635
    },
    {
        "x": 134.412,
        "y": 4.708507295
    },
    {
        "x": 134.614,
        "y": 4.7161949
    },
    {
        "x": 134.817,
        "y": 4.723927284
    },
    {
        "x": 135.019,
        "y": 4.731628277
    },
    {
        "x": 135.196,
        "y": 4.738381678
    },
    {
        "x": 135.424,
        "y": 4.747088562
    },
    {
        "x": 135.626,
        "y": 4.754809704
    },
    {
        "x": 135.803,
        "y": 4.761580788
    },
    {
        "x": 135.98,
        "y": 4.768357046
    },
    {
        "x": 136.182,
        "y": 4.776096732
    },
    {
        "x": 136.41,
        "y": 4.784840736
    },
    {
        "x": 136.612,
        "y": 4.792594822
    },
    {
        "x": 136.814,
        "y": 4.80035569
    },
    {
        "x": 136.991,
        "y": 4.807161638
    },
    {
        "x": 137.244,
        "y": 4.816898985
    },
    {
        "x": 137.446,
        "y": 4.824681143
    },
    {
        "x": 137.649,
        "y": 4.832508705
    },
    {
        "x": 137.826,
        "y": 4.839339358
    },
    {
        "x": 138.053,
        "y": 4.848107271
    },
    {
        "x": 138.205,
        "y": 4.853983138
    },
    {
        "x": 138.382,
        "y": 4.860830332
    },
    {
        "x": 138.483,
        "y": 4.864739854
    },
    {
        "x": 138.685,
        "y": 4.872564062
    },
    {
        "x": 138.837,
        "y": 4.878456131
    },
    {
        "x": 139.09,
        "y": 4.888271992
    },
    {
        "x": 139.267,
        "y": 4.895145665
    },
    {
        "x": 139.494,
        "y": 4.903968837
    },
    {
        "x": 139.671,
        "y": 4.910854657
    },
    {
        "x": 139.874,
        "y": 4.918758521
    },
    {
        "x": 140.076,
        "y": 4.926630426
    },
    {
        "x": 140.253,
        "y": 4.933533813
    },
    {
        "x": 140.48,
        "y": 4.942395153
    },
    {
        "x": 140.657,
        "y": 4.94931078
    },
    {
        "x": 140.834,
        "y": 4.956231782
    },
    {
        "x": 141.037,
        "y": 4.964176054
    },
    {
        "x": 141.214,
        "y": 4.971108619
    },
    {
        "x": 141.441,
        "y": 4.980007435
    },
    {
        "x": 141.618,
        "y": 4.986952329
    },
    {
        "x": 141.82,
        "y": 4.994884755
    },
    {
        "x": 141.997,
        "y": 5.001841249
    },
    {
        "x": 142.2,
        "y": 5.009826285
    },
    {
        "x": 142.427,
        "y": 5.018763834
    },
    {
        "x": 142.604,
        "y": 5.025738975
    },
    {
        "x": 142.832,
        "y": 5.03473194
    },
    {
        "x": 142.984,
        "y": 5.040732284
    },
    {
        "x": 143.211,
        "y": 5.049700833
    },
    {
        "x": 143.388,
        "y": 5.056700182
    },
    {
        "x": 143.565,
        "y": 5.063705015
    },
    {
        "x": 143.767,
        "y": 5.071705942
    },
    {
        "x": 143.97,
        "y": 5.079753693
    },
    {
        "x": 144.147,
        "y": 5.086776609
    },
    {
        "x": 144.324,
        "y": 5.09380504
    },
    {
        "x": 144.526,
        "y": 5.101832935
    },
    {
        "x": 144.753,
        "y": 5.110862975
    },
    {
        "x": 144.981,
        "y": 5.119941965
    },
    {
        "x": 145.107,
        "y": 5.12496325
    },
    {
        "x": 145.31,
        "y": 5.133059017
    },
    {
        "x": 145.487,
        "y": 5.140123857
    },
    {
        "x": 145.664,
        "y": 5.147194264
    },
    {
        "x": 145.891,
        "y": 5.156270122
    },
    {
        "x": 146.068,
        "y": 5.163353262
    },
    {
        "x": 146.271,
        "y": 5.171483748
    },
    {
        "x": 146.473,
        "y": 5.17958149
    },
    {
        "x": 146.675,
        "y": 5.187686535
    },
    {
        "x": 146.852,
        "y": 5.194794489
    },
    {
        "x": 147.054,
        "y": 5.202913261
    },
    {
        "x": 147.282,
        "y": 5.21208583
    },
    {
        "x": 147.459,
        "y": 5.219213089
    },
    {
        "x": 147.636,
        "y": 5.226345993
    },
    {
        "x": 147.813,
        "y": 5.233484548
    },
    {
        "x": 148.04,
        "y": 5.242647926
    },
    {
        "x": 148.243,
        "y": 5.250850383
    },
    {
        "x": 148.394,
        "y": 5.256956559
    },
    {
        "x": 148.597,
        "y": 5.265172039
    },
    {
        "x": 148.799,
        "y": 5.273354475
    },
    {
        "x": 149.001,
        "y": 5.281544331
    },
    {
        "x": 149.204,
        "y": 5.289782214
    },
    {
        "x": 149.381,
        "y": 5.296971129
    },
    {
        "x": 149.583,
        "y": 5.305182416
    },
    {
        "x": 149.785,
        "y": 5.31340116
    },
    {
        "x": 149.962,
        "y": 5.320608871
    },
    {
        "x": 150.164,
        "y": 5.328841634
    },
    {
        "x": 150.367,
        "y": 5.337122696
    },
    {
        "x": 150.594,
        "y": 5.346391767
    },
    {
        "x": 150.796,
        "y": 5.354647988
    },
    {
        "x": 150.973,
        "y": 5.361888578
    },
    {
        "x": 151.125,
        "y": 5.368111098
    },
    {
        "x": 151.277,
        "y": 5.374337884
    },
    {
        "x": 151.504,
        "y": 5.383645048
    },
    {
        "x": 151.681,
        "y": 5.390908794
    },
    {
        "x": 151.909,
        "y": 5.40027404
    },
    {
        "x": 152.111,
        "y": 5.408579378
    },
    {
        "x": 152.288,
        "y": 5.415863061
    },
    {
        "x": 152.516,
        "y": 5.425254022
    },
    {
        "x": 152.693,
        "y": 5.43255105
    },
    {
        "x": 152.92,
        "y": 5.44191794
    },
    {
        "x": 153.072,
        "y": 5.448195422
    },
    {
        "x": 153.249,
        "y": 5.455510828
    },
    {
        "x": 153.451,
        "y": 5.46386665
    },
    {
        "x": 153.654,
        "y": 5.472271541
    },
    {
        "x": 153.856,
        "y": 5.480642705
    },
    {
        "x": 154.008,
        "y": 5.486946854
    },
    {
        "x": 154.235,
        "y": 5.496369695
    },
    {
        "x": 154.463,
        "y": 5.505843816
    },
    {
        "x": 154.64,
        "y": 5.513205485
    },
    {
        "x": 154.867,
        "y": 5.522655382
    },
    {
        "x": 155.07,
        "y": 5.531114423
    },
    {
        "x": 155.272,
        "y": 5.539539539
    },
    {
        "x": 155.449,
        "y": 5.546928301
    },
    {
        "x": 155.626,
        "y": 5.554323009
    },
    {
        "x": 155.828,
        "y": 5.56276944
    },
    {
        "x": 156.005,
        "y": 5.5701769
    },
    {
        "x": 156.233,
        "y": 5.579727507
    },
    {
        "x": 156.41,
        "y": 5.587148629
    },
    {
        "x": 156.662,
        "y": 5.597724614
    },
    {
        "x": 156.89,
        "y": 5.607303825
    },
    {
        "x": 157.067,
        "y": 5.614747179
    },
    {
        "x": 157.269,
        "y": 5.623249192
    },
    {
        "x": 157.446,
        "y": 5.630705412
    },
    {
        "x": 157.649,
        "y": 5.639264307
    },
    {
        "x": 157.826,
        "y": 5.646733456
    },
    {
        "x": 158.003,
        "y": 5.654208638
    },
    {
        "x": 158.18,
        "y": 5.661689859
    },
    {
        "x": 158.357,
        "y": 5.669177126
    },
    {
        "x": 158.584,
        "y": 5.678788297
    },
    {
        "x": 158.786,
        "y": 5.68734935
    },
    {
        "x": 158.989,
        "y": 5.695960746
    },
    {
        "x": 159.166,
        "y": 5.703475724
    },
    {
        "x": 159.368,
        "y": 5.712059571
    },
    {
        "x": 159.595,
        "y": 5.721715237
    },
    {
        "x": 159.772,
        "y": 5.729251061
    },
    {
        "x": 160,
        "y": 5.738967219
    },
    {
        "x": 160.126,
        "y": 5.744341024
    },
    {
        "x": 160.354,
        "y": 5.754072931
    },
    {
        "x": 160.506,
        "y": 5.760566513
    },
    {
        "x": 160.708,
        "y": 5.769203137
    },
    {
        "x": 160.91,
        "y": 5.777847753
    },
    {
        "x": 161.087,
        "y": 5.785429067
    },
    {
        "x": 161.34,
        "y": 5.796276314
    },
    {
        "x": 161.492,
        "y": 5.802799287
    },
    {
        "x": 161.719,
        "y": 5.812549297
    },
    {
        "x": 161.896,
        "y": 5.820158771
    },
    {
        "x": 162.099,
        "y": 5.828893627
    },
    {
        "x": 162.276,
        "y": 5.836516373
    },
    {
        "x": 162.503,
        "y": 5.8463015
    },
    {
        "x": 162.655,
        "y": 5.852859355
    },
    {
        "x": 162.882,
        "y": 5.862661515
    },
    {
        "x": 163.11,
        "y": 5.872517144
    },
    {
        "x": 163.312,
        "y": 5.881257509
    },
    {
        "x": 163.515,
        "y": 5.890049316
    },
    {
        "x": 163.717,
        "y": 5.898805954
    },
    {
        "x": 163.869,
        "y": 5.905400469
    },
    {
        "x": 164.121,
        "y": 5.916343633
    },
    {
        "x": 164.273,
        "y": 5.922950403
    },
    {
        "x": 164.45,
        "y": 5.930649633
    },
    {
        "x": 164.627,
        "y": 5.938355131
    },
    {
        "x": 164.829,
        "y": 5.947156643
    },
    {
        "x": 165.082,
        "y": 5.958191861
    },
    {
        "x": 165.259,
        "y": 5.965919791
    },
    {
        "x": 165.461,
        "y": 5.974746931
    },
    {
        "x": 165.664,
        "y": 5.983626041
    },
    {
        "x": 165.866,
        "y": 5.992469651
    },
    {
        "x": 166.068,
        "y": 6.001321489
    },
    {
        "x": 166.271,
        "y": 6.010225448
    },
    {
        "x": 166.498,
        "y": 6.020191955
    },
    {
        "x": 166.675,
        "y": 6.027970431
    },
    {
        "x": 166.928,
        "y": 6.039099836
    },
    {
        "x": 167.054,
        "y": 6.044647386
    },
    {
        "x": 167.282,
        "y": 6.054694002
    },
    {
        "x": 167.509,
        "y": 6.064707049
    },
    {
        "x": 167.737,
        "y": 6.074774761
    },
    {
        "x": 167.914,
        "y": 6.082597785
    },
    {
        "x": 168.116,
        "y": 6.091533563
    },
    {
        "x": 168.293,
        "y": 6.099370275
    },
    {
        "x": 168.496,
        "y": 6.108366023
    },
    {
        "x": 168.698,
        "y": 6.117325824
    },
    {
        "x": 168.9,
        "y": 6.126293982
    },
    {
        "x": 169.077,
        "y": 6.134159094
    },
    {
        "x": 169.305,
        "y": 6.1442999
    },
    {
        "x": 169.507,
        "y": 6.15329322
    },
    {
        "x": 169.709,
        "y": 6.162294932
    },
    {
        "x": 169.911,
        "y": 6.171305044
    },
    {
        "x": 170.139,
        "y": 6.181484978
    },
    {
        "x": 170.341,
        "y": 6.190513003
    },
    {
        "x": 170.544,
        "y": 6.199594213
    },
    {
        "x": 170.721,
        "y": 6.207519266
    },
    {
        "x": 170.948,
        "y": 6.217692526
    },
    {
        "x": 171.15,
        "y": 6.226754362
    },
    {
        "x": 171.327,
        "y": 6.234701641
    },
    {
        "x": 171.504,
        "y": 6.242655425
    },
    {
        "x": 171.732,
        "y": 6.252910574
    },
    {
        "x": 171.96,
        "y": 6.263176539
    },
    {
        "x": 172.187,
        "y": 6.273408235
    },
    {
        "x": 172.364,
        "y": 6.281393711
    },
    {
        "x": 172.566,
        "y": 6.29051507
    },
    {
        "x": 172.769,
        "y": 6.299690174
    },
    {
        "x": 172.946,
        "y": 6.307697176
    },
    {
        "x": 173.097,
        "y": 6.314533194
    },
    {
        "x": 173.274,
        "y": 6.322552357
    },
    {
        "x": 173.477,
        "y": 6.331757565
    },
    {
        "x": 173.704,
        "y": 6.342061321
    },
    {
        "x": 173.881,
        "y": 6.350103042
    },
    {
        "x": 174.083,
        "y": 6.359288653
    },
    {
        "x": 174.286,
        "y": 6.368528394
    },
    {
        "x": 174.513,
        "y": 6.378870807
    },
    {
        "x": 174.741,
        "y": 6.389269729
    },
    {
        "x": 174.943,
        "y": 6.398491985
    },
    {
        "x": 175.145,
        "y": 6.407722872
    },
    {
        "x": 175.322,
        "y": 6.415818424
    },
    {
        "x": 175.55,
        "y": 6.426256377
    },
    {
        "x": 175.727,
        "y": 6.434367129
    },
    {
        "x": 175.929,
        "y": 6.443631596
    },
    {
        "x": 176.157,
        "y": 6.454098938
    },
    {
        "x": 176.359,
        "y": 6.463381879
    },
    {
        "x": 176.612,
        "y": 6.475020793
    },
    {
        "x": 176.764,
        "y": 6.482019906
    },
    {
        "x": 176.991,
        "y": 6.492481712
    },
    {
        "x": 177.168,
        "y": 6.500646794
    },
    {
        "x": 177.421,
        "y": 6.512329423
    },
    {
        "x": 177.573,
        "y": 6.519354825
    },
    {
        "x": 177.775,
        "y": 6.528698877
    },
    {
        "x": 177.977,
        "y": 6.538051683
    },
    {
        "x": 178.154,
        "y": 6.546254165
    },
    {
        "x": 178.382,
        "y": 6.556829996
    },
    {
        "x": 178.559,
        "y": 6.565047892
    },
    {
        "x": 178.761,
        "y": 6.574434751
    },
    {
        "x": 178.989,
        "y": 6.585040385
    },
    {
        "x": 179.191,
        "y": 6.594445977
    },
    {
        "x": 179.368,
        "y": 6.602694754
    },
    {
        "x": 179.595,
        "y": 6.613283608
    },
    {
        "x": 179.823,
        "y": 6.623930334
    },
    {
        "x": 180,
        "y": 6.632203321
    },
    {
        "x": 180.202,
        "y": 6.641653109
    },
    {
        "x": 180.379,
        "y": 6.649940649
    },
    {
        "x": 180.632,
        "y": 6.661798498
    },
    {
        "x": 180.834,
        "y": 6.671276024
    },
    {
        "x": 181.037,
        "y": 6.680809418
    },
    {
        "x": 181.239,
        "y": 6.690304764
    },
    {
        "x": 181.441,
        "y": 6.69980901
    },
    {
        "x": 181.669,
        "y": 6.710547278
    },
    {
        "x": 181.871,
        "y": 6.720070498
    },
    {
        "x": 182.099,
        "y": 6.730830206
    },
    {
        "x": 182.276,
        "y": 6.739190986
    },
    {
        "x": 182.503,
        "y": 6.74992362
    },
    {
        "x": 182.705,
        "y": 6.759483754
    },
    {
        "x": 182.933,
        "y": 6.770285168
    },
    {
        "x": 183.135,
        "y": 6.77986439
    },
    {
        "x": 183.338,
        "y": 6.789500082
    },
    {
        "x": 183.54,
        "y": 6.799097318
    },
    {
        "x": 183.742,
        "y": 6.808703552
    },
    {
        "x": 183.944,
        "y": 6.818318792
    },
    {
        "x": 184.172,
        "y": 6.829182467
    },
    {
        "x": 184.399,
        "y": 6.840009916
    },
    {
        "x": 184.602,
        "y": 6.849702274
    },
    {
        "x": 184.804,
        "y": 6.859355951
    },
    {
        "x": 185.006,
        "y": 6.869018679
    },
    {
        "x": 185.209,
        "y": 6.878738368
    },
    {
        "x": 185.411,
        "y": 6.888419266
    },
    {
        "x": 185.638,
        "y": 6.899309127
    },
    {
        "x": 185.841,
        "y": 6.909057354
    },
    {
        "x": 186.018,
        "y": 6.917564534
    },
    {
        "x": 186.22,
        "y": 6.927281832
    },
    {
        "x": 186.422,
        "y": 6.937008239
    },
    {
        "x": 186.599,
        "y": 6.945538378
    },
    {
        "x": 186.827,
        "y": 6.956536679
    },
    {
        "x": 187.029,
        "y": 6.96629051
    },
    {
        "x": 187.231,
        "y": 6.976053485
    },
    {
        "x": 187.408,
        "y": 6.984615693
    },
    {
        "x": 187.661,
        "y": 6.99686654
    },
    {
        "x": 187.838,
        "y": 7.005445842
    },
    {
        "x": 188.04,
        "y": 7.015245518
    },
    {
        "x": 188.243,
        "y": 7.025102961
    },
    {
        "x": 188.445,
        "y": 7.034921061
    },
    {
        "x": 188.698,
        "y": 7.047230969
    },
    {
        "x": 188.9,
        "y": 7.057069808
    },
    {
        "x": 189.077,
        "y": 7.065698548
    },
    {
        "x": 189.279,
        "y": 7.075554693
    },
    {
        "x": 189.532,
        "y": 7.087912301
    },
    {
        "x": 189.76,
        "y": 7.099061226
    },
    {
        "x": 189.987,
        "y": 7.110172967
    },
    {
        "x": 190.164,
        "y": 7.118845306
    },
    {
        "x": 190.392,
        "y": 7.130026948
    },
    {
        "x": 190.594,
        "y": 7.139943369
    },
    {
        "x": 190.796,
        "y": 7.14986908
    },
    {
        "x": 190.898,
        "y": 7.154884605
    },
    {
        "x": 191.125,
        "y": 7.166055122
    },
    {
        "x": 191.302,
        "y": 7.174773332
    },
    {
        "x": 191.53,
        "y": 7.186014113
    },
    {
        "x": 191.681,
        "y": 7.193465207
    },
    {
        "x": 191.96,
        "y": 7.207246185
    },
    {
        "x": 192.162,
        "y": 7.217234937
    },
    {
        "x": 192.389,
        "y": 7.228471084
    },
    {
        "x": 192.566,
        "y": 7.237240512
    },
    {
        "x": 192.769,
        "y": 7.247306963
    },
    {
        "x": 192.996,
        "y": 7.258574755
    },
    {
        "x": 193.224,
        "y": 7.26990412
    },
    {
        "x": 193.401,
        "y": 7.278707539
    },
    {
        "x": 193.603,
        "y": 7.288763201
    },
    {
        "x": 193.78,
        "y": 7.297582089
    },
    {
        "x": 194.083,
        "y": 7.31269562
    },
    {
        "x": 194.26,
        "y": 7.321534134
    },
    {
        "x": 194.437,
        "y": 7.330379896
    },
    {
        "x": 194.614,
        "y": 7.339232912
    },
    {
        "x": 194.893,
        "y": 7.353202405
    },
    {
        "x": 195.07,
        "y": 7.362074133
    },
    {
        "x": 195.297,
        "y": 7.373462643
    },
    {
        "x": 195.424,
        "y": 7.379839411
    },
    {
        "x": 195.676,
        "y": 7.39250363
    },
    {
        "x": 195.904,
        "y": 7.403974469
    },
    {
        "x": 196.106,
        "y": 7.414147347
    },
    {
        "x": 196.359,
        "y": 7.426902047
    },
    {
        "x": 196.561,
        "y": 7.437096368
    },
    {
        "x": 196.839,
        "y": 7.451141764
    },
    {
        "x": 197.042,
        "y": 7.461409353
    },
    {
        "x": 197.269,
        "y": 7.472902269
    },
    {
        "x": 197.446,
        "y": 7.481872083
    },
    {
        "x": 197.724,
        "y": 7.495975099
    },
    {
        "x": 197.876,
        "y": 7.503693771
    },
    {
        "x": 198.053,
        "y": 7.512688802
    },
    {
        "x": 198.255,
        "y": 7.522963316
    },
    {
        "x": 198.458,
        "y": 7.533298367
    },
    {
        "x": 198.711,
        "y": 7.546192582
    },
    {
        "x": 198.913,
        "y": 7.556498401
    },
    {
        "x": 199.09,
        "y": 7.56553666
    },
    {
        "x": 199.292,
        "y": 7.575860548
    },
    {
        "x": 199.494,
        "y": 7.586194078
    },
    {
        "x": 199.722,
        "y": 7.59786926
    },
    {
        "x": 199.899,
        "y": 7.606941373
    },
    {
        "x": 200.101,
        "y": 7.617303927
    },
    {
        "x": 200.278,
        "y": 7.626391938
    },
    {
        "x": 200.506,
        "y": 7.638109485
    },
    {
        "x": 200.708,
        "y": 7.648501135
    },
    {
        "x": 200.936,
        "y": 7.660241975
    },
    {
        "x": 201.113,
        "y": ""
    },
    {
        "x": 201.29,
        "y": ""
    },
    {
        "x": 201.492,
        "y": ""
    },
    {
        "x": 201.694,
        "y": ""
    },
    {
        "x": 201.896,
        "y": ""
    },
    {
        "x": 202.099,
        "y": ""
    },
    {
        "x": 202.301,
        "y": ""
    },
    {
        "x": 202.528,
        "y": ""
    },
    {
        "x": 202.705,
        "y": ""
    },
    {
        "x": 202.933,
        "y": ""
    },
    {
        "x": 203.11,
        "y": ""
    },
    {
        "x": 203.287,
        "y": ""
    },
    {
        "x": 203.515,
        "y": ""
    },
    {
        "x": 203.717,
        "y": ""
    },
    {
        "x": 203.894,
        "y": ""
    },
    {
        "x": 204.096,
        "y": ""
    },
    {
        "x": 204.298,
        "y": ""
    },
    {
        "x": 204.501,
        "y": ""
    },
    {
        "x": 204.703,
        "y": ""
    },
    {
        "x": 204.905,
        "y": ""
    },
    {
        "x": 205.082,
        "y": ""
    },
    {
        "x": 205.284,
        "y": ""
    },
    {
        "x": 205.487,
        "y": ""
    },
    {
        "x": 205.664,
        "y": ""
    },
    {
        "x": 205.866,
        "y": ""
    },
    {
        "x": 206.043,
        "y": ""
    },
    {
        "x": 206.271,
        "y": ""
    },
    {
        "x": 206.473,
        "y": ""
    },
    {
        "x": 206.675,
        "y": ""
    },
    {
        "x": 206.877,
        "y": ""
    },
    {
        "x": 207.105,
        "y": ""
    },
    {
        "x": 207.358,
        "y": ""
    },
    {
        "x": 207.535,
        "y": ""
    },
    {
        "x": 207.737,
        "y": ""
    },
    {
        "x": 207.939,
        "y": ""
    },
    {
        "x": 208.192,
        "y": ""
    },
    {
        "x": 208.319,
        "y": ""
    },
    {
        "x": 208.47,
        "y": ""
    },
    {
        "x": 208.622,
        "y": ""
    },
    {
        "x": 208.85,
        "y": ""
    },
    {
        "x": 209.027,
        "y": ""
    },
    {
        "x": 209.254,
        "y": ""
    },
    {
        "x": 209.406,
        "y": ""
    },
    {
        "x": 209.633,
        "y": ""
    },
    {
        "x": 209.861,
        "y": ""
    },
    {
        "x": 210.038,
        "y": ""
    },
    {
        "x": 210.24,
        "y": ""
    },
    {
        "x": 210.468,
        "y": ""
    },
    {
        "x": 210.695,
        "y": ""
    },
    {
        "x": 210.872,
        "y": ""
    },
    {
        "x": 211.049,
        "y": ""
    },
    {
        "x": 211.252,
        "y": ""
    },
    {
        "x": 211.479,
        "y": ""
    },
    {
        "x": 211.732,
        "y": ""
    },
    {
        "x": 211.909,
        "y": ""
    },
    {
        "x": 212.137,
        "y": ""
    },
    {
        "x": 212.314,
        "y": ""
    },
    {
        "x": 212.566,
        "y": ""
    },
    {
        "x": 212.693,
        "y": ""
    },
    {
        "x": 212.895,
        "y": ""
    },
    {
        "x": 213.123,
        "y": ""
    },
    {
        "x": 213.3,
        "y": ""
    },
    {
        "x": 213.527,
        "y": ""
    },
    {
        "x": 213.654,
        "y": ""
    },
    {
        "x": 213.881,
        "y": ""
    },
    {
        "x": 214.058,
        "y": ""
    },
    {
        "x": 214.336,
        "y": ""
    },
    {
        "x": 214.488,
        "y": ""
    },
    {
        "x": 214.69,
        "y": ""
    },
    {
        "x": 214.893,
        "y": ""
    },
    {
        "x": 215.12,
        "y": ""
    },
    {
        "x": 215.297,
        "y": ""
    },
    {
        "x": 215.449,
        "y": ""
    },
    {
        "x": 215.651,
        "y": ""
    },
    {
        "x": 215.879,
        "y": ""
    },
    {
        "x": 216.106,
        "y": ""
    },
    {
        "x": 216.283,
        "y": ""
    },
    {
        "x": 216.485,
        "y": ""
    },
    {
        "x": 216.713,
        "y": ""
    },
    {
        "x": 216.89,
        "y": ""
    },
    {
        "x": 217.092,
        "y": ""
    },
    {
        "x": 217.295,
        "y": ""
    },
    {
        "x": 217.522,
        "y": ""
    },
    {
        "x": 217.699,
        "y": ""
    },
    {
        "x": 217.901,
        "y": ""
    },
    {
        "x": 218.104,
        "y": ""
    },
    {
        "x": 218.357,
        "y": ""
    },
    {
        "x": 218.534,
        "y": ""
    },
    {
        "x": 218.761,
        "y": ""
    },
    {
        "x": 218.913,
        "y": ""
    },
    {
        "x": 219.115,
        "y": ""
    },
    {
        "x": 219.317,
        "y": ""
    },
    {
        "x": 219.545,
        "y": ""
    },
    {
        "x": 219.722,
        "y": ""
    },
    {
        "x": 219.924,
        "y": ""
    },
    {
        "x": 220.126,
        "y": ""
    },
    {
        "x": 220.354,
        "y": ""
    },
    {
        "x": 220.531,
        "y": ""
    },
    {
        "x": 220.759,
        "y": ""
    },
    {
        "x": 220.936,
        "y": ""
    },
    {
        "x": 221.188,
        "y": ""
    },
    {
        "x": 221.34,
        "y": ""
    },
    {
        "x": 221.542,
        "y": ""
    },
    {
        "x": 221.719,
        "y": ""
    },
    {
        "x": 221.947,
        "y": ""
    },
    {
        "x": 222.149,
        "y": ""
    },
    {
        "x": 222.326,
        "y": ""
    },
    {
        "x": 222.554,
        "y": ""
    },
    {
        "x": 222.756,
        "y": ""
    },
    {
        "x": 222.958,
        "y": ""
    },
    {
        "x": 223.161,
        "y": ""
    },
    {
        "x": 223.388,
        "y": ""
    },
    {
        "x": 223.565,
        "y": ""
    },
    {
        "x": 223.793,
        "y": ""
    },
    {
        "x": 223.995,
        "y": ""
    },
    {
        "x": 224.223,
        "y": ""
    },
    {
        "x": 224.425,
        "y": ""
    },
    {
        "x": 224.652,
        "y": ""
    },
    {
        "x": 224.88,
        "y": ""
    },
    {
        "x": 225.107,
        "y": ""
    },
    {
        "x": 225.284,
        "y": ""
    },
    {
        "x": 225.563,
        "y": ""
    },
    {
        "x": 225.765,
        "y": ""
    },
    {
        "x": 225.992,
        "y": ""
    },
    {
        "x": 226.119,
        "y": ""
    },
    {
        "x": 226.296,
        "y": ""
    },
    {
        "x": 226.473,
        "y": ""
    },
    {
        "x": 226.675,
        "y": ""
    },
    {
        "x": 226.903,
        "y": ""
    },
    {
        "x": 227.13,
        "y": ""
    },
    {
        "x": 227.332,
        "y": ""
    },
    {
        "x": 227.56,
        "y": ""
    },
    {
        "x": 227.712,
        "y": ""
    },
    {
        "x": 227.965,
        "y": ""
    },
    {
        "x": 228.167,
        "y": ""
    },
    {
        "x": 228.394,
        "y": ""
    },
    {
        "x": 228.571,
        "y": ""
    },
    {
        "x": 228.774,
        "y": ""
    },
    {
        "x": 228.951,
        "y": ""
    },
    {
        "x": 229.178,
        "y": ""
    },
    {
        "x": 229.381,
        "y": ""
    },
    {
        "x": 229.608,
        "y": ""
    },
    {
        "x": 229.861,
        "y": ""
    },
    {
        "x": 230.038,
        "y": ""
    },
    {
        "x": 230.291,
        "y": ""
    },
    {
        "x": 230.468,
        "y": ""
    },
    {
        "x": 230.721,
        "y": ""
    },
    {
        "x": 230.898,
        "y": ""
    },
    {
        "x": 231.125,
        "y": ""
    },
    {
        "x": 231.327,
        "y": ""
    },
    {
        "x": 231.504,
        "y": ""
    },
    {
        "x": 231.732,
        "y": ""
    },
    {
        "x": 231.934,
        "y": ""
    },
    {
        "x": 232.162,
        "y": ""
    },
    {
        "x": 232.364,
        "y": ""
    },
    {
        "x": 232.541,
        "y": ""
    },
    {
        "x": 232.743,
        "y": ""
    },
    {
        "x": 232.971,
        "y": ""
    },
    {
        "x": 233.148,
        "y": ""
    },
    {
        "x": 233.375,
        "y": ""
    },
    {
        "x": 233.578,
        "y": ""
    },
    {
        "x": 233.729,
        "y": ""
    },
    {
        "x": 233.982,
        "y": ""
    },
    {
        "x": 234.21,
        "y": ""
    },
    {
        "x": 234.437,
        "y": ""
    },
    {
        "x": 234.64,
        "y": ""
    },
    {
        "x": 234.842,
        "y": ""
    },
    {
        "x": 235.019,
        "y": ""
    },
    {
        "x": 235.221,
        "y": ""
    },
    {
        "x": 235.449,
        "y": ""
    },
    {
        "x": 235.601,
        "y": ""
    },
    {
        "x": 235.803,
        "y": ""
    },
    {
        "x": 236.03,
        "y": ""
    },
    {
        "x": 236.283,
        "y": ""
    },
    {
        "x": 236.46,
        "y": ""
    },
    {
        "x": 236.662,
        "y": ""
    },
    {
        "x": 236.865,
        "y": ""
    },
    {
        "x": 237.092,
        "y": ""
    },
    {
        "x": 237.295,
        "y": ""
    },
    {
        "x": 237.573,
        "y": ""
    },
    {
        "x": 237.8,
        "y": ""
    },
    {
        "x": 238.053,
        "y": ""
    },
    {
        "x": 238.281,
        "y": ""
    },
    {
        "x": 238.483,
        "y": ""
    },
    {
        "x": 238.711,
        "y": ""
    },
    {
        "x": 238.963,
        "y": ""
    },
    {
        "x": 239.166,
        "y": ""
    },
    {
        "x": 239.368,
        "y": ""
    },
    {
        "x": 239.57,
        "y": ""
    },
    {
        "x": 239.747,
        "y": ""
    },
    {
        "x": 239.975,
        "y": ""
    },
    {
        "x": 240.202,
        "y": ""
    },
    {
        "x": 240.43,
        "y": ""
    },
    {
        "x": 240.632,
        "y": ""
    },
    {
        "x": 240.834,
        "y": ""
    },
    {
        "x": 241.062,
        "y": ""
    },
    {
        "x": 241.29,
        "y": ""
    },
    {
        "x": 241.517,
        "y": ""
    },
    {
        "x": 241.745,
        "y": ""
    },
    {
        "x": 241.896,
        "y": ""
    },
    {
        "x": 242.124,
        "y": ""
    },
    {
        "x": 242.351,
        "y": ""
    },
    {
        "x": 242.63,
        "y": ""
    },
    {
        "x": 242.807,
        "y": ""
    },
    {
        "x": 243.034,
        "y": ""
    },
    {
        "x": 243.236,
        "y": ""
    },
    {
        "x": 243.464,
        "y": ""
    },
    {
        "x": 243.692,
        "y": ""
    },
    {
        "x": 243.894,
        "y": ""
    },
    {
        "x": 244.121,
        "y": ""
    },
    {
        "x": 244.324,
        "y": ""
    },
    {
        "x": 244.475,
        "y": ""
    },
    {
        "x": 244.652,
        "y": ""
    },
    {
        "x": 244.855,
        "y": ""
    },
    {
        "x": 245.082,
        "y": ""
    },
    {
        "x": 245.31,
        "y": ""
    },
    {
        "x": 245.563,
        "y": ""
    },
    {
        "x": 245.79,
        "y": ""
    },
    {
        "x": 245.967,
        "y": ""
    },
    {
        "x": 246.22,
        "y": ""
    },
    {
        "x": 246.448,
        "y": ""
    },
    {
        "x": 246.7,
        "y": ""
    },
    {
        "x": 246.852,
        "y": ""
    },
    {
        "x": 247.105,
        "y": ""
    },
    {
        "x": 247.282,
        "y": ""
    },
    {
        "x": 247.509,
        "y": ""
    },
    {
        "x": 247.788,
        "y": ""
    },
    {
        "x": 247.965,
        "y": ""
    },
    {
        "x": 248.192,
        "y": ""
    },
    {
        "x": 248.369,
        "y": ""
    },
    {
        "x": 248.622,
        "y": ""
    },
    {
        "x": 248.799,
        "y": ""
    },
    {
        "x": 249.052,
        "y": ""
    },
    {
        "x": 249.229,
        "y": ""
    },
    {
        "x": 249.431,
        "y": ""
    },
    {
        "x": 249.684,
        "y": ""
    },
    {
        "x": 249.861,
        "y": ""
    },
    {
        "x": 250.089,
        "y": ""
    },
    {
        "x": 250.291,
        "y": ""
    },
    {
        "x": 250.544,
        "y": ""
    },
    {
        "x": 250.771,
        "y": ""
    },
    {
        "x": 250.973,
        "y": ""
    },
    {
        "x": 251.15,
        "y": ""
    },
    {
        "x": 251.403,
        "y": ""
    },
    {
        "x": 251.606,
        "y": ""
    },
    {
        "x": 251.858,
        "y": ""
    },
    {
        "x": 252.01,
        "y": ""
    },
    {
        "x": 252.238,
        "y": ""
    },
    {
        "x": 252.465,
        "y": ""
    },
    {
        "x": 252.718,
        "y": ""
    },
    {
        "x": 252.895,
        "y": ""
    },
    {
        "x": 253.123,
        "y": ""
    },
    {
        "x": 253.35,
        "y": ""
    },
    {
        "x": 253.578,
        "y": ""
    },
    {
        "x": 253.78,
        "y": ""
    },
    {
        "x": 254.033,
        "y": ""
    },
    {
        "x": 254.21,
        "y": ""
    },
    {
        "x": 254.463,
        "y": ""
    },
    {
        "x": 254.665,
        "y": ""
    },
    {
        "x": 254.918,
        "y": ""
    },
    {
        "x": 255.12,
        "y": ""
    },
    {
        "x": 255.322,
        "y": ""
    },
    {
        "x": 255.575,
        "y": ""
    },
    {
        "x": 255.778,
        "y": ""
    },
    {
        "x": 256.005,
        "y": ""
    },
    {
        "x": 256.207,
        "y": ""
    },
    {
        "x": 256.46,
        "y": ""
    },
    {
        "x": 256.688,
        "y": ""
    },
    {
        "x": 256.941,
        "y": ""
    },
    {
        "x": 257.143,
        "y": ""
    },
    {
        "x": 257.396,
        "y": ""
    },
    {
        "x": 257.547,
        "y": ""
    },
    {
        "x": 257.8,
        "y": ""
    },
    {
        "x": 258.003,
        "y": ""
    },
    {
        "x": 258.255,
        "y": ""
    },
    {
        "x": 258.458,
        "y": ""
    },
    {
        "x": 258.635,
        "y": ""
    },
    {
        "x": 258.887,
        "y": ""
    },
    {
        "x": 259.09,
        "y": ""
    },
    {
        "x": 259.343,
        "y": ""
    },
    {
        "x": 259.494,
        "y": ""
    },
    {
        "x": 259.772,
        "y": ""
    },
    {
        "x": 259.975,
        "y": ""
    },
    {
        "x": 260.228,
        "y": ""
    },
    {
        "x": 260.43,
        "y": ""
    },
    {
        "x": 260.632,
        "y": ""
    },
    {
        "x": 260.885,
        "y": ""
    },
    {
        "x": 261.138,
        "y": ""
    },
    {
        "x": 261.34,
        "y": ""
    },
    {
        "x": 261.542,
        "y": ""
    },
    {
        "x": 261.82,
        "y": ""
    },
    {
        "x": 262.048,
        "y": ""
    },
    {
        "x": 262.301,
        "y": ""
    },
    {
        "x": 262.503,
        "y": ""
    },
    {
        "x": 262.756,
        "y": ""
    },
    {
        "x": 262.958,
        "y": ""
    },
    {
        "x": 263.11,
        "y": ""
    },
    {
        "x": 263.312,
        "y": ""
    },
    {
        "x": 263.515,
        "y": ""
    },
    {
        "x": 263.742,
        "y": ""
    },
    {
        "x": 263.97,
        "y": ""
    },
    {
        "x": 264.223,
        "y": ""
    },
    {
        "x": 264.45,
        "y": ""
    },
    {
        "x": 264.678,
        "y": ""
    },
    {
        "x": 264.88,
        "y": ""
    },
    {
        "x": 265.133,
        "y": ""
    },
    {
        "x": 265.335,
        "y": ""
    },
    {
        "x": 265.537,
        "y": ""
    },
    {
        "x": 265.765,
        "y": ""
    },
    {
        "x": 265.967,
        "y": ""
    },
    {
        "x": 266.195,
        "y": ""
    },
    {
        "x": 266.448,
        "y": ""
    },
    {
        "x": 266.65,
        "y": ""
    },
    {
        "x": 266.903,
        "y": ""
    },
    {
        "x": 267.105,
        "y": ""
    },
    {
        "x": 267.358,
        "y": ""
    },
    {
        "x": 267.56,
        "y": ""
    },
    {
        "x": 267.788,
        "y": ""
    },
    {
        "x": 267.965,
        "y": ""
    },
    {
        "x": 268.192,
        "y": ""
    },
    {
        "x": 268.445,
        "y": ""
    },
    {
        "x": 268.622,
        "y": ""
    },
    {
        "x": 268.9,
        "y": ""
    },
    {
        "x": 269.077,
        "y": ""
    },
    {
        "x": 269.381,
        "y": ""
    },
    {
        "x": 269.558,
        "y": ""
    },
    {
        "x": 269.785,
        "y": ""
    },
    {
        "x": 269.987,
        "y": ""
    },
    {
        "x": 270.265,
        "y": ""
    },
    {
        "x": 270.442,
        "y": ""
    },
    {
        "x": 270.67,
        "y": ""
    },
    {
        "x": 270.898,
        "y": ""
    },
    {
        "x": 271.15,
        "y": ""
    },
    {
        "x": 271.429,
        "y": ""
    },
    {
        "x": 271.631,
        "y": ""
    },
    {
        "x": 271.833,
        "y": ""
    },
    {
        "x": 272.086,
        "y": ""
    },
    {
        "x": 272.314,
        "y": ""
    },
    {
        "x": 272.541,
        "y": ""
    },
    {
        "x": 272.769,
        "y": ""
    },
    {
        "x": 272.996,
        "y": ""
    },
    {
        "x": 273.224,
        "y": ""
    },
    {
        "x": 273.451,
        "y": ""
    },
    {
        "x": 273.679,
        "y": ""
    },
    {
        "x": 273.932,
        "y": ""
    },
    {
        "x": 274.185,
        "y": ""
    },
    {
        "x": 274.362,
        "y": ""
    },
    {
        "x": 274.589,
        "y": ""
    },
    {
        "x": 274.817,
        "y": ""
    },
    {
        "x": 275.07,
        "y": ""
    },
    {
        "x": 275.272,
        "y": ""
    },
    {
        "x": 275.499,
        "y": ""
    },
    {
        "x": 275.702,
        "y": ""
    },
    {
        "x": 275.929,
        "y": ""
    },
    {
        "x": 276.182,
        "y": ""
    },
    {
        "x": 276.384,
        "y": ""
    },
    {
        "x": 276.587,
        "y": ""
    },
    {
        "x": 276.814,
        "y": ""
    },
    {
        "x": 277.016,
        "y": ""
    },
    {
        "x": 277.269,
        "y": ""
    },
    {
        "x": 277.472,
        "y": ""
    },
    {
        "x": 277.699,
        "y": ""
    },
    {
        "x": 277.927,
        "y": ""
    },
    {
        "x": 278.154,
        "y": ""
    },
    {
        "x": 278.382,
        "y": ""
    },
    {
        "x": 278.609,
        "y": ""
    },
    {
        "x": 278.837,
        "y": ""
    },
    {
        "x": 279.064,
        "y": ""
    },
    {
        "x": 279.292,
        "y": ""
    },
    {
        "x": 279.52,
        "y": ""
    },
    {
        "x": 279.722,
        "y": ""
    },
    {
        "x": 279.949,
        "y": ""
    },
    {
        "x": 280.177,
        "y": ""
    },
    {
        "x": 280.379,
        "y": ""
    },
    {
        "x": 280.683,
        "y": ""
    },
    {
        "x": 280.936,
        "y": ""
    },
    {
        "x": 281.087,
        "y": ""
    },
    {
        "x": 281.34,
        "y": ""
    },
    {
        "x": 281.593,
        "y": ""
    },
    {
        "x": 281.871,
        "y": ""
    },
    {
        "x": 282.048,
        "y": ""
    },
    {
        "x": 282.2,
        "y": ""
    },
    {
        "x": 282.377,
        "y": ""
    },
    {
        "x": 282.604,
        "y": ""
    },
    {
        "x": 282.857,
        "y": ""
    },
    {
        "x": 283.034,
        "y": ""
    },
    {
        "x": 283.262,
        "y": ""
    },
    {
        "x": 283.489,
        "y": ""
    },
    {
        "x": 283.742,
        "y": ""
    },
    {
        "x": 283.97,
        "y": ""
    },
    {
        "x": 284.223,
        "y": ""
    },
    {
        "x": 284.45,
        "y": ""
    },
    {
        "x": 284.652,
        "y": ""
    },
    {
        "x": 284.88,
        "y": ""
    },
    {
        "x": 285.107,
        "y": ""
    },
    {
        "x": 285.36,
        "y": ""
    },
    {
        "x": 285.638,
        "y": ""
    },
    {
        "x": 285.866,
        "y": ""
    },
    {
        "x": 286.119,
        "y": ""
    },
    {
        "x": 286.372,
        "y": ""
    },
    {
        "x": 286.65,
        "y": ""
    },
    {
        "x": 286.827,
        "y": ""
    },
    {
        "x": 287.105,
        "y": ""
    },
    {
        "x": 287.282,
        "y": ""
    },
    {
        "x": 287.56,
        "y": ""
    },
    {
        "x": 287.737,
        "y": ""
    },
    {
        "x": 287.939,
        "y": ""
    },
    {
        "x": 288.167,
        "y": ""
    },
    {
        "x": 288.445,
        "y": ""
    },
    {
        "x": 288.673,
        "y": ""
    },
    {
        "x": 288.875,
        "y": ""
    },
    {
        "x": 289.102,
        "y": ""
    },
    {
        "x": 289.355,
        "y": ""
    },
    {
        "x": 289.583,
        "y": ""
    },
    {
        "x": 289.785,
        "y": ""
    },
    {
        "x": 290.038,
        "y": ""
    },
    {
        "x": 290.24,
        "y": ""
    },
    {
        "x": 290.493,
        "y": ""
    },
    {
        "x": 290.695,
        "y": ""
    },
    {
        "x": 290.948,
        "y": ""
    },
    {
        "x": 291.176,
        "y": ""
    },
    {
        "x": 291.403,
        "y": ""
    },
    {
        "x": 291.606,
        "y": ""
    },
    {
        "x": 291.858,
        "y": ""
    },
    {
        "x": 292.111,
        "y": ""
    },
    {
        "x": 292.314,
        "y": ""
    },
    {
        "x": 292.516,
        "y": ""
    },
    {
        "x": 292.743,
        "y": ""
    },
    {
        "x": 293.021,
        "y": ""
    },
    {
        "x": 293.224,
        "y": ""
    },
    {
        "x": 293.426,
        "y": ""
    },
    {
        "x": 293.603,
        "y": ""
    },
    {
        "x": 293.856,
        "y": ""
    },
    {
        "x": 294.109,
        "y": ""
    },
    {
        "x": 294.387,
        "y": ""
    },
    {
        "x": 294.589,
        "y": ""
    },
    {
        "x": 294.817,
        "y": ""
    },
    {
        "x": 295.044,
        "y": ""
    },
    {
        "x": 295.272,
        "y": ""
    },
    {
        "x": 295.525,
        "y": ""
    },
    {
        "x": 295.752,
        "y": ""
    },
    {
        "x": 295.98,
        "y": ""
    },
    {
        "x": 296.182,
        "y": ""
    },
    {
        "x": 296.41,
        "y": ""
    },
    {
        "x": 296.637,
        "y": ""
    },
    {
        "x": 296.865,
        "y": ""
    },
    {
        "x": 297.092,
        "y": ""
    },
    {
        "x": 297.295,
        "y": ""
    },
    {
        "x": 297.547,
        "y": ""
    },
    {
        "x": 297.75,
        "y": ""
    },
    {
        "x": 298.028,
        "y": ""
    },
    {
        "x": 298.23,
        "y": ""
    },
    {
        "x": 298.483,
        "y": ""
    },
    {
        "x": 298.71,
        "y": ""
    },
    {
        "x": 298.963,
        "y": ""
    },
    {
        "x": 299.216,
        "y": ""
    },
    {
        "x": 299.418,
        "y": ""
    },
    {
        "x": 299.671,
        "y": ""
    },
    {
        "x": 299.899,
        "y": ""
    },
    {
        "x": 300.152,
        "y": ""
    },
    {
        "x": 300.405,
        "y": ""
    },
    {
        "x": 300.607,
        "y": ""
    },
    {
        "x": 300.834,
        "y": ""
    },
    {
        "x": 301.087,
        "y": ""
    },
    {
        "x": 301.315,
        "y": ""
    },
    {
        "x": 301.467,
        "y": ""
    },
    {
        "x": 301.669,
        "y": ""
    },
    {
        "x": 301.871,
        "y": ""
    },
    {
        "x": 302.124,
        "y": ""
    },
    {
        "x": 302.377,
        "y": ""
    },
    {
        "x": 302.604,
        "y": ""
    },
    {
        "x": 302.857,
        "y": ""
    },
    {
        "x": 303.059,
        "y": ""
    },
    {
        "x": 303.312,
        "y": ""
    },
    {
        "x": 303.54,
        "y": ""
    },
    {
        "x": 303.843,
        "y": ""
    },
    {
        "x": 304.02,
        "y": ""
    },
    {
        "x": 304.248,
        "y": ""
    },
    {
        "x": 304.45,
        "y": ""
    },
    {
        "x": 304.703,
        "y": ""
    },
    {
        "x": 304.981,
        "y": ""
    },
    {
        "x": 305.209,
        "y": ""
    },
    {
        "x": 305.487,
        "y": ""
    },
    {
        "x": 305.714,
        "y": ""
    },
    {
        "x": 305.967,
        "y": ""
    },
    {
        "x": 306.144,
        "y": ""
    },
    {
        "x": 306.372,
        "y": ""
    },
    {
        "x": 306.599,
        "y": ""
    },
    {
        "x": 306.852,
        "y": ""
    },
    {
        "x": 307.105,
        "y": ""
    },
    {
        "x": 307.282,
        "y": ""
    },
    {
        "x": 307.535,
        "y": ""
    },
    {
        "x": 307.762,
        "y": ""
    },
    {
        "x": 308.04,
        "y": ""
    },
    {
        "x": 308.268,
        "y": ""
    },
    {
        "x": 308.496,
        "y": ""
    },
    {
        "x": 308.774,
        "y": ""
    },
    {
        "x": 308.951,
        "y": ""
    },
    {
        "x": 309.153,
        "y": ""
    },
    {
        "x": 309.355,
        "y": ""
    },
    {
        "x": 309.608,
        "y": ""
    },
    {
        "x": 309.836,
        "y": ""
    },
    {
        "x": 310.088,
        "y": ""
    },
    {
        "x": 310.341,
        "y": ""
    },
    {
        "x": 310.594,
        "y": ""
    },
    {
        "x": 310.796,
        "y": ""
    },
    {
        "x": 311.049,
        "y": ""
    },
    {
        "x": 311.252,
        "y": ""
    },
    {
        "x": 311.504,
        "y": ""
    },
    {
        "x": 311.707,
        "y": ""
    },
    {
        "x": 311.934,
        "y": ""
    },
    {
        "x": 312.162,
        "y": ""
    },
    {
        "x": 312.44,
        "y": ""
    },
    {
        "x": 312.668,
        "y": ""
    },
    {
        "x": 312.895,
        "y": ""
    },
    {
        "x": 313.072,
        "y": ""
    },
    {
        "x": 313.325,
        "y": ""
    },
    {
        "x": 313.578,
        "y": ""
    },
    {
        "x": 313.805,
        "y": ""
    },
    {
        "x": 314.033,
        "y": ""
    },
    {
        "x": 314.286,
        "y": ""
    },
    {
        "x": 314.513,
        "y": ""
    },
    {
        "x": 314.716,
        "y": ""
    },
    {
        "x": 314.994,
        "y": ""
    },
    {
        "x": 315.196,
        "y": ""
    },
    {
        "x": 315.474,
        "y": ""
    },
    {
        "x": 315.676,
        "y": ""
    },
    {
        "x": 315.904,
        "y": ""
    },
    {
        "x": 316.131,
        "y": ""
    },
    {
        "x": 316.359,
        "y": ""
    },
    {
        "x": 316.587,
        "y": ""
    },
    {
        "x": 316.814,
        "y": ""
    },
    {
        "x": 317.092,
        "y": ""
    },
    {
        "x": 317.295,
        "y": ""
    },
    {
        "x": 317.573,
        "y": ""
    },
    {
        "x": 317.75,
        "y": ""
    },
    {
        "x": 318.028,
        "y": ""
    },
    {
        "x": 318.23,
        "y": ""
    },
    {
        "x": 318.483,
        "y": ""
    },
    {
        "x": 318.685,
        "y": ""
    },
    {
        "x": 318.938,
        "y": ""
    },
    {
        "x": 319.166,
        "y": ""
    },
    {
        "x": 319.444,
        "y": ""
    },
    {
        "x": 319.722,
        "y": ""
    },
    {
        "x": 319.924,
        "y": ""
    },
    {
        "x": 320.202,
        "y": ""
    },
    {
        "x": 320.405,
        "y": ""
    },
    {
        "x": 320.683,
        "y": ""
    },
    {
        "x": 320.91,
        "y": ""
    },
    {
        "x": 321.062,
        "y": ""
    },
    {
        "x": 321.29,
        "y": ""
    },
    {
        "x": 321.492,
        "y": ""
    },
    {
        "x": 321.745,
        "y": ""
    },
    {
        "x": 321.997,
        "y": ""
    },
    {
        "x": 322.25,
        "y": ""
    },
    {
        "x": 322.503,
        "y": ""
    },
    {
        "x": 322.705,
        "y": ""
    },
    {
        "x": 323.009,
        "y": ""
    },
    {
        "x": 323.236,
        "y": ""
    },
    {
        "x": 323.489,
        "y": ""
    },
    {
        "x": 323.692,
        "y": ""
    },
    {
        "x": 323.919,
        "y": ""
    },
    {
        "x": 324.147,
        "y": ""
    },
    {
        "x": 324.374,
        "y": ""
    },
    {
        "x": 324.652,
        "y": ""
    },
    {
        "x": 324.855,
        "y": ""
    },
    {
        "x": 325.133,
        "y": ""
    },
    {
        "x": 325.36,
        "y": ""
    },
    {
        "x": 325.638,
        "y": ""
    },
    {
        "x": 325.815,
        "y": ""
    },
    {
        "x": 326.094,
        "y": ""
    },
    {
        "x": 326.271,
        "y": ""
    },
    {
        "x": 326.549,
        "y": ""
    },
    {
        "x": 326.751,
        "y": ""
    },
    {
        "x": 327.004,
        "y": ""
    },
    {
        "x": 327.206,
        "y": ""
    },
    {
        "x": 327.434,
        "y": ""
    },
    {
        "x": 327.661,
        "y": ""
    },
    {
        "x": 327.889,
        "y": ""
    },
    {
        "x": 328.142,
        "y": ""
    },
    {
        "x": 328.394,
        "y": ""
    },
    {
        "x": 328.622,
        "y": ""
    },
    {
        "x": 328.824,
        "y": ""
    },
    {
        "x": 329.052,
        "y": ""
    },
    {
        "x": 329.279,
        "y": ""
    },
    {
        "x": 329.532,
        "y": ""
    },
    {
        "x": 329.76,
        "y": ""
    },
    {
        "x": 329.987,
        "y": ""
    },
    {
        "x": 330.24,
        "y": ""
    },
    {
        "x": 330.468,
        "y": ""
    },
    {
        "x": 330.746,
        "y": ""
    },
    {
        "x": 330.973,
        "y": ""
    },
    {
        "x": 331.201,
        "y": ""
    },
    {
        "x": 331.403,
        "y": ""
    },
    {
        "x": 331.656,
        "y": ""
    },
    {
        "x": 331.909,
        "y": ""
    },
    {
        "x": 332.111,
        "y": ""
    },
    {
        "x": 332.364,
        "y": ""
    },
    {
        "x": 332.566,
        "y": ""
    },
    {
        "x": 332.87,
        "y": ""
    },
    {
        "x": 333.072,
        "y": ""
    },
    {
        "x": 333.325,
        "y": ""
    },
    {
        "x": 333.552,
        "y": ""
    },
    {
        "x": 333.831,
        "y": ""
    },
    {
        "x": 334.083,
        "y": ""
    },
    {
        "x": 334.311,
        "y": ""
    },
    {
        "x": 334.539,
        "y": ""
    },
    {
        "x": 334.791,
        "y": ""
    },
    {
        "x": 334.968,
        "y": ""
    },
    {
        "x": 335.247,
        "y": ""
    },
    {
        "x": 335.424,
        "y": ""
    },
    {
        "x": 335.676,
        "y": ""
    },
    {
        "x": 335.853,
        "y": ""
    },
    {
        "x": 336.106,
        "y": ""
    },
    {
        "x": 336.334,
        "y": ""
    },
    {
        "x": 336.612,
        "y": ""
    },
    {
        "x": 336.839,
        "y": ""
    },
    {
        "x": 337.067,
        "y": ""
    },
    {
        "x": 337.295,
        "y": ""
    },
    {
        "x": 337.573,
        "y": ""
    },
    {
        "x": 337.8,
        "y": ""
    },
    {
        "x": 338.078,
        "y": ""
    },
    {
        "x": 338.331,
        "y": ""
    },
    {
        "x": 338.584,
        "y": ""
    },
    {
        "x": 338.837,
        "y": ""
    },
    {
        "x": 339.064,
        "y": ""
    },
    {
        "x": 339.343,
        "y": ""
    },
    {
        "x": 339.646,
        "y": ""
    },
    {
        "x": 339.874,
        "y": ""
    },
    {
        "x": 340.076,
        "y": ""
    },
    {
        "x": 340.329,
        "y": ""
    },
    {
        "x": 340.531,
        "y": ""
    },
    {
        "x": 340.759,
        "y": ""
    },
    {
        "x": 340.936,
        "y": ""
    },
    {
        "x": 341.188,
        "y": ""
    },
    {
        "x": 341.416,
        "y": ""
    },
    {
        "x": 341.643,
        "y": ""
    },
    {
        "x": 341.871,
        "y": ""
    },
    {
        "x": 342.149,
        "y": ""
    },
    {
        "x": 342.402,
        "y": ""
    },
    {
        "x": 342.655,
        "y": ""
    },
    {
        "x": 342.882,
        "y": ""
    },
    {
        "x": 343.11,
        "y": ""
    },
    {
        "x": 343.338,
        "y": ""
    },
    {
        "x": 343.565,
        "y": ""
    },
    {
        "x": 343.818,
        "y": ""
    },
    {
        "x": 344.046,
        "y": ""
    },
    {
        "x": 344.324,
        "y": ""
    },
    {
        "x": 344.526,
        "y": ""
    },
    {
        "x": 344.804,
        "y": ""
    },
    {
        "x": 345.006,
        "y": ""
    },
    {
        "x": 345.259,
        "y": ""
    },
    {
        "x": 345.461,
        "y": ""
    },
    {
        "x": 345.714,
        "y": ""
    },
    {
        "x": 345.917,
        "y": ""
    },
    {
        "x": 346.169,
        "y": ""
    },
    {
        "x": 346.397,
        "y": ""
    },
    {
        "x": 346.625,
        "y": ""
    },
    {
        "x": 346.827,
        "y": ""
    },
    {
        "x": 347.08,
        "y": ""
    },
    {
        "x": 347.358,
        "y": ""
    },
    {
        "x": 347.611,
        "y": ""
    },
    {
        "x": 347.788,
        "y": ""
    },
    {
        "x": 348.04,
        "y": ""
    },
    {
        "x": 348.293,
        "y": ""
    },
    {
        "x": 348.496,
        "y": ""
    },
    {
        "x": 348.748,
        "y": ""
    },
    {
        "x": 348.976,
        "y": ""
    },
    {
        "x": 349.254,
        "y": ""
    },
    {
        "x": 349.456,
        "y": ""
    },
    {
        "x": 349.684,
        "y": ""
    },
    {
        "x": 349.937,
        "y": ""
    },
    {
        "x": 350.164,
        "y": ""
    },
    {
        "x": 350.392,
        "y": ""
    },
    {
        "x": 350.594,
        "y": ""
    },
    {
        "x": 350.847,
        "y": ""
    },
    {
        "x": 351.075,
        "y": ""
    },
    {
        "x": 351.327,
        "y": ""
    },
    {
        "x": 351.555,
        "y": ""
    },
    {
        "x": 351.858,
        "y": ""
    },
    {
        "x": 352.137,
        "y": ""
    },
    {
        "x": 352.339,
        "y": ""
    },
    {
        "x": 352.592,
        "y": ""
    },
    {
        "x": 352.819,
        "y": ""
    },
    {
        "x": 353.097,
        "y": ""
    },
    {
        "x": 353.325,
        "y": ""
    },
    {
        "x": 353.578,
        "y": ""
    },
    {
        "x": 353.805,
        "y": ""
    },
    {
        "x": 354.033,
        "y": ""
    },
    {
        "x": 354.26,
        "y": ""
    },
    {
        "x": 354.513,
        "y": ""
    },
    {
        "x": 354.741,
        "y": ""
    },
    {
        "x": 354.968,
        "y": ""
    },
    {
        "x": 355.221,
        "y": ""
    },
    {
        "x": 355.424,
        "y": ""
    },
    {
        "x": 355.651,
        "y": ""
    },
    {
        "x": 355.879,
        "y": ""
    },
    {
        "x": 356.131,
        "y": ""
    },
    {
        "x": 356.359,
        "y": ""
    },
    {
        "x": 356.587,
        "y": ""
    },
    {
        "x": 356.814,
        "y": ""
    },
    {
        "x": 357.092,
        "y": ""
    },
    {
        "x": 357.345,
        "y": ""
    },
    {
        "x": 357.547,
        "y": ""
    },
    {
        "x": 357.8,
        "y": ""
    },
    {
        "x": 358.053,
        "y": ""
    },
    {
        "x": 358.281,
        "y": ""
    },
    {
        "x": 358.534,
        "y": ""
    },
    {
        "x": 358.71,
        "y": ""
    },
    {
        "x": 359.014,
        "y": ""
    },
    {
        "x": 359.317,
        "y": ""
    },
    {
        "x": 359.545,
        "y": ""
    },
    {
        "x": 359.772,
        "y": ""
    },
    {
        "x": 360,
        "y": ""
    },
    {
        "x": 360.253,
        "y": ""
    },
    {
        "x": 360.43,
        "y": ""
    },
    {
        "x": 360.657,
        "y": ""
    },
    {
        "x": 360.86,
        "y": ""
    },
    {
        "x": 361.138,
        "y": ""
    },
    {
        "x": 361.34,
        "y": ""
    },
    {
        "x": 361.593,
        "y": ""
    },
    {
        "x": 361.871,
        "y": ""
    },
    {
        "x": 362.073,
        "y": ""
    },
    {
        "x": 362.351,
        "y": ""
    },
    {
        "x": 362.579,
        "y": ""
    },
    {
        "x": 362.857,
        "y": ""
    },
    {
        "x": 363.059,
        "y": ""
    },
    {
        "x": 363.287,
        "y": ""
    },
    {
        "x": 363.489,
        "y": ""
    },
    {
        "x": 363.717,
        "y": ""
    },
    {
        "x": 364.02,
        "y": ""
    },
    {
        "x": 364.223,
        "y": ""
    },
    {
        "x": 364.475,
        "y": ""
    },
    {
        "x": 364.703,
        "y": ""
    },
    {
        "x": 364.981,
        "y": ""
    },
    {
        "x": 365.209,
        "y": ""
    },
    {
        "x": 365.436,
        "y": ""
    },
    {
        "x": 365.664,
        "y": ""
    },
    {
        "x": 365.917,
        "y": ""
    },
    {
        "x": 366.144,
        "y": ""
    },
    {
        "x": 366.372,
        "y": ""
    },
    {
        "x": 366.625,
        "y": ""
    },
    {
        "x": 366.877,
        "y": ""
    },
    {
        "x": 367.105,
        "y": ""
    },
    {
        "x": 367.332,
        "y": ""
    },
    {
        "x": 367.585,
        "y": ""
    },
    {
        "x": 367.838,
        "y": ""
    },
    {
        "x": 368.091,
        "y": ""
    },
    {
        "x": 368.268,
        "y": ""
    },
    {
        "x": 368.546,
        "y": ""
    },
    {
        "x": 368.774,
        "y": ""
    },
    {
        "x": 369.052,
        "y": ""
    },
    {
        "x": 369.279,
        "y": ""
    },
    {
        "x": 369.532,
        "y": ""
    },
    {
        "x": 369.785,
        "y": ""
    },
    {
        "x": 370.013,
        "y": ""
    },
    {
        "x": 370.265,
        "y": ""
    },
    {
        "x": 370.493,
        "y": ""
    },
    {
        "x": 370.746,
        "y": ""
    },
    {
        "x": 370.999,
        "y": ""
    },
    {
        "x": 371.226,
        "y": ""
    },
    {
        "x": 371.454,
        "y": ""
    },
    {
        "x": 371.732,
        "y": ""
    },
    {
        "x": 371.96,
        "y": ""
    },
    {
        "x": 372.187,
        "y": ""
    },
    {
        "x": 372.415,
        "y": ""
    },
    {
        "x": 372.642,
        "y": ""
    },
    {
        "x": 372.895,
        "y": ""
    },
    {
        "x": 373.097,
        "y": ""
    },
    {
        "x": 373.35,
        "y": ""
    },
    {
        "x": 373.603,
        "y": ""
    },
    {
        "x": 373.906,
        "y": ""
    },
    {
        "x": 374.109,
        "y": ""
    },
    {
        "x": 374.362,
        "y": ""
    },
    {
        "x": 374.539,
        "y": ""
    },
    {
        "x": 374.817,
        "y": ""
    },
    {
        "x": 375.044,
        "y": ""
    },
    {
        "x": 375.247,
        "y": ""
    },
    {
        "x": 375.474,
        "y": ""
    },
    {
        "x": 375.702,
        "y": ""
    },
    {
        "x": 375.98,
        "y": ""
    },
    {
        "x": 376.207,
        "y": ""
    },
    {
        "x": 376.435,
        "y": ""
    },
    {
        "x": 376.662,
        "y": ""
    },
    {
        "x": 376.915,
        "y": ""
    },
    {
        "x": 377.219,
        "y": ""
    },
    {
        "x": 377.421,
        "y": ""
    },
    {
        "x": 377.674,
        "y": ""
    },
    {
        "x": 377.927,
        "y": ""
    },
    {
        "x": 378.18,
        "y": ""
    },
    {
        "x": 378.407,
        "y": ""
    },
    {
        "x": 378.609,
        "y": ""
    },
    {
        "x": 378.913,
        "y": ""
    },
    {
        "x": 379.166,
        "y": ""
    },
    {
        "x": 379.393,
        "y": ""
    },
    {
        "x": 379.671,
        "y": ""
    },
    {
        "x": 379.924,
        "y": ""
    },
    {
        "x": 380.152,
        "y": ""
    },
    {
        "x": 380.354,
        "y": ""
    },
    {
        "x": 380.531,
        "y": ""
    },
    {
        "x": 380.784,
        "y": ""
    },
    {
        "x": 381.011,
        "y": ""
    },
    {
        "x": 381.239,
        "y": ""
    },
    {
        "x": 381.466,
        "y": ""
    },
    {
        "x": 381.719,
        "y": ""
    },
    {
        "x": 381.947,
        "y": ""
    },
    {
        "x": 382.174,
        "y": ""
    },
    {
        "x": 382.453,
        "y": ""
    },
    {
        "x": 382.68,
        "y": ""
    },
    {
        "x": 382.908,
        "y": ""
    },
    {
        "x": 383.085,
        "y": ""
    },
    {
        "x": 383.338,
        "y": ""
    },
    {
        "x": 383.59,
        "y": ""
    },
    {
        "x": 383.818,
        "y": ""
    },
    {
        "x": 384.096,
        "y": ""
    },
    {
        "x": 384.324,
        "y": ""
    },
    {
        "x": 384.577,
        "y": ""
    },
    {
        "x": 384.829,
        "y": ""
    },
    {
        "x": 385.082,
        "y": ""
    },
    {
        "x": 385.335,
        "y": ""
    },
    {
        "x": 385.537,
        "y": ""
    },
    {
        "x": 385.765,
        "y": ""
    },
    {
        "x": 386.018,
        "y": ""
    },
    {
        "x": 386.195,
        "y": ""
    },
    {
        "x": 386.473,
        "y": ""
    },
    {
        "x": 386.7,
        "y": ""
    },
    {
        "x": 386.979,
        "y": ""
    },
    {
        "x": 387.206,
        "y": ""
    },
    {
        "x": 387.509,
        "y": ""
    },
    {
        "x": 387.712,
        "y": ""
    },
    {
        "x": 387.939,
        "y": ""
    },
    {
        "x": 388.142,
        "y": ""
    },
    {
        "x": 388.42,
        "y": ""
    },
    {
        "x": 388.673,
        "y": ""
    },
    {
        "x": 388.925,
        "y": ""
    },
    {
        "x": 389.153,
        "y": ""
    },
    {
        "x": 389.381,
        "y": ""
    },
    {
        "x": 389.659,
        "y": ""
    },
    {
        "x": 389.886,
        "y": ""
    },
    {
        "x": 390.088,
        "y": ""
    },
    {
        "x": 390.291,
        "y": ""
    },
    {
        "x": 390.569,
        "y": ""
    },
    {
        "x": 390.771,
        "y": ""
    },
    {
        "x": 391.024,
        "y": ""
    },
    {
        "x": 391.277,
        "y": ""
    },
    {
        "x": 391.504,
        "y": ""
    },
    {
        "x": 391.757,
        "y": ""
    },
    {
        "x": 391.934,
        "y": ""
    },
    {
        "x": 392.212,
        "y": ""
    },
    {
        "x": 392.465,
        "y": ""
    },
    {
        "x": 392.743,
        "y": ""
    },
    {
        "x": 393.021,
        "y": ""
    },
    {
        "x": 393.249,
        "y": ""
    },
    {
        "x": 393.527,
        "y": ""
    },
    {
        "x": 393.78,
        "y": ""
    },
    {
        "x": 393.957,
        "y": ""
    },
    {
        "x": 394.235,
        "y": ""
    },
    {
        "x": 394.463,
        "y": ""
    },
    {
        "x": 394.716,
        "y": ""
    },
    {
        "x": 394.893,
        "y": ""
    },
    {
        "x": 395.12,
        "y": ""
    },
    {
        "x": 395.373,
        "y": ""
    },
    {
        "x": 395.626,
        "y": ""
    },
    {
        "x": 395.853,
        "y": ""
    },
    {
        "x": 396.081,
        "y": ""
    },
    {
        "x": 396.283,
        "y": ""
    },
    {
        "x": 396.561,
        "y": ""
    },
    {
        "x": 396.814,
        "y": ""
    },
    {
        "x": 397.067,
        "y": ""
    },
    {
        "x": 397.295,
        "y": ""
    },
    {
        "x": 397.522,
        "y": ""
    },
    {
        "x": 397.75,
        "y": ""
    },
    {
        "x": 398.003,
        "y": ""
    },
    {
        "x": 398.255,
        "y": ""
    },
    {
        "x": 398.508,
        "y": ""
    },
    {
        "x": 398.761,
        "y": ""
    },
    {
        "x": 399.014,
        "y": ""
    },
    {
        "x": 399.241,
        "y": ""
    },
    {
        "x": 399.52,
        "y": ""
    },
    {
        "x": 399.722,
        "y": ""
    },
    {
        "x": 399.949,
        "y": ""
    },
    {
        "x": 400.177,
        "y": ""
    },
    {
        "x": 400.329,
        "y": ""
    },
    {
        "x": 400.582,
        "y": ""
    },
    {
        "x": 400.834,
        "y": ""
    },
    {
        "x": 401.087,
        "y": ""
    },
    {
        "x": 401.315,
        "y": ""
    },
    {
        "x": 401.542,
        "y": ""
    },
    {
        "x": 401.77,
        "y": ""
    },
    {
        "x": 402.023,
        "y": ""
    },
    {
        "x": 402.301,
        "y": ""
    },
    {
        "x": 402.554,
        "y": ""
    },
    {
        "x": 402.756,
        "y": ""
    },
    {
        "x": 402.984,
        "y": ""
    },
    {
        "x": 403.236,
        "y": ""
    },
    {
        "x": 403.515,
        "y": ""
    },
    {
        "x": 403.742,
        "y": ""
    },
    {
        "x": 403.995,
        "y": ""
    },
    {
        "x": 404.248,
        "y": ""
    },
    {
        "x": 404.475,
        "y": ""
    },
    {
        "x": 404.753,
        "y": ""
    },
    {
        "x": 404.956,
        "y": ""
    },
    {
        "x": 405.234,
        "y": ""
    },
    {
        "x": 405.436,
        "y": ""
    },
    {
        "x": 405.664,
        "y": ""
    },
    {
        "x": 405.917,
        "y": ""
    },
    {
        "x": 406.144,
        "y": ""
    },
    {
        "x": 406.422,
        "y": ""
    },
    {
        "x": 406.65,
        "y": ""
    },
    {
        "x": 406.928,
        "y": ""
    },
    {
        "x": 407.181,
        "y": ""
    },
    {
        "x": 407.459,
        "y": ""
    },
    {
        "x": 407.712,
        "y": ""
    },
    {
        "x": 407.939,
        "y": ""
    },
    {
        "x": 408.167,
        "y": ""
    },
    {
        "x": 408.445,
        "y": ""
    },
    {
        "x": 408.698,
        "y": ""
    },
    {
        "x": 408.951,
        "y": ""
    },
    {
        "x": 409.178,
        "y": ""
    },
    {
        "x": 409.431,
        "y": ""
    },
    {
        "x": 409.684,
        "y": ""
    },
    {
        "x": 409.962,
        "y": ""
    },
    {
        "x": 410.24,
        "y": ""
    }
];

export const dots = [
    {
        "x": 100,
        "y": 3.4259,
        indexLabel: '3.4',
        markerColor: "red",
        markerType: "circle"
    },
    {
        "x": 100,
        "y": null,
    },
    {
        "x": 200,
        "y": 7.38089,
        indexLabel: '7.4',
        markerColor: "red",
        markerType: "circle"
    },
    {
        "x": 200,
        "y": null,
    },
    {
        "x": 300,
        "y": 11.0608,
        indexLabel: '11.1',
        markerColor: "red",
        markerType: "circle"
    }
];

export const lastDot = [
    {
        "x": 410.24,
        "y": 14.29,
        indexLabel: '410.24, 14.29',
        markerColor: "green",
        markerType: "square"
    }
]