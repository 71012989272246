import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import {
    comparisonAILine1,
    comparisonAILine2,
    comparisonAILine3,
    comparisonAILine4,
    comparisonAILine5
} from "../../data/comparisonAIData";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stripLinesExample = [];

class DisplayGraphComparison1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            showCharts: false
        };
        this.showCharts = this.showCharts.bind(this);
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }

    componentDidMount() {
        setInterval(() => {
            if(!this.state.showCharts) {
                this.showCharts();
            }
        }, 5000);
    }

    toggleDataSeries(e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    showCharts() {
        if (localStorage.getItem("AIToggle") === "true") {
            this.setState({showCharts: true});
        }
    }

    render() {
        const options = {
            theme: "light2",
            animationEnabled: true,
            zoomEnabled: true,
            exportEnabled: true,
            axisX: {
                title: "Time in min",
                titleFontColor: "#6D78AD",
                maximum: 80,
                showInLegend: true
            },
            axisY: {
                title: "Torque S' in  dNm",
                titleFontColor: "#6D78AD",
                lineColor: "#6D78AD",
                labelFontColor: "#6D78AD",
                tickColor: "#6D78AD",
                includeZero: false,
                stripLines: stripLinesExample,
                showInLegend: true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
            },
            data: [
                {
                    type: "line",
                    name: "EPDM CB60 @150 °C",
                    color: 'red',
                    showInLegend: true,
                    lineColor: 'red',
                    dataPoints: comparisonAILine1,
                },
                {
                    type: "line",
                    lineColor: 'maroon',
                    name: "EPDM CB60 @160 °C",
                    showInLegend: true,
                    color: 'maroon',
                    dataPoints: comparisonAILine2,
                },
                {
                    type: "line",
                    name: "EPDM CB60 @170 °C",
                    showInLegend: true,
                    lineColor: 'yellow',
                    color: 'yellow',
                    dataPoints: comparisonAILine3,
                },
                {
                    type: "line",
                    lineDashType: 'dash',
                    name: "Upper bound",
                    showInLegend: true,
                    lineColor: 'gray',
                    color: 'gray',
                    dataPoints: comparisonAILine4,
                },
                {
                    type: "line",
                    lineDashType: "dash",
                    name: "Lower bound",
                    showInLegend: true,
                    lineColor: 'gray',
                    color: 'gray',
                    dataPoints: comparisonAILine5,
                },
            ],
        };

        return (
            <Container
                component={Paper}
                style={this.state.showCharts ? {
                    paddingLeft: "0",
                    paddingRight: '0',
                    paddingBottom: "48px",
                    marginBottom: "60px",
                } : {display: "none"}}
            >

                {/*element.style {*/}
                {/*margin: 15px -24px 45px;*/}
                {/*margin-top: 0;*/}
                {/*padding: lef;*/}
                {/*margin-left: 0;*/}
                {/*margin-right: 0;*/}
                <Container maxWidth="lg" style={{background: '#f5f5f5', padding: '20px'}}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        Comparison
                    </Typography>
                </Container>
                <Divider
                    variant="fullWidth"
                    style={{
                        marginBottom: "45px",
                    }}
                />
                <Container maxWidth="lg">
                    <Box>
                        <div className="MultipleAxisChart">
                            <CanvasJSChart
                                options={options}
                                onRef={(rheoref) => (this.chart = rheoref)}
                            />
                            {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
                        </div>
                    </Box>
                </Container>
            </Container>

        );
    }
}

export default DisplayGraphComparison1;
