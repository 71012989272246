import React from "react";
import OrderRecipts from "./OrderRecipts";
import Pdf from "../../assets/pdf/report.pdf";

class ReportPDF extends React.Component {
    render() {
        return (
            <div>
                <OrderRecipts file={Pdf} />
            </div>
        );
    }
}

export default ReportPDF;
