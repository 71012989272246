import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";

import DisplayCommodity from "./displayCommodities";
import DisplayAmounts from "./displayAmounts";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PrintIcon from "@material-ui/icons/Print";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import * as ROUTES from "../../constants/routes";
import DisplayMischprozess from "../Order/displayMischprozess";
import TableHead from "@material-ui/core/TableHead";

class ViewRecipe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            refid: 0,
            loading: false,
            recipe: [],
            rawmaterials: [],
            production: {}
        };
    }

    componentDidMount() {
        let recipeRefID = parseInt(this.props.match.params.id);
        this.setState({ loading: true });

        this.props.firebase.rawmaterials().on("value", (snapshot) => {
            const rawmaterialsObject = snapshot.val();

            this.setState({
                rawmaterials: rawmaterialsObject,
            });
        });

        this.props.firebase.productions().on("value", (snapshot) => {
            const productionObject = snapshot.val();
            console.log(productionObject);
            let foundProductionArray = productionObject.filter(
                (production) =>
                    production._id === 1
            );
            let productionObj = foundProductionArray[0];

            this.setState({
                production: productionObj,
            });
        });

        this.props.firebase.recipes().on("value", (snapshot) => {
            const recipesObject = snapshot.val();
            // Object.keys(rawmaterialList).map((item) => {
            //   if(rawmaterialList[item]._id === recipeid) {
            //     rawmaterialDensity = rawmaterialList[item].rawmaterialDensity;
            //   }
            // });
            let recipeRefObject = null;
            Object.keys(recipesObject).map((item) => {
                if(parseInt(recipesObject[item]._id) === recipeRefID) {
                    recipeRefObject = recipesObject[item];
                }
            });
            // const recipeRefObject = recipesObject.find(
            //   (recipe) => recipe._id === recipeRefID
            // );

            this.setState({
                refid: recipeRefID,
                recipe: recipeRefObject,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.recipes().off();
        this.props.firebase.rawmaterials().off();
    }

    render() {
        const { refid, recipe, rawmaterials, loading } = this.state;
        return (
            <div>
                {loading && (
                    <div
                        style={{
                            position: "fixed",
                            bottom: "0",
                            right: "0",
                            display: "block",
                            backgroundColor: "darkgrey",
                            padding: "5px 10px",
                        }}
                    >
                        Loading ...
                    </div>
                )}

                <RecipesList
                    refid={refid}
                    rawmaterials={rawmaterials}
                    recipe={recipe}
                    production={this.state.production}
                />
            </div>
        );
    }
}

function fetchRawmaterialGroup(grouprefid) {
    switch (grouprefid) {
        case 1:
            return "EPDM";
        case 2:
            return "Carbon black";
        case 3:
            return "Vulkanisationsaktivator";
        case 4:
            return "Weichmacher";
        case 5:
            return "Katalysator";
        case 6:
            return "Vulkanisationsaktivator";
        case 7:
            return "Vernetzungsmittel";
        case 8:
            return "Vulkanisationsbeschleuniger";
        case 9:
            return "Vulkanisationsbeschleuniger";
        case 10:
            return "IR";
        case 11:
            return "Carbon black";
        case 12:
            return "Carbon black";
        case 13:
            return "Alterungsschutzmittel";
        case 14:
            return "Vulkanisationsbeschleuniger";
        case 15:
            return "Vulkanisationsbeschleuniger";
        case 16:
            return "EPDM";
        case 17:
            return "Carbon black";
        case 18:
            return "Weichmacher";
        case 19:
            return "Weichmacher";
        case 20:
            return "Vulkanisationsbeschleuniger";
        case 21:
            return "Vulkanisationsbeschleuniger";
        case 22:
            return "Vulkanisationsaktivator";
        case 23:
            return "weitere";
        case 24:
            return "Zuschlagstoffe";
        case 25:
            return "EPDM";
        case 26:
            return "Vulkanisationsbeschleuniger";
        case 27:
            return "ACM";
        case 28:
            return "ACM";
        case 29:
            return "ACM";
        case 30:
            return "Weichmacher";
        case 31:
            return "Alterungsschutzmittel";
        case 32:
            return "Carbon black";
        case 33:
            return "Weichmacher";
        case 34:
            return "Vulkanisationsstopper";
        case 35:
            return "weitere";
        default:
            break;
    }
}

function fetchRawmaterialName(grouprefid) {
    switch (grouprefid) {
        case 1:
            return "Keltan 2450";
        case 2:
            return "N550";
        case 3:
            return "PEG-4000";
        case 4:
            return "Prozess Oil P 460";
        case 5:
            return "Zinkoxid (ZnO)";
        case 6:
            return "Stearic acid";
        case 7:
            return "Sulfur";
        case 8:
            return "TBBS";
        case 9:
            return "TBzTD-70";
        case 10:
            return "Natsyn 2200";
        case 11:
            return "N110";
        case 12:
            return "N990";
        case 13:
            return "Agerite Resin D";
        case 14:
            return "ALTAX (MBTS)";
        case 15:
            return "Methyl Tuads (TMTD)";
        case 16:
            return "Keltan 4506";
        case 17:
            return "N774";
        case 18:
            return "Sunpar 2280";
        case 19:
            return "Paraffin";
        case 20:
            return "MBT";
        case 21:
            return "DPTH";
        case 22:
            return "TMTD";
        case 23:
            return "TEDC";
        case 24:
            return "Cumar R-16";
        case 25:
            return "Keltan 7506";
        case 26:
            return "TMTM";
        case 27:
            return "Nipol AR-72HF";
        case 28:
            return "Nipol AR-74";
        case 29:
            return "Hy Temp AR-72LF";
        case 30:
            return "Struktol WB222";
        case 31:
            return "Naugard 445";
        case 32:
            return "N330";
        case 33:
            return "TP-759";
        case 34:
            return "Vulkalent E/C";
        case 35:
            return "Hy Temp ZC-50";
        default:
            break;
    }
}

const RecipesList = ({ refid, rawmaterials, recipe, production }) => (
    <React.Fragment>
        <Container
            component={Paper}
            maxWidth="lg"
            style={{
                paddingBottom: "24px",
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Typography
                        variant="h5"
                        component="h1"
                        style={{
                            marginBottom: "0",
                        }}
                    >
                        {recipe.recipeName}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <ButtonGroup
                            variant="contained"
                            color="primary"
                            aria-label="contained primary button group"
                        >
                            <Button
                                component={Link}
                                to={ROUTES.RECIPES}
                                startIcon={<ArrowBackIcon />}
                            >
                                Back to Recipes
                            </Button>
                            {/* <Button onClick={window.print()} startIcon={<PrintIcon />}>
                Print
              </Button> */}
                        </ButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            <Divider
                variant="fullWidth"
                style={{
                    marginTop: "15px",
                    marginBottom: "45px",
                    marginLeft: "-24px",
                    marginRight: "-24px",
                }}
            />
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Paper style={{background: '#e8e8e8'}}>
                            <TableContainer>
                                <Table size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell scope="row"><strong>Recipe Name</strong></TableCell>
                                            <TableCell align="right">{recipe.recipeName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row"><strong>Short Name</strong></TableCell>
                                            <TableCell align="right">{recipe.recipeShortName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <strong>Source</strong>
                                            </TableCell>
                                            <TableCell align="right">{recipe.source}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <Paper style={{ width: "100%" }}>
                            <TableContainer style={{marginTop: '20px'}}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <strong>Polymer</strong>
                                            </TableCell>
                                            <TableCell align="right">
                                                {fetchRawmaterialGroup(recipe.basePolymer)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <strong>Network</strong>
                                            </TableCell>
                                            <TableCell align="right">
                                                {console.log(recipe.baseNetwork)}
                                                {fetchRawmaterialName(recipe.baseNetwork)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <strong>Base Filler</strong>
                                            </TableCell>
                                            <TableCell align="right">
                                                {fetchRawmaterialGroup(recipe.baseFiller)}
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <Box component={Paper} style={{marginTop: '15px'}}>
                            <DisplayAmounts
                                refid={refid}
                                recipe={recipe}
                                rawmaterials={rawmaterials}
                                commoditylist={recipe.rawmaterialList}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <Box>
                                        <Box style={{width: "100%"}}>
                                            <Paper>
                                                <TableContainer>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableBody>
                                                            <TableRow style={{backgroundColor: '#e8e8e8'}}>
                                                                <TableCell scope="row"><strong>Mixing Procedure</strong></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <TableContainer>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>1</TableCell>
                                                            <TableCell style={{width: '90px'}}>1m 00s</TableCell>
                                                            <TableCell>Keltan 2450, N550, Prozess Oil P 460</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>2</TableCell>
                                                            <TableCell style={{width: '90px'}}>0m 0s</TableCell>
                                                            <TableCell>Venting</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>3</TableCell>
                                                            <TableCell style={{width: '90px'}}>1m 20s</TableCell>
                                                            <TableCell>Mixing</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Paper>
                                <Box style={{marginTop: '10px'}}>
                                    <Paper>
                                        <TableContainer>
                                            <Table size="small" aria-label="a dense table">
                                                <TableBody>
                                                    <TableRow style={{backgroundColor: '#e8e8e8'}}>
                                                        <TableCell scope="row"><strong>Physicals</strong></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                    <TableContainer>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell scope="row" style={{fontSize: '11px'}}>1 Produced with Mix Proc 1, Vulcanisation time t90 21.37 min @ 150&#8451;</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <DisplayCommodity
                                        fetchRawmaterialGroup={fetchRawmaterialGroup}
                                        fetchRawmaterialName={fetchRawmaterialName}
                                        refid={refid}
                                        recipe={recipe}
                                        rawmaterials={rawmaterials}
                                        commoditylist={recipe.rawmaterialList}
                                    />
                                </Box>
                                <Paper style={{ width: "100%" }}>
                                    <TableContainer style={{marginTop: '20px'}}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <strong>Elongation in %</strong>
                                                    </TableCell>
                                                    <TableCell align="right">{recipe.elongation}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <strong>Hardness in Shore A</strong>
                                                    </TableCell>
                                                    <TableCell align="right">{recipe.hardness}</TableCell>
                                                </TableRow>
                                                {/*<TableRow>*/}
                                                {/*    <TableCell component="th" scope="row">*/}
                                                {/*        <strong>Mixing Procedure</strong>*/}
                                                {/*    </TableCell>*/}
                                                {/*    <TableCell align="right">{recipe.mixing_proc}</TableCell>*/}
                                                {/*</TableRow>*/}
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <strong>Stiffenss in MPa 100%</strong>
                                                    </TableCell>
                                                    <TableCell align="right">{recipe.stiffness100 ? recipe.stiffness100.join('.') : void 0}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <strong>Stiffenss in MPa 200%</strong>
                                                    </TableCell>
                                                    <TableCell align="right">{recipe.stiffness200 ? recipe.stiffness200.join('.') : void 0}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <strong>Stiffenss in MPa 300%</strong>
                                                    </TableCell>
                                                    <TableCell align="right">{recipe.stiffness300 ? recipe.stiffness300.join('.') : void 0}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <strong>Tensile (max) in MPa</strong>
                                                    </TableCell>
                                                    <TableCell align="right">{recipe.tensile_max ? recipe.tensile_max.join('.') : void 0}</TableCell>
                                                </TableRow>
                                                {/*<TableRow>*/}
                                                {/*    <TableCell component="th" scope="row">*/}
                                                {/*        <strong>Vulcanisation t90</strong>*/}
                                                {/*    </TableCell>*/}
                                                {/*    <TableCell align="right">*/}
                                                {/*        {recipe.vulcanisation_t90}*/}
                                                {/*    </TableCell>*/}
                                                {/*</TableRow>*/}
                                                {/*<TableRow>*/}
                                                {/*    <TableCell component="th" scope="row">*/}
                                                {/*        <strong>Vulcanisation tem</strong>*/}
                                                {/*    </TableCell>*/}
                                                {/*    <TableCell align="right">*/}
                                                {/*        {recipe.vulcanisation_tem}*/}
                                                {/*    </TableCell>*/}
                                                {/*</TableRow>*/}
                                                {/*<TableRow>*/}
                                                {/*    <TableCell component="th" scope="row">*/}
                                                {/*        <strong>Physicals</strong>*/}
                                                {/*    </TableCell>*/}
                                                {/*</TableRow>*/}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper>
                                    <TableContainer>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>
                                                <TableRow style={{backgroundColor: '#e8e8e8'}}>
                                                    <TableCell component="th" scope="row">
                                                        <strong>Comments</strong>
                                                    </TableCell>
                                                    <TableCell align="right">{recipe.comments}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </React.Fragment>
);

export default compose(withFirebase)(ViewRecipe);
