import React from "react";
import * as ROUTES from "../../constants/routes";

import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { withFirebase } from "../Firebase";
import {Link} from "react-router-dom";

const SignOutButton = ({ display, firebase }) => {
  console.log(display);
  return (
      <Button
          color="secondary"
          startIcon={<ExitToAppIcon />}
          onClick={firebase.doSignOut}
          component={Link}
          to={ROUTES.SIGN_IN}
          style={display ? {} : {display: "none"}}
      >
        Sign Out
      </Button>
  )
}

export default withFirebase(SignOutButton);
