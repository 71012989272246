import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import {
    torqueDataWithoutAI,
    toqueDataWithAILine1,
    toqueDataWithAILine2,
    toqueDataPoint1,
    toqueDataPoint2,
    torqueDataPoint3, torqueDataPoint4, torqueDataPoint5, torqueDataPoint6, torqueDataPoint7
} from "../../data/TorqueData";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stripLinesExample = [];

class DisplayGraphTorque1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.updateChart = this.updateChart.bind(this);
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }
    componentDidMount() {
        this.updateChart();
        setInterval(() => {
            if(!this.state.loaded) {
                this.updateChart();
            }
        }, 5000);
    }

    updateChart() {
        let x = 0;
        if (localStorage.getItem("AIToggle") === "true") {
            if (x === 0) {
                this.setState({loaded: true});
                this.chart.options.data.push({
                    type: "line",
                    lineDashType: 'dash',
                    dataPoints: toqueDataWithAILine1,
                    legendText: 'Lower bound',
                    showInLegend: true,
                });
                this.chart.options.data.push({
                    type: "line",
                    lineDashType: 'dash',
                    dataPoints: toqueDataWithAILine2,
                    legendText: 'Upper bound',
                    showInLegend: true,
                });
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: toqueDataPoint1,
                    showInLegend: true,
                    markerType: "triangle",
                    color: 'red',
                    markerSize: '15',
                    name: 't5'
                });
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: toqueDataPoint2,
                    showInLegend: true,
                    markerType: "triangle",
                    color: 'maroon',
                    markerSize: '15',
                    name: 't10'
                });
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: torqueDataPoint3,
                    showInLegend: true,
                    markerType: "triangle",
                    color: '#8c8f00',
                    markerSize: '15',
                    name: 't25'
                });
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: torqueDataPoint4,
                    showInLegend: true,
                    markerType: "triangle",
                    color: 'yellow',
                    markerSize: '15',
                    name: 't50'
                });
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: torqueDataPoint5,
                    showInLegend: true,
                    markerType: "triangle",
                    color: '#007800',
                    markerSize: '15',
                    name: 't90'
                });
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: torqueDataPoint6,
                    showInLegend: true,
                    markerType: "triangle",
                    color: 'green',
                    markerSize: '15',
                    name: 't95'
                });
                this.chart.options.data.push({
                    type: "scatter",
                    dataPoints: torqueDataPoint7,
                    showInLegend: true,
                    markerType: "triangle",
                    color: '#3954db',
                    markerSize: '15',
                    name: 't100'
                });
                this.chart.render();
                x++;
            }
        } else {
            return;
        }
    }
    toggleDataSeries(e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    render() {
        const options = {
            theme: "light2",
            animationEnabled: true,
            zoomEnabled: true,
            exportEnabled: true,
            axisX: {
                title: "Time in min",
                titleFontColor: "#6D78AD",
                maximum: 80,
                showInLegend: true
            },
            axisY: {
                title: "Torque S' in dNm",
                titleFontColor: "#6D78AD",
                lineColor: "#6D78AD",
                labelFontColor: "#6D78AD",
                tickColor: "#6D78AD",
                includeZero: false,
                stripLines: stripLinesExample,
                showInLegend: true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
            },
            data: [
                {
                    type: "line",
                    name: "Torque S' [dNm]",
                    showInLegend: true,
                    dataPoints: torqueDataWithoutAI,
                },
            ],
        };

        return (
            <div className="MultipleAxisChart">
                <CanvasJSChart
                    options={options}
                    onRef={(rheoref) => (this.chart = rheoref)}
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}

export default DisplayGraphTorque1;
