import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import rheometertorque from "../../data/rheometertorque";
import rheometertemperatur from "../../data/rheometertemperatur";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stripLinesExample = [];

class DisplayGraphRheometer extends Component {
  constructor() {
    super();
    this.updateChart = this.updateChart.bind(this);
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }
  componentDidMount() {
    this.updateChart();

    // this.chart.options.data[1].dataPoints.filter(obj => {
    //   if (obj.y === 150.64) {
    //     obj.indexLabel = "\u2191 highest";
    //     obj.indexLabelFontSize = 16;
    //     obj.indexLabelColor = "black";
    //     obj.indexLabelBackgroundColor = "lightred";
    //     obj.markerColor = "red";
    //     obj.markerType = "triangle";
    //     obj.toolTipContent = "triangle";
    //     obj.cursor = "pointer";
    //   }
    // });
    setInterval(this.updateChart, 5000);
  }
  updateChart() {
    let x = 0;
    if (localStorage.getItem("AIToggle") === "true") {
      if (x === 0) {
        // this.chart.options.data.push
        this.chart.options.data.push({
          type: "stepLine",
          markerSize: 5,
          lineColor: "lightblue",
          dataPoints: [
            { x: 5.5, y: 0 },
            {
              x: 5.5,
              y: 25,
            },
            { x: 28, y: 25 },
            { x: 28, y: 0 },
          ],
        });
        stripLinesExample.push({
          value: 150.66,
          // label: "True Speed",
          // labelFontColor: "#FF0800",
          // showOnTop: true,
          // labelAlign: "center",
          color: "#FF0800",
        });
        stripLinesExample.push({
          value: 150,
          // label: "True Speed 2",
          // labelFontColor: "#FF0800",
          // showOnTop: true,
          // labelAlign: "center",
          color: "#FF0800",
        });
        this.chart.render();
        x++;
      }
    } else {
      return;
    }
  }
  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  render() {
    const options = {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,
      exportEnabled: true,
      axisY: {
        title: "Torque",
        titleFontColor: "#6D78AD",
        lineColor: "#6D78AD",
        labelFontColor: "#6D78AD",
        tickColor: "#6D78AD",
        includeZero: false,
      },
      axisY2: {
        title: "Temperature",
        titleFontColor: "#51CDA0",
        lineColor: "#51CDA0",
        labelFontColor: "#51CDA0",
        tickColor: "#51CDA0",
        includeZero: false,
        stripLines: stripLinesExample,
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
      },
      data: [
        {
          type: "line",
          name: "Torque (Nm)",
          showInLegend: true,
          xValueFormatString: "History: ### Sec",
          yValueFormatString: "###.## (Nm)",
          dataPoints: rheometertorque,
        },
        {
          type: "line",
          name: "Temperature",
          axisYType: "secondary",
          showInLegend: true,
          xValueFormatString: "History: ### Sec",
          yValueFormatString: "###.##° C",
          dataPoints: rheometertemperatur,
        },
      ],
    };

    return (
      <div className="MultipleAxisChart">
        <CanvasJSChart
          options={options}
          onRef={(rheoref) => (this.chart = rheoref)}
        />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  }
}

export default DisplayGraphRheometer;
