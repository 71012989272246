import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const SignUpPage = () => (
  <React.Fragment>
    <Container
      maxWidth="sm"
      component={Paper}
      style={{
        marginTop: "60px",
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      <Typography variant="h5" component="h1">
        Register an Account
      </Typography>
      <SignUpForm />
    </Container>
  </React.Fragment>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  isErwin: false,
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { username, email, passwordOne, isAdmin, isErwin } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }
    if (isErwin) {
      roles[ROLES.ERWIN] = ROLES.ERWIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          roles,
        });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      isErwin,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div
          style={{
            marginTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <TextField
            name="username"
            value={username}
            onChange={this.onChange}
            type="text"
            placeholder="Full Name"
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            marginTop: "15px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <TextField
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            marginTop: "15px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <TextField
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            marginTop: "15px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <TextField
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm Password"
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            marginTop: "15px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <label
            style={{
              marginRight: "10px",
            }}
          >
            Administrator:
            <input
              name="isAdmin"
              type="checkbox"
              checked={isAdmin}
              onChange={this.onChangeCheckbox}
            />
          </label>
          <label>
            Operator:
            <input
              name="isErwin"
              type="checkbox"
              checked={isErwin}
              onChange={this.onChangeCheckbox}
            />
          </label>
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={isInvalid}
          type="submit"
          style={{
            marginTop: "30px",
            marginLeft: "30px",
          }}
        >
          Sign Up
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p style={{visibility: 'hidden'}}>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
