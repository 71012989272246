import React, {Component} from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

let mixerArray = [];

function msToMinSec(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
}

function fetchCommodityName(rawmaterialID, rawmaterials) {
    let rawmaterialList = rawmaterials;
    let rawmaterialName = '';
    Object.keys(rawmaterialList).map((item) => {
        if (rawmaterialList[item]._id === rawmaterialID) {
            rawmaterialName = rawmaterialList[item].rawmaterialName;
        }
    });
    return rawmaterialName;
}

class DisplayMischprozess extends Component {
    render() {
        const {rawmaterials, production} = this.props;
        let mischerRowValue = [];
        let mixerTask;

        if (production.internalMixer !== undefined) {
            let productionArray = production.mixing;

            productionArray.shift();
            // if(productionArray.length === 4) {
            //   productionArray.splice(1, 1);
            // }

            for (let i = 0; i < productionArray.length; i++) {
                if (productionArray[i] != undefined) {
                    if (productionArray[i].commodityRefID != undefined) {
                        let commodityRefIDList = productionArray[i].commodityRefID;
                        if (Object.keys(rawmaterials).length > 0) {
                            commodityRefIDList.forEach((commodityID) => {
                                    if (commodityID) {
                                        mixerArray.push(fetchCommodityName(commodityID, rawmaterials))
                                    }
                                }
                            );
                          let uniqueArray = mixerArray.filter(function(item, pos) {
                            return mixerArray.indexOf(item) == pos;
                          })
                          mixerTask = uniqueArray.join(", ");
                        }
                    } else {
                        mixerTask = productionArray[i].mixingTask;
                    }
                    mischerRowValue.push(
                        <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                                {msToMinSec(productionArray[i].mixingDuration)}
                            </TableCell>
                            <TableCell>{mixerTask}</TableCell>
                        </TableRow>
                    );
                }
                // for (const [key, value] of mischerArray) {
                //   let mixerKey = key;
                //   let mixerTask;
                //   let mixerDuration = msToMinSec(value.mixingDuration);

                //   if (value.commodityRefID) {
                //     let mixerArray = [];
                //     let commodityRefIDList = Object.entries(value.commodityRefID);
                //     let commodityArray = Object.values(CommodityDB);

                //     for (const [refkey, refvalue] of commodityRefIDList) {
                //       let foundObjectArray = commodityArray.filter(
                //         (commodity) => commodity._id === refvalue
                //       );
                //       mixerArray.push(foundObjectArray[0].commodityName);
                //     }
                //     mixerTask = mixerArray.join(", ");
                //   } else {
                //     mixerTask = value.mixingTask;
                //   }

                //   mischerRowValue.push(
                //     <TableRow key={mixerKey}>
                //       <TableCell>{mixerKey}</TableCell>
                //       <TableCell>{mixerDuration}</TableCell>
                //       <TableCell>{mixerTask}</TableCell>
                //     </TableRow>
                //   );
                // }
            }
        }

        return (
            <Box style={{width: "100%"}}>
                <Paper>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <TableCell scope="row"><strong>Mixing Procedure</strong></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead style={this.props.hideHeader ? {display: "none"} : {}}>
                            <TableRow>
                                <TableCell>
                                    <strong>Step</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Duration</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Compound</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{mischerRowValue}</TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
}

export default DisplayMischprozess;
