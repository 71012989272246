export const ConversionAIData = [
    {
        "x": 0.02,
        "y": 0
    },
    {
        "x": 0.03,
        "y": 0
    },
    {
        "x": 0.04,
        "y": 0
    },
    {
        "x": 0.05,
        "y": 0
    },
    {
        "x": 0.06,
        "y": 0
    },
    {
        "x": 0.07,
        "y": 0
    },
    {
        "x": 0.08,
        "y": 0
    },
    {
        "x": 0.09,
        "y": 0
    },
    {
        "x": 0.1,
        "y": 0
    },
    {
        "x": 0.11,
        "y": 0
    },
    {
        "x": 0.12,
        "y": 0
    },
    {
        "x": 0.13,
        "y": 0
    },
    {
        "x": 0.14,
        "y": 0
    },
    {
        "x": 0.15,
        "y": 0
    },
    {
        "x": 0.16,
        "y": 0
    },
    {
        "x": 0.17,
        "y": 0
    },
    {
        "x": 0.18,
        "y": 0
    },
    {
        "x": 0.19,
        "y": 0
    },
    {
        "x": 0.2,
        "y": 0
    },
    {
        "x": 0.21,
        "y": 0
    },
    {
        "x": 0.22,
        "y": 0
    },
    {
        "x": 0.23,
        "y": 0
    },
    {
        "x": 0.24,
        "y": 0
    },
    {
        "x": 0.25,
        "y": 0
    },
    {
        "x": 0.26,
        "y": 0
    },
    {
        "x": 0.27,
        "y": 0
    },
    {
        "x": 0.28,
        "y": 0
    },
    {
        "x": 0.29,
        "y": 0
    },
    {
        "x": 0.3,
        "y": 0
    },
    {
        "x": 0.31,
        "y": 0
    },
    {
        "x": 0.32,
        "y": 0
    },
    {
        "x": 0.33,
        "y": 0
    },
    {
        "x": 0.34,
        "y": 0
    },
    {
        "x": 0.35,
        "y": 0
    },
    {
        "x": 0.36,
        "y": 0
    },
    {
        "x": 0.37,
        "y": 0
    },
    {
        "x": 0.38,
        "y": 0
    },
    {
        "x": 0.39,
        "y": 0
    },
    {
        "x": 0.4,
        "y": 0
    },
    {
        "x": 0.41,
        "y": 0
    },
    {
        "x": 0.42,
        "y": 0
    },
    {
        "x": 0.43,
        "y": 0
    },
    {
        "x": 0.44,
        "y": 0
    },
    {
        "x": 0.45,
        "y": 0
    },
    {
        "x": 0.46,
        "y": 0
    },
    {
        "x": 0.47,
        "y": 0
    },
    {
        "x": 0.48,
        "y": 0
    },
    {
        "x": 0.49,
        "y": 0
    },
    {
        "x": 0.5,
        "y": 0
    },
    {
        "x": 0.51,
        "y": 0
    },
    {
        "x": 0.52,
        "y": 0
    },
    {
        "x": 0.53,
        "y": 0
    },
    {
        "x": 0.54,
        "y": 0
    },
    {
        "x": 0.55,
        "y": 0
    },
    {
        "x": 0.56,
        "y": 0.000420345
    },
    {
        "x": 0.57,
        "y": 0.000420345
    },
    {
        "x": 0.58,
        "y": 0.000420345
    },
    {
        "x": 0.59,
        "y": 0.000420345
    },
    {
        "x": 0.6,
        "y": 0.000420345
    },
    {
        "x": 0.61,
        "y": 0.000420345
    },
    {
        "x": 0.62,
        "y": 0.000420345
    },
    {
        "x": 0.63,
        "y": 0.000420345
    },
    {
        "x": 0.64,
        "y": 0.000420345
    },
    {
        "x": 0.65,
        "y": 0.000420345
    },
    {
        "x": 0.66,
        "y": 0.000420345
    },
    {
        "x": 0.67,
        "y": 0.000420345
    },
    {
        "x": 0.68,
        "y": 0.000420345
    },
    {
        "x": 0.69,
        "y": 0.000840689
    },
    {
        "x": 0.7,
        "y": 0.000420345
    },
    {
        "x": 0.71,
        "y": 0.000840689
    },
    {
        "x": 0.72,
        "y": 0.000420345
    },
    {
        "x": 0.73,
        "y": 0.000420345
    },
    {
        "x": 0.74,
        "y": 0.000420345
    },
    {
        "x": 0.75,
        "y": 0.000840689
    },
    {
        "x": 0.76,
        "y": 0.000840689
    },
    {
        "x": 0.77,
        "y": 0.000840689
    },
    {
        "x": 0.78,
        "y": 0.000840689
    },
    {
        "x": 0.79,
        "y": 0.000840689
    },
    {
        "x": 0.8,
        "y": 0.000840689
    },
    {
        "x": 0.81,
        "y": 0.000840689
    },
    {
        "x": 0.82,
        "y": 0.000840689
    },
    {
        "x": 0.83,
        "y": 0.000840689
    },
    {
        "x": 0.84,
        "y": 0.000840689
    },
    {
        "x": 0.85,
        "y": 0.000840689
    },
    {
        "x": 0.86,
        "y": 0.000840689
    },
    {
        "x": 0.87,
        "y": 0.000840689
    },
    {
        "x": 0.88,
        "y": 0.000840689
    },
    {
        "x": 0.89,
        "y": 0.000840689
    },
    {
        "x": 0.9,
        "y": 0.000840689
    },
    {
        "x": 0.91,
        "y": 0.000840689
    },
    {
        "x": 0.92,
        "y": 0.000840689
    },
    {
        "x": 0.93,
        "y": 0.000840689
    },
    {
        "x": 0.94,
        "y": 0.000840689
    },
    {
        "x": 0.95,
        "y": 0.000840689
    },
    {
        "x": 0.96,
        "y": 0.000840689
    },
    {
        "x": 0.97,
        "y": 0.000840689
    },
    {
        "x": 0.98,
        "y": 0.000840689
    },
    {
        "x": 0.99,
        "y": 0.000840689
    },
    {
        "x": 1,
        "y": 0.000840689
    },
    {
        "x": 1.01,
        "y": 0.000840689
    },
    {
        "x": 1.02,
        "y": 0.000840689
    },
    {
        "x": 1.03,
        "y": 0.000840689
    },
    {
        "x": 1.04,
        "y": 0.000840689
    },
    {
        "x": 1.05,
        "y": 0.000840689
    },
    {
        "x": 1.06,
        "y": 0.000840689
    },
    {
        "x": 1.07,
        "y": 0.001261034
    },
    {
        "x": 1.08,
        "y": 0.000840689
    },
    {
        "x": 1.09,
        "y": 0.001261034
    },
    {
        "x": 1.1,
        "y": 0.001261034
    },
    {
        "x": 1.11,
        "y": 0.000840689
    },
    {
        "x": 1.12,
        "y": 0.000840689
    },
    {
        "x": 1.13,
        "y": 0.001261034
    },
    {
        "x": 1.14,
        "y": 0.001261034
    },
    {
        "x": 1.15,
        "y": 0.001261034
    },
    {
        "x": 1.16,
        "y": 0.001261034
    },
    {
        "x": 1.17,
        "y": 0.001261034
    },
    {
        "x": 1.18,
        "y": 0.001261034
    },
    {
        "x": 1.19,
        "y": 0.001261034
    },
    {
        "x": 1.2,
        "y": 0.001261034
    },
    {
        "x": 1.21,
        "y": 0.001261034
    },
    {
        "x": 1.22,
        "y": 0.001261034
    },
    {
        "x": 1.23,
        "y": 0.001261034
    },
    {
        "x": 1.24,
        "y": 0.001261034
    },
    {
        "x": 1.25,
        "y": 0.001261034
    },
    {
        "x": 1.26,
        "y": 0.001261034
    },
    {
        "x": 1.27,
        "y": 0.001261034
    },
    {
        "x": 1.28,
        "y": 0.001261034
    },
    {
        "x": 1.29,
        "y": 0.001261034
    },
    {
        "x": 1.3,
        "y": 0.001261034
    },
    {
        "x": 1.31,
        "y": 0.001261034
    },
    {
        "x": 1.32,
        "y": 0.001261034
    },
    {
        "x": 1.33,
        "y": 0.001261034
    },
    {
        "x": 1.34,
        "y": 0.001261034
    },
    {
        "x": 1.35,
        "y": 0.001261034
    },
    {
        "x": 1.36,
        "y": 0.001261034
    },
    {
        "x": 1.37,
        "y": 0.001261034
    },
    {
        "x": 1.38,
        "y": 0.001261034
    },
    {
        "x": 1.39,
        "y": 0.001261034
    },
    {
        "x": 1.4,
        "y": 0.001681379
    },
    {
        "x": 1.41,
        "y": 0.001261034
    },
    {
        "x": 1.42,
        "y": 0.001261034
    },
    {
        "x": 1.43,
        "y": 0.001681379
    },
    {
        "x": 1.44,
        "y": 0.001261034
    },
    {
        "x": 1.45,
        "y": 0.001681379
    },
    {
        "x": 1.46,
        "y": 0.001681379
    },
    {
        "x": 1.47,
        "y": 0.001681379
    },
    {
        "x": 1.48,
        "y": 0.001681379
    },
    {
        "x": 1.49,
        "y": 0.001681379
    },
    {
        "x": 1.5,
        "y": 0.001681379
    },
    {
        "x": 1.51,
        "y": 0.001261034
    },
    {
        "x": 1.52,
        "y": 0.001681379
    },
    {
        "x": 1.53,
        "y": 0.001681379
    },
    {
        "x": 1.54,
        "y": 0.001681379
    },
    {
        "x": 1.55,
        "y": 0.001261034
    },
    {
        "x": 1.56,
        "y": 0.001681379
    },
    {
        "x": 1.57,
        "y": 0.001681379
    },
    {
        "x": 1.58,
        "y": 0.001681379
    },
    {
        "x": 1.59,
        "y": 0.001681379
    },
    {
        "x": 1.6,
        "y": 0.001681379
    },
    {
        "x": 1.61,
        "y": 0.001681379
    },
    {
        "x": 1.62,
        "y": 0.001681379
    },
    {
        "x": 1.63,
        "y": 0.001681379
    },
    {
        "x": 1.64,
        "y": 0.001681379
    },
    {
        "x": 1.65,
        "y": 0.001681379
    },
    {
        "x": 1.66,
        "y": 0.001681379
    },
    {
        "x": 1.67,
        "y": 0.001681379
    },
    {
        "x": 1.68,
        "y": 0.001681379
    },
    {
        "x": 1.69,
        "y": 0.001681379
    },
    {
        "x": 1.7,
        "y": 0.001681379
    },
    {
        "x": 1.71,
        "y": 0.001681379
    },
    {
        "x": 1.72,
        "y": 0.002101723
    },
    {
        "x": 1.73,
        "y": 0.001681379
    },
    {
        "x": 1.74,
        "y": 0.001681379
    },
    {
        "x": 1.75,
        "y": 0.001681379
    },
    {
        "x": 1.76,
        "y": 0.002101723
    },
    {
        "x": 1.77,
        "y": 0.002101723
    },
    {
        "x": 1.78,
        "y": 0.002101723
    },
    {
        "x": 1.79,
        "y": 0.002101723
    },
    {
        "x": 1.8,
        "y": 0.001681379
    },
    {
        "x": 1.81,
        "y": 0.002101723
    },
    {
        "x": 1.82,
        "y": 0.002101723
    },
    {
        "x": 1.83,
        "y": 0.002101723
    },
    {
        "x": 1.84,
        "y": 0.002101723
    },
    {
        "x": 1.85,
        "y": 0.002101723
    },
    {
        "x": 1.86,
        "y": 0.002101723
    },
    {
        "x": 1.87,
        "y": 0.002101723
    },
    {
        "x": 1.88,
        "y": 0.002101723
    },
    {
        "x": 1.89,
        "y": 0.002101723
    },
    {
        "x": 1.9,
        "y": 0.002101723
    },
    {
        "x": 1.91,
        "y": 0.002101723
    },
    {
        "x": 1.92,
        "y": 0.002101723
    },
    {
        "x": 1.93,
        "y": 0.002101723
    },
    {
        "x": 1.94,
        "y": 0.002101723
    },
    {
        "x": 1.95,
        "y": 0.002101723
    },
    {
        "x": 1.96,
        "y": 0.002101723
    },
    {
        "x": 1.97,
        "y": 0.002522068
    },
    {
        "x": 1.98,
        "y": 0.002522068
    },
    {
        "x": 1.99,
        "y": 0.002522068
    },
    {
        "x": 2,
        "y": 0.002522068
    },
    {
        "x": 2.01,
        "y": 0.002522068
    },
    {
        "x": 2.02,
        "y": 0.002522068
    },
    {
        "x": 2.03,
        "y": 0.002522068
    },
    {
        "x": 2.04,
        "y": 0.002522068
    },
    {
        "x": 2.05,
        "y": 0.002522068
    },
    {
        "x": 2.06,
        "y": 0.002522068
    },
    {
        "x": 2.07,
        "y": 0.002522068
    },
    {
        "x": 2.08,
        "y": 0.002522068
    },
    {
        "x": 2.09,
        "y": 0.002522068
    },
    {
        "x": 2.1,
        "y": 0.002522068
    },
    {
        "x": 2.11,
        "y": 0.002522068
    },
    {
        "x": 2.12,
        "y": 0.002522068
    },
    {
        "x": 2.13,
        "y": 0.002942413
    },
    {
        "x": 2.14,
        "y": 0.002942413
    },
    {
        "x": 2.15,
        "y": 0.002942413
    },
    {
        "x": 2.16,
        "y": 0.002522068
    },
    {
        "x": 2.17,
        "y": 0.002942413
    },
    {
        "x": 2.18,
        "y": 0.002942413
    },
    {
        "x": 2.19,
        "y": 0.002942413
    },
    {
        "x": 2.2,
        "y": 0.002942413
    },
    {
        "x": 2.21,
        "y": 0.002942413
    },
    {
        "x": 2.22,
        "y": 0.002942413
    },
    {
        "x": 2.23,
        "y": 0.002942413
    },
    {
        "x": 2.24,
        "y": 0.002942413
    },
    {
        "x": 2.25,
        "y": 0.002942413
    },
    {
        "x": 2.26,
        "y": 0.002942413
    },
    {
        "x": 2.27,
        "y": 0.002942413
    },
    {
        "x": 2.28,
        "y": 0.002942413
    },
    {
        "x": 2.29,
        "y": 0.003362757
    },
    {
        "x": 2.3,
        "y": 0.002942413
    },
    {
        "x": 2.31,
        "y": 0.002942413
    },
    {
        "x": 2.32,
        "y": 0.002942413
    },
    {
        "x": 2.33,
        "y": 0.003362757
    },
    {
        "x": 2.34,
        "y": 0.003362757
    },
    {
        "x": 2.35,
        "y": 0.003362757
    },
    {
        "x": 2.36,
        "y": 0.003362757
    },
    {
        "x": 2.37,
        "y": 0.003362757
    },
    {
        "x": 2.38,
        "y": 0.003362757
    },
    {
        "x": 2.39,
        "y": 0.003362757
    },
    {
        "x": 2.4,
        "y": 0.003362757
    },
    {
        "x": 2.41,
        "y": 0.003362757
    },
    {
        "x": 2.42,
        "y": 0.003362757
    },
    {
        "x": 2.43,
        "y": 0.003362757
    },
    {
        "x": 2.44,
        "y": 0.003783102
    },
    {
        "x": 2.45,
        "y": 0.003783102
    },
    {
        "x": 2.46,
        "y": 0.003783102
    },
    {
        "x": 2.47,
        "y": 0.003783102
    },
    {
        "x": 2.48,
        "y": 0.003783102
    },
    {
        "x": 2.49,
        "y": 0.003783102
    },
    {
        "x": 2.5,
        "y": 0.003783102
    },
    {
        "x": 2.51,
        "y": 0.003783102
    },
    {
        "x": 2.52,
        "y": 0.003783102
    },
    {
        "x": 2.53,
        "y": 0.003783102
    },
    {
        "x": 2.54,
        "y": 0.003783102
    },
    {
        "x": 2.55,
        "y": 0.003783102
    },
    {
        "x": 2.56,
        "y": 0.003783102
    },
    {
        "x": 2.57,
        "y": 0.004203447
    },
    {
        "x": 2.58,
        "y": 0.004203447
    },
    {
        "x": 2.59,
        "y": 0.004203447
    },
    {
        "x": 2.6,
        "y": 0.004203447
    },
    {
        "x": 2.61,
        "y": 0.004203447
    },
    {
        "x": 2.62,
        "y": 0.004203447
    },
    {
        "x": 2.63,
        "y": 0.004203447
    },
    {
        "x": 2.64,
        "y": 0.004203447
    },
    {
        "x": 2.65,
        "y": 0.004203447
    },
    {
        "x": 2.66,
        "y": 0.004203447
    },
    {
        "x": 2.67,
        "y": 0.004623792
    },
    {
        "x": 2.68,
        "y": 0.004203447
    },
    {
        "x": 2.69,
        "y": 0.004623792
    },
    {
        "x": 2.7,
        "y": 0.004623792
    },
    {
        "x": 2.71,
        "y": 0.004623792
    },
    {
        "x": 2.72,
        "y": 0.004623792
    },
    {
        "x": 2.73,
        "y": 0.004623792
    },
    {
        "x": 2.74,
        "y": 0.004623792
    },
    {
        "x": 2.75,
        "y": 0.004623792
    },
    {
        "x": 2.76,
        "y": 0.004623792
    },
    {
        "x": 2.77,
        "y": 0.004623792
    },
    {
        "x": 2.78,
        "y": 0.004623792
    },
    {
        "x": 2.79,
        "y": 0.005044136
    },
    {
        "x": 2.8,
        "y": 0.005044136
    },
    {
        "x": 2.81,
        "y": 0.005044136
    },
    {
        "x": 2.82,
        "y": 0.005044136
    },
    {
        "x": 2.83,
        "y": 0.005044136
    },
    {
        "x": 2.84,
        "y": 0.005044136
    },
    {
        "x": 2.85,
        "y": 0.005464481
    },
    {
        "x": 2.86,
        "y": 0.005044136
    },
    {
        "x": 2.87,
        "y": 0.005464481
    },
    {
        "x": 2.88,
        "y": 0.005464481
    },
    {
        "x": 2.89,
        "y": 0.005464481
    },
    {
        "x": 2.9,
        "y": 0.005464481
    },
    {
        "x": 2.91,
        "y": 0.005464481
    },
    {
        "x": 2.92,
        "y": 0.005464481
    },
    {
        "x": 2.93,
        "y": 0.005464481
    },
    {
        "x": 2.94,
        "y": 0.005464481
    },
    {
        "x": 2.95,
        "y": 0.005464481
    },
    {
        "x": 2.96,
        "y": 0.005884826
    },
    {
        "x": 2.97,
        "y": 0.005884826
    },
    {
        "x": 2.98,
        "y": 0.005884826
    },
    {
        "x": 2.99,
        "y": 0.005884826
    },
    {
        "x": 3,
        "y": 0.005884826
    },
    {
        "x": 3.01,
        "y": 0.005884826
    },
    {
        "x": 3.02,
        "y": 0.00630517
    },
    {
        "x": 3.03,
        "y": 0.00630517
    },
    {
        "x": 3.04,
        "y": 0.00630517
    },
    {
        "x": 3.05,
        "y": 0.00630517
    },
    {
        "x": 3.06,
        "y": 0.00630517
    },
    {
        "x": 3.07,
        "y": 0.00630517
    },
    {
        "x": 3.08,
        "y": 0.00630517
    },
    {
        "x": 3.09,
        "y": 0.00630517
    },
    {
        "x": 3.1,
        "y": 0.00630517
    },
    {
        "x": 3.11,
        "y": 0.006725515
    },
    {
        "x": 3.12,
        "y": 0.006725515
    },
    {
        "x": 3.13,
        "y": 0.006725515
    },
    {
        "x": 3.14,
        "y": 0.006725515
    },
    {
        "x": 3.15,
        "y": 0.006725515
    },
    {
        "x": 3.16,
        "y": 0.006725515
    },
    {
        "x": 3.17,
        "y": 0.006725515
    },
    {
        "x": 3.18,
        "y": 0.00714586
    },
    {
        "x": 3.19,
        "y": 0.00714586
    },
    {
        "x": 3.2,
        "y": 0.00714586
    },
    {
        "x": 3.21,
        "y": 0.00714586
    },
    {
        "x": 3.22,
        "y": 0.00714586
    },
    {
        "x": 3.23,
        "y": 0.00714586
    },
    {
        "x": 3.24,
        "y": 0.007566204
    },
    {
        "x": 3.25,
        "y": 0.007566204
    },
    {
        "x": 3.26,
        "y": 0.007566204
    },
    {
        "x": 3.27,
        "y": 0.007566204
    },
    {
        "x": 3.28,
        "y": 0.007566204
    },
    {
        "x": 3.29,
        "y": 0.007566204
    },
    {
        "x": 3.3,
        "y": 0.007566204
    },
    {
        "x": 3.31,
        "y": 0.007566204
    },
    {
        "x": 3.32,
        "y": 0.007986549
    },
    {
        "x": 3.33,
        "y": 0.007986549
    },
    {
        "x": 3.34,
        "y": 0.007986549
    },
    {
        "x": 3.35,
        "y": 0.007986549
    },
    {
        "x": 3.36,
        "y": 0.007986549
    },
    {
        "x": 3.37,
        "y": 0.007986549
    },
    {
        "x": 3.38,
        "y": 0.008406894
    },
    {
        "x": 3.39,
        "y": 0.008406894
    },
    {
        "x": 3.4,
        "y": 0.007986549
    },
    {
        "x": 3.41,
        "y": 0.008406894
    },
    {
        "x": 3.42,
        "y": 0.008406894
    },
    {
        "x": 3.43,
        "y": 0.008406894
    },
    {
        "x": 3.44,
        "y": 0.008827238
    },
    {
        "x": 3.45,
        "y": 0.008827238
    },
    {
        "x": 3.46,
        "y": 0.008827238
    },
    {
        "x": 3.47,
        "y": 0.008827238
    },
    {
        "x": 3.48,
        "y": 0.008827238
    },
    {
        "x": 3.49,
        "y": 0.008827238
    },
    {
        "x": 3.5,
        "y": 0.009247583
    },
    {
        "x": 3.51,
        "y": 0.009247583
    },
    {
        "x": 3.52,
        "y": 0.009247583
    },
    {
        "x": 3.53,
        "y": 0.009247583
    },
    {
        "x": 3.54,
        "y": 0.009247583
    },
    {
        "x": 3.55,
        "y": 0.009667928
    },
    {
        "x": 3.56,
        "y": 0.009667928
    },
    {
        "x": 3.57,
        "y": 0.009667928
    },
    {
        "x": 3.58,
        "y": 0.009667928
    },
    {
        "x": 3.59,
        "y": 0.009667928
    },
    {
        "x": 3.6,
        "y": 0.009667928
    },
    {
        "x": 3.61,
        "y": 0.009667928
    },
    {
        "x": 3.62,
        "y": 0.010088272
    },
    {
        "x": 3.63,
        "y": 0.010088272
    },
    {
        "x": 3.64,
        "y": 0.010088272
    },
    {
        "x": 3.65,
        "y": 0.010088272
    },
    {
        "x": 3.66,
        "y": 0.010088272
    },
    {
        "x": 3.67,
        "y": 0.010508617
    },
    {
        "x": 3.68,
        "y": 0.010508617
    },
    {
        "x": 3.69,
        "y": 0.010508617
    },
    {
        "x": 3.7,
        "y": 0.010508617
    },
    {
        "x": 3.71,
        "y": 0.010508617
    },
    {
        "x": 3.72,
        "y": 0.010928962
    },
    {
        "x": 3.73,
        "y": 0.010928962
    },
    {
        "x": 3.74,
        "y": 0.010928962
    },
    {
        "x": 3.75,
        "y": 0.011349306
    },
    {
        "x": 3.76,
        "y": 0.011349306
    },
    {
        "x": 3.77,
        "y": 0.011349306
    },
    {
        "x": 3.78,
        "y": 0.011349306
    },
    {
        "x": 3.79,
        "y": 0.011349306
    },
    {
        "x": 3.8,
        "y": 0.011769651
    },
    {
        "x": 3.81,
        "y": 0.011769651
    },
    {
        "x": 3.82,
        "y": 0.011769651
    },
    {
        "x": 3.83,
        "y": 0.011769651
    },
    {
        "x": 3.84,
        "y": 0.012189996
    },
    {
        "x": 3.85,
        "y": 0.012189996
    },
    {
        "x": 3.86,
        "y": 0.012189996
    },
    {
        "x": 3.87,
        "y": 0.01261034
    },
    {
        "x": 3.88,
        "y": 0.01261034
    },
    {
        "x": 3.89,
        "y": 0.01261034
    },
    {
        "x": 3.9,
        "y": 0.01261034
    },
    {
        "x": 3.91,
        "y": 0.01261034
    },
    {
        "x": 3.92,
        "y": 0.013030685
    },
    {
        "x": 3.93,
        "y": 0.013030685
    },
    {
        "x": 3.94,
        "y": 0.013030685
    },
    {
        "x": 3.95,
        "y": 0.01345103
    },
    {
        "x": 3.96,
        "y": 0.01345103
    },
    {
        "x": 3.97,
        "y": 0.01345103
    },
    {
        "x": 3.98,
        "y": 0.01345103
    },
    {
        "x": 3.99,
        "y": 0.013871375
    },
    {
        "x": 4,
        "y": 0.013871375
    },
    {
        "x": 4.01,
        "y": 0.013871375
    },
    {
        "x": 4.02,
        "y": 0.013871375
    },
    {
        "x": 4.03,
        "y": 0.014291719
    },
    {
        "x": 4.04,
        "y": 0.014291719
    },
    {
        "x": 4.05,
        "y": 0.014291719
    },
    {
        "x": 4.06,
        "y": 0.014291719
    },
    {
        "x": 4.07,
        "y": 0.014712064
    },
    {
        "x": 4.08,
        "y": 0.014712064
    },
    {
        "x": 4.09,
        "y": 0.014712064
    },
    {
        "x": 4.1,
        "y": 0.014712064
    },
    {
        "x": 4.11,
        "y": 0.015132409
    },
    {
        "x": 4.12,
        "y": 0.015132409
    },
    {
        "x": 4.13,
        "y": 0.015132409
    },
    {
        "x": 4.14,
        "y": 0.015132409
    },
    {
        "x": 4.15,
        "y": 0.015552753
    },
    {
        "x": 4.16,
        "y": 0.015552753
    },
    {
        "x": 4.17,
        "y": 0.015552753
    },
    {
        "x": 4.18,
        "y": 0.015973098
    },
    {
        "x": 4.19,
        "y": 0.015973098
    },
    {
        "x": 4.2,
        "y": 0.015973098
    },
    {
        "x": 4.21,
        "y": 0.016393443
    },
    {
        "x": 4.22,
        "y": 0.016393443
    },
    {
        "x": 4.23,
        "y": 0.016813787
    },
    {
        "x": 4.24,
        "y": 0.016813787
    },
    {
        "x": 4.25,
        "y": 0.016813787
    },
    {
        "x": 4.26,
        "y": 0.016813787
    },
    {
        "x": 4.27,
        "y": 0.016813787
    },
    {
        "x": 4.28,
        "y": 0.017234132
    },
    {
        "x": 4.29,
        "y": 0.017234132
    },
    {
        "x": 4.3,
        "y": 0.017654477
    },
    {
        "x": 4.31,
        "y": 0.017654477
    },
    {
        "x": 4.32,
        "y": 0.017654477
    },
    {
        "x": 4.33,
        "y": 0.018074821
    },
    {
        "x": 4.34,
        "y": 0.018074821
    },
    {
        "x": 4.35,
        "y": 0.018074821
    },
    {
        "x": 4.36,
        "y": 0.018074821
    },
    {
        "x": 4.37,
        "y": 0.018495166
    },
    {
        "x": 4.38,
        "y": 0.018495166
    },
    {
        "x": 4.39,
        "y": 0.018495166
    },
    {
        "x": 4.4,
        "y": 0.018915511
    },
    {
        "x": 4.41,
        "y": 0.018915511
    },
    {
        "x": 4.42,
        "y": 0.018915511
    },
    {
        "x": 4.43,
        "y": 0.019335855
    },
    {
        "x": 4.44,
        "y": 0.019335855
    },
    {
        "x": 4.45,
        "y": 0.019335855
    },
    {
        "x": 4.46,
        "y": 0.0197562
    },
    {
        "x": 4.47,
        "y": 0.0197562
    },
    {
        "x": 4.48,
        "y": 0.020176545
    },
    {
        "x": 4.49,
        "y": 0.020176545
    },
    {
        "x": 4.5,
        "y": 0.020176545
    },
    {
        "x": 4.51,
        "y": 0.020596889
    },
    {
        "x": 4.52,
        "y": 0.020596889
    },
    {
        "x": 4.53,
        "y": 0.021017234
    },
    {
        "x": 4.54,
        "y": 0.021017234
    },
    {
        "x": 4.55,
        "y": 0.021017234
    },
    {
        "x": 4.56,
        "y": 0.021017234
    },
    {
        "x": 4.57,
        "y": 0.021437579
    },
    {
        "x": 4.58,
        "y": 0.021437579
    },
    {
        "x": 4.59,
        "y": 0.021857923
    },
    {
        "x": 4.6,
        "y": 0.021857923
    },
    {
        "x": 4.61,
        "y": 0.021857923
    },
    {
        "x": 4.62,
        "y": 0.022278268
    },
    {
        "x": 4.63,
        "y": 0.022278268
    },
    {
        "x": 4.64,
        "y": 0.022278268
    },
    {
        "x": 4.65,
        "y": 0.022698613
    },
    {
        "x": 4.66,
        "y": 0.023118958
    },
    {
        "x": 4.67,
        "y": 0.023118958
    },
    {
        "x": 4.68,
        "y": 0.023118958
    },
    {
        "x": 4.69,
        "y": 0.023539302
    },
    {
        "x": 4.7,
        "y": 0.023959647
    },
    {
        "x": 4.71,
        "y": 0.023959647
    },
    {
        "x": 4.72,
        "y": 0.023959647
    },
    {
        "x": 4.73,
        "y": 0.024379992
    },
    {
        "x": 4.74,
        "y": 0.024379992
    },
    {
        "x": 4.75,
        "y": 0.024379992
    },
    {
        "x": 4.76,
        "y": 0.024800336
    },
    {
        "x": 4.77,
        "y": 0.024800336
    },
    {
        "x": 4.78,
        "y": 0.025220681
    },
    {
        "x": 4.79,
        "y": 0.025220681
    },
    {
        "x": 4.8,
        "y": 0.025220681
    },
    {
        "x": 4.81,
        "y": 0.025641026
    },
    {
        "x": 4.82,
        "y": 0.02606137
    },
    {
        "x": 4.83,
        "y": 0.02606137
    },
    {
        "x": 4.84,
        "y": 0.026481715
    },
    {
        "x": 4.85,
        "y": 0.026481715
    },
    {
        "x": 4.86,
        "y": 0.026481715
    },
    {
        "x": 4.87,
        "y": 0.02690206
    },
    {
        "x": 4.88,
        "y": 0.02690206
    },
    {
        "x": 4.89,
        "y": 0.027322404
    },
    {
        "x": 4.9,
        "y": 0.027742749
    },
    {
        "x": 4.91,
        "y": 0.027742749
    },
    {
        "x": 4.92,
        "y": 0.027742749
    },
    {
        "x": 4.93,
        "y": 0.028163094
    },
    {
        "x": 4.94,
        "y": 0.028583438
    },
    {
        "x": 4.95,
        "y": 0.028583438
    },
    {
        "x": 4.96,
        "y": 0.029003783
    },
    {
        "x": 4.97,
        "y": 0.029003783
    },
    {
        "x": 4.98,
        "y": 0.029003783
    },
    {
        "x": 4.99,
        "y": 0.029424128
    },
    {
        "x": 5,
        "y": 0.029844472
    },
    {
        "x": 5.01,
        "y": 0.029844472
    },
    {
        "x": 5.02,
        "y": 0.030264817
    },
    {
        "x": 5.03,
        "y": 0.030264817
    },
    {
        "x": 5.04,
        "y": 0.030685162
    },
    {
        "x": 5.05,
        "y": 0.030685162
    },
    {
        "x": 5.06,
        "y": 0.031105507
    },
    {
        "x": 5.07,
        "y": 0.031525851
    },
    {
        "x": 5.08,
        "y": 0.031525851
    },
    {
        "x": 5.09,
        "y": 0.031946196
    },
    {
        "x": 5.1,
        "y": 0.031946196
    },
    {
        "x": 5.11,
        "y": 0.032366541
    },
    {
        "x": 5.12,
        "y": 0.032366541
    },
    {
        "x": 5.13,
        "y": 0.032786885
    },
    {
        "x": 5.14,
        "y": 0.032786885
    },
    {
        "x": 5.15,
        "y": 0.03320723
    },
    {
        "x": 5.16,
        "y": 0.03320723
    },
    {
        "x": 5.17,
        "y": 0.033627575
    },
    {
        "x": 5.18,
        "y": 0.034047919
    },
    {
        "x": 5.19,
        "y": 0.034468264
    },
    {
        "x": 5.2,
        "y": 0.034468264
    },
    {
        "x": 5.21,
        "y": 0.034888609
    },
    {
        "x": 5.22,
        "y": 0.034888609
    },
    {
        "x": 5.23,
        "y": 0.035308953
    },
    {
        "x": 5.24,
        "y": 0.035308953
    },
    {
        "x": 5.25,
        "y": 0.035729298
    },
    {
        "x": 5.26,
        "y": 0.036149643
    },
    {
        "x": 5.27,
        "y": 0.036149643
    },
    {
        "x": 5.28,
        "y": 0.036569987
    },
    {
        "x": 5.29,
        "y": 0.036990332
    },
    {
        "x": 5.3,
        "y": 0.036990332
    },
    {
        "x": 5.31,
        "y": 0.037410677
    },
    {
        "x": 5.32,
        "y": 0.037831021
    },
    {
        "x": 5.33,
        "y": 0.037831021
    },
    {
        "x": 5.34,
        "y": 0.038251366
    },
    {
        "x": 5.35,
        "y": 0.038671711
    },
    {
        "x": 5.36,
        "y": 0.038671711
    },
    {
        "x": 5.37,
        "y": 0.039092055
    },
    {
        "x": 5.38,
        "y": 0.039092055
    },
    {
        "x": 5.39,
        "y": 0.0395124
    },
    {
        "x": 5.4,
        "y": 0.039932745
    },
    {
        "x": 5.41,
        "y": 0.04035309
    },
    {
        "x": 5.42,
        "y": 0.040773434
    },
    {
        "x": 5.43,
        "y": 0.040773434
    },
    {
        "x": 5.44,
        "y": 0.041193779
    },
    {
        "x": 5.45,
        "y": 0.041614124
    },
    {
        "x": 5.46,
        "y": 0.041614124
    },
    {
        "x": 5.47,
        "y": 0.042034468
    },
    {
        "x": 5.48,
        "y": 0.042454813
    },
    {
        "x": 5.49,
        "y": 0.042875158
    },
    {
        "x": 5.5,
        "y": 0.042875158
    },
    {
        "x": 5.51,
        "y": 0.043295502
    },
    {
        "x": 5.52,
        "y": 0.043715847
    },
    {
        "x": 5.53,
        "y": 0.044136192
    },
    {
        "x": 5.54,
        "y": 0.044136192
    },
    {
        "x": 5.55,
        "y": 0.044556536
    },
    {
        "x": 5.56,
        "y": 0.044976881
    },
    {
        "x": 5.57,
        "y": 0.045397226
    },
    {
        "x": 5.58,
        "y": 0.045397226
    },
    {
        "x": 5.59,
        "y": 0.04581757
    },
    {
        "x": 5.6,
        "y": 0.046237915
    },
    {
        "x": 5.61,
        "y": 0.04665826
    },
    {
        "x": 5.62,
        "y": 0.04665826
    },
    {
        "x": 5.63,
        "y": 0.047078604
    },
    {
        "x": 5.64,
        "y": 0.047498949
    },
    {
        "x": 5.65,
        "y": 0.047919294
    },
    {
        "x": 5.66,
        "y": 0.048339639
    },
    {
        "x": 5.67,
        "y": 0.048339639
    },
    {
        "x": 5.68,
        "y": 0.048759983
    },
    {
        "x": 5.69,
        "y": 0.049180328
    },
    {
        "x": 5.7,
        "y": 0.049600673
    },
    {
        "x": 5.71,
        "y": 0.050021017
    },
    {
        "x": 5.72,
        "y": 0.050441362
    },
    {
        "x": 5.73,
        "y": 0.050861707
    },
    {
        "x": 5.74,
        "y": 0.051282051
    },
    {
        "x": 5.75,
        "y": 0.051282051
    },
    {
        "x": 5.76,
        "y": 0.051702396
    },
    {
        "x": 5.77,
        "y": 0.052122741
    },
    {
        "x": 5.78,
        "y": 0.052543085
    },
    {
        "x": 5.79,
        "y": 0.05296343
    },
    {
        "x": 5.8,
        "y": 0.053383775
    },
    {
        "x": 5.81,
        "y": 0.053804119
    },
    {
        "x": 5.82,
        "y": 0.054224464
    },
    {
        "x": 5.83,
        "y": 0.054644809
    },
    {
        "x": 5.84,
        "y": 0.054644809
    },
    {
        "x": 5.85,
        "y": 0.055485498
    },
    {
        "x": 5.86,
        "y": 0.055485498
    },
    {
        "x": 5.87,
        "y": 0.055905843
    },
    {
        "x": 5.88,
        "y": 0.056326187
    },
    {
        "x": 5.89,
        "y": 0.056746532
    },
    {
        "x": 5.9,
        "y": 0.057166877
    },
    {
        "x": 5.91,
        "y": 0.057587222
    },
    {
        "x": 5.92,
        "y": 0.058007566
    },
    {
        "x": 5.93,
        "y": 0.058427911
    },
    {
        "x": 5.94,
        "y": 0.058848256
    },
    {
        "x": 5.95,
        "y": 0.0592686
    },
    {
        "x": 5.96,
        "y": 0.059688945
    },
    {
        "x": 5.97,
        "y": 0.06010929
    },
    {
        "x": 5.98,
        "y": 0.060529634
    },
    {
        "x": 5.99,
        "y": 0.060949979
    },
    {
        "x": 6,
        "y": 0.061370324
    },
    {
        "x": 6.01,
        "y": 0.061790668
    },
    {
        "x": 6.02,
        "y": 0.062211013
    },
    {
        "x": 6.03,
        "y": 0.062631358
    },
    {
        "x": 6.04,
        "y": 0.063051702
    },
    {
        "x": 6.05,
        "y": 0.063472047
    },
    {
        "x": 6.06,
        "y": 0.063892392
    },
    {
        "x": 6.07,
        "y": 0.064312736
    },
    {
        "x": 6.08,
        "y": 0.064733081
    },
    {
        "x": 6.09,
        "y": 0.06557377
    },
    {
        "x": 6.1,
        "y": 0.065994115
    },
    {
        "x": 6.11,
        "y": 0.065994115
    },
    {
        "x": 6.12,
        "y": 0.066834805
    },
    {
        "x": 6.13,
        "y": 0.067255149
    },
    {
        "x": 6.14,
        "y": 0.067675494
    },
    {
        "x": 6.15,
        "y": 0.068095839
    },
    {
        "x": 6.16,
        "y": 0.068516183
    },
    {
        "x": 6.17,
        "y": 0.068936528
    },
    {
        "x": 6.18,
        "y": 0.069777217
    },
    {
        "x": 6.19,
        "y": 0.070197562
    },
    {
        "x": 6.2,
        "y": 0.070617907
    },
    {
        "x": 6.21,
        "y": 0.071038251
    },
    {
        "x": 6.22,
        "y": 0.071458596
    },
    {
        "x": 6.23,
        "y": 0.071878941
    },
    {
        "x": 6.24,
        "y": 0.072299285
    },
    {
        "x": 6.25,
        "y": 0.073139975
    },
    {
        "x": 6.26,
        "y": 0.073560319
    },
    {
        "x": 6.27,
        "y": 0.073980664
    },
    {
        "x": 6.28,
        "y": 0.074401009
    },
    {
        "x": 6.29,
        "y": 0.075241698
    },
    {
        "x": 6.3,
        "y": 0.075662043
    },
    {
        "x": 6.31,
        "y": 0.076082388
    },
    {
        "x": 6.32,
        "y": 0.076502732
    },
    {
        "x": 6.33,
        "y": 0.076923077
    },
    {
        "x": 6.34,
        "y": 0.077763766
    },
    {
        "x": 6.35,
        "y": 0.078184111
    },
    {
        "x": 6.36,
        "y": 0.0790248
    },
    {
        "x": 6.37,
        "y": 0.079445145
    },
    {
        "x": 6.38,
        "y": 0.07986549
    },
    {
        "x": 6.39,
        "y": 0.080285834
    },
    {
        "x": 6.4,
        "y": 0.081126524
    },
    {
        "x": 6.41,
        "y": 0.081546868
    },
    {
        "x": 6.42,
        "y": 0.081967213
    },
    {
        "x": 6.43,
        "y": 0.082807902
    },
    {
        "x": 6.44,
        "y": 0.083228247
    },
    {
        "x": 6.45,
        "y": 0.083648592
    },
    {
        "x": 6.46,
        "y": 0.084068937
    },
    {
        "x": 6.47,
        "y": 0.084909626
    },
    {
        "x": 6.48,
        "y": 0.085329971
    },
    {
        "x": 6.49,
        "y": 0.085750315
    },
    {
        "x": 6.5,
        "y": 0.086591005
    },
    {
        "x": 6.51,
        "y": 0.087011349
    },
    {
        "x": 6.52,
        "y": 0.087852039
    },
    {
        "x": 6.53,
        "y": 0.088272383
    },
    {
        "x": 6.54,
        "y": 0.089113073
    },
    {
        "x": 6.55,
        "y": 0.089533417
    },
    {
        "x": 6.56,
        "y": 0.089953762
    },
    {
        "x": 6.57,
        "y": 0.090794451
    },
    {
        "x": 6.58,
        "y": 0.091214796
    },
    {
        "x": 6.59,
        "y": 0.091635141
    },
    {
        "x": 6.6,
        "y": 0.09247583
    },
    {
        "x": 6.61,
        "y": 0.092896175
    },
    {
        "x": 6.62,
        "y": 0.093736864
    },
    {
        "x": 6.63,
        "y": 0.094577554
    },
    {
        "x": 6.64,
        "y": 0.094997898
    },
    {
        "x": 6.65,
        "y": 0.095418243
    },
    {
        "x": 6.66,
        "y": 0.096258932
    },
    {
        "x": 6.67,
        "y": 0.096679277
    },
    {
        "x": 6.68,
        "y": 0.097519966
    },
    {
        "x": 6.69,
        "y": 0.097940311
    },
    {
        "x": 6.7,
        "y": 0.098781
    },
    {
        "x": 6.71,
        "y": 0.09962169
    },
    {
        "x": 6.72,
        "y": 0.100042034
    },
    {
        "x": 6.73,
        "y": 0.100882724
    },
    {
        "x": 6.74,
        "y": 0.101303069
    },
    {
        "x": 6.75,
        "y": 0.102143758
    },
    {
        "x": 6.76,
        "y": 0.102984447
    },
    {
        "x": 6.77,
        "y": 0.103404792
    },
    {
        "x": 6.78,
        "y": 0.104245481
    },
    {
        "x": 6.79,
        "y": 0.104665826
    },
    {
        "x": 6.8,
        "y": 0.105506515
    },
    {
        "x": 6.81,
        "y": 0.106347205
    },
    {
        "x": 6.82,
        "y": 0.106767549
    },
    {
        "x": 6.83,
        "y": 0.107608239
    },
    {
        "x": 6.84,
        "y": 0.108028583
    },
    {
        "x": 6.85,
        "y": 0.108869273
    },
    {
        "x": 6.86,
        "y": 0.109709962
    },
    {
        "x": 6.87,
        "y": 0.110130307
    },
    {
        "x": 6.88,
        "y": 0.110970996
    },
    {
        "x": 6.89,
        "y": 0.111811686
    },
    {
        "x": 6.9,
        "y": 0.112652375
    },
    {
        "x": 6.91,
        "y": 0.11307272
    },
    {
        "x": 6.92,
        "y": 0.113913409
    },
    {
        "x": 6.93,
        "y": 0.114754098
    },
    {
        "x": 6.94,
        "y": 0.115174443
    },
    {
        "x": 6.95,
        "y": 0.116015132
    },
    {
        "x": 6.96,
        "y": 0.116435477
    },
    {
        "x": 6.97,
        "y": 0.117276166
    },
    {
        "x": 6.98,
        "y": 0.118116856
    },
    {
        "x": 6.99,
        "y": 0.118957545
    },
    {
        "x": 7,
        "y": 0.119798235
    },
    {
        "x": 7.01,
        "y": 0.120638924
    },
    {
        "x": 7.02,
        "y": 0.121059269
    },
    {
        "x": 7.03,
        "y": 0.121899958
    },
    {
        "x": 7.04,
        "y": 0.122740647
    },
    {
        "x": 7.05,
        "y": 0.123581337
    },
    {
        "x": 7.06,
        "y": 0.124422026
    },
    {
        "x": 7.07,
        "y": 0.125262715
    },
    {
        "x": 7.08,
        "y": 0.12568306
    },
    {
        "x": 7.09,
        "y": 0.126523749
    },
    {
        "x": 7.1,
        "y": 0.127364439
    },
    {
        "x": 7.11,
        "y": 0.128205128
    },
    {
        "x": 7.12,
        "y": 0.129045818
    },
    {
        "x": 7.13,
        "y": 0.129886507
    },
    {
        "x": 7.14,
        "y": 0.130306852
    },
    {
        "x": 7.15,
        "y": 0.131147541
    },
    {
        "x": 7.16,
        "y": 0.13198823
    },
    {
        "x": 7.17,
        "y": 0.13282892
    },
    {
        "x": 7.18,
        "y": 0.133669609
    },
    {
        "x": 7.19,
        "y": 0.134510298
    },
    {
        "x": 7.2,
        "y": 0.135350988
    },
    {
        "x": 7.21,
        "y": 0.136191677
    },
    {
        "x": 7.22,
        "y": 0.137032367
    },
    {
        "x": 7.23,
        "y": 0.137873056
    },
    {
        "x": 7.24,
        "y": 0.138713745
    },
    {
        "x": 7.25,
        "y": 0.139554435
    },
    {
        "x": 7.26,
        "y": 0.140395124
    },
    {
        "x": 7.27,
        "y": 0.141235813
    },
    {
        "x": 7.28,
        "y": 0.142076503
    },
    {
        "x": 7.29,
        "y": 0.142917192
    },
    {
        "x": 7.3,
        "y": 0.143757881
    },
    {
        "x": 7.31,
        "y": 0.144598571
    },
    {
        "x": 7.32,
        "y": 0.14543926
    },
    {
        "x": 7.33,
        "y": 0.14627995
    },
    {
        "x": 7.34,
        "y": 0.147120639
    },
    {
        "x": 7.35,
        "y": 0.147961328
    },
    {
        "x": 7.36,
        "y": 0.148802018
    },
    {
        "x": 7.37,
        "y": 0.150063052
    },
    {
        "x": 7.38,
        "y": 0.150903741
    },
    {
        "x": 7.39,
        "y": 0.15174443
    },
    {
        "x": 7.4,
        "y": 0.15258512
    },
    {
        "x": 7.41,
        "y": 0.153425809
    },
    {
        "x": 7.42,
        "y": 0.154266499
    },
    {
        "x": 7.43,
        "y": 0.155107188
    },
    {
        "x": 7.44,
        "y": 0.155947877
    },
    {
        "x": 7.45,
        "y": 0.156788567
    },
    {
        "x": 7.46,
        "y": 0.158049601
    },
    {
        "x": 7.47,
        "y": 0.15889029
    },
    {
        "x": 7.48,
        "y": 0.159730979
    },
    {
        "x": 7.49,
        "y": 0.160992013
    },
    {
        "x": 7.5,
        "y": 0.161412358
    },
    {
        "x": 7.51,
        "y": 0.162673392
    },
    {
        "x": 7.52,
        "y": 0.163514082
    },
    {
        "x": 7.53,
        "y": 0.164775116
    },
    {
        "x": 7.54,
        "y": 0.16519546
    },
    {
        "x": 7.55,
        "y": 0.166456494
    },
    {
        "x": 7.56,
        "y": 0.167297184
    },
    {
        "x": 7.57,
        "y": 0.168137873
    },
    {
        "x": 7.58,
        "y": 0.168978562
    },
    {
        "x": 7.59,
        "y": 0.170239596
    },
    {
        "x": 7.6,
        "y": 0.171080286
    },
    {
        "x": 7.61,
        "y": 0.171920975
    },
    {
        "x": 7.62,
        "y": 0.173182009
    },
    {
        "x": 7.63,
        "y": 0.174022699
    },
    {
        "x": 7.64,
        "y": 0.174863388
    },
    {
        "x": 7.65,
        "y": 0.175704077
    },
    {
        "x": 7.66,
        "y": 0.176965111
    },
    {
        "x": 7.67,
        "y": 0.177805801
    },
    {
        "x": 7.68,
        "y": 0.17864649
    },
    {
        "x": 7.69,
        "y": 0.179907524
    },
    {
        "x": 7.7,
        "y": 0.180748214
    },
    {
        "x": 7.71,
        "y": 0.182009248
    },
    {
        "x": 7.72,
        "y": 0.182849937
    },
    {
        "x": 7.73,
        "y": 0.183690626
    },
    {
        "x": 7.74,
        "y": 0.18495166
    },
    {
        "x": 7.75,
        "y": 0.18579235
    },
    {
        "x": 7.76,
        "y": 0.186633039
    },
    {
        "x": 7.77,
        "y": 0.187894073
    },
    {
        "x": 7.78,
        "y": 0.188734763
    },
    {
        "x": 7.79,
        "y": 0.189995797
    },
    {
        "x": 7.8,
        "y": 0.190836486
    },
    {
        "x": 7.81,
        "y": 0.19209752
    },
    {
        "x": 7.82,
        "y": 0.192938209
    },
    {
        "x": 7.83,
        "y": 0.194199243
    },
    {
        "x": 7.84,
        "y": 0.195039933
    },
    {
        "x": 7.85,
        "y": 0.195880622
    },
    {
        "x": 7.86,
        "y": 0.197141656
    },
    {
        "x": 7.87,
        "y": 0.19840269
    },
    {
        "x": 7.88,
        "y": 0.19924338
    },
    {
        "x": 7.89,
        "y": 0.200084069
    },
    {
        "x": 7.9,
        "y": 0.201345103
    },
    {
        "x": 7.91,
        "y": 0.202185792
    },
    {
        "x": 7.92,
        "y": 0.203446826
    },
    {
        "x": 7.93,
        "y": 0.204287516
    },
    {
        "x": 7.94,
        "y": 0.20554855
    },
    {
        "x": 7.95,
        "y": 0.206389239
    },
    {
        "x": 7.96,
        "y": 0.207650273
    },
    {
        "x": 7.97,
        "y": 0.208911307
    },
    {
        "x": 7.98,
        "y": 0.209751997
    },
    {
        "x": 7.99,
        "y": 0.211013031
    },
    {
        "x": 8,
        "y": 0.21185372
    },
    {
        "x": 8.01,
        "y": 0.213114754
    },
    {
        "x": 8.02,
        "y": 0.213955443
    },
    {
        "x": 8.03,
        "y": 0.215216478
    },
    {
        "x": 8.04,
        "y": 0.216057167
    },
    {
        "x": 8.05,
        "y": 0.217318201
    },
    {
        "x": 8.06,
        "y": 0.218579235
    },
    {
        "x": 8.07,
        "y": 0.219419924
    },
    {
        "x": 8.08,
        "y": 0.220680958
    },
    {
        "x": 8.09,
        "y": 0.221941992
    },
    {
        "x": 8.1,
        "y": 0.222782682
    },
    {
        "x": 8.11,
        "y": 0.224043716
    },
    {
        "x": 8.12,
        "y": 0.22530475
    },
    {
        "x": 8.13,
        "y": 0.226145439
    },
    {
        "x": 8.14,
        "y": 0.227406473
    },
    {
        "x": 8.15,
        "y": 0.228667507
    },
    {
        "x": 8.16,
        "y": 0.229508197
    },
    {
        "x": 8.17,
        "y": 0.230769231
    },
    {
        "x": 8.18,
        "y": 0.232030265
    },
    {
        "x": 8.19,
        "y": 0.232870954
    },
    {
        "x": 8.2,
        "y": 0.234131988
    },
    {
        "x": 8.21,
        "y": 0.235393022
    },
    {
        "x": 8.22,
        "y": 0.236233712
    },
    {
        "x": 8.23,
        "y": 0.237494746
    },
    {
        "x": 8.24,
        "y": 0.23875578
    },
    {
        "x": 8.25,
        "y": 0.240016814
    },
    {
        "x": 8.26,
        "y": 0.241277848
    },
    {
        "x": 8.27,
        "y": 0.242118537
    },
    {
        "x": 8.28,
        "y": 0.243379571
    },
    {
        "x": 8.29,
        "y": 0.244220261
    },
    {
        "x": 8.3,
        "y": 0.245481295
    },
    {
        "x": 8.31,
        "y": 0.246742329
    },
    {
        "x": 8.32,
        "y": 0.248003363
    },
    {
        "x": 8.33,
        "y": 0.249264397
    },
    {
        "x": 8.34,
        "y": 0.250105086
    },
    {
        "x": 8.35,
        "y": 0.25136612
    },
    {
        "x": 8.36,
        "y": 0.252627154
    },
    {
        "x": 8.37,
        "y": 0.253888188
    },
    {
        "x": 8.38,
        "y": 0.254728878
    },
    {
        "x": 8.39,
        "y": 0.255989912
    },
    {
        "x": 8.4,
        "y": 0.257250946
    },
    {
        "x": 8.41,
        "y": 0.25851198
    },
    {
        "x": 8.42,
        "y": 0.259773014
    },
    {
        "x": 8.43,
        "y": 0.261034048
    },
    {
        "x": 8.44,
        "y": 0.261874737
    },
    {
        "x": 8.45,
        "y": 0.263135771
    },
    {
        "x": 8.46,
        "y": 0.264396805
    },
    {
        "x": 8.47,
        "y": 0.265657839
    },
    {
        "x": 8.48,
        "y": 0.266918873
    },
    {
        "x": 8.49,
        "y": 0.268179908
    },
    {
        "x": 8.5,
        "y": 0.269440942
    },
    {
        "x": 8.51,
        "y": 0.270281631
    },
    {
        "x": 8.52,
        "y": 0.27196301
    },
    {
        "x": 8.53,
        "y": 0.272803699
    },
    {
        "x": 8.54,
        "y": 0.274064733
    },
    {
        "x": 8.55,
        "y": 0.275325767
    },
    {
        "x": 8.56,
        "y": 0.276586801
    },
    {
        "x": 8.57,
        "y": 0.277847835
    },
    {
        "x": 8.58,
        "y": 0.279108869
    },
    {
        "x": 8.59,
        "y": 0.280369903
    },
    {
        "x": 8.6,
        "y": 0.281630937
    },
    {
        "x": 8.61,
        "y": 0.282471627
    },
    {
        "x": 8.62,
        "y": 0.284153005
    },
    {
        "x": 8.63,
        "y": 0.284993695
    },
    {
        "x": 8.64,
        "y": 0.286254729
    },
    {
        "x": 8.65,
        "y": 0.287936108
    },
    {
        "x": 8.66,
        "y": 0.288776797
    },
    {
        "x": 8.67,
        "y": 0.290458176
    },
    {
        "x": 8.68,
        "y": 0.291298865
    },
    {
        "x": 8.69,
        "y": 0.292559899
    },
    {
        "x": 8.7,
        "y": 0.293820933
    },
    {
        "x": 8.71,
        "y": 0.295081967
    },
    {
        "x": 8.72,
        "y": 0.296343001
    },
    {
        "x": 8.73,
        "y": 0.297604035
    },
    {
        "x": 8.74,
        "y": 0.298865069
    },
    {
        "x": 8.75,
        "y": 0.300126103
    },
    {
        "x": 8.76,
        "y": 0.301387137
    },
    {
        "x": 8.77,
        "y": 0.302648172
    },
    {
        "x": 8.78,
        "y": 0.303909206
    },
    {
        "x": 8.79,
        "y": 0.30517024
    },
    {
        "x": 8.8,
        "y": 0.306431274
    },
    {
        "x": 8.81,
        "y": 0.307692308
    },
    {
        "x": 8.82,
        "y": 0.308953342
    },
    {
        "x": 8.83,
        "y": 0.310214376
    },
    {
        "x": 8.84,
        "y": 0.31147541
    },
    {
        "x": 8.85,
        "y": 0.312736444
    },
    {
        "x": 8.86,
        "y": 0.313997478
    },
    {
        "x": 8.87,
        "y": 0.315258512
    },
    {
        "x": 8.88,
        "y": 0.316519546
    },
    {
        "x": 8.89,
        "y": 0.31778058
    },
    {
        "x": 8.9,
        "y": 0.319041614
    },
    {
        "x": 8.91,
        "y": 0.320302648
    },
    {
        "x": 8.92,
        "y": 0.321563682
    },
    {
        "x": 8.93,
        "y": 0.322824716
    },
    {
        "x": 8.94,
        "y": 0.32408575
    },
    {
        "x": 8.95,
        "y": 0.325767129
    },
    {
        "x": 8.96,
        "y": 0.327028163
    },
    {
        "x": 8.97,
        "y": 0.328289197
    },
    {
        "x": 8.98,
        "y": 0.329550231
    },
    {
        "x": 8.99,
        "y": 0.330811265
    },
    {
        "x": 9,
        "y": 0.332072299
    },
    {
        "x": 9.01,
        "y": 0.333333333
    },
    {
        "x": 9.02,
        "y": 0.334594367
    },
    {
        "x": 9.03,
        "y": 0.335855401
    },
    {
        "x": 9.04,
        "y": 0.337116435
    },
    {
        "x": 9.05,
        "y": 0.33837747
    },
    {
        "x": 9.06,
        "y": 0.339638504
    },
    {
        "x": 9.07,
        "y": 0.341319882
    },
    {
        "x": 9.08,
        "y": 0.342160572
    },
    {
        "x": 9.09,
        "y": 0.34384195
    },
    {
        "x": 9.1,
        "y": 0.345102984
    },
    {
        "x": 9.11,
        "y": 0.346364018
    },
    {
        "x": 9.12,
        "y": 0.347625053
    },
    {
        "x": 9.13,
        "y": 0.348886087
    },
    {
        "x": 9.14,
        "y": 0.350147121
    },
    {
        "x": 9.15,
        "y": 0.351408155
    },
    {
        "x": 9.16,
        "y": 0.352669189
    },
    {
        "x": 9.17,
        "y": 0.353930223
    },
    {
        "x": 9.18,
        "y": 0.355191257
    },
    {
        "x": 9.19,
        "y": 0.356452291
    },
    {
        "x": 9.2,
        "y": 0.357713325
    },
    {
        "x": 9.21,
        "y": 0.358974359
    },
    {
        "x": 9.22,
        "y": 0.360235393
    },
    {
        "x": 9.23,
        "y": 0.361496427
    },
    {
        "x": 9.24,
        "y": 0.363177806
    },
    {
        "x": 9.25,
        "y": 0.36443884
    },
    {
        "x": 9.26,
        "y": 0.365699874
    },
    {
        "x": 9.27,
        "y": 0.366960908
    },
    {
        "x": 9.28,
        "y": 0.368221942
    },
    {
        "x": 9.29,
        "y": 0.369482976
    },
    {
        "x": 9.3,
        "y": 0.37074401
    },
    {
        "x": 9.31,
        "y": 0.372005044
    },
    {
        "x": 9.32,
        "y": 0.373266078
    },
    {
        "x": 9.33,
        "y": 0.374527112
    },
    {
        "x": 9.34,
        "y": 0.375788146
    },
    {
        "x": 9.35,
        "y": 0.37704918
    },
    {
        "x": 9.36,
        "y": 0.378310214
    },
    {
        "x": 9.37,
        "y": 0.379991593
    },
    {
        "x": 9.38,
        "y": 0.381252627
    },
    {
        "x": 9.39,
        "y": 0.382513661
    },
    {
        "x": 9.4,
        "y": 0.383774695
    },
    {
        "x": 9.41,
        "y": 0.385035729
    },
    {
        "x": 9.42,
        "y": 0.386296763
    },
    {
        "x": 9.43,
        "y": 0.387557797
    },
    {
        "x": 9.44,
        "y": 0.388818831
    },
    {
        "x": 9.45,
        "y": 0.390079865
    },
    {
        "x": 9.46,
        "y": 0.3913409
    },
    {
        "x": 9.47,
        "y": 0.392601934
    },
    {
        "x": 9.48,
        "y": 0.393862968
    },
    {
        "x": 9.49,
        "y": 0.395124002
    },
    {
        "x": 9.5,
        "y": 0.396385036
    },
    {
        "x": 9.51,
        "y": 0.398066414
    },
    {
        "x": 9.52,
        "y": 0.399327449
    },
    {
        "x": 9.53,
        "y": 0.400168138
    },
    {
        "x": 9.54,
        "y": 0.401429172
    },
    {
        "x": 9.55,
        "y": 0.402690206
    },
    {
        "x": 9.56,
        "y": 0.404371585
    },
    {
        "x": 9.57,
        "y": 0.405212274
    },
    {
        "x": 9.58,
        "y": 0.406893653
    },
    {
        "x": 9.59,
        "y": 0.408154687
    },
    {
        "x": 9.6,
        "y": 0.409415721
    },
    {
        "x": 9.61,
        "y": 0.410676755
    },
    {
        "x": 9.62,
        "y": 0.411937789
    },
    {
        "x": 9.63,
        "y": 0.413198823
    },
    {
        "x": 9.64,
        "y": 0.414459857
    },
    {
        "x": 9.65,
        "y": 0.415720891
    },
    {
        "x": 9.66,
        "y": 0.416981925
    },
    {
        "x": 9.67,
        "y": 0.418242959
    },
    {
        "x": 9.68,
        "y": 0.419503993
    },
    {
        "x": 9.69,
        "y": 0.420765027
    },
    {
        "x": 9.7,
        "y": 0.422026061
    },
    {
        "x": 9.71,
        "y": 0.423287095
    },
    {
        "x": 9.72,
        "y": 0.424548129
    },
    {
        "x": 9.73,
        "y": 0.425809164
    },
    {
        "x": 9.74,
        "y": 0.427070198
    },
    {
        "x": 9.75,
        "y": 0.428331232
    },
    {
        "x": 9.76,
        "y": 0.429592266
    },
    {
        "x": 9.77,
        "y": 0.4308533
    },
    {
        "x": 9.78,
        "y": 0.432114334
    },
    {
        "x": 9.79,
        "y": 0.433375368
    },
    {
        "x": 9.8,
        "y": 0.434636402
    },
    {
        "x": 9.81,
        "y": 0.435897436
    },
    {
        "x": 9.82,
        "y": 0.43715847
    },
    {
        "x": 9.83,
        "y": 0.438419504
    },
    {
        "x": 9.84,
        "y": 0.439680538
    },
    {
        "x": 9.85,
        "y": 0.440521227
    },
    {
        "x": 9.86,
        "y": 0.441782261
    },
    {
        "x": 9.87,
        "y": 0.443043296
    },
    {
        "x": 9.88,
        "y": 0.44430433
    },
    {
        "x": 9.89,
        "y": 0.445565364
    },
    {
        "x": 9.9,
        "y": 0.446826398
    },
    {
        "x": 9.91,
        "y": 0.448087432
    },
    {
        "x": 9.92,
        "y": 0.449348466
    },
    {
        "x": 9.93,
        "y": 0.4506095
    },
    {
        "x": 9.94,
        "y": 0.451870534
    },
    {
        "x": 9.95,
        "y": 0.453131568
    },
    {
        "x": 9.96,
        "y": 0.453972257
    },
    {
        "x": 9.97,
        "y": 0.455653636
    },
    {
        "x": 9.98,
        "y": 0.456494325
    },
    {
        "x": 9.99,
        "y": 0.457755359
    },
    {
        "x": 10,
        "y": 0.459016393
    },
    {
        "x": 10.01,
        "y": 0.460277427
    },
    {
        "x": 10.02,
        "y": 0.461538462
    },
    {
        "x": 10.03,
        "y": 0.462799496
    },
    {
        "x": 10.04,
        "y": 0.463640185
    },
    {
        "x": 10.05,
        "y": 0.464901219
    },
    {
        "x": 10.06,
        "y": 0.466162253
    },
    {
        "x": 10.07,
        "y": 0.467423287
    },
    {
        "x": 10.08,
        "y": 0.468684321
    },
    {
        "x": 10.09,
        "y": 0.469945355
    },
    {
        "x": 10.1,
        "y": 0.470786045
    },
    {
        "x": 10.11,
        "y": 0.472047079
    },
    {
        "x": 10.12,
        "y": 0.473308113
    },
    {
        "x": 10.13,
        "y": 0.474569147
    },
    {
        "x": 10.14,
        "y": 0.475830181
    },
    {
        "x": 10.15,
        "y": 0.47667087
    },
    {
        "x": 10.16,
        "y": 0.477931904
    },
    {
        "x": 10.17,
        "y": 0.479192938
    },
    {
        "x": 10.18,
        "y": 0.480453972
    },
    {
        "x": 10.19,
        "y": 0.481715006
    },
    {
        "x": 10.2,
        "y": 0.482555696
    },
    {
        "x": 10.21,
        "y": 0.48381673
    },
    {
        "x": 10.22,
        "y": 0.485077764
    },
    {
        "x": 10.23,
        "y": 0.486338798
    },
    {
        "x": 10.24,
        "y": 0.487179487
    },
    {
        "x": 10.25,
        "y": 0.488440521
    },
    {
        "x": 10.26,
        "y": 0.489701555
    },
    {
        "x": 10.27,
        "y": 0.490542245
    },
    {
        "x": 10.28,
        "y": 0.491803279
    },
    {
        "x": 10.29,
        "y": 0.493064313
    },
    {
        "x": 10.3,
        "y": 0.494325347
    },
    {
        "x": 10.31,
        "y": 0.495166036
    },
    {
        "x": 10.32,
        "y": 0.49642707
    },
    {
        "x": 10.33,
        "y": 0.497688104
    },
    {
        "x": 10.34,
        "y": 0.498528794
    },
    {
        "x": 10.35,
        "y": 0.499789828
    },
    {
        "x": 10.36,
        "y": 0.501050862
    },
    {
        "x": 10.37,
        "y": 0.502311896
    },
    {
        "x": 10.38,
        "y": 0.503152585
    },
    {
        "x": 10.39,
        "y": 0.504413619
    },
    {
        "x": 10.4,
        "y": 0.505254309
    },
    {
        "x": 10.41,
        "y": 0.506515343
    },
    {
        "x": 10.42,
        "y": 0.507776377
    },
    {
        "x": 10.43,
        "y": 0.508617066
    },
    {
        "x": 10.44,
        "y": 0.5098781
    },
    {
        "x": 10.45,
        "y": 0.511139134
    },
    {
        "x": 10.46,
        "y": 0.511979823
    },
    {
        "x": 10.47,
        "y": 0.513240858
    },
    {
        "x": 10.48,
        "y": 0.514081547
    },
    {
        "x": 10.49,
        "y": 0.515342581
    },
    {
        "x": 10.5,
        "y": 0.516603615
    },
    {
        "x": 10.51,
        "y": 0.517444304
    },
    {
        "x": 10.52,
        "y": 0.518705338
    },
    {
        "x": 10.53,
        "y": 0.519546028
    },
    {
        "x": 10.54,
        "y": 0.520807062
    },
    {
        "x": 10.55,
        "y": 0.521647751
    },
    {
        "x": 10.56,
        "y": 0.522908785
    },
    {
        "x": 10.57,
        "y": 0.524169819
    },
    {
        "x": 10.58,
        "y": 0.525010509
    },
    {
        "x": 10.59,
        "y": 0.526271543
    },
    {
        "x": 10.6,
        "y": 0.527112232
    },
    {
        "x": 10.61,
        "y": 0.527952921
    },
    {
        "x": 10.62,
        "y": 0.529213955
    },
    {
        "x": 10.63,
        "y": 0.530054645
    },
    {
        "x": 10.64,
        "y": 0.531315679
    },
    {
        "x": 10.65,
        "y": 0.532576713
    },
    {
        "x": 10.66,
        "y": 0.533417402
    },
    {
        "x": 10.67,
        "y": 0.534258092
    },
    {
        "x": 10.68,
        "y": 0.535519126
    },
    {
        "x": 10.69,
        "y": 0.536359815
    },
    {
        "x": 10.7,
        "y": 0.537620849
    },
    {
        "x": 10.71,
        "y": 0.538461538
    },
    {
        "x": 10.72,
        "y": 0.539722573
    },
    {
        "x": 10.73,
        "y": 0.540563262
    },
    {
        "x": 10.74,
        "y": 0.541403951
    },
    {
        "x": 10.75,
        "y": 0.542664985
    },
    {
        "x": 10.76,
        "y": 0.543505675
    },
    {
        "x": 10.77,
        "y": 0.544766709
    },
    {
        "x": 10.78,
        "y": 0.545607398
    },
    {
        "x": 10.79,
        "y": 0.546868432
    },
    {
        "x": 10.8,
        "y": 0.547709121
    },
    {
        "x": 10.81,
        "y": 0.548549811
    },
    {
        "x": 10.82,
        "y": 0.549810845
    },
    {
        "x": 10.83,
        "y": 0.550651534
    },
    {
        "x": 10.84,
        "y": 0.551492224
    },
    {
        "x": 10.85,
        "y": 0.552753258
    },
    {
        "x": 10.86,
        "y": 0.553593947
    },
    {
        "x": 10.87,
        "y": 0.554434636
    },
    {
        "x": 10.88,
        "y": 0.55569567
    },
    {
        "x": 10.89,
        "y": 0.55653636
    },
    {
        "x": 10.9,
        "y": 0.557797394
    },
    {
        "x": 10.91,
        "y": 0.558638083
    },
    {
        "x": 10.92,
        "y": 0.559478773
    },
    {
        "x": 10.93,
        "y": 0.560319462
    },
    {
        "x": 10.94,
        "y": 0.561160151
    },
    {
        "x": 10.95,
        "y": 0.562421185
    },
    {
        "x": 10.96,
        "y": 0.563261875
    },
    {
        "x": 10.97,
        "y": 0.564102564
    },
    {
        "x": 10.98,
        "y": 0.564943253
    },
    {
        "x": 10.99,
        "y": 0.566204288
    },
    {
        "x": 11,
        "y": 0.567044977
    },
    {
        "x": 11.01,
        "y": 0.567885666
    },
    {
        "x": 11.02,
        "y": 0.568726356
    },
    {
        "x": 11.03,
        "y": 0.56998739
    },
    {
        "x": 11.04,
        "y": 0.570828079
    },
    {
        "x": 11.05,
        "y": 0.571668768
    },
    {
        "x": 11.06,
        "y": 0.572509458
    },
    {
        "x": 11.07,
        "y": 0.573770492
    },
    {
        "x": 11.08,
        "y": 0.574611181
    },
    {
        "x": 11.09,
        "y": 0.575451871
    },
    {
        "x": 11.1,
        "y": 0.57629256
    },
    {
        "x": 11.11,
        "y": 0.577133249
    },
    {
        "x": 11.12,
        "y": 0.578394283
    },
    {
        "x": 11.13,
        "y": 0.579234973
    },
    {
        "x": 11.14,
        "y": 0.580075662
    },
    {
        "x": 11.15,
        "y": 0.580916351
    },
    {
        "x": 11.16,
        "y": 0.581757041
    },
    {
        "x": 11.17,
        "y": 0.58259773
    },
    {
        "x": 11.18,
        "y": 0.58343842
    },
    {
        "x": 11.19,
        "y": 0.584279109
    },
    {
        "x": 11.2,
        "y": 0.585540143
    },
    {
        "x": 11.21,
        "y": 0.586380832
    },
    {
        "x": 11.22,
        "y": 0.587221522
    },
    {
        "x": 11.23,
        "y": 0.588062211
    },
    {
        "x": 11.24,
        "y": 0.5889029
    },
    {
        "x": 11.25,
        "y": 0.58974359
    },
    {
        "x": 11.26,
        "y": 0.590584279
    },
    {
        "x": 11.27,
        "y": 0.591424968
    },
    {
        "x": 11.28,
        "y": 0.592686003
    },
    {
        "x": 11.29,
        "y": 0.593106347
    },
    {
        "x": 11.3,
        "y": 0.594367381
    },
    {
        "x": 11.31,
        "y": 0.595208071
    },
    {
        "x": 11.32,
        "y": 0.59604876
    },
    {
        "x": 11.33,
        "y": 0.596889449
    },
    {
        "x": 11.34,
        "y": 0.597730139
    },
    {
        "x": 11.35,
        "y": 0.598570828
    },
    {
        "x": 11.36,
        "y": 0.599411517
    },
    {
        "x": 11.37,
        "y": 0.600252207
    },
    {
        "x": 11.38,
        "y": 0.601092896
    },
    {
        "x": 11.39,
        "y": 0.601933586
    },
    {
        "x": 11.4,
        "y": 0.602774275
    },
    {
        "x": 11.41,
        "y": 0.603614964
    },
    {
        "x": 11.42,
        "y": 0.604455654
    },
    {
        "x": 11.43,
        "y": 0.605296343
    },
    {
        "x": 11.44,
        "y": 0.606137032
    },
    {
        "x": 11.45,
        "y": 0.606977722
    },
    {
        "x": 11.46,
        "y": 0.607398066
    },
    {
        "x": 11.47,
        "y": 0.6086591
    },
    {
        "x": 11.48,
        "y": 0.60949979
    },
    {
        "x": 11.49,
        "y": 0.609920135
    },
    {
        "x": 11.5,
        "y": 0.610760824
    },
    {
        "x": 11.51,
        "y": 0.611601513
    },
    {
        "x": 11.52,
        "y": 0.612442203
    },
    {
        "x": 11.53,
        "y": 0.613282892
    },
    {
        "x": 11.54,
        "y": 0.614123581
    },
    {
        "x": 11.55,
        "y": 0.614964271
    },
    {
        "x": 11.56,
        "y": 0.61580496
    },
    {
        "x": 11.57,
        "y": 0.616645649
    },
    {
        "x": 11.58,
        "y": 0.617065994
    },
    {
        "x": 11.59,
        "y": 0.617906683
    },
    {
        "x": 11.6,
        "y": 0.618747373
    },
    {
        "x": 11.61,
        "y": 0.619588062
    },
    {
        "x": 11.62,
        "y": 0.620428752
    },
    {
        "x": 11.63,
        "y": 0.621269441
    },
    {
        "x": 11.64,
        "y": 0.62211013
    },
    {
        "x": 11.65,
        "y": 0.62295082
    },
    {
        "x": 11.66,
        "y": 0.623371164
    },
    {
        "x": 11.67,
        "y": 0.624211854
    },
    {
        "x": 11.68,
        "y": 0.625052543
    },
    {
        "x": 11.69,
        "y": 0.625893232
    },
    {
        "x": 11.7,
        "y": 0.626733922
    },
    {
        "x": 11.71,
        "y": 0.627574611
    },
    {
        "x": 11.72,
        "y": 0.627994956
    },
    {
        "x": 11.73,
        "y": 0.628835645
    },
    {
        "x": 11.74,
        "y": 0.629676335
    },
    {
        "x": 11.75,
        "y": 0.630517024
    },
    {
        "x": 11.76,
        "y": 0.631357713
    },
    {
        "x": 11.77,
        "y": 0.631778058
    },
    {
        "x": 11.78,
        "y": 0.632618747
    },
    {
        "x": 11.79,
        "y": 0.633459437
    },
    {
        "x": 11.8,
        "y": 0.634300126
    },
    {
        "x": 11.81,
        "y": 0.634720471
    },
    {
        "x": 11.82,
        "y": 0.63556116
    },
    {
        "x": 11.83,
        "y": 0.63640185
    },
    {
        "x": 11.84,
        "y": 0.637242539
    },
    {
        "x": 11.85,
        "y": 0.637662884
    },
    {
        "x": 11.86,
        "y": 0.638503573
    },
    {
        "x": 11.87,
        "y": 0.639344262
    },
    {
        "x": 11.88,
        "y": 0.640184952
    },
    {
        "x": 11.89,
        "y": 0.640605296
    },
    {
        "x": 11.9,
        "y": 0.641445986
    },
    {
        "x": 11.91,
        "y": 0.642286675
    },
    {
        "x": 11.92,
        "y": 0.64270702
    },
    {
        "x": 11.93,
        "y": 0.643547709
    },
    {
        "x": 11.94,
        "y": 0.643968054
    },
    {
        "x": 11.95,
        "y": 0.645229088
    },
    {
        "x": 11.96,
        "y": 0.645649433
    },
    {
        "x": 11.97,
        "y": 0.646490122
    },
    {
        "x": 11.98,
        "y": 0.647330811
    },
    {
        "x": 11.99,
        "y": 0.647751156
    },
    {
        "x": 12,
        "y": 0.648591845
    },
    {
        "x": 12.01,
        "y": 0.649432535
    },
    {
        "x": 12.02,
        "y": 0.649852879
    },
    {
        "x": 12.03,
        "y": 0.650693569
    },
    {
        "x": 12.04,
        "y": 0.651113913
    },
    {
        "x": 12.05,
        "y": 0.651954603
    },
    {
        "x": 12.06,
        "y": 0.652795292
    },
    {
        "x": 12.07,
        "y": 0.653215637
    },
    {
        "x": 12.08,
        "y": 0.654056326
    },
    {
        "x": 12.09,
        "y": 0.654476671
    },
    {
        "x": 12.1,
        "y": 0.65531736
    },
    {
        "x": 12.11,
        "y": 0.65615805
    },
    {
        "x": 12.12,
        "y": 0.656578394
    },
    {
        "x": 12.13,
        "y": 0.657419084
    },
    {
        "x": 12.14,
        "y": 0.658259773
    },
    {
        "x": 12.15,
        "y": 0.658680118
    },
    {
        "x": 12.16,
        "y": 0.659520807
    },
    {
        "x": 12.17,
        "y": 0.659941152
    },
    {
        "x": 12.18,
        "y": 0.660781841
    },
    {
        "x": 12.19,
        "y": 0.661202186
    },
    {
        "x": 12.2,
        "y": 0.662042875
    },
    {
        "x": 12.21,
        "y": 0.66246322
    },
    {
        "x": 12.22,
        "y": 0.663303909
    },
    {
        "x": 12.23,
        "y": 0.664144599
    },
    {
        "x": 12.24,
        "y": 0.664564943
    },
    {
        "x": 12.25,
        "y": 0.665405633
    },
    {
        "x": 12.26,
        "y": 0.665825977
    },
    {
        "x": 12.27,
        "y": 0.666666667
    },
    {
        "x": 12.28,
        "y": 0.667087011
    },
    {
        "x": 12.29,
        "y": 0.667927701
    },
    {
        "x": 12.3,
        "y": 0.668348045
    },
    {
        "x": 12.31,
        "y": 0.669188735
    },
    {
        "x": 12.32,
        "y": 0.669609079
    },
    {
        "x": 12.33,
        "y": 0.670449769
    },
    {
        "x": 12.34,
        "y": 0.670870113
    },
    {
        "x": 12.35,
        "y": 0.671710803
    },
    {
        "x": 12.36,
        "y": 0.672551492
    },
    {
        "x": 12.37,
        "y": 0.672971837
    },
    {
        "x": 12.38,
        "y": 0.673392182
    },
    {
        "x": 12.39,
        "y": 0.673812526
    },
    {
        "x": 12.4,
        "y": 0.674653216
    },
    {
        "x": 12.41,
        "y": 0.67507356
    },
    {
        "x": 12.42,
        "y": 0.67591425
    },
    {
        "x": 12.43,
        "y": 0.676334594
    },
    {
        "x": 12.44,
        "y": 0.677175284
    },
    {
        "x": 12.45,
        "y": 0.677595628
    },
    {
        "x": 12.46,
        "y": 0.678436318
    },
    {
        "x": 12.47,
        "y": 0.678856662
    },
    {
        "x": 12.48,
        "y": 0.679277007
    },
    {
        "x": 12.49,
        "y": 0.680117697
    },
    {
        "x": 12.5,
        "y": 0.680538041
    },
    {
        "x": 12.51,
        "y": 0.681378731
    },
    {
        "x": 12.52,
        "y": 0.681799075
    },
    {
        "x": 12.53,
        "y": 0.68221942
    },
    {
        "x": 12.54,
        "y": 0.683060109
    },
    {
        "x": 12.55,
        "y": 0.683480454
    },
    {
        "x": 12.56,
        "y": 0.684321143
    },
    {
        "x": 12.57,
        "y": 0.684741488
    },
    {
        "x": 12.58,
        "y": 0.685161833
    },
    {
        "x": 12.59,
        "y": 0.686002522
    },
    {
        "x": 12.6,
        "y": 0.686422867
    },
    {
        "x": 12.61,
        "y": 0.687263556
    },
    {
        "x": 12.62,
        "y": 0.687683901
    },
    {
        "x": 12.63,
        "y": 0.688104245
    },
    {
        "x": 12.64,
        "y": 0.688944935
    },
    {
        "x": 12.65,
        "y": 0.68936528
    },
    {
        "x": 12.66,
        "y": 0.690205969
    },
    {
        "x": 12.67,
        "y": 0.690626314
    },
    {
        "x": 12.68,
        "y": 0.691046658
    },
    {
        "x": 12.69,
        "y": 0.691467003
    },
    {
        "x": 12.7,
        "y": 0.692307692
    },
    {
        "x": 12.71,
        "y": 0.692728037
    },
    {
        "x": 12.72,
        "y": 0.693148382
    },
    {
        "x": 12.73,
        "y": 0.693989071
    },
    {
        "x": 12.74,
        "y": 0.694409416
    },
    {
        "x": 12.75,
        "y": 0.69482976
    },
    {
        "x": 12.76,
        "y": 0.69567045
    },
    {
        "x": 12.77,
        "y": 0.696090794
    },
    {
        "x": 12.78,
        "y": 0.696511139
    },
    {
        "x": 12.79,
        "y": 0.696931484
    },
    {
        "x": 12.8,
        "y": 0.697772173
    },
    {
        "x": 12.81,
        "y": 0.698192518
    },
    {
        "x": 12.82,
        "y": 0.698612863
    },
    {
        "x": 12.83,
        "y": 0.699453552
    },
    {
        "x": 12.84,
        "y": 0.699873897
    },
    {
        "x": 12.85,
        "y": 0.700294241
    },
    {
        "x": 12.86,
        "y": 0.701134931
    },
    {
        "x": 12.87,
        "y": 0.701555275
    },
    {
        "x": 12.88,
        "y": 0.70197562
    },
    {
        "x": 12.89,
        "y": 0.702395965
    },
    {
        "x": 12.9,
        "y": 0.703236654
    },
    {
        "x": 12.91,
        "y": 0.703656999
    },
    {
        "x": 12.92,
        "y": 0.704077343
    },
    {
        "x": 12.93,
        "y": 0.704497688
    },
    {
        "x": 12.94,
        "y": 0.705338377
    },
    {
        "x": 12.95,
        "y": 0.705758722
    },
    {
        "x": 12.96,
        "y": 0.706179067
    },
    {
        "x": 12.97,
        "y": 0.706599412
    },
    {
        "x": 12.98,
        "y": 0.707440101
    },
    {
        "x": 12.99,
        "y": 0.707860446
    },
    {
        "x": 13,
        "y": 0.70828079
    },
    {
        "x": 13.01,
        "y": 0.708701135
    },
    {
        "x": 13.02,
        "y": 0.70912148
    },
    {
        "x": 13.03,
        "y": 0.709541824
    },
    {
        "x": 13.04,
        "y": 0.710382514
    },
    {
        "x": 13.05,
        "y": 0.710802858
    },
    {
        "x": 13.06,
        "y": 0.711223203
    },
    {
        "x": 13.07,
        "y": 0.712063892
    },
    {
        "x": 13.08,
        "y": 0.712484237
    },
    {
        "x": 13.09,
        "y": 0.712904582
    },
    {
        "x": 13.1,
        "y": 0.713324926
    },
    {
        "x": 13.11,
        "y": 0.713745271
    },
    {
        "x": 13.12,
        "y": 0.714165616
    },
    {
        "x": 13.13,
        "y": 0.71458596
    },
    {
        "x": 13.14,
        "y": 0.71542665
    },
    {
        "x": 13.15,
        "y": 0.715846995
    },
    {
        "x": 13.16,
        "y": 0.716267339
    },
    {
        "x": 13.17,
        "y": 0.716687684
    },
    {
        "x": 13.18,
        "y": 0.717528373
    },
    {
        "x": 13.19,
        "y": 0.717948718
    },
    {
        "x": 13.2,
        "y": 0.718369063
    },
    {
        "x": 13.21,
        "y": 0.718789407
    },
    {
        "x": 13.22,
        "y": 0.719209752
    },
    {
        "x": 13.23,
        "y": 0.719630097
    },
    {
        "x": 13.24,
        "y": 0.720050441
    },
    {
        "x": 13.25,
        "y": 0.720891131
    },
    {
        "x": 13.26,
        "y": 0.721311475
    },
    {
        "x": 13.27,
        "y": 0.72173182
    },
    {
        "x": 13.28,
        "y": 0.722152165
    },
    {
        "x": 13.29,
        "y": 0.722572509
    },
    {
        "x": 13.3,
        "y": 0.722992854
    },
    {
        "x": 13.31,
        "y": 0.723413199
    },
    {
        "x": 13.32,
        "y": 0.724253888
    },
    {
        "x": 13.33,
        "y": 0.724253888
    },
    {
        "x": 13.34,
        "y": 0.725094578
    },
    {
        "x": 13.35,
        "y": 0.725514922
    },
    {
        "x": 13.36,
        "y": 0.725935267
    },
    {
        "x": 13.37,
        "y": 0.726355612
    },
    {
        "x": 13.38,
        "y": 0.726775956
    },
    {
        "x": 13.39,
        "y": 0.727196301
    },
    {
        "x": 13.4,
        "y": 0.72803699
    },
    {
        "x": 13.41,
        "y": 0.728457335
    },
    {
        "x": 13.42,
        "y": 0.72887768
    },
    {
        "x": 13.43,
        "y": 0.729298024
    },
    {
        "x": 13.44,
        "y": 0.729718369
    },
    {
        "x": 13.45,
        "y": 0.730138714
    },
    {
        "x": 13.46,
        "y": 0.730559058
    },
    {
        "x": 13.47,
        "y": 0.730979403
    },
    {
        "x": 13.48,
        "y": 0.731399748
    },
    {
        "x": 13.49,
        "y": 0.731820092
    },
    {
        "x": 13.5,
        "y": 0.732240437
    },
    {
        "x": 13.51,
        "y": 0.732660782
    },
    {
        "x": 13.52,
        "y": 0.733081127
    },
    {
        "x": 13.53,
        "y": 0.733501471
    },
    {
        "x": 13.54,
        "y": 0.734342161
    },
    {
        "x": 13.55,
        "y": 0.734762505
    },
    {
        "x": 13.56,
        "y": 0.73518285
    },
    {
        "x": 13.57,
        "y": 0.735603195
    },
    {
        "x": 13.58,
        "y": 0.735603195
    },
    {
        "x": 13.59,
        "y": 0.736443884
    },
    {
        "x": 13.6,
        "y": 0.736864229
    },
    {
        "x": 13.61,
        "y": 0.737284573
    },
    {
        "x": 13.62,
        "y": 0.737704918
    },
    {
        "x": 13.63,
        "y": 0.738125263
    },
    {
        "x": 13.64,
        "y": 0.738545607
    },
    {
        "x": 13.65,
        "y": 0.738965952
    },
    {
        "x": 13.66,
        "y": 0.739386297
    },
    {
        "x": 13.67,
        "y": 0.739806641
    },
    {
        "x": 13.68,
        "y": 0.740226986
    },
    {
        "x": 13.69,
        "y": 0.740647331
    },
    {
        "x": 13.7,
        "y": 0.741067675
    },
    {
        "x": 13.71,
        "y": 0.74148802
    },
    {
        "x": 13.72,
        "y": 0.74232871
    },
    {
        "x": 13.73,
        "y": 0.74232871
    },
    {
        "x": 13.74,
        "y": 0.742749054
    },
    {
        "x": 13.75,
        "y": 0.743169399
    },
    {
        "x": 13.76,
        "y": 0.743589744
    },
    {
        "x": 13.77,
        "y": 0.744010088
    },
    {
        "x": 13.78,
        "y": 0.744430433
    },
    {
        "x": 13.79,
        "y": 0.744850778
    },
    {
        "x": 13.8,
        "y": 0.745271122
    },
    {
        "x": 13.81,
        "y": 0.745691467
    },
    {
        "x": 13.82,
        "y": 0.746111812
    },
    {
        "x": 13.83,
        "y": 0.746952501
    },
    {
        "x": 13.84,
        "y": 0.746952501
    },
    {
        "x": 13.85,
        "y": 0.747372846
    },
    {
        "x": 13.86,
        "y": 0.74779319
    },
    {
        "x": 13.87,
        "y": 0.748213535
    },
    {
        "x": 13.88,
        "y": 0.74863388
    },
    {
        "x": 13.89,
        "y": 0.749054224
    },
    {
        "x": 13.9,
        "y": 0.749474569
    },
    {
        "x": 13.91,
        "y": 0.749894914
    },
    {
        "x": 13.92,
        "y": 0.750315259
    },
    {
        "x": 13.93,
        "y": 0.750735603
    },
    {
        "x": 13.94,
        "y": 0.751155948
    },
    {
        "x": 13.95,
        "y": 0.751576293
    },
    {
        "x": 13.96,
        "y": 0.751996637
    },
    {
        "x": 13.97,
        "y": 0.752416982
    },
    {
        "x": 13.98,
        "y": 0.752837327
    },
    {
        "x": 13.99,
        "y": 0.753257671
    },
    {
        "x": 14,
        "y": 0.753678016
    },
    {
        "x": 14.01,
        "y": 0.754098361
    },
    {
        "x": 14.02,
        "y": 0.754518705
    },
    {
        "x": 14.03,
        "y": 0.75493905
    },
    {
        "x": 14.04,
        "y": 0.75493905
    },
    {
        "x": 14.05,
        "y": 0.755359395
    },
    {
        "x": 14.06,
        "y": 0.755779739
    },
    {
        "x": 14.07,
        "y": 0.756200084
    },
    {
        "x": 14.08,
        "y": 0.756620429
    },
    {
        "x": 14.09,
        "y": 0.757040773
    },
    {
        "x": 14.1,
        "y": 0.757461118
    },
    {
        "x": 14.11,
        "y": 0.757881463
    },
    {
        "x": 14.12,
        "y": 0.758301807
    },
    {
        "x": 14.13,
        "y": 0.758722152
    },
    {
        "x": 14.14,
        "y": 0.759142497
    },
    {
        "x": 14.15,
        "y": 0.759562842
    },
    {
        "x": 14.16,
        "y": 0.759983186
    },
    {
        "x": 14.17,
        "y": 0.759983186
    },
    {
        "x": 14.18,
        "y": 0.760823876
    },
    {
        "x": 14.19,
        "y": 0.760823876
    },
    {
        "x": 14.2,
        "y": 0.76124422
    },
    {
        "x": 14.21,
        "y": 0.761664565
    },
    {
        "x": 14.22,
        "y": 0.76208491
    },
    {
        "x": 14.23,
        "y": 0.762505254
    },
    {
        "x": 14.24,
        "y": 0.762925599
    },
    {
        "x": 14.25,
        "y": 0.763345944
    },
    {
        "x": 14.26,
        "y": 0.763766288
    },
    {
        "x": 14.27,
        "y": 0.763766288
    },
    {
        "x": 14.28,
        "y": 0.764186633
    },
    {
        "x": 14.29,
        "y": 0.764606978
    },
    {
        "x": 14.3,
        "y": 0.765027322
    },
    {
        "x": 14.31,
        "y": 0.765447667
    },
    {
        "x": 14.32,
        "y": 0.765868012
    },
    {
        "x": 14.33,
        "y": 0.766288356
    },
    {
        "x": 14.34,
        "y": 0.766708701
    },
    {
        "x": 14.35,
        "y": 0.767129046
    },
    {
        "x": 14.36,
        "y": 0.767549391
    },
    {
        "x": 14.37,
        "y": 0.767969735
    },
    {
        "x": 14.38,
        "y": 0.767969735
    },
    {
        "x": 14.39,
        "y": 0.76839008
    },
    {
        "x": 14.4,
        "y": 0.768810425
    },
    {
        "x": 14.41,
        "y": 0.769230769
    },
    {
        "x": 14.42,
        "y": 0.769651114
    },
    {
        "x": 14.43,
        "y": 0.770071459
    },
    {
        "x": 14.44,
        "y": 0.770071459
    },
    {
        "x": 14.45,
        "y": 0.770912148
    },
    {
        "x": 14.46,
        "y": 0.771332493
    },
    {
        "x": 14.47,
        "y": 0.771332493
    },
    {
        "x": 14.48,
        "y": 0.771752837
    },
    {
        "x": 14.49,
        "y": 0.772173182
    },
    {
        "x": 14.5,
        "y": 0.772593527
    },
    {
        "x": 14.51,
        "y": 0.773013871
    },
    {
        "x": 14.52,
        "y": 0.773434216
    },
    {
        "x": 14.53,
        "y": 0.773434216
    },
    {
        "x": 14.54,
        "y": 0.773854561
    },
    {
        "x": 14.55,
        "y": 0.774274905
    },
    {
        "x": 14.56,
        "y": 0.77469525
    },
    {
        "x": 14.57,
        "y": 0.775115595
    },
    {
        "x": 14.58,
        "y": 0.775115595
    },
    {
        "x": 14.59,
        "y": 0.775535939
    },
    {
        "x": 14.6,
        "y": 0.775956284
    },
    {
        "x": 14.61,
        "y": 0.776376629
    },
    {
        "x": 14.62,
        "y": 0.776796974
    },
    {
        "x": 14.63,
        "y": 0.777217318
    },
    {
        "x": 14.64,
        "y": 0.777217318
    },
    {
        "x": 14.65,
        "y": 0.777637663
    },
    {
        "x": 14.66,
        "y": 0.778058008
    },
    {
        "x": 14.67,
        "y": 0.778058008
    },
    {
        "x": 14.68,
        "y": 0.778478352
    },
    {
        "x": 14.69,
        "y": 0.778898697
    },
    {
        "x": 14.7,
        "y": 0.779319042
    },
    {
        "x": 14.71,
        "y": 0.779739386
    },
    {
        "x": 14.72,
        "y": 0.780159731
    },
    {
        "x": 14.73,
        "y": 0.780159731
    },
    {
        "x": 14.74,
        "y": 0.780580076
    },
    {
        "x": 14.75,
        "y": 0.78100042
    },
    {
        "x": 14.76,
        "y": 0.781420765
    },
    {
        "x": 14.77,
        "y": 0.78184111
    },
    {
        "x": 14.78,
        "y": 0.78184111
    },
    {
        "x": 14.79,
        "y": 0.782681799
    },
    {
        "x": 14.8,
        "y": 0.782681799
    },
    {
        "x": 14.81,
        "y": 0.783102144
    },
    {
        "x": 14.82,
        "y": 0.783522488
    },
    {
        "x": 14.83,
        "y": 0.783942833
    },
    {
        "x": 14.84,
        "y": 0.784363178
    },
    {
        "x": 14.85,
        "y": 0.784363178
    },
    {
        "x": 14.86,
        "y": 0.784783522
    },
    {
        "x": 14.87,
        "y": 0.785203867
    },
    {
        "x": 14.88,
        "y": 0.785624212
    },
    {
        "x": 14.89,
        "y": 0.785624212
    },
    {
        "x": 14.9,
        "y": 0.786044557
    },
    {
        "x": 14.91,
        "y": 0.786464901
    },
    {
        "x": 14.92,
        "y": 0.786885246
    },
    {
        "x": 14.93,
        "y": 0.787305591
    },
    {
        "x": 14.94,
        "y": 0.787305591
    },
    {
        "x": 14.95,
        "y": 0.787725935
    },
    {
        "x": 14.96,
        "y": 0.78814628
    },
    {
        "x": 14.97,
        "y": 0.78814628
    },
    {
        "x": 14.98,
        "y": 0.788566625
    },
    {
        "x": 14.99,
        "y": 0.788986969
    },
    {
        "x": 15,
        "y": 0.789407314
    },
    {
        "x": 15.01,
        "y": 0.789407314
    },
    {
        "x": 15.02,
        "y": 0.789827659
    },
    {
        "x": 15.03,
        "y": 0.790248003
    },
    {
        "x": 15.04,
        "y": 0.790668348
    },
    {
        "x": 15.05,
        "y": 0.791088693
    },
    {
        "x": 15.06,
        "y": 0.791509037
    },
    {
        "x": 15.07,
        "y": 0.791509037
    },
    {
        "x": 15.08,
        "y": 0.791929382
    },
    {
        "x": 15.09,
        "y": 0.792349727
    },
    {
        "x": 15.1,
        "y": 0.792770071
    },
    {
        "x": 15.11,
        "y": 0.792770071
    },
    {
        "x": 15.12,
        "y": 0.793190416
    },
    {
        "x": 15.13,
        "y": 0.793610761
    },
    {
        "x": 15.14,
        "y": 0.793610761
    },
    {
        "x": 15.15,
        "y": 0.794031106
    },
    {
        "x": 15.16,
        "y": 0.79445145
    },
    {
        "x": 15.17,
        "y": 0.79445145
    },
    {
        "x": 15.18,
        "y": 0.794871795
    },
    {
        "x": 15.19,
        "y": 0.79529214
    },
    {
        "x": 15.2,
        "y": 0.795712484
    },
    {
        "x": 15.21,
        "y": 0.796132829
    },
    {
        "x": 15.22,
        "y": 0.796132829
    },
    {
        "x": 15.23,
        "y": 0.796553174
    },
    {
        "x": 15.24,
        "y": 0.796973518
    },
    {
        "x": 15.25,
        "y": 0.796973518
    },
    {
        "x": 15.26,
        "y": 0.797393863
    },
    {
        "x": 15.27,
        "y": 0.797814208
    },
    {
        "x": 15.28,
        "y": 0.798234552
    },
    {
        "x": 15.29,
        "y": 0.798234552
    },
    {
        "x": 15.3,
        "y": 0.798654897
    },
    {
        "x": 15.31,
        "y": 0.799075242
    },
    {
        "x": 15.32,
        "y": 0.799075242
    },
    {
        "x": 15.33,
        "y": 0.799495586
    },
    {
        "x": 15.34,
        "y": 0.799495586
    },
    {
        "x": 15.35,
        "y": 0.799915931
    },
    {
        "x": 15.36,
        "y": 0.800336276
    },
    {
        "x": 15.37,
        "y": 0.80075662
    },
    {
        "x": 15.38,
        "y": 0.801176965
    },
    {
        "x": 15.39,
        "y": 0.801176965
    },
    {
        "x": 15.4,
        "y": 0.80159731
    },
    {
        "x": 15.41,
        "y": 0.802017654
    },
    {
        "x": 15.42,
        "y": 0.802437999
    },
    {
        "x": 15.43,
        "y": 0.802437999
    },
    {
        "x": 15.44,
        "y": 0.802858344
    },
    {
        "x": 15.45,
        "y": 0.803278689
    },
    {
        "x": 15.46,
        "y": 0.803278689
    },
    {
        "x": 15.47,
        "y": 0.803699033
    },
    {
        "x": 15.48,
        "y": 0.804119378
    },
    {
        "x": 15.49,
        "y": 0.804539723
    },
    {
        "x": 15.5,
        "y": 0.804539723
    },
    {
        "x": 15.51,
        "y": 0.804960067
    },
    {
        "x": 15.52,
        "y": 0.805380412
    },
    {
        "x": 15.53,
        "y": 0.805380412
    },
    {
        "x": 15.54,
        "y": 0.805800757
    },
    {
        "x": 15.55,
        "y": 0.805800757
    },
    {
        "x": 15.56,
        "y": 0.806221101
    },
    {
        "x": 15.57,
        "y": 0.806641446
    },
    {
        "x": 15.58,
        "y": 0.807061791
    },
    {
        "x": 15.59,
        "y": 0.807061791
    },
    {
        "x": 15.6,
        "y": 0.807482135
    },
    {
        "x": 15.61,
        "y": 0.80790248
    },
    {
        "x": 15.62,
        "y": 0.80790248
    },
    {
        "x": 15.63,
        "y": 0.808322825
    },
    {
        "x": 15.64,
        "y": 0.808322825
    },
    {
        "x": 15.65,
        "y": 0.808743169
    },
    {
        "x": 15.66,
        "y": 0.809163514
    },
    {
        "x": 15.67,
        "y": 0.809583859
    },
    {
        "x": 15.68,
        "y": 0.809583859
    },
    {
        "x": 15.69,
        "y": 0.810004203
    },
    {
        "x": 15.7,
        "y": 0.810004203
    },
    {
        "x": 15.71,
        "y": 0.810844893
    },
    {
        "x": 15.72,
        "y": 0.810844893
    },
    {
        "x": 15.73,
        "y": 0.811265237
    },
    {
        "x": 15.74,
        "y": 0.811265237
    },
    {
        "x": 15.75,
        "y": 0.811685582
    },
    {
        "x": 15.76,
        "y": 0.812105927
    },
    {
        "x": 15.77,
        "y": 0.812105927
    },
    {
        "x": 15.78,
        "y": 0.812526272
    },
    {
        "x": 15.79,
        "y": 0.812526272
    },
    {
        "x": 15.8,
        "y": 0.812946616
    },
    {
        "x": 15.81,
        "y": 0.813366961
    },
    {
        "x": 15.82,
        "y": 0.813787306
    },
    {
        "x": 15.83,
        "y": 0.81420765
    },
    {
        "x": 15.84,
        "y": 0.81420765
    },
    {
        "x": 15.85,
        "y": 0.81420765
    },
    {
        "x": 15.86,
        "y": 0.814627995
    },
    {
        "x": 15.87,
        "y": 0.81504834
    },
    {
        "x": 15.88,
        "y": 0.815468684
    },
    {
        "x": 15.89,
        "y": 0.815468684
    },
    {
        "x": 15.9,
        "y": 0.815889029
    },
    {
        "x": 15.91,
        "y": 0.815889029
    },
    {
        "x": 15.92,
        "y": 0.816309374
    },
    {
        "x": 15.93,
        "y": 0.816729718
    },
    {
        "x": 15.94,
        "y": 0.816729718
    },
    {
        "x": 15.95,
        "y": 0.817150063
    },
    {
        "x": 15.96,
        "y": 0.817150063
    },
    {
        "x": 15.97,
        "y": 0.817570408
    },
    {
        "x": 15.98,
        "y": 0.817990752
    },
    {
        "x": 15.99,
        "y": 0.818411097
    },
    {
        "x": 16,
        "y": 0.818411097
    },
    {
        "x": 16.01,
        "y": 0.818831442
    },
    {
        "x": 16.02,
        "y": 0.818831442
    },
    {
        "x": 16.03,
        "y": 0.819251786
    },
    {
        "x": 16.04,
        "y": 0.819672131
    },
    {
        "x": 16.05,
        "y": 0.819672131
    },
    {
        "x": 16.06,
        "y": 0.820092476
    },
    {
        "x": 16.07,
        "y": 0.820512821
    },
    {
        "x": 16.08,
        "y": 0.820512821
    },
    {
        "x": 16.09,
        "y": 0.820933165
    },
    {
        "x": 16.1,
        "y": 0.820933165
    },
    {
        "x": 16.11,
        "y": 0.82135351
    },
    {
        "x": 16.12,
        "y": 0.821773855
    },
    {
        "x": 16.13,
        "y": 0.821773855
    },
    {
        "x": 16.14,
        "y": 0.822194199
    },
    {
        "x": 16.15,
        "y": 0.822194199
    },
    {
        "x": 16.16,
        "y": 0.822614544
    },
    {
        "x": 16.17,
        "y": 0.822614544
    },
    {
        "x": 16.18,
        "y": 0.823034889
    },
    {
        "x": 16.19,
        "y": 0.823455233
    },
    {
        "x": 16.2,
        "y": 0.823875578
    },
    {
        "x": 16.21,
        "y": 0.823875578
    },
    {
        "x": 16.22,
        "y": 0.824295923
    },
    {
        "x": 16.23,
        "y": 0.824295923
    },
    {
        "x": 16.24,
        "y": 0.824716267
    },
    {
        "x": 16.25,
        "y": 0.824716267
    },
    {
        "x": 16.26,
        "y": 0.825136612
    },
    {
        "x": 16.27,
        "y": 0.825556957
    },
    {
        "x": 16.28,
        "y": 0.825556957
    },
    {
        "x": 16.29,
        "y": 0.825977301
    },
    {
        "x": 16.3,
        "y": 0.826397646
    },
    {
        "x": 16.31,
        "y": 0.826397646
    },
    {
        "x": 16.32,
        "y": 0.826817991
    },
    {
        "x": 16.33,
        "y": 0.826817991
    },
    {
        "x": 16.34,
        "y": 0.827238335
    },
    {
        "x": 16.35,
        "y": 0.82765868
    },
    {
        "x": 16.36,
        "y": 0.82765868
    },
    {
        "x": 16.37,
        "y": 0.828079025
    },
    {
        "x": 16.38,
        "y": 0.828079025
    },
    {
        "x": 16.39,
        "y": 0.828499369
    },
    {
        "x": 16.4,
        "y": 0.828499369
    },
    {
        "x": 16.41,
        "y": 0.828919714
    },
    {
        "x": 16.42,
        "y": 0.829340059
    },
    {
        "x": 16.43,
        "y": 0.829340059
    },
    {
        "x": 16.44,
        "y": 0.829760404
    },
    {
        "x": 16.45,
        "y": 0.829760404
    },
    {
        "x": 16.46,
        "y": 0.830180748
    },
    {
        "x": 16.47,
        "y": 0.830601093
    },
    {
        "x": 16.48,
        "y": 0.830601093
    },
    {
        "x": 16.49,
        "y": 0.831021438
    },
    {
        "x": 16.5,
        "y": 0.831021438
    },
    {
        "x": 16.51,
        "y": 0.831441782
    },
    {
        "x": 16.52,
        "y": 0.831862127
    },
    {
        "x": 16.53,
        "y": 0.831862127
    },
    {
        "x": 16.54,
        "y": 0.832282472
    },
    {
        "x": 16.55,
        "y": 0.832282472
    },
    {
        "x": 16.56,
        "y": 0.832702816
    },
    {
        "x": 16.57,
        "y": 0.832702816
    },
    {
        "x": 16.58,
        "y": 0.833123161
    },
    {
        "x": 16.59,
        "y": 0.833543506
    },
    {
        "x": 16.6,
        "y": 0.833543506
    },
    {
        "x": 16.61,
        "y": 0.833543506
    },
    {
        "x": 16.62,
        "y": 0.83396385
    },
    {
        "x": 16.63,
        "y": 0.834384195
    },
    {
        "x": 16.64,
        "y": 0.834384195
    },
    {
        "x": 16.65,
        "y": 0.83480454
    },
    {
        "x": 16.66,
        "y": 0.83480454
    },
    {
        "x": 16.67,
        "y": 0.835224884
    },
    {
        "x": 16.68,
        "y": 0.835645229
    },
    {
        "x": 16.69,
        "y": 0.835645229
    },
    {
        "x": 16.7,
        "y": 0.836065574
    },
    {
        "x": 16.71,
        "y": 0.836065574
    },
    {
        "x": 16.72,
        "y": 0.836485918
    },
    {
        "x": 16.73,
        "y": 0.836485918
    },
    {
        "x": 16.74,
        "y": 0.836906263
    },
    {
        "x": 16.75,
        "y": 0.836906263
    },
    {
        "x": 16.76,
        "y": 0.837326608
    },
    {
        "x": 16.77,
        "y": 0.837746953
    },
    {
        "x": 16.78,
        "y": 0.837746953
    },
    {
        "x": 16.79,
        "y": 0.838167297
    },
    {
        "x": 16.8,
        "y": 0.838167297
    },
    {
        "x": 16.81,
        "y": 0.838587642
    },
    {
        "x": 16.82,
        "y": 0.838587642
    },
    {
        "x": 16.83,
        "y": 0.839007987
    },
    {
        "x": 16.84,
        "y": 0.839007987
    },
    {
        "x": 16.85,
        "y": 0.839428331
    },
    {
        "x": 16.86,
        "y": 0.839848676
    },
    {
        "x": 16.87,
        "y": 0.839848676
    },
    {
        "x": 16.88,
        "y": 0.839848676
    },
    {
        "x": 16.89,
        "y": 0.840269021
    },
    {
        "x": 16.9,
        "y": 0.840269021
    },
    {
        "x": 16.91,
        "y": 0.840689365
    },
    {
        "x": 16.92,
        "y": 0.84110971
    },
    {
        "x": 16.93,
        "y": 0.84110971
    },
    {
        "x": 16.94,
        "y": 0.841530055
    },
    {
        "x": 16.95,
        "y": 0.841530055
    },
    {
        "x": 16.96,
        "y": 0.841950399
    },
    {
        "x": 16.97,
        "y": 0.841950399
    },
    {
        "x": 16.98,
        "y": 0.842370744
    },
    {
        "x": 16.99,
        "y": 0.842370744
    },
    {
        "x": 17,
        "y": 0.842791089
    },
    {
        "x": 17.01,
        "y": 0.843211433
    },
    {
        "x": 17.02,
        "y": 0.843211433
    },
    {
        "x": 17.03,
        "y": 0.843631778
    },
    {
        "x": 17.04,
        "y": 0.843631778
    },
    {
        "x": 17.05,
        "y": 0.844052123
    },
    {
        "x": 17.06,
        "y": 0.844052123
    },
    {
        "x": 17.07,
        "y": 0.844472467
    },
    {
        "x": 17.08,
        "y": 0.844472467
    },
    {
        "x": 17.09,
        "y": 0.844892812
    },
    {
        "x": 17.1,
        "y": 0.844892812
    },
    {
        "x": 17.11,
        "y": 0.845313157
    },
    {
        "x": 17.12,
        "y": 0.845313157
    },
    {
        "x": 17.13,
        "y": 0.845733501
    },
    {
        "x": 17.14,
        "y": 0.845733501
    },
    {
        "x": 17.15,
        "y": 0.846153846
    },
    {
        "x": 17.16,
        "y": 0.846153846
    },
    {
        "x": 17.17,
        "y": 0.846574191
    },
    {
        "x": 17.18,
        "y": 0.846994536
    },
    {
        "x": 17.19,
        "y": 0.846994536
    },
    {
        "x": 17.2,
        "y": 0.84741488
    },
    {
        "x": 17.21,
        "y": 0.84741488
    },
    {
        "x": 17.22,
        "y": 0.847835225
    },
    {
        "x": 17.23,
        "y": 0.847835225
    },
    {
        "x": 17.24,
        "y": 0.84825557
    },
    {
        "x": 17.25,
        "y": 0.84825557
    },
    {
        "x": 17.26,
        "y": 0.84825557
    },
    {
        "x": 17.27,
        "y": 0.848675914
    },
    {
        "x": 17.28,
        "y": 0.849096259
    },
    {
        "x": 17.29,
        "y": 0.849096259
    },
    {
        "x": 17.3,
        "y": 0.849516604
    },
    {
        "x": 17.31,
        "y": 0.849516604
    },
    {
        "x": 17.32,
        "y": 0.849936948
    },
    {
        "x": 17.33,
        "y": 0.849936948
    },
    {
        "x": 17.34,
        "y": 0.849936948
    },
    {
        "x": 17.35,
        "y": 0.850357293
    },
    {
        "x": 17.36,
        "y": 0.850777638
    },
    {
        "x": 17.37,
        "y": 0.850777638
    },
    {
        "x": 17.38,
        "y": 0.850777638
    },
    {
        "x": 17.39,
        "y": 0.851197982
    },
    {
        "x": 17.4,
        "y": 0.851618327
    },
    {
        "x": 17.41,
        "y": 0.851618327
    },
    {
        "x": 17.42,
        "y": 0.852038672
    },
    {
        "x": 17.43,
        "y": 0.852038672
    },
    {
        "x": 17.44,
        "y": 0.852459016
    },
    {
        "x": 17.45,
        "y": 0.852459016
    },
    {
        "x": 17.46,
        "y": 0.852879361
    },
    {
        "x": 17.47,
        "y": 0.852879361
    },
    {
        "x": 17.48,
        "y": 0.853299706
    },
    {
        "x": 17.49,
        "y": 0.853299706
    },
    {
        "x": 17.5,
        "y": 0.853299706
    },
    {
        "x": 17.51,
        "y": 0.85372005
    },
    {
        "x": 17.52,
        "y": 0.85372005
    },
    {
        "x": 17.53,
        "y": 0.854140395
    },
    {
        "x": 17.54,
        "y": 0.854140395
    },
    {
        "x": 17.55,
        "y": 0.85456074
    },
    {
        "x": 17.56,
        "y": 0.85456074
    },
    {
        "x": 17.57,
        "y": 0.854981084
    },
    {
        "x": 17.58,
        "y": 0.854981084
    },
    {
        "x": 17.59,
        "y": 0.855401429
    },
    {
        "x": 17.6,
        "y": 0.855401429
    },
    {
        "x": 17.61,
        "y": 0.855401429
    },
    {
        "x": 17.62,
        "y": 0.855821774
    },
    {
        "x": 17.63,
        "y": 0.856242119
    },
    {
        "x": 17.64,
        "y": 0.856242119
    },
    {
        "x": 17.65,
        "y": 0.856662463
    },
    {
        "x": 17.66,
        "y": 0.856662463
    },
    {
        "x": 17.67,
        "y": 0.857082808
    },
    {
        "x": 17.68,
        "y": 0.857082808
    },
    {
        "x": 17.69,
        "y": 0.857082808
    },
    {
        "x": 17.7,
        "y": 0.857503153
    },
    {
        "x": 17.71,
        "y": 0.857503153
    },
    {
        "x": 17.72,
        "y": 0.857923497
    },
    {
        "x": 17.73,
        "y": 0.857923497
    },
    {
        "x": 17.74,
        "y": 0.858343842
    },
    {
        "x": 17.75,
        "y": 0.858343842
    },
    {
        "x": 17.76,
        "y": 0.858764187
    },
    {
        "x": 17.77,
        "y": 0.858764187
    },
    {
        "x": 17.78,
        "y": 0.858764187
    },
    {
        "x": 17.79,
        "y": 0.859184531
    },
    {
        "x": 17.8,
        "y": 0.859184531
    },
    {
        "x": 17.81,
        "y": 0.859604876
    },
    {
        "x": 17.82,
        "y": 0.859604876
    },
    {
        "x": 17.83,
        "y": 0.860025221
    },
    {
        "x": 17.84,
        "y": 0.860025221
    },
    {
        "x": 17.85,
        "y": 0.860445565
    },
    {
        "x": 17.86,
        "y": 0.860445565
    },
    {
        "x": 17.87,
        "y": 0.860445565
    },
    {
        "x": 17.88,
        "y": 0.86086591
    },
    {
        "x": 17.89,
        "y": 0.86086591
    },
    {
        "x": 17.9,
        "y": 0.861286255
    },
    {
        "x": 17.91,
        "y": 0.861286255
    },
    {
        "x": 17.92,
        "y": 0.861706599
    },
    {
        "x": 17.93,
        "y": 0.861706599
    },
    {
        "x": 17.94,
        "y": 0.861706599
    },
    {
        "x": 17.95,
        "y": 0.862126944
    },
    {
        "x": 17.96,
        "y": 0.862547289
    },
    {
        "x": 17.97,
        "y": 0.862547289
    },
    {
        "x": 17.98,
        "y": 0.862547289
    },
    {
        "x": 17.99,
        "y": 0.862967633
    },
    {
        "x": 18,
        "y": 0.862967633
    },
    {
        "x": 18.01,
        "y": 0.863387978
    },
    {
        "x": 18.02,
        "y": 0.863387978
    },
    {
        "x": 18.03,
        "y": 0.863808323
    },
    {
        "x": 18.04,
        "y": 0.863808323
    },
    {
        "x": 18.05,
        "y": 0.863808323
    },
    {
        "x": 18.06,
        "y": 0.864228668
    },
    {
        "x": 18.07,
        "y": 0.864228668
    },
    {
        "x": 18.08,
        "y": 0.864649012
    },
    {
        "x": 18.09,
        "y": 0.864649012
    },
    {
        "x": 18.1,
        "y": 0.865069357
    },
    {
        "x": 18.11,
        "y": 0.865069357
    },
    {
        "x": 18.12,
        "y": 0.865489702
    },
    {
        "x": 18.13,
        "y": 0.865489702
    },
    {
        "x": 18.14,
        "y": 0.865910046
    },
    {
        "x": 18.15,
        "y": 0.865910046
    },
    {
        "x": 18.16,
        "y": 0.865910046
    },
    {
        "x": 18.17,
        "y": 0.866330391
    },
    {
        "x": 18.18,
        "y": 0.866330391
    },
    {
        "x": 18.19,
        "y": 0.866750736
    },
    {
        "x": 18.2,
        "y": 0.866750736
    },
    {
        "x": 18.21,
        "y": 0.86717108
    },
    {
        "x": 18.22,
        "y": 0.86717108
    },
    {
        "x": 18.23,
        "y": 0.867591425
    },
    {
        "x": 18.24,
        "y": 0.867591425
    },
    {
        "x": 18.25,
        "y": 0.86801177
    },
    {
        "x": 18.26,
        "y": 0.86801177
    },
    {
        "x": 18.27,
        "y": 0.86801177
    },
    {
        "x": 18.28,
        "y": 0.868432114
    },
    {
        "x": 18.29,
        "y": 0.868432114
    },
    {
        "x": 18.3,
        "y": 0.868852459
    },
    {
        "x": 18.31,
        "y": 0.868852459
    },
    {
        "x": 18.32,
        "y": 0.868852459
    },
    {
        "x": 18.33,
        "y": 0.869272804
    },
    {
        "x": 18.34,
        "y": 0.869272804
    },
    {
        "x": 18.35,
        "y": 0.869693148
    },
    {
        "x": 18.36,
        "y": 0.870113493
    },
    {
        "x": 18.37,
        "y": 0.870113493
    },
    {
        "x": 18.38,
        "y": 0.870113493
    },
    {
        "x": 18.39,
        "y": 0.870113493
    },
    {
        "x": 18.4,
        "y": 0.870533838
    },
    {
        "x": 18.41,
        "y": 0.870533838
    },
    {
        "x": 18.42,
        "y": 0.870533838
    },
    {
        "x": 18.43,
        "y": 0.870954182
    },
    {
        "x": 18.44,
        "y": 0.870954182
    },
    {
        "x": 18.45,
        "y": 0.871374527
    },
    {
        "x": 18.46,
        "y": 0.871374527
    },
    {
        "x": 18.47,
        "y": 0.871794872
    },
    {
        "x": 18.48,
        "y": 0.871794872
    },
    {
        "x": 18.49,
        "y": 0.872215216
    },
    {
        "x": 18.5,
        "y": 0.872215216
    },
    {
        "x": 18.51,
        "y": 0.872215216
    },
    {
        "x": 18.52,
        "y": 0.872635561
    },
    {
        "x": 18.53,
        "y": 0.872635561
    },
    {
        "x": 18.54,
        "y": 0.873055906
    },
    {
        "x": 18.55,
        "y": 0.873055906
    },
    {
        "x": 18.56,
        "y": 0.873055906
    },
    {
        "x": 18.57,
        "y": 0.873476251
    },
    {
        "x": 18.58,
        "y": 0.873476251
    },
    {
        "x": 18.59,
        "y": 0.873896595
    },
    {
        "x": 18.6,
        "y": 0.873896595
    },
    {
        "x": 18.61,
        "y": 0.87431694
    },
    {
        "x": 18.62,
        "y": 0.87431694
    },
    {
        "x": 18.63,
        "y": 0.87431694
    },
    {
        "x": 18.64,
        "y": 0.874737285
    },
    {
        "x": 18.65,
        "y": 0.874737285
    },
    {
        "x": 18.66,
        "y": 0.875157629
    },
    {
        "x": 18.67,
        "y": 0.875157629
    },
    {
        "x": 18.68,
        "y": 0.875577974
    },
    {
        "x": 18.69,
        "y": 0.875577974
    },
    {
        "x": 18.7,
        "y": 0.875577974
    },
    {
        "x": 18.71,
        "y": 0.875998319
    },
    {
        "x": 18.72,
        "y": 0.875998319
    },
    {
        "x": 18.73,
        "y": 0.876418663
    },
    {
        "x": 18.74,
        "y": 0.876418663
    },
    {
        "x": 18.75,
        "y": 0.876418663
    },
    {
        "x": 18.76,
        "y": 0.876839008
    },
    {
        "x": 18.77,
        "y": 0.876839008
    },
    {
        "x": 18.78,
        "y": 0.876839008
    },
    {
        "x": 18.79,
        "y": 0.877259353
    },
    {
        "x": 18.8,
        "y": 0.877259353
    },
    {
        "x": 18.81,
        "y": 0.877679697
    },
    {
        "x": 18.82,
        "y": 0.877679697
    },
    {
        "x": 18.83,
        "y": 0.878100042
    },
    {
        "x": 18.84,
        "y": 0.878100042
    },
    {
        "x": 18.85,
        "y": 0.878100042
    },
    {
        "x": 18.86,
        "y": 0.878520387
    },
    {
        "x": 18.87,
        "y": 0.878520387
    },
    {
        "x": 18.88,
        "y": 0.878940731
    },
    {
        "x": 18.89,
        "y": 0.878940731
    },
    {
        "x": 18.9,
        "y": 0.878940731
    },
    {
        "x": 18.91,
        "y": 0.879361076
    },
    {
        "x": 18.92,
        "y": 0.879361076
    },
    {
        "x": 18.93,
        "y": 0.879781421
    },
    {
        "x": 18.94,
        "y": 0.879781421
    },
    {
        "x": 18.95,
        "y": 0.879781421
    },
    {
        "x": 18.96,
        "y": 0.879781421
    },
    {
        "x": 18.97,
        "y": 0.880201765
    },
    {
        "x": 18.98,
        "y": 0.880201765
    },
    {
        "x": 18.99,
        "y": 0.88062211
    },
    {
        "x": 19,
        "y": 0.88062211
    },
    {
        "x": 19.01,
        "y": 0.88062211
    },
    {
        "x": 19.02,
        "y": 0.881042455
    },
    {
        "x": 19.03,
        "y": 0.881042455
    },
    {
        "x": 19.04,
        "y": 0.881462799
    },
    {
        "x": 19.05,
        "y": 0.881462799
    },
    {
        "x": 19.06,
        "y": 0.881462799
    },
    {
        "x": 19.07,
        "y": 0.881883144
    },
    {
        "x": 19.08,
        "y": 0.881883144
    },
    {
        "x": 19.09,
        "y": 0.881883144
    },
    {
        "x": 19.1,
        "y": 0.882303489
    },
    {
        "x": 19.11,
        "y": 0.882303489
    },
    {
        "x": 19.12,
        "y": 0.882723834
    },
    {
        "x": 19.13,
        "y": 0.882723834
    },
    {
        "x": 19.14,
        "y": 0.883144178
    },
    {
        "x": 19.15,
        "y": 0.883144178
    },
    {
        "x": 19.16,
        "y": 0.883144178
    },
    {
        "x": 19.17,
        "y": 0.883564523
    },
    {
        "x": 19.18,
        "y": 0.883564523
    },
    {
        "x": 19.19,
        "y": 0.883564523
    },
    {
        "x": 19.2,
        "y": 0.883984868
    },
    {
        "x": 19.21,
        "y": 0.883984868
    },
    {
        "x": 19.22,
        "y": 0.884405212
    },
    {
        "x": 19.23,
        "y": 0.884405212
    },
    {
        "x": 19.24,
        "y": 0.884405212
    },
    {
        "x": 19.25,
        "y": 0.884825557
    },
    {
        "x": 19.26,
        "y": 0.884825557
    },
    {
        "x": 19.27,
        "y": 0.884825557
    },
    {
        "x": 19.28,
        "y": 0.885245902
    },
    {
        "x": 19.29,
        "y": 0.885245902
    },
    {
        "x": 19.3,
        "y": 0.885245902
    },
    {
        "x": 19.31,
        "y": 0.885666246
    },
    {
        "x": 19.32,
        "y": 0.885666246
    },
    {
        "x": 19.33,
        "y": 0.885666246
    },
    {
        "x": 19.34,
        "y": 0.886086591
    },
    {
        "x": 19.35,
        "y": 0.886086591
    },
    {
        "x": 19.36,
        "y": 0.886086591
    },
    {
        "x": 19.37,
        "y": 0.886506936
    },
    {
        "x": 19.38,
        "y": 0.886506936
    },
    {
        "x": 19.39,
        "y": 0.88692728
    },
    {
        "x": 19.4,
        "y": 0.88692728
    },
    {
        "x": 19.41,
        "y": 0.88692728
    },
    {
        "x": 19.42,
        "y": 0.887347625
    },
    {
        "x": 19.43,
        "y": 0.887347625
    },
    {
        "x": 19.44,
        "y": 0.887347625
    },
    {
        "x": 19.45,
        "y": 0.88776797
    },
    {
        "x": 19.46,
        "y": 0.88776797
    },
    {
        "x": 19.47,
        "y": 0.888188314
    },
    {
        "x": 19.48,
        "y": 0.888188314
    },
    {
        "x": 19.49,
        "y": 0.888188314
    },
    {
        "x": 19.5,
        "y": 0.888188314
    },
    {
        "x": 19.51,
        "y": 0.888608659
    },
    {
        "x": 19.52,
        "y": 0.888608659
    },
    {
        "x": 19.53,
        "y": 0.889029004
    },
    {
        "x": 19.54,
        "y": 0.889029004
    },
    {
        "x": 19.55,
        "y": 0.889029004
    },
    {
        "x": 19.56,
        "y": 0.889449348
    },
    {
        "x": 19.57,
        "y": 0.889449348
    },
    {
        "x": 19.58,
        "y": 0.889869693
    },
    {
        "x": 19.59,
        "y": 0.889869693
    },
    {
        "x": 19.6,
        "y": 0.889869693
    },
    {
        "x": 19.61,
        "y": 0.889869693
    },
    {
        "x": 19.62,
        "y": 0.890290038
    },
    {
        "x": 19.63,
        "y": 0.890710383
    },
    {
        "x": 19.64,
        "y": 0.890710383
    },
    {
        "x": 19.65,
        "y": 0.890710383
    },
    {
        "x": 19.66,
        "y": 0.891130727
    },
    {
        "x": 19.67,
        "y": 0.891130727
    },
    {
        "x": 19.68,
        "y": 0.891130727
    },
    {
        "x": 19.69,
        "y": 0.891551072
    },
    {
        "x": 19.7,
        "y": 0.891551072
    },
    {
        "x": 19.71,
        "y": 0.891551072
    },
    {
        "x": 19.72,
        "y": 0.891971417
    },
    {
        "x": 19.73,
        "y": 0.891971417
    },
    {
        "x": 19.74,
        "y": 0.891971417
    },
    {
        "x": 19.75,
        "y": 0.892391761
    },
    {
        "x": 19.76,
        "y": 0.892391761
    },
    {
        "x": 19.77,
        "y": 0.892391761
    },
    {
        "x": 19.78,
        "y": 0.892812106
    },
    {
        "x": 19.79,
        "y": 0.892812106
    },
    {
        "x": 19.8,
        "y": 0.892812106
    },
    {
        "x": 19.81,
        "y": 0.893232451
    },
    {
        "x": 19.82,
        "y": 0.893232451
    },
    {
        "x": 19.83,
        "y": 0.893232451
    },
    {
        "x": 19.84,
        "y": 0.893652795
    },
    {
        "x": 19.85,
        "y": 0.893652795
    },
    {
        "x": 19.86,
        "y": 0.89407314
    },
    {
        "x": 19.87,
        "y": 0.89407314
    },
    {
        "x": 19.88,
        "y": 0.89407314
    },
    {
        "x": 19.89,
        "y": 0.89407314
    },
    {
        "x": 19.9,
        "y": 0.894493485
    },
    {
        "x": 19.91,
        "y": 0.894493485
    },
    {
        "x": 19.92,
        "y": 0.894493485
    },
    {
        "x": 19.93,
        "y": 0.894913829
    },
    {
        "x": 19.94,
        "y": 0.894913829
    },
    {
        "x": 19.95,
        "y": 0.895334174
    },
    {
        "x": 19.96,
        "y": 0.895334174
    },
    {
        "x": 19.97,
        "y": 0.895334174
    },
    {
        "x": 19.98,
        "y": 0.895334174
    },
    {
        "x": 19.99,
        "y": 0.895754519
    },
    {
        "x": 20,
        "y": 0.895754519
    },
    {
        "x": 20.01,
        "y": 0.896174863
    },
    {
        "x": 20.02,
        "y": 0.896174863
    },
    {
        "x": 20.03,
        "y": 0.896174863
    },
    {
        "x": 20.04,
        "y": 0.896595208
    },
    {
        "x": 20.05,
        "y": 0.896595208
    },
    {
        "x": 20.06,
        "y": 0.896595208
    },
    {
        "x": 20.07,
        "y": 0.896595208
    },
    {
        "x": 20.08,
        "y": 0.897015553
    },
    {
        "x": 20.09,
        "y": 0.897015553
    },
    {
        "x": 20.1,
        "y": 0.897435897
    },
    {
        "x": 20.11,
        "y": 0.897435897
    },
    {
        "x": 20.12,
        "y": 0.897435897
    },
    {
        "x": 20.13,
        "y": 0.897856242
    },
    {
        "x": 20.14,
        "y": 0.897856242
    },
    {
        "x": 20.15,
        "y": 0.897856242
    },
    {
        "x": 20.16,
        "y": 0.897856242
    },
    {
        "x": 20.17,
        "y": 0.898276587
    },
    {
        "x": 20.18,
        "y": 0.898276587
    },
    {
        "x": 20.19,
        "y": 0.898276587
    },
    {
        "x": 20.2,
        "y": 0.898696931
    },
    {
        "x": 20.21,
        "y": 0.898696931
    },
    {
        "x": 20.22,
        "y": 0.898696931
    },
    {
        "x": 20.23,
        "y": 0.898696931
    },
    {
        "x": 20.24,
        "y": 0.899117276
    },
    {
        "x": 20.25,
        "y": 0.899117276
    },
    {
        "x": 20.26,
        "y": 0.899117276
    },
    {
        "x": 20.27,
        "y": 0.899537621
    },
    {
        "x": 20.28,
        "y": 0.899537621
    },
    {
        "x": 20.29,
        "y": 0.899537621
    },
    {
        "x": 20.3,
        "y": 0.899537621
    },
    {
        "x": 20.31,
        "y": 0.899957966
    },
    {
        "x": 20.32,
        "y": 0.90037831
    },
    {
        "x": 20.33,
        "y": 0.90037831
    },
    {
        "x": 20.34,
        "y": 0.90037831
    },
    {
        "x": 20.35,
        "y": 0.90037831
    },
    {
        "x": 20.36,
        "y": 0.900798655
    },
    {
        "x": 20.37,
        "y": 0.900798655
    },
    {
        "x": 20.38,
        "y": 0.900798655
    },
    {
        "x": 20.39,
        "y": 0.900798655
    },
    {
        "x": 20.4,
        "y": 0.901219
    },
    {
        "x": 20.41,
        "y": 0.901219
    },
    {
        "x": 20.42,
        "y": 0.901639344
    },
    {
        "x": 20.43,
        "y": 0.901639344
    },
    {
        "x": 20.44,
        "y": 0.901639344
    },
    {
        "x": 20.45,
        "y": 0.901639344
    },
    {
        "x": 20.46,
        "y": 0.902059689
    },
    {
        "x": 20.47,
        "y": 0.902059689
    },
    {
        "x": 20.48,
        "y": 0.902480034
    },
    {
        "x": 20.49,
        "y": 0.902480034
    },
    {
        "x": 20.5,
        "y": 0.902480034
    },
    {
        "x": 20.51,
        "y": 0.902480034
    },
    {
        "x": 20.52,
        "y": 0.902900378
    },
    {
        "x": 20.53,
        "y": 0.902900378
    },
    {
        "x": 20.54,
        "y": 0.902900378
    },
    {
        "x": 20.55,
        "y": 0.903320723
    },
    {
        "x": 20.56,
        "y": 0.903320723
    },
    {
        "x": 20.57,
        "y": 0.903320723
    },
    {
        "x": 20.58,
        "y": 0.903741068
    },
    {
        "x": 20.59,
        "y": 0.903741068
    },
    {
        "x": 20.6,
        "y": 0.903741068
    },
    {
        "x": 20.61,
        "y": 0.903741068
    },
    {
        "x": 20.62,
        "y": 0.904161412
    },
    {
        "x": 20.63,
        "y": 0.904161412
    },
    {
        "x": 20.64,
        "y": 0.904161412
    },
    {
        "x": 20.65,
        "y": 0.904581757
    },
    {
        "x": 20.66,
        "y": 0.904581757
    },
    {
        "x": 20.67,
        "y": 0.904581757
    },
    {
        "x": 20.68,
        "y": 0.905002102
    },
    {
        "x": 20.69,
        "y": 0.905002102
    },
    {
        "x": 20.7,
        "y": 0.905002102
    },
    {
        "x": 20.71,
        "y": 0.905422446
    },
    {
        "x": 20.72,
        "y": 0.905422446
    },
    {
        "x": 20.73,
        "y": 0.905422446
    },
    {
        "x": 20.74,
        "y": 0.905842791
    },
    {
        "x": 20.75,
        "y": 0.905842791
    },
    {
        "x": 20.76,
        "y": 0.905842791
    },
    {
        "x": 20.77,
        "y": 0.906263136
    },
    {
        "x": 20.78,
        "y": 0.906263136
    },
    {
        "x": 20.79,
        "y": 0.906263136
    },
    {
        "x": 20.8,
        "y": 0.90668348
    },
    {
        "x": 20.81,
        "y": 0.90668348
    },
    {
        "x": 20.82,
        "y": 0.90668348
    },
    {
        "x": 20.83,
        "y": 0.90668348
    },
    {
        "x": 20.84,
        "y": 0.90668348
    },
    {
        "x": 20.85,
        "y": 0.907103825
    },
    {
        "x": 20.86,
        "y": 0.907103825
    },
    {
        "x": 20.87,
        "y": 0.90752417
    },
    {
        "x": 20.88,
        "y": 0.90752417
    },
    {
        "x": 20.89,
        "y": 0.90752417
    },
    {
        "x": 20.9,
        "y": 0.90752417
    },
    {
        "x": 20.91,
        "y": 0.907944515
    },
    {
        "x": 20.92,
        "y": 0.907944515
    },
    {
        "x": 20.93,
        "y": 0.907944515
    },
    {
        "x": 20.94,
        "y": 0.908364859
    },
    {
        "x": 20.95,
        "y": 0.908364859
    },
    {
        "x": 20.96,
        "y": 0.908364859
    },
    {
        "x": 20.97,
        "y": 0.908364859
    },
    {
        "x": 20.98,
        "y": 0.908785204
    },
    {
        "x": 20.99,
        "y": 0.908785204
    },
    {
        "x": 21,
        "y": 0.908785204
    },
    {
        "x": 21.01,
        "y": 0.909205549
    },
    {
        "x": 21.02,
        "y": 0.909205549
    },
    {
        "x": 21.03,
        "y": 0.909205549
    },
    {
        "x": 21.04,
        "y": 0.909625893
    },
    {
        "x": 21.05,
        "y": 0.909625893
    },
    {
        "x": 21.06,
        "y": 0.909625893
    },
    {
        "x": 21.07,
        "y": 0.909625893
    },
    {
        "x": 21.08,
        "y": 0.910046238
    },
    {
        "x": 21.09,
        "y": 0.910046238
    },
    {
        "x": 21.1,
        "y": 0.910046238
    },
    {
        "x": 21.11,
        "y": 0.910046238
    },
    {
        "x": 21.12,
        "y": 0.910466583
    },
    {
        "x": 21.13,
        "y": 0.910466583
    },
    {
        "x": 21.14,
        "y": 0.910466583
    },
    {
        "x": 21.15,
        "y": 0.910886927
    },
    {
        "x": 21.16,
        "y": 0.910886927
    },
    {
        "x": 21.17,
        "y": 0.910886927
    },
    {
        "x": 21.18,
        "y": 0.910886927
    },
    {
        "x": 21.19,
        "y": 0.911307272
    },
    {
        "x": 21.2,
        "y": 0.911307272
    },
    {
        "x": 21.21,
        "y": 0.911307272
    },
    {
        "x": 21.22,
        "y": 0.911727617
    },
    {
        "x": 21.23,
        "y": 0.911727617
    },
    {
        "x": 21.24,
        "y": 0.911727617
    },
    {
        "x": 21.25,
        "y": 0.912147961
    },
    {
        "x": 21.26,
        "y": 0.912147961
    },
    {
        "x": 21.27,
        "y": 0.912147961
    },
    {
        "x": 21.28,
        "y": 0.912568306
    },
    {
        "x": 21.29,
        "y": 0.912568306
    },
    {
        "x": 21.3,
        "y": 0.912568306
    },
    {
        "x": 21.31,
        "y": 0.912568306
    },
    {
        "x": 21.32,
        "y": 0.912988651
    },
    {
        "x": 21.33,
        "y": 0.912988651
    },
    {
        "x": 21.34,
        "y": 0.912988651
    },
    {
        "x": 21.35,
        "y": 0.912988651
    },
    {
        "x": 21.36,
        "y": 0.912988651
    },
    {
        "x": 21.37,
        "y": 0.913408995
    },
    {
        "x": 21.38,
        "y": 0.913408995
    },
    {
        "x": 21.39,
        "y": 0.913408995
    },
    {
        "x": 21.4,
        "y": 0.91382934
    },
    {
        "x": 21.41,
        "y": 0.91382934
    },
    {
        "x": 21.42,
        "y": 0.91382934
    },
    {
        "x": 21.43,
        "y": 0.91382934
    },
    {
        "x": 21.44,
        "y": 0.914249685
    },
    {
        "x": 21.45,
        "y": 0.914249685
    },
    {
        "x": 21.46,
        "y": 0.914670029
    },
    {
        "x": 21.47,
        "y": 0.914670029
    },
    {
        "x": 21.48,
        "y": 0.914670029
    },
    {
        "x": 21.49,
        "y": 0.914670029
    },
    {
        "x": 21.5,
        "y": 0.914670029
    },
    {
        "x": 21.51,
        "y": 0.915090374
    },
    {
        "x": 21.52,
        "y": 0.915090374
    },
    {
        "x": 21.53,
        "y": 0.915090374
    },
    {
        "x": 21.54,
        "y": 0.915510719
    },
    {
        "x": 21.55,
        "y": 0.915510719
    },
    {
        "x": 21.56,
        "y": 0.915510719
    },
    {
        "x": 21.57,
        "y": 0.915510719
    },
    {
        "x": 21.58,
        "y": 0.915510719
    },
    {
        "x": 21.59,
        "y": 0.915931063
    },
    {
        "x": 21.6,
        "y": 0.915931063
    },
    {
        "x": 21.61,
        "y": 0.915931063
    },
    {
        "x": 21.62,
        "y": 0.915931063
    },
    {
        "x": 21.63,
        "y": 0.915931063
    },
    {
        "x": 21.64,
        "y": 0.916351408
    },
    {
        "x": 21.65,
        "y": 0.916351408
    },
    {
        "x": 21.66,
        "y": 0.916771753
    },
    {
        "x": 21.67,
        "y": 0.916771753
    },
    {
        "x": 21.68,
        "y": 0.916771753
    },
    {
        "x": 21.69,
        "y": 0.916771753
    },
    {
        "x": 21.7,
        "y": 0.917192098
    },
    {
        "x": 21.71,
        "y": 0.917192098
    },
    {
        "x": 21.72,
        "y": 0.917192098
    },
    {
        "x": 21.73,
        "y": 0.917192098
    },
    {
        "x": 21.74,
        "y": 0.917612442
    },
    {
        "x": 21.75,
        "y": 0.917612442
    },
    {
        "x": 21.76,
        "y": 0.917612442
    },
    {
        "x": 21.77,
        "y": 0.917612442
    },
    {
        "x": 21.78,
        "y": 0.918032787
    },
    {
        "x": 21.79,
        "y": 0.918032787
    },
    {
        "x": 21.8,
        "y": 0.918032787
    },
    {
        "x": 21.81,
        "y": 0.918032787
    },
    {
        "x": 21.82,
        "y": 0.918453132
    },
    {
        "x": 21.83,
        "y": 0.918453132
    },
    {
        "x": 21.84,
        "y": 0.918453132
    },
    {
        "x": 21.85,
        "y": 0.918453132
    },
    {
        "x": 21.86,
        "y": 0.918873476
    },
    {
        "x": 21.87,
        "y": 0.918873476
    },
    {
        "x": 21.88,
        "y": 0.918873476
    },
    {
        "x": 21.89,
        "y": 0.919293821
    },
    {
        "x": 21.9,
        "y": 0.919293821
    },
    {
        "x": 21.91,
        "y": 0.919293821
    },
    {
        "x": 21.92,
        "y": 0.919293821
    },
    {
        "x": 21.93,
        "y": 0.919293821
    },
    {
        "x": 21.94,
        "y": 0.919714166
    },
    {
        "x": 21.95,
        "y": 0.919714166
    },
    {
        "x": 21.96,
        "y": 0.919714166
    },
    {
        "x": 21.97,
        "y": 0.92013451
    },
    {
        "x": 21.98,
        "y": 0.92013451
    },
    {
        "x": 21.99,
        "y": 0.92013451
    },
    {
        "x": 22,
        "y": 0.92013451
    },
    {
        "x": 22.01,
        "y": 0.920554855
    },
    {
        "x": 22.02,
        "y": 0.920554855
    },
    {
        "x": 22.03,
        "y": 0.920554855
    },
    {
        "x": 22.04,
        "y": 0.920554855
    },
    {
        "x": 22.05,
        "y": 0.9209752
    },
    {
        "x": 22.06,
        "y": 0.9209752
    },
    {
        "x": 22.07,
        "y": 0.9209752
    },
    {
        "x": 22.08,
        "y": 0.921395544
    },
    {
        "x": 22.09,
        "y": 0.921395544
    },
    {
        "x": 22.1,
        "y": 0.921395544
    },
    {
        "x": 22.11,
        "y": 0.921395544
    },
    {
        "x": 22.12,
        "y": 0.921815889
    },
    {
        "x": 22.13,
        "y": 0.921815889
    },
    {
        "x": 22.14,
        "y": 0.921815889
    },
    {
        "x": 22.15,
        "y": 0.921815889
    },
    {
        "x": 22.16,
        "y": 0.921815889
    },
    {
        "x": 22.17,
        "y": 0.922236234
    },
    {
        "x": 22.18,
        "y": 0.922236234
    },
    {
        "x": 22.19,
        "y": 0.922236234
    },
    {
        "x": 22.2,
        "y": 0.922236234
    },
    {
        "x": 22.21,
        "y": 0.922656578
    },
    {
        "x": 22.22,
        "y": 0.922656578
    },
    {
        "x": 22.23,
        "y": 0.922656578
    },
    {
        "x": 22.24,
        "y": 0.923076923
    },
    {
        "x": 22.25,
        "y": 0.923076923
    },
    {
        "x": 22.26,
        "y": 0.923076923
    },
    {
        "x": 22.27,
        "y": 0.923076923
    },
    {
        "x": 22.28,
        "y": 0.923076923
    },
    {
        "x": 22.29,
        "y": 0.923497268
    },
    {
        "x": 22.3,
        "y": 0.923497268
    },
    {
        "x": 22.31,
        "y": 0.923497268
    },
    {
        "x": 22.32,
        "y": 0.923497268
    },
    {
        "x": 22.33,
        "y": 0.923917612
    },
    {
        "x": 22.34,
        "y": 0.923917612
    },
    {
        "x": 22.35,
        "y": 0.923917612
    },
    {
        "x": 22.36,
        "y": 0.923917612
    },
    {
        "x": 22.37,
        "y": 0.924337957
    },
    {
        "x": 22.38,
        "y": 0.924337957
    },
    {
        "x": 22.39,
        "y": 0.924337957
    },
    {
        "x": 22.4,
        "y": 0.924758302
    },
    {
        "x": 22.41,
        "y": 0.924758302
    },
    {
        "x": 22.42,
        "y": 0.924758302
    },
    {
        "x": 22.43,
        "y": 0.924758302
    },
    {
        "x": 22.44,
        "y": 0.924758302
    },
    {
        "x": 22.45,
        "y": 0.925178646
    },
    {
        "x": 22.46,
        "y": 0.925178646
    },
    {
        "x": 22.47,
        "y": 0.925178646
    },
    {
        "x": 22.48,
        "y": 0.925178646
    },
    {
        "x": 22.49,
        "y": 0.925178646
    },
    {
        "x": 22.5,
        "y": 0.925598991
    },
    {
        "x": 22.51,
        "y": 0.925598991
    },
    {
        "x": 22.52,
        "y": 0.926019336
    },
    {
        "x": 22.53,
        "y": 0.926019336
    },
    {
        "x": 22.54,
        "y": 0.926019336
    },
    {
        "x": 22.55,
        "y": 0.926019336
    },
    {
        "x": 22.56,
        "y": 0.926439681
    },
    {
        "x": 22.57,
        "y": 0.926439681
    },
    {
        "x": 22.58,
        "y": 0.926439681
    },
    {
        "x": 22.59,
        "y": 0.926439681
    },
    {
        "x": 22.6,
        "y": 0.926439681
    },
    {
        "x": 22.61,
        "y": 0.926860025
    },
    {
        "x": 22.62,
        "y": 0.926860025
    },
    {
        "x": 22.63,
        "y": 0.926860025
    },
    {
        "x": 22.64,
        "y": 0.926860025
    },
    {
        "x": 22.65,
        "y": 0.926860025
    },
    {
        "x": 22.66,
        "y": 0.92728037
    },
    {
        "x": 22.67,
        "y": 0.92728037
    },
    {
        "x": 22.68,
        "y": 0.92728037
    },
    {
        "x": 22.69,
        "y": 0.92728037
    },
    {
        "x": 22.7,
        "y": 0.92728037
    },
    {
        "x": 22.71,
        "y": 0.927700715
    },
    {
        "x": 22.72,
        "y": 0.927700715
    },
    {
        "x": 22.73,
        "y": 0.927700715
    },
    {
        "x": 22.74,
        "y": 0.927700715
    },
    {
        "x": 22.75,
        "y": 0.928121059
    },
    {
        "x": 22.76,
        "y": 0.928121059
    },
    {
        "x": 22.77,
        "y": 0.928121059
    },
    {
        "x": 22.78,
        "y": 0.928121059
    },
    {
        "x": 22.79,
        "y": 0.928541404
    },
    {
        "x": 22.8,
        "y": 0.928541404
    },
    {
        "x": 22.81,
        "y": 0.928541404
    },
    {
        "x": 22.82,
        "y": 0.928541404
    },
    {
        "x": 22.83,
        "y": 0.928541404
    },
    {
        "x": 22.84,
        "y": 0.928961749
    },
    {
        "x": 22.85,
        "y": 0.928961749
    },
    {
        "x": 22.86,
        "y": 0.928961749
    },
    {
        "x": 22.87,
        "y": 0.928961749
    },
    {
        "x": 22.88,
        "y": 0.929382093
    },
    {
        "x": 22.89,
        "y": 0.929382093
    },
    {
        "x": 22.9,
        "y": 0.929382093
    },
    {
        "x": 22.91,
        "y": 0.929382093
    },
    {
        "x": 22.92,
        "y": 0.929382093
    },
    {
        "x": 22.93,
        "y": 0.929802438
    },
    {
        "x": 22.94,
        "y": 0.929802438
    },
    {
        "x": 22.95,
        "y": 0.929802438
    },
    {
        "x": 22.96,
        "y": 0.929802438
    },
    {
        "x": 22.97,
        "y": 0.929802438
    },
    {
        "x": 22.98,
        "y": 0.930222783
    },
    {
        "x": 22.99,
        "y": 0.930222783
    },
    {
        "x": 23,
        "y": 0.930222783
    },
    {
        "x": 23.01,
        "y": 0.930643127
    },
    {
        "x": 23.02,
        "y": 0.930643127
    },
    {
        "x": 23.03,
        "y": 0.930643127
    },
    {
        "x": 23.04,
        "y": 0.930643127
    },
    {
        "x": 23.05,
        "y": 0.931063472
    },
    {
        "x": 23.06,
        "y": 0.931063472
    },
    {
        "x": 23.07,
        "y": 0.931063472
    },
    {
        "x": 23.08,
        "y": 0.931063472
    },
    {
        "x": 23.09,
        "y": 0.931483817
    },
    {
        "x": 23.1,
        "y": 0.931483817
    },
    {
        "x": 23.11,
        "y": 0.931483817
    },
    {
        "x": 23.12,
        "y": 0.931483817
    },
    {
        "x": 23.13,
        "y": 0.931483817
    },
    {
        "x": 23.14,
        "y": 0.931904161
    },
    {
        "x": 23.15,
        "y": 0.931904161
    },
    {
        "x": 23.16,
        "y": 0.931904161
    },
    {
        "x": 23.17,
        "y": 0.931904161
    },
    {
        "x": 23.18,
        "y": 0.931904161
    },
    {
        "x": 23.19,
        "y": 0.932324506
    },
    {
        "x": 23.2,
        "y": 0.932324506
    },
    {
        "x": 23.21,
        "y": 0.932324506
    },
    {
        "x": 23.22,
        "y": 0.932324506
    },
    {
        "x": 23.23,
        "y": 0.932324506
    },
    {
        "x": 23.24,
        "y": 0.932744851
    },
    {
        "x": 23.25,
        "y": 0.932324506
    },
    {
        "x": 23.26,
        "y": 0.932744851
    },
    {
        "x": 23.27,
        "y": 0.932744851
    },
    {
        "x": 23.28,
        "y": 0.932744851
    },
    {
        "x": 23.29,
        "y": 0.932744851
    },
    {
        "x": 23.3,
        "y": 0.933165195
    },
    {
        "x": 23.31,
        "y": 0.933165195
    },
    {
        "x": 23.32,
        "y": 0.933165195
    },
    {
        "x": 23.33,
        "y": 0.93358554
    },
    {
        "x": 23.34,
        "y": 0.933165195
    },
    {
        "x": 23.35,
        "y": 0.93358554
    },
    {
        "x": 23.36,
        "y": 0.93358554
    },
    {
        "x": 23.37,
        "y": 0.93358554
    },
    {
        "x": 23.38,
        "y": 0.934005885
    },
    {
        "x": 23.39,
        "y": 0.934005885
    },
    {
        "x": 23.4,
        "y": 0.934005885
    },
    {
        "x": 23.41,
        "y": 0.934005885
    },
    {
        "x": 23.42,
        "y": 0.93442623
    },
    {
        "x": 23.43,
        "y": 0.93442623
    },
    {
        "x": 23.44,
        "y": 0.93442623
    },
    {
        "x": 23.45,
        "y": 0.93442623
    },
    {
        "x": 23.46,
        "y": 0.93442623
    },
    {
        "x": 23.47,
        "y": 0.93442623
    },
    {
        "x": 23.48,
        "y": 0.93442623
    },
    {
        "x": 23.49,
        "y": 0.93442623
    },
    {
        "x": 23.5,
        "y": 0.934846574
    },
    {
        "x": 23.51,
        "y": 0.934846574
    },
    {
        "x": 23.52,
        "y": 0.934846574
    },
    {
        "x": 23.53,
        "y": 0.935266919
    },
    {
        "x": 23.54,
        "y": 0.935266919
    },
    {
        "x": 23.55,
        "y": 0.935266919
    },
    {
        "x": 23.56,
        "y": 0.935266919
    },
    {
        "x": 23.57,
        "y": 0.935266919
    },
    {
        "x": 23.58,
        "y": 0.935687264
    },
    {
        "x": 23.59,
        "y": 0.935687264
    },
    {
        "x": 23.6,
        "y": 0.935687264
    },
    {
        "x": 23.61,
        "y": 0.935687264
    },
    {
        "x": 23.62,
        "y": 0.936107608
    },
    {
        "x": 23.63,
        "y": 0.936107608
    },
    {
        "x": 23.64,
        "y": 0.936107608
    },
    {
        "x": 23.65,
        "y": 0.936107608
    },
    {
        "x": 23.66,
        "y": 0.936527953
    },
    {
        "x": 23.67,
        "y": 0.936527953
    },
    {
        "x": 23.68,
        "y": 0.936527953
    },
    {
        "x": 23.69,
        "y": 0.936527953
    },
    {
        "x": 23.7,
        "y": 0.936948298
    },
    {
        "x": 23.71,
        "y": 0.936948298
    },
    {
        "x": 23.72,
        "y": 0.936948298
    },
    {
        "x": 23.73,
        "y": 0.936948298
    },
    {
        "x": 23.74,
        "y": 0.936948298
    },
    {
        "x": 23.75,
        "y": 0.937368642
    },
    {
        "x": 23.76,
        "y": 0.937368642
    },
    {
        "x": 23.77,
        "y": 0.937368642
    },
    {
        "x": 23.78,
        "y": 0.937368642
    },
    {
        "x": 23.79,
        "y": 0.937368642
    },
    {
        "x": 23.8,
        "y": 0.937368642
    },
    {
        "x": 23.81,
        "y": 0.937788987
    },
    {
        "x": 23.82,
        "y": 0.937788987
    },
    {
        "x": 23.83,
        "y": 0.937788987
    },
    {
        "x": 23.84,
        "y": 0.937788987
    },
    {
        "x": 23.85,
        "y": 0.938209332
    },
    {
        "x": 23.86,
        "y": 0.938209332
    },
    {
        "x": 23.87,
        "y": 0.938209332
    },
    {
        "x": 23.88,
        "y": 0.938209332
    },
    {
        "x": 23.89,
        "y": 0.938209332
    },
    {
        "x": 23.9,
        "y": 0.938209332
    },
    {
        "x": 23.91,
        "y": 0.938209332
    },
    {
        "x": 23.92,
        "y": 0.938629676
    },
    {
        "x": 23.93,
        "y": 0.938629676
    },
    {
        "x": 23.94,
        "y": 0.938629676
    },
    {
        "x": 23.95,
        "y": 0.938629676
    },
    {
        "x": 23.96,
        "y": 0.939050021
    },
    {
        "x": 23.97,
        "y": 0.938629676
    },
    {
        "x": 23.98,
        "y": 0.939050021
    },
    {
        "x": 23.99,
        "y": 0.939050021
    },
    {
        "x": 24,
        "y": 0.939050021
    },
    {
        "x": 24.01,
        "y": 0.939050021
    },
    {
        "x": 24.02,
        "y": 0.939470366
    },
    {
        "x": 24.03,
        "y": 0.939470366
    },
    {
        "x": 24.04,
        "y": 0.939470366
    },
    {
        "x": 24.05,
        "y": 0.939470366
    },
    {
        "x": 24.06,
        "y": 0.939470366
    },
    {
        "x": 24.07,
        "y": 0.93989071
    },
    {
        "x": 24.08,
        "y": 0.93989071
    },
    {
        "x": 24.09,
        "y": 0.93989071
    },
    {
        "x": 24.1,
        "y": 0.93989071
    },
    {
        "x": 24.11,
        "y": 0.93989071
    },
    {
        "x": 24.12,
        "y": 0.940311055
    },
    {
        "x": 24.13,
        "y": 0.940311055
    },
    {
        "x": 24.14,
        "y": 0.940311055
    },
    {
        "x": 24.15,
        "y": 0.940311055
    },
    {
        "x": 24.16,
        "y": 0.9407314
    },
    {
        "x": 24.17,
        "y": 0.940311055
    },
    {
        "x": 24.18,
        "y": 0.9407314
    },
    {
        "x": 24.19,
        "y": 0.9407314
    },
    {
        "x": 24.2,
        "y": 0.9407314
    },
    {
        "x": 24.21,
        "y": 0.9407314
    },
    {
        "x": 24.22,
        "y": 0.9407314
    },
    {
        "x": 24.23,
        "y": 0.941151744
    },
    {
        "x": 24.24,
        "y": 0.941151744
    },
    {
        "x": 24.25,
        "y": 0.941151744
    },
    {
        "x": 24.26,
        "y": 0.941151744
    },
    {
        "x": 24.27,
        "y": 0.941151744
    },
    {
        "x": 24.28,
        "y": 0.941572089
    },
    {
        "x": 24.29,
        "y": 0.941572089
    },
    {
        "x": 24.3,
        "y": 0.941572089
    },
    {
        "x": 24.31,
        "y": 0.941572089
    },
    {
        "x": 24.32,
        "y": 0.941572089
    },
    {
        "x": 24.33,
        "y": 0.941992434
    },
    {
        "x": 24.34,
        "y": 0.941992434
    },
    {
        "x": 24.35,
        "y": 0.941992434
    },
    {
        "x": 24.36,
        "y": 0.941992434
    },
    {
        "x": 24.37,
        "y": 0.941992434
    },
    {
        "x": 24.38,
        "y": 0.942412778
    },
    {
        "x": 24.39,
        "y": 0.941992434
    },
    {
        "x": 24.4,
        "y": 0.942412778
    },
    {
        "x": 24.41,
        "y": 0.942412778
    },
    {
        "x": 24.42,
        "y": 0.942412778
    },
    {
        "x": 24.43,
        "y": 0.942833123
    },
    {
        "x": 24.44,
        "y": 0.942833123
    },
    {
        "x": 24.45,
        "y": 0.942833123
    },
    {
        "x": 24.46,
        "y": 0.942833123
    },
    {
        "x": 24.47,
        "y": 0.942833123
    },
    {
        "x": 24.48,
        "y": 0.942833123
    },
    {
        "x": 24.49,
        "y": 0.943253468
    },
    {
        "x": 24.5,
        "y": 0.943253468
    },
    {
        "x": 24.51,
        "y": 0.943253468
    },
    {
        "x": 24.52,
        "y": 0.943253468
    },
    {
        "x": 24.53,
        "y": 0.943673813
    },
    {
        "x": 24.54,
        "y": 0.943673813
    },
    {
        "x": 24.55,
        "y": 0.943673813
    },
    {
        "x": 24.56,
        "y": 0.943673813
    },
    {
        "x": 24.57,
        "y": 0.943673813
    },
    {
        "x": 24.58,
        "y": 0.943673813
    },
    {
        "x": 24.59,
        "y": 0.944094157
    },
    {
        "x": 24.6,
        "y": 0.944094157
    },
    {
        "x": 24.61,
        "y": 0.944094157
    },
    {
        "x": 24.62,
        "y": 0.944094157
    },
    {
        "x": 24.63,
        "y": 0.944094157
    },
    {
        "x": 24.64,
        "y": 0.944094157
    },
    {
        "x": 24.65,
        "y": 0.944514502
    },
    {
        "x": 24.66,
        "y": 0.944514502
    },
    {
        "x": 24.67,
        "y": 0.944514502
    },
    {
        "x": 24.68,
        "y": 0.944514502
    },
    {
        "x": 24.69,
        "y": 0.944934847
    },
    {
        "x": 24.7,
        "y": 0.944514502
    },
    {
        "x": 24.71,
        "y": 0.944934847
    },
    {
        "x": 24.72,
        "y": 0.944934847
    },
    {
        "x": 24.73,
        "y": 0.944934847
    },
    {
        "x": 24.74,
        "y": 0.944934847
    },
    {
        "x": 24.75,
        "y": 0.944934847
    },
    {
        "x": 24.76,
        "y": 0.945355191
    },
    {
        "x": 24.77,
        "y": 0.945355191
    },
    {
        "x": 24.78,
        "y": 0.945355191
    },
    {
        "x": 24.79,
        "y": 0.945355191
    },
    {
        "x": 24.8,
        "y": 0.945355191
    },
    {
        "x": 24.81,
        "y": 0.945355191
    },
    {
        "x": 24.82,
        "y": 0.945775536
    },
    {
        "x": 24.83,
        "y": 0.945775536
    },
    {
        "x": 24.84,
        "y": 0.945775536
    },
    {
        "x": 24.85,
        "y": 0.945775536
    },
    {
        "x": 24.86,
        "y": 0.945775536
    },
    {
        "x": 24.87,
        "y": 0.946195881
    },
    {
        "x": 24.88,
        "y": 0.946195881
    },
    {
        "x": 24.89,
        "y": 0.946195881
    },
    {
        "x": 24.9,
        "y": 0.946195881
    },
    {
        "x": 24.91,
        "y": 0.946195881
    },
    {
        "x": 24.92,
        "y": 0.946616225
    },
    {
        "x": 24.93,
        "y": 0.946616225
    },
    {
        "x": 24.94,
        "y": 0.946616225
    },
    {
        "x": 24.95,
        "y": 0.946616225
    },
    {
        "x": 24.96,
        "y": 0.946616225
    },
    {
        "x": 24.97,
        "y": 0.946616225
    },
    {
        "x": 24.98,
        "y": 0.94703657
    },
    {
        "x": 24.99,
        "y": 0.94703657
    },
    {
        "x": 25,
        "y": 0.94703657
    },
    {
        "x": 25.01,
        "y": 0.94703657
    },
    {
        "x": 25.02,
        "y": 0.94703657
    },
    {
        "x": 25.03,
        "y": 0.947456915
    },
    {
        "x": 25.04,
        "y": 0.947456915
    },
    {
        "x": 25.05,
        "y": 0.947456915
    },
    {
        "x": 25.06,
        "y": 0.947456915
    },
    {
        "x": 25.07,
        "y": 0.947456915
    },
    {
        "x": 25.08,
        "y": 0.947456915
    },
    {
        "x": 25.09,
        "y": 0.947456915
    },
    {
        "x": 25.1,
        "y": 0.947877259
    },
    {
        "x": 25.11,
        "y": 0.947877259
    },
    {
        "x": 25.12,
        "y": 0.947877259
    },
    {
        "x": 25.13,
        "y": 0.947877259
    },
    {
        "x": 25.14,
        "y": 0.947877259
    },
    {
        "x": 25.15,
        "y": 0.948297604
    },
    {
        "x": 25.16,
        "y": 0.948297604
    },
    {
        "x": 25.17,
        "y": 0.948297604
    },
    {
        "x": 25.18,
        "y": 0.948297604
    },
    {
        "x": 25.19,
        "y": 0.948297604
    },
    {
        "x": 25.2,
        "y": 0.948297604
    },
    {
        "x": 25.21,
        "y": 0.948717949
    },
    {
        "x": 25.22,
        "y": 0.948717949
    },
    {
        "x": 25.23,
        "y": 0.948717949
    },
    {
        "x": 25.24,
        "y": 0.948717949
    },
    {
        "x": 25.25,
        "y": 0.948717949
    },
    {
        "x": 25.26,
        "y": 0.948717949
    },
    {
        "x": 25.27,
        "y": 0.948717949
    },
    {
        "x": 25.28,
        "y": 0.949138293
    },
    {
        "x": 25.29,
        "y": 0.949138293
    },
    {
        "x": 25.3,
        "y": 0.949138293
    },
    {
        "x": 25.31,
        "y": 0.949138293
    },
    {
        "x": 25.32,
        "y": 0.949138293
    },
    {
        "x": 25.33,
        "y": 0.949138293
    },
    {
        "x": 25.34,
        "y": 0.949558638
    },
    {
        "x": 25.35,
        "y": 0.949558638
    },
    {
        "x": 25.36,
        "y": 0.949558638
    },
    {
        "x": 25.37,
        "y": 0.949558638
    },
    {
        "x": 25.38,
        "y": 0.949558638
    },
    {
        "x": 25.39,
        "y": 0.949558638
    },
    {
        "x": 25.4,
        "y": 0.949978983
    },
    {
        "x": 25.41,
        "y": 0.949978983
    },
    {
        "x": 25.42,
        "y": 0.949978983
    },
    {
        "x": 25.43,
        "y": 0.949978983
    },
    {
        "x": 25.44,
        "y": 0.949978983
    },
    {
        "x": 25.45,
        "y": 0.949978983
    },
    {
        "x": 25.46,
        "y": 0.950399327
    },
    {
        "x": 25.47,
        "y": 0.949978983
    },
    {
        "x": 25.48,
        "y": 0.950399327
    },
    {
        "x": 25.49,
        "y": 0.950399327
    },
    {
        "x": 25.5,
        "y": 0.950399327
    },
    {
        "x": 25.51,
        "y": 0.950399327
    },
    {
        "x": 25.52,
        "y": 0.950819672
    },
    {
        "x": 25.53,
        "y": 0.950399327
    },
    {
        "x": 25.54,
        "y": 0.950819672
    },
    {
        "x": 25.55,
        "y": 0.950819672
    },
    {
        "x": 25.56,
        "y": 0.950819672
    },
    {
        "x": 25.57,
        "y": 0.951240017
    },
    {
        "x": 25.58,
        "y": 0.951240017
    },
    {
        "x": 25.59,
        "y": 0.951240017
    },
    {
        "x": 25.6,
        "y": 0.951240017
    },
    {
        "x": 25.61,
        "y": 0.951240017
    },
    {
        "x": 25.62,
        "y": 0.951240017
    },
    {
        "x": 25.63,
        "y": 0.951240017
    },
    {
        "x": 25.64,
        "y": 0.951660361
    },
    {
        "x": 25.65,
        "y": 0.951660361
    },
    {
        "x": 25.66,
        "y": 0.951660361
    },
    {
        "x": 25.67,
        "y": 0.951660361
    },
    {
        "x": 25.68,
        "y": 0.951660361
    },
    {
        "x": 25.69,
        "y": 0.951660361
    },
    {
        "x": 25.7,
        "y": 0.951660361
    },
    {
        "x": 25.71,
        "y": 0.952080706
    },
    {
        "x": 25.72,
        "y": 0.952080706
    },
    {
        "x": 25.73,
        "y": 0.952080706
    },
    {
        "x": 25.74,
        "y": 0.952080706
    },
    {
        "x": 25.75,
        "y": 0.952080706
    },
    {
        "x": 25.76,
        "y": 0.952080706
    },
    {
        "x": 25.77,
        "y": 0.952080706
    },
    {
        "x": 25.78,
        "y": 0.952501051
    },
    {
        "x": 25.79,
        "y": 0.952501051
    },
    {
        "x": 25.8,
        "y": 0.952501051
    },
    {
        "x": 25.81,
        "y": 0.952501051
    },
    {
        "x": 25.82,
        "y": 0.952921396
    },
    {
        "x": 25.83,
        "y": 0.952501051
    },
    {
        "x": 25.84,
        "y": 0.952921396
    },
    {
        "x": 25.85,
        "y": 0.952921396
    },
    {
        "x": 25.86,
        "y": 0.952921396
    },
    {
        "x": 25.87,
        "y": 0.952921396
    },
    {
        "x": 25.88,
        "y": 0.95334174
    },
    {
        "x": 25.89,
        "y": 0.952921396
    },
    {
        "x": 25.9,
        "y": 0.95334174
    },
    {
        "x": 25.91,
        "y": 0.95334174
    },
    {
        "x": 25.92,
        "y": 0.95334174
    },
    {
        "x": 25.93,
        "y": 0.95334174
    },
    {
        "x": 25.94,
        "y": 0.95334174
    },
    {
        "x": 25.95,
        "y": 0.953762085
    },
    {
        "x": 25.96,
        "y": 0.953762085
    },
    {
        "x": 25.97,
        "y": 0.953762085
    },
    {
        "x": 25.98,
        "y": 0.953762085
    },
    {
        "x": 25.99,
        "y": 0.953762085
    },
    {
        "x": 26,
        "y": 0.953762085
    },
    {
        "x": 26.01,
        "y": 0.953762085
    },
    {
        "x": 26.02,
        "y": 0.95418243
    },
    {
        "x": 26.03,
        "y": 0.95418243
    },
    {
        "x": 26.04,
        "y": 0.95418243
    },
    {
        "x": 26.05,
        "y": 0.95418243
    },
    {
        "x": 26.06,
        "y": 0.95418243
    },
    {
        "x": 26.07,
        "y": 0.95418243
    },
    {
        "x": 26.08,
        "y": 0.954602774
    },
    {
        "x": 26.09,
        "y": 0.954602774
    },
    {
        "x": 26.1,
        "y": 0.954602774
    },
    {
        "x": 26.11,
        "y": 0.954602774
    },
    {
        "x": 26.12,
        "y": 0.955023119
    },
    {
        "x": 26.13,
        "y": 0.954602774
    },
    {
        "x": 26.14,
        "y": 0.954602774
    },
    {
        "x": 26.15,
        "y": 0.955023119
    },
    {
        "x": 26.16,
        "y": 0.955023119
    },
    {
        "x": 26.17,
        "y": 0.955023119
    },
    {
        "x": 26.18,
        "y": 0.955023119
    },
    {
        "x": 26.19,
        "y": 0.955023119
    },
    {
        "x": 26.2,
        "y": 0.955023119
    },
    {
        "x": 26.21,
        "y": 0.955023119
    },
    {
        "x": 26.22,
        "y": 0.955023119
    },
    {
        "x": 26.23,
        "y": 0.955443464
    },
    {
        "x": 26.24,
        "y": 0.955443464
    },
    {
        "x": 26.25,
        "y": 0.955443464
    },
    {
        "x": 26.26,
        "y": 0.955443464
    },
    {
        "x": 26.27,
        "y": 0.955443464
    },
    {
        "x": 26.28,
        "y": 0.955443464
    },
    {
        "x": 26.29,
        "y": 0.955863808
    },
    {
        "x": 26.3,
        "y": 0.955863808
    },
    {
        "x": 26.31,
        "y": 0.955863808
    },
    {
        "x": 26.32,
        "y": 0.955863808
    },
    {
        "x": 26.33,
        "y": 0.955863808
    },
    {
        "x": 26.34,
        "y": 0.955863808
    },
    {
        "x": 26.35,
        "y": 0.955863808
    },
    {
        "x": 26.36,
        "y": 0.956284153
    },
    {
        "x": 26.37,
        "y": 0.956284153
    },
    {
        "x": 26.38,
        "y": 0.956284153
    },
    {
        "x": 26.39,
        "y": 0.956284153
    },
    {
        "x": 26.4,
        "y": 0.956284153
    },
    {
        "x": 26.41,
        "y": 0.956284153
    },
    {
        "x": 26.42,
        "y": 0.956704498
    },
    {
        "x": 26.43,
        "y": 0.956284153
    },
    {
        "x": 26.44,
        "y": 0.956704498
    },
    {
        "x": 26.45,
        "y": 0.956704498
    },
    {
        "x": 26.46,
        "y": 0.956704498
    },
    {
        "x": 26.47,
        "y": 0.956704498
    },
    {
        "x": 26.48,
        "y": 0.956704498
    },
    {
        "x": 26.49,
        "y": 0.956704498
    },
    {
        "x": 26.5,
        "y": 0.956704498
    },
    {
        "x": 26.51,
        "y": 0.956704498
    },
    {
        "x": 26.52,
        "y": 0.957124842
    },
    {
        "x": 26.53,
        "y": 0.957124842
    },
    {
        "x": 26.54,
        "y": 0.957124842
    },
    {
        "x": 26.55,
        "y": 0.957124842
    },
    {
        "x": 26.56,
        "y": 0.957124842
    },
    {
        "x": 26.57,
        "y": 0.957124842
    },
    {
        "x": 26.58,
        "y": 0.957545187
    },
    {
        "x": 26.59,
        "y": 0.957545187
    },
    {
        "x": 26.6,
        "y": 0.957545187
    },
    {
        "x": 26.61,
        "y": 0.957545187
    },
    {
        "x": 26.62,
        "y": 0.957545187
    },
    {
        "x": 26.63,
        "y": 0.957545187
    },
    {
        "x": 26.64,
        "y": 0.957545187
    },
    {
        "x": 26.65,
        "y": 0.957965532
    },
    {
        "x": 26.66,
        "y": 0.957965532
    },
    {
        "x": 26.67,
        "y": 0.957965532
    },
    {
        "x": 26.68,
        "y": 0.957965532
    },
    {
        "x": 26.69,
        "y": 0.957965532
    },
    {
        "x": 26.7,
        "y": 0.957965532
    },
    {
        "x": 26.71,
        "y": 0.957965532
    },
    {
        "x": 26.72,
        "y": 0.958385876
    },
    {
        "x": 26.73,
        "y": 0.958385876
    },
    {
        "x": 26.74,
        "y": 0.958385876
    },
    {
        "x": 26.75,
        "y": 0.958385876
    },
    {
        "x": 26.76,
        "y": 0.958385876
    },
    {
        "x": 26.77,
        "y": 0.958385876
    },
    {
        "x": 26.78,
        "y": 0.958385876
    },
    {
        "x": 26.79,
        "y": 0.958385876
    },
    {
        "x": 26.8,
        "y": 0.958385876
    },
    {
        "x": 26.81,
        "y": 0.958385876
    },
    {
        "x": 26.82,
        "y": 0.958806221
    },
    {
        "x": 26.83,
        "y": 0.958806221
    },
    {
        "x": 26.84,
        "y": 0.958806221
    },
    {
        "x": 26.85,
        "y": 0.958806221
    },
    {
        "x": 26.86,
        "y": 0.958806221
    },
    {
        "x": 26.87,
        "y": 0.958806221
    },
    {
        "x": 26.88,
        "y": 0.958806221
    },
    {
        "x": 26.89,
        "y": 0.958806221
    },
    {
        "x": 26.9,
        "y": 0.959226566
    },
    {
        "x": 26.91,
        "y": 0.959226566
    },
    {
        "x": 26.92,
        "y": 0.959226566
    },
    {
        "x": 26.93,
        "y": 0.959226566
    },
    {
        "x": 26.94,
        "y": 0.959226566
    },
    {
        "x": 26.95,
        "y": 0.959226566
    },
    {
        "x": 26.96,
        "y": 0.959226566
    },
    {
        "x": 26.97,
        "y": 0.95964691
    },
    {
        "x": 26.98,
        "y": 0.95964691
    },
    {
        "x": 26.99,
        "y": 0.95964691
    },
    {
        "x": 27,
        "y": 0.95964691
    },
    {
        "x": 27.01,
        "y": 0.95964691
    },
    {
        "x": 27.02,
        "y": 0.95964691
    },
    {
        "x": 27.03,
        "y": 0.960067255
    },
    {
        "x": 27.04,
        "y": 0.95964691
    },
    {
        "x": 27.05,
        "y": 0.960067255
    },
    {
        "x": 27.06,
        "y": 0.960067255
    },
    {
        "x": 27.07,
        "y": 0.960067255
    },
    {
        "x": 27.08,
        "y": 0.960067255
    },
    {
        "x": 27.09,
        "y": 0.960067255
    },
    {
        "x": 27.1,
        "y": 0.960067255
    },
    {
        "x": 27.11,
        "y": 0.960067255
    },
    {
        "x": 27.12,
        "y": 0.9604876
    },
    {
        "x": 27.13,
        "y": 0.9604876
    },
    {
        "x": 27.14,
        "y": 0.9604876
    },
    {
        "x": 27.15,
        "y": 0.9604876
    },
    {
        "x": 27.16,
        "y": 0.9604876
    },
    {
        "x": 27.17,
        "y": 0.960907945
    },
    {
        "x": 27.18,
        "y": 0.960907945
    },
    {
        "x": 27.19,
        "y": 0.9604876
    },
    {
        "x": 27.2,
        "y": 0.960907945
    },
    {
        "x": 27.21,
        "y": 0.960907945
    },
    {
        "x": 27.22,
        "y": 0.960907945
    },
    {
        "x": 27.23,
        "y": 0.960907945
    },
    {
        "x": 27.24,
        "y": 0.960907945
    },
    {
        "x": 27.25,
        "y": 0.960907945
    },
    {
        "x": 27.26,
        "y": 0.961328289
    },
    {
        "x": 27.27,
        "y": 0.961328289
    },
    {
        "x": 27.28,
        "y": 0.961328289
    },
    {
        "x": 27.29,
        "y": 0.961328289
    },
    {
        "x": 27.3,
        "y": 0.961328289
    },
    {
        "x": 27.31,
        "y": 0.961328289
    },
    {
        "x": 27.32,
        "y": 0.961328289
    },
    {
        "x": 27.33,
        "y": 0.961328289
    },
    {
        "x": 27.34,
        "y": 0.961748634
    },
    {
        "x": 27.35,
        "y": 0.961748634
    },
    {
        "x": 27.36,
        "y": 0.961748634
    },
    {
        "x": 27.37,
        "y": 0.961748634
    },
    {
        "x": 27.38,
        "y": 0.961748634
    },
    {
        "x": 27.39,
        "y": 0.961748634
    },
    {
        "x": 27.4,
        "y": 0.961748634
    },
    {
        "x": 27.41,
        "y": 0.961748634
    },
    {
        "x": 27.42,
        "y": 0.961748634
    },
    {
        "x": 27.43,
        "y": 0.962168979
    },
    {
        "x": 27.44,
        "y": 0.962168979
    },
    {
        "x": 27.45,
        "y": 0.962168979
    },
    {
        "x": 27.46,
        "y": 0.962168979
    },
    {
        "x": 27.47,
        "y": 0.962168979
    },
    {
        "x": 27.48,
        "y": 0.962168979
    },
    {
        "x": 27.49,
        "y": 0.962168979
    },
    {
        "x": 27.5,
        "y": 0.962589323
    },
    {
        "x": 27.51,
        "y": 0.962168979
    },
    {
        "x": 27.52,
        "y": 0.962168979
    },
    {
        "x": 27.53,
        "y": 0.962589323
    },
    {
        "x": 27.54,
        "y": 0.962589323
    },
    {
        "x": 27.55,
        "y": 0.962589323
    },
    {
        "x": 27.56,
        "y": 0.962589323
    },
    {
        "x": 27.57,
        "y": 0.962589323
    },
    {
        "x": 27.58,
        "y": 0.962589323
    },
    {
        "x": 27.59,
        "y": 0.963009668
    },
    {
        "x": 27.6,
        "y": 0.963009668
    },
    {
        "x": 27.61,
        "y": 0.963009668
    },
    {
        "x": 27.62,
        "y": 0.963009668
    },
    {
        "x": 27.63,
        "y": 0.963009668
    },
    {
        "x": 27.64,
        "y": 0.963009668
    },
    {
        "x": 27.65,
        "y": 0.963009668
    },
    {
        "x": 27.66,
        "y": 0.963009668
    },
    {
        "x": 27.67,
        "y": 0.963430013
    },
    {
        "x": 27.68,
        "y": 0.963430013
    },
    {
        "x": 27.69,
        "y": 0.963430013
    },
    {
        "x": 27.7,
        "y": 0.963430013
    },
    {
        "x": 27.71,
        "y": 0.963430013
    },
    {
        "x": 27.72,
        "y": 0.963430013
    },
    {
        "x": 27.73,
        "y": 0.963430013
    },
    {
        "x": 27.74,
        "y": 0.963850357
    },
    {
        "x": 27.75,
        "y": 0.963850357
    },
    {
        "x": 27.76,
        "y": 0.963430013
    },
    {
        "x": 27.77,
        "y": 0.963850357
    },
    {
        "x": 27.78,
        "y": 0.963850357
    },
    {
        "x": 27.79,
        "y": 0.963850357
    },
    {
        "x": 27.8,
        "y": 0.963850357
    },
    {
        "x": 27.81,
        "y": 0.963850357
    },
    {
        "x": 27.82,
        "y": 0.963850357
    },
    {
        "x": 27.83,
        "y": 0.963850357
    },
    {
        "x": 27.84,
        "y": 0.963850357
    },
    {
        "x": 27.85,
        "y": 0.964270702
    },
    {
        "x": 27.86,
        "y": 0.964270702
    },
    {
        "x": 27.87,
        "y": 0.964270702
    },
    {
        "x": 27.88,
        "y": 0.964270702
    },
    {
        "x": 27.89,
        "y": 0.964270702
    },
    {
        "x": 27.9,
        "y": 0.964270702
    },
    {
        "x": 27.91,
        "y": 0.964270702
    },
    {
        "x": 27.92,
        "y": 0.964270702
    },
    {
        "x": 27.93,
        "y": 0.964270702
    },
    {
        "x": 27.94,
        "y": 0.964691047
    },
    {
        "x": 27.95,
        "y": 0.964691047
    },
    {
        "x": 27.96,
        "y": 0.964691047
    },
    {
        "x": 27.97,
        "y": 0.964691047
    },
    {
        "x": 27.98,
        "y": 0.964691047
    },
    {
        "x": 27.99,
        "y": 0.964691047
    },
    {
        "x": 28,
        "y": 0.965111391
    },
    {
        "x": 28.01,
        "y": 0.964691047
    },
    {
        "x": 28.02,
        "y": 0.965111391
    },
    {
        "x": 28.03,
        "y": 0.965111391
    },
    {
        "x": 28.04,
        "y": 0.965111391
    },
    {
        "x": 28.05,
        "y": 0.965111391
    },
    {
        "x": 28.06,
        "y": 0.965111391
    },
    {
        "x": 28.07,
        "y": 0.965111391
    },
    {
        "x": 28.08,
        "y": 0.965531736
    },
    {
        "x": 28.09,
        "y": 0.965111391
    },
    {
        "x": 28.1,
        "y": 0.965111391
    },
    {
        "x": 28.11,
        "y": 0.965111391
    },
    {
        "x": 28.12,
        "y": 0.965111391
    },
    {
        "x": 28.13,
        "y": 0.965531736
    },
    {
        "x": 28.14,
        "y": 0.965531736
    },
    {
        "x": 28.15,
        "y": 0.965531736
    },
    {
        "x": 28.16,
        "y": 0.965531736
    },
    {
        "x": 28.17,
        "y": 0.965531736
    },
    {
        "x": 28.18,
        "y": 0.965531736
    },
    {
        "x": 28.19,
        "y": 0.965531736
    },
    {
        "x": 28.2,
        "y": 0.965952081
    },
    {
        "x": 28.21,
        "y": 0.965952081
    },
    {
        "x": 28.22,
        "y": 0.965952081
    },
    {
        "x": 28.23,
        "y": 0.965952081
    },
    {
        "x": 28.24,
        "y": 0.965952081
    },
    {
        "x": 28.25,
        "y": 0.965952081
    },
    {
        "x": 28.26,
        "y": 0.965952081
    },
    {
        "x": 28.27,
        "y": 0.965952081
    },
    {
        "x": 28.28,
        "y": 0.965952081
    },
    {
        "x": 28.29,
        "y": 0.966372425
    },
    {
        "x": 28.3,
        "y": 0.966372425
    },
    {
        "x": 28.31,
        "y": 0.966372425
    },
    {
        "x": 28.32,
        "y": 0.966372425
    },
    {
        "x": 28.33,
        "y": 0.966372425
    },
    {
        "x": 28.34,
        "y": 0.966372425
    },
    {
        "x": 28.35,
        "y": 0.966372425
    },
    {
        "x": 28.36,
        "y": 0.966372425
    },
    {
        "x": 28.37,
        "y": 0.966372425
    },
    {
        "x": 28.38,
        "y": 0.96679277
    },
    {
        "x": 28.39,
        "y": 0.96679277
    },
    {
        "x": 28.4,
        "y": 0.96679277
    },
    {
        "x": 28.41,
        "y": 0.96679277
    },
    {
        "x": 28.42,
        "y": 0.96679277
    },
    {
        "x": 28.43,
        "y": 0.96679277
    },
    {
        "x": 28.44,
        "y": 0.96679277
    },
    {
        "x": 28.45,
        "y": 0.967213115
    },
    {
        "x": 28.46,
        "y": 0.96679277
    },
    {
        "x": 28.47,
        "y": 0.967213115
    },
    {
        "x": 28.48,
        "y": 0.967213115
    },
    {
        "x": 28.49,
        "y": 0.967213115
    },
    {
        "x": 28.5,
        "y": 0.967213115
    },
    {
        "x": 28.51,
        "y": 0.967213115
    },
    {
        "x": 28.52,
        "y": 0.967213115
    },
    {
        "x": 28.53,
        "y": 0.967213115
    },
    {
        "x": 28.54,
        "y": 0.967633459
    },
    {
        "x": 28.55,
        "y": 0.967633459
    },
    {
        "x": 28.56,
        "y": 0.967633459
    },
    {
        "x": 28.57,
        "y": 0.967633459
    },
    {
        "x": 28.58,
        "y": 0.967633459
    },
    {
        "x": 28.59,
        "y": 0.967633459
    },
    {
        "x": 28.6,
        "y": 0.967633459
    },
    {
        "x": 28.61,
        "y": 0.967633459
    },
    {
        "x": 28.62,
        "y": 0.967633459
    },
    {
        "x": 28.63,
        "y": 0.967633459
    },
    {
        "x": 28.64,
        "y": 0.967633459
    },
    {
        "x": 28.65,
        "y": 0.967633459
    },
    {
        "x": 28.66,
        "y": 0.968053804
    },
    {
        "x": 28.67,
        "y": 0.968053804
    },
    {
        "x": 28.68,
        "y": 0.968053804
    },
    {
        "x": 28.69,
        "y": 0.967633459
    },
    {
        "x": 28.7,
        "y": 0.968053804
    },
    {
        "x": 28.71,
        "y": 0.968053804
    },
    {
        "x": 28.72,
        "y": 0.968053804
    },
    {
        "x": 28.73,
        "y": 0.968053804
    },
    {
        "x": 28.74,
        "y": 0.968053804
    },
    {
        "x": 28.75,
        "y": 0.968053804
    },
    {
        "x": 28.76,
        "y": 0.968053804
    },
    {
        "x": 28.77,
        "y": 0.968053804
    },
    {
        "x": 28.78,
        "y": 0.968474149
    },
    {
        "x": 28.79,
        "y": 0.968474149
    },
    {
        "x": 28.8,
        "y": 0.968474149
    },
    {
        "x": 28.81,
        "y": 0.968474149
    },
    {
        "x": 28.82,
        "y": 0.968474149
    },
    {
        "x": 28.83,
        "y": 0.968474149
    },
    {
        "x": 28.84,
        "y": 0.968474149
    },
    {
        "x": 28.85,
        "y": 0.968894493
    },
    {
        "x": 28.86,
        "y": 0.968474149
    },
    {
        "x": 28.87,
        "y": 0.968894493
    },
    {
        "x": 28.88,
        "y": 0.968894493
    },
    {
        "x": 28.89,
        "y": 0.968894493
    },
    {
        "x": 28.9,
        "y": 0.968894493
    },
    {
        "x": 28.91,
        "y": 0.968894493
    },
    {
        "x": 28.92,
        "y": 0.968894493
    },
    {
        "x": 28.93,
        "y": 0.968894493
    },
    {
        "x": 28.94,
        "y": 0.968894493
    },
    {
        "x": 28.95,
        "y": 0.969314838
    },
    {
        "x": 28.96,
        "y": 0.969314838
    },
    {
        "x": 28.97,
        "y": 0.968894493
    },
    {
        "x": 28.98,
        "y": 0.968894493
    },
    {
        "x": 28.99,
        "y": 0.969314838
    },
    {
        "x": 29,
        "y": 0.969314838
    },
    {
        "x": 29.01,
        "y": 0.969314838
    },
    {
        "x": 29.02,
        "y": 0.969314838
    },
    {
        "x": 29.03,
        "y": 0.969314838
    },
    {
        "x": 29.04,
        "y": 0.969314838
    },
    {
        "x": 29.05,
        "y": 0.969314838
    },
    {
        "x": 29.06,
        "y": 0.969314838
    },
    {
        "x": 29.07,
        "y": 0.969314838
    },
    {
        "x": 29.08,
        "y": 0.969735183
    },
    {
        "x": 29.09,
        "y": 0.969735183
    },
    {
        "x": 29.1,
        "y": 0.969735183
    },
    {
        "x": 29.11,
        "y": 0.969735183
    },
    {
        "x": 29.12,
        "y": 0.969735183
    },
    {
        "x": 29.13,
        "y": 0.969735183
    },
    {
        "x": 29.14,
        "y": 0.969735183
    },
    {
        "x": 29.15,
        "y": 0.969735183
    },
    {
        "x": 29.16,
        "y": 0.969735183
    },
    {
        "x": 29.17,
        "y": 0.970155528
    },
    {
        "x": 29.18,
        "y": 0.970155528
    },
    {
        "x": 29.19,
        "y": 0.970155528
    },
    {
        "x": 29.2,
        "y": 0.970155528
    },
    {
        "x": 29.21,
        "y": 0.970155528
    },
    {
        "x": 29.22,
        "y": 0.970155528
    },
    {
        "x": 29.23,
        "y": 0.970155528
    },
    {
        "x": 29.24,
        "y": 0.970155528
    },
    {
        "x": 29.25,
        "y": 0.970155528
    },
    {
        "x": 29.26,
        "y": 0.970155528
    },
    {
        "x": 29.27,
        "y": 0.970155528
    },
    {
        "x": 29.28,
        "y": 0.970155528
    },
    {
        "x": 29.29,
        "y": 0.970575872
    },
    {
        "x": 29.3,
        "y": 0.970575872
    },
    {
        "x": 29.31,
        "y": 0.970575872
    },
    {
        "x": 29.32,
        "y": 0.970575872
    },
    {
        "x": 29.33,
        "y": 0.970575872
    },
    {
        "x": 29.34,
        "y": 0.970575872
    },
    {
        "x": 29.35,
        "y": 0.970996217
    },
    {
        "x": 29.36,
        "y": 0.970996217
    },
    {
        "x": 29.37,
        "y": 0.970996217
    },
    {
        "x": 29.38,
        "y": 0.970996217
    },
    {
        "x": 29.39,
        "y": 0.970996217
    },
    {
        "x": 29.4,
        "y": 0.970996217
    },
    {
        "x": 29.41,
        "y": 0.970996217
    },
    {
        "x": 29.42,
        "y": 0.970996217
    },
    {
        "x": 29.43,
        "y": 0.970996217
    },
    {
        "x": 29.44,
        "y": 0.970996217
    },
    {
        "x": 29.45,
        "y": 0.970996217
    },
    {
        "x": 29.46,
        "y": 0.971416562
    },
    {
        "x": 29.47,
        "y": 0.971416562
    },
    {
        "x": 29.48,
        "y": 0.970996217
    },
    {
        "x": 29.49,
        "y": 0.971416562
    },
    {
        "x": 29.5,
        "y": 0.971416562
    },
    {
        "x": 29.51,
        "y": 0.971416562
    },
    {
        "x": 29.52,
        "y": 0.971416562
    },
    {
        "x": 29.53,
        "y": 0.971416562
    },
    {
        "x": 29.54,
        "y": 0.971836906
    },
    {
        "x": 29.55,
        "y": 0.971836906
    },
    {
        "x": 29.56,
        "y": 0.971416562
    },
    {
        "x": 29.57,
        "y": 0.971836906
    },
    {
        "x": 29.58,
        "y": 0.971836906
    },
    {
        "x": 29.59,
        "y": 0.971836906
    },
    {
        "x": 29.6,
        "y": 0.971836906
    },
    {
        "x": 29.61,
        "y": 0.971836906
    },
    {
        "x": 29.62,
        "y": 0.971836906
    },
    {
        "x": 29.63,
        "y": 0.971836906
    },
    {
        "x": 29.64,
        "y": 0.971836906
    },
    {
        "x": 29.65,
        "y": 0.971836906
    },
    {
        "x": 29.66,
        "y": 0.971836906
    },
    {
        "x": 29.67,
        "y": 0.971836906
    },
    {
        "x": 29.68,
        "y": 0.972257251
    },
    {
        "x": 29.69,
        "y": 0.972257251
    },
    {
        "x": 29.7,
        "y": 0.972257251
    },
    {
        "x": 29.71,
        "y": 0.972257251
    },
    {
        "x": 29.72,
        "y": 0.972257251
    },
    {
        "x": 29.73,
        "y": 0.972257251
    },
    {
        "x": 29.74,
        "y": 0.972257251
    },
    {
        "x": 29.75,
        "y": 0.972257251
    },
    {
        "x": 29.76,
        "y": 0.972257251
    },
    {
        "x": 29.77,
        "y": 0.972677596
    },
    {
        "x": 29.78,
        "y": 0.972677596
    },
    {
        "x": 29.79,
        "y": 0.972677596
    },
    {
        "x": 29.8,
        "y": 0.972677596
    },
    {
        "x": 29.81,
        "y": 0.972677596
    },
    {
        "x": 29.82,
        "y": 0.972677596
    },
    {
        "x": 29.83,
        "y": 0.972677596
    },
    {
        "x": 29.84,
        "y": 0.972677596
    },
    {
        "x": 29.85,
        "y": 0.972677596
    },
    {
        "x": 29.86,
        "y": 0.972677596
    },
    {
        "x": 29.87,
        "y": 0.972677596
    },
    {
        "x": 29.88,
        "y": 0.972677596
    },
    {
        "x": 29.89,
        "y": 0.972677596
    },
    {
        "x": 29.9,
        "y": 0.97309794
    },
    {
        "x": 29.91,
        "y": 0.97309794
    },
    {
        "x": 29.92,
        "y": 0.97309794
    },
    {
        "x": 29.93,
        "y": 0.97309794
    },
    {
        "x": 29.94,
        "y": 0.97309794
    },
    {
        "x": 29.95,
        "y": 0.97309794
    },
    {
        "x": 29.96,
        "y": 0.97309794
    },
    {
        "x": 29.97,
        "y": 0.97309794
    },
    {
        "x": 29.98,
        "y": 0.97309794
    },
    {
        "x": 29.99,
        "y": 0.97309794
    },
    {
        "x": 30,
        "y": 0.97309794
    },
    {
        "x": 30.01,
        "y": 0.973518285
    },
    {
        "x": 30.02,
        "y": 0.97309794
    },
    {
        "x": 30.03,
        "y": 0.97309794
    },
    {
        "x": 30.04,
        "y": 0.973518285
    },
    {
        "x": 30.05,
        "y": 0.973518285
    },
    {
        "x": 30.06,
        "y": 0.973518285
    },
    {
        "x": 30.07,
        "y": 0.973518285
    },
    {
        "x": 30.08,
        "y": 0.973518285
    },
    {
        "x": 30.09,
        "y": 0.973518285
    },
    {
        "x": 30.1,
        "y": 0.97393863
    },
    {
        "x": 30.11,
        "y": 0.97393863
    },
    {
        "x": 30.12,
        "y": 0.973518285
    },
    {
        "x": 30.13,
        "y": 0.973518285
    },
    {
        "x": 30.14,
        "y": 0.973518285
    },
    {
        "x": 30.15,
        "y": 0.97393863
    },
    {
        "x": 30.16,
        "y": 0.97393863
    },
    {
        "x": 30.17,
        "y": 0.97393863
    },
    {
        "x": 30.18,
        "y": 0.97393863
    },
    {
        "x": 30.19,
        "y": 0.97393863
    },
    {
        "x": 30.2,
        "y": 0.97393863
    },
    {
        "x": 30.21,
        "y": 0.97393863
    },
    {
        "x": 30.22,
        "y": 0.974358974
    },
    {
        "x": 30.23,
        "y": 0.97393863
    },
    {
        "x": 30.24,
        "y": 0.97393863
    },
    {
        "x": 30.25,
        "y": 0.974358974
    },
    {
        "x": 30.26,
        "y": 0.974358974
    },
    {
        "x": 30.27,
        "y": 0.974358974
    },
    {
        "x": 30.28,
        "y": 0.974358974
    },
    {
        "x": 30.29,
        "y": 0.974358974
    },
    {
        "x": 30.3,
        "y": 0.974358974
    },
    {
        "x": 30.31,
        "y": 0.974358974
    },
    {
        "x": 30.32,
        "y": 0.974358974
    },
    {
        "x": 30.33,
        "y": 0.974358974
    },
    {
        "x": 30.34,
        "y": 0.974358974
    },
    {
        "x": 30.35,
        "y": 0.974358974
    },
    {
        "x": 30.36,
        "y": 0.974779319
    },
    {
        "x": 30.37,
        "y": 0.974779319
    },
    {
        "x": 30.38,
        "y": 0.974779319
    },
    {
        "x": 30.39,
        "y": 0.974779319
    },
    {
        "x": 30.4,
        "y": 0.974779319
    },
    {
        "x": 30.41,
        "y": 0.974779319
    },
    {
        "x": 30.42,
        "y": 0.974779319
    },
    {
        "x": 30.43,
        "y": 0.974779319
    },
    {
        "x": 30.44,
        "y": 0.974779319
    },
    {
        "x": 30.45,
        "y": 0.974779319
    },
    {
        "x": 30.46,
        "y": 0.975199664
    },
    {
        "x": 30.47,
        "y": 0.975199664
    },
    {
        "x": 30.48,
        "y": 0.975199664
    },
    {
        "x": 30.49,
        "y": 0.975199664
    },
    {
        "x": 30.5,
        "y": 0.975199664
    },
    {
        "x": 30.51,
        "y": 0.975199664
    },
    {
        "x": 30.52,
        "y": 0.975199664
    },
    {
        "x": 30.53,
        "y": 0.975199664
    },
    {
        "x": 30.54,
        "y": 0.975199664
    },
    {
        "x": 30.55,
        "y": 0.975199664
    },
    {
        "x": 30.56,
        "y": 0.975199664
    },
    {
        "x": 30.57,
        "y": 0.975620008
    },
    {
        "x": 30.58,
        "y": 0.975620008
    },
    {
        "x": 30.59,
        "y": 0.975620008
    },
    {
        "x": 30.6,
        "y": 0.975620008
    },
    {
        "x": 30.61,
        "y": 0.975620008
    },
    {
        "x": 30.62,
        "y": 0.975620008
    },
    {
        "x": 30.63,
        "y": 0.975620008
    },
    {
        "x": 30.64,
        "y": 0.975620008
    },
    {
        "x": 30.65,
        "y": 0.975620008
    },
    {
        "x": 30.66,
        "y": 0.975620008
    },
    {
        "x": 30.67,
        "y": 0.975620008
    },
    {
        "x": 30.68,
        "y": 0.975620008
    },
    {
        "x": 30.69,
        "y": 0.975620008
    },
    {
        "x": 30.7,
        "y": 0.976040353
    },
    {
        "x": 30.71,
        "y": 0.976040353
    },
    {
        "x": 30.72,
        "y": 0.976040353
    },
    {
        "x": 30.73,
        "y": 0.976040353
    },
    {
        "x": 30.74,
        "y": 0.976040353
    },
    {
        "x": 30.75,
        "y": 0.976040353
    },
    {
        "x": 30.76,
        "y": 0.976040353
    },
    {
        "x": 30.77,
        "y": 0.976040353
    },
    {
        "x": 30.78,
        "y": 0.976040353
    },
    {
        "x": 30.79,
        "y": 0.976040353
    },
    {
        "x": 30.8,
        "y": 0.976460698
    },
    {
        "x": 30.81,
        "y": 0.976040353
    },
    {
        "x": 30.82,
        "y": 0.976040353
    },
    {
        "x": 30.83,
        "y": 0.976040353
    },
    {
        "x": 30.84,
        "y": 0.976460698
    },
    {
        "x": 30.85,
        "y": 0.976460698
    },
    {
        "x": 30.86,
        "y": 0.976460698
    },
    {
        "x": 30.87,
        "y": 0.976460698
    },
    {
        "x": 30.88,
        "y": 0.976460698
    },
    {
        "x": 30.89,
        "y": 0.976460698
    },
    {
        "x": 30.9,
        "y": 0.976460698
    },
    {
        "x": 30.91,
        "y": 0.976460698
    },
    {
        "x": 30.92,
        "y": 0.976460698
    },
    {
        "x": 30.93,
        "y": 0.976460698
    },
    {
        "x": 30.94,
        "y": 0.976460698
    },
    {
        "x": 30.95,
        "y": 0.976460698
    },
    {
        "x": 30.96,
        "y": 0.976881042
    },
    {
        "x": 30.97,
        "y": 0.976881042
    },
    {
        "x": 30.98,
        "y": 0.976881042
    },
    {
        "x": 30.99,
        "y": 0.976881042
    },
    {
        "x": 31,
        "y": 0.976881042
    },
    {
        "x": 31.01,
        "y": 0.976881042
    },
    {
        "x": 31.02,
        "y": 0.976881042
    },
    {
        "x": 31.03,
        "y": 0.976881042
    },
    {
        "x": 31.04,
        "y": 0.976881042
    },
    {
        "x": 31.05,
        "y": 0.976881042
    },
    {
        "x": 31.06,
        "y": 0.976881042
    },
    {
        "x": 31.07,
        "y": 0.976881042
    },
    {
        "x": 31.08,
        "y": 0.977301387
    },
    {
        "x": 31.09,
        "y": 0.977301387
    },
    {
        "x": 31.1,
        "y": 0.977301387
    },
    {
        "x": 31.11,
        "y": 0.977301387
    },
    {
        "x": 31.12,
        "y": 0.977301387
    },
    {
        "x": 31.13,
        "y": 0.977301387
    },
    {
        "x": 31.14,
        "y": 0.977301387
    },
    {
        "x": 31.15,
        "y": 0.977301387
    },
    {
        "x": 31.16,
        "y": 0.977301387
    },
    {
        "x": 31.17,
        "y": 0.977721732
    },
    {
        "x": 31.18,
        "y": 0.977301387
    },
    {
        "x": 31.19,
        "y": 0.977301387
    },
    {
        "x": 31.2,
        "y": 0.977721732
    },
    {
        "x": 31.21,
        "y": 0.977721732
    },
    {
        "x": 31.22,
        "y": 0.977301387
    },
    {
        "x": 31.23,
        "y": 0.977721732
    },
    {
        "x": 31.24,
        "y": 0.977721732
    },
    {
        "x": 31.25,
        "y": 0.977721732
    },
    {
        "x": 31.26,
        "y": 0.977721732
    },
    {
        "x": 31.27,
        "y": 0.977721732
    },
    {
        "x": 31.28,
        "y": 0.977721732
    },
    {
        "x": 31.29,
        "y": 0.977721732
    },
    {
        "x": 31.3,
        "y": 0.977721732
    },
    {
        "x": 31.31,
        "y": 0.977721732
    },
    {
        "x": 31.32,
        "y": 0.977721732
    },
    {
        "x": 31.33,
        "y": 0.978142077
    },
    {
        "x": 31.34,
        "y": 0.978142077
    },
    {
        "x": 31.35,
        "y": 0.978142077
    },
    {
        "x": 31.36,
        "y": 0.978142077
    },
    {
        "x": 31.37,
        "y": 0.978142077
    },
    {
        "x": 31.38,
        "y": 0.978142077
    },
    {
        "x": 31.39,
        "y": 0.978142077
    },
    {
        "x": 31.4,
        "y": 0.978142077
    },
    {
        "x": 31.41,
        "y": 0.978142077
    },
    {
        "x": 31.42,
        "y": 0.978142077
    },
    {
        "x": 31.43,
        "y": 0.978142077
    },
    {
        "x": 31.44,
        "y": 0.978142077
    },
    {
        "x": 31.45,
        "y": 0.978142077
    },
    {
        "x": 31.46,
        "y": 0.978562421
    },
    {
        "x": 31.47,
        "y": 0.978562421
    },
    {
        "x": 31.48,
        "y": 0.978562421
    },
    {
        "x": 31.49,
        "y": 0.978562421
    },
    {
        "x": 31.5,
        "y": 0.978562421
    },
    {
        "x": 31.51,
        "y": 0.978562421
    },
    {
        "x": 31.52,
        "y": 0.978562421
    },
    {
        "x": 31.53,
        "y": 0.978562421
    },
    {
        "x": 31.54,
        "y": 0.978982766
    },
    {
        "x": 31.55,
        "y": 0.978982766
    },
    {
        "x": 31.56,
        "y": 0.978982766
    },
    {
        "x": 31.57,
        "y": 0.978982766
    },
    {
        "x": 31.58,
        "y": 0.978982766
    },
    {
        "x": 31.59,
        "y": 0.978982766
    },
    {
        "x": 31.6,
        "y": 0.978982766
    },
    {
        "x": 31.61,
        "y": 0.978982766
    },
    {
        "x": 31.62,
        "y": 0.978982766
    },
    {
        "x": 31.63,
        "y": 0.978982766
    },
    {
        "x": 31.64,
        "y": 0.978982766
    },
    {
        "x": 31.65,
        "y": 0.978982766
    },
    {
        "x": 31.66,
        "y": 0.978982766
    },
    {
        "x": 31.67,
        "y": 0.979403111
    },
    {
        "x": 31.68,
        "y": 0.978982766
    },
    {
        "x": 31.69,
        "y": 0.978982766
    },
    {
        "x": 31.7,
        "y": 0.979403111
    },
    {
        "x": 31.71,
        "y": 0.979403111
    },
    {
        "x": 31.72,
        "y": 0.979403111
    },
    {
        "x": 31.73,
        "y": 0.979403111
    },
    {
        "x": 31.74,
        "y": 0.979403111
    },
    {
        "x": 31.75,
        "y": 0.979403111
    },
    {
        "x": 31.76,
        "y": 0.979403111
    },
    {
        "x": 31.77,
        "y": 0.979403111
    },
    {
        "x": 31.78,
        "y": 0.979403111
    },
    {
        "x": 31.79,
        "y": 0.979403111
    },
    {
        "x": 31.8,
        "y": 0.979403111
    },
    {
        "x": 31.81,
        "y": 0.979403111
    },
    {
        "x": 31.82,
        "y": 0.979823455
    },
    {
        "x": 31.83,
        "y": 0.979823455
    },
    {
        "x": 31.84,
        "y": 0.979403111
    },
    {
        "x": 31.85,
        "y": 0.979823455
    },
    {
        "x": 31.86,
        "y": 0.979823455
    },
    {
        "x": 31.87,
        "y": 0.979823455
    },
    {
        "x": 31.88,
        "y": 0.979823455
    },
    {
        "x": 31.89,
        "y": 0.979823455
    },
    {
        "x": 31.9,
        "y": 0.979823455
    },
    {
        "x": 31.91,
        "y": 0.979823455
    },
    {
        "x": 31.92,
        "y": 0.979823455
    },
    {
        "x": 31.93,
        "y": 0.979823455
    },
    {
        "x": 31.94,
        "y": 0.979823455
    },
    {
        "x": 31.95,
        "y": 0.979823455
    },
    {
        "x": 31.96,
        "y": 0.9802438
    },
    {
        "x": 31.97,
        "y": 0.9802438
    },
    {
        "x": 31.98,
        "y": 0.9802438
    },
    {
        "x": 31.99,
        "y": 0.9802438
    },
    {
        "x": 32,
        "y": 0.9802438
    },
    {
        "x": 32.01,
        "y": 0.9802438
    },
    {
        "x": 32.02,
        "y": 0.9802438
    },
    {
        "x": 32.03,
        "y": 0.9802438
    },
    {
        "x": 32.04,
        "y": 0.9802438
    },
    {
        "x": 32.05,
        "y": 0.9802438
    },
    {
        "x": 32.06,
        "y": 0.9802438
    },
    {
        "x": 32.07,
        "y": 0.9802438
    },
    {
        "x": 32.08,
        "y": 0.9802438
    },
    {
        "x": 32.09,
        "y": 0.9802438
    },
    {
        "x": 32.1,
        "y": 0.9802438
    },
    {
        "x": 32.11,
        "y": 0.9802438
    },
    {
        "x": 32.12,
        "y": 0.9802438
    },
    {
        "x": 32.13,
        "y": 0.980664145
    },
    {
        "x": 32.14,
        "y": 0.980664145
    },
    {
        "x": 32.15,
        "y": 0.980664145
    },
    {
        "x": 32.16,
        "y": 0.980664145
    },
    {
        "x": 32.17,
        "y": 0.980664145
    },
    {
        "x": 32.18,
        "y": 0.980664145
    },
    {
        "x": 32.19,
        "y": 0.980664145
    },
    {
        "x": 32.2,
        "y": 0.980664145
    },
    {
        "x": 32.21,
        "y": 0.980664145
    },
    {
        "x": 32.22,
        "y": 0.980664145
    },
    {
        "x": 32.23,
        "y": 0.980664145
    },
    {
        "x": 32.24,
        "y": 0.980664145
    },
    {
        "x": 32.25,
        "y": 0.980664145
    },
    {
        "x": 32.26,
        "y": 0.980664145
    },
    {
        "x": 32.27,
        "y": 0.980664145
    },
    {
        "x": 32.28,
        "y": 0.981084489
    },
    {
        "x": 32.29,
        "y": 0.981084489
    },
    {
        "x": 32.3,
        "y": 0.981084489
    },
    {
        "x": 32.31,
        "y": 0.981084489
    },
    {
        "x": 32.32,
        "y": 0.981084489
    },
    {
        "x": 32.33,
        "y": 0.981084489
    },
    {
        "x": 32.34,
        "y": 0.981084489
    },
    {
        "x": 32.35,
        "y": 0.981084489
    },
    {
        "x": 32.36,
        "y": 0.981084489
    },
    {
        "x": 32.37,
        "y": 0.981084489
    },
    {
        "x": 32.38,
        "y": 0.981504834
    },
    {
        "x": 32.39,
        "y": 0.981504834
    },
    {
        "x": 32.4,
        "y": 0.981084489
    },
    {
        "x": 32.41,
        "y": 0.981504834
    },
    {
        "x": 32.42,
        "y": 0.981504834
    },
    {
        "x": 32.43,
        "y": 0.981504834
    },
    {
        "x": 32.44,
        "y": 0.981504834
    },
    {
        "x": 32.45,
        "y": 0.981504834
    },
    {
        "x": 32.46,
        "y": 0.981504834
    },
    {
        "x": 32.47,
        "y": 0.981504834
    },
    {
        "x": 32.48,
        "y": 0.981504834
    },
    {
        "x": 32.49,
        "y": 0.981504834
    },
    {
        "x": 32.5,
        "y": 0.981925179
    },
    {
        "x": 32.51,
        "y": 0.981504834
    },
    {
        "x": 32.52,
        "y": 0.981504834
    },
    {
        "x": 32.53,
        "y": 0.981504834
    },
    {
        "x": 32.54,
        "y": 0.981925179
    },
    {
        "x": 32.55,
        "y": 0.981504834
    },
    {
        "x": 32.56,
        "y": 0.981925179
    },
    {
        "x": 32.57,
        "y": 0.981925179
    },
    {
        "x": 32.58,
        "y": 0.981925179
    },
    {
        "x": 32.59,
        "y": 0.981925179
    },
    {
        "x": 32.6,
        "y": 0.981925179
    },
    {
        "x": 32.61,
        "y": 0.981925179
    },
    {
        "x": 32.62,
        "y": 0.981925179
    },
    {
        "x": 32.63,
        "y": 0.981925179
    },
    {
        "x": 32.64,
        "y": 0.981925179
    },
    {
        "x": 32.65,
        "y": 0.981925179
    },
    {
        "x": 32.66,
        "y": 0.981925179
    },
    {
        "x": 32.67,
        "y": 0.981925179
    },
    {
        "x": 32.68,
        "y": 0.982345523
    },
    {
        "x": 32.69,
        "y": 0.982345523
    },
    {
        "x": 32.7,
        "y": 0.982345523
    },
    {
        "x": 32.71,
        "y": 0.982345523
    },
    {
        "x": 32.72,
        "y": 0.982345523
    },
    {
        "x": 32.73,
        "y": 0.982345523
    },
    {
        "x": 32.74,
        "y": 0.982345523
    },
    {
        "x": 32.75,
        "y": 0.982345523
    },
    {
        "x": 32.76,
        "y": 0.982345523
    },
    {
        "x": 32.77,
        "y": 0.982345523
    },
    {
        "x": 32.78,
        "y": 0.982345523
    },
    {
        "x": 32.79,
        "y": 0.982345523
    },
    {
        "x": 32.8,
        "y": 0.982345523
    },
    {
        "x": 32.81,
        "y": 0.982345523
    },
    {
        "x": 32.82,
        "y": 0.982765868
    },
    {
        "x": 32.83,
        "y": 0.982765868
    },
    {
        "x": 32.84,
        "y": 0.982765868
    },
    {
        "x": 32.85,
        "y": 0.982765868
    },
    {
        "x": 32.86,
        "y": 0.982765868
    },
    {
        "x": 32.87,
        "y": 0.982765868
    },
    {
        "x": 32.88,
        "y": 0.982765868
    },
    {
        "x": 32.89,
        "y": 0.982765868
    },
    {
        "x": 32.9,
        "y": 0.982765868
    },
    {
        "x": 32.91,
        "y": 0.982765868
    },
    {
        "x": 32.92,
        "y": 0.982765868
    },
    {
        "x": 32.93,
        "y": 0.982765868
    },
    {
        "x": 32.94,
        "y": 0.982765868
    },
    {
        "x": 32.95,
        "y": 0.982765868
    },
    {
        "x": 32.96,
        "y": 0.982765868
    },
    {
        "x": 32.97,
        "y": 0.982765868
    },
    {
        "x": 32.98,
        "y": 0.982765868
    },
    {
        "x": 32.99,
        "y": 0.983186213
    },
    {
        "x": 33,
        "y": 0.983186213
    },
    {
        "x": 33.01,
        "y": 0.983186213
    },
    {
        "x": 33.02,
        "y": 0.983186213
    },
    {
        "x": 33.03,
        "y": 0.983186213
    },
    {
        "x": 33.04,
        "y": 0.983186213
    },
    {
        "x": 33.05,
        "y": 0.983186213
    },
    {
        "x": 33.06,
        "y": 0.983186213
    },
    {
        "x": 33.07,
        "y": 0.983186213
    },
    {
        "x": 33.08,
        "y": 0.983186213
    },
    {
        "x": 33.09,
        "y": 0.983186213
    },
    {
        "x": 33.1,
        "y": 0.983606557
    },
    {
        "x": 33.11,
        "y": 0.983186213
    },
    {
        "x": 33.12,
        "y": 0.983186213
    },
    {
        "x": 33.13,
        "y": 0.983186213
    },
    {
        "x": 33.14,
        "y": 0.983606557
    },
    {
        "x": 33.15,
        "y": 0.983606557
    },
    {
        "x": 33.16,
        "y": 0.983606557
    },
    {
        "x": 33.17,
        "y": 0.983606557
    },
    {
        "x": 33.18,
        "y": 0.983606557
    },
    {
        "x": 33.19,
        "y": 0.983606557
    },
    {
        "x": 33.2,
        "y": 0.983606557
    },
    {
        "x": 33.21,
        "y": 0.983606557
    },
    {
        "x": 33.22,
        "y": 0.983606557
    },
    {
        "x": 33.23,
        "y": 0.983606557
    },
    {
        "x": 33.24,
        "y": 0.983606557
    },
    {
        "x": 33.25,
        "y": 0.983606557
    },
    {
        "x": 33.26,
        "y": 0.983606557
    },
    {
        "x": 33.27,
        "y": 0.983606557
    },
    {
        "x": 33.28,
        "y": 0.983606557
    },
    {
        "x": 33.29,
        "y": 0.983606557
    },
    {
        "x": 33.3,
        "y": 0.983606557
    },
    {
        "x": 33.31,
        "y": 0.984026902
    },
    {
        "x": 33.32,
        "y": 0.983606557
    },
    {
        "x": 33.33,
        "y": 0.984026902
    },
    {
        "x": 33.34,
        "y": 0.984026902
    },
    {
        "x": 33.35,
        "y": 0.984026902
    },
    {
        "x": 33.36,
        "y": 0.984026902
    },
    {
        "x": 33.37,
        "y": 0.984026902
    },
    {
        "x": 33.38,
        "y": 0.984026902
    },
    {
        "x": 33.39,
        "y": 0.984026902
    },
    {
        "x": 33.4,
        "y": 0.984026902
    },
    {
        "x": 33.41,
        "y": 0.984026902
    },
    {
        "x": 33.42,
        "y": 0.984026902
    },
    {
        "x": 33.43,
        "y": 0.984026902
    },
    {
        "x": 33.44,
        "y": 0.984026902
    },
    {
        "x": 33.45,
        "y": 0.984026902
    },
    {
        "x": 33.46,
        "y": 0.984026902
    },
    {
        "x": 33.47,
        "y": 0.984026902
    },
    {
        "x": 33.48,
        "y": 0.984026902
    },
    {
        "x": 33.49,
        "y": 0.984447247
    },
    {
        "x": 33.5,
        "y": 0.984447247
    },
    {
        "x": 33.51,
        "y": 0.984447247
    },
    {
        "x": 33.52,
        "y": 0.984447247
    },
    {
        "x": 33.53,
        "y": 0.984447247
    },
    {
        "x": 33.54,
        "y": 0.984447247
    },
    {
        "x": 33.55,
        "y": 0.984447247
    },
    {
        "x": 33.56,
        "y": 0.984447247
    },
    {
        "x": 33.57,
        "y": 0.984447247
    },
    {
        "x": 33.58,
        "y": 0.984447247
    },
    {
        "x": 33.59,
        "y": 0.984447247
    },
    {
        "x": 33.6,
        "y": 0.984447247
    },
    {
        "x": 33.61,
        "y": 0.984447247
    },
    {
        "x": 33.62,
        "y": 0.984447247
    },
    {
        "x": 33.63,
        "y": 0.984447247
    },
    {
        "x": 33.64,
        "y": 0.984447247
    },
    {
        "x": 33.65,
        "y": 0.984447247
    },
    {
        "x": 33.66,
        "y": 0.984867591
    },
    {
        "x": 33.67,
        "y": 0.984867591
    },
    {
        "x": 33.68,
        "y": 0.984867591
    },
    {
        "x": 33.69,
        "y": 0.984867591
    },
    {
        "x": 33.7,
        "y": 0.984867591
    },
    {
        "x": 33.71,
        "y": 0.984867591
    },
    {
        "x": 33.72,
        "y": 0.984867591
    },
    {
        "x": 33.73,
        "y": 0.984867591
    },
    {
        "x": 33.74,
        "y": 0.984867591
    },
    {
        "x": 33.75,
        "y": 0.984867591
    },
    {
        "x": 33.76,
        "y": 0.984867591
    },
    {
        "x": 33.77,
        "y": 0.984867591
    },
    {
        "x": 33.78,
        "y": 0.984867591
    },
    {
        "x": 33.79,
        "y": 0.984867591
    },
    {
        "x": 33.8,
        "y": 0.985287936
    },
    {
        "x": 33.81,
        "y": 0.984867591
    },
    {
        "x": 33.82,
        "y": 0.984867591
    },
    {
        "x": 33.83,
        "y": 0.984867591
    },
    {
        "x": 33.84,
        "y": 0.984867591
    },
    {
        "x": 33.85,
        "y": 0.984867591
    },
    {
        "x": 33.86,
        "y": 0.985287936
    },
    {
        "x": 33.87,
        "y": 0.985287936
    },
    {
        "x": 33.88,
        "y": 0.985287936
    },
    {
        "x": 33.89,
        "y": 0.985287936
    },
    {
        "x": 33.9,
        "y": 0.985287936
    },
    {
        "x": 33.91,
        "y": 0.985287936
    },
    {
        "x": 33.92,
        "y": 0.985287936
    },
    {
        "x": 33.93,
        "y": 0.985287936
    },
    {
        "x": 33.94,
        "y": 0.985287936
    },
    {
        "x": 33.95,
        "y": 0.985287936
    },
    {
        "x": 33.96,
        "y": 0.985708281
    },
    {
        "x": 33.97,
        "y": 0.985708281
    },
    {
        "x": 33.98,
        "y": 0.985287936
    },
    {
        "x": 33.99,
        "y": 0.985287936
    },
    {
        "x": 34,
        "y": 0.985708281
    },
    {
        "x": 34.01,
        "y": 0.985708281
    },
    {
        "x": 34.02,
        "y": 0.985708281
    },
    {
        "x": 34.03,
        "y": 0.985708281
    },
    {
        "x": 34.04,
        "y": 0.985708281
    },
    {
        "x": 34.05,
        "y": 0.985708281
    },
    {
        "x": 34.06,
        "y": 0.985708281
    },
    {
        "x": 34.07,
        "y": 0.985708281
    },
    {
        "x": 34.08,
        "y": 0.985708281
    },
    {
        "x": 34.09,
        "y": 0.985708281
    },
    {
        "x": 34.1,
        "y": 0.985708281
    },
    {
        "x": 34.11,
        "y": 0.985708281
    },
    {
        "x": 34.12,
        "y": 0.985708281
    },
    {
        "x": 34.13,
        "y": 0.985708281
    },
    {
        "x": 34.14,
        "y": 0.985708281
    },
    {
        "x": 34.15,
        "y": 0.985708281
    },
    {
        "x": 34.16,
        "y": 0.985708281
    },
    {
        "x": 34.17,
        "y": 0.985708281
    },
    {
        "x": 34.18,
        "y": 0.985708281
    },
    {
        "x": 34.19,
        "y": 0.985708281
    },
    {
        "x": 34.2,
        "y": 0.985708281
    },
    {
        "x": 34.21,
        "y": 0.985708281
    },
    {
        "x": 34.22,
        "y": 0.986128625
    },
    {
        "x": 34.23,
        "y": 0.986128625
    },
    {
        "x": 34.24,
        "y": 0.986128625
    },
    {
        "x": 34.25,
        "y": 0.986128625
    },
    {
        "x": 34.26,
        "y": 0.986128625
    },
    {
        "x": 34.27,
        "y": 0.986128625
    },
    {
        "x": 34.28,
        "y": 0.985708281
    },
    {
        "x": 34.29,
        "y": 0.986128625
    },
    {
        "x": 34.3,
        "y": 0.986128625
    },
    {
        "x": 34.31,
        "y": 0.986128625
    },
    {
        "x": 34.32,
        "y": 0.986128625
    },
    {
        "x": 34.33,
        "y": 0.986128625
    },
    {
        "x": 34.34,
        "y": 0.986128625
    },
    {
        "x": 34.35,
        "y": 0.986128625
    },
    {
        "x": 34.36,
        "y": 0.986128625
    },
    {
        "x": 34.37,
        "y": 0.986128625
    },
    {
        "x": 34.38,
        "y": 0.986128625
    },
    {
        "x": 34.39,
        "y": 0.986128625
    },
    {
        "x": 34.4,
        "y": 0.986128625
    },
    {
        "x": 34.41,
        "y": 0.986128625
    },
    {
        "x": 34.42,
        "y": 0.986128625
    },
    {
        "x": 34.43,
        "y": 0.986128625
    },
    {
        "x": 34.44,
        "y": 0.986128625
    },
    {
        "x": 34.45,
        "y": 0.986128625
    },
    {
        "x": 34.46,
        "y": 0.98654897
    },
    {
        "x": 34.47,
        "y": 0.98654897
    },
    {
        "x": 34.48,
        "y": 0.98654897
    },
    {
        "x": 34.49,
        "y": 0.98654897
    },
    {
        "x": 34.5,
        "y": 0.98654897
    },
    {
        "x": 34.51,
        "y": 0.98654897
    },
    {
        "x": 34.52,
        "y": 0.98654897
    },
    {
        "x": 34.53,
        "y": 0.98654897
    },
    {
        "x": 34.54,
        "y": 0.98654897
    },
    {
        "x": 34.55,
        "y": 0.98654897
    },
    {
        "x": 34.56,
        "y": 0.98654897
    },
    {
        "x": 34.57,
        "y": 0.98654897
    },
    {
        "x": 34.58,
        "y": 0.98654897
    },
    {
        "x": 34.59,
        "y": 0.98654897
    },
    {
        "x": 34.6,
        "y": 0.98654897
    },
    {
        "x": 34.61,
        "y": 0.986969315
    },
    {
        "x": 34.62,
        "y": 0.986969315
    },
    {
        "x": 34.63,
        "y": 0.986969315
    },
    {
        "x": 34.64,
        "y": 0.986969315
    },
    {
        "x": 34.65,
        "y": 0.986969315
    },
    {
        "x": 34.66,
        "y": 0.986969315
    },
    {
        "x": 34.67,
        "y": 0.986969315
    },
    {
        "x": 34.68,
        "y": 0.986969315
    },
    {
        "x": 34.69,
        "y": 0.986969315
    },
    {
        "x": 34.7,
        "y": 0.986969315
    },
    {
        "x": 34.71,
        "y": 0.986969315
    },
    {
        "x": 34.72,
        "y": 0.986969315
    },
    {
        "x": 34.73,
        "y": 0.986969315
    },
    {
        "x": 34.74,
        "y": 0.986969315
    },
    {
        "x": 34.75,
        "y": 0.986969315
    },
    {
        "x": 34.76,
        "y": 0.986969315
    },
    {
        "x": 34.77,
        "y": 0.986969315
    },
    {
        "x": 34.78,
        "y": 0.986969315
    },
    {
        "x": 34.79,
        "y": 0.986969315
    },
    {
        "x": 34.8,
        "y": 0.986969315
    },
    {
        "x": 34.81,
        "y": 0.986969315
    },
    {
        "x": 34.82,
        "y": 0.98738966
    },
    {
        "x": 34.83,
        "y": 0.986969315
    },
    {
        "x": 34.84,
        "y": 0.986969315
    },
    {
        "x": 34.85,
        "y": 0.986969315
    },
    {
        "x": 34.86,
        "y": 0.98738966
    },
    {
        "x": 34.87,
        "y": 0.98738966
    },
    {
        "x": 34.88,
        "y": 0.986969315
    },
    {
        "x": 34.89,
        "y": 0.98738966
    },
    {
        "x": 34.9,
        "y": 0.98738966
    },
    {
        "x": 34.91,
        "y": 0.98738966
    },
    {
        "x": 34.92,
        "y": 0.98738966
    },
    {
        "x": 34.93,
        "y": 0.98738966
    },
    {
        "x": 34.94,
        "y": 0.98738966
    },
    {
        "x": 34.95,
        "y": 0.98738966
    },
    {
        "x": 34.96,
        "y": 0.98738966
    },
    {
        "x": 34.97,
        "y": 0.98738966
    },
    {
        "x": 34.98,
        "y": 0.98738966
    },
    {
        "x": 34.99,
        "y": 0.98738966
    },
    {
        "x": 35,
        "y": 0.98738966
    },
    {
        "x": 35.01,
        "y": 0.987810004
    },
    {
        "x": 35.02,
        "y": 0.98738966
    },
    {
        "x": 35.03,
        "y": 0.98738966
    },
    {
        "x": 35.04,
        "y": 0.98738966
    },
    {
        "x": 35.05,
        "y": 0.98738966
    },
    {
        "x": 35.06,
        "y": 0.987810004
    },
    {
        "x": 35.07,
        "y": 0.987810004
    },
    {
        "x": 35.08,
        "y": 0.987810004
    },
    {
        "x": 35.09,
        "y": 0.987810004
    },
    {
        "x": 35.1,
        "y": 0.987810004
    },
    {
        "x": 35.11,
        "y": 0.987810004
    },
    {
        "x": 35.12,
        "y": 0.987810004
    },
    {
        "x": 35.13,
        "y": 0.987810004
    },
    {
        "x": 35.14,
        "y": 0.987810004
    },
    {
        "x": 35.15,
        "y": 0.988230349
    },
    {
        "x": 35.16,
        "y": 0.987810004
    },
    {
        "x": 35.17,
        "y": 0.987810004
    },
    {
        "x": 35.18,
        "y": 0.987810004
    },
    {
        "x": 35.19,
        "y": 0.988230349
    },
    {
        "x": 35.2,
        "y": 0.987810004
    },
    {
        "x": 35.21,
        "y": 0.987810004
    },
    {
        "x": 35.22,
        "y": 0.988230349
    },
    {
        "x": 35.23,
        "y": 0.987810004
    },
    {
        "x": 35.24,
        "y": 0.988230349
    },
    {
        "x": 35.25,
        "y": 0.988230349
    },
    {
        "x": 35.26,
        "y": 0.988230349
    },
    {
        "x": 35.27,
        "y": 0.988230349
    },
    {
        "x": 35.28,
        "y": 0.988230349
    },
    {
        "x": 35.29,
        "y": 0.988230349
    },
    {
        "x": 35.3,
        "y": 0.988230349
    },
    {
        "x": 35.31,
        "y": 0.988230349
    },
    {
        "x": 35.32,
        "y": 0.988230349
    },
    {
        "x": 35.33,
        "y": 0.988230349
    },
    {
        "x": 35.34,
        "y": 0.988230349
    },
    {
        "x": 35.35,
        "y": 0.988230349
    },
    {
        "x": 35.36,
        "y": 0.988230349
    },
    {
        "x": 35.37,
        "y": 0.988230349
    },
    {
        "x": 35.38,
        "y": 0.988230349
    },
    {
        "x": 35.39,
        "y": 0.988230349
    },
    {
        "x": 35.4,
        "y": 0.988230349
    },
    {
        "x": 35.41,
        "y": 0.988230349
    },
    {
        "x": 35.42,
        "y": 0.988650694
    },
    {
        "x": 35.43,
        "y": 0.988230349
    },
    {
        "x": 35.44,
        "y": 0.988230349
    },
    {
        "x": 35.45,
        "y": 0.988230349
    },
    {
        "x": 35.46,
        "y": 0.988650694
    },
    {
        "x": 35.47,
        "y": 0.988230349
    },
    {
        "x": 35.48,
        "y": 0.988230349
    },
    {
        "x": 35.49,
        "y": 0.988230349
    },
    {
        "x": 35.5,
        "y": 0.988230349
    },
    {
        "x": 35.51,
        "y": 0.988650694
    },
    {
        "x": 35.52,
        "y": 0.988650694
    },
    {
        "x": 35.53,
        "y": 0.988650694
    },
    {
        "x": 35.54,
        "y": 0.988650694
    },
    {
        "x": 35.55,
        "y": 0.988650694
    },
    {
        "x": 35.56,
        "y": 0.988650694
    },
    {
        "x": 35.57,
        "y": 0.988650694
    },
    {
        "x": 35.58,
        "y": 0.988650694
    },
    {
        "x": 35.59,
        "y": 0.988650694
    },
    {
        "x": 35.6,
        "y": 0.988650694
    },
    {
        "x": 35.61,
        "y": 0.988650694
    },
    {
        "x": 35.62,
        "y": 0.988650694
    },
    {
        "x": 35.63,
        "y": 0.988650694
    },
    {
        "x": 35.64,
        "y": 0.988650694
    },
    {
        "x": 35.65,
        "y": 0.988650694
    },
    {
        "x": 35.66,
        "y": 0.988650694
    },
    {
        "x": 35.67,
        "y": 0.988650694
    },
    {
        "x": 35.68,
        "y": 0.988650694
    },
    {
        "x": 35.69,
        "y": 0.988650694
    },
    {
        "x": 35.7,
        "y": 0.989071038
    },
    {
        "x": 35.71,
        "y": 0.988650694
    },
    {
        "x": 35.72,
        "y": 0.989071038
    },
    {
        "x": 35.73,
        "y": 0.989071038
    },
    {
        "x": 35.74,
        "y": 0.988650694
    },
    {
        "x": 35.75,
        "y": 0.988650694
    },
    {
        "x": 35.76,
        "y": 0.988650694
    },
    {
        "x": 35.77,
        "y": 0.988650694
    },
    {
        "x": 35.78,
        "y": 0.989071038
    },
    {
        "x": 35.79,
        "y": 0.989071038
    },
    {
        "x": 35.8,
        "y": 0.989071038
    },
    {
        "x": 35.81,
        "y": 0.989071038
    },
    {
        "x": 35.82,
        "y": 0.989071038
    },
    {
        "x": 35.83,
        "y": 0.989071038
    },
    {
        "x": 35.84,
        "y": 0.989071038
    },
    {
        "x": 35.85,
        "y": 0.989071038
    },
    {
        "x": 35.86,
        "y": 0.989071038
    },
    {
        "x": 35.87,
        "y": 0.989071038
    },
    {
        "x": 35.88,
        "y": 0.989071038
    },
    {
        "x": 35.89,
        "y": 0.989071038
    },
    {
        "x": 35.9,
        "y": 0.989071038
    },
    {
        "x": 35.91,
        "y": 0.989071038
    },
    {
        "x": 35.92,
        "y": 0.989071038
    },
    {
        "x": 35.93,
        "y": 0.989071038
    },
    {
        "x": 35.94,
        "y": 0.989491383
    },
    {
        "x": 35.95,
        "y": 0.989071038
    },
    {
        "x": 35.96,
        "y": 0.989071038
    },
    {
        "x": 35.97,
        "y": 0.989071038
    },
    {
        "x": 35.98,
        "y": 0.989491383
    },
    {
        "x": 35.99,
        "y": 0.989491383
    },
    {
        "x": 36,
        "y": 0.989071038
    },
    {
        "x": 36.01,
        "y": 0.989071038
    },
    {
        "x": 36.02,
        "y": 0.989071038
    },
    {
        "x": 36.03,
        "y": 0.989491383
    },
    {
        "x": 36.04,
        "y": 0.989491383
    },
    {
        "x": 36.05,
        "y": 0.989491383
    },
    {
        "x": 36.06,
        "y": 0.989071038
    },
    {
        "x": 36.07,
        "y": 0.989491383
    },
    {
        "x": 36.08,
        "y": 0.989491383
    },
    {
        "x": 36.09,
        "y": 0.989491383
    },
    {
        "x": 36.1,
        "y": 0.989491383
    },
    {
        "x": 36.11,
        "y": 0.989491383
    },
    {
        "x": 36.12,
        "y": 0.989491383
    },
    {
        "x": 36.13,
        "y": 0.989491383
    },
    {
        "x": 36.14,
        "y": 0.989491383
    },
    {
        "x": 36.15,
        "y": 0.989491383
    },
    {
        "x": 36.16,
        "y": 0.989491383
    },
    {
        "x": 36.17,
        "y": 0.989491383
    },
    {
        "x": 36.18,
        "y": 0.989491383
    },
    {
        "x": 36.19,
        "y": 0.989491383
    },
    {
        "x": 36.2,
        "y": 0.989491383
    },
    {
        "x": 36.21,
        "y": 0.989491383
    },
    {
        "x": 36.22,
        "y": 0.989491383
    },
    {
        "x": 36.23,
        "y": 0.989491383
    },
    {
        "x": 36.24,
        "y": 0.989491383
    },
    {
        "x": 36.25,
        "y": 0.989491383
    },
    {
        "x": 36.26,
        "y": 0.989491383
    },
    {
        "x": 36.27,
        "y": 0.989491383
    },
    {
        "x": 36.28,
        "y": 0.989491383
    },
    {
        "x": 36.29,
        "y": 0.989911728
    },
    {
        "x": 36.3,
        "y": 0.989911728
    },
    {
        "x": 36.31,
        "y": 0.989911728
    },
    {
        "x": 36.32,
        "y": 0.989491383
    },
    {
        "x": 36.33,
        "y": 0.989491383
    },
    {
        "x": 36.34,
        "y": 0.989911728
    },
    {
        "x": 36.35,
        "y": 0.989911728
    },
    {
        "x": 36.36,
        "y": 0.989911728
    },
    {
        "x": 36.37,
        "y": 0.989911728
    },
    {
        "x": 36.38,
        "y": 0.989911728
    },
    {
        "x": 36.39,
        "y": 0.989911728
    },
    {
        "x": 36.4,
        "y": 0.989911728
    },
    {
        "x": 36.41,
        "y": 0.989911728
    },
    {
        "x": 36.42,
        "y": 0.989911728
    },
    {
        "x": 36.43,
        "y": 0.989911728
    },
    {
        "x": 36.44,
        "y": 0.989911728
    },
    {
        "x": 36.45,
        "y": 0.989911728
    },
    {
        "x": 36.46,
        "y": 0.989911728
    },
    {
        "x": 36.47,
        "y": 0.989911728
    },
    {
        "x": 36.48,
        "y": 0.989911728
    },
    {
        "x": 36.49,
        "y": 0.989911728
    },
    {
        "x": 36.5,
        "y": 0.989911728
    },
    {
        "x": 36.51,
        "y": 0.990332072
    },
    {
        "x": 36.52,
        "y": 0.989911728
    },
    {
        "x": 36.53,
        "y": 0.990332072
    },
    {
        "x": 36.54,
        "y": 0.989911728
    },
    {
        "x": 36.55,
        "y": 0.989911728
    },
    {
        "x": 36.56,
        "y": 0.989911728
    },
    {
        "x": 36.57,
        "y": 0.990332072
    },
    {
        "x": 36.58,
        "y": 0.989911728
    },
    {
        "x": 36.59,
        "y": 0.989911728
    },
    {
        "x": 36.6,
        "y": 0.990332072
    },
    {
        "x": 36.61,
        "y": 0.990332072
    },
    {
        "x": 36.62,
        "y": 0.990332072
    },
    {
        "x": 36.63,
        "y": 0.990332072
    },
    {
        "x": 36.64,
        "y": 0.990332072
    },
    {
        "x": 36.65,
        "y": 0.990332072
    },
    {
        "x": 36.66,
        "y": 0.990332072
    },
    {
        "x": 36.67,
        "y": 0.990332072
    },
    {
        "x": 36.68,
        "y": 0.990332072
    },
    {
        "x": 36.69,
        "y": 0.990332072
    },
    {
        "x": 36.7,
        "y": 0.990332072
    },
    {
        "x": 36.71,
        "y": 0.990332072
    },
    {
        "x": 36.72,
        "y": 0.990332072
    },
    {
        "x": 36.73,
        "y": 0.990332072
    },
    {
        "x": 36.74,
        "y": 0.990332072
    },
    {
        "x": 36.75,
        "y": 0.990332072
    },
    {
        "x": 36.76,
        "y": 0.990332072
    },
    {
        "x": 36.77,
        "y": 0.990332072
    },
    {
        "x": 36.78,
        "y": 0.990332072
    },
    {
        "x": 36.79,
        "y": 0.990332072
    },
    {
        "x": 36.8,
        "y": 0.990332072
    },
    {
        "x": 36.81,
        "y": 0.990332072
    },
    {
        "x": 36.82,
        "y": 0.990332072
    },
    {
        "x": 36.83,
        "y": 0.990332072
    },
    {
        "x": 36.84,
        "y": 0.990332072
    },
    {
        "x": 36.85,
        "y": 0.990332072
    },
    {
        "x": 36.86,
        "y": 0.990332072
    },
    {
        "x": 36.87,
        "y": 0.990332072
    },
    {
        "x": 36.88,
        "y": 0.990332072
    },
    {
        "x": 36.89,
        "y": 0.990332072
    },
    {
        "x": 36.9,
        "y": 0.990332072
    },
    {
        "x": 36.91,
        "y": 0.990332072
    },
    {
        "x": 36.92,
        "y": 0.990332072
    },
    {
        "x": 36.93,
        "y": 0.990752417
    },
    {
        "x": 36.94,
        "y": 0.990332072
    },
    {
        "x": 36.95,
        "y": 0.990332072
    },
    {
        "x": 36.96,
        "y": 0.990332072
    },
    {
        "x": 36.97,
        "y": 0.990752417
    },
    {
        "x": 36.98,
        "y": 0.990332072
    },
    {
        "x": 36.99,
        "y": 0.990332072
    },
    {
        "x": 37,
        "y": 0.990332072
    },
    {
        "x": 37.01,
        "y": 0.990752417
    },
    {
        "x": 37.02,
        "y": 0.990752417
    },
    {
        "x": 37.03,
        "y": 0.990752417
    },
    {
        "x": 37.04,
        "y": 0.990332072
    },
    {
        "x": 37.05,
        "y": 0.990752417
    },
    {
        "x": 37.06,
        "y": 0.990752417
    },
    {
        "x": 37.07,
        "y": 0.990752417
    },
    {
        "x": 37.08,
        "y": 0.990752417
    },
    {
        "x": 37.09,
        "y": 0.990332072
    },
    {
        "x": 37.1,
        "y": 0.990332072
    },
    {
        "x": 37.11,
        "y": 0.990752417
    },
    {
        "x": 37.12,
        "y": 0.990752417
    },
    {
        "x": 37.13,
        "y": 0.990752417
    },
    {
        "x": 37.14,
        "y": 0.990752417
    },
    {
        "x": 37.15,
        "y": 0.990332072
    },
    {
        "x": 37.16,
        "y": 0.990752417
    },
    {
        "x": 37.17,
        "y": 0.990752417
    },
    {
        "x": 37.18,
        "y": 0.990752417
    },
    {
        "x": 37.19,
        "y": 0.990752417
    },
    {
        "x": 37.2,
        "y": 0.990752417
    },
    {
        "x": 37.21,
        "y": 0.990752417
    },
    {
        "x": 37.22,
        "y": 0.990752417
    },
    {
        "x": 37.23,
        "y": 0.990752417
    },
    {
        "x": 37.24,
        "y": 0.990752417
    },
    {
        "x": 37.25,
        "y": 0.990752417
    },
    {
        "x": 37.26,
        "y": 0.990752417
    },
    {
        "x": 37.27,
        "y": 0.990752417
    },
    {
        "x": 37.28,
        "y": 0.990752417
    },
    {
        "x": 37.29,
        "y": 0.990752417
    },
    {
        "x": 37.3,
        "y": 0.990752417
    },
    {
        "x": 37.31,
        "y": 0.990752417
    },
    {
        "x": 37.32,
        "y": 0.990752417
    },
    {
        "x": 37.33,
        "y": 0.990752417
    },
    {
        "x": 37.34,
        "y": 0.990752417
    },
    {
        "x": 37.35,
        "y": 0.991172762
    },
    {
        "x": 37.36,
        "y": 0.991172762
    },
    {
        "x": 37.37,
        "y": 0.990752417
    },
    {
        "x": 37.38,
        "y": 0.990752417
    },
    {
        "x": 37.39,
        "y": 0.990752417
    },
    {
        "x": 37.4,
        "y": 0.990752417
    },
    {
        "x": 37.41,
        "y": 0.991172762
    },
    {
        "x": 37.42,
        "y": 0.991172762
    },
    {
        "x": 37.43,
        "y": 0.991172762
    },
    {
        "x": 37.44,
        "y": 0.991172762
    },
    {
        "x": 37.45,
        "y": 0.991172762
    },
    {
        "x": 37.46,
        "y": 0.991172762
    },
    {
        "x": 37.47,
        "y": 0.991172762
    },
    {
        "x": 37.48,
        "y": 0.991172762
    },
    {
        "x": 37.49,
        "y": 0.991172762
    },
    {
        "x": 37.5,
        "y": 0.991172762
    },
    {
        "x": 37.51,
        "y": 0.991172762
    },
    {
        "x": 37.52,
        "y": 0.991172762
    },
    {
        "x": 37.53,
        "y": 0.991172762
    },
    {
        "x": 37.54,
        "y": 0.991172762
    },
    {
        "x": 37.55,
        "y": 0.991172762
    },
    {
        "x": 37.56,
        "y": 0.991172762
    },
    {
        "x": 37.57,
        "y": 0.991172762
    },
    {
        "x": 37.58,
        "y": 0.991172762
    },
    {
        "x": 37.59,
        "y": 0.991172762
    },
    {
        "x": 37.6,
        "y": 0.991172762
    },
    {
        "x": 37.61,
        "y": 0.991172762
    },
    {
        "x": 37.62,
        "y": 0.991172762
    },
    {
        "x": 37.63,
        "y": 0.991172762
    },
    {
        "x": 37.64,
        "y": 0.991593106
    },
    {
        "x": 37.65,
        "y": 0.991593106
    },
    {
        "x": 37.66,
        "y": 0.991172762
    },
    {
        "x": 37.67,
        "y": 0.991593106
    },
    {
        "x": 37.68,
        "y": 0.991172762
    },
    {
        "x": 37.69,
        "y": 0.991172762
    },
    {
        "x": 37.7,
        "y": 0.991593106
    },
    {
        "x": 37.71,
        "y": 0.991593106
    },
    {
        "x": 37.72,
        "y": 0.991593106
    },
    {
        "x": 37.73,
        "y": 0.991593106
    },
    {
        "x": 37.74,
        "y": 0.991593106
    },
    {
        "x": 37.75,
        "y": 0.991593106
    },
    {
        "x": 37.76,
        "y": 0.991593106
    },
    {
        "x": 37.77,
        "y": 0.991593106
    },
    {
        "x": 37.78,
        "y": 0.991593106
    },
    {
        "x": 37.79,
        "y": 0.991593106
    },
    {
        "x": 37.8,
        "y": 0.991172762
    },
    {
        "x": 37.81,
        "y": 0.991593106
    },
    {
        "x": 37.82,
        "y": 0.991593106
    },
    {
        "x": 37.83,
        "y": 0.991593106
    },
    {
        "x": 37.84,
        "y": 0.991593106
    },
    {
        "x": 37.85,
        "y": 0.991593106
    },
    {
        "x": 37.86,
        "y": 0.991593106
    },
    {
        "x": 37.87,
        "y": 0.991593106
    },
    {
        "x": 37.88,
        "y": 0.991593106
    },
    {
        "x": 37.89,
        "y": 0.991593106
    },
    {
        "x": 37.9,
        "y": 0.991593106
    },
    {
        "x": 37.91,
        "y": 0.991593106
    },
    {
        "x": 37.92,
        "y": 0.991593106
    },
    {
        "x": 37.93,
        "y": 0.991593106
    },
    {
        "x": 37.94,
        "y": 0.991593106
    },
    {
        "x": 37.95,
        "y": 0.991593106
    },
    {
        "x": 37.96,
        "y": 0.991593106
    },
    {
        "x": 37.97,
        "y": 0.991593106
    },
    {
        "x": 37.98,
        "y": 0.991593106
    },
    {
        "x": 37.99,
        "y": 0.991593106
    },
    {
        "x": 38,
        "y": 0.991593106
    },
    {
        "x": 38.01,
        "y": 0.991593106
    },
    {
        "x": 38.02,
        "y": 0.991593106
    },
    {
        "x": 38.03,
        "y": 0.991593106
    },
    {
        "x": 38.04,
        "y": 0.991593106
    },
    {
        "x": 38.05,
        "y": 0.991593106
    },
    {
        "x": 38.06,
        "y": 0.991593106
    },
    {
        "x": 38.07,
        "y": 0.991593106
    },
    {
        "x": 38.08,
        "y": 0.991593106
    },
    {
        "x": 38.09,
        "y": 0.991593106
    },
    {
        "x": 38.1,
        "y": 0.991593106
    },
    {
        "x": 38.11,
        "y": 0.991593106
    },
    {
        "x": 38.12,
        "y": 0.991593106
    },
    {
        "x": 38.13,
        "y": 0.991593106
    },
    {
        "x": 38.14,
        "y": 0.992013451
    },
    {
        "x": 38.15,
        "y": 0.991593106
    },
    {
        "x": 38.16,
        "y": 0.992013451
    },
    {
        "x": 38.17,
        "y": 0.992013451
    },
    {
        "x": 38.18,
        "y": 0.992013451
    },
    {
        "x": 38.19,
        "y": 0.991593106
    },
    {
        "x": 38.2,
        "y": 0.992013451
    },
    {
        "x": 38.21,
        "y": 0.992013451
    },
    {
        "x": 38.22,
        "y": 0.992013451
    },
    {
        "x": 38.23,
        "y": 0.992013451
    },
    {
        "x": 38.24,
        "y": 0.992013451
    },
    {
        "x": 38.25,
        "y": 0.992013451
    },
    {
        "x": 38.26,
        "y": 0.992013451
    },
    {
        "x": 38.27,
        "y": 0.992013451
    },
    {
        "x": 38.28,
        "y": 0.992013451
    },
    {
        "x": 38.29,
        "y": 0.992013451
    },
    {
        "x": 38.3,
        "y": 0.992013451
    },
    {
        "x": 38.31,
        "y": 0.992013451
    },
    {
        "x": 38.32,
        "y": 0.992013451
    },
    {
        "x": 38.33,
        "y": 0.992013451
    },
    {
        "x": 38.34,
        "y": 0.992013451
    },
    {
        "x": 38.35,
        "y": 0.992013451
    },
    {
        "x": 38.36,
        "y": 0.992013451
    },
    {
        "x": 38.37,
        "y": 0.992013451
    },
    {
        "x": 38.38,
        "y": 0.992013451
    },
    {
        "x": 38.39,
        "y": 0.992013451
    },
    {
        "x": 38.4,
        "y": 0.992013451
    },
    {
        "x": 38.41,
        "y": 0.992013451
    },
    {
        "x": 38.42,
        "y": 0.992433796
    },
    {
        "x": 38.43,
        "y": 0.992433796
    },
    {
        "x": 38.44,
        "y": 0.992433796
    },
    {
        "x": 38.45,
        "y": 0.992433796
    },
    {
        "x": 38.46,
        "y": 0.992013451
    },
    {
        "x": 38.47,
        "y": 0.992433796
    },
    {
        "x": 38.48,
        "y": 0.992433796
    },
    {
        "x": 38.49,
        "y": 0.992433796
    },
    {
        "x": 38.5,
        "y": 0.992013451
    },
    {
        "x": 38.51,
        "y": 0.992433796
    },
    {
        "x": 38.52,
        "y": 0.992433796
    },
    {
        "x": 38.53,
        "y": 0.992013451
    },
    {
        "x": 38.54,
        "y": 0.992433796
    },
    {
        "x": 38.55,
        "y": 0.992433796
    },
    {
        "x": 38.56,
        "y": 0.992433796
    },
    {
        "x": 38.57,
        "y": 0.992433796
    },
    {
        "x": 38.58,
        "y": 0.992433796
    },
    {
        "x": 38.59,
        "y": 0.992433796
    },
    {
        "x": 38.6,
        "y": 0.992433796
    },
    {
        "x": 38.61,
        "y": 0.992433796
    },
    {
        "x": 38.62,
        "y": 0.992433796
    },
    {
        "x": 38.63,
        "y": 0.992433796
    },
    {
        "x": 38.64,
        "y": 0.992433796
    },
    {
        "x": 38.65,
        "y": 0.992433796
    },
    {
        "x": 38.66,
        "y": 0.992433796
    },
    {
        "x": 38.67,
        "y": 0.992433796
    },
    {
        "x": 38.68,
        "y": 0.992433796
    },
    {
        "x": 38.69,
        "y": 0.992433796
    },
    {
        "x": 38.7,
        "y": 0.992433796
    },
    {
        "x": 38.71,
        "y": 0.992433796
    },
    {
        "x": 38.72,
        "y": 0.992433796
    },
    {
        "x": 38.73,
        "y": 0.99285414
    },
    {
        "x": 38.74,
        "y": 0.992433796
    },
    {
        "x": 38.75,
        "y": 0.99285414
    },
    {
        "x": 38.76,
        "y": 0.99285414
    },
    {
        "x": 38.77,
        "y": 0.992433796
    },
    {
        "x": 38.78,
        "y": 0.992433796
    },
    {
        "x": 38.79,
        "y": 0.992433796
    },
    {
        "x": 38.8,
        "y": 0.99285414
    },
    {
        "x": 38.81,
        "y": 0.99285414
    },
    {
        "x": 38.82,
        "y": 0.992433796
    },
    {
        "x": 38.83,
        "y": 0.99285414
    },
    {
        "x": 38.84,
        "y": 0.992433796
    },
    {
        "x": 38.85,
        "y": 0.99285414
    },
    {
        "x": 38.86,
        "y": 0.99285414
    },
    {
        "x": 38.87,
        "y": 0.99285414
    },
    {
        "x": 38.88,
        "y": 0.99285414
    },
    {
        "x": 38.89,
        "y": 0.99285414
    },
    {
        "x": 38.9,
        "y": 0.99285414
    },
    {
        "x": 38.91,
        "y": 0.99285414
    },
    {
        "x": 38.92,
        "y": 0.99285414
    },
    {
        "x": 38.93,
        "y": 0.99285414
    },
    {
        "x": 38.94,
        "y": 0.99285414
    },
    {
        "x": 38.95,
        "y": 0.99285414
    },
    {
        "x": 38.96,
        "y": 0.99285414
    },
    {
        "x": 38.97,
        "y": 0.99285414
    },
    {
        "x": 38.98,
        "y": 0.99285414
    },
    {
        "x": 38.99,
        "y": 0.99285414
    },
    {
        "x": 39,
        "y": 0.99285414
    },
    {
        "x": 39.01,
        "y": 0.99285414
    },
    {
        "x": 39.02,
        "y": 0.99285414
    },
    {
        "x": 39.03,
        "y": 0.99285414
    },
    {
        "x": 39.04,
        "y": 0.99285414
    },
    {
        "x": 39.05,
        "y": 0.99285414
    },
    {
        "x": 39.06,
        "y": 0.99285414
    },
    {
        "x": 39.07,
        "y": 0.99285414
    },
    {
        "x": 39.08,
        "y": 0.993274485
    },
    {
        "x": 39.09,
        "y": 0.99285414
    },
    {
        "x": 39.1,
        "y": 0.99285414
    },
    {
        "x": 39.11,
        "y": 0.99285414
    },
    {
        "x": 39.12,
        "y": 0.99285414
    },
    {
        "x": 39.13,
        "y": 0.99285414
    },
    {
        "x": 39.14,
        "y": 0.993274485
    },
    {
        "x": 39.15,
        "y": 0.993274485
    },
    {
        "x": 39.16,
        "y": 0.993274485
    },
    {
        "x": 39.17,
        "y": 0.993274485
    },
    {
        "x": 39.18,
        "y": 0.99285414
    },
    {
        "x": 39.19,
        "y": 0.993274485
    },
    {
        "x": 39.2,
        "y": 0.99285414
    },
    {
        "x": 39.21,
        "y": 0.993274485
    },
    {
        "x": 39.22,
        "y": 0.993274485
    },
    {
        "x": 39.23,
        "y": 0.993274485
    },
    {
        "x": 39.24,
        "y": 0.993274485
    },
    {
        "x": 39.25,
        "y": 0.993274485
    },
    {
        "x": 39.26,
        "y": 0.993274485
    },
    {
        "x": 39.27,
        "y": 0.993274485
    },
    {
        "x": 39.28,
        "y": 0.993274485
    },
    {
        "x": 39.29,
        "y": 0.993274485
    },
    {
        "x": 39.3,
        "y": 0.993274485
    },
    {
        "x": 39.31,
        "y": 0.993274485
    },
    {
        "x": 39.32,
        "y": 0.993274485
    },
    {
        "x": 39.33,
        "y": 0.993274485
    },
    {
        "x": 39.34,
        "y": 0.993274485
    },
    {
        "x": 39.35,
        "y": 0.993274485
    },
    {
        "x": 39.36,
        "y": 0.993274485
    },
    {
        "x": 39.37,
        "y": 0.993274485
    },
    {
        "x": 39.38,
        "y": 0.993274485
    },
    {
        "x": 39.39,
        "y": 0.993274485
    },
    {
        "x": 39.4,
        "y": 0.993274485
    },
    {
        "x": 39.41,
        "y": 0.993274485
    },
    {
        "x": 39.42,
        "y": 0.993274485
    },
    {
        "x": 39.43,
        "y": 0.993274485
    },
    {
        "x": 39.44,
        "y": 0.993274485
    },
    {
        "x": 39.45,
        "y": 0.993274485
    },
    {
        "x": 39.46,
        "y": 0.993274485
    },
    {
        "x": 39.47,
        "y": 0.993274485
    },
    {
        "x": 39.48,
        "y": 0.993274485
    },
    {
        "x": 39.49,
        "y": 0.993274485
    },
    {
        "x": 39.5,
        "y": 0.99369483
    },
    {
        "x": 39.51,
        "y": 0.993274485
    },
    {
        "x": 39.52,
        "y": 0.993274485
    },
    {
        "x": 39.53,
        "y": 0.993274485
    },
    {
        "x": 39.54,
        "y": 0.993274485
    },
    {
        "x": 39.55,
        "y": 0.993274485
    },
    {
        "x": 39.56,
        "y": 0.993274485
    },
    {
        "x": 39.57,
        "y": 0.99369483
    },
    {
        "x": 39.58,
        "y": 0.99369483
    },
    {
        "x": 39.59,
        "y": 0.993274485
    },
    {
        "x": 39.6,
        "y": 0.993274485
    },
    {
        "x": 39.61,
        "y": 0.993274485
    },
    {
        "x": 39.62,
        "y": 0.993274485
    },
    {
        "x": 39.63,
        "y": 0.993274485
    },
    {
        "x": 39.64,
        "y": 0.993274485
    },
    {
        "x": 39.65,
        "y": 0.99369483
    },
    {
        "x": 39.66,
        "y": 0.993274485
    },
    {
        "x": 39.67,
        "y": 0.99369483
    },
    {
        "x": 39.68,
        "y": 0.99369483
    },
    {
        "x": 39.69,
        "y": 0.99369483
    },
    {
        "x": 39.7,
        "y": 0.99369483
    },
    {
        "x": 39.71,
        "y": 0.99369483
    },
    {
        "x": 39.72,
        "y": 0.99369483
    },
    {
        "x": 39.73,
        "y": 0.99369483
    },
    {
        "x": 39.74,
        "y": 0.99369483
    },
    {
        "x": 39.75,
        "y": 0.99369483
    },
    {
        "x": 39.76,
        "y": 0.99369483
    },
    {
        "x": 39.77,
        "y": 0.99369483
    },
    {
        "x": 39.78,
        "y": 0.993274485
    },
    {
        "x": 39.79,
        "y": 0.99369483
    },
    {
        "x": 39.8,
        "y": 0.99369483
    },
    {
        "x": 39.81,
        "y": 0.99369483
    },
    {
        "x": 39.82,
        "y": 0.99369483
    },
    {
        "x": 39.83,
        "y": 0.99369483
    },
    {
        "x": 39.84,
        "y": 0.99369483
    },
    {
        "x": 39.85,
        "y": 0.99369483
    },
    {
        "x": 39.86,
        "y": 0.99369483
    },
    {
        "x": 39.87,
        "y": 0.99369483
    },
    {
        "x": 39.88,
        "y": 0.99369483
    },
    {
        "x": 39.89,
        "y": 0.99369483
    },
    {
        "x": 39.9,
        "y": 0.99369483
    },
    {
        "x": 39.91,
        "y": 0.99369483
    },
    {
        "x": 39.92,
        "y": 0.99369483
    },
    {
        "x": 39.93,
        "y": 0.99369483
    },
    {
        "x": 39.94,
        "y": 0.99369483
    },
    {
        "x": 39.95,
        "y": 0.99369483
    },
    {
        "x": 39.96,
        "y": 0.99369483
    },
    {
        "x": 39.97,
        "y": 0.99369483
    },
    {
        "x": 39.98,
        "y": 0.99369483
    },
    {
        "x": 39.99,
        "y": 0.99369483
    },
    {
        "x": 40,
        "y": 0.99369483
    },
    {
        "x": 40.01,
        "y": 0.99369483
    },
    {
        "x": 40.02,
        "y": 0.99369483
    },
    {
        "x": 40.03,
        "y": 0.994115174
    },
    {
        "x": 40.04,
        "y": 0.994115174
    },
    {
        "x": 40.05,
        "y": 0.99369483
    },
    {
        "x": 40.06,
        "y": 0.994115174
    },
    {
        "x": 40.07,
        "y": 0.994115174
    },
    {
        "x": 40.08,
        "y": 0.994115174
    },
    {
        "x": 40.09,
        "y": 0.994115174
    },
    {
        "x": 40.1,
        "y": 0.994115174
    },
    {
        "x": 40.11,
        "y": 0.994115174
    },
    {
        "x": 40.12,
        "y": 0.994115174
    },
    {
        "x": 40.13,
        "y": 0.994115174
    },
    {
        "x": 40.14,
        "y": 0.994115174
    },
    {
        "x": 40.15,
        "y": 0.994115174
    },
    {
        "x": 40.16,
        "y": 0.994115174
    },
    {
        "x": 40.17,
        "y": 0.994115174
    },
    {
        "x": 40.18,
        "y": 0.994115174
    },
    {
        "x": 40.19,
        "y": 0.994115174
    },
    {
        "x": 40.2,
        "y": 0.994115174
    },
    {
        "x": 40.21,
        "y": 0.994115174
    },
    {
        "x": 40.22,
        "y": 0.994115174
    },
    {
        "x": 40.23,
        "y": 0.994115174
    },
    {
        "x": 40.24,
        "y": 0.994115174
    },
    {
        "x": 40.25,
        "y": 0.994115174
    },
    {
        "x": 40.26,
        "y": 0.994115174
    },
    {
        "x": 40.27,
        "y": 0.994115174
    },
    {
        "x": 40.28,
        "y": 0.994115174
    },
    {
        "x": 40.29,
        "y": 0.994115174
    },
    {
        "x": 40.3,
        "y": 0.994115174
    },
    {
        "x": 40.31,
        "y": 0.994115174
    },
    {
        "x": 40.32,
        "y": 0.994115174
    },
    {
        "x": 40.33,
        "y": 0.994115174
    },
    {
        "x": 40.34,
        "y": 0.994115174
    },
    {
        "x": 40.35,
        "y": 0.994115174
    },
    {
        "x": 40.36,
        "y": 0.994115174
    },
    {
        "x": 40.37,
        "y": 0.994115174
    },
    {
        "x": 40.38,
        "y": 0.994115174
    },
    {
        "x": 40.39,
        "y": 0.994535519
    },
    {
        "x": 40.4,
        "y": 0.994115174
    },
    {
        "x": 40.41,
        "y": 0.994115174
    },
    {
        "x": 40.42,
        "y": 0.994115174
    },
    {
        "x": 40.43,
        "y": 0.994115174
    },
    {
        "x": 40.44,
        "y": 0.994115174
    },
    {
        "x": 40.45,
        "y": 0.994115174
    },
    {
        "x": 40.46,
        "y": 0.994115174
    },
    {
        "x": 40.47,
        "y": 0.994115174
    },
    {
        "x": 40.48,
        "y": 0.994115174
    },
    {
        "x": 40.49,
        "y": 0.994115174
    },
    {
        "x": 40.5,
        "y": 0.994115174
    },
    {
        "x": 40.51,
        "y": 0.994115174
    },
    {
        "x": 40.52,
        "y": 0.994115174
    },
    {
        "x": 40.53,
        "y": 0.994115174
    },
    {
        "x": 40.54,
        "y": 0.994115174
    },
    {
        "x": 40.55,
        "y": 0.994115174
    },
    {
        "x": 40.56,
        "y": 0.994535519
    },
    {
        "x": 40.57,
        "y": 0.994115174
    },
    {
        "x": 40.58,
        "y": 0.994115174
    },
    {
        "x": 40.59,
        "y": 0.994535519
    },
    {
        "x": 40.6,
        "y": 0.994115174
    },
    {
        "x": 40.61,
        "y": 0.994535519
    },
    {
        "x": 40.62,
        "y": 0.994115174
    },
    {
        "x": 40.63,
        "y": 0.994115174
    },
    {
        "x": 40.64,
        "y": 0.994115174
    },
    {
        "x": 40.65,
        "y": 0.994115174
    },
    {
        "x": 40.66,
        "y": 0.994535519
    },
    {
        "x": 40.67,
        "y": 0.994535519
    },
    {
        "x": 40.68,
        "y": 0.994535519
    },
    {
        "x": 40.69,
        "y": 0.994535519
    },
    {
        "x": 40.7,
        "y": 0.994535519
    },
    {
        "x": 40.71,
        "y": 0.994535519
    },
    {
        "x": 40.72,
        "y": 0.994535519
    },
    {
        "x": 40.73,
        "y": 0.994535519
    },
    {
        "x": 40.74,
        "y": 0.994535519
    },
    {
        "x": 40.75,
        "y": 0.994115174
    },
    {
        "x": 40.76,
        "y": 0.994535519
    },
    {
        "x": 40.77,
        "y": 0.994535519
    },
    {
        "x": 40.78,
        "y": 0.994535519
    },
    {
        "x": 40.79,
        "y": 0.994535519
    },
    {
        "x": 40.8,
        "y": 0.994535519
    },
    {
        "x": 40.81,
        "y": 0.994535519
    },
    {
        "x": 40.82,
        "y": 0.994535519
    },
    {
        "x": 40.83,
        "y": 0.994535519
    },
    {
        "x": 40.84,
        "y": 0.994535519
    },
    {
        "x": 40.85,
        "y": 0.994535519
    },
    {
        "x": 40.86,
        "y": 0.994535519
    },
    {
        "x": 40.87,
        "y": 0.994535519
    },
    {
        "x": 40.88,
        "y": 0.994535519
    },
    {
        "x": 40.89,
        "y": 0.994535519
    },
    {
        "x": 40.9,
        "y": 0.994535519
    },
    {
        "x": 40.91,
        "y": 0.994535519
    },
    {
        "x": 40.92,
        "y": 0.994535519
    },
    {
        "x": 40.93,
        "y": 0.994535519
    },
    {
        "x": 40.94,
        "y": 0.994535519
    },
    {
        "x": 40.95,
        "y": 0.994535519
    },
    {
        "x": 40.96,
        "y": 0.994535519
    },
    {
        "x": 40.97,
        "y": 0.994535519
    },
    {
        "x": 40.98,
        "y": 0.994535519
    },
    {
        "x": 40.99,
        "y": 0.994535519
    },
    {
        "x": 41,
        "y": 0.994535519
    },
    {
        "x": 41.01,
        "y": 0.994955864
    },
    {
        "x": 41.02,
        "y": 0.994535519
    },
    {
        "x": 41.03,
        "y": 0.994535519
    },
    {
        "x": 41.04,
        "y": 0.994535519
    },
    {
        "x": 41.05,
        "y": 0.994955864
    },
    {
        "x": 41.06,
        "y": 0.994535519
    },
    {
        "x": 41.07,
        "y": 0.994535519
    },
    {
        "x": 41.08,
        "y": 0.994535519
    },
    {
        "x": 41.09,
        "y": 0.994535519
    },
    {
        "x": 41.1,
        "y": 0.994955864
    },
    {
        "x": 41.11,
        "y": 0.994535519
    },
    {
        "x": 41.12,
        "y": 0.994535519
    },
    {
        "x": 41.13,
        "y": 0.994955864
    },
    {
        "x": 41.14,
        "y": 0.994955864
    },
    {
        "x": 41.15,
        "y": 0.994955864
    },
    {
        "x": 41.16,
        "y": 0.994955864
    },
    {
        "x": 41.17,
        "y": 0.994955864
    },
    {
        "x": 41.18,
        "y": 0.994535519
    },
    {
        "x": 41.19,
        "y": 0.994955864
    },
    {
        "x": 41.2,
        "y": 0.994955864
    },
    {
        "x": 41.21,
        "y": 0.994955864
    },
    {
        "x": 41.22,
        "y": 0.994955864
    },
    {
        "x": 41.23,
        "y": 0.994955864
    },
    {
        "x": 41.24,
        "y": 0.994955864
    },
    {
        "x": 41.25,
        "y": 0.994955864
    },
    {
        "x": 41.26,
        "y": 0.994955864
    },
    {
        "x": 41.27,
        "y": 0.994955864
    },
    {
        "x": 41.28,
        "y": 0.994955864
    },
    {
        "x": 41.29,
        "y": 0.994955864
    },
    {
        "x": 41.3,
        "y": 0.994955864
    },
    {
        "x": 41.31,
        "y": 0.994955864
    },
    {
        "x": 41.32,
        "y": 0.994955864
    },
    {
        "x": 41.33,
        "y": 0.994955864
    },
    {
        "x": 41.34,
        "y": 0.994955864
    },
    {
        "x": 41.35,
        "y": 0.994955864
    },
    {
        "x": 41.36,
        "y": 0.994955864
    },
    {
        "x": 41.37,
        "y": 0.994955864
    },
    {
        "x": 41.38,
        "y": 0.994955864
    },
    {
        "x": 41.39,
        "y": 0.994955864
    },
    {
        "x": 41.4,
        "y": 0.994955864
    },
    {
        "x": 41.41,
        "y": 0.994955864
    },
    {
        "x": 41.42,
        "y": 0.994955864
    },
    {
        "x": 41.43,
        "y": 0.994955864
    },
    {
        "x": 41.44,
        "y": 0.994955864
    },
    {
        "x": 41.45,
        "y": 0.994955864
    },
    {
        "x": 41.46,
        "y": 0.995376208
    },
    {
        "x": 41.47,
        "y": 0.995376208
    },
    {
        "x": 41.48,
        "y": 0.994955864
    },
    {
        "x": 41.49,
        "y": 0.994955864
    },
    {
        "x": 41.5,
        "y": 0.994955864
    },
    {
        "x": 41.51,
        "y": 0.994955864
    },
    {
        "x": 41.52,
        "y": 0.994955864
    },
    {
        "x": 41.53,
        "y": 0.994955864
    },
    {
        "x": 41.54,
        "y": 0.995376208
    },
    {
        "x": 41.55,
        "y": 0.995376208
    },
    {
        "x": 41.56,
        "y": 0.995376208
    },
    {
        "x": 41.57,
        "y": 0.994955864
    },
    {
        "x": 41.58,
        "y": 0.994955864
    },
    {
        "x": 41.59,
        "y": 0.995376208
    },
    {
        "x": 41.6,
        "y": 0.994955864
    },
    {
        "x": 41.61,
        "y": 0.995376208
    },
    {
        "x": 41.62,
        "y": 0.995376208
    },
    {
        "x": 41.63,
        "y": 0.995376208
    },
    {
        "x": 41.64,
        "y": 0.994955864
    },
    {
        "x": 41.65,
        "y": 0.994955864
    },
    {
        "x": 41.66,
        "y": 0.995376208
    },
    {
        "x": 41.67,
        "y": 0.995376208
    },
    {
        "x": 41.68,
        "y": 0.995376208
    },
    {
        "x": 41.69,
        "y": 0.995376208
    },
    {
        "x": 41.7,
        "y": 0.995376208
    },
    {
        "x": 41.71,
        "y": 0.994955864
    },
    {
        "x": 41.72,
        "y": 0.995376208
    },
    {
        "x": 41.73,
        "y": 0.995376208
    },
    {
        "x": 41.74,
        "y": 0.995376208
    },
    {
        "x": 41.75,
        "y": 0.995376208
    },
    {
        "x": 41.76,
        "y": 0.995376208
    },
    {
        "x": 41.77,
        "y": 0.995376208
    },
    {
        "x": 41.78,
        "y": 0.995376208
    },
    {
        "x": 41.79,
        "y": 0.995376208
    },
    {
        "x": 41.8,
        "y": 0.995376208
    },
    {
        "x": 41.81,
        "y": 0.995376208
    },
    {
        "x": 41.82,
        "y": 0.995376208
    },
    {
        "x": 41.83,
        "y": 0.995376208
    },
    {
        "x": 41.84,
        "y": 0.995376208
    },
    {
        "x": 41.85,
        "y": 0.995376208
    },
    {
        "x": 41.86,
        "y": 0.995376208
    },
    {
        "x": 41.87,
        "y": 0.995376208
    },
    {
        "x": 41.88,
        "y": 0.995376208
    },
    {
        "x": 41.89,
        "y": 0.995376208
    },
    {
        "x": 41.9,
        "y": 0.995376208
    },
    {
        "x": 41.91,
        "y": 0.995376208
    },
    {
        "x": 41.92,
        "y": 0.995376208
    },
    {
        "x": 41.93,
        "y": 0.995376208
    },
    {
        "x": 41.94,
        "y": 0.995376208
    },
    {
        "x": 41.95,
        "y": 0.995376208
    },
    {
        "x": 41.96,
        "y": 0.995376208
    },
    {
        "x": 41.97,
        "y": 0.995376208
    },
    {
        "x": 41.98,
        "y": 0.995376208
    },
    {
        "x": 41.99,
        "y": 0.995376208
    },
    {
        "x": 42,
        "y": 0.995376208
    },
    {
        "x": 42.01,
        "y": 0.995376208
    },
    {
        "x": 42.02,
        "y": 0.995376208
    },
    {
        "x": 42.03,
        "y": 0.995376208
    },
    {
        "x": 42.04,
        "y": 0.995376208
    },
    {
        "x": 42.05,
        "y": 0.995376208
    },
    {
        "x": 42.06,
        "y": 0.995376208
    },
    {
        "x": 42.07,
        "y": 0.995376208
    },
    {
        "x": 42.08,
        "y": 0.995376208
    },
    {
        "x": 42.09,
        "y": 0.995376208
    },
    {
        "x": 42.1,
        "y": 0.995376208
    },
    {
        "x": 42.11,
        "y": 0.995376208
    },
    {
        "x": 42.12,
        "y": 0.995376208
    },
    {
        "x": 42.13,
        "y": 0.995376208
    },
    {
        "x": 42.14,
        "y": 0.995376208
    },
    {
        "x": 42.15,
        "y": 0.995796553
    },
    {
        "x": 42.16,
        "y": 0.995376208
    },
    {
        "x": 42.17,
        "y": 0.995376208
    },
    {
        "x": 42.18,
        "y": 0.995376208
    },
    {
        "x": 42.19,
        "y": 0.995376208
    },
    {
        "x": 42.2,
        "y": 0.995796553
    },
    {
        "x": 42.21,
        "y": 0.995796553
    },
    {
        "x": 42.22,
        "y": 0.995376208
    },
    {
        "x": 42.23,
        "y": 0.995796553
    },
    {
        "x": 42.24,
        "y": 0.995796553
    },
    {
        "x": 42.25,
        "y": 0.995796553
    },
    {
        "x": 42.26,
        "y": 0.995796553
    },
    {
        "x": 42.27,
        "y": 0.995796553
    },
    {
        "x": 42.28,
        "y": 0.995796553
    },
    {
        "x": 42.29,
        "y": 0.995796553
    },
    {
        "x": 42.3,
        "y": 0.995376208
    },
    {
        "x": 42.31,
        "y": 0.995376208
    },
    {
        "x": 42.32,
        "y": 0.995796553
    },
    {
        "x": 42.33,
        "y": 0.995796553
    },
    {
        "x": 42.34,
        "y": 0.995796553
    },
    {
        "x": 42.35,
        "y": 0.995796553
    },
    {
        "x": 42.36,
        "y": 0.995796553
    },
    {
        "x": 42.37,
        "y": 0.995376208
    },
    {
        "x": 42.38,
        "y": 0.995796553
    },
    {
        "x": 42.39,
        "y": 0.995796553
    },
    {
        "x": 42.4,
        "y": 0.995796553
    },
    {
        "x": 42.41,
        "y": 0.995796553
    },
    {
        "x": 42.42,
        "y": 0.995796553
    },
    {
        "x": 42.43,
        "y": 0.995796553
    },
    {
        "x": 42.44,
        "y": 0.995796553
    },
    {
        "x": 42.45,
        "y": 0.995796553
    },
    {
        "x": 42.46,
        "y": 0.995796553
    },
    {
        "x": 42.47,
        "y": 0.995376208
    },
    {
        "x": 42.48,
        "y": 0.995796553
    },
    {
        "x": 42.49,
        "y": 0.995796553
    },
    {
        "x": 42.5,
        "y": 0.995796553
    },
    {
        "x": 42.51,
        "y": 0.995796553
    },
    {
        "x": 42.52,
        "y": 0.995796553
    },
    {
        "x": 42.53,
        "y": 0.995796553
    },
    {
        "x": 42.54,
        "y": 0.995796553
    },
    {
        "x": 42.55,
        "y": 0.995796553
    },
    {
        "x": 42.56,
        "y": 0.995796553
    },
    {
        "x": 42.57,
        "y": 0.995796553
    },
    {
        "x": 42.58,
        "y": 0.995796553
    },
    {
        "x": 42.59,
        "y": 0.995796553
    },
    {
        "x": 42.6,
        "y": 0.995796553
    },
    {
        "x": 42.61,
        "y": 0.995796553
    },
    {
        "x": 42.62,
        "y": 0.995796553
    },
    {
        "x": 42.63,
        "y": 0.995796553
    },
    {
        "x": 42.64,
        "y": 0.995796553
    },
    {
        "x": 42.65,
        "y": 0.995796553
    },
    {
        "x": 42.66,
        "y": 0.995796553
    },
    {
        "x": 42.67,
        "y": 0.995796553
    },
    {
        "x": 42.68,
        "y": 0.995796553
    },
    {
        "x": 42.69,
        "y": 0.995796553
    },
    {
        "x": 42.7,
        "y": 0.996216898
    },
    {
        "x": 42.71,
        "y": 0.995796553
    },
    {
        "x": 42.72,
        "y": 0.995796553
    },
    {
        "x": 42.73,
        "y": 0.995796553
    },
    {
        "x": 42.74,
        "y": 0.996216898
    },
    {
        "x": 42.75,
        "y": 0.996216898
    },
    {
        "x": 42.76,
        "y": 0.995796553
    },
    {
        "x": 42.77,
        "y": 0.995796553
    },
    {
        "x": 42.78,
        "y": 0.995796553
    },
    {
        "x": 42.79,
        "y": 0.996216898
    },
    {
        "x": 42.8,
        "y": 0.996216898
    },
    {
        "x": 42.81,
        "y": 0.996216898
    },
    {
        "x": 42.82,
        "y": 0.995796553
    },
    {
        "x": 42.83,
        "y": 0.995796553
    },
    {
        "x": 42.84,
        "y": 0.996216898
    },
    {
        "x": 42.85,
        "y": 0.995796553
    },
    {
        "x": 42.86,
        "y": 0.996216898
    },
    {
        "x": 42.87,
        "y": 0.996216898
    },
    {
        "x": 42.88,
        "y": 0.995796553
    },
    {
        "x": 42.89,
        "y": 0.995796553
    },
    {
        "x": 42.9,
        "y": 0.995796553
    },
    {
        "x": 42.91,
        "y": 0.995796553
    },
    {
        "x": 42.92,
        "y": 0.996216898
    },
    {
        "x": 42.93,
        "y": 0.995796553
    },
    {
        "x": 42.94,
        "y": 0.996216898
    },
    {
        "x": 42.95,
        "y": 0.996216898
    },
    {
        "x": 42.96,
        "y": 0.996216898
    },
    {
        "x": 42.97,
        "y": 0.996216898
    },
    {
        "x": 42.98,
        "y": 0.996216898
    },
    {
        "x": 42.99,
        "y": 0.996216898
    },
    {
        "x": 43,
        "y": 0.996216898
    },
    {
        "x": 43.01,
        "y": 0.995796553
    },
    {
        "x": 43.02,
        "y": 0.996216898
    },
    {
        "x": 43.03,
        "y": 0.996216898
    },
    {
        "x": 43.04,
        "y": 0.996216898
    },
    {
        "x": 43.05,
        "y": 0.996216898
    },
    {
        "x": 43.06,
        "y": 0.996216898
    },
    {
        "x": 43.07,
        "y": 0.996216898
    },
    {
        "x": 43.08,
        "y": 0.996216898
    },
    {
        "x": 43.09,
        "y": 0.996216898
    },
    {
        "x": 43.1,
        "y": 0.996216898
    },
    {
        "x": 43.11,
        "y": 0.996216898
    },
    {
        "x": 43.12,
        "y": 0.996216898
    },
    {
        "x": 43.13,
        "y": 0.996216898
    },
    {
        "x": 43.14,
        "y": 0.996216898
    },
    {
        "x": 43.15,
        "y": 0.996216898
    },
    {
        "x": 43.16,
        "y": 0.996216898
    },
    {
        "x": 43.17,
        "y": 0.996216898
    },
    {
        "x": 43.18,
        "y": 0.996216898
    },
    {
        "x": 43.19,
        "y": 0.996216898
    },
    {
        "x": 43.2,
        "y": 0.996216898
    },
    {
        "x": 43.21,
        "y": 0.996216898
    },
    {
        "x": 43.22,
        "y": 0.996216898
    },
    {
        "x": 43.23,
        "y": 0.996216898
    },
    {
        "x": 43.24,
        "y": 0.996216898
    },
    {
        "x": 43.25,
        "y": 0.996216898
    },
    {
        "x": 43.26,
        "y": 0.996216898
    },
    {
        "x": 43.27,
        "y": 0.996216898
    },
    {
        "x": 43.28,
        "y": 0.996216898
    },
    {
        "x": 43.29,
        "y": 0.996216898
    },
    {
        "x": 43.3,
        "y": 0.996216898
    },
    {
        "x": 43.31,
        "y": 0.996216898
    },
    {
        "x": 43.32,
        "y": 0.996216898
    },
    {
        "x": 43.33,
        "y": 0.996216898
    },
    {
        "x": 43.34,
        "y": 0.996216898
    },
    {
        "x": 43.35,
        "y": 0.996216898
    },
    {
        "x": 43.36,
        "y": 0.996216898
    },
    {
        "x": 43.37,
        "y": 0.996216898
    },
    {
        "x": 43.38,
        "y": 0.996216898
    },
    {
        "x": 43.39,
        "y": 0.996216898
    },
    {
        "x": 43.4,
        "y": 0.996216898
    },
    {
        "x": 43.41,
        "y": 0.996216898
    },
    {
        "x": 43.42,
        "y": 0.996216898
    },
    {
        "x": 43.43,
        "y": 0.996216898
    },
    {
        "x": 43.44,
        "y": 0.996216898
    },
    {
        "x": 43.45,
        "y": 0.996216898
    },
    {
        "x": 43.46,
        "y": 0.996216898
    },
    {
        "x": 43.47,
        "y": 0.996216898
    },
    {
        "x": 43.48,
        "y": 0.996216898
    },
    {
        "x": 43.49,
        "y": 0.996216898
    },
    {
        "x": 43.5,
        "y": 0.996216898
    },
    {
        "x": 43.51,
        "y": 0.996216898
    },
    {
        "x": 43.52,
        "y": 0.996216898
    },
    {
        "x": 43.53,
        "y": 0.996216898
    },
    {
        "x": 43.54,
        "y": 0.996216898
    },
    {
        "x": 43.55,
        "y": 0.996216898
    },
    {
        "x": 43.56,
        "y": 0.996216898
    },
    {
        "x": 43.57,
        "y": 0.996216898
    },
    {
        "x": 43.58,
        "y": 0.996216898
    },
    {
        "x": 43.59,
        "y": 0.996216898
    },
    {
        "x": 43.6,
        "y": 0.996216898
    },
    {
        "x": 43.61,
        "y": 0.996637243
    },
    {
        "x": 43.62,
        "y": 0.996637243
    },
    {
        "x": 43.63,
        "y": 0.996216898
    },
    {
        "x": 43.64,
        "y": 0.996216898
    },
    {
        "x": 43.65,
        "y": 0.996637243
    },
    {
        "x": 43.66,
        "y": 0.996216898
    },
    {
        "x": 43.67,
        "y": 0.996637243
    },
    {
        "x": 43.68,
        "y": 0.996637243
    },
    {
        "x": 43.69,
        "y": 0.996637243
    },
    {
        "x": 43.7,
        "y": 0.996637243
    },
    {
        "x": 43.71,
        "y": 0.996637243
    },
    {
        "x": 43.72,
        "y": 0.996637243
    },
    {
        "x": 43.73,
        "y": 0.996637243
    },
    {
        "x": 43.74,
        "y": 0.996637243
    },
    {
        "x": 43.75,
        "y": 0.996637243
    },
    {
        "x": 43.76,
        "y": 0.996637243
    },
    {
        "x": 43.77,
        "y": 0.996637243
    },
    {
        "x": 43.78,
        "y": 0.996637243
    },
    {
        "x": 43.79,
        "y": 0.996637243
    },
    {
        "x": 43.8,
        "y": 0.996637243
    },
    {
        "x": 43.81,
        "y": 0.996637243
    },
    {
        "x": 43.82,
        "y": 0.996637243
    },
    {
        "x": 43.83,
        "y": 0.996637243
    },
    {
        "x": 43.84,
        "y": 0.996637243
    },
    {
        "x": 43.85,
        "y": 0.996637243
    },
    {
        "x": 43.86,
        "y": 0.996637243
    },
    {
        "x": 43.87,
        "y": 0.996637243
    },
    {
        "x": 43.88,
        "y": 0.996637243
    },
    {
        "x": 43.89,
        "y": 0.996637243
    },
    {
        "x": 43.9,
        "y": 0.996637243
    },
    {
        "x": 43.91,
        "y": 0.996637243
    },
    {
        "x": 43.92,
        "y": 0.996637243
    },
    {
        "x": 43.93,
        "y": 0.996637243
    },
    {
        "x": 43.94,
        "y": 0.996637243
    },
    {
        "x": 43.95,
        "y": 0.996637243
    },
    {
        "x": 43.96,
        "y": 0.996637243
    },
    {
        "x": 43.97,
        "y": 0.996637243
    },
    {
        "x": 43.98,
        "y": 0.996637243
    },
    {
        "x": 43.99,
        "y": 0.996637243
    },
    {
        "x": 44,
        "y": 0.996637243
    },
    {
        "x": 44.01,
        "y": 0.996637243
    },
    {
        "x": 44.02,
        "y": 0.996637243
    },
    {
        "x": 44.03,
        "y": 0.996637243
    },
    {
        "x": 44.04,
        "y": 0.996637243
    },
    {
        "x": 44.05,
        "y": 0.996637243
    },
    {
        "x": 44.06,
        "y": 0.996637243
    },
    {
        "x": 44.07,
        "y": 0.997057587
    },
    {
        "x": 44.08,
        "y": 0.996637243
    },
    {
        "x": 44.09,
        "y": 0.996637243
    },
    {
        "x": 44.1,
        "y": 0.996637243
    },
    {
        "x": 44.11,
        "y": 0.996637243
    },
    {
        "x": 44.12,
        "y": 0.996637243
    },
    {
        "x": 44.13,
        "y": 0.996637243
    },
    {
        "x": 44.14,
        "y": 0.996637243
    },
    {
        "x": 44.15,
        "y": 0.996637243
    },
    {
        "x": 44.16,
        "y": 0.996637243
    },
    {
        "x": 44.17,
        "y": 0.996637243
    },
    {
        "x": 44.18,
        "y": 0.996637243
    },
    {
        "x": 44.19,
        "y": 0.996637243
    },
    {
        "x": 44.2,
        "y": 0.996637243
    },
    {
        "x": 44.21,
        "y": 0.996637243
    },
    {
        "x": 44.22,
        "y": 0.996637243
    },
    {
        "x": 44.23,
        "y": 0.997057587
    },
    {
        "x": 44.24,
        "y": 0.997057587
    },
    {
        "x": 44.25,
        "y": 0.996637243
    },
    {
        "x": 44.26,
        "y": 0.996637243
    },
    {
        "x": 44.27,
        "y": 0.996637243
    },
    {
        "x": 44.28,
        "y": 0.996637243
    },
    {
        "x": 44.29,
        "y": 0.996637243
    },
    {
        "x": 44.3,
        "y": 0.996637243
    },
    {
        "x": 44.31,
        "y": 0.996637243
    },
    {
        "x": 44.32,
        "y": 0.997057587
    },
    {
        "x": 44.33,
        "y": 0.997057587
    },
    {
        "x": 44.34,
        "y": 0.996637243
    },
    {
        "x": 44.35,
        "y": 0.996637243
    },
    {
        "x": 44.36,
        "y": 0.996637243
    },
    {
        "x": 44.37,
        "y": 0.996637243
    },
    {
        "x": 44.38,
        "y": 0.997057587
    },
    {
        "x": 44.39,
        "y": 0.997057587
    },
    {
        "x": 44.4,
        "y": 0.997057587
    },
    {
        "x": 44.41,
        "y": 0.996637243
    },
    {
        "x": 44.42,
        "y": 0.997057587
    },
    {
        "x": 44.43,
        "y": 0.996637243
    },
    {
        "x": 44.44,
        "y": 0.997057587
    },
    {
        "x": 44.45,
        "y": 0.997057587
    },
    {
        "x": 44.46,
        "y": 0.997057587
    },
    {
        "x": 44.47,
        "y": 0.997057587
    },
    {
        "x": 44.48,
        "y": 0.997057587
    },
    {
        "x": 44.49,
        "y": 0.997057587
    },
    {
        "x": 44.5,
        "y": 0.997057587
    },
    {
        "x": 44.51,
        "y": 0.996637243
    },
    {
        "x": 44.52,
        "y": 0.997057587
    },
    {
        "x": 44.53,
        "y": 0.997057587
    },
    {
        "x": 44.54,
        "y": 0.997057587
    },
    {
        "x": 44.55,
        "y": 0.997057587
    },
    {
        "x": 44.56,
        "y": 0.997057587
    },
    {
        "x": 44.57,
        "y": 0.997057587
    },
    {
        "x": 44.58,
        "y": 0.997057587
    },
    {
        "x": 44.59,
        "y": 0.997057587
    },
    {
        "x": 44.6,
        "y": 0.997057587
    },
    {
        "x": 44.61,
        "y": 0.997057587
    },
    {
        "x": 44.62,
        "y": 0.996637243
    },
    {
        "x": 44.63,
        "y": 0.997057587
    },
    {
        "x": 44.64,
        "y": 0.997057587
    },
    {
        "x": 44.65,
        "y": 0.997057587
    },
    {
        "x": 44.66,
        "y": 0.997057587
    },
    {
        "x": 44.67,
        "y": 0.997057587
    },
    {
        "x": 44.68,
        "y": 0.997057587
    },
    {
        "x": 44.69,
        "y": 0.997057587
    },
    {
        "x": 44.7,
        "y": 0.997057587
    },
    {
        "x": 44.71,
        "y": 0.997057587
    },
    {
        "x": 44.72,
        "y": 0.997057587
    },
    {
        "x": 44.73,
        "y": 0.997057587
    },
    {
        "x": 44.74,
        "y": 0.997057587
    },
    {
        "x": 44.75,
        "y": 0.997057587
    },
    {
        "x": 44.76,
        "y": 0.997057587
    },
    {
        "x": 44.77,
        "y": 0.997057587
    },
    {
        "x": 44.78,
        "y": 0.997057587
    },
    {
        "x": 44.79,
        "y": 0.997057587
    },
    {
        "x": 44.8,
        "y": 0.997057587
    },
    {
        "x": 44.81,
        "y": 0.997057587
    },
    {
        "x": 44.82,
        "y": 0.997057587
    },
    {
        "x": 44.83,
        "y": 0.997057587
    },
    {
        "x": 44.84,
        "y": 0.997057587
    },
    {
        "x": 44.85,
        "y": 0.997057587
    },
    {
        "x": 44.86,
        "y": 0.997057587
    },
    {
        "x": 44.87,
        "y": 0.997057587
    },
    {
        "x": 44.88,
        "y": 0.997057587
    },
    {
        "x": 44.89,
        "y": 0.997057587
    },
    {
        "x": 44.9,
        "y": 0.997057587
    },
    {
        "x": 44.91,
        "y": 0.997057587
    },
    {
        "x": 44.92,
        "y": 0.997057587
    },
    {
        "x": 44.93,
        "y": 0.997057587
    },
    {
        "x": 44.94,
        "y": 0.997057587
    },
    {
        "x": 44.95,
        "y": 0.997477932
    },
    {
        "x": 44.96,
        "y": 0.997477932
    },
    {
        "x": 44.97,
        "y": 0.997477932
    },
    {
        "x": 44.98,
        "y": 0.997477932
    },
    {
        "x": 44.99,
        "y": 0.997057587
    },
    {
        "x": 45,
        "y": 0.997057587
    },
    {
        "x": 45.01,
        "y": 0.997477932
    },
    {
        "x": 45.02,
        "y": 0.997477932
    },
    {
        "x": 45.03,
        "y": 0.997477932
    },
    {
        "x": 45.04,
        "y": 0.997477932
    },
    {
        "x": 45.05,
        "y": 0.997477932
    },
    {
        "x": 45.06,
        "y": 0.997477932
    },
    {
        "x": 45.07,
        "y": 0.997477932
    },
    {
        "x": 45.08,
        "y": 0.997057587
    },
    {
        "x": 45.09,
        "y": 0.997477932
    },
    {
        "x": 45.1,
        "y": 0.997477932
    },
    {
        "x": 45.11,
        "y": 0.997057587
    },
    {
        "x": 45.12,
        "y": 0.997057587
    },
    {
        "x": 45.13,
        "y": 0.997477932
    },
    {
        "x": 45.14,
        "y": 0.997477932
    },
    {
        "x": 45.15,
        "y": 0.997057587
    },
    {
        "x": 45.16,
        "y": 0.997477932
    },
    {
        "x": 45.17,
        "y": 0.997477932
    },
    {
        "x": 45.18,
        "y": 0.997477932
    },
    {
        "x": 45.19,
        "y": 0.997477932
    },
    {
        "x": 45.2,
        "y": 0.997477932
    },
    {
        "x": 45.21,
        "y": 0.997477932
    },
    {
        "x": 45.22,
        "y": 0.997477932
    },
    {
        "x": 45.23,
        "y": 0.997477932
    },
    {
        "x": 45.24,
        "y": 0.997477932
    },
    {
        "x": 45.25,
        "y": 0.997477932
    },
    {
        "x": 45.26,
        "y": 0.997057587
    },
    {
        "x": 45.27,
        "y": 0.997477932
    },
    {
        "x": 45.28,
        "y": 0.997477932
    },
    {
        "x": 45.29,
        "y": 0.997477932
    },
    {
        "x": 45.3,
        "y": 0.997477932
    },
    {
        "x": 45.31,
        "y": 0.997477932
    },
    {
        "x": 45.32,
        "y": 0.997477932
    },
    {
        "x": 45.33,
        "y": 0.997477932
    },
    {
        "x": 45.34,
        "y": 0.997477932
    },
    {
        "x": 45.35,
        "y": 0.997477932
    },
    {
        "x": 45.36,
        "y": 0.997477932
    },
    {
        "x": 45.37,
        "y": 0.997477932
    },
    {
        "x": 45.38,
        "y": 0.997477932
    },
    {
        "x": 45.39,
        "y": 0.997477932
    },
    {
        "x": 45.4,
        "y": 0.997057587
    },
    {
        "x": 45.41,
        "y": 0.997477932
    },
    {
        "x": 45.42,
        "y": 0.997477932
    },
    {
        "x": 45.43,
        "y": 0.997477932
    },
    {
        "x": 45.44,
        "y": 0.997477932
    },
    {
        "x": 45.45,
        "y": 0.997477932
    },
    {
        "x": 45.46,
        "y": 0.997477932
    },
    {
        "x": 45.47,
        "y": 0.997477932
    },
    {
        "x": 45.48,
        "y": 0.997477932
    },
    {
        "x": 45.49,
        "y": 0.997477932
    },
    {
        "x": 45.5,
        "y": 0.997477932
    },
    {
        "x": 45.51,
        "y": 0.997477932
    },
    {
        "x": 45.52,
        "y": 0.997477932
    },
    {
        "x": 45.53,
        "y": 0.997477932
    },
    {
        "x": 45.54,
        "y": 0.997477932
    },
    {
        "x": 45.55,
        "y": 0.997477932
    },
    {
        "x": 45.56,
        "y": 0.997477932
    },
    {
        "x": 45.57,
        "y": 0.997477932
    },
    {
        "x": 45.58,
        "y": 0.997477932
    },
    {
        "x": 45.59,
        "y": 0.997477932
    },
    {
        "x": 45.6,
        "y": 0.997477932
    },
    {
        "x": 45.61,
        "y": 0.997477932
    },
    {
        "x": 45.62,
        "y": 0.997477932
    },
    {
        "x": 45.63,
        "y": 0.997477932
    },
    {
        "x": 45.64,
        "y": 0.997477932
    },
    {
        "x": 45.65,
        "y": 0.997477932
    },
    {
        "x": 45.66,
        "y": 0.997477932
    },
    {
        "x": 45.67,
        "y": 0.997477932
    },
    {
        "x": 45.68,
        "y": 0.997477932
    },
    {
        "x": 45.69,
        "y": 0.997477932
    },
    {
        "x": 45.7,
        "y": 0.997477932
    },
    {
        "x": 45.71,
        "y": 0.997477932
    },
    {
        "x": 45.72,
        "y": 0.997477932
    },
    {
        "x": 45.73,
        "y": 0.997477932
    },
    {
        "x": 45.74,
        "y": 0.997477932
    },
    {
        "x": 45.75,
        "y": 0.997477932
    },
    {
        "x": 45.76,
        "y": 0.997477932
    },
    {
        "x": 45.77,
        "y": 0.997477932
    },
    {
        "x": 45.78,
        "y": 0.997477932
    },
    {
        "x": 45.79,
        "y": 0.997477932
    },
    {
        "x": 45.8,
        "y": 0.997477932
    },
    {
        "x": 45.81,
        "y": 0.997477932
    },
    {
        "x": 45.82,
        "y": 0.997477932
    },
    {
        "x": 45.83,
        "y": 0.997477932
    },
    {
        "x": 45.84,
        "y": 0.997477932
    },
    {
        "x": 45.85,
        "y": 0.997477932
    },
    {
        "x": 45.86,
        "y": 0.997477932
    },
    {
        "x": 45.87,
        "y": 0.997477932
    },
    {
        "x": 45.88,
        "y": 0.997477932
    },
    {
        "x": 45.89,
        "y": 0.997477932
    },
    {
        "x": 45.9,
        "y": 0.997477932
    },
    {
        "x": 45.91,
        "y": 0.997477932
    },
    {
        "x": 45.92,
        "y": 0.997477932
    },
    {
        "x": 45.93,
        "y": 0.997477932
    },
    {
        "x": 45.94,
        "y": 0.997477932
    },
    {
        "x": 45.95,
        "y": 0.997477932
    },
    {
        "x": 45.96,
        "y": 0.997477932
    },
    {
        "x": 45.97,
        "y": 0.997477932
    },
    {
        "x": 45.98,
        "y": 0.997477932
    },
    {
        "x": 45.99,
        "y": 0.997477932
    },
    {
        "x": 46,
        "y": 0.997898277
    },
    {
        "x": 46.01,
        "y": 0.997477932
    },
    {
        "x": 46.02,
        "y": 0.997477932
    },
    {
        "x": 46.03,
        "y": 0.997477932
    },
    {
        "x": 46.04,
        "y": 0.997477932
    },
    {
        "x": 46.05,
        "y": 0.997477932
    },
    {
        "x": 46.06,
        "y": 0.997898277
    },
    {
        "x": 46.07,
        "y": 0.997477932
    },
    {
        "x": 46.08,
        "y": 0.997477932
    },
    {
        "x": 46.09,
        "y": 0.997477932
    },
    {
        "x": 46.1,
        "y": 0.997477932
    },
    {
        "x": 46.11,
        "y": 0.997477932
    },
    {
        "x": 46.12,
        "y": 0.997477932
    },
    {
        "x": 46.13,
        "y": 0.997898277
    },
    {
        "x": 46.14,
        "y": 0.997898277
    },
    {
        "x": 46.15,
        "y": 0.997477932
    },
    {
        "x": 46.16,
        "y": 0.997477932
    },
    {
        "x": 46.17,
        "y": 0.997898277
    },
    {
        "x": 46.18,
        "y": 0.997898277
    },
    {
        "x": 46.19,
        "y": 0.997898277
    },
    {
        "x": 46.2,
        "y": 0.997898277
    },
    {
        "x": 46.21,
        "y": 0.997898277
    },
    {
        "x": 46.22,
        "y": 0.997898277
    },
    {
        "x": 46.23,
        "y": 0.997898277
    },
    {
        "x": 46.24,
        "y": 0.997898277
    },
    {
        "x": 46.25,
        "y": 0.997898277
    },
    {
        "x": 46.26,
        "y": 0.997898277
    },
    {
        "x": 46.27,
        "y": 0.997898277
    },
    {
        "x": 46.28,
        "y": 0.997898277
    },
    {
        "x": 46.29,
        "y": 0.997898277
    },
    {
        "x": 46.3,
        "y": 0.997898277
    },
    {
        "x": 46.31,
        "y": 0.997898277
    },
    {
        "x": 46.32,
        "y": 0.997898277
    },
    {
        "x": 46.33,
        "y": 0.997898277
    },
    {
        "x": 46.34,
        "y": 0.997898277
    },
    {
        "x": 46.35,
        "y": 0.997898277
    },
    {
        "x": 46.36,
        "y": 0.997898277
    },
    {
        "x": 46.37,
        "y": 0.997898277
    },
    {
        "x": 46.38,
        "y": 0.997898277
    },
    {
        "x": 46.39,
        "y": 0.997898277
    },
    {
        "x": 46.4,
        "y": 0.997898277
    },
    {
        "x": 46.41,
        "y": 0.997898277
    },
    {
        "x": 46.42,
        "y": 0.997898277
    },
    {
        "x": 46.43,
        "y": 0.997898277
    },
    {
        "x": 46.44,
        "y": 0.997898277
    },
    {
        "x": 46.45,
        "y": 0.997898277
    },
    {
        "x": 46.46,
        "y": 0.997898277
    },
    {
        "x": 46.47,
        "y": 0.997898277
    },
    {
        "x": 46.48,
        "y": 0.997898277
    },
    {
        "x": 46.49,
        "y": 0.997898277
    },
    {
        "x": 46.5,
        "y": 0.997898277
    },
    {
        "x": 46.51,
        "y": 0.997898277
    },
    {
        "x": 46.52,
        "y": 0.997898277
    },
    {
        "x": 46.53,
        "y": 0.997898277
    },
    {
        "x": 46.54,
        "y": 0.997898277
    },
    {
        "x": 46.55,
        "y": 0.997898277
    },
    {
        "x": 46.56,
        "y": 0.997898277
    },
    {
        "x": 46.57,
        "y": 0.997898277
    },
    {
        "x": 46.58,
        "y": 0.997898277
    },
    {
        "x": 46.59,
        "y": 0.997898277
    },
    {
        "x": 46.6,
        "y": 0.997898277
    },
    {
        "x": 46.61,
        "y": 0.997898277
    },
    {
        "x": 46.62,
        "y": 0.997898277
    },
    {
        "x": 46.63,
        "y": 0.997898277
    },
    {
        "x": 46.64,
        "y": 0.997898277
    },
    {
        "x": 46.65,
        "y": 0.997898277
    },
    {
        "x": 46.66,
        "y": 0.997898277
    },
    {
        "x": 46.67,
        "y": 0.997898277
    },
    {
        "x": 46.68,
        "y": 0.997898277
    },
    {
        "x": 46.69,
        "y": 0.997898277
    },
    {
        "x": 46.7,
        "y": 0.997898277
    },
    {
        "x": 46.71,
        "y": 0.997898277
    },
    {
        "x": 46.72,
        "y": 0.997898277
    },
    {
        "x": 46.73,
        "y": 0.997898277
    },
    {
        "x": 46.74,
        "y": 0.997898277
    },
    {
        "x": 46.75,
        "y": 0.997898277
    },
    {
        "x": 46.76,
        "y": 0.997898277
    },
    {
        "x": 46.77,
        "y": 0.997898277
    },
    {
        "x": 46.78,
        "y": 0.997898277
    },
    {
        "x": 46.79,
        "y": 0.997898277
    },
    {
        "x": 46.8,
        "y": 0.998318621
    },
    {
        "x": 46.81,
        "y": 0.997898277
    },
    {
        "x": 46.82,
        "y": 0.997898277
    },
    {
        "x": 46.83,
        "y": 0.997898277
    },
    {
        "x": 46.84,
        "y": 0.997898277
    },
    {
        "x": 46.85,
        "y": 0.997898277
    },
    {
        "x": 46.86,
        "y": 0.998318621
    },
    {
        "x": 46.87,
        "y": 0.997898277
    },
    {
        "x": 46.88,
        "y": 0.997898277
    },
    {
        "x": 46.89,
        "y": 0.997898277
    },
    {
        "x": 46.9,
        "y": 0.998318621
    },
    {
        "x": 46.91,
        "y": 0.997898277
    },
    {
        "x": 46.92,
        "y": 0.997898277
    },
    {
        "x": 46.93,
        "y": 0.997898277
    },
    {
        "x": 46.94,
        "y": 0.998318621
    },
    {
        "x": 46.95,
        "y": 0.997898277
    },
    {
        "x": 46.96,
        "y": 0.997898277
    },
    {
        "x": 46.97,
        "y": 0.997898277
    },
    {
        "x": 46.98,
        "y": 0.997898277
    },
    {
        "x": 46.99,
        "y": 0.997898277
    },
    {
        "x": 47,
        "y": 0.997898277
    },
    {
        "x": 47.01,
        "y": 0.998318621
    },
    {
        "x": 47.02,
        "y": 0.997898277
    },
    {
        "x": 47.03,
        "y": 0.997898277
    },
    {
        "x": 47.04,
        "y": 0.998318621
    },
    {
        "x": 47.05,
        "y": 0.998318621
    },
    {
        "x": 47.06,
        "y": 0.998318621
    },
    {
        "x": 47.07,
        "y": 0.997898277
    },
    {
        "x": 47.08,
        "y": 0.997898277
    },
    {
        "x": 47.09,
        "y": 0.997898277
    },
    {
        "x": 47.1,
        "y": 0.998318621
    },
    {
        "x": 47.11,
        "y": 0.998318621
    },
    {
        "x": 47.12,
        "y": 0.998318621
    },
    {
        "x": 47.13,
        "y": 0.998318621
    },
    {
        "x": 47.14,
        "y": 0.998318621
    },
    {
        "x": 47.15,
        "y": 0.997898277
    },
    {
        "x": 47.16,
        "y": 0.997898277
    },
    {
        "x": 47.17,
        "y": 0.998318621
    },
    {
        "x": 47.18,
        "y": 0.998318621
    },
    {
        "x": 47.19,
        "y": 0.998318621
    },
    {
        "x": 47.2,
        "y": 0.998318621
    },
    {
        "x": 47.21,
        "y": 0.998318621
    },
    {
        "x": 47.22,
        "y": 0.998318621
    },
    {
        "x": 47.23,
        "y": 0.998318621
    },
    {
        "x": 47.24,
        "y": 0.998318621
    },
    {
        "x": 47.25,
        "y": 0.998318621
    },
    {
        "x": 47.26,
        "y": 0.998318621
    },
    {
        "x": 47.27,
        "y": 0.998318621
    },
    {
        "x": 47.28,
        "y": 0.998318621
    },
    {
        "x": 47.29,
        "y": 0.998318621
    },
    {
        "x": 47.3,
        "y": 0.998318621
    },
    {
        "x": 47.31,
        "y": 0.998318621
    },
    {
        "x": 47.32,
        "y": 0.998318621
    },
    {
        "x": 47.33,
        "y": 0.998318621
    },
    {
        "x": 47.34,
        "y": 0.998318621
    },
    {
        "x": 47.35,
        "y": 0.998318621
    },
    {
        "x": 47.36,
        "y": 0.998318621
    },
    {
        "x": 47.37,
        "y": 0.998318621
    },
    {
        "x": 47.38,
        "y": 0.998318621
    },
    {
        "x": 47.39,
        "y": 0.998318621
    },
    {
        "x": 47.4,
        "y": 0.998318621
    },
    {
        "x": 47.41,
        "y": 0.998318621
    },
    {
        "x": 47.42,
        "y": 0.998318621
    },
    {
        "x": 47.43,
        "y": 0.998318621
    },
    {
        "x": 47.44,
        "y": 0.998318621
    },
    {
        "x": 47.45,
        "y": 0.998318621
    },
    {
        "x": 47.46,
        "y": 0.998318621
    },
    {
        "x": 47.47,
        "y": 0.998318621
    },
    {
        "x": 47.48,
        "y": 0.998318621
    },
    {
        "x": 47.49,
        "y": 0.998318621
    },
    {
        "x": 47.5,
        "y": 0.998318621
    },
    {
        "x": 47.51,
        "y": 0.998318621
    },
    {
        "x": 47.52,
        "y": 0.998318621
    },
    {
        "x": 47.53,
        "y": 0.998318621
    },
    {
        "x": 47.54,
        "y": 0.998318621
    },
    {
        "x": 47.55,
        "y": 0.998318621
    },
    {
        "x": 47.56,
        "y": 0.998318621
    },
    {
        "x": 47.57,
        "y": 0.998318621
    },
    {
        "x": 47.58,
        "y": 0.998318621
    },
    {
        "x": 47.59,
        "y": 0.998318621
    },
    {
        "x": 47.6,
        "y": 0.998318621
    },
    {
        "x": 47.61,
        "y": 0.998318621
    },
    {
        "x": 47.62,
        "y": 0.998318621
    },
    {
        "x": 47.63,
        "y": 0.998318621
    },
    {
        "x": 47.64,
        "y": 0.998318621
    },
    {
        "x": 47.65,
        "y": 0.998318621
    },
    {
        "x": 47.66,
        "y": 0.998318621
    },
    {
        "x": 47.67,
        "y": 0.998318621
    },
    {
        "x": 47.68,
        "y": 0.998318621
    },
    {
        "x": 47.69,
        "y": 0.998318621
    },
    {
        "x": 47.7,
        "y": 0.998318621
    },
    {
        "x": 47.71,
        "y": 0.998318621
    },
    {
        "x": 47.72,
        "y": 0.998318621
    },
    {
        "x": 47.73,
        "y": 0.998318621
    },
    {
        "x": 47.74,
        "y": 0.998318621
    },
    {
        "x": 47.75,
        "y": 0.998318621
    },
    {
        "x": 47.76,
        "y": 0.998318621
    },
    {
        "x": 47.77,
        "y": 0.998318621
    },
    {
        "x": 47.78,
        "y": 0.998318621
    },
    {
        "x": 47.79,
        "y": 0.998738966
    },
    {
        "x": 47.8,
        "y": 0.998318621
    },
    {
        "x": 47.81,
        "y": 0.998318621
    },
    {
        "x": 47.82,
        "y": 0.998738966
    },
    {
        "x": 47.83,
        "y": 0.998318621
    },
    {
        "x": 47.84,
        "y": 0.998738966
    },
    {
        "x": 47.85,
        "y": 0.998738966
    },
    {
        "x": 47.86,
        "y": 0.998318621
    },
    {
        "x": 47.87,
        "y": 0.998318621
    },
    {
        "x": 47.88,
        "y": 0.998318621
    },
    {
        "x": 47.89,
        "y": 0.998318621
    },
    {
        "x": 47.9,
        "y": 0.998318621
    },
    {
        "x": 47.91,
        "y": 0.998318621
    },
    {
        "x": 47.92,
        "y": 0.998318621
    },
    {
        "x": 47.93,
        "y": 0.998318621
    },
    {
        "x": 47.94,
        "y": 0.998318621
    },
    {
        "x": 47.95,
        "y": 0.998318621
    },
    {
        "x": 47.96,
        "y": 0.998318621
    },
    {
        "x": 47.97,
        "y": 0.998318621
    },
    {
        "x": 47.98,
        "y": 0.998318621
    },
    {
        "x": 47.99,
        "y": 0.998738966
    },
    {
        "x": 48,
        "y": 0.998738966
    },
    {
        "x": 48.01,
        "y": 0.998318621
    },
    {
        "x": 48.02,
        "y": 0.998738966
    },
    {
        "x": 48.03,
        "y": 0.998318621
    },
    {
        "x": 48.04,
        "y": 0.998318621
    },
    {
        "x": 48.05,
        "y": 0.998318621
    },
    {
        "x": 48.06,
        "y": 0.998738966
    },
    {
        "x": 48.07,
        "y": 0.998318621
    },
    {
        "x": 48.08,
        "y": 0.998318621
    },
    {
        "x": 48.09,
        "y": 0.998318621
    },
    {
        "x": 48.1,
        "y": 0.998318621
    },
    {
        "x": 48.11,
        "y": 0.998318621
    },
    {
        "x": 48.12,
        "y": 0.998318621
    },
    {
        "x": 48.13,
        "y": 0.998738966
    },
    {
        "x": 48.14,
        "y": 0.998318621
    },
    {
        "x": 48.15,
        "y": 0.998318621
    },
    {
        "x": 48.16,
        "y": 0.998738966
    },
    {
        "x": 48.17,
        "y": 0.998738966
    },
    {
        "x": 48.18,
        "y": 0.998318621
    },
    {
        "x": 48.19,
        "y": 0.998318621
    },
    {
        "x": 48.2,
        "y": 0.998738966
    },
    {
        "x": 48.21,
        "y": 0.998318621
    },
    {
        "x": 48.22,
        "y": 0.998318621
    },
    {
        "x": 48.23,
        "y": 0.998318621
    },
    {
        "x": 48.24,
        "y": 0.998738966
    },
    {
        "x": 48.25,
        "y": 0.998738966
    },
    {
        "x": 48.26,
        "y": 0.998318621
    },
    {
        "x": 48.27,
        "y": 0.998738966
    },
    {
        "x": 48.28,
        "y": 0.998318621
    },
    {
        "x": 48.29,
        "y": 0.998738966
    },
    {
        "x": 48.3,
        "y": 0.998738966
    },
    {
        "x": 48.31,
        "y": 0.998738966
    },
    {
        "x": 48.32,
        "y": 0.998738966
    },
    {
        "x": 48.33,
        "y": 0.998738966
    },
    {
        "x": 48.34,
        "y": 0.998738966
    },
    {
        "x": 48.35,
        "y": 0.998738966
    },
    {
        "x": 48.36,
        "y": 0.998738966
    },
    {
        "x": 48.37,
        "y": 0.998738966
    },
    {
        "x": 48.38,
        "y": 0.998738966
    },
    {
        "x": 48.39,
        "y": 0.998738966
    },
    {
        "x": 48.4,
        "y": 0.998738966
    },
    {
        "x": 48.41,
        "y": 0.998738966
    },
    {
        "x": 48.42,
        "y": 0.998738966
    },
    {
        "x": 48.43,
        "y": 0.998738966
    },
    {
        "x": 48.44,
        "y": 0.998738966
    },
    {
        "x": 48.45,
        "y": 0.998738966
    },
    {
        "x": 48.46,
        "y": 0.998738966
    },
    {
        "x": 48.47,
        "y": 0.998738966
    },
    {
        "x": 48.48,
        "y": 0.998738966
    },
    {
        "x": 48.49,
        "y": 0.998738966
    },
    {
        "x": 48.5,
        "y": 0.998738966
    },
    {
        "x": 48.51,
        "y": 0.998738966
    },
    {
        "x": 48.52,
        "y": 0.998738966
    },
    {
        "x": 48.53,
        "y": 0.998738966
    },
    {
        "x": 48.54,
        "y": 0.998738966
    },
    {
        "x": 48.55,
        "y": 0.998738966
    },
    {
        "x": 48.56,
        "y": 0.998738966
    },
    {
        "x": 48.57,
        "y": 0.998738966
    },
    {
        "x": 48.58,
        "y": 0.998738966
    },
    {
        "x": 48.59,
        "y": 0.998738966
    },
    {
        "x": 48.6,
        "y": 0.998738966
    },
    {
        "x": 48.61,
        "y": 0.998738966
    },
    {
        "x": 48.62,
        "y": 0.998738966
    },
    {
        "x": 48.63,
        "y": 0.998738966
    },
    {
        "x": 48.64,
        "y": 0.998738966
    },
    {
        "x": 48.65,
        "y": 0.998738966
    },
    {
        "x": 48.66,
        "y": 0.998738966
    },
    {
        "x": 48.67,
        "y": 0.998738966
    },
    {
        "x": 48.68,
        "y": 0.998738966
    },
    {
        "x": 48.69,
        "y": 0.998738966
    },
    {
        "x": 48.7,
        "y": 0.998738966
    },
    {
        "x": 48.71,
        "y": 0.998738966
    },
    {
        "x": 48.72,
        "y": 0.998738966
    },
    {
        "x": 48.73,
        "y": 0.998738966
    },
    {
        "x": 48.74,
        "y": 0.998738966
    },
    {
        "x": 48.75,
        "y": 0.998738966
    },
    {
        "x": 48.76,
        "y": 0.998738966
    },
    {
        "x": 48.77,
        "y": 0.998738966
    },
    {
        "x": 48.78,
        "y": 0.998738966
    },
    {
        "x": 48.79,
        "y": 0.998738966
    },
    {
        "x": 48.8,
        "y": 0.998738966
    },
    {
        "x": 48.81,
        "y": 0.998738966
    },
    {
        "x": 48.82,
        "y": 0.998738966
    },
    {
        "x": 48.83,
        "y": 0.998738966
    },
    {
        "x": 48.84,
        "y": 0.998738966
    },
    {
        "x": 48.85,
        "y": 0.998738966
    },
    {
        "x": 48.86,
        "y": 0.998738966
    },
    {
        "x": 48.87,
        "y": 0.998738966
    },
    {
        "x": 48.88,
        "y": 0.998738966
    },
    {
        "x": 48.89,
        "y": 0.998738966
    },
    {
        "x": 48.9,
        "y": 0.998738966
    },
    {
        "x": 48.91,
        "y": 0.998738966
    },
    {
        "x": 48.92,
        "y": 0.998738966
    },
    {
        "x": 48.93,
        "y": 0.998738966
    },
    {
        "x": 48.94,
        "y": 0.998738966
    },
    {
        "x": 48.95,
        "y": 0.998738966
    },
    {
        "x": 48.96,
        "y": 0.998738966
    },
    {
        "x": 48.97,
        "y": 0.998738966
    },
    {
        "x": 48.98,
        "y": 0.998738966
    },
    {
        "x": 48.99,
        "y": 0.998738966
    },
    {
        "x": 49,
        "y": 0.998738966
    },
    {
        "x": 49.01,
        "y": 0.998738966
    },
    {
        "x": 49.02,
        "y": 0.998738966
    },
    {
        "x": 49.03,
        "y": 0.998738966
    },
    {
        "x": 49.04,
        "y": 0.998738966
    },
    {
        "x": 49.05,
        "y": 0.998738966
    },
    {
        "x": 49.06,
        "y": 0.998738966
    },
    {
        "x": 49.07,
        "y": 0.998738966
    },
    {
        "x": 49.08,
        "y": 0.998738966
    },
    {
        "x": 49.09,
        "y": 0.998738966
    },
    {
        "x": 49.1,
        "y": 0.998738966
    },
    {
        "x": 49.11,
        "y": 0.998738966
    },
    {
        "x": 49.12,
        "y": 0.998738966
    },
    {
        "x": 49.13,
        "y": 0.998738966
    },
    {
        "x": 49.14,
        "y": 0.998738966
    },
    {
        "x": 49.15,
        "y": 0.998738966
    },
    {
        "x": 49.16,
        "y": 0.998738966
    },
    {
        "x": 49.17,
        "y": 0.998738966
    },
    {
        "x": 49.18,
        "y": 0.998738966
    },
    {
        "x": 49.19,
        "y": 0.998738966
    },
    {
        "x": 49.2,
        "y": 0.998738966
    },
    {
        "x": 49.21,
        "y": 0.998738966
    },
    {
        "x": 49.22,
        "y": 0.998738966
    },
    {
        "x": 49.23,
        "y": 0.998738966
    },
    {
        "x": 49.24,
        "y": 0.999159311
    },
    {
        "x": 49.25,
        "y": 0.998738966
    },
    {
        "x": 49.26,
        "y": 0.998738966
    },
    {
        "x": 49.27,
        "y": 0.998738966
    },
    {
        "x": 49.28,
        "y": 0.998738966
    },
    {
        "x": 49.29,
        "y": 0.998738966
    },
    {
        "x": 49.3,
        "y": 0.998738966
    },
    {
        "x": 49.31,
        "y": 0.998738966
    },
    {
        "x": 49.32,
        "y": 0.998738966
    },
    {
        "x": 49.33,
        "y": 0.998738966
    },
    {
        "x": 49.34,
        "y": 0.998738966
    },
    {
        "x": 49.35,
        "y": 0.998738966
    },
    {
        "x": 49.36,
        "y": 0.998738966
    },
    {
        "x": 49.37,
        "y": 0.998738966
    },
    {
        "x": 49.38,
        "y": 0.998738966
    },
    {
        "x": 49.39,
        "y": 0.998738966
    },
    {
        "x": 49.4,
        "y": 0.998738966
    },
    {
        "x": 49.41,
        "y": 0.998738966
    },
    {
        "x": 49.42,
        "y": 0.999159311
    },
    {
        "x": 49.43,
        "y": 0.999159311
    },
    {
        "x": 49.44,
        "y": 0.999159311
    },
    {
        "x": 49.45,
        "y": 0.999159311
    },
    {
        "x": 49.46,
        "y": 0.999159311
    },
    {
        "x": 49.47,
        "y": 0.998738966
    },
    {
        "x": 49.48,
        "y": 0.998738966
    },
    {
        "x": 49.49,
        "y": 0.998738966
    },
    {
        "x": 49.5,
        "y": 0.998738966
    },
    {
        "x": 49.51,
        "y": 0.999159311
    },
    {
        "x": 49.52,
        "y": 0.999159311
    },
    {
        "x": 49.53,
        "y": 0.999159311
    },
    {
        "x": 49.54,
        "y": 0.999159311
    },
    {
        "x": 49.55,
        "y": 0.999159311
    },
    {
        "x": 49.56,
        "y": 0.998738966
    },
    {
        "x": 49.57,
        "y": 0.998738966
    },
    {
        "x": 49.58,
        "y": 0.999159311
    },
    {
        "x": 49.59,
        "y": 0.999159311
    },
    {
        "x": 49.6,
        "y": 0.999159311
    },
    {
        "x": 49.61,
        "y": 0.999159311
    },
    {
        "x": 49.62,
        "y": 0.999159311
    },
    {
        "x": 49.63,
        "y": 0.999159311
    },
    {
        "x": 49.64,
        "y": 0.998738966
    },
    {
        "x": 49.65,
        "y": 0.999159311
    },
    {
        "x": 49.66,
        "y": 0.999159311
    },
    {
        "x": 49.67,
        "y": 0.999159311
    },
    {
        "x": 49.68,
        "y": 0.999159311
    },
    {
        "x": 49.69,
        "y": 0.999159311
    },
    {
        "x": 49.7,
        "y": 0.999159311
    },
    {
        "x": 49.71,
        "y": 0.999159311
    },
    {
        "x": 49.72,
        "y": 0.999159311
    },
    {
        "x": 49.73,
        "y": 0.999159311
    },
    {
        "x": 49.74,
        "y": 0.999159311
    },
    {
        "x": 49.75,
        "y": 0.999159311
    },
    {
        "x": 49.76,
        "y": 0.999159311
    },
    {
        "x": 49.77,
        "y": 0.999159311
    },
    {
        "x": 49.78,
        "y": 0.999159311
    },
    {
        "x": 49.79,
        "y": 0.999159311
    },
    {
        "x": 49.8,
        "y": 0.999159311
    },
    {
        "x": 49.81,
        "y": 0.999159311
    },
    {
        "x": 49.82,
        "y": 0.999159311
    },
    {
        "x": 49.83,
        "y": 0.999159311
    },
    {
        "x": 49.84,
        "y": 0.999159311
    },
    {
        "x": 49.85,
        "y": 0.999159311
    },
    {
        "x": 49.86,
        "y": 0.999159311
    },
    {
        "x": 49.87,
        "y": 0.999159311
    },
    {
        "x": 49.88,
        "y": 0.999159311
    },
    {
        "x": 49.89,
        "y": 0.999159311
    },
    {
        "x": 49.9,
        "y": 0.999159311
    },
    {
        "x": 49.91,
        "y": 0.999159311
    },
    {
        "x": 49.92,
        "y": 0.999159311
    },
    {
        "x": 49.93,
        "y": 0.999159311
    },
    {
        "x": 49.94,
        "y": 0.999159311
    },
    {
        "x": 49.95,
        "y": 0.999159311
    },
    {
        "x": 49.96,
        "y": 0.999159311
    },
    {
        "x": 49.97,
        "y": 0.999159311
    },
    {
        "x": 49.98,
        "y": 0.999159311
    },
    {
        "x": 49.99,
        "y": 0.999159311
    },
    {
        "x": 50,
        "y": 0.999159311
    },
    {
        "x": 50.01,
        "y": 0.999159311
    },
    {
        "x": 50.02,
        "y": 0.999159311
    },
    {
        "x": 50.03,
        "y": 0.999159311
    },
    {
        "x": 50.04,
        "y": 0.999159311
    },
    {
        "x": 50.05,
        "y": 0.999159311
    },
    {
        "x": 50.06,
        "y": 0.999159311
    },
    {
        "x": 50.07,
        "y": 0.999159311
    },
    {
        "x": 50.08,
        "y": 0.999159311
    },
    {
        "x": 50.09,
        "y": 0.999159311
    },
    {
        "x": 50.1,
        "y": 0.999159311
    },
    {
        "x": 50.11,
        "y": 0.999159311
    },
    {
        "x": 50.12,
        "y": 0.999159311
    },
    {
        "x": 50.13,
        "y": 0.999159311
    },
    {
        "x": 50.14,
        "y": 0.999159311
    },
    {
        "x": 50.15,
        "y": 0.999159311
    },
    {
        "x": 50.16,
        "y": 0.999159311
    },
    {
        "x": 50.17,
        "y": 0.999159311
    },
    {
        "x": 50.18,
        "y": 0.999159311
    },
    {
        "x": 50.19,
        "y": 0.999159311
    },
    {
        "x": 50.2,
        "y": 0.999159311
    },
    {
        "x": 50.21,
        "y": 0.999159311
    },
    {
        "x": 50.22,
        "y": 0.999159311
    },
    {
        "x": 50.23,
        "y": 0.999159311
    },
    {
        "x": 50.24,
        "y": 0.999159311
    },
    {
        "x": 50.25,
        "y": 0.999159311
    },
    {
        "x": 50.26,
        "y": 0.999159311
    },
    {
        "x": 50.27,
        "y": 0.999159311
    },
    {
        "x": 50.28,
        "y": 0.999159311
    },
    {
        "x": 50.29,
        "y": 0.999159311
    },
    {
        "x": 50.3,
        "y": 0.999159311
    },
    {
        "x": 50.31,
        "y": 0.999159311
    },
    {
        "x": 50.32,
        "y": 0.999159311
    },
    {
        "x": 50.33,
        "y": 0.999159311
    },
    {
        "x": 50.34,
        "y": 0.999159311
    },
    {
        "x": 50.35,
        "y": 0.999159311
    },
    {
        "x": 50.36,
        "y": 0.999159311
    },
    {
        "x": 50.37,
        "y": 0.999159311
    },
    {
        "x": 50.38,
        "y": 0.999159311
    },
    {
        "x": 50.39,
        "y": 0.999159311
    },
    {
        "x": 50.4,
        "y": 0.999159311
    },
    {
        "x": 50.41,
        "y": 0.999159311
    },
    {
        "x": 50.42,
        "y": 0.999159311
    },
    {
        "x": 50.43,
        "y": 0.999159311
    },
    {
        "x": 50.44,
        "y": 0.999159311
    },
    {
        "x": 50.45,
        "y": 0.999159311
    },
    {
        "x": 50.46,
        "y": 0.999159311
    },
    {
        "x": 50.47,
        "y": 0.999159311
    },
    {
        "x": 50.48,
        "y": 0.999159311
    },
    {
        "x": 50.49,
        "y": 0.999159311
    },
    {
        "x": 50.5,
        "y": 0.999159311
    },
    {
        "x": 50.51,
        "y": 0.999159311
    },
    {
        "x": 50.52,
        "y": 0.999159311
    },
    {
        "x": 50.53,
        "y": 0.999159311
    },
    {
        "x": 50.54,
        "y": 0.999159311
    },
    {
        "x": 50.55,
        "y": 0.999159311
    },
    {
        "x": 50.56,
        "y": 0.999159311
    },
    {
        "x": 50.57,
        "y": 0.999159311
    },
    {
        "x": 50.58,
        "y": 0.999159311
    },
    {
        "x": 50.59,
        "y": 0.999159311
    },
    {
        "x": 50.6,
        "y": 0.999159311
    },
    {
        "x": 50.61,
        "y": 0.999159311
    },
    {
        "x": 50.62,
        "y": 0.999159311
    },
    {
        "x": 50.63,
        "y": 0.999159311
    },
    {
        "x": 50.64,
        "y": 0.999159311
    },
    {
        "x": 50.65,
        "y": 0.999159311
    },
    {
        "x": 50.66,
        "y": 0.999159311
    },
    {
        "x": 50.67,
        "y": 0.999159311
    },
    {
        "x": 50.68,
        "y": 0.999159311
    },
    {
        "x": 50.69,
        "y": 0.999159311
    },
    {
        "x": 50.7,
        "y": 0.999159311
    },
    {
        "x": 50.71,
        "y": 0.999159311
    },
    {
        "x": 50.72,
        "y": 0.999159311
    },
    {
        "x": 50.73,
        "y": 0.999579655
    },
    {
        "x": 50.74,
        "y": 0.999159311
    },
    {
        "x": 50.75,
        "y": 0.999159311
    },
    {
        "x": 50.76,
        "y": 0.999159311
    },
    {
        "x": 50.77,
        "y": 0.999159311
    },
    {
        "x": 50.78,
        "y": 0.999159311
    },
    {
        "x": 50.79,
        "y": 0.999579655
    },
    {
        "x": 50.8,
        "y": 0.999159311
    },
    {
        "x": 50.81,
        "y": 0.999579655
    },
    {
        "x": 50.82,
        "y": 0.999159311
    },
    {
        "x": 50.83,
        "y": 0.999159311
    },
    {
        "x": 50.84,
        "y": 0.999159311
    },
    {
        "x": 50.85,
        "y": 0.999579655
    },
    {
        "x": 50.86,
        "y": 0.999579655
    },
    {
        "x": 50.87,
        "y": 0.999579655
    },
    {
        "x": 50.88,
        "y": 0.999159311
    },
    {
        "x": 50.89,
        "y": 0.999579655
    },
    {
        "x": 50.9,
        "y": 0.999579655
    },
    {
        "x": 50.91,
        "y": 0.999579655
    },
    {
        "x": 50.92,
        "y": 0.999579655
    },
    {
        "x": 50.93,
        "y": 0.999579655
    },
    {
        "x": 50.94,
        "y": 0.999579655
    },
    {
        "x": 50.95,
        "y": 0.999579655
    },
    {
        "x": 50.96,
        "y": 0.999579655
    },
    {
        "x": 50.97,
        "y": 0.999579655
    },
    {
        "x": 50.98,
        "y": 0.999579655
    },
    {
        "x": 50.99,
        "y": 0.999579655
    },
    {
        "x": 51,
        "y": 0.999159311
    },
    {
        "x": 51.01,
        "y": 0.999159311
    },
    {
        "x": 51.02,
        "y": 0.999579655
    },
    {
        "x": 51.03,
        "y": 0.999159311
    },
    {
        "x": 51.04,
        "y": 0.999579655
    },
    {
        "x": 51.05,
        "y": 0.999579655
    },
    {
        "x": 51.06,
        "y": 0.999579655
    },
    {
        "x": 51.07,
        "y": 0.999159311
    },
    {
        "x": 51.08,
        "y": 0.999579655
    },
    {
        "x": 51.09,
        "y": 0.999579655
    },
    {
        "x": 51.1,
        "y": 0.999159311
    },
    {
        "x": 51.11,
        "y": 0.999159311
    },
    {
        "x": 51.12,
        "y": 0.999159311
    },
    {
        "x": 51.13,
        "y": 0.999159311
    },
    {
        "x": 51.14,
        "y": 0.999159311
    },
    {
        "x": 51.15,
        "y": 0.999159311
    },
    {
        "x": 51.16,
        "y": 0.999579655
    },
    {
        "x": 51.17,
        "y": 0.999579655
    },
    {
        "x": 51.18,
        "y": 0.999579655
    },
    {
        "x": 51.19,
        "y": 0.999159311
    },
    {
        "x": 51.2,
        "y": 0.999579655
    },
    {
        "x": 51.21,
        "y": 0.999159311
    },
    {
        "x": 51.22,
        "y": 0.999159311
    },
    {
        "x": 51.23,
        "y": 0.999579655
    },
    {
        "x": 51.24,
        "y": 0.999579655
    },
    {
        "x": 51.25,
        "y": 0.999159311
    },
    {
        "x": 51.26,
        "y": 0.999579655
    },
    {
        "x": 51.27,
        "y": 0.999579655
    },
    {
        "x": 51.28,
        "y": 0.999159311
    },
    {
        "x": 51.29,
        "y": 0.999159311
    },
    {
        "x": 51.3,
        "y": 0.999159311
    },
    {
        "x": 51.31,
        "y": 0.999159311
    },
    {
        "x": 51.32,
        "y": 0.999159311
    },
    {
        "x": 51.33,
        "y": 0.999159311
    },
    {
        "x": 51.34,
        "y": 0.999159311
    },
    {
        "x": 51.35,
        "y": 0.999159311
    },
    {
        "x": 51.36,
        "y": 0.999159311
    },
    {
        "x": 51.37,
        "y": 0.999159311
    },
    {
        "x": 51.38,
        "y": 0.999159311
    },
    {
        "x": 51.39,
        "y": 0.999159311
    },
    {
        "x": 51.4,
        "y": 0.999579655
    },
    {
        "x": 51.41,
        "y": 0.999579655
    },
    {
        "x": 51.42,
        "y": 0.999159311
    },
    {
        "x": 51.43,
        "y": 0.999159311
    },
    {
        "x": 51.44,
        "y": 0.999579655
    },
    {
        "x": 51.45,
        "y": 0.999159311
    },
    {
        "x": 51.46,
        "y": 0.999159311
    },
    {
        "x": 51.47,
        "y": 0.999159311
    },
    {
        "x": 51.48,
        "y": 0.999159311
    },
    {
        "x": 51.49,
        "y": 0.999159311
    },
    {
        "x": 51.5,
        "y": 0.999159311
    },
    {
        "x": 51.51,
        "y": 0.999159311
    },
    {
        "x": 51.52,
        "y": 0.999579655
    },
    {
        "x": 51.53,
        "y": 0.999579655
    },
    {
        "x": 51.54,
        "y": 0.999579655
    },
    {
        "x": 51.55,
        "y": 0.999159311
    },
    {
        "x": 51.56,
        "y": 0.999159311
    },
    {
        "x": 51.57,
        "y": 0.999159311
    },
    {
        "x": 51.58,
        "y": 0.999159311
    },
    {
        "x": 51.59,
        "y": 0.999159311
    },
    {
        "x": 51.6,
        "y": 0.999159311
    },
    {
        "x": 51.61,
        "y": 0.999579655
    },
    {
        "x": 51.62,
        "y": 0.999579655
    },
    {
        "x": 51.63,
        "y": 0.999159311
    },
    {
        "x": 51.64,
        "y": 0.999159311
    },
    {
        "x": 51.65,
        "y": 0.999159311
    },
    {
        "x": 51.66,
        "y": 0.999579655
    },
    {
        "x": 51.67,
        "y": 0.999159311
    },
    {
        "x": 51.68,
        "y": 0.999579655
    },
    {
        "x": 51.69,
        "y": 0.999579655
    },
    {
        "x": 51.7,
        "y": 0.999579655
    },
    {
        "x": 51.71,
        "y": 0.999579655
    },
    {
        "x": 51.72,
        "y": 0.999579655
    },
    {
        "x": 51.73,
        "y": 0.999159311
    },
    {
        "x": 51.74,
        "y": 0.999579655
    },
    {
        "x": 51.75,
        "y": 0.999579655
    },
    {
        "x": 51.76,
        "y": 0.999579655
    },
    {
        "x": 51.77,
        "y": 0.999579655
    },
    {
        "x": 51.78,
        "y": 0.999579655
    },
    {
        "x": 51.79,
        "y": 0.999579655
    },
    {
        "x": 51.8,
        "y": 0.999579655
    },
    {
        "x": 51.81,
        "y": 0.999579655
    },
    {
        "x": 51.82,
        "y": 0.999579655
    },
    {
        "x": 51.83,
        "y": 0.999579655
    },
    {
        "x": 51.84,
        "y": 0.999579655
    },
    {
        "x": 51.85,
        "y": 0.999579655
    },
    {
        "x": 51.86,
        "y": 0.999579655
    },
    {
        "x": 51.87,
        "y": 0.999159311
    },
    {
        "x": 51.88,
        "y": 0.999159311
    },
    {
        "x": 51.89,
        "y": 0.999579655
    },
    {
        "x": 51.9,
        "y": 0.999579655
    },
    {
        "x": 51.91,
        "y": 0.999579655
    },
    {
        "x": 51.92,
        "y": 0.999579655
    },
    {
        "x": 51.93,
        "y": 0.999579655
    },
    {
        "x": 51.94,
        "y": 0.999579655
    },
    {
        "x": 51.95,
        "y": 0.999579655
    },
    {
        "x": 51.96,
        "y": 0.999159311
    },
    {
        "x": 51.97,
        "y": 0.999579655
    },
    {
        "x": 51.98,
        "y": 0.999579655
    },
    {
        "x": 51.99,
        "y": 0.999579655
    },
    {
        "x": 52,
        "y": 0.999579655
    },
    {
        "x": 52.01,
        "y": 0.999579655
    },
    {
        "x": 52.02,
        "y": 0.999579655
    },
    {
        "x": 52.03,
        "y": 0.999579655
    },
    {
        "x": 52.04,
        "y": 0.999579655
    },
    {
        "x": 52.05,
        "y": 0.999579655
    },
    {
        "x": 52.06,
        "y": 0.999579655
    },
    {
        "x": 52.07,
        "y": 0.999579655
    },
    {
        "x": 52.08,
        "y": 0.999579655
    },
    {
        "x": 52.09,
        "y": 0.999579655
    },
    {
        "x": 52.1,
        "y": 0.999579655
    },
    {
        "x": 52.11,
        "y": 0.999579655
    },
    {
        "x": 52.12,
        "y": 0.999579655
    },
    {
        "x": 52.13,
        "y": 0.999579655
    },
    {
        "x": 52.14,
        "y": 0.999579655
    },
    {
        "x": 52.15,
        "y": 0.999579655
    },
    {
        "x": 52.16,
        "y": 0.999579655
    },
    {
        "x": 52.17,
        "y": 0.999579655
    },
    {
        "x": 52.18,
        "y": 0.999579655
    },
    {
        "x": 52.19,
        "y": 0.999579655
    },
    {
        "x": 52.2,
        "y": 0.999579655
    },
    {
        "x": 52.21,
        "y": 0.999579655
    },
    {
        "x": 52.22,
        "y": 0.999579655
    },
    {
        "x": 52.23,
        "y": 0.999579655
    },
    {
        "x": 52.24,
        "y": 0.999579655
    },
    {
        "x": 52.25,
        "y": 0.999579655
    },
    {
        "x": 52.26,
        "y": 0.999579655
    },
    {
        "x": 52.27,
        "y": 0.999579655
    },
    {
        "x": 52.28,
        "y": 0.999579655
    },
    {
        "x": 52.29,
        "y": 0.999579655
    },
    {
        "x": 52.3,
        "y": 0.999579655
    },
    {
        "x": 52.31,
        "y": 0.999579655
    },
    {
        "x": 52.32,
        "y": 0.999579655
    },
    {
        "x": 52.33,
        "y": 0.999579655
    },
    {
        "x": 52.34,
        "y": 0.999579655
    },
    {
        "x": 52.35,
        "y": 0.999579655
    },
    {
        "x": 52.36,
        "y": 0.999579655
    },
    {
        "x": 52.37,
        "y": 0.999579655
    },
    {
        "x": 52.38,
        "y": 0.999579655
    },
    {
        "x": 52.39,
        "y": 0.999579655
    },
    {
        "x": 52.4,
        "y": 0.999579655
    },
    {
        "x": 52.41,
        "y": 0.999579655
    },
    {
        "x": 52.42,
        "y": 0.999579655
    },
    {
        "x": 52.43,
        "y": 0.999579655
    },
    {
        "x": 52.44,
        "y": 0.999579655
    },
    {
        "x": 52.45,
        "y": 0.999579655
    },
    {
        "x": 52.46,
        "y": 0.999579655
    },
    {
        "x": 52.47,
        "y": 0.999579655
    },
    {
        "x": 52.48,
        "y": 0.999579655
    },
    {
        "x": 52.49,
        "y": 0.999579655
    },
    {
        "x": 52.5,
        "y": 0.999579655
    },
    {
        "x": 52.51,
        "y": 0.999579655
    },
    {
        "x": 52.52,
        "y": 0.999579655
    },
    {
        "x": 52.53,
        "y": 0.999579655
    },
    {
        "x": 52.54,
        "y": 0.999579655
    },
    {
        "x": 52.55,
        "y": 0.999579655
    },
    {
        "x": 52.56,
        "y": 0.999579655
    },
    {
        "x": 52.57,
        "y": 0.999579655
    },
    {
        "x": 52.58,
        "y": 0.999579655
    },
    {
        "x": 52.59,
        "y": 0.999579655
    },
    {
        "x": 52.6,
        "y": 0.999579655
    },
    {
        "x": 52.61,
        "y": 0.999579655
    },
    {
        "x": 52.62,
        "y": 0.999579655
    },
    {
        "x": 52.63,
        "y": 0.999579655
    },
    {
        "x": 52.64,
        "y": 0.999579655
    },
    {
        "x": 52.65,
        "y": 0.999579655
    },
    {
        "x": 52.66,
        "y": 0.999579655
    },
    {
        "x": 52.67,
        "y": 0.999579655
    },
    {
        "x": 52.68,
        "y": 0.999579655
    },
    {
        "x": 52.69,
        "y": 0.999579655
    },
    {
        "x": 52.7,
        "y": 0.999579655
    },
    {
        "x": 52.71,
        "y": 0.999579655
    },
    {
        "x": 52.72,
        "y": 0.999579655
    },
    {
        "x": 52.73,
        "y": 0.999579655
    },
    {
        "x": 52.74,
        "y": 0.999579655
    },
    {
        "x": 52.75,
        "y": 0.999579655
    },
    {
        "x": 52.76,
        "y": 0.999579655
    },
    {
        "x": 52.77,
        "y": 0.999579655
    },
    {
        "x": 52.78,
        "y": 0.999579655
    },
    {
        "x": 52.79,
        "y": 0.999579655
    },
    {
        "x": 52.8,
        "y": 0.999579655
    },
    {
        "x": 52.81,
        "y": 0.999579655
    },
    {
        "x": 52.82,
        "y": 0.999579655
    },
    {
        "x": 52.83,
        "y": 0.999579655
    },
    {
        "x": 52.84,
        "y": 0.999579655
    },
    {
        "x": 52.85,
        "y": 0.999579655
    },
    {
        "x": 52.86,
        "y": 0.999579655
    },
    {
        "x": 52.87,
        "y": 0.999579655
    },
    {
        "x": 52.88,
        "y": 0.999579655
    },
    {
        "x": 52.89,
        "y": 0.999579655
    },
    {
        "x": 52.9,
        "y": 0.999579655
    },
    {
        "x": 52.91,
        "y": 0.999579655
    },
    {
        "x": 52.92,
        "y": 0.999579655
    },
    {
        "x": 52.93,
        "y": 0.999579655
    },
    {
        "x": 52.94,
        "y": 0.999579655
    },
    {
        "x": 52.95,
        "y": 0.999579655
    },
    {
        "x": 52.96,
        "y": 0.999579655
    },
    {
        "x": 52.97,
        "y": 0.999579655
    },
    {
        "x": 52.98,
        "y": 0.999579655
    },
    {
        "x": 52.99,
        "y": 0.999579655
    },
    {
        "x": 53,
        "y": 0.999579655
    },
    {
        "x": 53.01,
        "y": 0.999579655
    },
    {
        "x": 53.02,
        "y": 0.999579655
    },
    {
        "x": 53.03,
        "y": 0.999579655
    },
    {
        "x": 53.04,
        "y": 0.999579655
    },
    {
        "x": 53.05,
        "y": 0.999579655
    },
    {
        "x": 53.06,
        "y": 0.999579655
    },
    {
        "x": 53.07,
        "y": 0.999579655
    },
    {
        "x": 53.08,
        "y": 0.999579655
    },
    {
        "x": 53.09,
        "y": 0.999579655
    },
    {
        "x": 53.1,
        "y": 0.999579655
    },
    {
        "x": 53.11,
        "y": 0.999579655
    },
    {
        "x": 53.12,
        "y": 0.999579655
    },
    {
        "x": 53.13,
        "y": 0.999579655
    },
    {
        "x": 53.14,
        "y": 0.999579655
    },
    {
        "x": 53.15,
        "y": 0.999579655
    },
    {
        "x": 53.16,
        "y": 0.999579655
    },
    {
        "x": 53.17,
        "y": 0.999579655
    },
    {
        "x": 53.18,
        "y": 0.999579655
    },
    {
        "x": 53.19,
        "y": 0.999579655
    },
    {
        "x": 53.2,
        "y": 0.999579655
    },
    {
        "x": 53.21,
        "y": 1
    },
    {
        "x": 53.22,
        "y": 1
    },
    {
        "x": 53.23,
        "y": 1
    },
    {
        "x": 53.24,
        "y": 1
    },
    {
        "x": 53.25,
        "y": 1
    },
    {
        "x": 53.26,
        "y": 0.999579655
    },
    {
        "x": 53.27,
        "y": 0.999579655
    },
    {
        "x": 53.28,
        "y": 0.999579655
    },
    {
        "x": 53.29,
        "y": 1
    },
    {
        "x": 53.3,
        "y": 1
    },
    {
        "x": 53.31,
        "y": 0.999579655
    },
    {
        "x": 53.32,
        "y": 0.999579655
    },
    {
        "x": 53.33,
        "y": 0.999579655
    },
    {
        "x": 53.34,
        "y": 1
    },
    {
        "x": 53.35,
        "y": 0.999579655
    },
    {
        "x": 53.36,
        "y": 0.999579655
    },
    {
        "x": 53.37,
        "y": 0.999579655
    },
    {
        "x": 53.38,
        "y": 0.999579655
    },
    {
        "x": 53.39,
        "y": 0.999579655
    },
    {
        "x": 53.4,
        "y": 0.999579655
    },
    {
        "x": 53.41,
        "y": 0.999579655
    },
    {
        "x": 53.42,
        "y": 0.999579655
    },
    {
        "x": 53.43,
        "y": 0.999579655
    },
    {
        "x": 53.44,
        "y": 0.999579655
    },
    {
        "x": 53.45,
        "y": 0.999579655
    },
    {
        "x": 53.46,
        "y": 0.999579655
    },
    {
        "x": 53.47,
        "y": 0.999579655
    },
    {
        "x": 53.48,
        "y": 0.999579655
    },
    {
        "x": 53.49,
        "y": 0.999579655
    },
    {
        "x": 53.5,
        "y": 0.999579655
    },
    {
        "x": 53.51,
        "y": 0.999579655
    },
    {
        "x": 53.52,
        "y": 0.999579655
    },
    {
        "x": 53.53,
        "y": 0.999579655
    },
    {
        "x": 53.54,
        "y": 0.999579655
    },
    {
        "x": 53.55,
        "y": 0.999579655
    },
    {
        "x": 53.56,
        "y": 0.999579655
    },
    {
        "x": 53.57,
        "y": 0.999579655
    },
    {
        "x": 53.58,
        "y": 0.999579655
    },
    {
        "x": 53.59,
        "y": 0.999579655
    },
    {
        "x": 53.6,
        "y": 0.999579655
    },
    {
        "x": 53.61,
        "y": 0.999579655
    },
    {
        "x": 53.62,
        "y": 0.999579655
    },
    {
        "x": 53.63,
        "y": 0.999579655
    },
    {
        "x": 53.64,
        "y": 0.999579655
    },
    {
        "x": 53.65,
        "y": 0.999579655
    },
    {
        "x": 53.66,
        "y": 0.999579655
    },
    {
        "x": 53.67,
        "y": 0.999579655
    },
    {
        "x": 53.68,
        "y": 0.999579655
    },
    {
        "x": 53.69,
        "y": 0.999579655
    },
    {
        "x": 53.7,
        "y": 0.999579655
    },
    {
        "x": 53.71,
        "y": 0.999579655
    },
    {
        "x": 53.72,
        "y": 0.999579655
    },
    {
        "x": 53.73,
        "y": 0.999579655
    },
    {
        "x": 53.74,
        "y": 0.999579655
    },
    {
        "x": 53.75,
        "y": 0.999159311
    },
    {
        "x": 53.76,
        "y": 0.999579655
    },
    {
        "x": 53.77,
        "y": 0.999579655
    },
    {
        "x": 53.78,
        "y": 0.999579655
    },
    {
        "x": 53.79,
        "y": 0.999579655
    },
    {
        "x": 53.8,
        "y": 0.999579655
    },
    {
        "x": 53.81,
        "y": 0.999579655
    },
    {
        "x": 53.82,
        "y": 0.999579655
    },
    {
        "x": 53.83,
        "y": 0.999579655
    },
    {
        "x": 53.84,
        "y": 0.999579655
    },
    {
        "x": 53.85,
        "y": 0.999579655
    },
    {
        "x": 53.86,
        "y": 0.999579655
    },
    {
        "x": 53.87,
        "y": 0.999579655
    },
    {
        "x": 53.88,
        "y": 0.999579655
    },
    {
        "x": 53.89,
        "y": 0.999579655
    },
    {
        "x": 53.9,
        "y": 0.999579655
    },
    {
        "x": 53.91,
        "y": 0.999579655
    },
    {
        "x": 53.92,
        "y": 0.999579655
    },
    {
        "x": 53.93,
        "y": 0.999579655
    },
    {
        "x": 53.94,
        "y": 0.999579655
    },
    {
        "x": 53.95,
        "y": 0.999579655
    },
    {
        "x": 53.96,
        "y": 0.999579655
    },
    {
        "x": 53.97,
        "y": 0.999579655
    },
    {
        "x": 53.98,
        "y": 0.999579655
    },
    {
        "x": 53.99,
        "y": 0.999579655
    },
    {
        "x": 54,
        "y": 0.999579655
    },
    {
        "x": 54.01,
        "y": 0.999579655
    },
    {
        "x": 54.02,
        "y": 0.999579655
    },
    {
        "x": 54.03,
        "y": 0.999579655
    },
    {
        "x": 54.04,
        "y": 0.999579655
    },
    {
        "x": 54.05,
        "y": 0.999579655
    },
    {
        "x": 54.06,
        "y": 0.999579655
    },
    {
        "x": 54.07,
        "y": 0.999579655
    },
    {
        "x": 54.08,
        "y": 0.999579655
    },
    {
        "x": 54.09,
        "y": 0.999579655
    },
    {
        "x": 54.1,
        "y": 0.999579655
    },
    {
        "x": 54.11,
        "y": 0.999579655
    },
    {
        "x": 54.12,
        "y": 0.999579655
    },
    {
        "x": 54.13,
        "y": 0.999579655
    },
    {
        "x": 54.14,
        "y": 0.999579655
    },
    {
        "x": 54.15,
        "y": 0.999579655
    },
    {
        "x": 54.16,
        "y": 0.999579655
    },
    {
        "x": 54.17,
        "y": 0.999579655
    },
    {
        "x": 54.18,
        "y": 0.999579655
    },
    {
        "x": 54.19,
        "y": 0.999579655
    },
    {
        "x": 54.2,
        "y": 0.999579655
    },
    {
        "x": 54.21,
        "y": 0.999579655
    },
    {
        "x": 54.22,
        "y": 0.999579655
    },
    {
        "x": 54.23,
        "y": 0.999579655
    },
    {
        "x": 54.24,
        "y": 0.999579655
    },
    {
        "x": 54.25,
        "y": 0.999579655
    },
    {
        "x": 54.26,
        "y": 0.999579655
    },
    {
        "x": 54.27,
        "y": 0.999579655
    },
    {
        "x": 54.28,
        "y": 0.999579655
    },
    {
        "x": 54.29,
        "y": 0.999579655
    },
    {
        "x": 54.3,
        "y": 0.999579655
    },
    {
        "x": 54.31,
        "y": 0.999579655
    },
    {
        "x": 54.32,
        "y": 0.999579655
    },
    {
        "x": 54.33,
        "y": 0.999579655
    },
    {
        "x": 54.34,
        "y": 0.999579655
    },
    {
        "x": 54.35,
        "y": 0.999579655
    },
    {
        "x": 54.36,
        "y": 0.999579655
    },
    {
        "x": 54.37,
        "y": 0.999579655
    },
    {
        "x": 54.38,
        "y": 0.999579655
    },
    {
        "x": 54.39,
        "y": 0.999579655
    },
    {
        "x": 54.4,
        "y": 0.999579655
    },
    {
        "x": 54.41,
        "y": 0.999579655
    },
    {
        "x": 54.42,
        "y": 0.999579655
    },
    {
        "x": 54.43,
        "y": 0.999579655
    },
    {
        "x": 54.44,
        "y": 0.999579655
    },
    {
        "x": 54.45,
        "y": 0.999579655
    },
    {
        "x": 54.46,
        "y": 0.999579655
    },
    {
        "x": 54.47,
        "y": 0.999579655
    },
    {
        "x": 54.48,
        "y": 0.999579655
    },
    {
        "x": 54.49,
        "y": 0.999579655
    },
    {
        "x": 54.5,
        "y": 0.999579655
    },
    {
        "x": 54.51,
        "y": 1
    },
    {
        "x": 54.52,
        "y": 1
    },
    {
        "x": 54.53,
        "y": 0.999579655
    },
    {
        "x": 54.54,
        "y": 1
    },
    {
        "x": 54.55,
        "y": 0.999579655
    },
    {
        "x": 54.56,
        "y": 0.999579655
    },
    {
        "x": 54.57,
        "y": 0.999579655
    },
    {
        "x": 54.58,
        "y": 0.999579655
    },
    {
        "x": 54.59,
        "y": 0.999579655
    },
    {
        "x": 54.6,
        "y": 0.999579655
    },
    {
        "x": 54.61,
        "y": 0.999579655
    },
    {
        "x": 54.62,
        "y": 0.999579655
    },
    {
        "x": 54.63,
        "y": 0.999579655
    },
    {
        "x": 54.64,
        "y": 0.999579655
    },
    {
        "x": 54.65,
        "y": 0.999579655
    },
    {
        "x": 54.66,
        "y": 0.999579655
    },
    {
        "x": 54.67,
        "y": 0.999579655
    },
    {
        "x": 54.68,
        "y": 0.999579655
    },
    {
        "x": 54.69,
        "y": 0.999579655
    },
    {
        "x": 54.7,
        "y": 0.999579655
    },
    {
        "x": 54.71,
        "y": 0.999579655
    },
    {
        "x": 54.72,
        "y": 0.999579655
    },
    {
        "x": 54.73,
        "y": 0.999579655
    },
    {
        "x": 54.74,
        "y": 0.999579655
    },
    {
        "x": 54.75,
        "y": 0.999579655
    },
    {
        "x": 54.76,
        "y": 0.999579655
    },
    {
        "x": 54.77,
        "y": 0.999579655
    },
    {
        "x": 54.78,
        "y": 0.999579655
    },
    {
        "x": 54.79,
        "y": 0.999579655
    },
    {
        "x": 54.8,
        "y": 0.999579655
    },
    {
        "x": 54.81,
        "y": 0.999579655
    },
    {
        "x": 54.82,
        "y": 0.999579655
    },
    {
        "x": 54.83,
        "y": 0.999579655
    },
    {
        "x": 54.84,
        "y": 0.999579655
    },
    {
        "x": 54.85,
        "y": 0.999579655
    },
    {
        "x": 54.86,
        "y": 0.999579655
    },
    {
        "x": 54.87,
        "y": 0.999579655
    },
    {
        "x": 54.88,
        "y": 0.999579655
    },
    {
        "x": 54.89,
        "y": 0.999579655
    },
    {
        "x": 54.9,
        "y": 0.999579655
    },
    {
        "x": 54.91,
        "y": 0.999579655
    },
    {
        "x": 54.92,
        "y": 0.999579655
    },
    {
        "x": 54.93,
        "y": 0.999579655
    },
    {
        "x": 54.94,
        "y": 0.999579655
    },
    {
        "x": 54.95,
        "y": 0.999579655
    },
    {
        "x": 54.96,
        "y": 0.999579655
    },
    {
        "x": 54.97,
        "y": 0.999579655
    },
    {
        "x": 54.98,
        "y": 0.999579655
    },
    {
        "x": 54.99,
        "y": 0.999579655
    },
    {
        "x": 55,
        "y": 0.999579655
    },
    {
        "x": 55.01,
        "y": 0.999579655
    },
    {
        "x": 55.02,
        "y": 0.999579655
    },
    {
        "x": 55.03,
        "y": 0.999579655
    },
    {
        "x": 55.04,
        "y": 0.999579655
    },
    {
        "x": 55.05,
        "y": 0.999579655
    },
    {
        "x": 55.06,
        "y": 0.999579655
    },
    {
        "x": 55.07,
        "y": 0.999579655
    },
    {
        "x": 55.08,
        "y": 0.999579655
    },
    {
        "x": 55.09,
        "y": 0.999579655
    },
    {
        "x": 55.1,
        "y": 0.999579655
    },
    {
        "x": 55.11,
        "y": 0.999579655
    },
    {
        "x": 55.12,
        "y": 0.999579655
    },
    {
        "x": 55.13,
        "y": 0.999579655
    },
    {
        "x": 55.14,
        "y": 0.999579655
    },
    {
        "x": 55.15,
        "y": 0.999579655
    },
    {
        "x": 55.16,
        "y": 0.999579655
    },
    {
        "x": 55.17,
        "y": 0.999579655
    },
    {
        "x": 55.18,
        "y": 0.999579655
    },
    {
        "x": 55.19,
        "y": 0.999579655
    },
    {
        "x": 55.2,
        "y": 0.999579655
    },
    {
        "x": 55.21,
        "y": 0.999579655
    },
    {
        "x": 55.22,
        "y": 0.999579655
    },
    {
        "x": 55.23,
        "y": 0.999579655
    },
    {
        "x": 55.24,
        "y": 0.999579655
    },
    {
        "x": 55.25,
        "y": 0.999579655
    },
    {
        "x": 55.26,
        "y": 0.999579655
    },
    {
        "x": 55.27,
        "y": 0.999579655
    },
    {
        "x": 55.28,
        "y": 0.999579655
    },
    {
        "x": 55.29,
        "y": 0.999579655
    },
    {
        "x": 55.3,
        "y": 0.999579655
    },
    {
        "x": 55.31,
        "y": 0.999579655
    },
    {
        "x": 55.32,
        "y": 0.999579655
    },
    {
        "x": 55.33,
        "y": 0.999579655
    },
    {
        "x": 55.34,
        "y": 0.999579655
    },
    {
        "x": 55.35,
        "y": 0.999579655
    },
    {
        "x": 55.36,
        "y": 0.999579655
    },
    {
        "x": 55.37,
        "y": 0.999579655
    },
    {
        "x": 55.38,
        "y": 0.999579655
    },
    {
        "x": 55.39,
        "y": 0.999579655
    },
    {
        "x": 55.4,
        "y": 0.999579655
    },
    {
        "x": 55.41,
        "y": 0.999579655
    },
    {
        "x": 55.42,
        "y": 0.999579655
    },
    {
        "x": 55.43,
        "y": 0.999579655
    },
    {
        "x": 55.44,
        "y": 0.999579655
    },
    {
        "x": 55.45,
        "y": 0.999579655
    },
    {
        "x": 55.46,
        "y": 0.999579655
    },
    {
        "x": 55.47,
        "y": 0.999579655
    },
    {
        "x": 55.48,
        "y": 0.999579655
    },
    {
        "x": 55.49,
        "y": 0.999579655
    },
    {
        "x": 55.5,
        "y": 0.999579655
    },
    {
        "x": 55.51,
        "y": 0.999579655
    },
    {
        "x": 55.52,
        "y": 0.999579655
    },
    {
        "x": 55.53,
        "y": 0.999579655
    },
    {
        "x": 55.54,
        "y": 0.999579655
    },
    {
        "x": 55.55,
        "y": 0.999579655
    },
    {
        "x": 55.56,
        "y": 0.999579655
    },
    {
        "x": 55.57,
        "y": 0.999579655
    },
    {
        "x": 55.58,
        "y": 0.999579655
    },
    {
        "x": 55.59,
        "y": 0.999579655
    },
    {
        "x": 55.6,
        "y": 0.999579655
    },
    {
        "x": 55.61,
        "y": 0.999579655
    },
    {
        "x": 55.62,
        "y": 0.999579655
    },
    {
        "x": 55.63,
        "y": 0.999579655
    },
    {
        "x": 55.64,
        "y": 0.999579655
    },
    {
        "x": 55.65,
        "y": 0.999579655
    },
    {
        "x": 55.66,
        "y": 0.999579655
    },
    {
        "x": 55.67,
        "y": 0.999579655
    },
    {
        "x": 55.68,
        "y": 0.999579655
    },
    {
        "x": 55.69,
        "y": 0.999579655
    },
    {
        "x": 55.7,
        "y": 0.999579655
    },
    {
        "x": 55.71,
        "y": 0.999579655
    },
    {
        "x": 55.72,
        "y": 0.999579655
    },
    {
        "x": 55.73,
        "y": 0.999579655
    },
    {
        "x": 55.74,
        "y": 0.999579655
    },
    {
        "x": 55.75,
        "y": 0.999579655
    },
    {
        "x": 55.76,
        "y": 0.999579655
    },
    {
        "x": 55.77,
        "y": 0.999579655
    },
    {
        "x": 55.78,
        "y": 0.999579655
    },
    {
        "x": 55.79,
        "y": 0.999579655
    },
    {
        "x": 55.8,
        "y": 0.999579655
    },
    {
        "x": 55.81,
        "y": 0.999579655
    },
    {
        "x": 55.82,
        "y": 0.999579655
    },
    {
        "x": 55.83,
        "y": 0.999579655
    },
    {
        "x": 55.84,
        "y": 0.999579655
    },
    {
        "x": 55.85,
        "y": 0.999579655
    },
    {
        "x": 55.86,
        "y": 0.999579655
    },
    {
        "x": 55.87,
        "y": 0.999579655
    },
    {
        "x": 55.88,
        "y": 0.999579655
    },
    {
        "x": 55.89,
        "y": 0.999579655
    },
    {
        "x": 55.9,
        "y": 0.999579655
    },
    {
        "x": 55.91,
        "y": 0.999579655
    },
    {
        "x": 55.92,
        "y": 0.999579655
    },
    {
        "x": 55.93,
        "y": 0.999579655
    },
    {
        "x": 55.94,
        "y": 0.999579655
    },
    {
        "x": 55.95,
        "y": 0.999579655
    },
    {
        "x": 55.96,
        "y": 0.999579655
    },
    {
        "x": 55.97,
        "y": 0.999579655
    },
    {
        "x": 55.98,
        "y": 0.999579655
    },
    {
        "x": 55.99,
        "y": 0.999579655
    },
    {
        "x": 56,
        "y": 0.999159311
    },
    {
        "x": 56.01,
        "y": 0.999579655
    },
    {
        "x": 56.02,
        "y": 0.999579655
    },
    {
        "x": 56.03,
        "y": 0.999159311
    },
    {
        "x": 56.04,
        "y": 0.999579655
    },
    {
        "x": 56.05,
        "y": 0.999579655
    },
    {
        "x": 56.06,
        "y": 0.999579655
    },
    {
        "x": 56.07,
        "y": 0.999579655
    },
    {
        "x": 56.08,
        "y": 0.999579655
    },
    {
        "x": 56.09,
        "y": 0.999579655
    },
    {
        "x": 56.1,
        "y": 0.999579655
    },
    {
        "x": 56.11,
        "y": 0.999579655
    },
    {
        "x": 56.12,
        "y": 0.999579655
    },
    {
        "x": 56.13,
        "y": 0.999579655
    },
    {
        "x": 56.14,
        "y": 0.999579655
    },
    {
        "x": 56.15,
        "y": 0.999579655
    },
    {
        "x": 56.16,
        "y": 0.999159311
    },
    {
        "x": 56.17,
        "y": 0.999579655
    },
    {
        "x": 56.18,
        "y": 0.999579655
    },
    {
        "x": 56.19,
        "y": 0.999579655
    },
    {
        "x": 56.2,
        "y": 0.999579655
    },
    {
        "x": 56.21,
        "y": 0.999579655
    },
    {
        "x": 56.22,
        "y": 0.999579655
    },
    {
        "x": 56.23,
        "y": 0.999159311
    },
    {
        "x": 56.24,
        "y": 0.999579655
    },
    {
        "x": 56.25,
        "y": 0.999579655
    },
    {
        "x": 56.26,
        "y": 0.999159311
    },
    {
        "x": 56.27,
        "y": 0.999159311
    },
    {
        "x": 56.28,
        "y": 0.999579655
    },
    {
        "x": 56.29,
        "y": 0.999579655
    },
    {
        "x": 56.3,
        "y": 0.999579655
    },
    {
        "x": 56.31,
        "y": 0.999579655
    },
    {
        "x": 56.32,
        "y": 0.999579655
    },
    {
        "x": 56.33,
        "y": 0.999579655
    },
    {
        "x": 56.34,
        "y": 0.999579655
    },
    {
        "x": 56.35,
        "y": 0.999159311
    },
    {
        "x": 56.36,
        "y": 0.999579655
    },
    {
        "x": 56.37,
        "y": 0.999579655
    },
    {
        "x": 56.38,
        "y": 0.999579655
    },
    {
        "x": 56.39,
        "y": 0.999579655
    },
    {
        "x": 56.4,
        "y": 0.999579655
    },
    {
        "x": 56.41,
        "y": 0.999579655
    },
    {
        "x": 56.42,
        "y": 0.999579655
    },
    {
        "x": 56.43,
        "y": 0.999159311
    },
    {
        "x": 56.44,
        "y": 0.999159311
    },
    {
        "x": 56.45,
        "y": 0.999579655
    },
    {
        "x": 56.46,
        "y": 0.999159311
    },
    {
        "x": 56.47,
        "y": 0.999579655
    },
    {
        "x": 56.48,
        "y": 0.999159311
    },
    {
        "x": 56.49,
        "y": 0.999159311
    },
    {
        "x": 56.5,
        "y": 0.999159311
    },
    {
        "x": 56.51,
        "y": 0.999579655
    },
    {
        "x": 56.52,
        "y": 0.999579655
    },
    {
        "x": 56.53,
        "y": 0.999579655
    },
    {
        "x": 56.54,
        "y": 0.999159311
    },
    {
        "x": 56.55,
        "y": 0.999159311
    },
    {
        "x": 56.56,
        "y": 0.999159311
    },
    {
        "x": 56.57,
        "y": 0.999159311
    },
    {
        "x": 56.58,
        "y": 0.999159311
    },
    {
        "x": 56.59,
        "y": 0.999579655
    },
    {
        "x": 56.6,
        "y": 0.999159311
    },
    {
        "x": 56.61,
        "y": 0.999579655
    },
    {
        "x": 56.62,
        "y": 0.999579655
    },
    {
        "x": 56.63,
        "y": 0.999159311
    },
    {
        "x": 56.64,
        "y": 0.999579655
    },
    {
        "x": 56.65,
        "y": 0.999579655
    },
    {
        "x": 56.66,
        "y": 0.999159311
    },
    {
        "x": 56.67,
        "y": 0.999159311
    },
    {
        "x": 56.68,
        "y": 0.999579655
    },
    {
        "x": 56.69,
        "y": 0.999579655
    },
    {
        "x": 56.7,
        "y": 0.999579655
    },
    {
        "x": 56.71,
        "y": 0.999159311
    },
    {
        "x": 56.72,
        "y": 0.999159311
    },
    {
        "x": 56.73,
        "y": 0.999159311
    },
    {
        "x": 56.74,
        "y": 0.999159311
    },
    {
        "x": 56.75,
        "y": 0.999159311
    },
    {
        "x": 56.76,
        "y": 0.999579655
    },
    {
        "x": 56.77,
        "y": 0.999159311
    },
    {
        "x": 56.78,
        "y": 0.999159311
    },
    {
        "x": 56.79,
        "y": 0.999579655
    },
    {
        "x": 56.8,
        "y": 0.999159311
    },
    {
        "x": 56.81,
        "y": 0.999579655
    },
    {
        "x": 56.82,
        "y": 0.999579655
    },
    {
        "x": 56.83,
        "y": 0.999579655
    },
    {
        "x": 56.84,
        "y": 0.999159311
    },
    {
        "x": 56.85,
        "y": 0.999579655
    },
    {
        "x": 56.86,
        "y": 0.999579655
    },
    {
        "x": 56.87,
        "y": 0.999579655
    },
    {
        "x": 56.88,
        "y": 0.999579655
    },
    {
        "x": 56.89,
        "y": 0.999579655
    },
    {
        "x": 56.9,
        "y": 0.999579655
    },
    {
        "x": 56.91,
        "y": 0.999579655
    },
    {
        "x": 56.92,
        "y": 0.999159311
    },
    {
        "x": 56.93,
        "y": 0.999579655
    },
    {
        "x": 56.94,
        "y": 0.999579655
    },
    {
        "x": 56.95,
        "y": 0.999579655
    },
    {
        "x": 56.96,
        "y": 0.999159311
    },
    {
        "x": 56.97,
        "y": 0.999579655
    },
    {
        "x": 56.98,
        "y": 0.999579655
    },
    {
        "x": 56.99,
        "y": 0.999579655
    },
    {
        "x": 57,
        "y": 0.999579655
    },
    {
        "x": 57.01,
        "y": 0.999579655
    },
    {
        "x": 57.02,
        "y": 0.999579655
    },
    {
        "x": 57.03,
        "y": 0.999579655
    },
    {
        "x": 57.04,
        "y": 0.999579655
    },
    {
        "x": 57.05,
        "y": 0.999579655
    },
    {
        "x": 57.06,
        "y": 0.999159311
    },
    {
        "x": 57.07,
        "y": 0.999579655
    },
    {
        "x": 57.08,
        "y": 0.999159311
    },
    {
        "x": 57.09,
        "y": 0.999159311
    },
    {
        "x": 57.1,
        "y": 0.999159311
    },
    {
        "x": 57.11,
        "y": 0.999159311
    },
    {
        "x": 57.12,
        "y": 0.999159311
    },
    {
        "x": 57.13,
        "y": 0.999159311
    },
    {
        "x": 57.14,
        "y": 0.999579655
    },
    {
        "x": 57.15,
        "y": 0.999159311
    },
    {
        "x": 57.16,
        "y": 0.999159311
    },
    {
        "x": 57.17,
        "y": 0.999159311
    },
    {
        "x": 57.18,
        "y": 0.999579655
    },
    {
        "x": 57.19,
        "y": 0.999159311
    },
    {
        "x": 57.2,
        "y": 0.999579655
    },
    {
        "x": 57.21,
        "y": 0.999159311
    },
    {
        "x": 57.22,
        "y": 0.999159311
    },
    {
        "x": 57.23,
        "y": 0.999159311
    },
    {
        "x": 57.24,
        "y": 0.999159311
    },
    {
        "x": 57.25,
        "y": 0.999159311
    },
    {
        "x": 57.26,
        "y": 0.999159311
    },
    {
        "x": 57.27,
        "y": 0.999159311
    },
    {
        "x": 57.28,
        "y": 0.999159311
    },
    {
        "x": 57.29,
        "y": 0.999159311
    },
    {
        "x": 57.3,
        "y": 0.999159311
    },
    {
        "x": 57.31,
        "y": 0.999159311
    },
    {
        "x": 57.32,
        "y": 0.999159311
    },
    {
        "x": 57.33,
        "y": 0.999159311
    },
    {
        "x": 57.34,
        "y": 0.999159311
    },
    {
        "x": 57.35,
        "y": 0.999159311
    },
    {
        "x": 57.36,
        "y": 0.999159311
    },
    {
        "x": 57.37,
        "y": 0.999159311
    },
    {
        "x": 57.38,
        "y": 0.999159311
    },
    {
        "x": 57.39,
        "y": 0.999159311
    },
    {
        "x": 57.4,
        "y": 0.999159311
    },
    {
        "x": 57.41,
        "y": 0.999159311
    },
    {
        "x": 57.42,
        "y": 0.999159311
    },
    {
        "x": 57.43,
        "y": 0.999159311
    },
    {
        "x": 57.44,
        "y": 0.999159311
    },
    {
        "x": 57.45,
        "y": 0.999159311
    },
    {
        "x": 57.46,
        "y": 0.999159311
    },
    {
        "x": 57.47,
        "y": 0.999159311
    },
    {
        "x": 57.48,
        "y": 0.999159311
    },
    {
        "x": 57.49,
        "y": 0.999159311
    },
    {
        "x": 57.5,
        "y": 0.999159311
    },
    {
        "x": 57.51,
        "y": 0.999159311
    },
    {
        "x": 57.52,
        "y": 0.999159311
    },
    {
        "x": 57.53,
        "y": 0.999159311
    },
    {
        "x": 57.54,
        "y": 0.999159311
    },
    {
        "x": 57.55,
        "y": 0.999159311
    },
    {
        "x": 57.56,
        "y": 0.999159311
    },
    {
        "x": 57.57,
        "y": 0.999159311
    },
    {
        "x": 57.58,
        "y": 0.999159311
    },
    {
        "x": 57.59,
        "y": 0.999159311
    },
    {
        "x": 57.6,
        "y": 0.999159311
    },
    {
        "x": 57.61,
        "y": 0.999579655
    },
    {
        "x": 57.62,
        "y": 0.999159311
    },
    {
        "x": 57.63,
        "y": 0.999159311
    },
    {
        "x": 57.64,
        "y": 0.999159311
    },
    {
        "x": 57.65,
        "y": 0.999159311
    },
    {
        "x": 57.66,
        "y": 0.999159311
    },
    {
        "x": 57.67,
        "y": 0.999159311
    },
    {
        "x": 57.68,
        "y": 0.999159311
    },
    {
        "x": 57.69,
        "y": 0.999159311
    },
    {
        "x": 57.7,
        "y": 0.999159311
    },
    {
        "x": 57.71,
        "y": 0.999159311
    },
    {
        "x": 57.72,
        "y": 0.999159311
    },
    {
        "x": 57.73,
        "y": 0.999159311
    },
    {
        "x": 57.74,
        "y": 0.999159311
    },
    {
        "x": 57.75,
        "y": 0.999159311
    },
    {
        "x": 57.76,
        "y": 0.999159311
    },
    {
        "x": 57.77,
        "y": 0.999159311
    },
    {
        "x": 57.78,
        "y": 0.999159311
    },
    {
        "x": 57.79,
        "y": 0.999159311
    },
    {
        "x": 57.8,
        "y": 0.999159311
    },
    {
        "x": 57.81,
        "y": 0.999159311
    },
    {
        "x": 57.82,
        "y": 0.999159311
    },
    {
        "x": 57.83,
        "y": 0.999159311
    },
    {
        "x": 57.84,
        "y": 0.999159311
    },
    {
        "x": 57.85,
        "y": 0.999159311
    },
    {
        "x": 57.86,
        "y": 0.999159311
    },
    {
        "x": 57.87,
        "y": 0.999159311
    },
    {
        "x": 57.88,
        "y": 0.999159311
    },
    {
        "x": 57.89,
        "y": 0.999159311
    },
    {
        "x": 57.9,
        "y": 0.999159311
    },
    {
        "x": 57.91,
        "y": 0.999159311
    },
    {
        "x": 57.92,
        "y": 0.999159311
    },
    {
        "x": 57.93,
        "y": 0.999159311
    },
    {
        "x": 57.94,
        "y": 0.999159311
    },
    {
        "x": 57.95,
        "y": 0.999159311
    },
    {
        "x": 57.96,
        "y": 0.999159311
    },
    {
        "x": 57.97,
        "y": 0.999159311
    },
    {
        "x": 57.98,
        "y": 0.999159311
    },
    {
        "x": 57.99,
        "y": 0.999159311
    },
    {
        "x": 58,
        "y": 0.999159311
    },
    {
        "x": 58.01,
        "y": 0.999159311
    },
    {
        "x": 58.02,
        "y": 0.999159311
    },
    {
        "x": 58.03,
        "y": 0.999159311
    },
    {
        "x": 58.04,
        "y": 0.999159311
    },
    {
        "x": 58.05,
        "y": 0.999159311
    },
    {
        "x": 58.06,
        "y": 0.999159311
    },
    {
        "x": 58.07,
        "y": 0.999159311
    },
    {
        "x": 58.08,
        "y": 0.999579655
    },
    {
        "x": 58.09,
        "y": 0.999159311
    },
    {
        "x": 58.1,
        "y": 0.999159311
    },
    {
        "x": 58.11,
        "y": 0.999159311
    },
    {
        "x": 58.12,
        "y": 0.999159311
    },
    {
        "x": 58.13,
        "y": 0.999159311
    },
    {
        "x": 58.14,
        "y": 0.999159311
    },
    {
        "x": 58.15,
        "y": 0.999159311
    },
    {
        "x": 58.16,
        "y": 0.999159311
    },
    {
        "x": 58.17,
        "y": 0.999159311
    },
    {
        "x": 58.18,
        "y": 0.999159311
    },
    {
        "x": 58.19,
        "y": 0.999159311
    },
    {
        "x": 58.2,
        "y": 0.999159311
    },
    {
        "x": 58.21,
        "y": 0.999159311
    },
    {
        "x": 58.22,
        "y": 0.999159311
    },
    {
        "x": 58.23,
        "y": 0.999159311
    },
    {
        "x": 58.24,
        "y": 0.999159311
    },
    {
        "x": 58.25,
        "y": 0.999159311
    },
    {
        "x": 58.26,
        "y": 0.999159311
    },
    {
        "x": 58.27,
        "y": 0.999159311
    },
    {
        "x": 58.28,
        "y": 0.999159311
    },
    {
        "x": 58.29,
        "y": 0.999159311
    },
    {
        "x": 58.3,
        "y": 0.999159311
    },
    {
        "x": 58.31,
        "y": 0.999159311
    },
    {
        "x": 58.32,
        "y": 0.999159311
    },
    {
        "x": 58.33,
        "y": 0.999159311
    },
    {
        "x": 58.34,
        "y": 0.999159311
    },
    {
        "x": 58.35,
        "y": 0.999159311
    },
    {
        "x": 58.36,
        "y": 0.999159311
    },
    {
        "x": 58.37,
        "y": 0.999159311
    },
    {
        "x": 58.38,
        "y": 0.999159311
    },
    {
        "x": 58.39,
        "y": 0.998738966
    },
    {
        "x": 58.4,
        "y": 0.999159311
    },
    {
        "x": 58.41,
        "y": 0.999159311
    },
    {
        "x": 58.42,
        "y": 0.999159311
    },
    {
        "x": 58.43,
        "y": 0.999159311
    },
    {
        "x": 58.44,
        "y": 0.999159311
    },
    {
        "x": 58.45,
        "y": 0.999159311
    },
    {
        "x": 58.46,
        "y": 0.998738966
    },
    {
        "x": 58.47,
        "y": 0.999159311
    },
    {
        "x": 58.48,
        "y": 0.999159311
    },
    {
        "x": 58.49,
        "y": 0.999159311
    },
    {
        "x": 58.5,
        "y": 0.999159311
    },
    {
        "x": 58.51,
        "y": 0.999159311
    },
    {
        "x": 58.52,
        "y": 0.999159311
    },
    {
        "x": 58.53,
        "y": 0.999159311
    },
    {
        "x": 58.54,
        "y": 0.999159311
    },
    {
        "x": 58.55,
        "y": 0.999159311
    },
    {
        "x": 58.56,
        "y": 0.999159311
    },
    {
        "x": 58.57,
        "y": 0.999159311
    },
    {
        "x": 58.58,
        "y": 0.999159311
    },
    {
        "x": 58.59,
        "y": 0.999159311
    },
    {
        "x": 58.6,
        "y": 0.999159311
    },
    {
        "x": 58.61,
        "y": 0.999159311
    },
    {
        "x": 58.62,
        "y": 0.999159311
    },
    {
        "x": 58.63,
        "y": 0.999159311
    },
    {
        "x": 58.64,
        "y": 0.999159311
    },
    {
        "x": 58.65,
        "y": 0.998738966
    },
    {
        "x": 58.66,
        "y": 0.999159311
    },
    {
        "x": 58.67,
        "y": 0.999159311
    },
    {
        "x": 58.68,
        "y": 0.999159311
    },
    {
        "x": 58.69,
        "y": 0.999159311
    },
    {
        "x": 58.7,
        "y": 0.999159311
    },
    {
        "x": 58.71,
        "y": 0.999159311
    },
    {
        "x": 58.72,
        "y": 0.999159311
    },
    {
        "x": 58.73,
        "y": 0.999159311
    },
    {
        "x": 58.74,
        "y": 0.998738966
    },
    {
        "x": 58.75,
        "y": 0.999159311
    },
    {
        "x": 58.76,
        "y": 0.999159311
    },
    {
        "x": 58.77,
        "y": 0.998738966
    },
    {
        "x": 58.78,
        "y": 0.998738966
    },
    {
        "x": 58.79,
        "y": 0.999159311
    },
    {
        "x": 58.8,
        "y": 0.999159311
    },
    {
        "x": 58.81,
        "y": 0.999159311
    },
    {
        "x": 58.82,
        "y": 0.998738966
    },
    {
        "x": 58.83,
        "y": 0.999159311
    },
    {
        "x": 58.84,
        "y": 0.999159311
    },
    {
        "x": 58.85,
        "y": 0.999159311
    },
    {
        "x": 58.86,
        "y": 0.999159311
    },
    {
        "x": 58.87,
        "y": 0.999159311
    },
    {
        "x": 58.88,
        "y": 0.999159311
    },
    {
        "x": 58.89,
        "y": 0.999159311
    },
    {
        "x": 58.9,
        "y": 0.999159311
    },
    {
        "x": 58.91,
        "y": 0.999159311
    },
    {
        "x": 58.92,
        "y": 0.999159311
    },
    {
        "x": 58.93,
        "y": 0.999159311
    },
    {
        "x": 58.94,
        "y": 0.999159311
    },
    {
        "x": 58.95,
        "y": 0.999159311
    },
    {
        "x": 58.96,
        "y": 0.999159311
    },
    {
        "x": 58.97,
        "y": 0.999159311
    },
    {
        "x": 58.98,
        "y": 0.998738966
    },
    {
        "x": 58.99,
        "y": 0.999159311
    },
    {
        "x": 59,
        "y": 0.999159311
    },
    {
        "x": 59.01,
        "y": 0.999159311
    },
    {
        "x": 59.02,
        "y": 0.999159311
    },
    {
        "x": 59.03,
        "y": 0.999159311
    },
    {
        "x": 59.04,
        "y": 0.999159311
    },
    {
        "x": 59.05,
        "y": 0.999159311
    },
    {
        "x": 59.06,
        "y": 0.999159311
    },
    {
        "x": 59.07,
        "y": 0.999159311
    },
    {
        "x": 59.08,
        "y": 0.999159311
    },
    {
        "x": 59.09,
        "y": 0.999159311
    },
    {
        "x": 59.1,
        "y": 0.999159311
    },
    {
        "x": 59.11,
        "y": 0.999159311
    },
    {
        "x": 59.12,
        "y": 0.999159311
    },
    {
        "x": 59.13,
        "y": 0.999159311
    },
    {
        "x": 59.14,
        "y": 0.999159311
    },
    {
        "x": 59.15,
        "y": 0.999159311
    },
    {
        "x": 59.16,
        "y": 0.999159311
    },
    {
        "x": 59.17,
        "y": 0.999159311
    },
    {
        "x": 59.18,
        "y": 0.999159311
    },
    {
        "x": 59.19,
        "y": 0.999159311
    },
    {
        "x": 59.2,
        "y": 0.999159311
    },
    {
        "x": 59.21,
        "y": 0.998738966
    },
    {
        "x": 59.22,
        "y": 0.999159311
    },
    {
        "x": 59.23,
        "y": 0.999159311
    },
    {
        "x": 59.24,
        "y": 0.999159311
    },
    {
        "x": 59.25,
        "y": 0.999159311
    },
    {
        "x": 59.26,
        "y": 0.999159311
    },
    {
        "x": 59.27,
        "y": 0.999159311
    },
    {
        "x": 59.28,
        "y": 0.999159311
    },
    {
        "x": 59.29,
        "y": 0.999159311
    },
    {
        "x": 59.3,
        "y": 0.999159311
    },
    {
        "x": 59.31,
        "y": 0.999159311
    },
    {
        "x": 59.32,
        "y": 0.999159311
    },
    {
        "x": 59.33,
        "y": 0.999159311
    },
    {
        "x": 59.34,
        "y": 0.999159311
    },
    {
        "x": 59.35,
        "y": 0.999159311
    },
    {
        "x": 59.36,
        "y": 0.999159311
    },
    {
        "x": 59.37,
        "y": 0.999159311
    },
    {
        "x": 59.38,
        "y": 0.999159311
    },
    {
        "x": 59.39,
        "y": 0.999159311
    },
    {
        "x": 59.4,
        "y": 0.998738966
    },
    {
        "x": 59.41,
        "y": 0.999159311
    },
    {
        "x": 59.42,
        "y": 0.999159311
    },
    {
        "x": 59.43,
        "y": 0.999159311
    },
    {
        "x": 59.44,
        "y": 0.998738966
    },
    {
        "x": 59.45,
        "y": 0.999159311
    },
    {
        "x": 59.46,
        "y": 0.998738966
    },
    {
        "x": 59.47,
        "y": 0.998738966
    },
    {
        "x": 59.48,
        "y": 0.999159311
    },
    {
        "x": 59.49,
        "y": 0.999159311
    },
    {
        "x": 59.5,
        "y": 0.999159311
    },
    {
        "x": 59.51,
        "y": 0.999159311
    },
    {
        "x": 59.52,
        "y": 0.999159311
    },
    {
        "x": 59.53,
        "y": 0.998738966
    },
    {
        "x": 59.54,
        "y": 0.999159311
    },
    {
        "x": 59.55,
        "y": 0.999159311
    },
    {
        "x": 59.56,
        "y": 0.999159311
    },
    {
        "x": 59.57,
        "y": 0.998738966
    },
    {
        "x": 59.58,
        "y": 0.999159311
    },
    {
        "x": 59.59,
        "y": 0.999159311
    },
    {
        "x": 59.6,
        "y": 0.999159311
    },
    {
        "x": 59.61,
        "y": 0.998738966
    },
    {
        "x": 59.62,
        "y": 0.998738966
    },
    {
        "x": 59.63,
        "y": 0.998738966
    },
    {
        "x": 59.64,
        "y": 0.999159311
    },
    {
        "x": 59.65,
        "y": 0.998738966
    },
    {
        "x": 59.66,
        "y": 0.998738966
    },
    {
        "x": 59.67,
        "y": 0.998738966
    },
    {
        "x": 59.68,
        "y": 0.998738966
    },
    {
        "x": 59.69,
        "y": 0.998738966
    },
    {
        "x": 59.7,
        "y": 0.998738966
    },
    {
        "x": 59.71,
        "y": 0.999159311
    },
    {
        "x": 59.72,
        "y": 0.998738966
    },
    {
        "x": 59.73,
        "y": 0.998738966
    },
    {
        "x": 59.74,
        "y": 0.998738966
    },
    {
        "x": 59.75,
        "y": 0.998738966
    },
    {
        "x": 59.76,
        "y": 0.998738966
    },
    {
        "x": 59.77,
        "y": 0.999159311
    },
    {
        "x": 59.78,
        "y": 0.998738966
    },
    {
        "x": 59.79,
        "y": 0.999159311
    },
    {
        "x": 59.8,
        "y": 0.998738966
    },
    {
        "x": 59.81,
        "y": 0.998738966
    },
    {
        "x": 59.82,
        "y": 0.999159311
    },
    {
        "x": 59.83,
        "y": 0.999159311
    },
    {
        "x": 59.84,
        "y": 0.998738966
    },
    {
        "x": 59.85,
        "y": 0.998738966
    },
    {
        "x": 59.86,
        "y": 0.998738966
    },
    {
        "x": 59.87,
        "y": 0.998738966
    },
    {
        "x": 59.88,
        "y": 0.998738966
    },
    {
        "x": 59.89,
        "y": 0.999159311
    },
    {
        "x": 59.9,
        "y": 0.998738966
    },
    {
        "x": 59.91,
        "y": 0.998738966
    },
    {
        "x": 59.92,
        "y": 0.998738966
    },
    {
        "x": 59.93,
        "y": 0.998738966
    },
    {
        "x": 59.94,
        "y": 0.998738966
    },
    {
        "x": 59.95,
        "y": 0.998738966
    },
    {
        "x": 59.96,
        "y": 0.998738966
    },
    {
        "x": 59.97,
        "y": 0.998738966
    },
    {
        "x": 59.98,
        "y": 0.998738966
    },
    {
        "x": 59.99,
        "y": 0.998738966
    },
    {
        "x": 60,
        "y": 0.998738966
    },
    {
        "x": 60.01,
        "y": 0.998738966
    },
    {
        "x": 60.02,
        "y": 0.998738966
    },
    {
        "x": 60.03,
        "y": 0.999159311
    },
    {
        "x": 60.04,
        "y": 0.998738966
    },
    {
        "x": 60.05,
        "y": 0.998738966
    },
    {
        "x": 60.06,
        "y": 0.998738966
    },
    {
        "x": 60.07,
        "y": 0.998738966
    },
    {
        "x": 60.08,
        "y": 0.998738966
    },
    {
        "x": 60.09,
        "y": 0.998738966
    },
    {
        "x": 60.1,
        "y": 0.999159311
    },
    {
        "x": 60.11,
        "y": 0.998738966
    },
    {
        "x": 60.12,
        "y": 0.998738966
    },
    {
        "x": 60.13,
        "y": 0.998738966
    },
    {
        "x": 60.14,
        "y": 0.998738966
    },
    {
        "x": 60.15,
        "y": 0.998738966
    },
    {
        "x": 60.16,
        "y": 0.998738966
    },
    {
        "x": 60.17,
        "y": 0.998738966
    },
    {
        "x": 60.18,
        "y": 0.999159311
    },
    {
        "x": 60.19,
        "y": 0.998738966
    },
    {
        "x": 60.2,
        "y": 0.998738966
    },
    {
        "x": 60.21,
        "y": 0.999159311
    },
    {
        "x": 60.22,
        "y": 0.998738966
    },
    {
        "x": 60.23,
        "y": 0.998738966
    },
    {
        "x": 60.24,
        "y": 0.998738966
    },
    {
        "x": 60.25,
        "y": 0.998738966
    },
    {
        "x": 60.26,
        "y": 0.998738966
    },
    {
        "x": 60.27,
        "y": 0.998738966
    },
    {
        "x": 60.28,
        "y": 0.998738966
    },
    {
        "x": 60.29,
        "y": 0.998738966
    },
    {
        "x": 60.3,
        "y": 0.999159311
    },
    {
        "x": 60.31,
        "y": 0.998738966
    },
    {
        "x": 60.32,
        "y": 0.998738966
    },
    {
        "x": 60.33,
        "y": 0.998738966
    },
    {
        "x": 60.34,
        "y": 0.999159311
    },
    {
        "x": 60.35,
        "y": 0.998738966
    },
    {
        "x": 60.36,
        "y": 0.998738966
    },
    {
        "x": 60.37,
        "y": 0.998738966
    },
    {
        "x": 60.38,
        "y": 0.998738966
    },
    {
        "x": 60.39,
        "y": 0.998738966
    },
    {
        "x": 60.4,
        "y": 0.998738966
    },
    {
        "x": 60.41,
        "y": 0.998738966
    },
    {
        "x": 60.42,
        "y": 0.998738966
    },
    {
        "x": 60.43,
        "y": 0.998738966
    },
    {
        "x": 60.44,
        "y": 0.998738966
    },
    {
        "x": 60.45,
        "y": 0.998738966
    },
    {
        "x": 60.46,
        "y": 0.999159311
    },
    {
        "x": 60.47,
        "y": 0.998738966
    },
    {
        "x": 60.48,
        "y": 0.998738966
    },
    {
        "x": 60.49,
        "y": 0.998738966
    },
    {
        "x": 60.5,
        "y": 0.998738966
    },
    {
        "x": 60.51,
        "y": 0.998738966
    },
    {
        "x": 60.52,
        "y": 0.998738966
    },
    {
        "x": 60.53,
        "y": 0.998738966
    },
    {
        "x": 60.54,
        "y": 0.998738966
    },
    {
        "x": 60.55,
        "y": 0.998738966
    },
    {
        "x": 60.56,
        "y": 0.998738966
    },
    {
        "x": 60.57,
        "y": 0.998738966
    },
    {
        "x": 60.58,
        "y": 0.998738966
    },
    {
        "x": 60.59,
        "y": 0.998738966
    },
    {
        "x": 60.6,
        "y": 0.998738966
    },
    {
        "x": 60.61,
        "y": 0.998738966
    },
    {
        "x": 60.62,
        "y": 0.998738966
    },
    {
        "x": 60.63,
        "y": 0.998738966
    },
    {
        "x": 60.64,
        "y": 0.998738966
    },
    {
        "x": 60.65,
        "y": 0.998738966
    },
    {
        "x": 60.66,
        "y": 0.998738966
    },
    {
        "x": 60.67,
        "y": 0.998738966
    },
    {
        "x": 60.68,
        "y": 0.998738966
    },
    {
        "x": 60.69,
        "y": 0.998738966
    },
    {
        "x": 60.7,
        "y": 0.998738966
    },
    {
        "x": 60.71,
        "y": 0.998738966
    },
    {
        "x": 60.72,
        "y": 0.998738966
    },
    {
        "x": 60.73,
        "y": 0.998738966
    },
    {
        "x": 60.74,
        "y": 0.998738966
    },
    {
        "x": 60.75,
        "y": 0.998738966
    },
    {
        "x": 60.76,
        "y": 0.998738966
    },
    {
        "x": 60.77,
        "y": 0.998738966
    },
    {
        "x": 60.78,
        "y": 0.998738966
    },
    {
        "x": 60.79,
        "y": 0.998738966
    },
    {
        "x": 60.8,
        "y": 0.998738966
    },
    {
        "x": 60.81,
        "y": 0.998738966
    },
    {
        "x": 60.82,
        "y": 0.998738966
    },
    {
        "x": 60.83,
        "y": 0.998738966
    },
    {
        "x": 60.84,
        "y": 0.998318621
    },
    {
        "x": 60.85,
        "y": 0.998738966
    },
    {
        "x": 60.86,
        "y": 0.998318621
    },
    {
        "x": 60.87,
        "y": 0.998738966
    },
    {
        "x": 60.88,
        "y": 0.998738966
    },
    {
        "x": 60.89,
        "y": 0.998738966
    },
    {
        "x": 60.9,
        "y": 0.998738966
    },
    {
        "x": 60.91,
        "y": 0.998738966
    },
    {
        "x": 60.92,
        "y": 0.998318621
    },
    {
        "x": 60.93,
        "y": 0.998738966
    },
    {
        "x": 60.94,
        "y": 0.998738966
    },
    {
        "x": 60.95,
        "y": 0.998738966
    },
    {
        "x": 60.96,
        "y": 0.998738966
    },
    {
        "x": 60.97,
        "y": 0.998738966
    },
    {
        "x": 60.98,
        "y": 0.998738966
    },
    {
        "x": 60.99,
        "y": 0.998738966
    },
    {
        "x": 61,
        "y": 0.998738966
    },
    {
        "x": 61.01,
        "y": 0.998738966
    },
    {
        "x": 61.02,
        "y": 0.998738966
    },
    {
        "x": 61.03,
        "y": 0.998738966
    },
    {
        "x": 61.04,
        "y": 0.998738966
    },
    {
        "x": 61.05,
        "y": 0.998738966
    },
    {
        "x": 61.06,
        "y": 0.998738966
    },
    {
        "x": 61.07,
        "y": 0.998738966
    },
    {
        "x": 61.08,
        "y": 0.998738966
    },
    {
        "x": 61.09,
        "y": 0.998738966
    },
    {
        "x": 61.1,
        "y": 0.998738966
    },
    {
        "x": 61.11,
        "y": 0.998738966
    },
    {
        "x": 61.12,
        "y": 0.998738966
    },
    {
        "x": 61.13,
        "y": 0.998738966
    },
    {
        "x": 61.14,
        "y": 0.998738966
    },
    {
        "x": 61.15,
        "y": 0.998738966
    },
    {
        "x": 61.16,
        "y": 0.998738966
    },
    {
        "x": 61.17,
        "y": 0.998738966
    },
    {
        "x": 61.18,
        "y": 0.998738966
    },
    {
        "x": 61.19,
        "y": 0.998738966
    },
    {
        "x": 61.2,
        "y": 0.998738966
    },
    {
        "x": 61.21,
        "y": 0.998738966
    },
    {
        "x": 61.22,
        "y": 0.998738966
    },
    {
        "x": 61.23,
        "y": 0.998738966
    },
    {
        "x": 61.24,
        "y": 0.998738966
    },
    {
        "x": 61.25,
        "y": 0.998738966
    },
    {
        "x": 61.26,
        "y": 0.998738966
    },
    {
        "x": 61.27,
        "y": 0.998738966
    },
    {
        "x": 61.28,
        "y": 0.998738966
    },
    {
        "x": 61.29,
        "y": 0.998738966
    },
    {
        "x": 61.3,
        "y": 0.998738966
    },
    {
        "x": 61.31,
        "y": 0.998738966
    },
    {
        "x": 61.32,
        "y": 0.998738966
    },
    {
        "x": 61.33,
        "y": 0.998738966
    },
    {
        "x": 61.34,
        "y": 0.998738966
    },
    {
        "x": 61.35,
        "y": 0.998738966
    },
    {
        "x": 61.36,
        "y": 0.998738966
    },
    {
        "x": 61.37,
        "y": 0.998738966
    },
    {
        "x": 61.38,
        "y": 0.998738966
    },
    {
        "x": 61.39,
        "y": 0.998738966
    },
    {
        "x": 61.4,
        "y": 0.998738966
    },
    {
        "x": 61.41,
        "y": 0.998738966
    },
    {
        "x": 61.42,
        "y": 0.998738966
    },
    {
        "x": 61.43,
        "y": 0.998738966
    },
    {
        "x": 61.44,
        "y": 0.998738966
    },
    {
        "x": 61.45,
        "y": 0.998738966
    },
    {
        "x": 61.46,
        "y": 0.998738966
    },
    {
        "x": 61.47,
        "y": 0.998738966
    },
    {
        "x": 61.48,
        "y": 0.998738966
    },
    {
        "x": 61.49,
        "y": 0.998738966
    },
    {
        "x": 61.5,
        "y": 0.998738966
    },
    {
        "x": 61.51,
        "y": 0.998738966
    },
    {
        "x": 61.52,
        "y": 0.998738966
    },
    {
        "x": 61.53,
        "y": 0.998738966
    },
    {
        "x": 61.54,
        "y": 0.998738966
    },
    {
        "x": 61.55,
        "y": 0.998738966
    },
    {
        "x": 61.56,
        "y": 0.998318621
    },
    {
        "x": 61.57,
        "y": 0.998738966
    },
    {
        "x": 61.58,
        "y": 0.998738966
    },
    {
        "x": 61.59,
        "y": 0.998738966
    },
    {
        "x": 61.6,
        "y": 0.998318621
    },
    {
        "x": 61.61,
        "y": 0.998738966
    },
    {
        "x": 61.62,
        "y": 0.998738966
    },
    {
        "x": 61.63,
        "y": 0.998738966
    },
    {
        "x": 61.64,
        "y": 0.998738966
    },
    {
        "x": 61.65,
        "y": 0.998738966
    },
    {
        "x": 61.66,
        "y": 0.998738966
    },
    {
        "x": 61.67,
        "y": 0.998738966
    },
    {
        "x": 61.68,
        "y": 0.998318621
    },
    {
        "x": 61.69,
        "y": 0.998318621
    },
    {
        "x": 61.7,
        "y": 0.998738966
    },
    {
        "x": 61.71,
        "y": 0.998738966
    },
    {
        "x": 61.72,
        "y": 0.998738966
    },
    {
        "x": 61.73,
        "y": 0.998738966
    },
    {
        "x": 61.74,
        "y": 0.998738966
    },
    {
        "x": 61.75,
        "y": 0.998738966
    },
    {
        "x": 61.76,
        "y": 0.998738966
    },
    {
        "x": 61.77,
        "y": 0.998738966
    },
    {
        "x": 61.78,
        "y": 0.998738966
    },
    {
        "x": 61.79,
        "y": 0.998738966
    },
    {
        "x": 61.8,
        "y": 0.998738966
    },
    {
        "x": 61.81,
        "y": 0.998738966
    },
    {
        "x": 61.82,
        "y": 0.998738966
    },
    {
        "x": 61.83,
        "y": 0.998738966
    },
    {
        "x": 61.84,
        "y": 0.998738966
    },
    {
        "x": 61.85,
        "y": 0.998738966
    },
    {
        "x": 61.86,
        "y": 0.998738966
    },
    {
        "x": 61.87,
        "y": 0.998738966
    },
    {
        "x": 61.88,
        "y": 0.998738966
    },
    {
        "x": 61.89,
        "y": 0.998738966
    },
    {
        "x": 61.9,
        "y": 0.998738966
    },
    {
        "x": 61.91,
        "y": 0.998738966
    },
    {
        "x": 61.92,
        "y": 0.998738966
    },
    {
        "x": 61.93,
        "y": 0.998738966
    },
    {
        "x": 61.94,
        "y": 0.998738966
    },
    {
        "x": 61.95,
        "y": 0.998738966
    },
    {
        "x": 61.96,
        "y": 0.998738966
    },
    {
        "x": 61.97,
        "y": 0.998738966
    },
    {
        "x": 61.98,
        "y": 0.998318621
    },
    {
        "x": 61.99,
        "y": 0.998738966
    },
    {
        "x": 62,
        "y": 0.998738966
    },
    {
        "x": 62.01,
        "y": 0.998738966
    },
    {
        "x": 62.02,
        "y": 0.998318621
    },
    {
        "x": 62.03,
        "y": 0.998738966
    },
    {
        "x": 62.04,
        "y": 0.998738966
    },
    {
        "x": 62.05,
        "y": 0.998738966
    },
    {
        "x": 62.06,
        "y": 0.998738966
    },
    {
        "x": 62.07,
        "y": 0.998738966
    },
    {
        "x": 62.08,
        "y": 0.998738966
    },
    {
        "x": 62.09,
        "y": 0.998318621
    },
    {
        "x": 62.1,
        "y": 0.998318621
    },
    {
        "x": 62.11,
        "y": 0.998738966
    },
    {
        "x": 62.12,
        "y": 0.998318621
    },
    {
        "x": 62.13,
        "y": 0.998738966
    },
    {
        "x": 62.14,
        "y": 0.998738966
    },
    {
        "x": 62.15,
        "y": 0.998318621
    },
    {
        "x": 62.16,
        "y": 0.998318621
    },
    {
        "x": 62.17,
        "y": 0.998738966
    },
    {
        "x": 62.18,
        "y": 0.998318621
    },
    {
        "x": 62.19,
        "y": 0.998318621
    },
    {
        "x": 62.2,
        "y": 0.998318621
    },
    {
        "x": 62.21,
        "y": 0.998318621
    },
    {
        "x": 62.22,
        "y": 0.998318621
    },
    {
        "x": 62.23,
        "y": 0.998318621
    },
    {
        "x": 62.24,
        "y": 0.998318621
    },
    {
        "x": 62.25,
        "y": 0.998318621
    },
    {
        "x": 62.26,
        "y": 0.998318621
    },
    {
        "x": 62.27,
        "y": 0.998318621
    },
    {
        "x": 62.28,
        "y": 0.998318621
    },
    {
        "x": 62.29,
        "y": 0.998318621
    },
    {
        "x": 62.3,
        "y": 0.998318621
    },
    {
        "x": 62.31,
        "y": 0.998318621
    },
    {
        "x": 62.32,
        "y": 0.998318621
    },
    {
        "x": 62.33,
        "y": 0.998318621
    },
    {
        "x": 62.34,
        "y": 0.998318621
    },
    {
        "x": 62.35,
        "y": 0.998318621
    },
    {
        "x": 62.36,
        "y": 0.998318621
    },
    {
        "x": 62.37,
        "y": 0.998738966
    },
    {
        "x": 62.38,
        "y": 0.998318621
    },
    {
        "x": 62.39,
        "y": 0.998318621
    },
    {
        "x": 62.4,
        "y": 0.998318621
    },
    {
        "x": 62.41,
        "y": 0.998318621
    },
    {
        "x": 62.42,
        "y": 0.998738966
    },
    {
        "x": 62.43,
        "y": 0.998318621
    },
    {
        "x": 62.44,
        "y": 0.998318621
    },
    {
        "x": 62.45,
        "y": 0.998318621
    },
    {
        "x": 62.46,
        "y": 0.998318621
    },
    {
        "x": 62.47,
        "y": 0.998318621
    },
    {
        "x": 62.48,
        "y": 0.998318621
    },
    {
        "x": 62.49,
        "y": 0.998318621
    },
    {
        "x": 62.5,
        "y": 0.998318621
    },
    {
        "x": 62.51,
        "y": 0.998318621
    },
    {
        "x": 62.52,
        "y": 0.998318621
    },
    {
        "x": 62.53,
        "y": 0.998318621
    },
    {
        "x": 62.54,
        "y": 0.998318621
    },
    {
        "x": 62.55,
        "y": 0.998318621
    },
    {
        "x": 62.56,
        "y": 0.998318621
    },
    {
        "x": 62.57,
        "y": 0.998318621
    },
    {
        "x": 62.58,
        "y": 0.998318621
    },
    {
        "x": 62.59,
        "y": 0.998318621
    },
    {
        "x": 62.6,
        "y": 0.998318621
    },
    {
        "x": 62.61,
        "y": 0.998318621
    },
    {
        "x": 62.62,
        "y": 0.998318621
    },
    {
        "x": 62.63,
        "y": 0.998318621
    },
    {
        "x": 62.64,
        "y": 0.998318621
    },
    {
        "x": 62.65,
        "y": 0.998318621
    },
    {
        "x": 62.66,
        "y": 0.998318621
    },
    {
        "x": 62.67,
        "y": 0.998318621
    },
    {
        "x": 62.68,
        "y": 0.998318621
    },
    {
        "x": 62.69,
        "y": 0.998318621
    },
    {
        "x": 62.7,
        "y": 0.998318621
    },
    {
        "x": 62.71,
        "y": 0.998318621
    },
    {
        "x": 62.72,
        "y": 0.998318621
    },
    {
        "x": 62.73,
        "y": 0.998318621
    },
    {
        "x": 62.74,
        "y": 0.998318621
    },
    {
        "x": 62.75,
        "y": 0.998318621
    },
    {
        "x": 62.76,
        "y": 0.998318621
    },
    {
        "x": 62.77,
        "y": 0.998318621
    },
    {
        "x": 62.78,
        "y": 0.998318621
    },
    {
        "x": 62.79,
        "y": 0.998318621
    },
    {
        "x": 62.8,
        "y": 0.998318621
    },
    {
        "x": 62.81,
        "y": 0.998318621
    },
    {
        "x": 62.82,
        "y": 0.998318621
    },
    {
        "x": 62.83,
        "y": 0.998318621
    },
    {
        "x": 62.84,
        "y": 0.998318621
    },
    {
        "x": 62.85,
        "y": 0.998318621
    },
    {
        "x": 62.86,
        "y": 0.998318621
    },
    {
        "x": 62.87,
        "y": 0.998318621
    },
    {
        "x": 62.88,
        "y": 0.998318621
    },
    {
        "x": 62.89,
        "y": 0.998318621
    },
    {
        "x": 62.9,
        "y": 0.998318621
    },
    {
        "x": 62.91,
        "y": 0.998318621
    },
    {
        "x": 62.92,
        "y": 0.998318621
    },
    {
        "x": 62.93,
        "y": 0.998318621
    },
    {
        "x": 62.94,
        "y": 0.998318621
    },
    {
        "x": 62.95,
        "y": 0.998318621
    },
    {
        "x": 62.96,
        "y": 0.998318621
    },
    {
        "x": 62.97,
        "y": 0.998318621
    },
    {
        "x": 62.98,
        "y": 0.998318621
    },
    {
        "x": 62.99,
        "y": 0.998318621
    },
    {
        "x": 63,
        "y": 0.998318621
    },
    {
        "x": 63.01,
        "y": 0.998318621
    },
    {
        "x": 63.02,
        "y": 0.998318621
    },
    {
        "x": 63.03,
        "y": 0.998318621
    },
    {
        "x": 63.04,
        "y": 0.998318621
    },
    {
        "x": 63.05,
        "y": 0.998318621
    },
    {
        "x": 63.06,
        "y": 0.998318621
    },
    {
        "x": 63.07,
        "y": 0.998318621
    },
    {
        "x": 63.08,
        "y": 0.998318621
    },
    {
        "x": 63.09,
        "y": 0.998318621
    },
    {
        "x": 63.1,
        "y": 0.998318621
    },
    {
        "x": 63.11,
        "y": 0.998318621
    },
    {
        "x": 63.12,
        "y": 0.998318621
    },
    {
        "x": 63.13,
        "y": 0.998318621
    },
    {
        "x": 63.14,
        "y": 0.998318621
    },
    {
        "x": 63.15,
        "y": 0.998318621
    },
    {
        "x": 63.16,
        "y": 0.998318621
    },
    {
        "x": 63.17,
        "y": 0.998318621
    },
    {
        "x": 63.18,
        "y": 0.998318621
    },
    {
        "x": 63.19,
        "y": 0.998318621
    },
    {
        "x": 63.2,
        "y": 0.998318621
    },
    {
        "x": 63.21,
        "y": 0.998318621
    },
    {
        "x": 63.22,
        "y": 0.998318621
    },
    {
        "x": 63.23,
        "y": 0.998318621
    },
    {
        "x": 63.24,
        "y": 0.998318621
    },
    {
        "x": 63.25,
        "y": 0.998318621
    },
    {
        "x": 63.26,
        "y": 0.998318621
    },
    {
        "x": 63.27,
        "y": 0.998318621
    },
    {
        "x": 63.28,
        "y": 0.998318621
    },
    {
        "x": 63.29,
        "y": 0.998318621
    },
    {
        "x": 63.3,
        "y": 0.998318621
    },
    {
        "x": 63.31,
        "y": 0.998318621
    },
    {
        "x": 63.32,
        "y": 0.998318621
    },
    {
        "x": 63.33,
        "y": 0.998318621
    },
    {
        "x": 63.34,
        "y": 0.998318621
    },
    {
        "x": 63.35,
        "y": 0.998318621
    },
    {
        "x": 63.36,
        "y": 0.998318621
    },
    {
        "x": 63.37,
        "y": 0.998318621
    },
    {
        "x": 63.38,
        "y": 0.998318621
    },
    {
        "x": 63.39,
        "y": 0.998318621
    },
    {
        "x": 63.4,
        "y": 0.998318621
    },
    {
        "x": 63.41,
        "y": 0.998318621
    },
    {
        "x": 63.42,
        "y": 0.998318621
    },
    {
        "x": 63.43,
        "y": 0.998318621
    },
    {
        "x": 63.44,
        "y": 0.998318621
    },
    {
        "x": 63.45,
        "y": 0.998318621
    },
    {
        "x": 63.46,
        "y": 0.998318621
    },
    {
        "x": 63.47,
        "y": 0.998318621
    },
    {
        "x": 63.48,
        "y": 0.998318621
    },
    {
        "x": 63.49,
        "y": 0.998318621
    },
    {
        "x": 63.5,
        "y": 0.998318621
    },
    {
        "x": 63.51,
        "y": 0.997898277
    },
    {
        "x": 63.52,
        "y": 0.998318621
    },
    {
        "x": 63.53,
        "y": 0.998318621
    },
    {
        "x": 63.54,
        "y": 0.998318621
    },
    {
        "x": 63.55,
        "y": 0.997898277
    },
    {
        "x": 63.56,
        "y": 0.998318621
    },
    {
        "x": 63.57,
        "y": 0.998318621
    },
    {
        "x": 63.58,
        "y": 0.998318621
    },
    {
        "x": 63.59,
        "y": 0.998318621
    },
    {
        "x": 63.6,
        "y": 0.998318621
    },
    {
        "x": 63.61,
        "y": 0.998318621
    },
    {
        "x": 63.62,
        "y": 0.998318621
    },
    {
        "x": 63.63,
        "y": 0.998318621
    },
    {
        "x": 63.64,
        "y": 0.998318621
    },
    {
        "x": 63.65,
        "y": 0.998318621
    },
    {
        "x": 63.66,
        "y": 0.998318621
    },
    {
        "x": 63.67,
        "y": 0.998318621
    },
    {
        "x": 63.68,
        "y": 0.998318621
    },
    {
        "x": 63.69,
        "y": 0.998318621
    },
    {
        "x": 63.7,
        "y": 0.998318621
    },
    {
        "x": 63.71,
        "y": 0.998318621
    },
    {
        "x": 63.72,
        "y": 0.998318621
    },
    {
        "x": 63.73,
        "y": 0.997898277
    },
    {
        "x": 63.74,
        "y": 0.998318621
    },
    {
        "x": 63.75,
        "y": 0.998318621
    },
    {
        "x": 63.76,
        "y": 0.997898277
    },
    {
        "x": 63.77,
        "y": 0.997898277
    },
    {
        "x": 63.78,
        "y": 0.997898277
    },
    {
        "x": 63.79,
        "y": 0.998318621
    },
    {
        "x": 63.8,
        "y": 0.998318621
    },
    {
        "x": 63.81,
        "y": 0.997898277
    },
    {
        "x": 63.82,
        "y": 0.998318621
    },
    {
        "x": 63.83,
        "y": 0.998318621
    },
    {
        "x": 63.84,
        "y": 0.998318621
    },
    {
        "x": 63.85,
        "y": 0.997898277
    },
    {
        "x": 63.86,
        "y": 0.997898277
    },
    {
        "x": 63.87,
        "y": 0.998318621
    },
    {
        "x": 63.88,
        "y": 0.998318621
    },
    {
        "x": 63.89,
        "y": 0.998318621
    },
    {
        "x": 63.9,
        "y": 0.998318621
    },
    {
        "x": 63.91,
        "y": 0.997898277
    },
    {
        "x": 63.92,
        "y": 0.998318621
    },
    {
        "x": 63.93,
        "y": 0.997898277
    },
    {
        "x": 63.94,
        "y": 0.997898277
    },
    {
        "x": 63.95,
        "y": 0.997898277
    },
    {
        "x": 63.96,
        "y": 0.997898277
    },
    {
        "x": 63.97,
        "y": 0.998318621
    },
    {
        "x": 63.98,
        "y": 0.998318621
    },
    {
        "x": 63.99,
        "y": 0.998318621
    },
    {
        "x": 64,
        "y": 0.997898277
    },
    {
        "x": 64.01,
        "y": 0.997898277
    },
    {
        "x": 64.02,
        "y": 0.998318621
    },
    {
        "x": 64.03,
        "y": 0.997898277
    },
    {
        "x": 64.04,
        "y": 0.997898277
    },
    {
        "x": 64.05,
        "y": 0.997898277
    },
    {
        "x": 64.06,
        "y": 0.997898277
    },
    {
        "x": 64.07,
        "y": 0.997898277
    },
    {
        "x": 64.08,
        "y": 0.998318621
    },
    {
        "x": 64.09,
        "y": 0.997898277
    },
    {
        "x": 64.1,
        "y": 0.998318621
    },
    {
        "x": 64.11,
        "y": 0.997898277
    },
    {
        "x": 64.12,
        "y": 0.998318621
    },
    {
        "x": 64.13,
        "y": 0.998318621
    },
    {
        "x": 64.14,
        "y": 0.998318621
    },
    {
        "x": 64.15,
        "y": 0.998318621
    },
    {
        "x": 64.16,
        "y": 0.997898277
    },
    {
        "x": 64.17,
        "y": 0.997898277
    },
    {
        "x": 64.18,
        "y": 0.998318621
    },
    {
        "x": 64.19,
        "y": 0.998318621
    },
    {
        "x": 64.2,
        "y": 0.998318621
    },
    {
        "x": 64.21,
        "y": 0.997898277
    },
    {
        "x": 64.22,
        "y": 0.998318621
    },
    {
        "x": 64.23,
        "y": 0.998318621
    },
    {
        "x": 64.24,
        "y": 0.998318621
    },
    {
        "x": 64.25,
        "y": 0.998318621
    },
    {
        "x": 64.26,
        "y": 0.998318621
    },
    {
        "x": 64.27,
        "y": 0.998318621
    },
    {
        "x": 64.28,
        "y": 0.998318621
    },
    {
        "x": 64.29,
        "y": 0.997898277
    },
    {
        "x": 64.3,
        "y": 0.998318621
    },
    {
        "x": 64.31,
        "y": 0.997898277
    },
    {
        "x": 64.32,
        "y": 0.997898277
    },
    {
        "x": 64.33,
        "y": 0.997898277
    },
    {
        "x": 64.34,
        "y": 0.997898277
    },
    {
        "x": 64.35,
        "y": 0.997898277
    },
    {
        "x": 64.36,
        "y": 0.997898277
    },
    {
        "x": 64.37,
        "y": 0.997898277
    },
    {
        "x": 64.38,
        "y": 0.997898277
    },
    {
        "x": 64.39,
        "y": 0.997898277
    },
    {
        "x": 64.4,
        "y": 0.997898277
    },
    {
        "x": 64.41,
        "y": 0.997898277
    },
    {
        "x": 64.42,
        "y": 0.997898277
    },
    {
        "x": 64.43,
        "y": 0.997898277
    },
    {
        "x": 64.44,
        "y": 0.997898277
    },
    {
        "x": 64.45,
        "y": 0.997898277
    },
    {
        "x": 64.46,
        "y": 0.997898277
    },
    {
        "x": 64.47,
        "y": 0.997898277
    },
    {
        "x": 64.48,
        "y": 0.997898277
    },
    {
        "x": 64.49,
        "y": 0.997898277
    },
    {
        "x": 64.5,
        "y": 0.997898277
    },
    {
        "x": 64.51,
        "y": 0.997898277
    },
    {
        "x": 64.52,
        "y": 0.997898277
    },
    {
        "x": 64.53,
        "y": 0.997898277
    },
    {
        "x": 64.54,
        "y": 0.997898277
    },
    {
        "x": 64.55,
        "y": 0.997898277
    },
    {
        "x": 64.56,
        "y": 0.997898277
    },
    {
        "x": 64.57,
        "y": 0.997898277
    },
    {
        "x": 64.58,
        "y": 0.997898277
    },
    {
        "x": 64.59,
        "y": 0.997898277
    },
    {
        "x": 64.6,
        "y": 0.997898277
    },
    {
        "x": 64.61,
        "y": 0.997898277
    },
    {
        "x": 64.62,
        "y": 0.997898277
    },
    {
        "x": 64.63,
        "y": 0.997898277
    },
    {
        "x": 64.64,
        "y": 0.997898277
    },
    {
        "x": 64.65,
        "y": 0.997898277
    },
    {
        "x": 64.66,
        "y": 0.998318621
    },
    {
        "x": 64.67,
        "y": 0.997898277
    },
    {
        "x": 64.68,
        "y": 0.997898277
    },
    {
        "x": 64.69,
        "y": 0.997898277
    },
    {
        "x": 64.7,
        "y": 0.997898277
    },
    {
        "x": 64.71,
        "y": 0.997898277
    },
    {
        "x": 64.72,
        "y": 0.997898277
    },
    {
        "x": 64.73,
        "y": 0.997898277
    },
    {
        "x": 64.74,
        "y": 0.997898277
    },
    {
        "x": 64.75,
        "y": 0.997898277
    },
    {
        "x": 64.76,
        "y": 0.997898277
    },
    {
        "x": 64.77,
        "y": 0.997898277
    },
    {
        "x": 64.78,
        "y": 0.997898277
    },
    {
        "x": 64.79,
        "y": 0.997898277
    },
    {
        "x": 64.8,
        "y": 0.997898277
    },
    {
        "x": 64.81,
        "y": 0.997898277
    },
    {
        "x": 64.82,
        "y": 0.997898277
    },
    {
        "x": 64.83,
        "y": 0.997898277
    },
    {
        "x": 64.84,
        "y": 0.997898277
    },
    {
        "x": 64.85,
        "y": 0.997898277
    },
    {
        "x": 64.86,
        "y": 0.997898277
    },
    {
        "x": 64.87,
        "y": 0.997898277
    },
    {
        "x": 64.88,
        "y": 0.997898277
    },
    {
        "x": 64.89,
        "y": 0.997898277
    },
    {
        "x": 64.9,
        "y": 0.997898277
    },
    {
        "x": 64.91,
        "y": 0.997898277
    },
    {
        "x": 64.92,
        "y": 0.997898277
    },
    {
        "x": 64.93,
        "y": 0.997898277
    },
    {
        "x": 64.94,
        "y": 0.997898277
    },
    {
        "x": 64.95,
        "y": 0.997898277
    },
    {
        "x": 64.96,
        "y": 0.997898277
    },
    {
        "x": 64.97,
        "y": 0.997898277
    },
    {
        "x": 64.98,
        "y": 0.997898277
    },
    {
        "x": 64.99,
        "y": 0.997898277
    },
    {
        "x": 65,
        "y": 0.997898277
    },
    {
        "x": 65.01,
        "y": 0.997898277
    },
    {
        "x": 65.02,
        "y": 0.997898277
    },
    {
        "x": 65.03,
        "y": 0.997898277
    },
    {
        "x": 65.04,
        "y": 0.997898277
    },
    {
        "x": 65.05,
        "y": 0.997898277
    },
    {
        "x": 65.06,
        "y": 0.997898277
    },
    {
        "x": 65.07,
        "y": 0.997898277
    },
    {
        "x": 65.08,
        "y": 0.997898277
    },
    {
        "x": 65.09,
        "y": 0.997898277
    },
    {
        "x": 65.1,
        "y": 0.997898277
    },
    {
        "x": 65.11,
        "y": 0.997898277
    },
    {
        "x": 65.12,
        "y": 0.997898277
    },
    {
        "x": 65.13,
        "y": 0.997477932
    },
    {
        "x": 65.14,
        "y": 0.997898277
    },
    {
        "x": 65.15,
        "y": 0.997898277
    },
    {
        "x": 65.16,
        "y": 0.997898277
    },
    {
        "x": 65.17,
        "y": 0.997898277
    },
    {
        "x": 65.18,
        "y": 0.997898277
    },
    {
        "x": 65.19,
        "y": 0.997898277
    },
    {
        "x": 65.2,
        "y": 0.997898277
    },
    {
        "x": 65.21,
        "y": 0.997898277
    },
    {
        "x": 65.22,
        "y": 0.997898277
    },
    {
        "x": 65.23,
        "y": 0.997898277
    },
    {
        "x": 65.24,
        "y": 0.997898277
    },
    {
        "x": 65.25,
        "y": 0.997898277
    },
    {
        "x": 65.26,
        "y": 0.997898277
    },
    {
        "x": 65.27,
        "y": 0.997898277
    },
    {
        "x": 65.28,
        "y": 0.997898277
    },
    {
        "x": 65.29,
        "y": 0.997898277
    },
    {
        "x": 65.3,
        "y": 0.997898277
    },
    {
        "x": 65.31,
        "y": 0.997898277
    },
    {
        "x": 65.32,
        "y": 0.997898277
    },
    {
        "x": 65.33,
        "y": 0.997898277
    },
    {
        "x": 65.34,
        "y": 0.997898277
    },
    {
        "x": 65.35,
        "y": 0.997898277
    },
    {
        "x": 65.36,
        "y": 0.997898277
    },
    {
        "x": 65.37,
        "y": 0.997898277
    },
    {
        "x": 65.38,
        "y": 0.997898277
    },
    {
        "x": 65.39,
        "y": 0.997898277
    },
    {
        "x": 65.4,
        "y": 0.997898277
    },
    {
        "x": 65.41,
        "y": 0.997898277
    },
    {
        "x": 65.42,
        "y": 0.997898277
    },
    {
        "x": 65.43,
        "y": 0.997898277
    },
    {
        "x": 65.44,
        "y": 0.997898277
    },
    {
        "x": 65.45,
        "y": 0.997898277
    },
    {
        "x": 65.46,
        "y": 0.997477932
    },
    {
        "x": 65.47,
        "y": 0.997898277
    },
    {
        "x": 65.48,
        "y": 0.997898277
    },
    {
        "x": 65.49,
        "y": 0.997898277
    },
    {
        "x": 65.5,
        "y": 0.997898277
    },
    {
        "x": 65.51,
        "y": 0.997898277
    },
    {
        "x": 65.52,
        "y": 0.997898277
    },
    {
        "x": 65.53,
        "y": 0.997898277
    },
    {
        "x": 65.54,
        "y": 0.997898277
    },
    {
        "x": 65.55,
        "y": 0.997898277
    },
    {
        "x": 65.56,
        "y": 0.997898277
    },
    {
        "x": 65.57,
        "y": 0.997898277
    },
    {
        "x": 65.58,
        "y": 0.997898277
    },
    {
        "x": 65.59,
        "y": 0.997898277
    },
    {
        "x": 65.6,
        "y": 0.997898277
    },
    {
        "x": 65.61,
        "y": 0.997898277
    },
    {
        "x": 65.62,
        "y": 0.997898277
    },
    {
        "x": 65.63,
        "y": 0.997898277
    },
    {
        "x": 65.64,
        "y": 0.997898277
    },
    {
        "x": 65.65,
        "y": 0.997898277
    },
    {
        "x": 65.66,
        "y": 0.997898277
    },
    {
        "x": 65.67,
        "y": 0.997898277
    },
    {
        "x": 65.68,
        "y": 0.997898277
    },
    {
        "x": 65.69,
        "y": 0.997898277
    },
    {
        "x": 65.7,
        "y": 0.997898277
    },
    {
        "x": 65.71,
        "y": 0.997898277
    },
    {
        "x": 65.72,
        "y": 0.997477932
    },
    {
        "x": 65.73,
        "y": 0.997898277
    },
    {
        "x": 65.74,
        "y": 0.997898277
    },
    {
        "x": 65.75,
        "y": 0.997898277
    },
    {
        "x": 65.76,
        "y": 0.997477932
    },
    {
        "x": 65.77,
        "y": 0.997898277
    },
    {
        "x": 65.78,
        "y": 0.997898277
    },
    {
        "x": 65.79,
        "y": 0.997898277
    },
    {
        "x": 65.8,
        "y": 0.997898277
    },
    {
        "x": 65.81,
        "y": 0.997477932
    },
    {
        "x": 65.82,
        "y": 0.997477932
    },
    {
        "x": 65.83,
        "y": 0.997898277
    },
    {
        "x": 65.84,
        "y": 0.997898277
    },
    {
        "x": 65.85,
        "y": 0.997898277
    },
    {
        "x": 65.86,
        "y": 0.997898277
    },
    {
        "x": 65.87,
        "y": 0.997477932
    },
    {
        "x": 65.88,
        "y": 0.997477932
    },
    {
        "x": 65.89,
        "y": 0.997898277
    },
    {
        "x": 65.9,
        "y": 0.997898277
    },
    {
        "x": 65.91,
        "y": 0.997477932
    },
    {
        "x": 65.92,
        "y": 0.997477932
    },
    {
        "x": 65.93,
        "y": 0.997898277
    },
    {
        "x": 65.94,
        "y": 0.997898277
    },
    {
        "x": 65.95,
        "y": 0.997898277
    },
    {
        "x": 65.96,
        "y": 0.997477932
    },
    {
        "x": 65.97,
        "y": 0.997898277
    },
    {
        "x": 65.98,
        "y": 0.997898277
    },
    {
        "x": 65.99,
        "y": 0.997477932
    },
    {
        "x": 66,
        "y": 0.997477932
    },
    {
        "x": 66.01,
        "y": 0.997898277
    },
    {
        "x": 66.02,
        "y": 0.997898277
    },
    {
        "x": 66.03,
        "y": 0.997898277
    },
    {
        "x": 66.04,
        "y": 0.997898277
    },
    {
        "x": 66.05,
        "y": 0.997898277
    },
    {
        "x": 66.06,
        "y": 0.997898277
    },
    {
        "x": 66.07,
        "y": 0.997477932
    },
    {
        "x": 66.08,
        "y": 0.997898277
    },
    {
        "x": 66.09,
        "y": 0.997898277
    },
    {
        "x": 66.1,
        "y": 0.997477932
    },
    {
        "x": 66.11,
        "y": 0.997477932
    },
    {
        "x": 66.12,
        "y": 0.997477932
    },
    {
        "x": 66.13,
        "y": 0.997477932
    },
    {
        "x": 66.14,
        "y": 0.997477932
    },
    {
        "x": 66.15,
        "y": 0.997477932
    },
    {
        "x": 66.16,
        "y": 0.997477932
    },
    {
        "x": 66.17,
        "y": 0.997477932
    },
    {
        "x": 66.18,
        "y": 0.997477932
    },
    {
        "x": 66.19,
        "y": 0.997477932
    },
    {
        "x": 66.2,
        "y": 0.997477932
    },
    {
        "x": 66.21,
        "y": 0.997477932
    },
    {
        "x": 66.22,
        "y": 0.997477932
    },
    {
        "x": 66.23,
        "y": 0.997477932
    },
    {
        "x": 66.24,
        "y": 0.997477932
    },
    {
        "x": 66.25,
        "y": 0.997477932
    },
    {
        "x": 66.26,
        "y": 0.997477932
    },
    {
        "x": 66.27,
        "y": 0.997477932
    },
    {
        "x": 66.28,
        "y": 0.997477932
    },
    {
        "x": 66.29,
        "y": 0.997477932
    },
    {
        "x": 66.3,
        "y": 0.997477932
    },
    {
        "x": 66.31,
        "y": 0.997477932
    },
    {
        "x": 66.32,
        "y": 0.997477932
    },
    {
        "x": 66.33,
        "y": 0.997477932
    },
    {
        "x": 66.34,
        "y": 0.997477932
    },
    {
        "x": 66.35,
        "y": 0.997477932
    },
    {
        "x": 66.36,
        "y": 0.997477932
    },
    {
        "x": 66.37,
        "y": 0.997477932
    },
    {
        "x": 66.38,
        "y": 0.997477932
    },
    {
        "x": 66.39,
        "y": 0.997477932
    },
    {
        "x": 66.4,
        "y": 0.997477932
    },
    {
        "x": 66.41,
        "y": 0.997477932
    },
    {
        "x": 66.42,
        "y": 0.997477932
    },
    {
        "x": 66.43,
        "y": 0.997477932
    },
    {
        "x": 66.44,
        "y": 0.997477932
    },
    {
        "x": 66.45,
        "y": 0.997477932
    },
    {
        "x": 66.46,
        "y": 0.997477932
    },
    {
        "x": 66.47,
        "y": 0.997477932
    },
    {
        "x": 66.48,
        "y": 0.997477932
    },
    {
        "x": 66.49,
        "y": 0.997477932
    },
    {
        "x": 66.5,
        "y": 0.997477932
    },
    {
        "x": 66.51,
        "y": 0.997477932
    },
    {
        "x": 66.52,
        "y": 0.997477932
    },
    {
        "x": 66.53,
        "y": 0.997477932
    },
    {
        "x": 66.54,
        "y": 0.997477932
    },
    {
        "x": 66.55,
        "y": 0.997477932
    },
    {
        "x": 66.56,
        "y": 0.997477932
    },
    {
        "x": 66.57,
        "y": 0.997477932
    },
    {
        "x": 66.58,
        "y": 0.997477932
    },
    {
        "x": 66.59,
        "y": 0.997477932
    },
    {
        "x": 66.6,
        "y": 0.997477932
    },
    {
        "x": 66.61,
        "y": 0.997477932
    },
    {
        "x": 66.62,
        "y": 0.997477932
    },
    {
        "x": 66.63,
        "y": 0.997477932
    },
    {
        "x": 66.64,
        "y": 0.997477932
    },
    {
        "x": 66.65,
        "y": 0.997477932
    },
    {
        "x": 66.66,
        "y": 0.997477932
    },
    {
        "x": 66.67,
        "y": 0.997477932
    },
    {
        "x": 66.68,
        "y": 0.997477932
    },
    {
        "x": 66.69,
        "y": 0.997477932
    },
    {
        "x": 66.7,
        "y": 0.997477932
    },
    {
        "x": 66.71,
        "y": 0.997477932
    },
    {
        "x": 66.72,
        "y": 0.997477932
    },
    {
        "x": 66.73,
        "y": 0.997477932
    },
    {
        "x": 66.74,
        "y": 0.997477932
    },
    {
        "x": 66.75,
        "y": 0.997477932
    },
    {
        "x": 66.76,
        "y": 0.997477932
    },
    {
        "x": 66.77,
        "y": 0.997477932
    },
    {
        "x": 66.78,
        "y": 0.997477932
    },
    {
        "x": 66.79,
        "y": 0.997477932
    },
    {
        "x": 66.8,
        "y": 0.997477932
    },
    {
        "x": 66.81,
        "y": 0.997477932
    },
    {
        "x": 66.82,
        "y": 0.997477932
    },
    {
        "x": 66.83,
        "y": 0.997477932
    },
    {
        "x": 66.84,
        "y": 0.997477932
    },
    {
        "x": 66.85,
        "y": 0.997477932
    },
    {
        "x": 66.86,
        "y": 0.997477932
    },
    {
        "x": 66.87,
        "y": 0.997477932
    },
    {
        "x": 66.88,
        "y": 0.997477932
    },
    {
        "x": 66.89,
        "y": 0.997477932
    },
    {
        "x": 66.9,
        "y": 0.997477932
    },
    {
        "x": 66.91,
        "y": 0.997477932
    },
    {
        "x": 66.92,
        "y": 0.997477932
    },
    {
        "x": 66.93,
        "y": 0.997477932
    },
    {
        "x": 66.94,
        "y": 0.997477932
    },
    {
        "x": 66.95,
        "y": 0.997477932
    },
    {
        "x": 66.96,
        "y": 0.997477932
    },
    {
        "x": 66.97,
        "y": 0.997477932
    },
    {
        "x": 66.98,
        "y": 0.997477932
    },
    {
        "x": 66.99,
        "y": 0.997477932
    },
    {
        "x": 67,
        "y": 0.997477932
    },
    {
        "x": 67.01,
        "y": 0.997477932
    },
    {
        "x": 67.02,
        "y": 0.997477932
    },
    {
        "x": 67.03,
        "y": 0.997477932
    },
    {
        "x": 67.04,
        "y": 0.997477932
    },
    {
        "x": 67.05,
        "y": 0.997477932
    },
    {
        "x": 67.06,
        "y": 0.997477932
    },
    {
        "x": 67.07,
        "y": 0.997477932
    },
    {
        "x": 67.08,
        "y": 0.997477932
    },
    {
        "x": 67.09,
        "y": 0.997477932
    },
    {
        "x": 67.1,
        "y": 0.997477932
    },
    {
        "x": 67.11,
        "y": 0.997477932
    },
    {
        "x": 67.12,
        "y": 0.997477932
    },
    {
        "x": 67.13,
        "y": 0.997477932
    },
    {
        "x": 67.14,
        "y": 0.997477932
    },
    {
        "x": 67.15,
        "y": 0.997477932
    },
    {
        "x": 67.16,
        "y": 0.997477932
    },
    {
        "x": 67.17,
        "y": 0.997477932
    },
    {
        "x": 67.18,
        "y": 0.997477932
    },
    {
        "x": 67.19,
        "y": 0.997477932
    },
    {
        "x": 67.2,
        "y": 0.997057587
    },
    {
        "x": 67.21,
        "y": 0.997477932
    },
    {
        "x": 67.22,
        "y": 0.997477932
    },
    {
        "x": 67.23,
        "y": 0.997477932
    },
    {
        "x": 67.24,
        "y": 0.997477932
    },
    {
        "x": 67.25,
        "y": 0.997477932
    },
    {
        "x": 67.26,
        "y": 0.997477932
    },
    {
        "x": 67.27,
        "y": 0.997477932
    },
    {
        "x": 67.28,
        "y": 0.997057587
    },
    {
        "x": 67.29,
        "y": 0.997477932
    },
    {
        "x": 67.3,
        "y": 0.997477932
    },
    {
        "x": 67.31,
        "y": 0.997477932
    },
    {
        "x": 67.32,
        "y": 0.997477932
    },
    {
        "x": 67.33,
        "y": 0.997477932
    },
    {
        "x": 67.34,
        "y": 0.997477932
    },
    {
        "x": 67.35,
        "y": 0.997057587
    },
    {
        "x": 67.36,
        "y": 0.997477932
    },
    {
        "x": 67.37,
        "y": 0.997057587
    },
    {
        "x": 67.38,
        "y": 0.997057587
    },
    {
        "x": 67.39,
        "y": 0.997477932
    },
    {
        "x": 67.4,
        "y": 0.997057587
    },
    {
        "x": 67.41,
        "y": 0.997477932
    },
    {
        "x": 67.42,
        "y": 0.997477932
    },
    {
        "x": 67.43,
        "y": 0.997477932
    },
    {
        "x": 67.44,
        "y": 0.997477932
    },
    {
        "x": 67.45,
        "y": 0.997477932
    },
    {
        "x": 67.46,
        "y": 0.997057587
    },
    {
        "x": 67.47,
        "y": 0.997477932
    },
    {
        "x": 67.48,
        "y": 0.997477932
    },
    {
        "x": 67.49,
        "y": 0.997477932
    },
    {
        "x": 67.5,
        "y": 0.997477932
    },
    {
        "x": 67.51,
        "y": 0.997477932
    },
    {
        "x": 67.52,
        "y": 0.997057587
    },
    {
        "x": 67.53,
        "y": 0.997057587
    },
    {
        "x": 67.54,
        "y": 0.997477932
    },
    {
        "x": 67.55,
        "y": 0.997057587
    },
    {
        "x": 67.56,
        "y": 0.997477932
    },
    {
        "x": 67.57,
        "y": 0.997477932
    },
    {
        "x": 67.58,
        "y": 0.997477932
    },
    {
        "x": 67.59,
        "y": 0.997477932
    },
    {
        "x": 67.6,
        "y": 0.997057587
    },
    {
        "x": 67.61,
        "y": 0.997057587
    },
    {
        "x": 67.62,
        "y": 0.997477932
    },
    {
        "x": 67.63,
        "y": 0.997057587
    },
    {
        "x": 67.64,
        "y": 0.997477932
    },
    {
        "x": 67.65,
        "y": 0.997477932
    },
    {
        "x": 67.66,
        "y": 0.997477932
    },
    {
        "x": 67.67,
        "y": 0.997057587
    },
    {
        "x": 67.68,
        "y": 0.997477932
    },
    {
        "x": 67.69,
        "y": 0.997477932
    },
    {
        "x": 67.7,
        "y": 0.997057587
    },
    {
        "x": 67.71,
        "y": 0.997477932
    },
    {
        "x": 67.72,
        "y": 0.997477932
    },
    {
        "x": 67.73,
        "y": 0.997477932
    },
    {
        "x": 67.74,
        "y": 0.997477932
    },
    {
        "x": 67.75,
        "y": 0.997057587
    },
    {
        "x": 67.76,
        "y": 0.997057587
    },
    {
        "x": 67.77,
        "y": 0.997477932
    },
    {
        "x": 67.78,
        "y": 0.997477932
    },
    {
        "x": 67.79,
        "y": 0.997477932
    },
    {
        "x": 67.8,
        "y": 0.997057587
    },
    {
        "x": 67.81,
        "y": 0.997477932
    },
    {
        "x": 67.82,
        "y": 0.997477932
    },
    {
        "x": 67.83,
        "y": 0.997057587
    },
    {
        "x": 67.84,
        "y": 0.997477932
    },
    {
        "x": 67.85,
        "y": 0.997057587
    },
    {
        "x": 67.86,
        "y": 0.997057587
    },
    {
        "x": 67.87,
        "y": 0.997477932
    },
    {
        "x": 67.88,
        "y": 0.997057587
    },
    {
        "x": 67.89,
        "y": 0.997057587
    },
    {
        "x": 67.9,
        "y": 0.997057587
    },
    {
        "x": 67.91,
        "y": 0.997057587
    },
    {
        "x": 67.92,
        "y": 0.997057587
    },
    {
        "x": 67.93,
        "y": 0.997057587
    },
    {
        "x": 67.94,
        "y": 0.997057587
    },
    {
        "x": 67.95,
        "y": 0.997057587
    },
    {
        "x": 67.96,
        "y": 0.997057587
    },
    {
        "x": 67.97,
        "y": 0.997057587
    },
    {
        "x": 67.98,
        "y": 0.997057587
    },
    {
        "x": 67.99,
        "y": 0.997057587
    },
    {
        "x": 68,
        "y": 0.997057587
    },
    {
        "x": 68.01,
        "y": 0.997057587
    },
    {
        "x": 68.02,
        "y": 0.997057587
    },
    {
        "x": 68.03,
        "y": 0.997057587
    },
    {
        "x": 68.04,
        "y": 0.997057587
    },
    {
        "x": 68.05,
        "y": 0.997057587
    },
    {
        "x": 68.06,
        "y": 0.997057587
    },
    {
        "x": 68.07,
        "y": 0.997057587
    },
    {
        "x": 68.08,
        "y": 0.997057587
    },
    {
        "x": 68.09,
        "y": 0.997057587
    },
    {
        "x": 68.1,
        "y": 0.997057587
    },
    {
        "x": 68.11,
        "y": 0.997057587
    },
    {
        "x": 68.12,
        "y": 0.997057587
    },
    {
        "x": 68.13,
        "y": 0.997057587
    },
    {
        "x": 68.14,
        "y": 0.997057587
    },
    {
        "x": 68.15,
        "y": 0.997057587
    },
    {
        "x": 68.16,
        "y": 0.997057587
    },
    {
        "x": 68.17,
        "y": 0.997477932
    },
    {
        "x": 68.18,
        "y": 0.997057587
    },
    {
        "x": 68.19,
        "y": 0.997057587
    },
    {
        "x": 68.2,
        "y": 0.997057587
    },
    {
        "x": 68.21,
        "y": 0.997057587
    },
    {
        "x": 68.22,
        "y": 0.997057587
    },
    {
        "x": 68.23,
        "y": 0.997057587
    },
    {
        "x": 68.24,
        "y": 0.997057587
    },
    {
        "x": 68.25,
        "y": 0.997057587
    },
    {
        "x": 68.26,
        "y": 0.997057587
    },
    {
        "x": 68.27,
        "y": 0.997057587
    },
    {
        "x": 68.28,
        "y": 0.997057587
    },
    {
        "x": 68.29,
        "y": 0.997057587
    },
    {
        "x": 68.3,
        "y": 0.997057587
    },
    {
        "x": 68.31,
        "y": 0.997057587
    },
    {
        "x": 68.32,
        "y": 0.997057587
    },
    {
        "x": 68.33,
        "y": 0.997057587
    },
    {
        "x": 68.34,
        "y": 0.997057587
    },
    {
        "x": 68.35,
        "y": 0.997057587
    },
    {
        "x": 68.36,
        "y": 0.997057587
    },
    {
        "x": 68.37,
        "y": 0.997057587
    },
    {
        "x": 68.38,
        "y": 0.997057587
    },
    {
        "x": 68.39,
        "y": 0.997057587
    },
    {
        "x": 68.4,
        "y": 0.997057587
    },
    {
        "x": 68.41,
        "y": 0.997057587
    },
    {
        "x": 68.42,
        "y": 0.997057587
    },
    {
        "x": 68.43,
        "y": 0.997057587
    },
    {
        "x": 68.44,
        "y": 0.997057587
    },
    {
        "x": 68.45,
        "y": 0.997057587
    },
    {
        "x": 68.46,
        "y": 0.997057587
    },
    {
        "x": 68.47,
        "y": 0.997057587
    },
    {
        "x": 68.48,
        "y": 0.997057587
    },
    {
        "x": 68.49,
        "y": 0.997057587
    },
    {
        "x": 68.5,
        "y": 0.997057587
    },
    {
        "x": 68.51,
        "y": 0.997057587
    },
    {
        "x": 68.52,
        "y": 0.997057587
    },
    {
        "x": 68.53,
        "y": 0.997057587
    },
    {
        "x": 68.54,
        "y": 0.997057587
    },
    {
        "x": 68.55,
        "y": 0.997057587
    },
    {
        "x": 68.56,
        "y": 0.997057587
    },
    {
        "x": 68.57,
        "y": 0.997057587
    },
    {
        "x": 68.58,
        "y": 0.997057587
    },
    {
        "x": 68.59,
        "y": 0.997057587
    },
    {
        "x": 68.6,
        "y": 0.997057587
    },
    {
        "x": 68.61,
        "y": 0.997057587
    },
    {
        "x": 68.62,
        "y": 0.997057587
    },
    {
        "x": 68.63,
        "y": 0.997057587
    },
    {
        "x": 68.64,
        "y": 0.997057587
    },
    {
        "x": 68.65,
        "y": 0.997057587
    },
    {
        "x": 68.66,
        "y": 0.997057587
    },
    {
        "x": 68.67,
        "y": 0.997057587
    },
    {
        "x": 68.68,
        "y": 0.997057587
    },
    {
        "x": 68.69,
        "y": 0.997057587
    },
    {
        "x": 68.7,
        "y": 0.997057587
    },
    {
        "x": 68.71,
        "y": 0.997057587
    },
    {
        "x": 68.72,
        "y": 0.997057587
    },
    {
        "x": 68.73,
        "y": 0.997057587
    },
    {
        "x": 68.74,
        "y": 0.997057587
    },
    {
        "x": 68.75,
        "y": 0.997057587
    },
    {
        "x": 68.76,
        "y": 0.997057587
    },
    {
        "x": 68.77,
        "y": 0.997057587
    },
    {
        "x": 68.78,
        "y": 0.997057587
    },
    {
        "x": 68.79,
        "y": 0.997057587
    },
    {
        "x": 68.8,
        "y": 0.996637243
    },
    {
        "x": 68.81,
        "y": 0.997057587
    },
    {
        "x": 68.82,
        "y": 0.997057587
    },
    {
        "x": 68.83,
        "y": 0.997057587
    },
    {
        "x": 68.84,
        "y": 0.997057587
    },
    {
        "x": 68.85,
        "y": 0.997057587
    },
    {
        "x": 68.86,
        "y": 0.997057587
    },
    {
        "x": 68.87,
        "y": 0.997057587
    },
    {
        "x": 68.88,
        "y": 0.997057587
    },
    {
        "x": 68.89,
        "y": 0.997057587
    },
    {
        "x": 68.9,
        "y": 0.997057587
    },
    {
        "x": 68.91,
        "y": 0.997057587
    },
    {
        "x": 68.92,
        "y": 0.997057587
    },
    {
        "x": 68.93,
        "y": 0.997057587
    },
    {
        "x": 68.94,
        "y": 0.997057587
    },
    {
        "x": 68.95,
        "y": 0.997057587
    },
    {
        "x": 68.96,
        "y": 0.997057587
    },
    {
        "x": 68.97,
        "y": 0.997057587
    },
    {
        "x": 68.98,
        "y": 0.997057587
    },
    {
        "x": 68.99,
        "y": 0.996637243
    },
    {
        "x": 69,
        "y": 0.997057587
    },
    {
        "x": 69.01,
        "y": 0.997057587
    },
    {
        "x": 69.02,
        "y": 0.997057587
    },
    {
        "x": 69.03,
        "y": 0.997057587
    },
    {
        "x": 69.04,
        "y": 0.997057587
    },
    {
        "x": 69.05,
        "y": 0.997057587
    },
    {
        "x": 69.06,
        "y": 0.997057587
    },
    {
        "x": 69.07,
        "y": 0.997057587
    },
    {
        "x": 69.08,
        "y": 0.997057587
    },
    {
        "x": 69.09,
        "y": 0.996637243
    },
    {
        "x": 69.1,
        "y": 0.997057587
    },
    {
        "x": 69.11,
        "y": 0.997057587
    },
    {
        "x": 69.12,
        "y": 0.997057587
    },
    {
        "x": 69.13,
        "y": 0.997057587
    },
    {
        "x": 69.14,
        "y": 0.997057587
    },
    {
        "x": 69.15,
        "y": 0.997057587
    },
    {
        "x": 69.16,
        "y": 0.997057587
    },
    {
        "x": 69.17,
        "y": 0.997057587
    },
    {
        "x": 69.18,
        "y": 0.996637243
    },
    {
        "x": 69.19,
        "y": 0.997057587
    },
    {
        "x": 69.2,
        "y": 0.997057587
    },
    {
        "x": 69.21,
        "y": 0.997057587
    },
    {
        "x": 69.22,
        "y": 0.997057587
    },
    {
        "x": 69.23,
        "y": 0.997057587
    },
    {
        "x": 69.24,
        "y": 0.997057587
    },
    {
        "x": 69.25,
        "y": 0.997057587
    },
    {
        "x": 69.26,
        "y": 0.997057587
    },
    {
        "x": 69.27,
        "y": 0.997057587
    },
    {
        "x": 69.28,
        "y": 0.997057587
    },
    {
        "x": 69.29,
        "y": 0.996637243
    },
    {
        "x": 69.3,
        "y": 0.997057587
    },
    {
        "x": 69.31,
        "y": 0.996637243
    },
    {
        "x": 69.32,
        "y": 0.996637243
    },
    {
        "x": 69.33,
        "y": 0.996637243
    },
    {
        "x": 69.34,
        "y": 0.997057587
    },
    {
        "x": 69.35,
        "y": 0.997057587
    },
    {
        "x": 69.36,
        "y": 0.996637243
    },
    {
        "x": 69.37,
        "y": 0.997057587
    },
    {
        "x": 69.38,
        "y": 0.997057587
    },
    {
        "x": 69.39,
        "y": 0.997057587
    },
    {
        "x": 69.4,
        "y": 0.997057587
    },
    {
        "x": 69.41,
        "y": 0.997057587
    },
    {
        "x": 69.42,
        "y": 0.996637243
    },
    {
        "x": 69.43,
        "y": 0.996637243
    },
    {
        "x": 69.44,
        "y": 0.997057587
    },
    {
        "x": 69.45,
        "y": 0.996637243
    },
    {
        "x": 69.46,
        "y": 0.997057587
    },
    {
        "x": 69.47,
        "y": 0.996637243
    },
    {
        "x": 69.48,
        "y": 0.996637243
    },
    {
        "x": 69.49,
        "y": 0.996637243
    },
    {
        "x": 69.5,
        "y": 0.996637243
    },
    {
        "x": 69.51,
        "y": 0.996637243
    },
    {
        "x": 69.52,
        "y": 0.996637243
    },
    {
        "x": 69.53,
        "y": 0.996637243
    },
    {
        "x": 69.54,
        "y": 0.996637243
    },
    {
        "x": 69.55,
        "y": 0.996637243
    },
    {
        "x": 69.56,
        "y": 0.996637243
    },
    {
        "x": 69.57,
        "y": 0.996637243
    },
    {
        "x": 69.58,
        "y": 0.996637243
    },
    {
        "x": 69.59,
        "y": 0.996637243
    },
    {
        "x": 69.6,
        "y": 0.996637243
    },
    {
        "x": 69.61,
        "y": 0.996637243
    },
    {
        "x": 69.62,
        "y": 0.997057587
    },
    {
        "x": 69.63,
        "y": 0.996637243
    },
    {
        "x": 69.64,
        "y": 0.996637243
    },
    {
        "x": 69.65,
        "y": 0.996637243
    },
    {
        "x": 69.66,
        "y": 0.996637243
    },
    {
        "x": 69.67,
        "y": 0.996637243
    },
    {
        "x": 69.68,
        "y": 0.996637243
    },
    {
        "x": 69.69,
        "y": 0.997057587
    },
    {
        "x": 69.7,
        "y": 0.996637243
    },
    {
        "x": 69.71,
        "y": 0.996637243
    },
    {
        "x": 69.72,
        "y": 0.996637243
    },
    {
        "x": 69.73,
        "y": 0.996637243
    },
    {
        "x": 69.74,
        "y": 0.996637243
    },
    {
        "x": 69.75,
        "y": 0.996637243
    },
    {
        "x": 69.76,
        "y": 0.996637243
    },
    {
        "x": 69.77,
        "y": 0.996637243
    },
    {
        "x": 69.78,
        "y": 0.996637243
    },
    {
        "x": 69.79,
        "y": 0.996637243
    },
    {
        "x": 69.8,
        "y": 0.996637243
    },
    {
        "x": 69.81,
        "y": 0.996637243
    },
    {
        "x": 69.82,
        "y": 0.996637243
    },
    {
        "x": 69.83,
        "y": 0.996637243
    },
    {
        "x": 69.84,
        "y": 0.996637243
    },
    {
        "x": 69.85,
        "y": 0.996637243
    },
    {
        "x": 69.86,
        "y": 0.996637243
    },
    {
        "x": 69.87,
        "y": 0.996637243
    },
    {
        "x": 69.88,
        "y": 0.996637243
    },
    {
        "x": 69.89,
        "y": 0.996637243
    },
    {
        "x": 69.9,
        "y": 0.996637243
    },
    {
        "x": 69.91,
        "y": 0.996637243
    },
    {
        "x": 69.92,
        "y": 0.996637243
    },
    {
        "x": 69.93,
        "y": 0.996637243
    },
    {
        "x": 69.94,
        "y": 0.996637243
    },
    {
        "x": 69.95,
        "y": 0.996637243
    },
    {
        "x": 69.96,
        "y": 0.996637243
    },
    {
        "x": 69.97,
        "y": 0.996637243
    },
    {
        "x": 69.98,
        "y": 0.996637243
    },
    {
        "x": 69.99,
        "y": 0.996637243
    },
    {
        "x": 70,
        "y": 0.997057587
    },
    {
        "x": 70.01,
        "y": 0.996637243
    },
    {
        "x": 70.02,
        "y": 0.997057587
    },
    {
        "x": 70.03,
        "y": 0.997057587
    },
    {
        "x": 70.04,
        "y": 0.997057587
    },
    {
        "x": 70.05,
        "y": 0.997057587
    },
    {
        "x": 70.06,
        "y": 0.997057587
    },
    {
        "x": 70.07,
        "y": 0.997057587
    },
    {
        "x": 70.08,
        "y": 0.997057587
    },
    {
        "x": 70.09,
        "y": 0.997057587
    },
    {
        "x": 70.1,
        "y": 0.997057587
    },
    {
        "x": 70.11,
        "y": 0.997057587
    },
    {
        "x": 70.12,
        "y": 0.996637243
    },
    {
        "x": 70.13,
        "y": 0.997057587
    },
    {
        "x": 70.14,
        "y": 0.997057587
    },
    {
        "x": 70.15,
        "y": 0.997057587
    },
    {
        "x": 70.16,
        "y": 0.997057587
    },
    {
        "x": 70.17,
        "y": 0.997057587
    },
    {
        "x": 70.18,
        "y": 0.996637243
    },
    {
        "x": 70.19,
        "y": 0.997057587
    },
    {
        "x": 70.2,
        "y": 0.996637243
    },
    {
        "x": 70.21,
        "y": 0.996637243
    },
    {
        "x": 70.22,
        "y": 0.996637243
    },
    {
        "x": 70.23,
        "y": 0.996637243
    },
    {
        "x": 70.24,
        "y": 0.997057587
    },
    {
        "x": 70.25,
        "y": 0.996637243
    },
    {
        "x": 70.26,
        "y": 0.996637243
    },
    {
        "x": 70.27,
        "y": 0.996637243
    },
    {
        "x": 70.28,
        "y": 0.996637243
    },
    {
        "x": 70.29,
        "y": 0.996637243
    },
    {
        "x": 70.3,
        "y": 0.996637243
    },
    {
        "x": 70.31,
        "y": 0.996637243
    },
    {
        "x": 70.32,
        "y": 0.996637243
    },
    {
        "x": 70.33,
        "y": 0.996637243
    }
];

export const ConversionAIDataPoints = [
    {
        "x": 5.7,
        "y": 0.049600673
    },
    {
        "x": 6.71,
        "y": 0.09962169
    },
    {
        "x": 8.33,
        "y": 0.249264397
    },
    {
        "x": 10.35,
        "y": 0.499789828
    },
    {
        "x": 20.31,
        "y": 0.899957966
    },
    {
        "x": 25.45,
        "y": 0.949978983
    },
    {
        "x": 53.21,
        "y": 1
    }
];
