import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import * as ROLES from "../../constants/roles";

import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";
import {Button} from "@material-ui/core";
import {CloudUpload} from "@material-ui/icons";
import MaterialDrawer from "./SliderFilter";
import SwipeableTemporaryDrawer from "../Recipe/drawer";

class CommodityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      commodities: [],
      columns: [
        { title: "ID", field: "_id", filtering: false },
        { title: "Name", field: "rawmaterialName", filtering: false },
        { title: "Group", field: "rawmaterialGroup", filtering: false },
        { title: "Class", field: "rawmaterialClass", filtering: false },
        { title: "Density", field: "rawmaterialDensity", filtering: false },
      ],
    };
    this.onClickUpload = this.onClickUpload.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.rawmaterials().on("value", (snapshot) => {
      const commoditiesObject = snapshot.val();

      const commoditiesList = Object.keys(commoditiesObject).map((key) => ({
        ...commoditiesObject[key],
        uid: key,
      }));

      this.setState({
        commodities: commoditiesList,
        loading: false,
      });
    });
  }

  onClickUpload() {
    this.props.history.push('/upload/upload-rawmaterial')
  }

  componentWillUnmount() {
    this.props.firebase.rawmaterials().off();
  }

  render() {
    const { commodities, loading, columns } = this.state;

    return (
      <div>
        {loading && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              display: "block",
              backgroundColor: "darkgrey",
              padding: "5px 10px",
            }}
          >
            Loading ...
          </div>
        )}

        <CommoditiesList columns={columns} commodities={commodities} onClickUpload={this.onClickUpload} />
      </div>
    );
  }
}

class CommoditiesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValueSlider5: [0, 100],
      slider5: [0, 100],
      data: this.props.commodities,
      dataCC: this.props.commodities,
      checklistValClass: [],
      checklistValGroup: [],
    }
    this.getGroupNames = this.getGroupNames.bind(this);
    this.onChangeSlider = this.onChangeSlider.bind(this);
    this.onChangeClass = this.onChangeClass.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.commodities !== prevProps.commodities && this.props.commodities) {
      this.setState({data: this.props.commodities, dataCC: this.props.commodities}, () => this.getGroupNames());
    }
  }

  getGroupNames() {
    let classStuff = [];
    let rawmaterialGroup = [];
    let density = [];

    this.state.dataCC.map((item) => {
      if(!classStuff.includes(item.rawmaterialClass)) {
        classStuff.push(item.rawmaterialClass);
      }
      if(!rawmaterialGroup.includes(item.rawmaterialGroup)) {
        rawmaterialGroup.push(item.rawmaterialGroup);
      }
      item.rawmaterialDensity ? density.push(item.rawmaterialDensity) : void 0;
    });
    let sortedD = density.sort();
    this.setState({checklistValClass: classStuff, checklistValGroup: rawmaterialGroup, slider5: [sortedD[0], sortedD[sortedD.length - 1]], initialValueSlider5: [sortedD[0], sortedD[sortedD.length - 1]]});
  }

  onChangeSlider(event, newVal) {
    this.setState({slider5: newVal});
    let data = this.state.dataCC.slice();
    let filteredData = [];
    data.map((item) => {
      if(item.rawmaterialDensity >= newVal[0] && item.rawmaterialDensity <= newVal[1]) {
        filteredData.push(item);
      }
    });
    this.setState({data: filteredData});
  }

  onChangeClass(e) {
    let arr = [];
    if(e.length > 0) {
      e.map((item) => {
        this.state.dataCC.map((item1) => {
          if(item === item1.rawmaterialClass) {
            arr.push(item1);
          }
        })
      });
      this.setState({data: arr});
    } else {
      this.setState({data: this.state.dataCC});
    }
  }
  onChangeGroup(e) {
    let arr = [];
    if(e.length > 0) {
      e.map((item) => {
        this.state.dataCC.map((item1) => {
          if(item === item1.rawmaterialGroup) {
            arr.push(item1);
          }
        })
      });
      this.setState({data: arr});
    } else {
      this.setState({data: this.state.dataCC});
    }
  }

  render() {
    return (
        <React.Fragment>
          <MaterialDrawer
              initialValueSlider5={this.state.initialValueSlider5}
              slider5Val={this.state.slider5}
              onChangeSlider5={this.onChangeSlider}
              groupChecklist={this.state.checklistValGroup}
              classChecklist={this.state.checklistValClass}
              onChangeClass={this.onChangeClass}
              onChangeGroup={this.onChangeGroup}
          />
          <Container maxWidth="lg">
            <MaterialTable
                title="Material Database"
                columns={this.props.columns}
                data={this.state.data}
                options={{
                  exportButton: true,
                  pageSize: 10,
                }}
                localization={{
                  pagination: {
                    labelRowsSelect: "entries",
                  },
                  toolbar: {
                    searchTooltip: "Search Materials",
                    searchPlaceholder: "Search Materials",
                  },
                }}
                actions={[
                  {
                    icon: 'backup',
                    tooltip: "Upload commodities",
                    position: "toolbar",
                    onClick: () => {
                      this.props.onClickUpload()
                    }
                  }
                ]}
            />
          </Container>
        </React.Fragment>
    )
  }
}


export default compose(withFirebase)(CommodityPage);
