import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

function msToMinSec(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
}

class DisplayWalze extends Component {
  render() {
    const { production } = this.props;
    let rollerRowValue = [];

    if (production.internalMixer !== undefined) {
      let productionArray = production.roller;

      for (let i = 0; i < productionArray.length; i++) {
        if (productionArray[i] != undefined) {
          rollerRowValue.push(
            <TableRow key={i}>
              <TableCell>{i}</TableCell>
              <TableCell>
                {msToMinSec(productionArray[i].rollerDuration)}
              </TableCell>
              <TableCell>{productionArray[i].rollerPressure}</TableCell>
              <TableCell>{productionArray[i].rollerTemp}</TableCell>
            </TableRow>
          );
        }
      }
    }

    // let productionArray = production[0];
    // let rollerObject = productionArray.roller;
    // let rollerArray = Object.entries(rollerObject);

    // const rollerRowValue = [];

    // for (const [key, value] of rollerArray) {
    //   let rollerKey = key;
    //   let rollerDuration = msToMinSec(value.rollerDuration);
    //   let rollerPressure = value.rollerPressure;
    //   let rollerTemp = value.rollerTemp;

    //   rollerRowValue.push(
    //     <TableRow key={rollerKey}>
    //       <TableCell>{rollerKey}</TableCell>
    //       <TableCell>{rollerDuration}</TableCell>
    //       <TableCell>{rollerPressure}</TableCell>
    //       <TableCell>{rollerTemp}</TableCell>
    //     </TableRow>
    //   );
    // }

    return (
      <Box style={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Step</strong>
                </TableCell>
                <TableCell>
                  <strong>Duration</strong>
                </TableCell>
                <TableCell>
                  <strong>Pressure (g/cm3)</strong>
                </TableCell>
                <TableCell>
                  <strong>Temperature (C)</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            {<TableBody>{rollerRowValue}</TableBody>}
          </Table>
        </TableContainer>
      </Box>
    );
  }
}

export default DisplayWalze;
