import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { SignUpLink } from "../SignUp";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const SignInPage = () => (
  <React.Fragment>
    <Container
      maxWidth="sm"
      component={Paper}
      style={{
        marginTop: "60px",
        paddingTop: "16px",
        paddingBottom: "4px",
      }}
    >
      <Typography variant="h5" component="h1">
        Sign In
      </Typography>
      <Typography component="p" color="textSecondary" gutterBottom>
        Welcome to MAT AI
      </Typography>
      <SignInForm />
      <SignUpLink />
    </Container>
  </React.Fragment>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div
          style={{
            marginTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <TextField
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            marginTop: "15px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <TextField
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Password"
            style={{
              width: "100%",
            }}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          disabled={isInvalid}
          type="submit"
          style={{
            marginTop: "15px",
            marginLeft: "30px",
          }}
        >
          Sign In
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
