import React, { Component } from "react";
import { compose } from "recompose";

// import OrderProzess from "./extended";
import Order from "./compact";
import OrderDB from "../../data/orderDB.json";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import * as ROLES from "../../constants/roles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class OrdersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      OrderDB,
    };
  }

  render() {
    return (
      <div>
        <React.Fragment>
          <Container maxWidth="lg">
            <Typography
              variant="h6"
              component="h2"
              style={{ marginBottom: "15px" }}
              gutterBottom
            >
              Upcoming tasks
            </Typography>
            <Grid container spacing={3}>
              {OrderDB.map((order) =>
                order.currentProductionStep < 5 ? (
                  <Grid item xs={12} sm={12}>
                    <Order key={order._id} order={order} disableBtn={true} />
                  </Grid>
                ) : (
                  ""
                )
              )}
            </Grid>
          </Container>
        </React.Fragment>
      </div>
    );
  }
}

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withFirebase)(OrdersPage);
