import React from "react";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import { AuthUserContext, withAuthorization } from "../Session";
import { PasswordForgetForm } from "../PasswordForget";
import PasswordChangeForm from "../PasswordChange";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const AccountPage = () => (
  <Container
    maxWidth="lg"
    component={Paper}
    style={{
      marginBottom: "48px",
      paddingTop: "24px",
    }}
  >
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div></div>
            </Grid>
            <Grid item xs={9}>
              <div>
                <Typography variant="h5" component="h1">
                  {authUser.username}
                </Typography>
                <Typography variant="subtitle1" component="h1" gutterBottom>
                  {authUser.email}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div>xs=12</div>
            </Grid>
            <Grid item xs={9}>
              <div>
                <PasswordForgetForm />
                <PasswordChangeForm />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </AuthUserContext.Consumer>
  </Container>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AccountPage);
