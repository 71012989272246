import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import uniaxialway from "../../data/uniaxialway";
import uniaxialpower from "../../data/uniaxialpower";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class DisplayGraphUniaxial extends Component {
  constructor() {
    super();
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }

  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  render() {
    const options = {
      theme: "light2",
      animationEnabled: true,
      exportEnabled: true,
      axisY: {
        title: "Strain",
        titleFontColor: "#6D78AD",
        lineColor: "#6D78AD",
        labelFontColor: "#6D78AD",
        tickColor: "#6D78AD",
        includeZero: false,
      },
      axisY2: {
        title: "Tension",
        titleFontColor: "#51CDA0",
        lineColor: "#51CDA0",
        labelFontColor: "#51CDA0",
        tickColor: "#51CDA0",
        includeZero: false,
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
      },
      data: [
        {
          type: "spline",
          name: "Strain (%)",
          showInLegend: true,
          xValueFormatString: "History: ### Sec",
          yValueFormatString: "0000",
          dataPoints: uniaxialway,
        },
        {
          type: "spline",
          name: "Tension (MPa)",
          axisYType: "secondary",
          showInLegend: true,
          xValueFormatString: "History: ### Sec",
          yValueFormatString: "0000",
          dataPoints: uniaxialpower,
        },
      ],
    };

    return (
      <div className="MultipleAxisChart">
        <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  }
}

export default DisplayGraphUniaxial;
