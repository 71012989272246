const uniaxialway = [
  {
    x: 0.0400073,
    y: 0.00000192905,
    power: 0.077674
  },
  {
    x: 0.0800073,
    y: 0.00000192905,
    power: 0.0795017
  },
  {
    x: 1.00001,
    y: 0.151709,
    power: 0.108744
  },
  {
    x: 1.62001,
    y: 0.429838,
    power: 0.129762
  },
  {
    x: 2.24001,
    y: 0.707967,
    power: 0.149866
  },
  {
    x: 2.86001,
    y: 0.935527,
    power: 0.172711
  },
  {
    x: 3.48001,
    y: 1.23894,
    power: 0.190987
  },
  {
    x: 4.10001,
    y: 1.51707,
    power: 0.212919
  },
  {
    x: 4.72001,
    y: 1.76991,
    power: 0.233023
  },
  {
    x: 5.32001,
    y: 2.04804,
    power: 0.253127
  },
  {
    x: 5.92001,
    y: 2.2756,
    power: 0.266834
  },
  {
    x: 6.52001,
    y: 2.55373,
    power: 0.284196
  },
  {
    x: 7.14001,
    y: 2.75601,
    power: 0.300645
  },
  {
    x: 7.76001,
    y: 2.95828,
    power: 0.320749
  },
  {
    x: 8.38001,
    y: 3.18584,
    power: 0.339939
  },
  {
    x: 9.00001,
    y: 3.4134,
    power: 0.355474
  },
  {
    x: 9.62001,
    y: 3.69153,
    power: 0.371009
  },
  {
    x: 10.22,
    y: 3.96966,
    power: 0.388372
  },
  {
    x: 10.84,
    y: 4.24779,
    power: 0.402079
  },
  {
    x: 11.46,
    y: 4.52592,
    power: 0.418527
  },
  {
    x: 12.06,
    y: 4.77876,
    power: 0.428579
  },
  {
    x: 12.68,
    y: 5.03161,
    power: 0.446856
  },
  {
    x: 13.3,
    y: 5.28445,
    power: 0.459649
  },
  {
    x: 13.92,
    y: 5.5373,
    power: 0.476098
  },
  {
    x: 14.52,
    y: 5.81543,
    power: 0.490719
  },
  {
    x: 15.14,
    y: 6.06827,
    power: 0.504426
  },
  {
    x: 15.74,
    y: 6.29583,
    power: 0.51722
  },
  {
    x: 16.34,
    y: 6.54867,
    power: 0.533668
  },
  {
    x: 16.94,
    y: 6.8268,
    power: 0.54372
  },
  {
    x: 17.56,
    y: 7.05436,
    power: 0.551945
  },
  {
    x: 18.18,
    y: 7.30721,
    power: 0.565652
  },
  {
    x: 18.8,
    y: 7.61062,
    power: 0.577531
  },
  {
    x: 19.4,
    y: 7.8129,
    power: 0.590325
  },
  {
    x: 20,
    y: 8.14159,
    power: 0.603118
  },
  {
    x: 20.62,
    y: 8.36915,
    power: 0.614084
  },
  {
    x: 21.24,
    y: 8.64728,
    power: 0.626878
  },
  {
    x: 21.86,
    y: 8.92541,
    power: 0.638757
  },
  {
    x: 22.48,
    y: 9.25411,
    power: 0.654292
  },
  {
    x: 23.1,
    y: 9.53224,
    power: 0.666172
  },
  {
    x: 23.72,
    y: 9.83565,
    power: 0.676224
  },
  {
    x: 24.34,
    y: 10.1138,
    power: 0.689017
  },
  {
    x: 24.96,
    y: 10.4172,
    power: 0.699983
  },
  {
    x: 25.58,
    y: 10.67,
    power: 0.710949
  },
  {
    x: 26.18,
    y: 10.9482,
    power: 0.720087
  },
  {
    x: 26.78,
    y: 11.201,
    power: 0.730139
  },
  {
    x: 27.4,
    y: 11.4791,
    power: 0.742018
  },
  {
    x: 28,
    y: 11.732,
    power: 0.752984
  },
  {
    x: 28.62,
    y: 11.9848,
    power: 0.76395
  },
  {
    x: 29.22,
    y: 12.263,
    power: 0.77583
  },
  {
    x: 29.82,
    y: 12.5158,
    power: 0.784968
  },
  {
    x: 30.42,
    y: 12.7939,
    power: 0.79502
  },
  {
    x: 31.02,
    y: 13.0468,
    power: 0.80233
  },
  {
    x: 31.64,
    y: 13.3502,
    power: 0.816037
  },
  {
    x: 32.26,
    y: 13.603,
    power: 0.824262
  },
  {
    x: 32.88,
    y: 13.8812,
    power: 0.8334
  },
  {
    x: 33.5,
    y: 14.1087,
    power: 0.842538
  },
  {
    x: 34.12,
    y: 14.4121,
    power: 0.85259
  },
  {
    x: 34.74,
    y: 14.7156,
    power: 0.86447
  },
  {
    x: 35.36,
    y: 14.9937,
    power: 0.874522
  },
  {
    x: 35.96,
    y: 15.2718,
    power: 0.881832
  },
  {
    x: 36.56,
    y: 15.4994,
    power: 0.892798
  },
  {
    x: 37.16,
    y: 15.7775,
    power: 0.900109
  },
  {
    x: 37.78,
    y: 16.0051,
    power: 0.911074
  },
  {
    x: 38.4,
    y: 16.3338,
    power: 0.918385
  },
  {
    x: 39,
    y: 16.6119,
    power: 0.928437
  },
  {
    x: 39.62,
    y: 16.9406,
    power: 0.937575
  },
  {
    x: 40.22,
    y: 17.1934,
    power: 0.945799
  },
  {
    x: 40.84,
    y: 17.4463,
    power: 0.954024
  },
  {
    x: 41.46,
    y: 17.7244,
    power: 0.964076
  },
  {
    x: 42.06,
    y: 18.0025,
    power: 0.970472
  },
  {
    x: 42.68,
    y: 18.2554,
    power: 0.979611
  },
  {
    x: 43.3,
    y: 18.5335,
    power: 0.989663
  },
  {
    x: 43.92,
    y: 18.7863,
    power: 1.00063
  },
  {
    x: 44.54,
    y: 19.0645,
    power: 1.0052
  },
  {
    x: 45.14,
    y: 19.3173,
    power: 1.01525
  },
  {
    x: 45.74,
    y: 19.5702,
    power: 1.02439
  },
  {
    x: 46.34,
    y: 19.8989,
    power: 1.03353
  },
  {
    x: 46.96,
    y: 20.2023,
    power: 1.04084
  },
  {
    x: 47.58,
    y: 20.4298,
    power: 1.04906
  },
  {
    x: 48.2,
    y: 20.7333,
    power: 1.05454
  },
  {
    x: 48.82,
    y: 21.0114,
    power: 1.06368
  },
  {
    x: 49.42,
    y: 21.2642,
    power: 1.07373
  },
  {
    x: 50.02,
    y: 21.5424,
    power: 1.07922
  },
  {
    x: 50.64,
    y: 21.7952,
    power: 1.08927
  },
  {
    x: 51.26,
    y: 22.1745,
    power: 1.09475
  },
  {
    x: 51.88,
    y: 22.4273,
    power: 1.10572
  },
  {
    x: 52.48,
    y: 22.6549,
    power: 1.11394
  },
  {
    x: 53.1,
    y: 22.9836,
    power: 1.12125
  },
  {
    x: 53.7,
    y: 23.2617,
    power: 1.12673
  },
  {
    x: 54.32,
    y: 23.5904,
    power: 1.13587
  },
  {
    x: 54.94,
    y: 23.8432,
    power: 1.1441
  },
  {
    x: 55.56,
    y: 24.1719,
    power: 1.15141
  },
  {
    x: 56.16,
    y: 24.3995,
    power: 1.15963
  },
  {
    x: 56.76,
    y: 24.5765,
    power: 1.16603
  },
  {
    x: 57.38,
    y: 24.8293,
    power: 1.17517
  },
  {
    x: 57.98,
    y: 25.0822,
    power: 1.18339
  },
  {
    x: 58.6,
    y: 25.4109,
    power: 1.19162
  },
  {
    x: 59.2,
    y: 25.6637,
    power: 1.19893
  },
  {
    x: 59.8,
    y: 25.9166,
    power: 1.20441
  },
  {
    x: 60.4,
    y: 26.22,
    power: 1.21081
  },
  {
    x: 61.02,
    y: 26.5234,
    power: 1.21629
  },
  {
    x: 61.64,
    y: 26.7762,
    power: 1.22725
  },
  {
    x: 62.26,
    y: 27.0796,
    power: 1.23548
  },
  {
    x: 62.86,
    y: 27.3325,
    power: 1.24096
  },
  {
    x: 63.48,
    y: 27.6359,
    power: 1.2501
  },
  {
    x: 64.1,
    y: 27.8888,
    power: 1.25741
  },
  {
    x: 64.72,
    y: 28.1922,
    power: 1.26472
  },
  {
    x: 65.32,
    y: 28.4956,
    power: 1.2702
  },
  {
    x: 65.94,
    y: 28.7737,
    power: 1.27843
  },
  {
    x: 66.54,
    y: 29.0771,
    power: 1.28026
  },
  {
    x: 67.16,
    y: 29.3047,
    power: 1.29031
  },
  {
    x: 67.78,
    y: 29.6587,
    power: 1.2967
  },
  {
    x: 68.4,
    y: 29.9115,
    power: 1.30676
  },
  {
    x: 69,
    y: 30.1896,
    power: 1.31224
  },
  {
    x: 69.62,
    y: 30.4425,
    power: 1.32321
  },
  {
    x: 70.22,
    y: 30.7206,
    power: 1.32686
  },
  {
    x: 70.84,
    y: 30.9482,
    power: 1.33509
  },
  {
    x: 71.46,
    y: 31.2516,
    power: 1.34057
  },
  {
    x: 72.06,
    y: 31.4791,
    power: 1.34879
  },
  {
    x: 72.68,
    y: 31.8331,
    power: 1.35428
  },
  {
    x: 73.3,
    y: 32.1113,
    power: 1.36067
  },
  {
    x: 73.92,
    y: 32.3641,
    power: 1.3689
  },
  {
    x: 74.52,
    y: 32.6169,
    power: 1.37712
  },
  {
    x: 75.12,
    y: 32.8951,
    power: 1.38352
  },
  {
    x: 75.74,
    y: 33.1479,
    power: 1.39448
  },
  {
    x: 76.34,
    y: 33.4008,
    power: 1.39814
  },
  {
    x: 76.94,
    y: 33.6283,
    power: 1.40362
  },
  {
    x: 77.56,
    y: 33.9823,
    power: 1.4091
  },
  {
    x: 78.16,
    y: 34.2099,
    power: 1.4155
  },
  {
    x: 78.78,
    y: 34.4627,
    power: 1.42098
  },
  {
    x: 79.38,
    y: 34.7408,
    power: 1.42647
  },
  {
    x: 80,
    y: 34.9937,
    power: 1.43469
  },
  {
    x: 80.62,
    y: 35.2971,
    power: 1.44383
  },
  {
    x: 81.24,
    y: 35.5752,
    power: 1.45205
  },
  {
    x: 81.84,
    y: 35.8281,
    power: 1.45754
  },
  {
    x: 82.46,
    y: 36.1062,
    power: 1.46667
  },
  {
    x: 83.06,
    y: 36.3843,
    power: 1.47399
  },
  {
    x: 83.68,
    y: 36.6625,
    power: 1.47764
  },
  {
    x: 84.3,
    y: 36.9153,
    power: 1.48312
  },
  {
    x: 84.9,
    y: 37.1934,
    power: 1.48952
  },
  {
    x: 85.5,
    y: 37.4463,
    power: 1.49683
  },
  {
    x: 86.1,
    y: 37.7244,
    power: 1.50505
  },
  {
    x: 86.7,
    y: 37.952,
    power: 1.51328
  },
  {
    x: 87.32,
    y: 38.2807,
    power: 1.51876
  },
  {
    x: 87.92,
    y: 38.5335,
    power: 1.5279
  },
  {
    x: 88.54,
    y: 38.8116,
    power: 1.53338
  },
  {
    x: 89.14,
    y: 39.0392,
    power: 1.53795
  },
  {
    x: 89.74,
    y: 39.3173,
    power: 1.54526
  },
  {
    x: 90.36,
    y: 39.6207,
    power: 1.54983
  },
  {
    x: 90.98,
    y: 39.8736,
    power: 1.55531
  },
  {
    x: 91.6,
    y: 40.1011,
    power: 1.56628
  },
  {
    x: 92.22,
    y: 40.354,
    power: 1.57268
  },
  {
    x: 92.82,
    y: 40.6321,
    power: 1.57816
  },
  {
    x: 93.44,
    y: 40.885,
    power: 1.58547
  },
  {
    x: 94.04,
    y: 41.1631,
    power: 1.59644
  },
  {
    x: 94.66,
    y: 41.4159,
    power: 1.60101
  },
  {
    x: 95.28,
    y: 41.7446,
    power: 1.60649
  },
  {
    x: 95.88,
    y: 42.0228,
    power: 1.61289
  },
  {
    x: 96.5,
    y: 42.2503,
    power: 1.61563
  },
  {
    x: 97.12,
    y: 42.5537,
    power: 1.62385
  },
  {
    x: 97.74,
    y: 42.8319,
    power: 1.63208
  },
  {
    x: 98.36,
    y: 43.0847,
    power: 1.63847
  },
  {
    x: 98.96,
    y: 43.3123,
    power: 1.64487
  },
  {
    x: 99.56,
    y: 43.5904,
    power: 1.65127
  },
  {
    x: 100.18,
    y: 43.8938,
    power: 1.65949
  },
  {
    x: 100.8,
    y: 44.1467,
    power: 1.67137
  },
  {
    x: 101.42,
    y: 44.4248,
    power: 1.6732
  },
  {
    x: 102.04,
    y: 44.7029,
    power: 1.67777
  },
  {
    x: 102.66,
    y: 45.0063,
    power: 1.68142
  },
  {
    x: 103.26,
    y: 45.2844,
    power: 1.68873
  },
  {
    x: 103.88,
    y: 45.512,
    power: 1.69604
  },
  {
    x: 104.48,
    y: 45.8154,
    power: 1.70427
  },
  {
    x: 105.1,
    y: 46.043,
    power: 1.71158
  },
  {
    x: 105.7,
    y: 46.2453,
    power: 1.7198
  },
  {
    x: 106.3,
    y: 46.4223,
    power: 1.72711
  },
  {
    x: 106.92,
    y: 46.7004,
    power: 1.73351
  },
  {
    x: 107.54,
    y: 47.0038,
    power: 1.73991
  },
  {
    x: 108.16,
    y: 47.2061,
    power: 1.74447
  },
  {
    x: 108.76,
    y: 47.4842,
    power: 1.75087
  },
  {
    x: 109.36,
    y: 47.7623,
    power: 1.75818
  },
  {
    x: 109.98,
    y: 48.0152,
    power: 1.76366
  },
  {
    x: 110.6,
    y: 48.3186,
    power: 1.7728
  },
  {
    x: 111.22,
    y: 48.5714,
    power: 1.77829
  },
  {
    x: 111.82,
    y: 48.8243,
    power: 1.78742
  },
  {
    x: 112.44,
    y: 49.0771,
    power: 1.79565
  },
  {
    x: 113.06,
    y: 49.33,
    power: 1.79839
  },
  {
    x: 113.68,
    y: 49.5828,
    power: 1.80479
  },
  {
    x: 114.28,
    y: 49.8357,
    power: 1.81027
  },
  {
    x: 114.88,
    y: 50.1138,
    power: 1.81667
  },
  {
    x: 115.5,
    y: 50.3666,
    power: 1.82489
  },
  {
    x: 116.12,
    y: 50.6195,
    power: 1.83311
  },
  {
    x: 116.74,
    y: 50.9229,
    power: 1.8386
  },
  {
    x: 117.36,
    y: 51.1504,
    power: 1.84591
  },
  {
    x: 117.98,
    y: 51.4286,
    power: 1.85413
  },
  {
    x: 118.6,
    y: 51.6561,
    power: 1.86144
  },
  {
    x: 119.22,
    y: 51.9343,
    power: 1.86784
  },
  {
    x: 119.82,
    y: 52.1618,
    power: 1.87241
  },
  {
    x: 120.42,
    y: 52.3894,
    power: 1.87881
  },
  {
    x: 121.02,
    y: 52.6422,
    power: 1.88429
  },
  {
    x: 121.64,
    y: 52.9204,
    power: 1.89434
  },
  {
    x: 122.26,
    y: 53.1732,
    power: 1.90165
  },
  {
    x: 122.88,
    y: 53.426,
    power: 1.90896
  },
  {
    x: 123.48,
    y: 53.6789,
    power: 1.91444
  },
  {
    x: 124.1,
    y: 53.957,
    power: 1.92267
  },
  {
    x: 124.7,
    y: 54.2099,
    power: 1.92907
  },
  {
    x: 125.3,
    y: 54.3869,
    power: 1.93455
  },
  {
    x: 125.92,
    y: 54.665,
    power: 1.94186
  },
  {
    x: 126.52,
    y: 54.8925,
    power: 1.94643
  },
  {
    x: 127.14,
    y: 55.2212,
    power: 1.95191
  },
  {
    x: 127.76,
    y: 55.4235,
    power: 1.96288
  },
  {
    x: 128.38,
    y: 55.6511,
    power: 1.96745
  },
  {
    x: 129,
    y: 55.9545,
    power: 1.97567
  },
  {
    x: 129.62,
    y: 56.1821,
    power: 1.98572
  },
  {
    x: 130.22,
    y: 56.4349,
    power: 1.99029
  },
  {
    x: 130.82,
    y: 56.6625,
    power: 1.99852
  },
  {
    x: 131.44,
    y: 56.9153,
    power: 2.00217
  },
  {
    x: 132.06,
    y: 57.1681,
    power: 2.00948
  },
  {
    x: 132.68,
    y: 57.421,
    power: 2.01771
  },
  {
    x: 133.28,
    y: 57.6738,
    power: 2.02502
  },
  {
    x: 133.9,
    y: 57.9267,
    power: 2.03324
  },
  {
    x: 134.5,
    y: 58.2048,
    power: 2.03872
  },
  {
    x: 135.12,
    y: 58.4071,
    power: 2.04512
  },
  {
    x: 135.72,
    y: 58.6599,
    power: 2.05152
  },
  {
    x: 136.32,
    y: 58.8875,
    power: 2.06157
  },
  {
    x: 136.94,
    y: 59.1656,
    power: 2.06797
  },
  {
    x: 137.56,
    y: 59.4185,
    power: 2.07528
  },
  {
    x: 138.18,
    y: 59.6713,
    power: 2.07985
  },
  {
    x: 138.8,
    y: 59.8989,
    power: 2.08807
  },
  {
    x: 139.42,
    y: 60.2023,
    power: 2.09538
  },
  {
    x: 140.02,
    y: 60.4298,
    power: 2.10086
  },
  {
    x: 140.62,
    y: 60.6827,
    power: 2.10726
  },
  {
    x: 141.24,
    y: 60.9102,
    power: 2.11731
  },
  {
    x: 141.86,
    y: 61.1884,
    power: 2.12188
  },
  {
    x: 142.48,
    y: 61.4159,
    power: 2.12919
  },
  {
    x: 143.08,
    y: 61.6182,
    power: 2.13559
  },
  {
    x: 143.7,
    y: 61.8458,
    power: 2.13924
  },
  {
    x: 144.32,
    y: 62.1239,
    power: 2.14747
  },
  {
    x: 144.94,
    y: 62.3515,
    power: 2.15386
  },
  {
    x: 145.56,
    y: 62.6043,
    power: 2.16392
  },
  {
    x: 146.18,
    y: 62.8571,
    power: 2.17031
  },
  {
    x: 146.78,
    y: 63.11,
    power: 2.17671
  },
  {
    x: 147.38,
    y: 63.3628,
    power: 2.18219
  },
  {
    x: 148,
    y: 63.6157,
    power: 2.19133
  },
  {
    x: 148.62,
    y: 63.818,
    power: 2.19956
  },
  {
    x: 149.24,
    y: 64.0961,
    power: 2.20778
  },
  {
    x: 149.86,
    y: 64.3489,
    power: 2.21509
  },
  {
    x: 150.46,
    y: 64.6018,
    power: 2.21875
  },
  {
    x: 151.06,
    y: 64.8293,
    power: 2.22606
  },
  {
    x: 151.68,
    y: 65.0569,
    power: 2.23428
  },
  {
    x: 152.3,
    y: 65.3097,
    power: 2.24159
  },
  {
    x: 152.92,
    y: 65.5879,
    power: 2.24616
  },
  {
    x: 153.52,
    y: 65.8154,
    power: 2.25438
  },
  {
    x: 154.14,
    y: 66.0683,
    power: 2.26352
  },
  {
    x: 154.76,
    y: 66.3464,
    power: 2.27175
  },
  {
    x: 155.38,
    y: 66.4981,
    power: 2.27906
  },
  {
    x: 156,
    y: 66.7004,
    power: 2.28637
  },
  {
    x: 156.62,
    y: 66.8774,
    power: 2.29368
  },
  {
    x: 157.24,
    y: 67.1808,
    power: 2.29825
  },
  {
    x: 157.86,
    y: 67.4336,
    power: 2.30739
  },
  {
    x: 158.46,
    y: 67.6865,
    power: 2.31287
  },
  {
    x: 159.06,
    y: 67.914,
    power: 2.32201
  },
  {
    x: 159.68,
    y: 68.1669,
    power: 2.3284
  },
  {
    x: 160.3,
    y: 68.3944,
    power: 2.33297
  },
  {
    x: 160.92,
    y: 68.622,
    power: 2.34302
  },
  {
    x: 161.54,
    y: 68.8748,
    power: 2.35308
  },
  {
    x: 162.16,
    y: 69.153,
    power: 2.35856
  },
  {
    x: 162.78,
    y: 69.3805,
    power: 2.36404
  },
  {
    x: 163.38,
    y: 69.6081,
    power: 2.37318
  },
  {
    x: 163.98,
    y: 69.8104,
    power: 2.37775
  },
  {
    x: 164.6,
    y: 70.0379,
    power: 2.38597
  },
  {
    x: 165.2,
    y: 70.3161,
    power: 2.3942
  },
  {
    x: 165.82,
    y: 70.493,
    power: 2.39328
  },
  {
    x: 166.44,
    y: 70.7965,
    power: 2.40516
  },
  {
    x: 167.06,
    y: 71.024,
    power: 2.41613
  },
  {
    x: 167.68,
    y: 71.3022,
    power: 2.42253
  },
  {
    x: 168.28,
    y: 71.4539,
    power: 2.4271
  },
  {
    x: 168.88,
    y: 71.732,
    power: 2.43532
  },
  {
    x: 169.5,
    y: 71.9343,
    power: 2.4408
  },
  {
    x: 170.1,
    y: 72.1618,
    power: 2.44811
  },
  {
    x: 170.72,
    y: 72.3641,
    power: 2.45542
  },
  {
    x: 171.34,
    y: 72.5411,
    power: 2.46365
  },
  {
    x: 171.96,
    y: 72.7939,
    power: 2.47004
  },
  {
    x: 172.58,
    y: 73.0215,
    power: 2.4801
  },
  {
    x: 173.18,
    y: 73.2743,
    power: 2.48467
  },
  {
    x: 173.78,
    y: 73.4766,
    power: 2.49198
  },
  {
    x: 174.4,
    y: 73.7295,
    power: 2.49929
  },
  {
    x: 175.02,
    y: 73.957,
    power: 2.50842
  },
  {
    x: 175.64,
    y: 74.2351,
    power: 2.51482
  },
  {
    x: 176.24,
    y: 74.4374,
    power: 2.52579
  },
  {
    x: 176.86,
    y: 74.6903,
    power: 2.53127
  },
  {
    x: 177.48,
    y: 74.9684,
    power: 2.53584
  },
  {
    x: 178.1,
    y: 75.196,
    power: 2.54589
  },
  {
    x: 178.72,
    y: 75.4488,
    power: 2.5532
  },
  {
    x: 179.32,
    y: 75.6764,
    power: 2.5596
  },
  {
    x: 179.92,
    y: 75.9039,
    power: 2.566
  },
  {
    x: 180.52,
    y: 76.1315,
    power: 2.57148
  },
  {
    x: 181.14,
    y: 76.359,
    power: 2.58153
  },
  {
    x: 181.76,
    y: 76.5866,
    power: 2.58884
  },
  {
    x: 182.38,
    y: 76.8394,
    power: 2.59615
  },
  {
    x: 183,
    y: 77.0164,
    power: 2.60255
  },
  {
    x: 183.62,
    y: 77.244,
    power: 2.61169
  },
  {
    x: 184.22,
    y: 77.4968,
    power: 2.61808
  },
  {
    x: 184.82,
    y: 77.7244,
    power: 2.62539
  },
  {
    x: 185.44,
    y: 77.9014,
    power: 2.63453
  },
  {
    x: 186.06,
    y: 78.129,
    power: 2.64184
  },
  {
    x: 186.68,
    y: 78.3312,
    power: 2.64641
  },
  {
    x: 187.28,
    y: 78.5841,
    power: 2.65372
  },
  {
    x: 187.9,
    y: 78.8116,
    power: 2.6656
  },
  {
    x: 188.52,
    y: 79.0645,
    power: 2.66834
  },
  {
    x: 189.14,
    y: 79.2415,
    power: 2.67931
  },
  {
    x: 189.74,
    y: 79.4943,
    power: 2.68753
  },
  {
    x: 190.34,
    y: 79.6966,
    power: 2.69302
  },
  {
    x: 190.96,
    y: 79.9494,
    power: 2.70033
  },
  {
    x: 191.58,
    y: 80.1517,
    power: 2.70764
  },
  {
    x: 192.2,
    y: 80.354,
    power: 2.71312
  },
  {
    x: 192.82,
    y: 80.5815,
    power: 2.71952
  },
  {
    x: 193.44,
    y: 80.7838,
    power: 2.72591
  },
  {
    x: 194.06,
    y: 80.9861,
    power: 2.73505
  },
  {
    x: 194.66,
    y: 81.1884,
    power: 2.74328
  },
  {
    x: 195.26,
    y: 81.4159,
    power: 2.75241
  },
  {
    x: 195.88,
    y: 81.6688,
    power: 2.75972
  },
  {
    x: 196.5,
    y: 81.8963,
    power: 2.76886
  },
  {
    x: 197.12,
    y: 82.0986,
    power: 2.77435
  },
  {
    x: 197.74,
    y: 82.3262,
    power: 2.78166
  },
  {
    x: 198.36,
    y: 82.5284,
    power: 2.78988
  },
  {
    x: 198.98,
    y: 82.756,
    power: 2.79628
  },
  {
    x: 199.6,
    y: 83.0089,
    power: 2.80633
  },
  {
    x: 200.22,
    y: 83.2364,
    power: 2.81181
  },
  {
    x: 200.82,
    y: 83.4134,
    power: 2.82004
  },
  {
    x: 201.42,
    y: 83.6157,
    power: 2.82643
  },
  {
    x: 202.02,
    y: 83.818,
    power: 2.83831
  },
  {
    x: 202.64,
    y: 84.0961,
    power: 2.84562
  },
  {
    x: 203.24,
    y: 84.2731,
    power: 2.85293
  },
  {
    x: 203.86,
    y: 84.5512,
    power: 2.8575
  },
  {
    x: 204.46,
    y: 84.7282,
    power: 2.8639
  },
  {
    x: 205.06,
    y: 84.9305,
    power: 2.87212
  },
  {
    x: 205.68,
    y: 85.1833,
    power: 2.88126
  },
  {
    x: 206.28,
    y: 85.3856,
    power: 2.88766
  },
  {
    x: 206.9,
    y: 85.5879,
    power: 2.89406
  },
  {
    x: 207.5,
    y: 85.7396,
    power: 2.90593
  },
  {
    x: 208.12,
    y: 85.8913,
    power: 2.90593
  },
  {
    x: 208.74,
    y: 86.0936,
    power: 2.91507
  },
  {
    x: 209.36,
    y: 86.2705,
    power: 2.92238
  },
  {
    x: 209.98,
    y: 86.4981,
    power: 2.93152
  },
  {
    x: 210.58,
    y: 86.7257,
    power: 2.93883
  },
  {
    x: 211.18,
    y: 86.9785,
    power: 2.94706
  },
  {
    x: 211.8,
    y: 87.2061,
    power: 2.95528
  },
  {
    x: 212.42,
    y: 87.4083,
    power: 2.95985
  },
  {
    x: 213.04,
    y: 87.6359,
    power: 2.9699
  },
  {
    x: 213.66,
    y: 87.8888,
    power: 2.97813
  },
  {
    x: 214.28,
    y: 88.1163,
    power: 2.98635
  },
  {
    x: 214.88,
    y: 88.2933,
    power: 2.99458
  },
  {
    x: 215.5,
    y: 88.4956,
    power: 3.00006
  },
  {
    x: 216.12,
    y: 88.6979,
    power: 3.00828
  },
  {
    x: 216.72,
    y: 88.9507,
    power: 3.01833
  },
  {
    x: 217.34,
    y: 89.153,
    power: 3.02564
  },
  {
    x: 217.94,
    y: 89.3552,
    power: 3.03204
  },
  {
    x: 218.56,
    y: 89.5828,
    power: 3.03935
  },
  {
    x: 219.18,
    y: 89.7851,
    power: 3.04666
  },
  {
    x: 219.8,
    y: 90.0379,
    power: 3.05854
  },
  {
    x: 220.42,
    y: 90.2402,
    power: 3.06585
  },
  {
    x: 221.02,
    y: 90.4678,
    power: 3.07134
  },
  {
    x: 221.64,
    y: 90.6448,
    power: 3.08139
  },
  {
    x: 222.24,
    y: 90.8217,
    power: 3.08778
  },
  {
    x: 222.86,
    y: 91.0746,
    power: 3.09509
  },
  {
    x: 223.46,
    y: 91.2516,
    power: 3.10058
  },
  {
    x: 224.08,
    y: 91.4791,
    power: 3.10789
  },
  {
    x: 224.7,
    y: 91.6814,
    power: 3.11703
  },
  {
    x: 225.3,
    y: 91.8584,
    power: 3.12525
  },
  {
    x: 225.9,
    y: 92.086,
    power: 3.13622
  },
  {
    x: 226.5,
    y: 92.263,
    power: 3.1417
  },
  {
    x: 227.12,
    y: 92.5158,
    power: 3.15084
  },
  {
    x: 227.72,
    y: 92.7181,
    power: 3.15815
  },
  {
    x: 228.34,
    y: 92.9456,
    power: 3.16729
  },
  {
    x: 228.94,
    y: 93.0973,
    power: 3.16911
  },
  {
    x: 229.54,
    y: 93.2996,
    power: 3.18008
  },
  {
    x: 230.16,
    y: 93.4766,
    power: 3.18374
  },
  {
    x: 230.76,
    y: 93.7295,
    power: 3.19287
  },
  {
    x: 231.36,
    y: 93.9317,
    power: 3.20384
  },
  {
    x: 231.98,
    y: 94.134,
    power: 3.21024
  },
  {
    x: 232.6,
    y: 94.3616,
    power: 3.22212
  },
  {
    x: 233.22,
    y: 94.5638,
    power: 3.22577
  },
  {
    x: 233.82,
    y: 94.7661,
    power: 3.23217
  },
  {
    x: 234.44,
    y: 94.9937,
    power: 3.23765
  },
  {
    x: 235.04,
    y: 95.2465,
    power: 3.24679
  },
  {
    x: 235.66,
    y: 95.4235,
    power: 3.2541
  },
  {
    x: 236.26,
    y: 95.6258,
    power: 3.26506
  },
  {
    x: 236.88,
    y: 95.8028,
    power: 3.27329
  },
  {
    x: 237.5,
    y: 96.0556,
    power: 3.27877
  },
  {
    x: 238.12,
    y: 96.2579,
    power: 3.28882
  },
  {
    x: 238.74,
    y: 96.5108,
    power: 3.29431
  },
  {
    x: 239.36,
    y: 96.6372,
    power: 3.30436
  },
  {
    x: 239.96,
    y: 96.89,
    power: 3.31076
  },
  {
    x: 240.56,
    y: 97.067,
    power: 3.31715
  },
  {
    x: 241.16,
    y: 97.3199,
    power: 3.32538
  },
  {
    x: 241.78,
    y: 97.4968,
    power: 3.33086
  },
  {
    x: 242.4,
    y: 97.6991,
    power: 3.33908
  },
  {
    x: 243.02,
    y: 97.9014,
    power: 3.34548
  },
  {
    x: 243.64,
    y: 98.129,
    power: 3.35462
  },
  {
    x: 244.24,
    y: 98.3059,
    power: 3.36284
  },
  {
    x: 244.86,
    y: 98.5082,
    power: 3.37289
  },
  {
    x: 245.46,
    y: 98.6852,
    power: 3.38203
  },
  {
    x: 246.08,
    y: 98.9128,
    power: 3.38477
  },
  {
    x: 246.7,
    y: 99.0645,
    power: 3.393
  },
  {
    x: 247.3,
    y: 99.292,
    power: 3.40396
  },
  {
    x: 247.92,
    y: 99.4943,
    power: 3.41219
  },
  {
    x: 248.52,
    y: 99.6966,
    power: 3.4195
  },
  {
    x: 249.14,
    y: 99.8483,
    power: 3.4259
  },
  {
    x: 249.74,
    y: 100.076,
    power: 3.43503
  },
  {
    x: 250.34,
    y: 100.278,
    power: 3.44326
  },
  {
    x: 250.94,
    y: 100.48,
    power: 3.45331
  },
  {
    x: 251.56,
    y: 100.683,
    power: 3.45788
  },
  {
    x: 252.16,
    y: 100.86,
    power: 3.46519
  },
  {
    x: 252.76,
    y: 101.113,
    power: 3.47433
  },
  {
    x: 253.38,
    y: 101.29,
    power: 3.48164
  },
  {
    x: 253.98,
    y: 101.492,
    power: 3.48895
  },
  {
    x: 254.6,
    y: 101.669,
    power: 3.49992
  },
  {
    x: 255.22,
    y: 101.922,
    power: 3.50723
  },
  {
    x: 255.84,
    y: 102.124,
    power: 3.51362
  },
  {
    x: 256.46,
    y: 102.301,
    power: 3.52459
  },
  {
    x: 257.06,
    y: 102.528,
    power: 3.52916
  },
  {
    x: 257.68,
    y: 102.781,
    power: 3.5383
  },
  {
    x: 258.3,
    y: 102.958,
    power: 3.54286
  },
  {
    x: 258.92,
    y: 103.186,
    power: 3.552
  },
  {
    x: 259.52,
    y: 103.338,
    power: 3.56206
  },
  {
    x: 260.12,
    y: 103.59,
    power: 3.56845
  },
  {
    x: 260.74,
    y: 103.767,
    power: 3.57485
  },
  {
    x: 261.36,
    y: 103.919,
    power: 3.58581
  },
  {
    x: 261.98,
    y: 104.071,
    power: 3.59312
  },
  {
    x: 262.6,
    y: 104.248,
    power: 3.60044
  },
  {
    x: 263.22,
    y: 104.475,
    power: 3.60957
  },
  {
    x: 263.82,
    y: 104.703,
    power: 3.61506
  },
  {
    x: 264.42,
    y: 104.88,
    power: 3.62328
  },
  {
    x: 265.04,
    y: 105.107,
    power: 3.62876
  },
  {
    x: 265.66,
    y: 105.31,
    power: 3.64064
  },
  {
    x: 266.28,
    y: 105.537,
    power: 3.64978
  },
  {
    x: 266.9,
    y: 105.714,
    power: 3.65435
  },
  {
    x: 267.5,
    y: 105.917,
    power: 3.66349
  },
  {
    x: 268.12,
    y: 106.144,
    power: 3.67171
  },
  {
    x: 268.74,
    y: 106.321,
    power: 3.68176
  },
  {
    x: 269.36,
    y: 106.523,
    power: 3.68908
  },
  {
    x: 269.98,
    y: 106.726,
    power: 3.69639
  },
  {
    x: 270.58,
    y: 106.903,
    power: 3.70004
  },
  {
    x: 271.18,
    y: 107.13,
    power: 3.71009
  },
  {
    x: 271.78,
    y: 107.332,
    power: 3.71649
  },
  {
    x: 272.4,
    y: 107.56,
    power: 3.72471
  },
  {
    x: 273.02,
    y: 107.737,
    power: 3.73294
  },
  {
    x: 273.64,
    y: 107.99,
    power: 3.74482
  },
  {
    x: 274.24,
    y: 108.192,
    power: 3.75213
  },
  {
    x: 274.86,
    y: 108.42,
    power: 3.76035
  },
  {
    x: 275.46,
    y: 108.546,
    power: 3.76584
  },
  {
    x: 276.08,
    y: 108.774,
    power: 3.77406
  },
  {
    x: 276.7,
    y: 108.976,
    power: 3.78046
  },
  {
    x: 277.32,
    y: 109.178,
    power: 3.78594
  },
  {
    x: 277.94,
    y: 109.355,
    power: 3.79782
  },
  {
    x: 278.56,
    y: 109.558,
    power: 3.80787
  },
  {
    x: 279.18,
    y: 109.785,
    power: 3.81427
  },
  {
    x: 279.8,
    y: 109.962,
    power: 3.82249
  },
  {
    x: 280.42,
    y: 110.19,
    power: 3.83072
  },
  {
    x: 281.04,
    y: 110.392,
    power: 3.83711
  },
  {
    x: 281.66,
    y: 110.619,
    power: 3.84625
  },
  {
    x: 282.26,
    y: 110.746,
    power: 3.85356
  },
  {
    x: 282.88,
    y: 110.999,
    power: 3.85996
  },
  {
    x: 283.48,
    y: 111.15,
    power: 3.86818
  },
  {
    x: 284.1,
    y: 111.378,
    power: 3.87641
  },
  {
    x: 284.72,
    y: 111.58,
    power: 3.88463
  },
  {
    x: 285.34,
    y: 111.783,
    power: 3.89377
  },
  {
    x: 285.96,
    y: 111.96,
    power: 3.90382
  },
  {
    x: 286.58,
    y: 112.187,
    power: 3.91296
  },
  {
    x: 287.18,
    y: 112.364,
    power: 3.91844
  },
  {
    x: 287.78,
    y: 112.617,
    power: 3.92667
  },
  {
    x: 288.38,
    y: 112.769,
    power: 3.93489
  },
  {
    x: 289,
    y: 112.971,
    power: 3.94037
  },
  {
    x: 289.62,
    y: 113.123,
    power: 3.94951
  },
  {
    x: 290.24,
    y: 113.35,
    power: 3.95865
  },
  {
    x: 290.86,
    y: 113.552,
    power: 3.96596
  },
  {
    x: 291.48,
    y: 113.78,
    power: 3.9751
  },
  {
    x: 292.1,
    y: 113.957,
    power: 3.98332
  },
  {
    x: 292.72,
    y: 114.109,
    power: 3.99063
  },
  {
    x: 293.32,
    y: 114.311,
    power: 3.99612
  },
  {
    x: 293.94,
    y: 114.539,
    power: 4.00434
  },
  {
    x: 294.54,
    y: 114.766,
    power: 4.00982
  },
  {
    x: 295.14,
    y: 114.943,
    power: 4.01714
  },
  {
    x: 295.76,
    y: 115.12,
    power: 4.02993
  },
  {
    x: 296.38,
    y: 115.348,
    power: 4.03633
  },
  {
    x: 297,
    y: 115.525,
    power: 4.04455
  },
  {
    x: 297.6,
    y: 115.702,
    power: 4.05369
  },
  {
    x: 298.2,
    y: 115.879,
    power: 4.06008
  },
  {
    x: 298.82,
    y: 116.081,
    power: 4.0674
  },
  {
    x: 299.44,
    y: 116.283,
    power: 4.07653
  },
  {
    x: 300.06,
    y: 116.485,
    power: 4.0811
  },
  {
    x: 300.68,
    y: 116.662,
    power: 4.09115
  },
  {
    x: 301.3,
    y: 116.865,
    power: 4.10121
  },
  {
    x: 301.92,
    y: 117.042,
    power: 4.1076
  },
  {
    x: 302.54,
    y: 117.295,
    power: 4.11583
  },
  {
    x: 303.16,
    y: 117.497,
    power: 4.12222
  },
  {
    x: 303.76,
    y: 117.75,
    power: 4.13228
  },
  {
    x: 304.36,
    y: 117.952,
    power: 4.13959
  },
  {
    x: 304.96,
    y: 118.129,
    power: 4.14872
  },
  {
    x: 305.58,
    y: 118.357,
    power: 4.15512
  },
  {
    x: 306.2,
    y: 118.584,
    power: 4.16517
  },
  {
    x: 306.82,
    y: 118.786,
    power: 4.17066
  },
  {
    x: 307.44,
    y: 118.963,
    power: 4.17797
  },
  {
    x: 308.04,
    y: 119.115,
    power: 4.18528
  },
  {
    x: 308.64,
    y: 119.317,
    power: 4.1935
  },
  {
    x: 309.26,
    y: 119.52,
    power: 4.20355
  },
  {
    x: 309.88,
    y: 119.722,
    power: 4.21361
  },
  {
    x: 310.5,
    y: 119.899,
    power: 4.22274
  },
  {
    x: 311.12,
    y: 120.126,
    power: 4.22823
  },
  {
    x: 311.74,
    y: 120.329,
    power: 4.23371
  },
  {
    x: 312.36,
    y: 120.556,
    power: 4.24285
  },
  {
    x: 312.96,
    y: 120.759,
    power: 4.24833
  },
  {
    x: 313.58,
    y: 120.986,
    power: 4.25838
  },
  {
    x: 314.18,
    y: 121.138,
    power: 4.26752
  },
  {
    x: 314.78,
    y: 121.29,
    power: 4.27483
  },
  {
    x: 315.38,
    y: 121.416,
    power: 4.28214
  },
  {
    x: 316,
    y: 121.618,
    power: 4.29128
  },
  {
    x: 316.62,
    y: 121.82,
    power: 4.29768
  },
  {
    x: 317.24,
    y: 122.048,
    power: 4.30682
  },
  {
    x: 317.84,
    y: 122.2,
    power: 4.31504
  },
  {
    x: 318.46,
    y: 122.427,
    power: 4.32144
  },
  {
    x: 319.06,
    y: 122.63,
    power: 4.32966
  },
  {
    x: 319.66,
    y: 122.832,
    power: 4.33697
  },
  {
    x: 320.26,
    y: 123.009,
    power: 4.34337
  },
  {
    x: 320.88,
    y: 123.236,
    power: 4.34976
  },
  {
    x: 321.5,
    y: 123.439,
    power: 4.3589
  },
  {
    x: 322.12,
    y: 123.616,
    power: 4.36987
  },
  {
    x: 322.74,
    y: 123.793,
    power: 4.37992
  },
  {
    x: 323.36,
    y: 123.97,
    power: 4.3854
  },
  {
    x: 323.96,
    y: 124.197,
    power: 4.3918
  },
  {
    x: 324.56,
    y: 124.425,
    power: 4.40002
  },
  {
    x: 325.16,
    y: 124.576,
    power: 4.41008
  },
  {
    x: 325.78,
    y: 124.804,
    power: 4.41739
  },
  {
    x: 326.4,
    y: 124.981,
    power: 4.42378
  },
  {
    x: 327.02,
    y: 125.209,
    power: 4.43566
  },
  {
    x: 327.64,
    y: 125.411,
    power: 4.44206
  },
  {
    x: 328.24,
    y: 125.588,
    power: 4.44937
  },
  {
    x: 328.84,
    y: 125.765,
    power: 4.45851
  },
  {
    x: 329.44,
    y: 125.917,
    power: 4.46399
  },
  {
    x: 330.06,
    y: 126.144,
    power: 4.47222
  },
  {
    x: 330.68,
    y: 126.271,
    power: 4.4777
  },
  {
    x: 331.3,
    y: 126.473,
    power: 4.48958
  },
  {
    x: 331.92,
    y: 126.65,
    power: 4.4978
  },
  {
    x: 332.54,
    y: 126.877,
    power: 4.50511
  },
  {
    x: 333.16,
    y: 127.08,
    power: 4.51334
  },
  {
    x: 333.78,
    y: 127.282,
    power: 4.52248
  },
  {
    x: 334.4,
    y: 127.484,
    power: 4.52887
  },
  {
    x: 335,
    y: 127.686,
    power: 4.53801
  },
  {
    x: 335.62,
    y: 127.889,
    power: 4.54441
  },
  {
    x: 336.22,
    y: 128.04,
    power: 4.5508
  },
  {
    x: 336.84,
    y: 128.243,
    power: 4.55994
  },
  {
    x: 337.46,
    y: 128.445,
    power: 4.56725
  },
  {
    x: 338.06,
    y: 128.673,
    power: 4.57639
  },
  {
    x: 338.68,
    y: 128.85,
    power: 4.5837
  },
  {
    x: 339.28,
    y: 129.027,
    power: 4.59284
  },
  {
    x: 339.88,
    y: 129.229,
    power: 4.59832
  },
  {
    x: 340.5,
    y: 129.456,
    power: 4.6102
  },
  {
    x: 341.12,
    y: 129.633,
    power: 4.61568
  },
  {
    x: 341.74,
    y: 129.81,
    power: 4.62208
  },
  {
    x: 342.36,
    y: 130.038,
    power: 4.62939
  },
  {
    x: 342.98,
    y: 130.215,
    power: 4.63944
  },
  {
    x: 343.6,
    y: 130.417,
    power: 4.64767
  },
  {
    x: 344.22,
    y: 130.594,
    power: 4.65498
  },
  {
    x: 344.84,
    y: 130.796,
    power: 4.66412
  },
  {
    x: 345.46,
    y: 130.999,
    power: 4.67143
  },
  {
    x: 346.06,
    y: 131.226,
    power: 4.676
  },
  {
    x: 346.66,
    y: 131.353,
    power: 4.68788
  },
  {
    x: 347.26,
    y: 131.555,
    power: 4.69519
  },
  {
    x: 347.88,
    y: 131.732,
    power: 4.70158
  },
  {
    x: 348.5,
    y: 131.96,
    power: 4.70798
  },
  {
    x: 349.12,
    y: 132.137,
    power: 4.71803
  },
  {
    x: 349.74,
    y: 132.339,
    power: 4.72717
  },
  {
    x: 350.34,
    y: 132.516,
    power: 4.73357
  },
  {
    x: 350.94,
    y: 132.693,
    power: 4.74088
  },
  {
    x: 351.54,
    y: 132.87,
    power: 4.7491
  },
  {
    x: 352.16,
    y: 133.072,
    power: 4.75915
  },
  {
    x: 352.78,
    y: 133.274,
    power: 4.76646
  },
  {
    x: 353.4,
    y: 133.477,
    power: 4.7756
  },
  {
    x: 354.02,
    y: 133.654,
    power: 4.78109
  },
  {
    x: 354.64,
    y: 133.831,
    power: 4.78931
  },
  {
    x: 355.26,
    y: 134.033,
    power: 4.79571
  },
  {
    x: 355.88,
    y: 134.21,
    power: 4.80667
  },
  {
    x: 356.48,
    y: 134.412,
    power: 4.81398
  },
  {
    x: 357.08,
    y: 134.614,
    power: 4.82312
  },
  {
    x: 357.68,
    y: 134.817,
    power: 4.82678
  },
  {
    x: 358.3,
    y: 135.019,
    power: 4.83774
  },
  {
    x: 358.92,
    y: 135.196,
    power: 4.84688
  },
  {
    x: 359.54,
    y: 135.424,
    power: 4.85419
  },
  {
    x: 360.16,
    y: 135.626,
    power: 4.86242
  },
  {
    x: 360.76,
    y: 135.803,
    power: 4.86973
  },
  {
    x: 361.36,
    y: 135.98,
    power: 4.87795
  },
  {
    x: 361.96,
    y: 136.182,
    power: 4.88709
  },
  {
    x: 362.58,
    y: 136.41,
    power: 4.89348
  },
  {
    x: 363.2,
    y: 136.612,
    power: 4.90354
  },
  {
    x: 363.82,
    y: 136.814,
    power: 4.91176
  },
  {
    x: 364.44,
    y: 136.991,
    power: 4.91907
  },
  {
    x: 365.04,
    y: 137.244,
    power: 4.9273
  },
  {
    x: 365.66,
    y: 137.446,
    power: 4.93461
  },
  {
    x: 366.26,
    y: 137.649,
    power: 4.93918
  },
  {
    x: 366.86,
    y: 137.826,
    power: 4.94923
  },
  {
    x: 367.48,
    y: 138.053,
    power: 4.95837
  },
  {
    x: 368.08,
    y: 138.205,
    power: 4.96385
  },
  {
    x: 368.7,
    y: 138.382,
    power: 4.97116
  },
  {
    x: 369.32,
    y: 138.483,
    power: 4.98121
  },
  {
    x: 369.92,
    y: 138.685,
    power: 4.98944
  },
  {
    x: 370.52,
    y: 138.837,
    power: 4.99675
  },
  {
    x: 371.14,
    y: 139.09,
    power: 5.00406
  },
  {
    x: 371.74,
    y: 139.267,
    power: 5.01045
  },
  {
    x: 372.36,
    y: 139.494,
    power: 5.01868
  },
  {
    x: 372.98,
    y: 139.671,
    power: 5.0269
  },
  {
    x: 373.6,
    y: 139.874,
    power: 5.03604
  },
  {
    x: 374.22,
    y: 140.076,
    power: 5.04426
  },
  {
    x: 374.84,
    y: 140.253,
    power: 5.05249
  },
  {
    x: 375.46,
    y: 140.48,
    power: 5.06071
  },
  {
    x: 376.08,
    y: 140.657,
    power: 5.06894
  },
  {
    x: 376.68,
    y: 140.834,
    power: 5.07808
  },
  {
    x: 377.28,
    y: 141.037,
    power: 5.08447
  },
  {
    x: 377.88,
    y: 141.214,
    power: 5.09361
  },
  {
    x: 378.5,
    y: 141.441,
    power: 5.10092
  },
  {
    x: 379.12,
    y: 141.618,
    power: 5.10732
  },
  {
    x: 379.74,
    y: 141.82,
    power: 5.11463
  },
  {
    x: 380.34,
    y: 141.997,
    power: 5.12194
  },
  {
    x: 380.94,
    y: 142.2,
    power: 5.12925
  },
  {
    x: 381.54,
    y: 142.427,
    power: 5.13839
  },
  {
    x: 382.16,
    y: 142.604,
    power: 5.15027
  },
  {
    x: 382.78,
    y: 142.832,
    power: 5.15849
  },
  {
    x: 383.4,
    y: 142.984,
    power: 5.1658
  },
  {
    x: 384.02,
    y: 143.211,
    power: 5.16946
  },
  {
    x: 384.64,
    y: 143.388,
    power: 5.17677
  },
  {
    x: 385.24,
    y: 143.565,
    power: 5.18408
  },
  {
    x: 385.86,
    y: 143.767,
    power: 5.1923
  },
  {
    x: 386.46,
    y: 143.97,
    power: 5.20144
  },
  {
    x: 387.06,
    y: 144.147,
    power: 5.20875
  },
  {
    x: 387.68,
    y: 144.324,
    power: 5.2188
  },
  {
    x: 388.3,
    y: 144.526,
    power: 5.2252
  },
  {
    x: 388.92,
    y: 144.753,
    power: 5.23708
  },
  {
    x: 389.54,
    y: 144.981,
    power: 5.24348
  },
  {
    x: 390.16,
    y: 145.107,
    power: 5.24805
  },
  {
    x: 390.76,
    y: 145.31,
    power: 5.25536
  },
  {
    x: 391.36,
    y: 145.487,
    power: 5.26358
  },
  {
    x: 391.96,
    y: 145.664,
    power: 5.27272
  },
  {
    x: 392.58,
    y: 145.891,
    power: 5.28094
  },
  {
    x: 393.2,
    y: 146.068,
    power: 5.28917
  },
  {
    x: 393.82,
    y: 146.271,
    power: 5.29739
  },
  {
    x: 394.44,
    y: 146.473,
    power: 5.30836
  },
  {
    x: 395.04,
    y: 146.675,
    power: 5.31384
  },
  {
    x: 395.64,
    y: 146.852,
    power: 5.31932
  },
  {
    x: 396.26,
    y: 147.054,
    power: 5.32572
  },
  {
    x: 396.86,
    y: 147.282,
    power: 5.33394
  },
  {
    x: 397.48,
    y: 147.459,
    power: 5.34308
  },
  {
    x: 398.08,
    y: 147.636,
    power: 5.35131
  },
  {
    x: 398.7,
    y: 147.813,
    power: 5.36045
  },
  {
    x: 399.32,
    y: 148.04,
    power: 5.36776
  },
  {
    x: 399.92,
    y: 148.243,
    power: 5.37689
  },
  {
    x: 400.52,
    y: 148.394,
    power: 5.38512
  },
  {
    x: 401.14,
    y: 148.597,
    power: 5.39243
  },
  {
    x: 401.74,
    y: 148.799,
    power: 5.39974
  },
  {
    x: 402.36,
    y: 149.001,
    power: 5.40614
  },
  {
    x: 402.96,
    y: 149.204,
    power: 5.41436
  },
  {
    x: 403.58,
    y: 149.381,
    power: 5.42441
  },
  {
    x: 404.18,
    y: 149.583,
    power: 5.43081
  },
  {
    x: 404.78,
    y: 149.785,
    power: 5.43538
  },
  {
    x: 405.38,
    y: 149.962,
    power: 5.44634
  },
  {
    x: 406,
    y: 150.164,
    power: 5.45457
  },
  {
    x: 406.62,
    y: 150.367,
    power: 5.46279
  },
  {
    x: 407.24,
    y: 150.594,
    power: 5.47193
  },
  {
    x: 407.86,
    y: 150.796,
    power: 5.4765
  },
  {
    x: 408.48,
    y: 150.973,
    power: 5.4829
  },
  {
    x: 409.1,
    y: 151.125,
    power: 5.49203
  },
  {
    x: 409.7,
    y: 151.277,
    power: 5.50026
  },
  {
    x: 410.32,
    y: 151.504,
    power: 5.51122
  },
  {
    x: 410.92,
    y: 151.681,
    power: 5.51762
  },
  {
    x: 411.52,
    y: 151.909,
    power: 5.52493
  },
  {
    x: 412.14,
    y: 152.111,
    power: 5.53407
  },
  {
    x: 412.76,
    y: 152.288,
    power: 5.54138
  },
  {
    x: 413.38,
    y: 152.516,
    power: 5.54595
  },
  {
    x: 413.98,
    y: 152.693,
    power: 5.55783
  },
  {
    x: 414.58,
    y: 152.92,
    power: 5.56423
  },
  {
    x: 415.18,
    y: 153.072,
    power: 5.56971
  },
  {
    x: 415.78,
    y: 153.249,
    power: 5.57976
  },
  {
    x: 416.4,
    y: 153.451,
    power: 5.58616
  },
  {
    x: 417,
    y: 153.654,
    power: 5.59073
  },
  {
    x: 417.62,
    y: 153.856,
    power: 5.60352
  },
  {
    x: 418.22,
    y: 154.008,
    power: 5.61266
  },
  {
    x: 418.82,
    y: 154.235,
    power: 5.62088
  },
  {
    x: 419.44,
    y: 154.463,
    power: 5.62819
  },
  {
    x: 420.06,
    y: 154.64,
    power: 5.63368
  },
  {
    x: 420.68,
    y: 154.867,
    power: 5.64099
  },
  {
    x: 421.3,
    y: 155.07,
    power: 5.64921
  },
  {
    x: 421.92,
    y: 155.272,
    power: 5.65652
  },
  {
    x: 422.54,
    y: 155.449,
    power: 5.66566
  },
  {
    x: 423.16,
    y: 155.626,
    power: 5.67663
  },
  {
    x: 423.76,
    y: 155.828,
    power: 5.68302
  },
  {
    x: 424.36,
    y: 156.005,
    power: 5.69033
  },
  {
    x: 424.96,
    y: 156.233,
    power: 5.69582
  },
  {
    x: 425.56,
    y: 156.41,
    power: 5.70404
  },
  {
    x: 426.18,
    y: 156.662,
    power: 5.71135
  },
  {
    x: 426.8,
    y: 156.89,
    power: 5.71957
  },
  {
    x: 427.42,
    y: 157.067,
    power: 5.72689
  },
  {
    x: 428.02,
    y: 157.269,
    power: 5.73511
  },
  {
    x: 428.64,
    y: 157.446,
    power: 5.74333
  },
  {
    x: 429.24,
    y: 157.649,
    power: 5.74973
  },
  {
    x: 429.84,
    y: 157.826,
    power: 5.76161
  },
  {
    x: 430.46,
    y: 158.003,
    power: 5.76892
  },
  {
    x: 431.08,
    y: 158.18,
    power: 5.77623
  },
  {
    x: 431.7,
    y: 158.357,
    power: 5.78354
  },
  {
    x: 432.32,
    y: 158.584,
    power: 5.79268
  },
  {
    x: 432.92,
    y: 158.786,
    power: 5.79816
  },
  {
    x: 433.52,
    y: 158.989,
    power: 5.80913
  },
  {
    x: 434.12,
    y: 159.166,
    power: 5.81187
  },
  {
    x: 434.74,
    y: 159.368,
    power: 5.82101
  },
  {
    x: 435.36,
    y: 159.595,
    power: 5.83015
  },
  {
    x: 435.98,
    y: 159.772,
    power: 5.83746
  },
  {
    x: 436.6,
    y: 160,
    power: 5.84568
  },
  {
    x: 437.22,
    y: 160.126,
    power: 5.85665
  },
  {
    x: 437.82,
    y: 160.354,
    power: 5.86213
  },
  {
    x: 438.42,
    y: 160.506,
    power: 5.86853
  },
  {
    x: 439.04,
    y: 160.708,
    power: 5.87766
  },
  {
    x: 439.64,
    y: 160.91,
    power: 5.88223
  },
  {
    x: 440.24,
    y: 161.087,
    power: 5.89229
  },
  {
    x: 440.86,
    y: 161.34,
    power: 5.90051
  },
  {
    x: 441.48,
    y: 161.492,
    power: 5.90965
  },
  {
    x: 442.1,
    y: 161.719,
    power: 5.9197
  },
  {
    x: 442.72,
    y: 161.896,
    power: 5.92701
  },
  {
    x: 443.32,
    y: 162.099,
    power: 5.93432
  },
  {
    x: 443.92,
    y: 162.276,
    power: 5.9398
  },
  {
    x: 444.52,
    y: 162.503,
    power: 5.94986
  },
  {
    x: 445.14,
    y: 162.655,
    power: 5.95534
  },
  {
    x: 445.76,
    y: 162.882,
    power: 5.96356
  },
  {
    x: 446.38,
    y: 163.11,
    power: 5.97362
  },
  {
    x: 447,
    y: 163.312,
    power: 5.98275
  },
  {
    x: 447.6,
    y: 163.515,
    power: 5.99189
  },
  {
    x: 448.2,
    y: 163.717,
    power: 5.99463
  },
  {
    x: 448.8,
    y: 163.869,
    power: 6.00469
  },
  {
    x: 449.42,
    y: 164.121,
    power: 6.012
  },
  {
    x: 450.04,
    y: 164.273,
    power: 6.01839
  },
  {
    x: 450.66,
    y: 164.45,
    power: 6.02662
  },
  {
    x: 451.28,
    y: 164.627,
    power: 6.03393
  },
  {
    x: 451.9,
    y: 164.829,
    power: 6.04489
  },
  {
    x: 452.5,
    y: 165.082,
    power: 6.05038
  },
  {
    x: 453.1,
    y: 165.259,
    power: 6.05769
  },
  {
    x: 453.7,
    y: 165.461,
    power: 6.06682
  },
  {
    x: 454.32,
    y: 165.664,
    power: 6.07596
  },
  {
    x: 454.94,
    y: 165.866,
    power: 6.08236
  },
  {
    x: 455.56,
    y: 166.068,
    power: 6.08967
  },
  {
    x: 456.16,
    y: 166.271,
    power: 6.09424
  },
  {
    x: 456.76,
    y: 166.498,
    power: 6.10155
  },
  {
    x: 457.36,
    y: 166.675,
    power: 6.11343
  },
  {
    x: 457.96,
    y: 166.928,
    power: 6.12257
  },
  {
    x: 458.58,
    y: 167.054,
    power: 6.13079
  },
  {
    x: 459.18,
    y: 167.282,
    power: 6.1381
  },
  {
    x: 459.8,
    y: 167.509,
    power: 6.1445
  },
  {
    x: 460.42,
    y: 167.737,
    power: 6.15272
  },
  {
    x: 461.04,
    y: 167.914,
    power: 6.16003
  },
  {
    x: 461.64,
    y: 168.116,
    power: 6.16734
  },
  {
    x: 462.24,
    y: 168.293,
    power: 6.17465
  },
  {
    x: 462.86,
    y: 168.496,
    power: 6.18379
  },
  {
    x: 463.46,
    y: 168.698,
    power: 6.18928
  },
  {
    x: 464.08,
    y: 168.9,
    power: 6.20024
  },
  {
    x: 464.7,
    y: 169.077,
    power: 6.20755
  },
  {
    x: 465.3,
    y: 169.305,
    power: 6.21578
  },
  {
    x: 465.9,
    y: 169.507,
    power: 6.22674
  },
  {
    x: 466.5,
    y: 169.709,
    power: 6.23405
  },
  {
    x: 467.12,
    y: 169.911,
    power: 6.23954
  },
  {
    x: 467.74,
    y: 170.139,
    power: 6.24776
  },
  {
    x: 468.36,
    y: 170.341,
    power: 6.25416
  },
  {
    x: 468.96,
    y: 170.544,
    power: 6.26055
  },
  {
    x: 469.58,
    y: 170.721,
    power: 6.27152
  },
  {
    x: 470.2,
    y: 170.948,
    power: 6.27974
  },
  {
    x: 470.8,
    y: 171.15,
    power: 6.28523
  },
  {
    x: 471.42,
    y: 171.327,
    power: 6.29619
  },
  {
    x: 472.02,
    y: 171.504,
    power: 6.29985
  },
  {
    x: 472.62,
    y: 171.732,
    power: 6.31081
  },
  {
    x: 473.24,
    y: 171.96,
    power: 6.31812
  },
  {
    x: 473.86,
    y: 172.187,
    power: 6.32543
  },
  {
    x: 474.48,
    y: 172.364,
    power: 6.33275
  },
  {
    x: 475.1,
    y: 172.566,
    power: 6.34188
  },
  {
    x: 475.7,
    y: 172.769,
    power: 6.34645
  },
  {
    x: 476.3,
    y: 172.946,
    power: 6.35742
  },
  {
    x: 476.92,
    y: 173.097,
    power: 6.36473
  },
  {
    x: 477.52,
    y: 173.274,
    power: 6.37295
  },
  {
    x: 478.14,
    y: 173.477,
    power: 6.38301
  },
  {
    x: 478.76,
    y: 173.704,
    power: 6.39123
  },
  {
    x: 479.38,
    y: 173.881,
    power: 6.39763
  },
  {
    x: 480,
    y: 174.083,
    power: 6.40311
  },
  {
    x: 480.6,
    y: 174.286,
    power: 6.40768
  },
  {
    x: 481.2,
    y: 174.513,
    power: 6.41499
  },
  {
    x: 481.8,
    y: 174.741,
    power: 6.42595
  },
  {
    x: 482.42,
    y: 174.943,
    power: 6.43509
  },
  {
    x: 483.04,
    y: 175.145,
    power: 6.44149
  },
  {
    x: 483.66,
    y: 175.322,
    power: 6.44789
  },
  {
    x: 484.28,
    y: 175.55,
    power: 6.45977
  },
  {
    x: 484.9,
    y: 175.727,
    power: 6.46708
  },
  {
    x: 485.5,
    y: 175.929,
    power: 6.47347
  },
  {
    x: 486.1,
    y: 176.157,
    power: 6.48353
  },
  {
    x: 486.7,
    y: 176.359,
    power: 6.48718
  },
  {
    x: 487.3,
    y: 176.612,
    power: 6.49632
  },
  {
    x: 487.92,
    y: 176.764,
    power: 6.50546
  },
  {
    x: 488.52,
    y: 176.991,
    power: 6.51277
  },
  {
    x: 489.14,
    y: 177.168,
    power: 6.52099
  },
  {
    x: 489.74,
    y: 177.421,
    power: 6.52922
  },
  {
    x: 490.34,
    y: 177.573,
    power: 6.53378
  },
  {
    x: 490.96,
    y: 177.775,
    power: 6.54292
  },
  {
    x: 491.56,
    y: 177.977,
    power: 6.55115
  },
  {
    x: 492.18,
    y: 178.154,
    power: 6.55937
  },
  {
    x: 492.78,
    y: 178.382,
    power: 6.5676
  },
  {
    x: 493.4,
    y: 178.559,
    power: 6.57765
  },
  {
    x: 494,
    y: 178.761,
    power: 6.58404
  },
  {
    x: 494.62,
    y: 178.989,
    power: 6.58587
  },
  {
    x: 495.22,
    y: 179.191,
    power: 6.59592
  },
  {
    x: 495.84,
    y: 179.368,
    power: 6.6078
  },
  {
    x: 496.46,
    y: 179.595,
    power: 6.6142
  },
  {
    x: 497.06,
    y: 179.823,
    power: 6.62334
  },
  {
    x: 497.68,
    y: 180,
    power: 6.62699
  },
  {
    x: 498.3,
    y: 180.202,
    power: 6.63339
  },
  {
    x: 498.9,
    y: 180.379,
    power: 6.64253
  },
  {
    x: 499.5,
    y: 180.632,
    power: 6.64984
  },
  {
    x: 500.12,
    y: 180.834,
    power: 6.65806
  },
  {
    x: 500.72,
    y: 181.037,
    power: 6.66629
  },
  {
    x: 501.34,
    y: 181.239,
    power: 6.67725
  },
  {
    x: 501.96,
    y: 181.441,
    power: 6.68365
  },
  {
    x: 502.56,
    y: 181.669,
    power: 6.69279
  },
  {
    x: 503.16,
    y: 181.871,
    power: 6.69736
  },
  {
    x: 503.76,
    y: 182.099,
    power: 6.70558
  },
  {
    x: 504.38,
    y: 182.276,
    power: 6.71289
  },
  {
    x: 505,
    y: 182.503,
    power: 6.7202
  },
  {
    x: 505.62,
    y: 182.705,
    power: 6.72751
  },
  {
    x: 506.24,
    y: 182.933,
    power: 6.73665
  },
  {
    x: 506.86,
    y: 183.135,
    power: 6.74305
  },
  {
    x: 507.48,
    y: 183.338,
    power: 6.75584
  },
  {
    x: 508.1,
    y: 183.54,
    power: 6.76224
  },
  {
    x: 508.7,
    y: 183.742,
    power: 6.76772
  },
  {
    x: 509.3,
    y: 183.944,
    power: 6.77777
  },
  {
    x: 509.9,
    y: 184.172,
    power: 6.78234
  },
  {
    x: 510.5,
    y: 184.399,
    power: 6.79057
  },
  {
    x: 511.12,
    y: 184.602,
    power: 6.79696
  },
  {
    x: 511.74,
    y: 184.804,
    power: 6.80519
  },
  {
    x: 512.36,
    y: 185.006,
    power: 6.81615
  },
  {
    x: 512.96,
    y: 185.209,
    power: 6.82072
  },
  {
    x: 513.58,
    y: 185.411,
    power: 6.83169
  },
  {
    x: 514.2,
    y: 185.638,
    power: 6.83809
  },
  {
    x: 514.8,
    y: 185.841,
    power: 6.84631
  },
  {
    x: 515.42,
    y: 186.018,
    power: 6.85636
  },
  {
    x: 516.02,
    y: 186.22,
    power: 6.86093
  },
  {
    x: 516.64,
    y: 186.422,
    power: 6.87098
  },
  {
    x: 517.24,
    y: 186.599,
    power: 6.87647
  },
  {
    x: 517.84,
    y: 186.827,
    power: 6.8856
  },
  {
    x: 518.44,
    y: 187.029,
    power: 6.892
  },
  {
    x: 519.06,
    y: 187.231,
    power: 6.89931
  },
  {
    x: 519.68,
    y: 187.408,
    power: 6.90845
  },
  {
    x: 520.28,
    y: 187.661,
    power: 6.91667
  },
  {
    x: 520.9,
    y: 187.838,
    power: 6.92124
  },
  {
    x: 521.52,
    y: 188.04,
    power: 6.93129
  },
  {
    x: 522.12,
    y: 188.243,
    power: 6.93861
  },
  {
    x: 522.74,
    y: 188.445,
    power: 6.94683
  },
  {
    x: 523.36,
    y: 188.698,
    power: 6.95414
  },
  {
    x: 523.96,
    y: 188.9,
    power: 6.96419
  },
  {
    x: 524.56,
    y: 189.077,
    power: 6.96968
  },
  {
    x: 525.18,
    y: 189.279,
    power: 6.9779
  },
  {
    x: 525.8,
    y: 189.532,
    power: 6.98521
  },
  {
    x: 526.42,
    y: 189.76,
    power: 6.99435
  },
  {
    x: 527.02,
    y: 189.987,
    power: 7.00075
  },
  {
    x: 527.62,
    y: 190.164,
    power: 7.00531
  },
  {
    x: 528.22,
    y: 190.392,
    power: 7.01262
  },
  {
    x: 528.84,
    y: 190.594,
    power: 7.01994
  },
  {
    x: 529.46,
    y: 190.796,
    power: 7.02907
  },
  {
    x: 530.06,
    y: 190.898,
    power: 7.03912
  },
  {
    x: 530.68,
    y: 191.125,
    power: 7.04552
  },
  {
    x: 531.28,
    y: 191.302,
    power: 7.05375
  },
  {
    x: 531.88,
    y: 191.53,
    power: 7.06197
  },
  {
    x: 532.48,
    y: 191.681,
    power: 7.06928
  },
  {
    x: 533.1,
    y: 191.96,
    power: 7.07842
  },
  {
    x: 533.72,
    y: 192.162,
    power: 7.08664
  },
  {
    x: 534.34,
    y: 192.389,
    power: 7.09304
  },
  {
    x: 534.96,
    y: 192.566,
    power: 7.10218
  },
  {
    x: 535.58,
    y: 192.769,
    power: 7.11132
  },
  {
    x: 536.18,
    y: 192.996,
    power: 7.11771
  },
  {
    x: 536.8,
    y: 193.224,
    power: 7.12502
  },
  {
    x: 537.4,
    y: 193.401,
    power: 7.13325
  },
  {
    x: 538,
    y: 193.603,
    power: 7.14239
  },
  {
    x: 538.62,
    y: 193.78,
    power: 7.15061
  },
  {
    x: 539.24,
    y: 194.083,
    power: 7.15883
  },
  {
    x: 539.86,
    y: 194.26,
    power: 7.1634
  },
  {
    x: 540.46,
    y: 194.437,
    power: 7.17254
  },
  {
    x: 541.06,
    y: 194.614,
    power: 7.17803
  },
  {
    x: 541.66,
    y: 194.893,
    power: 7.18899
  },
  {
    x: 542.28,
    y: 195.07,
    power: 7.19447
  },
  {
    x: 542.9,
    y: 195.297,
    power: 7.2027
  },
  {
    x: 543.5,
    y: 195.424,
    power: 7.21366
  },
  {
    x: 544.12,
    y: 195.676,
    power: 7.21915
  },
  {
    x: 544.74,
    y: 195.904,
    power: 7.23011
  },
  {
    x: 545.36,
    y: 196.106,
    power: 7.23468
  },
  {
    x: 545.96,
    y: 196.359,
    power: 7.24108
  },
  {
    x: 546.56,
    y: 196.561,
    power: 7.2493
  },
  {
    x: 547.16,
    y: 196.839,
    power: 7.2557
  },
  {
    x: 547.78,
    y: 197.042,
    power: 7.26575
  },
  {
    x: 548.4,
    y: 197.269,
    power: 7.27398
  },
  {
    x: 549,
    y: 197.446,
    power: 7.28037
  },
  {
    x: 549.62,
    y: 197.724,
    power: 7.29225
  },
  {
    x: 550.22,
    y: 197.876,
    power: 7.29865
  },
  {
    x: 550.82,
    y: 198.053,
    power: 7.30596
  },
  {
    x: 551.44,
    y: 198.255,
    power: 7.31236
  },
  {
    x: 552.04,
    y: 198.458,
    power: 7.32058
  },
  {
    x: 552.66,
    y: 198.711,
    power: 7.32972
  },
  {
    x: 553.28,
    y: 198.913,
    power: 7.33794
  },
  {
    x: 553.9,
    y: 199.09,
    power: 7.34434
  },
  {
    x: 554.52,
    y: 199.292,
    power: 7.35713
  },
  {
    x: 555.14,
    y: 199.494,
    power: 7.36353
  },
  {
    x: 555.74,
    y: 199.722,
    power: 7.37084
  },
  {
    x: 556.34,
    y: 199.899,
    power: 7.38089
  },
  {
    x: 556.94,
    y: 200.101,
    power: 7.39003
  },
  {
    x: 557.56,
    y: 200.278,
    power: 7.39186
  },
  {
    x: 558.18,
    y: 200.506,
    power: 7.40282
  },
  {
    x: 558.8,
    y: 200.708,
    power: 7.40922
  },
  {
    x: 559.42,
    y: 200.936,
    power: 7.41836
  },
  {
    x: 560.02,
    y: 201.113,
    power: 7.42841
  },
  {
    x: 560.64,
    y: 201.29,
    power: 7.43755
  },
  {
    x: 561.24,
    y: 201.492,
    power: 7.44669
  },
  {
    x: 561.86,
    y: 201.694,
    power: 7.454
  },
  {
    x: 562.48,
    y: 201.896,
    power: 7.46314
  },
  {
    x: 563.08,
    y: 202.099,
    power: 7.47227
  },
  {
    x: 563.7,
    y: 202.301,
    power: 7.47958
  },
  {
    x: 564.32,
    y: 202.528,
    power: 7.48872
  },
  {
    x: 564.94,
    y: 202.705,
    power: 7.49786
  },
  {
    x: 565.54,
    y: 202.933,
    power: 7.50334
  },
  {
    x: 566.14,
    y: 203.11,
    power: 7.51431
  },
  {
    x: 566.74,
    y: 203.287,
    power: 7.51796
  },
  {
    x: 567.36,
    y: 203.515,
    power: 7.53076
  },
  {
    x: 567.98,
    y: 203.717,
    power: 7.5399
  },
  {
    x: 568.6,
    y: 203.894,
    power: 7.54721
  },
  {
    x: 569.22,
    y: 204.096,
    power: 7.55452
  },
  {
    x: 569.82,
    y: 204.298,
    power: 7.56366
  },
  {
    x: 570.42,
    y: 204.501,
    power: 7.57005
  },
  {
    x: 571.02,
    y: 204.703,
    power: 7.5801
  },
  {
    x: 571.64,
    y: 204.905,
    power: 7.58924
  },
  {
    x: 572.26,
    y: 205.082,
    power: 7.59473
  },
  {
    x: 572.88,
    y: 205.284,
    power: 7.60295
  },
  {
    x: 573.5,
    y: 205.487,
    power: 7.61392
  },
  {
    x: 574.12,
    y: 205.664,
    power: 7.62214
  },
  {
    x: 574.74,
    y: 205.866,
    power: 7.63128
  },
  {
    x: 575.36,
    y: 206.043,
    power: 7.6395
  },
  {
    x: 575.96,
    y: 206.271,
    power: 7.64864
  },
  {
    x: 576.56,
    y: 206.473,
    power: 7.65412
  },
  {
    x: 577.16,
    y: 206.675,
    power: 7.66235
  },
  {
    x: 577.78,
    y: 206.877,
    power: 7.6724
  },
  {
    x: 578.4,
    y: 207.105,
    power: 7.68062
  },
  {
    x: 579,
    y: 207.358,
    power: 7.68976
  },
  {
    x: 579.62,
    y: 207.535,
    power: 7.69981
  },
  {
    x: 580.22,
    y: 207.737,
    power: 7.70895
  },
  {
    x: 580.82,
    y: 207.939,
    power: 7.71444
  },
  {
    x: 581.44,
    y: 208.192,
    power: 7.7254
  },
  {
    x: 582.04,
    y: 208.319,
    power: 7.7318
  },
  {
    x: 582.66,
    y: 208.47,
    power: 7.73819
  },
  {
    x: 583.28,
    y: 208.622,
    power: 7.74642
  },
  {
    x: 583.9,
    y: 208.85,
    power: 7.75556
  },
  {
    x: 584.5,
    y: 209.027,
    power: 7.7647
  },
  {
    x: 585.1,
    y: 209.254,
    power: 7.77383
  },
  {
    x: 585.7,
    y: 209.406,
    power: 7.78206
  },
  {
    x: 586.3,
    y: 209.633,
    power: 7.7912
  },
  {
    x: 586.92,
    y: 209.861,
    power: 7.79851
  },
  {
    x: 587.54,
    y: 210.038,
    power: 7.80582
  },
  {
    x: 588.16,
    y: 210.24,
    power: 7.81404
  },
  {
    x: 588.76,
    y: 210.468,
    power: 7.82501
  },
  {
    x: 589.36,
    y: 210.695,
    power: 7.8314
  },
  {
    x: 589.96,
    y: 210.872,
    power: 7.84054
  },
  {
    x: 590.58,
    y: 211.049,
    power: 7.84785
  },
  {
    x: 591.2,
    y: 211.252,
    power: 7.85699
  },
  {
    x: 591.82,
    y: 211.479,
    power: 7.86796
  },
  {
    x: 592.44,
    y: 211.732,
    power: 7.87709
  },
  {
    x: 593.04,
    y: 211.909,
    power: 7.88441
  },
  {
    x: 593.66,
    y: 212.137,
    power: 7.88989
  },
  {
    x: 594.26,
    y: 212.314,
    power: 7.89811
  },
  {
    x: 594.86,
    y: 212.566,
    power: 7.90816
  },
  {
    x: 595.48,
    y: 212.693,
    power: 7.91639
  },
  {
    x: 596.1,
    y: 212.895,
    power: 7.92279
  },
  {
    x: 596.7,
    y: 213.123,
    power: 7.93192
  },
  {
    x: 597.32,
    y: 213.3,
    power: 7.94289
  },
  {
    x: 597.92,
    y: 213.527,
    power: 7.94837
  },
  {
    x: 598.52,
    y: 213.654,
    power: 7.95751
  },
  {
    x: 599.14,
    y: 213.881,
    power: 7.96665
  },
  {
    x: 599.74,
    y: 214.058,
    power: 7.97487
  },
  {
    x: 600.36,
    y: 214.336,
    power: 7.9831
  },
  {
    x: 600.98,
    y: 214.488,
    power: 7.99041
  },
  {
    x: 601.6,
    y: 214.69,
    power: 7.99955
  },
  {
    x: 602.22,
    y: 214.893,
    power: 8.00594
  },
  {
    x: 602.84,
    y: 215.12,
    power: 8.01417
  },
  {
    x: 603.46,
    y: 215.297,
    power: 8.02605
  },
  {
    x: 604.06,
    y: 215.449,
    power: 8.03427
  },
  {
    x: 604.66,
    y: 215.651,
    power: 8.0425
  },
  {
    x: 605.26,
    y: 215.879,
    power: 8.05072
  },
  {
    x: 605.88,
    y: 216.106,
    power: 8.05529
  },
  {
    x: 606.5,
    y: 216.283,
    power: 8.06717
  },
  {
    x: 607.12,
    y: 216.485,
    power: 8.07539
  },
  {
    x: 607.74,
    y: 216.713,
    power: 8.08636
  },
  {
    x: 608.34,
    y: 216.89,
    power: 8.09276
  },
  {
    x: 608.94,
    y: 217.092,
    power: 8.09915
  },
  {
    x: 609.56,
    y: 217.295,
    power: 8.10464
  },
  {
    x: 610.16,
    y: 217.522,
    power: 8.11651
  },
  {
    x: 610.78,
    y: 217.699,
    power: 8.12565
  },
  {
    x: 611.4,
    y: 217.901,
    power: 8.13662
  },
  {
    x: 612.02,
    y: 218.104,
    power: 8.14119
  },
  {
    x: 612.64,
    y: 218.357,
    power: 8.14941
  },
  {
    x: 613.26,
    y: 218.534,
    power: 8.15855
  },
  {
    x: 613.88,
    y: 218.761,
    power: 8.16677
  },
  {
    x: 614.48,
    y: 218.913,
    power: 8.17683
  },
  {
    x: 615.08,
    y: 219.115,
    power: 8.18505
  },
  {
    x: 615.68,
    y: 219.317,
    power: 8.19053
  },
  {
    x: 616.3,
    y: 219.545,
    power: 8.19967
  },
  {
    x: 616.92,
    y: 219.722,
    power: 8.21155
  },
  {
    x: 617.52,
    y: 219.924,
    power: 8.21795
  },
  {
    x: 618.12,
    y: 220.126,
    power: 8.22526
  },
  {
    x: 618.74,
    y: 220.354,
    power: 8.2344
  },
  {
    x: 619.34,
    y: 220.531,
    power: 8.23897
  },
  {
    x: 619.96,
    y: 220.759,
    power: 8.24719
  },
  {
    x: 620.58,
    y: 220.936,
    power: 8.25907
  },
  {
    x: 621.2,
    y: 221.188,
    power: 8.26547
  },
  {
    x: 621.82,
    y: 221.34,
    power: 8.27643
  },
  {
    x: 622.44,
    y: 221.542,
    power: 8.28374
  },
  {
    x: 623.06,
    y: 221.719,
    power: 8.29288
  },
  {
    x: 623.68,
    y: 221.947,
    power: 8.29928
  },
  {
    x: 624.28,
    y: 222.149,
    power: 8.3075
  },
  {
    x: 624.88,
    y: 222.326,
    power: 8.31664
  },
  {
    x: 625.48,
    y: 222.554,
    power: 8.32395
  },
  {
    x: 626.1,
    y: 222.756,
    power: 8.32943
  },
  {
    x: 626.7,
    y: 222.958,
    power: 8.33857
  },
  {
    x: 627.32,
    y: 223.161,
    power: 8.34771
  },
  {
    x: 627.92,
    y: 223.388,
    power: 8.35776
  },
  {
    x: 628.52,
    y: 223.565,
    power: 8.36507
  },
  {
    x: 629.12,
    y: 223.793,
    power: 8.37147
  },
  {
    x: 629.74,
    y: 223.995,
    power: 8.37878
  },
  {
    x: 630.34,
    y: 224.223,
    power: 8.38975
  },
  {
    x: 630.96,
    y: 224.425,
    power: 8.39888
  },
  {
    x: 631.58,
    y: 224.652,
    power: 8.40711
  },
  {
    x: 632.18,
    y: 224.88,
    power: 8.41259
  },
  {
    x: 632.78,
    y: 225.107,
    power: 8.42356
  },
  {
    x: 633.38,
    y: 225.284,
    power: 8.42721
  },
  {
    x: 633.98,
    y: 225.563,
    power: 8.43818
  },
  {
    x: 634.6,
    y: 225.765,
    power: 8.44823
  },
  {
    x: 635.2,
    y: 225.992,
    power: 8.4592
  },
  {
    x: 635.8,
    y: 226.119,
    power: 8.46194
  },
  {
    x: 636.4,
    y: 226.296,
    power: 8.47016
  },
  {
    x: 637,
    y: 226.473,
    power: 8.48113
  },
  {
    x: 637.62,
    y: 226.675,
    power: 8.4857
  },
  {
    x: 638.24,
    y: 226.903,
    power: 8.49392
  },
  {
    x: 638.86,
    y: 227.13,
    power: 8.50397
  },
  {
    x: 639.48,
    y: 227.332,
    power: 8.51311
  },
  {
    x: 640.08,
    y: 227.56,
    power: 8.52134
  },
  {
    x: 640.68,
    y: 227.712,
    power: 8.52865
  },
  {
    x: 641.3,
    y: 227.965,
    power: 8.53687
  },
  {
    x: 641.9,
    y: 228.167,
    power: 8.54327
  },
  {
    x: 642.52,
    y: 228.394,
    power: 8.55149
  },
  {
    x: 643.14,
    y: 228.571,
    power: 8.56063
  },
  {
    x: 643.76,
    y: 228.774,
    power: 8.56977
  },
  {
    x: 644.38,
    y: 228.951,
    power: 8.57525
  },
  {
    x: 644.98,
    y: 229.178,
    power: 8.58713
  },
  {
    x: 645.58,
    y: 229.381,
    power: 8.59353
  },
  {
    x: 646.2,
    y: 229.608,
    power: 8.60266
  },
  {
    x: 646.82,
    y: 229.861,
    power: 8.61089
  },
  {
    x: 647.44,
    y: 230.038,
    power: 8.61729
  },
  {
    x: 648.06,
    y: 230.291,
    power: 8.62734
  },
  {
    x: 648.68,
    y: 230.468,
    power: 8.63465
  },
  {
    x: 649.3,
    y: 230.721,
    power: 8.64287
  },
  {
    x: 649.92,
    y: 230.898,
    power: 8.6511
  },
  {
    x: 650.54,
    y: 231.125,
    power: 8.65841
  },
  {
    x: 651.16,
    y: 231.327,
    power: 8.66663
  },
  {
    x: 651.76,
    y: 231.504,
    power: 8.67394
  },
  {
    x: 652.36,
    y: 231.732,
    power: 8.68308
  },
  {
    x: 652.96,
    y: 231.934,
    power: 8.69313
  },
  {
    x: 653.56,
    y: 232.162,
    power: 8.70227
  },
  {
    x: 654.18,
    y: 232.364,
    power: 8.70867
  },
  {
    x: 654.8,
    y: 232.541,
    power: 8.71415
  },
  {
    x: 655.42,
    y: 232.743,
    power: 8.72055
  },
  {
    x: 656.02,
    y: 232.971,
    power: 8.73243
  },
  {
    x: 656.62,
    y: 233.148,
    power: 8.73791
  },
  {
    x: 657.22,
    y: 233.375,
    power: 8.74888
  },
  {
    x: 657.84,
    y: 233.578,
    power: 8.7571
  },
  {
    x: 658.46,
    y: 233.729,
    power: 8.76624
  },
  {
    x: 659.08,
    y: 233.982,
    power: 8.77355
  },
  {
    x: 659.7,
    y: 234.21,
    power: 8.7836
  },
  {
    x: 660.32,
    y: 234.437,
    power: 8.78817
  },
  {
    x: 660.94,
    y: 234.64,
    power: 8.79548
  },
  {
    x: 661.54,
    y: 234.842,
    power: 8.80553
  },
  {
    x: 662.14,
    y: 235.019,
    power: 8.81284
  },
  {
    x: 662.74,
    y: 235.221,
    power: 8.82107
  },
  {
    x: 663.36,
    y: 235.449,
    power: 8.8302
  },
  {
    x: 663.98,
    y: 235.601,
    power: 8.84026
  },
  {
    x: 664.6,
    y: 235.803,
    power: 8.84665
  },
  {
    x: 665.2,
    y: 236.03,
    power: 8.85579
  },
  {
    x: 665.8,
    y: 236.283,
    power: 8.86219
  },
  {
    x: 666.4,
    y: 236.46,
    power: 8.86767
  },
  {
    x: 667.02,
    y: 236.662,
    power: 8.88047
  },
  {
    x: 667.64,
    y: 236.865,
    power: 8.88777
  },
  {
    x: 668.24,
    y: 237.092,
    power: 8.89234
  },
  {
    x: 668.86,
    y: 237.295,
    power: 8.90148
  },
  {
    x: 669.48,
    y: 237.573,
    power: 8.91062
  },
  {
    x: 670.08,
    y: 237.8,
    power: 8.92067
  },
  {
    x: 670.68,
    y: 238.053,
    power: 8.93072
  },
  {
    x: 671.28,
    y: 238.281,
    power: 8.93438
  },
  {
    x: 671.88,
    y: 238.483,
    power: 8.93986
  },
  {
    x: 672.5,
    y: 238.711,
    power: 8.949
  },
  {
    x: 673.12,
    y: 238.963,
    power: 8.95723
  },
  {
    x: 673.72,
    y: 239.166,
    power: 8.96454
  },
  {
    x: 674.32,
    y: 239.368,
    power: 8.97367
  },
  {
    x: 674.94,
    y: 239.57,
    power: 8.98281
  },
  {
    x: 675.54,
    y: 239.747,
    power: 8.98829
  },
  {
    x: 676.16,
    y: 239.975,
    power: 8.99835
  },
  {
    x: 676.78,
    y: 240.202,
    power: 9.00657
  },
  {
    x: 677.4,
    y: 240.43,
    power: 9.0148
  },
  {
    x: 678.02,
    y: 240.632,
    power: 9.02119
  },
  {
    x: 678.64,
    y: 240.834,
    power: 9.02668
  },
  {
    x: 679.26,
    y: 241.062,
    power: 9.03673
  },
  {
    x: 679.86,
    y: 241.29,
    power: 9.04495
  },
  {
    x: 680.46,
    y: 241.517,
    power: 9.055
  },
  {
    x: 681.06,
    y: 241.745,
    power: 9.06049
  },
  {
    x: 681.68,
    y: 241.896,
    power: 9.07054
  },
  {
    x: 682.28,
    y: 242.124,
    power: 9.07785
  },
  {
    x: 682.9,
    y: 242.351,
    power: 9.08516
  },
  {
    x: 683.5,
    y: 242.63,
    power: 9.0943
  },
  {
    x: 684.1,
    y: 242.807,
    power: 9.10344
  },
  {
    x: 684.72,
    y: 243.034,
    power: 9.10709
  },
  {
    x: 685.32,
    y: 243.236,
    power: 9.11623
  },
  {
    x: 685.94,
    y: 243.464,
    power: 9.12445
  },
  {
    x: 686.54,
    y: 243.692,
    power: 9.13633
  },
  {
    x: 687.16,
    y: 243.894,
    power: 9.13907
  },
  {
    x: 687.78,
    y: 244.121,
    power: 9.15278
  },
  {
    x: 688.4,
    y: 244.324,
    power: 9.16009
  },
  {
    x: 689,
    y: 244.475,
    power: 9.1674
  },
  {
    x: 689.6,
    y: 244.652,
    power: 9.17654
  },
  {
    x: 690.22,
    y: 244.855,
    power: 9.18111
  },
  {
    x: 690.84,
    y: 245.082,
    power: 9.18842
  },
  {
    x: 691.46,
    y: 245.31,
    power: 9.2003
  },
  {
    x: 692.06,
    y: 245.563,
    power: 9.20578
  },
  {
    x: 692.68,
    y: 245.79,
    power: 9.21401
  },
  {
    x: 693.28,
    y: 245.967,
    power: 9.21949
  },
  {
    x: 693.88,
    y: 246.22,
    power: 9.23137
  },
  {
    x: 694.5,
    y: 246.448,
    power: 9.24051
  },
  {
    x: 695.1,
    y: 246.7,
    power: 9.24965
  },
  {
    x: 695.72,
    y: 246.852,
    power: 9.2533
  },
  {
    x: 696.34,
    y: 247.105,
    power: 9.2597
  },
  {
    x: 696.96,
    y: 247.282,
    power: 9.26792
  },
  {
    x: 697.56,
    y: 247.509,
    power: 9.27797
  },
  {
    x: 698.18,
    y: 247.788,
    power: 9.28711
  },
  {
    x: 698.78,
    y: 247.965,
    power: 9.2926
  },
  {
    x: 699.38,
    y: 248.192,
    power: 9.30265
  },
  {
    x: 699.98,
    y: 248.369,
    power: 9.31087
  },
  {
    x: 700.6,
    y: 248.622,
    power: 9.3191
  },
  {
    x: 701.22,
    y: 248.799,
    power: 9.32549
  },
  {
    x: 701.82,
    y: 249.052,
    power: 9.33189
  },
  {
    x: 702.42,
    y: 249.229,
    power: 9.33829
  },
  {
    x: 703.02,
    y: 249.431,
    power: 9.34742
  },
  {
    x: 703.64,
    y: 249.684,
    power: 9.35565
  },
  {
    x: 704.24,
    y: 249.861,
    power: 9.36479
  },
  {
    x: 704.84,
    y: 250.089,
    power: 9.37484
  },
  {
    x: 705.46,
    y: 250.291,
    power: 9.38124
  },
  {
    x: 706.06,
    y: 250.544,
    power: 9.39129
  },
  {
    x: 706.66,
    y: 250.771,
    power: 9.3986
  },
  {
    x: 707.26,
    y: 250.973,
    power: 9.40591
  },
  {
    x: 707.88,
    y: 251.15,
    power: 9.41139
  },
  {
    x: 708.48,
    y: 251.403,
    power: 9.4187
  },
  {
    x: 709.1,
    y: 251.606,
    power: 9.42875
  },
  {
    x: 709.7,
    y: 251.858,
    power: 9.43515
  },
  {
    x: 710.3,
    y: 252.01,
    power: 9.44246
  },
  {
    x: 710.9,
    y: 252.238,
    power: 9.45251
  },
  {
    x: 711.5,
    y: 252.465,
    power: 9.46165
  },
  {
    x: 712.12,
    y: 252.718,
    power: 9.47079
  },
  {
    x: 712.74,
    y: 252.895,
    power: 9.47993
  },
  {
    x: 713.36,
    y: 253.123,
    power: 9.48632
  },
  {
    x: 713.98,
    y: 253.35,
    power: 9.49272
  },
  {
    x: 714.58,
    y: 253.578,
    power: 9.50186
  },
  {
    x: 715.18,
    y: 253.78,
    power: 9.50734
  },
  {
    x: 715.8,
    y: 254.033,
    power: 9.51739
  },
  {
    x: 716.42,
    y: 254.21,
    power: 9.52379
  },
  {
    x: 717.02,
    y: 254.463,
    power: 9.53384
  },
  {
    x: 717.64,
    y: 254.665,
    power: 9.53933
  },
  {
    x: 718.24,
    y: 254.918,
    power: 9.54938
  },
  {
    x: 718.84,
    y: 255.12,
    power: 9.55852
  },
  {
    x: 719.46,
    y: 255.322,
    power: 9.564
  },
  {
    x: 720.08,
    y: 255.575,
    power: 9.57131
  },
  {
    x: 720.7,
    y: 255.778,
    power: 9.57771
  },
  {
    x: 721.3,
    y: 256.005,
    power: 9.58684
  },
  {
    x: 721.92,
    y: 256.207,
    power: 9.5969
  },
  {
    x: 722.54,
    y: 256.46,
    power: 9.60329
  },
  {
    x: 723.16,
    y: 256.688,
    power: 9.61152
  },
  {
    x: 723.78,
    y: 256.941,
    power: 9.61791
  },
  {
    x: 724.4,
    y: 257.143,
    power: 9.62888
  },
  {
    x: 725,
    y: 257.396,
    power: 9.63528
  },
  {
    x: 725.6,
    y: 257.547,
    power: 9.64259
  },
  {
    x: 726.2,
    y: 257.8,
    power: 9.64807
  },
  {
    x: 726.82,
    y: 258.003,
    power: 9.65538
  },
  {
    x: 727.44,
    y: 258.255,
    power: 9.6636
  },
  {
    x: 728.04,
    y: 258.458,
    power: 9.67366
  },
  {
    x: 728.64,
    y: 258.635,
    power: 9.6828
  },
  {
    x: 729.24,
    y: 258.887,
    power: 9.68736
  },
  {
    x: 729.84,
    y: 259.09,
    power: 9.69376
  },
  {
    x: 730.46,
    y: 259.343,
    power: 9.7029
  },
  {
    x: 731.08,
    y: 259.494,
    power: 9.71112
  },
  {
    x: 731.7,
    y: 259.772,
    power: 9.71843
  },
  {
    x: 732.32,
    y: 259.975,
    power: 9.72849
  },
  {
    x: 732.94,
    y: 260.228,
    power: 9.73488
  },
  {
    x: 733.56,
    y: 260.43,
    power: 9.74311
  },
  {
    x: 734.16,
    y: 260.632,
    power: 9.75133
  },
  {
    x: 734.76,
    y: 260.885,
    power: 9.7559
  },
  {
    x: 735.38,
    y: 261.138,
    power: 9.76778
  },
  {
    x: 736,
    y: 261.34,
    power: 9.77692
  },
  {
    x: 736.62,
    y: 261.542,
    power: 9.78331
  },
  {
    x: 737.24,
    y: 261.82,
    power: 9.7888
  },
  {
    x: 737.86,
    y: 262.048,
    power: 9.79611
  },
  {
    x: 738.48,
    y: 262.301,
    power: 9.8089
  },
  {
    x: 739.1,
    y: 262.503,
    power: 9.81347
  },
  {
    x: 739.7,
    y: 262.756,
    power: 9.82261
  },
  {
    x: 740.3,
    y: 262.958,
    power: 9.82992
  },
  {
    x: 740.92,
    y: 263.11,
    power: 9.8354
  },
  {
    x: 741.52,
    y: 263.312,
    power: 9.84363
  },
  {
    x: 742.14,
    y: 263.515,
    power: 9.85368
  },
  {
    x: 742.76,
    y: 263.742,
    power: 9.86282
  },
  {
    x: 743.38,
    y: 263.97,
    power: 9.87104
  },
  {
    x: 744,
    y: 264.223,
    power: 9.87561
  },
  {
    x: 744.6,
    y: 264.45,
    power: 9.88383
  },
  {
    x: 745.2,
    y: 264.678,
    power: 9.89297
  },
  {
    x: 745.8,
    y: 264.88,
    power: 9.9012
  },
  {
    x: 746.42,
    y: 265.133,
    power: 9.90851
  },
  {
    x: 747.02,
    y: 265.335,
    power: 9.9149
  },
  {
    x: 747.64,
    y: 265.537,
    power: 9.92496
  },
  {
    x: 748.26,
    y: 265.765,
    power: 9.92953
  },
  {
    x: 748.86,
    y: 265.967,
    power: 9.94049
  },
  {
    x: 749.46,
    y: 266.195,
    power: 9.94597
  },
  {
    x: 750.08,
    y: 266.448,
    power: 9.95329
  },
  {
    x: 750.7,
    y: 266.65,
    power: 9.95877
  },
  {
    x: 751.3,
    y: 266.903,
    power: 9.96973
  },
  {
    x: 751.92,
    y: 267.105,
    power: 9.97522
  },
  {
    x: 752.54,
    y: 267.358,
    power: 9.9871
  },
  {
    x: 753.16,
    y: 267.56,
    power: 9.99441
  },
  {
    x: 753.78,
    y: 267.788,
    power: 9.99989
  },
  {
    x: 754.38,
    y: 267.965,
    power: 10.0081
  },
  {
    x: 754.98,
    y: 268.192,
    power: 10.0145
  },
  {
    x: 755.58,
    y: 268.445,
    power: 10.0218
  },
  {
    x: 756.2,
    y: 268.622,
    power: 10.03
  },
  {
    x: 756.82,
    y: 268.9,
    power: 10.0364
  },
  {
    x: 757.44,
    y: 269.077,
    power: 10.0474
  },
  {
    x: 758.06,
    y: 269.381,
    power: 10.0538
  },
  {
    x: 758.66,
    y: 269.558,
    power: 10.0639
  },
  {
    x: 759.26,
    y: 269.785,
    power: 10.0703
  },
  {
    x: 759.86,
    y: 269.987,
    power: 10.0766
  },
  {
    x: 760.48,
    y: 270.265,
    power: 10.084
  },
  {
    x: 761.08,
    y: 270.442,
    power: 10.094
  },
  {
    x: 761.7,
    y: 270.67,
    power: 10.0995
  },
  {
    x: 762.32,
    y: 270.898,
    power: 10.1077
  },
  {
    x: 762.94,
    y: 271.15,
    power: 10.1141
  },
  {
    x: 763.54,
    y: 271.429,
    power: 10.1233
  },
  {
    x: 764.14,
    y: 271.631,
    power: 10.1306
  },
  {
    x: 764.74,
    y: 271.833,
    power: 10.1379
  },
  {
    x: 765.36,
    y: 272.086,
    power: 10.1488
  },
  {
    x: 765.98,
    y: 272.314,
    power: 10.158
  },
  {
    x: 766.6,
    y: 272.541,
    power: 10.1662
  },
  {
    x: 767.22,
    y: 272.769,
    power: 10.1699
  },
  {
    x: 767.82,
    y: 272.996,
    power: 10.1781
  },
  {
    x: 768.42,
    y: 273.224,
    power: 10.1863
  },
  {
    x: 769.02,
    y: 273.451,
    power: 10.1936
  },
  {
    x: 769.64,
    y: 273.679,
    power: 10.2018
  },
  {
    x: 770.26,
    y: 273.932,
    power: 10.2092
  },
  {
    x: 770.86,
    y: 274.185,
    power: 10.2137
  },
  {
    x: 771.48,
    y: 274.362,
    power: 10.2229
  },
  {
    x: 772.1,
    y: 274.589,
    power: 10.2347
  },
  {
    x: 772.7,
    y: 274.817,
    power: 10.2411
  },
  {
    x: 773.3,
    y: 275.07,
    power: 10.2494
  },
  {
    x: 773.92,
    y: 275.272,
    power: 10.2548
  },
  {
    x: 774.52,
    y: 275.499,
    power: 10.2594
  },
  {
    x: 775.14,
    y: 275.702,
    power: 10.2704
  },
  {
    x: 775.76,
    y: 275.929,
    power: 10.2768
  },
  {
    x: 776.38,
    y: 276.182,
    power: 10.2841
  },
  {
    x: 776.98,
    y: 276.384,
    power: 10.2905
  },
  {
    x: 777.58,
    y: 276.587,
    power: 10.2996
  },
  {
    x: 778.2,
    y: 276.814,
    power: 10.3051
  },
  {
    x: 778.8,
    y: 277.016,
    power: 10.3161
  },
  {
    x: 779.42,
    y: 277.269,
    power: 10.3234
  },
  {
    x: 780.04,
    y: 277.472,
    power: 10.3307
  },
  {
    x: 780.66,
    y: 277.699,
    power: 10.3371
  },
  {
    x: 781.28,
    y: 277.927,
    power: 10.3453
  },
  {
    x: 781.9,
    y: 278.154,
    power: 10.3544
  },
  {
    x: 782.5,
    y: 278.382,
    power: 10.3618
  },
  {
    x: 783.1,
    y: 278.609,
    power: 10.3663
  },
  {
    x: 783.7,
    y: 278.837,
    power: 10.3755
  },
  {
    x: 784.3,
    y: 279.064,
    power: 10.3837
  },
  {
    x: 784.92,
    y: 279.292,
    power: 10.391
  },
  {
    x: 785.54,
    y: 279.52,
    power: 10.3992
  },
  {
    x: 786.14,
    y: 279.722,
    power: 10.4038
  },
  {
    x: 786.74,
    y: 279.949,
    power: 10.4111
  },
  {
    x: 787.34,
    y: 280.177,
    power: 10.4175
  },
  {
    x: 787.94,
    y: 280.379,
    power: 10.4276
  },
  {
    x: 788.56,
    y: 280.683,
    power: 10.434
  },
  {
    x: 789.18,
    y: 280.936,
    power: 10.4422
  },
  {
    x: 789.78,
    y: 281.087,
    power: 10.4513
  },
  {
    x: 790.4,
    y: 281.34,
    power: 10.4595
  },
  {
    x: 791,
    y: 281.593,
    power: 10.465
  },
  {
    x: 791.6,
    y: 281.871,
    power: 10.4723
  },
  {
    x: 792.22,
    y: 282.048,
    power: 10.4815
  },
  {
    x: 792.84,
    y: 282.2,
    power: 10.486
  },
  {
    x: 793.46,
    y: 282.377,
    power: 10.4924
  },
  {
    x: 794.08,
    y: 282.604,
    power: 10.4997
  },
  {
    x: 794.7,
    y: 282.857,
    power: 10.5098
  },
  {
    x: 795.3,
    y: 283.034,
    power: 10.518
  },
  {
    x: 795.9,
    y: 283.262,
    power: 10.5272
  },
  {
    x: 796.5,
    y: 283.489,
    power: 10.5326
  },
  {
    x: 797.12,
    y: 283.742,
    power: 10.5409
  },
  {
    x: 797.74,
    y: 283.97,
    power: 10.5464
  },
  {
    x: 798.36,
    y: 284.223,
    power: 10.5546
  },
  {
    x: 798.96,
    y: 284.45,
    power: 10.5637
  },
  {
    x: 799.58,
    y: 284.652,
    power: 10.571
  },
  {
    x: 800.2,
    y: 284.88,
    power: 10.5765
  },
  {
    x: 800.8,
    y: 285.107,
    power: 10.5847
  },
  {
    x: 801.42,
    y: 285.36,
    power: 10.593
  },
  {
    x: 802.02,
    y: 285.638,
    power: 10.6048
  },
  {
    x: 802.62,
    y: 285.866,
    power: 10.6131
  },
  {
    x: 803.24,
    y: 286.119,
    power: 10.6176
  },
  {
    x: 803.86,
    y: 286.372,
    power: 10.624
  },
  {
    x: 804.48,
    y: 286.65,
    power: 10.6323
  },
  {
    x: 805.08,
    y: 286.827,
    power: 10.6405
  },
  {
    x: 805.68,
    y: 287.105,
    power: 10.6487
  },
  {
    x: 806.3,
    y: 287.282,
    power: 10.656
  },
  {
    x: 806.92,
    y: 287.56,
    power: 10.6633
  },
  {
    x: 807.52,
    y: 287.737,
    power: 10.6706
  },
  {
    x: 808.14,
    y: 287.939,
    power: 10.6807
  },
  {
    x: 808.76,
    y: 288.167,
    power: 10.6862
  },
  {
    x: 809.38,
    y: 288.445,
    power: 10.6953
  },
  {
    x: 810,
    y: 288.673,
    power: 10.6999
  },
  {
    x: 810.6,
    y: 288.875,
    power: 10.7063
  },
  {
    x: 811.2,
    y: 289.102,
    power: 10.7145
  },
  {
    x: 811.8,
    y: 289.355,
    power: 10.7218
  },
  {
    x: 812.42,
    y: 289.583,
    power: 10.7309
  },
  {
    x: 813.04,
    y: 289.785,
    power: 10.7373
  },
  {
    x: 813.66,
    y: 290.038,
    power: 10.7446
  },
  {
    x: 814.26,
    y: 290.24,
    power: 10.751
  },
  {
    x: 814.86,
    y: 290.493,
    power: 10.762
  },
  {
    x: 815.46,
    y: 290.695,
    power: 10.7702
  },
  {
    x: 816.08,
    y: 290.948,
    power: 10.7766
  },
  {
    x: 816.7,
    y: 291.176,
    power: 10.783
  },
  {
    x: 817.32,
    y: 291.403,
    power: 10.7913
  },
  {
    x: 817.92,
    y: 291.606,
    power: 10.7986
  },
  {
    x: 818.54,
    y: 291.858,
    power: 10.8068
  },
  {
    x: 819.16,
    y: 292.111,
    power: 10.8123
  },
  {
    x: 819.76,
    y: 292.314,
    power: 10.8196
  },
  {
    x: 820.36,
    y: 292.516,
    power: 10.8278
  },
  {
    x: 820.96,
    y: 292.743,
    power: 10.8351
  },
  {
    x: 821.58,
    y: 293.021,
    power: 10.8433
  },
  {
    x: 822.18,
    y: 293.224,
    power: 10.8479
  },
  {
    x: 822.8,
    y: 293.426,
    power: 10.8552
  },
  {
    x: 823.42,
    y: 293.603,
    power: 10.8634
  },
  {
    x: 824.02,
    y: 293.856,
    power: 10.8726
  },
  {
    x: 824.62,
    y: 294.109,
    power: 10.8799
  },
  {
    x: 825.24,
    y: 294.387,
    power: 10.889
  },
  {
    x: 825.86,
    y: 294.589,
    power: 10.8973
  },
  {
    x: 826.46,
    y: 294.817,
    power: 10.9027
  },
  {
    x: 827.08,
    y: 295.044,
    power: 10.9091
  },
  {
    x: 827.7,
    y: 295.272,
    power: 10.9183
  },
  {
    x: 828.32,
    y: 295.525,
    power: 10.9247
  },
  {
    x: 828.92,
    y: 295.752,
    power: 10.9347
  },
  {
    x: 829.52,
    y: 295.98,
    power: 10.9375
  },
  {
    x: 830.12,
    y: 296.182,
    power: 10.9457
  },
  {
    x: 830.72,
    y: 296.41,
    power: 10.9539
  },
  {
    x: 831.34,
    y: 296.637,
    power: 10.9585
  },
  {
    x: 831.96,
    y: 296.865,
    power: 10.9704
  },
  {
    x: 832.56,
    y: 297.092,
    power: 10.9768
  },
  {
    x: 833.16,
    y: 297.295,
    power: 10.9859
  },
  {
    x: 833.76,
    y: 297.547,
    power: 10.9896
  },
  {
    x: 834.38,
    y: 297.75,
    power: 10.9969
  },
  {
    x: 834.98,
    y: 298.028,
    power: 11.006
  },
  {
    x: 835.6,
    y: 298.23,
    power: 11.0124
  },
  {
    x: 836.22,
    y: 298.483,
    power: 11.0188
  },
  {
    x: 836.84,
    y: 298.71,
    power: 11.027
  },
  {
    x: 837.44,
    y: 298.963,
    power: 11.0371
  },
  {
    x: 838.04,
    y: 299.216,
    power: 11.0426
  },
  {
    x: 838.64,
    y: 299.418,
    power: 11.049
  },
  {
    x: 839.26,
    y: 299.671,
    power: 11.0544
  },
  {
    x: 839.86,
    y: 299.899,
    power: 11.0608
  },
  {
    x: 840.48,
    y: 300.152,
    power: 11.0691
  },
  {
    x: 841.08,
    y: 300.405,
    power: 11.0764
  },
  {
    x: 841.68,
    y: 300.607,
    power: 11.0855
  },
  {
    x: 842.3,
    y: 300.834,
    power: 11.0892
  },
  {
    x: 842.92,
    y: 301.087,
    power: 11.1001
  },
  {
    x: 843.52,
    y: 301.315,
    power: 11.1065
  },
  {
    x: 844.14,
    y: 301.467,
    power: 11.1147
  },
  {
    x: 844.76,
    y: 301.669,
    power: 11.1248
  },
  {
    x: 845.38,
    y: 301.871,
    power: 11.1312
  },
  {
    x: 846,
    y: 302.124,
    power: 11.1376
  },
  {
    x: 846.62,
    y: 302.377,
    power: 11.1431
  },
  {
    x: 847.22,
    y: 302.604,
    power: 11.1513
  },
  {
    x: 847.82,
    y: 302.857,
    power: 11.1586
  },
  {
    x: 848.42,
    y: 303.059,
    power: 11.1668
  },
  {
    x: 849.04,
    y: 303.312,
    power: 11.1723
  },
  {
    x: 849.66,
    y: 303.54,
    power: 11.1805
  },
  {
    x: 850.26,
    y: 303.843,
    power: 11.1897
  },
  {
    x: 850.86,
    y: 304.02,
    power: 11.1961
  },
  {
    x: 851.46,
    y: 304.248,
    power: 11.2052
  },
  {
    x: 852.06,
    y: 304.45,
    power: 11.2098
  },
  {
    x: 852.68,
    y: 304.703,
    power: 11.2162
  },
  {
    x: 853.3,
    y: 304.981,
    power: 11.2226
  },
  {
    x: 853.92,
    y: 305.209,
    power: 11.2317
  },
  {
    x: 854.54,
    y: 305.487,
    power: 11.2399
  },
  {
    x: 855.16,
    y: 305.714,
    power: 11.2472
  },
  {
    x: 855.76,
    y: 305.967,
    power: 11.2536
  },
  {
    x: 856.36,
    y: 306.144,
    power: 11.2628
  },
  {
    x: 856.96,
    y: 306.372,
    power: 11.271
  },
  {
    x: 857.58,
    y: 306.599,
    power: 11.2765
  },
  {
    x: 858.2,
    y: 306.852,
    power: 11.282
  },
  {
    x: 858.82,
    y: 307.105,
    power: 11.2884
  },
  {
    x: 859.44,
    y: 307.282,
    power: 11.3012
  },
  {
    x: 860.04,
    y: 307.535,
    power: 11.3039
  },
  {
    x: 860.64,
    y: 307.762,
    power: 11.314
  },
  {
    x: 861.24,
    y: 308.04,
    power: 11.3222
  },
  {
    x: 861.86,
    y: 308.268,
    power: 11.3304
  },
  {
    x: 862.48,
    y: 308.496,
    power: 11.3368
  },
  {
    x: 863.08,
    y: 308.774,
    power: 11.345
  },
  {
    x: 863.7,
    y: 308.951,
    power: 11.3505
  },
  {
    x: 864.32,
    y: 309.153,
    power: 11.3587
  },
  {
    x: 864.94,
    y: 309.355,
    power: 11.3624
  },
  {
    x: 865.54,
    y: 309.608,
    power: 11.3697
  },
  {
    x: 866.14,
    y: 309.836,
    power: 11.3798
  },
  {
    x: 866.74,
    y: 310.088,
    power: 11.3852
  },
  {
    x: 867.36,
    y: 310.341,
    power: 11.3925
  },
  {
    x: 867.98,
    y: 310.594,
    power: 11.4008
  },
  {
    x: 868.58,
    y: 310.796,
    power: 11.4081
  },
  {
    x: 869.2,
    y: 311.049,
    power: 11.4181
  },
  {
    x: 869.8,
    y: 311.252,
    power: 11.4236
  },
  {
    x: 870.42,
    y: 311.504,
    power: 11.4291
  },
  {
    x: 871.02,
    y: 311.707,
    power: 11.4373
  },
  {
    x: 871.64,
    y: 311.934,
    power: 11.4419
  },
  {
    x: 872.26,
    y: 312.162,
    power: 11.4465
  },
  {
    x: 872.88,
    y: 312.44,
    power: 11.4565
  },
  {
    x: 873.48,
    y: 312.668,
    power: 11.4675
  },
  {
    x: 874.1,
    y: 312.895,
    power: 11.4739
  },
  {
    x: 874.72,
    y: 313.072,
    power: 11.483
  },
  {
    x: 875.32,
    y: 313.325,
    power: 11.4867
  },
  {
    x: 875.92,
    y: 313.578,
    power: 11.494
  },
  {
    x: 876.52,
    y: 313.805,
    power: 11.5004
  },
  {
    x: 877.14,
    y: 314.033,
    power: 11.5068
  },
  {
    x: 877.74,
    y: 314.286,
    power: 11.5132
  },
  {
    x: 878.34,
    y: 314.513,
    power: 11.5214
  },
  {
    x: 878.94,
    y: 314.716,
    power: 11.525
  },
  {
    x: 879.56,
    y: 314.994,
    power: 11.5378
  },
  {
    x: 880.18,
    y: 315.196,
    power: 11.5461
  },
  {
    x: 880.8,
    y: 315.474,
    power: 11.5515
  },
  {
    x: 881.42,
    y: 315.676,
    power: 11.557
  },
  {
    x: 882.04,
    y: 315.904,
    power: 11.5662
  },
  {
    x: 882.64,
    y: 316.131,
    power: 11.5726
  },
  {
    x: 883.26,
    y: 316.359,
    power: 11.5771
  },
  {
    x: 883.88,
    y: 316.587,
    power: 11.5872
  },
  {
    x: 884.48,
    y: 316.814,
    power: 11.5936
  },
  {
    x: 885.08,
    y: 317.092,
    power: 11.6009
  },
  {
    x: 885.68,
    y: 317.295,
    power: 11.6109
  },
  {
    x: 886.28,
    y: 317.573,
    power: 11.6192
  },
  {
    x: 886.9,
    y: 317.75,
    power: 11.6228
  },
  {
    x: 887.5,
    y: 318.028,
    power: 11.6301
  },
  {
    x: 888.1,
    y: 318.23,
    power: 11.6365
  },
  {
    x: 888.72,
    y: 318.483,
    power: 11.6438
  },
  {
    x: 889.34,
    y: 318.685,
    power: 11.6521
  },
  {
    x: 889.94,
    y: 318.938,
    power: 11.6594
  },
  {
    x: 890.54,
    y: 319.166,
    power: 11.6649
  },
  {
    x: 891.16,
    y: 319.444,
    power: 11.6713
  },
  {
    x: 891.78,
    y: 319.722,
    power: 11.6822
  },
  {
    x: 892.38,
    y: 319.924,
    power: 11.6886
  },
  {
    x: 892.98,
    y: 320.202,
    power: 11.6978
  },
  {
    x: 893.6,
    y: 320.405,
    power: 11.7005
  },
  {
    x: 894.2,
    y: 320.683,
    power: 11.7069
  },
  {
    x: 894.82,
    y: 320.91,
    power: 11.716
  },
  {
    x: 895.44,
    y: 321.062,
    power: 11.7243
  },
  {
    x: 896.04,
    y: 321.29,
    power: 11.7288
  },
  {
    x: 896.64,
    y: 321.492,
    power: 11.7371
  },
  {
    x: 897.26,
    y: 321.745,
    power: 11.7444
  },
  {
    x: 897.88,
    y: 321.997,
    power: 11.7498
  },
  {
    x: 898.48,
    y: 322.25,
    power: 11.759
  },
  {
    x: 899.1,
    y: 322.503,
    power: 11.7663
  },
  {
    x: 899.72,
    y: 322.705,
    power: 11.7745
  },
  {
    x: 900.32,
    y: 323.009,
    power: 11.7773
  },
  {
    x: 900.94,
    y: 323.236,
    power: 11.7855
  },
  {
    x: 901.54,
    y: 323.489,
    power: 11.7928
  },
  {
    x: 902.14,
    y: 323.692,
    power: 11.8019
  },
  {
    x: 902.74,
    y: 323.919,
    power: 11.8074
  },
  {
    x: 903.36,
    y: 324.147,
    power: 11.8156
  },
  {
    x: 903.98,
    y: 324.374,
    power: 11.8239
  },
  {
    x: 904.6,
    y: 324.652,
    power: 11.8321
  },
  {
    x: 905.2,
    y: 324.855,
    power: 11.8357
  },
  {
    x: 905.8,
    y: 325.133,
    power: 11.8467
  },
  {
    x: 906.42,
    y: 325.36,
    power: 11.8513
  },
  {
    x: 907.02,
    y: 325.638,
    power: 11.8586
  },
  {
    x: 907.64,
    y: 325.815,
    power: 11.8677
  },
  {
    x: 908.24,
    y: 326.094,
    power: 11.8732
  },
  {
    x: 908.86,
    y: 326.271,
    power: 11.8824
  },
  {
    x: 909.48,
    y: 326.549,
    power: 11.8887
  },
  {
    x: 910.1,
    y: 326.751,
    power: 11.8979
  },
  {
    x: 910.72,
    y: 327.004,
    power: 11.9043
  },
  {
    x: 911.32,
    y: 327.206,
    power: 11.9125
  },
  {
    x: 911.92,
    y: 327.434,
    power: 11.9152
  },
  {
    x: 912.52,
    y: 327.661,
    power: 11.9226
  },
  {
    x: 913.14,
    y: 327.889,
    power: 11.9308
  },
  {
    x: 913.76,
    y: 328.142,
    power: 11.9372
  },
  {
    x: 914.38,
    y: 328.394,
    power: 11.9481
  },
  {
    x: 915,
    y: 328.622,
    power: 11.9545
  },
  {
    x: 915.62,
    y: 328.824,
    power: 11.96
  },
  {
    x: 916.22,
    y: 329.052,
    power: 11.9701
  },
  {
    x: 916.82,
    y: 329.279,
    power: 11.9756
  },
  {
    x: 917.44,
    y: 329.532,
    power: 11.982
  },
  {
    x: 918.06,
    y: 329.76,
    power: 11.9902
  },
  {
    x: 918.68,
    y: 329.987,
    power: 11.9975
  },
  {
    x: 919.28,
    y: 330.24,
    power: 12.0057
  },
  {
    x: 919.9,
    y: 330.468,
    power: 12.0094
  },
  {
    x: 920.5,
    y: 330.746,
    power: 12.0185
  },
  {
    x: 921.1,
    y: 330.973,
    power: 12.0258
  },
  {
    x: 921.7,
    y: 331.201,
    power: 12.0331
  },
  {
    x: 922.3,
    y: 331.403,
    power: 12.0395
  },
  {
    x: 922.92,
    y: 331.656,
    power: 12.0478
  },
  {
    x: 923.54,
    y: 331.909,
    power: 12.0541
  },
  {
    x: 924.16,
    y: 332.111,
    power: 12.0624
  },
  {
    x: 924.76,
    y: 332.364,
    power: 12.0669
  },
  {
    x: 925.36,
    y: 332.566,
    power: 12.0743
  },
  {
    x: 925.98,
    y: 332.87,
    power: 12.0843
  },
  {
    x: 926.6,
    y: 333.072,
    power: 12.0907
  },
  {
    x: 927.2,
    y: 333.325,
    power: 12.0971
  },
  {
    x: 927.82,
    y: 333.552,
    power: 12.1062
  },
  {
    x: 928.44,
    y: 333.831,
    power: 12.1117
  },
  {
    x: 929.04,
    y: 334.083,
    power: 12.119
  },
  {
    x: 929.64,
    y: 334.311,
    power: 12.1263
  },
  {
    x: 930.24,
    y: 334.539,
    power: 12.1318
  },
  {
    x: 930.86,
    y: 334.791,
    power: 12.1382
  },
  {
    x: 931.48,
    y: 334.968,
    power: 12.1474
  },
  {
    x: 932.1,
    y: 335.247,
    power: 12.1501
  },
  {
    x: 932.7,
    y: 335.424,
    power: 12.1602
  },
  {
    x: 933.32,
    y: 335.676,
    power: 12.1675
  },
  {
    x: 933.92,
    y: 335.853,
    power: 12.1766
  },
  {
    x: 934.52,
    y: 336.106,
    power: 12.1803
  },
  {
    x: 935.14,
    y: 336.334,
    power: 12.1857
  },
  {
    x: 935.74,
    y: 336.612,
    power: 12.193
  },
  {
    x: 936.36,
    y: 336.839,
    power: 12.2022
  },
  {
    x: 936.98,
    y: 337.067,
    power: 12.2104
  },
  {
    x: 937.6,
    y: 337.295,
    power: 12.2168
  },
  {
    x: 938.22,
    y: 337.573,
    power: 12.2241
  },
  {
    x: 938.82,
    y: 337.8,
    power: 12.2305
  },
  {
    x: 939.42,
    y: 338.078,
    power: 12.2387
  },
  {
    x: 940.02,
    y: 338.331,
    power: 12.246
  },
  {
    x: 940.62,
    y: 338.584,
    power: 12.2524
  },
  {
    x: 941.24,
    y: 338.837,
    power: 12.2598
  },
  {
    x: 941.86,
    y: 339.064,
    power: 12.2662
  },
  {
    x: 942.48,
    y: 339.343,
    power: 12.2762
  },
  {
    x: 943.1,
    y: 339.646,
    power: 12.2808
  },
  {
    x: 943.7,
    y: 339.874,
    power: 12.2908
  },
  {
    x: 944.3,
    y: 340.076,
    power: 12.2954
  },
  {
    x: 944.92,
    y: 340.329,
    power: 12.3027
  },
  {
    x: 945.52,
    y: 340.531,
    power: 12.3118
  },
  {
    x: 946.14,
    y: 340.759,
    power: 12.3228
  },
  {
    x: 946.76,
    y: 340.936,
    power: 12.3274
  },
  {
    x: 947.38,
    y: 341.188,
    power: 12.3329
  },
  {
    x: 948,
    y: 341.416,
    power: 12.3383
  },
  {
    x: 948.62,
    y: 341.643,
    power: 12.3457
  },
  {
    x: 949.22,
    y: 341.871,
    power: 12.353
  },
  {
    x: 949.82,
    y: 342.149,
    power: 12.3612
  },
  {
    x: 950.44,
    y: 342.402,
    power: 12.3685
  },
  {
    x: 951.04,
    y: 342.655,
    power: 12.3731
  },
  {
    x: 951.66,
    y: 342.882,
    power: 12.3859
  },
  {
    x: 952.28,
    y: 343.11,
    power: 12.3895
  },
  {
    x: 952.9,
    y: 343.338,
    power: 12.3977
  },
  {
    x: 953.5,
    y: 343.565,
    power: 12.4032
  },
  {
    x: 954.1,
    y: 343.818,
    power: 12.4105
  },
  {
    x: 954.7,
    y: 344.046,
    power: 12.4197
  },
  {
    x: 955.3,
    y: 344.324,
    power: 12.4233
  },
  {
    x: 955.92,
    y: 344.526,
    power: 12.4316
  },
  {
    x: 956.54,
    y: 344.804,
    power: 12.438
  },
  {
    x: 957.16,
    y: 345.006,
    power: 12.4443
  },
  {
    x: 957.78,
    y: 345.259,
    power: 12.4517
  },
  {
    x: 958.38,
    y: 345.461,
    power: 12.4617
  },
  {
    x: 958.98,
    y: 345.714,
    power: 12.4681
  },
  {
    x: 959.6,
    y: 345.917,
    power: 12.4736
  },
  {
    x: 960.2,
    y: 346.169,
    power: 12.4782
  },
  {
    x: 960.82,
    y: 346.397,
    power: 12.4864
  },
  {
    x: 961.44,
    y: 346.625,
    power: 12.4937
  },
  {
    x: 962.04,
    y: 346.827,
    power: 12.501
  },
  {
    x: 962.64,
    y: 347.08,
    power: 12.5083
  },
  {
    x: 963.24,
    y: 347.358,
    power: 12.5138
  },
  {
    x: 963.86,
    y: 347.611,
    power: 12.5229
  },
  {
    x: 964.48,
    y: 347.788,
    power: 12.5312
  },
  {
    x: 965.1,
    y: 348.04,
    power: 12.5385
  },
  {
    x: 965.72,
    y: 348.293,
    power: 12.5394
  },
  {
    x: 966.32,
    y: 348.496,
    power: 12.5449
  },
  {
    x: 966.94,
    y: 348.748,
    power: 12.5558
  },
  {
    x: 967.54,
    y: 348.976,
    power: 12.5622
  },
  {
    x: 968.14,
    y: 349.254,
    power: 12.5705
  },
  {
    x: 968.74,
    y: 349.456,
    power: 12.5769
  },
  {
    x: 969.36,
    y: 349.684,
    power: 12.5851
  },
  {
    x: 969.98,
    y: 349.937,
    power: 12.596
  },
  {
    x: 970.6,
    y: 350.164,
    power: 12.6006
  },
  {
    x: 971.2,
    y: 350.392,
    power: 12.6088
  },
  {
    x: 971.8,
    y: 350.594,
    power: 12.6125
  },
  {
    x: 972.4,
    y: 350.847,
    power: 12.6235
  },
  {
    x: 973.02,
    y: 351.075,
    power: 12.6271
  },
  {
    x: 973.64,
    y: 351.327,
    power: 12.6353
  },
  {
    x: 974.26,
    y: 351.555,
    power: 12.6436
  },
  {
    x: 974.88,
    y: 351.858,
    power: 12.65
  },
  {
    x: 975.5,
    y: 352.137,
    power: 12.6582
  },
  {
    x: 976.12,
    y: 352.339,
    power: 12.6655
  },
  {
    x: 976.74,
    y: 352.592,
    power: 12.6728
  },
  {
    x: 977.36,
    y: 352.819,
    power: 12.6801
  },
  {
    x: 977.96,
    y: 353.097,
    power: 12.6829
  },
  {
    x: 978.56,
    y: 353.325,
    power: 12.6893
  },
  {
    x: 979.16,
    y: 353.578,
    power: 12.6984
  },
  {
    x: 979.78,
    y: 353.805,
    power: 12.7057
  },
  {
    x: 980.38,
    y: 354.033,
    power: 12.7139
  },
  {
    x: 980.98,
    y: 354.26,
    power: 12.7221
  },
  {
    x: 981.58,
    y: 354.513,
    power: 12.7313
  },
  {
    x: 982.18,
    y: 354.741,
    power: 12.7368
  },
  {
    x: 982.78,
    y: 354.968,
    power: 12.745
  },
  {
    x: 983.4,
    y: 355.221,
    power: 12.7514
  },
  {
    x: 984.02,
    y: 355.424,
    power: 12.755
  },
  {
    x: 984.64,
    y: 355.651,
    power: 12.7624
  },
  {
    x: 985.26,
    y: 355.879,
    power: 12.7678
  },
  {
    x: 985.88,
    y: 356.131,
    power: 12.7788
  },
  {
    x: 986.48,
    y: 356.359,
    power: 12.7861
  },
  {
    x: 987.08,
    y: 356.587,
    power: 12.7916
  },
  {
    x: 987.68,
    y: 356.814,
    power: 12.7962
  },
  {
    x: 988.28,
    y: 357.092,
    power: 12.8035
  },
  {
    x: 988.9,
    y: 357.345,
    power: 12.8135
  },
  {
    x: 989.5,
    y: 357.547,
    power: 12.819
  },
  {
    x: 990.1,
    y: 357.8,
    power: 12.8263
  },
  {
    x: 990.72,
    y: 358.053,
    power: 12.8327
  },
  {
    x: 991.32,
    y: 358.281,
    power: 12.8382
  },
  {
    x: 991.94,
    y: 358.534,
    power: 12.8464
  },
  {
    x: 992.56,
    y: 358.71,
    power: 12.8519
  },
  {
    x: 993.18,
    y: 359.014,
    power: 12.861
  },
  {
    x: 993.8,
    y: 359.317,
    power: 12.8684
  },
  {
    x: 994.42,
    y: 359.545,
    power: 12.8757
  },
  {
    x: 995.02,
    y: 359.772,
    power: 12.8839
  },
  {
    x: 995.62,
    y: 360,
    power: 12.8903
  },
  {
    x: 996.22,
    y: 360.253,
    power: 12.8949
  },
  {
    x: 996.84,
    y: 360.43,
    power: 12.9003
  },
  {
    x: 997.46,
    y: 360.657,
    power: 12.9086
  },
  {
    x: 998.06,
    y: 360.86,
    power: 12.915
  },
  {
    x: 998.68,
    y: 361.138,
    power: 12.9241
  },
  {
    x: 999.28,
    y: 361.34,
    power: 12.9305
  },
  {
    x: 999.9,
    y: 361.593,
    power: 12.9369
  },
  {
    x: 1000.5,
    y: 361.871,
    power: 12.9433
  },
  {
    x: 1001.1,
    y: 362.073,
    power: 12.9515
  },
  {
    x: 1001.72,
    y: 362.351,
    power: 12.9579
  },
  {
    x: 1002.34,
    y: 362.579,
    power: 12.9643
  },
  {
    x: 1002.96,
    y: 362.857,
    power: 12.9707
  },
  {
    x: 1003.56,
    y: 363.059,
    power: 12.9798
  },
  {
    x: 1004.16,
    y: 363.287,
    power: 12.9862
  },
  {
    x: 1004.76,
    y: 363.489,
    power: 12.9936
  },
  {
    x: 1005.36,
    y: 363.717,
    power: 12.9999
  },
  {
    x: 1005.98,
    y: 364.02,
    power: 13.0091
  },
  {
    x: 1006.6,
    y: 364.223,
    power: 13.0118
  },
  {
    x: 1007.2,
    y: 364.475,
    power: 13.0173
  },
  {
    x: 1007.8,
    y: 364.703,
    power: 13.0264
  },
  {
    x: 1008.4,
    y: 364.981,
    power: 13.0328
  },
  {
    x: 1009.02,
    y: 365.209,
    power: 13.0411
  },
  {
    x: 1009.64,
    y: 365.436,
    power: 13.0475
  },
  {
    x: 1010.26,
    y: 365.664,
    power: 13.0557
  },
  {
    x: 1010.88,
    y: 365.917,
    power: 13.0612
  },
  {
    x: 1011.5,
    y: 366.144,
    power: 13.0694
  },
  {
    x: 1012.12,
    y: 366.372,
    power: 13.0785
  },
  {
    x: 1012.74,
    y: 366.625,
    power: 13.0831
  },
  {
    x: 1013.36,
    y: 366.877,
    power: 13.0904
  },
  {
    x: 1013.96,
    y: 367.105,
    power: 13.0959
  },
  {
    x: 1014.56,
    y: 367.332,
    power: 13.1023
  },
  {
    x: 1015.16,
    y: 367.585,
    power: 13.1105
  },
  {
    x: 1015.78,
    y: 367.838,
    power: 13.1169
  },
  {
    x: 1016.4,
    y: 368.091,
    power: 13.1206
  },
  {
    x: 1017.02,
    y: 368.268,
    power: 13.1279
  },
  {
    x: 1017.64,
    y: 368.546,
    power: 13.1388
  },
  {
    x: 1018.24,
    y: 368.774,
    power: 13.1407
  },
  {
    x: 1018.84,
    y: 369.052,
    power: 13.1526
  },
  {
    x: 1019.46,
    y: 369.279,
    power: 13.1571
  },
  {
    x: 1020.08,
    y: 369.532,
    power: 13.1644
  },
  {
    x: 1020.7,
    y: 369.785,
    power: 13.1699
  },
  {
    x: 1021.3,
    y: 370.013,
    power: 13.1772
  },
  {
    x: 1021.92,
    y: 370.265,
    power: 13.1809
  },
  {
    x: 1022.54,
    y: 370.493,
    power: 13.1909
  },
  {
    x: 1023.16,
    y: 370.746,
    power: 13.1992
  },
  {
    x: 1023.76,
    y: 370.999,
    power: 13.2083
  },
  {
    x: 1024.36,
    y: 371.226,
    power: 13.2138
  },
  {
    x: 1024.96,
    y: 371.454,
    power: 13.222
  },
  {
    x: 1025.56,
    y: 371.732,
    power: 13.2266
  },
  {
    x: 1026.18,
    y: 371.96,
    power: 13.2321
  },
  {
    x: 1026.8,
    y: 372.187,
    power: 13.243
  },
  {
    x: 1027.4,
    y: 372.415,
    power: 13.2503
  },
  {
    x: 1028,
    y: 372.642,
    power: 13.2549
  },
  {
    x: 1028.62,
    y: 372.895,
    power: 13.2622
  },
  {
    x: 1029.22,
    y: 373.097,
    power: 13.2695
  },
  {
    x: 1029.84,
    y: 373.35,
    power: 13.2787
  },
  {
    x: 1030.46,
    y: 373.603,
    power: 13.2841
  },
  {
    x: 1031.06,
    y: 373.906,
    power: 13.2924
  },
  {
    x: 1031.68,
    y: 374.109,
    power: 13.3006
  },
  {
    x: 1032.3,
    y: 374.362,
    power: 13.3061
  },
  {
    x: 1032.9,
    y: 374.539,
    power: 13.3116
  },
  {
    x: 1033.5,
    y: 374.817,
    power: 13.318
  },
  {
    x: 1034.12,
    y: 375.044,
    power: 13.3262
  },
  {
    x: 1034.72,
    y: 375.247,
    power: 13.3335
  },
  {
    x: 1035.34,
    y: 375.474,
    power: 13.3417
  },
  {
    x: 1035.96,
    y: 375.702,
    power: 13.3472
  },
  {
    x: 1036.58,
    y: 375.98,
    power: 13.3563
  },
  {
    x: 1037.18,
    y: 376.207,
    power: 13.3618
  },
  {
    x: 1037.78,
    y: 376.435,
    power: 13.3691
  },
  {
    x: 1038.4,
    y: 376.662,
    power: 13.3746
  },
  {
    x: 1039.02,
    y: 376.915,
    power: 13.3838
  },
  {
    x: 1039.64,
    y: 377.219,
    power: 13.3901
  },
  {
    x: 1040.26,
    y: 377.421,
    power: 13.3993
  },
  {
    x: 1040.88,
    y: 377.674,
    power: 13.4029
  },
  {
    x: 1041.5,
    y: 377.927,
    power: 13.413
  },
  {
    x: 1042.12,
    y: 378.18,
    power: 13.423
  },
  {
    x: 1042.74,
    y: 378.407,
    power: 13.4294
  },
  {
    x: 1043.34,
    y: 378.609,
    power: 13.4349
  },
  {
    x: 1043.94,
    y: 378.913,
    power: 13.4386
  },
  {
    x: 1044.54,
    y: 379.166,
    power: 13.445
  },
  {
    x: 1045.16,
    y: 379.393,
    power: 13.4541
  },
  {
    x: 1045.78,
    y: 379.671,
    power: 13.4605
  },
  {
    x: 1046.4,
    y: 379.924,
    power: 13.466
  },
  {
    x: 1047.02,
    y: 380.152,
    power: 13.4733
  },
  {
    x: 1047.64,
    y: 380.354,
    power: 13.4806
  },
  {
    x: 1048.24,
    y: 380.531,
    power: 13.4898
  },
  {
    x: 1048.84,
    y: 380.784,
    power: 13.4961
  },
  {
    x: 1049.44,
    y: 381.011,
    power: 13.5016
  },
  {
    x: 1050.06,
    y: 381.239,
    power: 13.5089
  },
  {
    x: 1050.68,
    y: 381.466,
    power: 13.5135
  },
  {
    x: 1051.28,
    y: 381.719,
    power: 13.5217
  },
  {
    x: 1051.9,
    y: 381.947,
    power: 13.5318
  },
  {
    x: 1052.5,
    y: 382.174,
    power: 13.5364
  },
  {
    x: 1053.1,
    y: 382.453,
    power: 13.5418
  },
  {
    x: 1053.7,
    y: 382.68,
    power: 13.5519
  },
  {
    x: 1054.3,
    y: 382.908,
    power: 13.5574
  },
  {
    x: 1054.92,
    y: 383.085,
    power: 13.5638
  },
  {
    x: 1055.52,
    y: 383.338,
    power: 13.5693
  },
  {
    x: 1056.14,
    y: 383.59,
    power: 13.5775
  },
  {
    x: 1056.74,
    y: 383.818,
    power: 13.583
  },
  {
    x: 1057.34,
    y: 384.096,
    power: 13.5921
  },
  {
    x: 1057.96,
    y: 384.324,
    power: 13.5976
  },
  {
    x: 1058.58,
    y: 384.577,
    power: 13.6058
  },
  {
    x: 1059.2,
    y: 384.829,
    power: 13.6122
  },
  {
    x: 1059.82,
    y: 385.082,
    power: 13.6186
  },
  {
    x: 1060.44,
    y: 385.335,
    power: 13.6277
  },
  {
    x: 1061.04,
    y: 385.537,
    power: 13.6323
  },
  {
    x: 1061.66,
    y: 385.765,
    power: 13.6396
  },
  {
    x: 1062.26,
    y: 386.018,
    power: 13.6478
  },
  {
    x: 1062.86,
    y: 386.195,
    power: 13.6515
  },
  {
    x: 1063.48,
    y: 386.473,
    power: 13.6606
  },
  {
    x: 1064.1,
    y: 386.7,
    power: 13.6661
  },
  {
    x: 1064.72,
    y: 386.979,
    power: 13.6716
  },
  {
    x: 1065.34,
    y: 387.206,
    power: 13.6807
  },
  {
    x: 1065.96,
    y: 387.509,
    power: 13.6917
  },
  {
    x: 1066.56,
    y: 387.712,
    power: 13.6963
  },
  {
    x: 1067.18,
    y: 387.939,
    power: 13.7045
  },
  {
    x: 1067.78,
    y: 388.142,
    power: 13.71
  },
  {
    x: 1068.38,
    y: 388.42,
    power: 13.7146
  },
  {
    x: 1069,
    y: 388.673,
    power: 13.7246
  },
  {
    x: 1069.62,
    y: 388.925,
    power: 13.7283
  },
  {
    x: 1070.22,
    y: 389.153,
    power: 13.7337
  },
  {
    x: 1070.84,
    y: 389.381,
    power: 13.7429
  },
  {
    x: 1071.44,
    y: 389.659,
    power: 13.7511
  },
  {
    x: 1072.04,
    y: 389.886,
    power: 13.7584
  },
  {
    x: 1072.64,
    y: 390.088,
    power: 13.7657
  },
  {
    x: 1073.24,
    y: 390.291,
    power: 13.773
  },
  {
    x: 1073.86,
    y: 390.569,
    power: 13.7776
  },
  {
    x: 1074.48,
    y: 390.771,
    power: 13.7858
  },
  {
    x: 1075.1,
    y: 391.024,
    power: 13.7922
  },
  {
    x: 1075.7,
    y: 391.277,
    power: 13.7995
  },
  {
    x: 1076.3,
    y: 391.504,
    power: 13.8059
  },
  {
    x: 1076.92,
    y: 391.757,
    power: 13.8105
  },
  {
    x: 1077.52,
    y: 391.934,
    power: 13.8196
  },
  {
    x: 1078.14,
    y: 392.212,
    power: 13.826
  },
  {
    x: 1078.76,
    y: 392.465,
    power: 13.837
  },
  {
    x: 1079.38,
    y: 392.743,
    power: 13.8397
  },
  {
    x: 1080,
    y: 393.021,
    power: 13.8471
  },
  {
    x: 1080.62,
    y: 393.249,
    power: 13.8553
  },
  {
    x: 1081.22,
    y: 393.527,
    power: 13.8617
  },
  {
    x: 1081.82,
    y: 393.78,
    power: 13.8681
  },
  {
    x: 1082.42,
    y: 393.957,
    power: 13.8736
  },
  {
    x: 1083.04,
    y: 394.235,
    power: 13.879
  },
  {
    x: 1083.66,
    y: 394.463,
    power: 13.8891
  },
  {
    x: 1084.26,
    y: 394.716,
    power: 13.8964
  },
  {
    x: 1084.86,
    y: 394.893,
    power: 13.9019
  },
  {
    x: 1085.46,
    y: 395.12,
    power: 13.9074
  },
  {
    x: 1086.08,
    y: 395.373,
    power: 13.9147
  },
  {
    x: 1086.7,
    y: 395.626,
    power: 13.9238
  },
  {
    x: 1087.32,
    y: 395.853,
    power: 13.9293
  },
  {
    x: 1087.94,
    y: 396.081,
    power: 13.9366
  },
  {
    x: 1088.54,
    y: 396.283,
    power: 13.9421
  },
  {
    x: 1089.16,
    y: 396.561,
    power: 13.9531
  },
  {
    x: 1089.78,
    y: 396.814,
    power: 13.9558
  },
  {
    x: 1090.38,
    y: 397.067,
    power: 13.9649
  },
  {
    x: 1090.98,
    y: 397.295,
    power: 13.9704
  },
  {
    x: 1091.58,
    y: 397.522,
    power: 13.9759
  },
  {
    x: 1092.2,
    y: 397.75,
    power: 13.9823
  },
  {
    x: 1092.82,
    y: 398.003,
    power: 13.9905
  },
  {
    x: 1093.44,
    y: 398.255,
    power: 13.9987
  },
  {
    x: 1094.04,
    y: 398.508,
    power: 14.0042
  },
  {
    x: 1094.64,
    y: 398.761,
    power: 14.0125
  },
  {
    x: 1095.24,
    y: 399.014,
    power: 14.0179
  },
  {
    x: 1095.86,
    y: 399.241,
    power: 14.0271
  },
  {
    x: 1096.48,
    y: 399.52,
    power: 14.0307
  },
  {
    x: 1097.08,
    y: 399.722,
    power: 14.0399
  },
  {
    x: 1097.7,
    y: 399.949,
    power: 14.0444
  },
  {
    x: 1098.32,
    y: 400.177,
    power: 14.0508
  },
  {
    x: 1098.92,
    y: 400.329,
    power: 14.0572
  },
  {
    x: 1099.52,
    y: 400.582,
    power: 14.0645
  },
  {
    x: 1100.12,
    y: 400.834,
    power: 14.07
  },
  {
    x: 1100.74,
    y: 401.087,
    power: 14.081
  },
  {
    x: 1101.34,
    y: 401.315,
    power: 14.0865
  },
  {
    x: 1101.96,
    y: 401.542,
    power: 14.0947
  },
  {
    x: 1102.56,
    y: 401.77,
    power: 14.1002
  },
  {
    x: 1103.16,
    y: 402.023,
    power: 14.1066
  },
  {
    x: 1103.76,
    y: 402.301,
    power: 14.1102
  },
  {
    x: 1104.38,
    y: 402.554,
    power: 14.1185
  },
  {
    x: 1104.98,
    y: 402.756,
    power: 14.1276
  },
  {
    x: 1105.6,
    y: 402.984,
    power: 14.1303
  },
  {
    x: 1106.22,
    y: 403.236,
    power: 14.1376
  },
  {
    x: 1106.84,
    y: 403.515,
    power: 14.1404
  },
  {
    x: 1107.44,
    y: 403.742,
    power: 14.1504
  },
  {
    x: 1108.04,
    y: 403.995,
    power: 14.1605
  },
  {
    x: 1108.64,
    y: 404.248,
    power: 14.1669
  },
  {
    x: 1109.26,
    y: 404.475,
    power: 14.1705
  },
  {
    x: 1109.88,
    y: 404.753,
    power: 14.1788
  },
  {
    x: 1110.5,
    y: 404.956,
    power: 14.1824
  },
  {
    x: 1111.12,
    y: 405.234,
    power: 14.1906
  },
  {
    x: 1111.74,
    y: 405.436,
    power: 14.198
  },
  {
    x: 1112.34,
    y: 405.664,
    power: 14.2034
  },
  {
    x: 1112.96,
    y: 405.917,
    power: 14.2108
  },
  {
    x: 1113.56,
    y: 406.144,
    power: 14.2172
  },
  {
    x: 1114.18,
    y: 406.422,
    power: 14.2226
  },
  {
    x: 1114.78,
    y: 406.65,
    power: 14.2299
  },
  {
    x: 1115.4,
    y: 406.928,
    power: 14.2336
  },
  {
    x: 1116.02,
    y: 407.181,
    power: 14.24
  },
  {
    x: 1116.64,
    y: 407.459,
    power: 14.2473
  },
  {
    x: 1117.24,
    y: 407.712,
    power: 14.2537
  },
  {
    x: 1117.86,
    y: 407.939,
    power: 14.2592
  },
  {
    x: 1118.46,
    y: 408.167,
    power: 14.2638
  },
  {
    x: 1119.06,
    y: 408.445,
    power: 14.2683
  },
  {
    x: 1119.68,
    y: 408.698,
    power: 14.2738
  },
  {
    x: 1120.28,
    y: 408.951,
    power: 14.2811
  },
  {
    x: 1120.9,
    y: 409.178,
    power: 14.2829
  },
  {
    x: 1121.5,
    y: 409.431,
    power: 14.2875
  },
  {
    x: 1122.1,
    y: 409.684,
    power: 14.2912
  },
  {
    x: 1122.72,
    y: 409.962,
    power: 14.2903
  },
  {
    x: 1123.32,
    y: 410.24,
    power: 14.2866
  }
];

export default uniaxialway;
