import React, { Component } from "react";
import OrderDB from "../data/orderDB.json";
import Order from "./Order/compact";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { FirebaseContext } from "./Firebase";

import { withAuthorization } from "./Session";

const condition = (authUser) => !!authUser;

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      OrderDB,
    };
  }
  render() {
    return (
      <React.Fragment>
        <Container
          style={{
            marginBottom: "48px",
            paddingTop: "24px",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <Container
            maxWidth="lg"
            style={{ paddingLeft: "24px", paddingRight: "24px" }}
          >
            <Typography
              variant="h6"
              component="h2"
              style={{ marginBottom: "15px" }}
              gutterBottom
            >
              Upcoming Tasks
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="stretch"
              wrap="nowrap"
              style={{ marginBottom: "24px", overflowX: "scroll" }}
              spacing={3}
            >
              {OrderDB.map((order) =>
                order.currentProductionStep < 5 ? (
                  <Grid
                      key={order._id}
                    item
                    xs={6}
                    sm={6}
                    style={{
                      minWidth: "400px",
                    }}
                  >
                    <Order key={order._id} order={order} disableBtn={true} />
                  </Grid>
                ) : (
                  ""
                )
              )}
            </Grid>
          </Container>
        </Container>
        <Container
          style={{
            maxWidth: "100%",
            borderTop: "1px solid #dfdfdf",
            paddingTop: "24px",
            paddingLeft: "0",
            paddingRight: "0",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Container
            maxWidth="lg"
            style={{
              paddingTop: "24px",
              paddingBottom: "48px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              style={{ marginBottom: "45px" }}
              gutterBottom
            >
              Closed Tasks
            </Typography>
            <Grid container spacing={3}>
              {OrderDB.map((order) =>
                order.currentProductionStep === 5 ? (
                  <Grid item xs={12} sm={6} key={order._id}>
                    <Order key={order._id} order={order} />
                  </Grid>
                ) : (
                  ""
                )
              )}
            </Grid>
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

export default withAuthorization(condition)(Dashboard);
