import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import * as ROLES from "../../constants/roles";

import OrderDB from "../../data/orderDB.json";
import DisplayQuickStats from "./displayQuickstats";
import DisplayMischprozess from "./displayMischprozess";
import DisplayGraphOne from "./displayGraphOne";
import DisplayGraphTwo from "./displayGraphTwo";
import DisplayGraphRheometer from "./displayGraphRheometer";
import DisplayGraphUniaxial from "./displayGraphUniaxial";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Table1 from "./Table1";
import DisplayGraphStamp1 from "./displayGraphStamp1";
import DisplayGraphTemprature1 from "./displayGraphTemprature1";
import DisplayGraphTorque1 from "./displayGraphTorque1";
import DisplayGraphConversion1 from "./displayGraphConversion1";
import DisplayGraphComparison1 from "./displayGraphComparison1";
let interval;
class ViewOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderid: null,
      OrderDB,
      order: null,
      clientname: null,
      deadline: null,
      step: null,
      loading: false,
      production: [],
      recipe: [],
      rawmaterials: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    let orderRefID = parseInt(this.props.match.params.id);
    let orderArray = Object.values(this.state.OrderDB);

    let foundObjectArray = orderArray.filter(
      (order) => order._id === orderRefID
    );
    let foundOrder = foundObjectArray[0];

    this.setState({
      orderid: orderRefID,
      order: foundOrder,
      clientname: foundOrder.clientName,
      deadline: foundOrder.produceUntil,
      step: foundOrder.currentProductionStep - 1,
    });

    this.props.firebase.rawmaterials().on("value", (snapshot) => {
      const rawmaterialsObject = snapshot.val();

      this.setState({
        rawmaterials: rawmaterialsObject,
      });
    });

    this.props.firebase.productions().on("value", (snapshot) => {
      const productionObject = snapshot.val();
      let foundProductionArray = productionObject.filter(
        (production) =>
          production._id === this.state.order.productionLine[this.state.step]
      );
      let productionObj = foundProductionArray[0];

      this.setState({
        production: productionObj,
      });
    });

    this.props.firebase.recipes().on("value", (snapshot) => {
      const recipesObject = snapshot.val();
      let recipeRefObject = null;
      Object.keys(recipesObject).map((item) => {
        if(parseInt(recipesObject[item]._id) === this.state.production._id) {
          recipeRefObject = recipesObject[item];
        }
      });

      this.setState({
        recipe: recipeRefObject,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.productions().off();
    this.props.firebase.recipes().off();
    this.props.firebase.rawmaterials().off();
  }

  render() {
    const {
      orderid,
      order,
      production,
      recipe,
      rawmaterials,
      clientname,
      deadline,
      step,
      loading
    } = this.state;

    return (
      <div>
        {loading && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              display: "block",
              backgroundColor: "darkgrey",
              padding: "5px 10px",
            }}
          >
            Loading ...
          </div>
        )}

        <RecipesList
          orderid={orderid}
          order={order}
          production={production}
          recipe={recipe}
          rawmaterials={rawmaterials}
          clientname={clientname}
          deadline={deadline}
          step={step}
        />
      </div>
    );
  }
}

const RecipesList = ({
  orderid,
  order,
  production,
  recipe,
  rawmaterials,
  clientname,
  deadline,
  step
}) => (
  <React.Fragment>
    <Container
      component={Paper}
      style={{
        paddingTop: "24px",
        paddingBottom: "48px",
        marginBottom: "30px",
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h5" component="h1">
          {clientname}
        </Typography>
        <Typography component="p" color="textSecondary" gutterBottom>
          Task ID: {orderid}
        </Typography>
      </Container>
      <Divider
        variant="fullWidth"
        style={{
          marginTop: "15px",
          marginBottom: "45px",
          marginLeft: "-24px",
          marginRight: "-24px",
        }}
      />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box>
              <List>
                <ListItem style={{ paddingLeft: "0", paddingRight: "0" }}>
                  <ListItemText primary="Due date" secondary={deadline} />
                </ListItem>
                <ListItem style={{ paddingLeft: "0", paddingRight: "0" }}>
                  <ListItemText primary="Operator" secondary="Erwin Mixer" />
                </ListItem>
              </List>
            </Box>
            <DisplayQuickStats
              key={production._id}
              recipe={recipe}
              production={production}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <DisplayMischprozess
              key={production._id}
              production={production}
              rawmaterials={rawmaterials}
            />
          </Grid>
        </Grid>
      </Container>
    </Container>
    {/*chart 2*/}
      <Container component={Paper}
                 style={{
                     paddingTop: '10px',
                     paddingBottom: '10px',
                     marginBottom: "10px",
                     background: '#e8e8e8'
                 }}>
          <Container maxWidth="lg">
              <Typography variant="h5" component="h1" gutterBottom>
                  Mixing
              </Typography>
              <Typography variant="p" component="p" gutterBottom>
                  Mixing Procedure 1
              </Typography>
          </Container>
      </Container>
    <Container
        component={Paper}
        style={{
          paddingTop: "24px",
          paddingBottom: "48px",
          marginBottom: "60px",
        }}
    >
      <Container maxWidth="lg">
        <Typography variant="h5" component="h1" gutterBottom>
          Stamp
        </Typography>
      </Container>
      <Divider
          variant="fullWidth"
          style={{
            marginTop: "15px",
            marginBottom: "45px",
            marginLeft: "-24px",
            marginRight: "-24px",
          }}
      />
      <Container maxWidth="lg">
        <Box>
          <DisplayGraphStamp1 />
        </Box>
      </Container>
    </Container>
        {/*end chart 2*/}
        {/*start chart temprature 2*/}
    <Container
        component={Paper}
        style={{
          paddingTop: "24px",
          paddingBottom: "48px",
          marginBottom: "60px",
        }}
    >
      <Container maxWidth="lg">
        <Typography variant="h5" component="h1" gutterBottom>
          Temperature
        </Typography>
      </Container>
      <Divider
          variant="fullWidth"
          style={{
            marginTop: "15px",
            marginBottom: "45px",
            marginLeft: "-24px",
            marginRight: "-24px",
          }}
      />
      <Container maxWidth="lg">
        <Box>
          <DisplayGraphTemprature1 />
        </Box>
      </Container>
    </Container>
        {/*end chart temprature 2*/}
        {/*start chart torque 1*/}
      <Container component={Paper}
                 style={{
                     paddingTop: '10px',
                     paddingBottom: '10px',
                     marginBottom: "10px",
                     background: '#e8e8e8'
                 }}>
          <Container maxWidth="lg">
              <Typography variant="h5" component="h1" gutterBottom>
                  Vulcanisation
              </Typography>
              <Typography variant="p" component="p" gutterBottom>
                  Vulcanisate @150°C
              </Typography>
          </Container>
      </Container>
      <Container
          component={Paper}
          style={{
              paddingTop: "24px",
              paddingBottom: "48px",
              marginBottom: "60px",
          }}
      >
          <Container maxWidth="lg">
              <Typography variant="h5" component="h1" gutterBottom>
                  Torque
              </Typography>
              <Typography variant="p" component="p" gutterBottom>
                  Torque @150°C
              </Typography>

          </Container>
          <Divider
              variant="fullWidth"
              style={{
                  marginTop: "15px",
                  marginBottom: "45px",
                  marginLeft: "-24px",
                  marginRight: "-24px",
              }}
          />
          <Container maxWidth="lg">
              <Box>
                  <DisplayGraphTorque1 />
              </Box>
          </Container>
      </Container>
        {/*end chart torque 1*/}
        {/*start conversion ai chart*/}
         <DisplayGraphConversion1 />
        {/*end conversion ai chart*/}
        {/* start comparison AI */}
        <DisplayGraphComparison1 />
        {/*end comparison AI*/}
      <Container component={Paper}
                 style={{
                     paddingTop: '10px',
                     paddingBottom: '10px',
                     marginBottom: "10px",
                     background: '#e8e8e8'
                 }}>
          <Container maxWidth="lg">
              <Typography variant="h5" component="h1" gutterBottom>
                  Properties of the Vulcanisate
              </Typography>
          </Container>
      </Container>
      <Container
          component={Paper}
          style={{
              paddingTop: "24px",
              paddingBottom: "48px",
              marginBottom: "60px",
          }}
      >
          <Container maxWidth="lg">
              <Typography variant="h5" component="h1" gutterBottom>
                  Uniaxial Tension
              </Typography>
              <Typography variant="p" component="p" gutterBottom>
                  1 Produced with Mix Proc 1, Vulcanisation time	t90 21.37 min @ 150°C
              </Typography>
          </Container>
          <Divider
              variant="fullWidth"
              style={{
                  marginTop: "15px",
                  marginBottom: "45px",
                  marginLeft: "-24px",
                  marginRight: "-24px",
              }}
          />
          <Container maxWidth="lg">
              <Box>
                  <DisplayGraphOne />
              </Box>
          </Container>
      </Container>
        <Table1 />

    {/*<Container*/}
    {/*  component={Paper}*/}
    {/*  style={{*/}
    {/*    paddingTop: "24px",*/}
    {/*    paddingBottom: "48px",*/}
    {/*    marginBottom: "60px",*/}
    {/*  }}*/}
    {/*>*/}
      {/*<Container maxWidth="lg">*/}
      {/*  <Typography variant="h5" component="h1" gutterBottom>*/}
      {/*    Analysis of Mixing: Temperature [°C]*/}
      {/*  </Typography>*/}
      {/*</Container>*/}
      {/*<Divider*/}
      {/*  variant="fullWidth"*/}
      {/*  style={{*/}
      {/*    marginTop: "15px",*/}
      {/*    marginBottom: "45px",*/}
      {/*    marginLeft: "-24px",*/}
      {/*    marginRight: "-24px",*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<Container maxWidth="lg">*/}
      {/*  <Box>*/}
      {/*    <DisplayGraphTwo />*/}
      {/*  </Box>*/}
      {/*</Container>*/}
    {/*</Container>*/}

    {/*<Container*/}
    {/*  component={Paper}*/}
    {/*  style={{*/}
    {/*    paddingTop: "24px",*/}
    {/*    paddingBottom: "48px",*/}
    {/*    marginBottom: "60px",*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <Container maxWidth="lg">*/}
    {/*    <Typography variant="h5" component="h1" gutterBottom>*/}
    {/*      Vulkanisation behaviour: Analysis by rheometer*/}
    {/*    </Typography>*/}
    {/*  </Container>*/}
    {/*  <Divider*/}
    {/*    variant="fullWidth"*/}
    {/*    style={{*/}
    {/*      marginTop: "15px",*/}
    {/*      marginBottom: "45px",*/}
    {/*      marginLeft: "-24px",*/}
    {/*      marginRight: "-24px",*/}
    {/*    }}*/}
    {/*  />*/}
    {/*  <Container maxWidth="lg">*/}
    {/*    <Box>*/}
    {/*      <DisplayGraphRheometer />*/}
    {/*    </Box>*/}
    {/*  </Container>*/}
    {/*</Container>*/}

    {/*<Container*/}
    {/*  component={Paper}*/}
    {/*  style={{*/}
    {/*    paddingTop: "24px",*/}
    {/*    paddingBottom: "48px",*/}
    {/*    marginBottom: "30px",*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <Container maxWidth="lg">*/}
    {/*    <Typography variant="h5" component="h1" gutterBottom>*/}
    {/*      Mechanical behaviour: Vulcanized Compound (t_90 @ 150°C), Elongation*/}
    {/*      until break*/}
    {/*    </Typography>*/}
    {/*  </Container>*/}
    {/*  <Divider*/}
    {/*    variant="fullWidth"*/}
    {/*    style={{*/}
    {/*      marginTop: "15px",*/}
    {/*      marginBottom: "45px",*/}
    {/*      marginLeft: "-24px",*/}
    {/*      marginRight: "-24px",*/}
    {/*    }}*/}
    {/*  />*/}
    {/*  <Container maxWidth="lg">*/}
    {/*    <Box>*/}
    {/*      <DisplayGraphUniaxial />*/}
    {/*    </Box>*/}
    {/*  </Container>*/}
    {/*</Container>*/}
  </React.Fragment>
);

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withFirebase)(ViewOrder);
