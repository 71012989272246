import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Backdrop from "@material-ui/core/Backdrop";

class Fallback extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Backdrop
                style={{ zIndex: 1201, color: "#fff" }}
                open={this.props.open}
                onClick={this.props.onHide}
            >
                <Box
                    component={Paper}
                    boxShadow={3}
                    p={2}
                    style={{ textAlign: "center" }}
                >
                    <CircularProgress
                        size="60px"
                        color="primary"
                        style={{
                            marginTop: "30px",
                            marginBottom: "60px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    />
                    <Typography variant="h6" component="h3" gutterBottom>
                        Uploading File to Database
                    </Typography>
                </Box>
            </Backdrop>
        )
    }
}

export default Fallback;