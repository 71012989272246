import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import {StampDataAI, StampDataWithoutAI} from "../../data/StampData";
import {TempratureWithAI, TempratureWithoutAI} from "../../data/TemperatureData";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stripLinesExample = [];

class DisplayGraphTemprature1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.updateChart = this.updateChart.bind(this);
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }
    componentDidMount() {
        this.updateChart();
        setInterval(() => {
            if(!this.state.loaded) {
                this.updateChart();
            }
        }, 5000);
    }

    updateChart() {
        let x = 0;
        if (localStorage.getItem("AIToggle") === "true") {
            if (x === 0) {
                this.setState({loaded: true});
                this.chart.options.data.push({
                    type: "stepLine",
                    markerSize: 5,
                    dataPoints: TempratureWithAI,
                    legendText: 'Critical compound temperature [°C]',
                    showInLegend: true,
                });

                this.chart.render();
                x++;
            }
        } else {
            return;
        }
    }
    toggleDataSeries(e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    render() {
        const options = {
            theme: "light2",
            animationEnabled: true,
            zoomEnabled: true,
            exportEnabled: true,
            axisX: {
                title: "Time in min",
                titleFontColor: "#6D78AD",
                maximum: 400,
                showInLegend: true
            },
            axisY: {
                title: "Temperature [°C]",
                titleFontColor: "#6D78AD",
                lineColor: "#6D78AD",
                labelFontColor: "#6D78AD",
                tickColor: "#6D78AD",
                includeZero: false,
                stripLines: stripLinesExample,
                showInLegend: true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
            },
            data: [
                {
                    type: "line",
                    name: "Compound temperature [°C]",
                    showInLegend: true,
                    dataPoints: TempratureWithoutAI,
                },
            ],
        };

        return (
            <div className="MultipleAxisChart">
                <CanvasJSChart
                    options={options}
                    onRef={(rheoref) => (this.chart = rheoref)}
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}

export default DisplayGraphTemprature1;
