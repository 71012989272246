import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import CalculateDeadline from "./displayDeadline";
import DisplayDiagnostic from "./displayDiagnostic";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DisplaySuccessfulDeadline from "./displaySuccessfulDeadline";

class Order extends Component {
  state = {};

  render() {
    const { order } = this.props;

    // Transfer Object values to variables
    let productionMsg;

    // stepProduction
    switch (order.currentProductionStep) {
      case 1:
        productionMsg = "Mixing - initiated";
        break;
      case 2:
        productionMsg = "Mixing";
        break;
      case 3:
        productionMsg = "Rolling";
        break;
      case 4:
        productionMsg = "Molding";
        break;
      case 5:
        productionMsg = "";
        break;
      default:
        productionMsg = "Awaiting";
        break;
    }

    let produceUntil;
    let orderDiagnostic;

    if (order.currentProductionStep !== 5) {
      produceUntil = (
        <CalculateDeadline key={order._id} deadline={order.produceUntil} />
      );
    } else {
      produceUntil = (
          <DisplaySuccessfulDeadline key={order._id} deadline={order.produceUntil} />
      )
    }
    if (order.currentProductionStep === 5) {
      console.log(order);
      orderDiagnostic = (
        <DisplayDiagnostic
          key={order._id}
          status={order.currentProductionStep}
          diagnostic={order.diagnostics}
        />
      );
    }

    return (
      <Card key={order._id} style={{ borderRadius: "0px" }}>
        {produceUntil}
        <CardHeader
          title={order.clientName}
          subheader={`Task ID: ${order._id}`}
        />
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography component="p" color="textSecondary">
                {order.dateToFinish}
              </Typography>
            </Box>
          </Box>
          <Typography component="p" color="textSecondary">
            {productionMsg}
          </Typography>
          {orderDiagnostic}
        </CardContent>
        <CardActions style={{ padding: "16px" }}>
          <Button
            startIcon={<ListAltIcon />}
            component={Link}
            variant="contained"
            style={this.props.disableBtn ? {} : { color: "#fff", backgroundColor: "#00B19E" }}
            to={`/orders/${order._id}`}
            disabled={!!this.props.disableBtn}
          >
            Details
          </Button>
        </CardActions>
      </Card>
    );
  }
}

Order.propTypes = {
  order: PropTypes.object.isRequired,
};

export default Order;
