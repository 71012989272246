import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import PublishIcon from "@material-ui/icons/Publish";

import * as ROUTES from "../../constants/routes";

const useStyles = makeStyles({
  list: {
    width: 320,
  },
  fullList: {
    width: "auto",
  },
});

function valuetext(value) {
  return `${value}°C`;
}

export default function SwipeableTemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    property1: true,
    property2: false,
    property3: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    // if (
    //   event &&
    //   event.type === "keydown" &&
    //   (event.key === "Tab" || event.key === "Shift")
    // ) {
    //   return;
    // }

    setState({ ...state, [anchor]: open });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { property1, property2, property3 } = state;
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem
          style={{
            alignItems: "flex-start",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <Typography id="discrete-slider" gutterBottom>
            Stiffness
          </Typography>
          <Slider
            value={props.slider1Val}
            onChange={props.onChangeSlider1}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={props.initialValueSlider1[0]}
            max={props.initialValueSlider1[1]}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem style={{ alignItems: "flex-start", flexDirection: "column" }}>
          <Typography id="discrete-slider" gutterBottom>
            Elongation
          </Typography>
          <Slider
            value={props.slider2Val}
            onChange={props.onChangeSlider2}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={props.initialValueSlider2[0] - 100}
            max={props.initialValueSlider2[1] + 100}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem style={{ alignItems: "flex-start", flexDirection: "column" }}>
          <Typography id="discrete-slider" gutterBottom>
            Tensile Strength
          </Typography>
          <Slider
            value={props.slider3Val}
            onChange={props.onChangeSlider3}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={props.initialValueSlider3[0]}
            max={props.initialValueSlider3[1] + 10}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem style={{ alignItems: "flex-start", flexDirection: "column" }}>
          <Typography id="discrete-slider" gutterBottom>
            Rebound
          </Typography>
          <Slider
            value={props.slider4Val}
            onChange={props.onChangeSlider4}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={10}
            marks
            min={0}
            max={0}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem style={{ alignItems: "flex-start", flexDirection: "column" }}>
          <Typography id="discrete-slider" gutterBottom>
            Hardness
          </Typography>
          <Slider
            value={props.slider5Val}
            onChange={props.onChangeSlider5}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={props.initialValueSlider5[0]}
            max={props.initialValueSlider5[1] + 10}
          />
        </ListItem>
      </List>
      <List>
        <ListItem>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Properties</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={property1}
                    onChange={handleChange}
                    name="property1"
                  />
                }
                label="Property 1"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={property2}
                    onChange={handleChange}
                    name="property2"
                  />
                }
                label="Property 2"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={property3}
                    onChange={handleChange}
                    name="property3"
                  />
                }
                label="Property 3"
              />
            </FormGroup>
            <FormHelperText>(following DIN 53 517)</FormHelperText>
          </FormControl>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Container maxWidth="lg" style={{ position: "relative" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={toggleDrawer("right", true)}
            style={{
              position: "absolute",
              zIndex: "1",
              left: "220px",
              top: "13px",
            }}
          >
            <FilterNoneIcon style={{ marginRight: "10px" }} /> Filter
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={ROUTES.RECIPE_UPLOAD}
            style={{
              position: "absolute",
              zIndex: "1",
              left: "340px",
              top: "13px",
            }}
          >
            <PublishIcon style={{ marginRight: "10px" }} /> Upload recipe
          </Button>
        </Container>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
