import React from "react";
import { Route, HashRouter, Switch } from "react-router-dom";
import Header from "./components/layout/Header";
import Dashboard from "./components/Dashboard";
import SignUpPage from "./components/SignUp";
import SignInPage from "./components/SignIn";
import PasswordForgetPage from "./components/PasswordForget";
import AccountPage from "./components/Account";
import AdminPage from "./components/Admin";
import RecipesPage from "./components/Recipe";
import ViewRecipe from "./components/Recipe/single";
import RecipeUpload from "./components/Recipe/upload";
import CommodityPage from "./components/Commodity";
import OrdersPage from "./components/Order";
import ViewOrder from "./components/Order/single";
import ViewWorkOrder from "./components/Order/details";
import PDFRecipe from "./components/PDF/Recipe";
import PDFOEM from "./components/PDF/OEM";
import WorkOrder from "./components/Order/workOrder";
import WizardOrder from "./components/Order/wizardOrder";
import 'react-notifications/lib/notifications.css';

import * as ROUTES from "./constants/routes";
import { withAuthentication } from "./components/Session";

import Paper from "@material-ui/core/Paper";
import UploadRawMaterial from "./components/Recipe/UploadRawmaterials";
import {NotificationContainer} from 'react-notifications';
import ReportPDF from "./components/PDF/Report";
import ScrollToTop from "./constants/ScrollToTop";

class App extends React.Component {
  render() {
    return (
        <HashRouter>
          <div className="App">
            <Paper elevation={0} />
            <Header />
            <main className="content" style={{ paddingTop: "90px" }}>
              <Switch>
                <ScrollToTop>
                  <Route exact path="/" component={Dashboard} />
                  <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                  <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                  <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                  <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                  <Route path={ROUTES.ADMIN} component={AdminPage} />
                  <Route exact path={ROUTES.COMMODITIES} component={CommodityPage} />
                  <Route exact path={ROUTES.RECIPES} component={RecipesPage} />
                  <Route exact path={ROUTES.VIEW_RECIPE} component={ViewRecipe} />
                  <Route exact path={ROUTES.RECIPE_UPLOAD} component={RecipeUpload} />
                  <Route exact path={ROUTES.ORDERS} component={OrdersPage} />
                  <Route exact path={ROUTES.WORK_ORDERS} component={WorkOrder} />
                  <Route exact path={ROUTES.VIEW_ORDER} component={ViewOrder} />
                  <Route exact path={ROUTES.WIZARD_ORDER} component={WizardOrder} />
                  <Route
                      exact
                      path={ROUTES.VIEW_WORK_ORDER}
                      component={ViewWorkOrder}
                  />
                  <Route exact path={ROUTES.PDF_RECIPE} component={PDFRecipe} />
                  <Route exact path={ROUTES.PDF_OEM} component={PDFOEM} />
                  <Route exact path={ROUTES.PDF_REPORT} component={ReportPDF} />
                  <Route exact path={ROUTES.UPLOAD_RECIPE} component={UploadRawMaterial} />
                </ScrollToTop>
              </Switch>
            </main>
            <Paper />
            <NotificationContainer />
          </div>
        </HashRouter>
    );
  }
}

export default withAuthentication(App);
