export const StampDataWithoutAI =  [
    {
        "x": 0,
        "y": 110
    },
    {
        "x": 0,
        "y": 110
    },
    {
        "x": 1,
        "y": 110
    },
    {
        "x": 2,
        "y": 110
    },
    {
        "x": 3,
        "y": 110
    },
    {
        "x": 4,
        "y": 110
    },
    {
        "x": 5,
        "y": 110
    },
    {
        "x": 6,
        "y": 110
    },
    {
        "x": 7,
        "y": 110
    },
    {
        "x": 8,
        "y": 110
    },
    {
        "x": 9,
        "y": 110
    },
    {
        "x": 10,
        "y": 110
    },
    {
        "x": 11,
        "y": 110
    },
    {
        "x": 12,
        "y": 110
    },
    {
        "x": 13,
        "y": 109.7
    },
    {
        "x": 14,
        "y": 1
    },
    {
        "x": 15,
        "y": 0
    },
    {
        "x": 16,
        "y": 0
    },
    {
        "x": 17,
        "y": 0
    },
    {
        "x": 18,
        "y": 0
    },
    {
        "x": 19,
        "y": 0
    },
    {
        "x": 20,
        "y": 0
    },
    {
        "x": 21,
        "y": 0
    },
    {
        "x": 22,
        "y": 0
    },
    {
        "x": 23,
        "y": 0
    },
    {
        "x": 24,
        "y": 0
    },
    {
        "x": 25,
        "y": 0
    },
    {
        "x": 26,
        "y": 0
    },
    {
        "x": 27,
        "y": 0
    },
    {
        "x": 28,
        "y": 0
    },
    {
        "x": 29,
        "y": 0
    },
    {
        "x": 30,
        "y": 0
    },
    {
        "x": 31,
        "y": 0
    },
    {
        "x": 32,
        "y": 0
    },
    {
        "x": 33,
        "y": 0
    },
    {
        "x": 34,
        "y": 0
    },
    {
        "x": 35,
        "y": 0
    },
    {
        "x": 36,
        "y": 0
    },
    {
        "x": 37,
        "y": 0
    },
    {
        "x": 38,
        "y": 0
    },
    {
        "x": 39,
        "y": 0
    },
    {
        "x": 41,
        "y": 0
    },
    {
        "x": 41,
        "y": 0
    },
    {
        "x": 43,
        "y": 0
    },
    {
        "x": 43,
        "y": 0
    },
    {
        "x": 45,
        "y": 0
    },
    {
        "x": 46,
        "y": 0
    },
    {
        "x": 47,
        "y": 0
    },
    {
        "x": 48,
        "y": 0
    },
    {
        "x": 49,
        "y": 0
    },
    {
        "x": 50,
        "y": 0
    },
    {
        "x": 51,
        "y": 0
    },
    {
        "x": 52,
        "y": 0
    },
    {
        "x": 53,
        "y": 0
    },
    {
        "x": 54,
        "y": 0
    },
    {
        "x": 55,
        "y": 0
    },
    {
        "x": 56,
        "y": 0
    },
    {
        "x": 57,
        "y": 0
    },
    {
        "x": 58,
        "y": 0
    },
    {
        "x": 59,
        "y": 0
    },
    {
        "x": 60,
        "y": 0
    },
    {
        "x": 61,
        "y": 0
    },
    {
        "x": 62,
        "y": 0
    },
    {
        "x": 63,
        "y": 0
    },
    {
        "x": 64,
        "y": 0
    },
    {
        "x": 65,
        "y": 0
    },
    {
        "x": 66,
        "y": 0
    },
    {
        "x": 67,
        "y": 0
    },
    {
        "x": 68,
        "y": 0
    },
    {
        "x": 69,
        "y": 0
    },
    {
        "x": 70,
        "y": 0
    },
    {
        "x": 71,
        "y": 0
    },
    {
        "x": 72,
        "y": 0
    },
    {
        "x": 73,
        "y": 0
    },
    {
        "x": 74,
        "y": 0
    },
    {
        "x": 75,
        "y": 45.6
    },
    {
        "x": 76,
        "y": 98.8
    },
    {
        "x": 77,
        "y": 98.8
    },
    {
        "x": 78,
        "y": 98.8
    },
    {
        "x": 79,
        "y": 98.8
    },
    {
        "x": 80,
        "y": 98.8
    },
    {
        "x": 81,
        "y": 98.8
    },
    {
        "x": 82,
        "y": 98.8
    },
    {
        "x": 83,
        "y": 98.8
    },
    {
        "x": 84,
        "y": 98.8
    },
    {
        "x": 85,
        "y": 98.8
    },
    {
        "x": 86,
        "y": 98.8
    },
    {
        "x": 87,
        "y": 98.8
    },
    {
        "x": 88,
        "y": 98.8
    },
    {
        "x": 89,
        "y": 98.8
    },
    {
        "x": 90,
        "y": 98.8
    },
    {
        "x": 91,
        "y": 98.8
    },
    {
        "x": 92,
        "y": 98.8
    },
    {
        "x": 93,
        "y": 98.8
    },
    {
        "x": 94,
        "y": 98.8
    },
    {
        "x": 95,
        "y": 98.8
    },
    {
        "x": 96,
        "y": 49.1
    },
    {
        "x": 97,
        "y": 11.2
    },
    {
        "x": 98,
        "y": 5
    },
    {
        "x": 99,
        "y": 0
    },
    {
        "x": 100,
        "y": 0
    },
    {
        "x": 101,
        "y": 0
    },
    {
        "x": 102,
        "y": 0
    },
    {
        "x": 103,
        "y": 0
    },
    {
        "x": 104,
        "y": 0
    },
    {
        "x": 105,
        "y": 0
    },
    {
        "x": 106,
        "y": 0
    },
    {
        "x": 107,
        "y": 0
    },
    {
        "x": 108,
        "y": 0
    },
    {
        "x": 109,
        "y": 0
    },
    {
        "x": 110,
        "y": 0
    },
    {
        "x": 112,
        "y": 0
    },
    {
        "x": 112,
        "y": 0
    },
    {
        "x": 114,
        "y": 0
    },
    {
        "x": 114,
        "y": 0
    },
    {
        "x": 116,
        "y": 0
    },
    {
        "x": 117,
        "y": 0
    },
    {
        "x": 118,
        "y": 0
    },
    {
        "x": 119,
        "y": 0
    },
    {
        "x": 120,
        "y": 0
    },
    {
        "x": 121,
        "y": 0
    },
    {
        "x": 122,
        "y": 0
    },
    {
        "x": 123,
        "y": 0
    },
    {
        "x": 124,
        "y": 0
    },
    {
        "x": 125,
        "y": 0
    },
    {
        "x": 126,
        "y": 0
    },
    {
        "x": 127,
        "y": 0
    },
    {
        "x": 128,
        "y": 0
    },
    {
        "x": 129,
        "y": 0
    },
    {
        "x": 130,
        "y": 0
    },
    {
        "x": 131,
        "y": 0
    },
    {
        "x": 132,
        "y": 0
    },
    {
        "x": 133,
        "y": 0
    },
    {
        "x": 134,
        "y": 0
    },
    {
        "x": 135,
        "y": 0
    },
    {
        "x": 136,
        "y": 0
    },
    {
        "x": 137,
        "y": 0
    },
    {
        "x": 138,
        "y": 0
    },
    {
        "x": 139,
        "y": 0
    },
    {
        "x": 140,
        "y": 0
    },
    {
        "x": 141,
        "y": 0
    },
    {
        "x": 142,
        "y": 0
    },
    {
        "x": 143,
        "y": 0
    },
    {
        "x": 144,
        "y": 0
    },
    {
        "x": 145,
        "y": 0
    },
    {
        "x": 146,
        "y": 0
    },
    {
        "x": 147,
        "y": 0
    },
    {
        "x": 148,
        "y": 0
    },
    {
        "x": 149,
        "y": 0
    },
    {
        "x": 150,
        "y": 0
    },
    {
        "x": 151,
        "y": 0
    },
    {
        "x": 152,
        "y": 0
    },
    {
        "x": 153,
        "y": 0
    },
    {
        "x": 154,
        "y": 0
    },
    {
        "x": 155,
        "y": 0
    },
    {
        "x": 156,
        "y": 0
    },
    {
        "x": 157,
        "y": 0
    },
    {
        "x": 158,
        "y": 35.2
    },
    {
        "x": 159,
        "y": 97.7
    },
    {
        "x": 160,
        "y": 97.7
    },
    {
        "x": 161,
        "y": 97.7
    },
    {
        "x": 162,
        "y": 97.7
    },
    {
        "x": 163,
        "y": 97.7
    },
    {
        "x": 164,
        "y": 97.7
    },
    {
        "x": 165,
        "y": 97.7
    },
    {
        "x": 166,
        "y": 97.7
    },
    {
        "x": 167,
        "y": 97.7
    },
    {
        "x": 168,
        "y": 97.7
    },
    {
        "x": 169,
        "y": 97.7
    },
    {
        "x": 170,
        "y": 97.7
    },
    {
        "x": 171,
        "y": 97.7
    },
    {
        "x": 172,
        "y": 97.7
    },
    {
        "x": 173,
        "y": 97.7
    },
    {
        "x": 174,
        "y": 97.7
    },
    {
        "x": 175,
        "y": 97.7
    },
    {
        "x": 176,
        "y": 97.7
    },
    {
        "x": 177,
        "y": 97.7
    },
    {
        "x": 178,
        "y": 97.7
    },
    {
        "x": 179,
        "y": 83.9
    },
    {
        "x": 180,
        "y": 35.6
    },
    {
        "x": 181,
        "y": 13
    },
    {
        "x": 182,
        "y": 0
    },
    {
        "x": 183,
        "y": 0
    },
    {
        "x": 185,
        "y": 0
    },
    {
        "x": 185,
        "y": 0
    },
    {
        "x": 187,
        "y": 0
    },
    {
        "x": 187,
        "y": 0
    },
    {
        "x": 189,
        "y": 0
    },
    {
        "x": 190,
        "y": 0
    },
    {
        "x": 191,
        "y": 0
    },
    {
        "x": 192,
        "y": 0
    },
    {
        "x": 193,
        "y": 0
    },
    {
        "x": 194,
        "y": 0
    },
    {
        "x": 195,
        "y": 0
    },
    {
        "x": 196,
        "y": 0
    },
    {
        "x": 197,
        "y": 0
    },
    {
        "x": 198,
        "y": 0
    },
    {
        "x": 199,
        "y": 0
    },
    {
        "x": 200,
        "y": 0
    },
    {
        "x": 201,
        "y": 0
    },
    {
        "x": 202,
        "y": 0
    },
    {
        "x": 203,
        "y": 0
    },
    {
        "x": 204,
        "y": 0
    },
    {
        "x": 205,
        "y": 0
    },
    {
        "x": 206,
        "y": 0
    },
    {
        "x": 207,
        "y": 0
    },
    {
        "x": 208,
        "y": 0
    },
    {
        "x": 209,
        "y": 0
    },
    {
        "x": 210,
        "y": 0
    },
    {
        "x": 211,
        "y": 0
    },
    {
        "x": 212,
        "y": 0
    },
    {
        "x": 213,
        "y": 0
    },
    {
        "x": 214,
        "y": 0
    },
    {
        "x": 215,
        "y": 0
    },
    {
        "x": 216,
        "y": 0
    },
    {
        "x": 217,
        "y": 0
    },
    {
        "x": 218,
        "y": 0
    },
    {
        "x": 219,
        "y": 0
    },
    {
        "x": 220,
        "y": 0
    },
    {
        "x": 221,
        "y": 0
    },
    {
        "x": 222,
        "y": 0
    },
    {
        "x": 223,
        "y": 0
    },
    {
        "x": 224,
        "y": 0
    },
    {
        "x": 225,
        "y": 0
    },
    {
        "x": 226,
        "y": 0
    },
    {
        "x": 227,
        "y": 0
    },
    {
        "x": 228,
        "y": 0
    },
    {
        "x": 229,
        "y": 0
    },
    {
        "x": 230,
        "y": 0
    },
    {
        "x": 231,
        "y": 0
    },
    {
        "x": 232,
        "y": 0
    },
    {
        "x": 233,
        "y": 0
    },
    {
        "x": 234,
        "y": 0
    },
    {
        "x": 235,
        "y": 0
    },
    {
        "x": 236,
        "y": 0
    },
    {
        "x": 237,
        "y": 0
    },
    {
        "x": 238,
        "y": 0
    },
    {
        "x": 239,
        "y": 0
    },
    {
        "x": 240,
        "y": 0
    },
    {
        "x": 241,
        "y": 0
    },
    {
        "x": 242,
        "y": 0
    },
    {
        "x": 243,
        "y": 0
    },
    {
        "x": 244,
        "y": 0
    },
    {
        "x": 245,
        "y": 0
    },
    {
        "x": 246,
        "y": 0
    },
    {
        "x": 247,
        "y": 0
    },
    {
        "x": 248,
        "y": 0
    },
    {
        "x": 249,
        "y": 0
    },
    {
        "x": 250,
        "y": 0
    },
    {
        "x": 251,
        "y": 0
    },
    {
        "x": 252,
        "y": 0
    },
    {
        "x": 253,
        "y": 0
    },
    {
        "x": 254,
        "y": 0
    },
    {
        "x": 255,
        "y": 0
    },
    {
        "x": 256,
        "y": 0
    },
    {
        "x": 258,
        "y": 0
    },
    {
        "x": 258,
        "y": 0
    },
    {
        "x": 260,
        "y": 0
    },
    {
        "x": 260,
        "y": 0
    },
    {
        "x": 262,
        "y": 0
    },
    {
        "x": 263,
        "y": 0
    },
    {
        "x": 264,
        "y": 0
    },
    {
        "x": 265,
        "y": 0
    },
    {
        "x": 266,
        "y": 0
    },
    {
        "x": 267,
        "y": 0
    },
    {
        "x": 268,
        "y": 0
    },
    {
        "x": 269,
        "y": 0
    },
    {
        "x": 270,
        "y": 0
    },
    {
        "x": 271,
        "y": 0
    },
    {
        "x": 272,
        "y": 0
    },
    {
        "x": 273,
        "y": 60.8
    },
    {
        "x": 274,
        "y": 101.1
    },
    {
        "x": 275,
        "y": 101.1
    },
    {
        "x": 276,
        "y": 101.1
    },
    {
        "x": 277,
        "y": 101.1
    },
    {
        "x": 278,
        "y": 101.1
    },
    {
        "x": 279,
        "y": 101.1
    },
    {
        "x": 280,
        "y": 101.1
    },
    {
        "x": 281,
        "y": 101.1
    },
    {
        "x": 282,
        "y": 101.1
    },
    {
        "x": 283,
        "y": 101.1
    },
    {
        "x": 284,
        "y": 101.1
    },
    {
        "x": 285,
        "y": 101.1
    },
    {
        "x": 286,
        "y": 101.1
    },
    {
        "x": 287,
        "y": 101.1
    },
    {
        "x": 288,
        "y": 101.1
    },
    {
        "x": 289,
        "y": 101.1
    },
    {
        "x": 290,
        "y": 101.1
    },
    {
        "x": 291,
        "y": 101.1
    },
    {
        "x": 292,
        "y": 101.1
    },
    {
        "x": 293,
        "y": 101.1
    },
    {
        "x": 294,
        "y": 0
    },
    {
        "x": 295,
        "y": 0
    },
    {
        "x": 296,
        "y": 0
    },
    {
        "x": 297,
        "y": 0
    },
    {
        "x": 298,
        "y": 0
    },
    {
        "x": 299,
        "y": 0
    },
    {
        "x": 300,
        "y": 0
    },
    {
        "x": 301,
        "y": 0
    },
    {
        "x": 302,
        "y": 0
    },
    {
        "x": 303,
        "y": 0
    },
    {
        "x": 304,
        "y": 0
    },
    {
        "x": 305,
        "y": 0
    },
    {
        "x": 306,
        "y": 0
    },
    {
        "x": 307,
        "y": 0
    },
    {
        "x": 308,
        "y": 0
    },
    {
        "x": 309,
        "y": 0
    },
    {
        "x": 310,
        "y": 0
    },
    {
        "x": 311,
        "y": 0
    },
    {
        "x": 312,
        "y": 0
    },
    {
        "x": 313,
        "y": 0
    },
    {
        "x": 314,
        "y": 0
    },
    {
        "x": 315,
        "y": 0
    },
    {
        "x": 316,
        "y": 0
    },
    {
        "x": 317,
        "y": 0
    },
    {
        "x": 318,
        "y": 0
    },
    {
        "x": 319,
        "y": 0
    },
    {
        "x": 320,
        "y": 0
    },
    {
        "x": 321,
        "y": 0
    },
    {
        "x": 322,
        "y": 0
    },
    {
        "x": 323,
        "y": 0
    },
    {
        "x": 324,
        "y": 0
    },
    {
        "x": 325,
        "y": 0
    },
    {
        "x": 326,
        "y": 0
    },
    {
        "x": 327,
        "y": 0
    },
    {
        "x": 329,
        "y": 0
    },
    {
        "x": 329,
        "y": 0
    },
    {
        "x": 331,
        "y": 0
    },
    {
        "x": 331,
        "y": 0
    },
    {
        "x": 333,
        "y": 0
    },
    {
        "x": 334,
        "y": 0
    },
    {
        "x": 335,
        "y": 0
    },
    {
        "x": 336,
        "y": 0
    },
    {
        "x": 337,
        "y": 0
    },
    {
        "x": 338,
        "y": 0
    },
    {
        "x": 339,
        "y": 0
    },
    {
        "x": 340,
        "y": 0
    },
    {
        "x": 341,
        "y": 0
    },
    {
        "x": 342,
        "y": 0
    },
    {
        "x": 343,
        "y": 0
    },
    {
        "x": 344,
        "y": 0
    },
    {
        "x": 345,
        "y": 0
    },
    {
        "x": 346,
        "y": 0
    },
    {
        "x": 347,
        "y": 0
    },
    {
        "x": 348,
        "y": 0
    },
    {
        "x": 349,
        "y": 0
    },
    {
        "x": 350,
        "y": 0
    },
    {
        "x": 351,
        "y": 0
    },
    {
        "x": 352,
        "y": 0
    },
    {
        "x": 353,
        "y": 0
    },
    {
        "x": 354,
        "y": 0
    },
    {
        "x": 355,
        "y": 0
    },
    {
        "x": 356,
        "y": 0
    },
    {
        "x": 357,
        "y": 0
    },
    {
        "x": 358,
        "y": 0
    },
    {
        "x": 359,
        "y": 0
    },
    {
        "x": 360,
        "y": 0
    },
    {
        "x": 361,
        "y": 0
    },
    {
        "x": 362,
        "y": 0
    },
    {
        "x": 363,
        "y": 0
    },
    {
        "x": 364,
        "y": 0
    },
    {
        "x": 365,
        "y": 0
    },
    {
        "x": 366,
        "y": 0
    },
    {
        "x": 367,
        "y": 0
    },
    {
        "x": 368,
        "y": 0
    },
    {
        "x": 369,
        "y": 0
    },
    {
        "x": 370,
        "y": 0
    },
    {
        "x": 371,
        "y": 0
    },
    {
        "x": 372,
        "y": 0
    },
    {
        "x": 373,
        "y": 0
    },
    {
        "x": 374,
        "y": 0
    },
    {
        "x": 375,
        "y": 0
    },
    {
        "x": 376,
        "y": 0
    },
    {
        "x": 377,
        "y": 0
    }
];

export const StampDataAI = [
    {
        "x": 0,
        "y": 0.1
    },
    {
        "x": 0,
        "y": 0.1
    },
    {
        "x": 1,
        "y": 0.1
    },
    {
        "x": 2,
        "y": 0.1
    },
    {
        "x": 3,
        "y": 0.1
    },
    {
        "x": 4,
        "y": 0.1
    },
    {
        "x": 5,
        "y": 0.1
    },
    {
        "x": 6,
        "y": 0.1
    },
    {
        "x": 7,
        "y": 0.1
    },
    {
        "x": 8,
        "y": 0.1
    },
    {
        "x": 9,
        "y": 0.1
    },
    {
        "x": 10,
        "y": 4.2
    },
    {
        "x": 11,
        "y": 3.8
    },
    {
        "x": 12,
        "y": 3.4
    },
    {
        "x": 13,
        "y": 3.3
    },
    {
        "x": 14,
        "y": 3.7
    },
    {
        "x": 15,
        "y": 4.6
    },
    {
        "x": 16,
        "y": 5.3
    },
    {
        "x": 17,
        "y": 5.9
    },
    {
        "x": 18,
        "y": 6.2
    },
    {
        "x": 19,
        "y": 6.5
    },
    {
        "x": 20,
        "y": 6.6
    },
    {
        "x": 21,
        "y": 6.7
    },
    {
        "x": 22,
        "y": 6.7
    },
    {
        "x": 23,
        "y": 6.7
    },
    {
        "x": 24,
        "y": 6.8
    },
    {
        "x": 25,
        "y": 6.8
    },
    {
        "x": 26,
        "y": 6.8
    },
    {
        "x": 27,
        "y": 6.8
    },
    {
        "x": 28,
        "y": 6.8
    },
    {
        "x": 29,
        "y": 6.8
    },
    {
        "x": 30,
        "y": 6.8
    },
    {
        "x": 31,
        "y": 6.8
    },
    {
        "x": 32,
        "y": 6.8
    },
    {
        "x": 33,
        "y": 6.8
    },
    {
        "x": 34,
        "y": 6.8
    },
    {
        "x": 35,
        "y": 6.8
    },
    {
        "x": 36,
        "y": 6.8
    },
    {
        "x": 37,
        "y": 6.8
    },
    {
        "x": 38,
        "y": 6.8
    },
    {
        "x": 39,
        "y": 6.8
    },
    {
        "x": 41,
        "y": 6.8
    },
    {
        "x": 41,
        "y": 6.8
    },
    {
        "x": 43,
        "y": 6.8
    },
    {
        "x": 43,
        "y": 6.8
    },
    {
        "x": 45,
        "y": 6.8
    },
    {
        "x": 46,
        "y": 6.8
    },
    {
        "x": 47,
        "y": 6.8
    },
    {
        "x": 48,
        "y": 6.8
    },
    {
        "x": 49,
        "y": 6.8
    },
    {
        "x": 50,
        "y": 6.8
    },
    {
        "x": 51,
        "y": 6.8
    },
    {
        "x": 52,
        "y": 6.8
    },
    {
        "x": 53,
        "y": 6.8
    },
    {
        "x": 54,
        "y": 6.8
    },
    {
        "x": 55,
        "y": 6.8
    },
    {
        "x": 56,
        "y": 6.8
    },
    {
        "x": 57,
        "y": 6.8
    },
    {
        "x": 58,
        "y": 6.8
    },
    {
        "x": 59,
        "y": 6.8
    },
    {
        "x": 60,
        "y": 6.8
    },
    {
        "x": 61,
        "y": 6.8
    },
    {
        "x": 62,
        "y": 6.8
    },
    {
        "x": 63,
        "y": 6.8
    },
    {
        "x": 64,
        "y": 6.8
    },
    {
        "x": 65,
        "y": 6.8
    },
    {
        "x": 66,
        "y": 6.8
    },
    {
        "x": 67,
        "y": 6.8
    },
    {
        "x": 68,
        "y": 6.8
    },
    {
        "x": 69,
        "y": 6.8
    },
    {
        "x": 70,
        "y": 6.8
    },
    {
        "x": 71,
        "y": 6.8
    },
    {
        "x": 72,
        "y": 6.8
    },
    {
        "x": 73,
        "y": 6.8
    },
    {
        "x": 74,
        "y": 4.3
    },
    {
        "x": 75,
        "y": 2.8
    },
    {
        "x": 76,
        "y": 2.5
    },
    {
        "x": 77,
        "y": 2.2
    },
    {
        "x": 78,
        "y": 2
    },
    {
        "x": 79,
        "y": 2.3
    },
    {
        "x": 80,
        "y": 2.2
    },
    {
        "x": 81,
        "y": 2.2
    },
    {
        "x": 82,
        "y": 2.2
    },
    {
        "x": 83,
        "y": 2.2
    },
    {
        "x": 84,
        "y": 2.1
    },
    {
        "x": 85,
        "y": 2.1
    },
    {
        "x": 86,
        "y": 2.1
    },
    {
        "x": 87,
        "y": 2
    },
    {
        "x": 88,
        "y": 2
    },
    {
        "x": 89,
        "y": 2
    },
    {
        "x": 90,
        "y": 2
    },
    {
        "x": 91,
        "y": 1.9
    },
    {
        "x": 92,
        "y": 5.6
    },
    {
        "x": 93,
        "y": 4.1
    },
    {
        "x": 94,
        "y": 3.8
    },
    {
        "x": 95,
        "y": 3.4
    },
    {
        "x": 96,
        "y": 3.5
    },
    {
        "x": 97,
        "y": 4.2
    },
    {
        "x": 98,
        "y": 4.9
    },
    {
        "x": 99,
        "y": 5.5
    },
    {
        "x": 100,
        "y": 6
    },
    {
        "x": 101,
        "y": 6.4
    },
    {
        "x": 102,
        "y": 6.6
    },
    {
        "x": 103,
        "y": 6.7
    },
    {
        "x": 104,
        "y": 6.7
    },
    {
        "x": 105,
        "y": 6.8
    },
    {
        "x": 106,
        "y": 6.8
    },
    {
        "x": 107,
        "y": 6.8
    },
    {
        "x": 108,
        "y": 6.8
    },
    {
        "x": 109,
        "y": 6.8
    },
    {
        "x": 110,
        "y": 6.8
    },
    {
        "x": 112,
        "y": 6.8
    },
    {
        "x": 112,
        "y": 6.8
    },
    {
        "x": 114,
        "y": 6.8
    },
    {
        "x": 114,
        "y": 6.8
    },
    {
        "x": 116,
        "y": 6.9
    },
    {
        "x": 117,
        "y": 6.9
    },
    {
        "x": 118,
        "y": 6.9
    },
    {
        "x": 119,
        "y": 6.9
    },
    {
        "x": 120,
        "y": 6.9
    },
    {
        "x": 121,
        "y": 6.9
    },
    {
        "x": 122,
        "y": 6.9
    },
    {
        "x": 123,
        "y": 6.9
    },
    {
        "x": 124,
        "y": 6.9
    },
    {
        "x": 125,
        "y": 6.9
    },
    {
        "x": 126,
        "y": 6.9
    },
    {
        "x": 127,
        "y": 6.9
    },
    {
        "x": 128,
        "y": 6.9
    },
    {
        "x": 129,
        "y": 6.9
    },
    {
        "x": 130,
        "y": 6.9
    },
    {
        "x": 131,
        "y": 6.9
    },
    {
        "x": 132,
        "y": 6.9
    },
    {
        "x": 133,
        "y": 7
    },
    {
        "x": 134,
        "y": 7
    },
    {
        "x": 135,
        "y": 7
    },
    {
        "x": 136,
        "y": 7
    },
    {
        "x": 137,
        "y": 7
    },
    {
        "x": 138,
        "y": 7
    },
    {
        "x": 139,
        "y": 7
    },
    {
        "x": 140,
        "y": 7
    },
    {
        "x": 141,
        "y": 7
    },
    {
        "x": 142,
        "y": 7
    },
    {
        "x": 143,
        "y": 7
    },
    {
        "x": 144,
        "y": 7
    },
    {
        "x": 145,
        "y": 7
    },
    {
        "x": 146,
        "y": 7
    },
    {
        "x": 147,
        "y": 7
    },
    {
        "x": 148,
        "y": 7
    },
    {
        "x": 149,
        "y": 7
    },
    {
        "x": 150,
        "y": 7
    },
    {
        "x": 151,
        "y": 7
    },
    {
        "x": 152,
        "y": 7
    },
    {
        "x": 153,
        "y": 7
    },
    {
        "x": 154,
        "y": 7
    },
    {
        "x": 155,
        "y": 7
    },
    {
        "x": 156,
        "y": 7
    },
    {
        "x": 157,
        "y": 4.6
    },
    {
        "x": 158,
        "y": 3.1
    },
    {
        "x": 159,
        "y": 2.6
    },
    {
        "x": 160,
        "y": 2.3
    },
    {
        "x": 161,
        "y": 2.1
    },
    {
        "x": 162,
        "y": 2.8
    },
    {
        "x": 163,
        "y": 2.8
    },
    {
        "x": 164,
        "y": 2.8
    },
    {
        "x": 165,
        "y": 2.7
    },
    {
        "x": 166,
        "y": 2.7
    },
    {
        "x": 167,
        "y": 2.7
    },
    {
        "x": 168,
        "y": 2.6
    },
    {
        "x": 169,
        "y": 2.6
    },
    {
        "x": 170,
        "y": 2.5
    },
    {
        "x": 171,
        "y": 2.5
    },
    {
        "x": 172,
        "y": 2.5
    },
    {
        "x": 173,
        "y": 2.4
    },
    {
        "x": 174,
        "y": 2.4
    },
    {
        "x": 175,
        "y": 4.5
    },
    {
        "x": 176,
        "y": 4.5
    },
    {
        "x": 177,
        "y": 3.9
    },
    {
        "x": 178,
        "y": 3.5
    },
    {
        "x": 179,
        "y": 3.6
    },
    {
        "x": 180,
        "y": 4.3
    },
    {
        "x": 181,
        "y": 5
    },
    {
        "x": 182,
        "y": 5.5
    },
    {
        "x": 183,
        "y": 6
    },
    {
        "x": 185,
        "y": 6.4
    },
    {
        "x": 185,
        "y": 6.6
    },
    {
        "x": 187,
        "y": 6.8
    },
    {
        "x": 187,
        "y": 6.9
    },
    {
        "x": 189,
        "y": 6.9
    },
    {
        "x": 190,
        "y": 7
    },
    {
        "x": 191,
        "y": 7
    },
    {
        "x": 192,
        "y": 7
    },
    {
        "x": 193,
        "y": 7
    },
    {
        "x": 194,
        "y": 7
    },
    {
        "x": 195,
        "y": 7
    },
    {
        "x": 196,
        "y": 7
    },
    {
        "x": 197,
        "y": 7
    },
    {
        "x": 198,
        "y": 7
    },
    {
        "x": 199,
        "y": 7.1
    },
    {
        "x": 200,
        "y": 7
    },
    {
        "x": 201,
        "y": 7
    },
    {
        "x": 202,
        "y": 7
    },
    {
        "x": 203,
        "y": 7
    },
    {
        "x": 204,
        "y": 7
    },
    {
        "x": 205,
        "y": 7.1
    },
    {
        "x": 206,
        "y": 7.1
    },
    {
        "x": 207,
        "y": 7.1
    },
    {
        "x": 208,
        "y": 7.1
    },
    {
        "x": 209,
        "y": 7.1
    },
    {
        "x": 210,
        "y": 7.1
    },
    {
        "x": 211,
        "y": 7.1
    },
    {
        "x": 212,
        "y": 7.1
    },
    {
        "x": 213,
        "y": 7.1
    },
    {
        "x": 214,
        "y": 7.1
    },
    {
        "x": 215,
        "y": 7.1
    },
    {
        "x": 216,
        "y": 7.1
    },
    {
        "x": 217,
        "y": 7.1
    },
    {
        "x": 218,
        "y": 7.1
    },
    {
        "x": 219,
        "y": 7.1
    },
    {
        "x": 220,
        "y": 7.1
    },
    {
        "x": 221,
        "y": 7.1
    },
    {
        "x": 222,
        "y": 7.1
    },
    {
        "x": 223,
        "y": 7.1
    },
    {
        "x": 224,
        "y": 7.2
    },
    {
        "x": 225,
        "y": 7.1
    },
    {
        "x": 226,
        "y": 7.2
    },
    {
        "x": 227,
        "y": 7.2
    },
    {
        "x": 228,
        "y": 7.2
    },
    {
        "x": 229,
        "y": 7.2
    },
    {
        "x": 230,
        "y": 7.2
    },
    {
        "x": 231,
        "y": 7.2
    },
    {
        "x": 232,
        "y": 7.2
    },
    {
        "x": 233,
        "y": 7.2
    },
    {
        "x": 234,
        "y": 7.2
    },
    {
        "x": 235,
        "y": 7.2
    },
    {
        "x": 236,
        "y": 7.2
    },
    {
        "x": 237,
        "y": 7.2
    },
    {
        "x": 238,
        "y": 7.2
    },
    {
        "x": 239,
        "y": 7.2
    },
    {
        "x": 240,
        "y": 7.2
    },
    {
        "x": 241,
        "y": 7.2
    },
    {
        "x": 242,
        "y": 7.2
    },
    {
        "x": 243,
        "y": 7.2
    },
    {
        "x": 244,
        "y": 7.2
    },
    {
        "x": 245,
        "y": 7.2
    },
    {
        "x": 246,
        "y": 7.2
    },
    {
        "x": 247,
        "y": 7.2
    },
    {
        "x": 248,
        "y": 7.2
    },
    {
        "x": 249,
        "y": 7.2
    },
    {
        "x": 250,
        "y": 7.2
    },
    {
        "x": 251,
        "y": 7.2
    },
    {
        "x": 252,
        "y": 7.2
    },
    {
        "x": 253,
        "y": 7.2
    },
    {
        "x": 254,
        "y": 7.2
    },
    {
        "x": 255,
        "y": 7.2
    },
    {
        "x": 256,
        "y": 7.2
    },
    {
        "x": 258,
        "y": 7.2
    },
    {
        "x": 258,
        "y": 7.2
    },
    {
        "x": 260,
        "y": 7.2
    },
    {
        "x": 260,
        "y": 7.2
    },
    {
        "x": 262,
        "y": 7.2
    },
    {
        "x": 263,
        "y": 7.2
    },
    {
        "x": 264,
        "y": 7.2
    },
    {
        "x": 265,
        "y": 7.2
    },
    {
        "x": 266,
        "y": 7.2
    },
    {
        "x": 267,
        "y": 7.2
    },
    {
        "x": 268,
        "y": 7.3
    },
    {
        "x": 269,
        "y": 7.3
    },
    {
        "x": 270,
        "y": 7.3
    },
    {
        "x": 271,
        "y": 7.3
    },
    {
        "x": 272,
        "y": 4
    },
    {
        "x": 273,
        "y": 3
    },
    {
        "x": 274,
        "y": 2.6
    },
    {
        "x": 275,
        "y": 2.4
    },
    {
        "x": 276,
        "y": 2.2
    },
    {
        "x": 277,
        "y": 2.5
    },
    {
        "x": 278,
        "y": 2.4
    },
    {
        "x": 279,
        "y": 2.4
    },
    {
        "x": 280,
        "y": 2.4
    },
    {
        "x": 281,
        "y": 2.3
    },
    {
        "x": 282,
        "y": 2.3
    },
    {
        "x": 283,
        "y": 2.3
    },
    {
        "x": 284,
        "y": 2.2
    },
    {
        "x": 285,
        "y": 2.2
    },
    {
        "x": 286,
        "y": 2.2
    },
    {
        "x": 287,
        "y": 2.2
    },
    {
        "x": 288,
        "y": 2.1
    },
    {
        "x": 289,
        "y": 2.1
    },
    {
        "x": 290,
        "y": 4.7
    },
    {
        "x": 291,
        "y": 4.1
    },
    {
        "x": 292,
        "y": 3.7
    },
    {
        "x": 293,
        "y": 3.5
    },
    {
        "x": 294,
        "y": 3.8
    },
    {
        "x": 295,
        "y": 4.7
    },
    {
        "x": 296,
        "y": 5.6
    },
    {
        "x": 297,
        "y": 6.1
    },
    {
        "x": 298,
        "y": 6.6
    },
    {
        "x": 299,
        "y": 6.8
    },
    {
        "x": 300,
        "y": 7
    },
    {
        "x": 301,
        "y": 7.1
    },
    {
        "x": 302,
        "y": 7.2
    },
    {
        "x": 303,
        "y": 7.2
    },
    {
        "x": 304,
        "y": 7.2
    },
    {
        "x": 305,
        "y": 7.2
    },
    {
        "x": 306,
        "y": 7.2
    },
    {
        "x": 307,
        "y": 7.2
    },
    {
        "x": 308,
        "y": 7.2
    },
    {
        "x": 309,
        "y": 7.2
    },
    {
        "x": 310,
        "y": 7.3
    },
    {
        "x": 311,
        "y": 7.3
    },
    {
        "x": 312,
        "y": 7.3
    },
    {
        "x": 313,
        "y": 7.3
    },
    {
        "x": 314,
        "y": 7.3
    },
    {
        "x": 315,
        "y": 7.3
    },
    {
        "x": 316,
        "y": 7.3
    },
    {
        "x": 317,
        "y": 7.3
    },
    {
        "x": 318,
        "y": 7.3
    },
    {
        "x": 319,
        "y": 7.3
    },
    {
        "x": 320,
        "y": 7.3
    },
    {
        "x": 321,
        "y": 7.3
    },
    {
        "x": 322,
        "y": 7.3
    },
    {
        "x": 323,
        "y": 7.3
    },
    {
        "x": 324,
        "y": 7.3
    },
    {
        "x": 325,
        "y": 7.3
    },
    {
        "x": 326,
        "y": 7.3
    },
    {
        "x": 327,
        "y": 7.3
    },
    {
        "x": 329,
        "y": 7.3
    },
    {
        "x": 329,
        "y": 7.3
    },
    {
        "x": 331,
        "y": 7.3
    },
    {
        "x": 331,
        "y": 7.3
    },
    {
        "x": 333,
        "y": 7.3
    },
    {
        "x": 334,
        "y": 7.3
    },
    {
        "x": 335,
        "y": 7.3
    },
    {
        "x": 336,
        "y": 7.4
    },
    {
        "x": 337,
        "y": 7.4
    },
    {
        "x": 338,
        "y": 7.4
    },
    {
        "x": 339,
        "y": 7.4
    },
    {
        "x": 340,
        "y": 7.4
    },
    {
        "x": 341,
        "y": 7.4
    },
    {
        "x": 342,
        "y": 7.4
    },
    {
        "x": 343,
        "y": 7.4
    },
    {
        "x": 344,
        "y": 7.4
    },
    {
        "x": 345,
        "y": 7.4
    },
    {
        "x": 346,
        "y": 7.4
    },
    {
        "x": 347,
        "y": 7.4
    },
    {
        "x": 348,
        "y": 7.4
    },
    {
        "x": 349,
        "y": 7.4
    },
    {
        "x": 350,
        "y": 7.4
    },
    {
        "x": 351,
        "y": 7.4
    },
    {
        "x": 352,
        "y": 7.4
    },
    {
        "x": 353,
        "y": 7.4
    },
    {
        "x": 354,
        "y": 7.4
    },
    {
        "x": 355,
        "y": 7.4
    },
    {
        "x": 356,
        "y": 7.4
    },
    {
        "x": 357,
        "y": 7.4
    },
    {
        "x": 358,
        "y": 7.4
    },
    {
        "x": 359,
        "y": 7.4
    },
    {
        "x": 360,
        "y": 7.4
    },
    {
        "x": 361,
        "y": 7.4
    },
    {
        "x": 362,
        "y": 7.4
    },
    {
        "x": 363,
        "y": 7.4
    },
    {
        "x": 364,
        "y": 7.4
    },
    {
        "x": 365,
        "y": 7.4
    },
    {
        "x": 366,
        "y": 7.4
    },
    {
        "x": 367,
        "y": 7.4
    },
    {
        "x": 368,
        "y": 7.5
    },
    {
        "x": 369,
        "y": 7.5
    },
    {
        "x": 370,
        "y": 7.5
    },
    {
        "x": 371,
        "y": 7.5
    },
    {
        "x": 372,
        "y": 7.4
    },
    {
        "x": 373,
        "y": 7.5
    },
    {
        "x": 374,
        "y": 7.5
    },
    {
        "x": 375,
        "y": 7.5
    },
    {
        "x": 376,
        "y": 7.5
    },
    {
        "x": 377,
        "y": 7.5
    }
];

export const StampDataYellowLine = [
    {
        "x": 0,
        "y": 9
    },
    {
        "x": 0,
        "y": 9
    },
    {
        "x": 1,
        "y": 9
    },
    {
        "x": 2,
        "y": 9
    },
    {
        "x": 3,
        "y": 9
    },
    {
        "x": 4,
        "y": 9
    },
    {
        "x": 5,
        "y": 9
    },
    {
        "x": 6,
        "y": 9
    },
    {
        "x": 7,
        "y": 9
    },
    {
        "x": 8,
        "y": 9
    },
    {
        "x": 9,
        "y": 9
    },
    {
        "x": 10,
        "y": 9
    },
    {
        "x": 11,
        "y": 9
    },
    {
        "x": 12,
        "y": 9
    },
    {
        "x": 13,
        "y": 9
    },
    {
        "x": 14,
        "y": 9
    },
    {
        "x": 15,
        "y": 9
    },
    {
        "x": 16,
        "y": 9
    },
    {
        "x": 17,
        "y": 9
    },
    {
        "x": 18,
        "y": 9
    },
    {
        "x": 19,
        "y": 9
    },
    {
        "x": 20,
        "y": 9
    },
    {
        "x": 21,
        "y": 9
    },
    {
        "x": 22,
        "y": 9
    },
    {
        "x": 23,
        "y": 9
    },
    {
        "x": 24,
        "y": 9
    },
    {
        "x": 25,
        "y": 9
    },
    {
        "x": 26,
        "y": 9
    },
    {
        "x": 27,
        "y": 9
    },
    {
        "x": 28,
        "y": 9
    },
    {
        "x": 29,
        "y": 9
    },
    {
        "x": 30,
        "y": 9
    },
    {
        "x": 31,
        "y": 9
    },
    {
        "x": 32,
        "y": 9
    },
    {
        "x": 33,
        "y": 9
    },
    {
        "x": 34,
        "y": 9
    },
    {
        "x": 35,
        "y": 9
    },
    {
        "x": 36,
        "y": 9
    },
    {
        "x": 37,
        "y": 9
    },
    {
        "x": 38,
        "y": 9
    },
    {
        "x": 39,
        "y": 9
    },
    {
        "x": 41,
        "y": 9
    },
    {
        "x": 41,
        "y": 9
    },
    {
        "x": 43,
        "y": 9
    },
    {
        "x": 43,
        "y": 9
    },
    {
        "x": 45,
        "y": 9
    },
    {
        "x": 46,
        "y": 9
    },
    {
        "x": 47,
        "y": 9
    },
    {
        "x": 48,
        "y": 9
    },
    {
        "x": 49,
        "y": 9
    },
    {
        "x": 50,
        "y": 9
    },
    {
        "x": 51,
        "y": 9
    },
    {
        "x": 52,
        "y": 9
    },
    {
        "x": 53,
        "y": 9
    },
    {
        "x": 54,
        "y": 9
    },
    {
        "x": 55,
        "y": 9
    },
    {
        "x": 56,
        "y": 9
    },
    {
        "x": 57,
        "y": 9
    },
    {
        "x": 58,
        "y": 9
    },
    {
        "x": 59,
        "y": 9
    },
    {
        "x": 60,
        "y": 9
    },
    {
        "x": 61,
        "y": 9
    },
    {
        "x": 62,
        "y": 9
    },
    {
        "x": 63,
        "y": 9
    },
    {
        "x": 64,
        "y": 9
    },
    {
        "x": 65,
        "y": 9
    },
    {
        "x": 66,
        "y": 9
    },
    {
        "x": 67,
        "y": 9
    },
    {
        "x": 68,
        "y": 9
    },
    {
        "x": 69,
        "y": 9
    },
    {
        "x": 70,
        "y": 9
    },
    {
        "x": 71,
        "y": 9
    },
    {
        "x": 72,
        "y": 9
    },
    {
        "x": 73,
        "y": 9
    },
    {
        "x": 74,
        "y": 9
    },
    {
        "x": 75,
        "y": 9
    },
    {
        "x": 76,
        "y": 9
    },
    {
        "x": 77,
        "y": 9
    },
    {
        "x": 78,
        "y": 9
    },
    {
        "x": 79,
        "y": 9
    },
    {
        "x": 80,
        "y": 9
    },
    {
        "x": 81,
        "y": 9
    },
    {
        "x": 82,
        "y": 9
    },
    {
        "x": 83,
        "y": 9
    },
    {
        "x": 84,
        "y": 9
    },
    {
        "x": 85,
        "y": 9
    },
    {
        "x": 86,
        "y": 9
    },
    {
        "x": 87,
        "y": 9
    },
    {
        "x": 88,
        "y": 9
    },
    {
        "x": 89,
        "y": 9
    },
    {
        "x": 90,
        "y": 9
    },
    {
        "x": 91,
        "y": 9
    },
    {
        "x": 92,
        "y": 9
    },
    {
        "x": 93,
        "y": 9
    },
    {
        "x": 94,
        "y": 9
    },
    {
        "x": 95,
        "y": 9
    },
    {
        "x": 96,
        "y": 9
    },
    {
        "x": 97,
        "y": 9
    },
    {
        "x": 98,
        "y": 9
    },
    {
        "x": 99,
        "y": 9
    },
    {
        "x": 100,
        "y": 9
    },
    {
        "x": 101,
        "y": 9
    },
    {
        "x": 102,
        "y": 9
    },
    {
        "x": 103,
        "y": 9
    },
    {
        "x": 104,
        "y": 9
    },
    {
        "x": 105,
        "y": 9
    },
    {
        "x": 106,
        "y": 9
    },
    {
        "x": 107,
        "y": 9
    },
    {
        "x": 108,
        "y": 9
    },
    {
        "x": 109,
        "y": 9
    },
    {
        "x": 110,
        "y": 9
    },
    {
        "x": 112,
        "y": 9
    },
    {
        "x": 112,
        "y": 9
    },
    {
        "x": 114,
        "y": 9
    },
    {
        "x": 114,
        "y": 9
    },
    {
        "x": 116,
        "y": 9
    },
    {
        "x": 117,
        "y": 9
    },
    {
        "x": 118,
        "y": 9
    },
    {
        "x": 119,
        "y": 9
    },
    {
        "x": 120,
        "y": 9
    },
    {
        "x": 121,
        "y": 9
    },
    {
        "x": 122,
        "y": 9
    },
    {
        "x": 123,
        "y": 9
    },
    {
        "x": 124,
        "y": 9
    },
    {
        "x": 125,
        "y": 9
    },
    {
        "x": 126,
        "y": 9
    },
    {
        "x": 127,
        "y": 9
    },
    {
        "x": 128,
        "y": 9
    },
    {
        "x": 129,
        "y": 9
    },
    {
        "x": 130,
        "y": 9
    },
    {
        "x": 131,
        "y": 9
    },
    {
        "x": 132,
        "y": 9
    },
    {
        "x": 133,
        "y": 9
    },
    {
        "x": 134,
        "y": 9
    },
    {
        "x": 135,
        "y": 9
    },
    {
        "x": 136,
        "y": 9
    },
    {
        "x": 137,
        "y": 9
    },
    {
        "x": 138,
        "y": 9
    },
    {
        "x": 139,
        "y": 9
    },
    {
        "x": 140,
        "y": 9
    },
    {
        "x": 141,
        "y": 9
    },
    {
        "x": 142,
        "y": 9
    },
    {
        "x": 143,
        "y": 9
    },
    {
        "x": 144,
        "y": 9
    },
    {
        "x": 145,
        "y": 9
    },
    {
        "x": 146,
        "y": 9
    },
    {
        "x": 147,
        "y": 9
    },
    {
        "x": 148,
        "y": 9
    },
    {
        "x": 149,
        "y": 9
    },
    {
        "x": 150,
        "y": 9
    },
    {
        "x": 151,
        "y": 9
    },
    {
        "x": 152,
        "y": 9
    },
    {
        "x": 153,
        "y": 9
    },
    {
        "x": 154,
        "y": 9
    },
    {
        "x": 155,
        "y": 9
    },
    {
        "x": 156,
        "y": 9
    },
    {
        "x": 157,
        "y": 9
    },
    {
        "x": 158,
        "y": 9
    },
    {
        "x": 159,
        "y": 9
    },
    {
        "x": 160,
        "y": 9
    },
    {
        "x": 161,
        "y": 9
    },
    {
        "x": 162,
        "y": 9
    },
    {
        "x": 163,
        "y": 9
    },
    {
        "x": 164,
        "y": 9
    },
    {
        "x": 165,
        "y": 9
    },
    {
        "x": 166,
        "y": 9
    },
    {
        "x": 167,
        "y": 9
    },
    {
        "x": 168,
        "y": 9
    },
    {
        "x": 169,
        "y": 9
    },
    {
        "x": 170,
        "y": 9
    },
    {
        "x": 171,
        "y": 9
    },
    {
        "x": 172,
        "y": 9
    },
    {
        "x": 173,
        "y": 9
    },
    {
        "x": 174,
        "y": 9
    },
    {
        "x": 175,
        "y": 9
    },
    {
        "x": 176,
        "y": 9
    },
    {
        "x": 177,
        "y": 9
    },
    {
        "x": 178,
        "y": 9
    },
    {
        "x": 179,
        "y": 9
    },
    {
        "x": 180,
        "y": 9
    },
    {
        "x": 181,
        "y": 9
    },
    {
        "x": 182,
        "y": 9
    },
    {
        "x": 183,
        "y": 9
    },
    {
        "x": 185,
        "y": 9
    },
    {
        "x": 185,
        "y": 9
    },
    {
        "x": 187,
        "y": 9
    },
    {
        "x": 187,
        "y": 9
    },
    {
        "x": 189,
        "y": 9
    },
    {
        "x": 190,
        "y": 9
    },
    {
        "x": 191,
        "y": 9
    },
    {
        "x": 192,
        "y": 9
    },
    {
        "x": 193,
        "y": 9
    },
    {
        "x": 194,
        "y": 9
    },
    {
        "x": 195,
        "y": 9
    },
    {
        "x": 196,
        "y": 9
    },
    {
        "x": 197,
        "y": 9
    },
    {
        "x": 198,
        "y": 9
    },
    {
        "x": 199,
        "y": 9
    },
    {
        "x": 200,
        "y": 9
    },
    {
        "x": 201,
        "y": 9
    },
    {
        "x": 202,
        "y": 9
    },
    {
        "x": 203,
        "y": 9
    },
    {
        "x": 204,
        "y": 9
    },
    {
        "x": 205,
        "y": 9
    },
    {
        "x": 206,
        "y": 9
    },
    {
        "x": 207,
        "y": 9
    },
    {
        "x": 208,
        "y": 9
    },
    {
        "x": 209,
        "y": 9
    },
    {
        "x": 210,
        "y": 9
    },
    {
        "x": 211,
        "y": 9
    },
    {
        "x": 212,
        "y": 9
    },
    {
        "x": 213,
        "y": 9
    },
    {
        "x": 214,
        "y": 9
    },
    {
        "x": 215,
        "y": 9
    },
    {
        "x": 216,
        "y": 9
    },
    {
        "x": 217,
        "y": 9
    },
    {
        "x": 218,
        "y": 9
    },
    {
        "x": 219,
        "y": 9
    },
    {
        "x": 220,
        "y": 9
    },
    {
        "x": 221,
        "y": 9
    },
    {
        "x": 222,
        "y": 9
    },
    {
        "x": 223,
        "y": 9
    },
    {
        "x": 224,
        "y": 9
    },
    {
        "x": 225,
        "y": 9
    },
    {
        "x": 226,
        "y": 9
    },
    {
        "x": 227,
        "y": 9
    },
    {
        "x": 228,
        "y": 9
    },
    {
        "x": 229,
        "y": 9
    },
    {
        "x": 230,
        "y": 9
    },
    {
        "x": 231,
        "y": 9
    },
    {
        "x": 232,
        "y": 9
    },
    {
        "x": 233,
        "y": 9
    },
    {
        "x": 234,
        "y": 9
    },
    {
        "x": 235,
        "y": 9
    },
    {
        "x": 236,
        "y": 9
    },
    {
        "x": 237,
        "y": 9
    },
    {
        "x": 238,
        "y": 9
    },
    {
        "x": 239,
        "y": 9
    },
    {
        "x": 240,
        "y": 9
    },
    {
        "x": 241,
        "y": 9
    },
    {
        "x": 242,
        "y": 9
    },
    {
        "x": 243,
        "y": 9
    },
    {
        "x": 244,
        "y": 9
    },
    {
        "x": 245,
        "y": 9
    },
    {
        "x": 246,
        "y": 9
    },
    {
        "x": 247,
        "y": 9
    },
    {
        "x": 248,
        "y": 9
    },
    {
        "x": 249,
        "y": 9
    },
    {
        "x": 250,
        "y": 9
    },
    {
        "x": 251,
        "y": 9
    },
    {
        "x": 252,
        "y": 9
    },
    {
        "x": 253,
        "y": 9
    },
    {
        "x": 254,
        "y": 9
    },
    {
        "x": 255,
        "y": 9
    },
    {
        "x": 256,
        "y": 9
    },
    {
        "x": 258,
        "y": 9
    },
    {
        "x": 258,
        "y": 9
    },
    {
        "x": 260,
        "y": 9
    },
    {
        "x": 260,
        "y": 9
    },
    {
        "x": 262,
        "y": 9
    },
    {
        "x": 263,
        "y": 9
    },
    {
        "x": 264,
        "y": 9
    },
    {
        "x": 265,
        "y": 9
    },
    {
        "x": 266,
        "y": 9
    },
    {
        "x": 267,
        "y": 9
    },
    {
        "x": 268,
        "y": 9
    },
    {
        "x": 269,
        "y": 9
    },
    {
        "x": 270,
        "y": 9
    },
    {
        "x": 271,
        "y": 9
    },
    {
        "x": 272,
        "y": 9
    },
    {
        "x": 273,
        "y": 9
    },
    {
        "x": 274,
        "y": 9
    },
    {
        "x": 275,
        "y": 9
    },
    {
        "x": 276,
        "y": 9
    },
    {
        "x": 277,
        "y": 9
    },
    {
        "x": 278,
        "y": 9
    },
    {
        "x": 279,
        "y": 9
    },
    {
        "x": 280,
        "y": 9
    },
    {
        "x": 281,
        "y": 9
    },
    {
        "x": 282,
        "y": 9
    },
    {
        "x": 283,
        "y": 9
    },
    {
        "x": 284,
        "y": 9
    },
    {
        "x": 285,
        "y": 9
    },
    {
        "x": 286,
        "y": 9
    },
    {
        "x": 287,
        "y": 9
    },
    {
        "x": 288,
        "y": 9
    },
    {
        "x": 289,
        "y": 9
    },
    {
        "x": 290,
        "y": 9
    },
    {
        "x": 291,
        "y": 9
    },
    {
        "x": 292,
        "y": 9
    },
    {
        "x": 293,
        "y": 9
    },
    {
        "x": 294,
        "y": 9
    },
    {
        "x": 295,
        "y": 9
    },
    {
        "x": 296,
        "y": 9
    },
    {
        "x": 297,
        "y": 9
    },
    {
        "x": 298,
        "y": 9
    },
    {
        "x": 299,
        "y": 9
    },
    {
        "x": 300,
        "y": 9
    },
    {
        "x": 301,
        "y": 9
    },
    {
        "x": 302,
        "y": 9
    },
    {
        "x": 303,
        "y": 9
    },
    {
        "x": 304,
        "y": 9
    },
    {
        "x": 305,
        "y": 9
    },
    {
        "x": 306,
        "y": 9
    },
    {
        "x": 307,
        "y": 9
    },
    {
        "x": 308,
        "y": 9
    },
    {
        "x": 309,
        "y": 9
    },
    {
        "x": 310,
        "y": 9
    },
    {
        "x": 311,
        "y": 9
    },
    {
        "x": 312,
        "y": 9
    },
    {
        "x": 313,
        "y": 9
    },
    {
        "x": 314,
        "y": 9
    },
    {
        "x": 315,
        "y": 9
    },
    {
        "x": 316,
        "y": 9
    },
    {
        "x": 317,
        "y": 9
    },
    {
        "x": 318,
        "y": 9
    },
    {
        "x": 319,
        "y": 9
    },
    {
        "x": 320,
        "y": 9
    },
    {
        "x": 321,
        "y": 9
    },
    {
        "x": 322,
        "y": 9
    },
    {
        "x": 323,
        "y": 9
    },
    {
        "x": 324,
        "y": 9
    },
    {
        "x": 325,
        "y": 9
    },
    {
        "x": 326,
        "y": 9
    },
    {
        "x": 327,
        "y": 9
    },
    {
        "x": 329,
        "y": 9
    },
    {
        "x": 329,
        "y": 9
    },
    {
        "x": 331,
        "y": 9
    },
    {
        "x": 331,
        "y": 9
    },
    {
        "x": 333,
        "y": 9
    },
    {
        "x": 334,
        "y": 9
    },
    {
        "x": 335,
        "y": 9
    },
    {
        "x": 336,
        "y": 9
    },
    {
        "x": 337,
        "y": 9
    },
    {
        "x": 338,
        "y": 9
    },
    {
        "x": 339,
        "y": 9
    },
    {
        "x": 340,
        "y": 9
    },
    {
        "x": 341,
        "y": 9
    },
    {
        "x": 342,
        "y": 9
    },
    {
        "x": 343,
        "y": 9
    },
    {
        "x": 344,
        "y": 9
    },
    {
        "x": 345,
        "y": 9
    },
    {
        "x": 346,
        "y": 9
    },
    {
        "x": 347,
        "y": 9
    },
    {
        "x": 348,
        "y": 9
    },
    {
        "x": 349,
        "y": 9
    },
    {
        "x": 350,
        "y": 9
    },
    {
        "x": 351,
        "y": 9
    },
    {
        "x": 352,
        "y": 9
    },
    {
        "x": 353,
        "y": 9
    },
    {
        "x": 354,
        "y": 9
    },
    {
        "x": 355,
        "y": 9
    },
    {
        "x": 356,
        "y": 9
    },
    {
        "x": 357,
        "y": 9
    },
    {
        "x": 358,
        "y": 9
    },
    {
        "x": 359,
        "y": 9
    },
    {
        "x": 360,
        "y": 9
    },
    {
        "x": 361,
        "y": 9
    },
    {
        "x": 362,
        "y": 9
    },
    {
        "x": 363,
        "y": 9
    },
    {
        "x": 364,
        "y": 9
    },
    {
        "x": 365,
        "y": 9
    },
    {
        "x": 366,
        "y": 9
    },
    {
        "x": 367,
        "y": 9
    },
    {
        "x": 368,
        "y": 9
    },
    {
        "x": 369,
        "y": 9
    },
    {
        "x": 370,
        "y": 9
    },
    {
        "x": 371,
        "y": 9
    },
    {
        "x": 372,
        "y": 9
    },
    {
        "x": 373,
        "y": 9
    },
    {
        "x": 374,
        "y": 9
    },
    {
        "x": 375,
        "y": 9
    },
    {
        "x": 376,
        "y": 9
    },
    {
        "x": 377,
        "y": 9
    }
];
