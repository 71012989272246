import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import {torqueDataWithoutAI, toqueDataWithAILine1, toqueDataWithAILine2, torqueDataPoint7} from "../../data/TorqueData";
import {ConversionAIData, ConversionAIDataPoints} from "../../data/ConversionData1";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stripLinesExample = [];

class DisplayGraphConversion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.showCharts = this.showCharts.bind(this);
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }

    componentDidMount() {
        setInterval(() => {
            if(!this.state.showCharts) {
                this.showCharts();
            }
        }, 5000);
    }

    toggleDataSeries(e) {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    showCharts() {
        if (localStorage.getItem("AIToggle") === "true") {
            this.setState({showCharts: true});
        }
    }

    render() {
        const options = {
            theme: "light2",
            animationEnabled: true,
            zoomEnabled: true,
            exportEnabled: true,
            axisX: {
                title: "Time in min",
                titleFontColor: "#6D78AD",
                maximum: 80,
                showInLegend: true
            },
            axisY: {
                title: "Conversion [ - ]",
                titleFontColor: "#6D78AD",
                lineColor: "#6D78AD",
                labelFontColor: "#6D78AD",
                tickColor: "#6D78AD",
                includeZero: false,
                stripLines: stripLinesExample,
                showInLegend: true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries,
            },
            data: [
                {
                    type: "line",
                    name: "Conversion [ - ]",
                    showInLegend: true,
                    dataPoints: ConversionAIData,
                },
                {
                    showInLegend: true,
                    markerType: "triangle",
                    color: 'red',
                    markerSize: '15',
                    type: 'scatter',
                    dataPoints: ConversionAIDataPoints,
                    name: 't_x'
                }
            ],
        };

        return (
            <Container
                component={Paper}
                style={this.state.showCharts ? {
                    paddingTop: "24px",
                    paddingBottom: "48px",
                    marginBottom: "60px",
                } : {display: "none"}}
            >
                <Container maxWidth="lg">
                    <Typography variant="h5" component="h1" gutterBottom>
                        Conversion
                    </Typography>
                </Container>
                <Divider
                    variant="fullWidth"
                    style={{
                        marginTop: "15px",
                        marginBottom: "45px",
                        marginLeft: "-24px",
                        marginRight: "-24px",
                    }}
                />
                <Container maxWidth="lg">
                    <Box>
                        <div className="MultipleAxisChart">
                            <CanvasJSChart
                                options={options}
                                onRef={(rheoref) => (this.chart = rheoref)}
                            />
                            {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
                        </div>
                    </Box>
                </Container>
            </Container>

        );
    }
}

export default DisplayGraphConversion1;
