import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import graphone from "../../data/graph1.js";
import {dots, lastDot, line2} from "../../data/line2";
import {mixterData} from "../../data/MixerData";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stripLinesExample = [];

class DisplayGraphOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
    this.updateChart = this.updateChart.bind(this);
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }
  componentDidMount() {
    this.updateChart();
    setInterval(() => {
      if(!this.state.loaded) {
        this.updateChart();
      }
    }, 5000);
  }

  updateChart() {
    let x = 0;
    if (localStorage.getItem("AIToggle") === "true") {
      // this.chart.options.data.push
      if (x === 0) {
        this.setState({loaded: true});
        this.chart.options.data.push({
          type: "stepLine",
          markerSize: 5,
          dataPoints: line2,
          legendText: 'Model fit (James)',
          showInLegend: true,
        });
        this.chart.options.data.push({
          type: "stepLine",
          markerSize: 8,
          dataPoints: dots,
          legendText: "Stress @",
          showInLegend: true,
          showLine: false
        });
        this.chart.options.data.push({
          type: "stepLine",
          markerSize: 8,
          lineColor: "#f4f72f",
          dataPoints: lastDot,
          showInLegend: true,
          legendText: 'Elongation / Stress @ break'
        });
        // this.chart.options.data.push({
        //   type: "spline",
        //   markerSize: 5,
        //   dataPoints: mixterData,
        //   showInLegend: true,
        //   legendText: 'Mixer'
        // });

        this.chart.render();
        x++;
      }
    } else {
      return;
    }
  }
  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  render() {
    const options = {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,
      exportEnabled: true,
      axisX: {
        title: "Strain [%]",
        titleFontColor: "#6D78AD",
        maximum: 450,
        showInLegend: true
      },
      axisY: {
        title: "Stress [MPa]",
        titleFontColor: "#6D78AD",
        lineColor: "#6D78AD",
        labelFontColor: "#6D78AD",
        tickColor: "#6D78AD",
        includeZero: false,
        stripLines: stripLinesExample,
        showInLegend: true
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
      },
      data: [
        {
          type: "line",
          name: "Strain [%]",
          showInLegend: false,
          dataPoints: graphone,
        },
      ],
    };

    return (
      <div className="MultipleAxisChart">
        <CanvasJSChart
          options={options}
          onRef={(rheoref) => (this.chart = rheoref)}
        />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  }
}

export default DisplayGraphOne;
