import React, { Component } from "react";
import CanvasJSReact from "../../vendors/canvasjs.react";
import graph2a from "../../data/graph2a";
import graph2b from "../../data/graph2b";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let extone = [];

class DisplayGraphTwo extends Component {
  constructor() {
    super();
    this.updateChart = this.updateChart.bind(this);
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }
  componentDidMount() {
    this.updateChart();

    // this.chart.options.data[1].dataPoints.filter(obj => {
    //   if (obj.y === 150.64) {
    //     obj.indexLabel = "\u2191 highest";
    //     obj.indexLabelFontSize = 16;
    //     obj.indexLabelColor = "black";
    //     obj.indexLabelBackgroundColor = "lightred";
    //     obj.markerColor = "red";
    //     obj.markerType = "triangle";
    //     obj.toolTipContent = "triangle";
    //     obj.cursor = "pointer";
    //   }
    // });
    setInterval(this.updateChart, 5000);
  }
  updateChart() {
    let x = 0;
    if (localStorage.getItem("AIToggle") === "true") {
      // this.chart.options.data.push
      if (x === 0) {
        extone.push({
          value: 40,
          color: "#FF0800",
        });
        extone.push({
          value: 42,
          color: "#FF0800",
        });
        this.chart.render();
        x++;
      }
    } else {
      return;
    }
  }
  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  render() {
    const options = {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,
      exportEnabled: true,
      axisY: {
        title: "Temp. TCU-1 Sa/Kl/St",
        titleFontColor: "#6D78AD",
        lineColor: "#6D78AD",
        labelFontColor: "#6D78AD",
        tickColor: "#6D78AD",
        includeZero: false,
        stripLines: extone,
      },
      axisY2: {
        title: "Temp. TCU-2 Rotor",
        titleFontColor: "#51CDA0",
        lineColor: "#51CDA0",
        labelFontColor: "#51CDA0",
        tickColor: "#51CDA0",
        includeZero: false,
        stripLines: extone,
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
      },
      data: [
        {
          type: "line",
          name: "Temp. TCU-1 Sa/Kl/St [°C]",
          showInLegend: true,
          xValueFormatString: "mm:ss",
          yValueFormatString: "###.## [°C]",
          dataPoints: graph2a,
        },
        {
          type: "line",
          name: "Temp. TCU-2 Rotor [°C]",
          axisYType: "secondary",
          showInLegend: true,
          xValueFormatString: "mm:ss",
          yValueFormatString: "###.##° [°C]",
          dataPoints: graph2b,
        },
      ],
    };

    return (
      <div className="MultipleAxisChart">
        <CanvasJSChart
          options={options}
          onRef={(rheoref) => (this.chart = rheoref)}
        />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  }
}

export default DisplayGraphTwo;
