import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import * as ROLES from "../../constants/roles";

import OrderDB from "../../data/orderDB.json";
import DisplayQuickStats from "./displayQuickstats";
import DisplayCommodity from "./displayCommodity";
import DisplayMischprozess from "./displayMischprozess";
import DisplayWalze from "./displayWalze";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Chip from "@material-ui/core/Chip";
import WarningIcon from "@material-ui/icons/Warning";
import Avatar from "@material-ui/core/Avatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class ViewWorkOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderid: null,
      OrderDB,
      order: null,
      clientname: null,
      deadline: null,
      step: null,
      loading: false,
      production: [],
      recipe: [],
      rawmaterials: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    let orderRefID = parseInt(this.props.match.params.id);
    let orderArray = Object.values(this.state.OrderDB);

    let foundObjectArray = orderArray.filter(
      (order) => order._id === orderRefID
    );
    let foundOrder = foundObjectArray[0];

    this.setState({
      orderid: orderRefID,
      order: foundOrder,
      clientname: foundOrder.clientName,
      deadline: foundOrder.produceUntil,
      step: foundOrder.currentProductionStep - 1,
    });

    this.props.firebase.rawmaterials().on("value", (snapshot) => {
      const rawmaterialsObject = snapshot.val();

      this.setState({
        rawmaterials: rawmaterialsObject,
      });
    });

    this.props.firebase.productions().on("value", (snapshot) => {
      const productionObject = snapshot.val();
      let foundProductionArray = productionObject.filter(
        (production) =>
          production._id === this.state.order.productionLine[this.state.step]
      );
      let productionObj = foundProductionArray[0];

      this.setState({
        production: productionObj,
      });
    });

    this.props.firebase.recipes().on("value", (snapshot) => {
      const recipesObject = snapshot.val();
      let recipeRefObject = null;
      Object.keys(recipesObject).map((item) => {
        console.log(recipesObject[item]);
        if(parseInt(recipesObject[item]._id) === this.state.production._id) {
          recipeRefObject = recipesObject[item];
        }
      });
      // const recipeRefObject = recipesObject.find(
      //   (recipe) => recipe._id === this.state.production._id
      // );

      this.setState({
        recipe: recipeRefObject,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.productions().off();
    this.props.firebase.recipes().off();
    this.props.firebase.rawmaterials().off();
  }

  render() {
    const {
      orderid,
      order,
      production,
      recipe,
      rawmaterials,
      clientname,
      deadline,
      step,
      loading,
    } = this.state;

    return (
      <div>
        {loading && (
          <div
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              display: "block",
              backgroundColor: "darkgrey",
              padding: "5px 10px",
            }}
          >
            Loading ...
          </div>
        )}

        <RecipesList
          orderid={orderid}
          order={order}
          production={production}
          recipe={recipe}
          rawmaterials={rawmaterials}
          clientname={clientname}
          deadline={deadline}
          step={step}
        />
      </div>
    );
  }
}

const RecipesList = ({
  orderid,
  order,
  production,
  recipe,
  rawmaterials,
  clientname,
  deadline,
  step,
}) => (
  <React.Fragment>
    <Container
      component={Paper}
      style={{
        paddingTop: "24px",
        paddingBottom: "24px",
        marginBottom: "30px",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" component="h1">
              {clientname}
            </Typography>
            <Typography component="p" color="textSecondary" gutterBottom>
              Task ID: {orderid}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Chip
                avatar={<Avatar>M</Avatar>}
                style={{ marginRight: "15px" }}
                label="234-678-247-KH-2-LMS"
              />
              <Chip icon={<WarningIcon />} color="secondary" label={deadline} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Divider
        variant="fullWidth"
        style={{
          marginTop: "15px",
          marginBottom: "45px",
          marginLeft: "-24px",
          marginRight: "-24px",
        }}
      />

      <Container maxWidth="lg" style={{ marginBottom: "60px" }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Mixing
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          Initiation state
        </Typography>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DisplayQuickStats
                key={production._id}
                recipe={recipe}
                production={production}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <ExpansionPanel square>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Materials</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DisplayCommodity
            key={production._id}
            production={production}
            recipe={recipe}
            rawmaterials={rawmaterials}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Mixing process</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DisplayMischprozess
            key={production._id}
            production={production}
            rawmaterials={rawmaterials}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Roller</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DisplayWalze key={production._id} production={production} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Container>
  </React.Fragment>
);

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ERWIN];

export default compose(
  withFirebase
)(ViewWorkOrder);
