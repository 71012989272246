import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import ComodityDB from '../../data/commodityDB';

function fetchRawMaterialAmount(order, refrecipe) {
  let amount = refrecipe.rawmaterialAmount[order];
  if (amount !== undefined || amount !== null) {
    return (
      <TableCell>
        <small>{amount}</small>
      </TableCell>
    );
  } else {
    return (
      <TableCell>
        <small>-</small>
      </TableCell>
    );
  }
}
function fetchRawMaterialDichte(order, refrecipe) {
  let dichte = refrecipe.rawmaterialDichte[order];
  if (dichte !== undefined || dichte !== null) {
    return (
      <TableCell>
        <small>{dichte}</small>
      </TableCell>
    );
  } else {
    return (
      <TableCell>
        <small>-</small>
      </TableCell>
    );
  }
}
function fetchRawMaterialVol(order, refrecipe) {
  let vol = refrecipe.rawmaterialVol[order];
  if (vol !== undefined || vol !== null) {
    return (
      <TableCell>
        <small>{vol}</small>
      </TableCell>
    );
  } else {
    return (
      <TableCell>
        <small>-</small>
      </TableCell>
    );
  }
}
function fetchRawMaterialAbwaage(order, refrecipe) {
  let abwaage = refrecipe.rawmaterialAbwaage[order];
  if (abwaage !== undefined || abwaage !== null) {
    return (
      <TableCell>
        <small>{abwaage}</small>
      </TableCell>
    );
  } else {
    return (
      <TableCell>
        <small>-</small>
      </TableCell>
    );
  }
}

function fetchName(order, refrecipe) {
  let dichte = refrecipe.rawmaterialDichte[order];
  let name;
  ComodityDB.map((item) => {
    if(item.commodityDensity === dichte) {
      name = item.commodityName;
    }
  });
  if (name !== undefined || name !== null) {
    return (
        <TableCell>
          <small>{name}</small>
        </TableCell>
    );
  } else {
    return (
        <TableCell>
          <small>-</small>
        </TableCell>
    );
  }
}

class DisplayAmounts extends Component {
  render() {
    const { refid, rawmaterials, recipe, commoditylist } = this.props;
    console.log(ComodityDB);
    let commodityRowValue = [];
    let recipeList;

    if (recipe.recipeName !== undefined) {
      recipeList = commoditylist;

      let foundRecipeArray = Object.entries(recipeList);
      for (const [order, recipeid] of foundRecipeArray) {
        let amount = fetchRawMaterialAmount(order, recipe);
        let dichte = fetchRawMaterialDichte(order, recipe);
        let vol = fetchRawMaterialVol(order, recipe);
        let abwaage = fetchRawMaterialAbwaage(order, recipe);
        let name = fetchName(order, recipe);

        // console.log(order, recipe);
        commodityRowValue.push(
          <TableRow key={order}>
            {name}
            {amount}
            {dichte}
            {vol}
            {abwaage}
          </TableRow>
        );
      }
    }

    return (
      <Box style={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>phr</strong>
                </TableCell>
                <TableCell>
                  <strong>Density (g/cm<sup>2</sup>)</strong>
                </TableCell>
                <TableCell>
                  <strong>Vol. (cm<sup>2</sup>)</strong>
                </TableCell>
                <TableCell>
                  <strong>Mass (g)</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{commodityRowValue}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
}

export default DisplayAmounts;
