import app from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
  apiKey: "AIzaSyB4h-QDAYaqAb9JcHrrHanUL5c5GC9a94U",
  authDomain: "matai-a22ae.firebaseapp.com",
  databaseURL: "https://matai-a22ae.firebaseio.com",
  projectId: "matai-a22ae",
  storageBucket: "matai-a22ae.appspot.com",
  messagingSenderId: "699630071645",
  appId: "1:699630071645:web:6ee0f1a23318eebde882b4"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();

    this.serverValue = app.database.ServerValue;
  }

  // AUTH Api

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then((snapshot) => {
            let dbUser = snapshot.val() ? snapshot.val() : { roles: {} };

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // User API

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");

  // Recipes API

  recipe = (uid) => this.db.ref(`recipes/${uid}`);

  recipes = () => this.db.ref("recipes");

  // Raw Materials API

  rawmaterial = (uid) => this.db.ref(`rawmaterials/${uid}`);

  rawmaterials = () => this.db.ref("rawmaterials");

  // Production API

  production = (uid) => this.db.ref(`production/${uid}`);

  productions = () => this.db.ref("production");

  // Property API

  property = (uid) => this.db.ref(`property/${uid}`);

  properties = () => this.db.ref("property");
}

export default Firebase;
