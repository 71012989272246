import React, { Component } from "react";
import { Link } from "react-router-dom";
import OrderDB from "../../data/orderDB.json";
import ProductionDB from "../../data/productionDB.json";
import CommodityDB from "../../data/commodityDB.json";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { DropzoneArea } from "material-ui-dropzone";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

function msToMinSec(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
}

class WizardOrder extends Component {
  constructor(props) {
    super(props);
    let productionArray = ProductionDB[0];
    let maxMixing = Object.keys(productionArray.mixing).length;
    let maxRoller = Object.keys(productionArray.roller).length;

    this.orderid = props.match.params.id;
    this.maxcalculatedsteps = maxMixing + maxRoller;
    this.productionstep = parseInt(props.match.params.step);
    this.state = {
      currentStep: this.productionstep,
      maxStep: this.maxcalculatedsteps,
      settempvalue: productionArray.setTempValue,
      setrotationalspeed: productionArray.setRotationalSpeed,
      settorque: productionArray.setTorque,
      open: false,
      setOpen: false,
      files: [],
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange(files) {
    this.setState({
      files: files,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { settempvalue, setrotationalspeed, settorque } = this.state;
    alert(`Your registration detail: \n 
           Email: ${settempvalue} \n 
           Username: ${setrotationalspeed} \n
           Password: ${settorque}`);
  };

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep =
      currentStep >= this.state.maxStep
        ? this.state.maxStep + 1
        : currentStep + 1;
    this.setState({
      currentStep: currentStep,
      open: true,
    });
    setTimeout(this.handleToggle, 5000);
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleClose = () => {
    this.setState({
      setOpen: false,
    });
  };

  /*
   *  Display content
   */

  displayModal() {
    return (
      <Backdrop
        style={{ zIndex: "1201", color: "#FFF" }}
        open={this.state.open}
        onClick={this.handleClose}
      >
        <Box
          component={Paper}
          boxShadow={3}
          p={2}
          style={{ textAlign: "center" }}
        >
          <CircularProgress
            size="60px"
            color="primary"
            style={{
              marginTop: "30px",
              marginBottom: "60px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <Typography variant="h6" component="h3" gutterBottom>
            Please wait...
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            style={{ display: "block", width: "100%", marginTop: "10px" }}
            onClick={this.handleToggle}
          >
            Cancel
          </Button>
        </Box>
      </Backdrop>
    );
  }

  displayContent() {
    let currentStep = this.state.currentStep;
    let currentStepString = currentStep.toString();
    let productionArray = ProductionDB[0];

    // Mixing
    let mischerObject = productionArray.mixing;
    let mischerArray = Object.entries(mischerObject);
    let maxMixingSteps = Object.keys(mischerObject).length;

    // Roller
    let rollerObject = productionArray.roller;
    let rollerArray = Object.entries(rollerObject);
    let maxRollerSteps = Object.keys(rollerObject).length;

    if (currentStep <= maxMixingSteps) {
      for (const [key, value] of mischerArray) {
        // Fetch values from
        let mixerKey = key;
        let mixerDuration = msToMinSec(value.mixingDuration);
        let mixerTask = value.mixingTask;

        if (currentStepString === mixerKey) {
          console.log(mischerArray[mixerKey - 1]);
          if (value.commodityRefID) {
            let commodityRefObj = mischerArray[mixerKey - 1][1].commodityRefID;
            let commodityRefArray = Object.entries(commodityRefObj);
            let commodityArray = Object.values(CommodityDB);
            let mixerArray = [];

            for (const [mkey, mvalue] of commodityRefArray) {
              let foundObjectArray = commodityArray.filter(
                (commodity) => commodity._id === mvalue
              );
              mixerArray.push(foundObjectArray[0].commodityName);
            }

            mixerTask = mixerArray.join(", ");
          } else {
            mixerTask = value.mixingTask;
          }

          return (
            <TableContainer key={mixerKey} component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Duration</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {mixerDuration}
                    </TableCell>
                    <TableCell>{mixerTask}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        }
      }
    } else if (
      currentStep > maxMixingSteps &&
      currentStep <= maxMixingSteps + maxRollerSteps
    ) {
      for (const [key, value] of rollerArray) {
        let rollerKey = key;
        let rollerDuration = msToMinSec(value.rollerDuration);
        let calculatedStep = currentStep - maxMixingSteps;
        let currentCalculatedStepString = calculatedStep.toString();

        if (currentCalculatedStepString === rollerKey) {
          return (
            <TableContainer key={rollerKey} component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Duration</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {rollerDuration}
                    </TableCell>
                    <TableCell>Roller</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        }
      }
    }
  }

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep === 1) {
      return (
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "20px", marginTop: "22px" }}
          onClick={this._prev}
        >
          Previous
        </Button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < this.state.maxStep) {
      return (
        <Button
          variant="contained"
          style={{
            color: "#FFF",
            backgroundColor: "#00B19E",
            marginTop: "22px",
          }}
          onClick={this._next}
        >
          Next
        </Button>
      );
    } else if (currentStep === this.state.maxStep) {
      return (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "22px" }}
          onClick={this._next}
        >
          Deviations
        </Button>
      );
    } else if (currentStep === this.state.maxStep + 1) {
      return (
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: "22px" }}
          component={Link}
          to={`/workorders`}
        >
          Complete
        </Button>
      );
    }
    return null;
  }

  render() {
    // fetch id from params
    let orderPropsID = parseInt(this.orderid);

    // load object db and lookup correct json parse
    let orderArray = Object.values(OrderDB);
    let foundObjectArray = orderArray.filter(
      (order) => order._id === orderPropsID
    );
    let currentOrder = foundObjectArray[0];

    return (
      <React.Fragment>
        {this.displayModal()}
        <Container
          component={Paper}
          style={{
            paddingTop: "24px",
            paddingBottom: "48px",
            marginBottom: "30px",
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h5" component="h1">
              {currentOrder.clientName}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h2"
              color="textSecondary"
              gutterBottom
            >
              Step {this.state.currentStep}
            </Typography>
          </Container>
          <Divider
            variant="fullWidth"
            style={{
              marginTop: "15px",
              marginBottom: "45px",
              marginLeft: "-24px",
              marginRight: "-24px",
            }}
          />
          <Container maxWidth="lg">
            <form onSubmit={this.handleSubmit}>
              {/* 
        render the form steps and pass required props in
      */}
              <Step
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                settempvalue={this.state.settempvalue}
                setrotationalspeed={this.state.setrotationalspeed}
                settorque={this.state.settorque}
              />
              {this.displayContent()}
              {this.previousButton()}
              {this.nextButton()}
            </form>
          </Container>
        </Container>
      </React.Fragment>
    );
  }
}

function Step(props) {
  let productionArray = ProductionDB[0];
  let maxMixing = Object.keys(productionArray.mixing).length;
  let maxRoller = Object.keys(productionArray.roller).length;

  if (props.currentStep <= maxMixing + maxRoller) {
    return (
      <div className="form-group">
        <i>Please follow the process described below.</i>
        <h1>Add material</h1>
      </div>
    );
  } else if (props.currentStep === maxMixing + maxRoller + 1) {
    return (
      <div className="form-group">
        <i>Write down differences in your measurements.</i>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <h1>IST Machine Settings</h1>
            <TextField
              id="setTempValue"
              label="Temperature (°C)"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={props.settempvalue}
              style={{ marginBottom: "20px", marginRight: "20px" }}
            />
            <TextField
              id="setRotationalSpeed"
              label="RPM"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={props.setrotationalspeed}
              style={{ marginBottom: "20px", marginRight: "20px" }}
            />
            <TextField
              id="setTorque"
              label="(NM)"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={props.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1>Upload technical Sheet</h1>
            <DropzoneArea
              onChange={props.handleChange.bind(this)}
              dropzoneText="Select your technical sheet."
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>Comments</h1>
            <TextareaAutosize
              aria-label="minimum height"
              style={{ width: "100%" }}
              rowsMin={12}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WizardOrder;
