import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

class DisplayDeadline extends Component {
  render() {
    const { deadline } = this.props;
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    let today = new Date();
    let date = new Date(deadline);
    let dateFormatted = date.toLocaleDateString("en-US", options);
    let hoursFormatted = date.getHours() + ":" + date.getMinutes();
    let dateOutput = dateFormatted + " " + hoursFormatted;
    let hoursInput = (date.getTime() - today.getTime()) / 1000 / 3600;

    if (hoursInput <= 48) {
      return (
        <Alert
          icon={<ErrorOutlineOutlinedIcon fontSize="inherit" />}
          style={{ padding: "0 16px", borderRadius: "0" }}
          severity="error"
        >
          <span>
            <strong>Due on</strong> {dateOutput}
          </span>
        </Alert>
      );
    } else if (hoursInput > 48 && hoursInput <= 336) {
      return (
        <Alert
          icon={<WarningOutlinedIcon fontSize="inherit" />}
          style={{ padding: "0 16px", borderRadius: "0" }}
          severity="warning"
        >
          <span>
            <strong>Due on</strong> {dateOutput}
          </span>
        </Alert>
      );
    } else {
      return (
        <Alert
          icon={<InfoOutlinedIcon fontSize="inherit" />}
          style={{ padding: "0 16px", borderRadius: "0" }}
          severity="info"
        >
          <span>
            <strong>Due on</strong> {dateOutput}
          </span>
        </Alert>
      );
    }
  }
}
export default DisplayDeadline;
