const graphtwoa = [
  {
    x: new Date("2019-06-19T11:14:13.5000z"),
    y: 39.9
  },
  {
    x: new Date("2019-06-19T11:14:14.5000z"),
    y: 39.9
  },
  {
    x: new Date("2019-06-19T11:14:15.5000z"),
    y: 39.9
  },
  {
    x: new Date("2019-06-19T11:14:16.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:14:17.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:14:18.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:14:19.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:14:20.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:14:21.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:14:22.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:14:23.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:14:24.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:14:25.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:14:26.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:14:27.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:14:28.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:14:29.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:14:30.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:14:31.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:14:32.5000z"),
    y: 39.9
  },
  {
    x: new Date("2019-06-19T11:14:33.5000z"),
    y: 39.9
  },
  {
    x: new Date("2019-06-19T11:14:34.5000z"),
    y: 39.8
  },
  {
    x: new Date("2019-06-19T11:14:35.5000z"),
    y: 39.9
  },
  {
    x: new Date("2019-06-19T11:14:36.5000z"),
    y: 39.9
  },
  {
    x: new Date("2019-06-19T11:14:37.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:14:38.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:14:39.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:14:40.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:14:41.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:14:42.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:14:43.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:14:44.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:14:45.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:14:46.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:14:47.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:14:48.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:14:49.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:14:50.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:14:51.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:14:52.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:14:53.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:14:54.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:14:55.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:14:56.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:14:57.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:14:58.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:14:59.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:15:00.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:15:01.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:15:02.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:15:03.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:15:04.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:15:05.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:15:06.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:15:07.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:15:08.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:15:09.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:10.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:11.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:15:12.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:15:13.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:15:14.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:15:15.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:15:16.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:15:17.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:15:18.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:15:19.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:15:20.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:15:21.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:15:22.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:15:23.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:15:24.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:15:25.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:15:27.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:15:28.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:15:29.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:15:30.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:15:31.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:32.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:33.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:34.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:15:35.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:15:36.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:15:37.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:15:38.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:15:39.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:15:40.5000z"),
    y: 40
  },
  {
    x: new Date("2019-06-19T11:15:41.5000z"),
    y: 40.1
  },
  {
    x: new Date("2019-06-19T11:15:42.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:15:43.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:15:44.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:15:45.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:15:46.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:15:47.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:15:48.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:49.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:50.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:15:51.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:15:52.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:15:53.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:15:54.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:15:55.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:15:56.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:15:57.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:15:58.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:15:59.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:16:00.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:16:01.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:16:02.5000z"),
    y: 40.2
  },
  {
    x: new Date("2019-06-19T11:16:03.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:16:04.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:16:05.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:06.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:16:07.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:16:08.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:16:09.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:16:10.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:16:11.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:16:12.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:16:13.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:16:14.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:16:15.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:16:16.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:16:17.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:16:18.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:19.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:20.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:16:21.5000z"),
    y: 40.3
  },
  {
    x: new Date("2019-06-19T11:16:22.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:23.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:24.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:16:25.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:16:26.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:16:27.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:16:28.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:16:29.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:16:30.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:16:31.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:16:32.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:16:33.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:16:34.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:16:35.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:16:36.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:16:37.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:16:39.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:40.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:41.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:16:42.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:16:43.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:16:44.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:16:45.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:16:46.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:16:47.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:16:48.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:16:49.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:16:50.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:16:51.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:16:52.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:16:53.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:16:54.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:16:55.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:16:56.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:16:57.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:16:58.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:16:59.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:17:00.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:17:01.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:17:02.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:17:03.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:17:04.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:17:05.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:17:06.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:17:07.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:17:08.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:17:09.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:17:10.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:17:11.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:12.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:13.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:17:14.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:15.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:17:16.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:17:17.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:17:18.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:17:19.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:17:20.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:17:21.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:17:22.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:17:23.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:17:24.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:17:25.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:17:26.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:17:27.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:17:28.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:17:29.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:17:30.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:17:31.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:17:32.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:33.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:34.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:17:35.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:17:36.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:17:37.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:17:38.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:17:39.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:17:40.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:17:41.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:17:42.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:17:43.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:17:44.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:17:45.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:17:46.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:17:47.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:17:48.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:17:49.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:17:50.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:52.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:53.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:17:54.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:17:55.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:17:56.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:17:57.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:17:58.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:17:59.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:18:00.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:18:01.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:18:02.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:18:03.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:18:04.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:18:05.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:18:06.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:18:07.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:18:08.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:18:09.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:18:10.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:18:11.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:18:12.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:18:13.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:18:14.5000z"),
    y: 42
  },
  {
    x: new Date("2019-06-19T11:18:15.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:18:16.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:18:17.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:18:18.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:18:19.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:18:20.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:18:21.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:18:22.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:18:23.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:18:24.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:18:25.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:18:26.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:18:27.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:18:28.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:18:29.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:18:30.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:18:31.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:18:32.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:18:33.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:18:34.5000z"),
    y: 42
  },
  {
    x: new Date("2019-06-19T11:18:35.5000z"),
    y: 42.1
  },
  {
    x: new Date("2019-06-19T11:18:36.5000z"),
    y: 42.1
  },
  {
    x: new Date("2019-06-19T11:18:37.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:18:38.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:18:39.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:18:40.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:18:41.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:18:42.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:18:43.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:18:44.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:18:45.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:18:46.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:18:47.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:18:48.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:18:49.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:18:50.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:18:51.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:18:52.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:18:53.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:18:54.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:18:55.5000z"),
    y: 42
  },
  {
    x: new Date("2019-06-19T11:18:56.5000z"),
    y: 42.1
  },
  {
    x: new Date("2019-06-19T11:18:57.5000z"),
    y: 42.2
  },
  {
    x: new Date("2019-06-19T11:18:58.5000z"),
    y: 42.2
  },
  {
    x: new Date("2019-06-19T11:18:59.5000z"),
    y: 42
  },
  {
    x: new Date("2019-06-19T11:19:00.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:19:01.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:19:02.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:19:04.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:19:05.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:19:06.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:19:07.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:19:08.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:19:09.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:19:10.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:19:11.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:19:12.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:19:13.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:19:14.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:19:15.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:19:16.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:19:17.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:19:18.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:19:19.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:19:20.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:19:21.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:19:22.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:19:23.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:19:24.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:19:25.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:19:26.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:19:27.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:19:28.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:19:29.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:19:30.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:19:31.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:19:32.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:19:33.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:19:34.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:19:35.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:19:36.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:19:37.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:19:38.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:19:39.5000z"),
    y: 41.8
  },
  {
    x: new Date("2019-06-19T11:19:40.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:19:41.5000z"),
    y: 42
  },
  {
    x: new Date("2019-06-19T11:19:42.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:19:43.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:19:44.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:19:45.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:19:46.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:19:47.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:19:48.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:19:49.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:19:50.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:19:51.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:19:52.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:19:53.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:19:54.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:19:55.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:19:56.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:19:57.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:19:58.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:19:59.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:20:00.5000z"),
    y: 41.9
  },
  {
    x: new Date("2019-06-19T11:20:01.5000z"),
    y: 42
  },
  {
    x: new Date("2019-06-19T11:20:02.5000z"),
    y: 42.1
  },
  {
    x: new Date("2019-06-19T11:20:03.5000z"),
    y: 42
  },
  {
    x: new Date("2019-06-19T11:20:04.5000z"),
    y: 41.7
  },
  {
    x: new Date("2019-06-19T11:20:05.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:20:06.5000z"),
    y: 41.1
  },
  {
    x: new Date("2019-06-19T11:20:07.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:20:08.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:20:09.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:20:10.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:20:11.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:20:12.5000z"),
    y: 40.8
  },
  {
    x: new Date("2019-06-19T11:20:13.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:20:14.5000z"),
    y: 41
  },
  {
    x: new Date("2019-06-19T11:20:16.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:20:17.5000z"),
    y: 41.3
  },
  {
    x: new Date("2019-06-19T11:20:18.5000z"),
    y: 41.5
  },
  {
    x: new Date("2019-06-19T11:20:19.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:20:20.5000z"),
    y: 41.6
  },
  {
    x: new Date("2019-06-19T11:20:21.5000z"),
    y: 41.4
  },
  {
    x: new Date("2019-06-19T11:20:22.5000z"),
    y: 41.2
  },
  {
    x: new Date("2019-06-19T11:20:23.5000z"),
    y: 40.9
  },
  {
    x: new Date("2019-06-19T11:20:24.5000z"),
    y: 40.7
  },
  {
    x: new Date("2019-06-19T11:20:25.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:20:26.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:20:27.5000z"),
    y: 40.4
  },
  {
    x: new Date("2019-06-19T11:20:28.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:20:29.5000z"),
    y: 40.5
  },
  {
    x: new Date("2019-06-19T11:20:30.5000z"),
    y: 40.6
  },
  {
    x: new Date("2019-06-19T11:20:30.5000z"),
    y: 40.7
  }
];

export default graphtwoa;
