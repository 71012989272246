import React from 'react';
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import csv from 'csv';
import firebase from "firebase";
import {compose} from "recompose";
import {withFirebase} from "../Firebase";
import Container from "@material-ui/core/Container";
import Dropzone from "react-dropzone";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import DescriptionSharpIcon from "@material-ui/icons/DescriptionSharp";
import Fallback from "./Fallback";
import {NotificationManager} from "react-notifications";

class RecipeUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFallback: false
        };
        this.convertToArrayOfObjects = this.convertToArrayOfObjects.bind(this);
        this.onDropzoneChange = this.onDropzoneChange.bind(this);
    }

    onDropzoneChange(e) {
        this.setState({showFallback: true});
        let file = e[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            csv.parse(reader.result, (err, data) => {
                let obj = this.convertToArrayOfObjects(data);
                if(obj.length > 0) {
                    obj.map((item) => {
                        Object.keys(item).map((i) => {
                            if(/(,|-)/.test(item[i])) {
                                let arr = item[i].split(',');
                                item[i] = arr;
                            }
                            if (i.indexOf("_id") >= 0) {
                                item._id = item[i];
                                delete item[i];
                            }
                        });
                        this.props.firebase.recipes().push(item);
                    });
                }

                // Object.keys(obj).map((item) => {
                //     this.props.firebase.rawmaterials().push(obj[item]);
                // });
                // setTimeout(() => {
                //     this.setState({showFallback: false});
                //     NotificationManager.success('File uploaded', 'Success');
                //     this.props.history.push('/rawmaterials');
                // }, 3000);
            });
        };
        reader.readAsBinaryString(file);
    }

    convertToArrayOfObjects(data) {
        var keys = data.shift(),
            i = 0, k = 0,
            obj = null,
            output = [];

        for (i = 0; i < data.length; i++) {
            obj = {};

            for (k = 0; k < keys.length; k++) {
                obj[keys[k]] = data[i][k];
            }

            output.push(obj);
        }

        return output;
    }

    render() {
        return (
            <React.Fragment>
                <Container
                    maxWidth="sm"
                    style={{
                        marginTop: "30px",
                        backgroundColor: "#f4f6ff",
                        border: "1px dashed #4051b5",
                        padding: "70px 0",
                    }}
                >
                    <Dropzone accept=".csv" onDrop={this.onDropzoneChange}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    <div
                                        style={{
                                            marginBottom: "15px",
                                            position: "relative",
                                            width: "210px",
                                            height: "210px",
                                            border: "10px solid rgb(64, 81, 181)",
                                            borderRadius: "50%",
                                            margin: "0 auto",
                                        }}
                                    >
                                        <CloudUploadOutlinedIcon
                                            style={{
                                                position: "relative",
                                                left: "calc(50% - 60px)",
                                                top: "60px",
                                                color: "#4051b5",
                                                fontSize: "90px",
                                            }}
                                        />
                                        <DescriptionSharpIcon
                                            style={{
                                                position: "relative",
                                                left: "calc(50% - 98px)",
                                                top: "53px",
                                                color: "#00b19e",
                                                fontSize: "87px",
                                                zIndex: "1",
                                            }}
                                        />
                                    </div>
                                    <h1
                                        style={{
                                            marginBottom: "0",
                                            fontSize: "24px",
                                            color: "#4051b5",
                                            textAlign: "center",
                                        }}
                                    >
                                        Upload Recipes
                                    </h1>
                                    <p
                                        style={{
                                            margin: "0",
                                            color: "#4051b5",
                                            textAlign: "center",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Drag 'n' drop some files here, or click to select files
                                    </p>
                                    <Button
                                        // onClick={handleToggle}
                                        variant="contained"
                                        startIcon={<CloudUploadOutlinedIcon />}
                                        color="secondary"
                                        style={{
                                            marginTop: "15px",
                                            marginLeft: "calc(50% - 82px)",
                                        }}
                                    >
                                        <input onChange={this.onChangeFile} type="file" style={{display: 'none'}} />
                                        Upload Recipes
                                    </Button>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Container>
                <Fallback open={this.state.showFallback} onHide={() => this.setState({showFallback: false})} />
            </React.Fragment>
        )
    }
}

export default compose(withFirebase)(RecipeUpload);
