import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const initialState = {
  buttonEnabled: false,
};

function toggleBackdrop(open, handleClose, handleToggle) {
    if(open) {
        setTimeout(() => {
            handleClose();
        }, 3000);
    }
  return (
    <Backdrop
      style={{ zIndex: 1201, color: "#fff" }}
      open={open}
      onClick={handleClose}
    >
      <Box
        component={Paper}
        boxShadow={3}
        p={2}
        style={{ textAlign: "center" }}
      >
        <CircularProgress
          size="60px"
          color="primary"
          style={{
            marginTop: "30px",
            marginBottom: "60px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Typography variant="h6" component="h3" gutterBottom>
          Starting AI Analysis...
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{ display: "block", width: "100%", marginTop: "10px" }}
          onClick={handleToggle}
        >
          Cancel
        </Button>
      </Box>
    </Backdrop>
  );
}

function AIButton() {
  let [buttonEnabled, setEnabled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(true);
    localStorage.setItem("AIToggle", true);
  };

  return (
    <React.Fragment>
      {toggleBackdrop(open, handleClose, handleToggle)}
      <Switch>
        <Route
          exact
          path="/orders/:id"
          render={() => (
            <Button
              onClick={handleToggle}
              variant="contained"
              startIcon={<AssessmentIcon />}
              style={{ color: "#FFF", backgroundColor: "#00B19E" }}
            >
              Activate AI Analysis
            </Button>
          )}
        />
        <Route
          path="/"
          render={() => (
            <Button
              variant="contained"
              startIcon={<AssessmentIcon />}
              color="secondary"
              disabled={buttonEnabled ? false : true}
            >
              Activate AI Analysis
            </Button>
          )}
        />
      </Switch>
    </React.Fragment>
  );
}

export default AIButton;
