import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import recipeDB from "../../data/recipeDB.json";

function msToMinSec(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
}

function fetchCommodityName(recipeid, rawmaterials) {
  let rawmaterialList = rawmaterials;
  let rawmaterialName = '';
  Object.keys(rawmaterialList).map((item) => {
    if(rawmaterialList[item]._id === recipeid) {
      rawmaterialName = rawmaterialList[item].rawmaterialName;
    }
  });
  return rawmaterialName;
}
function fetchCommodityDensity(recipeid, rawmaterials) {
  let rawmaterialList = rawmaterials;
  let rawmaterialDensity = '';
  Object.keys(rawmaterialList).map((item) => {
    if(rawmaterialList[item]._id === recipeid) {
      rawmaterialDensity = rawmaterialList[item].rawmaterialDensity;
    }
  });
  if (rawmaterialDensity === "") {
    return "-";
  } else {
    return rawmaterialDensity;
  }
}
function fetchCommodityGroup(recipeid, rawmaterials) {
  let rawmaterialList = rawmaterials;
  let rawmaterialGroup = '';
  Object.keys(rawmaterialList).map((item) => {
    if(rawmaterialList[item]._id === recipeid) {
      rawmaterialGroup = rawmaterialList[item].rawmaterialGroup;
    }
  });
  return rawmaterialGroup;
}

class DisplayCommodity extends Component {
  render() {
    const { rawmaterials, production } = this.props;
    let commodityRowValue = [];
    let recipeRefID;
    let recipeList;

    if (production.internalMixer !== undefined) {
      recipeRefID = production.recipeRefID;
      recipeList = recipeDB;

      let foundRecipe = recipeList.find((x) => x._id === recipeRefID)
        .rawmaterialList;

      let foundRecipeArray = Object.entries(foundRecipe);

      for (const [order, recipeid] of foundRecipeArray) {
        let pos = parseInt(order) + 1;
        let name = fetchCommodityName(recipeid, rawmaterials);
        let density = fetchCommodityDensity(recipeid, rawmaterials);
        let group = fetchCommodityGroup(recipeid, rawmaterials);

        commodityRowValue.push(
          <TableRow key={order}>
            <TableCell>{pos}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{group}</TableCell>
            <TableCell>{density}</TableCell>
          </TableRow>
        );
      }
    }

    return (
      <Box style={{ width: "100%" }}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Step</strong>
                </TableCell>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Group</strong>
                </TableCell>
                <TableCell>
                  <strong>Density</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{commodityRowValue}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
}

export default DisplayCommodity;
