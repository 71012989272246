import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import CalculateDeadline from "./displayDeadline";
import DisplayDiagnostic from "./displayDiagnostic";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ListAltIcon from "@material-ui/icons/ListAlt";

class OrderExtended extends Component {
  state = {};

  render() {
    const { order } = this.props;

    // Transfer Object values to variables
    let productionMsg;

    // stepProduction
    switch (order.currentProductionStep) {
      case 1:
        productionMsg = "Mixer - initiated";
        break;
      case 2:
        productionMsg = "Mixer";
        break;
      case 3:
        productionMsg = "Roller";
        break;
      case 4:
        productionMsg = "Molding";
        break;
      case 5:
        productionMsg = "";
        break;
      default:
        productionMsg = "Awaiting";
        break;
    }

    let produceUntil;
    let orderDiagnostic;

    if (order.currentProductionStep !== 5) {
      produceUntil = (
        <CalculateDeadline key={order._id} deadline={order.produceUntil} />
      );
    }
    if (order.currentProductionStep === 5) {
      orderDiagnostic = (
        <DisplayDiagnostic
          key={order._id}
          status={order.currentProductionStep}
          diagnostic={order.diagnostics}
        />
      );
    }

    return (
      <Card key={order._id} style={{ borderRadius: "0px" }}>
        {produceUntil}
        <CardContent>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item style={{ flex: 1 }}>
              <CardHeader
                title={order.clientName}
                subheader={`Order-ID: ${order._id}`}
              />
            </Grid>
            <Grid item>
              <Typography
                component="h4"
                color="textSecondary"
                style={{ fontSize: "21px", marginBottom: "6px" }}
              >
                {productionMsg}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<ListAltIcon />}
                component={Link}
                style={{ marginRight: "10px" }}
                to={`/wizardorder/${order._id}/${order.currentProductionStep}`}
              >
                Initiate
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<ListAltIcon />}
                component={Link}
                to={`/workorders/${order._id}`}
                disabled={this.props.disableBtn}
              >
                Details
              </Button>
            </Grid>
          </Grid>
          {orderDiagnostic}
        </CardContent>
      </Card>
    );
  }
}

OrderExtended.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderExtended;
