import React from 'react';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { MathComponent } from 'mathjax-react'

class Table1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            bgColor: 'white'
        }
        this.changeBgColor = this.changeBgColor.bind(this);
    }

    componentDidMount() {
        setInterval(() => {
            if(!this.state.loaded) {
                this.changeBgColor();
            }
        }, 5000);
    }

    changeBgColor() {
        if (localStorage.getItem("AIToggle") === "true") {
            this.setState({bgColor: 'green', loaded: true});
        }
    }

    render() {
        return (
            <div>
        <Container
            component={Paper}
            style={this.state.loaded ? {
                paddingTop: "24px",
                paddingBottom: "48px",
                marginBottom: "60px",
            } : {display: 'none'}}
        >
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={2}>
                                    Modal
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                    Error
                                </TableCell>
                                <TableCell align="center" colSpan={6}>
                                    Parameter
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Material</TableCell>
                                <TableCell align="left">EPDM CB 60 (Fit upto 200%)</TableCell>
                                <TableCell align="left"><MathComponent tex={String.raw`\chi^2`} /></TableCell>
                                <TableCell align="left">C10 [MPa]</TableCell>
                                <TableCell align="left">C20 [MPa]</TableCell>
                                <TableCell align="left">C30 [MPa]</TableCell>
                                <TableCell align="left">C01 [MPa]</TableCell>
                                <TableCell align="left">C11 [MPa]</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell rowSpan={4}>Mooney-Series</TableCell>
                                <TableCell>Neo-Hooke</TableCell>
                                <TableCell>0.2223</TableCell>
                                <TableCell>1.1332</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Mooney-Rivlin</TableCell>
                                <TableCell>0.2223</TableCell>
                                <TableCell>1.133</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>2.5359</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Yeoh</TableCell>
                                <TableCell>0.0122</TableCell>
                                <TableCell>0.8582</TableCell>
                                <TableCell>0.0344</TableCell>
                                <TableCell>3.1084</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{background: this.state.bgColor}}>James {this.state.loaded ? <small style={{color: 'white', marginLeft: '30px', fontWeight: '900'}}>(Best fit)</small> : ""}</TableCell>
                                <TableCell style={{background: this.state.bgColor}}>0.0086</TableCell>
                                <TableCell>1.0000</TableCell>
                                <TableCell>1.0000</TableCell>
                                <TableCell>1.0000</TableCell>
                                <TableCell>0.3914</TableCell>
                                <TableCell>0.1233</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={6} style={{background: '#b8b8b6'}}></TableCell>
                                <TableCell rowSpan={2}>Ogden (N=3)</TableCell>
                                <TableCell><MathComponent tex={String.raw`\chi^2`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\mu_1 [MPa]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\alpha_1 [ - ]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\mu_2 [MPa]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\alpha_2 [ - ]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\mu_3 [MPa]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\alpha_3 [ - ]`} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>0.0098</TableCell>
                                <TableCell>4.5295</TableCell>
                                <TableCell>0.0688</TableCell>
                                <TableCell>4.7438</TableCell>
                                <TableCell>0.0689</TableCell>
                                <TableCell>0.8748</TableCell>
                                <TableCell>2.9228</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>Arruda-Boyce</TableCell>
                                <TableCell><MathComponent tex={String.raw`\chi^2`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\mu [MPa]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`\lambda_m [ - ]`} /></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>0.0224</TableCell>
                                <TableCell>1.6359</TableCell>
                                <TableCell>5.9800</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>Simplified Non-affine Tube Model</TableCell>
                                <TableCell><MathComponent tex={String.raw`\chi^2`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`G_c [MPa]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`G_e [MPa]`} /></TableCell>
                                <TableCell><MathComponent tex={String.raw`1/n [ - ]`} /></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>0.0182</TableCell>
                                <TableCell>1.7941</TableCell>
                                <TableCell>1.0000</TableCell>
                                <TableCell>0.2674</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
        </Container>
            </div>

        )
    }
}

export default Table1;
