export const torqueDataWithoutAI = [
    {
        "x": 0.02,
        "y": 4.51
    },
    {
        "x": 0.03,
        "y": 4.49
    },
    {
        "x": 0.04,
        "y": 4.37
    },
    {
        "x": 0.05,
        "y": 4.14
    },
    {
        "x": 0.06,
        "y": 3.84
    },
    {
        "x": 0.07,
        "y": 3.56
    },
    {
        "x": 0.08,
        "y": 3.32
    },
    {
        "x": 0.09,
        "y": 3.13
    },
    {
        "x": 0.1,
        "y": 2.96
    },
    {
        "x": 0.11,
        "y": 2.83
    },
    {
        "x": 0.12,
        "y": 2.72
    },
    {
        "x": 0.13,
        "y": 2.63
    },
    {
        "x": 0.14,
        "y": 2.55
    },
    {
        "x": 0.15,
        "y": 2.49
    },
    {
        "x": 0.16,
        "y": 2.44
    },
    {
        "x": 0.17,
        "y": 2.39
    },
    {
        "x": 0.18,
        "y": 2.35
    },
    {
        "x": 0.19,
        "y": 2.31
    },
    {
        "x": 0.2,
        "y": 2.28
    },
    {
        "x": 0.21,
        "y": 2.25
    },
    {
        "x": 0.22,
        "y": 2.22
    },
    {
        "x": 0.23,
        "y": 2.2
    },
    {
        "x": 0.24,
        "y": 2.18
    },
    {
        "x": 0.25,
        "y": 2.16
    },
    {
        "x": 0.26,
        "y": 2.14
    },
    {
        "x": 0.27,
        "y": 2.13
    },
    {
        "x": 0.28,
        "y": 2.11
    },
    {
        "x": 0.29,
        "y": 2.1
    },
    {
        "x": 0.3,
        "y": 2.09
    },
    {
        "x": 0.31,
        "y": 2.08
    },
    {
        "x": 0.32,
        "y": 2.07
    },
    {
        "x": 0.33,
        "y": 2.06
    },
    {
        "x": 0.34,
        "y": 2.05
    },
    {
        "x": 0.35,
        "y": 2.04
    },
    {
        "x": 0.36,
        "y": 2.04
    },
    {
        "x": 0.37,
        "y": 2.03
    },
    {
        "x": 0.38,
        "y": 2.02
    },
    {
        "x": 0.39,
        "y": 2.02
    },
    {
        "x": 0.4,
        "y": 2.02
    },
    {
        "x": 0.41,
        "y": 2.01
    },
    {
        "x": 0.42,
        "y": 2.01
    },
    {
        "x": 0.43,
        "y": 2.01
    },
    {
        "x": 0.44,
        "y": 2
    },
    {
        "x": 0.45,
        "y": 2
    },
    {
        "x": 0.46,
        "y": 2
    },
    {
        "x": 0.47,
        "y": 2
    },
    {
        "x": 0.48,
        "y": 1.99
    },
    {
        "x": 0.49,
        "y": 1.99
    },
    {
        "x": 0.5,
        "y": 1.99
    },
    {
        "x": 0.51,
        "y": 1.99
    },
    {
        "x": 0.52,
        "y": 1.99
    },
    {
        "x": 0.53,
        "y": 1.99
    },
    {
        "x": 0.54,
        "y": 1.99
    },
    {
        "x": 0.55,
        "y": 1.98
    },
    {
        "x": 0.56,
        "y": 1.99
    },
    {
        "x": 0.57,
        "y": 1.99
    },
    {
        "x": 0.58,
        "y": 1.99
    },
    {
        "x": 0.59,
        "y": 1.99
    },
    {
        "x": 0.6,
        "y": 1.99
    },
    {
        "x": 0.61,
        "y": 1.99
    },
    {
        "x": 0.62,
        "y": 1.99
    },
    {
        "x": 0.63,
        "y": 1.99
    },
    {
        "x": 0.64,
        "y": 1.99
    },
    {
        "x": 0.65,
        "y": 1.99
    },
    {
        "x": 0.66,
        "y": 1.99
    },
    {
        "x": 0.67,
        "y": 1.99
    },
    {
        "x": 0.68,
        "y": 1.99
    },
    {
        "x": 0.69,
        "y": 2
    },
    {
        "x": 0.7,
        "y": 1.99
    },
    {
        "x": 0.71,
        "y": 2
    },
    {
        "x": 0.72,
        "y": 1.99
    },
    {
        "x": 0.73,
        "y": 1.99
    },
    {
        "x": 0.74,
        "y": 1.99
    },
    {
        "x": 0.75,
        "y": 2
    },
    {
        "x": 0.76,
        "y": 2
    },
    {
        "x": 0.77,
        "y": 2
    },
    {
        "x": 0.78,
        "y": 2
    },
    {
        "x": 0.79,
        "y": 2
    },
    {
        "x": 0.8,
        "y": 2
    },
    {
        "x": 0.81,
        "y": 2
    },
    {
        "x": 0.82,
        "y": 2
    },
    {
        "x": 0.83,
        "y": 2
    },
    {
        "x": 0.84,
        "y": 2
    },
    {
        "x": 0.85,
        "y": 2
    },
    {
        "x": 0.86,
        "y": 2
    },
    {
        "x": 0.87,
        "y": 2
    },
    {
        "x": 0.88,
        "y": 2
    },
    {
        "x": 0.89,
        "y": 2
    },
    {
        "x": 0.9,
        "y": 2
    },
    {
        "x": 0.91,
        "y": 2
    },
    {
        "x": 0.92,
        "y": 2
    },
    {
        "x": 0.93,
        "y": 2
    },
    {
        "x": 0.94,
        "y": 2
    },
    {
        "x": 0.95,
        "y": 2
    },
    {
        "x": 0.96,
        "y": 2
    },
    {
        "x": 0.97,
        "y": 2
    },
    {
        "x": 0.98,
        "y": 2
    },
    {
        "x": 0.99,
        "y": 2
    },
    {
        "x": 1,
        "y": 2
    },
    {
        "x": 1.01,
        "y": 2
    },
    {
        "x": 1.02,
        "y": 2
    },
    {
        "x": 1.03,
        "y": 2
    },
    {
        "x": 1.04,
        "y": 2
    },
    {
        "x": 1.05,
        "y": 2
    },
    {
        "x": 1.06,
        "y": 2
    },
    {
        "x": 1.07,
        "y": 2.01
    },
    {
        "x": 1.08,
        "y": 2
    },
    {
        "x": 1.09,
        "y": 2.01
    },
    {
        "x": 1.1,
        "y": 2.01
    },
    {
        "x": 1.11,
        "y": 2
    },
    {
        "x": 1.12,
        "y": 2
    },
    {
        "x": 1.13,
        "y": 2.01
    },
    {
        "x": 1.14,
        "y": 2.01
    },
    {
        "x": 1.15,
        "y": 2.01
    },
    {
        "x": 1.16,
        "y": 2.01
    },
    {
        "x": 1.17,
        "y": 2.01
    },
    {
        "x": 1.18,
        "y": 2.01
    },
    {
        "x": 1.19,
        "y": 2.01
    },
    {
        "x": 1.2,
        "y": 2.01
    },
    {
        "x": 1.21,
        "y": 2.01
    },
    {
        "x": 1.22,
        "y": 2.01
    },
    {
        "x": 1.23,
        "y": 2.01
    },
    {
        "x": 1.24,
        "y": 2.01
    },
    {
        "x": 1.25,
        "y": 2.01
    },
    {
        "x": 1.26,
        "y": 2.01
    },
    {
        "x": 1.27,
        "y": 2.01
    },
    {
        "x": 1.28,
        "y": 2.01
    },
    {
        "x": 1.29,
        "y": 2.01
    },
    {
        "x": 1.3,
        "y": 2.01
    },
    {
        "x": 1.31,
        "y": 2.01
    },
    {
        "x": 1.32,
        "y": 2.01
    },
    {
        "x": 1.33,
        "y": 2.01
    },
    {
        "x": 1.34,
        "y": 2.01
    },
    {
        "x": 1.35,
        "y": 2.01
    },
    {
        "x": 1.36,
        "y": 2.01
    },
    {
        "x": 1.37,
        "y": 2.01
    },
    {
        "x": 1.38,
        "y": 2.01
    },
    {
        "x": 1.39,
        "y": 2.01
    },
    {
        "x": 1.4,
        "y": 2.02
    },
    {
        "x": 1.41,
        "y": 2.01
    },
    {
        "x": 1.42,
        "y": 2.01
    },
    {
        "x": 1.43,
        "y": 2.02
    },
    {
        "x": 1.44,
        "y": 2.01
    },
    {
        "x": 1.45,
        "y": 2.02
    },
    {
        "x": 1.46,
        "y": 2.02
    },
    {
        "x": 1.47,
        "y": 2.02
    },
    {
        "x": 1.48,
        "y": 2.02
    },
    {
        "x": 1.49,
        "y": 2.02
    },
    {
        "x": 1.5,
        "y": 2.02
    },
    {
        "x": 1.51,
        "y": 2.01
    },
    {
        "x": 1.52,
        "y": 2.02
    },
    {
        "x": 1.53,
        "y": 2.02
    },
    {
        "x": 1.54,
        "y": 2.02
    },
    {
        "x": 1.55,
        "y": 2.01
    },
    {
        "x": 1.56,
        "y": 2.02
    },
    {
        "x": 1.57,
        "y": 2.02
    },
    {
        "x": 1.58,
        "y": 2.02
    },
    {
        "x": 1.59,
        "y": 2.02
    },
    {
        "x": 1.6,
        "y": 2.02
    },
    {
        "x": 1.61,
        "y": 2.02
    },
    {
        "x": 1.62,
        "y": 2.02
    },
    {
        "x": 1.63,
        "y": 2.02
    },
    {
        "x": 1.64,
        "y": 2.02
    },
    {
        "x": 1.65,
        "y": 2.02
    },
    {
        "x": 1.66,
        "y": 2.02
    },
    {
        "x": 1.67,
        "y": 2.02
    },
    {
        "x": 1.68,
        "y": 2.02
    },
    {
        "x": 1.69,
        "y": 2.02
    },
    {
        "x": 1.7,
        "y": 2.02
    },
    {
        "x": 1.71,
        "y": 2.02
    },
    {
        "x": 1.72,
        "y": 2.03
    },
    {
        "x": 1.73,
        "y": 2.02
    },
    {
        "x": 1.74,
        "y": 2.02
    },
    {
        "x": 1.75,
        "y": 2.02
    },
    {
        "x": 1.76,
        "y": 2.03
    },
    {
        "x": 1.77,
        "y": 2.03
    },
    {
        "x": 1.78,
        "y": 2.03
    },
    {
        "x": 1.79,
        "y": 2.03
    },
    {
        "x": 1.8,
        "y": 2.02
    },
    {
        "x": 1.81,
        "y": 2.03
    },
    {
        "x": 1.82,
        "y": 2.03
    },
    {
        "x": 1.83,
        "y": 2.03
    },
    {
        "x": 1.84,
        "y": 2.03
    },
    {
        "x": 1.85,
        "y": 2.03
    },
    {
        "x": 1.86,
        "y": 2.03
    },
    {
        "x": 1.87,
        "y": 2.03
    },
    {
        "x": 1.88,
        "y": 2.03
    },
    {
        "x": 1.89,
        "y": 2.03
    },
    {
        "x": 1.9,
        "y": 2.03
    },
    {
        "x": 1.91,
        "y": 2.03
    },
    {
        "x": 1.92,
        "y": 2.03
    },
    {
        "x": 1.93,
        "y": 2.03
    },
    {
        "x": 1.94,
        "y": 2.03
    },
    {
        "x": 1.95,
        "y": 2.03
    },
    {
        "x": 1.96,
        "y": 2.03
    },
    {
        "x": 1.97,
        "y": 2.04
    },
    {
        "x": 1.98,
        "y": 2.04
    },
    {
        "x": 1.99,
        "y": 2.04
    },
    {
        "x": 2,
        "y": 2.04
    },
    {
        "x": 2.01,
        "y": 2.04
    },
    {
        "x": 2.02,
        "y": 2.04
    },
    {
        "x": 2.03,
        "y": 2.04
    },
    {
        "x": 2.04,
        "y": 2.04
    },
    {
        "x": 2.05,
        "y": 2.04
    },
    {
        "x": 2.06,
        "y": 2.04
    },
    {
        "x": 2.07,
        "y": 2.04
    },
    {
        "x": 2.08,
        "y": 2.04
    },
    {
        "x": 2.09,
        "y": 2.04
    },
    {
        "x": 2.1,
        "y": 2.04
    },
    {
        "x": 2.11,
        "y": 2.04
    },
    {
        "x": 2.12,
        "y": 2.04
    },
    {
        "x": 2.13,
        "y": 2.05
    },
    {
        "x": 2.14,
        "y": 2.05
    },
    {
        "x": 2.15,
        "y": 2.05
    },
    {
        "x": 2.16,
        "y": 2.04
    },
    {
        "x": 2.17,
        "y": 2.05
    },
    {
        "x": 2.18,
        "y": 2.05
    },
    {
        "x": 2.19,
        "y": 2.05
    },
    {
        "x": 2.2,
        "y": 2.05
    },
    {
        "x": 2.21,
        "y": 2.05
    },
    {
        "x": 2.22,
        "y": 2.05
    },
    {
        "x": 2.23,
        "y": 2.05
    },
    {
        "x": 2.24,
        "y": 2.05
    },
    {
        "x": 2.25,
        "y": 2.05
    },
    {
        "x": 2.26,
        "y": 2.05
    },
    {
        "x": 2.27,
        "y": 2.05
    },
    {
        "x": 2.28,
        "y": 2.05
    },
    {
        "x": 2.29,
        "y": 2.06
    },
    {
        "x": 2.3,
        "y": 2.05
    },
    {
        "x": 2.31,
        "y": 2.05
    },
    {
        "x": 2.32,
        "y": 2.05
    },
    {
        "x": 2.33,
        "y": 2.06
    },
    {
        "x": 2.34,
        "y": 2.06
    },
    {
        "x": 2.35,
        "y": 2.06
    },
    {
        "x": 2.36,
        "y": 2.06
    },
    {
        "x": 2.37,
        "y": 2.06
    },
    {
        "x": 2.38,
        "y": 2.06
    },
    {
        "x": 2.39,
        "y": 2.06
    },
    {
        "x": 2.4,
        "y": 2.06
    },
    {
        "x": 2.41,
        "y": 2.06
    },
    {
        "x": 2.42,
        "y": 2.06
    },
    {
        "x": 2.43,
        "y": 2.06
    },
    {
        "x": 2.44,
        "y": 2.07
    },
    {
        "x": 2.45,
        "y": 2.07
    },
    {
        "x": 2.46,
        "y": 2.07
    },
    {
        "x": 2.47,
        "y": 2.07
    },
    {
        "x": 2.48,
        "y": 2.07
    },
    {
        "x": 2.49,
        "y": 2.07
    },
    {
        "x": 2.5,
        "y": 2.07
    },
    {
        "x": 2.51,
        "y": 2.07
    },
    {
        "x": 2.52,
        "y": 2.07
    },
    {
        "x": 2.53,
        "y": 2.07
    },
    {
        "x": 2.54,
        "y": 2.07
    },
    {
        "x": 2.55,
        "y": 2.07
    },
    {
        "x": 2.56,
        "y": 2.07
    },
    {
        "x": 2.57,
        "y": 2.08
    },
    {
        "x": 2.58,
        "y": 2.08
    },
    {
        "x": 2.59,
        "y": 2.08
    },
    {
        "x": 2.6,
        "y": 2.08
    },
    {
        "x": 2.61,
        "y": 2.08
    },
    {
        "x": 2.62,
        "y": 2.08
    },
    {
        "x": 2.63,
        "y": 2.08
    },
    {
        "x": 2.64,
        "y": 2.08
    },
    {
        "x": 2.65,
        "y": 2.08
    },
    {
        "x": 2.66,
        "y": 2.08
    },
    {
        "x": 2.67,
        "y": 2.09
    },
    {
        "x": 2.68,
        "y": 2.08
    },
    {
        "x": 2.69,
        "y": 2.09
    },
    {
        "x": 2.7,
        "y": 2.09
    },
    {
        "x": 2.71,
        "y": 2.09
    },
    {
        "x": 2.72,
        "y": 2.09
    },
    {
        "x": 2.73,
        "y": 2.09
    },
    {
        "x": 2.74,
        "y": 2.09
    },
    {
        "x": 2.75,
        "y": 2.09
    },
    {
        "x": 2.76,
        "y": 2.09
    },
    {
        "x": 2.77,
        "y": 2.09
    },
    {
        "x": 2.78,
        "y": 2.09
    },
    {
        "x": 2.79,
        "y": 2.1
    },
    {
        "x": 2.8,
        "y": 2.1
    },
    {
        "x": 2.81,
        "y": 2.1
    },
    {
        "x": 2.82,
        "y": 2.1
    },
    {
        "x": 2.83,
        "y": 2.1
    },
    {
        "x": 2.84,
        "y": 2.1
    },
    {
        "x": 2.85,
        "y": 2.11
    },
    {
        "x": 2.86,
        "y": 2.1
    },
    {
        "x": 2.87,
        "y": 2.11
    },
    {
        "x": 2.88,
        "y": 2.11
    },
    {
        "x": 2.89,
        "y": 2.11
    },
    {
        "x": 2.9,
        "y": 2.11
    },
    {
        "x": 2.91,
        "y": 2.11
    },
    {
        "x": 2.92,
        "y": 2.11
    },
    {
        "x": 2.93,
        "y": 2.11
    },
    {
        "x": 2.94,
        "y": 2.11
    },
    {
        "x": 2.95,
        "y": 2.11
    },
    {
        "x": 2.96,
        "y": 2.12
    },
    {
        "x": 2.97,
        "y": 2.12
    },
    {
        "x": 2.98,
        "y": 2.12
    },
    {
        "x": 2.99,
        "y": 2.12
    },
    {
        "x": 3,
        "y": 2.12
    },
    {
        "x": 3.01,
        "y": 2.12
    },
    {
        "x": 3.02,
        "y": 2.13
    },
    {
        "x": 3.03,
        "y": 2.13
    },
    {
        "x": 3.04,
        "y": 2.13
    },
    {
        "x": 3.05,
        "y": 2.13
    },
    {
        "x": 3.06,
        "y": 2.13
    },
    {
        "x": 3.07,
        "y": 2.13
    },
    {
        "x": 3.08,
        "y": 2.13
    },
    {
        "x": 3.09,
        "y": 2.13
    },
    {
        "x": 3.1,
        "y": 2.13
    },
    {
        "x": 3.11,
        "y": 2.14
    },
    {
        "x": 3.12,
        "y": 2.14
    },
    {
        "x": 3.13,
        "y": 2.14
    },
    {
        "x": 3.14,
        "y": 2.14
    },
    {
        "x": 3.15,
        "y": 2.14
    },
    {
        "x": 3.16,
        "y": 2.14
    },
    {
        "x": 3.17,
        "y": 2.14
    },
    {
        "x": 3.18,
        "y": 2.15
    },
    {
        "x": 3.19,
        "y": 2.15
    },
    {
        "x": 3.2,
        "y": 2.15
    },
    {
        "x": 3.21,
        "y": 2.15
    },
    {
        "x": 3.22,
        "y": 2.15
    },
    {
        "x": 3.23,
        "y": 2.15
    },
    {
        "x": 3.24,
        "y": 2.16
    },
    {
        "x": 3.25,
        "y": 2.16
    },
    {
        "x": 3.26,
        "y": 2.16
    },
    {
        "x": 3.27,
        "y": 2.16
    },
    {
        "x": 3.28,
        "y": 2.16
    },
    {
        "x": 3.29,
        "y": 2.16
    },
    {
        "x": 3.3,
        "y": 2.16
    },
    {
        "x": 3.31,
        "y": 2.16
    },
    {
        "x": 3.32,
        "y": 2.17
    },
    {
        "x": 3.33,
        "y": 2.17
    },
    {
        "x": 3.34,
        "y": 2.17
    },
    {
        "x": 3.35,
        "y": 2.17
    },
    {
        "x": 3.36,
        "y": 2.17
    },
    {
        "x": 3.37,
        "y": 2.17
    },
    {
        "x": 3.38,
        "y": 2.18
    },
    {
        "x": 3.39,
        "y": 2.18
    },
    {
        "x": 3.4,
        "y": 2.17
    },
    {
        "x": 3.41,
        "y": 2.18
    },
    {
        "x": 3.42,
        "y": 2.18
    },
    {
        "x": 3.43,
        "y": 2.18
    },
    {
        "x": 3.44,
        "y": 2.19
    },
    {
        "x": 3.45,
        "y": 2.19
    },
    {
        "x": 3.46,
        "y": 2.19
    },
    {
        "x": 3.47,
        "y": 2.19
    },
    {
        "x": 3.48,
        "y": 2.19
    },
    {
        "x": 3.49,
        "y": 2.19
    },
    {
        "x": 3.5,
        "y": 2.2
    },
    {
        "x": 3.51,
        "y": 2.2
    },
    {
        "x": 3.52,
        "y": 2.2
    },
    {
        "x": 3.53,
        "y": 2.2
    },
    {
        "x": 3.54,
        "y": 2.2
    },
    {
        "x": 3.55,
        "y": 2.21
    },
    {
        "x": 3.56,
        "y": 2.21
    },
    {
        "x": 3.57,
        "y": 2.21
    },
    {
        "x": 3.58,
        "y": 2.21
    },
    {
        "x": 3.59,
        "y": 2.21
    },
    {
        "x": 3.6,
        "y": 2.21
    },
    {
        "x": 3.61,
        "y": 2.21
    },
    {
        "x": 3.62,
        "y": 2.22
    },
    {
        "x": 3.63,
        "y": 2.22
    },
    {
        "x": 3.64,
        "y": 2.22
    },
    {
        "x": 3.65,
        "y": 2.22
    },
    {
        "x": 3.66,
        "y": 2.22
    },
    {
        "x": 3.67,
        "y": 2.23
    },
    {
        "x": 3.68,
        "y": 2.23
    },
    {
        "x": 3.69,
        "y": 2.23
    },
    {
        "x": 3.7,
        "y": 2.23
    },
    {
        "x": 3.71,
        "y": 2.23
    },
    {
        "x": 3.72,
        "y": 2.24
    },
    {
        "x": 3.73,
        "y": 2.24
    },
    {
        "x": 3.74,
        "y": 2.24
    },
    {
        "x": 3.75,
        "y": 2.25
    },
    {
        "x": 3.76,
        "y": 2.25
    },
    {
        "x": 3.77,
        "y": 2.25
    },
    {
        "x": 3.78,
        "y": 2.25
    },
    {
        "x": 3.79,
        "y": 2.25
    },
    {
        "x": 3.8,
        "y": 2.26
    },
    {
        "x": 3.81,
        "y": 2.26
    },
    {
        "x": 3.82,
        "y": 2.26
    },
    {
        "x": 3.83,
        "y": 2.26
    },
    {
        "x": 3.84,
        "y": 2.27
    },
    {
        "x": 3.85,
        "y": 2.27
    },
    {
        "x": 3.86,
        "y": 2.27
    },
    {
        "x": 3.87,
        "y": 2.28
    },
    {
        "x": 3.88,
        "y": 2.28
    },
    {
        "x": 3.89,
        "y": 2.28
    },
    {
        "x": 3.9,
        "y": 2.28
    },
    {
        "x": 3.91,
        "y": 2.28
    },
    {
        "x": 3.92,
        "y": 2.29
    },
    {
        "x": 3.93,
        "y": 2.29
    },
    {
        "x": 3.94,
        "y": 2.29
    },
    {
        "x": 3.95,
        "y": 2.3
    },
    {
        "x": 3.96,
        "y": 2.3
    },
    {
        "x": 3.97,
        "y": 2.3
    },
    {
        "x": 3.98,
        "y": 2.3
    },
    {
        "x": 3.99,
        "y": 2.31
    },
    {
        "x": 4,
        "y": 2.31
    },
    {
        "x": 4.01,
        "y": 2.31
    },
    {
        "x": 4.02,
        "y": 2.31
    },
    {
        "x": 4.03,
        "y": 2.32
    },
    {
        "x": 4.04,
        "y": 2.32
    },
    {
        "x": 4.05,
        "y": 2.32
    },
    {
        "x": 4.06,
        "y": 2.32
    },
    {
        "x": 4.07,
        "y": 2.33
    },
    {
        "x": 4.08,
        "y": 2.33
    },
    {
        "x": 4.09,
        "y": 2.33
    },
    {
        "x": 4.1,
        "y": 2.33
    },
    {
        "x": 4.11,
        "y": 2.34
    },
    {
        "x": 4.12,
        "y": 2.34
    },
    {
        "x": 4.13,
        "y": 2.34
    },
    {
        "x": 4.14,
        "y": 2.34
    },
    {
        "x": 4.15,
        "y": 2.35
    },
    {
        "x": 4.16,
        "y": 2.35
    },
    {
        "x": 4.17,
        "y": 2.35
    },
    {
        "x": 4.18,
        "y": 2.36
    },
    {
        "x": 4.19,
        "y": 2.36
    },
    {
        "x": 4.2,
        "y": 2.36
    },
    {
        "x": 4.21,
        "y": 2.37
    },
    {
        "x": 4.22,
        "y": 2.37
    },
    {
        "x": 4.23,
        "y": 2.38
    },
    {
        "x": 4.24,
        "y": 2.38
    },
    {
        "x": 4.25,
        "y": 2.38
    },
    {
        "x": 4.26,
        "y": 2.38
    },
    {
        "x": 4.27,
        "y": 2.38
    },
    {
        "x": 4.28,
        "y": 2.39
    },
    {
        "x": 4.29,
        "y": 2.39
    },
    {
        "x": 4.3,
        "y": 2.4
    },
    {
        "x": 4.31,
        "y": 2.4
    },
    {
        "x": 4.32,
        "y": 2.4
    },
    {
        "x": 4.33,
        "y": 2.41
    },
    {
        "x": 4.34,
        "y": 2.41
    },
    {
        "x": 4.35,
        "y": 2.41
    },
    {
        "x": 4.36,
        "y": 2.41
    },
    {
        "x": 4.37,
        "y": 2.42
    },
    {
        "x": 4.38,
        "y": 2.42
    },
    {
        "x": 4.39,
        "y": 2.42
    },
    {
        "x": 4.4,
        "y": 2.43
    },
    {
        "x": 4.41,
        "y": 2.43
    },
    {
        "x": 4.42,
        "y": 2.43
    },
    {
        "x": 4.43,
        "y": 2.44
    },
    {
        "x": 4.44,
        "y": 2.44
    },
    {
        "x": 4.45,
        "y": 2.44
    },
    {
        "x": 4.46,
        "y": 2.45
    },
    {
        "x": 4.47,
        "y": 2.45
    },
    {
        "x": 4.48,
        "y": 2.46
    },
    {
        "x": 4.49,
        "y": 2.46
    },
    {
        "x": 4.5,
        "y": 2.46
    },
    {
        "x": 4.51,
        "y": 2.47
    },
    {
        "x": 4.52,
        "y": 2.47
    },
    {
        "x": 4.53,
        "y": 2.48
    },
    {
        "x": 4.54,
        "y": 2.48
    },
    {
        "x": 4.55,
        "y": 2.48
    },
    {
        "x": 4.56,
        "y": 2.48
    },
    {
        "x": 4.57,
        "y": 2.49
    },
    {
        "x": 4.58,
        "y": 2.49
    },
    {
        "x": 4.59,
        "y": 2.5
    },
    {
        "x": 4.6,
        "y": 2.5
    },
    {
        "x": 4.61,
        "y": 2.5
    },
    {
        "x": 4.62,
        "y": 2.51
    },
    {
        "x": 4.63,
        "y": 2.51
    },
    {
        "x": 4.64,
        "y": 2.51
    },
    {
        "x": 4.65,
        "y": 2.52
    },
    {
        "x": 4.66,
        "y": 2.53
    },
    {
        "x": 4.67,
        "y": 2.53
    },
    {
        "x": 4.68,
        "y": 2.53
    },
    {
        "x": 4.69,
        "y": 2.54
    },
    {
        "x": 4.7,
        "y": 2.55
    },
    {
        "x": 4.71,
        "y": 2.55
    },
    {
        "x": 4.72,
        "y": 2.55
    },
    {
        "x": 4.73,
        "y": 2.56
    },
    {
        "x": 4.74,
        "y": 2.56
    },
    {
        "x": 4.75,
        "y": 2.56
    },
    {
        "x": 4.76,
        "y": 2.57
    },
    {
        "x": 4.77,
        "y": 2.57
    },
    {
        "x": 4.78,
        "y": 2.58
    },
    {
        "x": 4.79,
        "y": 2.58
    },
    {
        "x": 4.8,
        "y": 2.58
    },
    {
        "x": 4.81,
        "y": 2.59
    },
    {
        "x": 4.82,
        "y": 2.6
    },
    {
        "x": 4.83,
        "y": 2.6
    },
    {
        "x": 4.84,
        "y": 2.61
    },
    {
        "x": 4.85,
        "y": 2.61
    },
    {
        "x": 4.86,
        "y": 2.61
    },
    {
        "x": 4.87,
        "y": 2.62
    },
    {
        "x": 4.88,
        "y": 2.62
    },
    {
        "x": 4.89,
        "y": 2.63
    },
    {
        "x": 4.9,
        "y": 2.64
    },
    {
        "x": 4.91,
        "y": 2.64
    },
    {
        "x": 4.92,
        "y": 2.64
    },
    {
        "x": 4.93,
        "y": 2.65
    },
    {
        "x": 4.94,
        "y": 2.66
    },
    {
        "x": 4.95,
        "y": 2.66
    },
    {
        "x": 4.96,
        "y": 2.67
    },
    {
        "x": 4.97,
        "y": 2.67
    },
    {
        "x": 4.98,
        "y": 2.67
    },
    {
        "x": 4.99,
        "y": 2.68
    },
    {
        "x": 5,
        "y": 2.69
    },
    {
        "x": 5.01,
        "y": 2.69
    },
    {
        "x": 5.02,
        "y": 2.7
    },
    {
        "x": 5.03,
        "y": 2.7
    },
    {
        "x": 5.04,
        "y": 2.71
    },
    {
        "x": 5.05,
        "y": 2.71
    },
    {
        "x": 5.06,
        "y": 2.72
    },
    {
        "x": 5.07,
        "y": 2.73
    },
    {
        "x": 5.08,
        "y": 2.73
    },
    {
        "x": 5.09,
        "y": 2.74
    },
    {
        "x": 5.1,
        "y": 2.74
    },
    {
        "x": 5.11,
        "y": 2.75
    },
    {
        "x": 5.12,
        "y": 2.75
    },
    {
        "x": 5.13,
        "y": 2.76
    },
    {
        "x": 5.14,
        "y": 2.76
    },
    {
        "x": 5.15,
        "y": 2.77
    },
    {
        "x": 5.16,
        "y": 2.77
    },
    {
        "x": 5.17,
        "y": 2.78
    },
    {
        "x": 5.18,
        "y": 2.79
    },
    {
        "x": 5.19,
        "y": 2.8
    },
    {
        "x": 5.2,
        "y": 2.8
    },
    {
        "x": 5.21,
        "y": 2.81
    },
    {
        "x": 5.22,
        "y": 2.81
    },
    {
        "x": 5.23,
        "y": 2.82
    },
    {
        "x": 5.24,
        "y": 2.82
    },
    {
        "x": 5.25,
        "y": 2.83
    },
    {
        "x": 5.26,
        "y": 2.84
    },
    {
        "x": 5.27,
        "y": 2.84
    },
    {
        "x": 5.28,
        "y": 2.85
    },
    {
        "x": 5.29,
        "y": 2.86
    },
    {
        "x": 5.3,
        "y": 2.86
    },
    {
        "x": 5.31,
        "y": 2.87
    },
    {
        "x": 5.32,
        "y": 2.88
    },
    {
        "x": 5.33,
        "y": 2.88
    },
    {
        "x": 5.34,
        "y": 2.89
    },
    {
        "x": 5.35,
        "y": 2.9
    },
    {
        "x": 5.36,
        "y": 2.9
    },
    {
        "x": 5.37,
        "y": 2.91
    },
    {
        "x": 5.38,
        "y": 2.91
    },
    {
        "x": 5.39,
        "y": 2.92
    },
    {
        "x": 5.4,
        "y": 2.93
    },
    {
        "x": 5.41,
        "y": 2.94
    },
    {
        "x": 5.42,
        "y": 2.95
    },
    {
        "x": 5.43,
        "y": 2.95
    },
    {
        "x": 5.44,
        "y": 2.96
    },
    {
        "x": 5.45,
        "y": 2.97
    },
    {
        "x": 5.46,
        "y": 2.97
    },
    {
        "x": 5.47,
        "y": 2.98
    },
    {
        "x": 5.48,
        "y": 2.99
    },
    {
        "x": 5.49,
        "y": 3
    },
    {
        "x": 5.5,
        "y": 3
    },
    {
        "x": 5.51,
        "y": 3.01
    },
    {
        "x": 5.52,
        "y": 3.02
    },
    {
        "x": 5.53,
        "y": 3.03
    },
    {
        "x": 5.54,
        "y": 3.03
    },
    {
        "x": 5.55,
        "y": 3.04
    },
    {
        "x": 5.56,
        "y": 3.05
    },
    {
        "x": 5.57,
        "y": 3.06
    },
    {
        "x": 5.58,
        "y": 3.06
    },
    {
        "x": 5.59,
        "y": 3.07
    },
    {
        "x": 5.6,
        "y": 3.08
    },
    {
        "x": 5.61,
        "y": 3.09
    },
    {
        "x": 5.62,
        "y": 3.09
    },
    {
        "x": 5.63,
        "y": 3.1
    },
    {
        "x": 5.64,
        "y": 3.11
    },
    {
        "x": 5.65,
        "y": 3.12
    },
    {
        "x": 5.66,
        "y": 3.13
    },
    {
        "x": 5.67,
        "y": 3.13
    },
    {
        "x": 5.68,
        "y": 3.14
    },
    {
        "x": 5.69,
        "y": 3.15
    },
    {
        "x": 5.7,
        "y": 3.16
    },
    {
        "x": 5.71,
        "y": 3.17
    },
    {
        "x": 5.72,
        "y": 3.18
    },
    {
        "x": 5.73,
        "y": 3.19
    },
    {
        "x": 5.74,
        "y": 3.2
    },
    {
        "x": 5.75,
        "y": 3.2
    },
    {
        "x": 5.76,
        "y": 3.21
    },
    {
        "x": 5.77,
        "y": 3.22
    },
    {
        "x": 5.78,
        "y": 3.23
    },
    {
        "x": 5.79,
        "y": 3.24
    },
    {
        "x": 5.8,
        "y": 3.25
    },
    {
        "x": 5.81,
        "y": 3.26
    },
    {
        "x": 5.82,
        "y": 3.27
    },
    {
        "x": 5.83,
        "y": 3.28
    },
    {
        "x": 5.84,
        "y": 3.28
    },
    {
        "x": 5.85,
        "y": 3.3
    },
    {
        "x": 5.86,
        "y": 3.3
    },
    {
        "x": 5.87,
        "y": 3.31
    },
    {
        "x": 5.88,
        "y": 3.32
    },
    {
        "x": 5.89,
        "y": 3.33
    },
    {
        "x": 5.9,
        "y": 3.34
    },
    {
        "x": 5.91,
        "y": 3.35
    },
    {
        "x": 5.92,
        "y": 3.36
    },
    {
        "x": 5.93,
        "y": 3.37
    },
    {
        "x": 5.94,
        "y": 3.38
    },
    {
        "x": 5.95,
        "y": 3.39
    },
    {
        "x": 5.96,
        "y": 3.4
    },
    {
        "x": 5.97,
        "y": 3.41
    },
    {
        "x": 5.98,
        "y": 3.42
    },
    {
        "x": 5.99,
        "y": 3.43
    },
    {
        "x": 6,
        "y": 3.44
    },
    {
        "x": 6.01,
        "y": 3.45
    },
    {
        "x": 6.02,
        "y": 3.46
    },
    {
        "x": 6.03,
        "y": 3.47
    },
    {
        "x": 6.04,
        "y": 3.48
    },
    {
        "x": 6.05,
        "y": 3.49
    },
    {
        "x": 6.06,
        "y": 3.5
    },
    {
        "x": 6.07,
        "y": 3.51
    },
    {
        "x": 6.08,
        "y": 3.52
    },
    {
        "x": 6.09,
        "y": 3.54
    },
    {
        "x": 6.1,
        "y": 3.55
    },
    {
        "x": 6.11,
        "y": 3.55
    },
    {
        "x": 6.12,
        "y": 3.57
    },
    {
        "x": 6.13,
        "y": 3.58
    },
    {
        "x": 6.14,
        "y": 3.59
    },
    {
        "x": 6.15,
        "y": 3.6
    },
    {
        "x": 6.16,
        "y": 3.61
    },
    {
        "x": 6.17,
        "y": 3.62
    },
    {
        "x": 6.18,
        "y": 3.64
    },
    {
        "x": 6.19,
        "y": 3.65
    },
    {
        "x": 6.2,
        "y": 3.66
    },
    {
        "x": 6.21,
        "y": 3.67
    },
    {
        "x": 6.22,
        "y": 3.68
    },
    {
        "x": 6.23,
        "y": 3.69
    },
    {
        "x": 6.24,
        "y": 3.7
    },
    {
        "x": 6.25,
        "y": 3.72
    },
    {
        "x": 6.26,
        "y": 3.73
    },
    {
        "x": 6.27,
        "y": 3.74
    },
    {
        "x": 6.28,
        "y": 3.75
    },
    {
        "x": 6.29,
        "y": 3.77
    },
    {
        "x": 6.3,
        "y": 3.78
    },
    {
        "x": 6.31,
        "y": 3.79
    },
    {
        "x": 6.32,
        "y": 3.8
    },
    {
        "x": 6.33,
        "y": 3.81
    },
    {
        "x": 6.34,
        "y": 3.83
    },
    {
        "x": 6.35,
        "y": 3.84
    },
    {
        "x": 6.36,
        "y": 3.86
    },
    {
        "x": 6.37,
        "y": 3.87
    },
    {
        "x": 6.38,
        "y": 3.88
    },
    {
        "x": 6.39,
        "y": 3.89
    },
    {
        "x": 6.4,
        "y": 3.91
    },
    {
        "x": 6.41,
        "y": 3.92
    },
    {
        "x": 6.42,
        "y": 3.93
    },
    {
        "x": 6.43,
        "y": 3.95
    },
    {
        "x": 6.44,
        "y": 3.96
    },
    {
        "x": 6.45,
        "y": 3.97
    },
    {
        "x": 6.46,
        "y": 3.98
    },
    {
        "x": 6.47,
        "y": 4
    },
    {
        "x": 6.48,
        "y": 4.01
    },
    {
        "x": 6.49,
        "y": 4.02
    },
    {
        "x": 6.5,
        "y": 4.04
    },
    {
        "x": 6.51,
        "y": 4.05
    },
    {
        "x": 6.52,
        "y": 4.07
    },
    {
        "x": 6.53,
        "y": 4.08
    },
    {
        "x": 6.54,
        "y": 4.1
    },
    {
        "x": 6.55,
        "y": 4.11
    },
    {
        "x": 6.56,
        "y": 4.12
    },
    {
        "x": 6.57,
        "y": 4.14
    },
    {
        "x": 6.58,
        "y": 4.15
    },
    {
        "x": 6.59,
        "y": 4.16
    },
    {
        "x": 6.6,
        "y": 4.18
    },
    {
        "x": 6.61,
        "y": 4.19
    },
    {
        "x": 6.62,
        "y": 4.21
    },
    {
        "x": 6.63,
        "y": 4.23
    },
    {
        "x": 6.64,
        "y": 4.24
    },
    {
        "x": 6.65,
        "y": 4.25
    },
    {
        "x": 6.66,
        "y": 4.27
    },
    {
        "x": 6.67,
        "y": 4.28
    },
    {
        "x": 6.68,
        "y": 4.3
    },
    {
        "x": 6.69,
        "y": 4.31
    },
    {
        "x": 6.7,
        "y": 4.33
    },
    {
        "x": 6.71,
        "y": 4.35
    },
    {
        "x": 6.72,
        "y": 4.36
    },
    {
        "x": 6.73,
        "y": 4.38
    },
    {
        "x": 6.74,
        "y": 4.39
    },
    {
        "x": 6.75,
        "y": 4.41
    },
    {
        "x": 6.76,
        "y": 4.43
    },
    {
        "x": 6.77,
        "y": 4.44
    },
    {
        "x": 6.78,
        "y": 4.46
    },
    {
        "x": 6.79,
        "y": 4.47
    },
    {
        "x": 6.8,
        "y": 4.49
    },
    {
        "x": 6.81,
        "y": 4.51
    },
    {
        "x": 6.82,
        "y": 4.52
    },
    {
        "x": 6.83,
        "y": 4.54
    },
    {
        "x": 6.84,
        "y": 4.55
    },
    {
        "x": 6.85,
        "y": 4.57
    },
    {
        "x": 6.86,
        "y": 4.59
    },
    {
        "x": 6.87,
        "y": 4.6
    },
    {
        "x": 6.88,
        "y": 4.62
    },
    {
        "x": 6.89,
        "y": 4.64
    },
    {
        "x": 6.9,
        "y": 4.66
    },
    {
        "x": 6.91,
        "y": 4.67
    },
    {
        "x": 6.92,
        "y": 4.69
    },
    {
        "x": 6.93,
        "y": 4.71
    },
    {
        "x": 6.94,
        "y": 4.72
    },
    {
        "x": 6.95,
        "y": 4.74
    },
    {
        "x": 6.96,
        "y": 4.75
    },
    {
        "x": 6.97,
        "y": 4.77
    },
    {
        "x": 6.98,
        "y": 4.79
    },
    {
        "x": 6.99,
        "y": 4.81
    },
    {
        "x": 7,
        "y": 4.83
    },
    {
        "x": 7.01,
        "y": 4.85
    },
    {
        "x": 7.02,
        "y": 4.86
    },
    {
        "x": 7.03,
        "y": 4.88
    },
    {
        "x": 7.04,
        "y": 4.9
    },
    {
        "x": 7.05,
        "y": 4.92
    },
    {
        "x": 7.06,
        "y": 4.94
    },
    {
        "x": 7.07,
        "y": 4.96
    },
    {
        "x": 7.08,
        "y": 4.97
    },
    {
        "x": 7.09,
        "y": 4.99
    },
    {
        "x": 7.1,
        "y": 5.01
    },
    {
        "x": 7.11,
        "y": 5.03
    },
    {
        "x": 7.12,
        "y": 5.05
    },
    {
        "x": 7.13,
        "y": 5.07
    },
    {
        "x": 7.14,
        "y": 5.08
    },
    {
        "x": 7.15,
        "y": 5.1
    },
    {
        "x": 7.16,
        "y": 5.12
    },
    {
        "x": 7.17,
        "y": 5.14
    },
    {
        "x": 7.18,
        "y": 5.16
    },
    {
        "x": 7.19,
        "y": 5.18
    },
    {
        "x": 7.2,
        "y": 5.2
    },
    {
        "x": 7.21,
        "y": 5.22
    },
    {
        "x": 7.22,
        "y": 5.24
    },
    {
        "x": 7.23,
        "y": 5.26
    },
    {
        "x": 7.24,
        "y": 5.28
    },
    {
        "x": 7.25,
        "y": 5.3
    },
    {
        "x": 7.26,
        "y": 5.32
    },
    {
        "x": 7.27,
        "y": 5.34
    },
    {
        "x": 7.28,
        "y": 5.36
    },
    {
        "x": 7.29,
        "y": 5.38
    },
    {
        "x": 7.3,
        "y": 5.4
    },
    {
        "x": 7.31,
        "y": 5.42
    },
    {
        "x": 7.32,
        "y": 5.44
    },
    {
        "x": 7.33,
        "y": 5.46
    },
    {
        "x": 7.34,
        "y": 5.48
    },
    {
        "x": 7.35,
        "y": 5.5
    },
    {
        "x": 7.36,
        "y": 5.52
    },
    {
        "x": 7.37,
        "y": 5.55
    },
    {
        "x": 7.38,
        "y": 5.57
    },
    {
        "x": 7.39,
        "y": 5.59
    },
    {
        "x": 7.4,
        "y": 5.61
    },
    {
        "x": 7.41,
        "y": 5.63
    },
    {
        "x": 7.42,
        "y": 5.65
    },
    {
        "x": 7.43,
        "y": 5.67
    },
    {
        "x": 7.44,
        "y": 5.69
    },
    {
        "x": 7.45,
        "y": 5.71
    },
    {
        "x": 7.46,
        "y": 5.74
    },
    {
        "x": 7.47,
        "y": 5.76
    },
    {
        "x": 7.48,
        "y": 5.78
    },
    {
        "x": 7.49,
        "y": 5.81
    },
    {
        "x": 7.5,
        "y": 5.82
    },
    {
        "x": 7.51,
        "y": 5.85
    },
    {
        "x": 7.52,
        "y": 5.87
    },
    {
        "x": 7.53,
        "y": 5.9
    },
    {
        "x": 7.54,
        "y": 5.91
    },
    {
        "x": 7.55,
        "y": 5.94
    },
    {
        "x": 7.56,
        "y": 5.96
    },
    {
        "x": 7.57,
        "y": 5.98
    },
    {
        "x": 7.58,
        "y": 6
    },
    {
        "x": 7.59,
        "y": 6.03
    },
    {
        "x": 7.6,
        "y": 6.05
    },
    {
        "x": 7.61,
        "y": 6.07
    },
    {
        "x": 7.62,
        "y": 6.1
    },
    {
        "x": 7.63,
        "y": 6.12
    },
    {
        "x": 7.64,
        "y": 6.14
    },
    {
        "x": 7.65,
        "y": 6.16
    },
    {
        "x": 7.66,
        "y": 6.19
    },
    {
        "x": 7.67,
        "y": 6.21
    },
    {
        "x": 7.68,
        "y": 6.23
    },
    {
        "x": 7.69,
        "y": 6.26
    },
    {
        "x": 7.7,
        "y": 6.28
    },
    {
        "x": 7.71,
        "y": 6.31
    },
    {
        "x": 7.72,
        "y": 6.33
    },
    {
        "x": 7.73,
        "y": 6.35
    },
    {
        "x": 7.74,
        "y": 6.38
    },
    {
        "x": 7.75,
        "y": 6.4
    },
    {
        "x": 7.76,
        "y": 6.42
    },
    {
        "x": 7.77,
        "y": 6.45
    },
    {
        "x": 7.78,
        "y": 6.47
    },
    {
        "x": 7.79,
        "y": 6.5
    },
    {
        "x": 7.8,
        "y": 6.52
    },
    {
        "x": 7.81,
        "y": 6.55
    },
    {
        "x": 7.82,
        "y": 6.57
    },
    {
        "x": 7.83,
        "y": 6.6
    },
    {
        "x": 7.84,
        "y": 6.62
    },
    {
        "x": 7.85,
        "y": 6.64
    },
    {
        "x": 7.86,
        "y": 6.67
    },
    {
        "x": 7.87,
        "y": 6.7
    },
    {
        "x": 7.88,
        "y": 6.72
    },
    {
        "x": 7.89,
        "y": 6.74
    },
    {
        "x": 7.9,
        "y": 6.77
    },
    {
        "x": 7.91,
        "y": 6.79
    },
    {
        "x": 7.92,
        "y": 6.82
    },
    {
        "x": 7.93,
        "y": 6.84
    },
    {
        "x": 7.94,
        "y": 6.87
    },
    {
        "x": 7.95,
        "y": 6.89
    },
    {
        "x": 7.96,
        "y": 6.92
    },
    {
        "x": 7.97,
        "y": 6.95
    },
    {
        "x": 7.98,
        "y": 6.97
    },
    {
        "x": 7.99,
        "y": 7
    },
    {
        "x": 8,
        "y": 7.02
    },
    {
        "x": 8.01,
        "y": 7.05
    },
    {
        "x": 8.02,
        "y": 7.07
    },
    {
        "x": 8.03,
        "y": 7.1
    },
    {
        "x": 8.04,
        "y": 7.12
    },
    {
        "x": 8.05,
        "y": 7.15
    },
    {
        "x": 8.06,
        "y": 7.18
    },
    {
        "x": 8.07,
        "y": 7.2
    },
    {
        "x": 8.08,
        "y": 7.23
    },
    {
        "x": 8.09,
        "y": 7.26
    },
    {
        "x": 8.1,
        "y": 7.28
    },
    {
        "x": 8.11,
        "y": 7.31
    },
    {
        "x": 8.12,
        "y": 7.34
    },
    {
        "x": 8.13,
        "y": 7.36
    },
    {
        "x": 8.14,
        "y": 7.39
    },
    {
        "x": 8.15,
        "y": 7.42
    },
    {
        "x": 8.16,
        "y": 7.44
    },
    {
        "x": 8.17,
        "y": 7.47
    },
    {
        "x": 8.18,
        "y": 7.5
    },
    {
        "x": 8.19,
        "y": 7.52
    },
    {
        "x": 8.2,
        "y": 7.55
    },
    {
        "x": 8.21,
        "y": 7.58
    },
    {
        "x": 8.22,
        "y": 7.6
    },
    {
        "x": 8.23,
        "y": 7.63
    },
    {
        "x": 8.24,
        "y": 7.66
    },
    {
        "x": 8.25,
        "y": 7.69
    },
    {
        "x": 8.26,
        "y": 7.72
    },
    {
        "x": 8.27,
        "y": 7.74
    },
    {
        "x": 8.28,
        "y": 7.77
    },
    {
        "x": 8.29,
        "y": 7.79
    },
    {
        "x": 8.3,
        "y": 7.82
    },
    {
        "x": 8.31,
        "y": 7.85
    },
    {
        "x": 8.32,
        "y": 7.88
    },
    {
        "x": 8.33,
        "y": 7.91
    },
    {
        "x": 8.34,
        "y": 7.93
    },
    {
        "x": 8.35,
        "y": 7.96
    },
    {
        "x": 8.36,
        "y": 7.99
    },
    {
        "x": 8.37,
        "y": 8.02
    },
    {
        "x": 8.38,
        "y": 8.04
    },
    {
        "x": 8.39,
        "y": 8.07
    },
    {
        "x": 8.4,
        "y": 8.1
    },
    {
        "x": 8.41,
        "y": 8.13
    },
    {
        "x": 8.42,
        "y": 8.16
    },
    {
        "x": 8.43,
        "y": 8.19
    },
    {
        "x": 8.44,
        "y": 8.21
    },
    {
        "x": 8.45,
        "y": 8.24
    },
    {
        "x": 8.46,
        "y": 8.27
    },
    {
        "x": 8.47,
        "y": 8.3
    },
    {
        "x": 8.48,
        "y": 8.33
    },
    {
        "x": 8.49,
        "y": 8.36
    },
    {
        "x": 8.5,
        "y": 8.39
    },
    {
        "x": 8.51,
        "y": 8.41
    },
    {
        "x": 8.52,
        "y": 8.45
    },
    {
        "x": 8.53,
        "y": 8.47
    },
    {
        "x": 8.54,
        "y": 8.5
    },
    {
        "x": 8.55,
        "y": 8.53
    },
    {
        "x": 8.56,
        "y": 8.56
    },
    {
        "x": 8.57,
        "y": 8.59
    },
    {
        "x": 8.58,
        "y": 8.62
    },
    {
        "x": 8.59,
        "y": 8.65
    },
    {
        "x": 8.6,
        "y": 8.68
    },
    {
        "x": 8.61,
        "y": 8.7
    },
    {
        "x": 8.62,
        "y": 8.74
    },
    {
        "x": 8.63,
        "y": 8.76
    },
    {
        "x": 8.64,
        "y": 8.79
    },
    {
        "x": 8.65,
        "y": 8.83
    },
    {
        "x": 8.66,
        "y": 8.85
    },
    {
        "x": 8.67,
        "y": 8.89
    },
    {
        "x": 8.68,
        "y": 8.91
    },
    {
        "x": 8.69,
        "y": 8.94
    },
    {
        "x": 8.7,
        "y": 8.97
    },
    {
        "x": 8.71,
        "y": 9
    },
    {
        "x": 8.72,
        "y": 9.03
    },
    {
        "x": 8.73,
        "y": 9.06
    },
    {
        "x": 8.74,
        "y": 9.09
    },
    {
        "x": 8.75,
        "y": 9.12
    },
    {
        "x": 8.76,
        "y": 9.15
    },
    {
        "x": 8.77,
        "y": 9.18
    },
    {
        "x": 8.78,
        "y": 9.21
    },
    {
        "x": 8.79,
        "y": 9.24
    },
    {
        "x": 8.8,
        "y": 9.27
    },
    {
        "x": 8.81,
        "y": 9.3
    },
    {
        "x": 8.82,
        "y": 9.33
    },
    {
        "x": 8.83,
        "y": 9.36
    },
    {
        "x": 8.84,
        "y": 9.39
    },
    {
        "x": 8.85,
        "y": 9.42
    },
    {
        "x": 8.86,
        "y": 9.45
    },
    {
        "x": 8.87,
        "y": 9.48
    },
    {
        "x": 8.88,
        "y": 9.51
    },
    {
        "x": 8.89,
        "y": 9.54
    },
    {
        "x": 8.9,
        "y": 9.57
    },
    {
        "x": 8.91,
        "y": 9.6
    },
    {
        "x": 8.92,
        "y": 9.63
    },
    {
        "x": 8.93,
        "y": 9.66
    },
    {
        "x": 8.94,
        "y": 9.69
    },
    {
        "x": 8.95,
        "y": 9.73
    },
    {
        "x": 8.96,
        "y": 9.76
    },
    {
        "x": 8.97,
        "y": 9.79
    },
    {
        "x": 8.98,
        "y": 9.82
    },
    {
        "x": 8.99,
        "y": 9.85
    },
    {
        "x": 9,
        "y": 9.88
    },
    {
        "x": 9.01,
        "y": 9.91
    },
    {
        "x": 9.02,
        "y": 9.94
    },
    {
        "x": 9.03,
        "y": 9.97
    },
    {
        "x": 9.04,
        "y": 10
    },
    {
        "x": 9.05,
        "y": 10.03
    },
    {
        "x": 9.06,
        "y": 10.06
    },
    {
        "x": 9.07,
        "y": 10.1
    },
    {
        "x": 9.08,
        "y": 10.12
    },
    {
        "x": 9.09,
        "y": 10.16
    },
    {
        "x": 9.1,
        "y": 10.19
    },
    {
        "x": 9.11,
        "y": 10.22
    },
    {
        "x": 9.12,
        "y": 10.25
    },
    {
        "x": 9.13,
        "y": 10.28
    },
    {
        "x": 9.14,
        "y": 10.31
    },
    {
        "x": 9.15,
        "y": 10.34
    },
    {
        "x": 9.16,
        "y": 10.37
    },
    {
        "x": 9.17,
        "y": 10.4
    },
    {
        "x": 9.18,
        "y": 10.43
    },
    {
        "x": 9.19,
        "y": 10.46
    },
    {
        "x": 9.2,
        "y": 10.49
    },
    {
        "x": 9.21,
        "y": 10.52
    },
    {
        "x": 9.22,
        "y": 10.55
    },
    {
        "x": 9.23,
        "y": 10.58
    },
    {
        "x": 9.24,
        "y": 10.62
    },
    {
        "x": 9.25,
        "y": 10.65
    },
    {
        "x": 9.26,
        "y": 10.68
    },
    {
        "x": 9.27,
        "y": 10.71
    },
    {
        "x": 9.28,
        "y": 10.74
    },
    {
        "x": 9.29,
        "y": 10.77
    },
    {
        "x": 9.3,
        "y": 10.8
    },
    {
        "x": 9.31,
        "y": 10.83
    },
    {
        "x": 9.32,
        "y": 10.86
    },
    {
        "x": 9.33,
        "y": 10.89
    },
    {
        "x": 9.34,
        "y": 10.92
    },
    {
        "x": 9.35,
        "y": 10.95
    },
    {
        "x": 9.36,
        "y": 10.98
    },
    {
        "x": 9.37,
        "y": 11.02
    },
    {
        "x": 9.38,
        "y": 11.05
    },
    {
        "x": 9.39,
        "y": 11.08
    },
    {
        "x": 9.4,
        "y": 11.11
    },
    {
        "x": 9.41,
        "y": 11.14
    },
    {
        "x": 9.42,
        "y": 11.17
    },
    {
        "x": 9.43,
        "y": 11.2
    },
    {
        "x": 9.44,
        "y": 11.23
    },
    {
        "x": 9.45,
        "y": 11.26
    },
    {
        "x": 9.46,
        "y": 11.29
    },
    {
        "x": 9.47,
        "y": 11.32
    },
    {
        "x": 9.48,
        "y": 11.35
    },
    {
        "x": 9.49,
        "y": 11.38
    },
    {
        "x": 9.5,
        "y": 11.41
    },
    {
        "x": 9.51,
        "y": 11.45
    },
    {
        "x": 9.52,
        "y": 11.48
    },
    {
        "x": 9.53,
        "y": 11.5
    },
    {
        "x": 9.54,
        "y": 11.53
    },
    {
        "x": 9.55,
        "y": 11.56
    },
    {
        "x": 9.56,
        "y": 11.6
    },
    {
        "x": 9.57,
        "y": 11.62
    },
    {
        "x": 9.58,
        "y": 11.66
    },
    {
        "x": 9.59,
        "y": 11.69
    },
    {
        "x": 9.6,
        "y": 11.72
    },
    {
        "x": 9.61,
        "y": 11.75
    },
    {
        "x": 9.62,
        "y": 11.78
    },
    {
        "x": 9.63,
        "y": 11.81
    },
    {
        "x": 9.64,
        "y": 11.84
    },
    {
        "x": 9.65,
        "y": 11.87
    },
    {
        "x": 9.66,
        "y": 11.9
    },
    {
        "x": 9.67,
        "y": 11.93
    },
    {
        "x": 9.68,
        "y": 11.96
    },
    {
        "x": 9.69,
        "y": 11.99
    },
    {
        "x": 9.7,
        "y": 12.02
    },
    {
        "x": 9.71,
        "y": 12.05
    },
    {
        "x": 9.72,
        "y": 12.08
    },
    {
        "x": 9.73,
        "y": 12.11
    },
    {
        "x": 9.74,
        "y": 12.14
    },
    {
        "x": 9.75,
        "y": 12.17
    },
    {
        "x": 9.76,
        "y": 12.2
    },
    {
        "x": 9.77,
        "y": 12.23
    },
    {
        "x": 9.78,
        "y": 12.26
    },
    {
        "x": 9.79,
        "y": 12.29
    },
    {
        "x": 9.8,
        "y": 12.32
    },
    {
        "x": 9.81,
        "y": 12.35
    },
    {
        "x": 9.82,
        "y": 12.38
    },
    {
        "x": 9.83,
        "y": 12.41
    },
    {
        "x": 9.84,
        "y": 12.44
    },
    {
        "x": 9.85,
        "y": 12.46
    },
    {
        "x": 9.86,
        "y": 12.49
    },
    {
        "x": 9.87,
        "y": 12.52
    },
    {
        "x": 9.88,
        "y": 12.55
    },
    {
        "x": 9.89,
        "y": 12.58
    },
    {
        "x": 9.9,
        "y": 12.61
    },
    {
        "x": 9.91,
        "y": 12.64
    },
    {
        "x": 9.92,
        "y": 12.67
    },
    {
        "x": 9.93,
        "y": 12.7
    },
    {
        "x": 9.94,
        "y": 12.73
    },
    {
        "x": 9.95,
        "y": 12.76
    },
    {
        "x": 9.96,
        "y": 12.78
    },
    {
        "x": 9.97,
        "y": 12.82
    },
    {
        "x": 9.98,
        "y": 12.84
    },
    {
        "x": 9.99,
        "y": 12.87
    },
    {
        "x": 10,
        "y": 12.9
    },
    {
        "x": 10.01,
        "y": 12.93
    },
    {
        "x": 10.02,
        "y": 12.96
    },
    {
        "x": 10.03,
        "y": 12.99
    },
    {
        "x": 10.04,
        "y": 13.01
    },
    {
        "x": 10.05,
        "y": 13.04
    },
    {
        "x": 10.06,
        "y": 13.07
    },
    {
        "x": 10.07,
        "y": 13.1
    },
    {
        "x": 10.08,
        "y": 13.13
    },
    {
        "x": 10.09,
        "y": 13.16
    },
    {
        "x": 10.1,
        "y": 13.18
    },
    {
        "x": 10.11,
        "y": 13.21
    },
    {
        "x": 10.12,
        "y": 13.24
    },
    {
        "x": 10.13,
        "y": 13.27
    },
    {
        "x": 10.14,
        "y": 13.3
    },
    {
        "x": 10.15,
        "y": 13.32
    },
    {
        "x": 10.16,
        "y": 13.35
    },
    {
        "x": 10.17,
        "y": 13.38
    },
    {
        "x": 10.18,
        "y": 13.41
    },
    {
        "x": 10.19,
        "y": 13.44
    },
    {
        "x": 10.2,
        "y": 13.46
    },
    {
        "x": 10.21,
        "y": 13.49
    },
    {
        "x": 10.22,
        "y": 13.52
    },
    {
        "x": 10.23,
        "y": 13.55
    },
    {
        "x": 10.24,
        "y": 13.57
    },
    {
        "x": 10.25,
        "y": 13.6
    },
    {
        "x": 10.26,
        "y": 13.63
    },
    {
        "x": 10.27,
        "y": 13.65
    },
    {
        "x": 10.28,
        "y": 13.68
    },
    {
        "x": 10.29,
        "y": 13.71
    },
    {
        "x": 10.3,
        "y": 13.74
    },
    {
        "x": 10.31,
        "y": 13.76
    },
    {
        "x": 10.32,
        "y": 13.79
    },
    {
        "x": 10.33,
        "y": 13.82
    },
    {
        "x": 10.34,
        "y": 13.84
    },
    {
        "x": 10.35,
        "y": 13.87
    },
    {
        "x": 10.36,
        "y": 13.9
    },
    {
        "x": 10.37,
        "y": 13.93
    },
    {
        "x": 10.38,
        "y": 13.95
    },
    {
        "x": 10.39,
        "y": 13.98
    },
    {
        "x": 10.4,
        "y": 14
    },
    {
        "x": 10.41,
        "y": 14.03
    },
    {
        "x": 10.42,
        "y": 14.06
    },
    {
        "x": 10.43,
        "y": 14.08
    },
    {
        "x": 10.44,
        "y": 14.11
    },
    {
        "x": 10.45,
        "y": 14.14
    },
    {
        "x": 10.46,
        "y": 14.16
    },
    {
        "x": 10.47,
        "y": 14.19
    },
    {
        "x": 10.48,
        "y": 14.21
    },
    {
        "x": 10.49,
        "y": 14.24
    },
    {
        "x": 10.5,
        "y": 14.27
    },
    {
        "x": 10.51,
        "y": 14.29
    },
    {
        "x": 10.52,
        "y": 14.32
    },
    {
        "x": 10.53,
        "y": 14.34
    },
    {
        "x": 10.54,
        "y": 14.37
    },
    {
        "x": 10.55,
        "y": 14.39
    },
    {
        "x": 10.56,
        "y": 14.42
    },
    {
        "x": 10.57,
        "y": 14.45
    },
    {
        "x": 10.58,
        "y": 14.47
    },
    {
        "x": 10.59,
        "y": 14.5
    },
    {
        "x": 10.6,
        "y": 14.52
    },
    {
        "x": 10.61,
        "y": 14.54
    },
    {
        "x": 10.62,
        "y": 14.57
    },
    {
        "x": 10.63,
        "y": 14.59
    },
    {
        "x": 10.64,
        "y": 14.62
    },
    {
        "x": 10.65,
        "y": 14.65
    },
    {
        "x": 10.66,
        "y": 14.67
    },
    {
        "x": 10.67,
        "y": 14.69
    },
    {
        "x": 10.68,
        "y": 14.72
    },
    {
        "x": 10.69,
        "y": 14.74
    },
    {
        "x": 10.7,
        "y": 14.77
    },
    {
        "x": 10.71,
        "y": 14.79
    },
    {
        "x": 10.72,
        "y": 14.82
    },
    {
        "x": 10.73,
        "y": 14.84
    },
    {
        "x": 10.74,
        "y": 14.86
    },
    {
        "x": 10.75,
        "y": 14.89
    },
    {
        "x": 10.76,
        "y": 14.91
    },
    {
        "x": 10.77,
        "y": 14.94
    },
    {
        "x": 10.78,
        "y": 14.96
    },
    {
        "x": 10.79,
        "y": 14.99
    },
    {
        "x": 10.8,
        "y": 15.01
    },
    {
        "x": 10.81,
        "y": 15.03
    },
    {
        "x": 10.82,
        "y": 15.06
    },
    {
        "x": 10.83,
        "y": 15.08
    },
    {
        "x": 10.84,
        "y": 15.1
    },
    {
        "x": 10.85,
        "y": 15.13
    },
    {
        "x": 10.86,
        "y": 15.15
    },
    {
        "x": 10.87,
        "y": 15.17
    },
    {
        "x": 10.88,
        "y": 15.2
    },
    {
        "x": 10.89,
        "y": 15.22
    },
    {
        "x": 10.9,
        "y": 15.25
    },
    {
        "x": 10.91,
        "y": 15.27
    },
    {
        "x": 10.92,
        "y": 15.29
    },
    {
        "x": 10.93,
        "y": 15.31
    },
    {
        "x": 10.94,
        "y": 15.33
    },
    {
        "x": 10.95,
        "y": 15.36
    },
    {
        "x": 10.96,
        "y": 15.38
    },
    {
        "x": 10.97,
        "y": 15.4
    },
    {
        "x": 10.98,
        "y": 15.42
    },
    {
        "x": 10.99,
        "y": 15.45
    },
    {
        "x": 11,
        "y": 15.47
    },
    {
        "x": 11.01,
        "y": 15.49
    },
    {
        "x": 11.02,
        "y": 15.51
    },
    {
        "x": 11.03,
        "y": 15.54
    },
    {
        "x": 11.04,
        "y": 15.56
    },
    {
        "x": 11.05,
        "y": 15.58
    },
    {
        "x": 11.06,
        "y": 15.6
    },
    {
        "x": 11.07,
        "y": 15.63
    },
    {
        "x": 11.08,
        "y": 15.65
    },
    {
        "x": 11.09,
        "y": 15.67
    },
    {
        "x": 11.1,
        "y": 15.69
    },
    {
        "x": 11.11,
        "y": 15.71
    },
    {
        "x": 11.12,
        "y": 15.74
    },
    {
        "x": 11.13,
        "y": 15.76
    },
    {
        "x": 11.14,
        "y": 15.78
    },
    {
        "x": 11.15,
        "y": 15.8
    },
    {
        "x": 11.16,
        "y": 15.82
    },
    {
        "x": 11.17,
        "y": 15.84
    },
    {
        "x": 11.18,
        "y": 15.86
    },
    {
        "x": 11.19,
        "y": 15.88
    },
    {
        "x": 11.2,
        "y": 15.91
    },
    {
        "x": 11.21,
        "y": 15.93
    },
    {
        "x": 11.22,
        "y": 15.95
    },
    {
        "x": 11.23,
        "y": 15.97
    },
    {
        "x": 11.24,
        "y": 15.99
    },
    {
        "x": 11.25,
        "y": 16.01
    },
    {
        "x": 11.26,
        "y": 16.03
    },
    {
        "x": 11.27,
        "y": 16.05
    },
    {
        "x": 11.28,
        "y": 16.08
    },
    {
        "x": 11.29,
        "y": 16.09
    },
    {
        "x": 11.3,
        "y": 16.12
    },
    {
        "x": 11.31,
        "y": 16.14
    },
    {
        "x": 11.32,
        "y": 16.16
    },
    {
        "x": 11.33,
        "y": 16.18
    },
    {
        "x": 11.34,
        "y": 16.2
    },
    {
        "x": 11.35,
        "y": 16.22
    },
    {
        "x": 11.36,
        "y": 16.24
    },
    {
        "x": 11.37,
        "y": 16.26
    },
    {
        "x": 11.38,
        "y": 16.28
    },
    {
        "x": 11.39,
        "y": 16.3
    },
    {
        "x": 11.4,
        "y": 16.32
    },
    {
        "x": 11.41,
        "y": 16.34
    },
    {
        "x": 11.42,
        "y": 16.36
    },
    {
        "x": 11.43,
        "y": 16.38
    },
    {
        "x": 11.44,
        "y": 16.4
    },
    {
        "x": 11.45,
        "y": 16.42
    },
    {
        "x": 11.46,
        "y": 16.43
    },
    {
        "x": 11.47,
        "y": 16.46
    },
    {
        "x": 11.48,
        "y": 16.48
    },
    {
        "x": 11.49,
        "y": 16.49
    },
    {
        "x": 11.5,
        "y": 16.51
    },
    {
        "x": 11.51,
        "y": 16.53
    },
    {
        "x": 11.52,
        "y": 16.55
    },
    {
        "x": 11.53,
        "y": 16.57
    },
    {
        "x": 11.54,
        "y": 16.59
    },
    {
        "x": 11.55,
        "y": 16.61
    },
    {
        "x": 11.56,
        "y": 16.63
    },
    {
        "x": 11.57,
        "y": 16.65
    },
    {
        "x": 11.58,
        "y": 16.66
    },
    {
        "x": 11.59,
        "y": 16.68
    },
    {
        "x": 11.6,
        "y": 16.7
    },
    {
        "x": 11.61,
        "y": 16.72
    },
    {
        "x": 11.62,
        "y": 16.74
    },
    {
        "x": 11.63,
        "y": 16.76
    },
    {
        "x": 11.64,
        "y": 16.78
    },
    {
        "x": 11.65,
        "y": 16.8
    },
    {
        "x": 11.66,
        "y": 16.81
    },
    {
        "x": 11.67,
        "y": 16.83
    },
    {
        "x": 11.68,
        "y": 16.85
    },
    {
        "x": 11.69,
        "y": 16.87
    },
    {
        "x": 11.7,
        "y": 16.89
    },
    {
        "x": 11.71,
        "y": 16.91
    },
    {
        "x": 11.72,
        "y": 16.92
    },
    {
        "x": 11.73,
        "y": 16.94
    },
    {
        "x": 11.74,
        "y": 16.96
    },
    {
        "x": 11.75,
        "y": 16.98
    },
    {
        "x": 11.76,
        "y": 17
    },
    {
        "x": 11.77,
        "y": 17.01
    },
    {
        "x": 11.78,
        "y": 17.03
    },
    {
        "x": 11.79,
        "y": 17.05
    },
    {
        "x": 11.8,
        "y": 17.07
    },
    {
        "x": 11.81,
        "y": 17.08
    },
    {
        "x": 11.82,
        "y": 17.1
    },
    {
        "x": 11.83,
        "y": 17.12
    },
    {
        "x": 11.84,
        "y": 17.14
    },
    {
        "x": 11.85,
        "y": 17.15
    },
    {
        "x": 11.86,
        "y": 17.17
    },
    {
        "x": 11.87,
        "y": 17.19
    },
    {
        "x": 11.88,
        "y": 17.21
    },
    {
        "x": 11.89,
        "y": 17.22
    },
    {
        "x": 11.9,
        "y": 17.24
    },
    {
        "x": 11.91,
        "y": 17.26
    },
    {
        "x": 11.92,
        "y": 17.27
    },
    {
        "x": 11.93,
        "y": 17.29
    },
    {
        "x": 11.94,
        "y": 17.3
    },
    {
        "x": 11.95,
        "y": 17.33
    },
    {
        "x": 11.96,
        "y": 17.34
    },
    {
        "x": 11.97,
        "y": 17.36
    },
    {
        "x": 11.98,
        "y": 17.38
    },
    {
        "x": 11.99,
        "y": 17.39
    },
    {
        "x": 12,
        "y": 17.41
    },
    {
        "x": 12.01,
        "y": 17.43
    },
    {
        "x": 12.02,
        "y": 17.44
    },
    {
        "x": 12.03,
        "y": 17.46
    },
    {
        "x": 12.04,
        "y": 17.47
    },
    {
        "x": 12.05,
        "y": 17.49
    },
    {
        "x": 12.06,
        "y": 17.51
    },
    {
        "x": 12.07,
        "y": 17.52
    },
    {
        "x": 12.08,
        "y": 17.54
    },
    {
        "x": 12.09,
        "y": 17.55
    },
    {
        "x": 12.1,
        "y": 17.57
    },
    {
        "x": 12.11,
        "y": 17.59
    },
    {
        "x": 12.12,
        "y": 17.6
    },
    {
        "x": 12.13,
        "y": 17.62
    },
    {
        "x": 12.14,
        "y": 17.64
    },
    {
        "x": 12.15,
        "y": 17.65
    },
    {
        "x": 12.16,
        "y": 17.67
    },
    {
        "x": 12.17,
        "y": 17.68
    },
    {
        "x": 12.18,
        "y": 17.7
    },
    {
        "x": 12.19,
        "y": 17.71
    },
    {
        "x": 12.2,
        "y": 17.73
    },
    {
        "x": 12.21,
        "y": 17.74
    },
    {
        "x": 12.22,
        "y": 17.76
    },
    {
        "x": 12.23,
        "y": 17.78
    },
    {
        "x": 12.24,
        "y": 17.79
    },
    {
        "x": 12.25,
        "y": 17.81
    },
    {
        "x": 12.26,
        "y": 17.82
    },
    {
        "x": 12.27,
        "y": 17.84
    },
    {
        "x": 12.28,
        "y": 17.85
    },
    {
        "x": 12.29,
        "y": 17.87
    },
    {
        "x": 12.3,
        "y": 17.88
    },
    {
        "x": 12.31,
        "y": 17.9
    },
    {
        "x": 12.32,
        "y": 17.91
    },
    {
        "x": 12.33,
        "y": 17.93
    },
    {
        "x": 12.34,
        "y": 17.94
    },
    {
        "x": 12.35,
        "y": 17.96
    },
    {
        "x": 12.36,
        "y": 17.98
    },
    {
        "x": 12.37,
        "y": 17.99
    },
    {
        "x": 12.38,
        "y": 18
    },
    {
        "x": 12.39,
        "y": 18.01
    },
    {
        "x": 12.4,
        "y": 18.03
    },
    {
        "x": 12.41,
        "y": 18.04
    },
    {
        "x": 12.42,
        "y": 18.06
    },
    {
        "x": 12.43,
        "y": 18.07
    },
    {
        "x": 12.44,
        "y": 18.09
    },
    {
        "x": 12.45,
        "y": 18.1
    },
    {
        "x": 12.46,
        "y": 18.12
    },
    {
        "x": 12.47,
        "y": 18.13
    },
    {
        "x": 12.48,
        "y": 18.14
    },
    {
        "x": 12.49,
        "y": 18.16
    },
    {
        "x": 12.5,
        "y": 18.17
    },
    {
        "x": 12.51,
        "y": 18.19
    },
    {
        "x": 12.52,
        "y": 18.2
    },
    {
        "x": 12.53,
        "y": 18.21
    },
    {
        "x": 12.54,
        "y": 18.23
    },
    {
        "x": 12.55,
        "y": 18.24
    },
    {
        "x": 12.56,
        "y": 18.26
    },
    {
        "x": 12.57,
        "y": 18.27
    },
    {
        "x": 12.58,
        "y": 18.28
    },
    {
        "x": 12.59,
        "y": 18.3
    },
    {
        "x": 12.6,
        "y": 18.31
    },
    {
        "x": 12.61,
        "y": 18.33
    },
    {
        "x": 12.62,
        "y": 18.34
    },
    {
        "x": 12.63,
        "y": 18.35
    },
    {
        "x": 12.64,
        "y": 18.37
    },
    {
        "x": 12.65,
        "y": 18.38
    },
    {
        "x": 12.66,
        "y": 18.4
    },
    {
        "x": 12.67,
        "y": 18.41
    },
    {
        "x": 12.68,
        "y": 18.42
    },
    {
        "x": 12.69,
        "y": 18.43
    },
    {
        "x": 12.7,
        "y": 18.45
    },
    {
        "x": 12.71,
        "y": 18.46
    },
    {
        "x": 12.72,
        "y": 18.47
    },
    {
        "x": 12.73,
        "y": 18.49
    },
    {
        "x": 12.74,
        "y": 18.5
    },
    {
        "x": 12.75,
        "y": 18.51
    },
    {
        "x": 12.76,
        "y": 18.53
    },
    {
        "x": 12.77,
        "y": 18.54
    },
    {
        "x": 12.78,
        "y": 18.55
    },
    {
        "x": 12.79,
        "y": 18.56
    },
    {
        "x": 12.8,
        "y": 18.58
    },
    {
        "x": 12.81,
        "y": 18.59
    },
    {
        "x": 12.82,
        "y": 18.6
    },
    {
        "x": 12.83,
        "y": 18.62
    },
    {
        "x": 12.84,
        "y": 18.63
    },
    {
        "x": 12.85,
        "y": 18.64
    },
    {
        "x": 12.86,
        "y": 18.66
    },
    {
        "x": 12.87,
        "y": 18.67
    },
    {
        "x": 12.88,
        "y": 18.68
    },
    {
        "x": 12.89,
        "y": 18.69
    },
    {
        "x": 12.9,
        "y": 18.71
    },
    {
        "x": 12.91,
        "y": 18.72
    },
    {
        "x": 12.92,
        "y": 18.73
    },
    {
        "x": 12.93,
        "y": 18.74
    },
    {
        "x": 12.94,
        "y": 18.76
    },
    {
        "x": 12.95,
        "y": 18.77
    },
    {
        "x": 12.96,
        "y": 18.78
    },
    {
        "x": 12.97,
        "y": 18.79
    },
    {
        "x": 12.98,
        "y": 18.81
    },
    {
        "x": 12.99,
        "y": 18.82
    },
    {
        "x": 13,
        "y": 18.83
    },
    {
        "x": 13.01,
        "y": 18.84
    },
    {
        "x": 13.02,
        "y": 18.85
    },
    {
        "x": 13.03,
        "y": 18.86
    },
    {
        "x": 13.04,
        "y": 18.88
    },
    {
        "x": 13.05,
        "y": 18.89
    },
    {
        "x": 13.06,
        "y": 18.9
    },
    {
        "x": 13.07,
        "y": 18.92
    },
    {
        "x": 13.08,
        "y": 18.93
    },
    {
        "x": 13.09,
        "y": 18.94
    },
    {
        "x": 13.1,
        "y": 18.95
    },
    {
        "x": 13.11,
        "y": 18.96
    },
    {
        "x": 13.12,
        "y": 18.97
    },
    {
        "x": 13.13,
        "y": 18.98
    },
    {
        "x": 13.14,
        "y": 19
    },
    {
        "x": 13.15,
        "y": 19.01
    },
    {
        "x": 13.16,
        "y": 19.02
    },
    {
        "x": 13.17,
        "y": 19.03
    },
    {
        "x": 13.18,
        "y": 19.05
    },
    {
        "x": 13.19,
        "y": 19.06
    },
    {
        "x": 13.2,
        "y": 19.07
    },
    {
        "x": 13.21,
        "y": 19.08
    },
    {
        "x": 13.22,
        "y": 19.09
    },
    {
        "x": 13.23,
        "y": 19.1
    },
    {
        "x": 13.24,
        "y": 19.11
    },
    {
        "x": 13.25,
        "y": 19.13
    },
    {
        "x": 13.26,
        "y": 19.14
    },
    {
        "x": 13.27,
        "y": 19.15
    },
    {
        "x": 13.28,
        "y": 19.16
    },
    {
        "x": 13.29,
        "y": 19.17
    },
    {
        "x": 13.3,
        "y": 19.18
    },
    {
        "x": 13.31,
        "y": 19.19
    },
    {
        "x": 13.32,
        "y": 19.21
    },
    {
        "x": 13.33,
        "y": 19.21
    },
    {
        "x": 13.34,
        "y": 19.23
    },
    {
        "x": 13.35,
        "y": 19.24
    },
    {
        "x": 13.36,
        "y": 19.25
    },
    {
        "x": 13.37,
        "y": 19.26
    },
    {
        "x": 13.38,
        "y": 19.27
    },
    {
        "x": 13.39,
        "y": 19.28
    },
    {
        "x": 13.4,
        "y": 19.3
    },
    {
        "x": 13.41,
        "y": 19.31
    },
    {
        "x": 13.42,
        "y": 19.32
    },
    {
        "x": 13.43,
        "y": 19.33
    },
    {
        "x": 13.44,
        "y": 19.34
    },
    {
        "x": 13.45,
        "y": 19.35
    },
    {
        "x": 13.46,
        "y": 19.36
    },
    {
        "x": 13.47,
        "y": 19.37
    },
    {
        "x": 13.48,
        "y": 19.38
    },
    {
        "x": 13.49,
        "y": 19.39
    },
    {
        "x": 13.5,
        "y": 19.4
    },
    {
        "x": 13.51,
        "y": 19.41
    },
    {
        "x": 13.52,
        "y": 19.42
    },
    {
        "x": 13.53,
        "y": 19.43
    },
    {
        "x": 13.54,
        "y": 19.45
    },
    {
        "x": 13.55,
        "y": 19.46
    },
    {
        "x": 13.56,
        "y": 19.47
    },
    {
        "x": 13.57,
        "y": 19.48
    },
    {
        "x": 13.58,
        "y": 19.48
    },
    {
        "x": 13.59,
        "y": 19.5
    },
    {
        "x": 13.6,
        "y": 19.51
    },
    {
        "x": 13.61,
        "y": 19.52
    },
    {
        "x": 13.62,
        "y": 19.53
    },
    {
        "x": 13.63,
        "y": 19.54
    },
    {
        "x": 13.64,
        "y": 19.55
    },
    {
        "x": 13.65,
        "y": 19.56
    },
    {
        "x": 13.66,
        "y": 19.57
    },
    {
        "x": 13.67,
        "y": 19.58
    },
    {
        "x": 13.68,
        "y": 19.59
    },
    {
        "x": 13.69,
        "y": 19.6
    },
    {
        "x": 13.7,
        "y": 19.61
    },
    {
        "x": 13.71,
        "y": 19.62
    },
    {
        "x": 13.72,
        "y": 19.64
    },
    {
        "x": 13.73,
        "y": 19.64
    },
    {
        "x": 13.74,
        "y": 19.65
    },
    {
        "x": 13.75,
        "y": 19.66
    },
    {
        "x": 13.76,
        "y": 19.67
    },
    {
        "x": 13.77,
        "y": 19.68
    },
    {
        "x": 13.78,
        "y": 19.69
    },
    {
        "x": 13.79,
        "y": 19.7
    },
    {
        "x": 13.8,
        "y": 19.71
    },
    {
        "x": 13.81,
        "y": 19.72
    },
    {
        "x": 13.82,
        "y": 19.73
    },
    {
        "x": 13.83,
        "y": 19.75
    },
    {
        "x": 13.84,
        "y": 19.75
    },
    {
        "x": 13.85,
        "y": 19.76
    },
    {
        "x": 13.86,
        "y": 19.77
    },
    {
        "x": 13.87,
        "y": 19.78
    },
    {
        "x": 13.88,
        "y": 19.79
    },
    {
        "x": 13.89,
        "y": 19.8
    },
    {
        "x": 13.9,
        "y": 19.81
    },
    {
        "x": 13.91,
        "y": 19.82
    },
    {
        "x": 13.92,
        "y": 19.83
    },
    {
        "x": 13.93,
        "y": 19.84
    },
    {
        "x": 13.94,
        "y": 19.85
    },
    {
        "x": 13.95,
        "y": 19.86
    },
    {
        "x": 13.96,
        "y": 19.87
    },
    {
        "x": 13.97,
        "y": 19.88
    },
    {
        "x": 13.98,
        "y": 19.89
    },
    {
        "x": 13.99,
        "y": 19.9
    },
    {
        "x": 14,
        "y": 19.91
    },
    {
        "x": 14.01,
        "y": 19.92
    },
    {
        "x": 14.02,
        "y": 19.93
    },
    {
        "x": 14.03,
        "y": 19.94
    },
    {
        "x": 14.04,
        "y": 19.94
    },
    {
        "x": 14.05,
        "y": 19.95
    },
    {
        "x": 14.06,
        "y": 19.96
    },
    {
        "x": 14.07,
        "y": 19.97
    },
    {
        "x": 14.08,
        "y": 19.98
    },
    {
        "x": 14.09,
        "y": 19.99
    },
    {
        "x": 14.1,
        "y": 20
    },
    {
        "x": 14.11,
        "y": 20.01
    },
    {
        "x": 14.12,
        "y": 20.02
    },
    {
        "x": 14.13,
        "y": 20.03
    },
    {
        "x": 14.14,
        "y": 20.04
    },
    {
        "x": 14.15,
        "y": 20.05
    },
    {
        "x": 14.16,
        "y": 20.06
    },
    {
        "x": 14.17,
        "y": 20.06
    },
    {
        "x": 14.18,
        "y": 20.08
    },
    {
        "x": 14.19,
        "y": 20.08
    },
    {
        "x": 14.2,
        "y": 20.09
    },
    {
        "x": 14.21,
        "y": 20.1
    },
    {
        "x": 14.22,
        "y": 20.11
    },
    {
        "x": 14.23,
        "y": 20.12
    },
    {
        "x": 14.24,
        "y": 20.13
    },
    {
        "x": 14.25,
        "y": 20.14
    },
    {
        "x": 14.26,
        "y": 20.15
    },
    {
        "x": 14.27,
        "y": 20.15
    },
    {
        "x": 14.28,
        "y": 20.16
    },
    {
        "x": 14.29,
        "y": 20.17
    },
    {
        "x": 14.3,
        "y": 20.18
    },
    {
        "x": 14.31,
        "y": 20.19
    },
    {
        "x": 14.32,
        "y": 20.2
    },
    {
        "x": 14.33,
        "y": 20.21
    },
    {
        "x": 14.34,
        "y": 20.22
    },
    {
        "x": 14.35,
        "y": 20.23
    },
    {
        "x": 14.36,
        "y": 20.24
    },
    {
        "x": 14.37,
        "y": 20.25
    },
    {
        "x": 14.38,
        "y": 20.25
    },
    {
        "x": 14.39,
        "y": 20.26
    },
    {
        "x": 14.4,
        "y": 20.27
    },
    {
        "x": 14.41,
        "y": 20.28
    },
    {
        "x": 14.42,
        "y": 20.29
    },
    {
        "x": 14.43,
        "y": 20.3
    },
    {
        "x": 14.44,
        "y": 20.3
    },
    {
        "x": 14.45,
        "y": 20.32
    },
    {
        "x": 14.46,
        "y": 20.33
    },
    {
        "x": 14.47,
        "y": 20.33
    },
    {
        "x": 14.48,
        "y": 20.34
    },
    {
        "x": 14.49,
        "y": 20.35
    },
    {
        "x": 14.5,
        "y": 20.36
    },
    {
        "x": 14.51,
        "y": 20.37
    },
    {
        "x": 14.52,
        "y": 20.38
    },
    {
        "x": 14.53,
        "y": 20.38
    },
    {
        "x": 14.54,
        "y": 20.39
    },
    {
        "x": 14.55,
        "y": 20.4
    },
    {
        "x": 14.56,
        "y": 20.41
    },
    {
        "x": 14.57,
        "y": 20.42
    },
    {
        "x": 14.58,
        "y": 20.42
    },
    {
        "x": 14.59,
        "y": 20.43
    },
    {
        "x": 14.6,
        "y": 20.44
    },
    {
        "x": 14.61,
        "y": 20.45
    },
    {
        "x": 14.62,
        "y": 20.46
    },
    {
        "x": 14.63,
        "y": 20.47
    },
    {
        "x": 14.64,
        "y": 20.47
    },
    {
        "x": 14.65,
        "y": 20.48
    },
    {
        "x": 14.66,
        "y": 20.49
    },
    {
        "x": 14.67,
        "y": 20.49
    },
    {
        "x": 14.68,
        "y": 20.5
    },
    {
        "x": 14.69,
        "y": 20.51
    },
    {
        "x": 14.7,
        "y": 20.52
    },
    {
        "x": 14.71,
        "y": 20.53
    },
    {
        "x": 14.72,
        "y": 20.54
    },
    {
        "x": 14.73,
        "y": 20.54
    },
    {
        "x": 14.74,
        "y": 20.55
    },
    {
        "x": 14.75,
        "y": 20.56
    },
    {
        "x": 14.76,
        "y": 20.57
    },
    {
        "x": 14.77,
        "y": 20.58
    },
    {
        "x": 14.78,
        "y": 20.58
    },
    {
        "x": 14.79,
        "y": 20.6
    },
    {
        "x": 14.8,
        "y": 20.6
    },
    {
        "x": 14.81,
        "y": 20.61
    },
    {
        "x": 14.82,
        "y": 20.62
    },
    {
        "x": 14.83,
        "y": 20.63
    },
    {
        "x": 14.84,
        "y": 20.64
    },
    {
        "x": 14.85,
        "y": 20.64
    },
    {
        "x": 14.86,
        "y": 20.65
    },
    {
        "x": 14.87,
        "y": 20.66
    },
    {
        "x": 14.88,
        "y": 20.67
    },
    {
        "x": 14.89,
        "y": 20.67
    },
    {
        "x": 14.9,
        "y": 20.68
    },
    {
        "x": 14.91,
        "y": 20.69
    },
    {
        "x": 14.92,
        "y": 20.7
    },
    {
        "x": 14.93,
        "y": 20.71
    },
    {
        "x": 14.94,
        "y": 20.71
    },
    {
        "x": 14.95,
        "y": 20.72
    },
    {
        "x": 14.96,
        "y": 20.73
    },
    {
        "x": 14.97,
        "y": 20.73
    },
    {
        "x": 14.98,
        "y": 20.74
    },
    {
        "x": 14.99,
        "y": 20.75
    },
    {
        "x": 15,
        "y": 20.76
    },
    {
        "x": 15.01,
        "y": 20.76
    },
    {
        "x": 15.02,
        "y": 20.77
    },
    {
        "x": 15.03,
        "y": 20.78
    },
    {
        "x": 15.04,
        "y": 20.79
    },
    {
        "x": 15.05,
        "y": 20.8
    },
    {
        "x": 15.06,
        "y": 20.81
    },
    {
        "x": 15.07,
        "y": 20.81
    },
    {
        "x": 15.08,
        "y": 20.82
    },
    {
        "x": 15.09,
        "y": 20.83
    },
    {
        "x": 15.1,
        "y": 20.84
    },
    {
        "x": 15.11,
        "y": 20.84
    },
    {
        "x": 15.12,
        "y": 20.85
    },
    {
        "x": 15.13,
        "y": 20.86
    },
    {
        "x": 15.14,
        "y": 20.86
    },
    {
        "x": 15.15,
        "y": 20.87
    },
    {
        "x": 15.16,
        "y": 20.88
    },
    {
        "x": 15.17,
        "y": 20.88
    },
    {
        "x": 15.18,
        "y": 20.89
    },
    {
        "x": 15.19,
        "y": 20.9
    },
    {
        "x": 15.2,
        "y": 20.91
    },
    {
        "x": 15.21,
        "y": 20.92
    },
    {
        "x": 15.22,
        "y": 20.92
    },
    {
        "x": 15.23,
        "y": 20.93
    },
    {
        "x": 15.24,
        "y": 20.94
    },
    {
        "x": 15.25,
        "y": 20.94
    },
    {
        "x": 15.26,
        "y": 20.95
    },
    {
        "x": 15.27,
        "y": 20.96
    },
    {
        "x": 15.28,
        "y": 20.97
    },
    {
        "x": 15.29,
        "y": 20.97
    },
    {
        "x": 15.3,
        "y": 20.98
    },
    {
        "x": 15.31,
        "y": 20.99
    },
    {
        "x": 15.32,
        "y": 20.99
    },
    {
        "x": 15.33,
        "y": 21
    },
    {
        "x": 15.34,
        "y": 21
    },
    {
        "x": 15.35,
        "y": 21.01
    },
    {
        "x": 15.36,
        "y": 21.02
    },
    {
        "x": 15.37,
        "y": 21.03
    },
    {
        "x": 15.38,
        "y": 21.04
    },
    {
        "x": 15.39,
        "y": 21.04
    },
    {
        "x": 15.4,
        "y": 21.05
    },
    {
        "x": 15.41,
        "y": 21.06
    },
    {
        "x": 15.42,
        "y": 21.07
    },
    {
        "x": 15.43,
        "y": 21.07
    },
    {
        "x": 15.44,
        "y": 21.08
    },
    {
        "x": 15.45,
        "y": 21.09
    },
    {
        "x": 15.46,
        "y": 21.09
    },
    {
        "x": 15.47,
        "y": 21.1
    },
    {
        "x": 15.48,
        "y": 21.11
    },
    {
        "x": 15.49,
        "y": 21.12
    },
    {
        "x": 15.5,
        "y": 21.12
    },
    {
        "x": 15.51,
        "y": 21.13
    },
    {
        "x": 15.52,
        "y": 21.14
    },
    {
        "x": 15.53,
        "y": 21.14
    },
    {
        "x": 15.54,
        "y": 21.15
    },
    {
        "x": 15.55,
        "y": 21.15
    },
    {
        "x": 15.56,
        "y": 21.16
    },
    {
        "x": 15.57,
        "y": 21.17
    },
    {
        "x": 15.58,
        "y": 21.18
    },
    {
        "x": 15.59,
        "y": 21.18
    },
    {
        "x": 15.6,
        "y": 21.19
    },
    {
        "x": 15.61,
        "y": 21.2
    },
    {
        "x": 15.62,
        "y": 21.2
    },
    {
        "x": 15.63,
        "y": 21.21
    },
    {
        "x": 15.64,
        "y": 21.21
    },
    {
        "x": 15.65,
        "y": 21.22
    },
    {
        "x": 15.66,
        "y": 21.23
    },
    {
        "x": 15.67,
        "y": 21.24
    },
    {
        "x": 15.68,
        "y": 21.24
    },
    {
        "x": 15.69,
        "y": 21.25
    },
    {
        "x": 15.7,
        "y": 21.25
    },
    {
        "x": 15.71,
        "y": 21.27
    },
    {
        "x": 15.72,
        "y": 21.27
    },
    {
        "x": 15.73,
        "y": 21.28
    },
    {
        "x": 15.74,
        "y": 21.28
    },
    {
        "x": 15.75,
        "y": 21.29
    },
    {
        "x": 15.76,
        "y": 21.3
    },
    {
        "x": 15.77,
        "y": 21.3
    },
    {
        "x": 15.78,
        "y": 21.31
    },
    {
        "x": 15.79,
        "y": 21.31
    },
    {
        "x": 15.8,
        "y": 21.32
    },
    {
        "x": 15.81,
        "y": 21.33
    },
    {
        "x": 15.82,
        "y": 21.34
    },
    {
        "x": 15.83,
        "y": 21.35
    },
    {
        "x": 15.84,
        "y": 21.35
    },
    {
        "x": 15.85,
        "y": 21.35
    },
    {
        "x": 15.86,
        "y": 21.36
    },
    {
        "x": 15.87,
        "y": 21.37
    },
    {
        "x": 15.88,
        "y": 21.38
    },
    {
        "x": 15.89,
        "y": 21.38
    },
    {
        "x": 15.9,
        "y": 21.39
    },
    {
        "x": 15.91,
        "y": 21.39
    },
    {
        "x": 15.92,
        "y": 21.4
    },
    {
        "x": 15.93,
        "y": 21.41
    },
    {
        "x": 15.94,
        "y": 21.41
    },
    {
        "x": 15.95,
        "y": 21.42
    },
    {
        "x": 15.96,
        "y": 21.42
    },
    {
        "x": 15.97,
        "y": 21.43
    },
    {
        "x": 15.98,
        "y": 21.44
    },
    {
        "x": 15.99,
        "y": 21.45
    },
    {
        "x": 16,
        "y": 21.45
    },
    {
        "x": 16.01,
        "y": 21.46
    },
    {
        "x": 16.02,
        "y": 21.46
    },
    {
        "x": 16.03,
        "y": 21.47
    },
    {
        "x": 16.04,
        "y": 21.48
    },
    {
        "x": 16.05,
        "y": 21.48
    },
    {
        "x": 16.06,
        "y": 21.49
    },
    {
        "x": 16.07,
        "y": 21.5
    },
    {
        "x": 16.08,
        "y": 21.5
    },
    {
        "x": 16.09,
        "y": 21.51
    },
    {
        "x": 16.1,
        "y": 21.51
    },
    {
        "x": 16.11,
        "y": 21.52
    },
    {
        "x": 16.12,
        "y": 21.53
    },
    {
        "x": 16.13,
        "y": 21.53
    },
    {
        "x": 16.14,
        "y": 21.54
    },
    {
        "x": 16.15,
        "y": 21.54
    },
    {
        "x": 16.16,
        "y": 21.55
    },
    {
        "x": 16.17,
        "y": 21.55
    },
    {
        "x": 16.18,
        "y": 21.56
    },
    {
        "x": 16.19,
        "y": 21.57
    },
    {
        "x": 16.2,
        "y": 21.58
    },
    {
        "x": 16.21,
        "y": 21.58
    },
    {
        "x": 16.22,
        "y": 21.59
    },
    {
        "x": 16.23,
        "y": 21.59
    },
    {
        "x": 16.24,
        "y": 21.6
    },
    {
        "x": 16.25,
        "y": 21.6
    },
    {
        "x": 16.26,
        "y": 21.61
    },
    {
        "x": 16.27,
        "y": 21.62
    },
    {
        "x": 16.28,
        "y": 21.62
    },
    {
        "x": 16.29,
        "y": 21.63
    },
    {
        "x": 16.3,
        "y": 21.64
    },
    {
        "x": 16.31,
        "y": 21.64
    },
    {
        "x": 16.32,
        "y": 21.65
    },
    {
        "x": 16.33,
        "y": 21.65
    },
    {
        "x": 16.34,
        "y": 21.66
    },
    {
        "x": 16.35,
        "y": 21.67
    },
    {
        "x": 16.36,
        "y": 21.67
    },
    {
        "x": 16.37,
        "y": 21.68
    },
    {
        "x": 16.38,
        "y": 21.68
    },
    {
        "x": 16.39,
        "y": 21.69
    },
    {
        "x": 16.4,
        "y": 21.69
    },
    {
        "x": 16.41,
        "y": 21.7
    },
    {
        "x": 16.42,
        "y": 21.71
    },
    {
        "x": 16.43,
        "y": 21.71
    },
    {
        "x": 16.44,
        "y": 21.72
    },
    {
        "x": 16.45,
        "y": 21.72
    },
    {
        "x": 16.46,
        "y": 21.73
    },
    {
        "x": 16.47,
        "y": 21.74
    },
    {
        "x": 16.48,
        "y": 21.74
    },
    {
        "x": 16.49,
        "y": 21.75
    },
    {
        "x": 16.5,
        "y": 21.75
    },
    {
        "x": 16.51,
        "y": 21.76
    },
    {
        "x": 16.52,
        "y": 21.77
    },
    {
        "x": 16.53,
        "y": 21.77
    },
    {
        "x": 16.54,
        "y": 21.78
    },
    {
        "x": 16.55,
        "y": 21.78
    },
    {
        "x": 16.56,
        "y": 21.79
    },
    {
        "x": 16.57,
        "y": 21.79
    },
    {
        "x": 16.58,
        "y": 21.8
    },
    {
        "x": 16.59,
        "y": 21.81
    },
    {
        "x": 16.6,
        "y": 21.81
    },
    {
        "x": 16.61,
        "y": 21.81
    },
    {
        "x": 16.62,
        "y": 21.82
    },
    {
        "x": 16.63,
        "y": 21.83
    },
    {
        "x": 16.64,
        "y": 21.83
    },
    {
        "x": 16.65,
        "y": 21.84
    },
    {
        "x": 16.66,
        "y": 21.84
    },
    {
        "x": 16.67,
        "y": 21.85
    },
    {
        "x": 16.68,
        "y": 21.86
    },
    {
        "x": 16.69,
        "y": 21.86
    },
    {
        "x": 16.7,
        "y": 21.87
    },
    {
        "x": 16.71,
        "y": 21.87
    },
    {
        "x": 16.72,
        "y": 21.88
    },
    {
        "x": 16.73,
        "y": 21.88
    },
    {
        "x": 16.74,
        "y": 21.89
    },
    {
        "x": 16.75,
        "y": 21.89
    },
    {
        "x": 16.76,
        "y": 21.9
    },
    {
        "x": 16.77,
        "y": 21.91
    },
    {
        "x": 16.78,
        "y": 21.91
    },
    {
        "x": 16.79,
        "y": 21.92
    },
    {
        "x": 16.8,
        "y": 21.92
    },
    {
        "x": 16.81,
        "y": 21.93
    },
    {
        "x": 16.82,
        "y": 21.93
    },
    {
        "x": 16.83,
        "y": 21.94
    },
    {
        "x": 16.84,
        "y": 21.94
    },
    {
        "x": 16.85,
        "y": 21.95
    },
    {
        "x": 16.86,
        "y": 21.96
    },
    {
        "x": 16.87,
        "y": 21.96
    },
    {
        "x": 16.88,
        "y": 21.96
    },
    {
        "x": 16.89,
        "y": 21.97
    },
    {
        "x": 16.9,
        "y": 21.97
    },
    {
        "x": 16.91,
        "y": 21.98
    },
    {
        "x": 16.92,
        "y": 21.99
    },
    {
        "x": 16.93,
        "y": 21.99
    },
    {
        "x": 16.94,
        "y": 22
    },
    {
        "x": 16.95,
        "y": 22
    },
    {
        "x": 16.96,
        "y": 22.01
    },
    {
        "x": 16.97,
        "y": 22.01
    },
    {
        "x": 16.98,
        "y": 22.02
    },
    {
        "x": 16.99,
        "y": 22.02
    },
    {
        "x": 17,
        "y": 22.03
    },
    {
        "x": 17.01,
        "y": 22.04
    },
    {
        "x": 17.02,
        "y": 22.04
    },
    {
        "x": 17.03,
        "y": 22.05
    },
    {
        "x": 17.04,
        "y": 22.05
    },
    {
        "x": 17.05,
        "y": 22.06
    },
    {
        "x": 17.06,
        "y": 22.06
    },
    {
        "x": 17.07,
        "y": 22.07
    },
    {
        "x": 17.08,
        "y": 22.07
    },
    {
        "x": 17.09,
        "y": 22.08
    },
    {
        "x": 17.1,
        "y": 22.08
    },
    {
        "x": 17.11,
        "y": 22.09
    },
    {
        "x": 17.12,
        "y": 22.09
    },
    {
        "x": 17.13,
        "y": 22.1
    },
    {
        "x": 17.14,
        "y": 22.1
    },
    {
        "x": 17.15,
        "y": 22.11
    },
    {
        "x": 17.16,
        "y": 22.11
    },
    {
        "x": 17.17,
        "y": 22.12
    },
    {
        "x": 17.18,
        "y": 22.13
    },
    {
        "x": 17.19,
        "y": 22.13
    },
    {
        "x": 17.2,
        "y": 22.14
    },
    {
        "x": 17.21,
        "y": 22.14
    },
    {
        "x": 17.22,
        "y": 22.15
    },
    {
        "x": 17.23,
        "y": 22.15
    },
    {
        "x": 17.24,
        "y": 22.16
    },
    {
        "x": 17.25,
        "y": 22.16
    },
    {
        "x": 17.26,
        "y": 22.16
    },
    {
        "x": 17.27,
        "y": 22.17
    },
    {
        "x": 17.28,
        "y": 22.18
    },
    {
        "x": 17.29,
        "y": 22.18
    },
    {
        "x": 17.3,
        "y": 22.19
    },
    {
        "x": 17.31,
        "y": 22.19
    },
    {
        "x": 17.32,
        "y": 22.2
    },
    {
        "x": 17.33,
        "y": 22.2
    },
    {
        "x": 17.34,
        "y": 22.2
    },
    {
        "x": 17.35,
        "y": 22.21
    },
    {
        "x": 17.36,
        "y": 22.22
    },
    {
        "x": 17.37,
        "y": 22.22
    },
    {
        "x": 17.38,
        "y": 22.22
    },
    {
        "x": 17.39,
        "y": 22.23
    },
    {
        "x": 17.4,
        "y": 22.24
    },
    {
        "x": 17.41,
        "y": 22.24
    },
    {
        "x": 17.42,
        "y": 22.25
    },
    {
        "x": 17.43,
        "y": 22.25
    },
    {
        "x": 17.44,
        "y": 22.26
    },
    {
        "x": 17.45,
        "y": 22.26
    },
    {
        "x": 17.46,
        "y": 22.27
    },
    {
        "x": 17.47,
        "y": 22.27
    },
    {
        "x": 17.48,
        "y": 22.28
    },
    {
        "x": 17.49,
        "y": 22.28
    },
    {
        "x": 17.5,
        "y": 22.28
    },
    {
        "x": 17.51,
        "y": 22.29
    },
    {
        "x": 17.52,
        "y": 22.29
    },
    {
        "x": 17.53,
        "y": 22.3
    },
    {
        "x": 17.54,
        "y": 22.3
    },
    {
        "x": 17.55,
        "y": 22.31
    },
    {
        "x": 17.56,
        "y": 22.31
    },
    {
        "x": 17.57,
        "y": 22.32
    },
    {
        "x": 17.58,
        "y": 22.32
    },
    {
        "x": 17.59,
        "y": 22.33
    },
    {
        "x": 17.6,
        "y": 22.33
    },
    {
        "x": 17.61,
        "y": 22.33
    },
    {
        "x": 17.62,
        "y": 22.34
    },
    {
        "x": 17.63,
        "y": 22.35
    },
    {
        "x": 17.64,
        "y": 22.35
    },
    {
        "x": 17.65,
        "y": 22.36
    },
    {
        "x": 17.66,
        "y": 22.36
    },
    {
        "x": 17.67,
        "y": 22.37
    },
    {
        "x": 17.68,
        "y": 22.37
    },
    {
        "x": 17.69,
        "y": 22.37
    },
    {
        "x": 17.7,
        "y": 22.38
    },
    {
        "x": 17.71,
        "y": 22.38
    },
    {
        "x": 17.72,
        "y": 22.39
    },
    {
        "x": 17.73,
        "y": 22.39
    },
    {
        "x": 17.74,
        "y": 22.4
    },
    {
        "x": 17.75,
        "y": 22.4
    },
    {
        "x": 17.76,
        "y": 22.41
    },
    {
        "x": 17.77,
        "y": 22.41
    },
    {
        "x": 17.78,
        "y": 22.41
    },
    {
        "x": 17.79,
        "y": 22.42
    },
    {
        "x": 17.8,
        "y": 22.42
    },
    {
        "x": 17.81,
        "y": 22.43
    },
    {
        "x": 17.82,
        "y": 22.43
    },
    {
        "x": 17.83,
        "y": 22.44
    },
    {
        "x": 17.84,
        "y": 22.44
    },
    {
        "x": 17.85,
        "y": 22.45
    },
    {
        "x": 17.86,
        "y": 22.45
    },
    {
        "x": 17.87,
        "y": 22.45
    },
    {
        "x": 17.88,
        "y": 22.46
    },
    {
        "x": 17.89,
        "y": 22.46
    },
    {
        "x": 17.9,
        "y": 22.47
    },
    {
        "x": 17.91,
        "y": 22.47
    },
    {
        "x": 17.92,
        "y": 22.48
    },
    {
        "x": 17.93,
        "y": 22.48
    },
    {
        "x": 17.94,
        "y": 22.48
    },
    {
        "x": 17.95,
        "y": 22.49
    },
    {
        "x": 17.96,
        "y": 22.5
    },
    {
        "x": 17.97,
        "y": 22.5
    },
    {
        "x": 17.98,
        "y": 22.5
    },
    {
        "x": 17.99,
        "y": 22.51
    },
    {
        "x": 18,
        "y": 22.51
    },
    {
        "x": 18.01,
        "y": 22.52
    },
    {
        "x": 18.02,
        "y": 22.52
    },
    {
        "x": 18.03,
        "y": 22.53
    },
    {
        "x": 18.04,
        "y": 22.53
    },
    {
        "x": 18.05,
        "y": 22.53
    },
    {
        "x": 18.06,
        "y": 22.54
    },
    {
        "x": 18.07,
        "y": 22.54
    },
    {
        "x": 18.08,
        "y": 22.55
    },
    {
        "x": 18.09,
        "y": 22.55
    },
    {
        "x": 18.1,
        "y": 22.56
    },
    {
        "x": 18.11,
        "y": 22.56
    },
    {
        "x": 18.12,
        "y": 22.57
    },
    {
        "x": 18.13,
        "y": 22.57
    },
    {
        "x": 18.14,
        "y": 22.58
    },
    {
        "x": 18.15,
        "y": 22.58
    },
    {
        "x": 18.16,
        "y": 22.58
    },
    {
        "x": 18.17,
        "y": 22.59
    },
    {
        "x": 18.18,
        "y": 22.59
    },
    {
        "x": 18.19,
        "y": 22.6
    },
    {
        "x": 18.2,
        "y": 22.6
    },
    {
        "x": 18.21,
        "y": 22.61
    },
    {
        "x": 18.22,
        "y": 22.61
    },
    {
        "x": 18.23,
        "y": 22.62
    },
    {
        "x": 18.24,
        "y": 22.62
    },
    {
        "x": 18.25,
        "y": 22.63
    },
    {
        "x": 18.26,
        "y": 22.63
    },
    {
        "x": 18.27,
        "y": 22.63
    },
    {
        "x": 18.28,
        "y": 22.64
    },
    {
        "x": 18.29,
        "y": 22.64
    },
    {
        "x": 18.3,
        "y": 22.65
    },
    {
        "x": 18.31,
        "y": 22.65
    },
    {
        "x": 18.32,
        "y": 22.65
    },
    {
        "x": 18.33,
        "y": 22.66
    },
    {
        "x": 18.34,
        "y": 22.66
    },
    {
        "x": 18.35,
        "y": 22.67
    },
    {
        "x": 18.36,
        "y": 22.68
    },
    {
        "x": 18.37,
        "y": 22.68
    },
    {
        "x": 18.38,
        "y": 22.68
    },
    {
        "x": 18.39,
        "y": 22.68
    },
    {
        "x": 18.4,
        "y": 22.69
    },
    {
        "x": 18.41,
        "y": 22.69
    },
    {
        "x": 18.42,
        "y": 22.69
    },
    {
        "x": 18.43,
        "y": 22.7
    },
    {
        "x": 18.44,
        "y": 22.7
    },
    {
        "x": 18.45,
        "y": 22.71
    },
    {
        "x": 18.46,
        "y": 22.71
    },
    {
        "x": 18.47,
        "y": 22.72
    },
    {
        "x": 18.48,
        "y": 22.72
    },
    {
        "x": 18.49,
        "y": 22.73
    },
    {
        "x": 18.5,
        "y": 22.73
    },
    {
        "x": 18.51,
        "y": 22.73
    },
    {
        "x": 18.52,
        "y": 22.74
    },
    {
        "x": 18.53,
        "y": 22.74
    },
    {
        "x": 18.54,
        "y": 22.75
    },
    {
        "x": 18.55,
        "y": 22.75
    },
    {
        "x": 18.56,
        "y": 22.75
    },
    {
        "x": 18.57,
        "y": 22.76
    },
    {
        "x": 18.58,
        "y": 22.76
    },
    {
        "x": 18.59,
        "y": 22.77
    },
    {
        "x": 18.6,
        "y": 22.77
    },
    {
        "x": 18.61,
        "y": 22.78
    },
    {
        "x": 18.62,
        "y": 22.78
    },
    {
        "x": 18.63,
        "y": 22.78
    },
    {
        "x": 18.64,
        "y": 22.79
    },
    {
        "x": 18.65,
        "y": 22.79
    },
    {
        "x": 18.66,
        "y": 22.8
    },
    {
        "x": 18.67,
        "y": 22.8
    },
    {
        "x": 18.68,
        "y": 22.81
    },
    {
        "x": 18.69,
        "y": 22.81
    },
    {
        "x": 18.7,
        "y": 22.81
    },
    {
        "x": 18.71,
        "y": 22.82
    },
    {
        "x": 18.72,
        "y": 22.82
    },
    {
        "x": 18.73,
        "y": 22.83
    },
    {
        "x": 18.74,
        "y": 22.83
    },
    {
        "x": 18.75,
        "y": 22.83
    },
    {
        "x": 18.76,
        "y": 22.84
    },
    {
        "x": 18.77,
        "y": 22.84
    },
    {
        "x": 18.78,
        "y": 22.84
    },
    {
        "x": 18.79,
        "y": 22.85
    },
    {
        "x": 18.8,
        "y": 22.85
    },
    {
        "x": 18.81,
        "y": 22.86
    },
    {
        "x": 18.82,
        "y": 22.86
    },
    {
        "x": 18.83,
        "y": 22.87
    },
    {
        "x": 18.84,
        "y": 22.87
    },
    {
        "x": 18.85,
        "y": 22.87
    },
    {
        "x": 18.86,
        "y": 22.88
    },
    {
        "x": 18.87,
        "y": 22.88
    },
    {
        "x": 18.88,
        "y": 22.89
    },
    {
        "x": 18.89,
        "y": 22.89
    },
    {
        "x": 18.9,
        "y": 22.89
    },
    {
        "x": 18.91,
        "y": 22.9
    },
    {
        "x": 18.92,
        "y": 22.9
    },
    {
        "x": 18.93,
        "y": 22.91
    },
    {
        "x": 18.94,
        "y": 22.91
    },
    {
        "x": 18.95,
        "y": 22.91
    },
    {
        "x": 18.96,
        "y": 22.91
    },
    {
        "x": 18.97,
        "y": 22.92
    },
    {
        "x": 18.98,
        "y": 22.92
    },
    {
        "x": 18.99,
        "y": 22.93
    },
    {
        "x": 19,
        "y": 22.93
    },
    {
        "x": 19.01,
        "y": 22.93
    },
    {
        "x": 19.02,
        "y": 22.94
    },
    {
        "x": 19.03,
        "y": 22.94
    },
    {
        "x": 19.04,
        "y": 22.95
    },
    {
        "x": 19.05,
        "y": 22.95
    },
    {
        "x": 19.06,
        "y": 22.95
    },
    {
        "x": 19.07,
        "y": 22.96
    },
    {
        "x": 19.08,
        "y": 22.96
    },
    {
        "x": 19.09,
        "y": 22.96
    },
    {
        "x": 19.1,
        "y": 22.97
    },
    {
        "x": 19.11,
        "y": 22.97
    },
    {
        "x": 19.12,
        "y": 22.98
    },
    {
        "x": 19.13,
        "y": 22.98
    },
    {
        "x": 19.14,
        "y": 22.99
    },
    {
        "x": 19.15,
        "y": 22.99
    },
    {
        "x": 19.16,
        "y": 22.99
    },
    {
        "x": 19.17,
        "y": 23
    },
    {
        "x": 19.18,
        "y": 23
    },
    {
        "x": 19.19,
        "y": 23
    },
    {
        "x": 19.2,
        "y": 23.01
    },
    {
        "x": 19.21,
        "y": 23.01
    },
    {
        "x": 19.22,
        "y": 23.02
    },
    {
        "x": 19.23,
        "y": 23.02
    },
    {
        "x": 19.24,
        "y": 23.02
    },
    {
        "x": 19.25,
        "y": 23.03
    },
    {
        "x": 19.26,
        "y": 23.03
    },
    {
        "x": 19.27,
        "y": 23.03
    },
    {
        "x": 19.28,
        "y": 23.04
    },
    {
        "x": 19.29,
        "y": 23.04
    },
    {
        "x": 19.3,
        "y": 23.04
    },
    {
        "x": 19.31,
        "y": 23.05
    },
    {
        "x": 19.32,
        "y": 23.05
    },
    {
        "x": 19.33,
        "y": 23.05
    },
    {
        "x": 19.34,
        "y": 23.06
    },
    {
        "x": 19.35,
        "y": 23.06
    },
    {
        "x": 19.36,
        "y": 23.06
    },
    {
        "x": 19.37,
        "y": 23.07
    },
    {
        "x": 19.38,
        "y": 23.07
    },
    {
        "x": 19.39,
        "y": 23.08
    },
    {
        "x": 19.4,
        "y": 23.08
    },
    {
        "x": 19.41,
        "y": 23.08
    },
    {
        "x": 19.42,
        "y": 23.09
    },
    {
        "x": 19.43,
        "y": 23.09
    },
    {
        "x": 19.44,
        "y": 23.09
    },
    {
        "x": 19.45,
        "y": 23.1
    },
    {
        "x": 19.46,
        "y": 23.1
    },
    {
        "x": 19.47,
        "y": 23.11
    },
    {
        "x": 19.48,
        "y": 23.11
    },
    {
        "x": 19.49,
        "y": 23.11
    },
    {
        "x": 19.5,
        "y": 23.11
    },
    {
        "x": 19.51,
        "y": 23.12
    },
    {
        "x": 19.52,
        "y": 23.12
    },
    {
        "x": 19.53,
        "y": 23.13
    },
    {
        "x": 19.54,
        "y": 23.13
    },
    {
        "x": 19.55,
        "y": 23.13
    },
    {
        "x": 19.56,
        "y": 23.14
    },
    {
        "x": 19.57,
        "y": 23.14
    },
    {
        "x": 19.58,
        "y": 23.15
    },
    {
        "x": 19.59,
        "y": 23.15
    },
    {
        "x": 19.6,
        "y": 23.15
    },
    {
        "x": 19.61,
        "y": 23.15
    },
    {
        "x": 19.62,
        "y": 23.16
    },
    {
        "x": 19.63,
        "y": 23.17
    },
    {
        "x": 19.64,
        "y": 23.17
    },
    {
        "x": 19.65,
        "y": 23.17
    },
    {
        "x": 19.66,
        "y": 23.18
    },
    {
        "x": 19.67,
        "y": 23.18
    },
    {
        "x": 19.68,
        "y": 23.18
    },
    {
        "x": 19.69,
        "y": 23.19
    },
    {
        "x": 19.7,
        "y": 23.19
    },
    {
        "x": 19.71,
        "y": 23.19
    },
    {
        "x": 19.72,
        "y": 23.2
    },
    {
        "x": 19.73,
        "y": 23.2
    },
    {
        "x": 19.74,
        "y": 23.2
    },
    {
        "x": 19.75,
        "y": 23.21
    },
    {
        "x": 19.76,
        "y": 23.21
    },
    {
        "x": 19.77,
        "y": 23.21
    },
    {
        "x": 19.78,
        "y": 23.22
    },
    {
        "x": 19.79,
        "y": 23.22
    },
    {
        "x": 19.8,
        "y": 23.22
    },
    {
        "x": 19.81,
        "y": 23.23
    },
    {
        "x": 19.82,
        "y": 23.23
    },
    {
        "x": 19.83,
        "y": 23.23
    },
    {
        "x": 19.84,
        "y": 23.24
    },
    {
        "x": 19.85,
        "y": 23.24
    },
    {
        "x": 19.86,
        "y": 23.25
    },
    {
        "x": 19.87,
        "y": 23.25
    },
    {
        "x": 19.88,
        "y": 23.25
    },
    {
        "x": 19.89,
        "y": 23.25
    },
    {
        "x": 19.9,
        "y": 23.26
    },
    {
        "x": 19.91,
        "y": 23.26
    },
    {
        "x": 19.92,
        "y": 23.26
    },
    {
        "x": 19.93,
        "y": 23.27
    },
    {
        "x": 19.94,
        "y": 23.27
    },
    {
        "x": 19.95,
        "y": 23.28
    },
    {
        "x": 19.96,
        "y": 23.28
    },
    {
        "x": 19.97,
        "y": 23.28
    },
    {
        "x": 19.98,
        "y": 23.28
    },
    {
        "x": 19.99,
        "y": 23.29
    },
    {
        "x": 20,
        "y": 23.29
    },
    {
        "x": 20.01,
        "y": 23.3
    },
    {
        "x": 20.02,
        "y": 23.3
    },
    {
        "x": 20.03,
        "y": 23.3
    },
    {
        "x": 20.04,
        "y": 23.31
    },
    {
        "x": 20.05,
        "y": 23.31
    },
    {
        "x": 20.06,
        "y": 23.31
    },
    {
        "x": 20.07,
        "y": 23.31
    },
    {
        "x": 20.08,
        "y": 23.32
    },
    {
        "x": 20.09,
        "y": 23.32
    },
    {
        "x": 20.1,
        "y": 23.33
    },
    {
        "x": 20.11,
        "y": 23.33
    },
    {
        "x": 20.12,
        "y": 23.33
    },
    {
        "x": 20.13,
        "y": 23.34
    },
    {
        "x": 20.14,
        "y": 23.34
    },
    {
        "x": 20.15,
        "y": 23.34
    },
    {
        "x": 20.16,
        "y": 23.34
    },
    {
        "x": 20.17,
        "y": 23.35
    },
    {
        "x": 20.18,
        "y": 23.35
    },
    {
        "x": 20.19,
        "y": 23.35
    },
    {
        "x": 20.2,
        "y": 23.36
    },
    {
        "x": 20.21,
        "y": 23.36
    },
    {
        "x": 20.22,
        "y": 23.36
    },
    {
        "x": 20.23,
        "y": 23.36
    },
    {
        "x": 20.24,
        "y": 23.37
    },
    {
        "x": 20.25,
        "y": 23.37
    },
    {
        "x": 20.26,
        "y": 23.37
    },
    {
        "x": 20.27,
        "y": 23.38
    },
    {
        "x": 20.28,
        "y": 23.38
    },
    {
        "x": 20.29,
        "y": 23.38
    },
    {
        "x": 20.3,
        "y": 23.38
    },
    {
        "x": 20.31,
        "y": 23.39
    },
    {
        "x": 20.32,
        "y": 23.4
    },
    {
        "x": 20.33,
        "y": 23.4
    },
    {
        "x": 20.34,
        "y": 23.4
    },
    {
        "x": 20.35,
        "y": 23.4
    },
    {
        "x": 20.36,
        "y": 23.41
    },
    {
        "x": 20.37,
        "y": 23.41
    },
    {
        "x": 20.38,
        "y": 23.41
    },
    {
        "x": 20.39,
        "y": 23.41
    },
    {
        "x": 20.4,
        "y": 23.42
    },
    {
        "x": 20.41,
        "y": 23.42
    },
    {
        "x": 20.42,
        "y": 23.43
    },
    {
        "x": 20.43,
        "y": 23.43
    },
    {
        "x": 20.44,
        "y": 23.43
    },
    {
        "x": 20.45,
        "y": 23.43
    },
    {
        "x": 20.46,
        "y": 23.44
    },
    {
        "x": 20.47,
        "y": 23.44
    },
    {
        "x": 20.48,
        "y": 23.45
    },
    {
        "x": 20.49,
        "y": 23.45
    },
    {
        "x": 20.5,
        "y": 23.45
    },
    {
        "x": 20.51,
        "y": 23.45
    },
    {
        "x": 20.52,
        "y": 23.46
    },
    {
        "x": 20.53,
        "y": 23.46
    },
    {
        "x": 20.54,
        "y": 23.46
    },
    {
        "x": 20.55,
        "y": 23.47
    },
    {
        "x": 20.56,
        "y": 23.47
    },
    {
        "x": 20.57,
        "y": 23.47
    },
    {
        "x": 20.58,
        "y": 23.48
    },
    {
        "x": 20.59,
        "y": 23.48
    },
    {
        "x": 20.6,
        "y": 23.48
    },
    {
        "x": 20.61,
        "y": 23.48
    },
    {
        "x": 20.62,
        "y": 23.49
    },
    {
        "x": 20.63,
        "y": 23.49
    },
    {
        "x": 20.64,
        "y": 23.49
    },
    {
        "x": 20.65,
        "y": 23.5
    },
    {
        "x": 20.66,
        "y": 23.5
    },
    {
        "x": 20.67,
        "y": 23.5
    },
    {
        "x": 20.68,
        "y": 23.51
    },
    {
        "x": 20.69,
        "y": 23.51
    },
    {
        "x": 20.7,
        "y": 23.51
    },
    {
        "x": 20.71,
        "y": 23.52
    },
    {
        "x": 20.72,
        "y": 23.52
    },
    {
        "x": 20.73,
        "y": 23.52
    },
    {
        "x": 20.74,
        "y": 23.53
    },
    {
        "x": 20.75,
        "y": 23.53
    },
    {
        "x": 20.76,
        "y": 23.53
    },
    {
        "x": 20.77,
        "y": 23.54
    },
    {
        "x": 20.78,
        "y": 23.54
    },
    {
        "x": 20.79,
        "y": 23.54
    },
    {
        "x": 20.8,
        "y": 23.55
    },
    {
        "x": 20.81,
        "y": 23.55
    },
    {
        "x": 20.82,
        "y": 23.55
    },
    {
        "x": 20.83,
        "y": 23.55
    },
    {
        "x": 20.84,
        "y": 23.55
    },
    {
        "x": 20.85,
        "y": 23.56
    },
    {
        "x": 20.86,
        "y": 23.56
    },
    {
        "x": 20.87,
        "y": 23.57
    },
    {
        "x": 20.88,
        "y": 23.57
    },
    {
        "x": 20.89,
        "y": 23.57
    },
    {
        "x": 20.9,
        "y": 23.57
    },
    {
        "x": 20.91,
        "y": 23.58
    },
    {
        "x": 20.92,
        "y": 23.58
    },
    {
        "x": 20.93,
        "y": 23.58
    },
    {
        "x": 20.94,
        "y": 23.59
    },
    {
        "x": 20.95,
        "y": 23.59
    },
    {
        "x": 20.96,
        "y": 23.59
    },
    {
        "x": 20.97,
        "y": 23.59
    },
    {
        "x": 20.98,
        "y": 23.6
    },
    {
        "x": 20.99,
        "y": 23.6
    },
    {
        "x": 21,
        "y": 23.6
    },
    {
        "x": 21.01,
        "y": 23.61
    },
    {
        "x": 21.02,
        "y": 23.61
    },
    {
        "x": 21.03,
        "y": 23.61
    },
    {
        "x": 21.04,
        "y": 23.62
    },
    {
        "x": 21.05,
        "y": 23.62
    },
    {
        "x": 21.06,
        "y": 23.62
    },
    {
        "x": 21.07,
        "y": 23.62
    },
    {
        "x": 21.08,
        "y": 23.63
    },
    {
        "x": 21.09,
        "y": 23.63
    },
    {
        "x": 21.1,
        "y": 23.63
    },
    {
        "x": 21.11,
        "y": 23.63
    },
    {
        "x": 21.12,
        "y": 23.64
    },
    {
        "x": 21.13,
        "y": 23.64
    },
    {
        "x": 21.14,
        "y": 23.64
    },
    {
        "x": 21.15,
        "y": 23.65
    },
    {
        "x": 21.16,
        "y": 23.65
    },
    {
        "x": 21.17,
        "y": 23.65
    },
    {
        "x": 21.18,
        "y": 23.65
    },
    {
        "x": 21.19,
        "y": 23.66
    },
    {
        "x": 21.2,
        "y": 23.66
    },
    {
        "x": 21.21,
        "y": 23.66
    },
    {
        "x": 21.22,
        "y": 23.67
    },
    {
        "x": 21.23,
        "y": 23.67
    },
    {
        "x": 21.24,
        "y": 23.67
    },
    {
        "x": 21.25,
        "y": 23.68
    },
    {
        "x": 21.26,
        "y": 23.68
    },
    {
        "x": 21.27,
        "y": 23.68
    },
    {
        "x": 21.28,
        "y": 23.69
    },
    {
        "x": 21.29,
        "y": 23.69
    },
    {
        "x": 21.3,
        "y": 23.69
    },
    {
        "x": 21.31,
        "y": 23.69
    },
    {
        "x": 21.32,
        "y": 23.7
    },
    {
        "x": 21.33,
        "y": 23.7
    },
    {
        "x": 21.34,
        "y": 23.7
    },
    {
        "x": 21.35,
        "y": 23.7
    },
    {
        "x": 21.36,
        "y": 23.7
    },
    {
        "x": 21.37,
        "y": 23.71
    },
    {
        "x": 21.38,
        "y": 23.71
    },
    {
        "x": 21.39,
        "y": 23.71
    },
    {
        "x": 21.4,
        "y": 23.72
    },
    {
        "x": 21.41,
        "y": 23.72
    },
    {
        "x": 21.42,
        "y": 23.72
    },
    {
        "x": 21.43,
        "y": 23.72
    },
    {
        "x": 21.44,
        "y": 23.73
    },
    {
        "x": 21.45,
        "y": 23.73
    },
    {
        "x": 21.46,
        "y": 23.74
    },
    {
        "x": 21.47,
        "y": 23.74
    },
    {
        "x": 21.48,
        "y": 23.74
    },
    {
        "x": 21.49,
        "y": 23.74
    },
    {
        "x": 21.5,
        "y": 23.74
    },
    {
        "x": 21.51,
        "y": 23.75
    },
    {
        "x": 21.52,
        "y": 23.75
    },
    {
        "x": 21.53,
        "y": 23.75
    },
    {
        "x": 21.54,
        "y": 23.76
    },
    {
        "x": 21.55,
        "y": 23.76
    },
    {
        "x": 21.56,
        "y": 23.76
    },
    {
        "x": 21.57,
        "y": 23.76
    },
    {
        "x": 21.58,
        "y": 23.76
    },
    {
        "x": 21.59,
        "y": 23.77
    },
    {
        "x": 21.6,
        "y": 23.77
    },
    {
        "x": 21.61,
        "y": 23.77
    },
    {
        "x": 21.62,
        "y": 23.77
    },
    {
        "x": 21.63,
        "y": 23.77
    },
    {
        "x": 21.64,
        "y": 23.78
    },
    {
        "x": 21.65,
        "y": 23.78
    },
    {
        "x": 21.66,
        "y": 23.79
    },
    {
        "x": 21.67,
        "y": 23.79
    },
    {
        "x": 21.68,
        "y": 23.79
    },
    {
        "x": 21.69,
        "y": 23.79
    },
    {
        "x": 21.7,
        "y": 23.8
    },
    {
        "x": 21.71,
        "y": 23.8
    },
    {
        "x": 21.72,
        "y": 23.8
    },
    {
        "x": 21.73,
        "y": 23.8
    },
    {
        "x": 21.74,
        "y": 23.81
    },
    {
        "x": 21.75,
        "y": 23.81
    },
    {
        "x": 21.76,
        "y": 23.81
    },
    {
        "x": 21.77,
        "y": 23.81
    },
    {
        "x": 21.78,
        "y": 23.82
    },
    {
        "x": 21.79,
        "y": 23.82
    },
    {
        "x": 21.8,
        "y": 23.82
    },
    {
        "x": 21.81,
        "y": 23.82
    },
    {
        "x": 21.82,
        "y": 23.83
    },
    {
        "x": 21.83,
        "y": 23.83
    },
    {
        "x": 21.84,
        "y": 23.83
    },
    {
        "x": 21.85,
        "y": 23.83
    },
    {
        "x": 21.86,
        "y": 23.84
    },
    {
        "x": 21.87,
        "y": 23.84
    },
    {
        "x": 21.88,
        "y": 23.84
    },
    {
        "x": 21.89,
        "y": 23.85
    },
    {
        "x": 21.9,
        "y": 23.85
    },
    {
        "x": 21.91,
        "y": 23.85
    },
    {
        "x": 21.92,
        "y": 23.85
    },
    {
        "x": 21.93,
        "y": 23.85
    },
    {
        "x": 21.94,
        "y": 23.86
    },
    {
        "x": 21.95,
        "y": 23.86
    },
    {
        "x": 21.96,
        "y": 23.86
    },
    {
        "x": 21.97,
        "y": 23.87
    },
    {
        "x": 21.98,
        "y": 23.87
    },
    {
        "x": 21.99,
        "y": 23.87
    },
    {
        "x": 22,
        "y": 23.87
    },
    {
        "x": 22.01,
        "y": 23.88
    },
    {
        "x": 22.02,
        "y": 23.88
    },
    {
        "x": 22.03,
        "y": 23.88
    },
    {
        "x": 22.04,
        "y": 23.88
    },
    {
        "x": 22.05,
        "y": 23.89
    },
    {
        "x": 22.06,
        "y": 23.89
    },
    {
        "x": 22.07,
        "y": 23.89
    },
    {
        "x": 22.08,
        "y": 23.9
    },
    {
        "x": 22.09,
        "y": 23.9
    },
    {
        "x": 22.1,
        "y": 23.9
    },
    {
        "x": 22.11,
        "y": 23.9
    },
    {
        "x": 22.12,
        "y": 23.91
    },
    {
        "x": 22.13,
        "y": 23.91
    },
    {
        "x": 22.14,
        "y": 23.91
    },
    {
        "x": 22.15,
        "y": 23.91
    },
    {
        "x": 22.16,
        "y": 23.91
    },
    {
        "x": 22.17,
        "y": 23.92
    },
    {
        "x": 22.18,
        "y": 23.92
    },
    {
        "x": 22.19,
        "y": 23.92
    },
    {
        "x": 22.2,
        "y": 23.92
    },
    {
        "x": 22.21,
        "y": 23.93
    },
    {
        "x": 22.22,
        "y": 23.93
    },
    {
        "x": 22.23,
        "y": 23.93
    },
    {
        "x": 22.24,
        "y": 23.94
    },
    {
        "x": 22.25,
        "y": 23.94
    },
    {
        "x": 22.26,
        "y": 23.94
    },
    {
        "x": 22.27,
        "y": 23.94
    },
    {
        "x": 22.28,
        "y": 23.94
    },
    {
        "x": 22.29,
        "y": 23.95
    },
    {
        "x": 22.3,
        "y": 23.95
    },
    {
        "x": 22.31,
        "y": 23.95
    },
    {
        "x": 22.32,
        "y": 23.95
    },
    {
        "x": 22.33,
        "y": 23.96
    },
    {
        "x": 22.34,
        "y": 23.96
    },
    {
        "x": 22.35,
        "y": 23.96
    },
    {
        "x": 22.36,
        "y": 23.96
    },
    {
        "x": 22.37,
        "y": 23.97
    },
    {
        "x": 22.38,
        "y": 23.97
    },
    {
        "x": 22.39,
        "y": 23.97
    },
    {
        "x": 22.4,
        "y": 23.98
    },
    {
        "x": 22.41,
        "y": 23.98
    },
    {
        "x": 22.42,
        "y": 23.98
    },
    {
        "x": 22.43,
        "y": 23.98
    },
    {
        "x": 22.44,
        "y": 23.98
    },
    {
        "x": 22.45,
        "y": 23.99
    },
    {
        "x": 22.46,
        "y": 23.99
    },
    {
        "x": 22.47,
        "y": 23.99
    },
    {
        "x": 22.48,
        "y": 23.99
    },
    {
        "x": 22.49,
        "y": 23.99
    },
    {
        "x": 22.5,
        "y": 24
    },
    {
        "x": 22.51,
        "y": 24
    },
    {
        "x": 22.52,
        "y": 24.01
    },
    {
        "x": 22.53,
        "y": 24.01
    },
    {
        "x": 22.54,
        "y": 24.01
    },
    {
        "x": 22.55,
        "y": 24.01
    },
    {
        "x": 22.56,
        "y": 24.02
    },
    {
        "x": 22.57,
        "y": 24.02
    },
    {
        "x": 22.58,
        "y": 24.02
    },
    {
        "x": 22.59,
        "y": 24.02
    },
    {
        "x": 22.6,
        "y": 24.02
    },
    {
        "x": 22.61,
        "y": 24.03
    },
    {
        "x": 22.62,
        "y": 24.03
    },
    {
        "x": 22.63,
        "y": 24.03
    },
    {
        "x": 22.64,
        "y": 24.03
    },
    {
        "x": 22.65,
        "y": 24.03
    },
    {
        "x": 22.66,
        "y": 24.04
    },
    {
        "x": 22.67,
        "y": 24.04
    },
    {
        "x": 22.68,
        "y": 24.04
    },
    {
        "x": 22.69,
        "y": 24.04
    },
    {
        "x": 22.7,
        "y": 24.04
    },
    {
        "x": 22.71,
        "y": 24.05
    },
    {
        "x": 22.72,
        "y": 24.05
    },
    {
        "x": 22.73,
        "y": 24.05
    },
    {
        "x": 22.74,
        "y": 24.05
    },
    {
        "x": 22.75,
        "y": 24.06
    },
    {
        "x": 22.76,
        "y": 24.06
    },
    {
        "x": 22.77,
        "y": 24.06
    },
    {
        "x": 22.78,
        "y": 24.06
    },
    {
        "x": 22.79,
        "y": 24.07
    },
    {
        "x": 22.8,
        "y": 24.07
    },
    {
        "x": 22.81,
        "y": 24.07
    },
    {
        "x": 22.82,
        "y": 24.07
    },
    {
        "x": 22.83,
        "y": 24.07
    },
    {
        "x": 22.84,
        "y": 24.08
    },
    {
        "x": 22.85,
        "y": 24.08
    },
    {
        "x": 22.86,
        "y": 24.08
    },
    {
        "x": 22.87,
        "y": 24.08
    },
    {
        "x": 22.88,
        "y": 24.09
    },
    {
        "x": 22.89,
        "y": 24.09
    },
    {
        "x": 22.9,
        "y": 24.09
    },
    {
        "x": 22.91,
        "y": 24.09
    },
    {
        "x": 22.92,
        "y": 24.09
    },
    {
        "x": 22.93,
        "y": 24.1
    },
    {
        "x": 22.94,
        "y": 24.1
    },
    {
        "x": 22.95,
        "y": 24.1
    },
    {
        "x": 22.96,
        "y": 24.1
    },
    {
        "x": 22.97,
        "y": 24.1
    },
    {
        "x": 22.98,
        "y": 24.11
    },
    {
        "x": 22.99,
        "y": 24.11
    },
    {
        "x": 23,
        "y": 24.11
    },
    {
        "x": 23.01,
        "y": 24.12
    },
    {
        "x": 23.02,
        "y": 24.12
    },
    {
        "x": 23.03,
        "y": 24.12
    },
    {
        "x": 23.04,
        "y": 24.12
    },
    {
        "x": 23.05,
        "y": 24.13
    },
    {
        "x": 23.06,
        "y": 24.13
    },
    {
        "x": 23.07,
        "y": 24.13
    },
    {
        "x": 23.08,
        "y": 24.13
    },
    {
        "x": 23.09,
        "y": 24.14
    },
    {
        "x": 23.1,
        "y": 24.14
    },
    {
        "x": 23.11,
        "y": 24.14
    },
    {
        "x": 23.12,
        "y": 24.14
    },
    {
        "x": 23.13,
        "y": 24.14
    },
    {
        "x": 23.14,
        "y": 24.15
    },
    {
        "x": 23.15,
        "y": 24.15
    },
    {
        "x": 23.16,
        "y": 24.15
    },
    {
        "x": 23.17,
        "y": 24.15
    },
    {
        "x": 23.18,
        "y": 24.15
    },
    {
        "x": 23.19,
        "y": 24.16
    },
    {
        "x": 23.2,
        "y": 24.16
    },
    {
        "x": 23.21,
        "y": 24.16
    },
    {
        "x": 23.22,
        "y": 24.16
    },
    {
        "x": 23.23,
        "y": 24.16
    },
    {
        "x": 23.24,
        "y": 24.17
    },
    {
        "x": 23.25,
        "y": 24.16
    },
    {
        "x": 23.26,
        "y": 24.17
    },
    {
        "x": 23.27,
        "y": 24.17
    },
    {
        "x": 23.28,
        "y": 24.17
    },
    {
        "x": 23.29,
        "y": 24.17
    },
    {
        "x": 23.3,
        "y": 24.18
    },
    {
        "x": 23.31,
        "y": 24.18
    },
    {
        "x": 23.32,
        "y": 24.18
    },
    {
        "x": 23.33,
        "y": 24.19
    },
    {
        "x": 23.34,
        "y": 24.18
    },
    {
        "x": 23.35,
        "y": 24.19
    },
    {
        "x": 23.36,
        "y": 24.19
    },
    {
        "x": 23.37,
        "y": 24.19
    },
    {
        "x": 23.38,
        "y": 24.2
    },
    {
        "x": 23.39,
        "y": 24.2
    },
    {
        "x": 23.4,
        "y": 24.2
    },
    {
        "x": 23.41,
        "y": 24.2
    },
    {
        "x": 23.42,
        "y": 24.21
    },
    {
        "x": 23.43,
        "y": 24.21
    },
    {
        "x": 23.44,
        "y": 24.21
    },
    {
        "x": 23.45,
        "y": 24.21
    },
    {
        "x": 23.46,
        "y": 24.21
    },
    {
        "x": 23.47,
        "y": 24.21
    },
    {
        "x": 23.48,
        "y": 24.21
    },
    {
        "x": 23.49,
        "y": 24.21
    },
    {
        "x": 23.5,
        "y": 24.22
    },
    {
        "x": 23.51,
        "y": 24.22
    },
    {
        "x": 23.52,
        "y": 24.22
    },
    {
        "x": 23.53,
        "y": 24.23
    },
    {
        "x": 23.54,
        "y": 24.23
    },
    {
        "x": 23.55,
        "y": 24.23
    },
    {
        "x": 23.56,
        "y": 24.23
    },
    {
        "x": 23.57,
        "y": 24.23
    },
    {
        "x": 23.58,
        "y": 24.24
    },
    {
        "x": 23.59,
        "y": 24.24
    },
    {
        "x": 23.6,
        "y": 24.24
    },
    {
        "x": 23.61,
        "y": 24.24
    },
    {
        "x": 23.62,
        "y": 24.25
    },
    {
        "x": 23.63,
        "y": 24.25
    },
    {
        "x": 23.64,
        "y": 24.25
    },
    {
        "x": 23.65,
        "y": 24.25
    },
    {
        "x": 23.66,
        "y": 24.26
    },
    {
        "x": 23.67,
        "y": 24.26
    },
    {
        "x": 23.68,
        "y": 24.26
    },
    {
        "x": 23.69,
        "y": 24.26
    },
    {
        "x": 23.7,
        "y": 24.27
    },
    {
        "x": 23.71,
        "y": 24.27
    },
    {
        "x": 23.72,
        "y": 24.27
    },
    {
        "x": 23.73,
        "y": 24.27
    },
    {
        "x": 23.74,
        "y": 24.27
    },
    {
        "x": 23.75,
        "y": 24.28
    },
    {
        "x": 23.76,
        "y": 24.28
    },
    {
        "x": 23.77,
        "y": 24.28
    },
    {
        "x": 23.78,
        "y": 24.28
    },
    {
        "x": 23.79,
        "y": 24.28
    },
    {
        "x": 23.8,
        "y": 24.28
    },
    {
        "x": 23.81,
        "y": 24.29
    },
    {
        "x": 23.82,
        "y": 24.29
    },
    {
        "x": 23.83,
        "y": 24.29
    },
    {
        "x": 23.84,
        "y": 24.29
    },
    {
        "x": 23.85,
        "y": 24.3
    },
    {
        "x": 23.86,
        "y": 24.3
    },
    {
        "x": 23.87,
        "y": 24.3
    },
    {
        "x": 23.88,
        "y": 24.3
    },
    {
        "x": 23.89,
        "y": 24.3
    },
    {
        "x": 23.9,
        "y": 24.3
    },
    {
        "x": 23.91,
        "y": 24.3
    },
    {
        "x": 23.92,
        "y": 24.31
    },
    {
        "x": 23.93,
        "y": 24.31
    },
    {
        "x": 23.94,
        "y": 24.31
    },
    {
        "x": 23.95,
        "y": 24.31
    },
    {
        "x": 23.96,
        "y": 24.32
    },
    {
        "x": 23.97,
        "y": 24.31
    },
    {
        "x": 23.98,
        "y": 24.32
    },
    {
        "x": 23.99,
        "y": 24.32
    },
    {
        "x": 24,
        "y": 24.32
    },
    {
        "x": 24.01,
        "y": 24.32
    },
    {
        "x": 24.02,
        "y": 24.33
    },
    {
        "x": 24.03,
        "y": 24.33
    },
    {
        "x": 24.04,
        "y": 24.33
    },
    {
        "x": 24.05,
        "y": 24.33
    },
    {
        "x": 24.06,
        "y": 24.33
    },
    {
        "x": 24.07,
        "y": 24.34
    },
    {
        "x": 24.08,
        "y": 24.34
    },
    {
        "x": 24.09,
        "y": 24.34
    },
    {
        "x": 24.1,
        "y": 24.34
    },
    {
        "x": 24.11,
        "y": 24.34
    },
    {
        "x": 24.12,
        "y": 24.35
    },
    {
        "x": 24.13,
        "y": 24.35
    },
    {
        "x": 24.14,
        "y": 24.35
    },
    {
        "x": 24.15,
        "y": 24.35
    },
    {
        "x": 24.16,
        "y": 24.36
    },
    {
        "x": 24.17,
        "y": 24.35
    },
    {
        "x": 24.18,
        "y": 24.36
    },
    {
        "x": 24.19,
        "y": 24.36
    },
    {
        "x": 24.2,
        "y": 24.36
    },
    {
        "x": 24.21,
        "y": 24.36
    },
    {
        "x": 24.22,
        "y": 24.36
    },
    {
        "x": 24.23,
        "y": 24.37
    },
    {
        "x": 24.24,
        "y": 24.37
    },
    {
        "x": 24.25,
        "y": 24.37
    },
    {
        "x": 24.26,
        "y": 24.37
    },
    {
        "x": 24.27,
        "y": 24.37
    },
    {
        "x": 24.28,
        "y": 24.38
    },
    {
        "x": 24.29,
        "y": 24.38
    },
    {
        "x": 24.3,
        "y": 24.38
    },
    {
        "x": 24.31,
        "y": 24.38
    },
    {
        "x": 24.32,
        "y": 24.38
    },
    {
        "x": 24.33,
        "y": 24.39
    },
    {
        "x": 24.34,
        "y": 24.39
    },
    {
        "x": 24.35,
        "y": 24.39
    },
    {
        "x": 24.36,
        "y": 24.39
    },
    {
        "x": 24.37,
        "y": 24.39
    },
    {
        "x": 24.38,
        "y": 24.4
    },
    {
        "x": 24.39,
        "y": 24.39
    },
    {
        "x": 24.4,
        "y": 24.4
    },
    {
        "x": 24.41,
        "y": 24.4
    },
    {
        "x": 24.42,
        "y": 24.4
    },
    {
        "x": 24.43,
        "y": 24.41
    },
    {
        "x": 24.44,
        "y": 24.41
    },
    {
        "x": 24.45,
        "y": 24.41
    },
    {
        "x": 24.46,
        "y": 24.41
    },
    {
        "x": 24.47,
        "y": 24.41
    },
    {
        "x": 24.48,
        "y": 24.41
    },
    {
        "x": 24.49,
        "y": 24.42
    },
    {
        "x": 24.5,
        "y": 24.42
    },
    {
        "x": 24.51,
        "y": 24.42
    },
    {
        "x": 24.52,
        "y": 24.42
    },
    {
        "x": 24.53,
        "y": 24.43
    },
    {
        "x": 24.54,
        "y": 24.43
    },
    {
        "x": 24.55,
        "y": 24.43
    },
    {
        "x": 24.56,
        "y": 24.43
    },
    {
        "x": 24.57,
        "y": 24.43
    },
    {
        "x": 24.58,
        "y": 24.43
    },
    {
        "x": 24.59,
        "y": 24.44
    },
    {
        "x": 24.6,
        "y": 24.44
    },
    {
        "x": 24.61,
        "y": 24.44
    },
    {
        "x": 24.62,
        "y": 24.44
    },
    {
        "x": 24.63,
        "y": 24.44
    },
    {
        "x": 24.64,
        "y": 24.44
    },
    {
        "x": 24.65,
        "y": 24.45
    },
    {
        "x": 24.66,
        "y": 24.45
    },
    {
        "x": 24.67,
        "y": 24.45
    },
    {
        "x": 24.68,
        "y": 24.45
    },
    {
        "x": 24.69,
        "y": 24.46
    },
    {
        "x": 24.7,
        "y": 24.45
    },
    {
        "x": 24.71,
        "y": 24.46
    },
    {
        "x": 24.72,
        "y": 24.46
    },
    {
        "x": 24.73,
        "y": 24.46
    },
    {
        "x": 24.74,
        "y": 24.46
    },
    {
        "x": 24.75,
        "y": 24.46
    },
    {
        "x": 24.76,
        "y": 24.47
    },
    {
        "x": 24.77,
        "y": 24.47
    },
    {
        "x": 24.78,
        "y": 24.47
    },
    {
        "x": 24.79,
        "y": 24.47
    },
    {
        "x": 24.8,
        "y": 24.47
    },
    {
        "x": 24.81,
        "y": 24.47
    },
    {
        "x": 24.82,
        "y": 24.48
    },
    {
        "x": 24.83,
        "y": 24.48
    },
    {
        "x": 24.84,
        "y": 24.48
    },
    {
        "x": 24.85,
        "y": 24.48
    },
    {
        "x": 24.86,
        "y": 24.48
    },
    {
        "x": 24.87,
        "y": 24.49
    },
    {
        "x": 24.88,
        "y": 24.49
    },
    {
        "x": 24.89,
        "y": 24.49
    },
    {
        "x": 24.9,
        "y": 24.49
    },
    {
        "x": 24.91,
        "y": 24.49
    },
    {
        "x": 24.92,
        "y": 24.5
    },
    {
        "x": 24.93,
        "y": 24.5
    },
    {
        "x": 24.94,
        "y": 24.5
    },
    {
        "x": 24.95,
        "y": 24.5
    },
    {
        "x": 24.96,
        "y": 24.5
    },
    {
        "x": 24.97,
        "y": 24.5
    },
    {
        "x": 24.98,
        "y": 24.51
    },
    {
        "x": 24.99,
        "y": 24.51
    },
    {
        "x": 25,
        "y": 24.51
    },
    {
        "x": 25.01,
        "y": 24.51
    },
    {
        "x": 25.02,
        "y": 24.51
    },
    {
        "x": 25.03,
        "y": 24.52
    },
    {
        "x": 25.04,
        "y": 24.52
    },
    {
        "x": 25.05,
        "y": 24.52
    },
    {
        "x": 25.06,
        "y": 24.52
    },
    {
        "x": 25.07,
        "y": 24.52
    },
    {
        "x": 25.08,
        "y": 24.52
    },
    {
        "x": 25.09,
        "y": 24.52
    },
    {
        "x": 25.1,
        "y": 24.53
    },
    {
        "x": 25.11,
        "y": 24.53
    },
    {
        "x": 25.12,
        "y": 24.53
    },
    {
        "x": 25.13,
        "y": 24.53
    },
    {
        "x": 25.14,
        "y": 24.53
    },
    {
        "x": 25.15,
        "y": 24.54
    },
    {
        "x": 25.16,
        "y": 24.54
    },
    {
        "x": 25.17,
        "y": 24.54
    },
    {
        "x": 25.18,
        "y": 24.54
    },
    {
        "x": 25.19,
        "y": 24.54
    },
    {
        "x": 25.2,
        "y": 24.54
    },
    {
        "x": 25.21,
        "y": 24.55
    },
    {
        "x": 25.22,
        "y": 24.55
    },
    {
        "x": 25.23,
        "y": 24.55
    },
    {
        "x": 25.24,
        "y": 24.55
    },
    {
        "x": 25.25,
        "y": 24.55
    },
    {
        "x": 25.26,
        "y": 24.55
    },
    {
        "x": 25.27,
        "y": 24.55
    },
    {
        "x": 25.28,
        "y": 24.56
    },
    {
        "x": 25.29,
        "y": 24.56
    },
    {
        "x": 25.3,
        "y": 24.56
    },
    {
        "x": 25.31,
        "y": 24.56
    },
    {
        "x": 25.32,
        "y": 24.56
    },
    {
        "x": 25.33,
        "y": 24.56
    },
    {
        "x": 25.34,
        "y": 24.57
    },
    {
        "x": 25.35,
        "y": 24.57
    },
    {
        "x": 25.36,
        "y": 24.57
    },
    {
        "x": 25.37,
        "y": 24.57
    },
    {
        "x": 25.38,
        "y": 24.57
    },
    {
        "x": 25.39,
        "y": 24.57
    },
    {
        "x": 25.4,
        "y": 24.58
    },
    {
        "x": 25.41,
        "y": 24.58
    },
    {
        "x": 25.42,
        "y": 24.58
    },
    {
        "x": 25.43,
        "y": 24.58
    },
    {
        "x": 25.44,
        "y": 24.58
    },
    {
        "x": 25.45,
        "y": 24.58
    },
    {
        "x": 25.46,
        "y": 24.59
    },
    {
        "x": 25.47,
        "y": 24.58
    },
    {
        "x": 25.48,
        "y": 24.59
    },
    {
        "x": 25.49,
        "y": 24.59
    },
    {
        "x": 25.5,
        "y": 24.59
    },
    {
        "x": 25.51,
        "y": 24.59
    },
    {
        "x": 25.52,
        "y": 24.6
    },
    {
        "x": 25.53,
        "y": 24.59
    },
    {
        "x": 25.54,
        "y": 24.6
    },
    {
        "x": 25.55,
        "y": 24.6
    },
    {
        "x": 25.56,
        "y": 24.6
    },
    {
        "x": 25.57,
        "y": 24.61
    },
    {
        "x": 25.58,
        "y": 24.61
    },
    {
        "x": 25.59,
        "y": 24.61
    },
    {
        "x": 25.6,
        "y": 24.61
    },
    {
        "x": 25.61,
        "y": 24.61
    },
    {
        "x": 25.62,
        "y": 24.61
    },
    {
        "x": 25.63,
        "y": 24.61
    },
    {
        "x": 25.64,
        "y": 24.62
    },
    {
        "x": 25.65,
        "y": 24.62
    },
    {
        "x": 25.66,
        "y": 24.62
    },
    {
        "x": 25.67,
        "y": 24.62
    },
    {
        "x": 25.68,
        "y": 24.62
    },
    {
        "x": 25.69,
        "y": 24.62
    },
    {
        "x": 25.7,
        "y": 24.62
    },
    {
        "x": 25.71,
        "y": 24.63
    },
    {
        "x": 25.72,
        "y": 24.63
    },
    {
        "x": 25.73,
        "y": 24.63
    },
    {
        "x": 25.74,
        "y": 24.63
    },
    {
        "x": 25.75,
        "y": 24.63
    },
    {
        "x": 25.76,
        "y": 24.63
    },
    {
        "x": 25.77,
        "y": 24.63
    },
    {
        "x": 25.78,
        "y": 24.64
    },
    {
        "x": 25.79,
        "y": 24.64
    },
    {
        "x": 25.8,
        "y": 24.64
    },
    {
        "x": 25.81,
        "y": 24.64
    },
    {
        "x": 25.82,
        "y": 24.65
    },
    {
        "x": 25.83,
        "y": 24.64
    },
    {
        "x": 25.84,
        "y": 24.65
    },
    {
        "x": 25.85,
        "y": 24.65
    },
    {
        "x": 25.86,
        "y": 24.65
    },
    {
        "x": 25.87,
        "y": 24.65
    },
    {
        "x": 25.88,
        "y": 24.66
    },
    {
        "x": 25.89,
        "y": 24.65
    },
    {
        "x": 25.9,
        "y": 24.66
    },
    {
        "x": 25.91,
        "y": 24.66
    },
    {
        "x": 25.92,
        "y": 24.66
    },
    {
        "x": 25.93,
        "y": 24.66
    },
    {
        "x": 25.94,
        "y": 24.66
    },
    {
        "x": 25.95,
        "y": 24.67
    },
    {
        "x": 25.96,
        "y": 24.67
    },
    {
        "x": 25.97,
        "y": 24.67
    },
    {
        "x": 25.98,
        "y": 24.67
    },
    {
        "x": 25.99,
        "y": 24.67
    },
    {
        "x": 26,
        "y": 24.67
    },
    {
        "x": 26.01,
        "y": 24.67
    },
    {
        "x": 26.02,
        "y": 24.68
    },
    {
        "x": 26.03,
        "y": 24.68
    },
    {
        "x": 26.04,
        "y": 24.68
    },
    {
        "x": 26.05,
        "y": 24.68
    },
    {
        "x": 26.06,
        "y": 24.68
    },
    {
        "x": 26.07,
        "y": 24.68
    },
    {
        "x": 26.08,
        "y": 24.69
    },
    {
        "x": 26.09,
        "y": 24.69
    },
    {
        "x": 26.1,
        "y": 24.69
    },
    {
        "x": 26.11,
        "y": 24.69
    },
    {
        "x": 26.12,
        "y": 24.7
    },
    {
        "x": 26.13,
        "y": 24.69
    },
    {
        "x": 26.14,
        "y": 24.69
    },
    {
        "x": 26.15,
        "y": 24.7
    },
    {
        "x": 26.16,
        "y": 24.7
    },
    {
        "x": 26.17,
        "y": 24.7
    },
    {
        "x": 26.18,
        "y": 24.7
    },
    {
        "x": 26.19,
        "y": 24.7
    },
    {
        "x": 26.2,
        "y": 24.7
    },
    {
        "x": 26.21,
        "y": 24.7
    },
    {
        "x": 26.22,
        "y": 24.7
    },
    {
        "x": 26.23,
        "y": 24.71
    },
    {
        "x": 26.24,
        "y": 24.71
    },
    {
        "x": 26.25,
        "y": 24.71
    },
    {
        "x": 26.26,
        "y": 24.71
    },
    {
        "x": 26.27,
        "y": 24.71
    },
    {
        "x": 26.28,
        "y": 24.71
    },
    {
        "x": 26.29,
        "y": 24.72
    },
    {
        "x": 26.3,
        "y": 24.72
    },
    {
        "x": 26.31,
        "y": 24.72
    },
    {
        "x": 26.32,
        "y": 24.72
    },
    {
        "x": 26.33,
        "y": 24.72
    },
    {
        "x": 26.34,
        "y": 24.72
    },
    {
        "x": 26.35,
        "y": 24.72
    },
    {
        "x": 26.36,
        "y": 24.73
    },
    {
        "x": 26.37,
        "y": 24.73
    },
    {
        "x": 26.38,
        "y": 24.73
    },
    {
        "x": 26.39,
        "y": 24.73
    },
    {
        "x": 26.4,
        "y": 24.73
    },
    {
        "x": 26.41,
        "y": 24.73
    },
    {
        "x": 26.42,
        "y": 24.74
    },
    {
        "x": 26.43,
        "y": 24.73
    },
    {
        "x": 26.44,
        "y": 24.74
    },
    {
        "x": 26.45,
        "y": 24.74
    },
    {
        "x": 26.46,
        "y": 24.74
    },
    {
        "x": 26.47,
        "y": 24.74
    },
    {
        "x": 26.48,
        "y": 24.74
    },
    {
        "x": 26.49,
        "y": 24.74
    },
    {
        "x": 26.5,
        "y": 24.74
    },
    {
        "x": 26.51,
        "y": 24.74
    },
    {
        "x": 26.52,
        "y": 24.75
    },
    {
        "x": 26.53,
        "y": 24.75
    },
    {
        "x": 26.54,
        "y": 24.75
    },
    {
        "x": 26.55,
        "y": 24.75
    },
    {
        "x": 26.56,
        "y": 24.75
    },
    {
        "x": 26.57,
        "y": 24.75
    },
    {
        "x": 26.58,
        "y": 24.76
    },
    {
        "x": 26.59,
        "y": 24.76
    },
    {
        "x": 26.6,
        "y": 24.76
    },
    {
        "x": 26.61,
        "y": 24.76
    },
    {
        "x": 26.62,
        "y": 24.76
    },
    {
        "x": 26.63,
        "y": 24.76
    },
    {
        "x": 26.64,
        "y": 24.76
    },
    {
        "x": 26.65,
        "y": 24.77
    },
    {
        "x": 26.66,
        "y": 24.77
    },
    {
        "x": 26.67,
        "y": 24.77
    },
    {
        "x": 26.68,
        "y": 24.77
    },
    {
        "x": 26.69,
        "y": 24.77
    },
    {
        "x": 26.7,
        "y": 24.77
    },
    {
        "x": 26.71,
        "y": 24.77
    },
    {
        "x": 26.72,
        "y": 24.78
    },
    {
        "x": 26.73,
        "y": 24.78
    },
    {
        "x": 26.74,
        "y": 24.78
    },
    {
        "x": 26.75,
        "y": 24.78
    },
    {
        "x": 26.76,
        "y": 24.78
    },
    {
        "x": 26.77,
        "y": 24.78
    },
    {
        "x": 26.78,
        "y": 24.78
    },
    {
        "x": 26.79,
        "y": 24.78
    },
    {
        "x": 26.8,
        "y": 24.78
    },
    {
        "x": 26.81,
        "y": 24.78
    },
    {
        "x": 26.82,
        "y": 24.79
    },
    {
        "x": 26.83,
        "y": 24.79
    },
    {
        "x": 26.84,
        "y": 24.79
    },
    {
        "x": 26.85,
        "y": 24.79
    },
    {
        "x": 26.86,
        "y": 24.79
    },
    {
        "x": 26.87,
        "y": 24.79
    },
    {
        "x": 26.88,
        "y": 24.79
    },
    {
        "x": 26.89,
        "y": 24.79
    },
    {
        "x": 26.9,
        "y": 24.8
    },
    {
        "x": 26.91,
        "y": 24.8
    },
    {
        "x": 26.92,
        "y": 24.8
    },
    {
        "x": 26.93,
        "y": 24.8
    },
    {
        "x": 26.94,
        "y": 24.8
    },
    {
        "x": 26.95,
        "y": 24.8
    },
    {
        "x": 26.96,
        "y": 24.8
    },
    {
        "x": 26.97,
        "y": 24.81
    },
    {
        "x": 26.98,
        "y": 24.81
    },
    {
        "x": 26.99,
        "y": 24.81
    },
    {
        "x": 27,
        "y": 24.81
    },
    {
        "x": 27.01,
        "y": 24.81
    },
    {
        "x": 27.02,
        "y": 24.81
    },
    {
        "x": 27.03,
        "y": 24.82
    },
    {
        "x": 27.04,
        "y": 24.81
    },
    {
        "x": 27.05,
        "y": 24.82
    },
    {
        "x": 27.06,
        "y": 24.82
    },
    {
        "x": 27.07,
        "y": 24.82
    },
    {
        "x": 27.08,
        "y": 24.82
    },
    {
        "x": 27.09,
        "y": 24.82
    },
    {
        "x": 27.1,
        "y": 24.82
    },
    {
        "x": 27.11,
        "y": 24.82
    },
    {
        "x": 27.12,
        "y": 24.83
    },
    {
        "x": 27.13,
        "y": 24.83
    },
    {
        "x": 27.14,
        "y": 24.83
    },
    {
        "x": 27.15,
        "y": 24.83
    },
    {
        "x": 27.16,
        "y": 24.83
    },
    {
        "x": 27.17,
        "y": 24.84
    },
    {
        "x": 27.18,
        "y": 24.84
    },
    {
        "x": 27.19,
        "y": 24.83
    },
    {
        "x": 27.2,
        "y": 24.84
    },
    {
        "x": 27.21,
        "y": 24.84
    },
    {
        "x": 27.22,
        "y": 24.84
    },
    {
        "x": 27.23,
        "y": 24.84
    },
    {
        "x": 27.24,
        "y": 24.84
    },
    {
        "x": 27.25,
        "y": 24.84
    },
    {
        "x": 27.26,
        "y": 24.85
    },
    {
        "x": 27.27,
        "y": 24.85
    },
    {
        "x": 27.28,
        "y": 24.85
    },
    {
        "x": 27.29,
        "y": 24.85
    },
    {
        "x": 27.3,
        "y": 24.85
    },
    {
        "x": 27.31,
        "y": 24.85
    },
    {
        "x": 27.32,
        "y": 24.85
    },
    {
        "x": 27.33,
        "y": 24.85
    },
    {
        "x": 27.34,
        "y": 24.86
    },
    {
        "x": 27.35,
        "y": 24.86
    },
    {
        "x": 27.36,
        "y": 24.86
    },
    {
        "x": 27.37,
        "y": 24.86
    },
    {
        "x": 27.38,
        "y": 24.86
    },
    {
        "x": 27.39,
        "y": 24.86
    },
    {
        "x": 27.4,
        "y": 24.86
    },
    {
        "x": 27.41,
        "y": 24.86
    },
    {
        "x": 27.42,
        "y": 24.86
    },
    {
        "x": 27.43,
        "y": 24.87
    },
    {
        "x": 27.44,
        "y": 24.87
    },
    {
        "x": 27.45,
        "y": 24.87
    },
    {
        "x": 27.46,
        "y": 24.87
    },
    {
        "x": 27.47,
        "y": 24.87
    },
    {
        "x": 27.48,
        "y": 24.87
    },
    {
        "x": 27.49,
        "y": 24.87
    },
    {
        "x": 27.5,
        "y": 24.88
    },
    {
        "x": 27.51,
        "y": 24.87
    },
    {
        "x": 27.52,
        "y": 24.87
    },
    {
        "x": 27.53,
        "y": 24.88
    },
    {
        "x": 27.54,
        "y": 24.88
    },
    {
        "x": 27.55,
        "y": 24.88
    },
    {
        "x": 27.56,
        "y": 24.88
    },
    {
        "x": 27.57,
        "y": 24.88
    },
    {
        "x": 27.58,
        "y": 24.88
    },
    {
        "x": 27.59,
        "y": 24.89
    },
    {
        "x": 27.6,
        "y": 24.89
    },
    {
        "x": 27.61,
        "y": 24.89
    },
    {
        "x": 27.62,
        "y": 24.89
    },
    {
        "x": 27.63,
        "y": 24.89
    },
    {
        "x": 27.64,
        "y": 24.89
    },
    {
        "x": 27.65,
        "y": 24.89
    },
    {
        "x": 27.66,
        "y": 24.89
    },
    {
        "x": 27.67,
        "y": 24.9
    },
    {
        "x": 27.68,
        "y": 24.9
    },
    {
        "x": 27.69,
        "y": 24.9
    },
    {
        "x": 27.7,
        "y": 24.9
    },
    {
        "x": 27.71,
        "y": 24.9
    },
    {
        "x": 27.72,
        "y": 24.9
    },
    {
        "x": 27.73,
        "y": 24.9
    },
    {
        "x": 27.74,
        "y": 24.91
    },
    {
        "x": 27.75,
        "y": 24.91
    },
    {
        "x": 27.76,
        "y": 24.9
    },
    {
        "x": 27.77,
        "y": 24.91
    },
    {
        "x": 27.78,
        "y": 24.91
    },
    {
        "x": 27.79,
        "y": 24.91
    },
    {
        "x": 27.8,
        "y": 24.91
    },
    {
        "x": 27.81,
        "y": 24.91
    },
    {
        "x": 27.82,
        "y": 24.91
    },
    {
        "x": 27.83,
        "y": 24.91
    },
    {
        "x": 27.84,
        "y": 24.91
    },
    {
        "x": 27.85,
        "y": 24.92
    },
    {
        "x": 27.86,
        "y": 24.92
    },
    {
        "x": 27.87,
        "y": 24.92
    },
    {
        "x": 27.88,
        "y": 24.92
    },
    {
        "x": 27.89,
        "y": 24.92
    },
    {
        "x": 27.9,
        "y": 24.92
    },
    {
        "x": 27.91,
        "y": 24.92
    },
    {
        "x": 27.92,
        "y": 24.92
    },
    {
        "x": 27.93,
        "y": 24.92
    },
    {
        "x": 27.94,
        "y": 24.93
    },
    {
        "x": 27.95,
        "y": 24.93
    },
    {
        "x": 27.96,
        "y": 24.93
    },
    {
        "x": 27.97,
        "y": 24.93
    },
    {
        "x": 27.98,
        "y": 24.93
    },
    {
        "x": 27.99,
        "y": 24.93
    },
    {
        "x": 28,
        "y": 24.94
    },
    {
        "x": 28.01,
        "y": 24.93
    },
    {
        "x": 28.02,
        "y": 24.94
    },
    {
        "x": 28.03,
        "y": 24.94
    },
    {
        "x": 28.04,
        "y": 24.94
    },
    {
        "x": 28.05,
        "y": 24.94
    },
    {
        "x": 28.06,
        "y": 24.94
    },
    {
        "x": 28.07,
        "y": 24.94
    },
    {
        "x": 28.08,
        "y": 24.95
    },
    {
        "x": 28.09,
        "y": 24.94
    },
    {
        "x": 28.1,
        "y": 24.94
    },
    {
        "x": 28.11,
        "y": 24.94
    },
    {
        "x": 28.12,
        "y": 24.94
    },
    {
        "x": 28.13,
        "y": 24.95
    },
    {
        "x": 28.14,
        "y": 24.95
    },
    {
        "x": 28.15,
        "y": 24.95
    },
    {
        "x": 28.16,
        "y": 24.95
    },
    {
        "x": 28.17,
        "y": 24.95
    },
    {
        "x": 28.18,
        "y": 24.95
    },
    {
        "x": 28.19,
        "y": 24.95
    },
    {
        "x": 28.2,
        "y": 24.96
    },
    {
        "x": 28.21,
        "y": 24.96
    },
    {
        "x": 28.22,
        "y": 24.96
    },
    {
        "x": 28.23,
        "y": 24.96
    },
    {
        "x": 28.24,
        "y": 24.96
    },
    {
        "x": 28.25,
        "y": 24.96
    },
    {
        "x": 28.26,
        "y": 24.96
    },
    {
        "x": 28.27,
        "y": 24.96
    },
    {
        "x": 28.28,
        "y": 24.96
    },
    {
        "x": 28.29,
        "y": 24.97
    },
    {
        "x": 28.3,
        "y": 24.97
    },
    {
        "x": 28.31,
        "y": 24.97
    },
    {
        "x": 28.32,
        "y": 24.97
    },
    {
        "x": 28.33,
        "y": 24.97
    },
    {
        "x": 28.34,
        "y": 24.97
    },
    {
        "x": 28.35,
        "y": 24.97
    },
    {
        "x": 28.36,
        "y": 24.97
    },
    {
        "x": 28.37,
        "y": 24.97
    },
    {
        "x": 28.38,
        "y": 24.98
    },
    {
        "x": 28.39,
        "y": 24.98
    },
    {
        "x": 28.4,
        "y": 24.98
    },
    {
        "x": 28.41,
        "y": 24.98
    },
    {
        "x": 28.42,
        "y": 24.98
    },
    {
        "x": 28.43,
        "y": 24.98
    },
    {
        "x": 28.44,
        "y": 24.98
    },
    {
        "x": 28.45,
        "y": 24.99
    },
    {
        "x": 28.46,
        "y": 24.98
    },
    {
        "x": 28.47,
        "y": 24.99
    },
    {
        "x": 28.48,
        "y": 24.99
    },
    {
        "x": 28.49,
        "y": 24.99
    },
    {
        "x": 28.5,
        "y": 24.99
    },
    {
        "x": 28.51,
        "y": 24.99
    },
    {
        "x": 28.52,
        "y": 24.99
    },
    {
        "x": 28.53,
        "y": 24.99
    },
    {
        "x": 28.54,
        "y": 25
    },
    {
        "x": 28.55,
        "y": 25
    },
    {
        "x": 28.56,
        "y": 25
    },
    {
        "x": 28.57,
        "y": 25
    },
    {
        "x": 28.58,
        "y": 25
    },
    {
        "x": 28.59,
        "y": 25
    },
    {
        "x": 28.6,
        "y": 25
    },
    {
        "x": 28.61,
        "y": 25
    },
    {
        "x": 28.62,
        "y": 25
    },
    {
        "x": 28.63,
        "y": 25
    },
    {
        "x": 28.64,
        "y": 25
    },
    {
        "x": 28.65,
        "y": 25
    },
    {
        "x": 28.66,
        "y": 25.01
    },
    {
        "x": 28.67,
        "y": 25.01
    },
    {
        "x": 28.68,
        "y": 25.01
    },
    {
        "x": 28.69,
        "y": 25
    },
    {
        "x": 28.7,
        "y": 25.01
    },
    {
        "x": 28.71,
        "y": 25.01
    },
    {
        "x": 28.72,
        "y": 25.01
    },
    {
        "x": 28.73,
        "y": 25.01
    },
    {
        "x": 28.74,
        "y": 25.01
    },
    {
        "x": 28.75,
        "y": 25.01
    },
    {
        "x": 28.76,
        "y": 25.01
    },
    {
        "x": 28.77,
        "y": 25.01
    },
    {
        "x": 28.78,
        "y": 25.02
    },
    {
        "x": 28.79,
        "y": 25.02
    },
    {
        "x": 28.8,
        "y": 25.02
    },
    {
        "x": 28.81,
        "y": 25.02
    },
    {
        "x": 28.82,
        "y": 25.02
    },
    {
        "x": 28.83,
        "y": 25.02
    },
    {
        "x": 28.84,
        "y": 25.02
    },
    {
        "x": 28.85,
        "y": 25.03
    },
    {
        "x": 28.86,
        "y": 25.02
    },
    {
        "x": 28.87,
        "y": 25.03
    },
    {
        "x": 28.88,
        "y": 25.03
    },
    {
        "x": 28.89,
        "y": 25.03
    },
    {
        "x": 28.9,
        "y": 25.03
    },
    {
        "x": 28.91,
        "y": 25.03
    },
    {
        "x": 28.92,
        "y": 25.03
    },
    {
        "x": 28.93,
        "y": 25.03
    },
    {
        "x": 28.94,
        "y": 25.03
    },
    {
        "x": 28.95,
        "y": 25.04
    },
    {
        "x": 28.96,
        "y": 25.04
    },
    {
        "x": 28.97,
        "y": 25.03
    },
    {
        "x": 28.98,
        "y": 25.03
    },
    {
        "x": 28.99,
        "y": 25.04
    },
    {
        "x": 29,
        "y": 25.04
    },
    {
        "x": 29.01,
        "y": 25.04
    },
    {
        "x": 29.02,
        "y": 25.04
    },
    {
        "x": 29.03,
        "y": 25.04
    },
    {
        "x": 29.04,
        "y": 25.04
    },
    {
        "x": 29.05,
        "y": 25.04
    },
    {
        "x": 29.06,
        "y": 25.04
    },
    {
        "x": 29.07,
        "y": 25.04
    },
    {
        "x": 29.08,
        "y": 25.05
    },
    {
        "x": 29.09,
        "y": 25.05
    },
    {
        "x": 29.1,
        "y": 25.05
    },
    {
        "x": 29.11,
        "y": 25.05
    },
    {
        "x": 29.12,
        "y": 25.05
    },
    {
        "x": 29.13,
        "y": 25.05
    },
    {
        "x": 29.14,
        "y": 25.05
    },
    {
        "x": 29.15,
        "y": 25.05
    },
    {
        "x": 29.16,
        "y": 25.05
    },
    {
        "x": 29.17,
        "y": 25.06
    },
    {
        "x": 29.18,
        "y": 25.06
    },
    {
        "x": 29.19,
        "y": 25.06
    },
    {
        "x": 29.2,
        "y": 25.06
    },
    {
        "x": 29.21,
        "y": 25.06
    },
    {
        "x": 29.22,
        "y": 25.06
    },
    {
        "x": 29.23,
        "y": 25.06
    },
    {
        "x": 29.24,
        "y": 25.06
    },
    {
        "x": 29.25,
        "y": 25.06
    },
    {
        "x": 29.26,
        "y": 25.06
    },
    {
        "x": 29.27,
        "y": 25.06
    },
    {
        "x": 29.28,
        "y": 25.06
    },
    {
        "x": 29.29,
        "y": 25.07
    },
    {
        "x": 29.3,
        "y": 25.07
    },
    {
        "x": 29.31,
        "y": 25.07
    },
    {
        "x": 29.32,
        "y": 25.07
    },
    {
        "x": 29.33,
        "y": 25.07
    },
    {
        "x": 29.34,
        "y": 25.07
    },
    {
        "x": 29.35,
        "y": 25.08
    },
    {
        "x": 29.36,
        "y": 25.08
    },
    {
        "x": 29.37,
        "y": 25.08
    },
    {
        "x": 29.38,
        "y": 25.08
    },
    {
        "x": 29.39,
        "y": 25.08
    },
    {
        "x": 29.4,
        "y": 25.08
    },
    {
        "x": 29.41,
        "y": 25.08
    },
    {
        "x": 29.42,
        "y": 25.08
    },
    {
        "x": 29.43,
        "y": 25.08
    },
    {
        "x": 29.44,
        "y": 25.08
    },
    {
        "x": 29.45,
        "y": 25.08
    },
    {
        "x": 29.46,
        "y": 25.09
    },
    {
        "x": 29.47,
        "y": 25.09
    },
    {
        "x": 29.48,
        "y": 25.08
    },
    {
        "x": 29.49,
        "y": 25.09
    },
    {
        "x": 29.5,
        "y": 25.09
    },
    {
        "x": 29.51,
        "y": 25.09
    },
    {
        "x": 29.52,
        "y": 25.09
    },
    {
        "x": 29.53,
        "y": 25.09
    },
    {
        "x": 29.54,
        "y": 25.1
    },
    {
        "x": 29.55,
        "y": 25.1
    },
    {
        "x": 29.56,
        "y": 25.09
    },
    {
        "x": 29.57,
        "y": 25.1
    },
    {
        "x": 29.58,
        "y": 25.1
    },
    {
        "x": 29.59,
        "y": 25.1
    },
    {
        "x": 29.6,
        "y": 25.1
    },
    {
        "x": 29.61,
        "y": 25.1
    },
    {
        "x": 29.62,
        "y": 25.1
    },
    {
        "x": 29.63,
        "y": 25.1
    },
    {
        "x": 29.64,
        "y": 25.1
    },
    {
        "x": 29.65,
        "y": 25.1
    },
    {
        "x": 29.66,
        "y": 25.1
    },
    {
        "x": 29.67,
        "y": 25.1
    },
    {
        "x": 29.68,
        "y": 25.11
    },
    {
        "x": 29.69,
        "y": 25.11
    },
    {
        "x": 29.7,
        "y": 25.11
    },
    {
        "x": 29.71,
        "y": 25.11
    },
    {
        "x": 29.72,
        "y": 25.11
    },
    {
        "x": 29.73,
        "y": 25.11
    },
    {
        "x": 29.74,
        "y": 25.11
    },
    {
        "x": 29.75,
        "y": 25.11
    },
    {
        "x": 29.76,
        "y": 25.11
    },
    {
        "x": 29.77,
        "y": 25.12
    },
    {
        "x": 29.78,
        "y": 25.12
    },
    {
        "x": 29.79,
        "y": 25.12
    },
    {
        "x": 29.8,
        "y": 25.12
    },
    {
        "x": 29.81,
        "y": 25.12
    },
    {
        "x": 29.82,
        "y": 25.12
    },
    {
        "x": 29.83,
        "y": 25.12
    },
    {
        "x": 29.84,
        "y": 25.12
    },
    {
        "x": 29.85,
        "y": 25.12
    },
    {
        "x": 29.86,
        "y": 25.12
    },
    {
        "x": 29.87,
        "y": 25.12
    },
    {
        "x": 29.88,
        "y": 25.12
    },
    {
        "x": 29.89,
        "y": 25.12
    },
    {
        "x": 29.9,
        "y": 25.13
    },
    {
        "x": 29.91,
        "y": 25.13
    },
    {
        "x": 29.92,
        "y": 25.13
    },
    {
        "x": 29.93,
        "y": 25.13
    },
    {
        "x": 29.94,
        "y": 25.13
    },
    {
        "x": 29.95,
        "y": 25.13
    },
    {
        "x": 29.96,
        "y": 25.13
    },
    {
        "x": 29.97,
        "y": 25.13
    },
    {
        "x": 29.98,
        "y": 25.13
    },
    {
        "x": 29.99,
        "y": 25.13
    },
    {
        "x": 30,
        "y": 25.13
    },
    {
        "x": 30.01,
        "y": 25.14
    },
    {
        "x": 30.02,
        "y": 25.13
    },
    {
        "x": 30.03,
        "y": 25.13
    },
    {
        "x": 30.04,
        "y": 25.14
    },
    {
        "x": 30.05,
        "y": 25.14
    },
    {
        "x": 30.06,
        "y": 25.14
    },
    {
        "x": 30.07,
        "y": 25.14
    },
    {
        "x": 30.08,
        "y": 25.14
    },
    {
        "x": 30.09,
        "y": 25.14
    },
    {
        "x": 30.1,
        "y": 25.15
    },
    {
        "x": 30.11,
        "y": 25.15
    },
    {
        "x": 30.12,
        "y": 25.14
    },
    {
        "x": 30.13,
        "y": 25.14
    },
    {
        "x": 30.14,
        "y": 25.14
    },
    {
        "x": 30.15,
        "y": 25.15
    },
    {
        "x": 30.16,
        "y": 25.15
    },
    {
        "x": 30.17,
        "y": 25.15
    },
    {
        "x": 30.18,
        "y": 25.15
    },
    {
        "x": 30.19,
        "y": 25.15
    },
    {
        "x": 30.2,
        "y": 25.15
    },
    {
        "x": 30.21,
        "y": 25.15
    },
    {
        "x": 30.22,
        "y": 25.16
    },
    {
        "x": 30.23,
        "y": 25.15
    },
    {
        "x": 30.24,
        "y": 25.15
    },
    {
        "x": 30.25,
        "y": 25.16
    },
    {
        "x": 30.26,
        "y": 25.16
    },
    {
        "x": 30.27,
        "y": 25.16
    },
    {
        "x": 30.28,
        "y": 25.16
    },
    {
        "x": 30.29,
        "y": 25.16
    },
    {
        "x": 30.3,
        "y": 25.16
    },
    {
        "x": 30.31,
        "y": 25.16
    },
    {
        "x": 30.32,
        "y": 25.16
    },
    {
        "x": 30.33,
        "y": 25.16
    },
    {
        "x": 30.34,
        "y": 25.16
    },
    {
        "x": 30.35,
        "y": 25.16
    },
    {
        "x": 30.36,
        "y": 25.17
    },
    {
        "x": 30.37,
        "y": 25.17
    },
    {
        "x": 30.38,
        "y": 25.17
    },
    {
        "x": 30.39,
        "y": 25.17
    },
    {
        "x": 30.4,
        "y": 25.17
    },
    {
        "x": 30.41,
        "y": 25.17
    },
    {
        "x": 30.42,
        "y": 25.17
    },
    {
        "x": 30.43,
        "y": 25.17
    },
    {
        "x": 30.44,
        "y": 25.17
    },
    {
        "x": 30.45,
        "y": 25.17
    },
    {
        "x": 30.46,
        "y": 25.18
    },
    {
        "x": 30.47,
        "y": 25.18
    },
    {
        "x": 30.48,
        "y": 25.18
    },
    {
        "x": 30.49,
        "y": 25.18
    },
    {
        "x": 30.5,
        "y": 25.18
    },
    {
        "x": 30.51,
        "y": 25.18
    },
    {
        "x": 30.52,
        "y": 25.18
    },
    {
        "x": 30.53,
        "y": 25.18
    },
    {
        "x": 30.54,
        "y": 25.18
    },
    {
        "x": 30.55,
        "y": 25.18
    },
    {
        "x": 30.56,
        "y": 25.18
    },
    {
        "x": 30.57,
        "y": 25.19
    },
    {
        "x": 30.58,
        "y": 25.19
    },
    {
        "x": 30.59,
        "y": 25.19
    },
    {
        "x": 30.6,
        "y": 25.19
    },
    {
        "x": 30.61,
        "y": 25.19
    },
    {
        "x": 30.62,
        "y": 25.19
    },
    {
        "x": 30.63,
        "y": 25.19
    },
    {
        "x": 30.64,
        "y": 25.19
    },
    {
        "x": 30.65,
        "y": 25.19
    },
    {
        "x": 30.66,
        "y": 25.19
    },
    {
        "x": 30.67,
        "y": 25.19
    },
    {
        "x": 30.68,
        "y": 25.19
    },
    {
        "x": 30.69,
        "y": 25.19
    },
    {
        "x": 30.7,
        "y": 25.2
    },
    {
        "x": 30.71,
        "y": 25.2
    },
    {
        "x": 30.72,
        "y": 25.2
    },
    {
        "x": 30.73,
        "y": 25.2
    },
    {
        "x": 30.74,
        "y": 25.2
    },
    {
        "x": 30.75,
        "y": 25.2
    },
    {
        "x": 30.76,
        "y": 25.2
    },
    {
        "x": 30.77,
        "y": 25.2
    },
    {
        "x": 30.78,
        "y": 25.2
    },
    {
        "x": 30.79,
        "y": 25.2
    },
    {
        "x": 30.8,
        "y": 25.21
    },
    {
        "x": 30.81,
        "y": 25.2
    },
    {
        "x": 30.82,
        "y": 25.2
    },
    {
        "x": 30.83,
        "y": 25.2
    },
    {
        "x": 30.84,
        "y": 25.21
    },
    {
        "x": 30.85,
        "y": 25.21
    },
    {
        "x": 30.86,
        "y": 25.21
    },
    {
        "x": 30.87,
        "y": 25.21
    },
    {
        "x": 30.88,
        "y": 25.21
    },
    {
        "x": 30.89,
        "y": 25.21
    },
    {
        "x": 30.9,
        "y": 25.21
    },
    {
        "x": 30.91,
        "y": 25.21
    },
    {
        "x": 30.92,
        "y": 25.21
    },
    {
        "x": 30.93,
        "y": 25.21
    },
    {
        "x": 30.94,
        "y": 25.21
    },
    {
        "x": 30.95,
        "y": 25.21
    },
    {
        "x": 30.96,
        "y": 25.22
    },
    {
        "x": 30.97,
        "y": 25.22
    },
    {
        "x": 30.98,
        "y": 25.22
    },
    {
        "x": 30.99,
        "y": 25.22
    },
    {
        "x": 31,
        "y": 25.22
    },
    {
        "x": 31.01,
        "y": 25.22
    },
    {
        "x": 31.02,
        "y": 25.22
    },
    {
        "x": 31.03,
        "y": 25.22
    },
    {
        "x": 31.04,
        "y": 25.22
    },
    {
        "x": 31.05,
        "y": 25.22
    },
    {
        "x": 31.06,
        "y": 25.22
    },
    {
        "x": 31.07,
        "y": 25.22
    },
    {
        "x": 31.08,
        "y": 25.23
    },
    {
        "x": 31.09,
        "y": 25.23
    },
    {
        "x": 31.1,
        "y": 25.23
    },
    {
        "x": 31.11,
        "y": 25.23
    },
    {
        "x": 31.12,
        "y": 25.23
    },
    {
        "x": 31.13,
        "y": 25.23
    },
    {
        "x": 31.14,
        "y": 25.23
    },
    {
        "x": 31.15,
        "y": 25.23
    },
    {
        "x": 31.16,
        "y": 25.23
    },
    {
        "x": 31.17,
        "y": 25.24
    },
    {
        "x": 31.18,
        "y": 25.23
    },
    {
        "x": 31.19,
        "y": 25.23
    },
    {
        "x": 31.2,
        "y": 25.24
    },
    {
        "x": 31.21,
        "y": 25.24
    },
    {
        "x": 31.22,
        "y": 25.23
    },
    {
        "x": 31.23,
        "y": 25.24
    },
    {
        "x": 31.24,
        "y": 25.24
    },
    {
        "x": 31.25,
        "y": 25.24
    },
    {
        "x": 31.26,
        "y": 25.24
    },
    {
        "x": 31.27,
        "y": 25.24
    },
    {
        "x": 31.28,
        "y": 25.24
    },
    {
        "x": 31.29,
        "y": 25.24
    },
    {
        "x": 31.3,
        "y": 25.24
    },
    {
        "x": 31.31,
        "y": 25.24
    },
    {
        "x": 31.32,
        "y": 25.24
    },
    {
        "x": 31.33,
        "y": 25.25
    },
    {
        "x": 31.34,
        "y": 25.25
    },
    {
        "x": 31.35,
        "y": 25.25
    },
    {
        "x": 31.36,
        "y": 25.25
    },
    {
        "x": 31.37,
        "y": 25.25
    },
    {
        "x": 31.38,
        "y": 25.25
    },
    {
        "x": 31.39,
        "y": 25.25
    },
    {
        "x": 31.4,
        "y": 25.25
    },
    {
        "x": 31.41,
        "y": 25.25
    },
    {
        "x": 31.42,
        "y": 25.25
    },
    {
        "x": 31.43,
        "y": 25.25
    },
    {
        "x": 31.44,
        "y": 25.25
    },
    {
        "x": 31.45,
        "y": 25.25
    },
    {
        "x": 31.46,
        "y": 25.26
    },
    {
        "x": 31.47,
        "y": 25.26
    },
    {
        "x": 31.48,
        "y": 25.26
    },
    {
        "x": 31.49,
        "y": 25.26
    },
    {
        "x": 31.5,
        "y": 25.26
    },
    {
        "x": 31.51,
        "y": 25.26
    },
    {
        "x": 31.52,
        "y": 25.26
    },
    {
        "x": 31.53,
        "y": 25.26
    },
    {
        "x": 31.54,
        "y": 25.27
    },
    {
        "x": 31.55,
        "y": 25.27
    },
    {
        "x": 31.56,
        "y": 25.27
    },
    {
        "x": 31.57,
        "y": 25.27
    },
    {
        "x": 31.58,
        "y": 25.27
    },
    {
        "x": 31.59,
        "y": 25.27
    },
    {
        "x": 31.6,
        "y": 25.27
    },
    {
        "x": 31.61,
        "y": 25.27
    },
    {
        "x": 31.62,
        "y": 25.27
    },
    {
        "x": 31.63,
        "y": 25.27
    },
    {
        "x": 31.64,
        "y": 25.27
    },
    {
        "x": 31.65,
        "y": 25.27
    },
    {
        "x": 31.66,
        "y": 25.27
    },
    {
        "x": 31.67,
        "y": 25.28
    },
    {
        "x": 31.68,
        "y": 25.27
    },
    {
        "x": 31.69,
        "y": 25.27
    },
    {
        "x": 31.7,
        "y": 25.28
    },
    {
        "x": 31.71,
        "y": 25.28
    },
    {
        "x": 31.72,
        "y": 25.28
    },
    {
        "x": 31.73,
        "y": 25.28
    },
    {
        "x": 31.74,
        "y": 25.28
    },
    {
        "x": 31.75,
        "y": 25.28
    },
    {
        "x": 31.76,
        "y": 25.28
    },
    {
        "x": 31.77,
        "y": 25.28
    },
    {
        "x": 31.78,
        "y": 25.28
    },
    {
        "x": 31.79,
        "y": 25.28
    },
    {
        "x": 31.8,
        "y": 25.28
    },
    {
        "x": 31.81,
        "y": 25.28
    },
    {
        "x": 31.82,
        "y": 25.29
    },
    {
        "x": 31.83,
        "y": 25.29
    },
    {
        "x": 31.84,
        "y": 25.28
    },
    {
        "x": 31.85,
        "y": 25.29
    },
    {
        "x": 31.86,
        "y": 25.29
    },
    {
        "x": 31.87,
        "y": 25.29
    },
    {
        "x": 31.88,
        "y": 25.29
    },
    {
        "x": 31.89,
        "y": 25.29
    },
    {
        "x": 31.9,
        "y": 25.29
    },
    {
        "x": 31.91,
        "y": 25.29
    },
    {
        "x": 31.92,
        "y": 25.29
    },
    {
        "x": 31.93,
        "y": 25.29
    },
    {
        "x": 31.94,
        "y": 25.29
    },
    {
        "x": 31.95,
        "y": 25.29
    },
    {
        "x": 31.96,
        "y": 25.3
    },
    {
        "x": 31.97,
        "y": 25.3
    },
    {
        "x": 31.98,
        "y": 25.3
    },
    {
        "x": 31.99,
        "y": 25.3
    },
    {
        "x": 32,
        "y": 25.3
    },
    {
        "x": 32.01,
        "y": 25.3
    },
    {
        "x": 32.02,
        "y": 25.3
    },
    {
        "x": 32.03,
        "y": 25.3
    },
    {
        "x": 32.04,
        "y": 25.3
    },
    {
        "x": 32.05,
        "y": 25.3
    },
    {
        "x": 32.06,
        "y": 25.3
    },
    {
        "x": 32.07,
        "y": 25.3
    },
    {
        "x": 32.08,
        "y": 25.3
    },
    {
        "x": 32.09,
        "y": 25.3
    },
    {
        "x": 32.1,
        "y": 25.3
    },
    {
        "x": 32.11,
        "y": 25.3
    },
    {
        "x": 32.12,
        "y": 25.3
    },
    {
        "x": 32.13,
        "y": 25.31
    },
    {
        "x": 32.14,
        "y": 25.31
    },
    {
        "x": 32.15,
        "y": 25.31
    },
    {
        "x": 32.16,
        "y": 25.31
    },
    {
        "x": 32.17,
        "y": 25.31
    },
    {
        "x": 32.18,
        "y": 25.31
    },
    {
        "x": 32.19,
        "y": 25.31
    },
    {
        "x": 32.2,
        "y": 25.31
    },
    {
        "x": 32.21,
        "y": 25.31
    },
    {
        "x": 32.22,
        "y": 25.31
    },
    {
        "x": 32.23,
        "y": 25.31
    },
    {
        "x": 32.24,
        "y": 25.31
    },
    {
        "x": 32.25,
        "y": 25.31
    },
    {
        "x": 32.26,
        "y": 25.31
    },
    {
        "x": 32.27,
        "y": 25.31
    },
    {
        "x": 32.28,
        "y": 25.32
    },
    {
        "x": 32.29,
        "y": 25.32
    },
    {
        "x": 32.3,
        "y": 25.32
    },
    {
        "x": 32.31,
        "y": 25.32
    },
    {
        "x": 32.32,
        "y": 25.32
    },
    {
        "x": 32.33,
        "y": 25.32
    },
    {
        "x": 32.34,
        "y": 25.32
    },
    {
        "x": 32.35,
        "y": 25.32
    },
    {
        "x": 32.36,
        "y": 25.32
    },
    {
        "x": 32.37,
        "y": 25.32
    },
    {
        "x": 32.38,
        "y": 25.33
    },
    {
        "x": 32.39,
        "y": 25.33
    },
    {
        "x": 32.4,
        "y": 25.32
    },
    {
        "x": 32.41,
        "y": 25.33
    },
    {
        "x": 32.42,
        "y": 25.33
    },
    {
        "x": 32.43,
        "y": 25.33
    },
    {
        "x": 32.44,
        "y": 25.33
    },
    {
        "x": 32.45,
        "y": 25.33
    },
    {
        "x": 32.46,
        "y": 25.33
    },
    {
        "x": 32.47,
        "y": 25.33
    },
    {
        "x": 32.48,
        "y": 25.33
    },
    {
        "x": 32.49,
        "y": 25.33
    },
    {
        "x": 32.5,
        "y": 25.34
    },
    {
        "x": 32.51,
        "y": 25.33
    },
    {
        "x": 32.52,
        "y": 25.33
    },
    {
        "x": 32.53,
        "y": 25.33
    },
    {
        "x": 32.54,
        "y": 25.34
    },
    {
        "x": 32.55,
        "y": 25.33
    },
    {
        "x": 32.56,
        "y": 25.34
    },
    {
        "x": 32.57,
        "y": 25.34
    },
    {
        "x": 32.58,
        "y": 25.34
    },
    {
        "x": 32.59,
        "y": 25.34
    },
    {
        "x": 32.6,
        "y": 25.34
    },
    {
        "x": 32.61,
        "y": 25.34
    },
    {
        "x": 32.62,
        "y": 25.34
    },
    {
        "x": 32.63,
        "y": 25.34
    },
    {
        "x": 32.64,
        "y": 25.34
    },
    {
        "x": 32.65,
        "y": 25.34
    },
    {
        "x": 32.66,
        "y": 25.34
    },
    {
        "x": 32.67,
        "y": 25.34
    },
    {
        "x": 32.68,
        "y": 25.35
    },
    {
        "x": 32.69,
        "y": 25.35
    },
    {
        "x": 32.7,
        "y": 25.35
    },
    {
        "x": 32.71,
        "y": 25.35
    },
    {
        "x": 32.72,
        "y": 25.35
    },
    {
        "x": 32.73,
        "y": 25.35
    },
    {
        "x": 32.74,
        "y": 25.35
    },
    {
        "x": 32.75,
        "y": 25.35
    },
    {
        "x": 32.76,
        "y": 25.35
    },
    {
        "x": 32.77,
        "y": 25.35
    },
    {
        "x": 32.78,
        "y": 25.35
    },
    {
        "x": 32.79,
        "y": 25.35
    },
    {
        "x": 32.8,
        "y": 25.35
    },
    {
        "x": 32.81,
        "y": 25.35
    },
    {
        "x": 32.82,
        "y": 25.36
    },
    {
        "x": 32.83,
        "y": 25.36
    },
    {
        "x": 32.84,
        "y": 25.36
    },
    {
        "x": 32.85,
        "y": 25.36
    },
    {
        "x": 32.86,
        "y": 25.36
    },
    {
        "x": 32.87,
        "y": 25.36
    },
    {
        "x": 32.88,
        "y": 25.36
    },
    {
        "x": 32.89,
        "y": 25.36
    },
    {
        "x": 32.9,
        "y": 25.36
    },
    {
        "x": 32.91,
        "y": 25.36
    },
    {
        "x": 32.92,
        "y": 25.36
    },
    {
        "x": 32.93,
        "y": 25.36
    },
    {
        "x": 32.94,
        "y": 25.36
    },
    {
        "x": 32.95,
        "y": 25.36
    },
    {
        "x": 32.96,
        "y": 25.36
    },
    {
        "x": 32.97,
        "y": 25.36
    },
    {
        "x": 32.98,
        "y": 25.36
    },
    {
        "x": 32.99,
        "y": 25.37
    },
    {
        "x": 33,
        "y": 25.37
    },
    {
        "x": 33.01,
        "y": 25.37
    },
    {
        "x": 33.02,
        "y": 25.37
    },
    {
        "x": 33.03,
        "y": 25.37
    },
    {
        "x": 33.04,
        "y": 25.37
    },
    {
        "x": 33.05,
        "y": 25.37
    },
    {
        "x": 33.06,
        "y": 25.37
    },
    {
        "x": 33.07,
        "y": 25.37
    },
    {
        "x": 33.08,
        "y": 25.37
    },
    {
        "x": 33.09,
        "y": 25.37
    },
    {
        "x": 33.1,
        "y": 25.38
    },
    {
        "x": 33.11,
        "y": 25.37
    },
    {
        "x": 33.12,
        "y": 25.37
    },
    {
        "x": 33.13,
        "y": 25.37
    },
    {
        "x": 33.14,
        "y": 25.38
    },
    {
        "x": 33.15,
        "y": 25.38
    },
    {
        "x": 33.16,
        "y": 25.38
    },
    {
        "x": 33.17,
        "y": 25.38
    },
    {
        "x": 33.18,
        "y": 25.38
    },
    {
        "x": 33.19,
        "y": 25.38
    },
    {
        "x": 33.2,
        "y": 25.38
    },
    {
        "x": 33.21,
        "y": 25.38
    },
    {
        "x": 33.22,
        "y": 25.38
    },
    {
        "x": 33.23,
        "y": 25.38
    },
    {
        "x": 33.24,
        "y": 25.38
    },
    {
        "x": 33.25,
        "y": 25.38
    },
    {
        "x": 33.26,
        "y": 25.38
    },
    {
        "x": 33.27,
        "y": 25.38
    },
    {
        "x": 33.28,
        "y": 25.38
    },
    {
        "x": 33.29,
        "y": 25.38
    },
    {
        "x": 33.3,
        "y": 25.38
    },
    {
        "x": 33.31,
        "y": 25.39
    },
    {
        "x": 33.32,
        "y": 25.38
    },
    {
        "x": 33.33,
        "y": 25.39
    },
    {
        "x": 33.34,
        "y": 25.39
    },
    {
        "x": 33.35,
        "y": 25.39
    },
    {
        "x": 33.36,
        "y": 25.39
    },
    {
        "x": 33.37,
        "y": 25.39
    },
    {
        "x": 33.38,
        "y": 25.39
    },
    {
        "x": 33.39,
        "y": 25.39
    },
    {
        "x": 33.4,
        "y": 25.39
    },
    {
        "x": 33.41,
        "y": 25.39
    },
    {
        "x": 33.42,
        "y": 25.39
    },
    {
        "x": 33.43,
        "y": 25.39
    },
    {
        "x": 33.44,
        "y": 25.39
    },
    {
        "x": 33.45,
        "y": 25.39
    },
    {
        "x": 33.46,
        "y": 25.39
    },
    {
        "x": 33.47,
        "y": 25.39
    },
    {
        "x": 33.48,
        "y": 25.39
    },
    {
        "x": 33.49,
        "y": 25.4
    },
    {
        "x": 33.5,
        "y": 25.4
    },
    {
        "x": 33.51,
        "y": 25.4
    },
    {
        "x": 33.52,
        "y": 25.4
    },
    {
        "x": 33.53,
        "y": 25.4
    },
    {
        "x": 33.54,
        "y": 25.4
    },
    {
        "x": 33.55,
        "y": 25.4
    },
    {
        "x": 33.56,
        "y": 25.4
    },
    {
        "x": 33.57,
        "y": 25.4
    },
    {
        "x": 33.58,
        "y": 25.4
    },
    {
        "x": 33.59,
        "y": 25.4
    },
    {
        "x": 33.6,
        "y": 25.4
    },
    {
        "x": 33.61,
        "y": 25.4
    },
    {
        "x": 33.62,
        "y": 25.4
    },
    {
        "x": 33.63,
        "y": 25.4
    },
    {
        "x": 33.64,
        "y": 25.4
    },
    {
        "x": 33.65,
        "y": 25.4
    },
    {
        "x": 33.66,
        "y": 25.41
    },
    {
        "x": 33.67,
        "y": 25.41
    },
    {
        "x": 33.68,
        "y": 25.41
    },
    {
        "x": 33.69,
        "y": 25.41
    },
    {
        "x": 33.7,
        "y": 25.41
    },
    {
        "x": 33.71,
        "y": 25.41
    },
    {
        "x": 33.72,
        "y": 25.41
    },
    {
        "x": 33.73,
        "y": 25.41
    },
    {
        "x": 33.74,
        "y": 25.41
    },
    {
        "x": 33.75,
        "y": 25.41
    },
    {
        "x": 33.76,
        "y": 25.41
    },
    {
        "x": 33.77,
        "y": 25.41
    },
    {
        "x": 33.78,
        "y": 25.41
    },
    {
        "x": 33.79,
        "y": 25.41
    },
    {
        "x": 33.8,
        "y": 25.42
    },
    {
        "x": 33.81,
        "y": 25.41
    },
    {
        "x": 33.82,
        "y": 25.41
    },
    {
        "x": 33.83,
        "y": 25.41
    },
    {
        "x": 33.84,
        "y": 25.41
    },
    {
        "x": 33.85,
        "y": 25.41
    },
    {
        "x": 33.86,
        "y": 25.42
    },
    {
        "x": 33.87,
        "y": 25.42
    },
    {
        "x": 33.88,
        "y": 25.42
    },
    {
        "x": 33.89,
        "y": 25.42
    },
    {
        "x": 33.9,
        "y": 25.42
    },
    {
        "x": 33.91,
        "y": 25.42
    },
    {
        "x": 33.92,
        "y": 25.42
    },
    {
        "x": 33.93,
        "y": 25.42
    },
    {
        "x": 33.94,
        "y": 25.42
    },
    {
        "x": 33.95,
        "y": 25.42
    },
    {
        "x": 33.96,
        "y": 25.43
    },
    {
        "x": 33.97,
        "y": 25.43
    },
    {
        "x": 33.98,
        "y": 25.42
    },
    {
        "x": 33.99,
        "y": 25.42
    },
    {
        "x": 34,
        "y": 25.43
    },
    {
        "x": 34.01,
        "y": 25.43
    },
    {
        "x": 34.02,
        "y": 25.43
    },
    {
        "x": 34.03,
        "y": 25.43
    },
    {
        "x": 34.04,
        "y": 25.43
    },
    {
        "x": 34.05,
        "y": 25.43
    },
    {
        "x": 34.06,
        "y": 25.43
    },
    {
        "x": 34.07,
        "y": 25.43
    },
    {
        "x": 34.08,
        "y": 25.43
    },
    {
        "x": 34.09,
        "y": 25.43
    },
    {
        "x": 34.1,
        "y": 25.43
    },
    {
        "x": 34.11,
        "y": 25.43
    },
    {
        "x": 34.12,
        "y": 25.43
    },
    {
        "x": 34.13,
        "y": 25.43
    },
    {
        "x": 34.14,
        "y": 25.43
    },
    {
        "x": 34.15,
        "y": 25.43
    },
    {
        "x": 34.16,
        "y": 25.43
    },
    {
        "x": 34.17,
        "y": 25.43
    },
    {
        "x": 34.18,
        "y": 25.43
    },
    {
        "x": 34.19,
        "y": 25.43
    },
    {
        "x": 34.2,
        "y": 25.43
    },
    {
        "x": 34.21,
        "y": 25.43
    },
    {
        "x": 34.22,
        "y": 25.44
    },
    {
        "x": 34.23,
        "y": 25.44
    },
    {
        "x": 34.24,
        "y": 25.44
    },
    {
        "x": 34.25,
        "y": 25.44
    },
    {
        "x": 34.26,
        "y": 25.44
    },
    {
        "x": 34.27,
        "y": 25.44
    },
    {
        "x": 34.28,
        "y": 25.43
    },
    {
        "x": 34.29,
        "y": 25.44
    },
    {
        "x": 34.3,
        "y": 25.44
    },
    {
        "x": 34.31,
        "y": 25.44
    },
    {
        "x": 34.32,
        "y": 25.44
    },
    {
        "x": 34.33,
        "y": 25.44
    },
    {
        "x": 34.34,
        "y": 25.44
    },
    {
        "x": 34.35,
        "y": 25.44
    },
    {
        "x": 34.36,
        "y": 25.44
    },
    {
        "x": 34.37,
        "y": 25.44
    },
    {
        "x": 34.38,
        "y": 25.44
    },
    {
        "x": 34.39,
        "y": 25.44
    },
    {
        "x": 34.4,
        "y": 25.44
    },
    {
        "x": 34.41,
        "y": 25.44
    },
    {
        "x": 34.42,
        "y": 25.44
    },
    {
        "x": 34.43,
        "y": 25.44
    },
    {
        "x": 34.44,
        "y": 25.44
    },
    {
        "x": 34.45,
        "y": 25.44
    },
    {
        "x": 34.46,
        "y": 25.45
    },
    {
        "x": 34.47,
        "y": 25.45
    },
    {
        "x": 34.48,
        "y": 25.45
    },
    {
        "x": 34.49,
        "y": 25.45
    },
    {
        "x": 34.5,
        "y": 25.45
    },
    {
        "x": 34.51,
        "y": 25.45
    },
    {
        "x": 34.52,
        "y": 25.45
    },
    {
        "x": 34.53,
        "y": 25.45
    },
    {
        "x": 34.54,
        "y": 25.45
    },
    {
        "x": 34.55,
        "y": 25.45
    },
    {
        "x": 34.56,
        "y": 25.45
    },
    {
        "x": 34.57,
        "y": 25.45
    },
    {
        "x": 34.58,
        "y": 25.45
    },
    {
        "x": 34.59,
        "y": 25.45
    },
    {
        "x": 34.6,
        "y": 25.45
    },
    {
        "x": 34.61,
        "y": 25.46
    },
    {
        "x": 34.62,
        "y": 25.46
    },
    {
        "x": 34.63,
        "y": 25.46
    },
    {
        "x": 34.64,
        "y": 25.46
    },
    {
        "x": 34.65,
        "y": 25.46
    },
    {
        "x": 34.66,
        "y": 25.46
    },
    {
        "x": 34.67,
        "y": 25.46
    },
    {
        "x": 34.68,
        "y": 25.46
    },
    {
        "x": 34.69,
        "y": 25.46
    },
    {
        "x": 34.7,
        "y": 25.46
    },
    {
        "x": 34.71,
        "y": 25.46
    },
    {
        "x": 34.72,
        "y": 25.46
    },
    {
        "x": 34.73,
        "y": 25.46
    },
    {
        "x": 34.74,
        "y": 25.46
    },
    {
        "x": 34.75,
        "y": 25.46
    },
    {
        "x": 34.76,
        "y": 25.46
    },
    {
        "x": 34.77,
        "y": 25.46
    },
    {
        "x": 34.78,
        "y": 25.46
    },
    {
        "x": 34.79,
        "y": 25.46
    },
    {
        "x": 34.8,
        "y": 25.46
    },
    {
        "x": 34.81,
        "y": 25.46
    },
    {
        "x": 34.82,
        "y": 25.47
    },
    {
        "x": 34.83,
        "y": 25.46
    },
    {
        "x": 34.84,
        "y": 25.46
    },
    {
        "x": 34.85,
        "y": 25.46
    },
    {
        "x": 34.86,
        "y": 25.47
    },
    {
        "x": 34.87,
        "y": 25.47
    },
    {
        "x": 34.88,
        "y": 25.46
    },
    {
        "x": 34.89,
        "y": 25.47
    },
    {
        "x": 34.9,
        "y": 25.47
    },
    {
        "x": 34.91,
        "y": 25.47
    },
    {
        "x": 34.92,
        "y": 25.47
    },
    {
        "x": 34.93,
        "y": 25.47
    },
    {
        "x": 34.94,
        "y": 25.47
    },
    {
        "x": 34.95,
        "y": 25.47
    },
    {
        "x": 34.96,
        "y": 25.47
    },
    {
        "x": 34.97,
        "y": 25.47
    },
    {
        "x": 34.98,
        "y": 25.47
    },
    {
        "x": 34.99,
        "y": 25.47
    },
    {
        "x": 35,
        "y": 25.47
    },
    {
        "x": 35.01,
        "y": 25.48
    },
    {
        "x": 35.02,
        "y": 25.47
    },
    {
        "x": 35.03,
        "y": 25.47
    },
    {
        "x": 35.04,
        "y": 25.47
    },
    {
        "x": 35.05,
        "y": 25.47
    },
    {
        "x": 35.06,
        "y": 25.48
    },
    {
        "x": 35.07,
        "y": 25.48
    },
    {
        "x": 35.08,
        "y": 25.48
    },
    {
        "x": 35.09,
        "y": 25.48
    },
    {
        "x": 35.1,
        "y": 25.48
    },
    {
        "x": 35.11,
        "y": 25.48
    },
    {
        "x": 35.12,
        "y": 25.48
    },
    {
        "x": 35.13,
        "y": 25.48
    },
    {
        "x": 35.14,
        "y": 25.48
    },
    {
        "x": 35.15,
        "y": 25.49
    },
    {
        "x": 35.16,
        "y": 25.48
    },
    {
        "x": 35.17,
        "y": 25.48
    },
    {
        "x": 35.18,
        "y": 25.48
    },
    {
        "x": 35.19,
        "y": 25.49
    },
    {
        "x": 35.2,
        "y": 25.48
    },
    {
        "x": 35.21,
        "y": 25.48
    },
    {
        "x": 35.22,
        "y": 25.49
    },
    {
        "x": 35.23,
        "y": 25.48
    },
    {
        "x": 35.24,
        "y": 25.49
    },
    {
        "x": 35.25,
        "y": 25.49
    },
    {
        "x": 35.26,
        "y": 25.49
    },
    {
        "x": 35.27,
        "y": 25.49
    },
    {
        "x": 35.28,
        "y": 25.49
    },
    {
        "x": 35.29,
        "y": 25.49
    },
    {
        "x": 35.3,
        "y": 25.49
    },
    {
        "x": 35.31,
        "y": 25.49
    },
    {
        "x": 35.32,
        "y": 25.49
    },
    {
        "x": 35.33,
        "y": 25.49
    },
    {
        "x": 35.34,
        "y": 25.49
    },
    {
        "x": 35.35,
        "y": 25.49
    },
    {
        "x": 35.36,
        "y": 25.49
    },
    {
        "x": 35.37,
        "y": 25.49
    },
    {
        "x": 35.38,
        "y": 25.49
    },
    {
        "x": 35.39,
        "y": 25.49
    },
    {
        "x": 35.4,
        "y": 25.49
    },
    {
        "x": 35.41,
        "y": 25.49
    },
    {
        "x": 35.42,
        "y": 25.5
    },
    {
        "x": 35.43,
        "y": 25.49
    },
    {
        "x": 35.44,
        "y": 25.49
    },
    {
        "x": 35.45,
        "y": 25.49
    },
    {
        "x": 35.46,
        "y": 25.5
    },
    {
        "x": 35.47,
        "y": 25.49
    },
    {
        "x": 35.48,
        "y": 25.49
    },
    {
        "x": 35.49,
        "y": 25.49
    },
    {
        "x": 35.5,
        "y": 25.49
    },
    {
        "x": 35.51,
        "y": 25.5
    },
    {
        "x": 35.52,
        "y": 25.5
    },
    {
        "x": 35.53,
        "y": 25.5
    },
    {
        "x": 35.54,
        "y": 25.5
    },
    {
        "x": 35.55,
        "y": 25.5
    },
    {
        "x": 35.56,
        "y": 25.5
    },
    {
        "x": 35.57,
        "y": 25.5
    },
    {
        "x": 35.58,
        "y": 25.5
    },
    {
        "x": 35.59,
        "y": 25.5
    },
    {
        "x": 35.6,
        "y": 25.5
    },
    {
        "x": 35.61,
        "y": 25.5
    },
    {
        "x": 35.62,
        "y": 25.5
    },
    {
        "x": 35.63,
        "y": 25.5
    },
    {
        "x": 35.64,
        "y": 25.5
    },
    {
        "x": 35.65,
        "y": 25.5
    },
    {
        "x": 35.66,
        "y": 25.5
    },
    {
        "x": 35.67,
        "y": 25.5
    },
    {
        "x": 35.68,
        "y": 25.5
    },
    {
        "x": 35.69,
        "y": 25.5
    },
    {
        "x": 35.7,
        "y": 25.51
    },
    {
        "x": 35.71,
        "y": 25.5
    },
    {
        "x": 35.72,
        "y": 25.51
    },
    {
        "x": 35.73,
        "y": 25.51
    },
    {
        "x": 35.74,
        "y": 25.5
    },
    {
        "x": 35.75,
        "y": 25.5
    },
    {
        "x": 35.76,
        "y": 25.5
    },
    {
        "x": 35.77,
        "y": 25.5
    },
    {
        "x": 35.78,
        "y": 25.51
    },
    {
        "x": 35.79,
        "y": 25.51
    },
    {
        "x": 35.8,
        "y": 25.51
    },
    {
        "x": 35.81,
        "y": 25.51
    },
    {
        "x": 35.82,
        "y": 25.51
    },
    {
        "x": 35.83,
        "y": 25.51
    },
    {
        "x": 35.84,
        "y": 25.51
    },
    {
        "x": 35.85,
        "y": 25.51
    },
    {
        "x": 35.86,
        "y": 25.51
    },
    {
        "x": 35.87,
        "y": 25.51
    },
    {
        "x": 35.88,
        "y": 25.51
    },
    {
        "x": 35.89,
        "y": 25.51
    },
    {
        "x": 35.9,
        "y": 25.51
    },
    {
        "x": 35.91,
        "y": 25.51
    },
    {
        "x": 35.92,
        "y": 25.51
    },
    {
        "x": 35.93,
        "y": 25.51
    },
    {
        "x": 35.94,
        "y": 25.52
    },
    {
        "x": 35.95,
        "y": 25.51
    },
    {
        "x": 35.96,
        "y": 25.51
    },
    {
        "x": 35.97,
        "y": 25.51
    },
    {
        "x": 35.98,
        "y": 25.52
    },
    {
        "x": 35.99,
        "y": 25.52
    },
    {
        "x": 36,
        "y": 25.51
    },
    {
        "x": 36.01,
        "y": 25.51
    },
    {
        "x": 36.02,
        "y": 25.51
    },
    {
        "x": 36.03,
        "y": 25.52
    },
    {
        "x": 36.04,
        "y": 25.52
    },
    {
        "x": 36.05,
        "y": 25.52
    },
    {
        "x": 36.06,
        "y": 25.51
    },
    {
        "x": 36.07,
        "y": 25.52
    },
    {
        "x": 36.08,
        "y": 25.52
    },
    {
        "x": 36.09,
        "y": 25.52
    },
    {
        "x": 36.1,
        "y": 25.52
    },
    {
        "x": 36.11,
        "y": 25.52
    },
    {
        "x": 36.12,
        "y": 25.52
    },
    {
        "x": 36.13,
        "y": 25.52
    },
    {
        "x": 36.14,
        "y": 25.52
    },
    {
        "x": 36.15,
        "y": 25.52
    },
    {
        "x": 36.16,
        "y": 25.52
    },
    {
        "x": 36.17,
        "y": 25.52
    },
    {
        "x": 36.18,
        "y": 25.52
    },
    {
        "x": 36.19,
        "y": 25.52
    },
    {
        "x": 36.2,
        "y": 25.52
    },
    {
        "x": 36.21,
        "y": 25.52
    },
    {
        "x": 36.22,
        "y": 25.52
    },
    {
        "x": 36.23,
        "y": 25.52
    },
    {
        "x": 36.24,
        "y": 25.52
    },
    {
        "x": 36.25,
        "y": 25.52
    },
    {
        "x": 36.26,
        "y": 25.52
    },
    {
        "x": 36.27,
        "y": 25.52
    },
    {
        "x": 36.28,
        "y": 25.52
    },
    {
        "x": 36.29,
        "y": 25.53
    },
    {
        "x": 36.3,
        "y": 25.53
    },
    {
        "x": 36.31,
        "y": 25.53
    },
    {
        "x": 36.32,
        "y": 25.52
    },
    {
        "x": 36.33,
        "y": 25.52
    },
    {
        "x": 36.34,
        "y": 25.53
    },
    {
        "x": 36.35,
        "y": 25.53
    },
    {
        "x": 36.36,
        "y": 25.53
    },
    {
        "x": 36.37,
        "y": 25.53
    },
    {
        "x": 36.38,
        "y": 25.53
    },
    {
        "x": 36.39,
        "y": 25.53
    },
    {
        "x": 36.4,
        "y": 25.53
    },
    {
        "x": 36.41,
        "y": 25.53
    },
    {
        "x": 36.42,
        "y": 25.53
    },
    {
        "x": 36.43,
        "y": 25.53
    },
    {
        "x": 36.44,
        "y": 25.53
    },
    {
        "x": 36.45,
        "y": 25.53
    },
    {
        "x": 36.46,
        "y": 25.53
    },
    {
        "x": 36.47,
        "y": 25.53
    },
    {
        "x": 36.48,
        "y": 25.53
    },
    {
        "x": 36.49,
        "y": 25.53
    },
    {
        "x": 36.5,
        "y": 25.53
    },
    {
        "x": 36.51,
        "y": 25.54
    },
    {
        "x": 36.52,
        "y": 25.53
    },
    {
        "x": 36.53,
        "y": 25.54
    },
    {
        "x": 36.54,
        "y": 25.53
    },
    {
        "x": 36.55,
        "y": 25.53
    },
    {
        "x": 36.56,
        "y": 25.53
    },
    {
        "x": 36.57,
        "y": 25.54
    },
    {
        "x": 36.58,
        "y": 25.53
    },
    {
        "x": 36.59,
        "y": 25.53
    },
    {
        "x": 36.6,
        "y": 25.54
    },
    {
        "x": 36.61,
        "y": 25.54
    },
    {
        "x": 36.62,
        "y": 25.54
    },
    {
        "x": 36.63,
        "y": 25.54
    },
    {
        "x": 36.64,
        "y": 25.54
    },
    {
        "x": 36.65,
        "y": 25.54
    },
    {
        "x": 36.66,
        "y": 25.54
    },
    {
        "x": 36.67,
        "y": 25.54
    },
    {
        "x": 36.68,
        "y": 25.54
    },
    {
        "x": 36.69,
        "y": 25.54
    },
    {
        "x": 36.7,
        "y": 25.54
    },
    {
        "x": 36.71,
        "y": 25.54
    },
    {
        "x": 36.72,
        "y": 25.54
    },
    {
        "x": 36.73,
        "y": 25.54
    },
    {
        "x": 36.74,
        "y": 25.54
    },
    {
        "x": 36.75,
        "y": 25.54
    },
    {
        "x": 36.76,
        "y": 25.54
    },
    {
        "x": 36.77,
        "y": 25.54
    },
    {
        "x": 36.78,
        "y": 25.54
    },
    {
        "x": 36.79,
        "y": 25.54
    },
    {
        "x": 36.8,
        "y": 25.54
    },
    {
        "x": 36.81,
        "y": 25.54
    },
    {
        "x": 36.82,
        "y": 25.54
    },
    {
        "x": 36.83,
        "y": 25.54
    },
    {
        "x": 36.84,
        "y": 25.54
    },
    {
        "x": 36.85,
        "y": 25.54
    },
    {
        "x": 36.86,
        "y": 25.54
    },
    {
        "x": 36.87,
        "y": 25.54
    },
    {
        "x": 36.88,
        "y": 25.54
    },
    {
        "x": 36.89,
        "y": 25.54
    },
    {
        "x": 36.9,
        "y": 25.54
    },
    {
        "x": 36.91,
        "y": 25.54
    },
    {
        "x": 36.92,
        "y": 25.54
    },
    {
        "x": 36.93,
        "y": 25.55
    },
    {
        "x": 36.94,
        "y": 25.54
    },
    {
        "x": 36.95,
        "y": 25.54
    },
    {
        "x": 36.96,
        "y": 25.54
    },
    {
        "x": 36.97,
        "y": 25.55
    },
    {
        "x": 36.98,
        "y": 25.54
    },
    {
        "x": 36.99,
        "y": 25.54
    },
    {
        "x": 37,
        "y": 25.54
    },
    {
        "x": 37.01,
        "y": 25.55
    },
    {
        "x": 37.02,
        "y": 25.55
    },
    {
        "x": 37.03,
        "y": 25.55
    },
    {
        "x": 37.04,
        "y": 25.54
    },
    {
        "x": 37.05,
        "y": 25.55
    },
    {
        "x": 37.06,
        "y": 25.55
    },
    {
        "x": 37.07,
        "y": 25.55
    },
    {
        "x": 37.08,
        "y": 25.55
    },
    {
        "x": 37.09,
        "y": 25.54
    },
    {
        "x": 37.1,
        "y": 25.54
    },
    {
        "x": 37.11,
        "y": 25.55
    },
    {
        "x": 37.12,
        "y": 25.55
    },
    {
        "x": 37.13,
        "y": 25.55
    },
    {
        "x": 37.14,
        "y": 25.55
    },
    {
        "x": 37.15,
        "y": 25.54
    },
    {
        "x": 37.16,
        "y": 25.55
    },
    {
        "x": 37.17,
        "y": 25.55
    },
    {
        "x": 37.18,
        "y": 25.55
    },
    {
        "x": 37.19,
        "y": 25.55
    },
    {
        "x": 37.2,
        "y": 25.55
    },
    {
        "x": 37.21,
        "y": 25.55
    },
    {
        "x": 37.22,
        "y": 25.55
    },
    {
        "x": 37.23,
        "y": 25.55
    },
    {
        "x": 37.24,
        "y": 25.55
    },
    {
        "x": 37.25,
        "y": 25.55
    },
    {
        "x": 37.26,
        "y": 25.55
    },
    {
        "x": 37.27,
        "y": 25.55
    },
    {
        "x": 37.28,
        "y": 25.55
    },
    {
        "x": 37.29,
        "y": 25.55
    },
    {
        "x": 37.3,
        "y": 25.55
    },
    {
        "x": 37.31,
        "y": 25.55
    },
    {
        "x": 37.32,
        "y": 25.55
    },
    {
        "x": 37.33,
        "y": 25.55
    },
    {
        "x": 37.34,
        "y": 25.55
    },
    {
        "x": 37.35,
        "y": 25.56
    },
    {
        "x": 37.36,
        "y": 25.56
    },
    {
        "x": 37.37,
        "y": 25.55
    },
    {
        "x": 37.38,
        "y": 25.55
    },
    {
        "x": 37.39,
        "y": 25.55
    },
    {
        "x": 37.4,
        "y": 25.55
    },
    {
        "x": 37.41,
        "y": 25.56
    },
    {
        "x": 37.42,
        "y": 25.56
    },
    {
        "x": 37.43,
        "y": 25.56
    },
    {
        "x": 37.44,
        "y": 25.56
    },
    {
        "x": 37.45,
        "y": 25.56
    },
    {
        "x": 37.46,
        "y": 25.56
    },
    {
        "x": 37.47,
        "y": 25.56
    },
    {
        "x": 37.48,
        "y": 25.56
    },
    {
        "x": 37.49,
        "y": 25.56
    },
    {
        "x": 37.5,
        "y": 25.56
    },
    {
        "x": 37.51,
        "y": 25.56
    },
    {
        "x": 37.52,
        "y": 25.56
    },
    {
        "x": 37.53,
        "y": 25.56
    },
    {
        "x": 37.54,
        "y": 25.56
    },
    {
        "x": 37.55,
        "y": 25.56
    },
    {
        "x": 37.56,
        "y": 25.56
    },
    {
        "x": 37.57,
        "y": 25.56
    },
    {
        "x": 37.58,
        "y": 25.56
    },
    {
        "x": 37.59,
        "y": 25.56
    },
    {
        "x": 37.6,
        "y": 25.56
    },
    {
        "x": 37.61,
        "y": 25.56
    },
    {
        "x": 37.62,
        "y": 25.56
    },
    {
        "x": 37.63,
        "y": 25.56
    },
    {
        "x": 37.64,
        "y": 25.57
    },
    {
        "x": 37.65,
        "y": 25.57
    },
    {
        "x": 37.66,
        "y": 25.56
    },
    {
        "x": 37.67,
        "y": 25.57
    },
    {
        "x": 37.68,
        "y": 25.56
    },
    {
        "x": 37.69,
        "y": 25.56
    },
    {
        "x": 37.7,
        "y": 25.57
    },
    {
        "x": 37.71,
        "y": 25.57
    },
    {
        "x": 37.72,
        "y": 25.57
    },
    {
        "x": 37.73,
        "y": 25.57
    },
    {
        "x": 37.74,
        "y": 25.57
    },
    {
        "x": 37.75,
        "y": 25.57
    },
    {
        "x": 37.76,
        "y": 25.57
    },
    {
        "x": 37.77,
        "y": 25.57
    },
    {
        "x": 37.78,
        "y": 25.57
    },
    {
        "x": 37.79,
        "y": 25.57
    },
    {
        "x": 37.8,
        "y": 25.56
    },
    {
        "x": 37.81,
        "y": 25.57
    },
    {
        "x": 37.82,
        "y": 25.57
    },
    {
        "x": 37.83,
        "y": 25.57
    },
    {
        "x": 37.84,
        "y": 25.57
    },
    {
        "x": 37.85,
        "y": 25.57
    },
    {
        "x": 37.86,
        "y": 25.57
    },
    {
        "x": 37.87,
        "y": 25.57
    },
    {
        "x": 37.88,
        "y": 25.57
    },
    {
        "x": 37.89,
        "y": 25.57
    },
    {
        "x": 37.9,
        "y": 25.57
    },
    {
        "x": 37.91,
        "y": 25.57
    },
    {
        "x": 37.92,
        "y": 25.57
    },
    {
        "x": 37.93,
        "y": 25.57
    },
    {
        "x": 37.94,
        "y": 25.57
    },
    {
        "x": 37.95,
        "y": 25.57
    },
    {
        "x": 37.96,
        "y": 25.57
    },
    {
        "x": 37.97,
        "y": 25.57
    },
    {
        "x": 37.98,
        "y": 25.57
    },
    {
        "x": 37.99,
        "y": 25.57
    },
    {
        "x": 38,
        "y": 25.57
    },
    {
        "x": 38.01,
        "y": 25.57
    },
    {
        "x": 38.02,
        "y": 25.57
    },
    {
        "x": 38.03,
        "y": 25.57
    },
    {
        "x": 38.04,
        "y": 25.57
    },
    {
        "x": 38.05,
        "y": 25.57
    },
    {
        "x": 38.06,
        "y": 25.57
    },
    {
        "x": 38.07,
        "y": 25.57
    },
    {
        "x": 38.08,
        "y": 25.57
    },
    {
        "x": 38.09,
        "y": 25.57
    },
    {
        "x": 38.1,
        "y": 25.57
    },
    {
        "x": 38.11,
        "y": 25.57
    },
    {
        "x": 38.12,
        "y": 25.57
    },
    {
        "x": 38.13,
        "y": 25.57
    },
    {
        "x": 38.14,
        "y": 25.58
    },
    {
        "x": 38.15,
        "y": 25.57
    },
    {
        "x": 38.16,
        "y": 25.58
    },
    {
        "x": 38.17,
        "y": 25.58
    },
    {
        "x": 38.18,
        "y": 25.58
    },
    {
        "x": 38.19,
        "y": 25.57
    },
    {
        "x": 38.2,
        "y": 25.58
    },
    {
        "x": 38.21,
        "y": 25.58
    },
    {
        "x": 38.22,
        "y": 25.58
    },
    {
        "x": 38.23,
        "y": 25.58
    },
    {
        "x": 38.24,
        "y": 25.58
    },
    {
        "x": 38.25,
        "y": 25.58
    },
    {
        "x": 38.26,
        "y": 25.58
    },
    {
        "x": 38.27,
        "y": 25.58
    },
    {
        "x": 38.28,
        "y": 25.58
    },
    {
        "x": 38.29,
        "y": 25.58
    },
    {
        "x": 38.3,
        "y": 25.58
    },
    {
        "x": 38.31,
        "y": 25.58
    },
    {
        "x": 38.32,
        "y": 25.58
    },
    {
        "x": 38.33,
        "y": 25.58
    },
    {
        "x": 38.34,
        "y": 25.58
    },
    {
        "x": 38.35,
        "y": 25.58
    },
    {
        "x": 38.36,
        "y": 25.58
    },
    {
        "x": 38.37,
        "y": 25.58
    },
    {
        "x": 38.38,
        "y": 25.58
    },
    {
        "x": 38.39,
        "y": 25.58
    },
    {
        "x": 38.4,
        "y": 25.58
    },
    {
        "x": 38.41,
        "y": 25.58
    },
    {
        "x": 38.42,
        "y": 25.59
    },
    {
        "x": 38.43,
        "y": 25.59
    },
    {
        "x": 38.44,
        "y": 25.59
    },
    {
        "x": 38.45,
        "y": 25.59
    },
    {
        "x": 38.46,
        "y": 25.58
    },
    {
        "x": 38.47,
        "y": 25.59
    },
    {
        "x": 38.48,
        "y": 25.59
    },
    {
        "x": 38.49,
        "y": 25.59
    },
    {
        "x": 38.5,
        "y": 25.58
    },
    {
        "x": 38.51,
        "y": 25.59
    },
    {
        "x": 38.52,
        "y": 25.59
    },
    {
        "x": 38.53,
        "y": 25.58
    },
    {
        "x": 38.54,
        "y": 25.59
    },
    {
        "x": 38.55,
        "y": 25.59
    },
    {
        "x": 38.56,
        "y": 25.59
    },
    {
        "x": 38.57,
        "y": 25.59
    },
    {
        "x": 38.58,
        "y": 25.59
    },
    {
        "x": 38.59,
        "y": 25.59
    },
    {
        "x": 38.6,
        "y": 25.59
    },
    {
        "x": 38.61,
        "y": 25.59
    },
    {
        "x": 38.62,
        "y": 25.59
    },
    {
        "x": 38.63,
        "y": 25.59
    },
    {
        "x": 38.64,
        "y": 25.59
    },
    {
        "x": 38.65,
        "y": 25.59
    },
    {
        "x": 38.66,
        "y": 25.59
    },
    {
        "x": 38.67,
        "y": 25.59
    },
    {
        "x": 38.68,
        "y": 25.59
    },
    {
        "x": 38.69,
        "y": 25.59
    },
    {
        "x": 38.7,
        "y": 25.59
    },
    {
        "x": 38.71,
        "y": 25.59
    },
    {
        "x": 38.72,
        "y": 25.59
    },
    {
        "x": 38.73,
        "y": 25.6
    },
    {
        "x": 38.74,
        "y": 25.59
    },
    {
        "x": 38.75,
        "y": 25.6
    },
    {
        "x": 38.76,
        "y": 25.6
    },
    {
        "x": 38.77,
        "y": 25.59
    },
    {
        "x": 38.78,
        "y": 25.59
    },
    {
        "x": 38.79,
        "y": 25.59
    },
    {
        "x": 38.8,
        "y": 25.6
    },
    {
        "x": 38.81,
        "y": 25.6
    },
    {
        "x": 38.82,
        "y": 25.59
    },
    {
        "x": 38.83,
        "y": 25.6
    },
    {
        "x": 38.84,
        "y": 25.59
    },
    {
        "x": 38.85,
        "y": 25.6
    },
    {
        "x": 38.86,
        "y": 25.6
    },
    {
        "x": 38.87,
        "y": 25.6
    },
    {
        "x": 38.88,
        "y": 25.6
    },
    {
        "x": 38.89,
        "y": 25.6
    },
    {
        "x": 38.9,
        "y": 25.6
    },
    {
        "x": 38.91,
        "y": 25.6
    },
    {
        "x": 38.92,
        "y": 25.6
    },
    {
        "x": 38.93,
        "y": 25.6
    },
    {
        "x": 38.94,
        "y": 25.6
    },
    {
        "x": 38.95,
        "y": 25.6
    },
    {
        "x": 38.96,
        "y": 25.6
    },
    {
        "x": 38.97,
        "y": 25.6
    },
    {
        "x": 38.98,
        "y": 25.6
    },
    {
        "x": 38.99,
        "y": 25.6
    },
    {
        "x": 39,
        "y": 25.6
    },
    {
        "x": 39.01,
        "y": 25.6
    },
    {
        "x": 39.02,
        "y": 25.6
    },
    {
        "x": 39.03,
        "y": 25.6
    },
    {
        "x": 39.04,
        "y": 25.6
    },
    {
        "x": 39.05,
        "y": 25.6
    },
    {
        "x": 39.06,
        "y": 25.6
    },
    {
        "x": 39.07,
        "y": 25.6
    },
    {
        "x": 39.08,
        "y": 25.61
    },
    {
        "x": 39.09,
        "y": 25.6
    },
    {
        "x": 39.1,
        "y": 25.6
    },
    {
        "x": 39.11,
        "y": 25.6
    },
    {
        "x": 39.12,
        "y": 25.6
    },
    {
        "x": 39.13,
        "y": 25.6
    },
    {
        "x": 39.14,
        "y": 25.61
    },
    {
        "x": 39.15,
        "y": 25.61
    },
    {
        "x": 39.16,
        "y": 25.61
    },
    {
        "x": 39.17,
        "y": 25.61
    },
    {
        "x": 39.18,
        "y": 25.6
    },
    {
        "x": 39.19,
        "y": 25.61
    },
    {
        "x": 39.2,
        "y": 25.6
    },
    {
        "x": 39.21,
        "y": 25.61
    },
    {
        "x": 39.22,
        "y": 25.61
    },
    {
        "x": 39.23,
        "y": 25.61
    },
    {
        "x": 39.24,
        "y": 25.61
    },
    {
        "x": 39.25,
        "y": 25.61
    },
    {
        "x": 39.26,
        "y": 25.61
    },
    {
        "x": 39.27,
        "y": 25.61
    },
    {
        "x": 39.28,
        "y": 25.61
    },
    {
        "x": 39.29,
        "y": 25.61
    },
    {
        "x": 39.3,
        "y": 25.61
    },
    {
        "x": 39.31,
        "y": 25.61
    },
    {
        "x": 39.32,
        "y": 25.61
    },
    {
        "x": 39.33,
        "y": 25.61
    },
    {
        "x": 39.34,
        "y": 25.61
    },
    {
        "x": 39.35,
        "y": 25.61
    },
    {
        "x": 39.36,
        "y": 25.61
    },
    {
        "x": 39.37,
        "y": 25.61
    },
    {
        "x": 39.38,
        "y": 25.61
    },
    {
        "x": 39.39,
        "y": 25.61
    },
    {
        "x": 39.4,
        "y": 25.61
    },
    {
        "x": 39.41,
        "y": 25.61
    },
    {
        "x": 39.42,
        "y": 25.61
    },
    {
        "x": 39.43,
        "y": 25.61
    },
    {
        "x": 39.44,
        "y": 25.61
    },
    {
        "x": 39.45,
        "y": 25.61
    },
    {
        "x": 39.46,
        "y": 25.61
    },
    {
        "x": 39.47,
        "y": 25.61
    },
    {
        "x": 39.48,
        "y": 25.61
    },
    {
        "x": 39.49,
        "y": 25.61
    },
    {
        "x": 39.5,
        "y": 25.62
    },
    {
        "x": 39.51,
        "y": 25.61
    },
    {
        "x": 39.52,
        "y": 25.61
    },
    {
        "x": 39.53,
        "y": 25.61
    },
    {
        "x": 39.54,
        "y": 25.61
    },
    {
        "x": 39.55,
        "y": 25.61
    },
    {
        "x": 39.56,
        "y": 25.61
    },
    {
        "x": 39.57,
        "y": 25.62
    },
    {
        "x": 39.58,
        "y": 25.62
    },
    {
        "x": 39.59,
        "y": 25.61
    },
    {
        "x": 39.6,
        "y": 25.61
    },
    {
        "x": 39.61,
        "y": 25.61
    },
    {
        "x": 39.62,
        "y": 25.61
    },
    {
        "x": 39.63,
        "y": 25.61
    },
    {
        "x": 39.64,
        "y": 25.61
    },
    {
        "x": 39.65,
        "y": 25.62
    },
    {
        "x": 39.66,
        "y": 25.61
    },
    {
        "x": 39.67,
        "y": 25.62
    },
    {
        "x": 39.68,
        "y": 25.62
    },
    {
        "x": 39.69,
        "y": 25.62
    },
    {
        "x": 39.7,
        "y": 25.62
    },
    {
        "x": 39.71,
        "y": 25.62
    },
    {
        "x": 39.72,
        "y": 25.62
    },
    {
        "x": 39.73,
        "y": 25.62
    },
    {
        "x": 39.74,
        "y": 25.62
    },
    {
        "x": 39.75,
        "y": 25.62
    },
    {
        "x": 39.76,
        "y": 25.62
    },
    {
        "x": 39.77,
        "y": 25.62
    },
    {
        "x": 39.78,
        "y": 25.61
    },
    {
        "x": 39.79,
        "y": 25.62
    },
    {
        "x": 39.8,
        "y": 25.62
    },
    {
        "x": 39.81,
        "y": 25.62
    },
    {
        "x": 39.82,
        "y": 25.62
    },
    {
        "x": 39.83,
        "y": 25.62
    },
    {
        "x": 39.84,
        "y": 25.62
    },
    {
        "x": 39.85,
        "y": 25.62
    },
    {
        "x": 39.86,
        "y": 25.62
    },
    {
        "x": 39.87,
        "y": 25.62
    },
    {
        "x": 39.88,
        "y": 25.62
    },
    {
        "x": 39.89,
        "y": 25.62
    },
    {
        "x": 39.9,
        "y": 25.62
    },
    {
        "x": 39.91,
        "y": 25.62
    },
    {
        "x": 39.92,
        "y": 25.62
    },
    {
        "x": 39.93,
        "y": 25.62
    },
    {
        "x": 39.94,
        "y": 25.62
    },
    {
        "x": 39.95,
        "y": 25.62
    },
    {
        "x": 39.96,
        "y": 25.62
    },
    {
        "x": 39.97,
        "y": 25.62
    },
    {
        "x": 39.98,
        "y": 25.62
    },
    {
        "x": 39.99,
        "y": 25.62
    },
    {
        "x": 40,
        "y": 25.62
    },
    {
        "x": 40.01,
        "y": 25.62
    },
    {
        "x": 40.02,
        "y": 25.62
    },
    {
        "x": 40.03,
        "y": 25.63
    },
    {
        "x": 40.04,
        "y": 25.63
    },
    {
        "x": 40.05,
        "y": 25.62
    },
    {
        "x": 40.06,
        "y": 25.63
    },
    {
        "x": 40.07,
        "y": 25.63
    },
    {
        "x": 40.08,
        "y": 25.63
    },
    {
        "x": 40.09,
        "y": 25.63
    },
    {
        "x": 40.1,
        "y": 25.63
    },
    {
        "x": 40.11,
        "y": 25.63
    },
    {
        "x": 40.12,
        "y": 25.63
    },
    {
        "x": 40.13,
        "y": 25.63
    },
    {
        "x": 40.14,
        "y": 25.63
    },
    {
        "x": 40.15,
        "y": 25.63
    },
    {
        "x": 40.16,
        "y": 25.63
    },
    {
        "x": 40.17,
        "y": 25.63
    },
    {
        "x": 40.18,
        "y": 25.63
    },
    {
        "x": 40.19,
        "y": 25.63
    },
    {
        "x": 40.2,
        "y": 25.63
    },
    {
        "x": 40.21,
        "y": 25.63
    },
    {
        "x": 40.22,
        "y": 25.63
    },
    {
        "x": 40.23,
        "y": 25.63
    },
    {
        "x": 40.24,
        "y": 25.63
    },
    {
        "x": 40.25,
        "y": 25.63
    },
    {
        "x": 40.26,
        "y": 25.63
    },
    {
        "x": 40.27,
        "y": 25.63
    },
    {
        "x": 40.28,
        "y": 25.63
    },
    {
        "x": 40.29,
        "y": 25.63
    },
    {
        "x": 40.3,
        "y": 25.63
    },
    {
        "x": 40.31,
        "y": 25.63
    },
    {
        "x": 40.32,
        "y": 25.63
    },
    {
        "x": 40.33,
        "y": 25.63
    },
    {
        "x": 40.34,
        "y": 25.63
    },
    {
        "x": 40.35,
        "y": 25.63
    },
    {
        "x": 40.36,
        "y": 25.63
    },
    {
        "x": 40.37,
        "y": 25.63
    },
    {
        "x": 40.38,
        "y": 25.63
    },
    {
        "x": 40.39,
        "y": 25.64
    },
    {
        "x": 40.4,
        "y": 25.63
    },
    {
        "x": 40.41,
        "y": 25.63
    },
    {
        "x": 40.42,
        "y": 25.63
    },
    {
        "x": 40.43,
        "y": 25.63
    },
    {
        "x": 40.44,
        "y": 25.63
    },
    {
        "x": 40.45,
        "y": 25.63
    },
    {
        "x": 40.46,
        "y": 25.63
    },
    {
        "x": 40.47,
        "y": 25.63
    },
    {
        "x": 40.48,
        "y": 25.63
    },
    {
        "x": 40.49,
        "y": 25.63
    },
    {
        "x": 40.5,
        "y": 25.63
    },
    {
        "x": 40.51,
        "y": 25.63
    },
    {
        "x": 40.52,
        "y": 25.63
    },
    {
        "x": 40.53,
        "y": 25.63
    },
    {
        "x": 40.54,
        "y": 25.63
    },
    {
        "x": 40.55,
        "y": 25.63
    },
    {
        "x": 40.56,
        "y": 25.64
    },
    {
        "x": 40.57,
        "y": 25.63
    },
    {
        "x": 40.58,
        "y": 25.63
    },
    {
        "x": 40.59,
        "y": 25.64
    },
    {
        "x": 40.6,
        "y": 25.63
    },
    {
        "x": 40.61,
        "y": 25.64
    },
    {
        "x": 40.62,
        "y": 25.63
    },
    {
        "x": 40.63,
        "y": 25.63
    },
    {
        "x": 40.64,
        "y": 25.63
    },
    {
        "x": 40.65,
        "y": 25.63
    },
    {
        "x": 40.66,
        "y": 25.64
    },
    {
        "x": 40.67,
        "y": 25.64
    },
    {
        "x": 40.68,
        "y": 25.64
    },
    {
        "x": 40.69,
        "y": 25.64
    },
    {
        "x": 40.7,
        "y": 25.64
    },
    {
        "x": 40.71,
        "y": 25.64
    },
    {
        "x": 40.72,
        "y": 25.64
    },
    {
        "x": 40.73,
        "y": 25.64
    },
    {
        "x": 40.74,
        "y": 25.64
    },
    {
        "x": 40.75,
        "y": 25.63
    },
    {
        "x": 40.76,
        "y": 25.64
    },
    {
        "x": 40.77,
        "y": 25.64
    },
    {
        "x": 40.78,
        "y": 25.64
    },
    {
        "x": 40.79,
        "y": 25.64
    },
    {
        "x": 40.8,
        "y": 25.64
    },
    {
        "x": 40.81,
        "y": 25.64
    },
    {
        "x": 40.82,
        "y": 25.64
    },
    {
        "x": 40.83,
        "y": 25.64
    },
    {
        "x": 40.84,
        "y": 25.64
    },
    {
        "x": 40.85,
        "y": 25.64
    },
    {
        "x": 40.86,
        "y": 25.64
    },
    {
        "x": 40.87,
        "y": 25.64
    },
    {
        "x": 40.88,
        "y": 25.64
    },
    {
        "x": 40.89,
        "y": 25.64
    },
    {
        "x": 40.9,
        "y": 25.64
    },
    {
        "x": 40.91,
        "y": 25.64
    },
    {
        "x": 40.92,
        "y": 25.64
    },
    {
        "x": 40.93,
        "y": 25.64
    },
    {
        "x": 40.94,
        "y": 25.64
    },
    {
        "x": 40.95,
        "y": 25.64
    },
    {
        "x": 40.96,
        "y": 25.64
    },
    {
        "x": 40.97,
        "y": 25.64
    },
    {
        "x": 40.98,
        "y": 25.64
    },
    {
        "x": 40.99,
        "y": 25.64
    },
    {
        "x": 41,
        "y": 25.64
    },
    {
        "x": 41.01,
        "y": 25.65
    },
    {
        "x": 41.02,
        "y": 25.64
    },
    {
        "x": 41.03,
        "y": 25.64
    },
    {
        "x": 41.04,
        "y": 25.64
    },
    {
        "x": 41.05,
        "y": 25.65
    },
    {
        "x": 41.06,
        "y": 25.64
    },
    {
        "x": 41.07,
        "y": 25.64
    },
    {
        "x": 41.08,
        "y": 25.64
    },
    {
        "x": 41.09,
        "y": 25.64
    },
    {
        "x": 41.1,
        "y": 25.65
    },
    {
        "x": 41.11,
        "y": 25.64
    },
    {
        "x": 41.12,
        "y": 25.64
    },
    {
        "x": 41.13,
        "y": 25.65
    },
    {
        "x": 41.14,
        "y": 25.65
    },
    {
        "x": 41.15,
        "y": 25.65
    },
    {
        "x": 41.16,
        "y": 25.65
    },
    {
        "x": 41.17,
        "y": 25.65
    },
    {
        "x": 41.18,
        "y": 25.64
    },
    {
        "x": 41.19,
        "y": 25.65
    },
    {
        "x": 41.2,
        "y": 25.65
    },
    {
        "x": 41.21,
        "y": 25.65
    },
    {
        "x": 41.22,
        "y": 25.65
    },
    {
        "x": 41.23,
        "y": 25.65
    },
    {
        "x": 41.24,
        "y": 25.65
    },
    {
        "x": 41.25,
        "y": 25.65
    },
    {
        "x": 41.26,
        "y": 25.65
    },
    {
        "x": 41.27,
        "y": 25.65
    },
    {
        "x": 41.28,
        "y": 25.65
    },
    {
        "x": 41.29,
        "y": 25.65
    },
    {
        "x": 41.3,
        "y": 25.65
    },
    {
        "x": 41.31,
        "y": 25.65
    },
    {
        "x": 41.32,
        "y": 25.65
    },
    {
        "x": 41.33,
        "y": 25.65
    },
    {
        "x": 41.34,
        "y": 25.65
    },
    {
        "x": 41.35,
        "y": 25.65
    },
    {
        "x": 41.36,
        "y": 25.65
    },
    {
        "x": 41.37,
        "y": 25.65
    },
    {
        "x": 41.38,
        "y": 25.65
    },
    {
        "x": 41.39,
        "y": 25.65
    },
    {
        "x": 41.4,
        "y": 25.65
    },
    {
        "x": 41.41,
        "y": 25.65
    },
    {
        "x": 41.42,
        "y": 25.65
    },
    {
        "x": 41.43,
        "y": 25.65
    },
    {
        "x": 41.44,
        "y": 25.65
    },
    {
        "x": 41.45,
        "y": 25.65
    },
    {
        "x": 41.46,
        "y": 25.66
    },
    {
        "x": 41.47,
        "y": 25.66
    },
    {
        "x": 41.48,
        "y": 25.65
    },
    {
        "x": 41.49,
        "y": 25.65
    },
    {
        "x": 41.5,
        "y": 25.65
    },
    {
        "x": 41.51,
        "y": 25.65
    },
    {
        "x": 41.52,
        "y": 25.65
    },
    {
        "x": 41.53,
        "y": 25.65
    },
    {
        "x": 41.54,
        "y": 25.66
    },
    {
        "x": 41.55,
        "y": 25.66
    },
    {
        "x": 41.56,
        "y": 25.66
    },
    {
        "x": 41.57,
        "y": 25.65
    },
    {
        "x": 41.58,
        "y": 25.65
    },
    {
        "x": 41.59,
        "y": 25.66
    },
    {
        "x": 41.6,
        "y": 25.65
    },
    {
        "x": 41.61,
        "y": 25.66
    },
    {
        "x": 41.62,
        "y": 25.66
    },
    {
        "x": 41.63,
        "y": 25.66
    },
    {
        "x": 41.64,
        "y": 25.65
    },
    {
        "x": 41.65,
        "y": 25.65
    },
    {
        "x": 41.66,
        "y": 25.66
    },
    {
        "x": 41.67,
        "y": 25.66
    },
    {
        "x": 41.68,
        "y": 25.66
    },
    {
        "x": 41.69,
        "y": 25.66
    },
    {
        "x": 41.7,
        "y": 25.66
    },
    {
        "x": 41.71,
        "y": 25.65
    },
    {
        "x": 41.72,
        "y": 25.66
    },
    {
        "x": 41.73,
        "y": 25.66
    },
    {
        "x": 41.74,
        "y": 25.66
    },
    {
        "x": 41.75,
        "y": 25.66
    },
    {
        "x": 41.76,
        "y": 25.66
    },
    {
        "x": 41.77,
        "y": 25.66
    },
    {
        "x": 41.78,
        "y": 25.66
    },
    {
        "x": 41.79,
        "y": 25.66
    },
    {
        "x": 41.8,
        "y": 25.66
    },
    {
        "x": 41.81,
        "y": 25.66
    },
    {
        "x": 41.82,
        "y": 25.66
    },
    {
        "x": 41.83,
        "y": 25.66
    },
    {
        "x": 41.84,
        "y": 25.66
    },
    {
        "x": 41.85,
        "y": 25.66
    },
    {
        "x": 41.86,
        "y": 25.66
    },
    {
        "x": 41.87,
        "y": 25.66
    },
    {
        "x": 41.88,
        "y": 25.66
    },
    {
        "x": 41.89,
        "y": 25.66
    },
    {
        "x": 41.9,
        "y": 25.66
    },
    {
        "x": 41.91,
        "y": 25.66
    },
    {
        "x": 41.92,
        "y": 25.66
    },
    {
        "x": 41.93,
        "y": 25.66
    },
    {
        "x": 41.94,
        "y": 25.66
    },
    {
        "x": 41.95,
        "y": 25.66
    },
    {
        "x": 41.96,
        "y": 25.66
    },
    {
        "x": 41.97,
        "y": 25.66
    },
    {
        "x": 41.98,
        "y": 25.66
    },
    {
        "x": 41.99,
        "y": 25.66
    },
    {
        "x": 42,
        "y": 25.66
    },
    {
        "x": 42.01,
        "y": 25.66
    },
    {
        "x": 42.02,
        "y": 25.66
    },
    {
        "x": 42.03,
        "y": 25.66
    },
    {
        "x": 42.04,
        "y": 25.66
    },
    {
        "x": 42.05,
        "y": 25.66
    },
    {
        "x": 42.06,
        "y": 25.66
    },
    {
        "x": 42.07,
        "y": 25.66
    },
    {
        "x": 42.08,
        "y": 25.66
    },
    {
        "x": 42.09,
        "y": 25.66
    },
    {
        "x": 42.1,
        "y": 25.66
    },
    {
        "x": 42.11,
        "y": 25.66
    },
    {
        "x": 42.12,
        "y": 25.66
    },
    {
        "x": 42.13,
        "y": 25.66
    },
    {
        "x": 42.14,
        "y": 25.66
    },
    {
        "x": 42.15,
        "y": 25.67
    },
    {
        "x": 42.16,
        "y": 25.66
    },
    {
        "x": 42.17,
        "y": 25.66
    },
    {
        "x": 42.18,
        "y": 25.66
    },
    {
        "x": 42.19,
        "y": 25.66
    },
    {
        "x": 42.2,
        "y": 25.67
    },
    {
        "x": 42.21,
        "y": 25.67
    },
    {
        "x": 42.22,
        "y": 25.66
    },
    {
        "x": 42.23,
        "y": 25.67
    },
    {
        "x": 42.24,
        "y": 25.67
    },
    {
        "x": 42.25,
        "y": 25.67
    },
    {
        "x": 42.26,
        "y": 25.67
    },
    {
        "x": 42.27,
        "y": 25.67
    },
    {
        "x": 42.28,
        "y": 25.67
    },
    {
        "x": 42.29,
        "y": 25.67
    },
    {
        "x": 42.3,
        "y": 25.66
    },
    {
        "x": 42.31,
        "y": 25.66
    },
    {
        "x": 42.32,
        "y": 25.67
    },
    {
        "x": 42.33,
        "y": 25.67
    },
    {
        "x": 42.34,
        "y": 25.67
    },
    {
        "x": 42.35,
        "y": 25.67
    },
    {
        "x": 42.36,
        "y": 25.67
    },
    {
        "x": 42.37,
        "y": 25.66
    },
    {
        "x": 42.38,
        "y": 25.67
    },
    {
        "x": 42.39,
        "y": 25.67
    },
    {
        "x": 42.4,
        "y": 25.67
    },
    {
        "x": 42.41,
        "y": 25.67
    },
    {
        "x": 42.42,
        "y": 25.67
    },
    {
        "x": 42.43,
        "y": 25.67
    },
    {
        "x": 42.44,
        "y": 25.67
    },
    {
        "x": 42.45,
        "y": 25.67
    },
    {
        "x": 42.46,
        "y": 25.67
    },
    {
        "x": 42.47,
        "y": 25.66
    },
    {
        "x": 42.48,
        "y": 25.67
    },
    {
        "x": 42.49,
        "y": 25.67
    },
    {
        "x": 42.5,
        "y": 25.67
    },
    {
        "x": 42.51,
        "y": 25.67
    },
    {
        "x": 42.52,
        "y": 25.67
    },
    {
        "x": 42.53,
        "y": 25.67
    },
    {
        "x": 42.54,
        "y": 25.67
    },
    {
        "x": 42.55,
        "y": 25.67
    },
    {
        "x": 42.56,
        "y": 25.67
    },
    {
        "x": 42.57,
        "y": 25.67
    },
    {
        "x": 42.58,
        "y": 25.67
    },
    {
        "x": 42.59,
        "y": 25.67
    },
    {
        "x": 42.6,
        "y": 25.67
    },
    {
        "x": 42.61,
        "y": 25.67
    },
    {
        "x": 42.62,
        "y": 25.67
    },
    {
        "x": 42.63,
        "y": 25.67
    },
    {
        "x": 42.64,
        "y": 25.67
    },
    {
        "x": 42.65,
        "y": 25.67
    },
    {
        "x": 42.66,
        "y": 25.67
    },
    {
        "x": 42.67,
        "y": 25.67
    },
    {
        "x": 42.68,
        "y": 25.67
    },
    {
        "x": 42.69,
        "y": 25.67
    },
    {
        "x": 42.7,
        "y": 25.68
    },
    {
        "x": 42.71,
        "y": 25.67
    },
    {
        "x": 42.72,
        "y": 25.67
    },
    {
        "x": 42.73,
        "y": 25.67
    },
    {
        "x": 42.74,
        "y": 25.68
    },
    {
        "x": 42.75,
        "y": 25.68
    },
    {
        "x": 42.76,
        "y": 25.67
    },
    {
        "x": 42.77,
        "y": 25.67
    },
    {
        "x": 42.78,
        "y": 25.67
    },
    {
        "x": 42.79,
        "y": 25.68
    },
    {
        "x": 42.8,
        "y": 25.68
    },
    {
        "x": 42.81,
        "y": 25.68
    },
    {
        "x": 42.82,
        "y": 25.67
    },
    {
        "x": 42.83,
        "y": 25.67
    },
    {
        "x": 42.84,
        "y": 25.68
    },
    {
        "x": 42.85,
        "y": 25.67
    },
    {
        "x": 42.86,
        "y": 25.68
    },
    {
        "x": 42.87,
        "y": 25.68
    },
    {
        "x": 42.88,
        "y": 25.67
    },
    {
        "x": 42.89,
        "y": 25.67
    },
    {
        "x": 42.9,
        "y": 25.67
    },
    {
        "x": 42.91,
        "y": 25.67
    },
    {
        "x": 42.92,
        "y": 25.68
    },
    {
        "x": 42.93,
        "y": 25.67
    },
    {
        "x": 42.94,
        "y": 25.68
    },
    {
        "x": 42.95,
        "y": 25.68
    },
    {
        "x": 42.96,
        "y": 25.68
    },
    {
        "x": 42.97,
        "y": 25.68
    },
    {
        "x": 42.98,
        "y": 25.68
    },
    {
        "x": 42.99,
        "y": 25.68
    },
    {
        "x": 43,
        "y": 25.68
    },
    {
        "x": 43.01,
        "y": 25.67
    },
    {
        "x": 43.02,
        "y": 25.68
    },
    {
        "x": 43.03,
        "y": 25.68
    },
    {
        "x": 43.04,
        "y": 25.68
    },
    {
        "x": 43.05,
        "y": 25.68
    },
    {
        "x": 43.06,
        "y": 25.68
    },
    {
        "x": 43.07,
        "y": 25.68
    },
    {
        "x": 43.08,
        "y": 25.68
    },
    {
        "x": 43.09,
        "y": 25.68
    },
    {
        "x": 43.1,
        "y": 25.68
    },
    {
        "x": 43.11,
        "y": 25.68
    },
    {
        "x": 43.12,
        "y": 25.68
    },
    {
        "x": 43.13,
        "y": 25.68
    },
    {
        "x": 43.14,
        "y": 25.68
    },
    {
        "x": 43.15,
        "y": 25.68
    },
    {
        "x": 43.16,
        "y": 25.68
    },
    {
        "x": 43.17,
        "y": 25.68
    },
    {
        "x": 43.18,
        "y": 25.68
    },
    {
        "x": 43.19,
        "y": 25.68
    },
    {
        "x": 43.2,
        "y": 25.68
    },
    {
        "x": 43.21,
        "y": 25.68
    },
    {
        "x": 43.22,
        "y": 25.68
    },
    {
        "x": 43.23,
        "y": 25.68
    },
    {
        "x": 43.24,
        "y": 25.68
    },
    {
        "x": 43.25,
        "y": 25.68
    },
    {
        "x": 43.26,
        "y": 25.68
    },
    {
        "x": 43.27,
        "y": 25.68
    },
    {
        "x": 43.28,
        "y": 25.68
    },
    {
        "x": 43.29,
        "y": 25.68
    },
    {
        "x": 43.3,
        "y": 25.68
    },
    {
        "x": 43.31,
        "y": 25.68
    },
    {
        "x": 43.32,
        "y": 25.68
    },
    {
        "x": 43.33,
        "y": 25.68
    },
    {
        "x": 43.34,
        "y": 25.68
    },
    {
        "x": 43.35,
        "y": 25.68
    },
    {
        "x": 43.36,
        "y": 25.68
    },
    {
        "x": 43.37,
        "y": 25.68
    },
    {
        "x": 43.38,
        "y": 25.68
    },
    {
        "x": 43.39,
        "y": 25.68
    },
    {
        "x": 43.4,
        "y": 25.68
    },
    {
        "x": 43.41,
        "y": 25.68
    },
    {
        "x": 43.42,
        "y": 25.68
    },
    {
        "x": 43.43,
        "y": 25.68
    },
    {
        "x": 43.44,
        "y": 25.68
    },
    {
        "x": 43.45,
        "y": 25.68
    },
    {
        "x": 43.46,
        "y": 25.68
    },
    {
        "x": 43.47,
        "y": 25.68
    },
    {
        "x": 43.48,
        "y": 25.68
    },
    {
        "x": 43.49,
        "y": 25.68
    },
    {
        "x": 43.5,
        "y": 25.68
    },
    {
        "x": 43.51,
        "y": 25.68
    },
    {
        "x": 43.52,
        "y": 25.68
    },
    {
        "x": 43.53,
        "y": 25.68
    },
    {
        "x": 43.54,
        "y": 25.68
    },
    {
        "x": 43.55,
        "y": 25.68
    },
    {
        "x": 43.56,
        "y": 25.68
    },
    {
        "x": 43.57,
        "y": 25.68
    },
    {
        "x": 43.58,
        "y": 25.68
    },
    {
        "x": 43.59,
        "y": 25.68
    },
    {
        "x": 43.6,
        "y": 25.68
    },
    {
        "x": 43.61,
        "y": 25.69
    },
    {
        "x": 43.62,
        "y": 25.69
    },
    {
        "x": 43.63,
        "y": 25.68
    },
    {
        "x": 43.64,
        "y": 25.68
    },
    {
        "x": 43.65,
        "y": 25.69
    },
    {
        "x": 43.66,
        "y": 25.68
    },
    {
        "x": 43.67,
        "y": 25.69
    },
    {
        "x": 43.68,
        "y": 25.69
    },
    {
        "x": 43.69,
        "y": 25.69
    },
    {
        "x": 43.7,
        "y": 25.69
    },
    {
        "x": 43.71,
        "y": 25.69
    },
    {
        "x": 43.72,
        "y": 25.69
    },
    {
        "x": 43.73,
        "y": 25.69
    },
    {
        "x": 43.74,
        "y": 25.69
    },
    {
        "x": 43.75,
        "y": 25.69
    },
    {
        "x": 43.76,
        "y": 25.69
    },
    {
        "x": 43.77,
        "y": 25.69
    },
    {
        "x": 43.78,
        "y": 25.69
    },
    {
        "x": 43.79,
        "y": 25.69
    },
    {
        "x": 43.8,
        "y": 25.69
    },
    {
        "x": 43.81,
        "y": 25.69
    },
    {
        "x": 43.82,
        "y": 25.69
    },
    {
        "x": 43.83,
        "y": 25.69
    },
    {
        "x": 43.84,
        "y": 25.69
    },
    {
        "x": 43.85,
        "y": 25.69
    },
    {
        "x": 43.86,
        "y": 25.69
    },
    {
        "x": 43.87,
        "y": 25.69
    },
    {
        "x": 43.88,
        "y": 25.69
    },
    {
        "x": 43.89,
        "y": 25.69
    },
    {
        "x": 43.9,
        "y": 25.69
    },
    {
        "x": 43.91,
        "y": 25.69
    },
    {
        "x": 43.92,
        "y": 25.69
    },
    {
        "x": 43.93,
        "y": 25.69
    },
    {
        "x": 43.94,
        "y": 25.69
    },
    {
        "x": 43.95,
        "y": 25.69
    },
    {
        "x": 43.96,
        "y": 25.69
    },
    {
        "x": 43.97,
        "y": 25.69
    },
    {
        "x": 43.98,
        "y": 25.69
    },
    {
        "x": 43.99,
        "y": 25.69
    },
    {
        "x": 44,
        "y": 25.69
    },
    {
        "x": 44.01,
        "y": 25.69
    },
    {
        "x": 44.02,
        "y": 25.69
    },
    {
        "x": 44.03,
        "y": 25.69
    },
    {
        "x": 44.04,
        "y": 25.69
    },
    {
        "x": 44.05,
        "y": 25.69
    },
    {
        "x": 44.06,
        "y": 25.69
    },
    {
        "x": 44.07,
        "y": 25.7
    },
    {
        "x": 44.08,
        "y": 25.69
    },
    {
        "x": 44.09,
        "y": 25.69
    },
    {
        "x": 44.1,
        "y": 25.69
    },
    {
        "x": 44.11,
        "y": 25.69
    },
    {
        "x": 44.12,
        "y": 25.69
    },
    {
        "x": 44.13,
        "y": 25.69
    },
    {
        "x": 44.14,
        "y": 25.69
    },
    {
        "x": 44.15,
        "y": 25.69
    },
    {
        "x": 44.16,
        "y": 25.69
    },
    {
        "x": 44.17,
        "y": 25.69
    },
    {
        "x": 44.18,
        "y": 25.69
    },
    {
        "x": 44.19,
        "y": 25.69
    },
    {
        "x": 44.2,
        "y": 25.69
    },
    {
        "x": 44.21,
        "y": 25.69
    },
    {
        "x": 44.22,
        "y": 25.69
    },
    {
        "x": 44.23,
        "y": 25.7
    },
    {
        "x": 44.24,
        "y": 25.7
    },
    {
        "x": 44.25,
        "y": 25.69
    },
    {
        "x": 44.26,
        "y": 25.69
    },
    {
        "x": 44.27,
        "y": 25.69
    },
    {
        "x": 44.28,
        "y": 25.69
    },
    {
        "x": 44.29,
        "y": 25.69
    },
    {
        "x": 44.3,
        "y": 25.69
    },
    {
        "x": 44.31,
        "y": 25.69
    },
    {
        "x": 44.32,
        "y": 25.7
    },
    {
        "x": 44.33,
        "y": 25.7
    },
    {
        "x": 44.34,
        "y": 25.69
    },
    {
        "x": 44.35,
        "y": 25.69
    },
    {
        "x": 44.36,
        "y": 25.69
    },
    {
        "x": 44.37,
        "y": 25.69
    },
    {
        "x": 44.38,
        "y": 25.7
    },
    {
        "x": 44.39,
        "y": 25.7
    },
    {
        "x": 44.4,
        "y": 25.7
    },
    {
        "x": 44.41,
        "y": 25.69
    },
    {
        "x": 44.42,
        "y": 25.7
    },
    {
        "x": 44.43,
        "y": 25.69
    },
    {
        "x": 44.44,
        "y": 25.7
    },
    {
        "x": 44.45,
        "y": 25.7
    },
    {
        "x": 44.46,
        "y": 25.7
    },
    {
        "x": 44.47,
        "y": 25.7
    },
    {
        "x": 44.48,
        "y": 25.7
    },
    {
        "x": 44.49,
        "y": 25.7
    },
    {
        "x": 44.5,
        "y": 25.7
    },
    {
        "x": 44.51,
        "y": 25.69
    },
    {
        "x": 44.52,
        "y": 25.7
    },
    {
        "x": 44.53,
        "y": 25.7
    },
    {
        "x": 44.54,
        "y": 25.7
    },
    {
        "x": 44.55,
        "y": 25.7
    },
    {
        "x": 44.56,
        "y": 25.7
    },
    {
        "x": 44.57,
        "y": 25.7
    },
    {
        "x": 44.58,
        "y": 25.7
    },
    {
        "x": 44.59,
        "y": 25.7
    },
    {
        "x": 44.6,
        "y": 25.7
    },
    {
        "x": 44.61,
        "y": 25.7
    },
    {
        "x": 44.62,
        "y": 25.69
    },
    {
        "x": 44.63,
        "y": 25.7
    },
    {
        "x": 44.64,
        "y": 25.7
    },
    {
        "x": 44.65,
        "y": 25.7
    },
    {
        "x": 44.66,
        "y": 25.7
    },
    {
        "x": 44.67,
        "y": 25.7
    },
    {
        "x": 44.68,
        "y": 25.7
    },
    {
        "x": 44.69,
        "y": 25.7
    },
    {
        "x": 44.7,
        "y": 25.7
    },
    {
        "x": 44.71,
        "y": 25.7
    },
    {
        "x": 44.72,
        "y": 25.7
    },
    {
        "x": 44.73,
        "y": 25.7
    },
    {
        "x": 44.74,
        "y": 25.7
    },
    {
        "x": 44.75,
        "y": 25.7
    },
    {
        "x": 44.76,
        "y": 25.7
    },
    {
        "x": 44.77,
        "y": 25.7
    },
    {
        "x": 44.78,
        "y": 25.7
    },
    {
        "x": 44.79,
        "y": 25.7
    },
    {
        "x": 44.8,
        "y": 25.7
    },
    {
        "x": 44.81,
        "y": 25.7
    },
    {
        "x": 44.82,
        "y": 25.7
    },
    {
        "x": 44.83,
        "y": 25.7
    },
    {
        "x": 44.84,
        "y": 25.7
    },
    {
        "x": 44.85,
        "y": 25.7
    },
    {
        "x": 44.86,
        "y": 25.7
    },
    {
        "x": 44.87,
        "y": 25.7
    },
    {
        "x": 44.88,
        "y": 25.7
    },
    {
        "x": 44.89,
        "y": 25.7
    },
    {
        "x": 44.9,
        "y": 25.7
    },
    {
        "x": 44.91,
        "y": 25.7
    },
    {
        "x": 44.92,
        "y": 25.7
    },
    {
        "x": 44.93,
        "y": 25.7
    },
    {
        "x": 44.94,
        "y": 25.7
    },
    {
        "x": 44.95,
        "y": 25.71
    },
    {
        "x": 44.96,
        "y": 25.71
    },
    {
        "x": 44.97,
        "y": 25.71
    },
    {
        "x": 44.98,
        "y": 25.71
    },
    {
        "x": 44.99,
        "y": 25.7
    },
    {
        "x": 45,
        "y": 25.7
    },
    {
        "x": 45.01,
        "y": 25.71
    },
    {
        "x": 45.02,
        "y": 25.71
    },
    {
        "x": 45.03,
        "y": 25.71
    },
    {
        "x": 45.04,
        "y": 25.71
    },
    {
        "x": 45.05,
        "y": 25.71
    },
    {
        "x": 45.06,
        "y": 25.71
    },
    {
        "x": 45.07,
        "y": 25.71
    },
    {
        "x": 45.08,
        "y": 25.7
    },
    {
        "x": 45.09,
        "y": 25.71
    },
    {
        "x": 45.1,
        "y": 25.71
    },
    {
        "x": 45.11,
        "y": 25.7
    },
    {
        "x": 45.12,
        "y": 25.7
    },
    {
        "x": 45.13,
        "y": 25.71
    },
    {
        "x": 45.14,
        "y": 25.71
    },
    {
        "x": 45.15,
        "y": 25.7
    },
    {
        "x": 45.16,
        "y": 25.71
    },
    {
        "x": 45.17,
        "y": 25.71
    },
    {
        "x": 45.18,
        "y": 25.71
    },
    {
        "x": 45.19,
        "y": 25.71
    },
    {
        "x": 45.2,
        "y": 25.71
    },
    {
        "x": 45.21,
        "y": 25.71
    },
    {
        "x": 45.22,
        "y": 25.71
    },
    {
        "x": 45.23,
        "y": 25.71
    },
    {
        "x": 45.24,
        "y": 25.71
    },
    {
        "x": 45.25,
        "y": 25.71
    },
    {
        "x": 45.26,
        "y": 25.7
    },
    {
        "x": 45.27,
        "y": 25.71
    },
    {
        "x": 45.28,
        "y": 25.71
    },
    {
        "x": 45.29,
        "y": 25.71
    },
    {
        "x": 45.3,
        "y": 25.71
    },
    {
        "x": 45.31,
        "y": 25.71
    },
    {
        "x": 45.32,
        "y": 25.71
    },
    {
        "x": 45.33,
        "y": 25.71
    },
    {
        "x": 45.34,
        "y": 25.71
    },
    {
        "x": 45.35,
        "y": 25.71
    },
    {
        "x": 45.36,
        "y": 25.71
    },
    {
        "x": 45.37,
        "y": 25.71
    },
    {
        "x": 45.38,
        "y": 25.71
    },
    {
        "x": 45.39,
        "y": 25.71
    },
    {
        "x": 45.4,
        "y": 25.7
    },
    {
        "x": 45.41,
        "y": 25.71
    },
    {
        "x": 45.42,
        "y": 25.71
    },
    {
        "x": 45.43,
        "y": 25.71
    },
    {
        "x": 45.44,
        "y": 25.71
    },
    {
        "x": 45.45,
        "y": 25.71
    },
    {
        "x": 45.46,
        "y": 25.71
    },
    {
        "x": 45.47,
        "y": 25.71
    },
    {
        "x": 45.48,
        "y": 25.71
    },
    {
        "x": 45.49,
        "y": 25.71
    },
    {
        "x": 45.5,
        "y": 25.71
    },
    {
        "x": 45.51,
        "y": 25.71
    },
    {
        "x": 45.52,
        "y": 25.71
    },
    {
        "x": 45.53,
        "y": 25.71
    },
    {
        "x": 45.54,
        "y": 25.71
    },
    {
        "x": 45.55,
        "y": 25.71
    },
    {
        "x": 45.56,
        "y": 25.71
    },
    {
        "x": 45.57,
        "y": 25.71
    },
    {
        "x": 45.58,
        "y": 25.71
    },
    {
        "x": 45.59,
        "y": 25.71
    },
    {
        "x": 45.6,
        "y": 25.71
    },
    {
        "x": 45.61,
        "y": 25.71
    },
    {
        "x": 45.62,
        "y": 25.71
    },
    {
        "x": 45.63,
        "y": 25.71
    },
    {
        "x": 45.64,
        "y": 25.71
    },
    {
        "x": 45.65,
        "y": 25.71
    },
    {
        "x": 45.66,
        "y": 25.71
    },
    {
        "x": 45.67,
        "y": 25.71
    },
    {
        "x": 45.68,
        "y": 25.71
    },
    {
        "x": 45.69,
        "y": 25.71
    },
    {
        "x": 45.7,
        "y": 25.71
    },
    {
        "x": 45.71,
        "y": 25.71
    },
    {
        "x": 45.72,
        "y": 25.71
    },
    {
        "x": 45.73,
        "y": 25.71
    },
    {
        "x": 45.74,
        "y": 25.71
    },
    {
        "x": 45.75,
        "y": 25.71
    },
    {
        "x": 45.76,
        "y": 25.71
    },
    {
        "x": 45.77,
        "y": 25.71
    },
    {
        "x": 45.78,
        "y": 25.71
    },
    {
        "x": 45.79,
        "y": 25.71
    },
    {
        "x": 45.8,
        "y": 25.71
    },
    {
        "x": 45.81,
        "y": 25.71
    },
    {
        "x": 45.82,
        "y": 25.71
    },
    {
        "x": 45.83,
        "y": 25.71
    },
    {
        "x": 45.84,
        "y": 25.71
    },
    {
        "x": 45.85,
        "y": 25.71
    },
    {
        "x": 45.86,
        "y": 25.71
    },
    {
        "x": 45.87,
        "y": 25.71
    },
    {
        "x": 45.88,
        "y": 25.71
    },
    {
        "x": 45.89,
        "y": 25.71
    },
    {
        "x": 45.9,
        "y": 25.71
    },
    {
        "x": 45.91,
        "y": 25.71
    },
    {
        "x": 45.92,
        "y": 25.71
    },
    {
        "x": 45.93,
        "y": 25.71
    },
    {
        "x": 45.94,
        "y": 25.71
    },
    {
        "x": 45.95,
        "y": 25.71
    },
    {
        "x": 45.96,
        "y": 25.71
    },
    {
        "x": 45.97,
        "y": 25.71
    },
    {
        "x": 45.98,
        "y": 25.71
    },
    {
        "x": 45.99,
        "y": 25.71
    },
    {
        "x": 46,
        "y": 25.72
    },
    {
        "x": 46.01,
        "y": 25.71
    },
    {
        "x": 46.02,
        "y": 25.71
    },
    {
        "x": 46.03,
        "y": 25.71
    },
    {
        "x": 46.04,
        "y": 25.71
    },
    {
        "x": 46.05,
        "y": 25.71
    },
    {
        "x": 46.06,
        "y": 25.72
    },
    {
        "x": 46.07,
        "y": 25.71
    },
    {
        "x": 46.08,
        "y": 25.71
    },
    {
        "x": 46.09,
        "y": 25.71
    },
    {
        "x": 46.1,
        "y": 25.71
    },
    {
        "x": 46.11,
        "y": 25.71
    },
    {
        "x": 46.12,
        "y": 25.71
    },
    {
        "x": 46.13,
        "y": 25.72
    },
    {
        "x": 46.14,
        "y": 25.72
    },
    {
        "x": 46.15,
        "y": 25.71
    },
    {
        "x": 46.16,
        "y": 25.71
    },
    {
        "x": 46.17,
        "y": 25.72
    },
    {
        "x": 46.18,
        "y": 25.72
    },
    {
        "x": 46.19,
        "y": 25.72
    },
    {
        "x": 46.2,
        "y": 25.72
    },
    {
        "x": 46.21,
        "y": 25.72
    },
    {
        "x": 46.22,
        "y": 25.72
    },
    {
        "x": 46.23,
        "y": 25.72
    },
    {
        "x": 46.24,
        "y": 25.72
    },
    {
        "x": 46.25,
        "y": 25.72
    },
    {
        "x": 46.26,
        "y": 25.72
    },
    {
        "x": 46.27,
        "y": 25.72
    },
    {
        "x": 46.28,
        "y": 25.72
    },
    {
        "x": 46.29,
        "y": 25.72
    },
    {
        "x": 46.3,
        "y": 25.72
    },
    {
        "x": 46.31,
        "y": 25.72
    },
    {
        "x": 46.32,
        "y": 25.72
    },
    {
        "x": 46.33,
        "y": 25.72
    },
    {
        "x": 46.34,
        "y": 25.72
    },
    {
        "x": 46.35,
        "y": 25.72
    },
    {
        "x": 46.36,
        "y": 25.72
    },
    {
        "x": 46.37,
        "y": 25.72
    },
    {
        "x": 46.38,
        "y": 25.72
    },
    {
        "x": 46.39,
        "y": 25.72
    },
    {
        "x": 46.4,
        "y": 25.72
    },
    {
        "x": 46.41,
        "y": 25.72
    },
    {
        "x": 46.42,
        "y": 25.72
    },
    {
        "x": 46.43,
        "y": 25.72
    },
    {
        "x": 46.44,
        "y": 25.72
    },
    {
        "x": 46.45,
        "y": 25.72
    },
    {
        "x": 46.46,
        "y": 25.72
    },
    {
        "x": 46.47,
        "y": 25.72
    },
    {
        "x": 46.48,
        "y": 25.72
    },
    {
        "x": 46.49,
        "y": 25.72
    },
    {
        "x": 46.5,
        "y": 25.72
    },
    {
        "x": 46.51,
        "y": 25.72
    },
    {
        "x": 46.52,
        "y": 25.72
    },
    {
        "x": 46.53,
        "y": 25.72
    },
    {
        "x": 46.54,
        "y": 25.72
    },
    {
        "x": 46.55,
        "y": 25.72
    },
    {
        "x": 46.56,
        "y": 25.72
    },
    {
        "x": 46.57,
        "y": 25.72
    },
    {
        "x": 46.58,
        "y": 25.72
    },
    {
        "x": 46.59,
        "y": 25.72
    },
    {
        "x": 46.6,
        "y": 25.72
    },
    {
        "x": 46.61,
        "y": 25.72
    },
    {
        "x": 46.62,
        "y": 25.72
    },
    {
        "x": 46.63,
        "y": 25.72
    },
    {
        "x": 46.64,
        "y": 25.72
    },
    {
        "x": 46.65,
        "y": 25.72
    },
    {
        "x": 46.66,
        "y": 25.72
    },
    {
        "x": 46.67,
        "y": 25.72
    },
    {
        "x": 46.68,
        "y": 25.72
    },
    {
        "x": 46.69,
        "y": 25.72
    },
    {
        "x": 46.7,
        "y": 25.72
    },
    {
        "x": 46.71,
        "y": 25.72
    },
    {
        "x": 46.72,
        "y": 25.72
    },
    {
        "x": 46.73,
        "y": 25.72
    },
    {
        "x": 46.74,
        "y": 25.72
    },
    {
        "x": 46.75,
        "y": 25.72
    },
    {
        "x": 46.76,
        "y": 25.72
    },
    {
        "x": 46.77,
        "y": 25.72
    },
    {
        "x": 46.78,
        "y": 25.72
    },
    {
        "x": 46.79,
        "y": 25.72
    },
    {
        "x": 46.8,
        "y": 25.73
    },
    {
        "x": 46.81,
        "y": 25.72
    },
    {
        "x": 46.82,
        "y": 25.72
    },
    {
        "x": 46.83,
        "y": 25.72
    },
    {
        "x": 46.84,
        "y": 25.72
    },
    {
        "x": 46.85,
        "y": 25.72
    },
    {
        "x": 46.86,
        "y": 25.73
    },
    {
        "x": 46.87,
        "y": 25.72
    },
    {
        "x": 46.88,
        "y": 25.72
    },
    {
        "x": 46.89,
        "y": 25.72
    },
    {
        "x": 46.9,
        "y": 25.73
    },
    {
        "x": 46.91,
        "y": 25.72
    },
    {
        "x": 46.92,
        "y": 25.72
    },
    {
        "x": 46.93,
        "y": 25.72
    },
    {
        "x": 46.94,
        "y": 25.73
    },
    {
        "x": 46.95,
        "y": 25.72
    },
    {
        "x": 46.96,
        "y": 25.72
    },
    {
        "x": 46.97,
        "y": 25.72
    },
    {
        "x": 46.98,
        "y": 25.72
    },
    {
        "x": 46.99,
        "y": 25.72
    },
    {
        "x": 47,
        "y": 25.72
    },
    {
        "x": 47.01,
        "y": 25.73
    },
    {
        "x": 47.02,
        "y": 25.72
    },
    {
        "x": 47.03,
        "y": 25.72
    },
    {
        "x": 47.04,
        "y": 25.73
    },
    {
        "x": 47.05,
        "y": 25.73
    },
    {
        "x": 47.06,
        "y": 25.73
    },
    {
        "x": 47.07,
        "y": 25.72
    },
    {
        "x": 47.08,
        "y": 25.72
    },
    {
        "x": 47.09,
        "y": 25.72
    },
    {
        "x": 47.1,
        "y": 25.73
    },
    {
        "x": 47.11,
        "y": 25.73
    },
    {
        "x": 47.12,
        "y": 25.73
    },
    {
        "x": 47.13,
        "y": 25.73
    },
    {
        "x": 47.14,
        "y": 25.73
    },
    {
        "x": 47.15,
        "y": 25.72
    },
    {
        "x": 47.16,
        "y": 25.72
    },
    {
        "x": 47.17,
        "y": 25.73
    },
    {
        "x": 47.18,
        "y": 25.73
    },
    {
        "x": 47.19,
        "y": 25.73
    },
    {
        "x": 47.2,
        "y": 25.73
    },
    {
        "x": 47.21,
        "y": 25.73
    },
    {
        "x": 47.22,
        "y": 25.73
    },
    {
        "x": 47.23,
        "y": 25.73
    },
    {
        "x": 47.24,
        "y": 25.73
    },
    {
        "x": 47.25,
        "y": 25.73
    },
    {
        "x": 47.26,
        "y": 25.73
    },
    {
        "x": 47.27,
        "y": 25.73
    },
    {
        "x": 47.28,
        "y": 25.73
    },
    {
        "x": 47.29,
        "y": 25.73
    },
    {
        "x": 47.3,
        "y": 25.73
    },
    {
        "x": 47.31,
        "y": 25.73
    },
    {
        "x": 47.32,
        "y": 25.73
    },
    {
        "x": 47.33,
        "y": 25.73
    },
    {
        "x": 47.34,
        "y": 25.73
    },
    {
        "x": 47.35,
        "y": 25.73
    },
    {
        "x": 47.36,
        "y": 25.73
    },
    {
        "x": 47.37,
        "y": 25.73
    },
    {
        "x": 47.38,
        "y": 25.73
    },
    {
        "x": 47.39,
        "y": 25.73
    },
    {
        "x": 47.4,
        "y": 25.73
    },
    {
        "x": 47.41,
        "y": 25.73
    },
    {
        "x": 47.42,
        "y": 25.73
    },
    {
        "x": 47.43,
        "y": 25.73
    },
    {
        "x": 47.44,
        "y": 25.73
    },
    {
        "x": 47.45,
        "y": 25.73
    },
    {
        "x": 47.46,
        "y": 25.73
    },
    {
        "x": 47.47,
        "y": 25.73
    },
    {
        "x": 47.48,
        "y": 25.73
    },
    {
        "x": 47.49,
        "y": 25.73
    },
    {
        "x": 47.5,
        "y": 25.73
    },
    {
        "x": 47.51,
        "y": 25.73
    },
    {
        "x": 47.52,
        "y": 25.73
    },
    {
        "x": 47.53,
        "y": 25.73
    },
    {
        "x": 47.54,
        "y": 25.73
    },
    {
        "x": 47.55,
        "y": 25.73
    },
    {
        "x": 47.56,
        "y": 25.73
    },
    {
        "x": 47.57,
        "y": 25.73
    },
    {
        "x": 47.58,
        "y": 25.73
    },
    {
        "x": 47.59,
        "y": 25.73
    },
    {
        "x": 47.6,
        "y": 25.73
    },
    {
        "x": 47.61,
        "y": 25.73
    },
    {
        "x": 47.62,
        "y": 25.73
    },
    {
        "x": 47.63,
        "y": 25.73
    },
    {
        "x": 47.64,
        "y": 25.73
    },
    {
        "x": 47.65,
        "y": 25.73
    },
    {
        "x": 47.66,
        "y": 25.73
    },
    {
        "x": 47.67,
        "y": 25.73
    },
    {
        "x": 47.68,
        "y": 25.73
    },
    {
        "x": 47.69,
        "y": 25.73
    },
    {
        "x": 47.7,
        "y": 25.73
    },
    {
        "x": 47.71,
        "y": 25.73
    },
    {
        "x": 47.72,
        "y": 25.73
    },
    {
        "x": 47.73,
        "y": 25.73
    },
    {
        "x": 47.74,
        "y": 25.73
    },
    {
        "x": 47.75,
        "y": 25.73
    },
    {
        "x": 47.76,
        "y": 25.73
    },
    {
        "x": 47.77,
        "y": 25.73
    },
    {
        "x": 47.78,
        "y": 25.73
    },
    {
        "x": 47.79,
        "y": 25.74
    },
    {
        "x": 47.8,
        "y": 25.73
    },
    {
        "x": 47.81,
        "y": 25.73
    },
    {
        "x": 47.82,
        "y": 25.74
    },
    {
        "x": 47.83,
        "y": 25.73
    },
    {
        "x": 47.84,
        "y": 25.74
    },
    {
        "x": 47.85,
        "y": 25.74
    },
    {
        "x": 47.86,
        "y": 25.73
    },
    {
        "x": 47.87,
        "y": 25.73
    },
    {
        "x": 47.88,
        "y": 25.73
    },
    {
        "x": 47.89,
        "y": 25.73
    },
    {
        "x": 47.9,
        "y": 25.73
    },
    {
        "x": 47.91,
        "y": 25.73
    },
    {
        "x": 47.92,
        "y": 25.73
    },
    {
        "x": 47.93,
        "y": 25.73
    },
    {
        "x": 47.94,
        "y": 25.73
    },
    {
        "x": 47.95,
        "y": 25.73
    },
    {
        "x": 47.96,
        "y": 25.73
    },
    {
        "x": 47.97,
        "y": 25.73
    },
    {
        "x": 47.98,
        "y": 25.73
    },
    {
        "x": 47.99,
        "y": 25.74
    },
    {
        "x": 48,
        "y": 25.74
    },
    {
        "x": 48.01,
        "y": 25.73
    },
    {
        "x": 48.02,
        "y": 25.74
    },
    {
        "x": 48.03,
        "y": 25.73
    },
    {
        "x": 48.04,
        "y": 25.73
    },
    {
        "x": 48.05,
        "y": 25.73
    },
    {
        "x": 48.06,
        "y": 25.74
    },
    {
        "x": 48.07,
        "y": 25.73
    },
    {
        "x": 48.08,
        "y": 25.73
    },
    {
        "x": 48.09,
        "y": 25.73
    },
    {
        "x": 48.1,
        "y": 25.73
    },
    {
        "x": 48.11,
        "y": 25.73
    },
    {
        "x": 48.12,
        "y": 25.73
    },
    {
        "x": 48.13,
        "y": 25.74
    },
    {
        "x": 48.14,
        "y": 25.73
    },
    {
        "x": 48.15,
        "y": 25.73
    },
    {
        "x": 48.16,
        "y": 25.74
    },
    {
        "x": 48.17,
        "y": 25.74
    },
    {
        "x": 48.18,
        "y": 25.73
    },
    {
        "x": 48.19,
        "y": 25.73
    },
    {
        "x": 48.2,
        "y": 25.74
    },
    {
        "x": 48.21,
        "y": 25.73
    },
    {
        "x": 48.22,
        "y": 25.73
    },
    {
        "x": 48.23,
        "y": 25.73
    },
    {
        "x": 48.24,
        "y": 25.74
    },
    {
        "x": 48.25,
        "y": 25.74
    },
    {
        "x": 48.26,
        "y": 25.73
    },
    {
        "x": 48.27,
        "y": 25.74
    },
    {
        "x": 48.28,
        "y": 25.73
    },
    {
        "x": 48.29,
        "y": 25.74
    },
    {
        "x": 48.3,
        "y": 25.74
    },
    {
        "x": 48.31,
        "y": 25.74
    },
    {
        "x": 48.32,
        "y": 25.74
    },
    {
        "x": 48.33,
        "y": 25.74
    },
    {
        "x": 48.34,
        "y": 25.74
    },
    {
        "x": 48.35,
        "y": 25.74
    },
    {
        "x": 48.36,
        "y": 25.74
    },
    {
        "x": 48.37,
        "y": 25.74
    },
    {
        "x": 48.38,
        "y": 25.74
    },
    {
        "x": 48.39,
        "y": 25.74
    },
    {
        "x": 48.4,
        "y": 25.74
    },
    {
        "x": 48.41,
        "y": 25.74
    },
    {
        "x": 48.42,
        "y": 25.74
    },
    {
        "x": 48.43,
        "y": 25.74
    },
    {
        "x": 48.44,
        "y": 25.74
    },
    {
        "x": 48.45,
        "y": 25.74
    },
    {
        "x": 48.46,
        "y": 25.74
    },
    {
        "x": 48.47,
        "y": 25.74
    },
    {
        "x": 48.48,
        "y": 25.74
    },
    {
        "x": 48.49,
        "y": 25.74
    },
    {
        "x": 48.5,
        "y": 25.74
    },
    {
        "x": 48.51,
        "y": 25.74
    },
    {
        "x": 48.52,
        "y": 25.74
    },
    {
        "x": 48.53,
        "y": 25.74
    },
    {
        "x": 48.54,
        "y": 25.74
    },
    {
        "x": 48.55,
        "y": 25.74
    },
    {
        "x": 48.56,
        "y": 25.74
    },
    {
        "x": 48.57,
        "y": 25.74
    },
    {
        "x": 48.58,
        "y": 25.74
    },
    {
        "x": 48.59,
        "y": 25.74
    },
    {
        "x": 48.6,
        "y": 25.74
    },
    {
        "x": 48.61,
        "y": 25.74
    },
    {
        "x": 48.62,
        "y": 25.74
    },
    {
        "x": 48.63,
        "y": 25.74
    },
    {
        "x": 48.64,
        "y": 25.74
    },
    {
        "x": 48.65,
        "y": 25.74
    },
    {
        "x": 48.66,
        "y": 25.74
    },
    {
        "x": 48.67,
        "y": 25.74
    },
    {
        "x": 48.68,
        "y": 25.74
    },
    {
        "x": 48.69,
        "y": 25.74
    },
    {
        "x": 48.7,
        "y": 25.74
    },
    {
        "x": 48.71,
        "y": 25.74
    },
    {
        "x": 48.72,
        "y": 25.74
    },
    {
        "x": 48.73,
        "y": 25.74
    },
    {
        "x": 48.74,
        "y": 25.74
    },
    {
        "x": 48.75,
        "y": 25.74
    },
    {
        "x": 48.76,
        "y": 25.74
    },
    {
        "x": 48.77,
        "y": 25.74
    },
    {
        "x": 48.78,
        "y": 25.74
    },
    {
        "x": 48.79,
        "y": 25.74
    },
    {
        "x": 48.8,
        "y": 25.74
    },
    {
        "x": 48.81,
        "y": 25.74
    },
    {
        "x": 48.82,
        "y": 25.74
    },
    {
        "x": 48.83,
        "y": 25.74
    },
    {
        "x": 48.84,
        "y": 25.74
    },
    {
        "x": 48.85,
        "y": 25.74
    },
    {
        "x": 48.86,
        "y": 25.74
    },
    {
        "x": 48.87,
        "y": 25.74
    },
    {
        "x": 48.88,
        "y": 25.74
    },
    {
        "x": 48.89,
        "y": 25.74
    },
    {
        "x": 48.9,
        "y": 25.74
    },
    {
        "x": 48.91,
        "y": 25.74
    },
    {
        "x": 48.92,
        "y": 25.74
    },
    {
        "x": 48.93,
        "y": 25.74
    },
    {
        "x": 48.94,
        "y": 25.74
    },
    {
        "x": 48.95,
        "y": 25.74
    },
    {
        "x": 48.96,
        "y": 25.74
    },
    {
        "x": 48.97,
        "y": 25.74
    },
    {
        "x": 48.98,
        "y": 25.74
    },
    {
        "x": 48.99,
        "y": 25.74
    },
    {
        "x": 49,
        "y": 25.74
    },
    {
        "x": 49.01,
        "y": 25.74
    },
    {
        "x": 49.02,
        "y": 25.74
    },
    {
        "x": 49.03,
        "y": 25.74
    },
    {
        "x": 49.04,
        "y": 25.74
    },
    {
        "x": 49.05,
        "y": 25.74
    },
    {
        "x": 49.06,
        "y": 25.74
    },
    {
        "x": 49.07,
        "y": 25.74
    },
    {
        "x": 49.08,
        "y": 25.74
    },
    {
        "x": 49.09,
        "y": 25.74
    },
    {
        "x": 49.1,
        "y": 25.74
    },
    {
        "x": 49.11,
        "y": 25.74
    },
    {
        "x": 49.12,
        "y": 25.74
    },
    {
        "x": 49.13,
        "y": 25.74
    },
    {
        "x": 49.14,
        "y": 25.74
    },
    {
        "x": 49.15,
        "y": 25.74
    },
    {
        "x": 49.16,
        "y": 25.74
    },
    {
        "x": 49.17,
        "y": 25.74
    },
    {
        "x": 49.18,
        "y": 25.74
    },
    {
        "x": 49.19,
        "y": 25.74
    },
    {
        "x": 49.2,
        "y": 25.74
    },
    {
        "x": 49.21,
        "y": 25.74
    },
    {
        "x": 49.22,
        "y": 25.74
    },
    {
        "x": 49.23,
        "y": 25.74
    },
    {
        "x": 49.24,
        "y": 25.75
    },
    {
        "x": 49.25,
        "y": 25.74
    },
    {
        "x": 49.26,
        "y": 25.74
    },
    {
        "x": 49.27,
        "y": 25.74
    },
    {
        "x": 49.28,
        "y": 25.74
    },
    {
        "x": 49.29,
        "y": 25.74
    },
    {
        "x": 49.3,
        "y": 25.74
    },
    {
        "x": 49.31,
        "y": 25.74
    },
    {
        "x": 49.32,
        "y": 25.74
    },
    {
        "x": 49.33,
        "y": 25.74
    },
    {
        "x": 49.34,
        "y": 25.74
    },
    {
        "x": 49.35,
        "y": 25.74
    },
    {
        "x": 49.36,
        "y": 25.74
    },
    {
        "x": 49.37,
        "y": 25.74
    },
    {
        "x": 49.38,
        "y": 25.74
    },
    {
        "x": 49.39,
        "y": 25.74
    },
    {
        "x": 49.4,
        "y": 25.74
    },
    {
        "x": 49.41,
        "y": 25.74
    },
    {
        "x": 49.42,
        "y": 25.75
    },
    {
        "x": 49.43,
        "y": 25.75
    },
    {
        "x": 49.44,
        "y": 25.75
    },
    {
        "x": 49.45,
        "y": 25.75
    },
    {
        "x": 49.46,
        "y": 25.75
    },
    {
        "x": 49.47,
        "y": 25.74
    },
    {
        "x": 49.48,
        "y": 25.74
    },
    {
        "x": 49.49,
        "y": 25.74
    },
    {
        "x": 49.5,
        "y": 25.74
    },
    {
        "x": 49.51,
        "y": 25.75
    },
    {
        "x": 49.52,
        "y": 25.75
    },
    {
        "x": 49.53,
        "y": 25.75
    },
    {
        "x": 49.54,
        "y": 25.75
    },
    {
        "x": 49.55,
        "y": 25.75
    },
    {
        "x": 49.56,
        "y": 25.74
    },
    {
        "x": 49.57,
        "y": 25.74
    },
    {
        "x": 49.58,
        "y": 25.75
    },
    {
        "x": 49.59,
        "y": 25.75
    },
    {
        "x": 49.6,
        "y": 25.75
    },
    {
        "x": 49.61,
        "y": 25.75
    },
    {
        "x": 49.62,
        "y": 25.75
    },
    {
        "x": 49.63,
        "y": 25.75
    },
    {
        "x": 49.64,
        "y": 25.74
    },
    {
        "x": 49.65,
        "y": 25.75
    },
    {
        "x": 49.66,
        "y": 25.75
    },
    {
        "x": 49.67,
        "y": 25.75
    },
    {
        "x": 49.68,
        "y": 25.75
    },
    {
        "x": 49.69,
        "y": 25.75
    },
    {
        "x": 49.7,
        "y": 25.75
    },
    {
        "x": 49.71,
        "y": 25.75
    },
    {
        "x": 49.72,
        "y": 25.75
    },
    {
        "x": 49.73,
        "y": 25.75
    },
    {
        "x": 49.74,
        "y": 25.75
    },
    {
        "x": 49.75,
        "y": 25.75
    },
    {
        "x": 49.76,
        "y": 25.75
    },
    {
        "x": 49.77,
        "y": 25.75
    },
    {
        "x": 49.78,
        "y": 25.75
    },
    {
        "x": 49.79,
        "y": 25.75
    },
    {
        "x": 49.8,
        "y": 25.75
    },
    {
        "x": 49.81,
        "y": 25.75
    },
    {
        "x": 49.82,
        "y": 25.75
    },
    {
        "x": 49.83,
        "y": 25.75
    },
    {
        "x": 49.84,
        "y": 25.75
    },
    {
        "x": 49.85,
        "y": 25.75
    },
    {
        "x": 49.86,
        "y": 25.75
    },
    {
        "x": 49.87,
        "y": 25.75
    },
    {
        "x": 49.88,
        "y": 25.75
    },
    {
        "x": 49.89,
        "y": 25.75
    },
    {
        "x": 49.9,
        "y": 25.75
    },
    {
        "x": 49.91,
        "y": 25.75
    },
    {
        "x": 49.92,
        "y": 25.75
    },
    {
        "x": 49.93,
        "y": 25.75
    },
    {
        "x": 49.94,
        "y": 25.75
    },
    {
        "x": 49.95,
        "y": 25.75
    },
    {
        "x": 49.96,
        "y": 25.75
    },
    {
        "x": 49.97,
        "y": 25.75
    },
    {
        "x": 49.98,
        "y": 25.75
    },
    {
        "x": 49.99,
        "y": 25.75
    },
    {
        "x": 50,
        "y": 25.75
    },
    {
        "x": 50.01,
        "y": 25.75
    },
    {
        "x": 50.02,
        "y": 25.75
    },
    {
        "x": 50.03,
        "y": 25.75
    },
    {
        "x": 50.04,
        "y": 25.75
    },
    {
        "x": 50.05,
        "y": 25.75
    },
    {
        "x": 50.06,
        "y": 25.75
    },
    {
        "x": 50.07,
        "y": 25.75
    },
    {
        "x": 50.08,
        "y": 25.75
    },
    {
        "x": 50.09,
        "y": 25.75
    },
    {
        "x": 50.1,
        "y": 25.75
    },
    {
        "x": 50.11,
        "y": 25.75
    },
    {
        "x": 50.12,
        "y": 25.75
    },
    {
        "x": 50.13,
        "y": 25.75
    },
    {
        "x": 50.14,
        "y": 25.75
    },
    {
        "x": 50.15,
        "y": 25.75
    },
    {
        "x": 50.16,
        "y": 25.75
    },
    {
        "x": 50.17,
        "y": 25.75
    },
    {
        "x": 50.18,
        "y": 25.75
    },
    {
        "x": 50.19,
        "y": 25.75
    },
    {
        "x": 50.2,
        "y": 25.75
    },
    {
        "x": 50.21,
        "y": 25.75
    },
    {
        "x": 50.22,
        "y": 25.75
    },
    {
        "x": 50.23,
        "y": 25.75
    },
    {
        "x": 50.24,
        "y": 25.75
    },
    {
        "x": 50.25,
        "y": 25.75
    },
    {
        "x": 50.26,
        "y": 25.75
    },
    {
        "x": 50.27,
        "y": 25.75
    },
    {
        "x": 50.28,
        "y": 25.75
    },
    {
        "x": 50.29,
        "y": 25.75
    },
    {
        "x": 50.3,
        "y": 25.75
    },
    {
        "x": 50.31,
        "y": 25.75
    },
    {
        "x": 50.32,
        "y": 25.75
    },
    {
        "x": 50.33,
        "y": 25.75
    },
    {
        "x": 50.34,
        "y": 25.75
    },
    {
        "x": 50.35,
        "y": 25.75
    },
    {
        "x": 50.36,
        "y": 25.75
    },
    {
        "x": 50.37,
        "y": 25.75
    },
    {
        "x": 50.38,
        "y": 25.75
    },
    {
        "x": 50.39,
        "y": 25.75
    },
    {
        "x": 50.4,
        "y": 25.75
    },
    {
        "x": 50.41,
        "y": 25.75
    },
    {
        "x": 50.42,
        "y": 25.75
    },
    {
        "x": 50.43,
        "y": 25.75
    },
    {
        "x": 50.44,
        "y": 25.75
    },
    {
        "x": 50.45,
        "y": 25.75
    },
    {
        "x": 50.46,
        "y": 25.75
    },
    {
        "x": 50.47,
        "y": 25.75
    },
    {
        "x": 50.48,
        "y": 25.75
    },
    {
        "x": 50.49,
        "y": 25.75
    },
    {
        "x": 50.5,
        "y": 25.75
    },
    {
        "x": 50.51,
        "y": 25.75
    },
    {
        "x": 50.52,
        "y": 25.75
    },
    {
        "x": 50.53,
        "y": 25.75
    },
    {
        "x": 50.54,
        "y": 25.75
    },
    {
        "x": 50.55,
        "y": 25.75
    },
    {
        "x": 50.56,
        "y": 25.75
    },
    {
        "x": 50.57,
        "y": 25.75
    },
    {
        "x": 50.58,
        "y": 25.75
    },
    {
        "x": 50.59,
        "y": 25.75
    },
    {
        "x": 50.6,
        "y": 25.75
    },
    {
        "x": 50.61,
        "y": 25.75
    },
    {
        "x": 50.62,
        "y": 25.75
    },
    {
        "x": 50.63,
        "y": 25.75
    },
    {
        "x": 50.64,
        "y": 25.75
    },
    {
        "x": 50.65,
        "y": 25.75
    },
    {
        "x": 50.66,
        "y": 25.75
    },
    {
        "x": 50.67,
        "y": 25.75
    },
    {
        "x": 50.68,
        "y": 25.75
    },
    {
        "x": 50.69,
        "y": 25.75
    },
    {
        "x": 50.7,
        "y": 25.75
    },
    {
        "x": 50.71,
        "y": 25.75
    },
    {
        "x": 50.72,
        "y": 25.75
    },
    {
        "x": 50.73,
        "y": 25.76
    },
    {
        "x": 50.74,
        "y": 25.75
    },
    {
        "x": 50.75,
        "y": 25.75
    },
    {
        "x": 50.76,
        "y": 25.75
    },
    {
        "x": 50.77,
        "y": 25.75
    },
    {
        "x": 50.78,
        "y": 25.75
    },
    {
        "x": 50.79,
        "y": 25.76
    },
    {
        "x": 50.8,
        "y": 25.75
    },
    {
        "x": 50.81,
        "y": 25.76
    },
    {
        "x": 50.82,
        "y": 25.75
    },
    {
        "x": 50.83,
        "y": 25.75
    },
    {
        "x": 50.84,
        "y": 25.75
    },
    {
        "x": 50.85,
        "y": 25.76
    },
    {
        "x": 50.86,
        "y": 25.76
    },
    {
        "x": 50.87,
        "y": 25.76
    },
    {
        "x": 50.88,
        "y": 25.75
    },
    {
        "x": 50.89,
        "y": 25.76
    },
    {
        "x": 50.9,
        "y": 25.76
    },
    {
        "x": 50.91,
        "y": 25.76
    },
    {
        "x": 50.92,
        "y": 25.76
    },
    {
        "x": 50.93,
        "y": 25.76
    },
    {
        "x": 50.94,
        "y": 25.76
    },
    {
        "x": 50.95,
        "y": 25.76
    },
    {
        "x": 50.96,
        "y": 25.76
    },
    {
        "x": 50.97,
        "y": 25.76
    },
    {
        "x": 50.98,
        "y": 25.76
    },
    {
        "x": 50.99,
        "y": 25.76
    },
    {
        "x": 51,
        "y": 25.75
    },
    {
        "x": 51.01,
        "y": 25.75
    },
    {
        "x": 51.02,
        "y": 25.76
    },
    {
        "x": 51.03,
        "y": 25.75
    },
    {
        "x": 51.04,
        "y": 25.76
    },
    {
        "x": 51.05,
        "y": 25.76
    },
    {
        "x": 51.06,
        "y": 25.76
    },
    {
        "x": 51.07,
        "y": 25.75
    },
    {
        "x": 51.08,
        "y": 25.76
    },
    {
        "x": 51.09,
        "y": 25.76
    },
    {
        "x": 51.1,
        "y": 25.75
    },
    {
        "x": 51.11,
        "y": 25.75
    },
    {
        "x": 51.12,
        "y": 25.75
    },
    {
        "x": 51.13,
        "y": 25.75
    },
    {
        "x": 51.14,
        "y": 25.75
    },
    {
        "x": 51.15,
        "y": 25.75
    },
    {
        "x": 51.16,
        "y": 25.76
    },
    {
        "x": 51.17,
        "y": 25.76
    },
    {
        "x": 51.18,
        "y": 25.76
    },
    {
        "x": 51.19,
        "y": 25.75
    },
    {
        "x": 51.2,
        "y": 25.76
    },
    {
        "x": 51.21,
        "y": 25.75
    },
    {
        "x": 51.22,
        "y": 25.75
    },
    {
        "x": 51.23,
        "y": 25.76
    },
    {
        "x": 51.24,
        "y": 25.76
    },
    {
        "x": 51.25,
        "y": 25.75
    },
    {
        "x": 51.26,
        "y": 25.76
    },
    {
        "x": 51.27,
        "y": 25.76
    },
    {
        "x": 51.28,
        "y": 25.75
    },
    {
        "x": 51.29,
        "y": 25.75
    },
    {
        "x": 51.3,
        "y": 25.75
    },
    {
        "x": 51.31,
        "y": 25.75
    },
    {
        "x": 51.32,
        "y": 25.75
    },
    {
        "x": 51.33,
        "y": 25.75
    },
    {
        "x": 51.34,
        "y": 25.75
    },
    {
        "x": 51.35,
        "y": 25.75
    },
    {
        "x": 51.36,
        "y": 25.75
    },
    {
        "x": 51.37,
        "y": 25.75
    },
    {
        "x": 51.38,
        "y": 25.75
    },
    {
        "x": 51.39,
        "y": 25.75
    },
    {
        "x": 51.4,
        "y": 25.76
    },
    {
        "x": 51.41,
        "y": 25.76
    },
    {
        "x": 51.42,
        "y": 25.75
    },
    {
        "x": 51.43,
        "y": 25.75
    },
    {
        "x": 51.44,
        "y": 25.76
    },
    {
        "x": 51.45,
        "y": 25.75
    },
    {
        "x": 51.46,
        "y": 25.75
    },
    {
        "x": 51.47,
        "y": 25.75
    },
    {
        "x": 51.48,
        "y": 25.75
    },
    {
        "x": 51.49,
        "y": 25.75
    },
    {
        "x": 51.5,
        "y": 25.75
    },
    {
        "x": 51.51,
        "y": 25.75
    },
    {
        "x": 51.52,
        "y": 25.76
    },
    {
        "x": 51.53,
        "y": 25.76
    },
    {
        "x": 51.54,
        "y": 25.76
    },
    {
        "x": 51.55,
        "y": 25.75
    },
    {
        "x": 51.56,
        "y": 25.75
    },
    {
        "x": 51.57,
        "y": 25.75
    },
    {
        "x": 51.58,
        "y": 25.75
    },
    {
        "x": 51.59,
        "y": 25.75
    },
    {
        "x": 51.6,
        "y": 25.75
    },
    {
        "x": 51.61,
        "y": 25.76
    },
    {
        "x": 51.62,
        "y": 25.76
    },
    {
        "x": 51.63,
        "y": 25.75
    },
    {
        "x": 51.64,
        "y": 25.75
    },
    {
        "x": 51.65,
        "y": 25.75
    },
    {
        "x": 51.66,
        "y": 25.76
    },
    {
        "x": 51.67,
        "y": 25.75
    },
    {
        "x": 51.68,
        "y": 25.76
    },
    {
        "x": 51.69,
        "y": 25.76
    },
    {
        "x": 51.7,
        "y": 25.76
    },
    {
        "x": 51.71,
        "y": 25.76
    },
    {
        "x": 51.72,
        "y": 25.76
    },
    {
        "x": 51.73,
        "y": 25.75
    },
    {
        "x": 51.74,
        "y": 25.76
    },
    {
        "x": 51.75,
        "y": 25.76
    },
    {
        "x": 51.76,
        "y": 25.76
    },
    {
        "x": 51.77,
        "y": 25.76
    },
    {
        "x": 51.78,
        "y": 25.76
    },
    {
        "x": 51.79,
        "y": 25.76
    },
    {
        "x": 51.8,
        "y": 25.76
    },
    {
        "x": 51.81,
        "y": 25.76
    },
    {
        "x": 51.82,
        "y": 25.76
    },
    {
        "x": 51.83,
        "y": 25.76
    },
    {
        "x": 51.84,
        "y": 25.76
    },
    {
        "x": 51.85,
        "y": 25.76
    },
    {
        "x": 51.86,
        "y": 25.76
    },
    {
        "x": 51.87,
        "y": 25.75
    },
    {
        "x": 51.88,
        "y": 25.75
    },
    {
        "x": 51.89,
        "y": 25.76
    },
    {
        "x": 51.9,
        "y": 25.76
    },
    {
        "x": 51.91,
        "y": 25.76
    },
    {
        "x": 51.92,
        "y": 25.76
    },
    {
        "x": 51.93,
        "y": 25.76
    },
    {
        "x": 51.94,
        "y": 25.76
    },
    {
        "x": 51.95,
        "y": 25.76
    },
    {
        "x": 51.96,
        "y": 25.75
    },
    {
        "x": 51.97,
        "y": 25.76
    },
    {
        "x": 51.98,
        "y": 25.76
    },
    {
        "x": 51.99,
        "y": 25.76
    },
    {
        "x": 52,
        "y": 25.76
    },
    {
        "x": 52.01,
        "y": 25.76
    },
    {
        "x": 52.02,
        "y": 25.76
    },
    {
        "x": 52.03,
        "y": 25.76
    },
    {
        "x": 52.04,
        "y": 25.76
    },
    {
        "x": 52.05,
        "y": 25.76
    },
    {
        "x": 52.06,
        "y": 25.76
    },
    {
        "x": 52.07,
        "y": 25.76
    },
    {
        "x": 52.08,
        "y": 25.76
    },
    {
        "x": 52.09,
        "y": 25.76
    },
    {
        "x": 52.1,
        "y": 25.76
    },
    {
        "x": 52.11,
        "y": 25.76
    },
    {
        "x": 52.12,
        "y": 25.76
    },
    {
        "x": 52.13,
        "y": 25.76
    },
    {
        "x": 52.14,
        "y": 25.76
    },
    {
        "x": 52.15,
        "y": 25.76
    },
    {
        "x": 52.16,
        "y": 25.76
    },
    {
        "x": 52.17,
        "y": 25.76
    },
    {
        "x": 52.18,
        "y": 25.76
    },
    {
        "x": 52.19,
        "y": 25.76
    },
    {
        "x": 52.2,
        "y": 25.76
    },
    {
        "x": 52.21,
        "y": 25.76
    },
    {
        "x": 52.22,
        "y": 25.76
    },
    {
        "x": 52.23,
        "y": 25.76
    },
    {
        "x": 52.24,
        "y": 25.76
    },
    {
        "x": 52.25,
        "y": 25.76
    },
    {
        "x": 52.26,
        "y": 25.76
    },
    {
        "x": 52.27,
        "y": 25.76
    },
    {
        "x": 52.28,
        "y": 25.76
    },
    {
        "x": 52.29,
        "y": 25.76
    },
    {
        "x": 52.3,
        "y": 25.76
    },
    {
        "x": 52.31,
        "y": 25.76
    },
    {
        "x": 52.32,
        "y": 25.76
    },
    {
        "x": 52.33,
        "y": 25.76
    },
    {
        "x": 52.34,
        "y": 25.76
    },
    {
        "x": 52.35,
        "y": 25.76
    },
    {
        "x": 52.36,
        "y": 25.76
    },
    {
        "x": 52.37,
        "y": 25.76
    },
    {
        "x": 52.38,
        "y": 25.76
    },
    {
        "x": 52.39,
        "y": 25.76
    },
    {
        "x": 52.4,
        "y": 25.76
    },
    {
        "x": 52.41,
        "y": 25.76
    },
    {
        "x": 52.42,
        "y": 25.76
    },
    {
        "x": 52.43,
        "y": 25.76
    },
    {
        "x": 52.44,
        "y": 25.76
    },
    {
        "x": 52.45,
        "y": 25.76
    },
    {
        "x": 52.46,
        "y": 25.76
    },
    {
        "x": 52.47,
        "y": 25.76
    },
    {
        "x": 52.48,
        "y": 25.76
    },
    {
        "x": 52.49,
        "y": 25.76
    },
    {
        "x": 52.5,
        "y": 25.76
    },
    {
        "x": 52.51,
        "y": 25.76
    },
    {
        "x": 52.52,
        "y": 25.76
    },
    {
        "x": 52.53,
        "y": 25.76
    },
    {
        "x": 52.54,
        "y": 25.76
    },
    {
        "x": 52.55,
        "y": 25.76
    },
    {
        "x": 52.56,
        "y": 25.76
    },
    {
        "x": 52.57,
        "y": 25.76
    },
    {
        "x": 52.58,
        "y": 25.76
    },
    {
        "x": 52.59,
        "y": 25.76
    },
    {
        "x": 52.6,
        "y": 25.76
    },
    {
        "x": 52.61,
        "y": 25.76
    },
    {
        "x": 52.62,
        "y": 25.76
    },
    {
        "x": 52.63,
        "y": 25.76
    },
    {
        "x": 52.64,
        "y": 25.76
    },
    {
        "x": 52.65,
        "y": 25.76
    },
    {
        "x": 52.66,
        "y": 25.76
    },
    {
        "x": 52.67,
        "y": 25.76
    },
    {
        "x": 52.68,
        "y": 25.76
    },
    {
        "x": 52.69,
        "y": 25.76
    },
    {
        "x": 52.7,
        "y": 25.76
    },
    {
        "x": 52.71,
        "y": 25.76
    },
    {
        "x": 52.72,
        "y": 25.76
    },
    {
        "x": 52.73,
        "y": 25.76
    },
    {
        "x": 52.74,
        "y": 25.76
    },
    {
        "x": 52.75,
        "y": 25.76
    },
    {
        "x": 52.76,
        "y": 25.76
    },
    {
        "x": 52.77,
        "y": 25.76
    },
    {
        "x": 52.78,
        "y": 25.76
    },
    {
        "x": 52.79,
        "y": 25.76
    },
    {
        "x": 52.8,
        "y": 25.76
    },
    {
        "x": 52.81,
        "y": 25.76
    },
    {
        "x": 52.82,
        "y": 25.76
    },
    {
        "x": 52.83,
        "y": 25.76
    },
    {
        "x": 52.84,
        "y": 25.76
    },
    {
        "x": 52.85,
        "y": 25.76
    },
    {
        "x": 52.86,
        "y": 25.76
    },
    {
        "x": 52.87,
        "y": 25.76
    },
    {
        "x": 52.88,
        "y": 25.76
    },
    {
        "x": 52.89,
        "y": 25.76
    },
    {
        "x": 52.9,
        "y": 25.76
    },
    {
        "x": 52.91,
        "y": 25.76
    },
    {
        "x": 52.92,
        "y": 25.76
    },
    {
        "x": 52.93,
        "y": 25.76
    },
    {
        "x": 52.94,
        "y": 25.76
    },
    {
        "x": 52.95,
        "y": 25.76
    },
    {
        "x": 52.96,
        "y": 25.76
    },
    {
        "x": 52.97,
        "y": 25.76
    },
    {
        "x": 52.98,
        "y": 25.76
    },
    {
        "x": 52.99,
        "y": 25.76
    },
    {
        "x": 53,
        "y": 25.76
    },
    {
        "x": 53.01,
        "y": 25.76
    },
    {
        "x": 53.02,
        "y": 25.76
    },
    {
        "x": 53.03,
        "y": 25.76
    },
    {
        "x": 53.04,
        "y": 25.76
    },
    {
        "x": 53.05,
        "y": 25.76
    },
    {
        "x": 53.06,
        "y": 25.76
    },
    {
        "x": 53.07,
        "y": 25.76
    },
    {
        "x": 53.08,
        "y": 25.76
    },
    {
        "x": 53.09,
        "y": 25.76
    },
    {
        "x": 53.1,
        "y": 25.76
    },
    {
        "x": 53.11,
        "y": 25.76
    },
    {
        "x": 53.12,
        "y": 25.76
    },
    {
        "x": 53.13,
        "y": 25.76
    },
    {
        "x": 53.14,
        "y": 25.76
    },
    {
        "x": 53.15,
        "y": 25.76
    },
    {
        "x": 53.16,
        "y": 25.76
    },
    {
        "x": 53.17,
        "y": 25.76
    },
    {
        "x": 53.18,
        "y": 25.76
    },
    {
        "x": 53.19,
        "y": 25.76
    },
    {
        "x": 53.2,
        "y": 25.76
    },
    {
        "x": 53.21,
        "y": 25.77
    },
    {
        "x": 53.22,
        "y": 25.77
    },
    {
        "x": 53.23,
        "y": 25.77
    },
    {
        "x": 53.24,
        "y": 25.77
    },
    {
        "x": 53.25,
        "y": 25.77
    },
    {
        "x": 53.26,
        "y": 25.76
    },
    {
        "x": 53.27,
        "y": 25.76
    },
    {
        "x": 53.28,
        "y": 25.76
    },
    {
        "x": 53.29,
        "y": 25.77
    },
    {
        "x": 53.3,
        "y": 25.77
    },
    {
        "x": 53.31,
        "y": 25.76
    },
    {
        "x": 53.32,
        "y": 25.76
    },
    {
        "x": 53.33,
        "y": 25.76
    },
    {
        "x": 53.34,
        "y": 25.77
    },
    {
        "x": 53.35,
        "y": 25.76
    },
    {
        "x": 53.36,
        "y": 25.76
    },
    {
        "x": 53.37,
        "y": 25.76
    },
    {
        "x": 53.38,
        "y": 25.76
    },
    {
        "x": 53.39,
        "y": 25.76
    },
    {
        "x": 53.4,
        "y": 25.76
    },
    {
        "x": 53.41,
        "y": 25.76
    },
    {
        "x": 53.42,
        "y": 25.76
    },
    {
        "x": 53.43,
        "y": 25.76
    },
    {
        "x": 53.44,
        "y": 25.76
    },
    {
        "x": 53.45,
        "y": 25.76
    },
    {
        "x": 53.46,
        "y": 25.76
    },
    {
        "x": 53.47,
        "y": 25.76
    },
    {
        "x": 53.48,
        "y": 25.76
    },
    {
        "x": 53.49,
        "y": 25.76
    },
    {
        "x": 53.5,
        "y": 25.76
    },
    {
        "x": 53.51,
        "y": 25.76
    },
    {
        "x": 53.52,
        "y": 25.76
    },
    {
        "x": 53.53,
        "y": 25.76
    },
    {
        "x": 53.54,
        "y": 25.76
    },
    {
        "x": 53.55,
        "y": 25.76
    },
    {
        "x": 53.56,
        "y": 25.76
    },
    {
        "x": 53.57,
        "y": 25.76
    },
    {
        "x": 53.58,
        "y": 25.76
    },
    {
        "x": 53.59,
        "y": 25.76
    },
    {
        "x": 53.6,
        "y": 25.76
    },
    {
        "x": 53.61,
        "y": 25.76
    },
    {
        "x": 53.62,
        "y": 25.76
    },
    {
        "x": 53.63,
        "y": 25.76
    },
    {
        "x": 53.64,
        "y": 25.76
    },
    {
        "x": 53.65,
        "y": 25.76
    },
    {
        "x": 53.66,
        "y": 25.76
    },
    {
        "x": 53.67,
        "y": 25.76
    },
    {
        "x": 53.68,
        "y": 25.76
    },
    {
        "x": 53.69,
        "y": 25.76
    },
    {
        "x": 53.7,
        "y": 25.76
    },
    {
        "x": 53.71,
        "y": 25.76
    },
    {
        "x": 53.72,
        "y": 25.76
    },
    {
        "x": 53.73,
        "y": 25.76
    },
    {
        "x": 53.74,
        "y": 25.76
    },
    {
        "x": 53.75,
        "y": 25.75
    },
    {
        "x": 53.76,
        "y": 25.76
    },
    {
        "x": 53.77,
        "y": 25.76
    },
    {
        "x": 53.78,
        "y": 25.76
    },
    {
        "x": 53.79,
        "y": 25.76
    },
    {
        "x": 53.8,
        "y": 25.76
    },
    {
        "x": 53.81,
        "y": 25.76
    },
    {
        "x": 53.82,
        "y": 25.76
    },
    {
        "x": 53.83,
        "y": 25.76
    },
    {
        "x": 53.84,
        "y": 25.76
    },
    {
        "x": 53.85,
        "y": 25.76
    },
    {
        "x": 53.86,
        "y": 25.76
    },
    {
        "x": 53.87,
        "y": 25.76
    },
    {
        "x": 53.88,
        "y": 25.76
    },
    {
        "x": 53.89,
        "y": 25.76
    },
    {
        "x": 53.9,
        "y": 25.76
    },
    {
        "x": 53.91,
        "y": 25.76
    },
    {
        "x": 53.92,
        "y": 25.76
    },
    {
        "x": 53.93,
        "y": 25.76
    },
    {
        "x": 53.94,
        "y": 25.76
    },
    {
        "x": 53.95,
        "y": 25.76
    },
    {
        "x": 53.96,
        "y": 25.76
    },
    {
        "x": 53.97,
        "y": 25.76
    },
    {
        "x": 53.98,
        "y": 25.76
    },
    {
        "x": 53.99,
        "y": 25.76
    },
    {
        "x": 54,
        "y": 25.76
    },
    {
        "x": 54.01,
        "y": 25.76
    },
    {
        "x": 54.02,
        "y": 25.76
    },
    {
        "x": 54.03,
        "y": 25.76
    },
    {
        "x": 54.04,
        "y": 25.76
    },
    {
        "x": 54.05,
        "y": 25.76
    },
    {
        "x": 54.06,
        "y": 25.76
    },
    {
        "x": 54.07,
        "y": 25.76
    },
    {
        "x": 54.08,
        "y": 25.76
    },
    {
        "x": 54.09,
        "y": 25.76
    },
    {
        "x": 54.1,
        "y": 25.76
    },
    {
        "x": 54.11,
        "y": 25.76
    },
    {
        "x": 54.12,
        "y": 25.76
    },
    {
        "x": 54.13,
        "y": 25.76
    },
    {
        "x": 54.14,
        "y": 25.76
    },
    {
        "x": 54.15,
        "y": 25.76
    },
    {
        "x": 54.16,
        "y": 25.76
    },
    {
        "x": 54.17,
        "y": 25.76
    },
    {
        "x": 54.18,
        "y": 25.76
    },
    {
        "x": 54.19,
        "y": 25.76
    },
    {
        "x": 54.2,
        "y": 25.76
    },
    {
        "x": 54.21,
        "y": 25.76
    },
    {
        "x": 54.22,
        "y": 25.76
    },
    {
        "x": 54.23,
        "y": 25.76
    },
    {
        "x": 54.24,
        "y": 25.76
    },
    {
        "x": 54.25,
        "y": 25.76
    },
    {
        "x": 54.26,
        "y": 25.76
    },
    {
        "x": 54.27,
        "y": 25.76
    },
    {
        "x": 54.28,
        "y": 25.76
    },
    {
        "x": 54.29,
        "y": 25.76
    },
    {
        "x": 54.3,
        "y": 25.76
    },
    {
        "x": 54.31,
        "y": 25.76
    },
    {
        "x": 54.32,
        "y": 25.76
    },
    {
        "x": 54.33,
        "y": 25.76
    },
    {
        "x": 54.34,
        "y": 25.76
    },
    {
        "x": 54.35,
        "y": 25.76
    },
    {
        "x": 54.36,
        "y": 25.76
    },
    {
        "x": 54.37,
        "y": 25.76
    },
    {
        "x": 54.38,
        "y": 25.76
    },
    {
        "x": 54.39,
        "y": 25.76
    },
    {
        "x": 54.4,
        "y": 25.76
    },
    {
        "x": 54.41,
        "y": 25.76
    },
    {
        "x": 54.42,
        "y": 25.76
    },
    {
        "x": 54.43,
        "y": 25.76
    },
    {
        "x": 54.44,
        "y": 25.76
    },
    {
        "x": 54.45,
        "y": 25.76
    },
    {
        "x": 54.46,
        "y": 25.76
    },
    {
        "x": 54.47,
        "y": 25.76
    },
    {
        "x": 54.48,
        "y": 25.76
    },
    {
        "x": 54.49,
        "y": 25.76
    },
    {
        "x": 54.5,
        "y": 25.76
    },
    {
        "x": 54.51,
        "y": 25.77
    },
    {
        "x": 54.52,
        "y": 25.77
    },
    {
        "x": 54.53,
        "y": 25.76
    },
    {
        "x": 54.54,
        "y": 25.77
    },
    {
        "x": 54.55,
        "y": 25.76
    },
    {
        "x": 54.56,
        "y": 25.76
    },
    {
        "x": 54.57,
        "y": 25.76
    },
    {
        "x": 54.58,
        "y": 25.76
    },
    {
        "x": 54.59,
        "y": 25.76
    },
    {
        "x": 54.6,
        "y": 25.76
    },
    {
        "x": 54.61,
        "y": 25.76
    },
    {
        "x": 54.62,
        "y": 25.76
    },
    {
        "x": 54.63,
        "y": 25.76
    },
    {
        "x": 54.64,
        "y": 25.76
    },
    {
        "x": 54.65,
        "y": 25.76
    },
    {
        "x": 54.66,
        "y": 25.76
    },
    {
        "x": 54.67,
        "y": 25.76
    },
    {
        "x": 54.68,
        "y": 25.76
    },
    {
        "x": 54.69,
        "y": 25.76
    },
    {
        "x": 54.7,
        "y": 25.76
    },
    {
        "x": 54.71,
        "y": 25.76
    },
    {
        "x": 54.72,
        "y": 25.76
    },
    {
        "x": 54.73,
        "y": 25.76
    },
    {
        "x": 54.74,
        "y": 25.76
    },
    {
        "x": 54.75,
        "y": 25.76
    },
    {
        "x": 54.76,
        "y": 25.76
    },
    {
        "x": 54.77,
        "y": 25.76
    },
    {
        "x": 54.78,
        "y": 25.76
    },
    {
        "x": 54.79,
        "y": 25.76
    },
    {
        "x": 54.8,
        "y": 25.76
    },
    {
        "x": 54.81,
        "y": 25.76
    },
    {
        "x": 54.82,
        "y": 25.76
    },
    {
        "x": 54.83,
        "y": 25.76
    },
    {
        "x": 54.84,
        "y": 25.76
    },
    {
        "x": 54.85,
        "y": 25.76
    },
    {
        "x": 54.86,
        "y": 25.76
    },
    {
        "x": 54.87,
        "y": 25.76
    },
    {
        "x": 54.88,
        "y": 25.76
    },
    {
        "x": 54.89,
        "y": 25.76
    },
    {
        "x": 54.9,
        "y": 25.76
    },
    {
        "x": 54.91,
        "y": 25.76
    },
    {
        "x": 54.92,
        "y": 25.76
    },
    {
        "x": 54.93,
        "y": 25.76
    },
    {
        "x": 54.94,
        "y": 25.76
    },
    {
        "x": 54.95,
        "y": 25.76
    },
    {
        "x": 54.96,
        "y": 25.76
    },
    {
        "x": 54.97,
        "y": 25.76
    },
    {
        "x": 54.98,
        "y": 25.76
    },
    {
        "x": 54.99,
        "y": 25.76
    },
    {
        "x": 55,
        "y": 25.76
    },
    {
        "x": 55.01,
        "y": 25.76
    },
    {
        "x": 55.02,
        "y": 25.76
    },
    {
        "x": 55.03,
        "y": 25.76
    },
    {
        "x": 55.04,
        "y": 25.76
    },
    {
        "x": 55.05,
        "y": 25.76
    },
    {
        "x": 55.06,
        "y": 25.76
    },
    {
        "x": 55.07,
        "y": 25.76
    },
    {
        "x": 55.08,
        "y": 25.76
    },
    {
        "x": 55.09,
        "y": 25.76
    },
    {
        "x": 55.1,
        "y": 25.76
    },
    {
        "x": 55.11,
        "y": 25.76
    },
    {
        "x": 55.12,
        "y": 25.76
    },
    {
        "x": 55.13,
        "y": 25.76
    },
    {
        "x": 55.14,
        "y": 25.76
    },
    {
        "x": 55.15,
        "y": 25.76
    },
    {
        "x": 55.16,
        "y": 25.76
    },
    {
        "x": 55.17,
        "y": 25.76
    },
    {
        "x": 55.18,
        "y": 25.76
    },
    {
        "x": 55.19,
        "y": 25.76
    },
    {
        "x": 55.2,
        "y": 25.76
    },
    {
        "x": 55.21,
        "y": 25.76
    },
    {
        "x": 55.22,
        "y": 25.76
    },
    {
        "x": 55.23,
        "y": 25.76
    },
    {
        "x": 55.24,
        "y": 25.76
    },
    {
        "x": 55.25,
        "y": 25.76
    },
    {
        "x": 55.26,
        "y": 25.76
    },
    {
        "x": 55.27,
        "y": 25.76
    },
    {
        "x": 55.28,
        "y": 25.76
    },
    {
        "x": 55.29,
        "y": 25.76
    },
    {
        "x": 55.3,
        "y": 25.76
    },
    {
        "x": 55.31,
        "y": 25.76
    },
    {
        "x": 55.32,
        "y": 25.76
    },
    {
        "x": 55.33,
        "y": 25.76
    },
    {
        "x": 55.34,
        "y": 25.76
    },
    {
        "x": 55.35,
        "y": 25.76
    },
    {
        "x": 55.36,
        "y": 25.76
    },
    {
        "x": 55.37,
        "y": 25.76
    },
    {
        "x": 55.38,
        "y": 25.76
    },
    {
        "x": 55.39,
        "y": 25.76
    },
    {
        "x": 55.4,
        "y": 25.76
    },
    {
        "x": 55.41,
        "y": 25.76
    },
    {
        "x": 55.42,
        "y": 25.76
    },
    {
        "x": 55.43,
        "y": 25.76
    },
    {
        "x": 55.44,
        "y": 25.76
    },
    {
        "x": 55.45,
        "y": 25.76
    },
    {
        "x": 55.46,
        "y": 25.76
    },
    {
        "x": 55.47,
        "y": 25.76
    },
    {
        "x": 55.48,
        "y": 25.76
    },
    {
        "x": 55.49,
        "y": 25.76
    },
    {
        "x": 55.5,
        "y": 25.76
    },
    {
        "x": 55.51,
        "y": 25.76
    },
    {
        "x": 55.52,
        "y": 25.76
    },
    {
        "x": 55.53,
        "y": 25.76
    },
    {
        "x": 55.54,
        "y": 25.76
    },
    {
        "x": 55.55,
        "y": 25.76
    },
    {
        "x": 55.56,
        "y": 25.76
    },
    {
        "x": 55.57,
        "y": 25.76
    },
    {
        "x": 55.58,
        "y": 25.76
    },
    {
        "x": 55.59,
        "y": 25.76
    },
    {
        "x": 55.6,
        "y": 25.76
    },
    {
        "x": 55.61,
        "y": 25.76
    },
    {
        "x": 55.62,
        "y": 25.76
    },
    {
        "x": 55.63,
        "y": 25.76
    },
    {
        "x": 55.64,
        "y": 25.76
    },
    {
        "x": 55.65,
        "y": 25.76
    },
    {
        "x": 55.66,
        "y": 25.76
    },
    {
        "x": 55.67,
        "y": 25.76
    },
    {
        "x": 55.68,
        "y": 25.76
    },
    {
        "x": 55.69,
        "y": 25.76
    },
    {
        "x": 55.7,
        "y": 25.76
    },
    {
        "x": 55.71,
        "y": 25.76
    },
    {
        "x": 55.72,
        "y": 25.76
    },
    {
        "x": 55.73,
        "y": 25.76
    },
    {
        "x": 55.74,
        "y": 25.76
    },
    {
        "x": 55.75,
        "y": 25.76
    },
    {
        "x": 55.76,
        "y": 25.76
    },
    {
        "x": 55.77,
        "y": 25.76
    },
    {
        "x": 55.78,
        "y": 25.76
    },
    {
        "x": 55.79,
        "y": 25.76
    },
    {
        "x": 55.8,
        "y": 25.76
    },
    {
        "x": 55.81,
        "y": 25.76
    },
    {
        "x": 55.82,
        "y": 25.76
    },
    {
        "x": 55.83,
        "y": 25.76
    },
    {
        "x": 55.84,
        "y": 25.76
    },
    {
        "x": 55.85,
        "y": 25.76
    },
    {
        "x": 55.86,
        "y": 25.76
    },
    {
        "x": 55.87,
        "y": 25.76
    },
    {
        "x": 55.88,
        "y": 25.76
    },
    {
        "x": 55.89,
        "y": 25.76
    },
    {
        "x": 55.9,
        "y": 25.76
    },
    {
        "x": 55.91,
        "y": 25.76
    },
    {
        "x": 55.92,
        "y": 25.76
    },
    {
        "x": 55.93,
        "y": 25.76
    },
    {
        "x": 55.94,
        "y": 25.76
    },
    {
        "x": 55.95,
        "y": 25.76
    },
    {
        "x": 55.96,
        "y": 25.76
    },
    {
        "x": 55.97,
        "y": 25.76
    },
    {
        "x": 55.98,
        "y": 25.76
    },
    {
        "x": 55.99,
        "y": 25.76
    },
    {
        "x": 56,
        "y": 25.75
    },
    {
        "x": 56.01,
        "y": 25.76
    },
    {
        "x": 56.02,
        "y": 25.76
    },
    {
        "x": 56.03,
        "y": 25.75
    },
    {
        "x": 56.04,
        "y": 25.76
    },
    {
        "x": 56.05,
        "y": 25.76
    },
    {
        "x": 56.06,
        "y": 25.76
    },
    {
        "x": 56.07,
        "y": 25.76
    },
    {
        "x": 56.08,
        "y": 25.76
    },
    {
        "x": 56.09,
        "y": 25.76
    },
    {
        "x": 56.1,
        "y": 25.76
    },
    {
        "x": 56.11,
        "y": 25.76
    },
    {
        "x": 56.12,
        "y": 25.76
    },
    {
        "x": 56.13,
        "y": 25.76
    },
    {
        "x": 56.14,
        "y": 25.76
    },
    {
        "x": 56.15,
        "y": 25.76
    },
    {
        "x": 56.16,
        "y": 25.75
    },
    {
        "x": 56.17,
        "y": 25.76
    },
    {
        "x": 56.18,
        "y": 25.76
    },
    {
        "x": 56.19,
        "y": 25.76
    },
    {
        "x": 56.2,
        "y": 25.76
    },
    {
        "x": 56.21,
        "y": 25.76
    },
    {
        "x": 56.22,
        "y": 25.76
    },
    {
        "x": 56.23,
        "y": 25.75
    },
    {
        "x": 56.24,
        "y": 25.76
    },
    {
        "x": 56.25,
        "y": 25.76
    },
    {
        "x": 56.26,
        "y": 25.75
    },
    {
        "x": 56.27,
        "y": 25.75
    },
    {
        "x": 56.28,
        "y": 25.76
    },
    {
        "x": 56.29,
        "y": 25.76
    },
    {
        "x": 56.3,
        "y": 25.76
    },
    {
        "x": 56.31,
        "y": 25.76
    },
    {
        "x": 56.32,
        "y": 25.76
    },
    {
        "x": 56.33,
        "y": 25.76
    },
    {
        "x": 56.34,
        "y": 25.76
    },
    {
        "x": 56.35,
        "y": 25.75
    },
    {
        "x": 56.36,
        "y": 25.76
    },
    {
        "x": 56.37,
        "y": 25.76
    },
    {
        "x": 56.38,
        "y": 25.76
    },
    {
        "x": 56.39,
        "y": 25.76
    },
    {
        "x": 56.4,
        "y": 25.76
    },
    {
        "x": 56.41,
        "y": 25.76
    },
    {
        "x": 56.42,
        "y": 25.76
    },
    {
        "x": 56.43,
        "y": 25.75
    },
    {
        "x": 56.44,
        "y": 25.75
    },
    {
        "x": 56.45,
        "y": 25.76
    },
    {
        "x": 56.46,
        "y": 25.75
    },
    {
        "x": 56.47,
        "y": 25.76
    },
    {
        "x": 56.48,
        "y": 25.75
    },
    {
        "x": 56.49,
        "y": 25.75
    },
    {
        "x": 56.5,
        "y": 25.75
    },
    {
        "x": 56.51,
        "y": 25.76
    },
    {
        "x": 56.52,
        "y": 25.76
    },
    {
        "x": 56.53,
        "y": 25.76
    },
    {
        "x": 56.54,
        "y": 25.75
    },
    {
        "x": 56.55,
        "y": 25.75
    },
    {
        "x": 56.56,
        "y": 25.75
    },
    {
        "x": 56.57,
        "y": 25.75
    },
    {
        "x": 56.58,
        "y": 25.75
    },
    {
        "x": 56.59,
        "y": 25.76
    },
    {
        "x": 56.6,
        "y": 25.75
    },
    {
        "x": 56.61,
        "y": 25.76
    },
    {
        "x": 56.62,
        "y": 25.76
    },
    {
        "x": 56.63,
        "y": 25.75
    },
    {
        "x": 56.64,
        "y": 25.76
    },
    {
        "x": 56.65,
        "y": 25.76
    },
    {
        "x": 56.66,
        "y": 25.75
    },
    {
        "x": 56.67,
        "y": 25.75
    },
    {
        "x": 56.68,
        "y": 25.76
    },
    {
        "x": 56.69,
        "y": 25.76
    },
    {
        "x": 56.7,
        "y": 25.76
    },
    {
        "x": 56.71,
        "y": 25.75
    },
    {
        "x": 56.72,
        "y": 25.75
    },
    {
        "x": 56.73,
        "y": 25.75
    },
    {
        "x": 56.74,
        "y": 25.75
    },
    {
        "x": 56.75,
        "y": 25.75
    },
    {
        "x": 56.76,
        "y": 25.76
    },
    {
        "x": 56.77,
        "y": 25.75
    },
    {
        "x": 56.78,
        "y": 25.75
    },
    {
        "x": 56.79,
        "y": 25.76
    },
    {
        "x": 56.8,
        "y": 25.75
    },
    {
        "x": 56.81,
        "y": 25.76
    },
    {
        "x": 56.82,
        "y": 25.76
    },
    {
        "x": 56.83,
        "y": 25.76
    },
    {
        "x": 56.84,
        "y": 25.75
    },
    {
        "x": 56.85,
        "y": 25.76
    },
    {
        "x": 56.86,
        "y": 25.76
    },
    {
        "x": 56.87,
        "y": 25.76
    },
    {
        "x": 56.88,
        "y": 25.76
    },
    {
        "x": 56.89,
        "y": 25.76
    },
    {
        "x": 56.9,
        "y": 25.76
    },
    {
        "x": 56.91,
        "y": 25.76
    },
    {
        "x": 56.92,
        "y": 25.75
    },
    {
        "x": 56.93,
        "y": 25.76
    },
    {
        "x": 56.94,
        "y": 25.76
    },
    {
        "x": 56.95,
        "y": 25.76
    },
    {
        "x": 56.96,
        "y": 25.75
    },
    {
        "x": 56.97,
        "y": 25.76
    },
    {
        "x": 56.98,
        "y": 25.76
    },
    {
        "x": 56.99,
        "y": 25.76
    },
    {
        "x": 57,
        "y": 25.76
    },
    {
        "x": 57.01,
        "y": 25.76
    },
    {
        "x": 57.02,
        "y": 25.76
    },
    {
        "x": 57.03,
        "y": 25.76
    },
    {
        "x": 57.04,
        "y": 25.76
    },
    {
        "x": 57.05,
        "y": 25.76
    },
    {
        "x": 57.06,
        "y": 25.75
    },
    {
        "x": 57.07,
        "y": 25.76
    },
    {
        "x": 57.08,
        "y": 25.75
    },
    {
        "x": 57.09,
        "y": 25.75
    },
    {
        "x": 57.1,
        "y": 25.75
    },
    {
        "x": 57.11,
        "y": 25.75
    },
    {
        "x": 57.12,
        "y": 25.75
    },
    {
        "x": 57.13,
        "y": 25.75
    },
    {
        "x": 57.14,
        "y": 25.76
    },
    {
        "x": 57.15,
        "y": 25.75
    },
    {
        "x": 57.16,
        "y": 25.75
    },
    {
        "x": 57.17,
        "y": 25.75
    },
    {
        "x": 57.18,
        "y": 25.76
    },
    {
        "x": 57.19,
        "y": 25.75
    },
    {
        "x": 57.2,
        "y": 25.76
    },
    {
        "x": 57.21,
        "y": 25.75
    },
    {
        "x": 57.22,
        "y": 25.75
    },
    {
        "x": 57.23,
        "y": 25.75
    },
    {
        "x": 57.24,
        "y": 25.75
    },
    {
        "x": 57.25,
        "y": 25.75
    },
    {
        "x": 57.26,
        "y": 25.75
    },
    {
        "x": 57.27,
        "y": 25.75
    },
    {
        "x": 57.28,
        "y": 25.75
    },
    {
        "x": 57.29,
        "y": 25.75
    },
    {
        "x": 57.3,
        "y": 25.75
    },
    {
        "x": 57.31,
        "y": 25.75
    },
    {
        "x": 57.32,
        "y": 25.75
    },
    {
        "x": 57.33,
        "y": 25.75
    },
    {
        "x": 57.34,
        "y": 25.75
    },
    {
        "x": 57.35,
        "y": 25.75
    },
    {
        "x": 57.36,
        "y": 25.75
    },
    {
        "x": 57.37,
        "y": 25.75
    },
    {
        "x": 57.38,
        "y": 25.75
    },
    {
        "x": 57.39,
        "y": 25.75
    },
    {
        "x": 57.4,
        "y": 25.75
    },
    {
        "x": 57.41,
        "y": 25.75
    },
    {
        "x": 57.42,
        "y": 25.75
    },
    {
        "x": 57.43,
        "y": 25.75
    },
    {
        "x": 57.44,
        "y": 25.75
    },
    {
        "x": 57.45,
        "y": 25.75
    },
    {
        "x": 57.46,
        "y": 25.75
    },
    {
        "x": 57.47,
        "y": 25.75
    },
    {
        "x": 57.48,
        "y": 25.75
    },
    {
        "x": 57.49,
        "y": 25.75
    },
    {
        "x": 57.5,
        "y": 25.75
    },
    {
        "x": 57.51,
        "y": 25.75
    },
    {
        "x": 57.52,
        "y": 25.75
    },
    {
        "x": 57.53,
        "y": 25.75
    },
    {
        "x": 57.54,
        "y": 25.75
    },
    {
        "x": 57.55,
        "y": 25.75
    },
    {
        "x": 57.56,
        "y": 25.75
    },
    {
        "x": 57.57,
        "y": 25.75
    },
    {
        "x": 57.58,
        "y": 25.75
    },
    {
        "x": 57.59,
        "y": 25.75
    },
    {
        "x": 57.6,
        "y": 25.75
    },
    {
        "x": 57.61,
        "y": 25.76
    },
    {
        "x": 57.62,
        "y": 25.75
    },
    {
        "x": 57.63,
        "y": 25.75
    },
    {
        "x": 57.64,
        "y": 25.75
    },
    {
        "x": 57.65,
        "y": 25.75
    },
    {
        "x": 57.66,
        "y": 25.75
    },
    {
        "x": 57.67,
        "y": 25.75
    },
    {
        "x": 57.68,
        "y": 25.75
    },
    {
        "x": 57.69,
        "y": 25.75
    },
    {
        "x": 57.7,
        "y": 25.75
    },
    {
        "x": 57.71,
        "y": 25.75
    },
    {
        "x": 57.72,
        "y": 25.75
    },
    {
        "x": 57.73,
        "y": 25.75
    },
    {
        "x": 57.74,
        "y": 25.75
    },
    {
        "x": 57.75,
        "y": 25.75
    },
    {
        "x": 57.76,
        "y": 25.75
    },
    {
        "x": 57.77,
        "y": 25.75
    },
    {
        "x": 57.78,
        "y": 25.75
    },
    {
        "x": 57.79,
        "y": 25.75
    },
    {
        "x": 57.8,
        "y": 25.75
    },
    {
        "x": 57.81,
        "y": 25.75
    },
    {
        "x": 57.82,
        "y": 25.75
    },
    {
        "x": 57.83,
        "y": 25.75
    },
    {
        "x": 57.84,
        "y": 25.75
    },
    {
        "x": 57.85,
        "y": 25.75
    },
    {
        "x": 57.86,
        "y": 25.75
    },
    {
        "x": 57.87,
        "y": 25.75
    },
    {
        "x": 57.88,
        "y": 25.75
    },
    {
        "x": 57.89,
        "y": 25.75
    },
    {
        "x": 57.9,
        "y": 25.75
    },
    {
        "x": 57.91,
        "y": 25.75
    },
    {
        "x": 57.92,
        "y": 25.75
    },
    {
        "x": 57.93,
        "y": 25.75
    },
    {
        "x": 57.94,
        "y": 25.75
    },
    {
        "x": 57.95,
        "y": 25.75
    },
    {
        "x": 57.96,
        "y": 25.75
    },
    {
        "x": 57.97,
        "y": 25.75
    },
    {
        "x": 57.98,
        "y": 25.75
    },
    {
        "x": 57.99,
        "y": 25.75
    },
    {
        "x": 58,
        "y": 25.75
    },
    {
        "x": 58.01,
        "y": 25.75
    },
    {
        "x": 58.02,
        "y": 25.75
    },
    {
        "x": 58.03,
        "y": 25.75
    },
    {
        "x": 58.04,
        "y": 25.75
    },
    {
        "x": 58.05,
        "y": 25.75
    },
    {
        "x": 58.06,
        "y": 25.75
    },
    {
        "x": 58.07,
        "y": 25.75
    },
    {
        "x": 58.08,
        "y": 25.76
    },
    {
        "x": 58.09,
        "y": 25.75
    },
    {
        "x": 58.1,
        "y": 25.75
    },
    {
        "x": 58.11,
        "y": 25.75
    },
    {
        "x": 58.12,
        "y": 25.75
    },
    {
        "x": 58.13,
        "y": 25.75
    },
    {
        "x": 58.14,
        "y": 25.75
    },
    {
        "x": 58.15,
        "y": 25.75
    },
    {
        "x": 58.16,
        "y": 25.75
    },
    {
        "x": 58.17,
        "y": 25.75
    },
    {
        "x": 58.18,
        "y": 25.75
    },
    {
        "x": 58.19,
        "y": 25.75
    },
    {
        "x": 58.2,
        "y": 25.75
    },
    {
        "x": 58.21,
        "y": 25.75
    },
    {
        "x": 58.22,
        "y": 25.75
    },
    {
        "x": 58.23,
        "y": 25.75
    },
    {
        "x": 58.24,
        "y": 25.75
    },
    {
        "x": 58.25,
        "y": 25.75
    },
    {
        "x": 58.26,
        "y": 25.75
    },
    {
        "x": 58.27,
        "y": 25.75
    },
    {
        "x": 58.28,
        "y": 25.75
    },
    {
        "x": 58.29,
        "y": 25.75
    },
    {
        "x": 58.3,
        "y": 25.75
    },
    {
        "x": 58.31,
        "y": 25.75
    },
    {
        "x": 58.32,
        "y": 25.75
    },
    {
        "x": 58.33,
        "y": 25.75
    },
    {
        "x": 58.34,
        "y": 25.75
    },
    {
        "x": 58.35,
        "y": 25.75
    },
    {
        "x": 58.36,
        "y": 25.75
    },
    {
        "x": 58.37,
        "y": 25.75
    },
    {
        "x": 58.38,
        "y": 25.75
    },
    {
        "x": 58.39,
        "y": 25.74
    },
    {
        "x": 58.4,
        "y": 25.75
    },
    {
        "x": 58.41,
        "y": 25.75
    },
    {
        "x": 58.42,
        "y": 25.75
    },
    {
        "x": 58.43,
        "y": 25.75
    },
    {
        "x": 58.44,
        "y": 25.75
    },
    {
        "x": 58.45,
        "y": 25.75
    },
    {
        "x": 58.46,
        "y": 25.74
    },
    {
        "x": 58.47,
        "y": 25.75
    },
    {
        "x": 58.48,
        "y": 25.75
    },
    {
        "x": 58.49,
        "y": 25.75
    },
    {
        "x": 58.5,
        "y": 25.75
    },
    {
        "x": 58.51,
        "y": 25.75
    },
    {
        "x": 58.52,
        "y": 25.75
    },
    {
        "x": 58.53,
        "y": 25.75
    },
    {
        "x": 58.54,
        "y": 25.75
    },
    {
        "x": 58.55,
        "y": 25.75
    },
    {
        "x": 58.56,
        "y": 25.75
    },
    {
        "x": 58.57,
        "y": 25.75
    },
    {
        "x": 58.58,
        "y": 25.75
    },
    {
        "x": 58.59,
        "y": 25.75
    },
    {
        "x": 58.6,
        "y": 25.75
    },
    {
        "x": 58.61,
        "y": 25.75
    },
    {
        "x": 58.62,
        "y": 25.75
    },
    {
        "x": 58.63,
        "y": 25.75
    },
    {
        "x": 58.64,
        "y": 25.75
    },
    {
        "x": 58.65,
        "y": 25.74
    },
    {
        "x": 58.66,
        "y": 25.75
    },
    {
        "x": 58.67,
        "y": 25.75
    },
    {
        "x": 58.68,
        "y": 25.75
    },
    {
        "x": 58.69,
        "y": 25.75
    },
    {
        "x": 58.7,
        "y": 25.75
    },
    {
        "x": 58.71,
        "y": 25.75
    },
    {
        "x": 58.72,
        "y": 25.75
    },
    {
        "x": 58.73,
        "y": 25.75
    },
    {
        "x": 58.74,
        "y": 25.74
    },
    {
        "x": 58.75,
        "y": 25.75
    },
    {
        "x": 58.76,
        "y": 25.75
    },
    {
        "x": 58.77,
        "y": 25.74
    },
    {
        "x": 58.78,
        "y": 25.74
    },
    {
        "x": 58.79,
        "y": 25.75
    },
    {
        "x": 58.8,
        "y": 25.75
    },
    {
        "x": 58.81,
        "y": 25.75
    },
    {
        "x": 58.82,
        "y": 25.74
    },
    {
        "x": 58.83,
        "y": 25.75
    },
    {
        "x": 58.84,
        "y": 25.75
    },
    {
        "x": 58.85,
        "y": 25.75
    },
    {
        "x": 58.86,
        "y": 25.75
    },
    {
        "x": 58.87,
        "y": 25.75
    },
    {
        "x": 58.88,
        "y": 25.75
    },
    {
        "x": 58.89,
        "y": 25.75
    },
    {
        "x": 58.9,
        "y": 25.75
    },
    {
        "x": 58.91,
        "y": 25.75
    },
    {
        "x": 58.92,
        "y": 25.75
    },
    {
        "x": 58.93,
        "y": 25.75
    },
    {
        "x": 58.94,
        "y": 25.75
    },
    {
        "x": 58.95,
        "y": 25.75
    },
    {
        "x": 58.96,
        "y": 25.75
    },
    {
        "x": 58.97,
        "y": 25.75
    },
    {
        "x": 58.98,
        "y": 25.74
    },
    {
        "x": 58.99,
        "y": 25.75
    },
    {
        "x": 59,
        "y": 25.75
    },
    {
        "x": 59.01,
        "y": 25.75
    },
    {
        "x": 59.02,
        "y": 25.75
    },
    {
        "x": 59.03,
        "y": 25.75
    },
    {
        "x": 59.04,
        "y": 25.75
    },
    {
        "x": 59.05,
        "y": 25.75
    },
    {
        "x": 59.06,
        "y": 25.75
    },
    {
        "x": 59.07,
        "y": 25.75
    },
    {
        "x": 59.08,
        "y": 25.75
    },
    {
        "x": 59.09,
        "y": 25.75
    },
    {
        "x": 59.1,
        "y": 25.75
    },
    {
        "x": 59.11,
        "y": 25.75
    },
    {
        "x": 59.12,
        "y": 25.75
    },
    {
        "x": 59.13,
        "y": 25.75
    },
    {
        "x": 59.14,
        "y": 25.75
    },
    {
        "x": 59.15,
        "y": 25.75
    },
    {
        "x": 59.16,
        "y": 25.75
    },
    {
        "x": 59.17,
        "y": 25.75
    },
    {
        "x": 59.18,
        "y": 25.75
    },
    {
        "x": 59.19,
        "y": 25.75
    },
    {
        "x": 59.2,
        "y": 25.75
    },
    {
        "x": 59.21,
        "y": 25.74
    },
    {
        "x": 59.22,
        "y": 25.75
    },
    {
        "x": 59.23,
        "y": 25.75
    },
    {
        "x": 59.24,
        "y": 25.75
    },
    {
        "x": 59.25,
        "y": 25.75
    },
    {
        "x": 59.26,
        "y": 25.75
    },
    {
        "x": 59.27,
        "y": 25.75
    },
    {
        "x": 59.28,
        "y": 25.75
    },
    {
        "x": 59.29,
        "y": 25.75
    },
    {
        "x": 59.3,
        "y": 25.75
    },
    {
        "x": 59.31,
        "y": 25.75
    },
    {
        "x": 59.32,
        "y": 25.75
    },
    {
        "x": 59.33,
        "y": 25.75
    },
    {
        "x": 59.34,
        "y": 25.75
    },
    {
        "x": 59.35,
        "y": 25.75
    },
    {
        "x": 59.36,
        "y": 25.75
    },
    {
        "x": 59.37,
        "y": 25.75
    },
    {
        "x": 59.38,
        "y": 25.75
    },
    {
        "x": 59.39,
        "y": 25.75
    },
    {
        "x": 59.4,
        "y": 25.74
    },
    {
        "x": 59.41,
        "y": 25.75
    },
    {
        "x": 59.42,
        "y": 25.75
    },
    {
        "x": 59.43,
        "y": 25.75
    },
    {
        "x": 59.44,
        "y": 25.74
    },
    {
        "x": 59.45,
        "y": 25.75
    },
    {
        "x": 59.46,
        "y": 25.74
    },
    {
        "x": 59.47,
        "y": 25.74
    },
    {
        "x": 59.48,
        "y": 25.75
    },
    {
        "x": 59.49,
        "y": 25.75
    },
    {
        "x": 59.5,
        "y": 25.75
    },
    {
        "x": 59.51,
        "y": 25.75
    },
    {
        "x": 59.52,
        "y": 25.75
    },
    {
        "x": 59.53,
        "y": 25.74
    },
    {
        "x": 59.54,
        "y": 25.75
    },
    {
        "x": 59.55,
        "y": 25.75
    },
    {
        "x": 59.56,
        "y": 25.75
    },
    {
        "x": 59.57,
        "y": 25.74
    },
    {
        "x": 59.58,
        "y": 25.75
    },
    {
        "x": 59.59,
        "y": 25.75
    },
    {
        "x": 59.6,
        "y": 25.75
    },
    {
        "x": 59.61,
        "y": 25.74
    },
    {
        "x": 59.62,
        "y": 25.74
    },
    {
        "x": 59.63,
        "y": 25.74
    },
    {
        "x": 59.64,
        "y": 25.75
    },
    {
        "x": 59.65,
        "y": 25.74
    },
    {
        "x": 59.66,
        "y": 25.74
    },
    {
        "x": 59.67,
        "y": 25.74
    },
    {
        "x": 59.68,
        "y": 25.74
    },
    {
        "x": 59.69,
        "y": 25.74
    },
    {
        "x": 59.7,
        "y": 25.74
    },
    {
        "x": 59.71,
        "y": 25.75
    },
    {
        "x": 59.72,
        "y": 25.74
    },
    {
        "x": 59.73,
        "y": 25.74
    },
    {
        "x": 59.74,
        "y": 25.74
    },
    {
        "x": 59.75,
        "y": 25.74
    },
    {
        "x": 59.76,
        "y": 25.74
    },
    {
        "x": 59.77,
        "y": 25.75
    },
    {
        "x": 59.78,
        "y": 25.74
    },
    {
        "x": 59.79,
        "y": 25.75
    },
    {
        "x": 59.8,
        "y": 25.74
    },
    {
        "x": 59.81,
        "y": 25.74
    },
    {
        "x": 59.82,
        "y": 25.75
    },
    {
        "x": 59.83,
        "y": 25.75
    },
    {
        "x": 59.84,
        "y": 25.74
    },
    {
        "x": 59.85,
        "y": 25.74
    },
    {
        "x": 59.86,
        "y": 25.74
    },
    {
        "x": 59.87,
        "y": 25.74
    },
    {
        "x": 59.88,
        "y": 25.74
    },
    {
        "x": 59.89,
        "y": 25.75
    },
    {
        "x": 59.9,
        "y": 25.74
    },
    {
        "x": 59.91,
        "y": 25.74
    },
    {
        "x": 59.92,
        "y": 25.74
    },
    {
        "x": 59.93,
        "y": 25.74
    },
    {
        "x": 59.94,
        "y": 25.74
    },
    {
        "x": 59.95,
        "y": 25.74
    },
    {
        "x": 59.96,
        "y": 25.74
    },
    {
        "x": 59.97,
        "y": 25.74
    },
    {
        "x": 59.98,
        "y": 25.74
    },
    {
        "x": 59.99,
        "y": 25.74
    },
    {
        "x": 60,
        "y": 25.74
    },
    {
        "x": 60.01,
        "y": 25.74
    },
    {
        "x": 60.02,
        "y": 25.74
    },
    {
        "x": 60.03,
        "y": 25.75
    },
    {
        "x": 60.04,
        "y": 25.74
    },
    {
        "x": 60.05,
        "y": 25.74
    },
    {
        "x": 60.06,
        "y": 25.74
    },
    {
        "x": 60.07,
        "y": 25.74
    },
    {
        "x": 60.08,
        "y": 25.74
    },
    {
        "x": 60.09,
        "y": 25.74
    },
    {
        "x": 60.1,
        "y": 25.75
    },
    {
        "x": 60.11,
        "y": 25.74
    },
    {
        "x": 60.12,
        "y": 25.74
    },
    {
        "x": 60.13,
        "y": 25.74
    },
    {
        "x": 60.14,
        "y": 25.74
    },
    {
        "x": 60.15,
        "y": 25.74
    },
    {
        "x": 60.16,
        "y": 25.74
    },
    {
        "x": 60.17,
        "y": 25.74
    },
    {
        "x": 60.18,
        "y": 25.75
    },
    {
        "x": 60.19,
        "y": 25.74
    },
    {
        "x": 60.2,
        "y": 25.74
    },
    {
        "x": 60.21,
        "y": 25.75
    },
    {
        "x": 60.22,
        "y": 25.74
    },
    {
        "x": 60.23,
        "y": 25.74
    },
    {
        "x": 60.24,
        "y": 25.74
    },
    {
        "x": 60.25,
        "y": 25.74
    },
    {
        "x": 60.26,
        "y": 25.74
    },
    {
        "x": 60.27,
        "y": 25.74
    },
    {
        "x": 60.28,
        "y": 25.74
    },
    {
        "x": 60.29,
        "y": 25.74
    },
    {
        "x": 60.3,
        "y": 25.75
    },
    {
        "x": 60.31,
        "y": 25.74
    },
    {
        "x": 60.32,
        "y": 25.74
    },
    {
        "x": 60.33,
        "y": 25.74
    },
    {
        "x": 60.34,
        "y": 25.75
    },
    {
        "x": 60.35,
        "y": 25.74
    },
    {
        "x": 60.36,
        "y": 25.74
    },
    {
        "x": 60.37,
        "y": 25.74
    },
    {
        "x": 60.38,
        "y": 25.74
    },
    {
        "x": 60.39,
        "y": 25.74
    },
    {
        "x": 60.4,
        "y": 25.74
    },
    {
        "x": 60.41,
        "y": 25.74
    },
    {
        "x": 60.42,
        "y": 25.74
    },
    {
        "x": 60.43,
        "y": 25.74
    },
    {
        "x": 60.44,
        "y": 25.74
    },
    {
        "x": 60.45,
        "y": 25.74
    },
    {
        "x": 60.46,
        "y": 25.75
    },
    {
        "x": 60.47,
        "y": 25.74
    },
    {
        "x": 60.48,
        "y": 25.74
    },
    {
        "x": 60.49,
        "y": 25.74
    },
    {
        "x": 60.5,
        "y": 25.74
    },
    {
        "x": 60.51,
        "y": 25.74
    },
    {
        "x": 60.52,
        "y": 25.74
    },
    {
        "x": 60.53,
        "y": 25.74
    },
    {
        "x": 60.54,
        "y": 25.74
    },
    {
        "x": 60.55,
        "y": 25.74
    },
    {
        "x": 60.56,
        "y": 25.74
    },
    {
        "x": 60.57,
        "y": 25.74
    },
    {
        "x": 60.58,
        "y": 25.74
    },
    {
        "x": 60.59,
        "y": 25.74
    },
    {
        "x": 60.6,
        "y": 25.74
    },
    {
        "x": 60.61,
        "y": 25.74
    },
    {
        "x": 60.62,
        "y": 25.74
    },
    {
        "x": 60.63,
        "y": 25.74
    },
    {
        "x": 60.64,
        "y": 25.74
    },
    {
        "x": 60.65,
        "y": 25.74
    },
    {
        "x": 60.66,
        "y": 25.74
    },
    {
        "x": 60.67,
        "y": 25.74
    },
    {
        "x": 60.68,
        "y": 25.74
    },
    {
        "x": 60.69,
        "y": 25.74
    },
    {
        "x": 60.7,
        "y": 25.74
    },
    {
        "x": 60.71,
        "y": 25.74
    },
    {
        "x": 60.72,
        "y": 25.74
    },
    {
        "x": 60.73,
        "y": 25.74
    },
    {
        "x": 60.74,
        "y": 25.74
    },
    {
        "x": 60.75,
        "y": 25.74
    },
    {
        "x": 60.76,
        "y": 25.74
    },
    {
        "x": 60.77,
        "y": 25.74
    },
    {
        "x": 60.78,
        "y": 25.74
    },
    {
        "x": 60.79,
        "y": 25.74
    },
    {
        "x": 60.8,
        "y": 25.74
    },
    {
        "x": 60.81,
        "y": 25.74
    },
    {
        "x": 60.82,
        "y": 25.74
    },
    {
        "x": 60.83,
        "y": 25.74
    },
    {
        "x": 60.84,
        "y": 25.73
    },
    {
        "x": 60.85,
        "y": 25.74
    },
    {
        "x": 60.86,
        "y": 25.73
    },
    {
        "x": 60.87,
        "y": 25.74
    },
    {
        "x": 60.88,
        "y": 25.74
    },
    {
        "x": 60.89,
        "y": 25.74
    },
    {
        "x": 60.9,
        "y": 25.74
    },
    {
        "x": 60.91,
        "y": 25.74
    },
    {
        "x": 60.92,
        "y": 25.73
    },
    {
        "x": 60.93,
        "y": 25.74
    },
    {
        "x": 60.94,
        "y": 25.74
    },
    {
        "x": 60.95,
        "y": 25.74
    },
    {
        "x": 60.96,
        "y": 25.74
    },
    {
        "x": 60.97,
        "y": 25.74
    },
    {
        "x": 60.98,
        "y": 25.74
    },
    {
        "x": 60.99,
        "y": 25.74
    },
    {
        "x": 61,
        "y": 25.74
    },
    {
        "x": 61.01,
        "y": 25.74
    },
    {
        "x": 61.02,
        "y": 25.74
    },
    {
        "x": 61.03,
        "y": 25.74
    },
    {
        "x": 61.04,
        "y": 25.74
    },
    {
        "x": 61.05,
        "y": 25.74
    },
    {
        "x": 61.06,
        "y": 25.74
    },
    {
        "x": 61.07,
        "y": 25.74
    },
    {
        "x": 61.08,
        "y": 25.74
    },
    {
        "x": 61.09,
        "y": 25.74
    },
    {
        "x": 61.1,
        "y": 25.74
    },
    {
        "x": 61.11,
        "y": 25.74
    },
    {
        "x": 61.12,
        "y": 25.74
    },
    {
        "x": 61.13,
        "y": 25.74
    },
    {
        "x": 61.14,
        "y": 25.74
    },
    {
        "x": 61.15,
        "y": 25.74
    },
    {
        "x": 61.16,
        "y": 25.74
    },
    {
        "x": 61.17,
        "y": 25.74
    },
    {
        "x": 61.18,
        "y": 25.74
    },
    {
        "x": 61.19,
        "y": 25.74
    },
    {
        "x": 61.2,
        "y": 25.74
    },
    {
        "x": 61.21,
        "y": 25.74
    },
    {
        "x": 61.22,
        "y": 25.74
    },
    {
        "x": 61.23,
        "y": 25.74
    },
    {
        "x": 61.24,
        "y": 25.74
    },
    {
        "x": 61.25,
        "y": 25.74
    },
    {
        "x": 61.26,
        "y": 25.74
    },
    {
        "x": 61.27,
        "y": 25.74
    },
    {
        "x": 61.28,
        "y": 25.74
    },
    {
        "x": 61.29,
        "y": 25.74
    },
    {
        "x": 61.3,
        "y": 25.74
    },
    {
        "x": 61.31,
        "y": 25.74
    },
    {
        "x": 61.32,
        "y": 25.74
    },
    {
        "x": 61.33,
        "y": 25.74
    },
    {
        "x": 61.34,
        "y": 25.74
    },
    {
        "x": 61.35,
        "y": 25.74
    },
    {
        "x": 61.36,
        "y": 25.74
    },
    {
        "x": 61.37,
        "y": 25.74
    },
    {
        "x": 61.38,
        "y": 25.74
    },
    {
        "x": 61.39,
        "y": 25.74
    },
    {
        "x": 61.4,
        "y": 25.74
    },
    {
        "x": 61.41,
        "y": 25.74
    },
    {
        "x": 61.42,
        "y": 25.74
    },
    {
        "x": 61.43,
        "y": 25.74
    },
    {
        "x": 61.44,
        "y": 25.74
    },
    {
        "x": 61.45,
        "y": 25.74
    },
    {
        "x": 61.46,
        "y": 25.74
    },
    {
        "x": 61.47,
        "y": 25.74
    },
    {
        "x": 61.48,
        "y": 25.74
    },
    {
        "x": 61.49,
        "y": 25.74
    },
    {
        "x": 61.5,
        "y": 25.74
    },
    {
        "x": 61.51,
        "y": 25.74
    },
    {
        "x": 61.52,
        "y": 25.74
    },
    {
        "x": 61.53,
        "y": 25.74
    },
    {
        "x": 61.54,
        "y": 25.74
    },
    {
        "x": 61.55,
        "y": 25.74
    },
    {
        "x": 61.56,
        "y": 25.73
    },
    {
        "x": 61.57,
        "y": 25.74
    },
    {
        "x": 61.58,
        "y": 25.74
    },
    {
        "x": 61.59,
        "y": 25.74
    },
    {
        "x": 61.6,
        "y": 25.73
    },
    {
        "x": 61.61,
        "y": 25.74
    },
    {
        "x": 61.62,
        "y": 25.74
    },
    {
        "x": 61.63,
        "y": 25.74
    },
    {
        "x": 61.64,
        "y": 25.74
    },
    {
        "x": 61.65,
        "y": 25.74
    },
    {
        "x": 61.66,
        "y": 25.74
    },
    {
        "x": 61.67,
        "y": 25.74
    },
    {
        "x": 61.68,
        "y": 25.73
    },
    {
        "x": 61.69,
        "y": 25.73
    },
    {
        "x": 61.7,
        "y": 25.74
    },
    {
        "x": 61.71,
        "y": 25.74
    },
    {
        "x": 61.72,
        "y": 25.74
    },
    {
        "x": 61.73,
        "y": 25.74
    },
    {
        "x": 61.74,
        "y": 25.74
    },
    {
        "x": 61.75,
        "y": 25.74
    },
    {
        "x": 61.76,
        "y": 25.74
    },
    {
        "x": 61.77,
        "y": 25.74
    },
    {
        "x": 61.78,
        "y": 25.74
    },
    {
        "x": 61.79,
        "y": 25.74
    },
    {
        "x": 61.8,
        "y": 25.74
    },
    {
        "x": 61.81,
        "y": 25.74
    },
    {
        "x": 61.82,
        "y": 25.74
    },
    {
        "x": 61.83,
        "y": 25.74
    },
    {
        "x": 61.84,
        "y": 25.74
    },
    {
        "x": 61.85,
        "y": 25.74
    },
    {
        "x": 61.86,
        "y": 25.74
    },
    {
        "x": 61.87,
        "y": 25.74
    },
    {
        "x": 61.88,
        "y": 25.74
    },
    {
        "x": 61.89,
        "y": 25.74
    },
    {
        "x": 61.9,
        "y": 25.74
    },
    {
        "x": 61.91,
        "y": 25.74
    },
    {
        "x": 61.92,
        "y": 25.74
    },
    {
        "x": 61.93,
        "y": 25.74
    },
    {
        "x": 61.94,
        "y": 25.74
    },
    {
        "x": 61.95,
        "y": 25.74
    },
    {
        "x": 61.96,
        "y": 25.74
    },
    {
        "x": 61.97,
        "y": 25.74
    },
    {
        "x": 61.98,
        "y": 25.73
    },
    {
        "x": 61.99,
        "y": 25.74
    },
    {
        "x": 62,
        "y": 25.74
    },
    {
        "x": 62.01,
        "y": 25.74
    },
    {
        "x": 62.02,
        "y": 25.73
    },
    {
        "x": 62.03,
        "y": 25.74
    },
    {
        "x": 62.04,
        "y": 25.74
    },
    {
        "x": 62.05,
        "y": 25.74
    },
    {
        "x": 62.06,
        "y": 25.74
    },
    {
        "x": 62.07,
        "y": 25.74
    },
    {
        "x": 62.08,
        "y": 25.74
    },
    {
        "x": 62.09,
        "y": 25.73
    },
    {
        "x": 62.1,
        "y": 25.73
    },
    {
        "x": 62.11,
        "y": 25.74
    },
    {
        "x": 62.12,
        "y": 25.73
    },
    {
        "x": 62.13,
        "y": 25.74
    },
    {
        "x": 62.14,
        "y": 25.74
    },
    {
        "x": 62.15,
        "y": 25.73
    },
    {
        "x": 62.16,
        "y": 25.73
    },
    {
        "x": 62.17,
        "y": 25.74
    },
    {
        "x": 62.18,
        "y": 25.73
    },
    {
        "x": 62.19,
        "y": 25.73
    },
    {
        "x": 62.2,
        "y": 25.73
    },
    {
        "x": 62.21,
        "y": 25.73
    },
    {
        "x": 62.22,
        "y": 25.73
    },
    {
        "x": 62.23,
        "y": 25.73
    },
    {
        "x": 62.24,
        "y": 25.73
    },
    {
        "x": 62.25,
        "y": 25.73
    },
    {
        "x": 62.26,
        "y": 25.73
    },
    {
        "x": 62.27,
        "y": 25.73
    },
    {
        "x": 62.28,
        "y": 25.73
    },
    {
        "x": 62.29,
        "y": 25.73
    },
    {
        "x": 62.3,
        "y": 25.73
    },
    {
        "x": 62.31,
        "y": 25.73
    },
    {
        "x": 62.32,
        "y": 25.73
    },
    {
        "x": 62.33,
        "y": 25.73
    },
    {
        "x": 62.34,
        "y": 25.73
    },
    {
        "x": 62.35,
        "y": 25.73
    },
    {
        "x": 62.36,
        "y": 25.73
    },
    {
        "x": 62.37,
        "y": 25.74
    },
    {
        "x": 62.38,
        "y": 25.73
    },
    {
        "x": 62.39,
        "y": 25.73
    },
    {
        "x": 62.4,
        "y": 25.73
    },
    {
        "x": 62.41,
        "y": 25.73
    },
    {
        "x": 62.42,
        "y": 25.74
    },
    {
        "x": 62.43,
        "y": 25.73
    },
    {
        "x": 62.44,
        "y": 25.73
    },
    {
        "x": 62.45,
        "y": 25.73
    },
    {
        "x": 62.46,
        "y": 25.73
    },
    {
        "x": 62.47,
        "y": 25.73
    },
    {
        "x": 62.48,
        "y": 25.73
    },
    {
        "x": 62.49,
        "y": 25.73
    },
    {
        "x": 62.5,
        "y": 25.73
    },
    {
        "x": 62.51,
        "y": 25.73
    },
    {
        "x": 62.52,
        "y": 25.73
    },
    {
        "x": 62.53,
        "y": 25.73
    },
    {
        "x": 62.54,
        "y": 25.73
    },
    {
        "x": 62.55,
        "y": 25.73
    },
    {
        "x": 62.56,
        "y": 25.73
    },
    {
        "x": 62.57,
        "y": 25.73
    },
    {
        "x": 62.58,
        "y": 25.73
    },
    {
        "x": 62.59,
        "y": 25.73
    },
    {
        "x": 62.6,
        "y": 25.73
    },
    {
        "x": 62.61,
        "y": 25.73
    },
    {
        "x": 62.62,
        "y": 25.73
    },
    {
        "x": 62.63,
        "y": 25.73
    },
    {
        "x": 62.64,
        "y": 25.73
    },
    {
        "x": 62.65,
        "y": 25.73
    },
    {
        "x": 62.66,
        "y": 25.73
    },
    {
        "x": 62.67,
        "y": 25.73
    },
    {
        "x": 62.68,
        "y": 25.73
    },
    {
        "x": 62.69,
        "y": 25.73
    },
    {
        "x": 62.7,
        "y": 25.73
    },
    {
        "x": 62.71,
        "y": 25.73
    },
    {
        "x": 62.72,
        "y": 25.73
    },
    {
        "x": 62.73,
        "y": 25.73
    },
    {
        "x": 62.74,
        "y": 25.73
    },
    {
        "x": 62.75,
        "y": 25.73
    },
    {
        "x": 62.76,
        "y": 25.73
    },
    {
        "x": 62.77,
        "y": 25.73
    },
    {
        "x": 62.78,
        "y": 25.73
    },
    {
        "x": 62.79,
        "y": 25.73
    },
    {
        "x": 62.8,
        "y": 25.73
    },
    {
        "x": 62.81,
        "y": 25.73
    },
    {
        "x": 62.82,
        "y": 25.73
    },
    {
        "x": 62.83,
        "y": 25.73
    },
    {
        "x": 62.84,
        "y": 25.73
    },
    {
        "x": 62.85,
        "y": 25.73
    },
    {
        "x": 62.86,
        "y": 25.73
    },
    {
        "x": 62.87,
        "y": 25.73
    },
    {
        "x": 62.88,
        "y": 25.73
    },
    {
        "x": 62.89,
        "y": 25.73
    },
    {
        "x": 62.9,
        "y": 25.73
    },
    {
        "x": 62.91,
        "y": 25.73
    },
    {
        "x": 62.92,
        "y": 25.73
    },
    {
        "x": 62.93,
        "y": 25.73
    },
    {
        "x": 62.94,
        "y": 25.73
    },
    {
        "x": 62.95,
        "y": 25.73
    },
    {
        "x": 62.96,
        "y": 25.73
    },
    {
        "x": 62.97,
        "y": 25.73
    },
    {
        "x": 62.98,
        "y": 25.73
    },
    {
        "x": 62.99,
        "y": 25.73
    },
    {
        "x": 63,
        "y": 25.73
    },
    {
        "x": 63.01,
        "y": 25.73
    },
    {
        "x": 63.02,
        "y": 25.73
    },
    {
        "x": 63.03,
        "y": 25.73
    },
    {
        "x": 63.04,
        "y": 25.73
    },
    {
        "x": 63.05,
        "y": 25.73
    },
    {
        "x": 63.06,
        "y": 25.73
    },
    {
        "x": 63.07,
        "y": 25.73
    },
    {
        "x": 63.08,
        "y": 25.73
    },
    {
        "x": 63.09,
        "y": 25.73
    },
    {
        "x": 63.1,
        "y": 25.73
    },
    {
        "x": 63.11,
        "y": 25.73
    },
    {
        "x": 63.12,
        "y": 25.73
    },
    {
        "x": 63.13,
        "y": 25.73
    },
    {
        "x": 63.14,
        "y": 25.73
    },
    {
        "x": 63.15,
        "y": 25.73
    },
    {
        "x": 63.16,
        "y": 25.73
    },
    {
        "x": 63.17,
        "y": 25.73
    },
    {
        "x": 63.18,
        "y": 25.73
    },
    {
        "x": 63.19,
        "y": 25.73
    },
    {
        "x": 63.2,
        "y": 25.73
    },
    {
        "x": 63.21,
        "y": 25.73
    },
    {
        "x": 63.22,
        "y": 25.73
    },
    {
        "x": 63.23,
        "y": 25.73
    },
    {
        "x": 63.24,
        "y": 25.73
    },
    {
        "x": 63.25,
        "y": 25.73
    },
    {
        "x": 63.26,
        "y": 25.73
    },
    {
        "x": 63.27,
        "y": 25.73
    },
    {
        "x": 63.28,
        "y": 25.73
    },
    {
        "x": 63.29,
        "y": 25.73
    },
    {
        "x": 63.3,
        "y": 25.73
    },
    {
        "x": 63.31,
        "y": 25.73
    },
    {
        "x": 63.32,
        "y": 25.73
    },
    {
        "x": 63.33,
        "y": 25.73
    },
    {
        "x": 63.34,
        "y": 25.73
    },
    {
        "x": 63.35,
        "y": 25.73
    },
    {
        "x": 63.36,
        "y": 25.73
    },
    {
        "x": 63.37,
        "y": 25.73
    },
    {
        "x": 63.38,
        "y": 25.73
    },
    {
        "x": 63.39,
        "y": 25.73
    },
    {
        "x": 63.4,
        "y": 25.73
    },
    {
        "x": 63.41,
        "y": 25.73
    },
    {
        "x": 63.42,
        "y": 25.73
    },
    {
        "x": 63.43,
        "y": 25.73
    },
    {
        "x": 63.44,
        "y": 25.73
    },
    {
        "x": 63.45,
        "y": 25.73
    },
    {
        "x": 63.46,
        "y": 25.73
    },
    {
        "x": 63.47,
        "y": 25.73
    },
    {
        "x": 63.48,
        "y": 25.73
    },
    {
        "x": 63.49,
        "y": 25.73
    },
    {
        "x": 63.5,
        "y": 25.73
    },
    {
        "x": 63.51,
        "y": 25.72
    },
    {
        "x": 63.52,
        "y": 25.73
    },
    {
        "x": 63.53,
        "y": 25.73
    },
    {
        "x": 63.54,
        "y": 25.73
    },
    {
        "x": 63.55,
        "y": 25.72
    },
    {
        "x": 63.56,
        "y": 25.73
    },
    {
        "x": 63.57,
        "y": 25.73
    },
    {
        "x": 63.58,
        "y": 25.73
    },
    {
        "x": 63.59,
        "y": 25.73
    },
    {
        "x": 63.6,
        "y": 25.73
    },
    {
        "x": 63.61,
        "y": 25.73
    },
    {
        "x": 63.62,
        "y": 25.73
    },
    {
        "x": 63.63,
        "y": 25.73
    },
    {
        "x": 63.64,
        "y": 25.73
    },
    {
        "x": 63.65,
        "y": 25.73
    },
    {
        "x": 63.66,
        "y": 25.73
    },
    {
        "x": 63.67,
        "y": 25.73
    },
    {
        "x": 63.68,
        "y": 25.73
    },
    {
        "x": 63.69,
        "y": 25.73
    },
    {
        "x": 63.7,
        "y": 25.73
    },
    {
        "x": 63.71,
        "y": 25.73
    },
    {
        "x": 63.72,
        "y": 25.73
    },
    {
        "x": 63.73,
        "y": 25.72
    },
    {
        "x": 63.74,
        "y": 25.73
    },
    {
        "x": 63.75,
        "y": 25.73
    },
    {
        "x": 63.76,
        "y": 25.72
    },
    {
        "x": 63.77,
        "y": 25.72
    },
    {
        "x": 63.78,
        "y": 25.72
    },
    {
        "x": 63.79,
        "y": 25.73
    },
    {
        "x": 63.8,
        "y": 25.73
    },
    {
        "x": 63.81,
        "y": 25.72
    },
    {
        "x": 63.82,
        "y": 25.73
    },
    {
        "x": 63.83,
        "y": 25.73
    },
    {
        "x": 63.84,
        "y": 25.73
    },
    {
        "x": 63.85,
        "y": 25.72
    },
    {
        "x": 63.86,
        "y": 25.72
    },
    {
        "x": 63.87,
        "y": 25.73
    },
    {
        "x": 63.88,
        "y": 25.73
    },
    {
        "x": 63.89,
        "y": 25.73
    },
    {
        "x": 63.9,
        "y": 25.73
    },
    {
        "x": 63.91,
        "y": 25.72
    },
    {
        "x": 63.92,
        "y": 25.73
    },
    {
        "x": 63.93,
        "y": 25.72
    },
    {
        "x": 63.94,
        "y": 25.72
    },
    {
        "x": 63.95,
        "y": 25.72
    },
    {
        "x": 63.96,
        "y": 25.72
    },
    {
        "x": 63.97,
        "y": 25.73
    },
    {
        "x": 63.98,
        "y": 25.73
    },
    {
        "x": 63.99,
        "y": 25.73
    },
    {
        "x": 64,
        "y": 25.72
    },
    {
        "x": 64.01,
        "y": 25.72
    },
    {
        "x": 64.02,
        "y": 25.73
    },
    {
        "x": 64.03,
        "y": 25.72
    },
    {
        "x": 64.04,
        "y": 25.72
    },
    {
        "x": 64.05,
        "y": 25.72
    },
    {
        "x": 64.06,
        "y": 25.72
    },
    {
        "x": 64.07,
        "y": 25.72
    },
    {
        "x": 64.08,
        "y": 25.73
    },
    {
        "x": 64.09,
        "y": 25.72
    },
    {
        "x": 64.1,
        "y": 25.73
    },
    {
        "x": 64.11,
        "y": 25.72
    },
    {
        "x": 64.12,
        "y": 25.73
    },
    {
        "x": 64.13,
        "y": 25.73
    },
    {
        "x": 64.14,
        "y": 25.73
    },
    {
        "x": 64.15,
        "y": 25.73
    },
    {
        "x": 64.16,
        "y": 25.72
    },
    {
        "x": 64.17,
        "y": 25.72
    },
    {
        "x": 64.18,
        "y": 25.73
    },
    {
        "x": 64.19,
        "y": 25.73
    },
    {
        "x": 64.2,
        "y": 25.73
    },
    {
        "x": 64.21,
        "y": 25.72
    },
    {
        "x": 64.22,
        "y": 25.73
    },
    {
        "x": 64.23,
        "y": 25.73
    },
    {
        "x": 64.24,
        "y": 25.73
    },
    {
        "x": 64.25,
        "y": 25.73
    },
    {
        "x": 64.26,
        "y": 25.73
    },
    {
        "x": 64.27,
        "y": 25.73
    },
    {
        "x": 64.28,
        "y": 25.73
    },
    {
        "x": 64.29,
        "y": 25.72
    },
    {
        "x": 64.3,
        "y": 25.73
    },
    {
        "x": 64.31,
        "y": 25.72
    },
    {
        "x": 64.32,
        "y": 25.72
    },
    {
        "x": 64.33,
        "y": 25.72
    },
    {
        "x": 64.34,
        "y": 25.72
    },
    {
        "x": 64.35,
        "y": 25.72
    },
    {
        "x": 64.36,
        "y": 25.72
    },
    {
        "x": 64.37,
        "y": 25.72
    },
    {
        "x": 64.38,
        "y": 25.72
    },
    {
        "x": 64.39,
        "y": 25.72
    },
    {
        "x": 64.4,
        "y": 25.72
    },
    {
        "x": 64.41,
        "y": 25.72
    },
    {
        "x": 64.42,
        "y": 25.72
    },
    {
        "x": 64.43,
        "y": 25.72
    },
    {
        "x": 64.44,
        "y": 25.72
    },
    {
        "x": 64.45,
        "y": 25.72
    },
    {
        "x": 64.46,
        "y": 25.72
    },
    {
        "x": 64.47,
        "y": 25.72
    },
    {
        "x": 64.48,
        "y": 25.72
    },
    {
        "x": 64.49,
        "y": 25.72
    },
    {
        "x": 64.5,
        "y": 25.72
    },
    {
        "x": 64.51,
        "y": 25.72
    },
    {
        "x": 64.52,
        "y": 25.72
    },
    {
        "x": 64.53,
        "y": 25.72
    },
    {
        "x": 64.54,
        "y": 25.72
    },
    {
        "x": 64.55,
        "y": 25.72
    },
    {
        "x": 64.56,
        "y": 25.72
    },
    {
        "x": 64.57,
        "y": 25.72
    },
    {
        "x": 64.58,
        "y": 25.72
    },
    {
        "x": 64.59,
        "y": 25.72
    },
    {
        "x": 64.6,
        "y": 25.72
    },
    {
        "x": 64.61,
        "y": 25.72
    },
    {
        "x": 64.62,
        "y": 25.72
    },
    {
        "x": 64.63,
        "y": 25.72
    },
    {
        "x": 64.64,
        "y": 25.72
    },
    {
        "x": 64.65,
        "y": 25.72
    },
    {
        "x": 64.66,
        "y": 25.73
    },
    {
        "x": 64.67,
        "y": 25.72
    },
    {
        "x": 64.68,
        "y": 25.72
    },
    {
        "x": 64.69,
        "y": 25.72
    },
    {
        "x": 64.7,
        "y": 25.72
    },
    {
        "x": 64.71,
        "y": 25.72
    },
    {
        "x": 64.72,
        "y": 25.72
    },
    {
        "x": 64.73,
        "y": 25.72
    },
    {
        "x": 64.74,
        "y": 25.72
    },
    {
        "x": 64.75,
        "y": 25.72
    },
    {
        "x": 64.76,
        "y": 25.72
    },
    {
        "x": 64.77,
        "y": 25.72
    },
    {
        "x": 64.78,
        "y": 25.72
    },
    {
        "x": 64.79,
        "y": 25.72
    },
    {
        "x": 64.8,
        "y": 25.72
    },
    {
        "x": 64.81,
        "y": 25.72
    },
    {
        "x": 64.82,
        "y": 25.72
    },
    {
        "x": 64.83,
        "y": 25.72
    },
    {
        "x": 64.84,
        "y": 25.72
    },
    {
        "x": 64.85,
        "y": 25.72
    },
    {
        "x": 64.86,
        "y": 25.72
    },
    {
        "x": 64.87,
        "y": 25.72
    },
    {
        "x": 64.88,
        "y": 25.72
    },
    {
        "x": 64.89,
        "y": 25.72
    },
    {
        "x": 64.9,
        "y": 25.72
    },
    {
        "x": 64.91,
        "y": 25.72
    },
    {
        "x": 64.92,
        "y": 25.72
    },
    {
        "x": 64.93,
        "y": 25.72
    },
    {
        "x": 64.94,
        "y": 25.72
    },
    {
        "x": 64.95,
        "y": 25.72
    },
    {
        "x": 64.96,
        "y": 25.72
    },
    {
        "x": 64.97,
        "y": 25.72
    },
    {
        "x": 64.98,
        "y": 25.72
    },
    {
        "x": 64.99,
        "y": 25.72
    },
    {
        "x": 65,
        "y": 25.72
    },
    {
        "x": 65.01,
        "y": 25.72
    },
    {
        "x": 65.02,
        "y": 25.72
    },
    {
        "x": 65.03,
        "y": 25.72
    },
    {
        "x": 65.04,
        "y": 25.72
    },
    {
        "x": 65.05,
        "y": 25.72
    },
    {
        "x": 65.06,
        "y": 25.72
    },
    {
        "x": 65.07,
        "y": 25.72
    },
    {
        "x": 65.08,
        "y": 25.72
    },
    {
        "x": 65.09,
        "y": 25.72
    },
    {
        "x": 65.1,
        "y": 25.72
    },
    {
        "x": 65.11,
        "y": 25.72
    },
    {
        "x": 65.12,
        "y": 25.72
    },
    {
        "x": 65.13,
        "y": 25.71
    },
    {
        "x": 65.14,
        "y": 25.72
    },
    {
        "x": 65.15,
        "y": 25.72
    },
    {
        "x": 65.16,
        "y": 25.72
    },
    {
        "x": 65.17,
        "y": 25.72
    },
    {
        "x": 65.18,
        "y": 25.72
    },
    {
        "x": 65.19,
        "y": 25.72
    },
    {
        "x": 65.2,
        "y": 25.72
    },
    {
        "x": 65.21,
        "y": 25.72
    },
    {
        "x": 65.22,
        "y": 25.72
    },
    {
        "x": 65.23,
        "y": 25.72
    },
    {
        "x": 65.24,
        "y": 25.72
    },
    {
        "x": 65.25,
        "y": 25.72
    },
    {
        "x": 65.26,
        "y": 25.72
    },
    {
        "x": 65.27,
        "y": 25.72
    },
    {
        "x": 65.28,
        "y": 25.72
    },
    {
        "x": 65.29,
        "y": 25.72
    },
    {
        "x": 65.3,
        "y": 25.72
    },
    {
        "x": 65.31,
        "y": 25.72
    },
    {
        "x": 65.32,
        "y": 25.72
    },
    {
        "x": 65.33,
        "y": 25.72
    },
    {
        "x": 65.34,
        "y": 25.72
    },
    {
        "x": 65.35,
        "y": 25.72
    },
    {
        "x": 65.36,
        "y": 25.72
    },
    {
        "x": 65.37,
        "y": 25.72
    },
    {
        "x": 65.38,
        "y": 25.72
    },
    {
        "x": 65.39,
        "y": 25.72
    },
    {
        "x": 65.4,
        "y": 25.72
    },
    {
        "x": 65.41,
        "y": 25.72
    },
    {
        "x": 65.42,
        "y": 25.72
    },
    {
        "x": 65.43,
        "y": 25.72
    },
    {
        "x": 65.44,
        "y": 25.72
    },
    {
        "x": 65.45,
        "y": 25.72
    },
    {
        "x": 65.46,
        "y": 25.71
    },
    {
        "x": 65.47,
        "y": 25.72
    },
    {
        "x": 65.48,
        "y": 25.72
    },
    {
        "x": 65.49,
        "y": 25.72
    },
    {
        "x": 65.5,
        "y": 25.72
    },
    {
        "x": 65.51,
        "y": 25.72
    },
    {
        "x": 65.52,
        "y": 25.72
    },
    {
        "x": 65.53,
        "y": 25.72
    },
    {
        "x": 65.54,
        "y": 25.72
    },
    {
        "x": 65.55,
        "y": 25.72
    },
    {
        "x": 65.56,
        "y": 25.72
    },
    {
        "x": 65.57,
        "y": 25.72
    },
    {
        "x": 65.58,
        "y": 25.72
    },
    {
        "x": 65.59,
        "y": 25.72
    },
    {
        "x": 65.6,
        "y": 25.72
    },
    {
        "x": 65.61,
        "y": 25.72
    },
    {
        "x": 65.62,
        "y": 25.72
    },
    {
        "x": 65.63,
        "y": 25.72
    },
    {
        "x": 65.64,
        "y": 25.72
    },
    {
        "x": 65.65,
        "y": 25.72
    },
    {
        "x": 65.66,
        "y": 25.72
    },
    {
        "x": 65.67,
        "y": 25.72
    },
    {
        "x": 65.68,
        "y": 25.72
    },
    {
        "x": 65.69,
        "y": 25.72
    },
    {
        "x": 65.7,
        "y": 25.72
    },
    {
        "x": 65.71,
        "y": 25.72
    },
    {
        "x": 65.72,
        "y": 25.71
    },
    {
        "x": 65.73,
        "y": 25.72
    },
    {
        "x": 65.74,
        "y": 25.72
    },
    {
        "x": 65.75,
        "y": 25.72
    },
    {
        "x": 65.76,
        "y": 25.71
    },
    {
        "x": 65.77,
        "y": 25.72
    },
    {
        "x": 65.78,
        "y": 25.72
    },
    {
        "x": 65.79,
        "y": 25.72
    },
    {
        "x": 65.8,
        "y": 25.72
    },
    {
        "x": 65.81,
        "y": 25.71
    },
    {
        "x": 65.82,
        "y": 25.71
    },
    {
        "x": 65.83,
        "y": 25.72
    },
    {
        "x": 65.84,
        "y": 25.72
    },
    {
        "x": 65.85,
        "y": 25.72
    },
    {
        "x": 65.86,
        "y": 25.72
    },
    {
        "x": 65.87,
        "y": 25.71
    },
    {
        "x": 65.88,
        "y": 25.71
    },
    {
        "x": 65.89,
        "y": 25.72
    },
    {
        "x": 65.9,
        "y": 25.72
    },
    {
        "x": 65.91,
        "y": 25.71
    },
    {
        "x": 65.92,
        "y": 25.71
    },
    {
        "x": 65.93,
        "y": 25.72
    },
    {
        "x": 65.94,
        "y": 25.72
    },
    {
        "x": 65.95,
        "y": 25.72
    },
    {
        "x": 65.96,
        "y": 25.71
    },
    {
        "x": 65.97,
        "y": 25.72
    },
    {
        "x": 65.98,
        "y": 25.72
    },
    {
        "x": 65.99,
        "y": 25.71
    },
    {
        "x": 66,
        "y": 25.71
    },
    {
        "x": 66.01,
        "y": 25.72
    },
    {
        "x": 66.02,
        "y": 25.72
    },
    {
        "x": 66.03,
        "y": 25.72
    },
    {
        "x": 66.04,
        "y": 25.72
    },
    {
        "x": 66.05,
        "y": 25.72
    },
    {
        "x": 66.06,
        "y": 25.72
    },
    {
        "x": 66.07,
        "y": 25.71
    },
    {
        "x": 66.08,
        "y": 25.72
    },
    {
        "x": 66.09,
        "y": 25.72
    },
    {
        "x": 66.1,
        "y": 25.71
    },
    {
        "x": 66.11,
        "y": 25.71
    },
    {
        "x": 66.12,
        "y": 25.71
    },
    {
        "x": 66.13,
        "y": 25.71
    },
    {
        "x": 66.14,
        "y": 25.71
    },
    {
        "x": 66.15,
        "y": 25.71
    },
    {
        "x": 66.16,
        "y": 25.71
    },
    {
        "x": 66.17,
        "y": 25.71
    },
    {
        "x": 66.18,
        "y": 25.71
    },
    {
        "x": 66.19,
        "y": 25.71
    },
    {
        "x": 66.2,
        "y": 25.71
    },
    {
        "x": 66.21,
        "y": 25.71
    },
    {
        "x": 66.22,
        "y": 25.71
    },
    {
        "x": 66.23,
        "y": 25.71
    },
    {
        "x": 66.24,
        "y": 25.71
    },
    {
        "x": 66.25,
        "y": 25.71
    },
    {
        "x": 66.26,
        "y": 25.71
    },
    {
        "x": 66.27,
        "y": 25.71
    },
    {
        "x": 66.28,
        "y": 25.71
    },
    {
        "x": 66.29,
        "y": 25.71
    },
    {
        "x": 66.3,
        "y": 25.71
    },
    {
        "x": 66.31,
        "y": 25.71
    },
    {
        "x": 66.32,
        "y": 25.71
    },
    {
        "x": 66.33,
        "y": 25.71
    },
    {
        "x": 66.34,
        "y": 25.71
    },
    {
        "x": 66.35,
        "y": 25.71
    },
    {
        "x": 66.36,
        "y": 25.71
    },
    {
        "x": 66.37,
        "y": 25.71
    },
    {
        "x": 66.38,
        "y": 25.71
    },
    {
        "x": 66.39,
        "y": 25.71
    },
    {
        "x": 66.4,
        "y": 25.71
    },
    {
        "x": 66.41,
        "y": 25.71
    },
    {
        "x": 66.42,
        "y": 25.71
    },
    {
        "x": 66.43,
        "y": 25.71
    },
    {
        "x": 66.44,
        "y": 25.71
    },
    {
        "x": 66.45,
        "y": 25.71
    },
    {
        "x": 66.46,
        "y": 25.71
    },
    {
        "x": 66.47,
        "y": 25.71
    },
    {
        "x": 66.48,
        "y": 25.71
    },
    {
        "x": 66.49,
        "y": 25.71
    },
    {
        "x": 66.5,
        "y": 25.71
    },
    {
        "x": 66.51,
        "y": 25.71
    },
    {
        "x": 66.52,
        "y": 25.71
    },
    {
        "x": 66.53,
        "y": 25.71
    },
    {
        "x": 66.54,
        "y": 25.71
    },
    {
        "x": 66.55,
        "y": 25.71
    },
    {
        "x": 66.56,
        "y": 25.71
    },
    {
        "x": 66.57,
        "y": 25.71
    },
    {
        "x": 66.58,
        "y": 25.71
    },
    {
        "x": 66.59,
        "y": 25.71
    },
    {
        "x": 66.6,
        "y": 25.71
    },
    {
        "x": 66.61,
        "y": 25.71
    },
    {
        "x": 66.62,
        "y": 25.71
    },
    {
        "x": 66.63,
        "y": 25.71
    },
    {
        "x": 66.64,
        "y": 25.71
    },
    {
        "x": 66.65,
        "y": 25.71
    },
    {
        "x": 66.66,
        "y": 25.71
    },
    {
        "x": 66.67,
        "y": 25.71
    },
    {
        "x": 66.68,
        "y": 25.71
    },
    {
        "x": 66.69,
        "y": 25.71
    },
    {
        "x": 66.7,
        "y": 25.71
    },
    {
        "x": 66.71,
        "y": 25.71
    },
    {
        "x": 66.72,
        "y": 25.71
    },
    {
        "x": 66.73,
        "y": 25.71
    },
    {
        "x": 66.74,
        "y": 25.71
    },
    {
        "x": 66.75,
        "y": 25.71
    },
    {
        "x": 66.76,
        "y": 25.71
    },
    {
        "x": 66.77,
        "y": 25.71
    },
    {
        "x": 66.78,
        "y": 25.71
    },
    {
        "x": 66.79,
        "y": 25.71
    },
    {
        "x": 66.8,
        "y": 25.71
    },
    {
        "x": 66.81,
        "y": 25.71
    },
    {
        "x": 66.82,
        "y": 25.71
    },
    {
        "x": 66.83,
        "y": 25.71
    },
    {
        "x": 66.84,
        "y": 25.71
    },
    {
        "x": 66.85,
        "y": 25.71
    },
    {
        "x": 66.86,
        "y": 25.71
    },
    {
        "x": 66.87,
        "y": 25.71
    },
    {
        "x": 66.88,
        "y": 25.71
    },
    {
        "x": 66.89,
        "y": 25.71
    },
    {
        "x": 66.9,
        "y": 25.71
    },
    {
        "x": 66.91,
        "y": 25.71
    },
    {
        "x": 66.92,
        "y": 25.71
    },
    {
        "x": 66.93,
        "y": 25.71
    },
    {
        "x": 66.94,
        "y": 25.71
    },
    {
        "x": 66.95,
        "y": 25.71
    },
    {
        "x": 66.96,
        "y": 25.71
    },
    {
        "x": 66.97,
        "y": 25.71
    },
    {
        "x": 66.98,
        "y": 25.71
    },
    {
        "x": 66.99,
        "y": 25.71
    },
    {
        "x": 67,
        "y": 25.71
    },
    {
        "x": 67.01,
        "y": 25.71
    },
    {
        "x": 67.02,
        "y": 25.71
    },
    {
        "x": 67.03,
        "y": 25.71
    },
    {
        "x": 67.04,
        "y": 25.71
    },
    {
        "x": 67.05,
        "y": 25.71
    },
    {
        "x": 67.06,
        "y": 25.71
    },
    {
        "x": 67.07,
        "y": 25.71
    },
    {
        "x": 67.08,
        "y": 25.71
    },
    {
        "x": 67.09,
        "y": 25.71
    },
    {
        "x": 67.1,
        "y": 25.71
    },
    {
        "x": 67.11,
        "y": 25.71
    },
    {
        "x": 67.12,
        "y": 25.71
    },
    {
        "x": 67.13,
        "y": 25.71
    },
    {
        "x": 67.14,
        "y": 25.71
    },
    {
        "x": 67.15,
        "y": 25.71
    },
    {
        "x": 67.16,
        "y": 25.71
    },
    {
        "x": 67.17,
        "y": 25.71
    },
    {
        "x": 67.18,
        "y": 25.71
    },
    {
        "x": 67.19,
        "y": 25.71
    },
    {
        "x": 67.2,
        "y": 25.7
    },
    {
        "x": 67.21,
        "y": 25.71
    },
    {
        "x": 67.22,
        "y": 25.71
    },
    {
        "x": 67.23,
        "y": 25.71
    },
    {
        "x": 67.24,
        "y": 25.71
    },
    {
        "x": 67.25,
        "y": 25.71
    },
    {
        "x": 67.26,
        "y": 25.71
    },
    {
        "x": 67.27,
        "y": 25.71
    },
    {
        "x": 67.28,
        "y": 25.7
    },
    {
        "x": 67.29,
        "y": 25.71
    },
    {
        "x": 67.3,
        "y": 25.71
    },
    {
        "x": 67.31,
        "y": 25.71
    },
    {
        "x": 67.32,
        "y": 25.71
    },
    {
        "x": 67.33,
        "y": 25.71
    },
    {
        "x": 67.34,
        "y": 25.71
    },
    {
        "x": 67.35,
        "y": 25.7
    },
    {
        "x": 67.36,
        "y": 25.71
    },
    {
        "x": 67.37,
        "y": 25.7
    },
    {
        "x": 67.38,
        "y": 25.7
    },
    {
        "x": 67.39,
        "y": 25.71
    },
    {
        "x": 67.4,
        "y": 25.7
    },
    {
        "x": 67.41,
        "y": 25.71
    },
    {
        "x": 67.42,
        "y": 25.71
    },
    {
        "x": 67.43,
        "y": 25.71
    },
    {
        "x": 67.44,
        "y": 25.71
    },
    {
        "x": 67.45,
        "y": 25.71
    },
    {
        "x": 67.46,
        "y": 25.7
    },
    {
        "x": 67.47,
        "y": 25.71
    },
    {
        "x": 67.48,
        "y": 25.71
    },
    {
        "x": 67.49,
        "y": 25.71
    },
    {
        "x": 67.5,
        "y": 25.71
    },
    {
        "x": 67.51,
        "y": 25.71
    },
    {
        "x": 67.52,
        "y": 25.7
    },
    {
        "x": 67.53,
        "y": 25.7
    },
    {
        "x": 67.54,
        "y": 25.71
    },
    {
        "x": 67.55,
        "y": 25.7
    },
    {
        "x": 67.56,
        "y": 25.71
    },
    {
        "x": 67.57,
        "y": 25.71
    },
    {
        "x": 67.58,
        "y": 25.71
    },
    {
        "x": 67.59,
        "y": 25.71
    },
    {
        "x": 67.6,
        "y": 25.7
    },
    {
        "x": 67.61,
        "y": 25.7
    },
    {
        "x": 67.62,
        "y": 25.71
    },
    {
        "x": 67.63,
        "y": 25.7
    },
    {
        "x": 67.64,
        "y": 25.71
    },
    {
        "x": 67.65,
        "y": 25.71
    },
    {
        "x": 67.66,
        "y": 25.71
    },
    {
        "x": 67.67,
        "y": 25.7
    },
    {
        "x": 67.68,
        "y": 25.71
    },
    {
        "x": 67.69,
        "y": 25.71
    },
    {
        "x": 67.7,
        "y": 25.7
    },
    {
        "x": 67.71,
        "y": 25.71
    },
    {
        "x": 67.72,
        "y": 25.71
    },
    {
        "x": 67.73,
        "y": 25.71
    },
    {
        "x": 67.74,
        "y": 25.71
    },
    {
        "x": 67.75,
        "y": 25.7
    },
    {
        "x": 67.76,
        "y": 25.7
    },
    {
        "x": 67.77,
        "y": 25.71
    },
    {
        "x": 67.78,
        "y": 25.71
    },
    {
        "x": 67.79,
        "y": 25.71
    },
    {
        "x": 67.8,
        "y": 25.7
    },
    {
        "x": 67.81,
        "y": 25.71
    },
    {
        "x": 67.82,
        "y": 25.71
    },
    {
        "x": 67.83,
        "y": 25.7
    },
    {
        "x": 67.84,
        "y": 25.71
    },
    {
        "x": 67.85,
        "y": 25.7
    },
    {
        "x": 67.86,
        "y": 25.7
    },
    {
        "x": 67.87,
        "y": 25.71
    },
    {
        "x": 67.88,
        "y": 25.7
    },
    {
        "x": 67.89,
        "y": 25.7
    },
    {
        "x": 67.9,
        "y": 25.7
    },
    {
        "x": 67.91,
        "y": 25.7
    },
    {
        "x": 67.92,
        "y": 25.7
    },
    {
        "x": 67.93,
        "y": 25.7
    },
    {
        "x": 67.94,
        "y": 25.7
    },
    {
        "x": 67.95,
        "y": 25.7
    },
    {
        "x": 67.96,
        "y": 25.7
    },
    {
        "x": 67.97,
        "y": 25.7
    },
    {
        "x": 67.98,
        "y": 25.7
    },
    {
        "x": 67.99,
        "y": 25.7
    },
    {
        "x": 68,
        "y": 25.7
    },
    {
        "x": 68.01,
        "y": 25.7
    },
    {
        "x": 68.02,
        "y": 25.7
    },
    {
        "x": 68.03,
        "y": 25.7
    },
    {
        "x": 68.04,
        "y": 25.7
    },
    {
        "x": 68.05,
        "y": 25.7
    },
    {
        "x": 68.06,
        "y": 25.7
    },
    {
        "x": 68.07,
        "y": 25.7
    },
    {
        "x": 68.08,
        "y": 25.7
    },
    {
        "x": 68.09,
        "y": 25.7
    },
    {
        "x": 68.1,
        "y": 25.7
    },
    {
        "x": 68.11,
        "y": 25.7
    },
    {
        "x": 68.12,
        "y": 25.7
    },
    {
        "x": 68.13,
        "y": 25.7
    },
    {
        "x": 68.14,
        "y": 25.7
    },
    {
        "x": 68.15,
        "y": 25.7
    },
    {
        "x": 68.16,
        "y": 25.7
    },
    {
        "x": 68.17,
        "y": 25.71
    },
    {
        "x": 68.18,
        "y": 25.7
    },
    {
        "x": 68.19,
        "y": 25.7
    },
    {
        "x": 68.2,
        "y": 25.7
    },
    {
        "x": 68.21,
        "y": 25.7
    },
    {
        "x": 68.22,
        "y": 25.7
    },
    {
        "x": 68.23,
        "y": 25.7
    },
    {
        "x": 68.24,
        "y": 25.7
    },
    {
        "x": 68.25,
        "y": 25.7
    },
    {
        "x": 68.26,
        "y": 25.7
    },
    {
        "x": 68.27,
        "y": 25.7
    },
    {
        "x": 68.28,
        "y": 25.7
    },
    {
        "x": 68.29,
        "y": 25.7
    },
    {
        "x": 68.3,
        "y": 25.7
    },
    {
        "x": 68.31,
        "y": 25.7
    },
    {
        "x": 68.32,
        "y": 25.7
    },
    {
        "x": 68.33,
        "y": 25.7
    },
    {
        "x": 68.34,
        "y": 25.7
    },
    {
        "x": 68.35,
        "y": 25.7
    },
    {
        "x": 68.36,
        "y": 25.7
    },
    {
        "x": 68.37,
        "y": 25.7
    },
    {
        "x": 68.38,
        "y": 25.7
    },
    {
        "x": 68.39,
        "y": 25.7
    },
    {
        "x": 68.4,
        "y": 25.7
    },
    {
        "x": 68.41,
        "y": 25.7
    },
    {
        "x": 68.42,
        "y": 25.7
    },
    {
        "x": 68.43,
        "y": 25.7
    },
    {
        "x": 68.44,
        "y": 25.7
    },
    {
        "x": 68.45,
        "y": 25.7
    },
    {
        "x": 68.46,
        "y": 25.7
    },
    {
        "x": 68.47,
        "y": 25.7
    },
    {
        "x": 68.48,
        "y": 25.7
    },
    {
        "x": 68.49,
        "y": 25.7
    },
    {
        "x": 68.5,
        "y": 25.7
    },
    {
        "x": 68.51,
        "y": 25.7
    },
    {
        "x": 68.52,
        "y": 25.7
    },
    {
        "x": 68.53,
        "y": 25.7
    },
    {
        "x": 68.54,
        "y": 25.7
    },
    {
        "x": 68.55,
        "y": 25.7
    },
    {
        "x": 68.56,
        "y": 25.7
    },
    {
        "x": 68.57,
        "y": 25.7
    },
    {
        "x": 68.58,
        "y": 25.7
    },
    {
        "x": 68.59,
        "y": 25.7
    },
    {
        "x": 68.6,
        "y": 25.7
    },
    {
        "x": 68.61,
        "y": 25.7
    },
    {
        "x": 68.62,
        "y": 25.7
    },
    {
        "x": 68.63,
        "y": 25.7
    },
    {
        "x": 68.64,
        "y": 25.7
    },
    {
        "x": 68.65,
        "y": 25.7
    },
    {
        "x": 68.66,
        "y": 25.7
    },
    {
        "x": 68.67,
        "y": 25.7
    },
    {
        "x": 68.68,
        "y": 25.7
    },
    {
        "x": 68.69,
        "y": 25.7
    },
    {
        "x": 68.7,
        "y": 25.7
    },
    {
        "x": 68.71,
        "y": 25.7
    },
    {
        "x": 68.72,
        "y": 25.7
    },
    {
        "x": 68.73,
        "y": 25.7
    },
    {
        "x": 68.74,
        "y": 25.7
    },
    {
        "x": 68.75,
        "y": 25.7
    },
    {
        "x": 68.76,
        "y": 25.7
    },
    {
        "x": 68.77,
        "y": 25.7
    },
    {
        "x": 68.78,
        "y": 25.7
    },
    {
        "x": 68.79,
        "y": 25.7
    },
    {
        "x": 68.8,
        "y": 25.69
    },
    {
        "x": 68.81,
        "y": 25.7
    },
    {
        "x": 68.82,
        "y": 25.7
    },
    {
        "x": 68.83,
        "y": 25.7
    },
    {
        "x": 68.84,
        "y": 25.7
    },
    {
        "x": 68.85,
        "y": 25.7
    },
    {
        "x": 68.86,
        "y": 25.7
    },
    {
        "x": 68.87,
        "y": 25.7
    },
    {
        "x": 68.88,
        "y": 25.7
    },
    {
        "x": 68.89,
        "y": 25.7
    },
    {
        "x": 68.9,
        "y": 25.7
    },
    {
        "x": 68.91,
        "y": 25.7
    },
    {
        "x": 68.92,
        "y": 25.7
    },
    {
        "x": 68.93,
        "y": 25.7
    },
    {
        "x": 68.94,
        "y": 25.7
    },
    {
        "x": 68.95,
        "y": 25.7
    },
    {
        "x": 68.96,
        "y": 25.7
    },
    {
        "x": 68.97,
        "y": 25.7
    },
    {
        "x": 68.98,
        "y": 25.7
    },
    {
        "x": 68.99,
        "y": 25.69
    },
    {
        "x": 69,
        "y": 25.7
    },
    {
        "x": 69.01,
        "y": 25.7
    },
    {
        "x": 69.02,
        "y": 25.7
    },
    {
        "x": 69.03,
        "y": 25.7
    },
    {
        "x": 69.04,
        "y": 25.7
    },
    {
        "x": 69.05,
        "y": 25.7
    },
    {
        "x": 69.06,
        "y": 25.7
    },
    {
        "x": 69.07,
        "y": 25.7
    },
    {
        "x": 69.08,
        "y": 25.7
    },
    {
        "x": 69.09,
        "y": 25.69
    },
    {
        "x": 69.1,
        "y": 25.7
    },
    {
        "x": 69.11,
        "y": 25.7
    },
    {
        "x": 69.12,
        "y": 25.7
    },
    {
        "x": 69.13,
        "y": 25.7
    },
    {
        "x": 69.14,
        "y": 25.7
    },
    {
        "x": 69.15,
        "y": 25.7
    },
    {
        "x": 69.16,
        "y": 25.7
    },
    {
        "x": 69.17,
        "y": 25.7
    },
    {
        "x": 69.18,
        "y": 25.69
    },
    {
        "x": 69.19,
        "y": 25.7
    },
    {
        "x": 69.2,
        "y": 25.7
    },
    {
        "x": 69.21,
        "y": 25.7
    },
    {
        "x": 69.22,
        "y": 25.7
    },
    {
        "x": 69.23,
        "y": 25.7
    },
    {
        "x": 69.24,
        "y": 25.7
    },
    {
        "x": 69.25,
        "y": 25.7
    },
    {
        "x": 69.26,
        "y": 25.7
    },
    {
        "x": 69.27,
        "y": 25.7
    },
    {
        "x": 69.28,
        "y": 25.7
    },
    {
        "x": 69.29,
        "y": 25.69
    },
    {
        "x": 69.3,
        "y": 25.7
    },
    {
        "x": 69.31,
        "y": 25.69
    },
    {
        "x": 69.32,
        "y": 25.69
    },
    {
        "x": 69.33,
        "y": 25.69
    },
    {
        "x": 69.34,
        "y": 25.7
    },
    {
        "x": 69.35,
        "y": 25.7
    },
    {
        "x": 69.36,
        "y": 25.69
    },
    {
        "x": 69.37,
        "y": 25.7
    },
    {
        "x": 69.38,
        "y": 25.7
    },
    {
        "x": 69.39,
        "y": 25.7
    },
    {
        "x": 69.4,
        "y": 25.7
    },
    {
        "x": 69.41,
        "y": 25.7
    },
    {
        "x": 69.42,
        "y": 25.69
    },
    {
        "x": 69.43,
        "y": 25.69
    },
    {
        "x": 69.44,
        "y": 25.7
    },
    {
        "x": 69.45,
        "y": 25.69
    },
    {
        "x": 69.46,
        "y": 25.7
    },
    {
        "x": 69.47,
        "y": 25.69
    },
    {
        "x": 69.48,
        "y": 25.69
    },
    {
        "x": 69.49,
        "y": 25.69
    },
    {
        "x": 69.5,
        "y": 25.69
    },
    {
        "x": 69.51,
        "y": 25.69
    },
    {
        "x": 69.52,
        "y": 25.69
    },
    {
        "x": 69.53,
        "y": 25.69
    },
    {
        "x": 69.54,
        "y": 25.69
    },
    {
        "x": 69.55,
        "y": 25.69
    },
    {
        "x": 69.56,
        "y": 25.69
    },
    {
        "x": 69.57,
        "y": 25.69
    },
    {
        "x": 69.58,
        "y": 25.69
    },
    {
        "x": 69.59,
        "y": 25.69
    },
    {
        "x": 69.6,
        "y": 25.69
    },
    {
        "x": 69.61,
        "y": 25.69
    },
    {
        "x": 69.62,
        "y": 25.7
    },
    {
        "x": 69.63,
        "y": 25.69
    },
    {
        "x": 69.64,
        "y": 25.69
    },
    {
        "x": 69.65,
        "y": 25.69
    },
    {
        "x": 69.66,
        "y": 25.69
    },
    {
        "x": 69.67,
        "y": 25.69
    },
    {
        "x": 69.68,
        "y": 25.69
    },
    {
        "x": 69.69,
        "y": 25.7
    },
    {
        "x": 69.7,
        "y": 25.69
    },
    {
        "x": 69.71,
        "y": 25.69
    },
    {
        "x": 69.72,
        "y": 25.69
    },
    {
        "x": 69.73,
        "y": 25.69
    },
    {
        "x": 69.74,
        "y": 25.69
    },
    {
        "x": 69.75,
        "y": 25.69
    },
    {
        "x": 69.76,
        "y": 25.69
    },
    {
        "x": 69.77,
        "y": 25.69
    },
    {
        "x": 69.78,
        "y": 25.69
    },
    {
        "x": 69.79,
        "y": 25.69
    },
    {
        "x": 69.8,
        "y": 25.69
    },
    {
        "x": 69.81,
        "y": 25.69
    },
    {
        "x": 69.82,
        "y": 25.69
    },
    {
        "x": 69.83,
        "y": 25.69
    },
    {
        "x": 69.84,
        "y": 25.69
    },
    {
        "x": 69.85,
        "y": 25.69
    },
    {
        "x": 69.86,
        "y": 25.69
    },
    {
        "x": 69.87,
        "y": 25.69
    },
    {
        "x": 69.88,
        "y": 25.69
    },
    {
        "x": 69.89,
        "y": 25.69
    },
    {
        "x": 69.9,
        "y": 25.69
    },
    {
        "x": 69.91,
        "y": 25.69
    },
    {
        "x": 69.92,
        "y": 25.69
    },
    {
        "x": 69.93,
        "y": 25.69
    },
    {
        "x": 69.94,
        "y": 25.69
    },
    {
        "x": 69.95,
        "y": 25.69
    },
    {
        "x": 69.96,
        "y": 25.69
    },
    {
        "x": 69.97,
        "y": 25.69
    },
    {
        "x": 69.98,
        "y": 25.69
    },
    {
        "x": 69.99,
        "y": 25.69
    },
    {
        "x": 70,
        "y": 25.7
    },
    {
        "x": 70.01,
        "y": 25.69
    },
    {
        "x": 70.02,
        "y": 25.7
    },
    {
        "x": 70.03,
        "y": 25.7
    },
    {
        "x": 70.04,
        "y": 25.7
    },
    {
        "x": 70.05,
        "y": 25.7
    },
    {
        "x": 70.06,
        "y": 25.7
    },
    {
        "x": 70.07,
        "y": 25.7
    },
    {
        "x": 70.08,
        "y": 25.7
    },
    {
        "x": 70.09,
        "y": 25.7
    },
    {
        "x": 70.1,
        "y": 25.7
    },
    {
        "x": 70.11,
        "y": 25.7
    },
    {
        "x": 70.12,
        "y": 25.69
    },
    {
        "x": 70.13,
        "y": 25.7
    },
    {
        "x": 70.14,
        "y": 25.7
    },
    {
        "x": 70.15,
        "y": 25.7
    },
    {
        "x": 70.16,
        "y": 25.7
    },
    {
        "x": 70.17,
        "y": 25.7
    },
    {
        "x": 70.18,
        "y": 25.69
    },
    {
        "x": 70.19,
        "y": 25.7
    },
    {
        "x": 70.2,
        "y": 25.69
    },
    {
        "x": 70.21,
        "y": 25.69
    },
    {
        "x": 70.22,
        "y": 25.69
    },
    {
        "x": 70.23,
        "y": 25.69
    },
    {
        "x": 70.24,
        "y": 25.7
    },
    {
        "x": 70.25,
        "y": 25.69
    },
    {
        "x": 70.26,
        "y": 25.69
    },
    {
        "x": 70.27,
        "y": 25.69
    },
    {
        "x": 70.28,
        "y": 25.69
    },
    {
        "x": 70.29,
        "y": 25.69
    },
    {
        "x": 70.3,
        "y": 25.69
    },
    {
        "x": 70.31,
        "y": 25.69
    },
    {
        "x": 70.32,
        "y": 25.69
    },
    {
        "x": 70.33,
        "y": 25.69
    }
];

export const toqueDataWithAILine1 = [
    {
        "x": 0.02,
        "y": 4.059
    },
    {
        "x": 0.03,
        "y": 4.041
    },
    {
        "x": 0.04,
        "y": 3.933
    },
    {
        "x": 0.05,
        "y": 3.726
    },
    {
        "x": 0.06,
        "y": 3.456
    },
    {
        "x": 0.07,
        "y": 3.204
    },
    {
        "x": 0.08,
        "y": 2.988
    },
    {
        "x": 0.09,
        "y": 2.817
    },
    {
        "x": 0.1,
        "y": 2.664
    },
    {
        "x": 0.11,
        "y": 2.547
    },
    {
        "x": 0.12,
        "y": 2.448
    },
    {
        "x": 0.13,
        "y": 2.367
    },
    {
        "x": 0.14,
        "y": 2.295
    },
    {
        "x": 0.15,
        "y": 2.241
    },
    {
        "x": 0.16,
        "y": 2.196
    },
    {
        "x": 0.17,
        "y": 2.151
    },
    {
        "x": 0.18,
        "y": 2.115
    },
    {
        "x": 0.19,
        "y": 2.079
    },
    {
        "x": 0.2,
        "y": 2.052
    },
    {
        "x": 0.21,
        "y": 2.025
    },
    {
        "x": 0.22,
        "y": 1.998
    },
    {
        "x": 0.23,
        "y": 1.98
    },
    {
        "x": 0.24,
        "y": 1.962
    },
    {
        "x": 0.25,
        "y": 1.944
    },
    {
        "x": 0.26,
        "y": 1.926
    },
    {
        "x": 0.27,
        "y": 1.917
    },
    {
        "x": 0.28,
        "y": 1.899
    },
    {
        "x": 0.29,
        "y": 1.89
    },
    {
        "x": 0.3,
        "y": 1.881
    },
    {
        "x": 0.31,
        "y": 1.872
    },
    {
        "x": 0.32,
        "y": 1.863
    },
    {
        "x": 0.33,
        "y": 1.854
    },
    {
        "x": 0.34,
        "y": 1.845
    },
    {
        "x": 0.35,
        "y": 1.836
    },
    {
        "x": 0.36,
        "y": 1.836
    },
    {
        "x": 0.37,
        "y": 1.827
    },
    {
        "x": 0.38,
        "y": 1.818
    },
    {
        "x": 0.39,
        "y": 1.818
    },
    {
        "x": 0.4,
        "y": 1.818
    },
    {
        "x": 0.41,
        "y": 1.809
    },
    {
        "x": 0.42,
        "y": 1.809
    },
    {
        "x": 0.43,
        "y": 1.809
    },
    {
        "x": 0.44,
        "y": 1.8
    },
    {
        "x": 0.45,
        "y": 1.8
    },
    {
        "x": 0.46,
        "y": 1.8
    },
    {
        "x": 0.47,
        "y": 1.8
    },
    {
        "x": 0.48,
        "y": 1.791
    },
    {
        "x": 0.49,
        "y": 1.791
    },
    {
        "x": 0.5,
        "y": 1.791
    },
    {
        "x": 0.51,
        "y": 1.791
    },
    {
        "x": 0.52,
        "y": 1.791
    },
    {
        "x": 0.53,
        "y": 1.791
    },
    {
        "x": 0.54,
        "y": 1.791
    },
    {
        "x": 0.55,
        "y": 1.782
    },
    {
        "x": 0.56,
        "y": 1.791
    },
    {
        "x": 0.57,
        "y": 1.791
    },
    {
        "x": 0.58,
        "y": 1.791
    },
    {
        "x": 0.59,
        "y": 1.791
    },
    {
        "x": 0.6,
        "y": 1.791
    },
    {
        "x": 0.61,
        "y": 1.791
    },
    {
        "x": 0.62,
        "y": 1.791
    },
    {
        "x": 0.63,
        "y": 1.791
    },
    {
        "x": 0.64,
        "y": 1.791
    },
    {
        "x": 0.65,
        "y": 1.791
    },
    {
        "x": 0.66,
        "y": 1.791
    },
    {
        "x": 0.67,
        "y": 1.791
    },
    {
        "x": 0.68,
        "y": 1.791
    },
    {
        "x": 0.69,
        "y": 1.8
    },
    {
        "x": 0.7,
        "y": 1.791
    },
    {
        "x": 0.71,
        "y": 1.8
    },
    {
        "x": 0.72,
        "y": 1.791
    },
    {
        "x": 0.73,
        "y": 1.791
    },
    {
        "x": 0.74,
        "y": 1.791
    },
    {
        "x": 0.75,
        "y": 1.8
    },
    {
        "x": 0.76,
        "y": 1.8
    },
    {
        "x": 0.77,
        "y": 1.8
    },
    {
        "x": 0.78,
        "y": 1.8
    },
    {
        "x": 0.79,
        "y": 1.8
    },
    {
        "x": 0.8,
        "y": 1.8
    },
    {
        "x": 0.81,
        "y": 1.8
    },
    {
        "x": 0.82,
        "y": 1.8
    },
    {
        "x": 0.83,
        "y": 1.8
    },
    {
        "x": 0.84,
        "y": 1.8
    },
    {
        "x": 0.85,
        "y": 1.8
    },
    {
        "x": 0.86,
        "y": 1.8
    },
    {
        "x": 0.87,
        "y": 1.8
    },
    {
        "x": 0.88,
        "y": 1.8
    },
    {
        "x": 0.89,
        "y": 1.8
    },
    {
        "x": 0.9,
        "y": 1.8
    },
    {
        "x": 0.91,
        "y": 1.8
    },
    {
        "x": 0.92,
        "y": 1.8
    },
    {
        "x": 0.93,
        "y": 1.8
    },
    {
        "x": 0.94,
        "y": 1.8
    },
    {
        "x": 0.95,
        "y": 1.8
    },
    {
        "x": 0.96,
        "y": 1.8
    },
    {
        "x": 0.97,
        "y": 1.8
    },
    {
        "x": 0.98,
        "y": 1.8
    },
    {
        "x": 0.99,
        "y": 1.8
    },
    {
        "x": 1,
        "y": 1.8
    },
    {
        "x": 1.01,
        "y": 1.8
    },
    {
        "x": 1.02,
        "y": 1.8
    },
    {
        "x": 1.03,
        "y": 1.8
    },
    {
        "x": 1.04,
        "y": 1.8
    },
    {
        "x": 1.05,
        "y": 1.8
    },
    {
        "x": 1.06,
        "y": 1.8
    },
    {
        "x": 1.07,
        "y": 1.809
    },
    {
        "x": 1.08,
        "y": 1.8
    },
    {
        "x": 1.09,
        "y": 1.809
    },
    {
        "x": 1.1,
        "y": 1.809
    },
    {
        "x": 1.11,
        "y": 1.8
    },
    {
        "x": 1.12,
        "y": 1.8
    },
    {
        "x": 1.13,
        "y": 1.809
    },
    {
        "x": 1.14,
        "y": 1.809
    },
    {
        "x": 1.15,
        "y": 1.809
    },
    {
        "x": 1.16,
        "y": 1.809
    },
    {
        "x": 1.17,
        "y": 1.809
    },
    {
        "x": 1.18,
        "y": 1.809
    },
    {
        "x": 1.19,
        "y": 1.809
    },
    {
        "x": 1.2,
        "y": 1.809
    },
    {
        "x": 1.21,
        "y": 1.809
    },
    {
        "x": 1.22,
        "y": 1.809
    },
    {
        "x": 1.23,
        "y": 1.809
    },
    {
        "x": 1.24,
        "y": 1.809
    },
    {
        "x": 1.25,
        "y": 1.809
    },
    {
        "x": 1.26,
        "y": 1.809
    },
    {
        "x": 1.27,
        "y": 1.809
    },
    {
        "x": 1.28,
        "y": 1.809
    },
    {
        "x": 1.29,
        "y": 1.809
    },
    {
        "x": 1.3,
        "y": 1.809
    },
    {
        "x": 1.31,
        "y": 1.809
    },
    {
        "x": 1.32,
        "y": 1.809
    },
    {
        "x": 1.33,
        "y": 1.809
    },
    {
        "x": 1.34,
        "y": 1.809
    },
    {
        "x": 1.35,
        "y": 1.809
    },
    {
        "x": 1.36,
        "y": 1.809
    },
    {
        "x": 1.37,
        "y": 1.809
    },
    {
        "x": 1.38,
        "y": 1.809
    },
    {
        "x": 1.39,
        "y": 1.809
    },
    {
        "x": 1.4,
        "y": 1.818
    },
    {
        "x": 1.41,
        "y": 1.809
    },
    {
        "x": 1.42,
        "y": 1.809
    },
    {
        "x": 1.43,
        "y": 1.818
    },
    {
        "x": 1.44,
        "y": 1.809
    },
    {
        "x": 1.45,
        "y": 1.818
    },
    {
        "x": 1.46,
        "y": 1.818
    },
    {
        "x": 1.47,
        "y": 1.818
    },
    {
        "x": 1.48,
        "y": 1.818
    },
    {
        "x": 1.49,
        "y": 1.818
    },
    {
        "x": 1.5,
        "y": 1.818
    },
    {
        "x": 1.51,
        "y": 1.809
    },
    {
        "x": 1.52,
        "y": 1.818
    },
    {
        "x": 1.53,
        "y": 1.818
    },
    {
        "x": 1.54,
        "y": 1.818
    },
    {
        "x": 1.55,
        "y": 1.809
    },
    {
        "x": 1.56,
        "y": 1.818
    },
    {
        "x": 1.57,
        "y": 1.818
    },
    {
        "x": 1.58,
        "y": 1.818
    },
    {
        "x": 1.59,
        "y": 1.818
    },
    {
        "x": 1.6,
        "y": 1.818
    },
    {
        "x": 1.61,
        "y": 1.818
    },
    {
        "x": 1.62,
        "y": 1.818
    },
    {
        "x": 1.63,
        "y": 1.818
    },
    {
        "x": 1.64,
        "y": 1.818
    },
    {
        "x": 1.65,
        "y": 1.818
    },
    {
        "x": 1.66,
        "y": 1.818
    },
    {
        "x": 1.67,
        "y": 1.818
    },
    {
        "x": 1.68,
        "y": 1.818
    },
    {
        "x": 1.69,
        "y": 1.818
    },
    {
        "x": 1.7,
        "y": 1.818
    },
    {
        "x": 1.71,
        "y": 1.818
    },
    {
        "x": 1.72,
        "y": 1.827
    },
    {
        "x": 1.73,
        "y": 1.818
    },
    {
        "x": 1.74,
        "y": 1.818
    },
    {
        "x": 1.75,
        "y": 1.818
    },
    {
        "x": 1.76,
        "y": 1.827
    },
    {
        "x": 1.77,
        "y": 1.827
    },
    {
        "x": 1.78,
        "y": 1.827
    },
    {
        "x": 1.79,
        "y": 1.827
    },
    {
        "x": 1.8,
        "y": 1.818
    },
    {
        "x": 1.81,
        "y": 1.827
    },
    {
        "x": 1.82,
        "y": 1.827
    },
    {
        "x": 1.83,
        "y": 1.827
    },
    {
        "x": 1.84,
        "y": 1.827
    },
    {
        "x": 1.85,
        "y": 1.827
    },
    {
        "x": 1.86,
        "y": 1.827
    },
    {
        "x": 1.87,
        "y": 1.827
    },
    {
        "x": 1.88,
        "y": 1.827
    },
    {
        "x": 1.89,
        "y": 1.827
    },
    {
        "x": 1.9,
        "y": 1.827
    },
    {
        "x": 1.91,
        "y": 1.827
    },
    {
        "x": 1.92,
        "y": 1.827
    },
    {
        "x": 1.93,
        "y": 1.827
    },
    {
        "x": 1.94,
        "y": 1.827
    },
    {
        "x": 1.95,
        "y": 1.827
    },
    {
        "x": 1.96,
        "y": 1.827
    },
    {
        "x": 1.97,
        "y": 1.836
    },
    {
        "x": 1.98,
        "y": 1.836
    },
    {
        "x": 1.99,
        "y": 1.836
    },
    {
        "x": 2,
        "y": 1.836
    },
    {
        "x": 2.01,
        "y": 1.836
    },
    {
        "x": 2.02,
        "y": 1.836
    },
    {
        "x": 2.03,
        "y": 1.836
    },
    {
        "x": 2.04,
        "y": 1.836
    },
    {
        "x": 2.05,
        "y": 1.836
    },
    {
        "x": 2.06,
        "y": 1.836
    },
    {
        "x": 2.07,
        "y": 1.836
    },
    {
        "x": 2.08,
        "y": 1.836
    },
    {
        "x": 2.09,
        "y": 1.836
    },
    {
        "x": 2.1,
        "y": 1.836
    },
    {
        "x": 2.11,
        "y": 1.836
    },
    {
        "x": 2.12,
        "y": 1.836
    },
    {
        "x": 2.13,
        "y": 1.845
    },
    {
        "x": 2.14,
        "y": 1.845
    },
    {
        "x": 2.15,
        "y": 1.845
    },
    {
        "x": 2.16,
        "y": 1.836
    },
    {
        "x": 2.17,
        "y": 1.845
    },
    {
        "x": 2.18,
        "y": 1.845
    },
    {
        "x": 2.19,
        "y": 1.845
    },
    {
        "x": 2.2,
        "y": 1.845
    },
    {
        "x": 2.21,
        "y": 1.845
    },
    {
        "x": 2.22,
        "y": 1.845
    },
    {
        "x": 2.23,
        "y": 1.845
    },
    {
        "x": 2.24,
        "y": 1.845
    },
    {
        "x": 2.25,
        "y": 1.845
    },
    {
        "x": 2.26,
        "y": 1.845
    },
    {
        "x": 2.27,
        "y": 1.845
    },
    {
        "x": 2.28,
        "y": 1.845
    },
    {
        "x": 2.29,
        "y": 1.854
    },
    {
        "x": 2.3,
        "y": 1.845
    },
    {
        "x": 2.31,
        "y": 1.845
    },
    {
        "x": 2.32,
        "y": 1.845
    },
    {
        "x": 2.33,
        "y": 1.854
    },
    {
        "x": 2.34,
        "y": 1.854
    },
    {
        "x": 2.35,
        "y": 1.854
    },
    {
        "x": 2.36,
        "y": 1.854
    },
    {
        "x": 2.37,
        "y": 1.854
    },
    {
        "x": 2.38,
        "y": 1.854
    },
    {
        "x": 2.39,
        "y": 1.854
    },
    {
        "x": 2.4,
        "y": 1.854
    },
    {
        "x": 2.41,
        "y": 1.854
    },
    {
        "x": 2.42,
        "y": 1.854
    },
    {
        "x": 2.43,
        "y": 1.854
    },
    {
        "x": 2.44,
        "y": 1.863
    },
    {
        "x": 2.45,
        "y": 1.863
    },
    {
        "x": 2.46,
        "y": 1.863
    },
    {
        "x": 2.47,
        "y": 1.863
    },
    {
        "x": 2.48,
        "y": 1.863
    },
    {
        "x": 2.49,
        "y": 1.863
    },
    {
        "x": 2.5,
        "y": 1.863
    },
    {
        "x": 2.51,
        "y": 1.863
    },
    {
        "x": 2.52,
        "y": 1.863
    },
    {
        "x": 2.53,
        "y": 1.863
    },
    {
        "x": 2.54,
        "y": 1.863
    },
    {
        "x": 2.55,
        "y": 1.863
    },
    {
        "x": 2.56,
        "y": 1.863
    },
    {
        "x": 2.57,
        "y": 1.872
    },
    {
        "x": 2.58,
        "y": 1.872
    },
    {
        "x": 2.59,
        "y": 1.872
    },
    {
        "x": 2.6,
        "y": 1.872
    },
    {
        "x": 2.61,
        "y": 1.872
    },
    {
        "x": 2.62,
        "y": 1.872
    },
    {
        "x": 2.63,
        "y": 1.872
    },
    {
        "x": 2.64,
        "y": 1.872
    },
    {
        "x": 2.65,
        "y": 1.872
    },
    {
        "x": 2.66,
        "y": 1.872
    },
    {
        "x": 2.67,
        "y": 1.881
    },
    {
        "x": 2.68,
        "y": 1.872
    },
    {
        "x": 2.69,
        "y": 1.881
    },
    {
        "x": 2.7,
        "y": 1.881
    },
    {
        "x": 2.71,
        "y": 1.881
    },
    {
        "x": 2.72,
        "y": 1.881
    },
    {
        "x": 2.73,
        "y": 1.881
    },
    {
        "x": 2.74,
        "y": 1.881
    },
    {
        "x": 2.75,
        "y": 1.881
    },
    {
        "x": 2.76,
        "y": 1.881
    },
    {
        "x": 2.77,
        "y": 1.881
    },
    {
        "x": 2.78,
        "y": 1.881
    },
    {
        "x": 2.79,
        "y": 1.89
    },
    {
        "x": 2.8,
        "y": 1.89
    },
    {
        "x": 2.81,
        "y": 1.89
    },
    {
        "x": 2.82,
        "y": 1.89
    },
    {
        "x": 2.83,
        "y": 1.89
    },
    {
        "x": 2.84,
        "y": 1.89
    },
    {
        "x": 2.85,
        "y": 1.899
    },
    {
        "x": 2.86,
        "y": 1.89
    },
    {
        "x": 2.87,
        "y": 1.899
    },
    {
        "x": 2.88,
        "y": 1.899
    },
    {
        "x": 2.89,
        "y": 1.899
    },
    {
        "x": 2.9,
        "y": 1.899
    },
    {
        "x": 2.91,
        "y": 1.899
    },
    {
        "x": 2.92,
        "y": 1.899
    },
    {
        "x": 2.93,
        "y": 1.899
    },
    {
        "x": 2.94,
        "y": 1.899
    },
    {
        "x": 2.95,
        "y": 1.899
    },
    {
        "x": 2.96,
        "y": 1.908
    },
    {
        "x": 2.97,
        "y": 1.908
    },
    {
        "x": 2.98,
        "y": 1.908
    },
    {
        "x": 2.99,
        "y": 1.908
    },
    {
        "x": 3,
        "y": 1.908
    },
    {
        "x": 3.01,
        "y": 1.908
    },
    {
        "x": 3.02,
        "y": 1.917
    },
    {
        "x": 3.03,
        "y": 1.917
    },
    {
        "x": 3.04,
        "y": 1.917
    },
    {
        "x": 3.05,
        "y": 1.917
    },
    {
        "x": 3.06,
        "y": 1.917
    },
    {
        "x": 3.07,
        "y": 1.917
    },
    {
        "x": 3.08,
        "y": 1.917
    },
    {
        "x": 3.09,
        "y": 1.917
    },
    {
        "x": 3.1,
        "y": 1.917
    },
    {
        "x": 3.11,
        "y": 1.926
    },
    {
        "x": 3.12,
        "y": 1.926
    },
    {
        "x": 3.13,
        "y": 1.926
    },
    {
        "x": 3.14,
        "y": 1.926
    },
    {
        "x": 3.15,
        "y": 1.926
    },
    {
        "x": 3.16,
        "y": 1.926
    },
    {
        "x": 3.17,
        "y": 1.926
    },
    {
        "x": 3.18,
        "y": 1.935
    },
    {
        "x": 3.19,
        "y": 1.935
    },
    {
        "x": 3.2,
        "y": 1.935
    },
    {
        "x": 3.21,
        "y": 1.935
    },
    {
        "x": 3.22,
        "y": 1.935
    },
    {
        "x": 3.23,
        "y": 1.935
    },
    {
        "x": 3.24,
        "y": 1.944
    },
    {
        "x": 3.25,
        "y": 1.944
    },
    {
        "x": 3.26,
        "y": 1.944
    },
    {
        "x": 3.27,
        "y": 1.944
    },
    {
        "x": 3.28,
        "y": 1.944
    },
    {
        "x": 3.29,
        "y": 1.944
    },
    {
        "x": 3.3,
        "y": 1.944
    },
    {
        "x": 3.31,
        "y": 1.944
    },
    {
        "x": 3.32,
        "y": 1.953
    },
    {
        "x": 3.33,
        "y": 1.953
    },
    {
        "x": 3.34,
        "y": 1.953
    },
    {
        "x": 3.35,
        "y": 1.953
    },
    {
        "x": 3.36,
        "y": 1.953
    },
    {
        "x": 3.37,
        "y": 1.953
    },
    {
        "x": 3.38,
        "y": 1.962
    },
    {
        "x": 3.39,
        "y": 1.962
    },
    {
        "x": 3.4,
        "y": 1.953
    },
    {
        "x": 3.41,
        "y": 1.962
    },
    {
        "x": 3.42,
        "y": 1.962
    },
    {
        "x": 3.43,
        "y": 1.962
    },
    {
        "x": 3.44,
        "y": 1.971
    },
    {
        "x": 3.45,
        "y": 1.971
    },
    {
        "x": 3.46,
        "y": 1.971
    },
    {
        "x": 3.47,
        "y": 1.971
    },
    {
        "x": 3.48,
        "y": 1.971
    },
    {
        "x": 3.49,
        "y": 1.971
    },
    {
        "x": 3.5,
        "y": 1.98
    },
    {
        "x": 3.51,
        "y": 1.98
    },
    {
        "x": 3.52,
        "y": 1.98
    },
    {
        "x": 3.53,
        "y": 1.98
    },
    {
        "x": 3.54,
        "y": 1.98
    },
    {
        "x": 3.55,
        "y": 1.989
    },
    {
        "x": 3.56,
        "y": 1.989
    },
    {
        "x": 3.57,
        "y": 1.989
    },
    {
        "x": 3.58,
        "y": 1.989
    },
    {
        "x": 3.59,
        "y": 1.989
    },
    {
        "x": 3.6,
        "y": 1.989
    },
    {
        "x": 3.61,
        "y": 1.989
    },
    {
        "x": 3.62,
        "y": 1.998
    },
    {
        "x": 3.63,
        "y": 1.998
    },
    {
        "x": 3.64,
        "y": 1.998
    },
    {
        "x": 3.65,
        "y": 1.998
    },
    {
        "x": 3.66,
        "y": 1.998
    },
    {
        "x": 3.67,
        "y": 2.007
    },
    {
        "x": 3.68,
        "y": 2.007
    },
    {
        "x": 3.69,
        "y": 2.007
    },
    {
        "x": 3.7,
        "y": 2.007
    },
    {
        "x": 3.71,
        "y": 2.007
    },
    {
        "x": 3.72,
        "y": 2.016
    },
    {
        "x": 3.73,
        "y": 2.016
    },
    {
        "x": 3.74,
        "y": 2.016
    },
    {
        "x": 3.75,
        "y": 2.025
    },
    {
        "x": 3.76,
        "y": 2.025
    },
    {
        "x": 3.77,
        "y": 2.025
    },
    {
        "x": 3.78,
        "y": 2.025
    },
    {
        "x": 3.79,
        "y": 2.025
    },
    {
        "x": 3.8,
        "y": 2.034
    },
    {
        "x": 3.81,
        "y": 2.034
    },
    {
        "x": 3.82,
        "y": 2.034
    },
    {
        "x": 3.83,
        "y": 2.034
    },
    {
        "x": 3.84,
        "y": 2.043
    },
    {
        "x": 3.85,
        "y": 2.043
    },
    {
        "x": 3.86,
        "y": 2.043
    },
    {
        "x": 3.87,
        "y": 2.052
    },
    {
        "x": 3.88,
        "y": 2.052
    },
    {
        "x": 3.89,
        "y": 2.052
    },
    {
        "x": 3.9,
        "y": 2.052
    },
    {
        "x": 3.91,
        "y": 2.052
    },
    {
        "x": 3.92,
        "y": 2.061
    },
    {
        "x": 3.93,
        "y": 2.061
    },
    {
        "x": 3.94,
        "y": 2.061
    },
    {
        "x": 3.95,
        "y": 2.07
    },
    {
        "x": 3.96,
        "y": 2.07
    },
    {
        "x": 3.97,
        "y": 2.07
    },
    {
        "x": 3.98,
        "y": 2.07
    },
    {
        "x": 3.99,
        "y": 2.079
    },
    {
        "x": 4,
        "y": 2.079
    },
    {
        "x": 4.01,
        "y": 2.079
    },
    {
        "x": 4.02,
        "y": 2.079
    },
    {
        "x": 4.03,
        "y": 2.088
    },
    {
        "x": 4.04,
        "y": 2.088
    },
    {
        "x": 4.05,
        "y": 2.088
    },
    {
        "x": 4.06,
        "y": 2.088
    },
    {
        "x": 4.07,
        "y": 2.097
    },
    {
        "x": 4.08,
        "y": 2.097
    },
    {
        "x": 4.09,
        "y": 2.097
    },
    {
        "x": 4.1,
        "y": 2.097
    },
    {
        "x": 4.11,
        "y": 2.106
    },
    {
        "x": 4.12,
        "y": 2.106
    },
    {
        "x": 4.13,
        "y": 2.106
    },
    {
        "x": 4.14,
        "y": 2.106
    },
    {
        "x": 4.15,
        "y": 2.115
    },
    {
        "x": 4.16,
        "y": 2.115
    },
    {
        "x": 4.17,
        "y": 2.115
    },
    {
        "x": 4.18,
        "y": 2.124
    },
    {
        "x": 4.19,
        "y": 2.124
    },
    {
        "x": 4.2,
        "y": 2.124
    },
    {
        "x": 4.21,
        "y": 2.133
    },
    {
        "x": 4.22,
        "y": 2.133
    },
    {
        "x": 4.23,
        "y": 2.142
    },
    {
        "x": 4.24,
        "y": 2.142
    },
    {
        "x": 4.25,
        "y": 2.142
    },
    {
        "x": 4.26,
        "y": 2.142
    },
    {
        "x": 4.27,
        "y": 2.142
    },
    {
        "x": 4.28,
        "y": 2.151
    },
    {
        "x": 4.29,
        "y": 2.151
    },
    {
        "x": 4.3,
        "y": 2.16
    },
    {
        "x": 4.31,
        "y": 2.16
    },
    {
        "x": 4.32,
        "y": 2.16
    },
    {
        "x": 4.33,
        "y": 2.169
    },
    {
        "x": 4.34,
        "y": 2.169
    },
    {
        "x": 4.35,
        "y": 2.169
    },
    {
        "x": 4.36,
        "y": 2.169
    },
    {
        "x": 4.37,
        "y": 2.178
    },
    {
        "x": 4.38,
        "y": 2.178
    },
    {
        "x": 4.39,
        "y": 2.178
    },
    {
        "x": 4.4,
        "y": 2.187
    },
    {
        "x": 4.41,
        "y": 2.187
    },
    {
        "x": 4.42,
        "y": 2.187
    },
    {
        "x": 4.43,
        "y": 2.196
    },
    {
        "x": 4.44,
        "y": 2.196
    },
    {
        "x": 4.45,
        "y": 2.196
    },
    {
        "x": 4.46,
        "y": 2.205
    },
    {
        "x": 4.47,
        "y": 2.205
    },
    {
        "x": 4.48,
        "y": 2.214
    },
    {
        "x": 4.49,
        "y": 2.214
    },
    {
        "x": 4.5,
        "y": 2.214
    },
    {
        "x": 4.51,
        "y": 2.223
    },
    {
        "x": 4.52,
        "y": 2.223
    },
    {
        "x": 4.53,
        "y": 2.232
    },
    {
        "x": 4.54,
        "y": 2.232
    },
    {
        "x": 4.55,
        "y": 2.232
    },
    {
        "x": 4.56,
        "y": 2.232
    },
    {
        "x": 4.57,
        "y": 2.241
    },
    {
        "x": 4.58,
        "y": 2.241
    },
    {
        "x": 4.59,
        "y": 2.25
    },
    {
        "x": 4.6,
        "y": 2.25
    },
    {
        "x": 4.61,
        "y": 2.25
    },
    {
        "x": 4.62,
        "y": 2.259
    },
    {
        "x": 4.63,
        "y": 2.259
    },
    {
        "x": 4.64,
        "y": 2.259
    },
    {
        "x": 4.65,
        "y": 2.268
    },
    {
        "x": 4.66,
        "y": 2.277
    },
    {
        "x": 4.67,
        "y": 2.277
    },
    {
        "x": 4.68,
        "y": 2.277
    },
    {
        "x": 4.69,
        "y": 2.286
    },
    {
        "x": 4.7,
        "y": 2.295
    },
    {
        "x": 4.71,
        "y": 2.295
    },
    {
        "x": 4.72,
        "y": 2.295
    },
    {
        "x": 4.73,
        "y": 2.304
    },
    {
        "x": 4.74,
        "y": 2.304
    },
    {
        "x": 4.75,
        "y": 2.304
    },
    {
        "x": 4.76,
        "y": 2.313
    },
    {
        "x": 4.77,
        "y": 2.313
    },
    {
        "x": 4.78,
        "y": 2.322
    },
    {
        "x": 4.79,
        "y": 2.322
    },
    {
        "x": 4.8,
        "y": 2.322
    },
    {
        "x": 4.81,
        "y": 2.331
    },
    {
        "x": 4.82,
        "y": 2.34
    },
    {
        "x": 4.83,
        "y": 2.34
    },
    {
        "x": 4.84,
        "y": 2.349
    },
    {
        "x": 4.85,
        "y": 2.349
    },
    {
        "x": 4.86,
        "y": 2.349
    },
    {
        "x": 4.87,
        "y": 2.358
    },
    {
        "x": 4.88,
        "y": 2.358
    },
    {
        "x": 4.89,
        "y": 2.367
    },
    {
        "x": 4.9,
        "y": 2.376
    },
    {
        "x": 4.91,
        "y": 2.376
    },
    {
        "x": 4.92,
        "y": 2.376
    },
    {
        "x": 4.93,
        "y": 2.385
    },
    {
        "x": 4.94,
        "y": 2.394
    },
    {
        "x": 4.95,
        "y": 2.394
    },
    {
        "x": 4.96,
        "y": 2.403
    },
    {
        "x": 4.97,
        "y": 2.403
    },
    {
        "x": 4.98,
        "y": 2.403
    },
    {
        "x": 4.99,
        "y": 2.412
    },
    {
        "x": 5,
        "y": 2.421
    },
    {
        "x": 5.01,
        "y": 2.421
    },
    {
        "x": 5.02,
        "y": 2.43
    },
    {
        "x": 5.03,
        "y": 2.43
    },
    {
        "x": 5.04,
        "y": 2.439
    },
    {
        "x": 5.05,
        "y": 2.439
    },
    {
        "x": 5.06,
        "y": 2.448
    },
    {
        "x": 5.07,
        "y": 2.457
    },
    {
        "x": 5.08,
        "y": 2.457
    },
    {
        "x": 5.09,
        "y": 2.466
    },
    {
        "x": 5.1,
        "y": 2.466
    },
    {
        "x": 5.11,
        "y": 2.475
    },
    {
        "x": 5.12,
        "y": 2.475
    },
    {
        "x": 5.13,
        "y": 2.484
    },
    {
        "x": 5.14,
        "y": 2.484
    },
    {
        "x": 5.15,
        "y": 2.493
    },
    {
        "x": 5.16,
        "y": 2.493
    },
    {
        "x": 5.17,
        "y": 2.502
    },
    {
        "x": 5.18,
        "y": 2.511
    },
    {
        "x": 5.19,
        "y": 2.52
    },
    {
        "x": 5.2,
        "y": 2.52
    },
    {
        "x": 5.21,
        "y": 2.529
    },
    {
        "x": 5.22,
        "y": 2.529
    },
    {
        "x": 5.23,
        "y": 2.538
    },
    {
        "x": 5.24,
        "y": 2.538
    },
    {
        "x": 5.25,
        "y": 2.547
    },
    {
        "x": 5.26,
        "y": 2.556
    },
    {
        "x": 5.27,
        "y": 2.556
    },
    {
        "x": 5.28,
        "y": 2.565
    },
    {
        "x": 5.29,
        "y": 2.574
    },
    {
        "x": 5.3,
        "y": 2.574
    },
    {
        "x": 5.31,
        "y": 2.583
    },
    {
        "x": 5.32,
        "y": 2.592
    },
    {
        "x": 5.33,
        "y": 2.592
    },
    {
        "x": 5.34,
        "y": 2.601
    },
    {
        "x": 5.35,
        "y": 2.61
    },
    {
        "x": 5.36,
        "y": 2.61
    },
    {
        "x": 5.37,
        "y": 2.619
    },
    {
        "x": 5.38,
        "y": 2.619
    },
    {
        "x": 5.39,
        "y": 2.628
    },
    {
        "x": 5.4,
        "y": 2.637
    },
    {
        "x": 5.41,
        "y": 2.646
    },
    {
        "x": 5.42,
        "y": 2.655
    },
    {
        "x": 5.43,
        "y": 2.655
    },
    {
        "x": 5.44,
        "y": 2.664
    },
    {
        "x": 5.45,
        "y": 2.673
    },
    {
        "x": 5.46,
        "y": 2.673
    },
    {
        "x": 5.47,
        "y": 2.682
    },
    {
        "x": 5.48,
        "y": 2.691
    },
    {
        "x": 5.49,
        "y": 2.7
    },
    {
        "x": 5.5,
        "y": 2.7
    },
    {
        "x": 5.51,
        "y": 2.709
    },
    {
        "x": 5.52,
        "y": 2.718
    },
    {
        "x": 5.53,
        "y": 2.727
    },
    {
        "x": 5.54,
        "y": 2.727
    },
    {
        "x": 5.55,
        "y": 2.736
    },
    {
        "x": 5.56,
        "y": 2.745
    },
    {
        "x": 5.57,
        "y": 2.754
    },
    {
        "x": 5.58,
        "y": 2.754
    },
    {
        "x": 5.59,
        "y": 2.763
    },
    {
        "x": 5.6,
        "y": 2.772
    },
    {
        "x": 5.61,
        "y": 2.781
    },
    {
        "x": 5.62,
        "y": 2.781
    },
    {
        "x": 5.63,
        "y": 2.79
    },
    {
        "x": 5.64,
        "y": 2.799
    },
    {
        "x": 5.65,
        "y": 2.808
    },
    {
        "x": 5.66,
        "y": 2.817
    },
    {
        "x": 5.67,
        "y": 2.817
    },
    {
        "x": 5.68,
        "y": 2.826
    },
    {
        "x": 5.69,
        "y": 2.835
    },
    {
        "x": 5.7,
        "y": 2.844
    },
    {
        "x": 5.71,
        "y": 2.853
    },
    {
        "x": 5.72,
        "y": 2.862
    },
    {
        "x": 5.73,
        "y": 2.871
    },
    {
        "x": 5.74,
        "y": 2.88
    },
    {
        "x": 5.75,
        "y": 2.88
    },
    {
        "x": 5.76,
        "y": 2.889
    },
    {
        "x": 5.77,
        "y": 2.898
    },
    {
        "x": 5.78,
        "y": 2.907
    },
    {
        "x": 5.79,
        "y": 2.916
    },
    {
        "x": 5.8,
        "y": 2.925
    },
    {
        "x": 5.81,
        "y": 2.934
    },
    {
        "x": 5.82,
        "y": 2.943
    },
    {
        "x": 5.83,
        "y": 2.952
    },
    {
        "x": 5.84,
        "y": 2.952
    },
    {
        "x": 5.85,
        "y": 2.97
    },
    {
        "x": 5.86,
        "y": 2.97
    },
    {
        "x": 5.87,
        "y": 2.979
    },
    {
        "x": 5.88,
        "y": 2.988
    },
    {
        "x": 5.89,
        "y": 2.997
    },
    {
        "x": 5.9,
        "y": 3.006
    },
    {
        "x": 5.91,
        "y": 3.015
    },
    {
        "x": 5.92,
        "y": 3.024
    },
    {
        "x": 5.93,
        "y": 3.033
    },
    {
        "x": 5.94,
        "y": 3.042
    },
    {
        "x": 5.95,
        "y": 3.051
    },
    {
        "x": 5.96,
        "y": 3.06
    },
    {
        "x": 5.97,
        "y": 3.069
    },
    {
        "x": 5.98,
        "y": 3.078
    },
    {
        "x": 5.99,
        "y": 3.087
    },
    {
        "x": 6,
        "y": 3.096
    },
    {
        "x": 6.01,
        "y": 3.105
    },
    {
        "x": 6.02,
        "y": 3.114
    },
    {
        "x": 6.03,
        "y": 3.123
    },
    {
        "x": 6.04,
        "y": 3.132
    },
    {
        "x": 6.05,
        "y": 3.141
    },
    {
        "x": 6.06,
        "y": 3.15
    },
    {
        "x": 6.07,
        "y": 3.159
    },
    {
        "x": 6.08,
        "y": 3.168
    },
    {
        "x": 6.09,
        "y": 3.186
    },
    {
        "x": 6.1,
        "y": 3.195
    },
    {
        "x": 6.11,
        "y": 3.195
    },
    {
        "x": 6.12,
        "y": 3.213
    },
    {
        "x": 6.13,
        "y": 3.222
    },
    {
        "x": 6.14,
        "y": 3.231
    },
    {
        "x": 6.15,
        "y": 3.24
    },
    {
        "x": 6.16,
        "y": 3.249
    },
    {
        "x": 6.17,
        "y": 3.258
    },
    {
        "x": 6.18,
        "y": 3.276
    },
    {
        "x": 6.19,
        "y": 3.285
    },
    {
        "x": 6.2,
        "y": 3.294
    },
    {
        "x": 6.21,
        "y": 3.303
    },
    {
        "x": 6.22,
        "y": 3.312
    },
    {
        "x": 6.23,
        "y": 3.321
    },
    {
        "x": 6.24,
        "y": 3.33
    },
    {
        "x": 6.25,
        "y": 3.348
    },
    {
        "x": 6.26,
        "y": 3.357
    },
    {
        "x": 6.27,
        "y": 3.366
    },
    {
        "x": 6.28,
        "y": 3.375
    },
    {
        "x": 6.29,
        "y": 3.393
    },
    {
        "x": 6.3,
        "y": 3.402
    },
    {
        "x": 6.31,
        "y": 3.411
    },
    {
        "x": 6.32,
        "y": 3.42
    },
    {
        "x": 6.33,
        "y": 3.429
    },
    {
        "x": 6.34,
        "y": 3.447
    },
    {
        "x": 6.35,
        "y": 3.456
    },
    {
        "x": 6.36,
        "y": 3.474
    },
    {
        "x": 6.37,
        "y": 3.483
    },
    {
        "x": 6.38,
        "y": 3.492
    },
    {
        "x": 6.39,
        "y": 3.501
    },
    {
        "x": 6.4,
        "y": 3.519
    },
    {
        "x": 6.41,
        "y": 3.528
    },
    {
        "x": 6.42,
        "y": 3.537
    },
    {
        "x": 6.43,
        "y": 3.555
    },
    {
        "x": 6.44,
        "y": 3.564
    },
    {
        "x": 6.45,
        "y": 3.573
    },
    {
        "x": 6.46,
        "y": 3.582
    },
    {
        "x": 6.47,
        "y": 3.6
    },
    {
        "x": 6.48,
        "y": 3.609
    },
    {
        "x": 6.49,
        "y": 3.618
    },
    {
        "x": 6.5,
        "y": 3.636
    },
    {
        "x": 6.51,
        "y": 3.645
    },
    {
        "x": 6.52,
        "y": 3.663
    },
    {
        "x": 6.53,
        "y": 3.672
    },
    {
        "x": 6.54,
        "y": 3.69
    },
    {
        "x": 6.55,
        "y": 3.699
    },
    {
        "x": 6.56,
        "y": 3.708
    },
    {
        "x": 6.57,
        "y": 3.726
    },
    {
        "x": 6.58,
        "y": 3.735
    },
    {
        "x": 6.59,
        "y": 3.744
    },
    {
        "x": 6.6,
        "y": 3.762
    },
    {
        "x": 6.61,
        "y": 3.771
    },
    {
        "x": 6.62,
        "y": 3.789
    },
    {
        "x": 6.63,
        "y": 3.807
    },
    {
        "x": 6.64,
        "y": 3.816
    },
    {
        "x": 6.65,
        "y": 3.825
    },
    {
        "x": 6.66,
        "y": 3.843
    },
    {
        "x": 6.67,
        "y": 3.852
    },
    {
        "x": 6.68,
        "y": 3.87
    },
    {
        "x": 6.69,
        "y": 3.879
    },
    {
        "x": 6.7,
        "y": 3.897
    },
    {
        "x": 6.71,
        "y": 3.915
    },
    {
        "x": 6.72,
        "y": 3.924
    },
    {
        "x": 6.73,
        "y": 3.942
    },
    {
        "x": 6.74,
        "y": 3.951
    },
    {
        "x": 6.75,
        "y": 3.969
    },
    {
        "x": 6.76,
        "y": 3.987
    },
    {
        "x": 6.77,
        "y": 3.996
    },
    {
        "x": 6.78,
        "y": 4.014
    },
    {
        "x": 6.79,
        "y": 4.023
    },
    {
        "x": 6.8,
        "y": 4.041
    },
    {
        "x": 6.81,
        "y": 4.059
    },
    {
        "x": 6.82,
        "y": 4.068
    },
    {
        "x": 6.83,
        "y": 4.086
    },
    {
        "x": 6.84,
        "y": 4.095
    },
    {
        "x": 6.85,
        "y": 4.113
    },
    {
        "x": 6.86,
        "y": 4.131
    },
    {
        "x": 6.87,
        "y": 4.14
    },
    {
        "x": 6.88,
        "y": 4.158
    },
    {
        "x": 6.89,
        "y": 4.176
    },
    {
        "x": 6.9,
        "y": 4.194
    },
    {
        "x": 6.91,
        "y": 4.203
    },
    {
        "x": 6.92,
        "y": 4.221
    },
    {
        "x": 6.93,
        "y": 4.239
    },
    {
        "x": 6.94,
        "y": 4.248
    },
    {
        "x": 6.95,
        "y": 4.266
    },
    {
        "x": 6.96,
        "y": 4.275
    },
    {
        "x": 6.97,
        "y": 4.293
    },
    {
        "x": 6.98,
        "y": 4.311
    },
    {
        "x": 6.99,
        "y": 4.329
    },
    {
        "x": 7,
        "y": 4.347
    },
    {
        "x": 7.01,
        "y": 4.365
    },
    {
        "x": 7.02,
        "y": 4.374
    },
    {
        "x": 7.03,
        "y": 4.392
    },
    {
        "x": 7.04,
        "y": 4.41
    },
    {
        "x": 7.05,
        "y": 4.428
    },
    {
        "x": 7.06,
        "y": 4.446
    },
    {
        "x": 7.07,
        "y": 4.464
    },
    {
        "x": 7.08,
        "y": 4.473
    },
    {
        "x": 7.09,
        "y": 4.491
    },
    {
        "x": 7.1,
        "y": 4.509
    },
    {
        "x": 7.11,
        "y": 4.527
    },
    {
        "x": 7.12,
        "y": 4.545
    },
    {
        "x": 7.13,
        "y": 4.563
    },
    {
        "x": 7.14,
        "y": 4.572
    },
    {
        "x": 7.15,
        "y": 4.59
    },
    {
        "x": 7.16,
        "y": 4.608
    },
    {
        "x": 7.17,
        "y": 4.626
    },
    {
        "x": 7.18,
        "y": 4.644
    },
    {
        "x": 7.19,
        "y": 4.662
    },
    {
        "x": 7.2,
        "y": 4.68
    },
    {
        "x": 7.21,
        "y": 4.698
    },
    {
        "x": 7.22,
        "y": 4.716
    },
    {
        "x": 7.23,
        "y": 4.734
    },
    {
        "x": 7.24,
        "y": 4.752
    },
    {
        "x": 7.25,
        "y": 4.77
    },
    {
        "x": 7.26,
        "y": 4.788
    },
    {
        "x": 7.27,
        "y": 4.806
    },
    {
        "x": 7.28,
        "y": 4.824
    },
    {
        "x": 7.29,
        "y": 4.842
    },
    {
        "x": 7.3,
        "y": 4.86
    },
    {
        "x": 7.31,
        "y": 4.878
    },
    {
        "x": 7.32,
        "y": 4.896
    },
    {
        "x": 7.33,
        "y": 4.914
    },
    {
        "x": 7.34,
        "y": 4.932
    },
    {
        "x": 7.35,
        "y": 4.95
    },
    {
        "x": 7.36,
        "y": 4.968
    },
    {
        "x": 7.37,
        "y": 4.995
    },
    {
        "x": 7.38,
        "y": 5.013
    },
    {
        "x": 7.39,
        "y": 5.031
    },
    {
        "x": 7.4,
        "y": 5.049
    },
    {
        "x": 7.41,
        "y": 5.067
    },
    {
        "x": 7.42,
        "y": 5.085
    },
    {
        "x": 7.43,
        "y": 5.103
    },
    {
        "x": 7.44,
        "y": 5.121
    },
    {
        "x": 7.45,
        "y": 5.139
    },
    {
        "x": 7.46,
        "y": 5.166
    },
    {
        "x": 7.47,
        "y": 5.184
    },
    {
        "x": 7.48,
        "y": 5.202
    },
    {
        "x": 7.49,
        "y": 5.229
    },
    {
        "x": 7.5,
        "y": 5.238
    },
    {
        "x": 7.51,
        "y": 5.265
    },
    {
        "x": 7.52,
        "y": 5.283
    },
    {
        "x": 7.53,
        "y": 5.31
    },
    {
        "x": 7.54,
        "y": 5.319
    },
    {
        "x": 7.55,
        "y": 5.346
    },
    {
        "x": 7.56,
        "y": 5.364
    },
    {
        "x": 7.57,
        "y": 5.382
    },
    {
        "x": 7.58,
        "y": 5.4
    },
    {
        "x": 7.59,
        "y": 5.427
    },
    {
        "x": 7.6,
        "y": 5.445
    },
    {
        "x": 7.61,
        "y": 5.463
    },
    {
        "x": 7.62,
        "y": 5.49
    },
    {
        "x": 7.63,
        "y": 5.508
    },
    {
        "x": 7.64,
        "y": 5.526
    },
    {
        "x": 7.65,
        "y": 5.544
    },
    {
        "x": 7.66,
        "y": 5.571
    },
    {
        "x": 7.67,
        "y": 5.589
    },
    {
        "x": 7.68,
        "y": 5.607
    },
    {
        "x": 7.69,
        "y": 5.634
    },
    {
        "x": 7.7,
        "y": 5.652
    },
    {
        "x": 7.71,
        "y": 5.679
    },
    {
        "x": 7.72,
        "y": 5.697
    },
    {
        "x": 7.73,
        "y": 5.715
    },
    {
        "x": 7.74,
        "y": 5.742
    },
    {
        "x": 7.75,
        "y": 5.76
    },
    {
        "x": 7.76,
        "y": 5.778
    },
    {
        "x": 7.77,
        "y": 5.805
    },
    {
        "x": 7.78,
        "y": 5.823
    },
    {
        "x": 7.79,
        "y": 5.85
    },
    {
        "x": 7.8,
        "y": 5.868
    },
    {
        "x": 7.81,
        "y": 5.895
    },
    {
        "x": 7.82,
        "y": 5.913
    },
    {
        "x": 7.83,
        "y": 5.94
    },
    {
        "x": 7.84,
        "y": 5.958
    },
    {
        "x": 7.85,
        "y": 5.976
    },
    {
        "x": 7.86,
        "y": 6.003
    },
    {
        "x": 7.87,
        "y": 6.03
    },
    {
        "x": 7.88,
        "y": 6.048
    },
    {
        "x": 7.89,
        "y": 6.066
    },
    {
        "x": 7.9,
        "y": 6.093
    },
    {
        "x": 7.91,
        "y": 6.111
    },
    {
        "x": 7.92,
        "y": 6.138
    },
    {
        "x": 7.93,
        "y": 6.156
    },
    {
        "x": 7.94,
        "y": 6.183
    },
    {
        "x": 7.95,
        "y": 6.201
    },
    {
        "x": 7.96,
        "y": 6.228
    },
    {
        "x": 7.97,
        "y": 6.255
    },
    {
        "x": 7.98,
        "y": 6.273
    },
    {
        "x": 7.99,
        "y": 6.3
    },
    {
        "x": 8,
        "y": 6.318
    },
    {
        "x": 8.01,
        "y": 6.345
    },
    {
        "x": 8.02,
        "y": 6.363
    },
    {
        "x": 8.03,
        "y": 6.39
    },
    {
        "x": 8.04,
        "y": 6.408
    },
    {
        "x": 8.05,
        "y": 6.435
    },
    {
        "x": 8.06,
        "y": 6.462
    },
    {
        "x": 8.07,
        "y": 6.48
    },
    {
        "x": 8.08,
        "y": 6.507
    },
    {
        "x": 8.09,
        "y": 6.534
    },
    {
        "x": 8.1,
        "y": 6.552
    },
    {
        "x": 8.11,
        "y": 6.579
    },
    {
        "x": 8.12,
        "y": 6.606
    },
    {
        "x": 8.13,
        "y": 6.624
    },
    {
        "x": 8.14,
        "y": 6.651
    },
    {
        "x": 8.15,
        "y": 6.678
    },
    {
        "x": 8.16,
        "y": 6.696
    },
    {
        "x": 8.17,
        "y": 6.723
    },
    {
        "x": 8.18,
        "y": 6.75
    },
    {
        "x": 8.19,
        "y": 6.768
    },
    {
        "x": 8.2,
        "y": 6.795
    },
    {
        "x": 8.21,
        "y": 6.822
    },
    {
        "x": 8.22,
        "y": 6.84
    },
    {
        "x": 8.23,
        "y": 6.867
    },
    {
        "x": 8.24,
        "y": 6.894
    },
    {
        "x": 8.25,
        "y": 6.921
    },
    {
        "x": 8.26,
        "y": 6.948
    },
    {
        "x": 8.27,
        "y": 6.966
    },
    {
        "x": 8.28,
        "y": 6.993
    },
    {
        "x": 8.29,
        "y": 7.011
    },
    {
        "x": 8.3,
        "y": 7.038
    },
    {
        "x": 8.31,
        "y": 7.065
    },
    {
        "x": 8.32,
        "y": 7.092
    },
    {
        "x": 8.33,
        "y": 7.119
    },
    {
        "x": 8.34,
        "y": 7.137
    },
    {
        "x": 8.35,
        "y": 7.164
    },
    {
        "x": 8.36,
        "y": 7.191
    },
    {
        "x": 8.37,
        "y": 7.218
    },
    {
        "x": 8.38,
        "y": 7.236
    },
    {
        "x": 8.39,
        "y": 7.263
    },
    {
        "x": 8.4,
        "y": 7.29
    },
    {
        "x": 8.41,
        "y": 7.317
    },
    {
        "x": 8.42,
        "y": 7.344
    },
    {
        "x": 8.43,
        "y": 7.371
    },
    {
        "x": 8.44,
        "y": 7.389
    },
    {
        "x": 8.45,
        "y": 7.416
    },
    {
        "x": 8.46,
        "y": 7.443
    },
    {
        "x": 8.47,
        "y": 7.47
    },
    {
        "x": 8.48,
        "y": 7.497
    },
    {
        "x": 8.49,
        "y": 7.524
    },
    {
        "x": 8.5,
        "y": 7.551
    },
    {
        "x": 8.51,
        "y": 7.569
    },
    {
        "x": 8.52,
        "y": 7.605
    },
    {
        "x": 8.53,
        "y": 7.623
    },
    {
        "x": 8.54,
        "y": 7.65
    },
    {
        "x": 8.55,
        "y": 7.677
    },
    {
        "x": 8.56,
        "y": 7.704
    },
    {
        "x": 8.57,
        "y": 7.731
    },
    {
        "x": 8.58,
        "y": 7.758
    },
    {
        "x": 8.59,
        "y": 7.785
    },
    {
        "x": 8.6,
        "y": 7.812
    },
    {
        "x": 8.61,
        "y": 7.83
    },
    {
        "x": 8.62,
        "y": 7.866
    },
    {
        "x": 8.63,
        "y": 7.884
    },
    {
        "x": 8.64,
        "y": 7.911
    },
    {
        "x": 8.65,
        "y": 7.947
    },
    {
        "x": 8.66,
        "y": 7.965
    },
    {
        "x": 8.67,
        "y": 8.001
    },
    {
        "x": 8.68,
        "y": 8.019
    },
    {
        "x": 8.69,
        "y": 8.046
    },
    {
        "x": 8.7,
        "y": 8.073
    },
    {
        "x": 8.71,
        "y": 8.1
    },
    {
        "x": 8.72,
        "y": 8.127
    },
    {
        "x": 8.73,
        "y": 8.154
    },
    {
        "x": 8.74,
        "y": 8.181
    },
    {
        "x": 8.75,
        "y": 8.208
    },
    {
        "x": 8.76,
        "y": 8.235
    },
    {
        "x": 8.77,
        "y": 8.262
    },
    {
        "x": 8.78,
        "y": 8.289
    },
    {
        "x": 8.79,
        "y": 8.316
    },
    {
        "x": 8.8,
        "y": 8.343
    },
    {
        "x": 8.81,
        "y": 8.37
    },
    {
        "x": 8.82,
        "y": 8.397
    },
    {
        "x": 8.83,
        "y": 8.424
    },
    {
        "x": 8.84,
        "y": 8.451
    },
    {
        "x": 8.85,
        "y": 8.478
    },
    {
        "x": 8.86,
        "y": 8.505
    },
    {
        "x": 8.87,
        "y": 8.532
    },
    {
        "x": 8.88,
        "y": 8.559
    },
    {
        "x": 8.89,
        "y": 8.586
    },
    {
        "x": 8.9,
        "y": 8.613
    },
    {
        "x": 8.91,
        "y": 8.64
    },
    {
        "x": 8.92,
        "y": 8.667
    },
    {
        "x": 8.93,
        "y": 8.694
    },
    {
        "x": 8.94,
        "y": 8.721
    },
    {
        "x": 8.95,
        "y": 8.757
    },
    {
        "x": 8.96,
        "y": 8.784
    },
    {
        "x": 8.97,
        "y": 8.811
    },
    {
        "x": 8.98,
        "y": 8.838
    },
    {
        "x": 8.99,
        "y": 8.865
    },
    {
        "x": 9,
        "y": 8.892
    },
    {
        "x": 9.01,
        "y": 8.919
    },
    {
        "x": 9.02,
        "y": 8.946
    },
    {
        "x": 9.03,
        "y": 8.973
    },
    {
        "x": 9.04,
        "y": 9
    },
    {
        "x": 9.05,
        "y": 9.027
    },
    {
        "x": 9.06,
        "y": 9.054
    },
    {
        "x": 9.07,
        "y": 9.09
    },
    {
        "x": 9.08,
        "y": 9.108
    },
    {
        "x": 9.09,
        "y": 9.144
    },
    {
        "x": 9.1,
        "y": 9.171
    },
    {
        "x": 9.11,
        "y": 9.198
    },
    {
        "x": 9.12,
        "y": 9.225
    },
    {
        "x": 9.13,
        "y": 9.252
    },
    {
        "x": 9.14,
        "y": 9.279
    },
    {
        "x": 9.15,
        "y": 9.306
    },
    {
        "x": 9.16,
        "y": 9.333
    },
    {
        "x": 9.17,
        "y": 9.36
    },
    {
        "x": 9.18,
        "y": 9.387
    },
    {
        "x": 9.19,
        "y": 9.414
    },
    {
        "x": 9.2,
        "y": 9.441
    },
    {
        "x": 9.21,
        "y": 9.468
    },
    {
        "x": 9.22,
        "y": 9.495
    },
    {
        "x": 9.23,
        "y": 9.522
    },
    {
        "x": 9.24,
        "y": 9.558
    },
    {
        "x": 9.25,
        "y": 9.585
    },
    {
        "x": 9.26,
        "y": 9.612
    },
    {
        "x": 9.27,
        "y": 9.639
    },
    {
        "x": 9.28,
        "y": 9.666
    },
    {
        "x": 9.29,
        "y": 9.693
    },
    {
        "x": 9.3,
        "y": 9.72
    },
    {
        "x": 9.31,
        "y": 9.747
    },
    {
        "x": 9.32,
        "y": 9.774
    },
    {
        "x": 9.33,
        "y": 9.801
    },
    {
        "x": 9.34,
        "y": 9.828
    },
    {
        "x": 9.35,
        "y": 9.855
    },
    {
        "x": 9.36,
        "y": 9.882
    },
    {
        "x": 9.37,
        "y": 9.918
    },
    {
        "x": 9.38,
        "y": 9.945
    },
    {
        "x": 9.39,
        "y": 9.972
    },
    {
        "x": 9.4,
        "y": 9.999
    },
    {
        "x": 9.41,
        "y": 10.026
    },
    {
        "x": 9.42,
        "y": 10.053
    },
    {
        "x": 9.43,
        "y": 10.08
    },
    {
        "x": 9.44,
        "y": 10.107
    },
    {
        "x": 9.45,
        "y": 10.134
    },
    {
        "x": 9.46,
        "y": 10.161
    },
    {
        "x": 9.47,
        "y": 10.188
    },
    {
        "x": 9.48,
        "y": 10.215
    },
    {
        "x": 9.49,
        "y": 10.242
    },
    {
        "x": 9.5,
        "y": 10.269
    },
    {
        "x": 9.51,
        "y": 10.305
    },
    {
        "x": 9.52,
        "y": 10.332
    },
    {
        "x": 9.53,
        "y": 10.35
    },
    {
        "x": 9.54,
        "y": 10.377
    },
    {
        "x": 9.55,
        "y": 10.404
    },
    {
        "x": 9.56,
        "y": 10.44
    },
    {
        "x": 9.57,
        "y": 10.458
    },
    {
        "x": 9.58,
        "y": 10.494
    },
    {
        "x": 9.59,
        "y": 10.521
    },
    {
        "x": 9.6,
        "y": 10.548
    },
    {
        "x": 9.61,
        "y": 10.575
    },
    {
        "x": 9.62,
        "y": 10.602
    },
    {
        "x": 9.63,
        "y": 10.629
    },
    {
        "x": 9.64,
        "y": 10.656
    },
    {
        "x": 9.65,
        "y": 10.683
    },
    {
        "x": 9.66,
        "y": 10.71
    },
    {
        "x": 9.67,
        "y": 10.737
    },
    {
        "x": 9.68,
        "y": 10.764
    },
    {
        "x": 9.69,
        "y": 10.791
    },
    {
        "x": 9.7,
        "y": 10.818
    },
    {
        "x": 9.71,
        "y": 10.845
    },
    {
        "x": 9.72,
        "y": 10.872
    },
    {
        "x": 9.73,
        "y": 10.899
    },
    {
        "x": 9.74,
        "y": 10.926
    },
    {
        "x": 9.75,
        "y": 10.953
    },
    {
        "x": 9.76,
        "y": 10.98
    },
    {
        "x": 9.77,
        "y": 11.007
    },
    {
        "x": 9.78,
        "y": 11.034
    },
    {
        "x": 9.79,
        "y": 11.061
    },
    {
        "x": 9.8,
        "y": 11.088
    },
    {
        "x": 9.81,
        "y": 11.115
    },
    {
        "x": 9.82,
        "y": 11.142
    },
    {
        "x": 9.83,
        "y": 11.169
    },
    {
        "x": 9.84,
        "y": 11.196
    },
    {
        "x": 9.85,
        "y": 11.214
    },
    {
        "x": 9.86,
        "y": 11.241
    },
    {
        "x": 9.87,
        "y": 11.268
    },
    {
        "x": 9.88,
        "y": 11.295
    },
    {
        "x": 9.89,
        "y": 11.322
    },
    {
        "x": 9.9,
        "y": 11.349
    },
    {
        "x": 9.91,
        "y": 11.376
    },
    {
        "x": 9.92,
        "y": 11.403
    },
    {
        "x": 9.93,
        "y": 11.43
    },
    {
        "x": 9.94,
        "y": 11.457
    },
    {
        "x": 9.95,
        "y": 11.484
    },
    {
        "x": 9.96,
        "y": 11.502
    },
    {
        "x": 9.97,
        "y": 11.538
    },
    {
        "x": 9.98,
        "y": 11.556
    },
    {
        "x": 9.99,
        "y": 11.583
    },
    {
        "x": 10,
        "y": 11.61
    },
    {
        "x": 10.01,
        "y": 11.637
    },
    {
        "x": 10.02,
        "y": 11.664
    },
    {
        "x": 10.03,
        "y": 11.691
    },
    {
        "x": 10.04,
        "y": 11.709
    },
    {
        "x": 10.05,
        "y": 11.736
    },
    {
        "x": 10.06,
        "y": 11.763
    },
    {
        "x": 10.07,
        "y": 11.79
    },
    {
        "x": 10.08,
        "y": 11.817
    },
    {
        "x": 10.09,
        "y": 11.844
    },
    {
        "x": 10.1,
        "y": 11.862
    },
    {
        "x": 10.11,
        "y": 11.889
    },
    {
        "x": 10.12,
        "y": 11.916
    },
    {
        "x": 10.13,
        "y": 11.943
    },
    {
        "x": 10.14,
        "y": 11.97
    },
    {
        "x": 10.15,
        "y": 11.988
    },
    {
        "x": 10.16,
        "y": 12.015
    },
    {
        "x": 10.17,
        "y": 12.042
    },
    {
        "x": 10.18,
        "y": 12.069
    },
    {
        "x": 10.19,
        "y": 12.096
    },
    {
        "x": 10.2,
        "y": 12.114
    },
    {
        "x": 10.21,
        "y": 12.141
    },
    {
        "x": 10.22,
        "y": 12.168
    },
    {
        "x": 10.23,
        "y": 12.195
    },
    {
        "x": 10.24,
        "y": 12.213
    },
    {
        "x": 10.25,
        "y": 12.24
    },
    {
        "x": 10.26,
        "y": 12.267
    },
    {
        "x": 10.27,
        "y": 12.285
    },
    {
        "x": 10.28,
        "y": 12.312
    },
    {
        "x": 10.29,
        "y": 12.339
    },
    {
        "x": 10.3,
        "y": 12.366
    },
    {
        "x": 10.31,
        "y": 12.384
    },
    {
        "x": 10.32,
        "y": 12.411
    },
    {
        "x": 10.33,
        "y": 12.438
    },
    {
        "x": 10.34,
        "y": 12.456
    },
    {
        "x": 10.35,
        "y": 12.483
    },
    {
        "x": 10.36,
        "y": 12.51
    },
    {
        "x": 10.37,
        "y": 12.537
    },
    {
        "x": 10.38,
        "y": 12.555
    },
    {
        "x": 10.39,
        "y": 12.582
    },
    {
        "x": 10.4,
        "y": 12.6
    },
    {
        "x": 10.41,
        "y": 12.627
    },
    {
        "x": 10.42,
        "y": 12.654
    },
    {
        "x": 10.43,
        "y": 12.672
    },
    {
        "x": 10.44,
        "y": 12.699
    },
    {
        "x": 10.45,
        "y": 12.726
    },
    {
        "x": 10.46,
        "y": 12.744
    },
    {
        "x": 10.47,
        "y": 12.771
    },
    {
        "x": 10.48,
        "y": 12.789
    },
    {
        "x": 10.49,
        "y": 12.816
    },
    {
        "x": 10.5,
        "y": 12.843
    },
    {
        "x": 10.51,
        "y": 12.861
    },
    {
        "x": 10.52,
        "y": 12.888
    },
    {
        "x": 10.53,
        "y": 12.906
    },
    {
        "x": 10.54,
        "y": 12.933
    },
    {
        "x": 10.55,
        "y": 12.951
    },
    {
        "x": 10.56,
        "y": 12.978
    },
    {
        "x": 10.57,
        "y": 13.005
    },
    {
        "x": 10.58,
        "y": 13.023
    },
    {
        "x": 10.59,
        "y": 13.05
    },
    {
        "x": 10.6,
        "y": 13.068
    },
    {
        "x": 10.61,
        "y": 13.086
    },
    {
        "x": 10.62,
        "y": 13.113
    },
    {
        "x": 10.63,
        "y": 13.131
    },
    {
        "x": 10.64,
        "y": 13.158
    },
    {
        "x": 10.65,
        "y": 13.185
    },
    {
        "x": 10.66,
        "y": 13.203
    },
    {
        "x": 10.67,
        "y": 13.221
    },
    {
        "x": 10.68,
        "y": 13.248
    },
    {
        "x": 10.69,
        "y": 13.266
    },
    {
        "x": 10.7,
        "y": 13.293
    },
    {
        "x": 10.71,
        "y": 13.311
    },
    {
        "x": 10.72,
        "y": 13.338
    },
    {
        "x": 10.73,
        "y": 13.356
    },
    {
        "x": 10.74,
        "y": 13.374
    },
    {
        "x": 10.75,
        "y": 13.401
    },
    {
        "x": 10.76,
        "y": 13.419
    },
    {
        "x": 10.77,
        "y": 13.446
    },
    {
        "x": 10.78,
        "y": 13.464
    },
    {
        "x": 10.79,
        "y": 13.491
    },
    {
        "x": 10.8,
        "y": 13.509
    },
    {
        "x": 10.81,
        "y": 13.527
    },
    {
        "x": 10.82,
        "y": 13.554
    },
    {
        "x": 10.83,
        "y": 13.572
    },
    {
        "x": 10.84,
        "y": 13.59
    },
    {
        "x": 10.85,
        "y": 13.617
    },
    {
        "x": 10.86,
        "y": 13.635
    },
    {
        "x": 10.87,
        "y": 13.653
    },
    {
        "x": 10.88,
        "y": 13.68
    },
    {
        "x": 10.89,
        "y": 13.698
    },
    {
        "x": 10.9,
        "y": 13.725
    },
    {
        "x": 10.91,
        "y": 13.743
    },
    {
        "x": 10.92,
        "y": 13.761
    },
    {
        "x": 10.93,
        "y": 13.779
    },
    {
        "x": 10.94,
        "y": 13.797
    },
    {
        "x": 10.95,
        "y": 13.824
    },
    {
        "x": 10.96,
        "y": 13.842
    },
    {
        "x": 10.97,
        "y": 13.86
    },
    {
        "x": 10.98,
        "y": 13.878
    },
    {
        "x": 10.99,
        "y": 13.905
    },
    {
        "x": 11,
        "y": 13.923
    },
    {
        "x": 11.01,
        "y": 13.941
    },
    {
        "x": 11.02,
        "y": 13.959
    },
    {
        "x": 11.03,
        "y": 13.986
    },
    {
        "x": 11.04,
        "y": 14.004
    },
    {
        "x": 11.05,
        "y": 14.022
    },
    {
        "x": 11.06,
        "y": 14.04
    },
    {
        "x": 11.07,
        "y": 14.067
    },
    {
        "x": 11.08,
        "y": 14.085
    },
    {
        "x": 11.09,
        "y": 14.103
    },
    {
        "x": 11.1,
        "y": 14.121
    },
    {
        "x": 11.11,
        "y": 14.139
    },
    {
        "x": 11.12,
        "y": 14.166
    },
    {
        "x": 11.13,
        "y": 14.184
    },
    {
        "x": 11.14,
        "y": 14.202
    },
    {
        "x": 11.15,
        "y": 14.22
    },
    {
        "x": 11.16,
        "y": 14.238
    },
    {
        "x": 11.17,
        "y": 14.256
    },
    {
        "x": 11.18,
        "y": 14.274
    },
    {
        "x": 11.19,
        "y": 14.292
    },
    {
        "x": 11.2,
        "y": 14.319
    },
    {
        "x": 11.21,
        "y": 14.337
    },
    {
        "x": 11.22,
        "y": 14.355
    },
    {
        "x": 11.23,
        "y": 14.373
    },
    {
        "x": 11.24,
        "y": 14.391
    },
    {
        "x": 11.25,
        "y": 14.409
    },
    {
        "x": 11.26,
        "y": 14.427
    },
    {
        "x": 11.27,
        "y": 14.445
    },
    {
        "x": 11.28,
        "y": 14.472
    },
    {
        "x": 11.29,
        "y": 14.481
    },
    {
        "x": 11.3,
        "y": 14.508
    },
    {
        "x": 11.31,
        "y": 14.526
    },
    {
        "x": 11.32,
        "y": 14.544
    },
    {
        "x": 11.33,
        "y": 14.562
    },
    {
        "x": 11.34,
        "y": 14.58
    },
    {
        "x": 11.35,
        "y": 14.598
    },
    {
        "x": 11.36,
        "y": 14.616
    },
    {
        "x": 11.37,
        "y": 14.634
    },
    {
        "x": 11.38,
        "y": 14.652
    },
    {
        "x": 11.39,
        "y": 14.67
    },
    {
        "x": 11.4,
        "y": 14.688
    },
    {
        "x": 11.41,
        "y": 14.706
    },
    {
        "x": 11.42,
        "y": 14.724
    },
    {
        "x": 11.43,
        "y": 14.742
    },
    {
        "x": 11.44,
        "y": 14.76
    },
    {
        "x": 11.45,
        "y": 14.778
    },
    {
        "x": 11.46,
        "y": 14.787
    },
    {
        "x": 11.47,
        "y": 14.814
    },
    {
        "x": 11.48,
        "y": 14.832
    },
    {
        "x": 11.49,
        "y": 14.841
    },
    {
        "x": 11.5,
        "y": 14.859
    },
    {
        "x": 11.51,
        "y": 14.877
    },
    {
        "x": 11.52,
        "y": 14.895
    },
    {
        "x": 11.53,
        "y": 14.913
    },
    {
        "x": 11.54,
        "y": 14.931
    },
    {
        "x": 11.55,
        "y": 14.949
    },
    {
        "x": 11.56,
        "y": 14.967
    },
    {
        "x": 11.57,
        "y": 14.985
    },
    {
        "x": 11.58,
        "y": 14.994
    },
    {
        "x": 11.59,
        "y": 15.012
    },
    {
        "x": 11.6,
        "y": 15.03
    },
    {
        "x": 11.61,
        "y": 15.048
    },
    {
        "x": 11.62,
        "y": 15.066
    },
    {
        "x": 11.63,
        "y": 15.084
    },
    {
        "x": 11.64,
        "y": 15.102
    },
    {
        "x": 11.65,
        "y": 15.12
    },
    {
        "x": 11.66,
        "y": 15.129
    },
    {
        "x": 11.67,
        "y": 15.147
    },
    {
        "x": 11.68,
        "y": 15.165
    },
    {
        "x": 11.69,
        "y": 15.183
    },
    {
        "x": 11.7,
        "y": 15.201
    },
    {
        "x": 11.71,
        "y": 15.219
    },
    {
        "x": 11.72,
        "y": 15.228
    },
    {
        "x": 11.73,
        "y": 15.246
    },
    {
        "x": 11.74,
        "y": 15.264
    },
    {
        "x": 11.75,
        "y": 15.282
    },
    {
        "x": 11.76,
        "y": 15.3
    },
    {
        "x": 11.77,
        "y": 15.309
    },
    {
        "x": 11.78,
        "y": 15.327
    },
    {
        "x": 11.79,
        "y": 15.345
    },
    {
        "x": 11.8,
        "y": 15.363
    },
    {
        "x": 11.81,
        "y": 15.372
    },
    {
        "x": 11.82,
        "y": 15.39
    },
    {
        "x": 11.83,
        "y": 15.408
    },
    {
        "x": 11.84,
        "y": 15.426
    },
    {
        "x": 11.85,
        "y": 15.435
    },
    {
        "x": 11.86,
        "y": 15.453
    },
    {
        "x": 11.87,
        "y": 15.471
    },
    {
        "x": 11.88,
        "y": 15.489
    },
    {
        "x": 11.89,
        "y": 15.498
    },
    {
        "x": 11.9,
        "y": 15.516
    },
    {
        "x": 11.91,
        "y": 15.534
    },
    {
        "x": 11.92,
        "y": 15.543
    },
    {
        "x": 11.93,
        "y": 15.561
    },
    {
        "x": 11.94,
        "y": 15.57
    },
    {
        "x": 11.95,
        "y": 15.597
    },
    {
        "x": 11.96,
        "y": 15.606
    },
    {
        "x": 11.97,
        "y": 15.624
    },
    {
        "x": 11.98,
        "y": 15.642
    },
    {
        "x": 11.99,
        "y": 15.651
    },
    {
        "x": 12,
        "y": 15.669
    },
    {
        "x": 12.01,
        "y": 15.687
    },
    {
        "x": 12.02,
        "y": 15.696
    },
    {
        "x": 12.03,
        "y": 15.714
    },
    {
        "x": 12.04,
        "y": 15.723
    },
    {
        "x": 12.05,
        "y": 15.741
    },
    {
        "x": 12.06,
        "y": 15.759
    },
    {
        "x": 12.07,
        "y": 15.768
    },
    {
        "x": 12.08,
        "y": 15.786
    },
    {
        "x": 12.09,
        "y": 15.795
    },
    {
        "x": 12.1,
        "y": 15.813
    },
    {
        "x": 12.11,
        "y": 15.831
    },
    {
        "x": 12.12,
        "y": 15.84
    },
    {
        "x": 12.13,
        "y": 15.858
    },
    {
        "x": 12.14,
        "y": 15.876
    },
    {
        "x": 12.15,
        "y": 15.885
    },
    {
        "x": 12.16,
        "y": 15.903
    },
    {
        "x": 12.17,
        "y": 15.912
    },
    {
        "x": 12.18,
        "y": 15.93
    },
    {
        "x": 12.19,
        "y": 15.939
    },
    {
        "x": 12.2,
        "y": 15.957
    },
    {
        "x": 12.21,
        "y": 15.966
    },
    {
        "x": 12.22,
        "y": 15.984
    },
    {
        "x": 12.23,
        "y": 16.002
    },
    {
        "x": 12.24,
        "y": 16.011
    },
    {
        "x": 12.25,
        "y": 16.029
    },
    {
        "x": 12.26,
        "y": 16.038
    },
    {
        "x": 12.27,
        "y": 16.056
    },
    {
        "x": 12.28,
        "y": 16.065
    },
    {
        "x": 12.29,
        "y": 16.083
    },
    {
        "x": 12.3,
        "y": 16.092
    },
    {
        "x": 12.31,
        "y": 16.11
    },
    {
        "x": 12.32,
        "y": 16.119
    },
    {
        "x": 12.33,
        "y": 16.137
    },
    {
        "x": 12.34,
        "y": 16.146
    },
    {
        "x": 12.35,
        "y": 16.164
    },
    {
        "x": 12.36,
        "y": 16.182
    },
    {
        "x": 12.37,
        "y": 16.191
    },
    {
        "x": 12.38,
        "y": 16.2
    },
    {
        "x": 12.39,
        "y": 16.209
    },
    {
        "x": 12.4,
        "y": 16.227
    },
    {
        "x": 12.41,
        "y": 16.236
    },
    {
        "x": 12.42,
        "y": 16.254
    },
    {
        "x": 12.43,
        "y": 16.263
    },
    {
        "x": 12.44,
        "y": 16.281
    },
    {
        "x": 12.45,
        "y": 16.29
    },
    {
        "x": 12.46,
        "y": 16.308
    },
    {
        "x": 12.47,
        "y": 16.317
    },
    {
        "x": 12.48,
        "y": 16.326
    },
    {
        "x": 12.49,
        "y": 16.344
    },
    {
        "x": 12.5,
        "y": 16.353
    },
    {
        "x": 12.51,
        "y": 16.371
    },
    {
        "x": 12.52,
        "y": 16.38
    },
    {
        "x": 12.53,
        "y": 16.389
    },
    {
        "x": 12.54,
        "y": 16.407
    },
    {
        "x": 12.55,
        "y": 16.416
    },
    {
        "x": 12.56,
        "y": 16.434
    },
    {
        "x": 12.57,
        "y": 16.443
    },
    {
        "x": 12.58,
        "y": 16.452
    },
    {
        "x": 12.59,
        "y": 16.47
    },
    {
        "x": 12.6,
        "y": 16.479
    },
    {
        "x": 12.61,
        "y": 16.497
    },
    {
        "x": 12.62,
        "y": 16.506
    },
    {
        "x": 12.63,
        "y": 16.515
    },
    {
        "x": 12.64,
        "y": 16.533
    },
    {
        "x": 12.65,
        "y": 16.542
    },
    {
        "x": 12.66,
        "y": 16.56
    },
    {
        "x": 12.67,
        "y": 16.569
    },
    {
        "x": 12.68,
        "y": 16.578
    },
    {
        "x": 12.69,
        "y": 16.587
    },
    {
        "x": 12.7,
        "y": 16.605
    },
    {
        "x": 12.71,
        "y": 16.614
    },
    {
        "x": 12.72,
        "y": 16.623
    },
    {
        "x": 12.73,
        "y": 16.641
    },
    {
        "x": 12.74,
        "y": 16.65
    },
    {
        "x": 12.75,
        "y": 16.659
    },
    {
        "x": 12.76,
        "y": 16.677
    },
    {
        "x": 12.77,
        "y": 16.686
    },
    {
        "x": 12.78,
        "y": 16.695
    },
    {
        "x": 12.79,
        "y": 16.704
    },
    {
        "x": 12.8,
        "y": 16.722
    },
    {
        "x": 12.81,
        "y": 16.731
    },
    {
        "x": 12.82,
        "y": 16.74
    },
    {
        "x": 12.83,
        "y": 16.758
    },
    {
        "x": 12.84,
        "y": 16.767
    },
    {
        "x": 12.85,
        "y": 16.776
    },
    {
        "x": 12.86,
        "y": 16.794
    },
    {
        "x": 12.87,
        "y": 16.803
    },
    {
        "x": 12.88,
        "y": 16.812
    },
    {
        "x": 12.89,
        "y": 16.821
    },
    {
        "x": 12.9,
        "y": 16.839
    },
    {
        "x": 12.91,
        "y": 16.848
    },
    {
        "x": 12.92,
        "y": 16.857
    },
    {
        "x": 12.93,
        "y": 16.866
    },
    {
        "x": 12.94,
        "y": 16.884
    },
    {
        "x": 12.95,
        "y": 16.893
    },
    {
        "x": 12.96,
        "y": 16.902
    },
    {
        "x": 12.97,
        "y": 16.911
    },
    {
        "x": 12.98,
        "y": 16.929
    },
    {
        "x": 12.99,
        "y": 16.938
    },
    {
        "x": 13,
        "y": 16.947
    },
    {
        "x": 13.01,
        "y": 16.956
    },
    {
        "x": 13.02,
        "y": 16.965
    },
    {
        "x": 13.03,
        "y": 16.974
    },
    {
        "x": 13.04,
        "y": 16.992
    },
    {
        "x": 13.05,
        "y": 17.001
    },
    {
        "x": 13.06,
        "y": 17.01
    },
    {
        "x": 13.07,
        "y": 17.028
    },
    {
        "x": 13.08,
        "y": 17.037
    },
    {
        "x": 13.09,
        "y": 17.046
    },
    {
        "x": 13.1,
        "y": 17.055
    },
    {
        "x": 13.11,
        "y": 17.064
    },
    {
        "x": 13.12,
        "y": 17.073
    },
    {
        "x": 13.13,
        "y": 17.082
    },
    {
        "x": 13.14,
        "y": 17.1
    },
    {
        "x": 13.15,
        "y": 17.109
    },
    {
        "x": 13.16,
        "y": 17.118
    },
    {
        "x": 13.17,
        "y": 17.127
    },
    {
        "x": 13.18,
        "y": 17.145
    },
    {
        "x": 13.19,
        "y": 17.154
    },
    {
        "x": 13.2,
        "y": 17.163
    },
    {
        "x": 13.21,
        "y": 17.172
    },
    {
        "x": 13.22,
        "y": 17.181
    },
    {
        "x": 13.23,
        "y": 17.19
    },
    {
        "x": 13.24,
        "y": 17.199
    },
    {
        "x": 13.25,
        "y": 17.217
    },
    {
        "x": 13.26,
        "y": 17.226
    },
    {
        "x": 13.27,
        "y": 17.235
    },
    {
        "x": 13.28,
        "y": 17.244
    },
    {
        "x": 13.29,
        "y": 17.253
    },
    {
        "x": 13.3,
        "y": 17.262
    },
    {
        "x": 13.31,
        "y": 17.271
    },
    {
        "x": 13.32,
        "y": 17.289
    },
    {
        "x": 13.33,
        "y": 17.289
    },
    {
        "x": 13.34,
        "y": 17.307
    },
    {
        "x": 13.35,
        "y": 17.316
    },
    {
        "x": 13.36,
        "y": 17.325
    },
    {
        "x": 13.37,
        "y": 17.334
    },
    {
        "x": 13.38,
        "y": 17.343
    },
    {
        "x": 13.39,
        "y": 17.352
    },
    {
        "x": 13.4,
        "y": 17.37
    },
    {
        "x": 13.41,
        "y": 17.379
    },
    {
        "x": 13.42,
        "y": 17.388
    },
    {
        "x": 13.43,
        "y": 17.397
    },
    {
        "x": 13.44,
        "y": 17.406
    },
    {
        "x": 13.45,
        "y": 17.415
    },
    {
        "x": 13.46,
        "y": 17.424
    },
    {
        "x": 13.47,
        "y": 17.433
    },
    {
        "x": 13.48,
        "y": 17.442
    },
    {
        "x": 13.49,
        "y": 17.451
    },
    {
        "x": 13.5,
        "y": 17.46
    },
    {
        "x": 13.51,
        "y": 17.469
    },
    {
        "x": 13.52,
        "y": 17.478
    },
    {
        "x": 13.53,
        "y": 17.487
    },
    {
        "x": 13.54,
        "y": 17.505
    },
    {
        "x": 13.55,
        "y": 17.514
    },
    {
        "x": 13.56,
        "y": 17.523
    },
    {
        "x": 13.57,
        "y": 17.532
    },
    {
        "x": 13.58,
        "y": 17.532
    },
    {
        "x": 13.59,
        "y": 17.55
    },
    {
        "x": 13.6,
        "y": 17.559
    },
    {
        "x": 13.61,
        "y": 17.568
    },
    {
        "x": 13.62,
        "y": 17.577
    },
    {
        "x": 13.63,
        "y": 17.586
    },
    {
        "x": 13.64,
        "y": 17.595
    },
    {
        "x": 13.65,
        "y": 17.604
    },
    {
        "x": 13.66,
        "y": 17.613
    },
    {
        "x": 13.67,
        "y": 17.622
    },
    {
        "x": 13.68,
        "y": 17.631
    },
    {
        "x": 13.69,
        "y": 17.64
    },
    {
        "x": 13.7,
        "y": 17.649
    },
    {
        "x": 13.71,
        "y": 17.658
    },
    {
        "x": 13.72,
        "y": 17.676
    },
    {
        "x": 13.73,
        "y": 17.676
    },
    {
        "x": 13.74,
        "y": 17.685
    },
    {
        "x": 13.75,
        "y": 17.694
    },
    {
        "x": 13.76,
        "y": 17.703
    },
    {
        "x": 13.77,
        "y": 17.712
    },
    {
        "x": 13.78,
        "y": 17.721
    },
    {
        "x": 13.79,
        "y": 17.73
    },
    {
        "x": 13.8,
        "y": 17.739
    },
    {
        "x": 13.81,
        "y": 17.748
    },
    {
        "x": 13.82,
        "y": 17.757
    },
    {
        "x": 13.83,
        "y": 17.775
    },
    {
        "x": 13.84,
        "y": 17.775
    },
    {
        "x": 13.85,
        "y": 17.784
    },
    {
        "x": 13.86,
        "y": 17.793
    },
    {
        "x": 13.87,
        "y": 17.802
    },
    {
        "x": 13.88,
        "y": 17.811
    },
    {
        "x": 13.89,
        "y": 17.82
    },
    {
        "x": 13.9,
        "y": 17.829
    },
    {
        "x": 13.91,
        "y": 17.838
    },
    {
        "x": 13.92,
        "y": 17.847
    },
    {
        "x": 13.93,
        "y": 17.856
    },
    {
        "x": 13.94,
        "y": 17.865
    },
    {
        "x": 13.95,
        "y": 17.874
    },
    {
        "x": 13.96,
        "y": 17.883
    },
    {
        "x": 13.97,
        "y": 17.892
    },
    {
        "x": 13.98,
        "y": 17.901
    },
    {
        "x": 13.99,
        "y": 17.91
    },
    {
        "x": 14,
        "y": 17.919
    },
    {
        "x": 14.01,
        "y": 17.928
    },
    {
        "x": 14.02,
        "y": 17.937
    },
    {
        "x": 14.03,
        "y": 17.946
    },
    {
        "x": 14.04,
        "y": 17.946
    },
    {
        "x": 14.05,
        "y": 17.955
    },
    {
        "x": 14.06,
        "y": 17.964
    },
    {
        "x": 14.07,
        "y": 17.973
    },
    {
        "x": 14.08,
        "y": 17.982
    },
    {
        "x": 14.09,
        "y": 17.991
    },
    {
        "x": 14.1,
        "y": 18
    },
    {
        "x": 14.11,
        "y": 18.009
    },
    {
        "x": 14.12,
        "y": 18.018
    },
    {
        "x": 14.13,
        "y": 18.027
    },
    {
        "x": 14.14,
        "y": 18.036
    },
    {
        "x": 14.15,
        "y": 18.045
    },
    {
        "x": 14.16,
        "y": 18.054
    },
    {
        "x": 14.17,
        "y": 18.054
    },
    {
        "x": 14.18,
        "y": 18.072
    },
    {
        "x": 14.19,
        "y": 18.072
    },
    {
        "x": 14.2,
        "y": 18.081
    },
    {
        "x": 14.21,
        "y": 18.09
    },
    {
        "x": 14.22,
        "y": 18.099
    },
    {
        "x": 14.23,
        "y": 18.108
    },
    {
        "x": 14.24,
        "y": 18.117
    },
    {
        "x": 14.25,
        "y": 18.126
    },
    {
        "x": 14.26,
        "y": 18.135
    },
    {
        "x": 14.27,
        "y": 18.135
    },
    {
        "x": 14.28,
        "y": 18.144
    },
    {
        "x": 14.29,
        "y": 18.153
    },
    {
        "x": 14.3,
        "y": 18.162
    },
    {
        "x": 14.31,
        "y": 18.171
    },
    {
        "x": 14.32,
        "y": 18.18
    },
    {
        "x": 14.33,
        "y": 18.189
    },
    {
        "x": 14.34,
        "y": 18.198
    },
    {
        "x": 14.35,
        "y": 18.207
    },
    {
        "x": 14.36,
        "y": 18.216
    },
    {
        "x": 14.37,
        "y": 18.225
    },
    {
        "x": 14.38,
        "y": 18.225
    },
    {
        "x": 14.39,
        "y": 18.234
    },
    {
        "x": 14.4,
        "y": 18.243
    },
    {
        "x": 14.41,
        "y": 18.252
    },
    {
        "x": 14.42,
        "y": 18.261
    },
    {
        "x": 14.43,
        "y": 18.27
    },
    {
        "x": 14.44,
        "y": 18.27
    },
    {
        "x": 14.45,
        "y": 18.288
    },
    {
        "x": 14.46,
        "y": 18.297
    },
    {
        "x": 14.47,
        "y": 18.297
    },
    {
        "x": 14.48,
        "y": 18.306
    },
    {
        "x": 14.49,
        "y": 18.315
    },
    {
        "x": 14.5,
        "y": 18.324
    },
    {
        "x": 14.51,
        "y": 18.333
    },
    {
        "x": 14.52,
        "y": 18.342
    },
    {
        "x": 14.53,
        "y": 18.342
    },
    {
        "x": 14.54,
        "y": 18.351
    },
    {
        "x": 14.55,
        "y": 18.36
    },
    {
        "x": 14.56,
        "y": 18.369
    },
    {
        "x": 14.57,
        "y": 18.378
    },
    {
        "x": 14.58,
        "y": 18.378
    },
    {
        "x": 14.59,
        "y": 18.387
    },
    {
        "x": 14.6,
        "y": 18.396
    },
    {
        "x": 14.61,
        "y": 18.405
    },
    {
        "x": 14.62,
        "y": 18.414
    },
    {
        "x": 14.63,
        "y": 18.423
    },
    {
        "x": 14.64,
        "y": 18.423
    },
    {
        "x": 14.65,
        "y": 18.432
    },
    {
        "x": 14.66,
        "y": 18.441
    },
    {
        "x": 14.67,
        "y": 18.441
    },
    {
        "x": 14.68,
        "y": 18.45
    },
    {
        "x": 14.69,
        "y": 18.459
    },
    {
        "x": 14.7,
        "y": 18.468
    },
    {
        "x": 14.71,
        "y": 18.477
    },
    {
        "x": 14.72,
        "y": 18.486
    },
    {
        "x": 14.73,
        "y": 18.486
    },
    {
        "x": 14.74,
        "y": 18.495
    },
    {
        "x": 14.75,
        "y": 18.504
    },
    {
        "x": 14.76,
        "y": 18.513
    },
    {
        "x": 14.77,
        "y": 18.522
    },
    {
        "x": 14.78,
        "y": 18.522
    },
    {
        "x": 14.79,
        "y": 18.54
    },
    {
        "x": 14.8,
        "y": 18.54
    },
    {
        "x": 14.81,
        "y": 18.549
    },
    {
        "x": 14.82,
        "y": 18.558
    },
    {
        "x": 14.83,
        "y": 18.567
    },
    {
        "x": 14.84,
        "y": 18.576
    },
    {
        "x": 14.85,
        "y": 18.576
    },
    {
        "x": 14.86,
        "y": 18.585
    },
    {
        "x": 14.87,
        "y": 18.594
    },
    {
        "x": 14.88,
        "y": 18.603
    },
    {
        "x": 14.89,
        "y": 18.603
    },
    {
        "x": 14.9,
        "y": 18.612
    },
    {
        "x": 14.91,
        "y": 18.621
    },
    {
        "x": 14.92,
        "y": 18.63
    },
    {
        "x": 14.93,
        "y": 18.639
    },
    {
        "x": 14.94,
        "y": 18.639
    },
    {
        "x": 14.95,
        "y": 18.648
    },
    {
        "x": 14.96,
        "y": 18.657
    },
    {
        "x": 14.97,
        "y": 18.657
    },
    {
        "x": 14.98,
        "y": 18.666
    },
    {
        "x": 14.99,
        "y": 18.675
    },
    {
        "x": 15,
        "y": 18.684
    },
    {
        "x": 15.01,
        "y": 18.684
    },
    {
        "x": 15.02,
        "y": 18.693
    },
    {
        "x": 15.03,
        "y": 18.702
    },
    {
        "x": 15.04,
        "y": 18.711
    },
    {
        "x": 15.05,
        "y": 18.72
    },
    {
        "x": 15.06,
        "y": 18.729
    },
    {
        "x": 15.07,
        "y": 18.729
    },
    {
        "x": 15.08,
        "y": 18.738
    },
    {
        "x": 15.09,
        "y": 18.747
    },
    {
        "x": 15.1,
        "y": 18.756
    },
    {
        "x": 15.11,
        "y": 18.756
    },
    {
        "x": 15.12,
        "y": 18.765
    },
    {
        "x": 15.13,
        "y": 18.774
    },
    {
        "x": 15.14,
        "y": 18.774
    },
    {
        "x": 15.15,
        "y": 18.783
    },
    {
        "x": 15.16,
        "y": 18.792
    },
    {
        "x": 15.17,
        "y": 18.792
    },
    {
        "x": 15.18,
        "y": 18.801
    },
    {
        "x": 15.19,
        "y": 18.81
    },
    {
        "x": 15.2,
        "y": 18.819
    },
    {
        "x": 15.21,
        "y": 18.828
    },
    {
        "x": 15.22,
        "y": 18.828
    },
    {
        "x": 15.23,
        "y": 18.837
    },
    {
        "x": 15.24,
        "y": 18.846
    },
    {
        "x": 15.25,
        "y": 18.846
    },
    {
        "x": 15.26,
        "y": 18.855
    },
    {
        "x": 15.27,
        "y": 18.864
    },
    {
        "x": 15.28,
        "y": 18.873
    },
    {
        "x": 15.29,
        "y": 18.873
    },
    {
        "x": 15.3,
        "y": 18.882
    },
    {
        "x": 15.31,
        "y": 18.891
    },
    {
        "x": 15.32,
        "y": 18.891
    },
    {
        "x": 15.33,
        "y": 18.9
    },
    {
        "x": 15.34,
        "y": 18.9
    },
    {
        "x": 15.35,
        "y": 18.909
    },
    {
        "x": 15.36,
        "y": 18.918
    },
    {
        "x": 15.37,
        "y": 18.927
    },
    {
        "x": 15.38,
        "y": 18.936
    },
    {
        "x": 15.39,
        "y": 18.936
    },
    {
        "x": 15.4,
        "y": 18.945
    },
    {
        "x": 15.41,
        "y": 18.954
    },
    {
        "x": 15.42,
        "y": 18.963
    },
    {
        "x": 15.43,
        "y": 18.963
    },
    {
        "x": 15.44,
        "y": 18.972
    },
    {
        "x": 15.45,
        "y": 18.981
    },
    {
        "x": 15.46,
        "y": 18.981
    },
    {
        "x": 15.47,
        "y": 18.99
    },
    {
        "x": 15.48,
        "y": 18.999
    },
    {
        "x": 15.49,
        "y": 19.008
    },
    {
        "x": 15.5,
        "y": 19.008
    },
    {
        "x": 15.51,
        "y": 19.017
    },
    {
        "x": 15.52,
        "y": 19.026
    },
    {
        "x": 15.53,
        "y": 19.026
    },
    {
        "x": 15.54,
        "y": 19.035
    },
    {
        "x": 15.55,
        "y": 19.035
    },
    {
        "x": 15.56,
        "y": 19.044
    },
    {
        "x": 15.57,
        "y": 19.053
    },
    {
        "x": 15.58,
        "y": 19.062
    },
    {
        "x": 15.59,
        "y": 19.062
    },
    {
        "x": 15.6,
        "y": 19.071
    },
    {
        "x": 15.61,
        "y": 19.08
    },
    {
        "x": 15.62,
        "y": 19.08
    },
    {
        "x": 15.63,
        "y": 19.089
    },
    {
        "x": 15.64,
        "y": 19.089
    },
    {
        "x": 15.65,
        "y": 19.098
    },
    {
        "x": 15.66,
        "y": 19.107
    },
    {
        "x": 15.67,
        "y": 19.116
    },
    {
        "x": 15.68,
        "y": 19.116
    },
    {
        "x": 15.69,
        "y": 19.125
    },
    {
        "x": 15.7,
        "y": 19.125
    },
    {
        "x": 15.71,
        "y": 19.143
    },
    {
        "x": 15.72,
        "y": 19.143
    },
    {
        "x": 15.73,
        "y": 19.152
    },
    {
        "x": 15.74,
        "y": 19.152
    },
    {
        "x": 15.75,
        "y": 19.161
    },
    {
        "x": 15.76,
        "y": 19.17
    },
    {
        "x": 15.77,
        "y": 19.17
    },
    {
        "x": 15.78,
        "y": 19.179
    },
    {
        "x": 15.79,
        "y": 19.179
    },
    {
        "x": 15.8,
        "y": 19.188
    },
    {
        "x": 15.81,
        "y": 19.197
    },
    {
        "x": 15.82,
        "y": 19.206
    },
    {
        "x": 15.83,
        "y": 19.215
    },
    {
        "x": 15.84,
        "y": 19.215
    },
    {
        "x": 15.85,
        "y": 19.215
    },
    {
        "x": 15.86,
        "y": 19.224
    },
    {
        "x": 15.87,
        "y": 19.233
    },
    {
        "x": 15.88,
        "y": 19.242
    },
    {
        "x": 15.89,
        "y": 19.242
    },
    {
        "x": 15.9,
        "y": 19.251
    },
    {
        "x": 15.91,
        "y": 19.251
    },
    {
        "x": 15.92,
        "y": 19.26
    },
    {
        "x": 15.93,
        "y": 19.269
    },
    {
        "x": 15.94,
        "y": 19.269
    },
    {
        "x": 15.95,
        "y": 19.278
    },
    {
        "x": 15.96,
        "y": 19.278
    },
    {
        "x": 15.97,
        "y": 19.287
    },
    {
        "x": 15.98,
        "y": 19.296
    },
    {
        "x": 15.99,
        "y": 19.305
    },
    {
        "x": 16,
        "y": 19.305
    },
    {
        "x": 16.01,
        "y": 19.314
    },
    {
        "x": 16.02,
        "y": 19.314
    },
    {
        "x": 16.03,
        "y": 19.323
    },
    {
        "x": 16.04,
        "y": 19.332
    },
    {
        "x": 16.05,
        "y": 19.332
    },
    {
        "x": 16.06,
        "y": 19.341
    },
    {
        "x": 16.07,
        "y": 19.35
    },
    {
        "x": 16.08,
        "y": 19.35
    },
    {
        "x": 16.09,
        "y": 19.359
    },
    {
        "x": 16.1,
        "y": 19.359
    },
    {
        "x": 16.11,
        "y": 19.368
    },
    {
        "x": 16.12,
        "y": 19.377
    },
    {
        "x": 16.13,
        "y": 19.377
    },
    {
        "x": 16.14,
        "y": 19.386
    },
    {
        "x": 16.15,
        "y": 19.386
    },
    {
        "x": 16.16,
        "y": 19.395
    },
    {
        "x": 16.17,
        "y": 19.395
    },
    {
        "x": 16.18,
        "y": 19.404
    },
    {
        "x": 16.19,
        "y": 19.413
    },
    {
        "x": 16.2,
        "y": 19.422
    },
    {
        "x": 16.21,
        "y": 19.422
    },
    {
        "x": 16.22,
        "y": 19.431
    },
    {
        "x": 16.23,
        "y": 19.431
    },
    {
        "x": 16.24,
        "y": 19.44
    },
    {
        "x": 16.25,
        "y": 19.44
    },
    {
        "x": 16.26,
        "y": 19.449
    },
    {
        "x": 16.27,
        "y": 19.458
    },
    {
        "x": 16.28,
        "y": 19.458
    },
    {
        "x": 16.29,
        "y": 19.467
    },
    {
        "x": 16.3,
        "y": 19.476
    },
    {
        "x": 16.31,
        "y": 19.476
    },
    {
        "x": 16.32,
        "y": 19.485
    },
    {
        "x": 16.33,
        "y": 19.485
    },
    {
        "x": 16.34,
        "y": 19.494
    },
    {
        "x": 16.35,
        "y": 19.503
    },
    {
        "x": 16.36,
        "y": 19.503
    },
    {
        "x": 16.37,
        "y": 19.512
    },
    {
        "x": 16.38,
        "y": 19.512
    },
    {
        "x": 16.39,
        "y": 19.521
    },
    {
        "x": 16.4,
        "y": 19.521
    },
    {
        "x": 16.41,
        "y": 19.53
    },
    {
        "x": 16.42,
        "y": 19.539
    },
    {
        "x": 16.43,
        "y": 19.539
    },
    {
        "x": 16.44,
        "y": 19.548
    },
    {
        "x": 16.45,
        "y": 19.548
    },
    {
        "x": 16.46,
        "y": 19.557
    },
    {
        "x": 16.47,
        "y": 19.566
    },
    {
        "x": 16.48,
        "y": 19.566
    },
    {
        "x": 16.49,
        "y": 19.575
    },
    {
        "x": 16.5,
        "y": 19.575
    },
    {
        "x": 16.51,
        "y": 19.584
    },
    {
        "x": 16.52,
        "y": 19.593
    },
    {
        "x": 16.53,
        "y": 19.593
    },
    {
        "x": 16.54,
        "y": 19.602
    },
    {
        "x": 16.55,
        "y": 19.602
    },
    {
        "x": 16.56,
        "y": 19.611
    },
    {
        "x": 16.57,
        "y": 19.611
    },
    {
        "x": 16.58,
        "y": 19.62
    },
    {
        "x": 16.59,
        "y": 19.629
    },
    {
        "x": 16.6,
        "y": 19.629
    },
    {
        "x": 16.61,
        "y": 19.629
    },
    {
        "x": 16.62,
        "y": 19.638
    },
    {
        "x": 16.63,
        "y": 19.647
    },
    {
        "x": 16.64,
        "y": 19.647
    },
    {
        "x": 16.65,
        "y": 19.656
    },
    {
        "x": 16.66,
        "y": 19.656
    },
    {
        "x": 16.67,
        "y": 19.665
    },
    {
        "x": 16.68,
        "y": 19.674
    },
    {
        "x": 16.69,
        "y": 19.674
    },
    {
        "x": 16.7,
        "y": 19.683
    },
    {
        "x": 16.71,
        "y": 19.683
    },
    {
        "x": 16.72,
        "y": 19.692
    },
    {
        "x": 16.73,
        "y": 19.692
    },
    {
        "x": 16.74,
        "y": 19.701
    },
    {
        "x": 16.75,
        "y": 19.701
    },
    {
        "x": 16.76,
        "y": 19.71
    },
    {
        "x": 16.77,
        "y": 19.719
    },
    {
        "x": 16.78,
        "y": 19.719
    },
    {
        "x": 16.79,
        "y": 19.728
    },
    {
        "x": 16.8,
        "y": 19.728
    },
    {
        "x": 16.81,
        "y": 19.737
    },
    {
        "x": 16.82,
        "y": 19.737
    },
    {
        "x": 16.83,
        "y": 19.746
    },
    {
        "x": 16.84,
        "y": 19.746
    },
    {
        "x": 16.85,
        "y": 19.755
    },
    {
        "x": 16.86,
        "y": 19.764
    },
    {
        "x": 16.87,
        "y": 19.764
    },
    {
        "x": 16.88,
        "y": 19.764
    },
    {
        "x": 16.89,
        "y": 19.773
    },
    {
        "x": 16.9,
        "y": 19.773
    },
    {
        "x": 16.91,
        "y": 19.782
    },
    {
        "x": 16.92,
        "y": 19.791
    },
    {
        "x": 16.93,
        "y": 19.791
    },
    {
        "x": 16.94,
        "y": 19.8
    },
    {
        "x": 16.95,
        "y": 19.8
    },
    {
        "x": 16.96,
        "y": 19.809
    },
    {
        "x": 16.97,
        "y": 19.809
    },
    {
        "x": 16.98,
        "y": 19.818
    },
    {
        "x": 16.99,
        "y": 19.818
    },
    {
        "x": 17,
        "y": 19.827
    },
    {
        "x": 17.01,
        "y": 19.836
    },
    {
        "x": 17.02,
        "y": 19.836
    },
    {
        "x": 17.03,
        "y": 19.845
    },
    {
        "x": 17.04,
        "y": 19.845
    },
    {
        "x": 17.05,
        "y": 19.854
    },
    {
        "x": 17.06,
        "y": 19.854
    },
    {
        "x": 17.07,
        "y": 19.863
    },
    {
        "x": 17.08,
        "y": 19.863
    },
    {
        "x": 17.09,
        "y": 19.872
    },
    {
        "x": 17.1,
        "y": 19.872
    },
    {
        "x": 17.11,
        "y": 19.881
    },
    {
        "x": 17.12,
        "y": 19.881
    },
    {
        "x": 17.13,
        "y": 19.89
    },
    {
        "x": 17.14,
        "y": 19.89
    },
    {
        "x": 17.15,
        "y": 19.899
    },
    {
        "x": 17.16,
        "y": 19.899
    },
    {
        "x": 17.17,
        "y": 19.908
    },
    {
        "x": 17.18,
        "y": 19.917
    },
    {
        "x": 17.19,
        "y": 19.917
    },
    {
        "x": 17.2,
        "y": 19.926
    },
    {
        "x": 17.21,
        "y": 19.926
    },
    {
        "x": 17.22,
        "y": 19.935
    },
    {
        "x": 17.23,
        "y": 19.935
    },
    {
        "x": 17.24,
        "y": 19.944
    },
    {
        "x": 17.25,
        "y": 19.944
    },
    {
        "x": 17.26,
        "y": 19.944
    },
    {
        "x": 17.27,
        "y": 19.953
    },
    {
        "x": 17.28,
        "y": 19.962
    },
    {
        "x": 17.29,
        "y": 19.962
    },
    {
        "x": 17.3,
        "y": 19.971
    },
    {
        "x": 17.31,
        "y": 19.971
    },
    {
        "x": 17.32,
        "y": 19.98
    },
    {
        "x": 17.33,
        "y": 19.98
    },
    {
        "x": 17.34,
        "y": 19.98
    },
    {
        "x": 17.35,
        "y": 19.989
    },
    {
        "x": 17.36,
        "y": 19.998
    },
    {
        "x": 17.37,
        "y": 19.998
    },
    {
        "x": 17.38,
        "y": 19.998
    },
    {
        "x": 17.39,
        "y": 20.007
    },
    {
        "x": 17.4,
        "y": 20.016
    },
    {
        "x": 17.41,
        "y": 20.016
    },
    {
        "x": 17.42,
        "y": 20.025
    },
    {
        "x": 17.43,
        "y": 20.025
    },
    {
        "x": 17.44,
        "y": 20.034
    },
    {
        "x": 17.45,
        "y": 20.034
    },
    {
        "x": 17.46,
        "y": 20.043
    },
    {
        "x": 17.47,
        "y": 20.043
    },
    {
        "x": 17.48,
        "y": 20.052
    },
    {
        "x": 17.49,
        "y": 20.052
    },
    {
        "x": 17.5,
        "y": 20.052
    },
    {
        "x": 17.51,
        "y": 20.061
    },
    {
        "x": 17.52,
        "y": 20.061
    },
    {
        "x": 17.53,
        "y": 20.07
    },
    {
        "x": 17.54,
        "y": 20.07
    },
    {
        "x": 17.55,
        "y": 20.079
    },
    {
        "x": 17.56,
        "y": 20.079
    },
    {
        "x": 17.57,
        "y": 20.088
    },
    {
        "x": 17.58,
        "y": 20.088
    },
    {
        "x": 17.59,
        "y": 20.097
    },
    {
        "x": 17.6,
        "y": 20.097
    },
    {
        "x": 17.61,
        "y": 20.097
    },
    {
        "x": 17.62,
        "y": 20.106
    },
    {
        "x": 17.63,
        "y": 20.115
    },
    {
        "x": 17.64,
        "y": 20.115
    },
    {
        "x": 17.65,
        "y": 20.124
    },
    {
        "x": 17.66,
        "y": 20.124
    },
    {
        "x": 17.67,
        "y": 20.133
    },
    {
        "x": 17.68,
        "y": 20.133
    },
    {
        "x": 17.69,
        "y": 20.133
    },
    {
        "x": 17.7,
        "y": 20.142
    },
    {
        "x": 17.71,
        "y": 20.142
    },
    {
        "x": 17.72,
        "y": 20.151
    },
    {
        "x": 17.73,
        "y": 20.151
    },
    {
        "x": 17.74,
        "y": 20.16
    },
    {
        "x": 17.75,
        "y": 20.16
    },
    {
        "x": 17.76,
        "y": 20.169
    },
    {
        "x": 17.77,
        "y": 20.169
    },
    {
        "x": 17.78,
        "y": 20.169
    },
    {
        "x": 17.79,
        "y": 20.178
    },
    {
        "x": 17.8,
        "y": 20.178
    },
    {
        "x": 17.81,
        "y": 20.187
    },
    {
        "x": 17.82,
        "y": 20.187
    },
    {
        "x": 17.83,
        "y": 20.196
    },
    {
        "x": 17.84,
        "y": 20.196
    },
    {
        "x": 17.85,
        "y": 20.205
    },
    {
        "x": 17.86,
        "y": 20.205
    },
    {
        "x": 17.87,
        "y": 20.205
    },
    {
        "x": 17.88,
        "y": 20.214
    },
    {
        "x": 17.89,
        "y": 20.214
    },
    {
        "x": 17.9,
        "y": 20.223
    },
    {
        "x": 17.91,
        "y": 20.223
    },
    {
        "x": 17.92,
        "y": 20.232
    },
    {
        "x": 17.93,
        "y": 20.232
    },
    {
        "x": 17.94,
        "y": 20.232
    },
    {
        "x": 17.95,
        "y": 20.241
    },
    {
        "x": 17.96,
        "y": 20.25
    },
    {
        "x": 17.97,
        "y": 20.25
    },
    {
        "x": 17.98,
        "y": 20.25
    },
    {
        "x": 17.99,
        "y": 20.259
    },
    {
        "x": 18,
        "y": 20.259
    },
    {
        "x": 18.01,
        "y": 20.268
    },
    {
        "x": 18.02,
        "y": 20.268
    },
    {
        "x": 18.03,
        "y": 20.277
    },
    {
        "x": 18.04,
        "y": 20.277
    },
    {
        "x": 18.05,
        "y": 20.277
    },
    {
        "x": 18.06,
        "y": 20.286
    },
    {
        "x": 18.07,
        "y": 20.286
    },
    {
        "x": 18.08,
        "y": 20.295
    },
    {
        "x": 18.09,
        "y": 20.295
    },
    {
        "x": 18.1,
        "y": 20.304
    },
    {
        "x": 18.11,
        "y": 20.304
    },
    {
        "x": 18.12,
        "y": 20.313
    },
    {
        "x": 18.13,
        "y": 20.313
    },
    {
        "x": 18.14,
        "y": 20.322
    },
    {
        "x": 18.15,
        "y": 20.322
    },
    {
        "x": 18.16,
        "y": 20.322
    },
    {
        "x": 18.17,
        "y": 20.331
    },
    {
        "x": 18.18,
        "y": 20.331
    },
    {
        "x": 18.19,
        "y": 20.34
    },
    {
        "x": 18.2,
        "y": 20.34
    },
    {
        "x": 18.21,
        "y": 20.349
    },
    {
        "x": 18.22,
        "y": 20.349
    },
    {
        "x": 18.23,
        "y": 20.358
    },
    {
        "x": 18.24,
        "y": 20.358
    },
    {
        "x": 18.25,
        "y": 20.367
    },
    {
        "x": 18.26,
        "y": 20.367
    },
    {
        "x": 18.27,
        "y": 20.367
    },
    {
        "x": 18.28,
        "y": 20.376
    },
    {
        "x": 18.29,
        "y": 20.376
    },
    {
        "x": 18.3,
        "y": 20.385
    },
    {
        "x": 18.31,
        "y": 20.385
    },
    {
        "x": 18.32,
        "y": 20.385
    },
    {
        "x": 18.33,
        "y": 20.394
    },
    {
        "x": 18.34,
        "y": 20.394
    },
    {
        "x": 18.35,
        "y": 20.403
    },
    {
        "x": 18.36,
        "y": 20.412
    },
    {
        "x": 18.37,
        "y": 20.412
    },
    {
        "x": 18.38,
        "y": 20.412
    },
    {
        "x": 18.39,
        "y": 20.412
    },
    {
        "x": 18.4,
        "y": 20.421
    },
    {
        "x": 18.41,
        "y": 20.421
    },
    {
        "x": 18.42,
        "y": 20.421
    },
    {
        "x": 18.43,
        "y": 20.43
    },
    {
        "x": 18.44,
        "y": 20.43
    },
    {
        "x": 18.45,
        "y": 20.439
    },
    {
        "x": 18.46,
        "y": 20.439
    },
    {
        "x": 18.47,
        "y": 20.448
    },
    {
        "x": 18.48,
        "y": 20.448
    },
    {
        "x": 18.49,
        "y": 20.457
    },
    {
        "x": 18.5,
        "y": 20.457
    },
    {
        "x": 18.51,
        "y": 20.457
    },
    {
        "x": 18.52,
        "y": 20.466
    },
    {
        "x": 18.53,
        "y": 20.466
    },
    {
        "x": 18.54,
        "y": 20.475
    },
    {
        "x": 18.55,
        "y": 20.475
    },
    {
        "x": 18.56,
        "y": 20.475
    },
    {
        "x": 18.57,
        "y": 20.484
    },
    {
        "x": 18.58,
        "y": 20.484
    },
    {
        "x": 18.59,
        "y": 20.493
    },
    {
        "x": 18.6,
        "y": 20.493
    },
    {
        "x": 18.61,
        "y": 20.502
    },
    {
        "x": 18.62,
        "y": 20.502
    },
    {
        "x": 18.63,
        "y": 20.502
    },
    {
        "x": 18.64,
        "y": 20.511
    },
    {
        "x": 18.65,
        "y": 20.511
    },
    {
        "x": 18.66,
        "y": 20.52
    },
    {
        "x": 18.67,
        "y": 20.52
    },
    {
        "x": 18.68,
        "y": 20.529
    },
    {
        "x": 18.69,
        "y": 20.529
    },
    {
        "x": 18.7,
        "y": 20.529
    },
    {
        "x": 18.71,
        "y": 20.538
    },
    {
        "x": 18.72,
        "y": 20.538
    },
    {
        "x": 18.73,
        "y": 20.547
    },
    {
        "x": 18.74,
        "y": 20.547
    },
    {
        "x": 18.75,
        "y": 20.547
    },
    {
        "x": 18.76,
        "y": 20.556
    },
    {
        "x": 18.77,
        "y": 20.556
    },
    {
        "x": 18.78,
        "y": 20.556
    },
    {
        "x": 18.79,
        "y": 20.565
    },
    {
        "x": 18.8,
        "y": 20.565
    },
    {
        "x": 18.81,
        "y": 20.574
    },
    {
        "x": 18.82,
        "y": 20.574
    },
    {
        "x": 18.83,
        "y": 20.583
    },
    {
        "x": 18.84,
        "y": 20.583
    },
    {
        "x": 18.85,
        "y": 20.583
    },
    {
        "x": 18.86,
        "y": 20.592
    },
    {
        "x": 18.87,
        "y": 20.592
    },
    {
        "x": 18.88,
        "y": 20.601
    },
    {
        "x": 18.89,
        "y": 20.601
    },
    {
        "x": 18.9,
        "y": 20.601
    },
    {
        "x": 18.91,
        "y": 20.61
    },
    {
        "x": 18.92,
        "y": 20.61
    },
    {
        "x": 18.93,
        "y": 20.619
    },
    {
        "x": 18.94,
        "y": 20.619
    },
    {
        "x": 18.95,
        "y": 20.619
    },
    {
        "x": 18.96,
        "y": 20.619
    },
    {
        "x": 18.97,
        "y": 20.628
    },
    {
        "x": 18.98,
        "y": 20.628
    },
    {
        "x": 18.99,
        "y": 20.637
    },
    {
        "x": 19,
        "y": 20.637
    },
    {
        "x": 19.01,
        "y": 20.637
    },
    {
        "x": 19.02,
        "y": 20.646
    },
    {
        "x": 19.03,
        "y": 20.646
    },
    {
        "x": 19.04,
        "y": 20.655
    },
    {
        "x": 19.05,
        "y": 20.655
    },
    {
        "x": 19.06,
        "y": 20.655
    },
    {
        "x": 19.07,
        "y": 20.664
    },
    {
        "x": 19.08,
        "y": 20.664
    },
    {
        "x": 19.09,
        "y": 20.664
    },
    {
        "x": 19.1,
        "y": 20.673
    },
    {
        "x": 19.11,
        "y": 20.673
    },
    {
        "x": 19.12,
        "y": 20.682
    },
    {
        "x": 19.13,
        "y": 20.682
    },
    {
        "x": 19.14,
        "y": 20.691
    },
    {
        "x": 19.15,
        "y": 20.691
    },
    {
        "x": 19.16,
        "y": 20.691
    },
    {
        "x": 19.17,
        "y": 20.7
    },
    {
        "x": 19.18,
        "y": 20.7
    },
    {
        "x": 19.19,
        "y": 20.7
    },
    {
        "x": 19.2,
        "y": 20.709
    },
    {
        "x": 19.21,
        "y": 20.709
    },
    {
        "x": 19.22,
        "y": 20.718
    },
    {
        "x": 19.23,
        "y": 20.718
    },
    {
        "x": 19.24,
        "y": 20.718
    },
    {
        "x": 19.25,
        "y": 20.727
    },
    {
        "x": 19.26,
        "y": 20.727
    },
    {
        "x": 19.27,
        "y": 20.727
    },
    {
        "x": 19.28,
        "y": 20.736
    },
    {
        "x": 19.29,
        "y": 20.736
    },
    {
        "x": 19.3,
        "y": 20.736
    },
    {
        "x": 19.31,
        "y": 20.745
    },
    {
        "x": 19.32,
        "y": 20.745
    },
    {
        "x": 19.33,
        "y": 20.745
    },
    {
        "x": 19.34,
        "y": 20.754
    },
    {
        "x": 19.35,
        "y": 20.754
    },
    {
        "x": 19.36,
        "y": 20.754
    },
    {
        "x": 19.37,
        "y": 20.763
    },
    {
        "x": 19.38,
        "y": 20.763
    },
    {
        "x": 19.39,
        "y": 20.772
    },
    {
        "x": 19.4,
        "y": 20.772
    },
    {
        "x": 19.41,
        "y": 20.772
    },
    {
        "x": 19.42,
        "y": 20.781
    },
    {
        "x": 19.43,
        "y": 20.781
    },
    {
        "x": 19.44,
        "y": 20.781
    },
    {
        "x": 19.45,
        "y": 20.79
    },
    {
        "x": 19.46,
        "y": 20.79
    },
    {
        "x": 19.47,
        "y": 20.799
    },
    {
        "x": 19.48,
        "y": 20.799
    },
    {
        "x": 19.49,
        "y": 20.799
    },
    {
        "x": 19.5,
        "y": 20.799
    },
    {
        "x": 19.51,
        "y": 20.808
    },
    {
        "x": 19.52,
        "y": 20.808
    },
    {
        "x": 19.53,
        "y": 20.817
    },
    {
        "x": 19.54,
        "y": 20.817
    },
    {
        "x": 19.55,
        "y": 20.817
    },
    {
        "x": 19.56,
        "y": 20.826
    },
    {
        "x": 19.57,
        "y": 20.826
    },
    {
        "x": 19.58,
        "y": 20.835
    },
    {
        "x": 19.59,
        "y": 20.835
    },
    {
        "x": 19.6,
        "y": 20.835
    },
    {
        "x": 19.61,
        "y": 20.835
    },
    {
        "x": 19.62,
        "y": 20.844
    },
    {
        "x": 19.63,
        "y": 20.853
    },
    {
        "x": 19.64,
        "y": 20.853
    },
    {
        "x": 19.65,
        "y": 20.853
    },
    {
        "x": 19.66,
        "y": 20.862
    },
    {
        "x": 19.67,
        "y": 20.862
    },
    {
        "x": 19.68,
        "y": 20.862
    },
    {
        "x": 19.69,
        "y": 20.871
    },
    {
        "x": 19.7,
        "y": 20.871
    },
    {
        "x": 19.71,
        "y": 20.871
    },
    {
        "x": 19.72,
        "y": 20.88
    },
    {
        "x": 19.73,
        "y": 20.88
    },
    {
        "x": 19.74,
        "y": 20.88
    },
    {
        "x": 19.75,
        "y": 20.889
    },
    {
        "x": 19.76,
        "y": 20.889
    },
    {
        "x": 19.77,
        "y": 20.889
    },
    {
        "x": 19.78,
        "y": 20.898
    },
    {
        "x": 19.79,
        "y": 20.898
    },
    {
        "x": 19.8,
        "y": 20.898
    },
    {
        "x": 19.81,
        "y": 20.907
    },
    {
        "x": 19.82,
        "y": 20.907
    },
    {
        "x": 19.83,
        "y": 20.907
    },
    {
        "x": 19.84,
        "y": 20.916
    },
    {
        "x": 19.85,
        "y": 20.916
    },
    {
        "x": 19.86,
        "y": 20.925
    },
    {
        "x": 19.87,
        "y": 20.925
    },
    {
        "x": 19.88,
        "y": 20.925
    },
    {
        "x": 19.89,
        "y": 20.925
    },
    {
        "x": 19.9,
        "y": 20.934
    },
    {
        "x": 19.91,
        "y": 20.934
    },
    {
        "x": 19.92,
        "y": 20.934
    },
    {
        "x": 19.93,
        "y": 20.943
    },
    {
        "x": 19.94,
        "y": 20.943
    },
    {
        "x": 19.95,
        "y": 20.952
    },
    {
        "x": 19.96,
        "y": 20.952
    },
    {
        "x": 19.97,
        "y": 20.952
    },
    {
        "x": 19.98,
        "y": 20.952
    },
    {
        "x": 19.99,
        "y": 20.961
    },
    {
        "x": 20,
        "y": 20.961
    },
    {
        "x": 20.01,
        "y": 20.97
    },
    {
        "x": 20.02,
        "y": 20.97
    },
    {
        "x": 20.03,
        "y": 20.97
    },
    {
        "x": 20.04,
        "y": 20.979
    },
    {
        "x": 20.05,
        "y": 20.979
    },
    {
        "x": 20.06,
        "y": 20.979
    },
    {
        "x": 20.07,
        "y": 20.979
    },
    {
        "x": 20.08,
        "y": 20.988
    },
    {
        "x": 20.09,
        "y": 20.988
    },
    {
        "x": 20.1,
        "y": 20.997
    },
    {
        "x": 20.11,
        "y": 20.997
    },
    {
        "x": 20.12,
        "y": 20.997
    },
    {
        "x": 20.13,
        "y": 21.006
    },
    {
        "x": 20.14,
        "y": 21.006
    },
    {
        "x": 20.15,
        "y": 21.006
    },
    {
        "x": 20.16,
        "y": 21.006
    },
    {
        "x": 20.17,
        "y": 21.015
    },
    {
        "x": 20.18,
        "y": 21.015
    },
    {
        "x": 20.19,
        "y": 21.015
    },
    {
        "x": 20.2,
        "y": 21.024
    },
    {
        "x": 20.21,
        "y": 21.024
    },
    {
        "x": 20.22,
        "y": 21.024
    },
    {
        "x": 20.23,
        "y": 21.024
    },
    {
        "x": 20.24,
        "y": 21.033
    },
    {
        "x": 20.25,
        "y": 21.033
    },
    {
        "x": 20.26,
        "y": 21.033
    },
    {
        "x": 20.27,
        "y": 21.042
    },
    {
        "x": 20.28,
        "y": 21.042
    },
    {
        "x": 20.29,
        "y": 21.042
    },
    {
        "x": 20.3,
        "y": 21.042
    },
    {
        "x": 20.31,
        "y": 21.051
    },
    {
        "x": 20.32,
        "y": 21.06
    },
    {
        "x": 20.33,
        "y": 21.06
    },
    {
        "x": 20.34,
        "y": 21.06
    },
    {
        "x": 20.35,
        "y": 21.06
    },
    {
        "x": 20.36,
        "y": 21.069
    },
    {
        "x": 20.37,
        "y": 21.069
    },
    {
        "x": 20.38,
        "y": 21.069
    },
    {
        "x": 20.39,
        "y": 21.069
    },
    {
        "x": 20.4,
        "y": 21.078
    },
    {
        "x": 20.41,
        "y": 21.078
    },
    {
        "x": 20.42,
        "y": 21.087
    },
    {
        "x": 20.43,
        "y": 21.087
    },
    {
        "x": 20.44,
        "y": 21.087
    },
    {
        "x": 20.45,
        "y": 21.087
    },
    {
        "x": 20.46,
        "y": 21.096
    },
    {
        "x": 20.47,
        "y": 21.096
    },
    {
        "x": 20.48,
        "y": 21.105
    },
    {
        "x": 20.49,
        "y": 21.105
    },
    {
        "x": 20.5,
        "y": 21.105
    },
    {
        "x": 20.51,
        "y": 21.105
    },
    {
        "x": 20.52,
        "y": 21.114
    },
    {
        "x": 20.53,
        "y": 21.114
    },
    {
        "x": 20.54,
        "y": 21.114
    },
    {
        "x": 20.55,
        "y": 21.123
    },
    {
        "x": 20.56,
        "y": 21.123
    },
    {
        "x": 20.57,
        "y": 21.123
    },
    {
        "x": 20.58,
        "y": 21.132
    },
    {
        "x": 20.59,
        "y": 21.132
    },
    {
        "x": 20.6,
        "y": 21.132
    },
    {
        "x": 20.61,
        "y": 21.132
    },
    {
        "x": 20.62,
        "y": 21.141
    },
    {
        "x": 20.63,
        "y": 21.141
    },
    {
        "x": 20.64,
        "y": 21.141
    },
    {
        "x": 20.65,
        "y": 21.15
    },
    {
        "x": 20.66,
        "y": 21.15
    },
    {
        "x": 20.67,
        "y": 21.15
    },
    {
        "x": 20.68,
        "y": 21.159
    },
    {
        "x": 20.69,
        "y": 21.159
    },
    {
        "x": 20.7,
        "y": 21.159
    },
    {
        "x": 20.71,
        "y": 21.168
    },
    {
        "x": 20.72,
        "y": 21.168
    },
    {
        "x": 20.73,
        "y": 21.168
    },
    {
        "x": 20.74,
        "y": 21.177
    },
    {
        "x": 20.75,
        "y": 21.177
    },
    {
        "x": 20.76,
        "y": 21.177
    },
    {
        "x": 20.77,
        "y": 21.186
    },
    {
        "x": 20.78,
        "y": 21.186
    },
    {
        "x": 20.79,
        "y": 21.186
    },
    {
        "x": 20.8,
        "y": 21.195
    },
    {
        "x": 20.81,
        "y": 21.195
    },
    {
        "x": 20.82,
        "y": 21.195
    },
    {
        "x": 20.83,
        "y": 21.195
    },
    {
        "x": 20.84,
        "y": 21.195
    },
    {
        "x": 20.85,
        "y": 21.204
    },
    {
        "x": 20.86,
        "y": 21.204
    },
    {
        "x": 20.87,
        "y": 21.213
    },
    {
        "x": 20.88,
        "y": 21.213
    },
    {
        "x": 20.89,
        "y": 21.213
    },
    {
        "x": 20.9,
        "y": 21.213
    },
    {
        "x": 20.91,
        "y": 21.222
    },
    {
        "x": 20.92,
        "y": 21.222
    },
    {
        "x": 20.93,
        "y": 21.222
    },
    {
        "x": 20.94,
        "y": 21.231
    },
    {
        "x": 20.95,
        "y": 21.231
    },
    {
        "x": 20.96,
        "y": 21.231
    },
    {
        "x": 20.97,
        "y": 21.231
    },
    {
        "x": 20.98,
        "y": 21.24
    },
    {
        "x": 20.99,
        "y": 21.24
    },
    {
        "x": 21,
        "y": 21.24
    },
    {
        "x": 21.01,
        "y": 21.249
    },
    {
        "x": 21.02,
        "y": 21.249
    },
    {
        "x": 21.03,
        "y": 21.249
    },
    {
        "x": 21.04,
        "y": 21.258
    },
    {
        "x": 21.05,
        "y": 21.258
    },
    {
        "x": 21.06,
        "y": 21.258
    },
    {
        "x": 21.07,
        "y": 21.258
    },
    {
        "x": 21.08,
        "y": 21.267
    },
    {
        "x": 21.09,
        "y": 21.267
    },
    {
        "x": 21.1,
        "y": 21.267
    },
    {
        "x": 21.11,
        "y": 21.267
    },
    {
        "x": 21.12,
        "y": 21.276
    },
    {
        "x": 21.13,
        "y": 21.276
    },
    {
        "x": 21.14,
        "y": 21.276
    },
    {
        "x": 21.15,
        "y": 21.285
    },
    {
        "x": 21.16,
        "y": 21.285
    },
    {
        "x": 21.17,
        "y": 21.285
    },
    {
        "x": 21.18,
        "y": 21.285
    },
    {
        "x": 21.19,
        "y": 21.294
    },
    {
        "x": 21.2,
        "y": 21.294
    },
    {
        "x": 21.21,
        "y": 21.294
    },
    {
        "x": 21.22,
        "y": 21.303
    },
    {
        "x": 21.23,
        "y": 21.303
    },
    {
        "x": 21.24,
        "y": 21.303
    },
    {
        "x": 21.25,
        "y": 21.312
    },
    {
        "x": 21.26,
        "y": 21.312
    },
    {
        "x": 21.27,
        "y": 21.312
    },
    {
        "x": 21.28,
        "y": 21.321
    },
    {
        "x": 21.29,
        "y": 21.321
    },
    {
        "x": 21.3,
        "y": 21.321
    },
    {
        "x": 21.31,
        "y": 21.321
    },
    {
        "x": 21.32,
        "y": 21.33
    },
    {
        "x": 21.33,
        "y": 21.33
    },
    {
        "x": 21.34,
        "y": 21.33
    },
    {
        "x": 21.35,
        "y": 21.33
    },
    {
        "x": 21.36,
        "y": 21.33
    },
    {
        "x": 21.37,
        "y": 21.339
    },
    {
        "x": 21.38,
        "y": 21.339
    },
    {
        "x": 21.39,
        "y": 21.339
    },
    {
        "x": 21.4,
        "y": 21.348
    },
    {
        "x": 21.41,
        "y": 21.348
    },
    {
        "x": 21.42,
        "y": 21.348
    },
    {
        "x": 21.43,
        "y": 21.348
    },
    {
        "x": 21.44,
        "y": 21.357
    },
    {
        "x": 21.45,
        "y": 21.357
    },
    {
        "x": 21.46,
        "y": 21.366
    },
    {
        "x": 21.47,
        "y": 21.366
    },
    {
        "x": 21.48,
        "y": 21.366
    },
    {
        "x": 21.49,
        "y": 21.366
    },
    {
        "x": 21.5,
        "y": 21.366
    },
    {
        "x": 21.51,
        "y": 21.375
    },
    {
        "x": 21.52,
        "y": 21.375
    },
    {
        "x": 21.53,
        "y": 21.375
    },
    {
        "x": 21.54,
        "y": 21.384
    },
    {
        "x": 21.55,
        "y": 21.384
    },
    {
        "x": 21.56,
        "y": 21.384
    },
    {
        "x": 21.57,
        "y": 21.384
    },
    {
        "x": 21.58,
        "y": 21.384
    },
    {
        "x": 21.59,
        "y": 21.393
    },
    {
        "x": 21.6,
        "y": 21.393
    },
    {
        "x": 21.61,
        "y": 21.393
    },
    {
        "x": 21.62,
        "y": 21.393
    },
    {
        "x": 21.63,
        "y": 21.393
    },
    {
        "x": 21.64,
        "y": 21.402
    },
    {
        "x": 21.65,
        "y": 21.402
    },
    {
        "x": 21.66,
        "y": 21.411
    },
    {
        "x": 21.67,
        "y": 21.411
    },
    {
        "x": 21.68,
        "y": 21.411
    },
    {
        "x": 21.69,
        "y": 21.411
    },
    {
        "x": 21.7,
        "y": 21.42
    },
    {
        "x": 21.71,
        "y": 21.42
    },
    {
        "x": 21.72,
        "y": 21.42
    },
    {
        "x": 21.73,
        "y": 21.42
    },
    {
        "x": 21.74,
        "y": 21.429
    },
    {
        "x": 21.75,
        "y": 21.429
    },
    {
        "x": 21.76,
        "y": 21.429
    },
    {
        "x": 21.77,
        "y": 21.429
    },
    {
        "x": 21.78,
        "y": 21.438
    },
    {
        "x": 21.79,
        "y": 21.438
    },
    {
        "x": 21.8,
        "y": 21.438
    },
    {
        "x": 21.81,
        "y": 21.438
    },
    {
        "x": 21.82,
        "y": 21.447
    },
    {
        "x": 21.83,
        "y": 21.447
    },
    {
        "x": 21.84,
        "y": 21.447
    },
    {
        "x": 21.85,
        "y": 21.447
    },
    {
        "x": 21.86,
        "y": 21.456
    },
    {
        "x": 21.87,
        "y": 21.456
    },
    {
        "x": 21.88,
        "y": 21.456
    },
    {
        "x": 21.89,
        "y": 21.465
    },
    {
        "x": 21.9,
        "y": 21.465
    },
    {
        "x": 21.91,
        "y": 21.465
    },
    {
        "x": 21.92,
        "y": 21.465
    },
    {
        "x": 21.93,
        "y": 21.465
    },
    {
        "x": 21.94,
        "y": 21.474
    },
    {
        "x": 21.95,
        "y": 21.474
    },
    {
        "x": 21.96,
        "y": 21.474
    },
    {
        "x": 21.97,
        "y": 21.483
    },
    {
        "x": 21.98,
        "y": 21.483
    },
    {
        "x": 21.99,
        "y": 21.483
    },
    {
        "x": 22,
        "y": 21.483
    },
    {
        "x": 22.01,
        "y": 21.492
    },
    {
        "x": 22.02,
        "y": 21.492
    },
    {
        "x": 22.03,
        "y": 21.492
    },
    {
        "x": 22.04,
        "y": 21.492
    },
    {
        "x": 22.05,
        "y": 21.501
    },
    {
        "x": 22.06,
        "y": 21.501
    },
    {
        "x": 22.07,
        "y": 21.501
    },
    {
        "x": 22.08,
        "y": 21.51
    },
    {
        "x": 22.09,
        "y": 21.51
    },
    {
        "x": 22.1,
        "y": 21.51
    },
    {
        "x": 22.11,
        "y": 21.51
    },
    {
        "x": 22.12,
        "y": 21.519
    },
    {
        "x": 22.13,
        "y": 21.519
    },
    {
        "x": 22.14,
        "y": 21.519
    },
    {
        "x": 22.15,
        "y": 21.519
    },
    {
        "x": 22.16,
        "y": 21.519
    },
    {
        "x": 22.17,
        "y": 21.528
    },
    {
        "x": 22.18,
        "y": 21.528
    },
    {
        "x": 22.19,
        "y": 21.528
    },
    {
        "x": 22.2,
        "y": 21.528
    },
    {
        "x": 22.21,
        "y": 21.537
    },
    {
        "x": 22.22,
        "y": 21.537
    },
    {
        "x": 22.23,
        "y": 21.537
    },
    {
        "x": 22.24,
        "y": 21.546
    },
    {
        "x": 22.25,
        "y": 21.546
    },
    {
        "x": 22.26,
        "y": 21.546
    },
    {
        "x": 22.27,
        "y": 21.546
    },
    {
        "x": 22.28,
        "y": 21.546
    },
    {
        "x": 22.29,
        "y": 21.555
    },
    {
        "x": 22.3,
        "y": 21.555
    },
    {
        "x": 22.31,
        "y": 21.555
    },
    {
        "x": 22.32,
        "y": 21.555
    },
    {
        "x": 22.33,
        "y": 21.564
    },
    {
        "x": 22.34,
        "y": 21.564
    },
    {
        "x": 22.35,
        "y": 21.564
    },
    {
        "x": 22.36,
        "y": 21.564
    },
    {
        "x": 22.37,
        "y": 21.573
    },
    {
        "x": 22.38,
        "y": 21.573
    },
    {
        "x": 22.39,
        "y": 21.573
    },
    {
        "x": 22.4,
        "y": 21.582
    },
    {
        "x": 22.41,
        "y": 21.582
    },
    {
        "x": 22.42,
        "y": 21.582
    },
    {
        "x": 22.43,
        "y": 21.582
    },
    {
        "x": 22.44,
        "y": 21.582
    },
    {
        "x": 22.45,
        "y": 21.591
    },
    {
        "x": 22.46,
        "y": 21.591
    },
    {
        "x": 22.47,
        "y": 21.591
    },
    {
        "x": 22.48,
        "y": 21.591
    },
    {
        "x": 22.49,
        "y": 21.591
    },
    {
        "x": 22.5,
        "y": 21.6
    },
    {
        "x": 22.51,
        "y": 21.6
    },
    {
        "x": 22.52,
        "y": 21.609
    },
    {
        "x": 22.53,
        "y": 21.609
    },
    {
        "x": 22.54,
        "y": 21.609
    },
    {
        "x": 22.55,
        "y": 21.609
    },
    {
        "x": 22.56,
        "y": 21.618
    },
    {
        "x": 22.57,
        "y": 21.618
    },
    {
        "x": 22.58,
        "y": 21.618
    },
    {
        "x": 22.59,
        "y": 21.618
    },
    {
        "x": 22.6,
        "y": 21.618
    },
    {
        "x": 22.61,
        "y": 21.627
    },
    {
        "x": 22.62,
        "y": 21.627
    },
    {
        "x": 22.63,
        "y": 21.627
    },
    {
        "x": 22.64,
        "y": 21.627
    },
    {
        "x": 22.65,
        "y": 21.627
    },
    {
        "x": 22.66,
        "y": 21.636
    },
    {
        "x": 22.67,
        "y": 21.636
    },
    {
        "x": 22.68,
        "y": 21.636
    },
    {
        "x": 22.69,
        "y": 21.636
    },
    {
        "x": 22.7,
        "y": 21.636
    },
    {
        "x": 22.71,
        "y": 21.645
    },
    {
        "x": 22.72,
        "y": 21.645
    },
    {
        "x": 22.73,
        "y": 21.645
    },
    {
        "x": 22.74,
        "y": 21.645
    },
    {
        "x": 22.75,
        "y": 21.654
    },
    {
        "x": 22.76,
        "y": 21.654
    },
    {
        "x": 22.77,
        "y": 21.654
    },
    {
        "x": 22.78,
        "y": 21.654
    },
    {
        "x": 22.79,
        "y": 21.663
    },
    {
        "x": 22.8,
        "y": 21.663
    },
    {
        "x": 22.81,
        "y": 21.663
    },
    {
        "x": 22.82,
        "y": 21.663
    },
    {
        "x": 22.83,
        "y": 21.663
    },
    {
        "x": 22.84,
        "y": 21.672
    },
    {
        "x": 22.85,
        "y": 21.672
    },
    {
        "x": 22.86,
        "y": 21.672
    },
    {
        "x": 22.87,
        "y": 21.672
    },
    {
        "x": 22.88,
        "y": 21.681
    },
    {
        "x": 22.89,
        "y": 21.681
    },
    {
        "x": 22.9,
        "y": 21.681
    },
    {
        "x": 22.91,
        "y": 21.681
    },
    {
        "x": 22.92,
        "y": 21.681
    },
    {
        "x": 22.93,
        "y": 21.69
    },
    {
        "x": 22.94,
        "y": 21.69
    },
    {
        "x": 22.95,
        "y": 21.69
    },
    {
        "x": 22.96,
        "y": 21.69
    },
    {
        "x": 22.97,
        "y": 21.69
    },
    {
        "x": 22.98,
        "y": 21.699
    },
    {
        "x": 22.99,
        "y": 21.699
    },
    {
        "x": 23,
        "y": 21.699
    },
    {
        "x": 23.01,
        "y": 21.708
    },
    {
        "x": 23.02,
        "y": 21.708
    },
    {
        "x": 23.03,
        "y": 21.708
    },
    {
        "x": 23.04,
        "y": 21.708
    },
    {
        "x": 23.05,
        "y": 21.717
    },
    {
        "x": 23.06,
        "y": 21.717
    },
    {
        "x": 23.07,
        "y": 21.717
    },
    {
        "x": 23.08,
        "y": 21.717
    },
    {
        "x": 23.09,
        "y": 21.726
    },
    {
        "x": 23.1,
        "y": 21.726
    },
    {
        "x": 23.11,
        "y": 21.726
    },
    {
        "x": 23.12,
        "y": 21.726
    },
    {
        "x": 23.13,
        "y": 21.726
    },
    {
        "x": 23.14,
        "y": 21.735
    },
    {
        "x": 23.15,
        "y": 21.735
    },
    {
        "x": 23.16,
        "y": 21.735
    },
    {
        "x": 23.17,
        "y": 21.735
    },
    {
        "x": 23.18,
        "y": 21.735
    },
    {
        "x": 23.19,
        "y": 21.744
    },
    {
        "x": 23.2,
        "y": 21.744
    },
    {
        "x": 23.21,
        "y": 21.744
    },
    {
        "x": 23.22,
        "y": 21.744
    },
    {
        "x": 23.23,
        "y": 21.744
    },
    {
        "x": 23.24,
        "y": 21.753
    },
    {
        "x": 23.25,
        "y": 21.744
    },
    {
        "x": 23.26,
        "y": 21.753
    },
    {
        "x": 23.27,
        "y": 21.753
    },
    {
        "x": 23.28,
        "y": 21.753
    },
    {
        "x": 23.29,
        "y": 21.753
    },
    {
        "x": 23.3,
        "y": 21.762
    },
    {
        "x": 23.31,
        "y": 21.762
    },
    {
        "x": 23.32,
        "y": 21.762
    },
    {
        "x": 23.33,
        "y": 21.771
    },
    {
        "x": 23.34,
        "y": 21.762
    },
    {
        "x": 23.35,
        "y": 21.771
    },
    {
        "x": 23.36,
        "y": 21.771
    },
    {
        "x": 23.37,
        "y": 21.771
    },
    {
        "x": 23.38,
        "y": 21.78
    },
    {
        "x": 23.39,
        "y": 21.78
    },
    {
        "x": 23.4,
        "y": 21.78
    },
    {
        "x": 23.41,
        "y": 21.78
    },
    {
        "x": 23.42,
        "y": 21.789
    },
    {
        "x": 23.43,
        "y": 21.789
    },
    {
        "x": 23.44,
        "y": 21.789
    },
    {
        "x": 23.45,
        "y": 21.789
    },
    {
        "x": 23.46,
        "y": 21.789
    },
    {
        "x": 23.47,
        "y": 21.789
    },
    {
        "x": 23.48,
        "y": 21.789
    },
    {
        "x": 23.49,
        "y": 21.789
    },
    {
        "x": 23.5,
        "y": 21.798
    },
    {
        "x": 23.51,
        "y": 21.798
    },
    {
        "x": 23.52,
        "y": 21.798
    },
    {
        "x": 23.53,
        "y": 21.807
    },
    {
        "x": 23.54,
        "y": 21.807
    },
    {
        "x": 23.55,
        "y": 21.807
    },
    {
        "x": 23.56,
        "y": 21.807
    },
    {
        "x": 23.57,
        "y": 21.807
    },
    {
        "x": 23.58,
        "y": 21.816
    },
    {
        "x": 23.59,
        "y": 21.816
    },
    {
        "x": 23.6,
        "y": 21.816
    },
    {
        "x": 23.61,
        "y": 21.816
    },
    {
        "x": 23.62,
        "y": 21.825
    },
    {
        "x": 23.63,
        "y": 21.825
    },
    {
        "x": 23.64,
        "y": 21.825
    },
    {
        "x": 23.65,
        "y": 21.825
    },
    {
        "x": 23.66,
        "y": 21.834
    },
    {
        "x": 23.67,
        "y": 21.834
    },
    {
        "x": 23.68,
        "y": 21.834
    },
    {
        "x": 23.69,
        "y": 21.834
    },
    {
        "x": 23.7,
        "y": 21.843
    },
    {
        "x": 23.71,
        "y": 21.843
    },
    {
        "x": 23.72,
        "y": 21.843
    },
    {
        "x": 23.73,
        "y": 21.843
    },
    {
        "x": 23.74,
        "y": 21.843
    },
    {
        "x": 23.75,
        "y": 21.852
    },
    {
        "x": 23.76,
        "y": 21.852
    },
    {
        "x": 23.77,
        "y": 21.852
    },
    {
        "x": 23.78,
        "y": 21.852
    },
    {
        "x": 23.79,
        "y": 21.852
    },
    {
        "x": 23.8,
        "y": 21.852
    },
    {
        "x": 23.81,
        "y": 21.861
    },
    {
        "x": 23.82,
        "y": 21.861
    },
    {
        "x": 23.83,
        "y": 21.861
    },
    {
        "x": 23.84,
        "y": 21.861
    },
    {
        "x": 23.85,
        "y": 21.87
    },
    {
        "x": 23.86,
        "y": 21.87
    },
    {
        "x": 23.87,
        "y": 21.87
    },
    {
        "x": 23.88,
        "y": 21.87
    },
    {
        "x": 23.89,
        "y": 21.87
    },
    {
        "x": 23.9,
        "y": 21.87
    },
    {
        "x": 23.91,
        "y": 21.87
    },
    {
        "x": 23.92,
        "y": 21.879
    },
    {
        "x": 23.93,
        "y": 21.879
    },
    {
        "x": 23.94,
        "y": 21.879
    },
    {
        "x": 23.95,
        "y": 21.879
    },
    {
        "x": 23.96,
        "y": 21.888
    },
    {
        "x": 23.97,
        "y": 21.879
    },
    {
        "x": 23.98,
        "y": 21.888
    },
    {
        "x": 23.99,
        "y": 21.888
    },
    {
        "x": 24,
        "y": 21.888
    },
    {
        "x": 24.01,
        "y": 21.888
    },
    {
        "x": 24.02,
        "y": 21.897
    },
    {
        "x": 24.03,
        "y": 21.897
    },
    {
        "x": 24.04,
        "y": 21.897
    },
    {
        "x": 24.05,
        "y": 21.897
    },
    {
        "x": 24.06,
        "y": 21.897
    },
    {
        "x": 24.07,
        "y": 21.906
    },
    {
        "x": 24.08,
        "y": 21.906
    },
    {
        "x": 24.09,
        "y": 21.906
    },
    {
        "x": 24.1,
        "y": 21.906
    },
    {
        "x": 24.11,
        "y": 21.906
    },
    {
        "x": 24.12,
        "y": 21.915
    },
    {
        "x": 24.13,
        "y": 21.915
    },
    {
        "x": 24.14,
        "y": 21.915
    },
    {
        "x": 24.15,
        "y": 21.915
    },
    {
        "x": 24.16,
        "y": 21.924
    },
    {
        "x": 24.17,
        "y": 21.915
    },
    {
        "x": 24.18,
        "y": 21.924
    },
    {
        "x": 24.19,
        "y": 21.924
    },
    {
        "x": 24.2,
        "y": 21.924
    },
    {
        "x": 24.21,
        "y": 21.924
    },
    {
        "x": 24.22,
        "y": 21.924
    },
    {
        "x": 24.23,
        "y": 21.933
    },
    {
        "x": 24.24,
        "y": 21.933
    },
    {
        "x": 24.25,
        "y": 21.933
    },
    {
        "x": 24.26,
        "y": 21.933
    },
    {
        "x": 24.27,
        "y": 21.933
    },
    {
        "x": 24.28,
        "y": 21.942
    },
    {
        "x": 24.29,
        "y": 21.942
    },
    {
        "x": 24.3,
        "y": 21.942
    },
    {
        "x": 24.31,
        "y": 21.942
    },
    {
        "x": 24.32,
        "y": 21.942
    },
    {
        "x": 24.33,
        "y": 21.951
    },
    {
        "x": 24.34,
        "y": 21.951
    },
    {
        "x": 24.35,
        "y": 21.951
    },
    {
        "x": 24.36,
        "y": 21.951
    },
    {
        "x": 24.37,
        "y": 21.951
    },
    {
        "x": 24.38,
        "y": 21.96
    },
    {
        "x": 24.39,
        "y": 21.951
    },
    {
        "x": 24.4,
        "y": 21.96
    },
    {
        "x": 24.41,
        "y": 21.96
    },
    {
        "x": 24.42,
        "y": 21.96
    },
    {
        "x": 24.43,
        "y": 21.969
    },
    {
        "x": 24.44,
        "y": 21.969
    },
    {
        "x": 24.45,
        "y": 21.969
    },
    {
        "x": 24.46,
        "y": 21.969
    },
    {
        "x": 24.47,
        "y": 21.969
    },
    {
        "x": 24.48,
        "y": 21.969
    },
    {
        "x": 24.49,
        "y": 21.978
    },
    {
        "x": 24.5,
        "y": 21.978
    },
    {
        "x": 24.51,
        "y": 21.978
    },
    {
        "x": 24.52,
        "y": 21.978
    },
    {
        "x": 24.53,
        "y": 21.987
    },
    {
        "x": 24.54,
        "y": 21.987
    },
    {
        "x": 24.55,
        "y": 21.987
    },
    {
        "x": 24.56,
        "y": 21.987
    },
    {
        "x": 24.57,
        "y": 21.987
    },
    {
        "x": 24.58,
        "y": 21.987
    },
    {
        "x": 24.59,
        "y": 21.996
    },
    {
        "x": 24.6,
        "y": 21.996
    },
    {
        "x": 24.61,
        "y": 21.996
    },
    {
        "x": 24.62,
        "y": 21.996
    },
    {
        "x": 24.63,
        "y": 21.996
    },
    {
        "x": 24.64,
        "y": 21.996
    },
    {
        "x": 24.65,
        "y": 22.005
    },
    {
        "x": 24.66,
        "y": 22.005
    },
    {
        "x": 24.67,
        "y": 22.005
    },
    {
        "x": 24.68,
        "y": 22.005
    },
    {
        "x": 24.69,
        "y": 22.014
    },
    {
        "x": 24.7,
        "y": 22.005
    },
    {
        "x": 24.71,
        "y": 22.014
    },
    {
        "x": 24.72,
        "y": 22.014
    },
    {
        "x": 24.73,
        "y": 22.014
    },
    {
        "x": 24.74,
        "y": 22.014
    },
    {
        "x": 24.75,
        "y": 22.014
    },
    {
        "x": 24.76,
        "y": 22.023
    },
    {
        "x": 24.77,
        "y": 22.023
    },
    {
        "x": 24.78,
        "y": 22.023
    },
    {
        "x": 24.79,
        "y": 22.023
    },
    {
        "x": 24.8,
        "y": 22.023
    },
    {
        "x": 24.81,
        "y": 22.023
    },
    {
        "x": 24.82,
        "y": 22.032
    },
    {
        "x": 24.83,
        "y": 22.032
    },
    {
        "x": 24.84,
        "y": 22.032
    },
    {
        "x": 24.85,
        "y": 22.032
    },
    {
        "x": 24.86,
        "y": 22.032
    },
    {
        "x": 24.87,
        "y": 22.041
    },
    {
        "x": 24.88,
        "y": 22.041
    },
    {
        "x": 24.89,
        "y": 22.041
    },
    {
        "x": 24.9,
        "y": 22.041
    },
    {
        "x": 24.91,
        "y": 22.041
    },
    {
        "x": 24.92,
        "y": 22.05
    },
    {
        "x": 24.93,
        "y": 22.05
    },
    {
        "x": 24.94,
        "y": 22.05
    },
    {
        "x": 24.95,
        "y": 22.05
    },
    {
        "x": 24.96,
        "y": 22.05
    },
    {
        "x": 24.97,
        "y": 22.05
    },
    {
        "x": 24.98,
        "y": 22.059
    },
    {
        "x": 24.99,
        "y": 22.059
    },
    {
        "x": 25,
        "y": 22.059
    },
    {
        "x": 25.01,
        "y": 22.059
    },
    {
        "x": 25.02,
        "y": 22.059
    },
    {
        "x": 25.03,
        "y": 22.068
    },
    {
        "x": 25.04,
        "y": 22.068
    },
    {
        "x": 25.05,
        "y": 22.068
    },
    {
        "x": 25.06,
        "y": 22.068
    },
    {
        "x": 25.07,
        "y": 22.068
    },
    {
        "x": 25.08,
        "y": 22.068
    },
    {
        "x": 25.09,
        "y": 22.068
    },
    {
        "x": 25.1,
        "y": 22.077
    },
    {
        "x": 25.11,
        "y": 22.077
    },
    {
        "x": 25.12,
        "y": 22.077
    },
    {
        "x": 25.13,
        "y": 22.077
    },
    {
        "x": 25.14,
        "y": 22.077
    },
    {
        "x": 25.15,
        "y": 22.086
    },
    {
        "x": 25.16,
        "y": 22.086
    },
    {
        "x": 25.17,
        "y": 22.086
    },
    {
        "x": 25.18,
        "y": 22.086
    },
    {
        "x": 25.19,
        "y": 22.086
    },
    {
        "x": 25.2,
        "y": 22.086
    },
    {
        "x": 25.21,
        "y": 22.095
    },
    {
        "x": 25.22,
        "y": 22.095
    },
    {
        "x": 25.23,
        "y": 22.095
    },
    {
        "x": 25.24,
        "y": 22.095
    },
    {
        "x": 25.25,
        "y": 22.095
    },
    {
        "x": 25.26,
        "y": 22.095
    },
    {
        "x": 25.27,
        "y": 22.095
    },
    {
        "x": 25.28,
        "y": 22.104
    },
    {
        "x": 25.29,
        "y": 22.104
    },
    {
        "x": 25.3,
        "y": 22.104
    },
    {
        "x": 25.31,
        "y": 22.104
    },
    {
        "x": 25.32,
        "y": 22.104
    },
    {
        "x": 25.33,
        "y": 22.104
    },
    {
        "x": 25.34,
        "y": 22.113
    },
    {
        "x": 25.35,
        "y": 22.113
    },
    {
        "x": 25.36,
        "y": 22.113
    },
    {
        "x": 25.37,
        "y": 22.113
    },
    {
        "x": 25.38,
        "y": 22.113
    },
    {
        "x": 25.39,
        "y": 22.113
    },
    {
        "x": 25.4,
        "y": 22.122
    },
    {
        "x": 25.41,
        "y": 22.122
    },
    {
        "x": 25.42,
        "y": 22.122
    },
    {
        "x": 25.43,
        "y": 22.122
    },
    {
        "x": 25.44,
        "y": 22.122
    },
    {
        "x": 25.45,
        "y": 22.122
    },
    {
        "x": 25.46,
        "y": 22.131
    },
    {
        "x": 25.47,
        "y": 22.122
    },
    {
        "x": 25.48,
        "y": 22.131
    },
    {
        "x": 25.49,
        "y": 22.131
    },
    {
        "x": 25.5,
        "y": 22.131
    },
    {
        "x": 25.51,
        "y": 22.131
    },
    {
        "x": 25.52,
        "y": 22.14
    },
    {
        "x": 25.53,
        "y": 22.131
    },
    {
        "x": 25.54,
        "y": 22.14
    },
    {
        "x": 25.55,
        "y": 22.14
    },
    {
        "x": 25.56,
        "y": 22.14
    },
    {
        "x": 25.57,
        "y": 22.149
    },
    {
        "x": 25.58,
        "y": 22.149
    },
    {
        "x": 25.59,
        "y": 22.149
    },
    {
        "x": 25.6,
        "y": 22.149
    },
    {
        "x": 25.61,
        "y": 22.149
    },
    {
        "x": 25.62,
        "y": 22.149
    },
    {
        "x": 25.63,
        "y": 22.149
    },
    {
        "x": 25.64,
        "y": 22.158
    },
    {
        "x": 25.65,
        "y": 22.158
    },
    {
        "x": 25.66,
        "y": 22.158
    },
    {
        "x": 25.67,
        "y": 22.158
    },
    {
        "x": 25.68,
        "y": 22.158
    },
    {
        "x": 25.69,
        "y": 22.158
    },
    {
        "x": 25.7,
        "y": 22.158
    },
    {
        "x": 25.71,
        "y": 22.167
    },
    {
        "x": 25.72,
        "y": 22.167
    },
    {
        "x": 25.73,
        "y": 22.167
    },
    {
        "x": 25.74,
        "y": 22.167
    },
    {
        "x": 25.75,
        "y": 22.167
    },
    {
        "x": 25.76,
        "y": 22.167
    },
    {
        "x": 25.77,
        "y": 22.167
    },
    {
        "x": 25.78,
        "y": 22.176
    },
    {
        "x": 25.79,
        "y": 22.176
    },
    {
        "x": 25.8,
        "y": 22.176
    },
    {
        "x": 25.81,
        "y": 22.176
    },
    {
        "x": 25.82,
        "y": 22.185
    },
    {
        "x": 25.83,
        "y": 22.176
    },
    {
        "x": 25.84,
        "y": 22.185
    },
    {
        "x": 25.85,
        "y": 22.185
    },
    {
        "x": 25.86,
        "y": 22.185
    },
    {
        "x": 25.87,
        "y": 22.185
    },
    {
        "x": 25.88,
        "y": 22.194
    },
    {
        "x": 25.89,
        "y": 22.185
    },
    {
        "x": 25.9,
        "y": 22.194
    },
    {
        "x": 25.91,
        "y": 22.194
    },
    {
        "x": 25.92,
        "y": 22.194
    },
    {
        "x": 25.93,
        "y": 22.194
    },
    {
        "x": 25.94,
        "y": 22.194
    },
    {
        "x": 25.95,
        "y": 22.203
    },
    {
        "x": 25.96,
        "y": 22.203
    },
    {
        "x": 25.97,
        "y": 22.203
    },
    {
        "x": 25.98,
        "y": 22.203
    },
    {
        "x": 25.99,
        "y": 22.203
    },
    {
        "x": 26,
        "y": 22.203
    },
    {
        "x": 26.01,
        "y": 22.203
    },
    {
        "x": 26.02,
        "y": 22.212
    },
    {
        "x": 26.03,
        "y": 22.212
    },
    {
        "x": 26.04,
        "y": 22.212
    },
    {
        "x": 26.05,
        "y": 22.212
    },
    {
        "x": 26.06,
        "y": 22.212
    },
    {
        "x": 26.07,
        "y": 22.212
    },
    {
        "x": 26.08,
        "y": 22.221
    },
    {
        "x": 26.09,
        "y": 22.221
    },
    {
        "x": 26.1,
        "y": 22.221
    },
    {
        "x": 26.11,
        "y": 22.221
    },
    {
        "x": 26.12,
        "y": 22.23
    },
    {
        "x": 26.13,
        "y": 22.221
    },
    {
        "x": 26.14,
        "y": 22.221
    },
    {
        "x": 26.15,
        "y": 22.23
    },
    {
        "x": 26.16,
        "y": 22.23
    },
    {
        "x": 26.17,
        "y": 22.23
    },
    {
        "x": 26.18,
        "y": 22.23
    },
    {
        "x": 26.19,
        "y": 22.23
    },
    {
        "x": 26.2,
        "y": 22.23
    },
    {
        "x": 26.21,
        "y": 22.23
    },
    {
        "x": 26.22,
        "y": 22.23
    },
    {
        "x": 26.23,
        "y": 22.239
    },
    {
        "x": 26.24,
        "y": 22.239
    },
    {
        "x": 26.25,
        "y": 22.239
    },
    {
        "x": 26.26,
        "y": 22.239
    },
    {
        "x": 26.27,
        "y": 22.239
    },
    {
        "x": 26.28,
        "y": 22.239
    },
    {
        "x": 26.29,
        "y": 22.248
    },
    {
        "x": 26.3,
        "y": 22.248
    },
    {
        "x": 26.31,
        "y": 22.248
    },
    {
        "x": 26.32,
        "y": 22.248
    },
    {
        "x": 26.33,
        "y": 22.248
    },
    {
        "x": 26.34,
        "y": 22.248
    },
    {
        "x": 26.35,
        "y": 22.248
    },
    {
        "x": 26.36,
        "y": 22.257
    },
    {
        "x": 26.37,
        "y": 22.257
    },
    {
        "x": 26.38,
        "y": 22.257
    },
    {
        "x": 26.39,
        "y": 22.257
    },
    {
        "x": 26.4,
        "y": 22.257
    },
    {
        "x": 26.41,
        "y": 22.257
    },
    {
        "x": 26.42,
        "y": 22.266
    },
    {
        "x": 26.43,
        "y": 22.257
    },
    {
        "x": 26.44,
        "y": 22.266
    },
    {
        "x": 26.45,
        "y": 22.266
    },
    {
        "x": 26.46,
        "y": 22.266
    },
    {
        "x": 26.47,
        "y": 22.266
    },
    {
        "x": 26.48,
        "y": 22.266
    },
    {
        "x": 26.49,
        "y": 22.266
    },
    {
        "x": 26.5,
        "y": 22.266
    },
    {
        "x": 26.51,
        "y": 22.266
    },
    {
        "x": 26.52,
        "y": 22.275
    },
    {
        "x": 26.53,
        "y": 22.275
    },
    {
        "x": 26.54,
        "y": 22.275
    },
    {
        "x": 26.55,
        "y": 22.275
    },
    {
        "x": 26.56,
        "y": 22.275
    },
    {
        "x": 26.57,
        "y": 22.275
    },
    {
        "x": 26.58,
        "y": 22.284
    },
    {
        "x": 26.59,
        "y": 22.284
    },
    {
        "x": 26.6,
        "y": 22.284
    },
    {
        "x": 26.61,
        "y": 22.284
    },
    {
        "x": 26.62,
        "y": 22.284
    },
    {
        "x": 26.63,
        "y": 22.284
    },
    {
        "x": 26.64,
        "y": 22.284
    },
    {
        "x": 26.65,
        "y": 22.293
    },
    {
        "x": 26.66,
        "y": 22.293
    },
    {
        "x": 26.67,
        "y": 22.293
    },
    {
        "x": 26.68,
        "y": 22.293
    },
    {
        "x": 26.69,
        "y": 22.293
    },
    {
        "x": 26.7,
        "y": 22.293
    },
    {
        "x": 26.71,
        "y": 22.293
    },
    {
        "x": 26.72,
        "y": 22.302
    },
    {
        "x": 26.73,
        "y": 22.302
    },
    {
        "x": 26.74,
        "y": 22.302
    },
    {
        "x": 26.75,
        "y": 22.302
    },
    {
        "x": 26.76,
        "y": 22.302
    },
    {
        "x": 26.77,
        "y": 22.302
    },
    {
        "x": 26.78,
        "y": 22.302
    },
    {
        "x": 26.79,
        "y": 22.302
    },
    {
        "x": 26.8,
        "y": 22.302
    },
    {
        "x": 26.81,
        "y": 22.302
    },
    {
        "x": 26.82,
        "y": 22.311
    },
    {
        "x": 26.83,
        "y": 22.311
    },
    {
        "x": 26.84,
        "y": 22.311
    },
    {
        "x": 26.85,
        "y": 22.311
    },
    {
        "x": 26.86,
        "y": 22.311
    },
    {
        "x": 26.87,
        "y": 22.311
    },
    {
        "x": 26.88,
        "y": 22.311
    },
    {
        "x": 26.89,
        "y": 22.311
    },
    {
        "x": 26.9,
        "y": 22.32
    },
    {
        "x": 26.91,
        "y": 22.32
    },
    {
        "x": 26.92,
        "y": 22.32
    },
    {
        "x": 26.93,
        "y": 22.32
    },
    {
        "x": 26.94,
        "y": 22.32
    },
    {
        "x": 26.95,
        "y": 22.32
    },
    {
        "x": 26.96,
        "y": 22.32
    },
    {
        "x": 26.97,
        "y": 22.329
    },
    {
        "x": 26.98,
        "y": 22.329
    },
    {
        "x": 26.99,
        "y": 22.329
    },
    {
        "x": 27,
        "y": 22.329
    },
    {
        "x": 27.01,
        "y": 22.329
    },
    {
        "x": 27.02,
        "y": 22.329
    },
    {
        "x": 27.03,
        "y": 22.338
    },
    {
        "x": 27.04,
        "y": 22.329
    },
    {
        "x": 27.05,
        "y": 22.338
    },
    {
        "x": 27.06,
        "y": 22.338
    },
    {
        "x": 27.07,
        "y": 22.338
    },
    {
        "x": 27.08,
        "y": 22.338
    },
    {
        "x": 27.09,
        "y": 22.338
    },
    {
        "x": 27.1,
        "y": 22.338
    },
    {
        "x": 27.11,
        "y": 22.338
    },
    {
        "x": 27.12,
        "y": 22.347
    },
    {
        "x": 27.13,
        "y": 22.347
    },
    {
        "x": 27.14,
        "y": 22.347
    },
    {
        "x": 27.15,
        "y": 22.347
    },
    {
        "x": 27.16,
        "y": 22.347
    },
    {
        "x": 27.17,
        "y": 22.356
    },
    {
        "x": 27.18,
        "y": 22.356
    },
    {
        "x": 27.19,
        "y": 22.347
    },
    {
        "x": 27.2,
        "y": 22.356
    },
    {
        "x": 27.21,
        "y": 22.356
    },
    {
        "x": 27.22,
        "y": 22.356
    },
    {
        "x": 27.23,
        "y": 22.356
    },
    {
        "x": 27.24,
        "y": 22.356
    },
    {
        "x": 27.25,
        "y": 22.356
    },
    {
        "x": 27.26,
        "y": 22.365
    },
    {
        "x": 27.27,
        "y": 22.365
    },
    {
        "x": 27.28,
        "y": 22.365
    },
    {
        "x": 27.29,
        "y": 22.365
    },
    {
        "x": 27.3,
        "y": 22.365
    },
    {
        "x": 27.31,
        "y": 22.365
    },
    {
        "x": 27.32,
        "y": 22.365
    },
    {
        "x": 27.33,
        "y": 22.365
    },
    {
        "x": 27.34,
        "y": 22.374
    },
    {
        "x": 27.35,
        "y": 22.374
    },
    {
        "x": 27.36,
        "y": 22.374
    },
    {
        "x": 27.37,
        "y": 22.374
    },
    {
        "x": 27.38,
        "y": 22.374
    },
    {
        "x": 27.39,
        "y": 22.374
    },
    {
        "x": 27.4,
        "y": 22.374
    },
    {
        "x": 27.41,
        "y": 22.374
    },
    {
        "x": 27.42,
        "y": 22.374
    },
    {
        "x": 27.43,
        "y": 22.383
    },
    {
        "x": 27.44,
        "y": 22.383
    },
    {
        "x": 27.45,
        "y": 22.383
    },
    {
        "x": 27.46,
        "y": 22.383
    },
    {
        "x": 27.47,
        "y": 22.383
    },
    {
        "x": 27.48,
        "y": 22.383
    },
    {
        "x": 27.49,
        "y": 22.383
    },
    {
        "x": 27.5,
        "y": 22.392
    },
    {
        "x": 27.51,
        "y": 22.383
    },
    {
        "x": 27.52,
        "y": 22.383
    },
    {
        "x": 27.53,
        "y": 22.392
    },
    {
        "x": 27.54,
        "y": 22.392
    },
    {
        "x": 27.55,
        "y": 22.392
    },
    {
        "x": 27.56,
        "y": 22.392
    },
    {
        "x": 27.57,
        "y": 22.392
    },
    {
        "x": 27.58,
        "y": 22.392
    },
    {
        "x": 27.59,
        "y": 22.401
    },
    {
        "x": 27.6,
        "y": 22.401
    },
    {
        "x": 27.61,
        "y": 22.401
    },
    {
        "x": 27.62,
        "y": 22.401
    },
    {
        "x": 27.63,
        "y": 22.401
    },
    {
        "x": 27.64,
        "y": 22.401
    },
    {
        "x": 27.65,
        "y": 22.401
    },
    {
        "x": 27.66,
        "y": 22.401
    },
    {
        "x": 27.67,
        "y": 22.41
    },
    {
        "x": 27.68,
        "y": 22.41
    },
    {
        "x": 27.69,
        "y": 22.41
    },
    {
        "x": 27.7,
        "y": 22.41
    },
    {
        "x": 27.71,
        "y": 22.41
    },
    {
        "x": 27.72,
        "y": 22.41
    },
    {
        "x": 27.73,
        "y": 22.41
    },
    {
        "x": 27.74,
        "y": 22.419
    },
    {
        "x": 27.75,
        "y": 22.419
    },
    {
        "x": 27.76,
        "y": 22.41
    },
    {
        "x": 27.77,
        "y": 22.419
    },
    {
        "x": 27.78,
        "y": 22.419
    },
    {
        "x": 27.79,
        "y": 22.419
    },
    {
        "x": 27.8,
        "y": 22.419
    },
    {
        "x": 27.81,
        "y": 22.419
    },
    {
        "x": 27.82,
        "y": 22.419
    },
    {
        "x": 27.83,
        "y": 22.419
    },
    {
        "x": 27.84,
        "y": 22.419
    },
    {
        "x": 27.85,
        "y": 22.428
    },
    {
        "x": 27.86,
        "y": 22.428
    },
    {
        "x": 27.87,
        "y": 22.428
    },
    {
        "x": 27.88,
        "y": 22.428
    },
    {
        "x": 27.89,
        "y": 22.428
    },
    {
        "x": 27.9,
        "y": 22.428
    },
    {
        "x": 27.91,
        "y": 22.428
    },
    {
        "x": 27.92,
        "y": 22.428
    },
    {
        "x": 27.93,
        "y": 22.428
    },
    {
        "x": 27.94,
        "y": 22.437
    },
    {
        "x": 27.95,
        "y": 22.437
    },
    {
        "x": 27.96,
        "y": 22.437
    },
    {
        "x": 27.97,
        "y": 22.437
    },
    {
        "x": 27.98,
        "y": 22.437
    },
    {
        "x": 27.99,
        "y": 22.437
    },
    {
        "x": 28,
        "y": 22.446
    },
    {
        "x": 28.01,
        "y": 22.437
    },
    {
        "x": 28.02,
        "y": 22.446
    },
    {
        "x": 28.03,
        "y": 22.446
    },
    {
        "x": 28.04,
        "y": 22.446
    },
    {
        "x": 28.05,
        "y": 22.446
    },
    {
        "x": 28.06,
        "y": 22.446
    },
    {
        "x": 28.07,
        "y": 22.446
    },
    {
        "x": 28.08,
        "y": 22.455
    },
    {
        "x": 28.09,
        "y": 22.446
    },
    {
        "x": 28.1,
        "y": 22.446
    },
    {
        "x": 28.11,
        "y": 22.446
    },
    {
        "x": 28.12,
        "y": 22.446
    },
    {
        "x": 28.13,
        "y": 22.455
    },
    {
        "x": 28.14,
        "y": 22.455
    },
    {
        "x": 28.15,
        "y": 22.455
    },
    {
        "x": 28.16,
        "y": 22.455
    },
    {
        "x": 28.17,
        "y": 22.455
    },
    {
        "x": 28.18,
        "y": 22.455
    },
    {
        "x": 28.19,
        "y": 22.455
    },
    {
        "x": 28.2,
        "y": 22.464
    },
    {
        "x": 28.21,
        "y": 22.464
    },
    {
        "x": 28.22,
        "y": 22.464
    },
    {
        "x": 28.23,
        "y": 22.464
    },
    {
        "x": 28.24,
        "y": 22.464
    },
    {
        "x": 28.25,
        "y": 22.464
    },
    {
        "x": 28.26,
        "y": 22.464
    },
    {
        "x": 28.27,
        "y": 22.464
    },
    {
        "x": 28.28,
        "y": 22.464
    },
    {
        "x": 28.29,
        "y": 22.473
    },
    {
        "x": 28.3,
        "y": 22.473
    },
    {
        "x": 28.31,
        "y": 22.473
    },
    {
        "x": 28.32,
        "y": 22.473
    },
    {
        "x": 28.33,
        "y": 22.473
    },
    {
        "x": 28.34,
        "y": 22.473
    },
    {
        "x": 28.35,
        "y": 22.473
    },
    {
        "x": 28.36,
        "y": 22.473
    },
    {
        "x": 28.37,
        "y": 22.473
    },
    {
        "x": 28.38,
        "y": 22.482
    },
    {
        "x": 28.39,
        "y": 22.482
    },
    {
        "x": 28.4,
        "y": 22.482
    },
    {
        "x": 28.41,
        "y": 22.482
    },
    {
        "x": 28.42,
        "y": 22.482
    },
    {
        "x": 28.43,
        "y": 22.482
    },
    {
        "x": 28.44,
        "y": 22.482
    },
    {
        "x": 28.45,
        "y": 22.491
    },
    {
        "x": 28.46,
        "y": 22.482
    },
    {
        "x": 28.47,
        "y": 22.491
    },
    {
        "x": 28.48,
        "y": 22.491
    },
    {
        "x": 28.49,
        "y": 22.491
    },
    {
        "x": 28.5,
        "y": 22.491
    },
    {
        "x": 28.51,
        "y": 22.491
    },
    {
        "x": 28.52,
        "y": 22.491
    },
    {
        "x": 28.53,
        "y": 22.491
    },
    {
        "x": 28.54,
        "y": 22.5
    },
    {
        "x": 28.55,
        "y": 22.5
    },
    {
        "x": 28.56,
        "y": 22.5
    },
    {
        "x": 28.57,
        "y": 22.5
    },
    {
        "x": 28.58,
        "y": 22.5
    },
    {
        "x": 28.59,
        "y": 22.5
    },
    {
        "x": 28.6,
        "y": 22.5
    },
    {
        "x": 28.61,
        "y": 22.5
    },
    {
        "x": 28.62,
        "y": 22.5
    },
    {
        "x": 28.63,
        "y": 22.5
    },
    {
        "x": 28.64,
        "y": 22.5
    },
    {
        "x": 28.65,
        "y": 22.5
    },
    {
        "x": 28.66,
        "y": 22.509
    },
    {
        "x": 28.67,
        "y": 22.509
    },
    {
        "x": 28.68,
        "y": 22.509
    },
    {
        "x": 28.69,
        "y": 22.5
    },
    {
        "x": 28.7,
        "y": 22.509
    },
    {
        "x": 28.71,
        "y": 22.509
    },
    {
        "x": 28.72,
        "y": 22.509
    },
    {
        "x": 28.73,
        "y": 22.509
    },
    {
        "x": 28.74,
        "y": 22.509
    },
    {
        "x": 28.75,
        "y": 22.509
    },
    {
        "x": 28.76,
        "y": 22.509
    },
    {
        "x": 28.77,
        "y": 22.509
    },
    {
        "x": 28.78,
        "y": 22.518
    },
    {
        "x": 28.79,
        "y": 22.518
    },
    {
        "x": 28.8,
        "y": 22.518
    },
    {
        "x": 28.81,
        "y": 22.518
    },
    {
        "x": 28.82,
        "y": 22.518
    },
    {
        "x": 28.83,
        "y": 22.518
    },
    {
        "x": 28.84,
        "y": 22.518
    },
    {
        "x": 28.85,
        "y": 22.527
    },
    {
        "x": 28.86,
        "y": 22.518
    },
    {
        "x": 28.87,
        "y": 22.527
    },
    {
        "x": 28.88,
        "y": 22.527
    },
    {
        "x": 28.89,
        "y": 22.527
    },
    {
        "x": 28.9,
        "y": 22.527
    },
    {
        "x": 28.91,
        "y": 22.527
    },
    {
        "x": 28.92,
        "y": 22.527
    },
    {
        "x": 28.93,
        "y": 22.527
    },
    {
        "x": 28.94,
        "y": 22.527
    },
    {
        "x": 28.95,
        "y": 22.536
    },
    {
        "x": 28.96,
        "y": 22.536
    },
    {
        "x": 28.97,
        "y": 22.527
    },
    {
        "x": 28.98,
        "y": 22.527
    },
    {
        "x": 28.99,
        "y": 22.536
    },
    {
        "x": 29,
        "y": 22.536
    },
    {
        "x": 29.01,
        "y": 22.536
    },
    {
        "x": 29.02,
        "y": 22.536
    },
    {
        "x": 29.03,
        "y": 22.536
    },
    {
        "x": 29.04,
        "y": 22.536
    },
    {
        "x": 29.05,
        "y": 22.536
    },
    {
        "x": 29.06,
        "y": 22.536
    },
    {
        "x": 29.07,
        "y": 22.536
    },
    {
        "x": 29.08,
        "y": 22.545
    },
    {
        "x": 29.09,
        "y": 22.545
    },
    {
        "x": 29.1,
        "y": 22.545
    },
    {
        "x": 29.11,
        "y": 22.545
    },
    {
        "x": 29.12,
        "y": 22.545
    },
    {
        "x": 29.13,
        "y": 22.545
    },
    {
        "x": 29.14,
        "y": 22.545
    },
    {
        "x": 29.15,
        "y": 22.545
    },
    {
        "x": 29.16,
        "y": 22.545
    },
    {
        "x": 29.17,
        "y": 22.554
    },
    {
        "x": 29.18,
        "y": 22.554
    },
    {
        "x": 29.19,
        "y": 22.554
    },
    {
        "x": 29.2,
        "y": 22.554
    },
    {
        "x": 29.21,
        "y": 22.554
    },
    {
        "x": 29.22,
        "y": 22.554
    },
    {
        "x": 29.23,
        "y": 22.554
    },
    {
        "x": 29.24,
        "y": 22.554
    },
    {
        "x": 29.25,
        "y": 22.554
    },
    {
        "x": 29.26,
        "y": 22.554
    },
    {
        "x": 29.27,
        "y": 22.554
    },
    {
        "x": 29.28,
        "y": 22.554
    },
    {
        "x": 29.29,
        "y": 22.563
    },
    {
        "x": 29.3,
        "y": 22.563
    },
    {
        "x": 29.31,
        "y": 22.563
    },
    {
        "x": 29.32,
        "y": 22.563
    },
    {
        "x": 29.33,
        "y": 22.563
    },
    {
        "x": 29.34,
        "y": 22.563
    },
    {
        "x": 29.35,
        "y": 22.572
    },
    {
        "x": 29.36,
        "y": 22.572
    },
    {
        "x": 29.37,
        "y": 22.572
    },
    {
        "x": 29.38,
        "y": 22.572
    },
    {
        "x": 29.39,
        "y": 22.572
    },
    {
        "x": 29.4,
        "y": 22.572
    },
    {
        "x": 29.41,
        "y": 22.572
    },
    {
        "x": 29.42,
        "y": 22.572
    },
    {
        "x": 29.43,
        "y": 22.572
    },
    {
        "x": 29.44,
        "y": 22.572
    },
    {
        "x": 29.45,
        "y": 22.572
    },
    {
        "x": 29.46,
        "y": 22.581
    },
    {
        "x": 29.47,
        "y": 22.581
    },
    {
        "x": 29.48,
        "y": 22.572
    },
    {
        "x": 29.49,
        "y": 22.581
    },
    {
        "x": 29.5,
        "y": 22.581
    },
    {
        "x": 29.51,
        "y": 22.581
    },
    {
        "x": 29.52,
        "y": 22.581
    },
    {
        "x": 29.53,
        "y": 22.581
    },
    {
        "x": 29.54,
        "y": 22.59
    },
    {
        "x": 29.55,
        "y": 22.59
    },
    {
        "x": 29.56,
        "y": 22.581
    },
    {
        "x": 29.57,
        "y": 22.59
    },
    {
        "x": 29.58,
        "y": 22.59
    },
    {
        "x": 29.59,
        "y": 22.59
    },
    {
        "x": 29.6,
        "y": 22.59
    },
    {
        "x": 29.61,
        "y": 22.59
    },
    {
        "x": 29.62,
        "y": 22.59
    },
    {
        "x": 29.63,
        "y": 22.59
    },
    {
        "x": 29.64,
        "y": 22.59
    },
    {
        "x": 29.65,
        "y": 22.59
    },
    {
        "x": 29.66,
        "y": 22.59
    },
    {
        "x": 29.67,
        "y": 22.59
    },
    {
        "x": 29.68,
        "y": 22.599
    },
    {
        "x": 29.69,
        "y": 22.599
    },
    {
        "x": 29.7,
        "y": 22.599
    },
    {
        "x": 29.71,
        "y": 22.599
    },
    {
        "x": 29.72,
        "y": 22.599
    },
    {
        "x": 29.73,
        "y": 22.599
    },
    {
        "x": 29.74,
        "y": 22.599
    },
    {
        "x": 29.75,
        "y": 22.599
    },
    {
        "x": 29.76,
        "y": 22.599
    },
    {
        "x": 29.77,
        "y": 22.608
    },
    {
        "x": 29.78,
        "y": 22.608
    },
    {
        "x": 29.79,
        "y": 22.608
    },
    {
        "x": 29.8,
        "y": 22.608
    },
    {
        "x": 29.81,
        "y": 22.608
    },
    {
        "x": 29.82,
        "y": 22.608
    },
    {
        "x": 29.83,
        "y": 22.608
    },
    {
        "x": 29.84,
        "y": 22.608
    },
    {
        "x": 29.85,
        "y": 22.608
    },
    {
        "x": 29.86,
        "y": 22.608
    },
    {
        "x": 29.87,
        "y": 22.608
    },
    {
        "x": 29.88,
        "y": 22.608
    },
    {
        "x": 29.89,
        "y": 22.608
    },
    {
        "x": 29.9,
        "y": 22.617
    },
    {
        "x": 29.91,
        "y": 22.617
    },
    {
        "x": 29.92,
        "y": 22.617
    },
    {
        "x": 29.93,
        "y": 22.617
    },
    {
        "x": 29.94,
        "y": 22.617
    },
    {
        "x": 29.95,
        "y": 22.617
    },
    {
        "x": 29.96,
        "y": 22.617
    },
    {
        "x": 29.97,
        "y": 22.617
    },
    {
        "x": 29.98,
        "y": 22.617
    },
    {
        "x": 29.99,
        "y": 22.617
    },
    {
        "x": 30,
        "y": 22.617
    },
    {
        "x": 30.01,
        "y": 22.626
    },
    {
        "x": 30.02,
        "y": 22.617
    },
    {
        "x": 30.03,
        "y": 22.617
    },
    {
        "x": 30.04,
        "y": 22.626
    },
    {
        "x": 30.05,
        "y": 22.626
    },
    {
        "x": 30.06,
        "y": 22.626
    },
    {
        "x": 30.07,
        "y": 22.626
    },
    {
        "x": 30.08,
        "y": 22.626
    },
    {
        "x": 30.09,
        "y": 22.626
    },
    {
        "x": 30.1,
        "y": 22.635
    },
    {
        "x": 30.11,
        "y": 22.635
    },
    {
        "x": 30.12,
        "y": 22.626
    },
    {
        "x": 30.13,
        "y": 22.626
    },
    {
        "x": 30.14,
        "y": 22.626
    },
    {
        "x": 30.15,
        "y": 22.635
    },
    {
        "x": 30.16,
        "y": 22.635
    },
    {
        "x": 30.17,
        "y": 22.635
    },
    {
        "x": 30.18,
        "y": 22.635
    },
    {
        "x": 30.19,
        "y": 22.635
    },
    {
        "x": 30.2,
        "y": 22.635
    },
    {
        "x": 30.21,
        "y": 22.635
    },
    {
        "x": 30.22,
        "y": 22.644
    },
    {
        "x": 30.23,
        "y": 22.635
    },
    {
        "x": 30.24,
        "y": 22.635
    },
    {
        "x": 30.25,
        "y": 22.644
    },
    {
        "x": 30.26,
        "y": 22.644
    },
    {
        "x": 30.27,
        "y": 22.644
    },
    {
        "x": 30.28,
        "y": 22.644
    },
    {
        "x": 30.29,
        "y": 22.644
    },
    {
        "x": 30.3,
        "y": 22.644
    },
    {
        "x": 30.31,
        "y": 22.644
    },
    {
        "x": 30.32,
        "y": 22.644
    },
    {
        "x": 30.33,
        "y": 22.644
    },
    {
        "x": 30.34,
        "y": 22.644
    },
    {
        "x": 30.35,
        "y": 22.644
    },
    {
        "x": 30.36,
        "y": 22.653
    },
    {
        "x": 30.37,
        "y": 22.653
    },
    {
        "x": 30.38,
        "y": 22.653
    },
    {
        "x": 30.39,
        "y": 22.653
    },
    {
        "x": 30.4,
        "y": 22.653
    },
    {
        "x": 30.41,
        "y": 22.653
    },
    {
        "x": 30.42,
        "y": 22.653
    },
    {
        "x": 30.43,
        "y": 22.653
    },
    {
        "x": 30.44,
        "y": 22.653
    },
    {
        "x": 30.45,
        "y": 22.653
    },
    {
        "x": 30.46,
        "y": 22.662
    },
    {
        "x": 30.47,
        "y": 22.662
    },
    {
        "x": 30.48,
        "y": 22.662
    },
    {
        "x": 30.49,
        "y": 22.662
    },
    {
        "x": 30.5,
        "y": 22.662
    },
    {
        "x": 30.51,
        "y": 22.662
    },
    {
        "x": 30.52,
        "y": 22.662
    },
    {
        "x": 30.53,
        "y": 22.662
    },
    {
        "x": 30.54,
        "y": 22.662
    },
    {
        "x": 30.55,
        "y": 22.662
    },
    {
        "x": 30.56,
        "y": 22.662
    },
    {
        "x": 30.57,
        "y": 22.671
    },
    {
        "x": 30.58,
        "y": 22.671
    },
    {
        "x": 30.59,
        "y": 22.671
    },
    {
        "x": 30.6,
        "y": 22.671
    },
    {
        "x": 30.61,
        "y": 22.671
    },
    {
        "x": 30.62,
        "y": 22.671
    },
    {
        "x": 30.63,
        "y": 22.671
    },
    {
        "x": 30.64,
        "y": 22.671
    },
    {
        "x": 30.65,
        "y": 22.671
    },
    {
        "x": 30.66,
        "y": 22.671
    },
    {
        "x": 30.67,
        "y": 22.671
    },
    {
        "x": 30.68,
        "y": 22.671
    },
    {
        "x": 30.69,
        "y": 22.671
    },
    {
        "x": 30.7,
        "y": 22.68
    },
    {
        "x": 30.71,
        "y": 22.68
    },
    {
        "x": 30.72,
        "y": 22.68
    },
    {
        "x": 30.73,
        "y": 22.68
    },
    {
        "x": 30.74,
        "y": 22.68
    },
    {
        "x": 30.75,
        "y": 22.68
    },
    {
        "x": 30.76,
        "y": 22.68
    },
    {
        "x": 30.77,
        "y": 22.68
    },
    {
        "x": 30.78,
        "y": 22.68
    },
    {
        "x": 30.79,
        "y": 22.68
    },
    {
        "x": 30.8,
        "y": 22.689
    },
    {
        "x": 30.81,
        "y": 22.68
    },
    {
        "x": 30.82,
        "y": 22.68
    },
    {
        "x": 30.83,
        "y": 22.68
    },
    {
        "x": 30.84,
        "y": 22.689
    },
    {
        "x": 30.85,
        "y": 22.689
    },
    {
        "x": 30.86,
        "y": 22.689
    },
    {
        "x": 30.87,
        "y": 22.689
    },
    {
        "x": 30.88,
        "y": 22.689
    },
    {
        "x": 30.89,
        "y": 22.689
    },
    {
        "x": 30.9,
        "y": 22.689
    },
    {
        "x": 30.91,
        "y": 22.689
    },
    {
        "x": 30.92,
        "y": 22.689
    },
    {
        "x": 30.93,
        "y": 22.689
    },
    {
        "x": 30.94,
        "y": 22.689
    },
    {
        "x": 30.95,
        "y": 22.689
    },
    {
        "x": 30.96,
        "y": 22.698
    },
    {
        "x": 30.97,
        "y": 22.698
    },
    {
        "x": 30.98,
        "y": 22.698
    },
    {
        "x": 30.99,
        "y": 22.698
    },
    {
        "x": 31,
        "y": 22.698
    },
    {
        "x": 31.01,
        "y": 22.698
    },
    {
        "x": 31.02,
        "y": 22.698
    },
    {
        "x": 31.03,
        "y": 22.698
    },
    {
        "x": 31.04,
        "y": 22.698
    },
    {
        "x": 31.05,
        "y": 22.698
    },
    {
        "x": 31.06,
        "y": 22.698
    },
    {
        "x": 31.07,
        "y": 22.698
    },
    {
        "x": 31.08,
        "y": 22.707
    },
    {
        "x": 31.09,
        "y": 22.707
    },
    {
        "x": 31.1,
        "y": 22.707
    },
    {
        "x": 31.11,
        "y": 22.707
    },
    {
        "x": 31.12,
        "y": 22.707
    },
    {
        "x": 31.13,
        "y": 22.707
    },
    {
        "x": 31.14,
        "y": 22.707
    },
    {
        "x": 31.15,
        "y": 22.707
    },
    {
        "x": 31.16,
        "y": 22.707
    },
    {
        "x": 31.17,
        "y": 22.716
    },
    {
        "x": 31.18,
        "y": 22.707
    },
    {
        "x": 31.19,
        "y": 22.707
    },
    {
        "x": 31.2,
        "y": 22.716
    },
    {
        "x": 31.21,
        "y": 22.716
    },
    {
        "x": 31.22,
        "y": 22.707
    },
    {
        "x": 31.23,
        "y": 22.716
    },
    {
        "x": 31.24,
        "y": 22.716
    },
    {
        "x": 31.25,
        "y": 22.716
    },
    {
        "x": 31.26,
        "y": 22.716
    },
    {
        "x": 31.27,
        "y": 22.716
    },
    {
        "x": 31.28,
        "y": 22.716
    },
    {
        "x": 31.29,
        "y": 22.716
    },
    {
        "x": 31.3,
        "y": 22.716
    },
    {
        "x": 31.31,
        "y": 22.716
    },
    {
        "x": 31.32,
        "y": 22.716
    },
    {
        "x": 31.33,
        "y": 22.725
    },
    {
        "x": 31.34,
        "y": 22.725
    },
    {
        "x": 31.35,
        "y": 22.725
    },
    {
        "x": 31.36,
        "y": 22.725
    },
    {
        "x": 31.37,
        "y": 22.725
    },
    {
        "x": 31.38,
        "y": 22.725
    },
    {
        "x": 31.39,
        "y": 22.725
    },
    {
        "x": 31.4,
        "y": 22.725
    },
    {
        "x": 31.41,
        "y": 22.725
    },
    {
        "x": 31.42,
        "y": 22.725
    },
    {
        "x": 31.43,
        "y": 22.725
    },
    {
        "x": 31.44,
        "y": 22.725
    },
    {
        "x": 31.45,
        "y": 22.725
    },
    {
        "x": 31.46,
        "y": 22.734
    },
    {
        "x": 31.47,
        "y": 22.734
    },
    {
        "x": 31.48,
        "y": 22.734
    },
    {
        "x": 31.49,
        "y": 22.734
    },
    {
        "x": 31.5,
        "y": 22.734
    },
    {
        "x": 31.51,
        "y": 22.734
    },
    {
        "x": 31.52,
        "y": 22.734
    },
    {
        "x": 31.53,
        "y": 22.734
    },
    {
        "x": 31.54,
        "y": 22.743
    },
    {
        "x": 31.55,
        "y": 22.743
    },
    {
        "x": 31.56,
        "y": 22.743
    },
    {
        "x": 31.57,
        "y": 22.743
    },
    {
        "x": 31.58,
        "y": 22.743
    },
    {
        "x": 31.59,
        "y": 22.743
    },
    {
        "x": 31.6,
        "y": 22.743
    },
    {
        "x": 31.61,
        "y": 22.743
    },
    {
        "x": 31.62,
        "y": 22.743
    },
    {
        "x": 31.63,
        "y": 22.743
    },
    {
        "x": 31.64,
        "y": 22.743
    },
    {
        "x": 31.65,
        "y": 22.743
    },
    {
        "x": 31.66,
        "y": 22.743
    },
    {
        "x": 31.67,
        "y": 22.752
    },
    {
        "x": 31.68,
        "y": 22.743
    },
    {
        "x": 31.69,
        "y": 22.743
    },
    {
        "x": 31.7,
        "y": 22.752
    },
    {
        "x": 31.71,
        "y": 22.752
    },
    {
        "x": 31.72,
        "y": 22.752
    },
    {
        "x": 31.73,
        "y": 22.752
    },
    {
        "x": 31.74,
        "y": 22.752
    },
    {
        "x": 31.75,
        "y": 22.752
    },
    {
        "x": 31.76,
        "y": 22.752
    },
    {
        "x": 31.77,
        "y": 22.752
    },
    {
        "x": 31.78,
        "y": 22.752
    },
    {
        "x": 31.79,
        "y": 22.752
    },
    {
        "x": 31.8,
        "y": 22.752
    },
    {
        "x": 31.81,
        "y": 22.752
    },
    {
        "x": 31.82,
        "y": 22.761
    },
    {
        "x": 31.83,
        "y": 22.761
    },
    {
        "x": 31.84,
        "y": 22.752
    },
    {
        "x": 31.85,
        "y": 22.761
    },
    {
        "x": 31.86,
        "y": 22.761
    },
    {
        "x": 31.87,
        "y": 22.761
    },
    {
        "x": 31.88,
        "y": 22.761
    },
    {
        "x": 31.89,
        "y": 22.761
    },
    {
        "x": 31.9,
        "y": 22.761
    },
    {
        "x": 31.91,
        "y": 22.761
    },
    {
        "x": 31.92,
        "y": 22.761
    },
    {
        "x": 31.93,
        "y": 22.761
    },
    {
        "x": 31.94,
        "y": 22.761
    },
    {
        "x": 31.95,
        "y": 22.761
    },
    {
        "x": 31.96,
        "y": 22.77
    },
    {
        "x": 31.97,
        "y": 22.77
    },
    {
        "x": 31.98,
        "y": 22.77
    },
    {
        "x": 31.99,
        "y": 22.77
    },
    {
        "x": 32,
        "y": 22.77
    },
    {
        "x": 32.01,
        "y": 22.77
    },
    {
        "x": 32.02,
        "y": 22.77
    },
    {
        "x": 32.03,
        "y": 22.77
    },
    {
        "x": 32.04,
        "y": 22.77
    },
    {
        "x": 32.05,
        "y": 22.77
    },
    {
        "x": 32.06,
        "y": 22.77
    },
    {
        "x": 32.07,
        "y": 22.77
    },
    {
        "x": 32.08,
        "y": 22.77
    },
    {
        "x": 32.09,
        "y": 22.77
    },
    {
        "x": 32.1,
        "y": 22.77
    },
    {
        "x": 32.11,
        "y": 22.77
    },
    {
        "x": 32.12,
        "y": 22.77
    },
    {
        "x": 32.13,
        "y": 22.779
    },
    {
        "x": 32.14,
        "y": 22.779
    },
    {
        "x": 32.15,
        "y": 22.779
    },
    {
        "x": 32.16,
        "y": 22.779
    },
    {
        "x": 32.17,
        "y": 22.779
    },
    {
        "x": 32.18,
        "y": 22.779
    },
    {
        "x": 32.19,
        "y": 22.779
    },
    {
        "x": 32.2,
        "y": 22.779
    },
    {
        "x": 32.21,
        "y": 22.779
    },
    {
        "x": 32.22,
        "y": 22.779
    },
    {
        "x": 32.23,
        "y": 22.779
    },
    {
        "x": 32.24,
        "y": 22.779
    },
    {
        "x": 32.25,
        "y": 22.779
    },
    {
        "x": 32.26,
        "y": 22.779
    },
    {
        "x": 32.27,
        "y": 22.779
    },
    {
        "x": 32.28,
        "y": 22.788
    },
    {
        "x": 32.29,
        "y": 22.788
    },
    {
        "x": 32.3,
        "y": 22.788
    },
    {
        "x": 32.31,
        "y": 22.788
    },
    {
        "x": 32.32,
        "y": 22.788
    },
    {
        "x": 32.33,
        "y": 22.788
    },
    {
        "x": 32.34,
        "y": 22.788
    },
    {
        "x": 32.35,
        "y": 22.788
    },
    {
        "x": 32.36,
        "y": 22.788
    },
    {
        "x": 32.37,
        "y": 22.788
    },
    {
        "x": 32.38,
        "y": 22.797
    },
    {
        "x": 32.39,
        "y": 22.797
    },
    {
        "x": 32.4,
        "y": 22.788
    },
    {
        "x": 32.41,
        "y": 22.797
    },
    {
        "x": 32.42,
        "y": 22.797
    },
    {
        "x": 32.43,
        "y": 22.797
    },
    {
        "x": 32.44,
        "y": 22.797
    },
    {
        "x": 32.45,
        "y": 22.797
    },
    {
        "x": 32.46,
        "y": 22.797
    },
    {
        "x": 32.47,
        "y": 22.797
    },
    {
        "x": 32.48,
        "y": 22.797
    },
    {
        "x": 32.49,
        "y": 22.797
    },
    {
        "x": 32.5,
        "y": 22.806
    },
    {
        "x": 32.51,
        "y": 22.797
    },
    {
        "x": 32.52,
        "y": 22.797
    },
    {
        "x": 32.53,
        "y": 22.797
    },
    {
        "x": 32.54,
        "y": 22.806
    },
    {
        "x": 32.55,
        "y": 22.797
    },
    {
        "x": 32.56,
        "y": 22.806
    },
    {
        "x": 32.57,
        "y": 22.806
    },
    {
        "x": 32.58,
        "y": 22.806
    },
    {
        "x": 32.59,
        "y": 22.806
    },
    {
        "x": 32.6,
        "y": 22.806
    },
    {
        "x": 32.61,
        "y": 22.806
    },
    {
        "x": 32.62,
        "y": 22.806
    },
    {
        "x": 32.63,
        "y": 22.806
    },
    {
        "x": 32.64,
        "y": 22.806
    },
    {
        "x": 32.65,
        "y": 22.806
    },
    {
        "x": 32.66,
        "y": 22.806
    },
    {
        "x": 32.67,
        "y": 22.806
    },
    {
        "x": 32.68,
        "y": 22.815
    },
    {
        "x": 32.69,
        "y": 22.815
    },
    {
        "x": 32.7,
        "y": 22.815
    },
    {
        "x": 32.71,
        "y": 22.815
    },
    {
        "x": 32.72,
        "y": 22.815
    },
    {
        "x": 32.73,
        "y": 22.815
    },
    {
        "x": 32.74,
        "y": 22.815
    },
    {
        "x": 32.75,
        "y": 22.815
    },
    {
        "x": 32.76,
        "y": 22.815
    },
    {
        "x": 32.77,
        "y": 22.815
    },
    {
        "x": 32.78,
        "y": 22.815
    },
    {
        "x": 32.79,
        "y": 22.815
    },
    {
        "x": 32.8,
        "y": 22.815
    },
    {
        "x": 32.81,
        "y": 22.815
    },
    {
        "x": 32.82,
        "y": 22.824
    },
    {
        "x": 32.83,
        "y": 22.824
    },
    {
        "x": 32.84,
        "y": 22.824
    },
    {
        "x": 32.85,
        "y": 22.824
    },
    {
        "x": 32.86,
        "y": 22.824
    },
    {
        "x": 32.87,
        "y": 22.824
    },
    {
        "x": 32.88,
        "y": 22.824
    },
    {
        "x": 32.89,
        "y": 22.824
    },
    {
        "x": 32.9,
        "y": 22.824
    },
    {
        "x": 32.91,
        "y": 22.824
    },
    {
        "x": 32.92,
        "y": 22.824
    },
    {
        "x": 32.93,
        "y": 22.824
    },
    {
        "x": 32.94,
        "y": 22.824
    },
    {
        "x": 32.95,
        "y": 22.824
    },
    {
        "x": 32.96,
        "y": 22.824
    },
    {
        "x": 32.97,
        "y": 22.824
    },
    {
        "x": 32.98,
        "y": 22.824
    },
    {
        "x": 32.99,
        "y": 22.833
    },
    {
        "x": 33,
        "y": 22.833
    },
    {
        "x": 33.01,
        "y": 22.833
    },
    {
        "x": 33.02,
        "y": 22.833
    },
    {
        "x": 33.03,
        "y": 22.833
    },
    {
        "x": 33.04,
        "y": 22.833
    },
    {
        "x": 33.05,
        "y": 22.833
    },
    {
        "x": 33.06,
        "y": 22.833
    },
    {
        "x": 33.07,
        "y": 22.833
    },
    {
        "x": 33.08,
        "y": 22.833
    },
    {
        "x": 33.09,
        "y": 22.833
    },
    {
        "x": 33.1,
        "y": 22.842
    },
    {
        "x": 33.11,
        "y": 22.833
    },
    {
        "x": 33.12,
        "y": 22.833
    },
    {
        "x": 33.13,
        "y": 22.833
    },
    {
        "x": 33.14,
        "y": 22.842
    },
    {
        "x": 33.15,
        "y": 22.842
    },
    {
        "x": 33.16,
        "y": 22.842
    },
    {
        "x": 33.17,
        "y": 22.842
    },
    {
        "x": 33.18,
        "y": 22.842
    },
    {
        "x": 33.19,
        "y": 22.842
    },
    {
        "x": 33.2,
        "y": 22.842
    },
    {
        "x": 33.21,
        "y": 22.842
    },
    {
        "x": 33.22,
        "y": 22.842
    },
    {
        "x": 33.23,
        "y": 22.842
    },
    {
        "x": 33.24,
        "y": 22.842
    },
    {
        "x": 33.25,
        "y": 22.842
    },
    {
        "x": 33.26,
        "y": 22.842
    },
    {
        "x": 33.27,
        "y": 22.842
    },
    {
        "x": 33.28,
        "y": 22.842
    },
    {
        "x": 33.29,
        "y": 22.842
    },
    {
        "x": 33.3,
        "y": 22.842
    },
    {
        "x": 33.31,
        "y": 22.851
    },
    {
        "x": 33.32,
        "y": 22.842
    },
    {
        "x": 33.33,
        "y": 22.851
    },
    {
        "x": 33.34,
        "y": 22.851
    },
    {
        "x": 33.35,
        "y": 22.851
    },
    {
        "x": 33.36,
        "y": 22.851
    },
    {
        "x": 33.37,
        "y": 22.851
    },
    {
        "x": 33.38,
        "y": 22.851
    },
    {
        "x": 33.39,
        "y": 22.851
    },
    {
        "x": 33.4,
        "y": 22.851
    },
    {
        "x": 33.41,
        "y": 22.851
    },
    {
        "x": 33.42,
        "y": 22.851
    },
    {
        "x": 33.43,
        "y": 22.851
    },
    {
        "x": 33.44,
        "y": 22.851
    },
    {
        "x": 33.45,
        "y": 22.851
    },
    {
        "x": 33.46,
        "y": 22.851
    },
    {
        "x": 33.47,
        "y": 22.851
    },
    {
        "x": 33.48,
        "y": 22.851
    },
    {
        "x": 33.49,
        "y": 22.86
    },
    {
        "x": 33.5,
        "y": 22.86
    },
    {
        "x": 33.51,
        "y": 22.86
    },
    {
        "x": 33.52,
        "y": 22.86
    },
    {
        "x": 33.53,
        "y": 22.86
    },
    {
        "x": 33.54,
        "y": 22.86
    },
    {
        "x": 33.55,
        "y": 22.86
    },
    {
        "x": 33.56,
        "y": 22.86
    },
    {
        "x": 33.57,
        "y": 22.86
    },
    {
        "x": 33.58,
        "y": 22.86
    },
    {
        "x": 33.59,
        "y": 22.86
    },
    {
        "x": 33.6,
        "y": 22.86
    },
    {
        "x": 33.61,
        "y": 22.86
    },
    {
        "x": 33.62,
        "y": 22.86
    },
    {
        "x": 33.63,
        "y": 22.86
    },
    {
        "x": 33.64,
        "y": 22.86
    },
    {
        "x": 33.65,
        "y": 22.86
    },
    {
        "x": 33.66,
        "y": 22.869
    },
    {
        "x": 33.67,
        "y": 22.869
    },
    {
        "x": 33.68,
        "y": 22.869
    },
    {
        "x": 33.69,
        "y": 22.869
    },
    {
        "x": 33.7,
        "y": 22.869
    },
    {
        "x": 33.71,
        "y": 22.869
    },
    {
        "x": 33.72,
        "y": 22.869
    },
    {
        "x": 33.73,
        "y": 22.869
    },
    {
        "x": 33.74,
        "y": 22.869
    },
    {
        "x": 33.75,
        "y": 22.869
    },
    {
        "x": 33.76,
        "y": 22.869
    },
    {
        "x": 33.77,
        "y": 22.869
    },
    {
        "x": 33.78,
        "y": 22.869
    },
    {
        "x": 33.79,
        "y": 22.869
    },
    {
        "x": 33.8,
        "y": 22.878
    },
    {
        "x": 33.81,
        "y": 22.869
    },
    {
        "x": 33.82,
        "y": 22.869
    },
    {
        "x": 33.83,
        "y": 22.869
    },
    {
        "x": 33.84,
        "y": 22.869
    },
    {
        "x": 33.85,
        "y": 22.869
    },
    {
        "x": 33.86,
        "y": 22.878
    },
    {
        "x": 33.87,
        "y": 22.878
    },
    {
        "x": 33.88,
        "y": 22.878
    },
    {
        "x": 33.89,
        "y": 22.878
    },
    {
        "x": 33.9,
        "y": 22.878
    },
    {
        "x": 33.91,
        "y": 22.878
    },
    {
        "x": 33.92,
        "y": 22.878
    },
    {
        "x": 33.93,
        "y": 22.878
    },
    {
        "x": 33.94,
        "y": 22.878
    },
    {
        "x": 33.95,
        "y": 22.878
    },
    {
        "x": 33.96,
        "y": 22.887
    },
    {
        "x": 33.97,
        "y": 22.887
    },
    {
        "x": 33.98,
        "y": 22.878
    },
    {
        "x": 33.99,
        "y": 22.878
    },
    {
        "x": 34,
        "y": 22.887
    },
    {
        "x": 34.01,
        "y": 22.887
    },
    {
        "x": 34.02,
        "y": 22.887
    },
    {
        "x": 34.03,
        "y": 22.887
    },
    {
        "x": 34.04,
        "y": 22.887
    },
    {
        "x": 34.05,
        "y": 22.887
    },
    {
        "x": 34.06,
        "y": 22.887
    },
    {
        "x": 34.07,
        "y": 22.887
    },
    {
        "x": 34.08,
        "y": 22.887
    },
    {
        "x": 34.09,
        "y": 22.887
    },
    {
        "x": 34.1,
        "y": 22.887
    },
    {
        "x": 34.11,
        "y": 22.887
    },
    {
        "x": 34.12,
        "y": 22.887
    },
    {
        "x": 34.13,
        "y": 22.887
    },
    {
        "x": 34.14,
        "y": 22.887
    },
    {
        "x": 34.15,
        "y": 22.887
    },
    {
        "x": 34.16,
        "y": 22.887
    },
    {
        "x": 34.17,
        "y": 22.887
    },
    {
        "x": 34.18,
        "y": 22.887
    },
    {
        "x": 34.19,
        "y": 22.887
    },
    {
        "x": 34.2,
        "y": 22.887
    },
    {
        "x": 34.21,
        "y": 22.887
    },
    {
        "x": 34.22,
        "y": 22.896
    },
    {
        "x": 34.23,
        "y": 22.896
    },
    {
        "x": 34.24,
        "y": 22.896
    },
    {
        "x": 34.25,
        "y": 22.896
    },
    {
        "x": 34.26,
        "y": 22.896
    },
    {
        "x": 34.27,
        "y": 22.896
    },
    {
        "x": 34.28,
        "y": 22.887
    },
    {
        "x": 34.29,
        "y": 22.896
    },
    {
        "x": 34.3,
        "y": 22.896
    },
    {
        "x": 34.31,
        "y": 22.896
    },
    {
        "x": 34.32,
        "y": 22.896
    },
    {
        "x": 34.33,
        "y": 22.896
    },
    {
        "x": 34.34,
        "y": 22.896
    },
    {
        "x": 34.35,
        "y": 22.896
    },
    {
        "x": 34.36,
        "y": 22.896
    },
    {
        "x": 34.37,
        "y": 22.896
    },
    {
        "x": 34.38,
        "y": 22.896
    },
    {
        "x": 34.39,
        "y": 22.896
    },
    {
        "x": 34.4,
        "y": 22.896
    },
    {
        "x": 34.41,
        "y": 22.896
    },
    {
        "x": 34.42,
        "y": 22.896
    },
    {
        "x": 34.43,
        "y": 22.896
    },
    {
        "x": 34.44,
        "y": 22.896
    },
    {
        "x": 34.45,
        "y": 22.896
    },
    {
        "x": 34.46,
        "y": 22.905
    },
    {
        "x": 34.47,
        "y": 22.905
    },
    {
        "x": 34.48,
        "y": 22.905
    },
    {
        "x": 34.49,
        "y": 22.905
    },
    {
        "x": 34.5,
        "y": 22.905
    },
    {
        "x": 34.51,
        "y": 22.905
    },
    {
        "x": 34.52,
        "y": 22.905
    },
    {
        "x": 34.53,
        "y": 22.905
    },
    {
        "x": 34.54,
        "y": 22.905
    },
    {
        "x": 34.55,
        "y": 22.905
    },
    {
        "x": 34.56,
        "y": 22.905
    },
    {
        "x": 34.57,
        "y": 22.905
    },
    {
        "x": 34.58,
        "y": 22.905
    },
    {
        "x": 34.59,
        "y": 22.905
    },
    {
        "x": 34.6,
        "y": 22.905
    },
    {
        "x": 34.61,
        "y": 22.914
    },
    {
        "x": 34.62,
        "y": 22.914
    },
    {
        "x": 34.63,
        "y": 22.914
    },
    {
        "x": 34.64,
        "y": 22.914
    },
    {
        "x": 34.65,
        "y": 22.914
    },
    {
        "x": 34.66,
        "y": 22.914
    },
    {
        "x": 34.67,
        "y": 22.914
    },
    {
        "x": 34.68,
        "y": 22.914
    },
    {
        "x": 34.69,
        "y": 22.914
    },
    {
        "x": 34.7,
        "y": 22.914
    },
    {
        "x": 34.71,
        "y": 22.914
    },
    {
        "x": 34.72,
        "y": 22.914
    },
    {
        "x": 34.73,
        "y": 22.914
    },
    {
        "x": 34.74,
        "y": 22.914
    },
    {
        "x": 34.75,
        "y": 22.914
    },
    {
        "x": 34.76,
        "y": 22.914
    },
    {
        "x": 34.77,
        "y": 22.914
    },
    {
        "x": 34.78,
        "y": 22.914
    },
    {
        "x": 34.79,
        "y": 22.914
    },
    {
        "x": 34.8,
        "y": 22.914
    },
    {
        "x": 34.81,
        "y": 22.914
    },
    {
        "x": 34.82,
        "y": 22.923
    },
    {
        "x": 34.83,
        "y": 22.914
    },
    {
        "x": 34.84,
        "y": 22.914
    },
    {
        "x": 34.85,
        "y": 22.914
    },
    {
        "x": 34.86,
        "y": 22.923
    },
    {
        "x": 34.87,
        "y": 22.923
    },
    {
        "x": 34.88,
        "y": 22.914
    },
    {
        "x": 34.89,
        "y": 22.923
    },
    {
        "x": 34.9,
        "y": 22.923
    },
    {
        "x": 34.91,
        "y": 22.923
    },
    {
        "x": 34.92,
        "y": 22.923
    },
    {
        "x": 34.93,
        "y": 22.923
    },
    {
        "x": 34.94,
        "y": 22.923
    },
    {
        "x": 34.95,
        "y": 22.923
    },
    {
        "x": 34.96,
        "y": 22.923
    },
    {
        "x": 34.97,
        "y": 22.923
    },
    {
        "x": 34.98,
        "y": 22.923
    },
    {
        "x": 34.99,
        "y": 22.923
    },
    {
        "x": 35,
        "y": 22.923
    },
    {
        "x": 35.01,
        "y": 22.932
    },
    {
        "x": 35.02,
        "y": 22.923
    },
    {
        "x": 35.03,
        "y": 22.923
    },
    {
        "x": 35.04,
        "y": 22.923
    },
    {
        "x": 35.05,
        "y": 22.923
    },
    {
        "x": 35.06,
        "y": 22.932
    },
    {
        "x": 35.07,
        "y": 22.932
    },
    {
        "x": 35.08,
        "y": 22.932
    },
    {
        "x": 35.09,
        "y": 22.932
    },
    {
        "x": 35.1,
        "y": 22.932
    },
    {
        "x": 35.11,
        "y": 22.932
    },
    {
        "x": 35.12,
        "y": 22.932
    },
    {
        "x": 35.13,
        "y": 22.932
    },
    {
        "x": 35.14,
        "y": 22.932
    },
    {
        "x": 35.15,
        "y": 22.941
    },
    {
        "x": 35.16,
        "y": 22.932
    },
    {
        "x": 35.17,
        "y": 22.932
    },
    {
        "x": 35.18,
        "y": 22.932
    },
    {
        "x": 35.19,
        "y": 22.941
    },
    {
        "x": 35.2,
        "y": 22.932
    },
    {
        "x": 35.21,
        "y": 22.932
    },
    {
        "x": 35.22,
        "y": 22.941
    },
    {
        "x": 35.23,
        "y": 22.932
    },
    {
        "x": 35.24,
        "y": 22.941
    },
    {
        "x": 35.25,
        "y": 22.941
    },
    {
        "x": 35.26,
        "y": 22.941
    },
    {
        "x": 35.27,
        "y": 22.941
    },
    {
        "x": 35.28,
        "y": 22.941
    },
    {
        "x": 35.29,
        "y": 22.941
    },
    {
        "x": 35.3,
        "y": 22.941
    },
    {
        "x": 35.31,
        "y": 22.941
    },
    {
        "x": 35.32,
        "y": 22.941
    },
    {
        "x": 35.33,
        "y": 22.941
    },
    {
        "x": 35.34,
        "y": 22.941
    },
    {
        "x": 35.35,
        "y": 22.941
    },
    {
        "x": 35.36,
        "y": 22.941
    },
    {
        "x": 35.37,
        "y": 22.941
    },
    {
        "x": 35.38,
        "y": 22.941
    },
    {
        "x": 35.39,
        "y": 22.941
    },
    {
        "x": 35.4,
        "y": 22.941
    },
    {
        "x": 35.41,
        "y": 22.941
    },
    {
        "x": 35.42,
        "y": 22.95
    },
    {
        "x": 35.43,
        "y": 22.941
    },
    {
        "x": 35.44,
        "y": 22.941
    },
    {
        "x": 35.45,
        "y": 22.941
    },
    {
        "x": 35.46,
        "y": 22.95
    },
    {
        "x": 35.47,
        "y": 22.941
    },
    {
        "x": 35.48,
        "y": 22.941
    },
    {
        "x": 35.49,
        "y": 22.941
    },
    {
        "x": 35.5,
        "y": 22.941
    },
    {
        "x": 35.51,
        "y": 22.95
    },
    {
        "x": 35.52,
        "y": 22.95
    },
    {
        "x": 35.53,
        "y": 22.95
    },
    {
        "x": 35.54,
        "y": 22.95
    },
    {
        "x": 35.55,
        "y": 22.95
    },
    {
        "x": 35.56,
        "y": 22.95
    },
    {
        "x": 35.57,
        "y": 22.95
    },
    {
        "x": 35.58,
        "y": 22.95
    },
    {
        "x": 35.59,
        "y": 22.95
    },
    {
        "x": 35.6,
        "y": 22.95
    },
    {
        "x": 35.61,
        "y": 22.95
    },
    {
        "x": 35.62,
        "y": 22.95
    },
    {
        "x": 35.63,
        "y": 22.95
    },
    {
        "x": 35.64,
        "y": 22.95
    },
    {
        "x": 35.65,
        "y": 22.95
    },
    {
        "x": 35.66,
        "y": 22.95
    },
    {
        "x": 35.67,
        "y": 22.95
    },
    {
        "x": 35.68,
        "y": 22.95
    },
    {
        "x": 35.69,
        "y": 22.95
    },
    {
        "x": 35.7,
        "y": 22.959
    },
    {
        "x": 35.71,
        "y": 22.95
    },
    {
        "x": 35.72,
        "y": 22.959
    },
    {
        "x": 35.73,
        "y": 22.959
    },
    {
        "x": 35.74,
        "y": 22.95
    },
    {
        "x": 35.75,
        "y": 22.95
    },
    {
        "x": 35.76,
        "y": 22.95
    },
    {
        "x": 35.77,
        "y": 22.95
    },
    {
        "x": 35.78,
        "y": 22.959
    },
    {
        "x": 35.79,
        "y": 22.959
    },
    {
        "x": 35.8,
        "y": 22.959
    },
    {
        "x": 35.81,
        "y": 22.959
    },
    {
        "x": 35.82,
        "y": 22.959
    },
    {
        "x": 35.83,
        "y": 22.959
    },
    {
        "x": 35.84,
        "y": 22.959
    },
    {
        "x": 35.85,
        "y": 22.959
    },
    {
        "x": 35.86,
        "y": 22.959
    },
    {
        "x": 35.87,
        "y": 22.959
    },
    {
        "x": 35.88,
        "y": 22.959
    },
    {
        "x": 35.89,
        "y": 22.959
    },
    {
        "x": 35.9,
        "y": 22.959
    },
    {
        "x": 35.91,
        "y": 22.959
    },
    {
        "x": 35.92,
        "y": 22.959
    },
    {
        "x": 35.93,
        "y": 22.959
    },
    {
        "x": 35.94,
        "y": 22.968
    },
    {
        "x": 35.95,
        "y": 22.959
    },
    {
        "x": 35.96,
        "y": 22.959
    },
    {
        "x": 35.97,
        "y": 22.959
    },
    {
        "x": 35.98,
        "y": 22.968
    },
    {
        "x": 35.99,
        "y": 22.968
    },
    {
        "x": 36,
        "y": 22.959
    },
    {
        "x": 36.01,
        "y": 22.959
    },
    {
        "x": 36.02,
        "y": 22.959
    },
    {
        "x": 36.03,
        "y": 22.968
    },
    {
        "x": 36.04,
        "y": 22.968
    },
    {
        "x": 36.05,
        "y": 22.968
    },
    {
        "x": 36.06,
        "y": 22.959
    },
    {
        "x": 36.07,
        "y": 22.968
    },
    {
        "x": 36.08,
        "y": 22.968
    },
    {
        "x": 36.09,
        "y": 22.968
    },
    {
        "x": 36.1,
        "y": 22.968
    },
    {
        "x": 36.11,
        "y": 22.968
    },
    {
        "x": 36.12,
        "y": 22.968
    },
    {
        "x": 36.13,
        "y": 22.968
    },
    {
        "x": 36.14,
        "y": 22.968
    },
    {
        "x": 36.15,
        "y": 22.968
    },
    {
        "x": 36.16,
        "y": 22.968
    },
    {
        "x": 36.17,
        "y": 22.968
    },
    {
        "x": 36.18,
        "y": 22.968
    },
    {
        "x": 36.19,
        "y": 22.968
    },
    {
        "x": 36.2,
        "y": 22.968
    },
    {
        "x": 36.21,
        "y": 22.968
    },
    {
        "x": 36.22,
        "y": 22.968
    },
    {
        "x": 36.23,
        "y": 22.968
    },
    {
        "x": 36.24,
        "y": 22.968
    },
    {
        "x": 36.25,
        "y": 22.968
    },
    {
        "x": 36.26,
        "y": 22.968
    },
    {
        "x": 36.27,
        "y": 22.968
    },
    {
        "x": 36.28,
        "y": 22.968
    },
    {
        "x": 36.29,
        "y": 22.977
    },
    {
        "x": 36.3,
        "y": 22.977
    },
    {
        "x": 36.31,
        "y": 22.977
    },
    {
        "x": 36.32,
        "y": 22.968
    },
    {
        "x": 36.33,
        "y": 22.968
    },
    {
        "x": 36.34,
        "y": 22.977
    },
    {
        "x": 36.35,
        "y": 22.977
    },
    {
        "x": 36.36,
        "y": 22.977
    },
    {
        "x": 36.37,
        "y": 22.977
    },
    {
        "x": 36.38,
        "y": 22.977
    },
    {
        "x": 36.39,
        "y": 22.977
    },
    {
        "x": 36.4,
        "y": 22.977
    },
    {
        "x": 36.41,
        "y": 22.977
    },
    {
        "x": 36.42,
        "y": 22.977
    },
    {
        "x": 36.43,
        "y": 22.977
    },
    {
        "x": 36.44,
        "y": 22.977
    },
    {
        "x": 36.45,
        "y": 22.977
    },
    {
        "x": 36.46,
        "y": 22.977
    },
    {
        "x": 36.47,
        "y": 22.977
    },
    {
        "x": 36.48,
        "y": 22.977
    },
    {
        "x": 36.49,
        "y": 22.977
    },
    {
        "x": 36.5,
        "y": 22.977
    },
    {
        "x": 36.51,
        "y": 22.986
    },
    {
        "x": 36.52,
        "y": 22.977
    },
    {
        "x": 36.53,
        "y": 22.986
    },
    {
        "x": 36.54,
        "y": 22.977
    },
    {
        "x": 36.55,
        "y": 22.977
    },
    {
        "x": 36.56,
        "y": 22.977
    },
    {
        "x": 36.57,
        "y": 22.986
    },
    {
        "x": 36.58,
        "y": 22.977
    },
    {
        "x": 36.59,
        "y": 22.977
    },
    {
        "x": 36.6,
        "y": 22.986
    },
    {
        "x": 36.61,
        "y": 22.986
    },
    {
        "x": 36.62,
        "y": 22.986
    },
    {
        "x": 36.63,
        "y": 22.986
    },
    {
        "x": 36.64,
        "y": 22.986
    },
    {
        "x": 36.65,
        "y": 22.986
    },
    {
        "x": 36.66,
        "y": 22.986
    },
    {
        "x": 36.67,
        "y": 22.986
    },
    {
        "x": 36.68,
        "y": 22.986
    },
    {
        "x": 36.69,
        "y": 22.986
    },
    {
        "x": 36.7,
        "y": 22.986
    },
    {
        "x": 36.71,
        "y": 22.986
    },
    {
        "x": 36.72,
        "y": 22.986
    },
    {
        "x": 36.73,
        "y": 22.986
    },
    {
        "x": 36.74,
        "y": 22.986
    },
    {
        "x": 36.75,
        "y": 22.986
    },
    {
        "x": 36.76,
        "y": 22.986
    },
    {
        "x": 36.77,
        "y": 22.986
    },
    {
        "x": 36.78,
        "y": 22.986
    },
    {
        "x": 36.79,
        "y": 22.986
    },
    {
        "x": 36.8,
        "y": 22.986
    },
    {
        "x": 36.81,
        "y": 22.986
    },
    {
        "x": 36.82,
        "y": 22.986
    },
    {
        "x": 36.83,
        "y": 22.986
    },
    {
        "x": 36.84,
        "y": 22.986
    },
    {
        "x": 36.85,
        "y": 22.986
    },
    {
        "x": 36.86,
        "y": 22.986
    },
    {
        "x": 36.87,
        "y": 22.986
    },
    {
        "x": 36.88,
        "y": 22.986
    },
    {
        "x": 36.89,
        "y": 22.986
    },
    {
        "x": 36.9,
        "y": 22.986
    },
    {
        "x": 36.91,
        "y": 22.986
    },
    {
        "x": 36.92,
        "y": 22.986
    },
    {
        "x": 36.93,
        "y": 22.995
    },
    {
        "x": 36.94,
        "y": 22.986
    },
    {
        "x": 36.95,
        "y": 22.986
    },
    {
        "x": 36.96,
        "y": 22.986
    },
    {
        "x": 36.97,
        "y": 22.995
    },
    {
        "x": 36.98,
        "y": 22.986
    },
    {
        "x": 36.99,
        "y": 22.986
    },
    {
        "x": 37,
        "y": 22.986
    },
    {
        "x": 37.01,
        "y": 22.995
    },
    {
        "x": 37.02,
        "y": 22.995
    },
    {
        "x": 37.03,
        "y": 22.995
    },
    {
        "x": 37.04,
        "y": 22.986
    },
    {
        "x": 37.05,
        "y": 22.995
    },
    {
        "x": 37.06,
        "y": 22.995
    },
    {
        "x": 37.07,
        "y": 22.995
    },
    {
        "x": 37.08,
        "y": 22.995
    },
    {
        "x": 37.09,
        "y": 22.986
    },
    {
        "x": 37.1,
        "y": 22.986
    },
    {
        "x": 37.11,
        "y": 22.995
    },
    {
        "x": 37.12,
        "y": 22.995
    },
    {
        "x": 37.13,
        "y": 22.995
    },
    {
        "x": 37.14,
        "y": 22.995
    },
    {
        "x": 37.15,
        "y": 22.986
    },
    {
        "x": 37.16,
        "y": 22.995
    },
    {
        "x": 37.17,
        "y": 22.995
    },
    {
        "x": 37.18,
        "y": 22.995
    },
    {
        "x": 37.19,
        "y": 22.995
    },
    {
        "x": 37.2,
        "y": 22.995
    },
    {
        "x": 37.21,
        "y": 22.995
    },
    {
        "x": 37.22,
        "y": 22.995
    },
    {
        "x": 37.23,
        "y": 22.995
    },
    {
        "x": 37.24,
        "y": 22.995
    },
    {
        "x": 37.25,
        "y": 22.995
    },
    {
        "x": 37.26,
        "y": 22.995
    },
    {
        "x": 37.27,
        "y": 22.995
    },
    {
        "x": 37.28,
        "y": 22.995
    },
    {
        "x": 37.29,
        "y": 22.995
    },
    {
        "x": 37.3,
        "y": 22.995
    },
    {
        "x": 37.31,
        "y": 22.995
    },
    {
        "x": 37.32,
        "y": 22.995
    },
    {
        "x": 37.33,
        "y": 22.995
    },
    {
        "x": 37.34,
        "y": 22.995
    },
    {
        "x": 37.35,
        "y": 23.004
    },
    {
        "x": 37.36,
        "y": 23.004
    },
    {
        "x": 37.37,
        "y": 22.995
    },
    {
        "x": 37.38,
        "y": 22.995
    },
    {
        "x": 37.39,
        "y": 22.995
    },
    {
        "x": 37.4,
        "y": 22.995
    },
    {
        "x": 37.41,
        "y": 23.004
    },
    {
        "x": 37.42,
        "y": 23.004
    },
    {
        "x": 37.43,
        "y": 23.004
    },
    {
        "x": 37.44,
        "y": 23.004
    },
    {
        "x": 37.45,
        "y": 23.004
    },
    {
        "x": 37.46,
        "y": 23.004
    },
    {
        "x": 37.47,
        "y": 23.004
    },
    {
        "x": 37.48,
        "y": 23.004
    },
    {
        "x": 37.49,
        "y": 23.004
    },
    {
        "x": 37.5,
        "y": 23.004
    },
    {
        "x": 37.51,
        "y": 23.004
    },
    {
        "x": 37.52,
        "y": 23.004
    },
    {
        "x": 37.53,
        "y": 23.004
    },
    {
        "x": 37.54,
        "y": 23.004
    },
    {
        "x": 37.55,
        "y": 23.004
    },
    {
        "x": 37.56,
        "y": 23.004
    },
    {
        "x": 37.57,
        "y": 23.004
    },
    {
        "x": 37.58,
        "y": 23.004
    },
    {
        "x": 37.59,
        "y": 23.004
    },
    {
        "x": 37.6,
        "y": 23.004
    },
    {
        "x": 37.61,
        "y": 23.004
    },
    {
        "x": 37.62,
        "y": 23.004
    },
    {
        "x": 37.63,
        "y": 23.004
    },
    {
        "x": 37.64,
        "y": 23.013
    },
    {
        "x": 37.65,
        "y": 23.013
    },
    {
        "x": 37.66,
        "y": 23.004
    },
    {
        "x": 37.67,
        "y": 23.013
    },
    {
        "x": 37.68,
        "y": 23.004
    },
    {
        "x": 37.69,
        "y": 23.004
    },
    {
        "x": 37.7,
        "y": 23.013
    },
    {
        "x": 37.71,
        "y": 23.013
    },
    {
        "x": 37.72,
        "y": 23.013
    },
    {
        "x": 37.73,
        "y": 23.013
    },
    {
        "x": 37.74,
        "y": 23.013
    },
    {
        "x": 37.75,
        "y": 23.013
    },
    {
        "x": 37.76,
        "y": 23.013
    },
    {
        "x": 37.77,
        "y": 23.013
    },
    {
        "x": 37.78,
        "y": 23.013
    },
    {
        "x": 37.79,
        "y": 23.013
    },
    {
        "x": 37.8,
        "y": 23.004
    },
    {
        "x": 37.81,
        "y": 23.013
    },
    {
        "x": 37.82,
        "y": 23.013
    },
    {
        "x": 37.83,
        "y": 23.013
    },
    {
        "x": 37.84,
        "y": 23.013
    },
    {
        "x": 37.85,
        "y": 23.013
    },
    {
        "x": 37.86,
        "y": 23.013
    },
    {
        "x": 37.87,
        "y": 23.013
    },
    {
        "x": 37.88,
        "y": 23.013
    },
    {
        "x": 37.89,
        "y": 23.013
    },
    {
        "x": 37.9,
        "y": 23.013
    },
    {
        "x": 37.91,
        "y": 23.013
    },
    {
        "x": 37.92,
        "y": 23.013
    },
    {
        "x": 37.93,
        "y": 23.013
    },
    {
        "x": 37.94,
        "y": 23.013
    },
    {
        "x": 37.95,
        "y": 23.013
    },
    {
        "x": 37.96,
        "y": 23.013
    },
    {
        "x": 37.97,
        "y": 23.013
    },
    {
        "x": 37.98,
        "y": 23.013
    },
    {
        "x": 37.99,
        "y": 23.013
    },
    {
        "x": 38,
        "y": 23.013
    },
    {
        "x": 38.01,
        "y": 23.013
    },
    {
        "x": 38.02,
        "y": 23.013
    },
    {
        "x": 38.03,
        "y": 23.013
    },
    {
        "x": 38.04,
        "y": 23.013
    },
    {
        "x": 38.05,
        "y": 23.013
    },
    {
        "x": 38.06,
        "y": 23.013
    },
    {
        "x": 38.07,
        "y": 23.013
    },
    {
        "x": 38.08,
        "y": 23.013
    },
    {
        "x": 38.09,
        "y": 23.013
    },
    {
        "x": 38.1,
        "y": 23.013
    },
    {
        "x": 38.11,
        "y": 23.013
    },
    {
        "x": 38.12,
        "y": 23.013
    },
    {
        "x": 38.13,
        "y": 23.013
    },
    {
        "x": 38.14,
        "y": 23.022
    },
    {
        "x": 38.15,
        "y": 23.013
    },
    {
        "x": 38.16,
        "y": 23.022
    },
    {
        "x": 38.17,
        "y": 23.022
    },
    {
        "x": 38.18,
        "y": 23.022
    },
    {
        "x": 38.19,
        "y": 23.013
    },
    {
        "x": 38.2,
        "y": 23.022
    },
    {
        "x": 38.21,
        "y": 23.022
    },
    {
        "x": 38.22,
        "y": 23.022
    },
    {
        "x": 38.23,
        "y": 23.022
    },
    {
        "x": 38.24,
        "y": 23.022
    },
    {
        "x": 38.25,
        "y": 23.022
    },
    {
        "x": 38.26,
        "y": 23.022
    },
    {
        "x": 38.27,
        "y": 23.022
    },
    {
        "x": 38.28,
        "y": 23.022
    },
    {
        "x": 38.29,
        "y": 23.022
    },
    {
        "x": 38.3,
        "y": 23.022
    },
    {
        "x": 38.31,
        "y": 23.022
    },
    {
        "x": 38.32,
        "y": 23.022
    },
    {
        "x": 38.33,
        "y": 23.022
    },
    {
        "x": 38.34,
        "y": 23.022
    },
    {
        "x": 38.35,
        "y": 23.022
    },
    {
        "x": 38.36,
        "y": 23.022
    },
    {
        "x": 38.37,
        "y": 23.022
    },
    {
        "x": 38.38,
        "y": 23.022
    },
    {
        "x": 38.39,
        "y": 23.022
    },
    {
        "x": 38.4,
        "y": 23.022
    },
    {
        "x": 38.41,
        "y": 23.022
    },
    {
        "x": 38.42,
        "y": 23.031
    },
    {
        "x": 38.43,
        "y": 23.031
    },
    {
        "x": 38.44,
        "y": 23.031
    },
    {
        "x": 38.45,
        "y": 23.031
    },
    {
        "x": 38.46,
        "y": 23.022
    },
    {
        "x": 38.47,
        "y": 23.031
    },
    {
        "x": 38.48,
        "y": 23.031
    },
    {
        "x": 38.49,
        "y": 23.031
    },
    {
        "x": 38.5,
        "y": 23.022
    },
    {
        "x": 38.51,
        "y": 23.031
    },
    {
        "x": 38.52,
        "y": 23.031
    },
    {
        "x": 38.53,
        "y": 23.022
    },
    {
        "x": 38.54,
        "y": 23.031
    },
    {
        "x": 38.55,
        "y": 23.031
    },
    {
        "x": 38.56,
        "y": 23.031
    },
    {
        "x": 38.57,
        "y": 23.031
    },
    {
        "x": 38.58,
        "y": 23.031
    },
    {
        "x": 38.59,
        "y": 23.031
    },
    {
        "x": 38.6,
        "y": 23.031
    },
    {
        "x": 38.61,
        "y": 23.031
    },
    {
        "x": 38.62,
        "y": 23.031
    },
    {
        "x": 38.63,
        "y": 23.031
    },
    {
        "x": 38.64,
        "y": 23.031
    },
    {
        "x": 38.65,
        "y": 23.031
    },
    {
        "x": 38.66,
        "y": 23.031
    },
    {
        "x": 38.67,
        "y": 23.031
    },
    {
        "x": 38.68,
        "y": 23.031
    },
    {
        "x": 38.69,
        "y": 23.031
    },
    {
        "x": 38.7,
        "y": 23.031
    },
    {
        "x": 38.71,
        "y": 23.031
    },
    {
        "x": 38.72,
        "y": 23.031
    },
    {
        "x": 38.73,
        "y": 23.04
    },
    {
        "x": 38.74,
        "y": 23.031
    },
    {
        "x": 38.75,
        "y": 23.04
    },
    {
        "x": 38.76,
        "y": 23.04
    },
    {
        "x": 38.77,
        "y": 23.031
    },
    {
        "x": 38.78,
        "y": 23.031
    },
    {
        "x": 38.79,
        "y": 23.031
    },
    {
        "x": 38.8,
        "y": 23.04
    },
    {
        "x": 38.81,
        "y": 23.04
    },
    {
        "x": 38.82,
        "y": 23.031
    },
    {
        "x": 38.83,
        "y": 23.04
    },
    {
        "x": 38.84,
        "y": 23.031
    },
    {
        "x": 38.85,
        "y": 23.04
    },
    {
        "x": 38.86,
        "y": 23.04
    },
    {
        "x": 38.87,
        "y": 23.04
    },
    {
        "x": 38.88,
        "y": 23.04
    },
    {
        "x": 38.89,
        "y": 23.04
    },
    {
        "x": 38.9,
        "y": 23.04
    },
    {
        "x": 38.91,
        "y": 23.04
    },
    {
        "x": 38.92,
        "y": 23.04
    },
    {
        "x": 38.93,
        "y": 23.04
    },
    {
        "x": 38.94,
        "y": 23.04
    },
    {
        "x": 38.95,
        "y": 23.04
    },
    {
        "x": 38.96,
        "y": 23.04
    },
    {
        "x": 38.97,
        "y": 23.04
    },
    {
        "x": 38.98,
        "y": 23.04
    },
    {
        "x": 38.99,
        "y": 23.04
    },
    {
        "x": 39,
        "y": 23.04
    },
    {
        "x": 39.01,
        "y": 23.04
    },
    {
        "x": 39.02,
        "y": 23.04
    },
    {
        "x": 39.03,
        "y": 23.04
    },
    {
        "x": 39.04,
        "y": 23.04
    },
    {
        "x": 39.05,
        "y": 23.04
    },
    {
        "x": 39.06,
        "y": 23.04
    },
    {
        "x": 39.07,
        "y": 23.04
    },
    {
        "x": 39.08,
        "y": 23.049
    },
    {
        "x": 39.09,
        "y": 23.04
    },
    {
        "x": 39.1,
        "y": 23.04
    },
    {
        "x": 39.11,
        "y": 23.04
    },
    {
        "x": 39.12,
        "y": 23.04
    },
    {
        "x": 39.13,
        "y": 23.04
    },
    {
        "x": 39.14,
        "y": 23.049
    },
    {
        "x": 39.15,
        "y": 23.049
    },
    {
        "x": 39.16,
        "y": 23.049
    },
    {
        "x": 39.17,
        "y": 23.049
    },
    {
        "x": 39.18,
        "y": 23.04
    },
    {
        "x": 39.19,
        "y": 23.049
    },
    {
        "x": 39.2,
        "y": 23.04
    },
    {
        "x": 39.21,
        "y": 23.049
    },
    {
        "x": 39.22,
        "y": 23.049
    },
    {
        "x": 39.23,
        "y": 23.049
    },
    {
        "x": 39.24,
        "y": 23.049
    },
    {
        "x": 39.25,
        "y": 23.049
    },
    {
        "x": 39.26,
        "y": 23.049
    },
    {
        "x": 39.27,
        "y": 23.049
    },
    {
        "x": 39.28,
        "y": 23.049
    },
    {
        "x": 39.29,
        "y": 23.049
    },
    {
        "x": 39.3,
        "y": 23.049
    },
    {
        "x": 39.31,
        "y": 23.049
    },
    {
        "x": 39.32,
        "y": 23.049
    },
    {
        "x": 39.33,
        "y": 23.049
    },
    {
        "x": 39.34,
        "y": 23.049
    },
    {
        "x": 39.35,
        "y": 23.049
    },
    {
        "x": 39.36,
        "y": 23.049
    },
    {
        "x": 39.37,
        "y": 23.049
    },
    {
        "x": 39.38,
        "y": 23.049
    },
    {
        "x": 39.39,
        "y": 23.049
    },
    {
        "x": 39.4,
        "y": 23.049
    },
    {
        "x": 39.41,
        "y": 23.049
    },
    {
        "x": 39.42,
        "y": 23.049
    },
    {
        "x": 39.43,
        "y": 23.049
    },
    {
        "x": 39.44,
        "y": 23.049
    },
    {
        "x": 39.45,
        "y": 23.049
    },
    {
        "x": 39.46,
        "y": 23.049
    },
    {
        "x": 39.47,
        "y": 23.049
    },
    {
        "x": 39.48,
        "y": 23.049
    },
    {
        "x": 39.49,
        "y": 23.049
    },
    {
        "x": 39.5,
        "y": 23.058
    },
    {
        "x": 39.51,
        "y": 23.049
    },
    {
        "x": 39.52,
        "y": 23.049
    },
    {
        "x": 39.53,
        "y": 23.049
    },
    {
        "x": 39.54,
        "y": 23.049
    },
    {
        "x": 39.55,
        "y": 23.049
    },
    {
        "x": 39.56,
        "y": 23.049
    },
    {
        "x": 39.57,
        "y": 23.058
    },
    {
        "x": 39.58,
        "y": 23.058
    },
    {
        "x": 39.59,
        "y": 23.049
    },
    {
        "x": 39.6,
        "y": 23.049
    },
    {
        "x": 39.61,
        "y": 23.049
    },
    {
        "x": 39.62,
        "y": 23.049
    },
    {
        "x": 39.63,
        "y": 23.049
    },
    {
        "x": 39.64,
        "y": 23.049
    },
    {
        "x": 39.65,
        "y": 23.058
    },
    {
        "x": 39.66,
        "y": 23.049
    },
    {
        "x": 39.67,
        "y": 23.058
    },
    {
        "x": 39.68,
        "y": 23.058
    },
    {
        "x": 39.69,
        "y": 23.058
    },
    {
        "x": 39.7,
        "y": 23.058
    },
    {
        "x": 39.71,
        "y": 23.058
    },
    {
        "x": 39.72,
        "y": 23.058
    },
    {
        "x": 39.73,
        "y": 23.058
    },
    {
        "x": 39.74,
        "y": 23.058
    },
    {
        "x": 39.75,
        "y": 23.058
    },
    {
        "x": 39.76,
        "y": 23.058
    },
    {
        "x": 39.77,
        "y": 23.058
    },
    {
        "x": 39.78,
        "y": 23.049
    },
    {
        "x": 39.79,
        "y": 23.058
    },
    {
        "x": 39.8,
        "y": 23.058
    },
    {
        "x": 39.81,
        "y": 23.058
    },
    {
        "x": 39.82,
        "y": 23.058
    },
    {
        "x": 39.83,
        "y": 23.058
    },
    {
        "x": 39.84,
        "y": 23.058
    },
    {
        "x": 39.85,
        "y": 23.058
    },
    {
        "x": 39.86,
        "y": 23.058
    },
    {
        "x": 39.87,
        "y": 23.058
    },
    {
        "x": 39.88,
        "y": 23.058
    },
    {
        "x": 39.89,
        "y": 23.058
    },
    {
        "x": 39.9,
        "y": 23.058
    },
    {
        "x": 39.91,
        "y": 23.058
    },
    {
        "x": 39.92,
        "y": 23.058
    },
    {
        "x": 39.93,
        "y": 23.058
    },
    {
        "x": 39.94,
        "y": 23.058
    },
    {
        "x": 39.95,
        "y": 23.058
    },
    {
        "x": 39.96,
        "y": 23.058
    },
    {
        "x": 39.97,
        "y": 23.058
    },
    {
        "x": 39.98,
        "y": 23.058
    },
    {
        "x": 39.99,
        "y": 23.058
    },
    {
        "x": 40,
        "y": 23.058
    },
    {
        "x": 40.01,
        "y": 23.058
    },
    {
        "x": 40.02,
        "y": 23.058
    },
    {
        "x": 40.03,
        "y": 23.067
    },
    {
        "x": 40.04,
        "y": 23.067
    },
    {
        "x": 40.05,
        "y": 23.058
    },
    {
        "x": 40.06,
        "y": 23.067
    },
    {
        "x": 40.07,
        "y": 23.067
    },
    {
        "x": 40.08,
        "y": 23.067
    },
    {
        "x": 40.09,
        "y": 23.067
    },
    {
        "x": 40.1,
        "y": 23.067
    },
    {
        "x": 40.11,
        "y": 23.067
    },
    {
        "x": 40.12,
        "y": 23.067
    },
    {
        "x": 40.13,
        "y": 23.067
    },
    {
        "x": 40.14,
        "y": 23.067
    },
    {
        "x": 40.15,
        "y": 23.067
    },
    {
        "x": 40.16,
        "y": 23.067
    },
    {
        "x": 40.17,
        "y": 23.067
    },
    {
        "x": 40.18,
        "y": 23.067
    },
    {
        "x": 40.19,
        "y": 23.067
    },
    {
        "x": 40.2,
        "y": 23.067
    },
    {
        "x": 40.21,
        "y": 23.067
    },
    {
        "x": 40.22,
        "y": 23.067
    },
    {
        "x": 40.23,
        "y": 23.067
    },
    {
        "x": 40.24,
        "y": 23.067
    },
    {
        "x": 40.25,
        "y": 23.067
    },
    {
        "x": 40.26,
        "y": 23.067
    },
    {
        "x": 40.27,
        "y": 23.067
    },
    {
        "x": 40.28,
        "y": 23.067
    },
    {
        "x": 40.29,
        "y": 23.067
    },
    {
        "x": 40.3,
        "y": 23.067
    },
    {
        "x": 40.31,
        "y": 23.067
    },
    {
        "x": 40.32,
        "y": 23.067
    },
    {
        "x": 40.33,
        "y": 23.067
    },
    {
        "x": 40.34,
        "y": 23.067
    },
    {
        "x": 40.35,
        "y": 23.067
    },
    {
        "x": 40.36,
        "y": 23.067
    },
    {
        "x": 40.37,
        "y": 23.067
    },
    {
        "x": 40.38,
        "y": 23.067
    },
    {
        "x": 40.39,
        "y": 23.076
    },
    {
        "x": 40.4,
        "y": 23.067
    },
    {
        "x": 40.41,
        "y": 23.067
    },
    {
        "x": 40.42,
        "y": 23.067
    },
    {
        "x": 40.43,
        "y": 23.067
    },
    {
        "x": 40.44,
        "y": 23.067
    },
    {
        "x": 40.45,
        "y": 23.067
    },
    {
        "x": 40.46,
        "y": 23.067
    },
    {
        "x": 40.47,
        "y": 23.067
    },
    {
        "x": 40.48,
        "y": 23.067
    },
    {
        "x": 40.49,
        "y": 23.067
    },
    {
        "x": 40.5,
        "y": 23.067
    },
    {
        "x": 40.51,
        "y": 23.067
    },
    {
        "x": 40.52,
        "y": 23.067
    },
    {
        "x": 40.53,
        "y": 23.067
    },
    {
        "x": 40.54,
        "y": 23.067
    },
    {
        "x": 40.55,
        "y": 23.067
    },
    {
        "x": 40.56,
        "y": 23.076
    },
    {
        "x": 40.57,
        "y": 23.067
    },
    {
        "x": 40.58,
        "y": 23.067
    },
    {
        "x": 40.59,
        "y": 23.076
    },
    {
        "x": 40.6,
        "y": 23.067
    },
    {
        "x": 40.61,
        "y": 23.076
    },
    {
        "x": 40.62,
        "y": 23.067
    },
    {
        "x": 40.63,
        "y": 23.067
    },
    {
        "x": 40.64,
        "y": 23.067
    },
    {
        "x": 40.65,
        "y": 23.067
    },
    {
        "x": 40.66,
        "y": 23.076
    },
    {
        "x": 40.67,
        "y": 23.076
    },
    {
        "x": 40.68,
        "y": 23.076
    },
    {
        "x": 40.69,
        "y": 23.076
    },
    {
        "x": 40.7,
        "y": 23.076
    },
    {
        "x": 40.71,
        "y": 23.076
    },
    {
        "x": 40.72,
        "y": 23.076
    },
    {
        "x": 40.73,
        "y": 23.076
    },
    {
        "x": 40.74,
        "y": 23.076
    },
    {
        "x": 40.75,
        "y": 23.067
    },
    {
        "x": 40.76,
        "y": 23.076
    },
    {
        "x": 40.77,
        "y": 23.076
    },
    {
        "x": 40.78,
        "y": 23.076
    },
    {
        "x": 40.79,
        "y": 23.076
    },
    {
        "x": 40.8,
        "y": 23.076
    },
    {
        "x": 40.81,
        "y": 23.076
    },
    {
        "x": 40.82,
        "y": 23.076
    },
    {
        "x": 40.83,
        "y": 23.076
    },
    {
        "x": 40.84,
        "y": 23.076
    },
    {
        "x": 40.85,
        "y": 23.076
    },
    {
        "x": 40.86,
        "y": 23.076
    },
    {
        "x": 40.87,
        "y": 23.076
    },
    {
        "x": 40.88,
        "y": 23.076
    },
    {
        "x": 40.89,
        "y": 23.076
    },
    {
        "x": 40.9,
        "y": 23.076
    },
    {
        "x": 40.91,
        "y": 23.076
    },
    {
        "x": 40.92,
        "y": 23.076
    },
    {
        "x": 40.93,
        "y": 23.076
    },
    {
        "x": 40.94,
        "y": 23.076
    },
    {
        "x": 40.95,
        "y": 23.076
    },
    {
        "x": 40.96,
        "y": 23.076
    },
    {
        "x": 40.97,
        "y": 23.076
    },
    {
        "x": 40.98,
        "y": 23.076
    },
    {
        "x": 40.99,
        "y": 23.076
    },
    {
        "x": 41,
        "y": 23.076
    },
    {
        "x": 41.01,
        "y": 23.085
    },
    {
        "x": 41.02,
        "y": 23.076
    },
    {
        "x": 41.03,
        "y": 23.076
    },
    {
        "x": 41.04,
        "y": 23.076
    },
    {
        "x": 41.05,
        "y": 23.085
    },
    {
        "x": 41.06,
        "y": 23.076
    },
    {
        "x": 41.07,
        "y": 23.076
    },
    {
        "x": 41.08,
        "y": 23.076
    },
    {
        "x": 41.09,
        "y": 23.076
    },
    {
        "x": 41.1,
        "y": 23.085
    },
    {
        "x": 41.11,
        "y": 23.076
    },
    {
        "x": 41.12,
        "y": 23.076
    },
    {
        "x": 41.13,
        "y": 23.085
    },
    {
        "x": 41.14,
        "y": 23.085
    },
    {
        "x": 41.15,
        "y": 23.085
    },
    {
        "x": 41.16,
        "y": 23.085
    },
    {
        "x": 41.17,
        "y": 23.085
    },
    {
        "x": 41.18,
        "y": 23.076
    },
    {
        "x": 41.19,
        "y": 23.085
    },
    {
        "x": 41.2,
        "y": 23.085
    },
    {
        "x": 41.21,
        "y": 23.085
    },
    {
        "x": 41.22,
        "y": 23.085
    },
    {
        "x": 41.23,
        "y": 23.085
    },
    {
        "x": 41.24,
        "y": 23.085
    },
    {
        "x": 41.25,
        "y": 23.085
    },
    {
        "x": 41.26,
        "y": 23.085
    },
    {
        "x": 41.27,
        "y": 23.085
    },
    {
        "x": 41.28,
        "y": 23.085
    },
    {
        "x": 41.29,
        "y": 23.085
    },
    {
        "x": 41.3,
        "y": 23.085
    },
    {
        "x": 41.31,
        "y": 23.085
    },
    {
        "x": 41.32,
        "y": 23.085
    },
    {
        "x": 41.33,
        "y": 23.085
    },
    {
        "x": 41.34,
        "y": 23.085
    },
    {
        "x": 41.35,
        "y": 23.085
    },
    {
        "x": 41.36,
        "y": 23.085
    },
    {
        "x": 41.37,
        "y": 23.085
    },
    {
        "x": 41.38,
        "y": 23.085
    },
    {
        "x": 41.39,
        "y": 23.085
    },
    {
        "x": 41.4,
        "y": 23.085
    },
    {
        "x": 41.41,
        "y": 23.085
    },
    {
        "x": 41.42,
        "y": 23.085
    },
    {
        "x": 41.43,
        "y": 23.085
    },
    {
        "x": 41.44,
        "y": 23.085
    },
    {
        "x": 41.45,
        "y": 23.085
    },
    {
        "x": 41.46,
        "y": 23.094
    },
    {
        "x": 41.47,
        "y": 23.094
    },
    {
        "x": 41.48,
        "y": 23.085
    },
    {
        "x": 41.49,
        "y": 23.085
    },
    {
        "x": 41.5,
        "y": 23.085
    },
    {
        "x": 41.51,
        "y": 23.085
    },
    {
        "x": 41.52,
        "y": 23.085
    },
    {
        "x": 41.53,
        "y": 23.085
    },
    {
        "x": 41.54,
        "y": 23.094
    },
    {
        "x": 41.55,
        "y": 23.094
    },
    {
        "x": 41.56,
        "y": 23.094
    },
    {
        "x": 41.57,
        "y": 23.085
    },
    {
        "x": 41.58,
        "y": 23.085
    },
    {
        "x": 41.59,
        "y": 23.094
    },
    {
        "x": 41.6,
        "y": 23.085
    },
    {
        "x": 41.61,
        "y": 23.094
    },
    {
        "x": 41.62,
        "y": 23.094
    },
    {
        "x": 41.63,
        "y": 23.094
    },
    {
        "x": 41.64,
        "y": 23.085
    },
    {
        "x": 41.65,
        "y": 23.085
    },
    {
        "x": 41.66,
        "y": 23.094
    },
    {
        "x": 41.67,
        "y": 23.094
    },
    {
        "x": 41.68,
        "y": 23.094
    },
    {
        "x": 41.69,
        "y": 23.094
    },
    {
        "x": 41.7,
        "y": 23.094
    },
    {
        "x": 41.71,
        "y": 23.085
    },
    {
        "x": 41.72,
        "y": 23.094
    },
    {
        "x": 41.73,
        "y": 23.094
    },
    {
        "x": 41.74,
        "y": 23.094
    },
    {
        "x": 41.75,
        "y": 23.094
    },
    {
        "x": 41.76,
        "y": 23.094
    },
    {
        "x": 41.77,
        "y": 23.094
    },
    {
        "x": 41.78,
        "y": 23.094
    },
    {
        "x": 41.79,
        "y": 23.094
    },
    {
        "x": 41.8,
        "y": 23.094
    },
    {
        "x": 41.81,
        "y": 23.094
    },
    {
        "x": 41.82,
        "y": 23.094
    },
    {
        "x": 41.83,
        "y": 23.094
    },
    {
        "x": 41.84,
        "y": 23.094
    },
    {
        "x": 41.85,
        "y": 23.094
    },
    {
        "x": 41.86,
        "y": 23.094
    },
    {
        "x": 41.87,
        "y": 23.094
    },
    {
        "x": 41.88,
        "y": 23.094
    },
    {
        "x": 41.89,
        "y": 23.094
    },
    {
        "x": 41.9,
        "y": 23.094
    },
    {
        "x": 41.91,
        "y": 23.094
    },
    {
        "x": 41.92,
        "y": 23.094
    },
    {
        "x": 41.93,
        "y": 23.094
    },
    {
        "x": 41.94,
        "y": 23.094
    },
    {
        "x": 41.95,
        "y": 23.094
    },
    {
        "x": 41.96,
        "y": 23.094
    },
    {
        "x": 41.97,
        "y": 23.094
    },
    {
        "x": 41.98,
        "y": 23.094
    },
    {
        "x": 41.99,
        "y": 23.094
    },
    {
        "x": 42,
        "y": 23.094
    },
    {
        "x": 42.01,
        "y": 23.094
    },
    {
        "x": 42.02,
        "y": 23.094
    },
    {
        "x": 42.03,
        "y": 23.094
    },
    {
        "x": 42.04,
        "y": 23.094
    },
    {
        "x": 42.05,
        "y": 23.094
    },
    {
        "x": 42.06,
        "y": 23.094
    },
    {
        "x": 42.07,
        "y": 23.094
    },
    {
        "x": 42.08,
        "y": 23.094
    },
    {
        "x": 42.09,
        "y": 23.094
    },
    {
        "x": 42.1,
        "y": 23.094
    },
    {
        "x": 42.11,
        "y": 23.094
    },
    {
        "x": 42.12,
        "y": 23.094
    },
    {
        "x": 42.13,
        "y": 23.094
    },
    {
        "x": 42.14,
        "y": 23.094
    },
    {
        "x": 42.15,
        "y": 23.103
    },
    {
        "x": 42.16,
        "y": 23.094
    },
    {
        "x": 42.17,
        "y": 23.094
    },
    {
        "x": 42.18,
        "y": 23.094
    },
    {
        "x": 42.19,
        "y": 23.094
    },
    {
        "x": 42.2,
        "y": 23.103
    },
    {
        "x": 42.21,
        "y": 23.103
    },
    {
        "x": 42.22,
        "y": 23.094
    },
    {
        "x": 42.23,
        "y": 23.103
    },
    {
        "x": 42.24,
        "y": 23.103
    },
    {
        "x": 42.25,
        "y": 23.103
    },
    {
        "x": 42.26,
        "y": 23.103
    },
    {
        "x": 42.27,
        "y": 23.103
    },
    {
        "x": 42.28,
        "y": 23.103
    },
    {
        "x": 42.29,
        "y": 23.103
    },
    {
        "x": 42.3,
        "y": 23.094
    },
    {
        "x": 42.31,
        "y": 23.094
    },
    {
        "x": 42.32,
        "y": 23.103
    },
    {
        "x": 42.33,
        "y": 23.103
    },
    {
        "x": 42.34,
        "y": 23.103
    },
    {
        "x": 42.35,
        "y": 23.103
    },
    {
        "x": 42.36,
        "y": 23.103
    },
    {
        "x": 42.37,
        "y": 23.094
    },
    {
        "x": 42.38,
        "y": 23.103
    },
    {
        "x": 42.39,
        "y": 23.103
    },
    {
        "x": 42.4,
        "y": 23.103
    },
    {
        "x": 42.41,
        "y": 23.103
    },
    {
        "x": 42.42,
        "y": 23.103
    },
    {
        "x": 42.43,
        "y": 23.103
    },
    {
        "x": 42.44,
        "y": 23.103
    },
    {
        "x": 42.45,
        "y": 23.103
    },
    {
        "x": 42.46,
        "y": 23.103
    },
    {
        "x": 42.47,
        "y": 23.094
    },
    {
        "x": 42.48,
        "y": 23.103
    },
    {
        "x": 42.49,
        "y": 23.103
    },
    {
        "x": 42.5,
        "y": 23.103
    },
    {
        "x": 42.51,
        "y": 23.103
    },
    {
        "x": 42.52,
        "y": 23.103
    },
    {
        "x": 42.53,
        "y": 23.103
    },
    {
        "x": 42.54,
        "y": 23.103
    },
    {
        "x": 42.55,
        "y": 23.103
    },
    {
        "x": 42.56,
        "y": 23.103
    },
    {
        "x": 42.57,
        "y": 23.103
    },
    {
        "x": 42.58,
        "y": 23.103
    },
    {
        "x": 42.59,
        "y": 23.103
    },
    {
        "x": 42.6,
        "y": 23.103
    },
    {
        "x": 42.61,
        "y": 23.103
    },
    {
        "x": 42.62,
        "y": 23.103
    },
    {
        "x": 42.63,
        "y": 23.103
    },
    {
        "x": 42.64,
        "y": 23.103
    },
    {
        "x": 42.65,
        "y": 23.103
    },
    {
        "x": 42.66,
        "y": 23.103
    },
    {
        "x": 42.67,
        "y": 23.103
    },
    {
        "x": 42.68,
        "y": 23.103
    },
    {
        "x": 42.69,
        "y": 23.103
    },
    {
        "x": 42.7,
        "y": 23.112
    },
    {
        "x": 42.71,
        "y": 23.103
    },
    {
        "x": 42.72,
        "y": 23.103
    },
    {
        "x": 42.73,
        "y": 23.103
    },
    {
        "x": 42.74,
        "y": 23.112
    },
    {
        "x": 42.75,
        "y": 23.112
    },
    {
        "x": 42.76,
        "y": 23.103
    },
    {
        "x": 42.77,
        "y": 23.103
    },
    {
        "x": 42.78,
        "y": 23.103
    },
    {
        "x": 42.79,
        "y": 23.112
    },
    {
        "x": 42.8,
        "y": 23.112
    },
    {
        "x": 42.81,
        "y": 23.112
    },
    {
        "x": 42.82,
        "y": 23.103
    },
    {
        "x": 42.83,
        "y": 23.103
    },
    {
        "x": 42.84,
        "y": 23.112
    },
    {
        "x": 42.85,
        "y": 23.103
    },
    {
        "x": 42.86,
        "y": 23.112
    },
    {
        "x": 42.87,
        "y": 23.112
    },
    {
        "x": 42.88,
        "y": 23.103
    },
    {
        "x": 42.89,
        "y": 23.103
    },
    {
        "x": 42.9,
        "y": 23.103
    },
    {
        "x": 42.91,
        "y": 23.103
    },
    {
        "x": 42.92,
        "y": 23.112
    },
    {
        "x": 42.93,
        "y": 23.103
    },
    {
        "x": 42.94,
        "y": 23.112
    },
    {
        "x": 42.95,
        "y": 23.112
    },
    {
        "x": 42.96,
        "y": 23.112
    },
    {
        "x": 42.97,
        "y": 23.112
    },
    {
        "x": 42.98,
        "y": 23.112
    },
    {
        "x": 42.99,
        "y": 23.112
    },
    {
        "x": 43,
        "y": 23.112
    },
    {
        "x": 43.01,
        "y": 23.103
    },
    {
        "x": 43.02,
        "y": 23.112
    },
    {
        "x": 43.03,
        "y": 23.112
    },
    {
        "x": 43.04,
        "y": 23.112
    },
    {
        "x": 43.05,
        "y": 23.112
    },
    {
        "x": 43.06,
        "y": 23.112
    },
    {
        "x": 43.07,
        "y": 23.112
    },
    {
        "x": 43.08,
        "y": 23.112
    },
    {
        "x": 43.09,
        "y": 23.112
    },
    {
        "x": 43.1,
        "y": 23.112
    },
    {
        "x": 43.11,
        "y": 23.112
    },
    {
        "x": 43.12,
        "y": 23.112
    },
    {
        "x": 43.13,
        "y": 23.112
    },
    {
        "x": 43.14,
        "y": 23.112
    },
    {
        "x": 43.15,
        "y": 23.112
    },
    {
        "x": 43.16,
        "y": 23.112
    },
    {
        "x": 43.17,
        "y": 23.112
    },
    {
        "x": 43.18,
        "y": 23.112
    },
    {
        "x": 43.19,
        "y": 23.112
    },
    {
        "x": 43.2,
        "y": 23.112
    },
    {
        "x": 43.21,
        "y": 23.112
    },
    {
        "x": 43.22,
        "y": 23.112
    },
    {
        "x": 43.23,
        "y": 23.112
    },
    {
        "x": 43.24,
        "y": 23.112
    },
    {
        "x": 43.25,
        "y": 23.112
    },
    {
        "x": 43.26,
        "y": 23.112
    },
    {
        "x": 43.27,
        "y": 23.112
    },
    {
        "x": 43.28,
        "y": 23.112
    },
    {
        "x": 43.29,
        "y": 23.112
    },
    {
        "x": 43.3,
        "y": 23.112
    },
    {
        "x": 43.31,
        "y": 23.112
    },
    {
        "x": 43.32,
        "y": 23.112
    },
    {
        "x": 43.33,
        "y": 23.112
    },
    {
        "x": 43.34,
        "y": 23.112
    },
    {
        "x": 43.35,
        "y": 23.112
    },
    {
        "x": 43.36,
        "y": 23.112
    },
    {
        "x": 43.37,
        "y": 23.112
    },
    {
        "x": 43.38,
        "y": 23.112
    },
    {
        "x": 43.39,
        "y": 23.112
    },
    {
        "x": 43.4,
        "y": 23.112
    },
    {
        "x": 43.41,
        "y": 23.112
    },
    {
        "x": 43.42,
        "y": 23.112
    },
    {
        "x": 43.43,
        "y": 23.112
    },
    {
        "x": 43.44,
        "y": 23.112
    },
    {
        "x": 43.45,
        "y": 23.112
    },
    {
        "x": 43.46,
        "y": 23.112
    },
    {
        "x": 43.47,
        "y": 23.112
    },
    {
        "x": 43.48,
        "y": 23.112
    },
    {
        "x": 43.49,
        "y": 23.112
    },
    {
        "x": 43.5,
        "y": 23.112
    },
    {
        "x": 43.51,
        "y": 23.112
    },
    {
        "x": 43.52,
        "y": 23.112
    },
    {
        "x": 43.53,
        "y": 23.112
    },
    {
        "x": 43.54,
        "y": 23.112
    },
    {
        "x": 43.55,
        "y": 23.112
    },
    {
        "x": 43.56,
        "y": 23.112
    },
    {
        "x": 43.57,
        "y": 23.112
    },
    {
        "x": 43.58,
        "y": 23.112
    },
    {
        "x": 43.59,
        "y": 23.112
    },
    {
        "x": 43.6,
        "y": 23.112
    },
    {
        "x": 43.61,
        "y": 23.121
    },
    {
        "x": 43.62,
        "y": 23.121
    },
    {
        "x": 43.63,
        "y": 23.112
    },
    {
        "x": 43.64,
        "y": 23.112
    },
    {
        "x": 43.65,
        "y": 23.121
    },
    {
        "x": 43.66,
        "y": 23.112
    },
    {
        "x": 43.67,
        "y": 23.121
    },
    {
        "x": 43.68,
        "y": 23.121
    },
    {
        "x": 43.69,
        "y": 23.121
    },
    {
        "x": 43.7,
        "y": 23.121
    },
    {
        "x": 43.71,
        "y": 23.121
    },
    {
        "x": 43.72,
        "y": 23.121
    },
    {
        "x": 43.73,
        "y": 23.121
    },
    {
        "x": 43.74,
        "y": 23.121
    },
    {
        "x": 43.75,
        "y": 23.121
    },
    {
        "x": 43.76,
        "y": 23.121
    },
    {
        "x": 43.77,
        "y": 23.121
    },
    {
        "x": 43.78,
        "y": 23.121
    },
    {
        "x": 43.79,
        "y": 23.121
    },
    {
        "x": 43.8,
        "y": 23.121
    },
    {
        "x": 43.81,
        "y": 23.121
    },
    {
        "x": 43.82,
        "y": 23.121
    },
    {
        "x": 43.83,
        "y": 23.121
    },
    {
        "x": 43.84,
        "y": 23.121
    },
    {
        "x": 43.85,
        "y": 23.121
    },
    {
        "x": 43.86,
        "y": 23.121
    },
    {
        "x": 43.87,
        "y": 23.121
    },
    {
        "x": 43.88,
        "y": 23.121
    },
    {
        "x": 43.89,
        "y": 23.121
    },
    {
        "x": 43.9,
        "y": 23.121
    },
    {
        "x": 43.91,
        "y": 23.121
    },
    {
        "x": 43.92,
        "y": 23.121
    },
    {
        "x": 43.93,
        "y": 23.121
    },
    {
        "x": 43.94,
        "y": 23.121
    },
    {
        "x": 43.95,
        "y": 23.121
    },
    {
        "x": 43.96,
        "y": 23.121
    },
    {
        "x": 43.97,
        "y": 23.121
    },
    {
        "x": 43.98,
        "y": 23.121
    },
    {
        "x": 43.99,
        "y": 23.121
    },
    {
        "x": 44,
        "y": 23.121
    },
    {
        "x": 44.01,
        "y": 23.121
    },
    {
        "x": 44.02,
        "y": 23.121
    },
    {
        "x": 44.03,
        "y": 23.121
    },
    {
        "x": 44.04,
        "y": 23.121
    },
    {
        "x": 44.05,
        "y": 23.121
    },
    {
        "x": 44.06,
        "y": 23.121
    },
    {
        "x": 44.07,
        "y": 23.13
    },
    {
        "x": 44.08,
        "y": 23.121
    },
    {
        "x": 44.09,
        "y": 23.121
    },
    {
        "x": 44.1,
        "y": 23.121
    },
    {
        "x": 44.11,
        "y": 23.121
    },
    {
        "x": 44.12,
        "y": 23.121
    },
    {
        "x": 44.13,
        "y": 23.121
    },
    {
        "x": 44.14,
        "y": 23.121
    },
    {
        "x": 44.15,
        "y": 23.121
    },
    {
        "x": 44.16,
        "y": 23.121
    },
    {
        "x": 44.17,
        "y": 23.121
    },
    {
        "x": 44.18,
        "y": 23.121
    },
    {
        "x": 44.19,
        "y": 23.121
    },
    {
        "x": 44.2,
        "y": 23.121
    },
    {
        "x": 44.21,
        "y": 23.121
    },
    {
        "x": 44.22,
        "y": 23.121
    },
    {
        "x": 44.23,
        "y": 23.13
    },
    {
        "x": 44.24,
        "y": 23.13
    },
    {
        "x": 44.25,
        "y": 23.121
    },
    {
        "x": 44.26,
        "y": 23.121
    },
    {
        "x": 44.27,
        "y": 23.121
    },
    {
        "x": 44.28,
        "y": 23.121
    },
    {
        "x": 44.29,
        "y": 23.121
    },
    {
        "x": 44.3,
        "y": 23.121
    },
    {
        "x": 44.31,
        "y": 23.121
    },
    {
        "x": 44.32,
        "y": 23.13
    },
    {
        "x": 44.33,
        "y": 23.13
    },
    {
        "x": 44.34,
        "y": 23.121
    },
    {
        "x": 44.35,
        "y": 23.121
    },
    {
        "x": 44.36,
        "y": 23.121
    },
    {
        "x": 44.37,
        "y": 23.121
    },
    {
        "x": 44.38,
        "y": 23.13
    },
    {
        "x": 44.39,
        "y": 23.13
    },
    {
        "x": 44.4,
        "y": 23.13
    },
    {
        "x": 44.41,
        "y": 23.121
    },
    {
        "x": 44.42,
        "y": 23.13
    },
    {
        "x": 44.43,
        "y": 23.121
    },
    {
        "x": 44.44,
        "y": 23.13
    },
    {
        "x": 44.45,
        "y": 23.13
    },
    {
        "x": 44.46,
        "y": 23.13
    },
    {
        "x": 44.47,
        "y": 23.13
    },
    {
        "x": 44.48,
        "y": 23.13
    },
    {
        "x": 44.49,
        "y": 23.13
    },
    {
        "x": 44.5,
        "y": 23.13
    },
    {
        "x": 44.51,
        "y": 23.121
    },
    {
        "x": 44.52,
        "y": 23.13
    },
    {
        "x": 44.53,
        "y": 23.13
    },
    {
        "x": 44.54,
        "y": 23.13
    },
    {
        "x": 44.55,
        "y": 23.13
    },
    {
        "x": 44.56,
        "y": 23.13
    },
    {
        "x": 44.57,
        "y": 23.13
    },
    {
        "x": 44.58,
        "y": 23.13
    },
    {
        "x": 44.59,
        "y": 23.13
    },
    {
        "x": 44.6,
        "y": 23.13
    },
    {
        "x": 44.61,
        "y": 23.13
    },
    {
        "x": 44.62,
        "y": 23.121
    },
    {
        "x": 44.63,
        "y": 23.13
    },
    {
        "x": 44.64,
        "y": 23.13
    },
    {
        "x": 44.65,
        "y": 23.13
    },
    {
        "x": 44.66,
        "y": 23.13
    },
    {
        "x": 44.67,
        "y": 23.13
    },
    {
        "x": 44.68,
        "y": 23.13
    },
    {
        "x": 44.69,
        "y": 23.13
    },
    {
        "x": 44.7,
        "y": 23.13
    },
    {
        "x": 44.71,
        "y": 23.13
    },
    {
        "x": 44.72,
        "y": 23.13
    },
    {
        "x": 44.73,
        "y": 23.13
    },
    {
        "x": 44.74,
        "y": 23.13
    },
    {
        "x": 44.75,
        "y": 23.13
    },
    {
        "x": 44.76,
        "y": 23.13
    },
    {
        "x": 44.77,
        "y": 23.13
    },
    {
        "x": 44.78,
        "y": 23.13
    },
    {
        "x": 44.79,
        "y": 23.13
    },
    {
        "x": 44.8,
        "y": 23.13
    },
    {
        "x": 44.81,
        "y": 23.13
    },
    {
        "x": 44.82,
        "y": 23.13
    },
    {
        "x": 44.83,
        "y": 23.13
    },
    {
        "x": 44.84,
        "y": 23.13
    },
    {
        "x": 44.85,
        "y": 23.13
    },
    {
        "x": 44.86,
        "y": 23.13
    },
    {
        "x": 44.87,
        "y": 23.13
    },
    {
        "x": 44.88,
        "y": 23.13
    },
    {
        "x": 44.89,
        "y": 23.13
    },
    {
        "x": 44.9,
        "y": 23.13
    },
    {
        "x": 44.91,
        "y": 23.13
    },
    {
        "x": 44.92,
        "y": 23.13
    },
    {
        "x": 44.93,
        "y": 23.13
    },
    {
        "x": 44.94,
        "y": 23.13
    },
    {
        "x": 44.95,
        "y": 23.139
    },
    {
        "x": 44.96,
        "y": 23.139
    },
    {
        "x": 44.97,
        "y": 23.139
    },
    {
        "x": 44.98,
        "y": 23.139
    },
    {
        "x": 44.99,
        "y": 23.13
    },
    {
        "x": 45,
        "y": 23.13
    },
    {
        "x": 45.01,
        "y": 23.139
    },
    {
        "x": 45.02,
        "y": 23.139
    },
    {
        "x": 45.03,
        "y": 23.139
    },
    {
        "x": 45.04,
        "y": 23.139
    },
    {
        "x": 45.05,
        "y": 23.139
    },
    {
        "x": 45.06,
        "y": 23.139
    },
    {
        "x": 45.07,
        "y": 23.139
    },
    {
        "x": 45.08,
        "y": 23.13
    },
    {
        "x": 45.09,
        "y": 23.139
    },
    {
        "x": 45.1,
        "y": 23.139
    },
    {
        "x": 45.11,
        "y": 23.13
    },
    {
        "x": 45.12,
        "y": 23.13
    },
    {
        "x": 45.13,
        "y": 23.139
    },
    {
        "x": 45.14,
        "y": 23.139
    },
    {
        "x": 45.15,
        "y": 23.13
    },
    {
        "x": 45.16,
        "y": 23.139
    },
    {
        "x": 45.17,
        "y": 23.139
    },
    {
        "x": 45.18,
        "y": 23.139
    },
    {
        "x": 45.19,
        "y": 23.139
    },
    {
        "x": 45.2,
        "y": 23.139
    },
    {
        "x": 45.21,
        "y": 23.139
    },
    {
        "x": 45.22,
        "y": 23.139
    },
    {
        "x": 45.23,
        "y": 23.139
    },
    {
        "x": 45.24,
        "y": 23.139
    },
    {
        "x": 45.25,
        "y": 23.139
    },
    {
        "x": 45.26,
        "y": 23.13
    },
    {
        "x": 45.27,
        "y": 23.139
    },
    {
        "x": 45.28,
        "y": 23.139
    },
    {
        "x": 45.29,
        "y": 23.139
    },
    {
        "x": 45.3,
        "y": 23.139
    },
    {
        "x": 45.31,
        "y": 23.139
    },
    {
        "x": 45.32,
        "y": 23.139
    },
    {
        "x": 45.33,
        "y": 23.139
    },
    {
        "x": 45.34,
        "y": 23.139
    },
    {
        "x": 45.35,
        "y": 23.139
    },
    {
        "x": 45.36,
        "y": 23.139
    },
    {
        "x": 45.37,
        "y": 23.139
    },
    {
        "x": 45.38,
        "y": 23.139
    },
    {
        "x": 45.39,
        "y": 23.139
    },
    {
        "x": 45.4,
        "y": 23.13
    },
    {
        "x": 45.41,
        "y": 23.139
    },
    {
        "x": 45.42,
        "y": 23.139
    },
    {
        "x": 45.43,
        "y": 23.139
    },
    {
        "x": 45.44,
        "y": 23.139
    },
    {
        "x": 45.45,
        "y": 23.139
    },
    {
        "x": 45.46,
        "y": 23.139
    },
    {
        "x": 45.47,
        "y": 23.139
    },
    {
        "x": 45.48,
        "y": 23.139
    },
    {
        "x": 45.49,
        "y": 23.139
    },
    {
        "x": 45.5,
        "y": 23.139
    },
    {
        "x": 45.51,
        "y": 23.139
    },
    {
        "x": 45.52,
        "y": 23.139
    },
    {
        "x": 45.53,
        "y": 23.139
    },
    {
        "x": 45.54,
        "y": 23.139
    },
    {
        "x": 45.55,
        "y": 23.139
    },
    {
        "x": 45.56,
        "y": 23.139
    },
    {
        "x": 45.57,
        "y": 23.139
    },
    {
        "x": 45.58,
        "y": 23.139
    },
    {
        "x": 45.59,
        "y": 23.139
    },
    {
        "x": 45.6,
        "y": 23.139
    },
    {
        "x": 45.61,
        "y": 23.139
    },
    {
        "x": 45.62,
        "y": 23.139
    },
    {
        "x": 45.63,
        "y": 23.139
    },
    {
        "x": 45.64,
        "y": 23.139
    },
    {
        "x": 45.65,
        "y": 23.139
    },
    {
        "x": 45.66,
        "y": 23.139
    },
    {
        "x": 45.67,
        "y": 23.139
    },
    {
        "x": 45.68,
        "y": 23.139
    },
    {
        "x": 45.69,
        "y": 23.139
    },
    {
        "x": 45.7,
        "y": 23.139
    },
    {
        "x": 45.71,
        "y": 23.139
    },
    {
        "x": 45.72,
        "y": 23.139
    },
    {
        "x": 45.73,
        "y": 23.139
    },
    {
        "x": 45.74,
        "y": 23.139
    },
    {
        "x": 45.75,
        "y": 23.139
    },
    {
        "x": 45.76,
        "y": 23.139
    },
    {
        "x": 45.77,
        "y": 23.139
    },
    {
        "x": 45.78,
        "y": 23.139
    },
    {
        "x": 45.79,
        "y": 23.139
    },
    {
        "x": 45.8,
        "y": 23.139
    },
    {
        "x": 45.81,
        "y": 23.139
    },
    {
        "x": 45.82,
        "y": 23.139
    },
    {
        "x": 45.83,
        "y": 23.139
    },
    {
        "x": 45.84,
        "y": 23.139
    },
    {
        "x": 45.85,
        "y": 23.139
    },
    {
        "x": 45.86,
        "y": 23.139
    },
    {
        "x": 45.87,
        "y": 23.139
    },
    {
        "x": 45.88,
        "y": 23.139
    },
    {
        "x": 45.89,
        "y": 23.139
    },
    {
        "x": 45.9,
        "y": 23.139
    },
    {
        "x": 45.91,
        "y": 23.139
    },
    {
        "x": 45.92,
        "y": 23.139
    },
    {
        "x": 45.93,
        "y": 23.139
    },
    {
        "x": 45.94,
        "y": 23.139
    },
    {
        "x": 45.95,
        "y": 23.139
    },
    {
        "x": 45.96,
        "y": 23.139
    },
    {
        "x": 45.97,
        "y": 23.139
    },
    {
        "x": 45.98,
        "y": 23.139
    },
    {
        "x": 45.99,
        "y": 23.139
    },
    {
        "x": 46,
        "y": 23.148
    },
    {
        "x": 46.01,
        "y": 23.139
    },
    {
        "x": 46.02,
        "y": 23.139
    },
    {
        "x": 46.03,
        "y": 23.139
    },
    {
        "x": 46.04,
        "y": 23.139
    },
    {
        "x": 46.05,
        "y": 23.139
    },
    {
        "x": 46.06,
        "y": 23.148
    },
    {
        "x": 46.07,
        "y": 23.139
    },
    {
        "x": 46.08,
        "y": 23.139
    },
    {
        "x": 46.09,
        "y": 23.139
    },
    {
        "x": 46.1,
        "y": 23.139
    },
    {
        "x": 46.11,
        "y": 23.139
    },
    {
        "x": 46.12,
        "y": 23.139
    },
    {
        "x": 46.13,
        "y": 23.148
    },
    {
        "x": 46.14,
        "y": 23.148
    },
    {
        "x": 46.15,
        "y": 23.139
    },
    {
        "x": 46.16,
        "y": 23.139
    },
    {
        "x": 46.17,
        "y": 23.148
    },
    {
        "x": 46.18,
        "y": 23.148
    },
    {
        "x": 46.19,
        "y": 23.148
    },
    {
        "x": 46.2,
        "y": 23.148
    },
    {
        "x": 46.21,
        "y": 23.148
    },
    {
        "x": 46.22,
        "y": 23.148
    },
    {
        "x": 46.23,
        "y": 23.148
    },
    {
        "x": 46.24,
        "y": 23.148
    },
    {
        "x": 46.25,
        "y": 23.148
    },
    {
        "x": 46.26,
        "y": 23.148
    },
    {
        "x": 46.27,
        "y": 23.148
    },
    {
        "x": 46.28,
        "y": 23.148
    },
    {
        "x": 46.29,
        "y": 23.148
    },
    {
        "x": 46.3,
        "y": 23.148
    },
    {
        "x": 46.31,
        "y": 23.148
    },
    {
        "x": 46.32,
        "y": 23.148
    },
    {
        "x": 46.33,
        "y": 23.148
    },
    {
        "x": 46.34,
        "y": 23.148
    },
    {
        "x": 46.35,
        "y": 23.148
    },
    {
        "x": 46.36,
        "y": 23.148
    },
    {
        "x": 46.37,
        "y": 23.148
    },
    {
        "x": 46.38,
        "y": 23.148
    },
    {
        "x": 46.39,
        "y": 23.148
    },
    {
        "x": 46.4,
        "y": 23.148
    },
    {
        "x": 46.41,
        "y": 23.148
    },
    {
        "x": 46.42,
        "y": 23.148
    },
    {
        "x": 46.43,
        "y": 23.148
    },
    {
        "x": 46.44,
        "y": 23.148
    },
    {
        "x": 46.45,
        "y": 23.148
    },
    {
        "x": 46.46,
        "y": 23.148
    },
    {
        "x": 46.47,
        "y": 23.148
    },
    {
        "x": 46.48,
        "y": 23.148
    },
    {
        "x": 46.49,
        "y": 23.148
    },
    {
        "x": 46.5,
        "y": 23.148
    },
    {
        "x": 46.51,
        "y": 23.148
    },
    {
        "x": 46.52,
        "y": 23.148
    },
    {
        "x": 46.53,
        "y": 23.148
    },
    {
        "x": 46.54,
        "y": 23.148
    },
    {
        "x": 46.55,
        "y": 23.148
    },
    {
        "x": 46.56,
        "y": 23.148
    },
    {
        "x": 46.57,
        "y": 23.148
    },
    {
        "x": 46.58,
        "y": 23.148
    },
    {
        "x": 46.59,
        "y": 23.148
    },
    {
        "x": 46.6,
        "y": 23.148
    },
    {
        "x": 46.61,
        "y": 23.148
    },
    {
        "x": 46.62,
        "y": 23.148
    },
    {
        "x": 46.63,
        "y": 23.148
    },
    {
        "x": 46.64,
        "y": 23.148
    },
    {
        "x": 46.65,
        "y": 23.148
    },
    {
        "x": 46.66,
        "y": 23.148
    },
    {
        "x": 46.67,
        "y": 23.148
    },
    {
        "x": 46.68,
        "y": 23.148
    },
    {
        "x": 46.69,
        "y": 23.148
    },
    {
        "x": 46.7,
        "y": 23.148
    },
    {
        "x": 46.71,
        "y": 23.148
    },
    {
        "x": 46.72,
        "y": 23.148
    },
    {
        "x": 46.73,
        "y": 23.148
    },
    {
        "x": 46.74,
        "y": 23.148
    },
    {
        "x": 46.75,
        "y": 23.148
    },
    {
        "x": 46.76,
        "y": 23.148
    },
    {
        "x": 46.77,
        "y": 23.148
    },
    {
        "x": 46.78,
        "y": 23.148
    },
    {
        "x": 46.79,
        "y": 23.148
    },
    {
        "x": 46.8,
        "y": 23.157
    },
    {
        "x": 46.81,
        "y": 23.148
    },
    {
        "x": 46.82,
        "y": 23.148
    },
    {
        "x": 46.83,
        "y": 23.148
    },
    {
        "x": 46.84,
        "y": 23.148
    },
    {
        "x": 46.85,
        "y": 23.148
    },
    {
        "x": 46.86,
        "y": 23.157
    },
    {
        "x": 46.87,
        "y": 23.148
    },
    {
        "x": 46.88,
        "y": 23.148
    },
    {
        "x": 46.89,
        "y": 23.148
    },
    {
        "x": 46.9,
        "y": 23.157
    },
    {
        "x": 46.91,
        "y": 23.148
    },
    {
        "x": 46.92,
        "y": 23.148
    },
    {
        "x": 46.93,
        "y": 23.148
    },
    {
        "x": 46.94,
        "y": 23.157
    },
    {
        "x": 46.95,
        "y": 23.148
    },
    {
        "x": 46.96,
        "y": 23.148
    },
    {
        "x": 46.97,
        "y": 23.148
    },
    {
        "x": 46.98,
        "y": 23.148
    },
    {
        "x": 46.99,
        "y": 23.148
    },
    {
        "x": 47,
        "y": 23.148
    },
    {
        "x": 47.01,
        "y": 23.157
    },
    {
        "x": 47.02,
        "y": 23.148
    },
    {
        "x": 47.03,
        "y": 23.148
    },
    {
        "x": 47.04,
        "y": 23.157
    },
    {
        "x": 47.05,
        "y": 23.157
    },
    {
        "x": 47.06,
        "y": 23.157
    },
    {
        "x": 47.07,
        "y": 23.148
    },
    {
        "x": 47.08,
        "y": 23.148
    },
    {
        "x": 47.09,
        "y": 23.148
    },
    {
        "x": 47.1,
        "y": 23.157
    },
    {
        "x": 47.11,
        "y": 23.157
    },
    {
        "x": 47.12,
        "y": 23.157
    },
    {
        "x": 47.13,
        "y": 23.157
    },
    {
        "x": 47.14,
        "y": 23.157
    },
    {
        "x": 47.15,
        "y": 23.148
    },
    {
        "x": 47.16,
        "y": 23.148
    },
    {
        "x": 47.17,
        "y": 23.157
    },
    {
        "x": 47.18,
        "y": 23.157
    },
    {
        "x": 47.19,
        "y": 23.157
    },
    {
        "x": 47.2,
        "y": 23.157
    },
    {
        "x": 47.21,
        "y": 23.157
    },
    {
        "x": 47.22,
        "y": 23.157
    },
    {
        "x": 47.23,
        "y": 23.157
    },
    {
        "x": 47.24,
        "y": 23.157
    },
    {
        "x": 47.25,
        "y": 23.157
    },
    {
        "x": 47.26,
        "y": 23.157
    },
    {
        "x": 47.27,
        "y": 23.157
    },
    {
        "x": 47.28,
        "y": 23.157
    },
    {
        "x": 47.29,
        "y": 23.157
    },
    {
        "x": 47.3,
        "y": 23.157
    },
    {
        "x": 47.31,
        "y": 23.157
    },
    {
        "x": 47.32,
        "y": 23.157
    },
    {
        "x": 47.33,
        "y": 23.157
    },
    {
        "x": 47.34,
        "y": 23.157
    },
    {
        "x": 47.35,
        "y": 23.157
    },
    {
        "x": 47.36,
        "y": 23.157
    },
    {
        "x": 47.37,
        "y": 23.157
    },
    {
        "x": 47.38,
        "y": 23.157
    },
    {
        "x": 47.39,
        "y": 23.157
    },
    {
        "x": 47.4,
        "y": 23.157
    },
    {
        "x": 47.41,
        "y": 23.157
    },
    {
        "x": 47.42,
        "y": 23.157
    },
    {
        "x": 47.43,
        "y": 23.157
    },
    {
        "x": 47.44,
        "y": 23.157
    },
    {
        "x": 47.45,
        "y": 23.157
    },
    {
        "x": 47.46,
        "y": 23.157
    },
    {
        "x": 47.47,
        "y": 23.157
    },
    {
        "x": 47.48,
        "y": 23.157
    },
    {
        "x": 47.49,
        "y": 23.157
    },
    {
        "x": 47.5,
        "y": 23.157
    },
    {
        "x": 47.51,
        "y": 23.157
    },
    {
        "x": 47.52,
        "y": 23.157
    },
    {
        "x": 47.53,
        "y": 23.157
    },
    {
        "x": 47.54,
        "y": 23.157
    },
    {
        "x": 47.55,
        "y": 23.157
    },
    {
        "x": 47.56,
        "y": 23.157
    },
    {
        "x": 47.57,
        "y": 23.157
    },
    {
        "x": 47.58,
        "y": 23.157
    },
    {
        "x": 47.59,
        "y": 23.157
    },
    {
        "x": 47.6,
        "y": 23.157
    },
    {
        "x": 47.61,
        "y": 23.157
    },
    {
        "x": 47.62,
        "y": 23.157
    },
    {
        "x": 47.63,
        "y": 23.157
    },
    {
        "x": 47.64,
        "y": 23.157
    },
    {
        "x": 47.65,
        "y": 23.157
    },
    {
        "x": 47.66,
        "y": 23.157
    },
    {
        "x": 47.67,
        "y": 23.157
    },
    {
        "x": 47.68,
        "y": 23.157
    },
    {
        "x": 47.69,
        "y": 23.157
    },
    {
        "x": 47.7,
        "y": 23.157
    },
    {
        "x": 47.71,
        "y": 23.157
    },
    {
        "x": 47.72,
        "y": 23.157
    },
    {
        "x": 47.73,
        "y": 23.157
    },
    {
        "x": 47.74,
        "y": 23.157
    },
    {
        "x": 47.75,
        "y": 23.157
    },
    {
        "x": 47.76,
        "y": 23.157
    },
    {
        "x": 47.77,
        "y": 23.157
    },
    {
        "x": 47.78,
        "y": 23.157
    },
    {
        "x": 47.79,
        "y": 23.166
    },
    {
        "x": 47.8,
        "y": 23.157
    },
    {
        "x": 47.81,
        "y": 23.157
    },
    {
        "x": 47.82,
        "y": 23.166
    },
    {
        "x": 47.83,
        "y": 23.157
    },
    {
        "x": 47.84,
        "y": 23.166
    },
    {
        "x": 47.85,
        "y": 23.166
    },
    {
        "x": 47.86,
        "y": 23.157
    },
    {
        "x": 47.87,
        "y": 23.157
    },
    {
        "x": 47.88,
        "y": 23.157
    },
    {
        "x": 47.89,
        "y": 23.157
    },
    {
        "x": 47.9,
        "y": 23.157
    },
    {
        "x": 47.91,
        "y": 23.157
    },
    {
        "x": 47.92,
        "y": 23.157
    },
    {
        "x": 47.93,
        "y": 23.157
    },
    {
        "x": 47.94,
        "y": 23.157
    },
    {
        "x": 47.95,
        "y": 23.157
    },
    {
        "x": 47.96,
        "y": 23.157
    },
    {
        "x": 47.97,
        "y": 23.157
    },
    {
        "x": 47.98,
        "y": 23.157
    },
    {
        "x": 47.99,
        "y": 23.166
    },
    {
        "x": 48,
        "y": 23.166
    },
    {
        "x": 48.01,
        "y": 23.157
    },
    {
        "x": 48.02,
        "y": 23.166
    },
    {
        "x": 48.03,
        "y": 23.157
    },
    {
        "x": 48.04,
        "y": 23.157
    },
    {
        "x": 48.05,
        "y": 23.157
    },
    {
        "x": 48.06,
        "y": 23.166
    },
    {
        "x": 48.07,
        "y": 23.157
    },
    {
        "x": 48.08,
        "y": 23.157
    },
    {
        "x": 48.09,
        "y": 23.157
    },
    {
        "x": 48.1,
        "y": 23.157
    },
    {
        "x": 48.11,
        "y": 23.157
    },
    {
        "x": 48.12,
        "y": 23.157
    },
    {
        "x": 48.13,
        "y": 23.166
    },
    {
        "x": 48.14,
        "y": 23.157
    },
    {
        "x": 48.15,
        "y": 23.157
    },
    {
        "x": 48.16,
        "y": 23.166
    },
    {
        "x": 48.17,
        "y": 23.166
    },
    {
        "x": 48.18,
        "y": 23.157
    },
    {
        "x": 48.19,
        "y": 23.157
    },
    {
        "x": 48.2,
        "y": 23.166
    },
    {
        "x": 48.21,
        "y": 23.157
    },
    {
        "x": 48.22,
        "y": 23.157
    },
    {
        "x": 48.23,
        "y": 23.157
    },
    {
        "x": 48.24,
        "y": 23.166
    },
    {
        "x": 48.25,
        "y": 23.166
    },
    {
        "x": 48.26,
        "y": 23.157
    },
    {
        "x": 48.27,
        "y": 23.166
    },
    {
        "x": 48.28,
        "y": 23.157
    },
    {
        "x": 48.29,
        "y": 23.166
    },
    {
        "x": 48.3,
        "y": 23.166
    },
    {
        "x": 48.31,
        "y": 23.166
    },
    {
        "x": 48.32,
        "y": 23.166
    },
    {
        "x": 48.33,
        "y": 23.166
    },
    {
        "x": 48.34,
        "y": 23.166
    },
    {
        "x": 48.35,
        "y": 23.166
    },
    {
        "x": 48.36,
        "y": 23.166
    },
    {
        "x": 48.37,
        "y": 23.166
    },
    {
        "x": 48.38,
        "y": 23.166
    },
    {
        "x": 48.39,
        "y": 23.166
    },
    {
        "x": 48.4,
        "y": 23.166
    },
    {
        "x": 48.41,
        "y": 23.166
    },
    {
        "x": 48.42,
        "y": 23.166
    },
    {
        "x": 48.43,
        "y": 23.166
    },
    {
        "x": 48.44,
        "y": 23.166
    },
    {
        "x": 48.45,
        "y": 23.166
    },
    {
        "x": 48.46,
        "y": 23.166
    },
    {
        "x": 48.47,
        "y": 23.166
    },
    {
        "x": 48.48,
        "y": 23.166
    },
    {
        "x": 48.49,
        "y": 23.166
    },
    {
        "x": 48.5,
        "y": 23.166
    },
    {
        "x": 48.51,
        "y": 23.166
    },
    {
        "x": 48.52,
        "y": 23.166
    },
    {
        "x": 48.53,
        "y": 23.166
    },
    {
        "x": 48.54,
        "y": 23.166
    },
    {
        "x": 48.55,
        "y": 23.166
    },
    {
        "x": 48.56,
        "y": 23.166
    },
    {
        "x": 48.57,
        "y": 23.166
    },
    {
        "x": 48.58,
        "y": 23.166
    },
    {
        "x": 48.59,
        "y": 23.166
    },
    {
        "x": 48.6,
        "y": 23.166
    },
    {
        "x": 48.61,
        "y": 23.166
    },
    {
        "x": 48.62,
        "y": 23.166
    },
    {
        "x": 48.63,
        "y": 23.166
    },
    {
        "x": 48.64,
        "y": 23.166
    },
    {
        "x": 48.65,
        "y": 23.166
    },
    {
        "x": 48.66,
        "y": 23.166
    },
    {
        "x": 48.67,
        "y": 23.166
    },
    {
        "x": 48.68,
        "y": 23.166
    },
    {
        "x": 48.69,
        "y": 23.166
    },
    {
        "x": 48.7,
        "y": 23.166
    },
    {
        "x": 48.71,
        "y": 23.166
    },
    {
        "x": 48.72,
        "y": 23.166
    },
    {
        "x": 48.73,
        "y": 23.166
    },
    {
        "x": 48.74,
        "y": 23.166
    },
    {
        "x": 48.75,
        "y": 23.166
    },
    {
        "x": 48.76,
        "y": 23.166
    },
    {
        "x": 48.77,
        "y": 23.166
    },
    {
        "x": 48.78,
        "y": 23.166
    },
    {
        "x": 48.79,
        "y": 23.166
    },
    {
        "x": 48.8,
        "y": 23.166
    },
    {
        "x": 48.81,
        "y": 23.166
    },
    {
        "x": 48.82,
        "y": 23.166
    },
    {
        "x": 48.83,
        "y": 23.166
    },
    {
        "x": 48.84,
        "y": 23.166
    },
    {
        "x": 48.85,
        "y": 23.166
    },
    {
        "x": 48.86,
        "y": 23.166
    },
    {
        "x": 48.87,
        "y": 23.166
    },
    {
        "x": 48.88,
        "y": 23.166
    },
    {
        "x": 48.89,
        "y": 23.166
    },
    {
        "x": 48.9,
        "y": 23.166
    },
    {
        "x": 48.91,
        "y": 23.166
    },
    {
        "x": 48.92,
        "y": 23.166
    },
    {
        "x": 48.93,
        "y": 23.166
    },
    {
        "x": 48.94,
        "y": 23.166
    },
    {
        "x": 48.95,
        "y": 23.166
    },
    {
        "x": 48.96,
        "y": 23.166
    },
    {
        "x": 48.97,
        "y": 23.166
    },
    {
        "x": 48.98,
        "y": 23.166
    },
    {
        "x": 48.99,
        "y": 23.166
    },
    {
        "x": 49,
        "y": 23.166
    },
    {
        "x": 49.01,
        "y": 23.166
    },
    {
        "x": 49.02,
        "y": 23.166
    },
    {
        "x": 49.03,
        "y": 23.166
    },
    {
        "x": 49.04,
        "y": 23.166
    },
    {
        "x": 49.05,
        "y": 23.166
    },
    {
        "x": 49.06,
        "y": 23.166
    },
    {
        "x": 49.07,
        "y": 23.166
    },
    {
        "x": 49.08,
        "y": 23.166
    },
    {
        "x": 49.09,
        "y": 23.166
    },
    {
        "x": 49.1,
        "y": 23.166
    },
    {
        "x": 49.11,
        "y": 23.166
    },
    {
        "x": 49.12,
        "y": 23.166
    },
    {
        "x": 49.13,
        "y": 23.166
    },
    {
        "x": 49.14,
        "y": 23.166
    },
    {
        "x": 49.15,
        "y": 23.166
    },
    {
        "x": 49.16,
        "y": 23.166
    },
    {
        "x": 49.17,
        "y": 23.166
    },
    {
        "x": 49.18,
        "y": 23.166
    },
    {
        "x": 49.19,
        "y": 23.166
    },
    {
        "x": 49.2,
        "y": 23.166
    },
    {
        "x": 49.21,
        "y": 23.166
    },
    {
        "x": 49.22,
        "y": 23.166
    },
    {
        "x": 49.23,
        "y": 23.166
    },
    {
        "x": 49.24,
        "y": 23.175
    },
    {
        "x": 49.25,
        "y": 23.166
    },
    {
        "x": 49.26,
        "y": 23.166
    },
    {
        "x": 49.27,
        "y": 23.166
    },
    {
        "x": 49.28,
        "y": 23.166
    },
    {
        "x": 49.29,
        "y": 23.166
    },
    {
        "x": 49.3,
        "y": 23.166
    },
    {
        "x": 49.31,
        "y": 23.166
    },
    {
        "x": 49.32,
        "y": 23.166
    },
    {
        "x": 49.33,
        "y": 23.166
    },
    {
        "x": 49.34,
        "y": 23.166
    },
    {
        "x": 49.35,
        "y": 23.166
    },
    {
        "x": 49.36,
        "y": 23.166
    },
    {
        "x": 49.37,
        "y": 23.166
    },
    {
        "x": 49.38,
        "y": 23.166
    },
    {
        "x": 49.39,
        "y": 23.166
    },
    {
        "x": 49.4,
        "y": 23.166
    },
    {
        "x": 49.41,
        "y": 23.166
    },
    {
        "x": 49.42,
        "y": 23.175
    },
    {
        "x": 49.43,
        "y": 23.175
    },
    {
        "x": 49.44,
        "y": 23.175
    },
    {
        "x": 49.45,
        "y": 23.175
    },
    {
        "x": 49.46,
        "y": 23.175
    },
    {
        "x": 49.47,
        "y": 23.166
    },
    {
        "x": 49.48,
        "y": 23.166
    },
    {
        "x": 49.49,
        "y": 23.166
    },
    {
        "x": 49.5,
        "y": 23.166
    },
    {
        "x": 49.51,
        "y": 23.175
    },
    {
        "x": 49.52,
        "y": 23.175
    },
    {
        "x": 49.53,
        "y": 23.175
    },
    {
        "x": 49.54,
        "y": 23.175
    },
    {
        "x": 49.55,
        "y": 23.175
    },
    {
        "x": 49.56,
        "y": 23.166
    },
    {
        "x": 49.57,
        "y": 23.166
    },
    {
        "x": 49.58,
        "y": 23.175
    },
    {
        "x": 49.59,
        "y": 23.175
    },
    {
        "x": 49.6,
        "y": 23.175
    },
    {
        "x": 49.61,
        "y": 23.175
    },
    {
        "x": 49.62,
        "y": 23.175
    },
    {
        "x": 49.63,
        "y": 23.175
    },
    {
        "x": 49.64,
        "y": 23.166
    },
    {
        "x": 49.65,
        "y": 23.175
    },
    {
        "x": 49.66,
        "y": 23.175
    },
    {
        "x": 49.67,
        "y": 23.175
    },
    {
        "x": 49.68,
        "y": 23.175
    },
    {
        "x": 49.69,
        "y": 23.175
    },
    {
        "x": 49.7,
        "y": 23.175
    },
    {
        "x": 49.71,
        "y": 23.175
    },
    {
        "x": 49.72,
        "y": 23.175
    },
    {
        "x": 49.73,
        "y": 23.175
    },
    {
        "x": 49.74,
        "y": 23.175
    },
    {
        "x": 49.75,
        "y": 23.175
    },
    {
        "x": 49.76,
        "y": 23.175
    },
    {
        "x": 49.77,
        "y": 23.175
    },
    {
        "x": 49.78,
        "y": 23.175
    },
    {
        "x": 49.79,
        "y": 23.175
    },
    {
        "x": 49.8,
        "y": 23.175
    },
    {
        "x": 49.81,
        "y": 23.175
    },
    {
        "x": 49.82,
        "y": 23.175
    },
    {
        "x": 49.83,
        "y": 23.175
    },
    {
        "x": 49.84,
        "y": 23.175
    },
    {
        "x": 49.85,
        "y": 23.175
    },
    {
        "x": 49.86,
        "y": 23.175
    },
    {
        "x": 49.87,
        "y": 23.175
    },
    {
        "x": 49.88,
        "y": 23.175
    },
    {
        "x": 49.89,
        "y": 23.175
    },
    {
        "x": 49.9,
        "y": 23.175
    },
    {
        "x": 49.91,
        "y": 23.175
    },
    {
        "x": 49.92,
        "y": 23.175
    },
    {
        "x": 49.93,
        "y": 23.175
    },
    {
        "x": 49.94,
        "y": 23.175
    },
    {
        "x": 49.95,
        "y": 23.175
    },
    {
        "x": 49.96,
        "y": 23.175
    },
    {
        "x": 49.97,
        "y": 23.175
    },
    {
        "x": 49.98,
        "y": 23.175
    },
    {
        "x": 49.99,
        "y": 23.175
    },
    {
        "x": 50,
        "y": 23.175
    },
    {
        "x": 50.01,
        "y": 23.175
    },
    {
        "x": 50.02,
        "y": 23.175
    },
    {
        "x": 50.03,
        "y": 23.175
    },
    {
        "x": 50.04,
        "y": 23.175
    },
    {
        "x": 50.05,
        "y": 23.175
    },
    {
        "x": 50.06,
        "y": 23.175
    },
    {
        "x": 50.07,
        "y": 23.175
    },
    {
        "x": 50.08,
        "y": 23.175
    },
    {
        "x": 50.09,
        "y": 23.175
    },
    {
        "x": 50.1,
        "y": 23.175
    },
    {
        "x": 50.11,
        "y": 23.175
    },
    {
        "x": 50.12,
        "y": 23.175
    },
    {
        "x": 50.13,
        "y": 23.175
    },
    {
        "x": 50.14,
        "y": 23.175
    },
    {
        "x": 50.15,
        "y": 23.175
    },
    {
        "x": 50.16,
        "y": 23.175
    },
    {
        "x": 50.17,
        "y": 23.175
    },
    {
        "x": 50.18,
        "y": 23.175
    },
    {
        "x": 50.19,
        "y": 23.175
    },
    {
        "x": 50.2,
        "y": 23.175
    },
    {
        "x": 50.21,
        "y": 23.175
    },
    {
        "x": 50.22,
        "y": 23.175
    },
    {
        "x": 50.23,
        "y": 23.175
    },
    {
        "x": 50.24,
        "y": 23.175
    },
    {
        "x": 50.25,
        "y": 23.175
    },
    {
        "x": 50.26,
        "y": 23.175
    },
    {
        "x": 50.27,
        "y": 23.175
    },
    {
        "x": 50.28,
        "y": 23.175
    },
    {
        "x": 50.29,
        "y": 23.175
    },
    {
        "x": 50.3,
        "y": 23.175
    },
    {
        "x": 50.31,
        "y": 23.175
    },
    {
        "x": 50.32,
        "y": 23.175
    },
    {
        "x": 50.33,
        "y": 23.175
    },
    {
        "x": 50.34,
        "y": 23.175
    },
    {
        "x": 50.35,
        "y": 23.175
    },
    {
        "x": 50.36,
        "y": 23.175
    },
    {
        "x": 50.37,
        "y": 23.175
    },
    {
        "x": 50.38,
        "y": 23.175
    },
    {
        "x": 50.39,
        "y": 23.175
    },
    {
        "x": 50.4,
        "y": 23.175
    },
    {
        "x": 50.41,
        "y": 23.175
    },
    {
        "x": 50.42,
        "y": 23.175
    },
    {
        "x": 50.43,
        "y": 23.175
    },
    {
        "x": 50.44,
        "y": 23.175
    },
    {
        "x": 50.45,
        "y": 23.175
    },
    {
        "x": 50.46,
        "y": 23.175
    },
    {
        "x": 50.47,
        "y": 23.175
    },
    {
        "x": 50.48,
        "y": 23.175
    },
    {
        "x": 50.49,
        "y": 23.175
    },
    {
        "x": 50.5,
        "y": 23.175
    },
    {
        "x": 50.51,
        "y": 23.175
    },
    {
        "x": 50.52,
        "y": 23.175
    },
    {
        "x": 50.53,
        "y": 23.175
    },
    {
        "x": 50.54,
        "y": 23.175
    },
    {
        "x": 50.55,
        "y": 23.175
    },
    {
        "x": 50.56,
        "y": 23.175
    },
    {
        "x": 50.57,
        "y": 23.175
    },
    {
        "x": 50.58,
        "y": 23.175
    },
    {
        "x": 50.59,
        "y": 23.175
    },
    {
        "x": 50.6,
        "y": 23.175
    },
    {
        "x": 50.61,
        "y": 23.175
    },
    {
        "x": 50.62,
        "y": 23.175
    },
    {
        "x": 50.63,
        "y": 23.175
    },
    {
        "x": 50.64,
        "y": 23.175
    },
    {
        "x": 50.65,
        "y": 23.175
    },
    {
        "x": 50.66,
        "y": 23.175
    },
    {
        "x": 50.67,
        "y": 23.175
    },
    {
        "x": 50.68,
        "y": 23.175
    },
    {
        "x": 50.69,
        "y": 23.175
    },
    {
        "x": 50.7,
        "y": 23.175
    },
    {
        "x": 50.71,
        "y": 23.175
    },
    {
        "x": 50.72,
        "y": 23.175
    },
    {
        "x": 50.73,
        "y": 23.184
    },
    {
        "x": 50.74,
        "y": 23.175
    },
    {
        "x": 50.75,
        "y": 23.175
    },
    {
        "x": 50.76,
        "y": 23.175
    },
    {
        "x": 50.77,
        "y": 23.175
    },
    {
        "x": 50.78,
        "y": 23.175
    },
    {
        "x": 50.79,
        "y": 23.184
    },
    {
        "x": 50.8,
        "y": 23.175
    },
    {
        "x": 50.81,
        "y": 23.184
    },
    {
        "x": 50.82,
        "y": 23.175
    },
    {
        "x": 50.83,
        "y": 23.175
    },
    {
        "x": 50.84,
        "y": 23.175
    },
    {
        "x": 50.85,
        "y": 23.184
    },
    {
        "x": 50.86,
        "y": 23.184
    },
    {
        "x": 50.87,
        "y": 23.184
    },
    {
        "x": 50.88,
        "y": 23.175
    },
    {
        "x": 50.89,
        "y": 23.184
    },
    {
        "x": 50.9,
        "y": 23.184
    },
    {
        "x": 50.91,
        "y": 23.184
    },
    {
        "x": 50.92,
        "y": 23.184
    },
    {
        "x": 50.93,
        "y": 23.184
    },
    {
        "x": 50.94,
        "y": 23.184
    },
    {
        "x": 50.95,
        "y": 23.184
    },
    {
        "x": 50.96,
        "y": 23.184
    },
    {
        "x": 50.97,
        "y": 23.184
    },
    {
        "x": 50.98,
        "y": 23.184
    },
    {
        "x": 50.99,
        "y": 23.184
    },
    {
        "x": 51,
        "y": 23.175
    },
    {
        "x": 51.01,
        "y": 23.175
    },
    {
        "x": 51.02,
        "y": 23.184
    },
    {
        "x": 51.03,
        "y": 23.175
    },
    {
        "x": 51.04,
        "y": 23.184
    },
    {
        "x": 51.05,
        "y": 23.184
    },
    {
        "x": 51.06,
        "y": 23.184
    },
    {
        "x": 51.07,
        "y": 23.175
    },
    {
        "x": 51.08,
        "y": 23.184
    },
    {
        "x": 51.09,
        "y": 23.184
    },
    {
        "x": 51.1,
        "y": 23.175
    },
    {
        "x": 51.11,
        "y": 23.175
    },
    {
        "x": 51.12,
        "y": 23.175
    },
    {
        "x": 51.13,
        "y": 23.175
    },
    {
        "x": 51.14,
        "y": 23.175
    },
    {
        "x": 51.15,
        "y": 23.175
    },
    {
        "x": 51.16,
        "y": 23.184
    },
    {
        "x": 51.17,
        "y": 23.184
    },
    {
        "x": 51.18,
        "y": 23.184
    },
    {
        "x": 51.19,
        "y": 23.175
    },
    {
        "x": 51.2,
        "y": 23.184
    },
    {
        "x": 51.21,
        "y": 23.175
    },
    {
        "x": 51.22,
        "y": 23.175
    },
    {
        "x": 51.23,
        "y": 23.184
    },
    {
        "x": 51.24,
        "y": 23.184
    },
    {
        "x": 51.25,
        "y": 23.175
    },
    {
        "x": 51.26,
        "y": 23.184
    },
    {
        "x": 51.27,
        "y": 23.184
    },
    {
        "x": 51.28,
        "y": 23.175
    },
    {
        "x": 51.29,
        "y": 23.175
    },
    {
        "x": 51.3,
        "y": 23.175
    },
    {
        "x": 51.31,
        "y": 23.175
    },
    {
        "x": 51.32,
        "y": 23.175
    },
    {
        "x": 51.33,
        "y": 23.175
    },
    {
        "x": 51.34,
        "y": 23.175
    },
    {
        "x": 51.35,
        "y": 23.175
    },
    {
        "x": 51.36,
        "y": 23.175
    },
    {
        "x": 51.37,
        "y": 23.175
    },
    {
        "x": 51.38,
        "y": 23.175
    },
    {
        "x": 51.39,
        "y": 23.175
    },
    {
        "x": 51.4,
        "y": 23.184
    },
    {
        "x": 51.41,
        "y": 23.184
    },
    {
        "x": 51.42,
        "y": 23.175
    },
    {
        "x": 51.43,
        "y": 23.175
    },
    {
        "x": 51.44,
        "y": 23.184
    },
    {
        "x": 51.45,
        "y": 23.175
    },
    {
        "x": 51.46,
        "y": 23.175
    },
    {
        "x": 51.47,
        "y": 23.175
    },
    {
        "x": 51.48,
        "y": 23.175
    },
    {
        "x": 51.49,
        "y": 23.175
    },
    {
        "x": 51.5,
        "y": 23.175
    },
    {
        "x": 51.51,
        "y": 23.175
    },
    {
        "x": 51.52,
        "y": 23.184
    },
    {
        "x": 51.53,
        "y": 23.184
    },
    {
        "x": 51.54,
        "y": 23.184
    },
    {
        "x": 51.55,
        "y": 23.175
    },
    {
        "x": 51.56,
        "y": 23.175
    },
    {
        "x": 51.57,
        "y": 23.175
    },
    {
        "x": 51.58,
        "y": 23.175
    },
    {
        "x": 51.59,
        "y": 23.175
    },
    {
        "x": 51.6,
        "y": 23.175
    },
    {
        "x": 51.61,
        "y": 23.184
    },
    {
        "x": 51.62,
        "y": 23.184
    },
    {
        "x": 51.63,
        "y": 23.175
    },
    {
        "x": 51.64,
        "y": 23.175
    },
    {
        "x": 51.65,
        "y": 23.175
    },
    {
        "x": 51.66,
        "y": 23.184
    },
    {
        "x": 51.67,
        "y": 23.175
    },
    {
        "x": 51.68,
        "y": 23.184
    },
    {
        "x": 51.69,
        "y": 23.184
    },
    {
        "x": 51.7,
        "y": 23.184
    },
    {
        "x": 51.71,
        "y": 23.184
    },
    {
        "x": 51.72,
        "y": 23.184
    },
    {
        "x": 51.73,
        "y": 23.175
    },
    {
        "x": 51.74,
        "y": 23.184
    },
    {
        "x": 51.75,
        "y": 23.184
    },
    {
        "x": 51.76,
        "y": 23.184
    },
    {
        "x": 51.77,
        "y": 23.184
    },
    {
        "x": 51.78,
        "y": 23.184
    },
    {
        "x": 51.79,
        "y": 23.184
    },
    {
        "x": 51.8,
        "y": 23.184
    },
    {
        "x": 51.81,
        "y": 23.184
    },
    {
        "x": 51.82,
        "y": 23.184
    },
    {
        "x": 51.83,
        "y": 23.184
    },
    {
        "x": 51.84,
        "y": 23.184
    },
    {
        "x": 51.85,
        "y": 23.184
    },
    {
        "x": 51.86,
        "y": 23.184
    },
    {
        "x": 51.87,
        "y": 23.175
    },
    {
        "x": 51.88,
        "y": 23.175
    },
    {
        "x": 51.89,
        "y": 23.184
    },
    {
        "x": 51.9,
        "y": 23.184
    },
    {
        "x": 51.91,
        "y": 23.184
    },
    {
        "x": 51.92,
        "y": 23.184
    },
    {
        "x": 51.93,
        "y": 23.184
    },
    {
        "x": 51.94,
        "y": 23.184
    },
    {
        "x": 51.95,
        "y": 23.184
    },
    {
        "x": 51.96,
        "y": 23.175
    },
    {
        "x": 51.97,
        "y": 23.184
    },
    {
        "x": 51.98,
        "y": 23.184
    },
    {
        "x": 51.99,
        "y": 23.184
    },
    {
        "x": 52,
        "y": 23.184
    },
    {
        "x": 52.01,
        "y": 23.184
    },
    {
        "x": 52.02,
        "y": 23.184
    },
    {
        "x": 52.03,
        "y": 23.184
    },
    {
        "x": 52.04,
        "y": 23.184
    },
    {
        "x": 52.05,
        "y": 23.184
    },
    {
        "x": 52.06,
        "y": 23.184
    },
    {
        "x": 52.07,
        "y": 23.184
    },
    {
        "x": 52.08,
        "y": 23.184
    },
    {
        "x": 52.09,
        "y": 23.184
    },
    {
        "x": 52.1,
        "y": 23.184
    },
    {
        "x": 52.11,
        "y": 23.184
    },
    {
        "x": 52.12,
        "y": 23.184
    },
    {
        "x": 52.13,
        "y": 23.184
    },
    {
        "x": 52.14,
        "y": 23.184
    },
    {
        "x": 52.15,
        "y": 23.184
    },
    {
        "x": 52.16,
        "y": 23.184
    },
    {
        "x": 52.17,
        "y": 23.184
    },
    {
        "x": 52.18,
        "y": 23.184
    },
    {
        "x": 52.19,
        "y": 23.184
    },
    {
        "x": 52.2,
        "y": 23.184
    },
    {
        "x": 52.21,
        "y": 23.184
    },
    {
        "x": 52.22,
        "y": 23.184
    },
    {
        "x": 52.23,
        "y": 23.184
    },
    {
        "x": 52.24,
        "y": 23.184
    },
    {
        "x": 52.25,
        "y": 23.184
    },
    {
        "x": 52.26,
        "y": 23.184
    },
    {
        "x": 52.27,
        "y": 23.184
    },
    {
        "x": 52.28,
        "y": 23.184
    },
    {
        "x": 52.29,
        "y": 23.184
    },
    {
        "x": 52.3,
        "y": 23.184
    },
    {
        "x": 52.31,
        "y": 23.184
    },
    {
        "x": 52.32,
        "y": 23.184
    },
    {
        "x": 52.33,
        "y": 23.184
    },
    {
        "x": 52.34,
        "y": 23.184
    },
    {
        "x": 52.35,
        "y": 23.184
    },
    {
        "x": 52.36,
        "y": 23.184
    },
    {
        "x": 52.37,
        "y": 23.184
    },
    {
        "x": 52.38,
        "y": 23.184
    },
    {
        "x": 52.39,
        "y": 23.184
    },
    {
        "x": 52.4,
        "y": 23.184
    },
    {
        "x": 52.41,
        "y": 23.184
    },
    {
        "x": 52.42,
        "y": 23.184
    },
    {
        "x": 52.43,
        "y": 23.184
    },
    {
        "x": 52.44,
        "y": 23.184
    },
    {
        "x": 52.45,
        "y": 23.184
    },
    {
        "x": 52.46,
        "y": 23.184
    },
    {
        "x": 52.47,
        "y": 23.184
    },
    {
        "x": 52.48,
        "y": 23.184
    },
    {
        "x": 52.49,
        "y": 23.184
    },
    {
        "x": 52.5,
        "y": 23.184
    },
    {
        "x": 52.51,
        "y": 23.184
    },
    {
        "x": 52.52,
        "y": 23.184
    },
    {
        "x": 52.53,
        "y": 23.184
    },
    {
        "x": 52.54,
        "y": 23.184
    },
    {
        "x": 52.55,
        "y": 23.184
    },
    {
        "x": 52.56,
        "y": 23.184
    },
    {
        "x": 52.57,
        "y": 23.184
    },
    {
        "x": 52.58,
        "y": 23.184
    },
    {
        "x": 52.59,
        "y": 23.184
    },
    {
        "x": 52.6,
        "y": 23.184
    },
    {
        "x": 52.61,
        "y": 23.184
    },
    {
        "x": 52.62,
        "y": 23.184
    },
    {
        "x": 52.63,
        "y": 23.184
    },
    {
        "x": 52.64,
        "y": 23.184
    },
    {
        "x": 52.65,
        "y": 23.184
    },
    {
        "x": 52.66,
        "y": 23.184
    },
    {
        "x": 52.67,
        "y": 23.184
    },
    {
        "x": 52.68,
        "y": 23.184
    },
    {
        "x": 52.69,
        "y": 23.184
    },
    {
        "x": 52.7,
        "y": 23.184
    },
    {
        "x": 52.71,
        "y": 23.184
    },
    {
        "x": 52.72,
        "y": 23.184
    },
    {
        "x": 52.73,
        "y": 23.184
    },
    {
        "x": 52.74,
        "y": 23.184
    },
    {
        "x": 52.75,
        "y": 23.184
    },
    {
        "x": 52.76,
        "y": 23.184
    },
    {
        "x": 52.77,
        "y": 23.184
    },
    {
        "x": 52.78,
        "y": 23.184
    },
    {
        "x": 52.79,
        "y": 23.184
    },
    {
        "x": 52.8,
        "y": 23.184
    },
    {
        "x": 52.81,
        "y": 23.184
    },
    {
        "x": 52.82,
        "y": 23.184
    },
    {
        "x": 52.83,
        "y": 23.184
    },
    {
        "x": 52.84,
        "y": 23.184
    },
    {
        "x": 52.85,
        "y": 23.184
    },
    {
        "x": 52.86,
        "y": 23.184
    },
    {
        "x": 52.87,
        "y": 23.184
    },
    {
        "x": 52.88,
        "y": 23.184
    },
    {
        "x": 52.89,
        "y": 23.184
    },
    {
        "x": 52.9,
        "y": 23.184
    },
    {
        "x": 52.91,
        "y": 23.184
    },
    {
        "x": 52.92,
        "y": 23.184
    },
    {
        "x": 52.93,
        "y": 23.184
    },
    {
        "x": 52.94,
        "y": 23.184
    },
    {
        "x": 52.95,
        "y": 23.184
    },
    {
        "x": 52.96,
        "y": 23.184
    },
    {
        "x": 52.97,
        "y": 23.184
    },
    {
        "x": 52.98,
        "y": 23.184
    },
    {
        "x": 52.99,
        "y": 23.184
    },
    {
        "x": 53,
        "y": 23.184
    },
    {
        "x": 53.01,
        "y": 23.184
    },
    {
        "x": 53.02,
        "y": 23.184
    },
    {
        "x": 53.03,
        "y": 23.184
    },
    {
        "x": 53.04,
        "y": 23.184
    },
    {
        "x": 53.05,
        "y": 23.184
    },
    {
        "x": 53.06,
        "y": 23.184
    },
    {
        "x": 53.07,
        "y": 23.184
    },
    {
        "x": 53.08,
        "y": 23.184
    },
    {
        "x": 53.09,
        "y": 23.184
    },
    {
        "x": 53.1,
        "y": 23.184
    },
    {
        "x": 53.11,
        "y": 23.184
    },
    {
        "x": 53.12,
        "y": 23.184
    },
    {
        "x": 53.13,
        "y": 23.184
    },
    {
        "x": 53.14,
        "y": 23.184
    },
    {
        "x": 53.15,
        "y": 23.184
    },
    {
        "x": 53.16,
        "y": 23.184
    },
    {
        "x": 53.17,
        "y": 23.184
    },
    {
        "x": 53.18,
        "y": 23.184
    },
    {
        "x": 53.19,
        "y": 23.184
    },
    {
        "x": 53.2,
        "y": 23.184
    },
    {
        "x": 53.21,
        "y": 23.193
    },
    {
        "x": 53.22,
        "y": 23.193
    },
    {
        "x": 53.23,
        "y": 23.193
    },
    {
        "x": 53.24,
        "y": 23.193
    },
    {
        "x": 53.25,
        "y": 23.193
    },
    {
        "x": 53.26,
        "y": 23.184
    },
    {
        "x": 53.27,
        "y": 23.184
    },
    {
        "x": 53.28,
        "y": 23.184
    },
    {
        "x": 53.29,
        "y": 23.193
    },
    {
        "x": 53.3,
        "y": 23.193
    },
    {
        "x": 53.31,
        "y": 23.184
    },
    {
        "x": 53.32,
        "y": 23.184
    },
    {
        "x": 53.33,
        "y": 23.184
    },
    {
        "x": 53.34,
        "y": 23.193
    },
    {
        "x": 53.35,
        "y": 23.184
    },
    {
        "x": 53.36,
        "y": 23.184
    },
    {
        "x": 53.37,
        "y": 23.184
    },
    {
        "x": 53.38,
        "y": 23.184
    },
    {
        "x": 53.39,
        "y": 23.184
    },
    {
        "x": 53.4,
        "y": 23.184
    },
    {
        "x": 53.41,
        "y": 23.184
    },
    {
        "x": 53.42,
        "y": 23.184
    },
    {
        "x": 53.43,
        "y": 23.184
    },
    {
        "x": 53.44,
        "y": 23.184
    },
    {
        "x": 53.45,
        "y": 23.184
    },
    {
        "x": 53.46,
        "y": 23.184
    },
    {
        "x": 53.47,
        "y": 23.184
    },
    {
        "x": 53.48,
        "y": 23.184
    },
    {
        "x": 53.49,
        "y": 23.184
    },
    {
        "x": 53.5,
        "y": 23.184
    },
    {
        "x": 53.51,
        "y": 23.184
    },
    {
        "x": 53.52,
        "y": 23.184
    },
    {
        "x": 53.53,
        "y": 23.184
    },
    {
        "x": 53.54,
        "y": 23.184
    },
    {
        "x": 53.55,
        "y": 23.184
    },
    {
        "x": 53.56,
        "y": 23.184
    },
    {
        "x": 53.57,
        "y": 23.184
    },
    {
        "x": 53.58,
        "y": 23.184
    },
    {
        "x": 53.59,
        "y": 23.184
    },
    {
        "x": 53.6,
        "y": 23.184
    },
    {
        "x": 53.61,
        "y": 23.184
    },
    {
        "x": 53.62,
        "y": 23.184
    },
    {
        "x": 53.63,
        "y": 23.184
    },
    {
        "x": 53.64,
        "y": 23.184
    },
    {
        "x": 53.65,
        "y": 23.184
    },
    {
        "x": 53.66,
        "y": 23.184
    },
    {
        "x": 53.67,
        "y": 23.184
    },
    {
        "x": 53.68,
        "y": 23.184
    },
    {
        "x": 53.69,
        "y": 23.184
    },
    {
        "x": 53.7,
        "y": 23.184
    },
    {
        "x": 53.71,
        "y": 23.184
    },
    {
        "x": 53.72,
        "y": 23.184
    },
    {
        "x": 53.73,
        "y": 23.184
    },
    {
        "x": 53.74,
        "y": 23.184
    },
    {
        "x": 53.75,
        "y": 23.175
    },
    {
        "x": 53.76,
        "y": 23.184
    },
    {
        "x": 53.77,
        "y": 23.184
    },
    {
        "x": 53.78,
        "y": 23.184
    },
    {
        "x": 53.79,
        "y": 23.184
    },
    {
        "x": 53.8,
        "y": 23.184
    },
    {
        "x": 53.81,
        "y": 23.184
    },
    {
        "x": 53.82,
        "y": 23.184
    },
    {
        "x": 53.83,
        "y": 23.184
    },
    {
        "x": 53.84,
        "y": 23.184
    },
    {
        "x": 53.85,
        "y": 23.184
    },
    {
        "x": 53.86,
        "y": 23.184
    },
    {
        "x": 53.87,
        "y": 23.184
    },
    {
        "x": 53.88,
        "y": 23.184
    },
    {
        "x": 53.89,
        "y": 23.184
    },
    {
        "x": 53.9,
        "y": 23.184
    },
    {
        "x": 53.91,
        "y": 23.184
    },
    {
        "x": 53.92,
        "y": 23.184
    },
    {
        "x": 53.93,
        "y": 23.184
    },
    {
        "x": 53.94,
        "y": 23.184
    },
    {
        "x": 53.95,
        "y": 23.184
    },
    {
        "x": 53.96,
        "y": 23.184
    },
    {
        "x": 53.97,
        "y": 23.184
    },
    {
        "x": 53.98,
        "y": 23.184
    },
    {
        "x": 53.99,
        "y": 23.184
    },
    {
        "x": 54,
        "y": 23.184
    },
    {
        "x": 54.01,
        "y": 23.184
    },
    {
        "x": 54.02,
        "y": 23.184
    },
    {
        "x": 54.03,
        "y": 23.184
    },
    {
        "x": 54.04,
        "y": 23.184
    },
    {
        "x": 54.05,
        "y": 23.184
    },
    {
        "x": 54.06,
        "y": 23.184
    },
    {
        "x": 54.07,
        "y": 23.184
    },
    {
        "x": 54.08,
        "y": 23.184
    },
    {
        "x": 54.09,
        "y": 23.184
    },
    {
        "x": 54.1,
        "y": 23.184
    },
    {
        "x": 54.11,
        "y": 23.184
    },
    {
        "x": 54.12,
        "y": 23.184
    },
    {
        "x": 54.13,
        "y": 23.184
    },
    {
        "x": 54.14,
        "y": 23.184
    },
    {
        "x": 54.15,
        "y": 23.184
    },
    {
        "x": 54.16,
        "y": 23.184
    },
    {
        "x": 54.17,
        "y": 23.184
    },
    {
        "x": 54.18,
        "y": 23.184
    },
    {
        "x": 54.19,
        "y": 23.184
    },
    {
        "x": 54.2,
        "y": 23.184
    },
    {
        "x": 54.21,
        "y": 23.184
    },
    {
        "x": 54.22,
        "y": 23.184
    },
    {
        "x": 54.23,
        "y": 23.184
    },
    {
        "x": 54.24,
        "y": 23.184
    },
    {
        "x": 54.25,
        "y": 23.184
    },
    {
        "x": 54.26,
        "y": 23.184
    },
    {
        "x": 54.27,
        "y": 23.184
    },
    {
        "x": 54.28,
        "y": 23.184
    },
    {
        "x": 54.29,
        "y": 23.184
    },
    {
        "x": 54.3,
        "y": 23.184
    },
    {
        "x": 54.31,
        "y": 23.184
    },
    {
        "x": 54.32,
        "y": 23.184
    },
    {
        "x": 54.33,
        "y": 23.184
    },
    {
        "x": 54.34,
        "y": 23.184
    },
    {
        "x": 54.35,
        "y": 23.184
    },
    {
        "x": 54.36,
        "y": 23.184
    },
    {
        "x": 54.37,
        "y": 23.184
    },
    {
        "x": 54.38,
        "y": 23.184
    },
    {
        "x": 54.39,
        "y": 23.184
    },
    {
        "x": 54.4,
        "y": 23.184
    },
    {
        "x": 54.41,
        "y": 23.184
    },
    {
        "x": 54.42,
        "y": 23.184
    },
    {
        "x": 54.43,
        "y": 23.184
    },
    {
        "x": 54.44,
        "y": 23.184
    },
    {
        "x": 54.45,
        "y": 23.184
    },
    {
        "x": 54.46,
        "y": 23.184
    },
    {
        "x": 54.47,
        "y": 23.184
    },
    {
        "x": 54.48,
        "y": 23.184
    },
    {
        "x": 54.49,
        "y": 23.184
    },
    {
        "x": 54.5,
        "y": 23.184
    },
    {
        "x": 54.51,
        "y": 23.193
    },
    {
        "x": 54.52,
        "y": 23.193
    },
    {
        "x": 54.53,
        "y": 23.184
    },
    {
        "x": 54.54,
        "y": 23.193
    },
    {
        "x": 54.55,
        "y": 23.184
    },
    {
        "x": 54.56,
        "y": 23.184
    },
    {
        "x": 54.57,
        "y": 23.184
    },
    {
        "x": 54.58,
        "y": 23.184
    },
    {
        "x": 54.59,
        "y": 23.184
    },
    {
        "x": 54.6,
        "y": 23.184
    },
    {
        "x": 54.61,
        "y": 23.184
    },
    {
        "x": 54.62,
        "y": 23.184
    },
    {
        "x": 54.63,
        "y": 23.184
    },
    {
        "x": 54.64,
        "y": 23.184
    },
    {
        "x": 54.65,
        "y": 23.184
    },
    {
        "x": 54.66,
        "y": 23.184
    },
    {
        "x": 54.67,
        "y": 23.184
    },
    {
        "x": 54.68,
        "y": 23.184
    },
    {
        "x": 54.69,
        "y": 23.184
    },
    {
        "x": 54.7,
        "y": 23.184
    },
    {
        "x": 54.71,
        "y": 23.184
    },
    {
        "x": 54.72,
        "y": 23.184
    },
    {
        "x": 54.73,
        "y": 23.184
    },
    {
        "x": 54.74,
        "y": 23.184
    },
    {
        "x": 54.75,
        "y": 23.184
    },
    {
        "x": 54.76,
        "y": 23.184
    },
    {
        "x": 54.77,
        "y": 23.184
    },
    {
        "x": 54.78,
        "y": 23.184
    },
    {
        "x": 54.79,
        "y": 23.184
    },
    {
        "x": 54.8,
        "y": 23.184
    },
    {
        "x": 54.81,
        "y": 23.184
    },
    {
        "x": 54.82,
        "y": 23.184
    },
    {
        "x": 54.83,
        "y": 23.184
    },
    {
        "x": 54.84,
        "y": 23.184
    },
    {
        "x": 54.85,
        "y": 23.184
    },
    {
        "x": 54.86,
        "y": 23.184
    },
    {
        "x": 54.87,
        "y": 23.184
    },
    {
        "x": 54.88,
        "y": 23.184
    },
    {
        "x": 54.89,
        "y": 23.184
    },
    {
        "x": 54.9,
        "y": 23.184
    },
    {
        "x": 54.91,
        "y": 23.184
    },
    {
        "x": 54.92,
        "y": 23.184
    },
    {
        "x": 54.93,
        "y": 23.184
    },
    {
        "x": 54.94,
        "y": 23.184
    },
    {
        "x": 54.95,
        "y": 23.184
    },
    {
        "x": 54.96,
        "y": 23.184
    },
    {
        "x": 54.97,
        "y": 23.184
    },
    {
        "x": 54.98,
        "y": 23.184
    },
    {
        "x": 54.99,
        "y": 23.184
    },
    {
        "x": 55,
        "y": 23.184
    },
    {
        "x": 55.01,
        "y": 23.184
    },
    {
        "x": 55.02,
        "y": 23.184
    },
    {
        "x": 55.03,
        "y": 23.184
    },
    {
        "x": 55.04,
        "y": 23.184
    },
    {
        "x": 55.05,
        "y": 23.184
    },
    {
        "x": 55.06,
        "y": 23.184
    },
    {
        "x": 55.07,
        "y": 23.184
    },
    {
        "x": 55.08,
        "y": 23.184
    },
    {
        "x": 55.09,
        "y": 23.184
    },
    {
        "x": 55.1,
        "y": 23.184
    },
    {
        "x": 55.11,
        "y": 23.184
    },
    {
        "x": 55.12,
        "y": 23.184
    },
    {
        "x": 55.13,
        "y": 23.184
    },
    {
        "x": 55.14,
        "y": 23.184
    },
    {
        "x": 55.15,
        "y": 23.184
    },
    {
        "x": 55.16,
        "y": 23.184
    },
    {
        "x": 55.17,
        "y": 23.184
    },
    {
        "x": 55.18,
        "y": 23.184
    },
    {
        "x": 55.19,
        "y": 23.184
    },
    {
        "x": 55.2,
        "y": 23.184
    },
    {
        "x": 55.21,
        "y": 23.184
    },
    {
        "x": 55.22,
        "y": 23.184
    },
    {
        "x": 55.23,
        "y": 23.184
    },
    {
        "x": 55.24,
        "y": 23.184
    },
    {
        "x": 55.25,
        "y": 23.184
    },
    {
        "x": 55.26,
        "y": 23.184
    },
    {
        "x": 55.27,
        "y": 23.184
    },
    {
        "x": 55.28,
        "y": 23.184
    },
    {
        "x": 55.29,
        "y": 23.184
    },
    {
        "x": 55.3,
        "y": 23.184
    },
    {
        "x": 55.31,
        "y": 23.184
    },
    {
        "x": 55.32,
        "y": 23.184
    },
    {
        "x": 55.33,
        "y": 23.184
    },
    {
        "x": 55.34,
        "y": 23.184
    },
    {
        "x": 55.35,
        "y": 23.184
    },
    {
        "x": 55.36,
        "y": 23.184
    },
    {
        "x": 55.37,
        "y": 23.184
    },
    {
        "x": 55.38,
        "y": 23.184
    },
    {
        "x": 55.39,
        "y": 23.184
    },
    {
        "x": 55.4,
        "y": 23.184
    },
    {
        "x": 55.41,
        "y": 23.184
    },
    {
        "x": 55.42,
        "y": 23.184
    },
    {
        "x": 55.43,
        "y": 23.184
    },
    {
        "x": 55.44,
        "y": 23.184
    },
    {
        "x": 55.45,
        "y": 23.184
    },
    {
        "x": 55.46,
        "y": 23.184
    },
    {
        "x": 55.47,
        "y": 23.184
    },
    {
        "x": 55.48,
        "y": 23.184
    },
    {
        "x": 55.49,
        "y": 23.184
    },
    {
        "x": 55.5,
        "y": 23.184
    },
    {
        "x": 55.51,
        "y": 23.184
    },
    {
        "x": 55.52,
        "y": 23.184
    },
    {
        "x": 55.53,
        "y": 23.184
    },
    {
        "x": 55.54,
        "y": 23.184
    },
    {
        "x": 55.55,
        "y": 23.184
    },
    {
        "x": 55.56,
        "y": 23.184
    },
    {
        "x": 55.57,
        "y": 23.184
    },
    {
        "x": 55.58,
        "y": 23.184
    },
    {
        "x": 55.59,
        "y": 23.184
    },
    {
        "x": 55.6,
        "y": 23.184
    },
    {
        "x": 55.61,
        "y": 23.184
    },
    {
        "x": 55.62,
        "y": 23.184
    },
    {
        "x": 55.63,
        "y": 23.184
    },
    {
        "x": 55.64,
        "y": 23.184
    },
    {
        "x": 55.65,
        "y": 23.184
    },
    {
        "x": 55.66,
        "y": 23.184
    },
    {
        "x": 55.67,
        "y": 23.184
    },
    {
        "x": 55.68,
        "y": 23.184
    },
    {
        "x": 55.69,
        "y": 23.184
    },
    {
        "x": 55.7,
        "y": 23.184
    },
    {
        "x": 55.71,
        "y": 23.184
    },
    {
        "x": 55.72,
        "y": 23.184
    },
    {
        "x": 55.73,
        "y": 23.184
    },
    {
        "x": 55.74,
        "y": 23.184
    },
    {
        "x": 55.75,
        "y": 23.184
    },
    {
        "x": 55.76,
        "y": 23.184
    },
    {
        "x": 55.77,
        "y": 23.184
    },
    {
        "x": 55.78,
        "y": 23.184
    },
    {
        "x": 55.79,
        "y": 23.184
    },
    {
        "x": 55.8,
        "y": 23.184
    },
    {
        "x": 55.81,
        "y": 23.184
    },
    {
        "x": 55.82,
        "y": 23.184
    },
    {
        "x": 55.83,
        "y": 23.184
    },
    {
        "x": 55.84,
        "y": 23.184
    },
    {
        "x": 55.85,
        "y": 23.184
    },
    {
        "x": 55.86,
        "y": 23.184
    },
    {
        "x": 55.87,
        "y": 23.184
    },
    {
        "x": 55.88,
        "y": 23.184
    },
    {
        "x": 55.89,
        "y": 23.184
    },
    {
        "x": 55.9,
        "y": 23.184
    },
    {
        "x": 55.91,
        "y": 23.184
    },
    {
        "x": 55.92,
        "y": 23.184
    },
    {
        "x": 55.93,
        "y": 23.184
    },
    {
        "x": 55.94,
        "y": 23.184
    },
    {
        "x": 55.95,
        "y": 23.184
    },
    {
        "x": 55.96,
        "y": 23.184
    },
    {
        "x": 55.97,
        "y": 23.184
    },
    {
        "x": 55.98,
        "y": 23.184
    },
    {
        "x": 55.99,
        "y": 23.184
    },
    {
        "x": 56,
        "y": 23.175
    },
    {
        "x": 56.01,
        "y": 23.184
    },
    {
        "x": 56.02,
        "y": 23.184
    },
    {
        "x": 56.03,
        "y": 23.175
    },
    {
        "x": 56.04,
        "y": 23.184
    },
    {
        "x": 56.05,
        "y": 23.184
    },
    {
        "x": 56.06,
        "y": 23.184
    },
    {
        "x": 56.07,
        "y": 23.184
    },
    {
        "x": 56.08,
        "y": 23.184
    },
    {
        "x": 56.09,
        "y": 23.184
    },
    {
        "x": 56.1,
        "y": 23.184
    },
    {
        "x": 56.11,
        "y": 23.184
    },
    {
        "x": 56.12,
        "y": 23.184
    },
    {
        "x": 56.13,
        "y": 23.184
    },
    {
        "x": 56.14,
        "y": 23.184
    },
    {
        "x": 56.15,
        "y": 23.184
    },
    {
        "x": 56.16,
        "y": 23.175
    },
    {
        "x": 56.17,
        "y": 23.184
    },
    {
        "x": 56.18,
        "y": 23.184
    },
    {
        "x": 56.19,
        "y": 23.184
    },
    {
        "x": 56.2,
        "y": 23.184
    },
    {
        "x": 56.21,
        "y": 23.184
    },
    {
        "x": 56.22,
        "y": 23.184
    },
    {
        "x": 56.23,
        "y": 23.175
    },
    {
        "x": 56.24,
        "y": 23.184
    },
    {
        "x": 56.25,
        "y": 23.184
    },
    {
        "x": 56.26,
        "y": 23.175
    },
    {
        "x": 56.27,
        "y": 23.175
    },
    {
        "x": 56.28,
        "y": 23.184
    },
    {
        "x": 56.29,
        "y": 23.184
    },
    {
        "x": 56.3,
        "y": 23.184
    },
    {
        "x": 56.31,
        "y": 23.184
    },
    {
        "x": 56.32,
        "y": 23.184
    },
    {
        "x": 56.33,
        "y": 23.184
    },
    {
        "x": 56.34,
        "y": 23.184
    },
    {
        "x": 56.35,
        "y": 23.175
    },
    {
        "x": 56.36,
        "y": 23.184
    },
    {
        "x": 56.37,
        "y": 23.184
    },
    {
        "x": 56.38,
        "y": 23.184
    },
    {
        "x": 56.39,
        "y": 23.184
    },
    {
        "x": 56.4,
        "y": 23.184
    },
    {
        "x": 56.41,
        "y": 23.184
    },
    {
        "x": 56.42,
        "y": 23.184
    },
    {
        "x": 56.43,
        "y": 23.175
    },
    {
        "x": 56.44,
        "y": 23.175
    },
    {
        "x": 56.45,
        "y": 23.184
    },
    {
        "x": 56.46,
        "y": 23.175
    },
    {
        "x": 56.47,
        "y": 23.184
    },
    {
        "x": 56.48,
        "y": 23.175
    },
    {
        "x": 56.49,
        "y": 23.175
    },
    {
        "x": 56.5,
        "y": 23.175
    },
    {
        "x": 56.51,
        "y": 23.184
    },
    {
        "x": 56.52,
        "y": 23.184
    },
    {
        "x": 56.53,
        "y": 23.184
    },
    {
        "x": 56.54,
        "y": 23.175
    },
    {
        "x": 56.55,
        "y": 23.175
    },
    {
        "x": 56.56,
        "y": 23.175
    },
    {
        "x": 56.57,
        "y": 23.175
    },
    {
        "x": 56.58,
        "y": 23.175
    },
    {
        "x": 56.59,
        "y": 23.184
    },
    {
        "x": 56.6,
        "y": 23.175
    },
    {
        "x": 56.61,
        "y": 23.184
    },
    {
        "x": 56.62,
        "y": 23.184
    },
    {
        "x": 56.63,
        "y": 23.175
    },
    {
        "x": 56.64,
        "y": 23.184
    },
    {
        "x": 56.65,
        "y": 23.184
    },
    {
        "x": 56.66,
        "y": 23.175
    },
    {
        "x": 56.67,
        "y": 23.175
    },
    {
        "x": 56.68,
        "y": 23.184
    },
    {
        "x": 56.69,
        "y": 23.184
    },
    {
        "x": 56.7,
        "y": 23.184
    },
    {
        "x": 56.71,
        "y": 23.175
    },
    {
        "x": 56.72,
        "y": 23.175
    },
    {
        "x": 56.73,
        "y": 23.175
    },
    {
        "x": 56.74,
        "y": 23.175
    },
    {
        "x": 56.75,
        "y": 23.175
    },
    {
        "x": 56.76,
        "y": 23.184
    },
    {
        "x": 56.77,
        "y": 23.175
    },
    {
        "x": 56.78,
        "y": 23.175
    },
    {
        "x": 56.79,
        "y": 23.184
    },
    {
        "x": 56.8,
        "y": 23.175
    },
    {
        "x": 56.81,
        "y": 23.184
    },
    {
        "x": 56.82,
        "y": 23.184
    },
    {
        "x": 56.83,
        "y": 23.184
    },
    {
        "x": 56.84,
        "y": 23.175
    },
    {
        "x": 56.85,
        "y": 23.184
    },
    {
        "x": 56.86,
        "y": 23.184
    },
    {
        "x": 56.87,
        "y": 23.184
    },
    {
        "x": 56.88,
        "y": 23.184
    },
    {
        "x": 56.89,
        "y": 23.184
    },
    {
        "x": 56.9,
        "y": 23.184
    },
    {
        "x": 56.91,
        "y": 23.184
    },
    {
        "x": 56.92,
        "y": 23.175
    },
    {
        "x": 56.93,
        "y": 23.184
    },
    {
        "x": 56.94,
        "y": 23.184
    },
    {
        "x": 56.95,
        "y": 23.184
    },
    {
        "x": 56.96,
        "y": 23.175
    },
    {
        "x": 56.97,
        "y": 23.184
    },
    {
        "x": 56.98,
        "y": 23.184
    },
    {
        "x": 56.99,
        "y": 23.184
    },
    {
        "x": 57,
        "y": 23.184
    },
    {
        "x": 57.01,
        "y": 23.184
    },
    {
        "x": 57.02,
        "y": 23.184
    },
    {
        "x": 57.03,
        "y": 23.184
    },
    {
        "x": 57.04,
        "y": 23.184
    },
    {
        "x": 57.05,
        "y": 23.184
    },
    {
        "x": 57.06,
        "y": 23.175
    },
    {
        "x": 57.07,
        "y": 23.184
    },
    {
        "x": 57.08,
        "y": 23.175
    },
    {
        "x": 57.09,
        "y": 23.175
    },
    {
        "x": 57.1,
        "y": 23.175
    },
    {
        "x": 57.11,
        "y": 23.175
    },
    {
        "x": 57.12,
        "y": 23.175
    },
    {
        "x": 57.13,
        "y": 23.175
    },
    {
        "x": 57.14,
        "y": 23.184
    },
    {
        "x": 57.15,
        "y": 23.175
    },
    {
        "x": 57.16,
        "y": 23.175
    },
    {
        "x": 57.17,
        "y": 23.175
    },
    {
        "x": 57.18,
        "y": 23.184
    },
    {
        "x": 57.19,
        "y": 23.175
    },
    {
        "x": 57.2,
        "y": 23.184
    },
    {
        "x": 57.21,
        "y": 23.175
    },
    {
        "x": 57.22,
        "y": 23.175
    },
    {
        "x": 57.23,
        "y": 23.175
    },
    {
        "x": 57.24,
        "y": 23.175
    },
    {
        "x": 57.25,
        "y": 23.175
    },
    {
        "x": 57.26,
        "y": 23.175
    },
    {
        "x": 57.27,
        "y": 23.175
    },
    {
        "x": 57.28,
        "y": 23.175
    },
    {
        "x": 57.29,
        "y": 23.175
    },
    {
        "x": 57.3,
        "y": 23.175
    },
    {
        "x": 57.31,
        "y": 23.175
    },
    {
        "x": 57.32,
        "y": 23.175
    },
    {
        "x": 57.33,
        "y": 23.175
    },
    {
        "x": 57.34,
        "y": 23.175
    },
    {
        "x": 57.35,
        "y": 23.175
    },
    {
        "x": 57.36,
        "y": 23.175
    },
    {
        "x": 57.37,
        "y": 23.175
    },
    {
        "x": 57.38,
        "y": 23.175
    },
    {
        "x": 57.39,
        "y": 23.175
    },
    {
        "x": 57.4,
        "y": 23.175
    },
    {
        "x": 57.41,
        "y": 23.175
    },
    {
        "x": 57.42,
        "y": 23.175
    },
    {
        "x": 57.43,
        "y": 23.175
    },
    {
        "x": 57.44,
        "y": 23.175
    },
    {
        "x": 57.45,
        "y": 23.175
    },
    {
        "x": 57.46,
        "y": 23.175
    },
    {
        "x": 57.47,
        "y": 23.175
    },
    {
        "x": 57.48,
        "y": 23.175
    },
    {
        "x": 57.49,
        "y": 23.175
    },
    {
        "x": 57.5,
        "y": 23.175
    },
    {
        "x": 57.51,
        "y": 23.175
    },
    {
        "x": 57.52,
        "y": 23.175
    },
    {
        "x": 57.53,
        "y": 23.175
    },
    {
        "x": 57.54,
        "y": 23.175
    },
    {
        "x": 57.55,
        "y": 23.175
    },
    {
        "x": 57.56,
        "y": 23.175
    },
    {
        "x": 57.57,
        "y": 23.175
    },
    {
        "x": 57.58,
        "y": 23.175
    },
    {
        "x": 57.59,
        "y": 23.175
    },
    {
        "x": 57.6,
        "y": 23.175
    },
    {
        "x": 57.61,
        "y": 23.184
    },
    {
        "x": 57.62,
        "y": 23.175
    },
    {
        "x": 57.63,
        "y": 23.175
    },
    {
        "x": 57.64,
        "y": 23.175
    },
    {
        "x": 57.65,
        "y": 23.175
    },
    {
        "x": 57.66,
        "y": 23.175
    },
    {
        "x": 57.67,
        "y": 23.175
    },
    {
        "x": 57.68,
        "y": 23.175
    },
    {
        "x": 57.69,
        "y": 23.175
    },
    {
        "x": 57.7,
        "y": 23.175
    },
    {
        "x": 57.71,
        "y": 23.175
    },
    {
        "x": 57.72,
        "y": 23.175
    },
    {
        "x": 57.73,
        "y": 23.175
    },
    {
        "x": 57.74,
        "y": 23.175
    },
    {
        "x": 57.75,
        "y": 23.175
    },
    {
        "x": 57.76,
        "y": 23.175
    },
    {
        "x": 57.77,
        "y": 23.175
    },
    {
        "x": 57.78,
        "y": 23.175
    },
    {
        "x": 57.79,
        "y": 23.175
    },
    {
        "x": 57.8,
        "y": 23.175
    },
    {
        "x": 57.81,
        "y": 23.175
    },
    {
        "x": 57.82,
        "y": 23.175
    },
    {
        "x": 57.83,
        "y": 23.175
    },
    {
        "x": 57.84,
        "y": 23.175
    },
    {
        "x": 57.85,
        "y": 23.175
    },
    {
        "x": 57.86,
        "y": 23.175
    },
    {
        "x": 57.87,
        "y": 23.175
    },
    {
        "x": 57.88,
        "y": 23.175
    },
    {
        "x": 57.89,
        "y": 23.175
    },
    {
        "x": 57.9,
        "y": 23.175
    },
    {
        "x": 57.91,
        "y": 23.175
    },
    {
        "x": 57.92,
        "y": 23.175
    },
    {
        "x": 57.93,
        "y": 23.175
    },
    {
        "x": 57.94,
        "y": 23.175
    },
    {
        "x": 57.95,
        "y": 23.175
    },
    {
        "x": 57.96,
        "y": 23.175
    },
    {
        "x": 57.97,
        "y": 23.175
    },
    {
        "x": 57.98,
        "y": 23.175
    },
    {
        "x": 57.99,
        "y": 23.175
    },
    {
        "x": 58,
        "y": 23.175
    },
    {
        "x": 58.01,
        "y": 23.175
    },
    {
        "x": 58.02,
        "y": 23.175
    },
    {
        "x": 58.03,
        "y": 23.175
    },
    {
        "x": 58.04,
        "y": 23.175
    },
    {
        "x": 58.05,
        "y": 23.175
    },
    {
        "x": 58.06,
        "y": 23.175
    },
    {
        "x": 58.07,
        "y": 23.175
    },
    {
        "x": 58.08,
        "y": 23.184
    },
    {
        "x": 58.09,
        "y": 23.175
    },
    {
        "x": 58.1,
        "y": 23.175
    },
    {
        "x": 58.11,
        "y": 23.175
    },
    {
        "x": 58.12,
        "y": 23.175
    },
    {
        "x": 58.13,
        "y": 23.175
    },
    {
        "x": 58.14,
        "y": 23.175
    },
    {
        "x": 58.15,
        "y": 23.175
    },
    {
        "x": 58.16,
        "y": 23.175
    },
    {
        "x": 58.17,
        "y": 23.175
    },
    {
        "x": 58.18,
        "y": 23.175
    },
    {
        "x": 58.19,
        "y": 23.175
    },
    {
        "x": 58.2,
        "y": 23.175
    },
    {
        "x": 58.21,
        "y": 23.175
    },
    {
        "x": 58.22,
        "y": 23.175
    },
    {
        "x": 58.23,
        "y": 23.175
    },
    {
        "x": 58.24,
        "y": 23.175
    },
    {
        "x": 58.25,
        "y": 23.175
    },
    {
        "x": 58.26,
        "y": 23.175
    },
    {
        "x": 58.27,
        "y": 23.175
    },
    {
        "x": 58.28,
        "y": 23.175
    },
    {
        "x": 58.29,
        "y": 23.175
    },
    {
        "x": 58.3,
        "y": 23.175
    },
    {
        "x": 58.31,
        "y": 23.175
    },
    {
        "x": 58.32,
        "y": 23.175
    },
    {
        "x": 58.33,
        "y": 23.175
    },
    {
        "x": 58.34,
        "y": 23.175
    },
    {
        "x": 58.35,
        "y": 23.175
    },
    {
        "x": 58.36,
        "y": 23.175
    },
    {
        "x": 58.37,
        "y": 23.175
    },
    {
        "x": 58.38,
        "y": 23.175
    },
    {
        "x": 58.39,
        "y": 23.166
    },
    {
        "x": 58.4,
        "y": 23.175
    },
    {
        "x": 58.41,
        "y": 23.175
    },
    {
        "x": 58.42,
        "y": 23.175
    },
    {
        "x": 58.43,
        "y": 23.175
    },
    {
        "x": 58.44,
        "y": 23.175
    },
    {
        "x": 58.45,
        "y": 23.175
    },
    {
        "x": 58.46,
        "y": 23.166
    },
    {
        "x": 58.47,
        "y": 23.175
    },
    {
        "x": 58.48,
        "y": 23.175
    },
    {
        "x": 58.49,
        "y": 23.175
    },
    {
        "x": 58.5,
        "y": 23.175
    },
    {
        "x": 58.51,
        "y": 23.175
    },
    {
        "x": 58.52,
        "y": 23.175
    },
    {
        "x": 58.53,
        "y": 23.175
    },
    {
        "x": 58.54,
        "y": 23.175
    },
    {
        "x": 58.55,
        "y": 23.175
    },
    {
        "x": 58.56,
        "y": 23.175
    },
    {
        "x": 58.57,
        "y": 23.175
    },
    {
        "x": 58.58,
        "y": 23.175
    },
    {
        "x": 58.59,
        "y": 23.175
    },
    {
        "x": 58.6,
        "y": 23.175
    },
    {
        "x": 58.61,
        "y": 23.175
    },
    {
        "x": 58.62,
        "y": 23.175
    },
    {
        "x": 58.63,
        "y": 23.175
    },
    {
        "x": 58.64,
        "y": 23.175
    },
    {
        "x": 58.65,
        "y": 23.166
    },
    {
        "x": 58.66,
        "y": 23.175
    },
    {
        "x": 58.67,
        "y": 23.175
    },
    {
        "x": 58.68,
        "y": 23.175
    },
    {
        "x": 58.69,
        "y": 23.175
    },
    {
        "x": 58.7,
        "y": 23.175
    },
    {
        "x": 58.71,
        "y": 23.175
    },
    {
        "x": 58.72,
        "y": 23.175
    },
    {
        "x": 58.73,
        "y": 23.175
    },
    {
        "x": 58.74,
        "y": 23.166
    },
    {
        "x": 58.75,
        "y": 23.175
    },
    {
        "x": 58.76,
        "y": 23.175
    },
    {
        "x": 58.77,
        "y": 23.166
    },
    {
        "x": 58.78,
        "y": 23.166
    },
    {
        "x": 58.79,
        "y": 23.175
    },
    {
        "x": 58.8,
        "y": 23.175
    },
    {
        "x": 58.81,
        "y": 23.175
    },
    {
        "x": 58.82,
        "y": 23.166
    },
    {
        "x": 58.83,
        "y": 23.175
    },
    {
        "x": 58.84,
        "y": 23.175
    },
    {
        "x": 58.85,
        "y": 23.175
    },
    {
        "x": 58.86,
        "y": 23.175
    },
    {
        "x": 58.87,
        "y": 23.175
    },
    {
        "x": 58.88,
        "y": 23.175
    },
    {
        "x": 58.89,
        "y": 23.175
    },
    {
        "x": 58.9,
        "y": 23.175
    },
    {
        "x": 58.91,
        "y": 23.175
    },
    {
        "x": 58.92,
        "y": 23.175
    },
    {
        "x": 58.93,
        "y": 23.175
    },
    {
        "x": 58.94,
        "y": 23.175
    },
    {
        "x": 58.95,
        "y": 23.175
    },
    {
        "x": 58.96,
        "y": 23.175
    },
    {
        "x": 58.97,
        "y": 23.175
    },
    {
        "x": 58.98,
        "y": 23.166
    },
    {
        "x": 58.99,
        "y": 23.175
    },
    {
        "x": 59,
        "y": 23.175
    },
    {
        "x": 59.01,
        "y": 23.175
    },
    {
        "x": 59.02,
        "y": 23.175
    },
    {
        "x": 59.03,
        "y": 23.175
    },
    {
        "x": 59.04,
        "y": 23.175
    },
    {
        "x": 59.05,
        "y": 23.175
    },
    {
        "x": 59.06,
        "y": 23.175
    },
    {
        "x": 59.07,
        "y": 23.175
    },
    {
        "x": 59.08,
        "y": 23.175
    },
    {
        "x": 59.09,
        "y": 23.175
    },
    {
        "x": 59.1,
        "y": 23.175
    },
    {
        "x": 59.11,
        "y": 23.175
    },
    {
        "x": 59.12,
        "y": 23.175
    },
    {
        "x": 59.13,
        "y": 23.175
    },
    {
        "x": 59.14,
        "y": 23.175
    },
    {
        "x": 59.15,
        "y": 23.175
    },
    {
        "x": 59.16,
        "y": 23.175
    },
    {
        "x": 59.17,
        "y": 23.175
    },
    {
        "x": 59.18,
        "y": 23.175
    },
    {
        "x": 59.19,
        "y": 23.175
    },
    {
        "x": 59.2,
        "y": 23.175
    },
    {
        "x": 59.21,
        "y": 23.166
    },
    {
        "x": 59.22,
        "y": 23.175
    },
    {
        "x": 59.23,
        "y": 23.175
    },
    {
        "x": 59.24,
        "y": 23.175
    },
    {
        "x": 59.25,
        "y": 23.175
    },
    {
        "x": 59.26,
        "y": 23.175
    },
    {
        "x": 59.27,
        "y": 23.175
    },
    {
        "x": 59.28,
        "y": 23.175
    },
    {
        "x": 59.29,
        "y": 23.175
    },
    {
        "x": 59.3,
        "y": 23.175
    },
    {
        "x": 59.31,
        "y": 23.175
    },
    {
        "x": 59.32,
        "y": 23.175
    },
    {
        "x": 59.33,
        "y": 23.175
    },
    {
        "x": 59.34,
        "y": 23.175
    },
    {
        "x": 59.35,
        "y": 23.175
    },
    {
        "x": 59.36,
        "y": 23.175
    },
    {
        "x": 59.37,
        "y": 23.175
    },
    {
        "x": 59.38,
        "y": 23.175
    },
    {
        "x": 59.39,
        "y": 23.175
    },
    {
        "x": 59.4,
        "y": 23.166
    },
    {
        "x": 59.41,
        "y": 23.175
    },
    {
        "x": 59.42,
        "y": 23.175
    },
    {
        "x": 59.43,
        "y": 23.175
    },
    {
        "x": 59.44,
        "y": 23.166
    },
    {
        "x": 59.45,
        "y": 23.175
    },
    {
        "x": 59.46,
        "y": 23.166
    },
    {
        "x": 59.47,
        "y": 23.166
    },
    {
        "x": 59.48,
        "y": 23.175
    },
    {
        "x": 59.49,
        "y": 23.175
    },
    {
        "x": 59.5,
        "y": 23.175
    },
    {
        "x": 59.51,
        "y": 23.175
    },
    {
        "x": 59.52,
        "y": 23.175
    },
    {
        "x": 59.53,
        "y": 23.166
    },
    {
        "x": 59.54,
        "y": 23.175
    },
    {
        "x": 59.55,
        "y": 23.175
    },
    {
        "x": 59.56,
        "y": 23.175
    },
    {
        "x": 59.57,
        "y": 23.166
    },
    {
        "x": 59.58,
        "y": 23.175
    },
    {
        "x": 59.59,
        "y": 23.175
    },
    {
        "x": 59.6,
        "y": 23.175
    },
    {
        "x": 59.61,
        "y": 23.166
    },
    {
        "x": 59.62,
        "y": 23.166
    },
    {
        "x": 59.63,
        "y": 23.166
    },
    {
        "x": 59.64,
        "y": 23.175
    },
    {
        "x": 59.65,
        "y": 23.166
    },
    {
        "x": 59.66,
        "y": 23.166
    },
    {
        "x": 59.67,
        "y": 23.166
    },
    {
        "x": 59.68,
        "y": 23.166
    },
    {
        "x": 59.69,
        "y": 23.166
    },
    {
        "x": 59.7,
        "y": 23.166
    },
    {
        "x": 59.71,
        "y": 23.175
    },
    {
        "x": 59.72,
        "y": 23.166
    },
    {
        "x": 59.73,
        "y": 23.166
    },
    {
        "x": 59.74,
        "y": 23.166
    },
    {
        "x": 59.75,
        "y": 23.166
    },
    {
        "x": 59.76,
        "y": 23.166
    },
    {
        "x": 59.77,
        "y": 23.175
    },
    {
        "x": 59.78,
        "y": 23.166
    },
    {
        "x": 59.79,
        "y": 23.175
    },
    {
        "x": 59.8,
        "y": 23.166
    },
    {
        "x": 59.81,
        "y": 23.166
    },
    {
        "x": 59.82,
        "y": 23.175
    },
    {
        "x": 59.83,
        "y": 23.175
    },
    {
        "x": 59.84,
        "y": 23.166
    },
    {
        "x": 59.85,
        "y": 23.166
    },
    {
        "x": 59.86,
        "y": 23.166
    },
    {
        "x": 59.87,
        "y": 23.166
    },
    {
        "x": 59.88,
        "y": 23.166
    },
    {
        "x": 59.89,
        "y": 23.175
    },
    {
        "x": 59.9,
        "y": 23.166
    },
    {
        "x": 59.91,
        "y": 23.166
    },
    {
        "x": 59.92,
        "y": 23.166
    },
    {
        "x": 59.93,
        "y": 23.166
    },
    {
        "x": 59.94,
        "y": 23.166
    },
    {
        "x": 59.95,
        "y": 23.166
    },
    {
        "x": 59.96,
        "y": 23.166
    },
    {
        "x": 59.97,
        "y": 23.166
    },
    {
        "x": 59.98,
        "y": 23.166
    },
    {
        "x": 59.99,
        "y": 23.166
    },
    {
        "x": 60,
        "y": 23.166
    },
    {
        "x": 60.01,
        "y": 23.166
    },
    {
        "x": 60.02,
        "y": 23.166
    },
    {
        "x": 60.03,
        "y": 23.175
    },
    {
        "x": 60.04,
        "y": 23.166
    },
    {
        "x": 60.05,
        "y": 23.166
    },
    {
        "x": 60.06,
        "y": 23.166
    },
    {
        "x": 60.07,
        "y": 23.166
    },
    {
        "x": 60.08,
        "y": 23.166
    },
    {
        "x": 60.09,
        "y": 23.166
    },
    {
        "x": 60.1,
        "y": 23.175
    },
    {
        "x": 60.11,
        "y": 23.166
    },
    {
        "x": 60.12,
        "y": 23.166
    },
    {
        "x": 60.13,
        "y": 23.166
    },
    {
        "x": 60.14,
        "y": 23.166
    },
    {
        "x": 60.15,
        "y": 23.166
    },
    {
        "x": 60.16,
        "y": 23.166
    },
    {
        "x": 60.17,
        "y": 23.166
    },
    {
        "x": 60.18,
        "y": 23.175
    },
    {
        "x": 60.19,
        "y": 23.166
    },
    {
        "x": 60.2,
        "y": 23.166
    },
    {
        "x": 60.21,
        "y": 23.175
    },
    {
        "x": 60.22,
        "y": 23.166
    },
    {
        "x": 60.23,
        "y": 23.166
    },
    {
        "x": 60.24,
        "y": 23.166
    },
    {
        "x": 60.25,
        "y": 23.166
    },
    {
        "x": 60.26,
        "y": 23.166
    },
    {
        "x": 60.27,
        "y": 23.166
    },
    {
        "x": 60.28,
        "y": 23.166
    },
    {
        "x": 60.29,
        "y": 23.166
    },
    {
        "x": 60.3,
        "y": 23.175
    },
    {
        "x": 60.31,
        "y": 23.166
    },
    {
        "x": 60.32,
        "y": 23.166
    },
    {
        "x": 60.33,
        "y": 23.166
    },
    {
        "x": 60.34,
        "y": 23.175
    },
    {
        "x": 60.35,
        "y": 23.166
    },
    {
        "x": 60.36,
        "y": 23.166
    },
    {
        "x": 60.37,
        "y": 23.166
    },
    {
        "x": 60.38,
        "y": 23.166
    },
    {
        "x": 60.39,
        "y": 23.166
    },
    {
        "x": 60.4,
        "y": 23.166
    },
    {
        "x": 60.41,
        "y": 23.166
    },
    {
        "x": 60.42,
        "y": 23.166
    },
    {
        "x": 60.43,
        "y": 23.166
    },
    {
        "x": 60.44,
        "y": 23.166
    },
    {
        "x": 60.45,
        "y": 23.166
    },
    {
        "x": 60.46,
        "y": 23.175
    },
    {
        "x": 60.47,
        "y": 23.166
    },
    {
        "x": 60.48,
        "y": 23.166
    },
    {
        "x": 60.49,
        "y": 23.166
    },
    {
        "x": 60.5,
        "y": 23.166
    },
    {
        "x": 60.51,
        "y": 23.166
    },
    {
        "x": 60.52,
        "y": 23.166
    },
    {
        "x": 60.53,
        "y": 23.166
    },
    {
        "x": 60.54,
        "y": 23.166
    },
    {
        "x": 60.55,
        "y": 23.166
    },
    {
        "x": 60.56,
        "y": 23.166
    },
    {
        "x": 60.57,
        "y": 23.166
    },
    {
        "x": 60.58,
        "y": 23.166
    },
    {
        "x": 60.59,
        "y": 23.166
    },
    {
        "x": 60.6,
        "y": 23.166
    },
    {
        "x": 60.61,
        "y": 23.166
    },
    {
        "x": 60.62,
        "y": 23.166
    },
    {
        "x": 60.63,
        "y": 23.166
    },
    {
        "x": 60.64,
        "y": 23.166
    },
    {
        "x": 60.65,
        "y": 23.166
    },
    {
        "x": 60.66,
        "y": 23.166
    },
    {
        "x": 60.67,
        "y": 23.166
    },
    {
        "x": 60.68,
        "y": 23.166
    },
    {
        "x": 60.69,
        "y": 23.166
    },
    {
        "x": 60.7,
        "y": 23.166
    },
    {
        "x": 60.71,
        "y": 23.166
    },
    {
        "x": 60.72,
        "y": 23.166
    },
    {
        "x": 60.73,
        "y": 23.166
    },
    {
        "x": 60.74,
        "y": 23.166
    },
    {
        "x": 60.75,
        "y": 23.166
    },
    {
        "x": 60.76,
        "y": 23.166
    },
    {
        "x": 60.77,
        "y": 23.166
    },
    {
        "x": 60.78,
        "y": 23.166
    },
    {
        "x": 60.79,
        "y": 23.166
    },
    {
        "x": 60.8,
        "y": 23.166
    },
    {
        "x": 60.81,
        "y": 23.166
    },
    {
        "x": 60.82,
        "y": 23.166
    },
    {
        "x": 60.83,
        "y": 23.166
    },
    {
        "x": 60.84,
        "y": 23.157
    },
    {
        "x": 60.85,
        "y": 23.166
    },
    {
        "x": 60.86,
        "y": 23.157
    },
    {
        "x": 60.87,
        "y": 23.166
    },
    {
        "x": 60.88,
        "y": 23.166
    },
    {
        "x": 60.89,
        "y": 23.166
    },
    {
        "x": 60.9,
        "y": 23.166
    },
    {
        "x": 60.91,
        "y": 23.166
    },
    {
        "x": 60.92,
        "y": 23.157
    },
    {
        "x": 60.93,
        "y": 23.166
    },
    {
        "x": 60.94,
        "y": 23.166
    },
    {
        "x": 60.95,
        "y": 23.166
    },
    {
        "x": 60.96,
        "y": 23.166
    },
    {
        "x": 60.97,
        "y": 23.166
    },
    {
        "x": 60.98,
        "y": 23.166
    },
    {
        "x": 60.99,
        "y": 23.166
    },
    {
        "x": 61,
        "y": 23.166
    },
    {
        "x": 61.01,
        "y": 23.166
    },
    {
        "x": 61.02,
        "y": 23.166
    },
    {
        "x": 61.03,
        "y": 23.166
    },
    {
        "x": 61.04,
        "y": 23.166
    },
    {
        "x": 61.05,
        "y": 23.166
    },
    {
        "x": 61.06,
        "y": 23.166
    },
    {
        "x": 61.07,
        "y": 23.166
    },
    {
        "x": 61.08,
        "y": 23.166
    },
    {
        "x": 61.09,
        "y": 23.166
    },
    {
        "x": 61.1,
        "y": 23.166
    },
    {
        "x": 61.11,
        "y": 23.166
    },
    {
        "x": 61.12,
        "y": 23.166
    },
    {
        "x": 61.13,
        "y": 23.166
    },
    {
        "x": 61.14,
        "y": 23.166
    },
    {
        "x": 61.15,
        "y": 23.166
    },
    {
        "x": 61.16,
        "y": 23.166
    },
    {
        "x": 61.17,
        "y": 23.166
    },
    {
        "x": 61.18,
        "y": 23.166
    },
    {
        "x": 61.19,
        "y": 23.166
    },
    {
        "x": 61.2,
        "y": 23.166
    },
    {
        "x": 61.21,
        "y": 23.166
    },
    {
        "x": 61.22,
        "y": 23.166
    },
    {
        "x": 61.23,
        "y": 23.166
    },
    {
        "x": 61.24,
        "y": 23.166
    },
    {
        "x": 61.25,
        "y": 23.166
    },
    {
        "x": 61.26,
        "y": 23.166
    },
    {
        "x": 61.27,
        "y": 23.166
    },
    {
        "x": 61.28,
        "y": 23.166
    },
    {
        "x": 61.29,
        "y": 23.166
    },
    {
        "x": 61.3,
        "y": 23.166
    },
    {
        "x": 61.31,
        "y": 23.166
    },
    {
        "x": 61.32,
        "y": 23.166
    },
    {
        "x": 61.33,
        "y": 23.166
    },
    {
        "x": 61.34,
        "y": 23.166
    },
    {
        "x": 61.35,
        "y": 23.166
    },
    {
        "x": 61.36,
        "y": 23.166
    },
    {
        "x": 61.37,
        "y": 23.166
    },
    {
        "x": 61.38,
        "y": 23.166
    },
    {
        "x": 61.39,
        "y": 23.166
    },
    {
        "x": 61.4,
        "y": 23.166
    },
    {
        "x": 61.41,
        "y": 23.166
    },
    {
        "x": 61.42,
        "y": 23.166
    },
    {
        "x": 61.43,
        "y": 23.166
    },
    {
        "x": 61.44,
        "y": 23.166
    },
    {
        "x": 61.45,
        "y": 23.166
    },
    {
        "x": 61.46,
        "y": 23.166
    },
    {
        "x": 61.47,
        "y": 23.166
    },
    {
        "x": 61.48,
        "y": 23.166
    },
    {
        "x": 61.49,
        "y": 23.166
    },
    {
        "x": 61.5,
        "y": 23.166
    },
    {
        "x": 61.51,
        "y": 23.166
    },
    {
        "x": 61.52,
        "y": 23.166
    },
    {
        "x": 61.53,
        "y": 23.166
    },
    {
        "x": 61.54,
        "y": 23.166
    },
    {
        "x": 61.55,
        "y": 23.166
    },
    {
        "x": 61.56,
        "y": 23.157
    },
    {
        "x": 61.57,
        "y": 23.166
    },
    {
        "x": 61.58,
        "y": 23.166
    },
    {
        "x": 61.59,
        "y": 23.166
    },
    {
        "x": 61.6,
        "y": 23.157
    },
    {
        "x": 61.61,
        "y": 23.166
    },
    {
        "x": 61.62,
        "y": 23.166
    },
    {
        "x": 61.63,
        "y": 23.166
    },
    {
        "x": 61.64,
        "y": 23.166
    },
    {
        "x": 61.65,
        "y": 23.166
    },
    {
        "x": 61.66,
        "y": 23.166
    },
    {
        "x": 61.67,
        "y": 23.166
    },
    {
        "x": 61.68,
        "y": 23.157
    },
    {
        "x": 61.69,
        "y": 23.157
    },
    {
        "x": 61.7,
        "y": 23.166
    },
    {
        "x": 61.71,
        "y": 23.166
    },
    {
        "x": 61.72,
        "y": 23.166
    },
    {
        "x": 61.73,
        "y": 23.166
    },
    {
        "x": 61.74,
        "y": 23.166
    },
    {
        "x": 61.75,
        "y": 23.166
    },
    {
        "x": 61.76,
        "y": 23.166
    },
    {
        "x": 61.77,
        "y": 23.166
    },
    {
        "x": 61.78,
        "y": 23.166
    },
    {
        "x": 61.79,
        "y": 23.166
    },
    {
        "x": 61.8,
        "y": 23.166
    },
    {
        "x": 61.81,
        "y": 23.166
    },
    {
        "x": 61.82,
        "y": 23.166
    },
    {
        "x": 61.83,
        "y": 23.166
    },
    {
        "x": 61.84,
        "y": 23.166
    },
    {
        "x": 61.85,
        "y": 23.166
    },
    {
        "x": 61.86,
        "y": 23.166
    },
    {
        "x": 61.87,
        "y": 23.166
    },
    {
        "x": 61.88,
        "y": 23.166
    },
    {
        "x": 61.89,
        "y": 23.166
    },
    {
        "x": 61.9,
        "y": 23.166
    },
    {
        "x": 61.91,
        "y": 23.166
    },
    {
        "x": 61.92,
        "y": 23.166
    },
    {
        "x": 61.93,
        "y": 23.166
    },
    {
        "x": 61.94,
        "y": 23.166
    },
    {
        "x": 61.95,
        "y": 23.166
    },
    {
        "x": 61.96,
        "y": 23.166
    },
    {
        "x": 61.97,
        "y": 23.166
    },
    {
        "x": 61.98,
        "y": 23.157
    },
    {
        "x": 61.99,
        "y": 23.166
    },
    {
        "x": 62,
        "y": 23.166
    },
    {
        "x": 62.01,
        "y": 23.166
    },
    {
        "x": 62.02,
        "y": 23.157
    },
    {
        "x": 62.03,
        "y": 23.166
    },
    {
        "x": 62.04,
        "y": 23.166
    },
    {
        "x": 62.05,
        "y": 23.166
    },
    {
        "x": 62.06,
        "y": 23.166
    },
    {
        "x": 62.07,
        "y": 23.166
    },
    {
        "x": 62.08,
        "y": 23.166
    },
    {
        "x": 62.09,
        "y": 23.157
    },
    {
        "x": 62.1,
        "y": 23.157
    },
    {
        "x": 62.11,
        "y": 23.166
    },
    {
        "x": 62.12,
        "y": 23.157
    },
    {
        "x": 62.13,
        "y": 23.166
    },
    {
        "x": 62.14,
        "y": 23.166
    },
    {
        "x": 62.15,
        "y": 23.157
    },
    {
        "x": 62.16,
        "y": 23.157
    },
    {
        "x": 62.17,
        "y": 23.166
    },
    {
        "x": 62.18,
        "y": 23.157
    },
    {
        "x": 62.19,
        "y": 23.157
    },
    {
        "x": 62.2,
        "y": 23.157
    },
    {
        "x": 62.21,
        "y": 23.157
    },
    {
        "x": 62.22,
        "y": 23.157
    },
    {
        "x": 62.23,
        "y": 23.157
    },
    {
        "x": 62.24,
        "y": 23.157
    },
    {
        "x": 62.25,
        "y": 23.157
    },
    {
        "x": 62.26,
        "y": 23.157
    },
    {
        "x": 62.27,
        "y": 23.157
    },
    {
        "x": 62.28,
        "y": 23.157
    },
    {
        "x": 62.29,
        "y": 23.157
    },
    {
        "x": 62.3,
        "y": 23.157
    },
    {
        "x": 62.31,
        "y": 23.157
    },
    {
        "x": 62.32,
        "y": 23.157
    },
    {
        "x": 62.33,
        "y": 23.157
    },
    {
        "x": 62.34,
        "y": 23.157
    },
    {
        "x": 62.35,
        "y": 23.157
    },
    {
        "x": 62.36,
        "y": 23.157
    },
    {
        "x": 62.37,
        "y": 23.166
    },
    {
        "x": 62.38,
        "y": 23.157
    },
    {
        "x": 62.39,
        "y": 23.157
    },
    {
        "x": 62.4,
        "y": 23.157
    },
    {
        "x": 62.41,
        "y": 23.157
    },
    {
        "x": 62.42,
        "y": 23.166
    },
    {
        "x": 62.43,
        "y": 23.157
    },
    {
        "x": 62.44,
        "y": 23.157
    },
    {
        "x": 62.45,
        "y": 23.157
    },
    {
        "x": 62.46,
        "y": 23.157
    },
    {
        "x": 62.47,
        "y": 23.157
    },
    {
        "x": 62.48,
        "y": 23.157
    },
    {
        "x": 62.49,
        "y": 23.157
    },
    {
        "x": 62.5,
        "y": 23.157
    },
    {
        "x": 62.51,
        "y": 23.157
    },
    {
        "x": 62.52,
        "y": 23.157
    },
    {
        "x": 62.53,
        "y": 23.157
    },
    {
        "x": 62.54,
        "y": 23.157
    },
    {
        "x": 62.55,
        "y": 23.157
    },
    {
        "x": 62.56,
        "y": 23.157
    },
    {
        "x": 62.57,
        "y": 23.157
    },
    {
        "x": 62.58,
        "y": 23.157
    },
    {
        "x": 62.59,
        "y": 23.157
    },
    {
        "x": 62.6,
        "y": 23.157
    },
    {
        "x": 62.61,
        "y": 23.157
    },
    {
        "x": 62.62,
        "y": 23.157
    },
    {
        "x": 62.63,
        "y": 23.157
    },
    {
        "x": 62.64,
        "y": 23.157
    },
    {
        "x": 62.65,
        "y": 23.157
    },
    {
        "x": 62.66,
        "y": 23.157
    },
    {
        "x": 62.67,
        "y": 23.157
    },
    {
        "x": 62.68,
        "y": 23.157
    },
    {
        "x": 62.69,
        "y": 23.157
    },
    {
        "x": 62.7,
        "y": 23.157
    },
    {
        "x": 62.71,
        "y": 23.157
    },
    {
        "x": 62.72,
        "y": 23.157
    },
    {
        "x": 62.73,
        "y": 23.157
    },
    {
        "x": 62.74,
        "y": 23.157
    },
    {
        "x": 62.75,
        "y": 23.157
    },
    {
        "x": 62.76,
        "y": 23.157
    },
    {
        "x": 62.77,
        "y": 23.157
    },
    {
        "x": 62.78,
        "y": 23.157
    },
    {
        "x": 62.79,
        "y": 23.157
    },
    {
        "x": 62.8,
        "y": 23.157
    },
    {
        "x": 62.81,
        "y": 23.157
    },
    {
        "x": 62.82,
        "y": 23.157
    },
    {
        "x": 62.83,
        "y": 23.157
    },
    {
        "x": 62.84,
        "y": 23.157
    },
    {
        "x": 62.85,
        "y": 23.157
    },
    {
        "x": 62.86,
        "y": 23.157
    },
    {
        "x": 62.87,
        "y": 23.157
    },
    {
        "x": 62.88,
        "y": 23.157
    },
    {
        "x": 62.89,
        "y": 23.157
    },
    {
        "x": 62.9,
        "y": 23.157
    },
    {
        "x": 62.91,
        "y": 23.157
    },
    {
        "x": 62.92,
        "y": 23.157
    },
    {
        "x": 62.93,
        "y": 23.157
    },
    {
        "x": 62.94,
        "y": 23.157
    },
    {
        "x": 62.95,
        "y": 23.157
    },
    {
        "x": 62.96,
        "y": 23.157
    },
    {
        "x": 62.97,
        "y": 23.157
    },
    {
        "x": 62.98,
        "y": 23.157
    },
    {
        "x": 62.99,
        "y": 23.157
    },
    {
        "x": 63,
        "y": 23.157
    },
    {
        "x": 63.01,
        "y": 23.157
    },
    {
        "x": 63.02,
        "y": 23.157
    },
    {
        "x": 63.03,
        "y": 23.157
    },
    {
        "x": 63.04,
        "y": 23.157
    },
    {
        "x": 63.05,
        "y": 23.157
    },
    {
        "x": 63.06,
        "y": 23.157
    },
    {
        "x": 63.07,
        "y": 23.157
    },
    {
        "x": 63.08,
        "y": 23.157
    },
    {
        "x": 63.09,
        "y": 23.157
    },
    {
        "x": 63.1,
        "y": 23.157
    },
    {
        "x": 63.11,
        "y": 23.157
    },
    {
        "x": 63.12,
        "y": 23.157
    },
    {
        "x": 63.13,
        "y": 23.157
    },
    {
        "x": 63.14,
        "y": 23.157
    },
    {
        "x": 63.15,
        "y": 23.157
    },
    {
        "x": 63.16,
        "y": 23.157
    },
    {
        "x": 63.17,
        "y": 23.157
    },
    {
        "x": 63.18,
        "y": 23.157
    },
    {
        "x": 63.19,
        "y": 23.157
    },
    {
        "x": 63.2,
        "y": 23.157
    },
    {
        "x": 63.21,
        "y": 23.157
    },
    {
        "x": 63.22,
        "y": 23.157
    },
    {
        "x": 63.23,
        "y": 23.157
    },
    {
        "x": 63.24,
        "y": 23.157
    },
    {
        "x": 63.25,
        "y": 23.157
    },
    {
        "x": 63.26,
        "y": 23.157
    },
    {
        "x": 63.27,
        "y": 23.157
    },
    {
        "x": 63.28,
        "y": 23.157
    },
    {
        "x": 63.29,
        "y": 23.157
    },
    {
        "x": 63.3,
        "y": 23.157
    },
    {
        "x": 63.31,
        "y": 23.157
    },
    {
        "x": 63.32,
        "y": 23.157
    },
    {
        "x": 63.33,
        "y": 23.157
    },
    {
        "x": 63.34,
        "y": 23.157
    },
    {
        "x": 63.35,
        "y": 23.157
    },
    {
        "x": 63.36,
        "y": 23.157
    },
    {
        "x": 63.37,
        "y": 23.157
    },
    {
        "x": 63.38,
        "y": 23.157
    },
    {
        "x": 63.39,
        "y": 23.157
    },
    {
        "x": 63.4,
        "y": 23.157
    },
    {
        "x": 63.41,
        "y": 23.157
    },
    {
        "x": 63.42,
        "y": 23.157
    },
    {
        "x": 63.43,
        "y": 23.157
    },
    {
        "x": 63.44,
        "y": 23.157
    },
    {
        "x": 63.45,
        "y": 23.157
    },
    {
        "x": 63.46,
        "y": 23.157
    },
    {
        "x": 63.47,
        "y": 23.157
    },
    {
        "x": 63.48,
        "y": 23.157
    },
    {
        "x": 63.49,
        "y": 23.157
    },
    {
        "x": 63.5,
        "y": 23.157
    },
    {
        "x": 63.51,
        "y": 23.148
    },
    {
        "x": 63.52,
        "y": 23.157
    },
    {
        "x": 63.53,
        "y": 23.157
    },
    {
        "x": 63.54,
        "y": 23.157
    },
    {
        "x": 63.55,
        "y": 23.148
    },
    {
        "x": 63.56,
        "y": 23.157
    },
    {
        "x": 63.57,
        "y": 23.157
    },
    {
        "x": 63.58,
        "y": 23.157
    },
    {
        "x": 63.59,
        "y": 23.157
    },
    {
        "x": 63.6,
        "y": 23.157
    },
    {
        "x": 63.61,
        "y": 23.157
    },
    {
        "x": 63.62,
        "y": 23.157
    },
    {
        "x": 63.63,
        "y": 23.157
    },
    {
        "x": 63.64,
        "y": 23.157
    },
    {
        "x": 63.65,
        "y": 23.157
    },
    {
        "x": 63.66,
        "y": 23.157
    },
    {
        "x": 63.67,
        "y": 23.157
    },
    {
        "x": 63.68,
        "y": 23.157
    },
    {
        "x": 63.69,
        "y": 23.157
    },
    {
        "x": 63.7,
        "y": 23.157
    },
    {
        "x": 63.71,
        "y": 23.157
    },
    {
        "x": 63.72,
        "y": 23.157
    },
    {
        "x": 63.73,
        "y": 23.148
    },
    {
        "x": 63.74,
        "y": 23.157
    },
    {
        "x": 63.75,
        "y": 23.157
    },
    {
        "x": 63.76,
        "y": 23.148
    },
    {
        "x": 63.77,
        "y": 23.148
    },
    {
        "x": 63.78,
        "y": 23.148
    },
    {
        "x": 63.79,
        "y": 23.157
    },
    {
        "x": 63.8,
        "y": 23.157
    },
    {
        "x": 63.81,
        "y": 23.148
    },
    {
        "x": 63.82,
        "y": 23.157
    },
    {
        "x": 63.83,
        "y": 23.157
    },
    {
        "x": 63.84,
        "y": 23.157
    },
    {
        "x": 63.85,
        "y": 23.148
    },
    {
        "x": 63.86,
        "y": 23.148
    },
    {
        "x": 63.87,
        "y": 23.157
    },
    {
        "x": 63.88,
        "y": 23.157
    },
    {
        "x": 63.89,
        "y": 23.157
    },
    {
        "x": 63.9,
        "y": 23.157
    },
    {
        "x": 63.91,
        "y": 23.148
    },
    {
        "x": 63.92,
        "y": 23.157
    },
    {
        "x": 63.93,
        "y": 23.148
    },
    {
        "x": 63.94,
        "y": 23.148
    },
    {
        "x": 63.95,
        "y": 23.148
    },
    {
        "x": 63.96,
        "y": 23.148
    },
    {
        "x": 63.97,
        "y": 23.157
    },
    {
        "x": 63.98,
        "y": 23.157
    },
    {
        "x": 63.99,
        "y": 23.157
    },
    {
        "x": 64,
        "y": 23.148
    },
    {
        "x": 64.01,
        "y": 23.148
    },
    {
        "x": 64.02,
        "y": 23.157
    },
    {
        "x": 64.03,
        "y": 23.148
    },
    {
        "x": 64.04,
        "y": 23.148
    },
    {
        "x": 64.05,
        "y": 23.148
    },
    {
        "x": 64.06,
        "y": 23.148
    },
    {
        "x": 64.07,
        "y": 23.148
    },
    {
        "x": 64.08,
        "y": 23.157
    },
    {
        "x": 64.09,
        "y": 23.148
    },
    {
        "x": 64.1,
        "y": 23.157
    },
    {
        "x": 64.11,
        "y": 23.148
    },
    {
        "x": 64.12,
        "y": 23.157
    },
    {
        "x": 64.13,
        "y": 23.157
    },
    {
        "x": 64.14,
        "y": 23.157
    },
    {
        "x": 64.15,
        "y": 23.157
    },
    {
        "x": 64.16,
        "y": 23.148
    },
    {
        "x": 64.17,
        "y": 23.148
    },
    {
        "x": 64.18,
        "y": 23.157
    },
    {
        "x": 64.19,
        "y": 23.157
    },
    {
        "x": 64.2,
        "y": 23.157
    },
    {
        "x": 64.21,
        "y": 23.148
    },
    {
        "x": 64.22,
        "y": 23.157
    },
    {
        "x": 64.23,
        "y": 23.157
    },
    {
        "x": 64.24,
        "y": 23.157
    },
    {
        "x": 64.25,
        "y": 23.157
    },
    {
        "x": 64.26,
        "y": 23.157
    },
    {
        "x": 64.27,
        "y": 23.157
    },
    {
        "x": 64.28,
        "y": 23.157
    },
    {
        "x": 64.29,
        "y": 23.148
    },
    {
        "x": 64.3,
        "y": 23.157
    },
    {
        "x": 64.31,
        "y": 23.148
    },
    {
        "x": 64.32,
        "y": 23.148
    },
    {
        "x": 64.33,
        "y": 23.148
    },
    {
        "x": 64.34,
        "y": 23.148
    },
    {
        "x": 64.35,
        "y": 23.148
    },
    {
        "x": 64.36,
        "y": 23.148
    },
    {
        "x": 64.37,
        "y": 23.148
    },
    {
        "x": 64.38,
        "y": 23.148
    },
    {
        "x": 64.39,
        "y": 23.148
    },
    {
        "x": 64.4,
        "y": 23.148
    },
    {
        "x": 64.41,
        "y": 23.148
    },
    {
        "x": 64.42,
        "y": 23.148
    },
    {
        "x": 64.43,
        "y": 23.148
    },
    {
        "x": 64.44,
        "y": 23.148
    },
    {
        "x": 64.45,
        "y": 23.148
    },
    {
        "x": 64.46,
        "y": 23.148
    },
    {
        "x": 64.47,
        "y": 23.148
    },
    {
        "x": 64.48,
        "y": 23.148
    },
    {
        "x": 64.49,
        "y": 23.148
    },
    {
        "x": 64.5,
        "y": 23.148
    },
    {
        "x": 64.51,
        "y": 23.148
    },
    {
        "x": 64.52,
        "y": 23.148
    },
    {
        "x": 64.53,
        "y": 23.148
    },
    {
        "x": 64.54,
        "y": 23.148
    },
    {
        "x": 64.55,
        "y": 23.148
    },
    {
        "x": 64.56,
        "y": 23.148
    },
    {
        "x": 64.57,
        "y": 23.148
    },
    {
        "x": 64.58,
        "y": 23.148
    },
    {
        "x": 64.59,
        "y": 23.148
    },
    {
        "x": 64.6,
        "y": 23.148
    },
    {
        "x": 64.61,
        "y": 23.148
    },
    {
        "x": 64.62,
        "y": 23.148
    },
    {
        "x": 64.63,
        "y": 23.148
    },
    {
        "x": 64.64,
        "y": 23.148
    },
    {
        "x": 64.65,
        "y": 23.148
    },
    {
        "x": 64.66,
        "y": 23.157
    },
    {
        "x": 64.67,
        "y": 23.148
    },
    {
        "x": 64.68,
        "y": 23.148
    },
    {
        "x": 64.69,
        "y": 23.148
    },
    {
        "x": 64.7,
        "y": 23.148
    },
    {
        "x": 64.71,
        "y": 23.148
    },
    {
        "x": 64.72,
        "y": 23.148
    },
    {
        "x": 64.73,
        "y": 23.148
    },
    {
        "x": 64.74,
        "y": 23.148
    },
    {
        "x": 64.75,
        "y": 23.148
    },
    {
        "x": 64.76,
        "y": 23.148
    },
    {
        "x": 64.77,
        "y": 23.148
    },
    {
        "x": 64.78,
        "y": 23.148
    },
    {
        "x": 64.79,
        "y": 23.148
    },
    {
        "x": 64.8,
        "y": 23.148
    },
    {
        "x": 64.81,
        "y": 23.148
    },
    {
        "x": 64.82,
        "y": 23.148
    },
    {
        "x": 64.83,
        "y": 23.148
    },
    {
        "x": 64.84,
        "y": 23.148
    },
    {
        "x": 64.85,
        "y": 23.148
    },
    {
        "x": 64.86,
        "y": 23.148
    },
    {
        "x": 64.87,
        "y": 23.148
    },
    {
        "x": 64.88,
        "y": 23.148
    },
    {
        "x": 64.89,
        "y": 23.148
    },
    {
        "x": 64.9,
        "y": 23.148
    },
    {
        "x": 64.91,
        "y": 23.148
    },
    {
        "x": 64.92,
        "y": 23.148
    },
    {
        "x": 64.93,
        "y": 23.148
    },
    {
        "x": 64.94,
        "y": 23.148
    },
    {
        "x": 64.95,
        "y": 23.148
    },
    {
        "x": 64.96,
        "y": 23.148
    },
    {
        "x": 64.97,
        "y": 23.148
    },
    {
        "x": 64.98,
        "y": 23.148
    },
    {
        "x": 64.99,
        "y": 23.148
    },
    {
        "x": 65,
        "y": 23.148
    },
    {
        "x": 65.01,
        "y": 23.148
    },
    {
        "x": 65.02,
        "y": 23.148
    },
    {
        "x": 65.03,
        "y": 23.148
    },
    {
        "x": 65.04,
        "y": 23.148
    },
    {
        "x": 65.05,
        "y": 23.148
    },
    {
        "x": 65.06,
        "y": 23.148
    },
    {
        "x": 65.07,
        "y": 23.148
    },
    {
        "x": 65.08,
        "y": 23.148
    },
    {
        "x": 65.09,
        "y": 23.148
    },
    {
        "x": 65.1,
        "y": 23.148
    },
    {
        "x": 65.11,
        "y": 23.148
    },
    {
        "x": 65.12,
        "y": 23.148
    },
    {
        "x": 65.13,
        "y": 23.139
    },
    {
        "x": 65.14,
        "y": 23.148
    },
    {
        "x": 65.15,
        "y": 23.148
    },
    {
        "x": 65.16,
        "y": 23.148
    },
    {
        "x": 65.17,
        "y": 23.148
    },
    {
        "x": 65.18,
        "y": 23.148
    },
    {
        "x": 65.19,
        "y": 23.148
    },
    {
        "x": 65.2,
        "y": 23.148
    },
    {
        "x": 65.21,
        "y": 23.148
    },
    {
        "x": 65.22,
        "y": 23.148
    },
    {
        "x": 65.23,
        "y": 23.148
    },
    {
        "x": 65.24,
        "y": 23.148
    },
    {
        "x": 65.25,
        "y": 23.148
    },
    {
        "x": 65.26,
        "y": 23.148
    },
    {
        "x": 65.27,
        "y": 23.148
    },
    {
        "x": 65.28,
        "y": 23.148
    },
    {
        "x": 65.29,
        "y": 23.148
    },
    {
        "x": 65.3,
        "y": 23.148
    },
    {
        "x": 65.31,
        "y": 23.148
    },
    {
        "x": 65.32,
        "y": 23.148
    },
    {
        "x": 65.33,
        "y": 23.148
    },
    {
        "x": 65.34,
        "y": 23.148
    },
    {
        "x": 65.35,
        "y": 23.148
    },
    {
        "x": 65.36,
        "y": 23.148
    },
    {
        "x": 65.37,
        "y": 23.148
    },
    {
        "x": 65.38,
        "y": 23.148
    },
    {
        "x": 65.39,
        "y": 23.148
    },
    {
        "x": 65.4,
        "y": 23.148
    },
    {
        "x": 65.41,
        "y": 23.148
    },
    {
        "x": 65.42,
        "y": 23.148
    },
    {
        "x": 65.43,
        "y": 23.148
    },
    {
        "x": 65.44,
        "y": 23.148
    },
    {
        "x": 65.45,
        "y": 23.148
    },
    {
        "x": 65.46,
        "y": 23.139
    },
    {
        "x": 65.47,
        "y": 23.148
    },
    {
        "x": 65.48,
        "y": 23.148
    },
    {
        "x": 65.49,
        "y": 23.148
    },
    {
        "x": 65.5,
        "y": 23.148
    },
    {
        "x": 65.51,
        "y": 23.148
    },
    {
        "x": 65.52,
        "y": 23.148
    },
    {
        "x": 65.53,
        "y": 23.148
    },
    {
        "x": 65.54,
        "y": 23.148
    },
    {
        "x": 65.55,
        "y": 23.148
    },
    {
        "x": 65.56,
        "y": 23.148
    },
    {
        "x": 65.57,
        "y": 23.148
    },
    {
        "x": 65.58,
        "y": 23.148
    },
    {
        "x": 65.59,
        "y": 23.148
    },
    {
        "x": 65.6,
        "y": 23.148
    },
    {
        "x": 65.61,
        "y": 23.148
    },
    {
        "x": 65.62,
        "y": 23.148
    },
    {
        "x": 65.63,
        "y": 23.148
    },
    {
        "x": 65.64,
        "y": 23.148
    },
    {
        "x": 65.65,
        "y": 23.148
    },
    {
        "x": 65.66,
        "y": 23.148
    },
    {
        "x": 65.67,
        "y": 23.148
    },
    {
        "x": 65.68,
        "y": 23.148
    },
    {
        "x": 65.69,
        "y": 23.148
    },
    {
        "x": 65.7,
        "y": 23.148
    },
    {
        "x": 65.71,
        "y": 23.148
    },
    {
        "x": 65.72,
        "y": 23.139
    },
    {
        "x": 65.73,
        "y": 23.148
    },
    {
        "x": 65.74,
        "y": 23.148
    },
    {
        "x": 65.75,
        "y": 23.148
    },
    {
        "x": 65.76,
        "y": 23.139
    },
    {
        "x": 65.77,
        "y": 23.148
    },
    {
        "x": 65.78,
        "y": 23.148
    },
    {
        "x": 65.79,
        "y": 23.148
    },
    {
        "x": 65.8,
        "y": 23.148
    },
    {
        "x": 65.81,
        "y": 23.139
    },
    {
        "x": 65.82,
        "y": 23.139
    },
    {
        "x": 65.83,
        "y": 23.148
    },
    {
        "x": 65.84,
        "y": 23.148
    },
    {
        "x": 65.85,
        "y": 23.148
    },
    {
        "x": 65.86,
        "y": 23.148
    },
    {
        "x": 65.87,
        "y": 23.139
    },
    {
        "x": 65.88,
        "y": 23.139
    },
    {
        "x": 65.89,
        "y": 23.148
    },
    {
        "x": 65.9,
        "y": 23.148
    },
    {
        "x": 65.91,
        "y": 23.139
    },
    {
        "x": 65.92,
        "y": 23.139
    },
    {
        "x": 65.93,
        "y": 23.148
    },
    {
        "x": 65.94,
        "y": 23.148
    },
    {
        "x": 65.95,
        "y": 23.148
    },
    {
        "x": 65.96,
        "y": 23.139
    },
    {
        "x": 65.97,
        "y": 23.148
    },
    {
        "x": 65.98,
        "y": 23.148
    },
    {
        "x": 65.99,
        "y": 23.139
    },
    {
        "x": 66,
        "y": 23.139
    },
    {
        "x": 66.01,
        "y": 23.148
    },
    {
        "x": 66.02,
        "y": 23.148
    },
    {
        "x": 66.03,
        "y": 23.148
    },
    {
        "x": 66.04,
        "y": 23.148
    },
    {
        "x": 66.05,
        "y": 23.148
    },
    {
        "x": 66.06,
        "y": 23.148
    },
    {
        "x": 66.07,
        "y": 23.139
    },
    {
        "x": 66.08,
        "y": 23.148
    },
    {
        "x": 66.09,
        "y": 23.148
    },
    {
        "x": 66.1,
        "y": 23.139
    },
    {
        "x": 66.11,
        "y": 23.139
    },
    {
        "x": 66.12,
        "y": 23.139
    },
    {
        "x": 66.13,
        "y": 23.139
    },
    {
        "x": 66.14,
        "y": 23.139
    },
    {
        "x": 66.15,
        "y": 23.139
    },
    {
        "x": 66.16,
        "y": 23.139
    },
    {
        "x": 66.17,
        "y": 23.139
    },
    {
        "x": 66.18,
        "y": 23.139
    },
    {
        "x": 66.19,
        "y": 23.139
    },
    {
        "x": 66.2,
        "y": 23.139
    },
    {
        "x": 66.21,
        "y": 23.139
    },
    {
        "x": 66.22,
        "y": 23.139
    },
    {
        "x": 66.23,
        "y": 23.139
    },
    {
        "x": 66.24,
        "y": 23.139
    },
    {
        "x": 66.25,
        "y": 23.139
    },
    {
        "x": 66.26,
        "y": 23.139
    },
    {
        "x": 66.27,
        "y": 23.139
    },
    {
        "x": 66.28,
        "y": 23.139
    },
    {
        "x": 66.29,
        "y": 23.139
    },
    {
        "x": 66.3,
        "y": 23.139
    },
    {
        "x": 66.31,
        "y": 23.139
    },
    {
        "x": 66.32,
        "y": 23.139
    },
    {
        "x": 66.33,
        "y": 23.139
    },
    {
        "x": 66.34,
        "y": 23.139
    },
    {
        "x": 66.35,
        "y": 23.139
    },
    {
        "x": 66.36,
        "y": 23.139
    },
    {
        "x": 66.37,
        "y": 23.139
    },
    {
        "x": 66.38,
        "y": 23.139
    },
    {
        "x": 66.39,
        "y": 23.139
    },
    {
        "x": 66.4,
        "y": 23.139
    },
    {
        "x": 66.41,
        "y": 23.139
    },
    {
        "x": 66.42,
        "y": 23.139
    },
    {
        "x": 66.43,
        "y": 23.139
    },
    {
        "x": 66.44,
        "y": 23.139
    },
    {
        "x": 66.45,
        "y": 23.139
    },
    {
        "x": 66.46,
        "y": 23.139
    },
    {
        "x": 66.47,
        "y": 23.139
    },
    {
        "x": 66.48,
        "y": 23.139
    },
    {
        "x": 66.49,
        "y": 23.139
    },
    {
        "x": 66.5,
        "y": 23.139
    },
    {
        "x": 66.51,
        "y": 23.139
    },
    {
        "x": 66.52,
        "y": 23.139
    },
    {
        "x": 66.53,
        "y": 23.139
    },
    {
        "x": 66.54,
        "y": 23.139
    },
    {
        "x": 66.55,
        "y": 23.139
    },
    {
        "x": 66.56,
        "y": 23.139
    },
    {
        "x": 66.57,
        "y": 23.139
    },
    {
        "x": 66.58,
        "y": 23.139
    },
    {
        "x": 66.59,
        "y": 23.139
    },
    {
        "x": 66.6,
        "y": 23.139
    },
    {
        "x": 66.61,
        "y": 23.139
    },
    {
        "x": 66.62,
        "y": 23.139
    },
    {
        "x": 66.63,
        "y": 23.139
    },
    {
        "x": 66.64,
        "y": 23.139
    },
    {
        "x": 66.65,
        "y": 23.139
    },
    {
        "x": 66.66,
        "y": 23.139
    },
    {
        "x": 66.67,
        "y": 23.139
    },
    {
        "x": 66.68,
        "y": 23.139
    },
    {
        "x": 66.69,
        "y": 23.139
    },
    {
        "x": 66.7,
        "y": 23.139
    },
    {
        "x": 66.71,
        "y": 23.139
    },
    {
        "x": 66.72,
        "y": 23.139
    },
    {
        "x": 66.73,
        "y": 23.139
    },
    {
        "x": 66.74,
        "y": 23.139
    },
    {
        "x": 66.75,
        "y": 23.139
    },
    {
        "x": 66.76,
        "y": 23.139
    },
    {
        "x": 66.77,
        "y": 23.139
    },
    {
        "x": 66.78,
        "y": 23.139
    },
    {
        "x": 66.79,
        "y": 23.139
    },
    {
        "x": 66.8,
        "y": 23.139
    },
    {
        "x": 66.81,
        "y": 23.139
    },
    {
        "x": 66.82,
        "y": 23.139
    },
    {
        "x": 66.83,
        "y": 23.139
    },
    {
        "x": 66.84,
        "y": 23.139
    },
    {
        "x": 66.85,
        "y": 23.139
    },
    {
        "x": 66.86,
        "y": 23.139
    },
    {
        "x": 66.87,
        "y": 23.139
    },
    {
        "x": 66.88,
        "y": 23.139
    },
    {
        "x": 66.89,
        "y": 23.139
    },
    {
        "x": 66.9,
        "y": 23.139
    },
    {
        "x": 66.91,
        "y": 23.139
    },
    {
        "x": 66.92,
        "y": 23.139
    },
    {
        "x": 66.93,
        "y": 23.139
    },
    {
        "x": 66.94,
        "y": 23.139
    },
    {
        "x": 66.95,
        "y": 23.139
    },
    {
        "x": 66.96,
        "y": 23.139
    },
    {
        "x": 66.97,
        "y": 23.139
    },
    {
        "x": 66.98,
        "y": 23.139
    },
    {
        "x": 66.99,
        "y": 23.139
    },
    {
        "x": 67,
        "y": 23.139
    },
    {
        "x": 67.01,
        "y": 23.139
    },
    {
        "x": 67.02,
        "y": 23.139
    },
    {
        "x": 67.03,
        "y": 23.139
    },
    {
        "x": 67.04,
        "y": 23.139
    },
    {
        "x": 67.05,
        "y": 23.139
    },
    {
        "x": 67.06,
        "y": 23.139
    },
    {
        "x": 67.07,
        "y": 23.139
    },
    {
        "x": 67.08,
        "y": 23.139
    },
    {
        "x": 67.09,
        "y": 23.139
    },
    {
        "x": 67.1,
        "y": 23.139
    },
    {
        "x": 67.11,
        "y": 23.139
    },
    {
        "x": 67.12,
        "y": 23.139
    },
    {
        "x": 67.13,
        "y": 23.139
    },
    {
        "x": 67.14,
        "y": 23.139
    },
    {
        "x": 67.15,
        "y": 23.139
    },
    {
        "x": 67.16,
        "y": 23.139
    },
    {
        "x": 67.17,
        "y": 23.139
    },
    {
        "x": 67.18,
        "y": 23.139
    },
    {
        "x": 67.19,
        "y": 23.139
    },
    {
        "x": 67.2,
        "y": 23.13
    },
    {
        "x": 67.21,
        "y": 23.139
    },
    {
        "x": 67.22,
        "y": 23.139
    },
    {
        "x": 67.23,
        "y": 23.139
    },
    {
        "x": 67.24,
        "y": 23.139
    },
    {
        "x": 67.25,
        "y": 23.139
    },
    {
        "x": 67.26,
        "y": 23.139
    },
    {
        "x": 67.27,
        "y": 23.139
    },
    {
        "x": 67.28,
        "y": 23.13
    },
    {
        "x": 67.29,
        "y": 23.139
    },
    {
        "x": 67.3,
        "y": 23.139
    },
    {
        "x": 67.31,
        "y": 23.139
    },
    {
        "x": 67.32,
        "y": 23.139
    },
    {
        "x": 67.33,
        "y": 23.139
    },
    {
        "x": 67.34,
        "y": 23.139
    },
    {
        "x": 67.35,
        "y": 23.13
    },
    {
        "x": 67.36,
        "y": 23.139
    },
    {
        "x": 67.37,
        "y": 23.13
    },
    {
        "x": 67.38,
        "y": 23.13
    },
    {
        "x": 67.39,
        "y": 23.139
    },
    {
        "x": 67.4,
        "y": 23.13
    },
    {
        "x": 67.41,
        "y": 23.139
    },
    {
        "x": 67.42,
        "y": 23.139
    },
    {
        "x": 67.43,
        "y": 23.139
    },
    {
        "x": 67.44,
        "y": 23.139
    },
    {
        "x": 67.45,
        "y": 23.139
    },
    {
        "x": 67.46,
        "y": 23.13
    },
    {
        "x": 67.47,
        "y": 23.139
    },
    {
        "x": 67.48,
        "y": 23.139
    },
    {
        "x": 67.49,
        "y": 23.139
    },
    {
        "x": 67.5,
        "y": 23.139
    },
    {
        "x": 67.51,
        "y": 23.139
    },
    {
        "x": 67.52,
        "y": 23.13
    },
    {
        "x": 67.53,
        "y": 23.13
    },
    {
        "x": 67.54,
        "y": 23.139
    },
    {
        "x": 67.55,
        "y": 23.13
    },
    {
        "x": 67.56,
        "y": 23.139
    },
    {
        "x": 67.57,
        "y": 23.139
    },
    {
        "x": 67.58,
        "y": 23.139
    },
    {
        "x": 67.59,
        "y": 23.139
    },
    {
        "x": 67.6,
        "y": 23.13
    },
    {
        "x": 67.61,
        "y": 23.13
    },
    {
        "x": 67.62,
        "y": 23.139
    },
    {
        "x": 67.63,
        "y": 23.13
    },
    {
        "x": 67.64,
        "y": 23.139
    },
    {
        "x": 67.65,
        "y": 23.139
    },
    {
        "x": 67.66,
        "y": 23.139
    },
    {
        "x": 67.67,
        "y": 23.13
    },
    {
        "x": 67.68,
        "y": 23.139
    },
    {
        "x": 67.69,
        "y": 23.139
    },
    {
        "x": 67.7,
        "y": 23.13
    },
    {
        "x": 67.71,
        "y": 23.139
    },
    {
        "x": 67.72,
        "y": 23.139
    },
    {
        "x": 67.73,
        "y": 23.139
    },
    {
        "x": 67.74,
        "y": 23.139
    },
    {
        "x": 67.75,
        "y": 23.13
    },
    {
        "x": 67.76,
        "y": 23.13
    },
    {
        "x": 67.77,
        "y": 23.139
    },
    {
        "x": 67.78,
        "y": 23.139
    },
    {
        "x": 67.79,
        "y": 23.139
    },
    {
        "x": 67.8,
        "y": 23.13
    },
    {
        "x": 67.81,
        "y": 23.139
    },
    {
        "x": 67.82,
        "y": 23.139
    },
    {
        "x": 67.83,
        "y": 23.13
    },
    {
        "x": 67.84,
        "y": 23.139
    },
    {
        "x": 67.85,
        "y": 23.13
    },
    {
        "x": 67.86,
        "y": 23.13
    },
    {
        "x": 67.87,
        "y": 23.139
    },
    {
        "x": 67.88,
        "y": 23.13
    },
    {
        "x": 67.89,
        "y": 23.13
    },
    {
        "x": 67.9,
        "y": 23.13
    },
    {
        "x": 67.91,
        "y": 23.13
    },
    {
        "x": 67.92,
        "y": 23.13
    },
    {
        "x": 67.93,
        "y": 23.13
    },
    {
        "x": 67.94,
        "y": 23.13
    },
    {
        "x": 67.95,
        "y": 23.13
    },
    {
        "x": 67.96,
        "y": 23.13
    },
    {
        "x": 67.97,
        "y": 23.13
    },
    {
        "x": 67.98,
        "y": 23.13
    },
    {
        "x": 67.99,
        "y": 23.13
    },
    {
        "x": 68,
        "y": 23.13
    },
    {
        "x": 68.01,
        "y": 23.13
    },
    {
        "x": 68.02,
        "y": 23.13
    },
    {
        "x": 68.03,
        "y": 23.13
    },
    {
        "x": 68.04,
        "y": 23.13
    },
    {
        "x": 68.05,
        "y": 23.13
    },
    {
        "x": 68.06,
        "y": 23.13
    },
    {
        "x": 68.07,
        "y": 23.13
    },
    {
        "x": 68.08,
        "y": 23.13
    },
    {
        "x": 68.09,
        "y": 23.13
    },
    {
        "x": 68.1,
        "y": 23.13
    },
    {
        "x": 68.11,
        "y": 23.13
    },
    {
        "x": 68.12,
        "y": 23.13
    },
    {
        "x": 68.13,
        "y": 23.13
    },
    {
        "x": 68.14,
        "y": 23.13
    },
    {
        "x": 68.15,
        "y": 23.13
    },
    {
        "x": 68.16,
        "y": 23.13
    },
    {
        "x": 68.17,
        "y": 23.139
    },
    {
        "x": 68.18,
        "y": 23.13
    },
    {
        "x": 68.19,
        "y": 23.13
    },
    {
        "x": 68.2,
        "y": 23.13
    },
    {
        "x": 68.21,
        "y": 23.13
    },
    {
        "x": 68.22,
        "y": 23.13
    },
    {
        "x": 68.23,
        "y": 23.13
    },
    {
        "x": 68.24,
        "y": 23.13
    },
    {
        "x": 68.25,
        "y": 23.13
    },
    {
        "x": 68.26,
        "y": 23.13
    },
    {
        "x": 68.27,
        "y": 23.13
    },
    {
        "x": 68.28,
        "y": 23.13
    },
    {
        "x": 68.29,
        "y": 23.13
    },
    {
        "x": 68.3,
        "y": 23.13
    },
    {
        "x": 68.31,
        "y": 23.13
    },
    {
        "x": 68.32,
        "y": 23.13
    },
    {
        "x": 68.33,
        "y": 23.13
    },
    {
        "x": 68.34,
        "y": 23.13
    },
    {
        "x": 68.35,
        "y": 23.13
    },
    {
        "x": 68.36,
        "y": 23.13
    },
    {
        "x": 68.37,
        "y": 23.13
    },
    {
        "x": 68.38,
        "y": 23.13
    },
    {
        "x": 68.39,
        "y": 23.13
    },
    {
        "x": 68.4,
        "y": 23.13
    },
    {
        "x": 68.41,
        "y": 23.13
    },
    {
        "x": 68.42,
        "y": 23.13
    },
    {
        "x": 68.43,
        "y": 23.13
    },
    {
        "x": 68.44,
        "y": 23.13
    },
    {
        "x": 68.45,
        "y": 23.13
    },
    {
        "x": 68.46,
        "y": 23.13
    },
    {
        "x": 68.47,
        "y": 23.13
    },
    {
        "x": 68.48,
        "y": 23.13
    },
    {
        "x": 68.49,
        "y": 23.13
    },
    {
        "x": 68.5,
        "y": 23.13
    },
    {
        "x": 68.51,
        "y": 23.13
    },
    {
        "x": 68.52,
        "y": 23.13
    },
    {
        "x": 68.53,
        "y": 23.13
    },
    {
        "x": 68.54,
        "y": 23.13
    },
    {
        "x": 68.55,
        "y": 23.13
    },
    {
        "x": 68.56,
        "y": 23.13
    },
    {
        "x": 68.57,
        "y": 23.13
    },
    {
        "x": 68.58,
        "y": 23.13
    },
    {
        "x": 68.59,
        "y": 23.13
    },
    {
        "x": 68.6,
        "y": 23.13
    },
    {
        "x": 68.61,
        "y": 23.13
    },
    {
        "x": 68.62,
        "y": 23.13
    },
    {
        "x": 68.63,
        "y": 23.13
    },
    {
        "x": 68.64,
        "y": 23.13
    },
    {
        "x": 68.65,
        "y": 23.13
    },
    {
        "x": 68.66,
        "y": 23.13
    },
    {
        "x": 68.67,
        "y": 23.13
    },
    {
        "x": 68.68,
        "y": 23.13
    },
    {
        "x": 68.69,
        "y": 23.13
    },
    {
        "x": 68.7,
        "y": 23.13
    },
    {
        "x": 68.71,
        "y": 23.13
    },
    {
        "x": 68.72,
        "y": 23.13
    },
    {
        "x": 68.73,
        "y": 23.13
    },
    {
        "x": 68.74,
        "y": 23.13
    },
    {
        "x": 68.75,
        "y": 23.13
    },
    {
        "x": 68.76,
        "y": 23.13
    },
    {
        "x": 68.77,
        "y": 23.13
    },
    {
        "x": 68.78,
        "y": 23.13
    },
    {
        "x": 68.79,
        "y": 23.13
    },
    {
        "x": 68.8,
        "y": 23.121
    },
    {
        "x": 68.81,
        "y": 23.13
    },
    {
        "x": 68.82,
        "y": 23.13
    },
    {
        "x": 68.83,
        "y": 23.13
    },
    {
        "x": 68.84,
        "y": 23.13
    },
    {
        "x": 68.85,
        "y": 23.13
    },
    {
        "x": 68.86,
        "y": 23.13
    },
    {
        "x": 68.87,
        "y": 23.13
    },
    {
        "x": 68.88,
        "y": 23.13
    },
    {
        "x": 68.89,
        "y": 23.13
    },
    {
        "x": 68.9,
        "y": 23.13
    },
    {
        "x": 68.91,
        "y": 23.13
    },
    {
        "x": 68.92,
        "y": 23.13
    },
    {
        "x": 68.93,
        "y": 23.13
    },
    {
        "x": 68.94,
        "y": 23.13
    },
    {
        "x": 68.95,
        "y": 23.13
    },
    {
        "x": 68.96,
        "y": 23.13
    },
    {
        "x": 68.97,
        "y": 23.13
    },
    {
        "x": 68.98,
        "y": 23.13
    },
    {
        "x": 68.99,
        "y": 23.121
    },
    {
        "x": 69,
        "y": 23.13
    },
    {
        "x": 69.01,
        "y": 23.13
    },
    {
        "x": 69.02,
        "y": 23.13
    },
    {
        "x": 69.03,
        "y": 23.13
    },
    {
        "x": 69.04,
        "y": 23.13
    },
    {
        "x": 69.05,
        "y": 23.13
    },
    {
        "x": 69.06,
        "y": 23.13
    },
    {
        "x": 69.07,
        "y": 23.13
    },
    {
        "x": 69.08,
        "y": 23.13
    },
    {
        "x": 69.09,
        "y": 23.121
    },
    {
        "x": 69.1,
        "y": 23.13
    },
    {
        "x": 69.11,
        "y": 23.13
    },
    {
        "x": 69.12,
        "y": 23.13
    },
    {
        "x": 69.13,
        "y": 23.13
    },
    {
        "x": 69.14,
        "y": 23.13
    },
    {
        "x": 69.15,
        "y": 23.13
    },
    {
        "x": 69.16,
        "y": 23.13
    },
    {
        "x": 69.17,
        "y": 23.13
    },
    {
        "x": 69.18,
        "y": 23.121
    },
    {
        "x": 69.19,
        "y": 23.13
    },
    {
        "x": 69.2,
        "y": 23.13
    },
    {
        "x": 69.21,
        "y": 23.13
    },
    {
        "x": 69.22,
        "y": 23.13
    },
    {
        "x": 69.23,
        "y": 23.13
    },
    {
        "x": 69.24,
        "y": 23.13
    },
    {
        "x": 69.25,
        "y": 23.13
    },
    {
        "x": 69.26,
        "y": 23.13
    },
    {
        "x": 69.27,
        "y": 23.13
    },
    {
        "x": 69.28,
        "y": 23.13
    },
    {
        "x": 69.29,
        "y": 23.121
    },
    {
        "x": 69.3,
        "y": 23.13
    },
    {
        "x": 69.31,
        "y": 23.121
    },
    {
        "x": 69.32,
        "y": 23.121
    },
    {
        "x": 69.33,
        "y": 23.121
    },
    {
        "x": 69.34,
        "y": 23.13
    },
    {
        "x": 69.35,
        "y": 23.13
    },
    {
        "x": 69.36,
        "y": 23.121
    },
    {
        "x": 69.37,
        "y": 23.13
    },
    {
        "x": 69.38,
        "y": 23.13
    },
    {
        "x": 69.39,
        "y": 23.13
    },
    {
        "x": 69.4,
        "y": 23.13
    },
    {
        "x": 69.41,
        "y": 23.13
    },
    {
        "x": 69.42,
        "y": 23.121
    },
    {
        "x": 69.43,
        "y": 23.121
    },
    {
        "x": 69.44,
        "y": 23.13
    },
    {
        "x": 69.45,
        "y": 23.121
    },
    {
        "x": 69.46,
        "y": 23.13
    },
    {
        "x": 69.47,
        "y": 23.121
    },
    {
        "x": 69.48,
        "y": 23.121
    },
    {
        "x": 69.49,
        "y": 23.121
    },
    {
        "x": 69.5,
        "y": 23.121
    },
    {
        "x": 69.51,
        "y": 23.121
    },
    {
        "x": 69.52,
        "y": 23.121
    },
    {
        "x": 69.53,
        "y": 23.121
    },
    {
        "x": 69.54,
        "y": 23.121
    },
    {
        "x": 69.55,
        "y": 23.121
    },
    {
        "x": 69.56,
        "y": 23.121
    },
    {
        "x": 69.57,
        "y": 23.121
    },
    {
        "x": 69.58,
        "y": 23.121
    },
    {
        "x": 69.59,
        "y": 23.121
    },
    {
        "x": 69.6,
        "y": 23.121
    },
    {
        "x": 69.61,
        "y": 23.121
    },
    {
        "x": 69.62,
        "y": 23.13
    },
    {
        "x": 69.63,
        "y": 23.121
    },
    {
        "x": 69.64,
        "y": 23.121
    },
    {
        "x": 69.65,
        "y": 23.121
    },
    {
        "x": 69.66,
        "y": 23.121
    },
    {
        "x": 69.67,
        "y": 23.121
    },
    {
        "x": 69.68,
        "y": 23.121
    },
    {
        "x": 69.69,
        "y": 23.13
    },
    {
        "x": 69.7,
        "y": 23.121
    },
    {
        "x": 69.71,
        "y": 23.121
    },
    {
        "x": 69.72,
        "y": 23.121
    },
    {
        "x": 69.73,
        "y": 23.121
    },
    {
        "x": 69.74,
        "y": 23.121
    },
    {
        "x": 69.75,
        "y": 23.121
    },
    {
        "x": 69.76,
        "y": 23.121
    },
    {
        "x": 69.77,
        "y": 23.121
    },
    {
        "x": 69.78,
        "y": 23.121
    },
    {
        "x": 69.79,
        "y": 23.121
    },
    {
        "x": 69.8,
        "y": 23.121
    },
    {
        "x": 69.81,
        "y": 23.121
    },
    {
        "x": 69.82,
        "y": 23.121
    },
    {
        "x": 69.83,
        "y": 23.121
    },
    {
        "x": 69.84,
        "y": 23.121
    },
    {
        "x": 69.85,
        "y": 23.121
    },
    {
        "x": 69.86,
        "y": 23.121
    },
    {
        "x": 69.87,
        "y": 23.121
    },
    {
        "x": 69.88,
        "y": 23.121
    },
    {
        "x": 69.89,
        "y": 23.121
    },
    {
        "x": 69.9,
        "y": 23.121
    },
    {
        "x": 69.91,
        "y": 23.121
    },
    {
        "x": 69.92,
        "y": 23.121
    },
    {
        "x": 69.93,
        "y": 23.121
    },
    {
        "x": 69.94,
        "y": 23.121
    },
    {
        "x": 69.95,
        "y": 23.121
    },
    {
        "x": 69.96,
        "y": 23.121
    },
    {
        "x": 69.97,
        "y": 23.121
    },
    {
        "x": 69.98,
        "y": 23.121
    },
    {
        "x": 69.99,
        "y": 23.121
    },
    {
        "x": 70,
        "y": 23.13
    },
    {
        "x": 70.01,
        "y": 23.121
    },
    {
        "x": 70.02,
        "y": 23.13
    },
    {
        "x": 70.03,
        "y": 23.13
    },
    {
        "x": 70.04,
        "y": 23.13
    },
    {
        "x": 70.05,
        "y": 23.13
    },
    {
        "x": 70.06,
        "y": 23.13
    },
    {
        "x": 70.07,
        "y": 23.13
    },
    {
        "x": 70.08,
        "y": 23.13
    },
    {
        "x": 70.09,
        "y": 23.13
    },
    {
        "x": 70.1,
        "y": 23.13
    },
    {
        "x": 70.11,
        "y": 23.13
    },
    {
        "x": 70.12,
        "y": 23.121
    },
    {
        "x": 70.13,
        "y": 23.13
    },
    {
        "x": 70.14,
        "y": 23.13
    },
    {
        "x": 70.15,
        "y": 23.13
    },
    {
        "x": 70.16,
        "y": 23.13
    },
    {
        "x": 70.17,
        "y": 23.13
    },
    {
        "x": 70.18,
        "y": 23.121
    },
    {
        "x": 70.19,
        "y": 23.13
    },
    {
        "x": 70.2,
        "y": 23.121
    },
    {
        "x": 70.21,
        "y": 23.121
    },
    {
        "x": 70.22,
        "y": 23.121
    },
    {
        "x": 70.23,
        "y": 23.121
    },
    {
        "x": 70.24,
        "y": 23.13
    },
    {
        "x": 70.25,
        "y": 23.121
    },
    {
        "x": 70.26,
        "y": 23.121
    },
    {
        "x": 70.27,
        "y": 23.121
    },
    {
        "x": 70.28,
        "y": 23.121
    },
    {
        "x": 70.29,
        "y": 23.121
    },
    {
        "x": 70.3,
        "y": 23.121
    },
    {
        "x": 70.31,
        "y": 23.121
    },
    {
        "x": 70.32,
        "y": 23.121
    },
    {
        "x": 70.33,
        "y": 23.121
    }
];

export const toqueDataWithAILine2 = [
    {
        "x": 0.02,
        "y": 4.96
    },
    {
        "x": 0.03,
        "y": 4.94
    },
    {
        "x": 0.04,
        "y": 4.81
    },
    {
        "x": 0.05,
        "y": 4.55
    },
    {
        "x": 0.06,
        "y": 4.22
    },
    {
        "x": 0.07,
        "y": 3.92
    },
    {
        "x": 0.08,
        "y": 3.65
    },
    {
        "x": 0.09,
        "y": 3.44
    },
    {
        "x": 0.1,
        "y": 3.26
    },
    {
        "x": 0.11,
        "y": 3.11
    },
    {
        "x": 0.12,
        "y": 2.99
    },
    {
        "x": 0.13,
        "y": 2.89
    },
    {
        "x": 0.14,
        "y": 2.81
    },
    {
        "x": 0.15,
        "y": 2.74
    },
    {
        "x": 0.16,
        "y": 2.68
    },
    {
        "x": 0.17,
        "y": 2.63
    },
    {
        "x": 0.18,
        "y": 2.59
    },
    {
        "x": 0.19,
        "y": 2.54
    },
    {
        "x": 0.2,
        "y": 2.51
    },
    {
        "x": 0.21,
        "y": 2.48
    },
    {
        "x": 0.22,
        "y": 2.44
    },
    {
        "x": 0.23,
        "y": 2.42
    },
    {
        "x": 0.24,
        "y": 2.4
    },
    {
        "x": 0.25,
        "y": 2.38
    },
    {
        "x": 0.26,
        "y": 2.35
    },
    {
        "x": 0.27,
        "y": 2.34
    },
    {
        "x": 0.28,
        "y": 2.32
    },
    {
        "x": 0.29,
        "y": 2.31
    },
    {
        "x": 0.3,
        "y": 2.3
    },
    {
        "x": 0.31,
        "y": 2.29
    },
    {
        "x": 0.32,
        "y": 2.28
    },
    {
        "x": 0.33,
        "y": 2.27
    },
    {
        "x": 0.34,
        "y": 2.26
    },
    {
        "x": 0.35,
        "y": 2.24
    },
    {
        "x": 0.36,
        "y": 2.24
    },
    {
        "x": 0.37,
        "y": 2.23
    },
    {
        "x": 0.38,
        "y": 2.22
    },
    {
        "x": 0.39,
        "y": 2.22
    },
    {
        "x": 0.4,
        "y": 2.22
    },
    {
        "x": 0.41,
        "y": 2.21
    },
    {
        "x": 0.42,
        "y": 2.21
    },
    {
        "x": 0.43,
        "y": 2.21
    },
    {
        "x": 0.44,
        "y": 2.2
    },
    {
        "x": 0.45,
        "y": 2.2
    },
    {
        "x": 0.46,
        "y": 2.2
    },
    {
        "x": 0.47,
        "y": 2.2
    },
    {
        "x": 0.48,
        "y": 2.19
    },
    {
        "x": 0.49,
        "y": 2.19
    },
    {
        "x": 0.5,
        "y": 2.19
    },
    {
        "x": 0.51,
        "y": 2.19
    },
    {
        "x": 0.52,
        "y": 2.19
    },
    {
        "x": 0.53,
        "y": 2.19
    },
    {
        "x": 0.54,
        "y": 2.19
    },
    {
        "x": 0.55,
        "y": 2.18
    },
    {
        "x": 0.56,
        "y": 2.19
    },
    {
        "x": 0.57,
        "y": 2.19
    },
    {
        "x": 0.58,
        "y": 2.19
    },
    {
        "x": 0.59,
        "y": 2.19
    },
    {
        "x": 0.6,
        "y": 2.19
    },
    {
        "x": 0.61,
        "y": 2.19
    },
    {
        "x": 0.62,
        "y": 2.19
    },
    {
        "x": 0.63,
        "y": 2.19
    },
    {
        "x": 0.64,
        "y": 2.19
    },
    {
        "x": 0.65,
        "y": 2.19
    },
    {
        "x": 0.66,
        "y": 2.19
    },
    {
        "x": 0.67,
        "y": 2.19
    },
    {
        "x": 0.68,
        "y": 2.19
    },
    {
        "x": 0.69,
        "y": 2.2
    },
    {
        "x": 0.7,
        "y": 2.19
    },
    {
        "x": 0.71,
        "y": 2.2
    },
    {
        "x": 0.72,
        "y": 2.19
    },
    {
        "x": 0.73,
        "y": 2.19
    },
    {
        "x": 0.74,
        "y": 2.19
    },
    {
        "x": 0.75,
        "y": 2.2
    },
    {
        "x": 0.76,
        "y": 2.2
    },
    {
        "x": 0.77,
        "y": 2.2
    },
    {
        "x": 0.78,
        "y": 2.2
    },
    {
        "x": 0.79,
        "y": 2.2
    },
    {
        "x": 0.8,
        "y": 2.2
    },
    {
        "x": 0.81,
        "y": 2.2
    },
    {
        "x": 0.82,
        "y": 2.2
    },
    {
        "x": 0.83,
        "y": 2.2
    },
    {
        "x": 0.84,
        "y": 2.2
    },
    {
        "x": 0.85,
        "y": 2.2
    },
    {
        "x": 0.86,
        "y": 2.2
    },
    {
        "x": 0.87,
        "y": 2.2
    },
    {
        "x": 0.88,
        "y": 2.2
    },
    {
        "x": 0.89,
        "y": 2.2
    },
    {
        "x": 0.9,
        "y": 2.2
    },
    {
        "x": 0.91,
        "y": 2.2
    },
    {
        "x": 0.92,
        "y": 2.2
    },
    {
        "x": 0.93,
        "y": 2.2
    },
    {
        "x": 0.94,
        "y": 2.2
    },
    {
        "x": 0.95,
        "y": 2.2
    },
    {
        "x": 0.96,
        "y": 2.2
    },
    {
        "x": 0.97,
        "y": 2.2
    },
    {
        "x": 0.98,
        "y": 2.2
    },
    {
        "x": 0.99,
        "y": 2.2
    },
    {
        "x": 1,
        "y": 2.2
    },
    {
        "x": 1.01,
        "y": 2.2
    },
    {
        "x": 1.02,
        "y": 2.2
    },
    {
        "x": 1.03,
        "y": 2.2
    },
    {
        "x": 1.04,
        "y": 2.2
    },
    {
        "x": 1.05,
        "y": 2.2
    },
    {
        "x": 1.06,
        "y": 2.2
    },
    {
        "x": 1.07,
        "y": 2.21
    },
    {
        "x": 1.08,
        "y": 2.2
    },
    {
        "x": 1.09,
        "y": 2.21
    },
    {
        "x": 1.1,
        "y": 2.21
    },
    {
        "x": 1.11,
        "y": 2.2
    },
    {
        "x": 1.12,
        "y": 2.2
    },
    {
        "x": 1.13,
        "y": 2.21
    },
    {
        "x": 1.14,
        "y": 2.21
    },
    {
        "x": 1.15,
        "y": 2.21
    },
    {
        "x": 1.16,
        "y": 2.21
    },
    {
        "x": 1.17,
        "y": 2.21
    },
    {
        "x": 1.18,
        "y": 2.21
    },
    {
        "x": 1.19,
        "y": 2.21
    },
    {
        "x": 1.2,
        "y": 2.21
    },
    {
        "x": 1.21,
        "y": 2.21
    },
    {
        "x": 1.22,
        "y": 2.21
    },
    {
        "x": 1.23,
        "y": 2.21
    },
    {
        "x": 1.24,
        "y": 2.21
    },
    {
        "x": 1.25,
        "y": 2.21
    },
    {
        "x": 1.26,
        "y": 2.21
    },
    {
        "x": 1.27,
        "y": 2.21
    },
    {
        "x": 1.28,
        "y": 2.21
    },
    {
        "x": 1.29,
        "y": 2.21
    },
    {
        "x": 1.3,
        "y": 2.21
    },
    {
        "x": 1.31,
        "y": 2.21
    },
    {
        "x": 1.32,
        "y": 2.21
    },
    {
        "x": 1.33,
        "y": 2.21
    },
    {
        "x": 1.34,
        "y": 2.21
    },
    {
        "x": 1.35,
        "y": 2.21
    },
    {
        "x": 1.36,
        "y": 2.21
    },
    {
        "x": 1.37,
        "y": 2.21
    },
    {
        "x": 1.38,
        "y": 2.21
    },
    {
        "x": 1.39,
        "y": 2.21
    },
    {
        "x": 1.4,
        "y": 2.22
    },
    {
        "x": 1.41,
        "y": 2.21
    },
    {
        "x": 1.42,
        "y": 2.21
    },
    {
        "x": 1.43,
        "y": 2.22
    },
    {
        "x": 1.44,
        "y": 2.21
    },
    {
        "x": 1.45,
        "y": 2.22
    },
    {
        "x": 1.46,
        "y": 2.22
    },
    {
        "x": 1.47,
        "y": 2.22
    },
    {
        "x": 1.48,
        "y": 2.22
    },
    {
        "x": 1.49,
        "y": 2.22
    },
    {
        "x": 1.5,
        "y": 2.22
    },
    {
        "x": 1.51,
        "y": 2.21
    },
    {
        "x": 1.52,
        "y": 2.22
    },
    {
        "x": 1.53,
        "y": 2.22
    },
    {
        "x": 1.54,
        "y": 2.22
    },
    {
        "x": 1.55,
        "y": 2.21
    },
    {
        "x": 1.56,
        "y": 2.22
    },
    {
        "x": 1.57,
        "y": 2.22
    },
    {
        "x": 1.58,
        "y": 2.22
    },
    {
        "x": 1.59,
        "y": 2.22
    },
    {
        "x": 1.6,
        "y": 2.22
    },
    {
        "x": 1.61,
        "y": 2.22
    },
    {
        "x": 1.62,
        "y": 2.22
    },
    {
        "x": 1.63,
        "y": 2.22
    },
    {
        "x": 1.64,
        "y": 2.22
    },
    {
        "x": 1.65,
        "y": 2.22
    },
    {
        "x": 1.66,
        "y": 2.22
    },
    {
        "x": 1.67,
        "y": 2.22
    },
    {
        "x": 1.68,
        "y": 2.22
    },
    {
        "x": 1.69,
        "y": 2.22
    },
    {
        "x": 1.7,
        "y": 2.22
    },
    {
        "x": 1.71,
        "y": 2.22
    },
    {
        "x": 1.72,
        "y": 2.23
    },
    {
        "x": 1.73,
        "y": 2.22
    },
    {
        "x": 1.74,
        "y": 2.22
    },
    {
        "x": 1.75,
        "y": 2.22
    },
    {
        "x": 1.76,
        "y": 2.23
    },
    {
        "x": 1.77,
        "y": 2.23
    },
    {
        "x": 1.78,
        "y": 2.23
    },
    {
        "x": 1.79,
        "y": 2.23
    },
    {
        "x": 1.8,
        "y": 2.22
    },
    {
        "x": 1.81,
        "y": 2.23
    },
    {
        "x": 1.82,
        "y": 2.23
    },
    {
        "x": 1.83,
        "y": 2.23
    },
    {
        "x": 1.84,
        "y": 2.23
    },
    {
        "x": 1.85,
        "y": 2.23
    },
    {
        "x": 1.86,
        "y": 2.23
    },
    {
        "x": 1.87,
        "y": 2.23
    },
    {
        "x": 1.88,
        "y": 2.23
    },
    {
        "x": 1.89,
        "y": 2.23
    },
    {
        "x": 1.9,
        "y": 2.23
    },
    {
        "x": 1.91,
        "y": 2.23
    },
    {
        "x": 1.92,
        "y": 2.23
    },
    {
        "x": 1.93,
        "y": 2.23
    },
    {
        "x": 1.94,
        "y": 2.23
    },
    {
        "x": 1.95,
        "y": 2.23
    },
    {
        "x": 1.96,
        "y": 2.23
    },
    {
        "x": 1.97,
        "y": 2.24
    },
    {
        "x": 1.98,
        "y": 2.24
    },
    {
        "x": 1.99,
        "y": 2.24
    },
    {
        "x": 2,
        "y": 2.24
    },
    {
        "x": 2.01,
        "y": 2.24
    },
    {
        "x": 2.02,
        "y": 2.24
    },
    {
        "x": 2.03,
        "y": 2.24
    },
    {
        "x": 2.04,
        "y": 2.24
    },
    {
        "x": 2.05,
        "y": 2.24
    },
    {
        "x": 2.06,
        "y": 2.24
    },
    {
        "x": 2.07,
        "y": 2.24
    },
    {
        "x": 2.08,
        "y": 2.24
    },
    {
        "x": 2.09,
        "y": 2.24
    },
    {
        "x": 2.1,
        "y": 2.24
    },
    {
        "x": 2.11,
        "y": 2.24
    },
    {
        "x": 2.12,
        "y": 2.24
    },
    {
        "x": 2.13,
        "y": 2.26
    },
    {
        "x": 2.14,
        "y": 2.26
    },
    {
        "x": 2.15,
        "y": 2.26
    },
    {
        "x": 2.16,
        "y": 2.24
    },
    {
        "x": 2.17,
        "y": 2.26
    },
    {
        "x": 2.18,
        "y": 2.26
    },
    {
        "x": 2.19,
        "y": 2.26
    },
    {
        "x": 2.2,
        "y": 2.26
    },
    {
        "x": 2.21,
        "y": 2.26
    },
    {
        "x": 2.22,
        "y": 2.26
    },
    {
        "x": 2.23,
        "y": 2.26
    },
    {
        "x": 2.24,
        "y": 2.26
    },
    {
        "x": 2.25,
        "y": 2.26
    },
    {
        "x": 2.26,
        "y": 2.26
    },
    {
        "x": 2.27,
        "y": 2.26
    },
    {
        "x": 2.28,
        "y": 2.26
    },
    {
        "x": 2.29,
        "y": 2.27
    },
    {
        "x": 2.3,
        "y": 2.26
    },
    {
        "x": 2.31,
        "y": 2.26
    },
    {
        "x": 2.32,
        "y": 2.26
    },
    {
        "x": 2.33,
        "y": 2.27
    },
    {
        "x": 2.34,
        "y": 2.27
    },
    {
        "x": 2.35,
        "y": 2.27
    },
    {
        "x": 2.36,
        "y": 2.27
    },
    {
        "x": 2.37,
        "y": 2.27
    },
    {
        "x": 2.38,
        "y": 2.27
    },
    {
        "x": 2.39,
        "y": 2.27
    },
    {
        "x": 2.4,
        "y": 2.27
    },
    {
        "x": 2.41,
        "y": 2.27
    },
    {
        "x": 2.42,
        "y": 2.27
    },
    {
        "x": 2.43,
        "y": 2.27
    },
    {
        "x": 2.44,
        "y": 2.28
    },
    {
        "x": 2.45,
        "y": 2.28
    },
    {
        "x": 2.46,
        "y": 2.28
    },
    {
        "x": 2.47,
        "y": 2.28
    },
    {
        "x": 2.48,
        "y": 2.28
    },
    {
        "x": 2.49,
        "y": 2.28
    },
    {
        "x": 2.5,
        "y": 2.28
    },
    {
        "x": 2.51,
        "y": 2.28
    },
    {
        "x": 2.52,
        "y": 2.28
    },
    {
        "x": 2.53,
        "y": 2.28
    },
    {
        "x": 2.54,
        "y": 2.28
    },
    {
        "x": 2.55,
        "y": 2.28
    },
    {
        "x": 2.56,
        "y": 2.28
    },
    {
        "x": 2.57,
        "y": 2.29
    },
    {
        "x": 2.58,
        "y": 2.29
    },
    {
        "x": 2.59,
        "y": 2.29
    },
    {
        "x": 2.6,
        "y": 2.29
    },
    {
        "x": 2.61,
        "y": 2.29
    },
    {
        "x": 2.62,
        "y": 2.29
    },
    {
        "x": 2.63,
        "y": 2.29
    },
    {
        "x": 2.64,
        "y": 2.29
    },
    {
        "x": 2.65,
        "y": 2.29
    },
    {
        "x": 2.66,
        "y": 2.29
    },
    {
        "x": 2.67,
        "y": 2.3
    },
    {
        "x": 2.68,
        "y": 2.29
    },
    {
        "x": 2.69,
        "y": 2.3
    },
    {
        "x": 2.7,
        "y": 2.3
    },
    {
        "x": 2.71,
        "y": 2.3
    },
    {
        "x": 2.72,
        "y": 2.3
    },
    {
        "x": 2.73,
        "y": 2.3
    },
    {
        "x": 2.74,
        "y": 2.3
    },
    {
        "x": 2.75,
        "y": 2.3
    },
    {
        "x": 2.76,
        "y": 2.3
    },
    {
        "x": 2.77,
        "y": 2.3
    },
    {
        "x": 2.78,
        "y": 2.3
    },
    {
        "x": 2.79,
        "y": 2.31
    },
    {
        "x": 2.8,
        "y": 2.31
    },
    {
        "x": 2.81,
        "y": 2.31
    },
    {
        "x": 2.82,
        "y": 2.31
    },
    {
        "x": 2.83,
        "y": 2.31
    },
    {
        "x": 2.84,
        "y": 2.31
    },
    {
        "x": 2.85,
        "y": 2.32
    },
    {
        "x": 2.86,
        "y": 2.31
    },
    {
        "x": 2.87,
        "y": 2.32
    },
    {
        "x": 2.88,
        "y": 2.32
    },
    {
        "x": 2.89,
        "y": 2.32
    },
    {
        "x": 2.9,
        "y": 2.32
    },
    {
        "x": 2.91,
        "y": 2.32
    },
    {
        "x": 2.92,
        "y": 2.32
    },
    {
        "x": 2.93,
        "y": 2.32
    },
    {
        "x": 2.94,
        "y": 2.32
    },
    {
        "x": 2.95,
        "y": 2.32
    },
    {
        "x": 2.96,
        "y": 2.33
    },
    {
        "x": 2.97,
        "y": 2.33
    },
    {
        "x": 2.98,
        "y": 2.33
    },
    {
        "x": 2.99,
        "y": 2.33
    },
    {
        "x": 3,
        "y": 2.33
    },
    {
        "x": 3.01,
        "y": 2.33
    },
    {
        "x": 3.02,
        "y": 2.34
    },
    {
        "x": 3.03,
        "y": 2.34
    },
    {
        "x": 3.04,
        "y": 2.34
    },
    {
        "x": 3.05,
        "y": 2.34
    },
    {
        "x": 3.06,
        "y": 2.34
    },
    {
        "x": 3.07,
        "y": 2.34
    },
    {
        "x": 3.08,
        "y": 2.34
    },
    {
        "x": 3.09,
        "y": 2.34
    },
    {
        "x": 3.1,
        "y": 2.34
    },
    {
        "x": 3.11,
        "y": 2.35
    },
    {
        "x": 3.12,
        "y": 2.35
    },
    {
        "x": 3.13,
        "y": 2.35
    },
    {
        "x": 3.14,
        "y": 2.35
    },
    {
        "x": 3.15,
        "y": 2.35
    },
    {
        "x": 3.16,
        "y": 2.35
    },
    {
        "x": 3.17,
        "y": 2.35
    },
    {
        "x": 3.18,
        "y": 2.37
    },
    {
        "x": 3.19,
        "y": 2.37
    },
    {
        "x": 3.2,
        "y": 2.37
    },
    {
        "x": 3.21,
        "y": 2.37
    },
    {
        "x": 3.22,
        "y": 2.37
    },
    {
        "x": 3.23,
        "y": 2.37
    },
    {
        "x": 3.24,
        "y": 2.38
    },
    {
        "x": 3.25,
        "y": 2.38
    },
    {
        "x": 3.26,
        "y": 2.38
    },
    {
        "x": 3.27,
        "y": 2.38
    },
    {
        "x": 3.28,
        "y": 2.38
    },
    {
        "x": 3.29,
        "y": 2.38
    },
    {
        "x": 3.3,
        "y": 2.38
    },
    {
        "x": 3.31,
        "y": 2.38
    },
    {
        "x": 3.32,
        "y": 2.39
    },
    {
        "x": 3.33,
        "y": 2.39
    },
    {
        "x": 3.34,
        "y": 2.39
    },
    {
        "x": 3.35,
        "y": 2.39
    },
    {
        "x": 3.36,
        "y": 2.39
    },
    {
        "x": 3.37,
        "y": 2.39
    },
    {
        "x": 3.38,
        "y": 2.4
    },
    {
        "x": 3.39,
        "y": 2.4
    },
    {
        "x": 3.4,
        "y": 2.39
    },
    {
        "x": 3.41,
        "y": 2.4
    },
    {
        "x": 3.42,
        "y": 2.4
    },
    {
        "x": 3.43,
        "y": 2.4
    },
    {
        "x": 3.44,
        "y": 2.41
    },
    {
        "x": 3.45,
        "y": 2.41
    },
    {
        "x": 3.46,
        "y": 2.41
    },
    {
        "x": 3.47,
        "y": 2.41
    },
    {
        "x": 3.48,
        "y": 2.41
    },
    {
        "x": 3.49,
        "y": 2.41
    },
    {
        "x": 3.5,
        "y": 2.42
    },
    {
        "x": 3.51,
        "y": 2.42
    },
    {
        "x": 3.52,
        "y": 2.42
    },
    {
        "x": 3.53,
        "y": 2.42
    },
    {
        "x": 3.54,
        "y": 2.42
    },
    {
        "x": 3.55,
        "y": 2.43
    },
    {
        "x": 3.56,
        "y": 2.43
    },
    {
        "x": 3.57,
        "y": 2.43
    },
    {
        "x": 3.58,
        "y": 2.43
    },
    {
        "x": 3.59,
        "y": 2.43
    },
    {
        "x": 3.6,
        "y": 2.43
    },
    {
        "x": 3.61,
        "y": 2.43
    },
    {
        "x": 3.62,
        "y": 2.44
    },
    {
        "x": 3.63,
        "y": 2.44
    },
    {
        "x": 3.64,
        "y": 2.44
    },
    {
        "x": 3.65,
        "y": 2.44
    },
    {
        "x": 3.66,
        "y": 2.44
    },
    {
        "x": 3.67,
        "y": 2.45
    },
    {
        "x": 3.68,
        "y": 2.45
    },
    {
        "x": 3.69,
        "y": 2.45
    },
    {
        "x": 3.7,
        "y": 2.45
    },
    {
        "x": 3.71,
        "y": 2.45
    },
    {
        "x": 3.72,
        "y": 2.46
    },
    {
        "x": 3.73,
        "y": 2.46
    },
    {
        "x": 3.74,
        "y": 2.46
    },
    {
        "x": 3.75,
        "y": 2.48
    },
    {
        "x": 3.76,
        "y": 2.48
    },
    {
        "x": 3.77,
        "y": 2.48
    },
    {
        "x": 3.78,
        "y": 2.48
    },
    {
        "x": 3.79,
        "y": 2.48
    },
    {
        "x": 3.8,
        "y": 2.49
    },
    {
        "x": 3.81,
        "y": 2.49
    },
    {
        "x": 3.82,
        "y": 2.49
    },
    {
        "x": 3.83,
        "y": 2.49
    },
    {
        "x": 3.84,
        "y": 2.5
    },
    {
        "x": 3.85,
        "y": 2.5
    },
    {
        "x": 3.86,
        "y": 2.5
    },
    {
        "x": 3.87,
        "y": 2.51
    },
    {
        "x": 3.88,
        "y": 2.51
    },
    {
        "x": 3.89,
        "y": 2.51
    },
    {
        "x": 3.9,
        "y": 2.51
    },
    {
        "x": 3.91,
        "y": 2.51
    },
    {
        "x": 3.92,
        "y": 2.52
    },
    {
        "x": 3.93,
        "y": 2.52
    },
    {
        "x": 3.94,
        "y": 2.52
    },
    {
        "x": 3.95,
        "y": 2.53
    },
    {
        "x": 3.96,
        "y": 2.53
    },
    {
        "x": 3.97,
        "y": 2.53
    },
    {
        "x": 3.98,
        "y": 2.53
    },
    {
        "x": 3.99,
        "y": 2.54
    },
    {
        "x": 4,
        "y": 2.54
    },
    {
        "x": 4.01,
        "y": 2.54
    },
    {
        "x": 4.02,
        "y": 2.54
    },
    {
        "x": 4.03,
        "y": 2.55
    },
    {
        "x": 4.04,
        "y": 2.55
    },
    {
        "x": 4.05,
        "y": 2.55
    },
    {
        "x": 4.06,
        "y": 2.55
    },
    {
        "x": 4.07,
        "y": 2.56
    },
    {
        "x": 4.08,
        "y": 2.56
    },
    {
        "x": 4.09,
        "y": 2.56
    },
    {
        "x": 4.1,
        "y": 2.56
    },
    {
        "x": 4.11,
        "y": 2.57
    },
    {
        "x": 4.12,
        "y": 2.57
    },
    {
        "x": 4.13,
        "y": 2.57
    },
    {
        "x": 4.14,
        "y": 2.57
    },
    {
        "x": 4.15,
        "y": 2.59
    },
    {
        "x": 4.16,
        "y": 2.59
    },
    {
        "x": 4.17,
        "y": 2.59
    },
    {
        "x": 4.18,
        "y": 2.6
    },
    {
        "x": 4.19,
        "y": 2.6
    },
    {
        "x": 4.2,
        "y": 2.6
    },
    {
        "x": 4.21,
        "y": 2.61
    },
    {
        "x": 4.22,
        "y": 2.61
    },
    {
        "x": 4.23,
        "y": 2.62
    },
    {
        "x": 4.24,
        "y": 2.62
    },
    {
        "x": 4.25,
        "y": 2.62
    },
    {
        "x": 4.26,
        "y": 2.62
    },
    {
        "x": 4.27,
        "y": 2.62
    },
    {
        "x": 4.28,
        "y": 2.63
    },
    {
        "x": 4.29,
        "y": 2.63
    },
    {
        "x": 4.3,
        "y": 2.64
    },
    {
        "x": 4.31,
        "y": 2.64
    },
    {
        "x": 4.32,
        "y": 2.64
    },
    {
        "x": 4.33,
        "y": 2.65
    },
    {
        "x": 4.34,
        "y": 2.65
    },
    {
        "x": 4.35,
        "y": 2.65
    },
    {
        "x": 4.36,
        "y": 2.65
    },
    {
        "x": 4.37,
        "y": 2.66
    },
    {
        "x": 4.38,
        "y": 2.66
    },
    {
        "x": 4.39,
        "y": 2.66
    },
    {
        "x": 4.4,
        "y": 2.67
    },
    {
        "x": 4.41,
        "y": 2.67
    },
    {
        "x": 4.42,
        "y": 2.67
    },
    {
        "x": 4.43,
        "y": 2.68
    },
    {
        "x": 4.44,
        "y": 2.68
    },
    {
        "x": 4.45,
        "y": 2.68
    },
    {
        "x": 4.46,
        "y": 2.7
    },
    {
        "x": 4.47,
        "y": 2.7
    },
    {
        "x": 4.48,
        "y": 2.71
    },
    {
        "x": 4.49,
        "y": 2.71
    },
    {
        "x": 4.5,
        "y": 2.71
    },
    {
        "x": 4.51,
        "y": 2.72
    },
    {
        "x": 4.52,
        "y": 2.72
    },
    {
        "x": 4.53,
        "y": 2.73
    },
    {
        "x": 4.54,
        "y": 2.73
    },
    {
        "x": 4.55,
        "y": 2.73
    },
    {
        "x": 4.56,
        "y": 2.73
    },
    {
        "x": 4.57,
        "y": 2.74
    },
    {
        "x": 4.58,
        "y": 2.74
    },
    {
        "x": 4.59,
        "y": 2.75
    },
    {
        "x": 4.6,
        "y": 2.75
    },
    {
        "x": 4.61,
        "y": 2.75
    },
    {
        "x": 4.62,
        "y": 2.76
    },
    {
        "x": 4.63,
        "y": 2.76
    },
    {
        "x": 4.64,
        "y": 2.76
    },
    {
        "x": 4.65,
        "y": 2.77
    },
    {
        "x": 4.66,
        "y": 2.78
    },
    {
        "x": 4.67,
        "y": 2.78
    },
    {
        "x": 4.68,
        "y": 2.78
    },
    {
        "x": 4.69,
        "y": 2.79
    },
    {
        "x": 4.7,
        "y": 2.81
    },
    {
        "x": 4.71,
        "y": 2.81
    },
    {
        "x": 4.72,
        "y": 2.81
    },
    {
        "x": 4.73,
        "y": 2.82
    },
    {
        "x": 4.74,
        "y": 2.82
    },
    {
        "x": 4.75,
        "y": 2.82
    },
    {
        "x": 4.76,
        "y": 2.83
    },
    {
        "x": 4.77,
        "y": 2.83
    },
    {
        "x": 4.78,
        "y": 2.84
    },
    {
        "x": 4.79,
        "y": 2.84
    },
    {
        "x": 4.8,
        "y": 2.84
    },
    {
        "x": 4.81,
        "y": 2.85
    },
    {
        "x": 4.82,
        "y": 2.86
    },
    {
        "x": 4.83,
        "y": 2.86
    },
    {
        "x": 4.84,
        "y": 2.87
    },
    {
        "x": 4.85,
        "y": 2.87
    },
    {
        "x": 4.86,
        "y": 2.87
    },
    {
        "x": 4.87,
        "y": 2.88
    },
    {
        "x": 4.88,
        "y": 2.88
    },
    {
        "x": 4.89,
        "y": 2.89
    },
    {
        "x": 4.9,
        "y": 2.9
    },
    {
        "x": 4.91,
        "y": 2.9
    },
    {
        "x": 4.92,
        "y": 2.9
    },
    {
        "x": 4.93,
        "y": 2.92
    },
    {
        "x": 4.94,
        "y": 2.93
    },
    {
        "x": 4.95,
        "y": 2.93
    },
    {
        "x": 4.96,
        "y": 2.94
    },
    {
        "x": 4.97,
        "y": 2.94
    },
    {
        "x": 4.98,
        "y": 2.94
    },
    {
        "x": 4.99,
        "y": 2.95
    },
    {
        "x": 5,
        "y": 2.96
    },
    {
        "x": 5.01,
        "y": 2.96
    },
    {
        "x": 5.02,
        "y": 2.97
    },
    {
        "x": 5.03,
        "y": 2.97
    },
    {
        "x": 5.04,
        "y": 2.98
    },
    {
        "x": 5.05,
        "y": 2.98
    },
    {
        "x": 5.06,
        "y": 2.99
    },
    {
        "x": 5.07,
        "y": 3
    },
    {
        "x": 5.08,
        "y": 3
    },
    {
        "x": 5.09,
        "y": 3.01
    },
    {
        "x": 5.1,
        "y": 3.01
    },
    {
        "x": 5.11,
        "y": 3.03
    },
    {
        "x": 5.12,
        "y": 3.03
    },
    {
        "x": 5.13,
        "y": 3.04
    },
    {
        "x": 5.14,
        "y": 3.04
    },
    {
        "x": 5.15,
        "y": 3.05
    },
    {
        "x": 5.16,
        "y": 3.05
    },
    {
        "x": 5.17,
        "y": 3.06
    },
    {
        "x": 5.18,
        "y": 3.07
    },
    {
        "x": 5.19,
        "y": 3.08
    },
    {
        "x": 5.2,
        "y": 3.08
    },
    {
        "x": 5.21,
        "y": 3.09
    },
    {
        "x": 5.22,
        "y": 3.09
    },
    {
        "x": 5.23,
        "y": 3.1
    },
    {
        "x": 5.24,
        "y": 3.1
    },
    {
        "x": 5.25,
        "y": 3.11
    },
    {
        "x": 5.26,
        "y": 3.12
    },
    {
        "x": 5.27,
        "y": 3.12
    },
    {
        "x": 5.28,
        "y": 3.14
    },
    {
        "x": 5.29,
        "y": 3.15
    },
    {
        "x": 5.3,
        "y": 3.15
    },
    {
        "x": 5.31,
        "y": 3.16
    },
    {
        "x": 5.32,
        "y": 3.17
    },
    {
        "x": 5.33,
        "y": 3.17
    },
    {
        "x": 5.34,
        "y": 3.18
    },
    {
        "x": 5.35,
        "y": 3.19
    },
    {
        "x": 5.36,
        "y": 3.19
    },
    {
        "x": 5.37,
        "y": 3.2
    },
    {
        "x": 5.38,
        "y": 3.2
    },
    {
        "x": 5.39,
        "y": 3.21
    },
    {
        "x": 5.4,
        "y": 3.22
    },
    {
        "x": 5.41,
        "y": 3.23
    },
    {
        "x": 5.42,
        "y": 3.25
    },
    {
        "x": 5.43,
        "y": 3.25
    },
    {
        "x": 5.44,
        "y": 3.26
    },
    {
        "x": 5.45,
        "y": 3.27
    },
    {
        "x": 5.46,
        "y": 3.27
    },
    {
        "x": 5.47,
        "y": 3.28
    },
    {
        "x": 5.48,
        "y": 3.29
    },
    {
        "x": 5.49,
        "y": 3.3
    },
    {
        "x": 5.5,
        "y": 3.3
    },
    {
        "x": 5.51,
        "y": 3.31
    },
    {
        "x": 5.52,
        "y": 3.32
    },
    {
        "x": 5.53,
        "y": 3.33
    },
    {
        "x": 5.54,
        "y": 3.33
    },
    {
        "x": 5.55,
        "y": 3.34
    },
    {
        "x": 5.56,
        "y": 3.36
    },
    {
        "x": 5.57,
        "y": 3.37
    },
    {
        "x": 5.58,
        "y": 3.37
    },
    {
        "x": 5.59,
        "y": 3.38
    },
    {
        "x": 5.6,
        "y": 3.39
    },
    {
        "x": 5.61,
        "y": 3.4
    },
    {
        "x": 5.62,
        "y": 3.4
    },
    {
        "x": 5.63,
        "y": 3.41
    },
    {
        "x": 5.64,
        "y": 3.42
    },
    {
        "x": 5.65,
        "y": 3.43
    },
    {
        "x": 5.66,
        "y": 3.44
    },
    {
        "x": 5.67,
        "y": 3.44
    },
    {
        "x": 5.68,
        "y": 3.45
    },
    {
        "x": 5.69,
        "y": 3.47
    },
    {
        "x": 5.7,
        "y": 3.48
    },
    {
        "x": 5.71,
        "y": 3.49
    },
    {
        "x": 5.72,
        "y": 3.5
    },
    {
        "x": 5.73,
        "y": 3.51
    },
    {
        "x": 5.74,
        "y": 3.52
    },
    {
        "x": 5.75,
        "y": 3.52
    },
    {
        "x": 5.76,
        "y": 3.53
    },
    {
        "x": 5.77,
        "y": 3.54
    },
    {
        "x": 5.78,
        "y": 3.55
    },
    {
        "x": 5.79,
        "y": 3.56
    },
    {
        "x": 5.8,
        "y": 3.58
    },
    {
        "x": 5.81,
        "y": 3.59
    },
    {
        "x": 5.82,
        "y": 3.6
    },
    {
        "x": 5.83,
        "y": 3.61
    },
    {
        "x": 5.84,
        "y": 3.61
    },
    {
        "x": 5.85,
        "y": 3.63
    },
    {
        "x": 5.86,
        "y": 3.63
    },
    {
        "x": 5.87,
        "y": 3.64
    },
    {
        "x": 5.88,
        "y": 3.65
    },
    {
        "x": 5.89,
        "y": 3.66
    },
    {
        "x": 5.9,
        "y": 3.67
    },
    {
        "x": 5.91,
        "y": 3.69
    },
    {
        "x": 5.92,
        "y": 3.7
    },
    {
        "x": 5.93,
        "y": 3.71
    },
    {
        "x": 5.94,
        "y": 3.72
    },
    {
        "x": 5.95,
        "y": 3.73
    },
    {
        "x": 5.96,
        "y": 3.74
    },
    {
        "x": 5.97,
        "y": 3.75
    },
    {
        "x": 5.98,
        "y": 3.76
    },
    {
        "x": 5.99,
        "y": 3.77
    },
    {
        "x": 6,
        "y": 3.78
    },
    {
        "x": 6.01,
        "y": 3.8
    },
    {
        "x": 6.02,
        "y": 3.81
    },
    {
        "x": 6.03,
        "y": 3.82
    },
    {
        "x": 6.04,
        "y": 3.83
    },
    {
        "x": 6.05,
        "y": 3.84
    },
    {
        "x": 6.06,
        "y": 3.85
    },
    {
        "x": 6.07,
        "y": 3.86
    },
    {
        "x": 6.08,
        "y": 3.87
    },
    {
        "x": 6.09,
        "y": 3.89
    },
    {
        "x": 6.1,
        "y": 3.91
    },
    {
        "x": 6.11,
        "y": 3.91
    },
    {
        "x": 6.12,
        "y": 3.93
    },
    {
        "x": 6.13,
        "y": 3.94
    },
    {
        "x": 6.14,
        "y": 3.95
    },
    {
        "x": 6.15,
        "y": 3.96
    },
    {
        "x": 6.16,
        "y": 3.97
    },
    {
        "x": 6.17,
        "y": 3.98
    },
    {
        "x": 6.18,
        "y": 4
    },
    {
        "x": 6.19,
        "y": 4.02
    },
    {
        "x": 6.2,
        "y": 4.03
    },
    {
        "x": 6.21,
        "y": 4.04
    },
    {
        "x": 6.22,
        "y": 4.05
    },
    {
        "x": 6.23,
        "y": 4.06
    },
    {
        "x": 6.24,
        "y": 4.07
    },
    {
        "x": 6.25,
        "y": 4.09
    },
    {
        "x": 6.26,
        "y": 4.1
    },
    {
        "x": 6.27,
        "y": 4.11
    },
    {
        "x": 6.28,
        "y": 4.13
    },
    {
        "x": 6.29,
        "y": 4.15
    },
    {
        "x": 6.3,
        "y": 4.16
    },
    {
        "x": 6.31,
        "y": 4.17
    },
    {
        "x": 6.32,
        "y": 4.18
    },
    {
        "x": 6.33,
        "y": 4.19
    },
    {
        "x": 6.34,
        "y": 4.21
    },
    {
        "x": 6.35,
        "y": 4.22
    },
    {
        "x": 6.36,
        "y": 4.25
    },
    {
        "x": 6.37,
        "y": 4.26
    },
    {
        "x": 6.38,
        "y": 4.27
    },
    {
        "x": 6.39,
        "y": 4.28
    },
    {
        "x": 6.4,
        "y": 4.3
    },
    {
        "x": 6.41,
        "y": 4.31
    },
    {
        "x": 6.42,
        "y": 4.32
    },
    {
        "x": 6.43,
        "y": 4.35
    },
    {
        "x": 6.44,
        "y": 4.36
    },
    {
        "x": 6.45,
        "y": 4.37
    },
    {
        "x": 6.46,
        "y": 4.38
    },
    {
        "x": 6.47,
        "y": 4.4
    },
    {
        "x": 6.48,
        "y": 4.41
    },
    {
        "x": 6.49,
        "y": 4.42
    },
    {
        "x": 6.5,
        "y": 4.44
    },
    {
        "x": 6.51,
        "y": 4.46
    },
    {
        "x": 6.52,
        "y": 4.48
    },
    {
        "x": 6.53,
        "y": 4.49
    },
    {
        "x": 6.54,
        "y": 4.51
    },
    {
        "x": 6.55,
        "y": 4.52
    },
    {
        "x": 6.56,
        "y": 4.53
    },
    {
        "x": 6.57,
        "y": 4.55
    },
    {
        "x": 6.58,
        "y": 4.57
    },
    {
        "x": 6.59,
        "y": 4.58
    },
    {
        "x": 6.6,
        "y": 4.6
    },
    {
        "x": 6.61,
        "y": 4.61
    },
    {
        "x": 6.62,
        "y": 4.63
    },
    {
        "x": 6.63,
        "y": 4.65
    },
    {
        "x": 6.64,
        "y": 4.66
    },
    {
        "x": 6.65,
        "y": 4.68
    },
    {
        "x": 6.66,
        "y": 4.7
    },
    {
        "x": 6.67,
        "y": 4.71
    },
    {
        "x": 6.68,
        "y": 4.73
    },
    {
        "x": 6.69,
        "y": 4.74
    },
    {
        "x": 6.7,
        "y": 4.76
    },
    {
        "x": 6.71,
        "y": 4.79
    },
    {
        "x": 6.72,
        "y": 4.8
    },
    {
        "x": 6.73,
        "y": 4.82
    },
    {
        "x": 6.74,
        "y": 4.83
    },
    {
        "x": 6.75,
        "y": 4.85
    },
    {
        "x": 6.76,
        "y": 4.87
    },
    {
        "x": 6.77,
        "y": 4.88
    },
    {
        "x": 6.78,
        "y": 4.91
    },
    {
        "x": 6.79,
        "y": 4.92
    },
    {
        "x": 6.8,
        "y": 4.94
    },
    {
        "x": 6.81,
        "y": 4.96
    },
    {
        "x": 6.82,
        "y": 4.97
    },
    {
        "x": 6.83,
        "y": 4.99
    },
    {
        "x": 6.84,
        "y": 5.01
    },
    {
        "x": 6.85,
        "y": 5.03
    },
    {
        "x": 6.86,
        "y": 5.05
    },
    {
        "x": 6.87,
        "y": 5.06
    },
    {
        "x": 6.88,
        "y": 5.08
    },
    {
        "x": 6.89,
        "y": 5.1
    },
    {
        "x": 6.9,
        "y": 5.13
    },
    {
        "x": 6.91,
        "y": 5.14
    },
    {
        "x": 6.92,
        "y": 5.16
    },
    {
        "x": 6.93,
        "y": 5.18
    },
    {
        "x": 6.94,
        "y": 5.19
    },
    {
        "x": 6.95,
        "y": 5.21
    },
    {
        "x": 6.96,
        "y": 5.23
    },
    {
        "x": 6.97,
        "y": 5.25
    },
    {
        "x": 6.98,
        "y": 5.27
    },
    {
        "x": 6.99,
        "y": 5.29
    },
    {
        "x": 7,
        "y": 5.31
    },
    {
        "x": 7.01,
        "y": 5.34
    },
    {
        "x": 7.02,
        "y": 5.35
    },
    {
        "x": 7.03,
        "y": 5.37
    },
    {
        "x": 7.04,
        "y": 5.39
    },
    {
        "x": 7.05,
        "y": 5.41
    },
    {
        "x": 7.06,
        "y": 5.43
    },
    {
        "x": 7.07,
        "y": 5.46
    },
    {
        "x": 7.08,
        "y": 5.47
    },
    {
        "x": 7.09,
        "y": 5.49
    },
    {
        "x": 7.1,
        "y": 5.51
    },
    {
        "x": 7.11,
        "y": 5.53
    },
    {
        "x": 7.12,
        "y": 5.56
    },
    {
        "x": 7.13,
        "y": 5.58
    },
    {
        "x": 7.14,
        "y": 5.59
    },
    {
        "x": 7.15,
        "y": 5.61
    },
    {
        "x": 7.16,
        "y": 5.63
    },
    {
        "x": 7.17,
        "y": 5.65
    },
    {
        "x": 7.18,
        "y": 5.68
    },
    {
        "x": 7.19,
        "y": 5.7
    },
    {
        "x": 7.2,
        "y": 5.72
    },
    {
        "x": 7.21,
        "y": 5.74
    },
    {
        "x": 7.22,
        "y": 5.76
    },
    {
        "x": 7.23,
        "y": 5.79
    },
    {
        "x": 7.24,
        "y": 5.81
    },
    {
        "x": 7.25,
        "y": 5.83
    },
    {
        "x": 7.26,
        "y": 5.85
    },
    {
        "x": 7.27,
        "y": 5.87
    },
    {
        "x": 7.28,
        "y": 5.9
    },
    {
        "x": 7.29,
        "y": 5.92
    },
    {
        "x": 7.3,
        "y": 5.94
    },
    {
        "x": 7.31,
        "y": 5.96
    },
    {
        "x": 7.32,
        "y": 5.98
    },
    {
        "x": 7.33,
        "y": 6.01
    },
    {
        "x": 7.34,
        "y": 6.03
    },
    {
        "x": 7.35,
        "y": 6.05
    },
    {
        "x": 7.36,
        "y": 6.07
    },
    {
        "x": 7.37,
        "y": 6.11
    },
    {
        "x": 7.38,
        "y": 6.13
    },
    {
        "x": 7.39,
        "y": 6.15
    },
    {
        "x": 7.4,
        "y": 6.17
    },
    {
        "x": 7.41,
        "y": 6.19
    },
    {
        "x": 7.42,
        "y": 6.22
    },
    {
        "x": 7.43,
        "y": 6.24
    },
    {
        "x": 7.44,
        "y": 6.26
    },
    {
        "x": 7.45,
        "y": 6.28
    },
    {
        "x": 7.46,
        "y": 6.31
    },
    {
        "x": 7.47,
        "y": 6.34
    },
    {
        "x": 7.48,
        "y": 6.36
    },
    {
        "x": 7.49,
        "y": 6.39
    },
    {
        "x": 7.5,
        "y": 6.4
    },
    {
        "x": 7.51,
        "y": 6.44
    },
    {
        "x": 7.52,
        "y": 6.46
    },
    {
        "x": 7.53,
        "y": 6.49
    },
    {
        "x": 7.54,
        "y": 6.5
    },
    {
        "x": 7.55,
        "y": 6.53
    },
    {
        "x": 7.56,
        "y": 6.56
    },
    {
        "x": 7.57,
        "y": 6.58
    },
    {
        "x": 7.58,
        "y": 6.6
    },
    {
        "x": 7.59,
        "y": 6.63
    },
    {
        "x": 7.6,
        "y": 6.66
    },
    {
        "x": 7.61,
        "y": 6.68
    },
    {
        "x": 7.62,
        "y": 6.71
    },
    {
        "x": 7.63,
        "y": 6.73
    },
    {
        "x": 7.64,
        "y": 6.75
    },
    {
        "x": 7.65,
        "y": 6.78
    },
    {
        "x": 7.66,
        "y": 6.81
    },
    {
        "x": 7.67,
        "y": 6.83
    },
    {
        "x": 7.68,
        "y": 6.85
    },
    {
        "x": 7.69,
        "y": 6.89
    },
    {
        "x": 7.7,
        "y": 6.91
    },
    {
        "x": 7.71,
        "y": 6.94
    },
    {
        "x": 7.72,
        "y": 6.96
    },
    {
        "x": 7.73,
        "y": 6.99
    },
    {
        "x": 7.74,
        "y": 7.02
    },
    {
        "x": 7.75,
        "y": 7.04
    },
    {
        "x": 7.76,
        "y": 7.06
    },
    {
        "x": 7.77,
        "y": 7.1
    },
    {
        "x": 7.78,
        "y": 7.12
    },
    {
        "x": 7.79,
        "y": 7.15
    },
    {
        "x": 7.8,
        "y": 7.17
    },
    {
        "x": 7.81,
        "y": 7.21
    },
    {
        "x": 7.82,
        "y": 7.23
    },
    {
        "x": 7.83,
        "y": 7.26
    },
    {
        "x": 7.84,
        "y": 7.28
    },
    {
        "x": 7.85,
        "y": 7.3
    },
    {
        "x": 7.86,
        "y": 7.34
    },
    {
        "x": 7.87,
        "y": 7.37
    },
    {
        "x": 7.88,
        "y": 7.39
    },
    {
        "x": 7.89,
        "y": 7.41
    },
    {
        "x": 7.9,
        "y": 7.45
    },
    {
        "x": 7.91,
        "y": 7.47
    },
    {
        "x": 7.92,
        "y": 7.5
    },
    {
        "x": 7.93,
        "y": 7.52
    },
    {
        "x": 7.94,
        "y": 7.56
    },
    {
        "x": 7.95,
        "y": 7.58
    },
    {
        "x": 7.96,
        "y": 7.61
    },
    {
        "x": 7.97,
        "y": 7.65
    },
    {
        "x": 7.98,
        "y": 7.67
    },
    {
        "x": 7.99,
        "y": 7.7
    },
    {
        "x": 8,
        "y": 7.72
    },
    {
        "x": 8.01,
        "y": 7.76
    },
    {
        "x": 8.02,
        "y": 7.78
    },
    {
        "x": 8.03,
        "y": 7.81
    },
    {
        "x": 8.04,
        "y": 7.83
    },
    {
        "x": 8.05,
        "y": 7.87
    },
    {
        "x": 8.06,
        "y": 7.9
    },
    {
        "x": 8.07,
        "y": 7.92
    },
    {
        "x": 8.08,
        "y": 7.95
    },
    {
        "x": 8.09,
        "y": 7.99
    },
    {
        "x": 8.1,
        "y": 8.01
    },
    {
        "x": 8.11,
        "y": 8.04
    },
    {
        "x": 8.12,
        "y": 8.07
    },
    {
        "x": 8.13,
        "y": 8.1
    },
    {
        "x": 8.14,
        "y": 8.13
    },
    {
        "x": 8.15,
        "y": 8.16
    },
    {
        "x": 8.16,
        "y": 8.18
    },
    {
        "x": 8.17,
        "y": 8.22
    },
    {
        "x": 8.18,
        "y": 8.25
    },
    {
        "x": 8.19,
        "y": 8.27
    },
    {
        "x": 8.2,
        "y": 8.31
    },
    {
        "x": 8.21,
        "y": 8.34
    },
    {
        "x": 8.22,
        "y": 8.36
    },
    {
        "x": 8.23,
        "y": 8.39
    },
    {
        "x": 8.24,
        "y": 8.43
    },
    {
        "x": 8.25,
        "y": 8.46
    },
    {
        "x": 8.26,
        "y": 8.49
    },
    {
        "x": 8.27,
        "y": 8.51
    },
    {
        "x": 8.28,
        "y": 8.55
    },
    {
        "x": 8.29,
        "y": 8.57
    },
    {
        "x": 8.3,
        "y": 8.6
    },
    {
        "x": 8.31,
        "y": 8.64
    },
    {
        "x": 8.32,
        "y": 8.67
    },
    {
        "x": 8.33,
        "y": 8.7
    },
    {
        "x": 8.34,
        "y": 8.72
    },
    {
        "x": 8.35,
        "y": 8.76
    },
    {
        "x": 8.36,
        "y": 8.79
    },
    {
        "x": 8.37,
        "y": 8.82
    },
    {
        "x": 8.38,
        "y": 8.84
    },
    {
        "x": 8.39,
        "y": 8.88
    },
    {
        "x": 8.4,
        "y": 8.91
    },
    {
        "x": 8.41,
        "y": 8.94
    },
    {
        "x": 8.42,
        "y": 8.98
    },
    {
        "x": 8.43,
        "y": 9.01
    },
    {
        "x": 8.44,
        "y": 9.03
    },
    {
        "x": 8.45,
        "y": 9.06
    },
    {
        "x": 8.46,
        "y": 9.1
    },
    {
        "x": 8.47,
        "y": 9.13
    },
    {
        "x": 8.48,
        "y": 9.16
    },
    {
        "x": 8.49,
        "y": 9.2
    },
    {
        "x": 8.5,
        "y": 9.23
    },
    {
        "x": 8.51,
        "y": 9.25
    },
    {
        "x": 8.52,
        "y": 9.3
    },
    {
        "x": 8.53,
        "y": 9.32
    },
    {
        "x": 8.54,
        "y": 9.35
    },
    {
        "x": 8.55,
        "y": 9.38
    },
    {
        "x": 8.56,
        "y": 9.42
    },
    {
        "x": 8.57,
        "y": 9.45
    },
    {
        "x": 8.58,
        "y": 9.48
    },
    {
        "x": 8.59,
        "y": 9.52
    },
    {
        "x": 8.6,
        "y": 9.55
    },
    {
        "x": 8.61,
        "y": 9.57
    },
    {
        "x": 8.62,
        "y": 9.61
    },
    {
        "x": 8.63,
        "y": 9.64
    },
    {
        "x": 8.64,
        "y": 9.67
    },
    {
        "x": 8.65,
        "y": 9.71
    },
    {
        "x": 8.66,
        "y": 9.74
    },
    {
        "x": 8.67,
        "y": 9.78
    },
    {
        "x": 8.68,
        "y": 9.8
    },
    {
        "x": 8.69,
        "y": 9.83
    },
    {
        "x": 8.7,
        "y": 9.87
    },
    {
        "x": 8.71,
        "y": 9.9
    },
    {
        "x": 8.72,
        "y": 9.93
    },
    {
        "x": 8.73,
        "y": 9.97
    },
    {
        "x": 8.74,
        "y": 10
    },
    {
        "x": 8.75,
        "y": 10.03
    },
    {
        "x": 8.76,
        "y": 10.07
    },
    {
        "x": 8.77,
        "y": 10.1
    },
    {
        "x": 8.78,
        "y": 10.13
    },
    {
        "x": 8.79,
        "y": 10.16
    },
    {
        "x": 8.8,
        "y": 10.2
    },
    {
        "x": 8.81,
        "y": 10.23
    },
    {
        "x": 8.82,
        "y": 10.26
    },
    {
        "x": 8.83,
        "y": 10.3
    },
    {
        "x": 8.84,
        "y": 10.33
    },
    {
        "x": 8.85,
        "y": 10.36
    },
    {
        "x": 8.86,
        "y": 10.4
    },
    {
        "x": 8.87,
        "y": 10.43
    },
    {
        "x": 8.88,
        "y": 10.46
    },
    {
        "x": 8.89,
        "y": 10.49
    },
    {
        "x": 8.9,
        "y": 10.53
    },
    {
        "x": 8.91,
        "y": 10.56
    },
    {
        "x": 8.92,
        "y": 10.59
    },
    {
        "x": 8.93,
        "y": 10.63
    },
    {
        "x": 8.94,
        "y": 10.66
    },
    {
        "x": 8.95,
        "y": 10.7
    },
    {
        "x": 8.96,
        "y": 10.74
    },
    {
        "x": 8.97,
        "y": 10.77
    },
    {
        "x": 8.98,
        "y": 10.8
    },
    {
        "x": 8.99,
        "y": 10.84
    },
    {
        "x": 9,
        "y": 10.87
    },
    {
        "x": 9.01,
        "y": 10.9
    },
    {
        "x": 9.02,
        "y": 10.93
    },
    {
        "x": 9.03,
        "y": 10.97
    },
    {
        "x": 9.04,
        "y": 11
    },
    {
        "x": 9.05,
        "y": 11.03
    },
    {
        "x": 9.06,
        "y": 11.07
    },
    {
        "x": 9.07,
        "y": 11.11
    },
    {
        "x": 9.08,
        "y": 11.13
    },
    {
        "x": 9.09,
        "y": 11.18
    },
    {
        "x": 9.1,
        "y": 11.21
    },
    {
        "x": 9.11,
        "y": 11.24
    },
    {
        "x": 9.12,
        "y": 11.28
    },
    {
        "x": 9.13,
        "y": 11.31
    },
    {
        "x": 9.14,
        "y": 11.34
    },
    {
        "x": 9.15,
        "y": 11.37
    },
    {
        "x": 9.16,
        "y": 11.41
    },
    {
        "x": 9.17,
        "y": 11.44
    },
    {
        "x": 9.18,
        "y": 11.47
    },
    {
        "x": 9.19,
        "y": 11.51
    },
    {
        "x": 9.2,
        "y": 11.54
    },
    {
        "x": 9.21,
        "y": 11.57
    },
    {
        "x": 9.22,
        "y": 11.61
    },
    {
        "x": 9.23,
        "y": 11.64
    },
    {
        "x": 9.24,
        "y": 11.68
    },
    {
        "x": 9.25,
        "y": 11.72
    },
    {
        "x": 9.26,
        "y": 11.75
    },
    {
        "x": 9.27,
        "y": 11.78
    },
    {
        "x": 9.28,
        "y": 11.81
    },
    {
        "x": 9.29,
        "y": 11.85
    },
    {
        "x": 9.3,
        "y": 11.88
    },
    {
        "x": 9.31,
        "y": 11.91
    },
    {
        "x": 9.32,
        "y": 11.95
    },
    {
        "x": 9.33,
        "y": 11.98
    },
    {
        "x": 9.34,
        "y": 12.01
    },
    {
        "x": 9.35,
        "y": 12.05
    },
    {
        "x": 9.36,
        "y": 12.08
    },
    {
        "x": 9.37,
        "y": 12.12
    },
    {
        "x": 9.38,
        "y": 12.16
    },
    {
        "x": 9.39,
        "y": 12.19
    },
    {
        "x": 9.4,
        "y": 12.22
    },
    {
        "x": 9.41,
        "y": 12.25
    },
    {
        "x": 9.42,
        "y": 12.29
    },
    {
        "x": 9.43,
        "y": 12.32
    },
    {
        "x": 9.44,
        "y": 12.35
    },
    {
        "x": 9.45,
        "y": 12.39
    },
    {
        "x": 9.46,
        "y": 12.42
    },
    {
        "x": 9.47,
        "y": 12.45
    },
    {
        "x": 9.48,
        "y": 12.49
    },
    {
        "x": 9.49,
        "y": 12.52
    },
    {
        "x": 9.5,
        "y": 12.55
    },
    {
        "x": 9.51,
        "y": 12.6
    },
    {
        "x": 9.52,
        "y": 12.63
    },
    {
        "x": 9.53,
        "y": 12.65
    },
    {
        "x": 9.54,
        "y": 12.68
    },
    {
        "x": 9.55,
        "y": 12.72
    },
    {
        "x": 9.56,
        "y": 12.76
    },
    {
        "x": 9.57,
        "y": 12.78
    },
    {
        "x": 9.58,
        "y": 12.83
    },
    {
        "x": 9.59,
        "y": 12.86
    },
    {
        "x": 9.6,
        "y": 12.89
    },
    {
        "x": 9.61,
        "y": 12.93
    },
    {
        "x": 9.62,
        "y": 12.96
    },
    {
        "x": 9.63,
        "y": 12.99
    },
    {
        "x": 9.64,
        "y": 13.02
    },
    {
        "x": 9.65,
        "y": 13.06
    },
    {
        "x": 9.66,
        "y": 13.09
    },
    {
        "x": 9.67,
        "y": 13.12
    },
    {
        "x": 9.68,
        "y": 13.16
    },
    {
        "x": 9.69,
        "y": 13.19
    },
    {
        "x": 9.7,
        "y": 13.22
    },
    {
        "x": 9.71,
        "y": 13.26
    },
    {
        "x": 9.72,
        "y": 13.29
    },
    {
        "x": 9.73,
        "y": 13.32
    },
    {
        "x": 9.74,
        "y": 13.35
    },
    {
        "x": 9.75,
        "y": 13.39
    },
    {
        "x": 9.76,
        "y": 13.42
    },
    {
        "x": 9.77,
        "y": 13.45
    },
    {
        "x": 9.78,
        "y": 13.49
    },
    {
        "x": 9.79,
        "y": 13.52
    },
    {
        "x": 9.8,
        "y": 13.55
    },
    {
        "x": 9.81,
        "y": 13.59
    },
    {
        "x": 9.82,
        "y": 13.62
    },
    {
        "x": 9.83,
        "y": 13.65
    },
    {
        "x": 9.84,
        "y": 13.68
    },
    {
        "x": 9.85,
        "y": 13.71
    },
    {
        "x": 9.86,
        "y": 13.74
    },
    {
        "x": 9.87,
        "y": 13.77
    },
    {
        "x": 9.88,
        "y": 13.81
    },
    {
        "x": 9.89,
        "y": 13.84
    },
    {
        "x": 9.9,
        "y": 13.87
    },
    {
        "x": 9.91,
        "y": 13.9
    },
    {
        "x": 9.92,
        "y": 13.94
    },
    {
        "x": 9.93,
        "y": 13.97
    },
    {
        "x": 9.94,
        "y": 14
    },
    {
        "x": 9.95,
        "y": 14.04
    },
    {
        "x": 9.96,
        "y": 14.06
    },
    {
        "x": 9.97,
        "y": 14.1
    },
    {
        "x": 9.98,
        "y": 14.12
    },
    {
        "x": 9.99,
        "y": 14.16
    },
    {
        "x": 10,
        "y": 14.19
    },
    {
        "x": 10.01,
        "y": 14.22
    },
    {
        "x": 10.02,
        "y": 14.26
    },
    {
        "x": 10.03,
        "y": 14.29
    },
    {
        "x": 10.04,
        "y": 14.31
    },
    {
        "x": 10.05,
        "y": 14.34
    },
    {
        "x": 10.06,
        "y": 14.38
    },
    {
        "x": 10.07,
        "y": 14.41
    },
    {
        "x": 10.08,
        "y": 14.44
    },
    {
        "x": 10.09,
        "y": 14.48
    },
    {
        "x": 10.1,
        "y": 14.5
    },
    {
        "x": 10.11,
        "y": 14.53
    },
    {
        "x": 10.12,
        "y": 14.56
    },
    {
        "x": 10.13,
        "y": 14.6
    },
    {
        "x": 10.14,
        "y": 14.63
    },
    {
        "x": 10.15,
        "y": 14.65
    },
    {
        "x": 10.16,
        "y": 14.69
    },
    {
        "x": 10.17,
        "y": 14.72
    },
    {
        "x": 10.18,
        "y": 14.75
    },
    {
        "x": 10.19,
        "y": 14.78
    },
    {
        "x": 10.2,
        "y": 14.81
    },
    {
        "x": 10.21,
        "y": 14.84
    },
    {
        "x": 10.22,
        "y": 14.87
    },
    {
        "x": 10.23,
        "y": 14.91
    },
    {
        "x": 10.24,
        "y": 14.93
    },
    {
        "x": 10.25,
        "y": 14.96
    },
    {
        "x": 10.26,
        "y": 14.99
    },
    {
        "x": 10.27,
        "y": 15.02
    },
    {
        "x": 10.28,
        "y": 15.05
    },
    {
        "x": 10.29,
        "y": 15.08
    },
    {
        "x": 10.3,
        "y": 15.11
    },
    {
        "x": 10.31,
        "y": 15.14
    },
    {
        "x": 10.32,
        "y": 15.17
    },
    {
        "x": 10.33,
        "y": 15.2
    },
    {
        "x": 10.34,
        "y": 15.22
    },
    {
        "x": 10.35,
        "y": 15.26
    },
    {
        "x": 10.36,
        "y": 15.29
    },
    {
        "x": 10.37,
        "y": 15.32
    },
    {
        "x": 10.38,
        "y": 15.35
    },
    {
        "x": 10.39,
        "y": 15.38
    },
    {
        "x": 10.4,
        "y": 15.4
    },
    {
        "x": 10.41,
        "y": 15.43
    },
    {
        "x": 10.42,
        "y": 15.47
    },
    {
        "x": 10.43,
        "y": 15.49
    },
    {
        "x": 10.44,
        "y": 15.52
    },
    {
        "x": 10.45,
        "y": 15.55
    },
    {
        "x": 10.46,
        "y": 15.58
    },
    {
        "x": 10.47,
        "y": 15.61
    },
    {
        "x": 10.48,
        "y": 15.63
    },
    {
        "x": 10.49,
        "y": 15.66
    },
    {
        "x": 10.5,
        "y": 15.7
    },
    {
        "x": 10.51,
        "y": 15.72
    },
    {
        "x": 10.52,
        "y": 15.75
    },
    {
        "x": 10.53,
        "y": 15.77
    },
    {
        "x": 10.54,
        "y": 15.81
    },
    {
        "x": 10.55,
        "y": 15.83
    },
    {
        "x": 10.56,
        "y": 15.86
    },
    {
        "x": 10.57,
        "y": 15.9
    },
    {
        "x": 10.58,
        "y": 15.92
    },
    {
        "x": 10.59,
        "y": 15.95
    },
    {
        "x": 10.6,
        "y": 15.97
    },
    {
        "x": 10.61,
        "y": 15.99
    },
    {
        "x": 10.62,
        "y": 16.03
    },
    {
        "x": 10.63,
        "y": 16.05
    },
    {
        "x": 10.64,
        "y": 16.08
    },
    {
        "x": 10.65,
        "y": 16.12
    },
    {
        "x": 10.66,
        "y": 16.14
    },
    {
        "x": 10.67,
        "y": 16.16
    },
    {
        "x": 10.68,
        "y": 16.19
    },
    {
        "x": 10.69,
        "y": 16.21
    },
    {
        "x": 10.7,
        "y": 16.25
    },
    {
        "x": 10.71,
        "y": 16.27
    },
    {
        "x": 10.72,
        "y": 16.3
    },
    {
        "x": 10.73,
        "y": 16.32
    },
    {
        "x": 10.74,
        "y": 16.35
    },
    {
        "x": 10.75,
        "y": 16.38
    },
    {
        "x": 10.76,
        "y": 16.4
    },
    {
        "x": 10.77,
        "y": 16.43
    },
    {
        "x": 10.78,
        "y": 16.46
    },
    {
        "x": 10.79,
        "y": 16.49
    },
    {
        "x": 10.8,
        "y": 16.51
    },
    {
        "x": 10.81,
        "y": 16.53
    },
    {
        "x": 10.82,
        "y": 16.57
    },
    {
        "x": 10.83,
        "y": 16.59
    },
    {
        "x": 10.84,
        "y": 16.61
    },
    {
        "x": 10.85,
        "y": 16.64
    },
    {
        "x": 10.86,
        "y": 16.67
    },
    {
        "x": 10.87,
        "y": 16.69
    },
    {
        "x": 10.88,
        "y": 16.72
    },
    {
        "x": 10.89,
        "y": 16.74
    },
    {
        "x": 10.9,
        "y": 16.78
    },
    {
        "x": 10.91,
        "y": 16.8
    },
    {
        "x": 10.92,
        "y": 16.82
    },
    {
        "x": 10.93,
        "y": 16.84
    },
    {
        "x": 10.94,
        "y": 16.86
    },
    {
        "x": 10.95,
        "y": 16.9
    },
    {
        "x": 10.96,
        "y": 16.92
    },
    {
        "x": 10.97,
        "y": 16.94
    },
    {
        "x": 10.98,
        "y": 16.96
    },
    {
        "x": 10.99,
        "y": 17
    },
    {
        "x": 11,
        "y": 17.02
    },
    {
        "x": 11.01,
        "y": 17.04
    },
    {
        "x": 11.02,
        "y": 17.06
    },
    {
        "x": 11.03,
        "y": 17.09
    },
    {
        "x": 11.04,
        "y": 17.12
    },
    {
        "x": 11.05,
        "y": 17.14
    },
    {
        "x": 11.06,
        "y": 17.16
    },
    {
        "x": 11.07,
        "y": 17.19
    },
    {
        "x": 11.08,
        "y": 17.22
    },
    {
        "x": 11.09,
        "y": 17.24
    },
    {
        "x": 11.1,
        "y": 17.26
    },
    {
        "x": 11.11,
        "y": 17.28
    },
    {
        "x": 11.12,
        "y": 17.31
    },
    {
        "x": 11.13,
        "y": 17.34
    },
    {
        "x": 11.14,
        "y": 17.36
    },
    {
        "x": 11.15,
        "y": 17.38
    },
    {
        "x": 11.16,
        "y": 17.4
    },
    {
        "x": 11.17,
        "y": 17.42
    },
    {
        "x": 11.18,
        "y": 17.45
    },
    {
        "x": 11.19,
        "y": 17.47
    },
    {
        "x": 11.2,
        "y": 17.5
    },
    {
        "x": 11.21,
        "y": 17.52
    },
    {
        "x": 11.22,
        "y": 17.55
    },
    {
        "x": 11.23,
        "y": 17.57
    },
    {
        "x": 11.24,
        "y": 17.59
    },
    {
        "x": 11.25,
        "y": 17.61
    },
    {
        "x": 11.26,
        "y": 17.63
    },
    {
        "x": 11.27,
        "y": 17.66
    },
    {
        "x": 11.28,
        "y": 17.69
    },
    {
        "x": 11.29,
        "y": 17.7
    },
    {
        "x": 11.3,
        "y": 17.73
    },
    {
        "x": 11.31,
        "y": 17.75
    },
    {
        "x": 11.32,
        "y": 17.78
    },
    {
        "x": 11.33,
        "y": 17.8
    },
    {
        "x": 11.34,
        "y": 17.82
    },
    {
        "x": 11.35,
        "y": 17.84
    },
    {
        "x": 11.36,
        "y": 17.86
    },
    {
        "x": 11.37,
        "y": 17.89
    },
    {
        "x": 11.38,
        "y": 17.91
    },
    {
        "x": 11.39,
        "y": 17.93
    },
    {
        "x": 11.4,
        "y": 17.95
    },
    {
        "x": 11.41,
        "y": 17.97
    },
    {
        "x": 11.42,
        "y": 18
    },
    {
        "x": 11.43,
        "y": 18.02
    },
    {
        "x": 11.44,
        "y": 18.04
    },
    {
        "x": 11.45,
        "y": 18.06
    },
    {
        "x": 11.46,
        "y": 18.07
    },
    {
        "x": 11.47,
        "y": 18.11
    },
    {
        "x": 11.48,
        "y": 18.13
    },
    {
        "x": 11.49,
        "y": 18.14
    },
    {
        "x": 11.5,
        "y": 18.16
    },
    {
        "x": 11.51,
        "y": 18.18
    },
    {
        "x": 11.52,
        "y": 18.21
    },
    {
        "x": 11.53,
        "y": 18.23
    },
    {
        "x": 11.54,
        "y": 18.25
    },
    {
        "x": 11.55,
        "y": 18.27
    },
    {
        "x": 11.56,
        "y": 18.29
    },
    {
        "x": 11.57,
        "y": 18.32
    },
    {
        "x": 11.58,
        "y": 18.33
    },
    {
        "x": 11.59,
        "y": 18.35
    },
    {
        "x": 11.6,
        "y": 18.37
    },
    {
        "x": 11.61,
        "y": 18.39
    },
    {
        "x": 11.62,
        "y": 18.41
    },
    {
        "x": 11.63,
        "y": 18.44
    },
    {
        "x": 11.64,
        "y": 18.46
    },
    {
        "x": 11.65,
        "y": 18.48
    },
    {
        "x": 11.66,
        "y": 18.49
    },
    {
        "x": 11.67,
        "y": 18.51
    },
    {
        "x": 11.68,
        "y": 18.54
    },
    {
        "x": 11.69,
        "y": 18.56
    },
    {
        "x": 11.7,
        "y": 18.58
    },
    {
        "x": 11.71,
        "y": 18.6
    },
    {
        "x": 11.72,
        "y": 18.61
    },
    {
        "x": 11.73,
        "y": 18.63
    },
    {
        "x": 11.74,
        "y": 18.66
    },
    {
        "x": 11.75,
        "y": 18.68
    },
    {
        "x": 11.76,
        "y": 18.7
    },
    {
        "x": 11.77,
        "y": 18.71
    },
    {
        "x": 11.78,
        "y": 18.73
    },
    {
        "x": 11.79,
        "y": 18.76
    },
    {
        "x": 11.8,
        "y": 18.78
    },
    {
        "x": 11.81,
        "y": 18.79
    },
    {
        "x": 11.82,
        "y": 18.81
    },
    {
        "x": 11.83,
        "y": 18.83
    },
    {
        "x": 11.84,
        "y": 18.85
    },
    {
        "x": 11.85,
        "y": 18.87
    },
    {
        "x": 11.86,
        "y": 18.89
    },
    {
        "x": 11.87,
        "y": 18.91
    },
    {
        "x": 11.88,
        "y": 18.93
    },
    {
        "x": 11.89,
        "y": 18.94
    },
    {
        "x": 11.9,
        "y": 18.96
    },
    {
        "x": 11.91,
        "y": 18.99
    },
    {
        "x": 11.92,
        "y": 19
    },
    {
        "x": 11.93,
        "y": 19.02
    },
    {
        "x": 11.94,
        "y": 19.03
    },
    {
        "x": 11.95,
        "y": 19.06
    },
    {
        "x": 11.96,
        "y": 19.07
    },
    {
        "x": 11.97,
        "y": 19.1
    },
    {
        "x": 11.98,
        "y": 19.12
    },
    {
        "x": 11.99,
        "y": 19.13
    },
    {
        "x": 12,
        "y": 19.15
    },
    {
        "x": 12.01,
        "y": 19.17
    },
    {
        "x": 12.02,
        "y": 19.18
    },
    {
        "x": 12.03,
        "y": 19.21
    },
    {
        "x": 12.04,
        "y": 19.22
    },
    {
        "x": 12.05,
        "y": 19.24
    },
    {
        "x": 12.06,
        "y": 19.26
    },
    {
        "x": 12.07,
        "y": 19.27
    },
    {
        "x": 12.08,
        "y": 19.29
    },
    {
        "x": 12.09,
        "y": 19.31
    },
    {
        "x": 12.1,
        "y": 19.33
    },
    {
        "x": 12.11,
        "y": 19.35
    },
    {
        "x": 12.12,
        "y": 19.36
    },
    {
        "x": 12.13,
        "y": 19.38
    },
    {
        "x": 12.14,
        "y": 19.4
    },
    {
        "x": 12.15,
        "y": 19.42
    },
    {
        "x": 12.16,
        "y": 19.44
    },
    {
        "x": 12.17,
        "y": 19.45
    },
    {
        "x": 12.18,
        "y": 19.47
    },
    {
        "x": 12.19,
        "y": 19.48
    },
    {
        "x": 12.2,
        "y": 19.5
    },
    {
        "x": 12.21,
        "y": 19.51
    },
    {
        "x": 12.22,
        "y": 19.54
    },
    {
        "x": 12.23,
        "y": 19.56
    },
    {
        "x": 12.24,
        "y": 19.57
    },
    {
        "x": 12.25,
        "y": 19.59
    },
    {
        "x": 12.26,
        "y": 19.6
    },
    {
        "x": 12.27,
        "y": 19.62
    },
    {
        "x": 12.28,
        "y": 19.64
    },
    {
        "x": 12.29,
        "y": 19.66
    },
    {
        "x": 12.3,
        "y": 19.67
    },
    {
        "x": 12.31,
        "y": 19.69
    },
    {
        "x": 12.32,
        "y": 19.7
    },
    {
        "x": 12.33,
        "y": 19.72
    },
    {
        "x": 12.34,
        "y": 19.73
    },
    {
        "x": 12.35,
        "y": 19.76
    },
    {
        "x": 12.36,
        "y": 19.78
    },
    {
        "x": 12.37,
        "y": 19.79
    },
    {
        "x": 12.38,
        "y": 19.8
    },
    {
        "x": 12.39,
        "y": 19.81
    },
    {
        "x": 12.4,
        "y": 19.83
    },
    {
        "x": 12.41,
        "y": 19.84
    },
    {
        "x": 12.42,
        "y": 19.87
    },
    {
        "x": 12.43,
        "y": 19.88
    },
    {
        "x": 12.44,
        "y": 19.9
    },
    {
        "x": 12.45,
        "y": 19.91
    },
    {
        "x": 12.46,
        "y": 19.93
    },
    {
        "x": 12.47,
        "y": 19.94
    },
    {
        "x": 12.48,
        "y": 19.95
    },
    {
        "x": 12.49,
        "y": 19.98
    },
    {
        "x": 12.5,
        "y": 19.99
    },
    {
        "x": 12.51,
        "y": 20.01
    },
    {
        "x": 12.52,
        "y": 20.02
    },
    {
        "x": 12.53,
        "y": 20.03
    },
    {
        "x": 12.54,
        "y": 20.05
    },
    {
        "x": 12.55,
        "y": 20.06
    },
    {
        "x": 12.56,
        "y": 20.09
    },
    {
        "x": 12.57,
        "y": 20.1
    },
    {
        "x": 12.58,
        "y": 20.11
    },
    {
        "x": 12.59,
        "y": 20.13
    },
    {
        "x": 12.6,
        "y": 20.14
    },
    {
        "x": 12.61,
        "y": 20.16
    },
    {
        "x": 12.62,
        "y": 20.17
    },
    {
        "x": 12.63,
        "y": 20.19
    },
    {
        "x": 12.64,
        "y": 20.21
    },
    {
        "x": 12.65,
        "y": 20.22
    },
    {
        "x": 12.66,
        "y": 20.24
    },
    {
        "x": 12.67,
        "y": 20.25
    },
    {
        "x": 12.68,
        "y": 20.26
    },
    {
        "x": 12.69,
        "y": 20.27
    },
    {
        "x": 12.7,
        "y": 20.3
    },
    {
        "x": 12.71,
        "y": 20.31
    },
    {
        "x": 12.72,
        "y": 20.32
    },
    {
        "x": 12.73,
        "y": 20.34
    },
    {
        "x": 12.74,
        "y": 20.35
    },
    {
        "x": 12.75,
        "y": 20.36
    },
    {
        "x": 12.76,
        "y": 20.38
    },
    {
        "x": 12.77,
        "y": 20.39
    },
    {
        "x": 12.78,
        "y": 20.41
    },
    {
        "x": 12.79,
        "y": 20.42
    },
    {
        "x": 12.8,
        "y": 20.44
    },
    {
        "x": 12.81,
        "y": 20.45
    },
    {
        "x": 12.82,
        "y": 20.46
    },
    {
        "x": 12.83,
        "y": 20.48
    },
    {
        "x": 12.84,
        "y": 20.49
    },
    {
        "x": 12.85,
        "y": 20.5
    },
    {
        "x": 12.86,
        "y": 20.53
    },
    {
        "x": 12.87,
        "y": 20.54
    },
    {
        "x": 12.88,
        "y": 20.55
    },
    {
        "x": 12.89,
        "y": 20.56
    },
    {
        "x": 12.9,
        "y": 20.58
    },
    {
        "x": 12.91,
        "y": 20.59
    },
    {
        "x": 12.92,
        "y": 20.6
    },
    {
        "x": 12.93,
        "y": 20.61
    },
    {
        "x": 12.94,
        "y": 20.64
    },
    {
        "x": 12.95,
        "y": 20.65
    },
    {
        "x": 12.96,
        "y": 20.66
    },
    {
        "x": 12.97,
        "y": 20.67
    },
    {
        "x": 12.98,
        "y": 20.69
    },
    {
        "x": 12.99,
        "y": 20.7
    },
    {
        "x": 13,
        "y": 20.71
    },
    {
        "x": 13.01,
        "y": 20.72
    },
    {
        "x": 13.02,
        "y": 20.74
    },
    {
        "x": 13.03,
        "y": 20.75
    },
    {
        "x": 13.04,
        "y": 20.77
    },
    {
        "x": 13.05,
        "y": 20.78
    },
    {
        "x": 13.06,
        "y": 20.79
    },
    {
        "x": 13.07,
        "y": 20.81
    },
    {
        "x": 13.08,
        "y": 20.82
    },
    {
        "x": 13.09,
        "y": 20.83
    },
    {
        "x": 13.1,
        "y": 20.85
    },
    {
        "x": 13.11,
        "y": 20.86
    },
    {
        "x": 13.12,
        "y": 20.87
    },
    {
        "x": 13.13,
        "y": 20.88
    },
    {
        "x": 13.14,
        "y": 20.9
    },
    {
        "x": 13.15,
        "y": 20.91
    },
    {
        "x": 13.16,
        "y": 20.92
    },
    {
        "x": 13.17,
        "y": 20.93
    },
    {
        "x": 13.18,
        "y": 20.96
    },
    {
        "x": 13.19,
        "y": 20.97
    },
    {
        "x": 13.2,
        "y": 20.98
    },
    {
        "x": 13.21,
        "y": 20.99
    },
    {
        "x": 13.22,
        "y": 21
    },
    {
        "x": 13.23,
        "y": 21.01
    },
    {
        "x": 13.24,
        "y": 21.02
    },
    {
        "x": 13.25,
        "y": 21.04
    },
    {
        "x": 13.26,
        "y": 21.05
    },
    {
        "x": 13.27,
        "y": 21.07
    },
    {
        "x": 13.28,
        "y": 21.08
    },
    {
        "x": 13.29,
        "y": 21.09
    },
    {
        "x": 13.3,
        "y": 21.1
    },
    {
        "x": 13.31,
        "y": 21.11
    },
    {
        "x": 13.32,
        "y": 21.13
    },
    {
        "x": 13.33,
        "y": 21.13
    },
    {
        "x": 13.34,
        "y": 21.15
    },
    {
        "x": 13.35,
        "y": 21.16
    },
    {
        "x": 13.36,
        "y": 21.18
    },
    {
        "x": 13.37,
        "y": 21.19
    },
    {
        "x": 13.38,
        "y": 21.2
    },
    {
        "x": 13.39,
        "y": 21.21
    },
    {
        "x": 13.4,
        "y": 21.23
    },
    {
        "x": 13.41,
        "y": 21.24
    },
    {
        "x": 13.42,
        "y": 21.25
    },
    {
        "x": 13.43,
        "y": 21.26
    },
    {
        "x": 13.44,
        "y": 21.27
    },
    {
        "x": 13.45,
        "y": 21.29
    },
    {
        "x": 13.46,
        "y": 21.3
    },
    {
        "x": 13.47,
        "y": 21.31
    },
    {
        "x": 13.48,
        "y": 21.32
    },
    {
        "x": 13.49,
        "y": 21.33
    },
    {
        "x": 13.5,
        "y": 21.34
    },
    {
        "x": 13.51,
        "y": 21.35
    },
    {
        "x": 13.52,
        "y": 21.36
    },
    {
        "x": 13.53,
        "y": 21.37
    },
    {
        "x": 13.54,
        "y": 21.4
    },
    {
        "x": 13.55,
        "y": 21.41
    },
    {
        "x": 13.56,
        "y": 21.42
    },
    {
        "x": 13.57,
        "y": 21.43
    },
    {
        "x": 13.58,
        "y": 21.43
    },
    {
        "x": 13.59,
        "y": 21.45
    },
    {
        "x": 13.6,
        "y": 21.46
    },
    {
        "x": 13.61,
        "y": 21.47
    },
    {
        "x": 13.62,
        "y": 21.48
    },
    {
        "x": 13.63,
        "y": 21.49
    },
    {
        "x": 13.64,
        "y": 21.51
    },
    {
        "x": 13.65,
        "y": 21.52
    },
    {
        "x": 13.66,
        "y": 21.53
    },
    {
        "x": 13.67,
        "y": 21.54
    },
    {
        "x": 13.68,
        "y": 21.55
    },
    {
        "x": 13.69,
        "y": 21.56
    },
    {
        "x": 13.7,
        "y": 21.57
    },
    {
        "x": 13.71,
        "y": 21.58
    },
    {
        "x": 13.72,
        "y": 21.6
    },
    {
        "x": 13.73,
        "y": 21.6
    },
    {
        "x": 13.74,
        "y": 21.62
    },
    {
        "x": 13.75,
        "y": 21.63
    },
    {
        "x": 13.76,
        "y": 21.64
    },
    {
        "x": 13.77,
        "y": 21.65
    },
    {
        "x": 13.78,
        "y": 21.66
    },
    {
        "x": 13.79,
        "y": 21.67
    },
    {
        "x": 13.8,
        "y": 21.68
    },
    {
        "x": 13.81,
        "y": 21.69
    },
    {
        "x": 13.82,
        "y": 21.7
    },
    {
        "x": 13.83,
        "y": 21.73
    },
    {
        "x": 13.84,
        "y": 21.73
    },
    {
        "x": 13.85,
        "y": 21.74
    },
    {
        "x": 13.86,
        "y": 21.75
    },
    {
        "x": 13.87,
        "y": 21.76
    },
    {
        "x": 13.88,
        "y": 21.77
    },
    {
        "x": 13.89,
        "y": 21.78
    },
    {
        "x": 13.9,
        "y": 21.79
    },
    {
        "x": 13.91,
        "y": 21.8
    },
    {
        "x": 13.92,
        "y": 21.81
    },
    {
        "x": 13.93,
        "y": 21.82
    },
    {
        "x": 13.94,
        "y": 21.84
    },
    {
        "x": 13.95,
        "y": 21.85
    },
    {
        "x": 13.96,
        "y": 21.86
    },
    {
        "x": 13.97,
        "y": 21.87
    },
    {
        "x": 13.98,
        "y": 21.88
    },
    {
        "x": 13.99,
        "y": 21.89
    },
    {
        "x": 14,
        "y": 21.9
    },
    {
        "x": 14.01,
        "y": 21.91
    },
    {
        "x": 14.02,
        "y": 21.92
    },
    {
        "x": 14.03,
        "y": 21.93
    },
    {
        "x": 14.04,
        "y": 21.93
    },
    {
        "x": 14.05,
        "y": 21.95
    },
    {
        "x": 14.06,
        "y": 21.96
    },
    {
        "x": 14.07,
        "y": 21.97
    },
    {
        "x": 14.08,
        "y": 21.98
    },
    {
        "x": 14.09,
        "y": 21.99
    },
    {
        "x": 14.1,
        "y": 22
    },
    {
        "x": 14.11,
        "y": 22.01
    },
    {
        "x": 14.12,
        "y": 22.02
    },
    {
        "x": 14.13,
        "y": 22.03
    },
    {
        "x": 14.14,
        "y": 22.04
    },
    {
        "x": 14.15,
        "y": 22.06
    },
    {
        "x": 14.16,
        "y": 22.07
    },
    {
        "x": 14.17,
        "y": 22.07
    },
    {
        "x": 14.18,
        "y": 22.09
    },
    {
        "x": 14.19,
        "y": 22.09
    },
    {
        "x": 14.2,
        "y": 22.1
    },
    {
        "x": 14.21,
        "y": 22.11
    },
    {
        "x": 14.22,
        "y": 22.12
    },
    {
        "x": 14.23,
        "y": 22.13
    },
    {
        "x": 14.24,
        "y": 22.14
    },
    {
        "x": 14.25,
        "y": 22.15
    },
    {
        "x": 14.26,
        "y": 22.17
    },
    {
        "x": 14.27,
        "y": 22.17
    },
    {
        "x": 14.28,
        "y": 22.18
    },
    {
        "x": 14.29,
        "y": 22.19
    },
    {
        "x": 14.3,
        "y": 22.2
    },
    {
        "x": 14.31,
        "y": 22.21
    },
    {
        "x": 14.32,
        "y": 22.22
    },
    {
        "x": 14.33,
        "y": 22.23
    },
    {
        "x": 14.34,
        "y": 22.24
    },
    {
        "x": 14.35,
        "y": 22.25
    },
    {
        "x": 14.36,
        "y": 22.26
    },
    {
        "x": 14.37,
        "y": 22.28
    },
    {
        "x": 14.38,
        "y": 22.28
    },
    {
        "x": 14.39,
        "y": 22.29
    },
    {
        "x": 14.4,
        "y": 22.3
    },
    {
        "x": 14.41,
        "y": 22.31
    },
    {
        "x": 14.42,
        "y": 22.32
    },
    {
        "x": 14.43,
        "y": 22.33
    },
    {
        "x": 14.44,
        "y": 22.33
    },
    {
        "x": 14.45,
        "y": 22.35
    },
    {
        "x": 14.46,
        "y": 22.36
    },
    {
        "x": 14.47,
        "y": 22.36
    },
    {
        "x": 14.48,
        "y": 22.37
    },
    {
        "x": 14.49,
        "y": 22.39
    },
    {
        "x": 14.5,
        "y": 22.4
    },
    {
        "x": 14.51,
        "y": 22.41
    },
    {
        "x": 14.52,
        "y": 22.42
    },
    {
        "x": 14.53,
        "y": 22.42
    },
    {
        "x": 14.54,
        "y": 22.43
    },
    {
        "x": 14.55,
        "y": 22.44
    },
    {
        "x": 14.56,
        "y": 22.45
    },
    {
        "x": 14.57,
        "y": 22.46
    },
    {
        "x": 14.58,
        "y": 22.46
    },
    {
        "x": 14.59,
        "y": 22.47
    },
    {
        "x": 14.6,
        "y": 22.48
    },
    {
        "x": 14.61,
        "y": 22.5
    },
    {
        "x": 14.62,
        "y": 22.51
    },
    {
        "x": 14.63,
        "y": 22.52
    },
    {
        "x": 14.64,
        "y": 22.52
    },
    {
        "x": 14.65,
        "y": 22.53
    },
    {
        "x": 14.66,
        "y": 22.54
    },
    {
        "x": 14.67,
        "y": 22.54
    },
    {
        "x": 14.68,
        "y": 22.55
    },
    {
        "x": 14.69,
        "y": 22.56
    },
    {
        "x": 14.7,
        "y": 22.57
    },
    {
        "x": 14.71,
        "y": 22.58
    },
    {
        "x": 14.72,
        "y": 22.59
    },
    {
        "x": 14.73,
        "y": 22.59
    },
    {
        "x": 14.74,
        "y": 22.61
    },
    {
        "x": 14.75,
        "y": 22.62
    },
    {
        "x": 14.76,
        "y": 22.63
    },
    {
        "x": 14.77,
        "y": 22.64
    },
    {
        "x": 14.78,
        "y": 22.64
    },
    {
        "x": 14.79,
        "y": 22.66
    },
    {
        "x": 14.8,
        "y": 22.66
    },
    {
        "x": 14.81,
        "y": 22.67
    },
    {
        "x": 14.82,
        "y": 22.68
    },
    {
        "x": 14.83,
        "y": 22.69
    },
    {
        "x": 14.84,
        "y": 22.7
    },
    {
        "x": 14.85,
        "y": 22.7
    },
    {
        "x": 14.86,
        "y": 22.72
    },
    {
        "x": 14.87,
        "y": 22.73
    },
    {
        "x": 14.88,
        "y": 22.74
    },
    {
        "x": 14.89,
        "y": 22.74
    },
    {
        "x": 14.9,
        "y": 22.75
    },
    {
        "x": 14.91,
        "y": 22.76
    },
    {
        "x": 14.92,
        "y": 22.77
    },
    {
        "x": 14.93,
        "y": 22.78
    },
    {
        "x": 14.94,
        "y": 22.78
    },
    {
        "x": 14.95,
        "y": 22.79
    },
    {
        "x": 14.96,
        "y": 22.8
    },
    {
        "x": 14.97,
        "y": 22.8
    },
    {
        "x": 14.98,
        "y": 22.81
    },
    {
        "x": 14.99,
        "y": 22.83
    },
    {
        "x": 15,
        "y": 22.84
    },
    {
        "x": 15.01,
        "y": 22.84
    },
    {
        "x": 15.02,
        "y": 22.85
    },
    {
        "x": 15.03,
        "y": 22.86
    },
    {
        "x": 15.04,
        "y": 22.87
    },
    {
        "x": 15.05,
        "y": 22.88
    },
    {
        "x": 15.06,
        "y": 22.89
    },
    {
        "x": 15.07,
        "y": 22.89
    },
    {
        "x": 15.08,
        "y": 22.9
    },
    {
        "x": 15.09,
        "y": 22.91
    },
    {
        "x": 15.1,
        "y": 22.92
    },
    {
        "x": 15.11,
        "y": 22.92
    },
    {
        "x": 15.12,
        "y": 22.94
    },
    {
        "x": 15.13,
        "y": 22.95
    },
    {
        "x": 15.14,
        "y": 22.95
    },
    {
        "x": 15.15,
        "y": 22.96
    },
    {
        "x": 15.16,
        "y": 22.97
    },
    {
        "x": 15.17,
        "y": 22.97
    },
    {
        "x": 15.18,
        "y": 22.98
    },
    {
        "x": 15.19,
        "y": 22.99
    },
    {
        "x": 15.2,
        "y": 23
    },
    {
        "x": 15.21,
        "y": 23.01
    },
    {
        "x": 15.22,
        "y": 23.01
    },
    {
        "x": 15.23,
        "y": 23.02
    },
    {
        "x": 15.24,
        "y": 23.03
    },
    {
        "x": 15.25,
        "y": 23.03
    },
    {
        "x": 15.26,
        "y": 23.05
    },
    {
        "x": 15.27,
        "y": 23.06
    },
    {
        "x": 15.28,
        "y": 23.07
    },
    {
        "x": 15.29,
        "y": 23.07
    },
    {
        "x": 15.3,
        "y": 23.08
    },
    {
        "x": 15.31,
        "y": 23.09
    },
    {
        "x": 15.32,
        "y": 23.09
    },
    {
        "x": 15.33,
        "y": 23.1
    },
    {
        "x": 15.34,
        "y": 23.1
    },
    {
        "x": 15.35,
        "y": 23.11
    },
    {
        "x": 15.36,
        "y": 23.12
    },
    {
        "x": 15.37,
        "y": 23.13
    },
    {
        "x": 15.38,
        "y": 23.14
    },
    {
        "x": 15.39,
        "y": 23.14
    },
    {
        "x": 15.4,
        "y": 23.16
    },
    {
        "x": 15.41,
        "y": 23.17
    },
    {
        "x": 15.42,
        "y": 23.18
    },
    {
        "x": 15.43,
        "y": 23.18
    },
    {
        "x": 15.44,
        "y": 23.19
    },
    {
        "x": 15.45,
        "y": 23.2
    },
    {
        "x": 15.46,
        "y": 23.2
    },
    {
        "x": 15.47,
        "y": 23.21
    },
    {
        "x": 15.48,
        "y": 23.22
    },
    {
        "x": 15.49,
        "y": 23.23
    },
    {
        "x": 15.5,
        "y": 23.23
    },
    {
        "x": 15.51,
        "y": 23.24
    },
    {
        "x": 15.52,
        "y": 23.25
    },
    {
        "x": 15.53,
        "y": 23.25
    },
    {
        "x": 15.54,
        "y": 23.27
    },
    {
        "x": 15.55,
        "y": 23.27
    },
    {
        "x": 15.56,
        "y": 23.28
    },
    {
        "x": 15.57,
        "y": 23.29
    },
    {
        "x": 15.58,
        "y": 23.3
    },
    {
        "x": 15.59,
        "y": 23.3
    },
    {
        "x": 15.6,
        "y": 23.31
    },
    {
        "x": 15.61,
        "y": 23.32
    },
    {
        "x": 15.62,
        "y": 23.32
    },
    {
        "x": 15.63,
        "y": 23.33
    },
    {
        "x": 15.64,
        "y": 23.33
    },
    {
        "x": 15.65,
        "y": 23.34
    },
    {
        "x": 15.66,
        "y": 23.35
    },
    {
        "x": 15.67,
        "y": 23.36
    },
    {
        "x": 15.68,
        "y": 23.36
    },
    {
        "x": 15.69,
        "y": 23.38
    },
    {
        "x": 15.7,
        "y": 23.38
    },
    {
        "x": 15.71,
        "y": 23.4
    },
    {
        "x": 15.72,
        "y": 23.4
    },
    {
        "x": 15.73,
        "y": 23.41
    },
    {
        "x": 15.74,
        "y": 23.41
    },
    {
        "x": 15.75,
        "y": 23.42
    },
    {
        "x": 15.76,
        "y": 23.43
    },
    {
        "x": 15.77,
        "y": 23.43
    },
    {
        "x": 15.78,
        "y": 23.44
    },
    {
        "x": 15.79,
        "y": 23.44
    },
    {
        "x": 15.8,
        "y": 23.45
    },
    {
        "x": 15.81,
        "y": 23.46
    },
    {
        "x": 15.82,
        "y": 23.47
    },
    {
        "x": 15.83,
        "y": 23.49
    },
    {
        "x": 15.84,
        "y": 23.49
    },
    {
        "x": 15.85,
        "y": 23.49
    },
    {
        "x": 15.86,
        "y": 23.5
    },
    {
        "x": 15.87,
        "y": 23.51
    },
    {
        "x": 15.88,
        "y": 23.52
    },
    {
        "x": 15.89,
        "y": 23.52
    },
    {
        "x": 15.9,
        "y": 23.53
    },
    {
        "x": 15.91,
        "y": 23.53
    },
    {
        "x": 15.92,
        "y": 23.54
    },
    {
        "x": 15.93,
        "y": 23.55
    },
    {
        "x": 15.94,
        "y": 23.55
    },
    {
        "x": 15.95,
        "y": 23.56
    },
    {
        "x": 15.96,
        "y": 23.56
    },
    {
        "x": 15.97,
        "y": 23.57
    },
    {
        "x": 15.98,
        "y": 23.58
    },
    {
        "x": 15.99,
        "y": 23.6
    },
    {
        "x": 16,
        "y": 23.6
    },
    {
        "x": 16.01,
        "y": 23.61
    },
    {
        "x": 16.02,
        "y": 23.61
    },
    {
        "x": 16.03,
        "y": 23.62
    },
    {
        "x": 16.04,
        "y": 23.63
    },
    {
        "x": 16.05,
        "y": 23.63
    },
    {
        "x": 16.06,
        "y": 23.64
    },
    {
        "x": 16.07,
        "y": 23.65
    },
    {
        "x": 16.08,
        "y": 23.65
    },
    {
        "x": 16.09,
        "y": 23.66
    },
    {
        "x": 16.1,
        "y": 23.66
    },
    {
        "x": 16.11,
        "y": 23.67
    },
    {
        "x": 16.12,
        "y": 23.68
    },
    {
        "x": 16.13,
        "y": 23.68
    },
    {
        "x": 16.14,
        "y": 23.69
    },
    {
        "x": 16.15,
        "y": 23.69
    },
    {
        "x": 16.16,
        "y": 23.71
    },
    {
        "x": 16.17,
        "y": 23.71
    },
    {
        "x": 16.18,
        "y": 23.72
    },
    {
        "x": 16.19,
        "y": 23.73
    },
    {
        "x": 16.2,
        "y": 23.74
    },
    {
        "x": 16.21,
        "y": 23.74
    },
    {
        "x": 16.22,
        "y": 23.75
    },
    {
        "x": 16.23,
        "y": 23.75
    },
    {
        "x": 16.24,
        "y": 23.76
    },
    {
        "x": 16.25,
        "y": 23.76
    },
    {
        "x": 16.26,
        "y": 23.77
    },
    {
        "x": 16.27,
        "y": 23.78
    },
    {
        "x": 16.28,
        "y": 23.78
    },
    {
        "x": 16.29,
        "y": 23.79
    },
    {
        "x": 16.3,
        "y": 23.8
    },
    {
        "x": 16.31,
        "y": 23.8
    },
    {
        "x": 16.32,
        "y": 23.82
    },
    {
        "x": 16.33,
        "y": 23.82
    },
    {
        "x": 16.34,
        "y": 23.83
    },
    {
        "x": 16.35,
        "y": 23.84
    },
    {
        "x": 16.36,
        "y": 23.84
    },
    {
        "x": 16.37,
        "y": 23.85
    },
    {
        "x": 16.38,
        "y": 23.85
    },
    {
        "x": 16.39,
        "y": 23.86
    },
    {
        "x": 16.4,
        "y": 23.86
    },
    {
        "x": 16.41,
        "y": 23.87
    },
    {
        "x": 16.42,
        "y": 23.88
    },
    {
        "x": 16.43,
        "y": 23.88
    },
    {
        "x": 16.44,
        "y": 23.89
    },
    {
        "x": 16.45,
        "y": 23.89
    },
    {
        "x": 16.46,
        "y": 23.9
    },
    {
        "x": 16.47,
        "y": 23.91
    },
    {
        "x": 16.48,
        "y": 23.91
    },
    {
        "x": 16.49,
        "y": 23.93
    },
    {
        "x": 16.5,
        "y": 23.93
    },
    {
        "x": 16.51,
        "y": 23.94
    },
    {
        "x": 16.52,
        "y": 23.95
    },
    {
        "x": 16.53,
        "y": 23.95
    },
    {
        "x": 16.54,
        "y": 23.96
    },
    {
        "x": 16.55,
        "y": 23.96
    },
    {
        "x": 16.56,
        "y": 23.97
    },
    {
        "x": 16.57,
        "y": 23.97
    },
    {
        "x": 16.58,
        "y": 23.98
    },
    {
        "x": 16.59,
        "y": 23.99
    },
    {
        "x": 16.6,
        "y": 23.99
    },
    {
        "x": 16.61,
        "y": 23.99
    },
    {
        "x": 16.62,
        "y": 24
    },
    {
        "x": 16.63,
        "y": 24.01
    },
    {
        "x": 16.64,
        "y": 24.01
    },
    {
        "x": 16.65,
        "y": 24.02
    },
    {
        "x": 16.66,
        "y": 24.02
    },
    {
        "x": 16.67,
        "y": 24.04
    },
    {
        "x": 16.68,
        "y": 24.05
    },
    {
        "x": 16.69,
        "y": 24.05
    },
    {
        "x": 16.7,
        "y": 24.06
    },
    {
        "x": 16.71,
        "y": 24.06
    },
    {
        "x": 16.72,
        "y": 24.07
    },
    {
        "x": 16.73,
        "y": 24.07
    },
    {
        "x": 16.74,
        "y": 24.08
    },
    {
        "x": 16.75,
        "y": 24.08
    },
    {
        "x": 16.76,
        "y": 24.09
    },
    {
        "x": 16.77,
        "y": 24.1
    },
    {
        "x": 16.78,
        "y": 24.1
    },
    {
        "x": 16.79,
        "y": 24.11
    },
    {
        "x": 16.8,
        "y": 24.11
    },
    {
        "x": 16.81,
        "y": 24.12
    },
    {
        "x": 16.82,
        "y": 24.12
    },
    {
        "x": 16.83,
        "y": 24.13
    },
    {
        "x": 16.84,
        "y": 24.13
    },
    {
        "x": 16.85,
        "y": 24.15
    },
    {
        "x": 16.86,
        "y": 24.16
    },
    {
        "x": 16.87,
        "y": 24.16
    },
    {
        "x": 16.88,
        "y": 24.16
    },
    {
        "x": 16.89,
        "y": 24.17
    },
    {
        "x": 16.9,
        "y": 24.17
    },
    {
        "x": 16.91,
        "y": 24.18
    },
    {
        "x": 16.92,
        "y": 24.19
    },
    {
        "x": 16.93,
        "y": 24.19
    },
    {
        "x": 16.94,
        "y": 24.2
    },
    {
        "x": 16.95,
        "y": 24.2
    },
    {
        "x": 16.96,
        "y": 24.21
    },
    {
        "x": 16.97,
        "y": 24.21
    },
    {
        "x": 16.98,
        "y": 24.22
    },
    {
        "x": 16.99,
        "y": 24.22
    },
    {
        "x": 17,
        "y": 24.23
    },
    {
        "x": 17.01,
        "y": 24.24
    },
    {
        "x": 17.02,
        "y": 24.24
    },
    {
        "x": 17.03,
        "y": 24.26
    },
    {
        "x": 17.04,
        "y": 24.26
    },
    {
        "x": 17.05,
        "y": 24.27
    },
    {
        "x": 17.06,
        "y": 24.27
    },
    {
        "x": 17.07,
        "y": 24.28
    },
    {
        "x": 17.08,
        "y": 24.28
    },
    {
        "x": 17.09,
        "y": 24.29
    },
    {
        "x": 17.1,
        "y": 24.29
    },
    {
        "x": 17.11,
        "y": 24.3
    },
    {
        "x": 17.12,
        "y": 24.3
    },
    {
        "x": 17.13,
        "y": 24.31
    },
    {
        "x": 17.14,
        "y": 24.31
    },
    {
        "x": 17.15,
        "y": 24.32
    },
    {
        "x": 17.16,
        "y": 24.32
    },
    {
        "x": 17.17,
        "y": 24.33
    },
    {
        "x": 17.18,
        "y": 24.34
    },
    {
        "x": 17.19,
        "y": 24.34
    },
    {
        "x": 17.2,
        "y": 24.35
    },
    {
        "x": 17.21,
        "y": 24.35
    },
    {
        "x": 17.22,
        "y": 24.37
    },
    {
        "x": 17.23,
        "y": 24.37
    },
    {
        "x": 17.24,
        "y": 24.38
    },
    {
        "x": 17.25,
        "y": 24.38
    },
    {
        "x": 17.26,
        "y": 24.38
    },
    {
        "x": 17.27,
        "y": 24.39
    },
    {
        "x": 17.28,
        "y": 24.4
    },
    {
        "x": 17.29,
        "y": 24.4
    },
    {
        "x": 17.3,
        "y": 24.41
    },
    {
        "x": 17.31,
        "y": 24.41
    },
    {
        "x": 17.32,
        "y": 24.42
    },
    {
        "x": 17.33,
        "y": 24.42
    },
    {
        "x": 17.34,
        "y": 24.42
    },
    {
        "x": 17.35,
        "y": 24.43
    },
    {
        "x": 17.36,
        "y": 24.44
    },
    {
        "x": 17.37,
        "y": 24.44
    },
    {
        "x": 17.38,
        "y": 24.44
    },
    {
        "x": 17.39,
        "y": 24.45
    },
    {
        "x": 17.4,
        "y": 24.46
    },
    {
        "x": 17.41,
        "y": 24.46
    },
    {
        "x": 17.42,
        "y": 24.48
    },
    {
        "x": 17.43,
        "y": 24.48
    },
    {
        "x": 17.44,
        "y": 24.49
    },
    {
        "x": 17.45,
        "y": 24.49
    },
    {
        "x": 17.46,
        "y": 24.5
    },
    {
        "x": 17.47,
        "y": 24.5
    },
    {
        "x": 17.48,
        "y": 24.51
    },
    {
        "x": 17.49,
        "y": 24.51
    },
    {
        "x": 17.5,
        "y": 24.51
    },
    {
        "x": 17.51,
        "y": 24.52
    },
    {
        "x": 17.52,
        "y": 24.52
    },
    {
        "x": 17.53,
        "y": 24.53
    },
    {
        "x": 17.54,
        "y": 24.53
    },
    {
        "x": 17.55,
        "y": 24.54
    },
    {
        "x": 17.56,
        "y": 24.54
    },
    {
        "x": 17.57,
        "y": 24.55
    },
    {
        "x": 17.58,
        "y": 24.55
    },
    {
        "x": 17.59,
        "y": 24.56
    },
    {
        "x": 17.6,
        "y": 24.56
    },
    {
        "x": 17.61,
        "y": 24.56
    },
    {
        "x": 17.62,
        "y": 24.57
    },
    {
        "x": 17.63,
        "y": 24.59
    },
    {
        "x": 17.64,
        "y": 24.59
    },
    {
        "x": 17.65,
        "y": 24.6
    },
    {
        "x": 17.66,
        "y": 24.6
    },
    {
        "x": 17.67,
        "y": 24.61
    },
    {
        "x": 17.68,
        "y": 24.61
    },
    {
        "x": 17.69,
        "y": 24.61
    },
    {
        "x": 17.7,
        "y": 24.62
    },
    {
        "x": 17.71,
        "y": 24.62
    },
    {
        "x": 17.72,
        "y": 24.63
    },
    {
        "x": 17.73,
        "y": 24.63
    },
    {
        "x": 17.74,
        "y": 24.64
    },
    {
        "x": 17.75,
        "y": 24.64
    },
    {
        "x": 17.76,
        "y": 24.65
    },
    {
        "x": 17.77,
        "y": 24.65
    },
    {
        "x": 17.78,
        "y": 24.65
    },
    {
        "x": 17.79,
        "y": 24.66
    },
    {
        "x": 17.8,
        "y": 24.66
    },
    {
        "x": 17.81,
        "y": 24.67
    },
    {
        "x": 17.82,
        "y": 24.67
    },
    {
        "x": 17.83,
        "y": 24.68
    },
    {
        "x": 17.84,
        "y": 24.68
    },
    {
        "x": 17.85,
        "y": 24.7
    },
    {
        "x": 17.86,
        "y": 24.7
    },
    {
        "x": 17.87,
        "y": 24.7
    },
    {
        "x": 17.88,
        "y": 24.71
    },
    {
        "x": 17.89,
        "y": 24.71
    },
    {
        "x": 17.9,
        "y": 24.72
    },
    {
        "x": 17.91,
        "y": 24.72
    },
    {
        "x": 17.92,
        "y": 24.73
    },
    {
        "x": 17.93,
        "y": 24.73
    },
    {
        "x": 17.94,
        "y": 24.73
    },
    {
        "x": 17.95,
        "y": 24.74
    },
    {
        "x": 17.96,
        "y": 24.75
    },
    {
        "x": 17.97,
        "y": 24.75
    },
    {
        "x": 17.98,
        "y": 24.75
    },
    {
        "x": 17.99,
        "y": 24.76
    },
    {
        "x": 18,
        "y": 24.76
    },
    {
        "x": 18.01,
        "y": 24.77
    },
    {
        "x": 18.02,
        "y": 24.77
    },
    {
        "x": 18.03,
        "y": 24.78
    },
    {
        "x": 18.04,
        "y": 24.78
    },
    {
        "x": 18.05,
        "y": 24.78
    },
    {
        "x": 18.06,
        "y": 24.79
    },
    {
        "x": 18.07,
        "y": 24.79
    },
    {
        "x": 18.08,
        "y": 24.81
    },
    {
        "x": 18.09,
        "y": 24.81
    },
    {
        "x": 18.1,
        "y": 24.82
    },
    {
        "x": 18.11,
        "y": 24.82
    },
    {
        "x": 18.12,
        "y": 24.83
    },
    {
        "x": 18.13,
        "y": 24.83
    },
    {
        "x": 18.14,
        "y": 24.84
    },
    {
        "x": 18.15,
        "y": 24.84
    },
    {
        "x": 18.16,
        "y": 24.84
    },
    {
        "x": 18.17,
        "y": 24.85
    },
    {
        "x": 18.18,
        "y": 24.85
    },
    {
        "x": 18.19,
        "y": 24.86
    },
    {
        "x": 18.2,
        "y": 24.86
    },
    {
        "x": 18.21,
        "y": 24.87
    },
    {
        "x": 18.22,
        "y": 24.87
    },
    {
        "x": 18.23,
        "y": 24.88
    },
    {
        "x": 18.24,
        "y": 24.88
    },
    {
        "x": 18.25,
        "y": 24.89
    },
    {
        "x": 18.26,
        "y": 24.89
    },
    {
        "x": 18.27,
        "y": 24.89
    },
    {
        "x": 18.28,
        "y": 24.9
    },
    {
        "x": 18.29,
        "y": 24.9
    },
    {
        "x": 18.3,
        "y": 24.92
    },
    {
        "x": 18.31,
        "y": 24.92
    },
    {
        "x": 18.32,
        "y": 24.92
    },
    {
        "x": 18.33,
        "y": 24.93
    },
    {
        "x": 18.34,
        "y": 24.93
    },
    {
        "x": 18.35,
        "y": 24.94
    },
    {
        "x": 18.36,
        "y": 24.95
    },
    {
        "x": 18.37,
        "y": 24.95
    },
    {
        "x": 18.38,
        "y": 24.95
    },
    {
        "x": 18.39,
        "y": 24.95
    },
    {
        "x": 18.4,
        "y": 24.96
    },
    {
        "x": 18.41,
        "y": 24.96
    },
    {
        "x": 18.42,
        "y": 24.96
    },
    {
        "x": 18.43,
        "y": 24.97
    },
    {
        "x": 18.44,
        "y": 24.97
    },
    {
        "x": 18.45,
        "y": 24.98
    },
    {
        "x": 18.46,
        "y": 24.98
    },
    {
        "x": 18.47,
        "y": 24.99
    },
    {
        "x": 18.48,
        "y": 24.99
    },
    {
        "x": 18.49,
        "y": 25
    },
    {
        "x": 18.5,
        "y": 25
    },
    {
        "x": 18.51,
        "y": 25
    },
    {
        "x": 18.52,
        "y": 25.01
    },
    {
        "x": 18.53,
        "y": 25.01
    },
    {
        "x": 18.54,
        "y": 25.03
    },
    {
        "x": 18.55,
        "y": 25.03
    },
    {
        "x": 18.56,
        "y": 25.03
    },
    {
        "x": 18.57,
        "y": 25.04
    },
    {
        "x": 18.58,
        "y": 25.04
    },
    {
        "x": 18.59,
        "y": 25.05
    },
    {
        "x": 18.6,
        "y": 25.05
    },
    {
        "x": 18.61,
        "y": 25.06
    },
    {
        "x": 18.62,
        "y": 25.06
    },
    {
        "x": 18.63,
        "y": 25.06
    },
    {
        "x": 18.64,
        "y": 25.07
    },
    {
        "x": 18.65,
        "y": 25.07
    },
    {
        "x": 18.66,
        "y": 25.08
    },
    {
        "x": 18.67,
        "y": 25.08
    },
    {
        "x": 18.68,
        "y": 25.09
    },
    {
        "x": 18.69,
        "y": 25.09
    },
    {
        "x": 18.7,
        "y": 25.09
    },
    {
        "x": 18.71,
        "y": 25.1
    },
    {
        "x": 18.72,
        "y": 25.1
    },
    {
        "x": 18.73,
        "y": 25.11
    },
    {
        "x": 18.74,
        "y": 25.11
    },
    {
        "x": 18.75,
        "y": 25.11
    },
    {
        "x": 18.76,
        "y": 25.12
    },
    {
        "x": 18.77,
        "y": 25.12
    },
    {
        "x": 18.78,
        "y": 25.12
    },
    {
        "x": 18.79,
        "y": 25.14
    },
    {
        "x": 18.8,
        "y": 25.14
    },
    {
        "x": 18.81,
        "y": 25.15
    },
    {
        "x": 18.82,
        "y": 25.15
    },
    {
        "x": 18.83,
        "y": 25.16
    },
    {
        "x": 18.84,
        "y": 25.16
    },
    {
        "x": 18.85,
        "y": 25.16
    },
    {
        "x": 18.86,
        "y": 25.17
    },
    {
        "x": 18.87,
        "y": 25.17
    },
    {
        "x": 18.88,
        "y": 25.18
    },
    {
        "x": 18.89,
        "y": 25.18
    },
    {
        "x": 18.9,
        "y": 25.18
    },
    {
        "x": 18.91,
        "y": 25.19
    },
    {
        "x": 18.92,
        "y": 25.19
    },
    {
        "x": 18.93,
        "y": 25.2
    },
    {
        "x": 18.94,
        "y": 25.2
    },
    {
        "x": 18.95,
        "y": 25.2
    },
    {
        "x": 18.96,
        "y": 25.2
    },
    {
        "x": 18.97,
        "y": 25.21
    },
    {
        "x": 18.98,
        "y": 25.21
    },
    {
        "x": 18.99,
        "y": 25.22
    },
    {
        "x": 19,
        "y": 25.22
    },
    {
        "x": 19.01,
        "y": 25.22
    },
    {
        "x": 19.02,
        "y": 25.23
    },
    {
        "x": 19.03,
        "y": 25.23
    },
    {
        "x": 19.04,
        "y": 25.25
    },
    {
        "x": 19.05,
        "y": 25.25
    },
    {
        "x": 19.06,
        "y": 25.25
    },
    {
        "x": 19.07,
        "y": 25.26
    },
    {
        "x": 19.08,
        "y": 25.26
    },
    {
        "x": 19.09,
        "y": 25.26
    },
    {
        "x": 19.1,
        "y": 25.27
    },
    {
        "x": 19.11,
        "y": 25.27
    },
    {
        "x": 19.12,
        "y": 25.28
    },
    {
        "x": 19.13,
        "y": 25.28
    },
    {
        "x": 19.14,
        "y": 25.29
    },
    {
        "x": 19.15,
        "y": 25.29
    },
    {
        "x": 19.16,
        "y": 25.29
    },
    {
        "x": 19.17,
        "y": 25.3
    },
    {
        "x": 19.18,
        "y": 25.3
    },
    {
        "x": 19.19,
        "y": 25.3
    },
    {
        "x": 19.2,
        "y": 25.31
    },
    {
        "x": 19.21,
        "y": 25.31
    },
    {
        "x": 19.22,
        "y": 25.32
    },
    {
        "x": 19.23,
        "y": 25.32
    },
    {
        "x": 19.24,
        "y": 25.32
    },
    {
        "x": 19.25,
        "y": 25.33
    },
    {
        "x": 19.26,
        "y": 25.33
    },
    {
        "x": 19.27,
        "y": 25.33
    },
    {
        "x": 19.28,
        "y": 25.34
    },
    {
        "x": 19.29,
        "y": 25.34
    },
    {
        "x": 19.3,
        "y": 25.34
    },
    {
        "x": 19.31,
        "y": 25.36
    },
    {
        "x": 19.32,
        "y": 25.36
    },
    {
        "x": 19.33,
        "y": 25.36
    },
    {
        "x": 19.34,
        "y": 25.37
    },
    {
        "x": 19.35,
        "y": 25.37
    },
    {
        "x": 19.36,
        "y": 25.37
    },
    {
        "x": 19.37,
        "y": 25.38
    },
    {
        "x": 19.38,
        "y": 25.38
    },
    {
        "x": 19.39,
        "y": 25.39
    },
    {
        "x": 19.4,
        "y": 25.39
    },
    {
        "x": 19.41,
        "y": 25.39
    },
    {
        "x": 19.42,
        "y": 25.4
    },
    {
        "x": 19.43,
        "y": 25.4
    },
    {
        "x": 19.44,
        "y": 25.4
    },
    {
        "x": 19.45,
        "y": 25.41
    },
    {
        "x": 19.46,
        "y": 25.41
    },
    {
        "x": 19.47,
        "y": 25.42
    },
    {
        "x": 19.48,
        "y": 25.42
    },
    {
        "x": 19.49,
        "y": 25.42
    },
    {
        "x": 19.5,
        "y": 25.42
    },
    {
        "x": 19.51,
        "y": 25.43
    },
    {
        "x": 19.52,
        "y": 25.43
    },
    {
        "x": 19.53,
        "y": 25.44
    },
    {
        "x": 19.54,
        "y": 25.44
    },
    {
        "x": 19.55,
        "y": 25.44
    },
    {
        "x": 19.56,
        "y": 25.45
    },
    {
        "x": 19.57,
        "y": 25.45
    },
    {
        "x": 19.58,
        "y": 25.47
    },
    {
        "x": 19.59,
        "y": 25.47
    },
    {
        "x": 19.6,
        "y": 25.47
    },
    {
        "x": 19.61,
        "y": 25.47
    },
    {
        "x": 19.62,
        "y": 25.48
    },
    {
        "x": 19.63,
        "y": 25.49
    },
    {
        "x": 19.64,
        "y": 25.49
    },
    {
        "x": 19.65,
        "y": 25.49
    },
    {
        "x": 19.66,
        "y": 25.5
    },
    {
        "x": 19.67,
        "y": 25.5
    },
    {
        "x": 19.68,
        "y": 25.5
    },
    {
        "x": 19.69,
        "y": 25.51
    },
    {
        "x": 19.7,
        "y": 25.51
    },
    {
        "x": 19.71,
        "y": 25.51
    },
    {
        "x": 19.72,
        "y": 25.52
    },
    {
        "x": 19.73,
        "y": 25.52
    },
    {
        "x": 19.74,
        "y": 25.52
    },
    {
        "x": 19.75,
        "y": 25.53
    },
    {
        "x": 19.76,
        "y": 25.53
    },
    {
        "x": 19.77,
        "y": 25.53
    },
    {
        "x": 19.78,
        "y": 25.54
    },
    {
        "x": 19.79,
        "y": 25.54
    },
    {
        "x": 19.8,
        "y": 25.54
    },
    {
        "x": 19.81,
        "y": 25.55
    },
    {
        "x": 19.82,
        "y": 25.55
    },
    {
        "x": 19.83,
        "y": 25.55
    },
    {
        "x": 19.84,
        "y": 25.56
    },
    {
        "x": 19.85,
        "y": 25.56
    },
    {
        "x": 19.86,
        "y": 25.58
    },
    {
        "x": 19.87,
        "y": 25.58
    },
    {
        "x": 19.88,
        "y": 25.58
    },
    {
        "x": 19.89,
        "y": 25.58
    },
    {
        "x": 19.9,
        "y": 25.59
    },
    {
        "x": 19.91,
        "y": 25.59
    },
    {
        "x": 19.92,
        "y": 25.59
    },
    {
        "x": 19.93,
        "y": 25.6
    },
    {
        "x": 19.94,
        "y": 25.6
    },
    {
        "x": 19.95,
        "y": 25.61
    },
    {
        "x": 19.96,
        "y": 25.61
    },
    {
        "x": 19.97,
        "y": 25.61
    },
    {
        "x": 19.98,
        "y": 25.61
    },
    {
        "x": 19.99,
        "y": 25.62
    },
    {
        "x": 20,
        "y": 25.62
    },
    {
        "x": 20.01,
        "y": 25.63
    },
    {
        "x": 20.02,
        "y": 25.63
    },
    {
        "x": 20.03,
        "y": 25.63
    },
    {
        "x": 20.04,
        "y": 25.64
    },
    {
        "x": 20.05,
        "y": 25.64
    },
    {
        "x": 20.06,
        "y": 25.64
    },
    {
        "x": 20.07,
        "y": 25.64
    },
    {
        "x": 20.08,
        "y": 25.65
    },
    {
        "x": 20.09,
        "y": 25.65
    },
    {
        "x": 20.1,
        "y": 25.66
    },
    {
        "x": 20.11,
        "y": 25.66
    },
    {
        "x": 20.12,
        "y": 25.66
    },
    {
        "x": 20.13,
        "y": 25.67
    },
    {
        "x": 20.14,
        "y": 25.67
    },
    {
        "x": 20.15,
        "y": 25.67
    },
    {
        "x": 20.16,
        "y": 25.67
    },
    {
        "x": 20.17,
        "y": 25.69
    },
    {
        "x": 20.18,
        "y": 25.69
    },
    {
        "x": 20.19,
        "y": 25.69
    },
    {
        "x": 20.2,
        "y": 25.7
    },
    {
        "x": 20.21,
        "y": 25.7
    },
    {
        "x": 20.22,
        "y": 25.7
    },
    {
        "x": 20.23,
        "y": 25.7
    },
    {
        "x": 20.24,
        "y": 25.71
    },
    {
        "x": 20.25,
        "y": 25.71
    },
    {
        "x": 20.26,
        "y": 25.71
    },
    {
        "x": 20.27,
        "y": 25.72
    },
    {
        "x": 20.28,
        "y": 25.72
    },
    {
        "x": 20.29,
        "y": 25.72
    },
    {
        "x": 20.3,
        "y": 25.72
    },
    {
        "x": 20.31,
        "y": 25.73
    },
    {
        "x": 20.32,
        "y": 25.74
    },
    {
        "x": 20.33,
        "y": 25.74
    },
    {
        "x": 20.34,
        "y": 25.74
    },
    {
        "x": 20.35,
        "y": 25.74
    },
    {
        "x": 20.36,
        "y": 25.75
    },
    {
        "x": 20.37,
        "y": 25.75
    },
    {
        "x": 20.38,
        "y": 25.75
    },
    {
        "x": 20.39,
        "y": 25.75
    },
    {
        "x": 20.4,
        "y": 25.76
    },
    {
        "x": 20.41,
        "y": 25.76
    },
    {
        "x": 20.42,
        "y": 25.77
    },
    {
        "x": 20.43,
        "y": 25.77
    },
    {
        "x": 20.44,
        "y": 25.77
    },
    {
        "x": 20.45,
        "y": 25.77
    },
    {
        "x": 20.46,
        "y": 25.78
    },
    {
        "x": 20.47,
        "y": 25.78
    },
    {
        "x": 20.48,
        "y": 25.8
    },
    {
        "x": 20.49,
        "y": 25.8
    },
    {
        "x": 20.5,
        "y": 25.8
    },
    {
        "x": 20.51,
        "y": 25.8
    },
    {
        "x": 20.52,
        "y": 25.81
    },
    {
        "x": 20.53,
        "y": 25.81
    },
    {
        "x": 20.54,
        "y": 25.81
    },
    {
        "x": 20.55,
        "y": 25.82
    },
    {
        "x": 20.56,
        "y": 25.82
    },
    {
        "x": 20.57,
        "y": 25.82
    },
    {
        "x": 20.58,
        "y": 25.83
    },
    {
        "x": 20.59,
        "y": 25.83
    },
    {
        "x": 20.6,
        "y": 25.83
    },
    {
        "x": 20.61,
        "y": 25.83
    },
    {
        "x": 20.62,
        "y": 25.84
    },
    {
        "x": 20.63,
        "y": 25.84
    },
    {
        "x": 20.64,
        "y": 25.84
    },
    {
        "x": 20.65,
        "y": 25.85
    },
    {
        "x": 20.66,
        "y": 25.85
    },
    {
        "x": 20.67,
        "y": 25.85
    },
    {
        "x": 20.68,
        "y": 25.86
    },
    {
        "x": 20.69,
        "y": 25.86
    },
    {
        "x": 20.7,
        "y": 25.86
    },
    {
        "x": 20.71,
        "y": 25.87
    },
    {
        "x": 20.72,
        "y": 25.87
    },
    {
        "x": 20.73,
        "y": 25.87
    },
    {
        "x": 20.74,
        "y": 25.88
    },
    {
        "x": 20.75,
        "y": 25.88
    },
    {
        "x": 20.76,
        "y": 25.88
    },
    {
        "x": 20.77,
        "y": 25.89
    },
    {
        "x": 20.78,
        "y": 25.89
    },
    {
        "x": 20.79,
        "y": 25.89
    },
    {
        "x": 20.8,
        "y": 25.91
    },
    {
        "x": 20.81,
        "y": 25.91
    },
    {
        "x": 20.82,
        "y": 25.91
    },
    {
        "x": 20.83,
        "y": 25.91
    },
    {
        "x": 20.84,
        "y": 25.91
    },
    {
        "x": 20.85,
        "y": 25.92
    },
    {
        "x": 20.86,
        "y": 25.92
    },
    {
        "x": 20.87,
        "y": 25.93
    },
    {
        "x": 20.88,
        "y": 25.93
    },
    {
        "x": 20.89,
        "y": 25.93
    },
    {
        "x": 20.9,
        "y": 25.93
    },
    {
        "x": 20.91,
        "y": 25.94
    },
    {
        "x": 20.92,
        "y": 25.94
    },
    {
        "x": 20.93,
        "y": 25.94
    },
    {
        "x": 20.94,
        "y": 25.95
    },
    {
        "x": 20.95,
        "y": 25.95
    },
    {
        "x": 20.96,
        "y": 25.95
    },
    {
        "x": 20.97,
        "y": 25.95
    },
    {
        "x": 20.98,
        "y": 25.96
    },
    {
        "x": 20.99,
        "y": 25.96
    },
    {
        "x": 21,
        "y": 25.96
    },
    {
        "x": 21.01,
        "y": 25.97
    },
    {
        "x": 21.02,
        "y": 25.97
    },
    {
        "x": 21.03,
        "y": 25.97
    },
    {
        "x": 21.04,
        "y": 25.98
    },
    {
        "x": 21.05,
        "y": 25.98
    },
    {
        "x": 21.06,
        "y": 25.98
    },
    {
        "x": 21.07,
        "y": 25.98
    },
    {
        "x": 21.08,
        "y": 25.99
    },
    {
        "x": 21.09,
        "y": 25.99
    },
    {
        "x": 21.1,
        "y": 25.99
    },
    {
        "x": 21.11,
        "y": 25.99
    },
    {
        "x": 21.12,
        "y": 26
    },
    {
        "x": 21.13,
        "y": 26
    },
    {
        "x": 21.14,
        "y": 26
    },
    {
        "x": 21.15,
        "y": 26.02
    },
    {
        "x": 21.16,
        "y": 26.02
    },
    {
        "x": 21.17,
        "y": 26.02
    },
    {
        "x": 21.18,
        "y": 26.02
    },
    {
        "x": 21.19,
        "y": 26.03
    },
    {
        "x": 21.2,
        "y": 26.03
    },
    {
        "x": 21.21,
        "y": 26.03
    },
    {
        "x": 21.22,
        "y": 26.04
    },
    {
        "x": 21.23,
        "y": 26.04
    },
    {
        "x": 21.24,
        "y": 26.04
    },
    {
        "x": 21.25,
        "y": 26.05
    },
    {
        "x": 21.26,
        "y": 26.05
    },
    {
        "x": 21.27,
        "y": 26.05
    },
    {
        "x": 21.28,
        "y": 26.06
    },
    {
        "x": 21.29,
        "y": 26.06
    },
    {
        "x": 21.3,
        "y": 26.06
    },
    {
        "x": 21.31,
        "y": 26.06
    },
    {
        "x": 21.32,
        "y": 26.07
    },
    {
        "x": 21.33,
        "y": 26.07
    },
    {
        "x": 21.34,
        "y": 26.07
    },
    {
        "x": 21.35,
        "y": 26.07
    },
    {
        "x": 21.36,
        "y": 26.07
    },
    {
        "x": 21.37,
        "y": 26.08
    },
    {
        "x": 21.38,
        "y": 26.08
    },
    {
        "x": 21.39,
        "y": 26.08
    },
    {
        "x": 21.4,
        "y": 26.09
    },
    {
        "x": 21.41,
        "y": 26.09
    },
    {
        "x": 21.42,
        "y": 26.09
    },
    {
        "x": 21.43,
        "y": 26.09
    },
    {
        "x": 21.44,
        "y": 26.1
    },
    {
        "x": 21.45,
        "y": 26.1
    },
    {
        "x": 21.46,
        "y": 26.11
    },
    {
        "x": 21.47,
        "y": 26.11
    },
    {
        "x": 21.48,
        "y": 26.11
    },
    {
        "x": 21.49,
        "y": 26.11
    },
    {
        "x": 21.5,
        "y": 26.11
    },
    {
        "x": 21.51,
        "y": 26.13
    },
    {
        "x": 21.52,
        "y": 26.13
    },
    {
        "x": 21.53,
        "y": 26.13
    },
    {
        "x": 21.54,
        "y": 26.14
    },
    {
        "x": 21.55,
        "y": 26.14
    },
    {
        "x": 21.56,
        "y": 26.14
    },
    {
        "x": 21.57,
        "y": 26.14
    },
    {
        "x": 21.58,
        "y": 26.14
    },
    {
        "x": 21.59,
        "y": 26.15
    },
    {
        "x": 21.6,
        "y": 26.15
    },
    {
        "x": 21.61,
        "y": 26.15
    },
    {
        "x": 21.62,
        "y": 26.15
    },
    {
        "x": 21.63,
        "y": 26.15
    },
    {
        "x": 21.64,
        "y": 26.16
    },
    {
        "x": 21.65,
        "y": 26.16
    },
    {
        "x": 21.66,
        "y": 26.17
    },
    {
        "x": 21.67,
        "y": 26.17
    },
    {
        "x": 21.68,
        "y": 26.17
    },
    {
        "x": 21.69,
        "y": 26.17
    },
    {
        "x": 21.7,
        "y": 26.18
    },
    {
        "x": 21.71,
        "y": 26.18
    },
    {
        "x": 21.72,
        "y": 26.18
    },
    {
        "x": 21.73,
        "y": 26.18
    },
    {
        "x": 21.74,
        "y": 26.19
    },
    {
        "x": 21.75,
        "y": 26.19
    },
    {
        "x": 21.76,
        "y": 26.19
    },
    {
        "x": 21.77,
        "y": 26.19
    },
    {
        "x": 21.78,
        "y": 26.2
    },
    {
        "x": 21.79,
        "y": 26.2
    },
    {
        "x": 21.8,
        "y": 26.2
    },
    {
        "x": 21.81,
        "y": 26.2
    },
    {
        "x": 21.82,
        "y": 26.21
    },
    {
        "x": 21.83,
        "y": 26.21
    },
    {
        "x": 21.84,
        "y": 26.21
    },
    {
        "x": 21.85,
        "y": 26.21
    },
    {
        "x": 21.86,
        "y": 26.22
    },
    {
        "x": 21.87,
        "y": 26.22
    },
    {
        "x": 21.88,
        "y": 26.22
    },
    {
        "x": 21.89,
        "y": 26.24
    },
    {
        "x": 21.9,
        "y": 26.24
    },
    {
        "x": 21.91,
        "y": 26.24
    },
    {
        "x": 21.92,
        "y": 26.24
    },
    {
        "x": 21.93,
        "y": 26.24
    },
    {
        "x": 21.94,
        "y": 26.25
    },
    {
        "x": 21.95,
        "y": 26.25
    },
    {
        "x": 21.96,
        "y": 26.25
    },
    {
        "x": 21.97,
        "y": 26.26
    },
    {
        "x": 21.98,
        "y": 26.26
    },
    {
        "x": 21.99,
        "y": 26.26
    },
    {
        "x": 22,
        "y": 26.26
    },
    {
        "x": 22.01,
        "y": 26.27
    },
    {
        "x": 22.02,
        "y": 26.27
    },
    {
        "x": 22.03,
        "y": 26.27
    },
    {
        "x": 22.04,
        "y": 26.27
    },
    {
        "x": 22.05,
        "y": 26.28
    },
    {
        "x": 22.06,
        "y": 26.28
    },
    {
        "x": 22.07,
        "y": 26.28
    },
    {
        "x": 22.08,
        "y": 26.29
    },
    {
        "x": 22.09,
        "y": 26.29
    },
    {
        "x": 22.1,
        "y": 26.29
    },
    {
        "x": 22.11,
        "y": 26.29
    },
    {
        "x": 22.12,
        "y": 26.3
    },
    {
        "x": 22.13,
        "y": 26.3
    },
    {
        "x": 22.14,
        "y": 26.3
    },
    {
        "x": 22.15,
        "y": 26.3
    },
    {
        "x": 22.16,
        "y": 26.3
    },
    {
        "x": 22.17,
        "y": 26.31
    },
    {
        "x": 22.18,
        "y": 26.31
    },
    {
        "x": 22.19,
        "y": 26.31
    },
    {
        "x": 22.2,
        "y": 26.31
    },
    {
        "x": 22.21,
        "y": 26.32
    },
    {
        "x": 22.22,
        "y": 26.32
    },
    {
        "x": 22.23,
        "y": 26.32
    },
    {
        "x": 22.24,
        "y": 26.33
    },
    {
        "x": 22.25,
        "y": 26.33
    },
    {
        "x": 22.26,
        "y": 26.33
    },
    {
        "x": 22.27,
        "y": 26.33
    },
    {
        "x": 22.28,
        "y": 26.33
    },
    {
        "x": 22.29,
        "y": 26.35
    },
    {
        "x": 22.3,
        "y": 26.35
    },
    {
        "x": 22.31,
        "y": 26.35
    },
    {
        "x": 22.32,
        "y": 26.35
    },
    {
        "x": 22.33,
        "y": 26.36
    },
    {
        "x": 22.34,
        "y": 26.36
    },
    {
        "x": 22.35,
        "y": 26.36
    },
    {
        "x": 22.36,
        "y": 26.36
    },
    {
        "x": 22.37,
        "y": 26.37
    },
    {
        "x": 22.38,
        "y": 26.37
    },
    {
        "x": 22.39,
        "y": 26.37
    },
    {
        "x": 22.4,
        "y": 26.38
    },
    {
        "x": 22.41,
        "y": 26.38
    },
    {
        "x": 22.42,
        "y": 26.38
    },
    {
        "x": 22.43,
        "y": 26.38
    },
    {
        "x": 22.44,
        "y": 26.38
    },
    {
        "x": 22.45,
        "y": 26.39
    },
    {
        "x": 22.46,
        "y": 26.39
    },
    {
        "x": 22.47,
        "y": 26.39
    },
    {
        "x": 22.48,
        "y": 26.39
    },
    {
        "x": 22.49,
        "y": 26.39
    },
    {
        "x": 22.5,
        "y": 26.4
    },
    {
        "x": 22.51,
        "y": 26.4
    },
    {
        "x": 22.52,
        "y": 26.41
    },
    {
        "x": 22.53,
        "y": 26.41
    },
    {
        "x": 22.54,
        "y": 26.41
    },
    {
        "x": 22.55,
        "y": 26.41
    },
    {
        "x": 22.56,
        "y": 26.42
    },
    {
        "x": 22.57,
        "y": 26.42
    },
    {
        "x": 22.58,
        "y": 26.42
    },
    {
        "x": 22.59,
        "y": 26.42
    },
    {
        "x": 22.6,
        "y": 26.42
    },
    {
        "x": 22.61,
        "y": 26.43
    },
    {
        "x": 22.62,
        "y": 26.43
    },
    {
        "x": 22.63,
        "y": 26.43
    },
    {
        "x": 22.64,
        "y": 26.43
    },
    {
        "x": 22.65,
        "y": 26.43
    },
    {
        "x": 22.66,
        "y": 26.44
    },
    {
        "x": 22.67,
        "y": 26.44
    },
    {
        "x": 22.68,
        "y": 26.44
    },
    {
        "x": 22.69,
        "y": 26.44
    },
    {
        "x": 22.7,
        "y": 26.44
    },
    {
        "x": 22.71,
        "y": 26.46
    },
    {
        "x": 22.72,
        "y": 26.46
    },
    {
        "x": 22.73,
        "y": 26.46
    },
    {
        "x": 22.74,
        "y": 26.46
    },
    {
        "x": 22.75,
        "y": 26.47
    },
    {
        "x": 22.76,
        "y": 26.47
    },
    {
        "x": 22.77,
        "y": 26.47
    },
    {
        "x": 22.78,
        "y": 26.47
    },
    {
        "x": 22.79,
        "y": 26.48
    },
    {
        "x": 22.8,
        "y": 26.48
    },
    {
        "x": 22.81,
        "y": 26.48
    },
    {
        "x": 22.82,
        "y": 26.48
    },
    {
        "x": 22.83,
        "y": 26.48
    },
    {
        "x": 22.84,
        "y": 26.49
    },
    {
        "x": 22.85,
        "y": 26.49
    },
    {
        "x": 22.86,
        "y": 26.49
    },
    {
        "x": 22.87,
        "y": 26.49
    },
    {
        "x": 22.88,
        "y": 26.5
    },
    {
        "x": 22.89,
        "y": 26.5
    },
    {
        "x": 22.9,
        "y": 26.5
    },
    {
        "x": 22.91,
        "y": 26.5
    },
    {
        "x": 22.92,
        "y": 26.5
    },
    {
        "x": 22.93,
        "y": 26.51
    },
    {
        "x": 22.94,
        "y": 26.51
    },
    {
        "x": 22.95,
        "y": 26.51
    },
    {
        "x": 22.96,
        "y": 26.51
    },
    {
        "x": 22.97,
        "y": 26.51
    },
    {
        "x": 22.98,
        "y": 26.52
    },
    {
        "x": 22.99,
        "y": 26.52
    },
    {
        "x": 23,
        "y": 26.52
    },
    {
        "x": 23.01,
        "y": 26.53
    },
    {
        "x": 23.02,
        "y": 26.53
    },
    {
        "x": 23.03,
        "y": 26.53
    },
    {
        "x": 23.04,
        "y": 26.53
    },
    {
        "x": 23.05,
        "y": 26.54
    },
    {
        "x": 23.06,
        "y": 26.54
    },
    {
        "x": 23.07,
        "y": 26.54
    },
    {
        "x": 23.08,
        "y": 26.54
    },
    {
        "x": 23.09,
        "y": 26.55
    },
    {
        "x": 23.1,
        "y": 26.55
    },
    {
        "x": 23.11,
        "y": 26.55
    },
    {
        "x": 23.12,
        "y": 26.55
    },
    {
        "x": 23.13,
        "y": 26.55
    },
    {
        "x": 23.14,
        "y": 26.57
    },
    {
        "x": 23.15,
        "y": 26.57
    },
    {
        "x": 23.16,
        "y": 26.57
    },
    {
        "x": 23.17,
        "y": 26.57
    },
    {
        "x": 23.18,
        "y": 26.57
    },
    {
        "x": 23.19,
        "y": 26.58
    },
    {
        "x": 23.2,
        "y": 26.58
    },
    {
        "x": 23.21,
        "y": 26.58
    },
    {
        "x": 23.22,
        "y": 26.58
    },
    {
        "x": 23.23,
        "y": 26.58
    },
    {
        "x": 23.24,
        "y": 26.59
    },
    {
        "x": 23.25,
        "y": 26.58
    },
    {
        "x": 23.26,
        "y": 26.59
    },
    {
        "x": 23.27,
        "y": 26.59
    },
    {
        "x": 23.28,
        "y": 26.59
    },
    {
        "x": 23.29,
        "y": 26.59
    },
    {
        "x": 23.3,
        "y": 26.6
    },
    {
        "x": 23.31,
        "y": 26.6
    },
    {
        "x": 23.32,
        "y": 26.6
    },
    {
        "x": 23.33,
        "y": 26.61
    },
    {
        "x": 23.34,
        "y": 26.6
    },
    {
        "x": 23.35,
        "y": 26.61
    },
    {
        "x": 23.36,
        "y": 26.61
    },
    {
        "x": 23.37,
        "y": 26.61
    },
    {
        "x": 23.38,
        "y": 26.62
    },
    {
        "x": 23.39,
        "y": 26.62
    },
    {
        "x": 23.4,
        "y": 26.62
    },
    {
        "x": 23.41,
        "y": 26.62
    },
    {
        "x": 23.42,
        "y": 26.63
    },
    {
        "x": 23.43,
        "y": 26.63
    },
    {
        "x": 23.44,
        "y": 26.63
    },
    {
        "x": 23.45,
        "y": 26.63
    },
    {
        "x": 23.46,
        "y": 26.63
    },
    {
        "x": 23.47,
        "y": 26.63
    },
    {
        "x": 23.48,
        "y": 26.63
    },
    {
        "x": 23.49,
        "y": 26.63
    },
    {
        "x": 23.5,
        "y": 26.64
    },
    {
        "x": 23.51,
        "y": 26.64
    },
    {
        "x": 23.52,
        "y": 26.64
    },
    {
        "x": 23.53,
        "y": 26.65
    },
    {
        "x": 23.54,
        "y": 26.65
    },
    {
        "x": 23.55,
        "y": 26.65
    },
    {
        "x": 23.56,
        "y": 26.65
    },
    {
        "x": 23.57,
        "y": 26.65
    },
    {
        "x": 23.58,
        "y": 26.66
    },
    {
        "x": 23.59,
        "y": 26.66
    },
    {
        "x": 23.6,
        "y": 26.66
    },
    {
        "x": 23.61,
        "y": 26.66
    },
    {
        "x": 23.62,
        "y": 26.68
    },
    {
        "x": 23.63,
        "y": 26.68
    },
    {
        "x": 23.64,
        "y": 26.68
    },
    {
        "x": 23.65,
        "y": 26.68
    },
    {
        "x": 23.66,
        "y": 26.69
    },
    {
        "x": 23.67,
        "y": 26.69
    },
    {
        "x": 23.68,
        "y": 26.69
    },
    {
        "x": 23.69,
        "y": 26.69
    },
    {
        "x": 23.7,
        "y": 26.7
    },
    {
        "x": 23.71,
        "y": 26.7
    },
    {
        "x": 23.72,
        "y": 26.7
    },
    {
        "x": 23.73,
        "y": 26.7
    },
    {
        "x": 23.74,
        "y": 26.7
    },
    {
        "x": 23.75,
        "y": 26.71
    },
    {
        "x": 23.76,
        "y": 26.71
    },
    {
        "x": 23.77,
        "y": 26.71
    },
    {
        "x": 23.78,
        "y": 26.71
    },
    {
        "x": 23.79,
        "y": 26.71
    },
    {
        "x": 23.8,
        "y": 26.71
    },
    {
        "x": 23.81,
        "y": 26.72
    },
    {
        "x": 23.82,
        "y": 26.72
    },
    {
        "x": 23.83,
        "y": 26.72
    },
    {
        "x": 23.84,
        "y": 26.72
    },
    {
        "x": 23.85,
        "y": 26.73
    },
    {
        "x": 23.86,
        "y": 26.73
    },
    {
        "x": 23.87,
        "y": 26.73
    },
    {
        "x": 23.88,
        "y": 26.73
    },
    {
        "x": 23.89,
        "y": 26.73
    },
    {
        "x": 23.9,
        "y": 26.73
    },
    {
        "x": 23.91,
        "y": 26.73
    },
    {
        "x": 23.92,
        "y": 26.74
    },
    {
        "x": 23.93,
        "y": 26.74
    },
    {
        "x": 23.94,
        "y": 26.74
    },
    {
        "x": 23.95,
        "y": 26.74
    },
    {
        "x": 23.96,
        "y": 26.75
    },
    {
        "x": 23.97,
        "y": 26.74
    },
    {
        "x": 23.98,
        "y": 26.75
    },
    {
        "x": 23.99,
        "y": 26.75
    },
    {
        "x": 24,
        "y": 26.75
    },
    {
        "x": 24.01,
        "y": 26.75
    },
    {
        "x": 24.02,
        "y": 26.76
    },
    {
        "x": 24.03,
        "y": 26.76
    },
    {
        "x": 24.04,
        "y": 26.76
    },
    {
        "x": 24.05,
        "y": 26.76
    },
    {
        "x": 24.06,
        "y": 26.76
    },
    {
        "x": 24.07,
        "y": 26.77
    },
    {
        "x": 24.08,
        "y": 26.77
    },
    {
        "x": 24.09,
        "y": 26.77
    },
    {
        "x": 24.1,
        "y": 26.77
    },
    {
        "x": 24.11,
        "y": 26.77
    },
    {
        "x": 24.12,
        "y": 26.79
    },
    {
        "x": 24.13,
        "y": 26.79
    },
    {
        "x": 24.14,
        "y": 26.79
    },
    {
        "x": 24.15,
        "y": 26.79
    },
    {
        "x": 24.16,
        "y": 26.8
    },
    {
        "x": 24.17,
        "y": 26.79
    },
    {
        "x": 24.18,
        "y": 26.8
    },
    {
        "x": 24.19,
        "y": 26.8
    },
    {
        "x": 24.2,
        "y": 26.8
    },
    {
        "x": 24.21,
        "y": 26.8
    },
    {
        "x": 24.22,
        "y": 26.8
    },
    {
        "x": 24.23,
        "y": 26.81
    },
    {
        "x": 24.24,
        "y": 26.81
    },
    {
        "x": 24.25,
        "y": 26.81
    },
    {
        "x": 24.26,
        "y": 26.81
    },
    {
        "x": 24.27,
        "y": 26.81
    },
    {
        "x": 24.28,
        "y": 26.82
    },
    {
        "x": 24.29,
        "y": 26.82
    },
    {
        "x": 24.3,
        "y": 26.82
    },
    {
        "x": 24.31,
        "y": 26.82
    },
    {
        "x": 24.32,
        "y": 26.82
    },
    {
        "x": 24.33,
        "y": 26.83
    },
    {
        "x": 24.34,
        "y": 26.83
    },
    {
        "x": 24.35,
        "y": 26.83
    },
    {
        "x": 24.36,
        "y": 26.83
    },
    {
        "x": 24.37,
        "y": 26.83
    },
    {
        "x": 24.38,
        "y": 26.84
    },
    {
        "x": 24.39,
        "y": 26.83
    },
    {
        "x": 24.4,
        "y": 26.84
    },
    {
        "x": 24.41,
        "y": 26.84
    },
    {
        "x": 24.42,
        "y": 26.84
    },
    {
        "x": 24.43,
        "y": 26.85
    },
    {
        "x": 24.44,
        "y": 26.85
    },
    {
        "x": 24.45,
        "y": 26.85
    },
    {
        "x": 24.46,
        "y": 26.85
    },
    {
        "x": 24.47,
        "y": 26.85
    },
    {
        "x": 24.48,
        "y": 26.85
    },
    {
        "x": 24.49,
        "y": 26.86
    },
    {
        "x": 24.5,
        "y": 26.86
    },
    {
        "x": 24.51,
        "y": 26.86
    },
    {
        "x": 24.52,
        "y": 26.86
    },
    {
        "x": 24.53,
        "y": 26.87
    },
    {
        "x": 24.54,
        "y": 26.87
    },
    {
        "x": 24.55,
        "y": 26.87
    },
    {
        "x": 24.56,
        "y": 26.87
    },
    {
        "x": 24.57,
        "y": 26.87
    },
    {
        "x": 24.58,
        "y": 26.87
    },
    {
        "x": 24.59,
        "y": 26.88
    },
    {
        "x": 24.6,
        "y": 26.88
    },
    {
        "x": 24.61,
        "y": 26.88
    },
    {
        "x": 24.62,
        "y": 26.88
    },
    {
        "x": 24.63,
        "y": 26.88
    },
    {
        "x": 24.64,
        "y": 26.88
    },
    {
        "x": 24.65,
        "y": 26.9
    },
    {
        "x": 24.66,
        "y": 26.9
    },
    {
        "x": 24.67,
        "y": 26.9
    },
    {
        "x": 24.68,
        "y": 26.9
    },
    {
        "x": 24.69,
        "y": 26.91
    },
    {
        "x": 24.7,
        "y": 26.9
    },
    {
        "x": 24.71,
        "y": 26.91
    },
    {
        "x": 24.72,
        "y": 26.91
    },
    {
        "x": 24.73,
        "y": 26.91
    },
    {
        "x": 24.74,
        "y": 26.91
    },
    {
        "x": 24.75,
        "y": 26.91
    },
    {
        "x": 24.76,
        "y": 26.92
    },
    {
        "x": 24.77,
        "y": 26.92
    },
    {
        "x": 24.78,
        "y": 26.92
    },
    {
        "x": 24.79,
        "y": 26.92
    },
    {
        "x": 24.8,
        "y": 26.92
    },
    {
        "x": 24.81,
        "y": 26.92
    },
    {
        "x": 24.82,
        "y": 26.93
    },
    {
        "x": 24.83,
        "y": 26.93
    },
    {
        "x": 24.84,
        "y": 26.93
    },
    {
        "x": 24.85,
        "y": 26.93
    },
    {
        "x": 24.86,
        "y": 26.93
    },
    {
        "x": 24.87,
        "y": 26.94
    },
    {
        "x": 24.88,
        "y": 26.94
    },
    {
        "x": 24.89,
        "y": 26.94
    },
    {
        "x": 24.9,
        "y": 26.94
    },
    {
        "x": 24.91,
        "y": 26.94
    },
    {
        "x": 24.92,
        "y": 26.95
    },
    {
        "x": 24.93,
        "y": 26.95
    },
    {
        "x": 24.94,
        "y": 26.95
    },
    {
        "x": 24.95,
        "y": 26.95
    },
    {
        "x": 24.96,
        "y": 26.95
    },
    {
        "x": 24.97,
        "y": 26.95
    },
    {
        "x": 24.98,
        "y": 26.96
    },
    {
        "x": 24.99,
        "y": 26.96
    },
    {
        "x": 25,
        "y": 26.96
    },
    {
        "x": 25.01,
        "y": 26.96
    },
    {
        "x": 25.02,
        "y": 26.96
    },
    {
        "x": 25.03,
        "y": 26.97
    },
    {
        "x": 25.04,
        "y": 26.97
    },
    {
        "x": 25.05,
        "y": 26.97
    },
    {
        "x": 25.06,
        "y": 26.97
    },
    {
        "x": 25.07,
        "y": 26.97
    },
    {
        "x": 25.08,
        "y": 26.97
    },
    {
        "x": 25.09,
        "y": 26.97
    },
    {
        "x": 25.1,
        "y": 26.98
    },
    {
        "x": 25.11,
        "y": 26.98
    },
    {
        "x": 25.12,
        "y": 26.98
    },
    {
        "x": 25.13,
        "y": 26.98
    },
    {
        "x": 25.14,
        "y": 26.98
    },
    {
        "x": 25.15,
        "y": 26.99
    },
    {
        "x": 25.16,
        "y": 26.99
    },
    {
        "x": 25.17,
        "y": 26.99
    },
    {
        "x": 25.18,
        "y": 26.99
    },
    {
        "x": 25.19,
        "y": 26.99
    },
    {
        "x": 25.2,
        "y": 26.99
    },
    {
        "x": 25.21,
        "y": 27.01
    },
    {
        "x": 25.22,
        "y": 27.01
    },
    {
        "x": 25.23,
        "y": 27.01
    },
    {
        "x": 25.24,
        "y": 27.01
    },
    {
        "x": 25.25,
        "y": 27.01
    },
    {
        "x": 25.26,
        "y": 27.01
    },
    {
        "x": 25.27,
        "y": 27.01
    },
    {
        "x": 25.28,
        "y": 27.02
    },
    {
        "x": 25.29,
        "y": 27.02
    },
    {
        "x": 25.3,
        "y": 27.02
    },
    {
        "x": 25.31,
        "y": 27.02
    },
    {
        "x": 25.32,
        "y": 27.02
    },
    {
        "x": 25.33,
        "y": 27.02
    },
    {
        "x": 25.34,
        "y": 27.03
    },
    {
        "x": 25.35,
        "y": 27.03
    },
    {
        "x": 25.36,
        "y": 27.03
    },
    {
        "x": 25.37,
        "y": 27.03
    },
    {
        "x": 25.38,
        "y": 27.03
    },
    {
        "x": 25.39,
        "y": 27.03
    },
    {
        "x": 25.4,
        "y": 27.04
    },
    {
        "x": 25.41,
        "y": 27.04
    },
    {
        "x": 25.42,
        "y": 27.04
    },
    {
        "x": 25.43,
        "y": 27.04
    },
    {
        "x": 25.44,
        "y": 27.04
    },
    {
        "x": 25.45,
        "y": 27.04
    },
    {
        "x": 25.46,
        "y": 27.05
    },
    {
        "x": 25.47,
        "y": 27.04
    },
    {
        "x": 25.48,
        "y": 27.05
    },
    {
        "x": 25.49,
        "y": 27.05
    },
    {
        "x": 25.5,
        "y": 27.05
    },
    {
        "x": 25.51,
        "y": 27.05
    },
    {
        "x": 25.52,
        "y": 27.06
    },
    {
        "x": 25.53,
        "y": 27.05
    },
    {
        "x": 25.54,
        "y": 27.06
    },
    {
        "x": 25.55,
        "y": 27.06
    },
    {
        "x": 25.56,
        "y": 27.06
    },
    {
        "x": 25.57,
        "y": 27.07
    },
    {
        "x": 25.58,
        "y": 27.07
    },
    {
        "x": 25.59,
        "y": 27.07
    },
    {
        "x": 25.6,
        "y": 27.07
    },
    {
        "x": 25.61,
        "y": 27.07
    },
    {
        "x": 25.62,
        "y": 27.07
    },
    {
        "x": 25.63,
        "y": 27.07
    },
    {
        "x": 25.64,
        "y": 27.08
    },
    {
        "x": 25.65,
        "y": 27.08
    },
    {
        "x": 25.66,
        "y": 27.08
    },
    {
        "x": 25.67,
        "y": 27.08
    },
    {
        "x": 25.68,
        "y": 27.08
    },
    {
        "x": 25.69,
        "y": 27.08
    },
    {
        "x": 25.7,
        "y": 27.08
    },
    {
        "x": 25.71,
        "y": 27.09
    },
    {
        "x": 25.72,
        "y": 27.09
    },
    {
        "x": 25.73,
        "y": 27.09
    },
    {
        "x": 25.74,
        "y": 27.09
    },
    {
        "x": 25.75,
        "y": 27.09
    },
    {
        "x": 25.76,
        "y": 27.09
    },
    {
        "x": 25.77,
        "y": 27.09
    },
    {
        "x": 25.78,
        "y": 27.1
    },
    {
        "x": 25.79,
        "y": 27.1
    },
    {
        "x": 25.8,
        "y": 27.1
    },
    {
        "x": 25.81,
        "y": 27.1
    },
    {
        "x": 25.82,
        "y": 27.12
    },
    {
        "x": 25.83,
        "y": 27.1
    },
    {
        "x": 25.84,
        "y": 27.12
    },
    {
        "x": 25.85,
        "y": 27.12
    },
    {
        "x": 25.86,
        "y": 27.12
    },
    {
        "x": 25.87,
        "y": 27.12
    },
    {
        "x": 25.88,
        "y": 27.13
    },
    {
        "x": 25.89,
        "y": 27.12
    },
    {
        "x": 25.9,
        "y": 27.13
    },
    {
        "x": 25.91,
        "y": 27.13
    },
    {
        "x": 25.92,
        "y": 27.13
    },
    {
        "x": 25.93,
        "y": 27.13
    },
    {
        "x": 25.94,
        "y": 27.13
    },
    {
        "x": 25.95,
        "y": 27.14
    },
    {
        "x": 25.96,
        "y": 27.14
    },
    {
        "x": 25.97,
        "y": 27.14
    },
    {
        "x": 25.98,
        "y": 27.14
    },
    {
        "x": 25.99,
        "y": 27.14
    },
    {
        "x": 26,
        "y": 27.14
    },
    {
        "x": 26.01,
        "y": 27.14
    },
    {
        "x": 26.02,
        "y": 27.15
    },
    {
        "x": 26.03,
        "y": 27.15
    },
    {
        "x": 26.04,
        "y": 27.15
    },
    {
        "x": 26.05,
        "y": 27.15
    },
    {
        "x": 26.06,
        "y": 27.15
    },
    {
        "x": 26.07,
        "y": 27.15
    },
    {
        "x": 26.08,
        "y": 27.16
    },
    {
        "x": 26.09,
        "y": 27.16
    },
    {
        "x": 26.1,
        "y": 27.16
    },
    {
        "x": 26.11,
        "y": 27.16
    },
    {
        "x": 26.12,
        "y": 27.17
    },
    {
        "x": 26.13,
        "y": 27.16
    },
    {
        "x": 26.14,
        "y": 27.16
    },
    {
        "x": 26.15,
        "y": 27.17
    },
    {
        "x": 26.16,
        "y": 27.17
    },
    {
        "x": 26.17,
        "y": 27.17
    },
    {
        "x": 26.18,
        "y": 27.17
    },
    {
        "x": 26.19,
        "y": 27.17
    },
    {
        "x": 26.2,
        "y": 27.17
    },
    {
        "x": 26.21,
        "y": 27.17
    },
    {
        "x": 26.22,
        "y": 27.17
    },
    {
        "x": 26.23,
        "y": 27.18
    },
    {
        "x": 26.24,
        "y": 27.18
    },
    {
        "x": 26.25,
        "y": 27.18
    },
    {
        "x": 26.26,
        "y": 27.18
    },
    {
        "x": 26.27,
        "y": 27.18
    },
    {
        "x": 26.28,
        "y": 27.18
    },
    {
        "x": 26.29,
        "y": 27.19
    },
    {
        "x": 26.3,
        "y": 27.19
    },
    {
        "x": 26.31,
        "y": 27.19
    },
    {
        "x": 26.32,
        "y": 27.19
    },
    {
        "x": 26.33,
        "y": 27.19
    },
    {
        "x": 26.34,
        "y": 27.19
    },
    {
        "x": 26.35,
        "y": 27.19
    },
    {
        "x": 26.36,
        "y": 27.2
    },
    {
        "x": 26.37,
        "y": 27.2
    },
    {
        "x": 26.38,
        "y": 27.2
    },
    {
        "x": 26.39,
        "y": 27.2
    },
    {
        "x": 26.4,
        "y": 27.2
    },
    {
        "x": 26.41,
        "y": 27.2
    },
    {
        "x": 26.42,
        "y": 27.21
    },
    {
        "x": 26.43,
        "y": 27.2
    },
    {
        "x": 26.44,
        "y": 27.21
    },
    {
        "x": 26.45,
        "y": 27.21
    },
    {
        "x": 26.46,
        "y": 27.21
    },
    {
        "x": 26.47,
        "y": 27.21
    },
    {
        "x": 26.48,
        "y": 27.21
    },
    {
        "x": 26.49,
        "y": 27.21
    },
    {
        "x": 26.5,
        "y": 27.21
    },
    {
        "x": 26.51,
        "y": 27.21
    },
    {
        "x": 26.52,
        "y": 27.23
    },
    {
        "x": 26.53,
        "y": 27.23
    },
    {
        "x": 26.54,
        "y": 27.23
    },
    {
        "x": 26.55,
        "y": 27.23
    },
    {
        "x": 26.56,
        "y": 27.23
    },
    {
        "x": 26.57,
        "y": 27.23
    },
    {
        "x": 26.58,
        "y": 27.24
    },
    {
        "x": 26.59,
        "y": 27.24
    },
    {
        "x": 26.6,
        "y": 27.24
    },
    {
        "x": 26.61,
        "y": 27.24
    },
    {
        "x": 26.62,
        "y": 27.24
    },
    {
        "x": 26.63,
        "y": 27.24
    },
    {
        "x": 26.64,
        "y": 27.24
    },
    {
        "x": 26.65,
        "y": 27.25
    },
    {
        "x": 26.66,
        "y": 27.25
    },
    {
        "x": 26.67,
        "y": 27.25
    },
    {
        "x": 26.68,
        "y": 27.25
    },
    {
        "x": 26.69,
        "y": 27.25
    },
    {
        "x": 26.7,
        "y": 27.25
    },
    {
        "x": 26.71,
        "y": 27.25
    },
    {
        "x": 26.72,
        "y": 27.26
    },
    {
        "x": 26.73,
        "y": 27.26
    },
    {
        "x": 26.74,
        "y": 27.26
    },
    {
        "x": 26.75,
        "y": 27.26
    },
    {
        "x": 26.76,
        "y": 27.26
    },
    {
        "x": 26.77,
        "y": 27.26
    },
    {
        "x": 26.78,
        "y": 27.26
    },
    {
        "x": 26.79,
        "y": 27.26
    },
    {
        "x": 26.8,
        "y": 27.26
    },
    {
        "x": 26.81,
        "y": 27.26
    },
    {
        "x": 26.82,
        "y": 27.27
    },
    {
        "x": 26.83,
        "y": 27.27
    },
    {
        "x": 26.84,
        "y": 27.27
    },
    {
        "x": 26.85,
        "y": 27.27
    },
    {
        "x": 26.86,
        "y": 27.27
    },
    {
        "x": 26.87,
        "y": 27.27
    },
    {
        "x": 26.88,
        "y": 27.27
    },
    {
        "x": 26.89,
        "y": 27.27
    },
    {
        "x": 26.9,
        "y": 27.28
    },
    {
        "x": 26.91,
        "y": 27.28
    },
    {
        "x": 26.92,
        "y": 27.28
    },
    {
        "x": 26.93,
        "y": 27.28
    },
    {
        "x": 26.94,
        "y": 27.28
    },
    {
        "x": 26.95,
        "y": 27.28
    },
    {
        "x": 26.96,
        "y": 27.28
    },
    {
        "x": 26.97,
        "y": 27.29
    },
    {
        "x": 26.98,
        "y": 27.29
    },
    {
        "x": 26.99,
        "y": 27.29
    },
    {
        "x": 27,
        "y": 27.29
    },
    {
        "x": 27.01,
        "y": 27.29
    },
    {
        "x": 27.02,
        "y": 27.29
    },
    {
        "x": 27.03,
        "y": 27.3
    },
    {
        "x": 27.04,
        "y": 27.29
    },
    {
        "x": 27.05,
        "y": 27.3
    },
    {
        "x": 27.06,
        "y": 27.3
    },
    {
        "x": 27.07,
        "y": 27.3
    },
    {
        "x": 27.08,
        "y": 27.3
    },
    {
        "x": 27.09,
        "y": 27.3
    },
    {
        "x": 27.1,
        "y": 27.3
    },
    {
        "x": 27.11,
        "y": 27.3
    },
    {
        "x": 27.12,
        "y": 27.31
    },
    {
        "x": 27.13,
        "y": 27.31
    },
    {
        "x": 27.14,
        "y": 27.31
    },
    {
        "x": 27.15,
        "y": 27.31
    },
    {
        "x": 27.16,
        "y": 27.31
    },
    {
        "x": 27.17,
        "y": 27.32
    },
    {
        "x": 27.18,
        "y": 27.32
    },
    {
        "x": 27.19,
        "y": 27.31
    },
    {
        "x": 27.2,
        "y": 27.32
    },
    {
        "x": 27.21,
        "y": 27.32
    },
    {
        "x": 27.22,
        "y": 27.32
    },
    {
        "x": 27.23,
        "y": 27.32
    },
    {
        "x": 27.24,
        "y": 27.32
    },
    {
        "x": 27.25,
        "y": 27.32
    },
    {
        "x": 27.26,
        "y": 27.34
    },
    {
        "x": 27.27,
        "y": 27.34
    },
    {
        "x": 27.28,
        "y": 27.34
    },
    {
        "x": 27.29,
        "y": 27.34
    },
    {
        "x": 27.3,
        "y": 27.34
    },
    {
        "x": 27.31,
        "y": 27.34
    },
    {
        "x": 27.32,
        "y": 27.34
    },
    {
        "x": 27.33,
        "y": 27.34
    },
    {
        "x": 27.34,
        "y": 27.35
    },
    {
        "x": 27.35,
        "y": 27.35
    },
    {
        "x": 27.36,
        "y": 27.35
    },
    {
        "x": 27.37,
        "y": 27.35
    },
    {
        "x": 27.38,
        "y": 27.35
    },
    {
        "x": 27.39,
        "y": 27.35
    },
    {
        "x": 27.4,
        "y": 27.35
    },
    {
        "x": 27.41,
        "y": 27.35
    },
    {
        "x": 27.42,
        "y": 27.35
    },
    {
        "x": 27.43,
        "y": 27.36
    },
    {
        "x": 27.44,
        "y": 27.36
    },
    {
        "x": 27.45,
        "y": 27.36
    },
    {
        "x": 27.46,
        "y": 27.36
    },
    {
        "x": 27.47,
        "y": 27.36
    },
    {
        "x": 27.48,
        "y": 27.36
    },
    {
        "x": 27.49,
        "y": 27.36
    },
    {
        "x": 27.5,
        "y": 27.37
    },
    {
        "x": 27.51,
        "y": 27.36
    },
    {
        "x": 27.52,
        "y": 27.36
    },
    {
        "x": 27.53,
        "y": 27.37
    },
    {
        "x": 27.54,
        "y": 27.37
    },
    {
        "x": 27.55,
        "y": 27.37
    },
    {
        "x": 27.56,
        "y": 27.37
    },
    {
        "x": 27.57,
        "y": 27.37
    },
    {
        "x": 27.58,
        "y": 27.37
    },
    {
        "x": 27.59,
        "y": 27.38
    },
    {
        "x": 27.6,
        "y": 27.38
    },
    {
        "x": 27.61,
        "y": 27.38
    },
    {
        "x": 27.62,
        "y": 27.38
    },
    {
        "x": 27.63,
        "y": 27.38
    },
    {
        "x": 27.64,
        "y": 27.38
    },
    {
        "x": 27.65,
        "y": 27.38
    },
    {
        "x": 27.66,
        "y": 27.38
    },
    {
        "x": 27.67,
        "y": 27.39
    },
    {
        "x": 27.68,
        "y": 27.39
    },
    {
        "x": 27.69,
        "y": 27.39
    },
    {
        "x": 27.7,
        "y": 27.39
    },
    {
        "x": 27.71,
        "y": 27.39
    },
    {
        "x": 27.72,
        "y": 27.39
    },
    {
        "x": 27.73,
        "y": 27.39
    },
    {
        "x": 27.74,
        "y": 27.4
    },
    {
        "x": 27.75,
        "y": 27.4
    },
    {
        "x": 27.76,
        "y": 27.39
    },
    {
        "x": 27.77,
        "y": 27.4
    },
    {
        "x": 27.78,
        "y": 27.4
    },
    {
        "x": 27.79,
        "y": 27.4
    },
    {
        "x": 27.8,
        "y": 27.4
    },
    {
        "x": 27.81,
        "y": 27.4
    },
    {
        "x": 27.82,
        "y": 27.4
    },
    {
        "x": 27.83,
        "y": 27.4
    },
    {
        "x": 27.84,
        "y": 27.4
    },
    {
        "x": 27.85,
        "y": 27.41
    },
    {
        "x": 27.86,
        "y": 27.41
    },
    {
        "x": 27.87,
        "y": 27.41
    },
    {
        "x": 27.88,
        "y": 27.41
    },
    {
        "x": 27.89,
        "y": 27.41
    },
    {
        "x": 27.9,
        "y": 27.41
    },
    {
        "x": 27.91,
        "y": 27.41
    },
    {
        "x": 27.92,
        "y": 27.41
    },
    {
        "x": 27.93,
        "y": 27.41
    },
    {
        "x": 27.94,
        "y": 27.42
    },
    {
        "x": 27.95,
        "y": 27.42
    },
    {
        "x": 27.96,
        "y": 27.42
    },
    {
        "x": 27.97,
        "y": 27.42
    },
    {
        "x": 27.98,
        "y": 27.42
    },
    {
        "x": 27.99,
        "y": 27.42
    },
    {
        "x": 28,
        "y": 27.43
    },
    {
        "x": 28.01,
        "y": 27.42
    },
    {
        "x": 28.02,
        "y": 27.43
    },
    {
        "x": 28.03,
        "y": 27.43
    },
    {
        "x": 28.04,
        "y": 27.43
    },
    {
        "x": 28.05,
        "y": 27.43
    },
    {
        "x": 28.06,
        "y": 27.43
    },
    {
        "x": 28.07,
        "y": 27.43
    },
    {
        "x": 28.08,
        "y": 27.45
    },
    {
        "x": 28.09,
        "y": 27.43
    },
    {
        "x": 28.1,
        "y": 27.43
    },
    {
        "x": 28.11,
        "y": 27.43
    },
    {
        "x": 28.12,
        "y": 27.43
    },
    {
        "x": 28.13,
        "y": 27.45
    },
    {
        "x": 28.14,
        "y": 27.45
    },
    {
        "x": 28.15,
        "y": 27.45
    },
    {
        "x": 28.16,
        "y": 27.45
    },
    {
        "x": 28.17,
        "y": 27.45
    },
    {
        "x": 28.18,
        "y": 27.45
    },
    {
        "x": 28.19,
        "y": 27.45
    },
    {
        "x": 28.2,
        "y": 27.46
    },
    {
        "x": 28.21,
        "y": 27.46
    },
    {
        "x": 28.22,
        "y": 27.46
    },
    {
        "x": 28.23,
        "y": 27.46
    },
    {
        "x": 28.24,
        "y": 27.46
    },
    {
        "x": 28.25,
        "y": 27.46
    },
    {
        "x": 28.26,
        "y": 27.46
    },
    {
        "x": 28.27,
        "y": 27.46
    },
    {
        "x": 28.28,
        "y": 27.46
    },
    {
        "x": 28.29,
        "y": 27.47
    },
    {
        "x": 28.3,
        "y": 27.47
    },
    {
        "x": 28.31,
        "y": 27.47
    },
    {
        "x": 28.32,
        "y": 27.47
    },
    {
        "x": 28.33,
        "y": 27.47
    },
    {
        "x": 28.34,
        "y": 27.47
    },
    {
        "x": 28.35,
        "y": 27.47
    },
    {
        "x": 28.36,
        "y": 27.47
    },
    {
        "x": 28.37,
        "y": 27.47
    },
    {
        "x": 28.38,
        "y": 27.48
    },
    {
        "x": 28.39,
        "y": 27.48
    },
    {
        "x": 28.4,
        "y": 27.48
    },
    {
        "x": 28.41,
        "y": 27.48
    },
    {
        "x": 28.42,
        "y": 27.48
    },
    {
        "x": 28.43,
        "y": 27.48
    },
    {
        "x": 28.44,
        "y": 27.48
    },
    {
        "x": 28.45,
        "y": 27.49
    },
    {
        "x": 28.46,
        "y": 27.48
    },
    {
        "x": 28.47,
        "y": 27.49
    },
    {
        "x": 28.48,
        "y": 27.49
    },
    {
        "x": 28.49,
        "y": 27.49
    },
    {
        "x": 28.5,
        "y": 27.49
    },
    {
        "x": 28.51,
        "y": 27.49
    },
    {
        "x": 28.52,
        "y": 27.49
    },
    {
        "x": 28.53,
        "y": 27.49
    },
    {
        "x": 28.54,
        "y": 27.5
    },
    {
        "x": 28.55,
        "y": 27.5
    },
    {
        "x": 28.56,
        "y": 27.5
    },
    {
        "x": 28.57,
        "y": 27.5
    },
    {
        "x": 28.58,
        "y": 27.5
    },
    {
        "x": 28.59,
        "y": 27.5
    },
    {
        "x": 28.6,
        "y": 27.5
    },
    {
        "x": 28.61,
        "y": 27.5
    },
    {
        "x": 28.62,
        "y": 27.5
    },
    {
        "x": 28.63,
        "y": 27.5
    },
    {
        "x": 28.64,
        "y": 27.5
    },
    {
        "x": 28.65,
        "y": 27.5
    },
    {
        "x": 28.66,
        "y": 27.51
    },
    {
        "x": 28.67,
        "y": 27.51
    },
    {
        "x": 28.68,
        "y": 27.51
    },
    {
        "x": 28.69,
        "y": 27.5
    },
    {
        "x": 28.7,
        "y": 27.51
    },
    {
        "x": 28.71,
        "y": 27.51
    },
    {
        "x": 28.72,
        "y": 27.51
    },
    {
        "x": 28.73,
        "y": 27.51
    },
    {
        "x": 28.74,
        "y": 27.51
    },
    {
        "x": 28.75,
        "y": 27.51
    },
    {
        "x": 28.76,
        "y": 27.51
    },
    {
        "x": 28.77,
        "y": 27.51
    },
    {
        "x": 28.78,
        "y": 27.52
    },
    {
        "x": 28.79,
        "y": 27.52
    },
    {
        "x": 28.8,
        "y": 27.52
    },
    {
        "x": 28.81,
        "y": 27.52
    },
    {
        "x": 28.82,
        "y": 27.52
    },
    {
        "x": 28.83,
        "y": 27.52
    },
    {
        "x": 28.84,
        "y": 27.52
    },
    {
        "x": 28.85,
        "y": 27.53
    },
    {
        "x": 28.86,
        "y": 27.52
    },
    {
        "x": 28.87,
        "y": 27.53
    },
    {
        "x": 28.88,
        "y": 27.53
    },
    {
        "x": 28.89,
        "y": 27.53
    },
    {
        "x": 28.9,
        "y": 27.53
    },
    {
        "x": 28.91,
        "y": 27.53
    },
    {
        "x": 28.92,
        "y": 27.53
    },
    {
        "x": 28.93,
        "y": 27.53
    },
    {
        "x": 28.94,
        "y": 27.53
    },
    {
        "x": 28.95,
        "y": 27.54
    },
    {
        "x": 28.96,
        "y": 27.54
    },
    {
        "x": 28.97,
        "y": 27.53
    },
    {
        "x": 28.98,
        "y": 27.53
    },
    {
        "x": 28.99,
        "y": 27.54
    },
    {
        "x": 29,
        "y": 27.54
    },
    {
        "x": 29.01,
        "y": 27.54
    },
    {
        "x": 29.02,
        "y": 27.54
    },
    {
        "x": 29.03,
        "y": 27.54
    },
    {
        "x": 29.04,
        "y": 27.54
    },
    {
        "x": 29.05,
        "y": 27.54
    },
    {
        "x": 29.06,
        "y": 27.54
    },
    {
        "x": 29.07,
        "y": 27.54
    },
    {
        "x": 29.08,
        "y": 27.56
    },
    {
        "x": 29.09,
        "y": 27.56
    },
    {
        "x": 29.1,
        "y": 27.56
    },
    {
        "x": 29.11,
        "y": 27.56
    },
    {
        "x": 29.12,
        "y": 27.56
    },
    {
        "x": 29.13,
        "y": 27.56
    },
    {
        "x": 29.14,
        "y": 27.56
    },
    {
        "x": 29.15,
        "y": 27.56
    },
    {
        "x": 29.16,
        "y": 27.56
    },
    {
        "x": 29.17,
        "y": 27.57
    },
    {
        "x": 29.18,
        "y": 27.57
    },
    {
        "x": 29.19,
        "y": 27.57
    },
    {
        "x": 29.2,
        "y": 27.57
    },
    {
        "x": 29.21,
        "y": 27.57
    },
    {
        "x": 29.22,
        "y": 27.57
    },
    {
        "x": 29.23,
        "y": 27.57
    },
    {
        "x": 29.24,
        "y": 27.57
    },
    {
        "x": 29.25,
        "y": 27.57
    },
    {
        "x": 29.26,
        "y": 27.57
    },
    {
        "x": 29.27,
        "y": 27.57
    },
    {
        "x": 29.28,
        "y": 27.57
    },
    {
        "x": 29.29,
        "y": 27.58
    },
    {
        "x": 29.3,
        "y": 27.58
    },
    {
        "x": 29.31,
        "y": 27.58
    },
    {
        "x": 29.32,
        "y": 27.58
    },
    {
        "x": 29.33,
        "y": 27.58
    },
    {
        "x": 29.34,
        "y": 27.58
    },
    {
        "x": 29.35,
        "y": 27.59
    },
    {
        "x": 29.36,
        "y": 27.59
    },
    {
        "x": 29.37,
        "y": 27.59
    },
    {
        "x": 29.38,
        "y": 27.59
    },
    {
        "x": 29.39,
        "y": 27.59
    },
    {
        "x": 29.4,
        "y": 27.59
    },
    {
        "x": 29.41,
        "y": 27.59
    },
    {
        "x": 29.42,
        "y": 27.59
    },
    {
        "x": 29.43,
        "y": 27.59
    },
    {
        "x": 29.44,
        "y": 27.59
    },
    {
        "x": 29.45,
        "y": 27.59
    },
    {
        "x": 29.46,
        "y": 27.6
    },
    {
        "x": 29.47,
        "y": 27.6
    },
    {
        "x": 29.48,
        "y": 27.59
    },
    {
        "x": 29.49,
        "y": 27.6
    },
    {
        "x": 29.5,
        "y": 27.6
    },
    {
        "x": 29.51,
        "y": 27.6
    },
    {
        "x": 29.52,
        "y": 27.6
    },
    {
        "x": 29.53,
        "y": 27.6
    },
    {
        "x": 29.54,
        "y": 27.61
    },
    {
        "x": 29.55,
        "y": 27.61
    },
    {
        "x": 29.56,
        "y": 27.6
    },
    {
        "x": 29.57,
        "y": 27.61
    },
    {
        "x": 29.58,
        "y": 27.61
    },
    {
        "x": 29.59,
        "y": 27.61
    },
    {
        "x": 29.6,
        "y": 27.61
    },
    {
        "x": 29.61,
        "y": 27.61
    },
    {
        "x": 29.62,
        "y": 27.61
    },
    {
        "x": 29.63,
        "y": 27.61
    },
    {
        "x": 29.64,
        "y": 27.61
    },
    {
        "x": 29.65,
        "y": 27.61
    },
    {
        "x": 29.66,
        "y": 27.61
    },
    {
        "x": 29.67,
        "y": 27.61
    },
    {
        "x": 29.68,
        "y": 27.62
    },
    {
        "x": 29.69,
        "y": 27.62
    },
    {
        "x": 29.7,
        "y": 27.62
    },
    {
        "x": 29.71,
        "y": 27.62
    },
    {
        "x": 29.72,
        "y": 27.62
    },
    {
        "x": 29.73,
        "y": 27.62
    },
    {
        "x": 29.74,
        "y": 27.62
    },
    {
        "x": 29.75,
        "y": 27.62
    },
    {
        "x": 29.76,
        "y": 27.62
    },
    {
        "x": 29.77,
        "y": 27.63
    },
    {
        "x": 29.78,
        "y": 27.63
    },
    {
        "x": 29.79,
        "y": 27.63
    },
    {
        "x": 29.8,
        "y": 27.63
    },
    {
        "x": 29.81,
        "y": 27.63
    },
    {
        "x": 29.82,
        "y": 27.63
    },
    {
        "x": 29.83,
        "y": 27.63
    },
    {
        "x": 29.84,
        "y": 27.63
    },
    {
        "x": 29.85,
        "y": 27.63
    },
    {
        "x": 29.86,
        "y": 27.63
    },
    {
        "x": 29.87,
        "y": 27.63
    },
    {
        "x": 29.88,
        "y": 27.63
    },
    {
        "x": 29.89,
        "y": 27.63
    },
    {
        "x": 29.9,
        "y": 27.64
    },
    {
        "x": 29.91,
        "y": 27.64
    },
    {
        "x": 29.92,
        "y": 27.64
    },
    {
        "x": 29.93,
        "y": 27.64
    },
    {
        "x": 29.94,
        "y": 27.64
    },
    {
        "x": 29.95,
        "y": 27.64
    },
    {
        "x": 29.96,
        "y": 27.64
    },
    {
        "x": 29.97,
        "y": 27.64
    },
    {
        "x": 29.98,
        "y": 27.64
    },
    {
        "x": 29.99,
        "y": 27.64
    },
    {
        "x": 30,
        "y": 27.64
    },
    {
        "x": 30.01,
        "y": 27.65
    },
    {
        "x": 30.02,
        "y": 27.64
    },
    {
        "x": 30.03,
        "y": 27.64
    },
    {
        "x": 30.04,
        "y": 27.65
    },
    {
        "x": 30.05,
        "y": 27.65
    },
    {
        "x": 30.06,
        "y": 27.65
    },
    {
        "x": 30.07,
        "y": 27.65
    },
    {
        "x": 30.08,
        "y": 27.65
    },
    {
        "x": 30.09,
        "y": 27.65
    },
    {
        "x": 30.1,
        "y": 27.67
    },
    {
        "x": 30.11,
        "y": 27.67
    },
    {
        "x": 30.12,
        "y": 27.65
    },
    {
        "x": 30.13,
        "y": 27.65
    },
    {
        "x": 30.14,
        "y": 27.65
    },
    {
        "x": 30.15,
        "y": 27.67
    },
    {
        "x": 30.16,
        "y": 27.67
    },
    {
        "x": 30.17,
        "y": 27.67
    },
    {
        "x": 30.18,
        "y": 27.67
    },
    {
        "x": 30.19,
        "y": 27.67
    },
    {
        "x": 30.2,
        "y": 27.67
    },
    {
        "x": 30.21,
        "y": 27.67
    },
    {
        "x": 30.22,
        "y": 27.68
    },
    {
        "x": 30.23,
        "y": 27.67
    },
    {
        "x": 30.24,
        "y": 27.67
    },
    {
        "x": 30.25,
        "y": 27.68
    },
    {
        "x": 30.26,
        "y": 27.68
    },
    {
        "x": 30.27,
        "y": 27.68
    },
    {
        "x": 30.28,
        "y": 27.68
    },
    {
        "x": 30.29,
        "y": 27.68
    },
    {
        "x": 30.3,
        "y": 27.68
    },
    {
        "x": 30.31,
        "y": 27.68
    },
    {
        "x": 30.32,
        "y": 27.68
    },
    {
        "x": 30.33,
        "y": 27.68
    },
    {
        "x": 30.34,
        "y": 27.68
    },
    {
        "x": 30.35,
        "y": 27.68
    },
    {
        "x": 30.36,
        "y": 27.69
    },
    {
        "x": 30.37,
        "y": 27.69
    },
    {
        "x": 30.38,
        "y": 27.69
    },
    {
        "x": 30.39,
        "y": 27.69
    },
    {
        "x": 30.4,
        "y": 27.69
    },
    {
        "x": 30.41,
        "y": 27.69
    },
    {
        "x": 30.42,
        "y": 27.69
    },
    {
        "x": 30.43,
        "y": 27.69
    },
    {
        "x": 30.44,
        "y": 27.69
    },
    {
        "x": 30.45,
        "y": 27.69
    },
    {
        "x": 30.46,
        "y": 27.7
    },
    {
        "x": 30.47,
        "y": 27.7
    },
    {
        "x": 30.48,
        "y": 27.7
    },
    {
        "x": 30.49,
        "y": 27.7
    },
    {
        "x": 30.5,
        "y": 27.7
    },
    {
        "x": 30.51,
        "y": 27.7
    },
    {
        "x": 30.52,
        "y": 27.7
    },
    {
        "x": 30.53,
        "y": 27.7
    },
    {
        "x": 30.54,
        "y": 27.7
    },
    {
        "x": 30.55,
        "y": 27.7
    },
    {
        "x": 30.56,
        "y": 27.7
    },
    {
        "x": 30.57,
        "y": 27.71
    },
    {
        "x": 30.58,
        "y": 27.71
    },
    {
        "x": 30.59,
        "y": 27.71
    },
    {
        "x": 30.6,
        "y": 27.71
    },
    {
        "x": 30.61,
        "y": 27.71
    },
    {
        "x": 30.62,
        "y": 27.71
    },
    {
        "x": 30.63,
        "y": 27.71
    },
    {
        "x": 30.64,
        "y": 27.71
    },
    {
        "x": 30.65,
        "y": 27.71
    },
    {
        "x": 30.66,
        "y": 27.71
    },
    {
        "x": 30.67,
        "y": 27.71
    },
    {
        "x": 30.68,
        "y": 27.71
    },
    {
        "x": 30.69,
        "y": 27.71
    },
    {
        "x": 30.7,
        "y": 27.72
    },
    {
        "x": 30.71,
        "y": 27.72
    },
    {
        "x": 30.72,
        "y": 27.72
    },
    {
        "x": 30.73,
        "y": 27.72
    },
    {
        "x": 30.74,
        "y": 27.72
    },
    {
        "x": 30.75,
        "y": 27.72
    },
    {
        "x": 30.76,
        "y": 27.72
    },
    {
        "x": 30.77,
        "y": 27.72
    },
    {
        "x": 30.78,
        "y": 27.72
    },
    {
        "x": 30.79,
        "y": 27.72
    },
    {
        "x": 30.8,
        "y": 27.73
    },
    {
        "x": 30.81,
        "y": 27.72
    },
    {
        "x": 30.82,
        "y": 27.72
    },
    {
        "x": 30.83,
        "y": 27.72
    },
    {
        "x": 30.84,
        "y": 27.73
    },
    {
        "x": 30.85,
        "y": 27.73
    },
    {
        "x": 30.86,
        "y": 27.73
    },
    {
        "x": 30.87,
        "y": 27.73
    },
    {
        "x": 30.88,
        "y": 27.73
    },
    {
        "x": 30.89,
        "y": 27.73
    },
    {
        "x": 30.9,
        "y": 27.73
    },
    {
        "x": 30.91,
        "y": 27.73
    },
    {
        "x": 30.92,
        "y": 27.73
    },
    {
        "x": 30.93,
        "y": 27.73
    },
    {
        "x": 30.94,
        "y": 27.73
    },
    {
        "x": 30.95,
        "y": 27.73
    },
    {
        "x": 30.96,
        "y": 27.74
    },
    {
        "x": 30.97,
        "y": 27.74
    },
    {
        "x": 30.98,
        "y": 27.74
    },
    {
        "x": 30.99,
        "y": 27.74
    },
    {
        "x": 31,
        "y": 27.74
    },
    {
        "x": 31.01,
        "y": 27.74
    },
    {
        "x": 31.02,
        "y": 27.74
    },
    {
        "x": 31.03,
        "y": 27.74
    },
    {
        "x": 31.04,
        "y": 27.74
    },
    {
        "x": 31.05,
        "y": 27.74
    },
    {
        "x": 31.06,
        "y": 27.74
    },
    {
        "x": 31.07,
        "y": 27.74
    },
    {
        "x": 31.08,
        "y": 27.75
    },
    {
        "x": 31.09,
        "y": 27.75
    },
    {
        "x": 31.1,
        "y": 27.75
    },
    {
        "x": 31.11,
        "y": 27.75
    },
    {
        "x": 31.12,
        "y": 27.75
    },
    {
        "x": 31.13,
        "y": 27.75
    },
    {
        "x": 31.14,
        "y": 27.75
    },
    {
        "x": 31.15,
        "y": 27.75
    },
    {
        "x": 31.16,
        "y": 27.75
    },
    {
        "x": 31.17,
        "y": 27.76
    },
    {
        "x": 31.18,
        "y": 27.75
    },
    {
        "x": 31.19,
        "y": 27.75
    },
    {
        "x": 31.2,
        "y": 27.76
    },
    {
        "x": 31.21,
        "y": 27.76
    },
    {
        "x": 31.22,
        "y": 27.75
    },
    {
        "x": 31.23,
        "y": 27.76
    },
    {
        "x": 31.24,
        "y": 27.76
    },
    {
        "x": 31.25,
        "y": 27.76
    },
    {
        "x": 31.26,
        "y": 27.76
    },
    {
        "x": 31.27,
        "y": 27.76
    },
    {
        "x": 31.28,
        "y": 27.76
    },
    {
        "x": 31.29,
        "y": 27.76
    },
    {
        "x": 31.3,
        "y": 27.76
    },
    {
        "x": 31.31,
        "y": 27.76
    },
    {
        "x": 31.32,
        "y": 27.76
    },
    {
        "x": 31.33,
        "y": 27.78
    },
    {
        "x": 31.34,
        "y": 27.78
    },
    {
        "x": 31.35,
        "y": 27.78
    },
    {
        "x": 31.36,
        "y": 27.78
    },
    {
        "x": 31.37,
        "y": 27.78
    },
    {
        "x": 31.38,
        "y": 27.78
    },
    {
        "x": 31.39,
        "y": 27.78
    },
    {
        "x": 31.4,
        "y": 27.78
    },
    {
        "x": 31.41,
        "y": 27.78
    },
    {
        "x": 31.42,
        "y": 27.78
    },
    {
        "x": 31.43,
        "y": 27.78
    },
    {
        "x": 31.44,
        "y": 27.78
    },
    {
        "x": 31.45,
        "y": 27.78
    },
    {
        "x": 31.46,
        "y": 27.79
    },
    {
        "x": 31.47,
        "y": 27.79
    },
    {
        "x": 31.48,
        "y": 27.79
    },
    {
        "x": 31.49,
        "y": 27.79
    },
    {
        "x": 31.5,
        "y": 27.79
    },
    {
        "x": 31.51,
        "y": 27.79
    },
    {
        "x": 31.52,
        "y": 27.79
    },
    {
        "x": 31.53,
        "y": 27.79
    },
    {
        "x": 31.54,
        "y": 27.8
    },
    {
        "x": 31.55,
        "y": 27.8
    },
    {
        "x": 31.56,
        "y": 27.8
    },
    {
        "x": 31.57,
        "y": 27.8
    },
    {
        "x": 31.58,
        "y": 27.8
    },
    {
        "x": 31.59,
        "y": 27.8
    },
    {
        "x": 31.6,
        "y": 27.8
    },
    {
        "x": 31.61,
        "y": 27.8
    },
    {
        "x": 31.62,
        "y": 27.8
    },
    {
        "x": 31.63,
        "y": 27.8
    },
    {
        "x": 31.64,
        "y": 27.8
    },
    {
        "x": 31.65,
        "y": 27.8
    },
    {
        "x": 31.66,
        "y": 27.8
    },
    {
        "x": 31.67,
        "y": 27.81
    },
    {
        "x": 31.68,
        "y": 27.8
    },
    {
        "x": 31.69,
        "y": 27.8
    },
    {
        "x": 31.7,
        "y": 27.81
    },
    {
        "x": 31.71,
        "y": 27.81
    },
    {
        "x": 31.72,
        "y": 27.81
    },
    {
        "x": 31.73,
        "y": 27.81
    },
    {
        "x": 31.74,
        "y": 27.81
    },
    {
        "x": 31.75,
        "y": 27.81
    },
    {
        "x": 31.76,
        "y": 27.81
    },
    {
        "x": 31.77,
        "y": 27.81
    },
    {
        "x": 31.78,
        "y": 27.81
    },
    {
        "x": 31.79,
        "y": 27.81
    },
    {
        "x": 31.8,
        "y": 27.81
    },
    {
        "x": 31.81,
        "y": 27.81
    },
    {
        "x": 31.82,
        "y": 27.82
    },
    {
        "x": 31.83,
        "y": 27.82
    },
    {
        "x": 31.84,
        "y": 27.81
    },
    {
        "x": 31.85,
        "y": 27.82
    },
    {
        "x": 31.86,
        "y": 27.82
    },
    {
        "x": 31.87,
        "y": 27.82
    },
    {
        "x": 31.88,
        "y": 27.82
    },
    {
        "x": 31.89,
        "y": 27.82
    },
    {
        "x": 31.9,
        "y": 27.82
    },
    {
        "x": 31.91,
        "y": 27.82
    },
    {
        "x": 31.92,
        "y": 27.82
    },
    {
        "x": 31.93,
        "y": 27.82
    },
    {
        "x": 31.94,
        "y": 27.82
    },
    {
        "x": 31.95,
        "y": 27.82
    },
    {
        "x": 31.96,
        "y": 27.83
    },
    {
        "x": 31.97,
        "y": 27.83
    },
    {
        "x": 31.98,
        "y": 27.83
    },
    {
        "x": 31.99,
        "y": 27.83
    },
    {
        "x": 32,
        "y": 27.83
    },
    {
        "x": 32.01,
        "y": 27.83
    },
    {
        "x": 32.02,
        "y": 27.83
    },
    {
        "x": 32.03,
        "y": 27.83
    },
    {
        "x": 32.04,
        "y": 27.83
    },
    {
        "x": 32.05,
        "y": 27.83
    },
    {
        "x": 32.06,
        "y": 27.83
    },
    {
        "x": 32.07,
        "y": 27.83
    },
    {
        "x": 32.08,
        "y": 27.83
    },
    {
        "x": 32.09,
        "y": 27.83
    },
    {
        "x": 32.1,
        "y": 27.83
    },
    {
        "x": 32.11,
        "y": 27.83
    },
    {
        "x": 32.12,
        "y": 27.83
    },
    {
        "x": 32.13,
        "y": 27.84
    },
    {
        "x": 32.14,
        "y": 27.84
    },
    {
        "x": 32.15,
        "y": 27.84
    },
    {
        "x": 32.16,
        "y": 27.84
    },
    {
        "x": 32.17,
        "y": 27.84
    },
    {
        "x": 32.18,
        "y": 27.84
    },
    {
        "x": 32.19,
        "y": 27.84
    },
    {
        "x": 32.2,
        "y": 27.84
    },
    {
        "x": 32.21,
        "y": 27.84
    },
    {
        "x": 32.22,
        "y": 27.84
    },
    {
        "x": 32.23,
        "y": 27.84
    },
    {
        "x": 32.24,
        "y": 27.84
    },
    {
        "x": 32.25,
        "y": 27.84
    },
    {
        "x": 32.26,
        "y": 27.84
    },
    {
        "x": 32.27,
        "y": 27.84
    },
    {
        "x": 32.28,
        "y": 27.85
    },
    {
        "x": 32.29,
        "y": 27.85
    },
    {
        "x": 32.3,
        "y": 27.85
    },
    {
        "x": 32.31,
        "y": 27.85
    },
    {
        "x": 32.32,
        "y": 27.85
    },
    {
        "x": 32.33,
        "y": 27.85
    },
    {
        "x": 32.34,
        "y": 27.85
    },
    {
        "x": 32.35,
        "y": 27.85
    },
    {
        "x": 32.36,
        "y": 27.85
    },
    {
        "x": 32.37,
        "y": 27.85
    },
    {
        "x": 32.38,
        "y": 27.86
    },
    {
        "x": 32.39,
        "y": 27.86
    },
    {
        "x": 32.4,
        "y": 27.85
    },
    {
        "x": 32.41,
        "y": 27.86
    },
    {
        "x": 32.42,
        "y": 27.86
    },
    {
        "x": 32.43,
        "y": 27.86
    },
    {
        "x": 32.44,
        "y": 27.86
    },
    {
        "x": 32.45,
        "y": 27.86
    },
    {
        "x": 32.46,
        "y": 27.86
    },
    {
        "x": 32.47,
        "y": 27.86
    },
    {
        "x": 32.48,
        "y": 27.86
    },
    {
        "x": 32.49,
        "y": 27.86
    },
    {
        "x": 32.5,
        "y": 27.87
    },
    {
        "x": 32.51,
        "y": 27.86
    },
    {
        "x": 32.52,
        "y": 27.86
    },
    {
        "x": 32.53,
        "y": 27.86
    },
    {
        "x": 32.54,
        "y": 27.87
    },
    {
        "x": 32.55,
        "y": 27.86
    },
    {
        "x": 32.56,
        "y": 27.87
    },
    {
        "x": 32.57,
        "y": 27.87
    },
    {
        "x": 32.58,
        "y": 27.87
    },
    {
        "x": 32.59,
        "y": 27.87
    },
    {
        "x": 32.6,
        "y": 27.87
    },
    {
        "x": 32.61,
        "y": 27.87
    },
    {
        "x": 32.62,
        "y": 27.87
    },
    {
        "x": 32.63,
        "y": 27.87
    },
    {
        "x": 32.64,
        "y": 27.87
    },
    {
        "x": 32.65,
        "y": 27.87
    },
    {
        "x": 32.66,
        "y": 27.87
    },
    {
        "x": 32.67,
        "y": 27.87
    },
    {
        "x": 32.68,
        "y": 27.89
    },
    {
        "x": 32.69,
        "y": 27.89
    },
    {
        "x": 32.7,
        "y": 27.89
    },
    {
        "x": 32.71,
        "y": 27.89
    },
    {
        "x": 32.72,
        "y": 27.89
    },
    {
        "x": 32.73,
        "y": 27.89
    },
    {
        "x": 32.74,
        "y": 27.89
    },
    {
        "x": 32.75,
        "y": 27.89
    },
    {
        "x": 32.76,
        "y": 27.89
    },
    {
        "x": 32.77,
        "y": 27.89
    },
    {
        "x": 32.78,
        "y": 27.89
    },
    {
        "x": 32.79,
        "y": 27.89
    },
    {
        "x": 32.8,
        "y": 27.89
    },
    {
        "x": 32.81,
        "y": 27.89
    },
    {
        "x": 32.82,
        "y": 27.9
    },
    {
        "x": 32.83,
        "y": 27.9
    },
    {
        "x": 32.84,
        "y": 27.9
    },
    {
        "x": 32.85,
        "y": 27.9
    },
    {
        "x": 32.86,
        "y": 27.9
    },
    {
        "x": 32.87,
        "y": 27.9
    },
    {
        "x": 32.88,
        "y": 27.9
    },
    {
        "x": 32.89,
        "y": 27.9
    },
    {
        "x": 32.9,
        "y": 27.9
    },
    {
        "x": 32.91,
        "y": 27.9
    },
    {
        "x": 32.92,
        "y": 27.9
    },
    {
        "x": 32.93,
        "y": 27.9
    },
    {
        "x": 32.94,
        "y": 27.9
    },
    {
        "x": 32.95,
        "y": 27.9
    },
    {
        "x": 32.96,
        "y": 27.9
    },
    {
        "x": 32.97,
        "y": 27.9
    },
    {
        "x": 32.98,
        "y": 27.9
    },
    {
        "x": 32.99,
        "y": 27.91
    },
    {
        "x": 33,
        "y": 27.91
    },
    {
        "x": 33.01,
        "y": 27.91
    },
    {
        "x": 33.02,
        "y": 27.91
    },
    {
        "x": 33.03,
        "y": 27.91
    },
    {
        "x": 33.04,
        "y": 27.91
    },
    {
        "x": 33.05,
        "y": 27.91
    },
    {
        "x": 33.06,
        "y": 27.91
    },
    {
        "x": 33.07,
        "y": 27.91
    },
    {
        "x": 33.08,
        "y": 27.91
    },
    {
        "x": 33.09,
        "y": 27.91
    },
    {
        "x": 33.1,
        "y": 27.92
    },
    {
        "x": 33.11,
        "y": 27.91
    },
    {
        "x": 33.12,
        "y": 27.91
    },
    {
        "x": 33.13,
        "y": 27.91
    },
    {
        "x": 33.14,
        "y": 27.92
    },
    {
        "x": 33.15,
        "y": 27.92
    },
    {
        "x": 33.16,
        "y": 27.92
    },
    {
        "x": 33.17,
        "y": 27.92
    },
    {
        "x": 33.18,
        "y": 27.92
    },
    {
        "x": 33.19,
        "y": 27.92
    },
    {
        "x": 33.2,
        "y": 27.92
    },
    {
        "x": 33.21,
        "y": 27.92
    },
    {
        "x": 33.22,
        "y": 27.92
    },
    {
        "x": 33.23,
        "y": 27.92
    },
    {
        "x": 33.24,
        "y": 27.92
    },
    {
        "x": 33.25,
        "y": 27.92
    },
    {
        "x": 33.26,
        "y": 27.92
    },
    {
        "x": 33.27,
        "y": 27.92
    },
    {
        "x": 33.28,
        "y": 27.92
    },
    {
        "x": 33.29,
        "y": 27.92
    },
    {
        "x": 33.3,
        "y": 27.92
    },
    {
        "x": 33.31,
        "y": 27.93
    },
    {
        "x": 33.32,
        "y": 27.92
    },
    {
        "x": 33.33,
        "y": 27.93
    },
    {
        "x": 33.34,
        "y": 27.93
    },
    {
        "x": 33.35,
        "y": 27.93
    },
    {
        "x": 33.36,
        "y": 27.93
    },
    {
        "x": 33.37,
        "y": 27.93
    },
    {
        "x": 33.38,
        "y": 27.93
    },
    {
        "x": 33.39,
        "y": 27.93
    },
    {
        "x": 33.4,
        "y": 27.93
    },
    {
        "x": 33.41,
        "y": 27.93
    },
    {
        "x": 33.42,
        "y": 27.93
    },
    {
        "x": 33.43,
        "y": 27.93
    },
    {
        "x": 33.44,
        "y": 27.93
    },
    {
        "x": 33.45,
        "y": 27.93
    },
    {
        "x": 33.46,
        "y": 27.93
    },
    {
        "x": 33.47,
        "y": 27.93
    },
    {
        "x": 33.48,
        "y": 27.93
    },
    {
        "x": 33.49,
        "y": 27.94
    },
    {
        "x": 33.5,
        "y": 27.94
    },
    {
        "x": 33.51,
        "y": 27.94
    },
    {
        "x": 33.52,
        "y": 27.94
    },
    {
        "x": 33.53,
        "y": 27.94
    },
    {
        "x": 33.54,
        "y": 27.94
    },
    {
        "x": 33.55,
        "y": 27.94
    },
    {
        "x": 33.56,
        "y": 27.94
    },
    {
        "x": 33.57,
        "y": 27.94
    },
    {
        "x": 33.58,
        "y": 27.94
    },
    {
        "x": 33.59,
        "y": 27.94
    },
    {
        "x": 33.6,
        "y": 27.94
    },
    {
        "x": 33.61,
        "y": 27.94
    },
    {
        "x": 33.62,
        "y": 27.94
    },
    {
        "x": 33.63,
        "y": 27.94
    },
    {
        "x": 33.64,
        "y": 27.94
    },
    {
        "x": 33.65,
        "y": 27.94
    },
    {
        "x": 33.66,
        "y": 27.95
    },
    {
        "x": 33.67,
        "y": 27.95
    },
    {
        "x": 33.68,
        "y": 27.95
    },
    {
        "x": 33.69,
        "y": 27.95
    },
    {
        "x": 33.7,
        "y": 27.95
    },
    {
        "x": 33.71,
        "y": 27.95
    },
    {
        "x": 33.72,
        "y": 27.95
    },
    {
        "x": 33.73,
        "y": 27.95
    },
    {
        "x": 33.74,
        "y": 27.95
    },
    {
        "x": 33.75,
        "y": 27.95
    },
    {
        "x": 33.76,
        "y": 27.95
    },
    {
        "x": 33.77,
        "y": 27.95
    },
    {
        "x": 33.78,
        "y": 27.95
    },
    {
        "x": 33.79,
        "y": 27.95
    },
    {
        "x": 33.8,
        "y": 27.96
    },
    {
        "x": 33.81,
        "y": 27.95
    },
    {
        "x": 33.82,
        "y": 27.95
    },
    {
        "x": 33.83,
        "y": 27.95
    },
    {
        "x": 33.84,
        "y": 27.95
    },
    {
        "x": 33.85,
        "y": 27.95
    },
    {
        "x": 33.86,
        "y": 27.96
    },
    {
        "x": 33.87,
        "y": 27.96
    },
    {
        "x": 33.88,
        "y": 27.96
    },
    {
        "x": 33.89,
        "y": 27.96
    },
    {
        "x": 33.9,
        "y": 27.96
    },
    {
        "x": 33.91,
        "y": 27.96
    },
    {
        "x": 33.92,
        "y": 27.96
    },
    {
        "x": 33.93,
        "y": 27.96
    },
    {
        "x": 33.94,
        "y": 27.96
    },
    {
        "x": 33.95,
        "y": 27.96
    },
    {
        "x": 33.96,
        "y": 27.97
    },
    {
        "x": 33.97,
        "y": 27.97
    },
    {
        "x": 33.98,
        "y": 27.96
    },
    {
        "x": 33.99,
        "y": 27.96
    },
    {
        "x": 34,
        "y": 27.97
    },
    {
        "x": 34.01,
        "y": 27.97
    },
    {
        "x": 34.02,
        "y": 27.97
    },
    {
        "x": 34.03,
        "y": 27.97
    },
    {
        "x": 34.04,
        "y": 27.97
    },
    {
        "x": 34.05,
        "y": 27.97
    },
    {
        "x": 34.06,
        "y": 27.97
    },
    {
        "x": 34.07,
        "y": 27.97
    },
    {
        "x": 34.08,
        "y": 27.97
    },
    {
        "x": 34.09,
        "y": 27.97
    },
    {
        "x": 34.1,
        "y": 27.97
    },
    {
        "x": 34.11,
        "y": 27.97
    },
    {
        "x": 34.12,
        "y": 27.97
    },
    {
        "x": 34.13,
        "y": 27.97
    },
    {
        "x": 34.14,
        "y": 27.97
    },
    {
        "x": 34.15,
        "y": 27.97
    },
    {
        "x": 34.16,
        "y": 27.97
    },
    {
        "x": 34.17,
        "y": 27.97
    },
    {
        "x": 34.18,
        "y": 27.97
    },
    {
        "x": 34.19,
        "y": 27.97
    },
    {
        "x": 34.2,
        "y": 27.97
    },
    {
        "x": 34.21,
        "y": 27.97
    },
    {
        "x": 34.22,
        "y": 27.98
    },
    {
        "x": 34.23,
        "y": 27.98
    },
    {
        "x": 34.24,
        "y": 27.98
    },
    {
        "x": 34.25,
        "y": 27.98
    },
    {
        "x": 34.26,
        "y": 27.98
    },
    {
        "x": 34.27,
        "y": 27.98
    },
    {
        "x": 34.28,
        "y": 27.97
    },
    {
        "x": 34.29,
        "y": 27.98
    },
    {
        "x": 34.3,
        "y": 27.98
    },
    {
        "x": 34.31,
        "y": 27.98
    },
    {
        "x": 34.32,
        "y": 27.98
    },
    {
        "x": 34.33,
        "y": 27.98
    },
    {
        "x": 34.34,
        "y": 27.98
    },
    {
        "x": 34.35,
        "y": 27.98
    },
    {
        "x": 34.36,
        "y": 27.98
    },
    {
        "x": 34.37,
        "y": 27.98
    },
    {
        "x": 34.38,
        "y": 27.98
    },
    {
        "x": 34.39,
        "y": 27.98
    },
    {
        "x": 34.4,
        "y": 27.98
    },
    {
        "x": 34.41,
        "y": 27.98
    },
    {
        "x": 34.42,
        "y": 27.98
    },
    {
        "x": 34.43,
        "y": 27.98
    },
    {
        "x": 34.44,
        "y": 27.98
    },
    {
        "x": 34.45,
        "y": 27.98
    },
    {
        "x": 34.46,
        "y": 28
    },
    {
        "x": 34.47,
        "y": 28
    },
    {
        "x": 34.48,
        "y": 28
    },
    {
        "x": 34.49,
        "y": 28
    },
    {
        "x": 34.5,
        "y": 28
    },
    {
        "x": 34.51,
        "y": 28
    },
    {
        "x": 34.52,
        "y": 28
    },
    {
        "x": 34.53,
        "y": 28
    },
    {
        "x": 34.54,
        "y": 28
    },
    {
        "x": 34.55,
        "y": 28
    },
    {
        "x": 34.56,
        "y": 28
    },
    {
        "x": 34.57,
        "y": 28
    },
    {
        "x": 34.58,
        "y": 28
    },
    {
        "x": 34.59,
        "y": 28
    },
    {
        "x": 34.6,
        "y": 28
    },
    {
        "x": 34.61,
        "y": 28.01
    },
    {
        "x": 34.62,
        "y": 28.01
    },
    {
        "x": 34.63,
        "y": 28.01
    },
    {
        "x": 34.64,
        "y": 28.01
    },
    {
        "x": 34.65,
        "y": 28.01
    },
    {
        "x": 34.66,
        "y": 28.01
    },
    {
        "x": 34.67,
        "y": 28.01
    },
    {
        "x": 34.68,
        "y": 28.01
    },
    {
        "x": 34.69,
        "y": 28.01
    },
    {
        "x": 34.7,
        "y": 28.01
    },
    {
        "x": 34.71,
        "y": 28.01
    },
    {
        "x": 34.72,
        "y": 28.01
    },
    {
        "x": 34.73,
        "y": 28.01
    },
    {
        "x": 34.74,
        "y": 28.01
    },
    {
        "x": 34.75,
        "y": 28.01
    },
    {
        "x": 34.76,
        "y": 28.01
    },
    {
        "x": 34.77,
        "y": 28.01
    },
    {
        "x": 34.78,
        "y": 28.01
    },
    {
        "x": 34.79,
        "y": 28.01
    },
    {
        "x": 34.8,
        "y": 28.01
    },
    {
        "x": 34.81,
        "y": 28.01
    },
    {
        "x": 34.82,
        "y": 28.02
    },
    {
        "x": 34.83,
        "y": 28.01
    },
    {
        "x": 34.84,
        "y": 28.01
    },
    {
        "x": 34.85,
        "y": 28.01
    },
    {
        "x": 34.86,
        "y": 28.02
    },
    {
        "x": 34.87,
        "y": 28.02
    },
    {
        "x": 34.88,
        "y": 28.01
    },
    {
        "x": 34.89,
        "y": 28.02
    },
    {
        "x": 34.9,
        "y": 28.02
    },
    {
        "x": 34.91,
        "y": 28.02
    },
    {
        "x": 34.92,
        "y": 28.02
    },
    {
        "x": 34.93,
        "y": 28.02
    },
    {
        "x": 34.94,
        "y": 28.02
    },
    {
        "x": 34.95,
        "y": 28.02
    },
    {
        "x": 34.96,
        "y": 28.02
    },
    {
        "x": 34.97,
        "y": 28.02
    },
    {
        "x": 34.98,
        "y": 28.02
    },
    {
        "x": 34.99,
        "y": 28.02
    },
    {
        "x": 35,
        "y": 28.02
    },
    {
        "x": 35.01,
        "y": 28.03
    },
    {
        "x": 35.02,
        "y": 28.02
    },
    {
        "x": 35.03,
        "y": 28.02
    },
    {
        "x": 35.04,
        "y": 28.02
    },
    {
        "x": 35.05,
        "y": 28.02
    },
    {
        "x": 35.06,
        "y": 28.03
    },
    {
        "x": 35.07,
        "y": 28.03
    },
    {
        "x": 35.08,
        "y": 28.03
    },
    {
        "x": 35.09,
        "y": 28.03
    },
    {
        "x": 35.1,
        "y": 28.03
    },
    {
        "x": 35.11,
        "y": 28.03
    },
    {
        "x": 35.12,
        "y": 28.03
    },
    {
        "x": 35.13,
        "y": 28.03
    },
    {
        "x": 35.14,
        "y": 28.03
    },
    {
        "x": 35.15,
        "y": 28.04
    },
    {
        "x": 35.16,
        "y": 28.03
    },
    {
        "x": 35.17,
        "y": 28.03
    },
    {
        "x": 35.18,
        "y": 28.03
    },
    {
        "x": 35.19,
        "y": 28.04
    },
    {
        "x": 35.2,
        "y": 28.03
    },
    {
        "x": 35.21,
        "y": 28.03
    },
    {
        "x": 35.22,
        "y": 28.04
    },
    {
        "x": 35.23,
        "y": 28.03
    },
    {
        "x": 35.24,
        "y": 28.04
    },
    {
        "x": 35.25,
        "y": 28.04
    },
    {
        "x": 35.26,
        "y": 28.04
    },
    {
        "x": 35.27,
        "y": 28.04
    },
    {
        "x": 35.28,
        "y": 28.04
    },
    {
        "x": 35.29,
        "y": 28.04
    },
    {
        "x": 35.3,
        "y": 28.04
    },
    {
        "x": 35.31,
        "y": 28.04
    },
    {
        "x": 35.32,
        "y": 28.04
    },
    {
        "x": 35.33,
        "y": 28.04
    },
    {
        "x": 35.34,
        "y": 28.04
    },
    {
        "x": 35.35,
        "y": 28.04
    },
    {
        "x": 35.36,
        "y": 28.04
    },
    {
        "x": 35.37,
        "y": 28.04
    },
    {
        "x": 35.38,
        "y": 28.04
    },
    {
        "x": 35.39,
        "y": 28.04
    },
    {
        "x": 35.4,
        "y": 28.04
    },
    {
        "x": 35.41,
        "y": 28.04
    },
    {
        "x": 35.42,
        "y": 28.05
    },
    {
        "x": 35.43,
        "y": 28.04
    },
    {
        "x": 35.44,
        "y": 28.04
    },
    {
        "x": 35.45,
        "y": 28.04
    },
    {
        "x": 35.46,
        "y": 28.05
    },
    {
        "x": 35.47,
        "y": 28.04
    },
    {
        "x": 35.48,
        "y": 28.04
    },
    {
        "x": 35.49,
        "y": 28.04
    },
    {
        "x": 35.5,
        "y": 28.04
    },
    {
        "x": 35.51,
        "y": 28.05
    },
    {
        "x": 35.52,
        "y": 28.05
    },
    {
        "x": 35.53,
        "y": 28.05
    },
    {
        "x": 35.54,
        "y": 28.05
    },
    {
        "x": 35.55,
        "y": 28.05
    },
    {
        "x": 35.56,
        "y": 28.05
    },
    {
        "x": 35.57,
        "y": 28.05
    },
    {
        "x": 35.58,
        "y": 28.05
    },
    {
        "x": 35.59,
        "y": 28.05
    },
    {
        "x": 35.6,
        "y": 28.05
    },
    {
        "x": 35.61,
        "y": 28.05
    },
    {
        "x": 35.62,
        "y": 28.05
    },
    {
        "x": 35.63,
        "y": 28.05
    },
    {
        "x": 35.64,
        "y": 28.05
    },
    {
        "x": 35.65,
        "y": 28.05
    },
    {
        "x": 35.66,
        "y": 28.05
    },
    {
        "x": 35.67,
        "y": 28.05
    },
    {
        "x": 35.68,
        "y": 28.05
    },
    {
        "x": 35.69,
        "y": 28.05
    },
    {
        "x": 35.7,
        "y": 28.06
    },
    {
        "x": 35.71,
        "y": 28.05
    },
    {
        "x": 35.72,
        "y": 28.06
    },
    {
        "x": 35.73,
        "y": 28.06
    },
    {
        "x": 35.74,
        "y": 28.05
    },
    {
        "x": 35.75,
        "y": 28.05
    },
    {
        "x": 35.76,
        "y": 28.05
    },
    {
        "x": 35.77,
        "y": 28.05
    },
    {
        "x": 35.78,
        "y": 28.06
    },
    {
        "x": 35.79,
        "y": 28.06
    },
    {
        "x": 35.8,
        "y": 28.06
    },
    {
        "x": 35.81,
        "y": 28.06
    },
    {
        "x": 35.82,
        "y": 28.06
    },
    {
        "x": 35.83,
        "y": 28.06
    },
    {
        "x": 35.84,
        "y": 28.06
    },
    {
        "x": 35.85,
        "y": 28.06
    },
    {
        "x": 35.86,
        "y": 28.06
    },
    {
        "x": 35.87,
        "y": 28.06
    },
    {
        "x": 35.88,
        "y": 28.06
    },
    {
        "x": 35.89,
        "y": 28.06
    },
    {
        "x": 35.9,
        "y": 28.06
    },
    {
        "x": 35.91,
        "y": 28.06
    },
    {
        "x": 35.92,
        "y": 28.06
    },
    {
        "x": 35.93,
        "y": 28.06
    },
    {
        "x": 35.94,
        "y": 28.07
    },
    {
        "x": 35.95,
        "y": 28.06
    },
    {
        "x": 35.96,
        "y": 28.06
    },
    {
        "x": 35.97,
        "y": 28.06
    },
    {
        "x": 35.98,
        "y": 28.07
    },
    {
        "x": 35.99,
        "y": 28.07
    },
    {
        "x": 36,
        "y": 28.06
    },
    {
        "x": 36.01,
        "y": 28.06
    },
    {
        "x": 36.02,
        "y": 28.06
    },
    {
        "x": 36.03,
        "y": 28.07
    },
    {
        "x": 36.04,
        "y": 28.07
    },
    {
        "x": 36.05,
        "y": 28.07
    },
    {
        "x": 36.06,
        "y": 28.06
    },
    {
        "x": 36.07,
        "y": 28.07
    },
    {
        "x": 36.08,
        "y": 28.07
    },
    {
        "x": 36.09,
        "y": 28.07
    },
    {
        "x": 36.1,
        "y": 28.07
    },
    {
        "x": 36.11,
        "y": 28.07
    },
    {
        "x": 36.12,
        "y": 28.07
    },
    {
        "x": 36.13,
        "y": 28.07
    },
    {
        "x": 36.14,
        "y": 28.07
    },
    {
        "x": 36.15,
        "y": 28.07
    },
    {
        "x": 36.16,
        "y": 28.07
    },
    {
        "x": 36.17,
        "y": 28.07
    },
    {
        "x": 36.18,
        "y": 28.07
    },
    {
        "x": 36.19,
        "y": 28.07
    },
    {
        "x": 36.2,
        "y": 28.07
    },
    {
        "x": 36.21,
        "y": 28.07
    },
    {
        "x": 36.22,
        "y": 28.07
    },
    {
        "x": 36.23,
        "y": 28.07
    },
    {
        "x": 36.24,
        "y": 28.07
    },
    {
        "x": 36.25,
        "y": 28.07
    },
    {
        "x": 36.26,
        "y": 28.07
    },
    {
        "x": 36.27,
        "y": 28.07
    },
    {
        "x": 36.28,
        "y": 28.07
    },
    {
        "x": 36.29,
        "y": 28.08
    },
    {
        "x": 36.3,
        "y": 28.08
    },
    {
        "x": 36.31,
        "y": 28.08
    },
    {
        "x": 36.32,
        "y": 28.07
    },
    {
        "x": 36.33,
        "y": 28.07
    },
    {
        "x": 36.34,
        "y": 28.08
    },
    {
        "x": 36.35,
        "y": 28.08
    },
    {
        "x": 36.36,
        "y": 28.08
    },
    {
        "x": 36.37,
        "y": 28.08
    },
    {
        "x": 36.38,
        "y": 28.08
    },
    {
        "x": 36.39,
        "y": 28.08
    },
    {
        "x": 36.4,
        "y": 28.08
    },
    {
        "x": 36.41,
        "y": 28.08
    },
    {
        "x": 36.42,
        "y": 28.08
    },
    {
        "x": 36.43,
        "y": 28.08
    },
    {
        "x": 36.44,
        "y": 28.08
    },
    {
        "x": 36.45,
        "y": 28.08
    },
    {
        "x": 36.46,
        "y": 28.08
    },
    {
        "x": 36.47,
        "y": 28.08
    },
    {
        "x": 36.48,
        "y": 28.08
    },
    {
        "x": 36.49,
        "y": 28.08
    },
    {
        "x": 36.5,
        "y": 28.08
    },
    {
        "x": 36.51,
        "y": 28.09
    },
    {
        "x": 36.52,
        "y": 28.08
    },
    {
        "x": 36.53,
        "y": 28.09
    },
    {
        "x": 36.54,
        "y": 28.08
    },
    {
        "x": 36.55,
        "y": 28.08
    },
    {
        "x": 36.56,
        "y": 28.08
    },
    {
        "x": 36.57,
        "y": 28.09
    },
    {
        "x": 36.58,
        "y": 28.08
    },
    {
        "x": 36.59,
        "y": 28.08
    },
    {
        "x": 36.6,
        "y": 28.09
    },
    {
        "x": 36.61,
        "y": 28.09
    },
    {
        "x": 36.62,
        "y": 28.09
    },
    {
        "x": 36.63,
        "y": 28.09
    },
    {
        "x": 36.64,
        "y": 28.09
    },
    {
        "x": 36.65,
        "y": 28.09
    },
    {
        "x": 36.66,
        "y": 28.09
    },
    {
        "x": 36.67,
        "y": 28.09
    },
    {
        "x": 36.68,
        "y": 28.09
    },
    {
        "x": 36.69,
        "y": 28.09
    },
    {
        "x": 36.7,
        "y": 28.09
    },
    {
        "x": 36.71,
        "y": 28.09
    },
    {
        "x": 36.72,
        "y": 28.09
    },
    {
        "x": 36.73,
        "y": 28.09
    },
    {
        "x": 36.74,
        "y": 28.09
    },
    {
        "x": 36.75,
        "y": 28.09
    },
    {
        "x": 36.76,
        "y": 28.09
    },
    {
        "x": 36.77,
        "y": 28.09
    },
    {
        "x": 36.78,
        "y": 28.09
    },
    {
        "x": 36.79,
        "y": 28.09
    },
    {
        "x": 36.8,
        "y": 28.09
    },
    {
        "x": 36.81,
        "y": 28.09
    },
    {
        "x": 36.82,
        "y": 28.09
    },
    {
        "x": 36.83,
        "y": 28.09
    },
    {
        "x": 36.84,
        "y": 28.09
    },
    {
        "x": 36.85,
        "y": 28.09
    },
    {
        "x": 36.86,
        "y": 28.09
    },
    {
        "x": 36.87,
        "y": 28.09
    },
    {
        "x": 36.88,
        "y": 28.09
    },
    {
        "x": 36.89,
        "y": 28.09
    },
    {
        "x": 36.9,
        "y": 28.09
    },
    {
        "x": 36.91,
        "y": 28.09
    },
    {
        "x": 36.92,
        "y": 28.09
    },
    {
        "x": 36.93,
        "y": 28.11
    },
    {
        "x": 36.94,
        "y": 28.09
    },
    {
        "x": 36.95,
        "y": 28.09
    },
    {
        "x": 36.96,
        "y": 28.09
    },
    {
        "x": 36.97,
        "y": 28.11
    },
    {
        "x": 36.98,
        "y": 28.09
    },
    {
        "x": 36.99,
        "y": 28.09
    },
    {
        "x": 37,
        "y": 28.09
    },
    {
        "x": 37.01,
        "y": 28.11
    },
    {
        "x": 37.02,
        "y": 28.11
    },
    {
        "x": 37.03,
        "y": 28.11
    },
    {
        "x": 37.04,
        "y": 28.09
    },
    {
        "x": 37.05,
        "y": 28.11
    },
    {
        "x": 37.06,
        "y": 28.11
    },
    {
        "x": 37.07,
        "y": 28.11
    },
    {
        "x": 37.08,
        "y": 28.11
    },
    {
        "x": 37.09,
        "y": 28.09
    },
    {
        "x": 37.1,
        "y": 28.09
    },
    {
        "x": 37.11,
        "y": 28.11
    },
    {
        "x": 37.12,
        "y": 28.11
    },
    {
        "x": 37.13,
        "y": 28.11
    },
    {
        "x": 37.14,
        "y": 28.11
    },
    {
        "x": 37.15,
        "y": 28.09
    },
    {
        "x": 37.16,
        "y": 28.11
    },
    {
        "x": 37.17,
        "y": 28.11
    },
    {
        "x": 37.18,
        "y": 28.11
    },
    {
        "x": 37.19,
        "y": 28.11
    },
    {
        "x": 37.2,
        "y": 28.11
    },
    {
        "x": 37.21,
        "y": 28.11
    },
    {
        "x": 37.22,
        "y": 28.11
    },
    {
        "x": 37.23,
        "y": 28.11
    },
    {
        "x": 37.24,
        "y": 28.11
    },
    {
        "x": 37.25,
        "y": 28.11
    },
    {
        "x": 37.26,
        "y": 28.11
    },
    {
        "x": 37.27,
        "y": 28.11
    },
    {
        "x": 37.28,
        "y": 28.11
    },
    {
        "x": 37.29,
        "y": 28.11
    },
    {
        "x": 37.3,
        "y": 28.11
    },
    {
        "x": 37.31,
        "y": 28.11
    },
    {
        "x": 37.32,
        "y": 28.11
    },
    {
        "x": 37.33,
        "y": 28.11
    },
    {
        "x": 37.34,
        "y": 28.11
    },
    {
        "x": 37.35,
        "y": 28.12
    },
    {
        "x": 37.36,
        "y": 28.12
    },
    {
        "x": 37.37,
        "y": 28.11
    },
    {
        "x": 37.38,
        "y": 28.11
    },
    {
        "x": 37.39,
        "y": 28.11
    },
    {
        "x": 37.4,
        "y": 28.11
    },
    {
        "x": 37.41,
        "y": 28.12
    },
    {
        "x": 37.42,
        "y": 28.12
    },
    {
        "x": 37.43,
        "y": 28.12
    },
    {
        "x": 37.44,
        "y": 28.12
    },
    {
        "x": 37.45,
        "y": 28.12
    },
    {
        "x": 37.46,
        "y": 28.12
    },
    {
        "x": 37.47,
        "y": 28.12
    },
    {
        "x": 37.48,
        "y": 28.12
    },
    {
        "x": 37.49,
        "y": 28.12
    },
    {
        "x": 37.5,
        "y": 28.12
    },
    {
        "x": 37.51,
        "y": 28.12
    },
    {
        "x": 37.52,
        "y": 28.12
    },
    {
        "x": 37.53,
        "y": 28.12
    },
    {
        "x": 37.54,
        "y": 28.12
    },
    {
        "x": 37.55,
        "y": 28.12
    },
    {
        "x": 37.56,
        "y": 28.12
    },
    {
        "x": 37.57,
        "y": 28.12
    },
    {
        "x": 37.58,
        "y": 28.12
    },
    {
        "x": 37.59,
        "y": 28.12
    },
    {
        "x": 37.6,
        "y": 28.12
    },
    {
        "x": 37.61,
        "y": 28.12
    },
    {
        "x": 37.62,
        "y": 28.12
    },
    {
        "x": 37.63,
        "y": 28.12
    },
    {
        "x": 37.64,
        "y": 28.13
    },
    {
        "x": 37.65,
        "y": 28.13
    },
    {
        "x": 37.66,
        "y": 28.12
    },
    {
        "x": 37.67,
        "y": 28.13
    },
    {
        "x": 37.68,
        "y": 28.12
    },
    {
        "x": 37.69,
        "y": 28.12
    },
    {
        "x": 37.7,
        "y": 28.13
    },
    {
        "x": 37.71,
        "y": 28.13
    },
    {
        "x": 37.72,
        "y": 28.13
    },
    {
        "x": 37.73,
        "y": 28.13
    },
    {
        "x": 37.74,
        "y": 28.13
    },
    {
        "x": 37.75,
        "y": 28.13
    },
    {
        "x": 37.76,
        "y": 28.13
    },
    {
        "x": 37.77,
        "y": 28.13
    },
    {
        "x": 37.78,
        "y": 28.13
    },
    {
        "x": 37.79,
        "y": 28.13
    },
    {
        "x": 37.8,
        "y": 28.12
    },
    {
        "x": 37.81,
        "y": 28.13
    },
    {
        "x": 37.82,
        "y": 28.13
    },
    {
        "x": 37.83,
        "y": 28.13
    },
    {
        "x": 37.84,
        "y": 28.13
    },
    {
        "x": 37.85,
        "y": 28.13
    },
    {
        "x": 37.86,
        "y": 28.13
    },
    {
        "x": 37.87,
        "y": 28.13
    },
    {
        "x": 37.88,
        "y": 28.13
    },
    {
        "x": 37.89,
        "y": 28.13
    },
    {
        "x": 37.9,
        "y": 28.13
    },
    {
        "x": 37.91,
        "y": 28.13
    },
    {
        "x": 37.92,
        "y": 28.13
    },
    {
        "x": 37.93,
        "y": 28.13
    },
    {
        "x": 37.94,
        "y": 28.13
    },
    {
        "x": 37.95,
        "y": 28.13
    },
    {
        "x": 37.96,
        "y": 28.13
    },
    {
        "x": 37.97,
        "y": 28.13
    },
    {
        "x": 37.98,
        "y": 28.13
    },
    {
        "x": 37.99,
        "y": 28.13
    },
    {
        "x": 38,
        "y": 28.13
    },
    {
        "x": 38.01,
        "y": 28.13
    },
    {
        "x": 38.02,
        "y": 28.13
    },
    {
        "x": 38.03,
        "y": 28.13
    },
    {
        "x": 38.04,
        "y": 28.13
    },
    {
        "x": 38.05,
        "y": 28.13
    },
    {
        "x": 38.06,
        "y": 28.13
    },
    {
        "x": 38.07,
        "y": 28.13
    },
    {
        "x": 38.08,
        "y": 28.13
    },
    {
        "x": 38.09,
        "y": 28.13
    },
    {
        "x": 38.1,
        "y": 28.13
    },
    {
        "x": 38.11,
        "y": 28.13
    },
    {
        "x": 38.12,
        "y": 28.13
    },
    {
        "x": 38.13,
        "y": 28.13
    },
    {
        "x": 38.14,
        "y": 28.14
    },
    {
        "x": 38.15,
        "y": 28.13
    },
    {
        "x": 38.16,
        "y": 28.14
    },
    {
        "x": 38.17,
        "y": 28.14
    },
    {
        "x": 38.18,
        "y": 28.14
    },
    {
        "x": 38.19,
        "y": 28.13
    },
    {
        "x": 38.2,
        "y": 28.14
    },
    {
        "x": 38.21,
        "y": 28.14
    },
    {
        "x": 38.22,
        "y": 28.14
    },
    {
        "x": 38.23,
        "y": 28.14
    },
    {
        "x": 38.24,
        "y": 28.14
    },
    {
        "x": 38.25,
        "y": 28.14
    },
    {
        "x": 38.26,
        "y": 28.14
    },
    {
        "x": 38.27,
        "y": 28.14
    },
    {
        "x": 38.28,
        "y": 28.14
    },
    {
        "x": 38.29,
        "y": 28.14
    },
    {
        "x": 38.3,
        "y": 28.14
    },
    {
        "x": 38.31,
        "y": 28.14
    },
    {
        "x": 38.32,
        "y": 28.14
    },
    {
        "x": 38.33,
        "y": 28.14
    },
    {
        "x": 38.34,
        "y": 28.14
    },
    {
        "x": 38.35,
        "y": 28.14
    },
    {
        "x": 38.36,
        "y": 28.14
    },
    {
        "x": 38.37,
        "y": 28.14
    },
    {
        "x": 38.38,
        "y": 28.14
    },
    {
        "x": 38.39,
        "y": 28.14
    },
    {
        "x": 38.4,
        "y": 28.14
    },
    {
        "x": 38.41,
        "y": 28.14
    },
    {
        "x": 38.42,
        "y": 28.15
    },
    {
        "x": 38.43,
        "y": 28.15
    },
    {
        "x": 38.44,
        "y": 28.15
    },
    {
        "x": 38.45,
        "y": 28.15
    },
    {
        "x": 38.46,
        "y": 28.14
    },
    {
        "x": 38.47,
        "y": 28.15
    },
    {
        "x": 38.48,
        "y": 28.15
    },
    {
        "x": 38.49,
        "y": 28.15
    },
    {
        "x": 38.5,
        "y": 28.14
    },
    {
        "x": 38.51,
        "y": 28.15
    },
    {
        "x": 38.52,
        "y": 28.15
    },
    {
        "x": 38.53,
        "y": 28.14
    },
    {
        "x": 38.54,
        "y": 28.15
    },
    {
        "x": 38.55,
        "y": 28.15
    },
    {
        "x": 38.56,
        "y": 28.15
    },
    {
        "x": 38.57,
        "y": 28.15
    },
    {
        "x": 38.58,
        "y": 28.15
    },
    {
        "x": 38.59,
        "y": 28.15
    },
    {
        "x": 38.6,
        "y": 28.15
    },
    {
        "x": 38.61,
        "y": 28.15
    },
    {
        "x": 38.62,
        "y": 28.15
    },
    {
        "x": 38.63,
        "y": 28.15
    },
    {
        "x": 38.64,
        "y": 28.15
    },
    {
        "x": 38.65,
        "y": 28.15
    },
    {
        "x": 38.66,
        "y": 28.15
    },
    {
        "x": 38.67,
        "y": 28.15
    },
    {
        "x": 38.68,
        "y": 28.15
    },
    {
        "x": 38.69,
        "y": 28.15
    },
    {
        "x": 38.7,
        "y": 28.15
    },
    {
        "x": 38.71,
        "y": 28.15
    },
    {
        "x": 38.72,
        "y": 28.15
    },
    {
        "x": 38.73,
        "y": 28.16
    },
    {
        "x": 38.74,
        "y": 28.15
    },
    {
        "x": 38.75,
        "y": 28.16
    },
    {
        "x": 38.76,
        "y": 28.16
    },
    {
        "x": 38.77,
        "y": 28.15
    },
    {
        "x": 38.78,
        "y": 28.15
    },
    {
        "x": 38.79,
        "y": 28.15
    },
    {
        "x": 38.8,
        "y": 28.16
    },
    {
        "x": 38.81,
        "y": 28.16
    },
    {
        "x": 38.82,
        "y": 28.15
    },
    {
        "x": 38.83,
        "y": 28.16
    },
    {
        "x": 38.84,
        "y": 28.15
    },
    {
        "x": 38.85,
        "y": 28.16
    },
    {
        "x": 38.86,
        "y": 28.16
    },
    {
        "x": 38.87,
        "y": 28.16
    },
    {
        "x": 38.88,
        "y": 28.16
    },
    {
        "x": 38.89,
        "y": 28.16
    },
    {
        "x": 38.9,
        "y": 28.16
    },
    {
        "x": 38.91,
        "y": 28.16
    },
    {
        "x": 38.92,
        "y": 28.16
    },
    {
        "x": 38.93,
        "y": 28.16
    },
    {
        "x": 38.94,
        "y": 28.16
    },
    {
        "x": 38.95,
        "y": 28.16
    },
    {
        "x": 38.96,
        "y": 28.16
    },
    {
        "x": 38.97,
        "y": 28.16
    },
    {
        "x": 38.98,
        "y": 28.16
    },
    {
        "x": 38.99,
        "y": 28.16
    },
    {
        "x": 39,
        "y": 28.16
    },
    {
        "x": 39.01,
        "y": 28.16
    },
    {
        "x": 39.02,
        "y": 28.16
    },
    {
        "x": 39.03,
        "y": 28.16
    },
    {
        "x": 39.04,
        "y": 28.16
    },
    {
        "x": 39.05,
        "y": 28.16
    },
    {
        "x": 39.06,
        "y": 28.16
    },
    {
        "x": 39.07,
        "y": 28.16
    },
    {
        "x": 39.08,
        "y": 28.17
    },
    {
        "x": 39.09,
        "y": 28.16
    },
    {
        "x": 39.1,
        "y": 28.16
    },
    {
        "x": 39.11,
        "y": 28.16
    },
    {
        "x": 39.12,
        "y": 28.16
    },
    {
        "x": 39.13,
        "y": 28.16
    },
    {
        "x": 39.14,
        "y": 28.17
    },
    {
        "x": 39.15,
        "y": 28.17
    },
    {
        "x": 39.16,
        "y": 28.17
    },
    {
        "x": 39.17,
        "y": 28.17
    },
    {
        "x": 39.18,
        "y": 28.16
    },
    {
        "x": 39.19,
        "y": 28.17
    },
    {
        "x": 39.2,
        "y": 28.16
    },
    {
        "x": 39.21,
        "y": 28.17
    },
    {
        "x": 39.22,
        "y": 28.17
    },
    {
        "x": 39.23,
        "y": 28.17
    },
    {
        "x": 39.24,
        "y": 28.17
    },
    {
        "x": 39.25,
        "y": 28.17
    },
    {
        "x": 39.26,
        "y": 28.17
    },
    {
        "x": 39.27,
        "y": 28.17
    },
    {
        "x": 39.28,
        "y": 28.17
    },
    {
        "x": 39.29,
        "y": 28.17
    },
    {
        "x": 39.3,
        "y": 28.17
    },
    {
        "x": 39.31,
        "y": 28.17
    },
    {
        "x": 39.32,
        "y": 28.17
    },
    {
        "x": 39.33,
        "y": 28.17
    },
    {
        "x": 39.34,
        "y": 28.17
    },
    {
        "x": 39.35,
        "y": 28.17
    },
    {
        "x": 39.36,
        "y": 28.17
    },
    {
        "x": 39.37,
        "y": 28.17
    },
    {
        "x": 39.38,
        "y": 28.17
    },
    {
        "x": 39.39,
        "y": 28.17
    },
    {
        "x": 39.4,
        "y": 28.17
    },
    {
        "x": 39.41,
        "y": 28.17
    },
    {
        "x": 39.42,
        "y": 28.17
    },
    {
        "x": 39.43,
        "y": 28.17
    },
    {
        "x": 39.44,
        "y": 28.17
    },
    {
        "x": 39.45,
        "y": 28.17
    },
    {
        "x": 39.46,
        "y": 28.17
    },
    {
        "x": 39.47,
        "y": 28.17
    },
    {
        "x": 39.48,
        "y": 28.17
    },
    {
        "x": 39.49,
        "y": 28.17
    },
    {
        "x": 39.5,
        "y": 28.18
    },
    {
        "x": 39.51,
        "y": 28.17
    },
    {
        "x": 39.52,
        "y": 28.17
    },
    {
        "x": 39.53,
        "y": 28.17
    },
    {
        "x": 39.54,
        "y": 28.17
    },
    {
        "x": 39.55,
        "y": 28.17
    },
    {
        "x": 39.56,
        "y": 28.17
    },
    {
        "x": 39.57,
        "y": 28.18
    },
    {
        "x": 39.58,
        "y": 28.18
    },
    {
        "x": 39.59,
        "y": 28.17
    },
    {
        "x": 39.6,
        "y": 28.17
    },
    {
        "x": 39.61,
        "y": 28.17
    },
    {
        "x": 39.62,
        "y": 28.17
    },
    {
        "x": 39.63,
        "y": 28.17
    },
    {
        "x": 39.64,
        "y": 28.17
    },
    {
        "x": 39.65,
        "y": 28.18
    },
    {
        "x": 39.66,
        "y": 28.17
    },
    {
        "x": 39.67,
        "y": 28.18
    },
    {
        "x": 39.68,
        "y": 28.18
    },
    {
        "x": 39.69,
        "y": 28.18
    },
    {
        "x": 39.7,
        "y": 28.18
    },
    {
        "x": 39.71,
        "y": 28.18
    },
    {
        "x": 39.72,
        "y": 28.18
    },
    {
        "x": 39.73,
        "y": 28.18
    },
    {
        "x": 39.74,
        "y": 28.18
    },
    {
        "x": 39.75,
        "y": 28.18
    },
    {
        "x": 39.76,
        "y": 28.18
    },
    {
        "x": 39.77,
        "y": 28.18
    },
    {
        "x": 39.78,
        "y": 28.17
    },
    {
        "x": 39.79,
        "y": 28.18
    },
    {
        "x": 39.8,
        "y": 28.18
    },
    {
        "x": 39.81,
        "y": 28.18
    },
    {
        "x": 39.82,
        "y": 28.18
    },
    {
        "x": 39.83,
        "y": 28.18
    },
    {
        "x": 39.84,
        "y": 28.18
    },
    {
        "x": 39.85,
        "y": 28.18
    },
    {
        "x": 39.86,
        "y": 28.18
    },
    {
        "x": 39.87,
        "y": 28.18
    },
    {
        "x": 39.88,
        "y": 28.18
    },
    {
        "x": 39.89,
        "y": 28.18
    },
    {
        "x": 39.9,
        "y": 28.18
    },
    {
        "x": 39.91,
        "y": 28.18
    },
    {
        "x": 39.92,
        "y": 28.18
    },
    {
        "x": 39.93,
        "y": 28.18
    },
    {
        "x": 39.94,
        "y": 28.18
    },
    {
        "x": 39.95,
        "y": 28.18
    },
    {
        "x": 39.96,
        "y": 28.18
    },
    {
        "x": 39.97,
        "y": 28.18
    },
    {
        "x": 39.98,
        "y": 28.18
    },
    {
        "x": 39.99,
        "y": 28.18
    },
    {
        "x": 40,
        "y": 28.18
    },
    {
        "x": 40.01,
        "y": 28.18
    },
    {
        "x": 40.02,
        "y": 28.18
    },
    {
        "x": 40.03,
        "y": 28.19
    },
    {
        "x": 40.04,
        "y": 28.19
    },
    {
        "x": 40.05,
        "y": 28.18
    },
    {
        "x": 40.06,
        "y": 28.19
    },
    {
        "x": 40.07,
        "y": 28.19
    },
    {
        "x": 40.08,
        "y": 28.19
    },
    {
        "x": 40.09,
        "y": 28.19
    },
    {
        "x": 40.1,
        "y": 28.19
    },
    {
        "x": 40.11,
        "y": 28.19
    },
    {
        "x": 40.12,
        "y": 28.19
    },
    {
        "x": 40.13,
        "y": 28.19
    },
    {
        "x": 40.14,
        "y": 28.19
    },
    {
        "x": 40.15,
        "y": 28.19
    },
    {
        "x": 40.16,
        "y": 28.19
    },
    {
        "x": 40.17,
        "y": 28.19
    },
    {
        "x": 40.18,
        "y": 28.19
    },
    {
        "x": 40.19,
        "y": 28.19
    },
    {
        "x": 40.2,
        "y": 28.19
    },
    {
        "x": 40.21,
        "y": 28.19
    },
    {
        "x": 40.22,
        "y": 28.19
    },
    {
        "x": 40.23,
        "y": 28.19
    },
    {
        "x": 40.24,
        "y": 28.19
    },
    {
        "x": 40.25,
        "y": 28.19
    },
    {
        "x": 40.26,
        "y": 28.19
    },
    {
        "x": 40.27,
        "y": 28.19
    },
    {
        "x": 40.28,
        "y": 28.19
    },
    {
        "x": 40.29,
        "y": 28.19
    },
    {
        "x": 40.3,
        "y": 28.19
    },
    {
        "x": 40.31,
        "y": 28.19
    },
    {
        "x": 40.32,
        "y": 28.19
    },
    {
        "x": 40.33,
        "y": 28.19
    },
    {
        "x": 40.34,
        "y": 28.19
    },
    {
        "x": 40.35,
        "y": 28.19
    },
    {
        "x": 40.36,
        "y": 28.19
    },
    {
        "x": 40.37,
        "y": 28.19
    },
    {
        "x": 40.38,
        "y": 28.19
    },
    {
        "x": 40.39,
        "y": 28.2
    },
    {
        "x": 40.4,
        "y": 28.19
    },
    {
        "x": 40.41,
        "y": 28.19
    },
    {
        "x": 40.42,
        "y": 28.19
    },
    {
        "x": 40.43,
        "y": 28.19
    },
    {
        "x": 40.44,
        "y": 28.19
    },
    {
        "x": 40.45,
        "y": 28.19
    },
    {
        "x": 40.46,
        "y": 28.19
    },
    {
        "x": 40.47,
        "y": 28.19
    },
    {
        "x": 40.48,
        "y": 28.19
    },
    {
        "x": 40.49,
        "y": 28.19
    },
    {
        "x": 40.5,
        "y": 28.19
    },
    {
        "x": 40.51,
        "y": 28.19
    },
    {
        "x": 40.52,
        "y": 28.19
    },
    {
        "x": 40.53,
        "y": 28.19
    },
    {
        "x": 40.54,
        "y": 28.19
    },
    {
        "x": 40.55,
        "y": 28.19
    },
    {
        "x": 40.56,
        "y": 28.2
    },
    {
        "x": 40.57,
        "y": 28.19
    },
    {
        "x": 40.58,
        "y": 28.19
    },
    {
        "x": 40.59,
        "y": 28.2
    },
    {
        "x": 40.6,
        "y": 28.19
    },
    {
        "x": 40.61,
        "y": 28.2
    },
    {
        "x": 40.62,
        "y": 28.19
    },
    {
        "x": 40.63,
        "y": 28.19
    },
    {
        "x": 40.64,
        "y": 28.19
    },
    {
        "x": 40.65,
        "y": 28.19
    },
    {
        "x": 40.66,
        "y": 28.2
    },
    {
        "x": 40.67,
        "y": 28.2
    },
    {
        "x": 40.68,
        "y": 28.2
    },
    {
        "x": 40.69,
        "y": 28.2
    },
    {
        "x": 40.7,
        "y": 28.2
    },
    {
        "x": 40.71,
        "y": 28.2
    },
    {
        "x": 40.72,
        "y": 28.2
    },
    {
        "x": 40.73,
        "y": 28.2
    },
    {
        "x": 40.74,
        "y": 28.2
    },
    {
        "x": 40.75,
        "y": 28.19
    },
    {
        "x": 40.76,
        "y": 28.2
    },
    {
        "x": 40.77,
        "y": 28.2
    },
    {
        "x": 40.78,
        "y": 28.2
    },
    {
        "x": 40.79,
        "y": 28.2
    },
    {
        "x": 40.8,
        "y": 28.2
    },
    {
        "x": 40.81,
        "y": 28.2
    },
    {
        "x": 40.82,
        "y": 28.2
    },
    {
        "x": 40.83,
        "y": 28.2
    },
    {
        "x": 40.84,
        "y": 28.2
    },
    {
        "x": 40.85,
        "y": 28.2
    },
    {
        "x": 40.86,
        "y": 28.2
    },
    {
        "x": 40.87,
        "y": 28.2
    },
    {
        "x": 40.88,
        "y": 28.2
    },
    {
        "x": 40.89,
        "y": 28.2
    },
    {
        "x": 40.9,
        "y": 28.2
    },
    {
        "x": 40.91,
        "y": 28.2
    },
    {
        "x": 40.92,
        "y": 28.2
    },
    {
        "x": 40.93,
        "y": 28.2
    },
    {
        "x": 40.94,
        "y": 28.2
    },
    {
        "x": 40.95,
        "y": 28.2
    },
    {
        "x": 40.96,
        "y": 28.2
    },
    {
        "x": 40.97,
        "y": 28.2
    },
    {
        "x": 40.98,
        "y": 28.2
    },
    {
        "x": 40.99,
        "y": 28.2
    },
    {
        "x": 41,
        "y": 28.2
    },
    {
        "x": 41.01,
        "y": 28.22
    },
    {
        "x": 41.02,
        "y": 28.2
    },
    {
        "x": 41.03,
        "y": 28.2
    },
    {
        "x": 41.04,
        "y": 28.2
    },
    {
        "x": 41.05,
        "y": 28.22
    },
    {
        "x": 41.06,
        "y": 28.2
    },
    {
        "x": 41.07,
        "y": 28.2
    },
    {
        "x": 41.08,
        "y": 28.2
    },
    {
        "x": 41.09,
        "y": 28.2
    },
    {
        "x": 41.1,
        "y": 28.22
    },
    {
        "x": 41.11,
        "y": 28.2
    },
    {
        "x": 41.12,
        "y": 28.2
    },
    {
        "x": 41.13,
        "y": 28.22
    },
    {
        "x": 41.14,
        "y": 28.22
    },
    {
        "x": 41.15,
        "y": 28.22
    },
    {
        "x": 41.16,
        "y": 28.22
    },
    {
        "x": 41.17,
        "y": 28.22
    },
    {
        "x": 41.18,
        "y": 28.2
    },
    {
        "x": 41.19,
        "y": 28.22
    },
    {
        "x": 41.2,
        "y": 28.22
    },
    {
        "x": 41.21,
        "y": 28.22
    },
    {
        "x": 41.22,
        "y": 28.22
    },
    {
        "x": 41.23,
        "y": 28.22
    },
    {
        "x": 41.24,
        "y": 28.22
    },
    {
        "x": 41.25,
        "y": 28.22
    },
    {
        "x": 41.26,
        "y": 28.22
    },
    {
        "x": 41.27,
        "y": 28.22
    },
    {
        "x": 41.28,
        "y": 28.22
    },
    {
        "x": 41.29,
        "y": 28.22
    },
    {
        "x": 41.3,
        "y": 28.22
    },
    {
        "x": 41.31,
        "y": 28.22
    },
    {
        "x": 41.32,
        "y": 28.22
    },
    {
        "x": 41.33,
        "y": 28.22
    },
    {
        "x": 41.34,
        "y": 28.22
    },
    {
        "x": 41.35,
        "y": 28.22
    },
    {
        "x": 41.36,
        "y": 28.22
    },
    {
        "x": 41.37,
        "y": 28.22
    },
    {
        "x": 41.38,
        "y": 28.22
    },
    {
        "x": 41.39,
        "y": 28.22
    },
    {
        "x": 41.4,
        "y": 28.22
    },
    {
        "x": 41.41,
        "y": 28.22
    },
    {
        "x": 41.42,
        "y": 28.22
    },
    {
        "x": 41.43,
        "y": 28.22
    },
    {
        "x": 41.44,
        "y": 28.22
    },
    {
        "x": 41.45,
        "y": 28.22
    },
    {
        "x": 41.46,
        "y": 28.23
    },
    {
        "x": 41.47,
        "y": 28.23
    },
    {
        "x": 41.48,
        "y": 28.22
    },
    {
        "x": 41.49,
        "y": 28.22
    },
    {
        "x": 41.5,
        "y": 28.22
    },
    {
        "x": 41.51,
        "y": 28.22
    },
    {
        "x": 41.52,
        "y": 28.22
    },
    {
        "x": 41.53,
        "y": 28.22
    },
    {
        "x": 41.54,
        "y": 28.23
    },
    {
        "x": 41.55,
        "y": 28.23
    },
    {
        "x": 41.56,
        "y": 28.23
    },
    {
        "x": 41.57,
        "y": 28.22
    },
    {
        "x": 41.58,
        "y": 28.22
    },
    {
        "x": 41.59,
        "y": 28.23
    },
    {
        "x": 41.6,
        "y": 28.22
    },
    {
        "x": 41.61,
        "y": 28.23
    },
    {
        "x": 41.62,
        "y": 28.23
    },
    {
        "x": 41.63,
        "y": 28.23
    },
    {
        "x": 41.64,
        "y": 28.22
    },
    {
        "x": 41.65,
        "y": 28.22
    },
    {
        "x": 41.66,
        "y": 28.23
    },
    {
        "x": 41.67,
        "y": 28.23
    },
    {
        "x": 41.68,
        "y": 28.23
    },
    {
        "x": 41.69,
        "y": 28.23
    },
    {
        "x": 41.7,
        "y": 28.23
    },
    {
        "x": 41.71,
        "y": 28.22
    },
    {
        "x": 41.72,
        "y": 28.23
    },
    {
        "x": 41.73,
        "y": 28.23
    },
    {
        "x": 41.74,
        "y": 28.23
    },
    {
        "x": 41.75,
        "y": 28.23
    },
    {
        "x": 41.76,
        "y": 28.23
    },
    {
        "x": 41.77,
        "y": 28.23
    },
    {
        "x": 41.78,
        "y": 28.23
    },
    {
        "x": 41.79,
        "y": 28.23
    },
    {
        "x": 41.8,
        "y": 28.23
    },
    {
        "x": 41.81,
        "y": 28.23
    },
    {
        "x": 41.82,
        "y": 28.23
    },
    {
        "x": 41.83,
        "y": 28.23
    },
    {
        "x": 41.84,
        "y": 28.23
    },
    {
        "x": 41.85,
        "y": 28.23
    },
    {
        "x": 41.86,
        "y": 28.23
    },
    {
        "x": 41.87,
        "y": 28.23
    },
    {
        "x": 41.88,
        "y": 28.23
    },
    {
        "x": 41.89,
        "y": 28.23
    },
    {
        "x": 41.9,
        "y": 28.23
    },
    {
        "x": 41.91,
        "y": 28.23
    },
    {
        "x": 41.92,
        "y": 28.23
    },
    {
        "x": 41.93,
        "y": 28.23
    },
    {
        "x": 41.94,
        "y": 28.23
    },
    {
        "x": 41.95,
        "y": 28.23
    },
    {
        "x": 41.96,
        "y": 28.23
    },
    {
        "x": 41.97,
        "y": 28.23
    },
    {
        "x": 41.98,
        "y": 28.23
    },
    {
        "x": 41.99,
        "y": 28.23
    },
    {
        "x": 42,
        "y": 28.23
    },
    {
        "x": 42.01,
        "y": 28.23
    },
    {
        "x": 42.02,
        "y": 28.23
    },
    {
        "x": 42.03,
        "y": 28.23
    },
    {
        "x": 42.04,
        "y": 28.23
    },
    {
        "x": 42.05,
        "y": 28.23
    },
    {
        "x": 42.06,
        "y": 28.23
    },
    {
        "x": 42.07,
        "y": 28.23
    },
    {
        "x": 42.08,
        "y": 28.23
    },
    {
        "x": 42.09,
        "y": 28.23
    },
    {
        "x": 42.1,
        "y": 28.23
    },
    {
        "x": 42.11,
        "y": 28.23
    },
    {
        "x": 42.12,
        "y": 28.23
    },
    {
        "x": 42.13,
        "y": 28.23
    },
    {
        "x": 42.14,
        "y": 28.23
    },
    {
        "x": 42.15,
        "y": 28.24
    },
    {
        "x": 42.16,
        "y": 28.23
    },
    {
        "x": 42.17,
        "y": 28.23
    },
    {
        "x": 42.18,
        "y": 28.23
    },
    {
        "x": 42.19,
        "y": 28.23
    },
    {
        "x": 42.2,
        "y": 28.24
    },
    {
        "x": 42.21,
        "y": 28.24
    },
    {
        "x": 42.22,
        "y": 28.23
    },
    {
        "x": 42.23,
        "y": 28.24
    },
    {
        "x": 42.24,
        "y": 28.24
    },
    {
        "x": 42.25,
        "y": 28.24
    },
    {
        "x": 42.26,
        "y": 28.24
    },
    {
        "x": 42.27,
        "y": 28.24
    },
    {
        "x": 42.28,
        "y": 28.24
    },
    {
        "x": 42.29,
        "y": 28.24
    },
    {
        "x": 42.3,
        "y": 28.23
    },
    {
        "x": 42.31,
        "y": 28.23
    },
    {
        "x": 42.32,
        "y": 28.24
    },
    {
        "x": 42.33,
        "y": 28.24
    },
    {
        "x": 42.34,
        "y": 28.24
    },
    {
        "x": 42.35,
        "y": 28.24
    },
    {
        "x": 42.36,
        "y": 28.24
    },
    {
        "x": 42.37,
        "y": 28.23
    },
    {
        "x": 42.38,
        "y": 28.24
    },
    {
        "x": 42.39,
        "y": 28.24
    },
    {
        "x": 42.4,
        "y": 28.24
    },
    {
        "x": 42.41,
        "y": 28.24
    },
    {
        "x": 42.42,
        "y": 28.24
    },
    {
        "x": 42.43,
        "y": 28.24
    },
    {
        "x": 42.44,
        "y": 28.24
    },
    {
        "x": 42.45,
        "y": 28.24
    },
    {
        "x": 42.46,
        "y": 28.24
    },
    {
        "x": 42.47,
        "y": 28.23
    },
    {
        "x": 42.48,
        "y": 28.24
    },
    {
        "x": 42.49,
        "y": 28.24
    },
    {
        "x": 42.5,
        "y": 28.24
    },
    {
        "x": 42.51,
        "y": 28.24
    },
    {
        "x": 42.52,
        "y": 28.24
    },
    {
        "x": 42.53,
        "y": 28.24
    },
    {
        "x": 42.54,
        "y": 28.24
    },
    {
        "x": 42.55,
        "y": 28.24
    },
    {
        "x": 42.56,
        "y": 28.24
    },
    {
        "x": 42.57,
        "y": 28.24
    },
    {
        "x": 42.58,
        "y": 28.24
    },
    {
        "x": 42.59,
        "y": 28.24
    },
    {
        "x": 42.6,
        "y": 28.24
    },
    {
        "x": 42.61,
        "y": 28.24
    },
    {
        "x": 42.62,
        "y": 28.24
    },
    {
        "x": 42.63,
        "y": 28.24
    },
    {
        "x": 42.64,
        "y": 28.24
    },
    {
        "x": 42.65,
        "y": 28.24
    },
    {
        "x": 42.66,
        "y": 28.24
    },
    {
        "x": 42.67,
        "y": 28.24
    },
    {
        "x": 42.68,
        "y": 28.24
    },
    {
        "x": 42.69,
        "y": 28.24
    },
    {
        "x": 42.7,
        "y": 28.25
    },
    {
        "x": 42.71,
        "y": 28.24
    },
    {
        "x": 42.72,
        "y": 28.24
    },
    {
        "x": 42.73,
        "y": 28.24
    },
    {
        "x": 42.74,
        "y": 28.25
    },
    {
        "x": 42.75,
        "y": 28.25
    },
    {
        "x": 42.76,
        "y": 28.24
    },
    {
        "x": 42.77,
        "y": 28.24
    },
    {
        "x": 42.78,
        "y": 28.24
    },
    {
        "x": 42.79,
        "y": 28.25
    },
    {
        "x": 42.8,
        "y": 28.25
    },
    {
        "x": 42.81,
        "y": 28.25
    },
    {
        "x": 42.82,
        "y": 28.24
    },
    {
        "x": 42.83,
        "y": 28.24
    },
    {
        "x": 42.84,
        "y": 28.25
    },
    {
        "x": 42.85,
        "y": 28.24
    },
    {
        "x": 42.86,
        "y": 28.25
    },
    {
        "x": 42.87,
        "y": 28.25
    },
    {
        "x": 42.88,
        "y": 28.24
    },
    {
        "x": 42.89,
        "y": 28.24
    },
    {
        "x": 42.9,
        "y": 28.24
    },
    {
        "x": 42.91,
        "y": 28.24
    },
    {
        "x": 42.92,
        "y": 28.25
    },
    {
        "x": 42.93,
        "y": 28.24
    },
    {
        "x": 42.94,
        "y": 28.25
    },
    {
        "x": 42.95,
        "y": 28.25
    },
    {
        "x": 42.96,
        "y": 28.25
    },
    {
        "x": 42.97,
        "y": 28.25
    },
    {
        "x": 42.98,
        "y": 28.25
    },
    {
        "x": 42.99,
        "y": 28.25
    },
    {
        "x": 43,
        "y": 28.25
    },
    {
        "x": 43.01,
        "y": 28.24
    },
    {
        "x": 43.02,
        "y": 28.25
    },
    {
        "x": 43.03,
        "y": 28.25
    },
    {
        "x": 43.04,
        "y": 28.25
    },
    {
        "x": 43.05,
        "y": 28.25
    },
    {
        "x": 43.06,
        "y": 28.25
    },
    {
        "x": 43.07,
        "y": 28.25
    },
    {
        "x": 43.08,
        "y": 28.25
    },
    {
        "x": 43.09,
        "y": 28.25
    },
    {
        "x": 43.1,
        "y": 28.25
    },
    {
        "x": 43.11,
        "y": 28.25
    },
    {
        "x": 43.12,
        "y": 28.25
    },
    {
        "x": 43.13,
        "y": 28.25
    },
    {
        "x": 43.14,
        "y": 28.25
    },
    {
        "x": 43.15,
        "y": 28.25
    },
    {
        "x": 43.16,
        "y": 28.25
    },
    {
        "x": 43.17,
        "y": 28.25
    },
    {
        "x": 43.18,
        "y": 28.25
    },
    {
        "x": 43.19,
        "y": 28.25
    },
    {
        "x": 43.2,
        "y": 28.25
    },
    {
        "x": 43.21,
        "y": 28.25
    },
    {
        "x": 43.22,
        "y": 28.25
    },
    {
        "x": 43.23,
        "y": 28.25
    },
    {
        "x": 43.24,
        "y": 28.25
    },
    {
        "x": 43.25,
        "y": 28.25
    },
    {
        "x": 43.26,
        "y": 28.25
    },
    {
        "x": 43.27,
        "y": 28.25
    },
    {
        "x": 43.28,
        "y": 28.25
    },
    {
        "x": 43.29,
        "y": 28.25
    },
    {
        "x": 43.3,
        "y": 28.25
    },
    {
        "x": 43.31,
        "y": 28.25
    },
    {
        "x": 43.32,
        "y": 28.25
    },
    {
        "x": 43.33,
        "y": 28.25
    },
    {
        "x": 43.34,
        "y": 28.25
    },
    {
        "x": 43.35,
        "y": 28.25
    },
    {
        "x": 43.36,
        "y": 28.25
    },
    {
        "x": 43.37,
        "y": 28.25
    },
    {
        "x": 43.38,
        "y": 28.25
    },
    {
        "x": 43.39,
        "y": 28.25
    },
    {
        "x": 43.4,
        "y": 28.25
    },
    {
        "x": 43.41,
        "y": 28.25
    },
    {
        "x": 43.42,
        "y": 28.25
    },
    {
        "x": 43.43,
        "y": 28.25
    },
    {
        "x": 43.44,
        "y": 28.25
    },
    {
        "x": 43.45,
        "y": 28.25
    },
    {
        "x": 43.46,
        "y": 28.25
    },
    {
        "x": 43.47,
        "y": 28.25
    },
    {
        "x": 43.48,
        "y": 28.25
    },
    {
        "x": 43.49,
        "y": 28.25
    },
    {
        "x": 43.5,
        "y": 28.25
    },
    {
        "x": 43.51,
        "y": 28.25
    },
    {
        "x": 43.52,
        "y": 28.25
    },
    {
        "x": 43.53,
        "y": 28.25
    },
    {
        "x": 43.54,
        "y": 28.25
    },
    {
        "x": 43.55,
        "y": 28.25
    },
    {
        "x": 43.56,
        "y": 28.25
    },
    {
        "x": 43.57,
        "y": 28.25
    },
    {
        "x": 43.58,
        "y": 28.25
    },
    {
        "x": 43.59,
        "y": 28.25
    },
    {
        "x": 43.6,
        "y": 28.25
    },
    {
        "x": 43.61,
        "y": 28.26
    },
    {
        "x": 43.62,
        "y": 28.26
    },
    {
        "x": 43.63,
        "y": 28.25
    },
    {
        "x": 43.64,
        "y": 28.25
    },
    {
        "x": 43.65,
        "y": 28.26
    },
    {
        "x": 43.66,
        "y": 28.25
    },
    {
        "x": 43.67,
        "y": 28.26
    },
    {
        "x": 43.68,
        "y": 28.26
    },
    {
        "x": 43.69,
        "y": 28.26
    },
    {
        "x": 43.7,
        "y": 28.26
    },
    {
        "x": 43.71,
        "y": 28.26
    },
    {
        "x": 43.72,
        "y": 28.26
    },
    {
        "x": 43.73,
        "y": 28.26
    },
    {
        "x": 43.74,
        "y": 28.26
    },
    {
        "x": 43.75,
        "y": 28.26
    },
    {
        "x": 43.76,
        "y": 28.26
    },
    {
        "x": 43.77,
        "y": 28.26
    },
    {
        "x": 43.78,
        "y": 28.26
    },
    {
        "x": 43.79,
        "y": 28.26
    },
    {
        "x": 43.8,
        "y": 28.26
    },
    {
        "x": 43.81,
        "y": 28.26
    },
    {
        "x": 43.82,
        "y": 28.26
    },
    {
        "x": 43.83,
        "y": 28.26
    },
    {
        "x": 43.84,
        "y": 28.26
    },
    {
        "x": 43.85,
        "y": 28.26
    },
    {
        "x": 43.86,
        "y": 28.26
    },
    {
        "x": 43.87,
        "y": 28.26
    },
    {
        "x": 43.88,
        "y": 28.26
    },
    {
        "x": 43.89,
        "y": 28.26
    },
    {
        "x": 43.9,
        "y": 28.26
    },
    {
        "x": 43.91,
        "y": 28.26
    },
    {
        "x": 43.92,
        "y": 28.26
    },
    {
        "x": 43.93,
        "y": 28.26
    },
    {
        "x": 43.94,
        "y": 28.26
    },
    {
        "x": 43.95,
        "y": 28.26
    },
    {
        "x": 43.96,
        "y": 28.26
    },
    {
        "x": 43.97,
        "y": 28.26
    },
    {
        "x": 43.98,
        "y": 28.26
    },
    {
        "x": 43.99,
        "y": 28.26
    },
    {
        "x": 44,
        "y": 28.26
    },
    {
        "x": 44.01,
        "y": 28.26
    },
    {
        "x": 44.02,
        "y": 28.26
    },
    {
        "x": 44.03,
        "y": 28.26
    },
    {
        "x": 44.04,
        "y": 28.26
    },
    {
        "x": 44.05,
        "y": 28.26
    },
    {
        "x": 44.06,
        "y": 28.26
    },
    {
        "x": 44.07,
        "y": 28.27
    },
    {
        "x": 44.08,
        "y": 28.26
    },
    {
        "x": 44.09,
        "y": 28.26
    },
    {
        "x": 44.1,
        "y": 28.26
    },
    {
        "x": 44.11,
        "y": 28.26
    },
    {
        "x": 44.12,
        "y": 28.26
    },
    {
        "x": 44.13,
        "y": 28.26
    },
    {
        "x": 44.14,
        "y": 28.26
    },
    {
        "x": 44.15,
        "y": 28.26
    },
    {
        "x": 44.16,
        "y": 28.26
    },
    {
        "x": 44.17,
        "y": 28.26
    },
    {
        "x": 44.18,
        "y": 28.26
    },
    {
        "x": 44.19,
        "y": 28.26
    },
    {
        "x": 44.2,
        "y": 28.26
    },
    {
        "x": 44.21,
        "y": 28.26
    },
    {
        "x": 44.22,
        "y": 28.26
    },
    {
        "x": 44.23,
        "y": 28.27
    },
    {
        "x": 44.24,
        "y": 28.27
    },
    {
        "x": 44.25,
        "y": 28.26
    },
    {
        "x": 44.26,
        "y": 28.26
    },
    {
        "x": 44.27,
        "y": 28.26
    },
    {
        "x": 44.28,
        "y": 28.26
    },
    {
        "x": 44.29,
        "y": 28.26
    },
    {
        "x": 44.3,
        "y": 28.26
    },
    {
        "x": 44.31,
        "y": 28.26
    },
    {
        "x": 44.32,
        "y": 28.27
    },
    {
        "x": 44.33,
        "y": 28.27
    },
    {
        "x": 44.34,
        "y": 28.26
    },
    {
        "x": 44.35,
        "y": 28.26
    },
    {
        "x": 44.36,
        "y": 28.26
    },
    {
        "x": 44.37,
        "y": 28.26
    },
    {
        "x": 44.38,
        "y": 28.27
    },
    {
        "x": 44.39,
        "y": 28.27
    },
    {
        "x": 44.4,
        "y": 28.27
    },
    {
        "x": 44.41,
        "y": 28.26
    },
    {
        "x": 44.42,
        "y": 28.27
    },
    {
        "x": 44.43,
        "y": 28.26
    },
    {
        "x": 44.44,
        "y": 28.27
    },
    {
        "x": 44.45,
        "y": 28.27
    },
    {
        "x": 44.46,
        "y": 28.27
    },
    {
        "x": 44.47,
        "y": 28.27
    },
    {
        "x": 44.48,
        "y": 28.27
    },
    {
        "x": 44.49,
        "y": 28.27
    },
    {
        "x": 44.5,
        "y": 28.27
    },
    {
        "x": 44.51,
        "y": 28.26
    },
    {
        "x": 44.52,
        "y": 28.27
    },
    {
        "x": 44.53,
        "y": 28.27
    },
    {
        "x": 44.54,
        "y": 28.27
    },
    {
        "x": 44.55,
        "y": 28.27
    },
    {
        "x": 44.56,
        "y": 28.27
    },
    {
        "x": 44.57,
        "y": 28.27
    },
    {
        "x": 44.58,
        "y": 28.27
    },
    {
        "x": 44.59,
        "y": 28.27
    },
    {
        "x": 44.6,
        "y": 28.27
    },
    {
        "x": 44.61,
        "y": 28.27
    },
    {
        "x": 44.62,
        "y": 28.26
    },
    {
        "x": 44.63,
        "y": 28.27
    },
    {
        "x": 44.64,
        "y": 28.27
    },
    {
        "x": 44.65,
        "y": 28.27
    },
    {
        "x": 44.66,
        "y": 28.27
    },
    {
        "x": 44.67,
        "y": 28.27
    },
    {
        "x": 44.68,
        "y": 28.27
    },
    {
        "x": 44.69,
        "y": 28.27
    },
    {
        "x": 44.7,
        "y": 28.27
    },
    {
        "x": 44.71,
        "y": 28.27
    },
    {
        "x": 44.72,
        "y": 28.27
    },
    {
        "x": 44.73,
        "y": 28.27
    },
    {
        "x": 44.74,
        "y": 28.27
    },
    {
        "x": 44.75,
        "y": 28.27
    },
    {
        "x": 44.76,
        "y": 28.27
    },
    {
        "x": 44.77,
        "y": 28.27
    },
    {
        "x": 44.78,
        "y": 28.27
    },
    {
        "x": 44.79,
        "y": 28.27
    },
    {
        "x": 44.8,
        "y": 28.27
    },
    {
        "x": 44.81,
        "y": 28.27
    },
    {
        "x": 44.82,
        "y": 28.27
    },
    {
        "x": 44.83,
        "y": 28.27
    },
    {
        "x": 44.84,
        "y": 28.27
    },
    {
        "x": 44.85,
        "y": 28.27
    },
    {
        "x": 44.86,
        "y": 28.27
    },
    {
        "x": 44.87,
        "y": 28.27
    },
    {
        "x": 44.88,
        "y": 28.27
    },
    {
        "x": 44.89,
        "y": 28.27
    },
    {
        "x": 44.9,
        "y": 28.27
    },
    {
        "x": 44.91,
        "y": 28.27
    },
    {
        "x": 44.92,
        "y": 28.27
    },
    {
        "x": 44.93,
        "y": 28.27
    },
    {
        "x": 44.94,
        "y": 28.27
    },
    {
        "x": 44.95,
        "y": 28.28
    },
    {
        "x": 44.96,
        "y": 28.28
    },
    {
        "x": 44.97,
        "y": 28.28
    },
    {
        "x": 44.98,
        "y": 28.28
    },
    {
        "x": 44.99,
        "y": 28.27
    },
    {
        "x": 45,
        "y": 28.27
    },
    {
        "x": 45.01,
        "y": 28.28
    },
    {
        "x": 45.02,
        "y": 28.28
    },
    {
        "x": 45.03,
        "y": 28.28
    },
    {
        "x": 45.04,
        "y": 28.28
    },
    {
        "x": 45.05,
        "y": 28.28
    },
    {
        "x": 45.06,
        "y": 28.28
    },
    {
        "x": 45.07,
        "y": 28.28
    },
    {
        "x": 45.08,
        "y": 28.27
    },
    {
        "x": 45.09,
        "y": 28.28
    },
    {
        "x": 45.1,
        "y": 28.28
    },
    {
        "x": 45.11,
        "y": 28.27
    },
    {
        "x": 45.12,
        "y": 28.27
    },
    {
        "x": 45.13,
        "y": 28.28
    },
    {
        "x": 45.14,
        "y": 28.28
    },
    {
        "x": 45.15,
        "y": 28.27
    },
    {
        "x": 45.16,
        "y": 28.28
    },
    {
        "x": 45.17,
        "y": 28.28
    },
    {
        "x": 45.18,
        "y": 28.28
    },
    {
        "x": 45.19,
        "y": 28.28
    },
    {
        "x": 45.2,
        "y": 28.28
    },
    {
        "x": 45.21,
        "y": 28.28
    },
    {
        "x": 45.22,
        "y": 28.28
    },
    {
        "x": 45.23,
        "y": 28.28
    },
    {
        "x": 45.24,
        "y": 28.28
    },
    {
        "x": 45.25,
        "y": 28.28
    },
    {
        "x": 45.26,
        "y": 28.27
    },
    {
        "x": 45.27,
        "y": 28.28
    },
    {
        "x": 45.28,
        "y": 28.28
    },
    {
        "x": 45.29,
        "y": 28.28
    },
    {
        "x": 45.3,
        "y": 28.28
    },
    {
        "x": 45.31,
        "y": 28.28
    },
    {
        "x": 45.32,
        "y": 28.28
    },
    {
        "x": 45.33,
        "y": 28.28
    },
    {
        "x": 45.34,
        "y": 28.28
    },
    {
        "x": 45.35,
        "y": 28.28
    },
    {
        "x": 45.36,
        "y": 28.28
    },
    {
        "x": 45.37,
        "y": 28.28
    },
    {
        "x": 45.38,
        "y": 28.28
    },
    {
        "x": 45.39,
        "y": 28.28
    },
    {
        "x": 45.4,
        "y": 28.27
    },
    {
        "x": 45.41,
        "y": 28.28
    },
    {
        "x": 45.42,
        "y": 28.28
    },
    {
        "x": 45.43,
        "y": 28.28
    },
    {
        "x": 45.44,
        "y": 28.28
    },
    {
        "x": 45.45,
        "y": 28.28
    },
    {
        "x": 45.46,
        "y": 28.28
    },
    {
        "x": 45.47,
        "y": 28.28
    },
    {
        "x": 45.48,
        "y": 28.28
    },
    {
        "x": 45.49,
        "y": 28.28
    },
    {
        "x": 45.5,
        "y": 28.28
    },
    {
        "x": 45.51,
        "y": 28.28
    },
    {
        "x": 45.52,
        "y": 28.28
    },
    {
        "x": 45.53,
        "y": 28.28
    },
    {
        "x": 45.54,
        "y": 28.28
    },
    {
        "x": 45.55,
        "y": 28.28
    },
    {
        "x": 45.56,
        "y": 28.28
    },
    {
        "x": 45.57,
        "y": 28.28
    },
    {
        "x": 45.58,
        "y": 28.28
    },
    {
        "x": 45.59,
        "y": 28.28
    },
    {
        "x": 45.6,
        "y": 28.28
    },
    {
        "x": 45.61,
        "y": 28.28
    },
    {
        "x": 45.62,
        "y": 28.28
    },
    {
        "x": 45.63,
        "y": 28.28
    },
    {
        "x": 45.64,
        "y": 28.28
    },
    {
        "x": 45.65,
        "y": 28.28
    },
    {
        "x": 45.66,
        "y": 28.28
    },
    {
        "x": 45.67,
        "y": 28.28
    },
    {
        "x": 45.68,
        "y": 28.28
    },
    {
        "x": 45.69,
        "y": 28.28
    },
    {
        "x": 45.7,
        "y": 28.28
    },
    {
        "x": 45.71,
        "y": 28.28
    },
    {
        "x": 45.72,
        "y": 28.28
    },
    {
        "x": 45.73,
        "y": 28.28
    },
    {
        "x": 45.74,
        "y": 28.28
    },
    {
        "x": 45.75,
        "y": 28.28
    },
    {
        "x": 45.76,
        "y": 28.28
    },
    {
        "x": 45.77,
        "y": 28.28
    },
    {
        "x": 45.78,
        "y": 28.28
    },
    {
        "x": 45.79,
        "y": 28.28
    },
    {
        "x": 45.8,
        "y": 28.28
    },
    {
        "x": 45.81,
        "y": 28.28
    },
    {
        "x": 45.82,
        "y": 28.28
    },
    {
        "x": 45.83,
        "y": 28.28
    },
    {
        "x": 45.84,
        "y": 28.28
    },
    {
        "x": 45.85,
        "y": 28.28
    },
    {
        "x": 45.86,
        "y": 28.28
    },
    {
        "x": 45.87,
        "y": 28.28
    },
    {
        "x": 45.88,
        "y": 28.28
    },
    {
        "x": 45.89,
        "y": 28.28
    },
    {
        "x": 45.9,
        "y": 28.28
    },
    {
        "x": 45.91,
        "y": 28.28
    },
    {
        "x": 45.92,
        "y": 28.28
    },
    {
        "x": 45.93,
        "y": 28.28
    },
    {
        "x": 45.94,
        "y": 28.28
    },
    {
        "x": 45.95,
        "y": 28.28
    },
    {
        "x": 45.96,
        "y": 28.28
    },
    {
        "x": 45.97,
        "y": 28.28
    },
    {
        "x": 45.98,
        "y": 28.28
    },
    {
        "x": 45.99,
        "y": 28.28
    },
    {
        "x": 46,
        "y": 28.29
    },
    {
        "x": 46.01,
        "y": 28.28
    },
    {
        "x": 46.02,
        "y": 28.28
    },
    {
        "x": 46.03,
        "y": 28.28
    },
    {
        "x": 46.04,
        "y": 28.28
    },
    {
        "x": 46.05,
        "y": 28.28
    },
    {
        "x": 46.06,
        "y": 28.29
    },
    {
        "x": 46.07,
        "y": 28.28
    },
    {
        "x": 46.08,
        "y": 28.28
    },
    {
        "x": 46.09,
        "y": 28.28
    },
    {
        "x": 46.1,
        "y": 28.28
    },
    {
        "x": 46.11,
        "y": 28.28
    },
    {
        "x": 46.12,
        "y": 28.28
    },
    {
        "x": 46.13,
        "y": 28.29
    },
    {
        "x": 46.14,
        "y": 28.29
    },
    {
        "x": 46.15,
        "y": 28.28
    },
    {
        "x": 46.16,
        "y": 28.28
    },
    {
        "x": 46.17,
        "y": 28.29
    },
    {
        "x": 46.18,
        "y": 28.29
    },
    {
        "x": 46.19,
        "y": 28.29
    },
    {
        "x": 46.2,
        "y": 28.29
    },
    {
        "x": 46.21,
        "y": 28.29
    },
    {
        "x": 46.22,
        "y": 28.29
    },
    {
        "x": 46.23,
        "y": 28.29
    },
    {
        "x": 46.24,
        "y": 28.29
    },
    {
        "x": 46.25,
        "y": 28.29
    },
    {
        "x": 46.26,
        "y": 28.29
    },
    {
        "x": 46.27,
        "y": 28.29
    },
    {
        "x": 46.28,
        "y": 28.29
    },
    {
        "x": 46.29,
        "y": 28.29
    },
    {
        "x": 46.3,
        "y": 28.29
    },
    {
        "x": 46.31,
        "y": 28.29
    },
    {
        "x": 46.32,
        "y": 28.29
    },
    {
        "x": 46.33,
        "y": 28.29
    },
    {
        "x": 46.34,
        "y": 28.29
    },
    {
        "x": 46.35,
        "y": 28.29
    },
    {
        "x": 46.36,
        "y": 28.29
    },
    {
        "x": 46.37,
        "y": 28.29
    },
    {
        "x": 46.38,
        "y": 28.29
    },
    {
        "x": 46.39,
        "y": 28.29
    },
    {
        "x": 46.4,
        "y": 28.29
    },
    {
        "x": 46.41,
        "y": 28.29
    },
    {
        "x": 46.42,
        "y": 28.29
    },
    {
        "x": 46.43,
        "y": 28.29
    },
    {
        "x": 46.44,
        "y": 28.29
    },
    {
        "x": 46.45,
        "y": 28.29
    },
    {
        "x": 46.46,
        "y": 28.29
    },
    {
        "x": 46.47,
        "y": 28.29
    },
    {
        "x": 46.48,
        "y": 28.29
    },
    {
        "x": 46.49,
        "y": 28.29
    },
    {
        "x": 46.5,
        "y": 28.29
    },
    {
        "x": 46.51,
        "y": 28.29
    },
    {
        "x": 46.52,
        "y": 28.29
    },
    {
        "x": 46.53,
        "y": 28.29
    },
    {
        "x": 46.54,
        "y": 28.29
    },
    {
        "x": 46.55,
        "y": 28.29
    },
    {
        "x": 46.56,
        "y": 28.29
    },
    {
        "x": 46.57,
        "y": 28.29
    },
    {
        "x": 46.58,
        "y": 28.29
    },
    {
        "x": 46.59,
        "y": 28.29
    },
    {
        "x": 46.6,
        "y": 28.29
    },
    {
        "x": 46.61,
        "y": 28.29
    },
    {
        "x": 46.62,
        "y": 28.29
    },
    {
        "x": 46.63,
        "y": 28.29
    },
    {
        "x": 46.64,
        "y": 28.29
    },
    {
        "x": 46.65,
        "y": 28.29
    },
    {
        "x": 46.66,
        "y": 28.29
    },
    {
        "x": 46.67,
        "y": 28.29
    },
    {
        "x": 46.68,
        "y": 28.29
    },
    {
        "x": 46.69,
        "y": 28.29
    },
    {
        "x": 46.7,
        "y": 28.29
    },
    {
        "x": 46.71,
        "y": 28.29
    },
    {
        "x": 46.72,
        "y": 28.29
    },
    {
        "x": 46.73,
        "y": 28.29
    },
    {
        "x": 46.74,
        "y": 28.29
    },
    {
        "x": 46.75,
        "y": 28.29
    },
    {
        "x": 46.76,
        "y": 28.29
    },
    {
        "x": 46.77,
        "y": 28.29
    },
    {
        "x": 46.78,
        "y": 28.29
    },
    {
        "x": 46.79,
        "y": 28.29
    },
    {
        "x": 46.8,
        "y": 28.3
    },
    {
        "x": 46.81,
        "y": 28.29
    },
    {
        "x": 46.82,
        "y": 28.29
    },
    {
        "x": 46.83,
        "y": 28.29
    },
    {
        "x": 46.84,
        "y": 28.29
    },
    {
        "x": 46.85,
        "y": 28.29
    },
    {
        "x": 46.86,
        "y": 28.3
    },
    {
        "x": 46.87,
        "y": 28.29
    },
    {
        "x": 46.88,
        "y": 28.29
    },
    {
        "x": 46.89,
        "y": 28.29
    },
    {
        "x": 46.9,
        "y": 28.3
    },
    {
        "x": 46.91,
        "y": 28.29
    },
    {
        "x": 46.92,
        "y": 28.29
    },
    {
        "x": 46.93,
        "y": 28.29
    },
    {
        "x": 46.94,
        "y": 28.3
    },
    {
        "x": 46.95,
        "y": 28.29
    },
    {
        "x": 46.96,
        "y": 28.29
    },
    {
        "x": 46.97,
        "y": 28.29
    },
    {
        "x": 46.98,
        "y": 28.29
    },
    {
        "x": 46.99,
        "y": 28.29
    },
    {
        "x": 47,
        "y": 28.29
    },
    {
        "x": 47.01,
        "y": 28.3
    },
    {
        "x": 47.02,
        "y": 28.29
    },
    {
        "x": 47.03,
        "y": 28.29
    },
    {
        "x": 47.04,
        "y": 28.3
    },
    {
        "x": 47.05,
        "y": 28.3
    },
    {
        "x": 47.06,
        "y": 28.3
    },
    {
        "x": 47.07,
        "y": 28.29
    },
    {
        "x": 47.08,
        "y": 28.29
    },
    {
        "x": 47.09,
        "y": 28.29
    },
    {
        "x": 47.1,
        "y": 28.3
    },
    {
        "x": 47.11,
        "y": 28.3
    },
    {
        "x": 47.12,
        "y": 28.3
    },
    {
        "x": 47.13,
        "y": 28.3
    },
    {
        "x": 47.14,
        "y": 28.3
    },
    {
        "x": 47.15,
        "y": 28.29
    },
    {
        "x": 47.16,
        "y": 28.29
    },
    {
        "x": 47.17,
        "y": 28.3
    },
    {
        "x": 47.18,
        "y": 28.3
    },
    {
        "x": 47.19,
        "y": 28.3
    },
    {
        "x": 47.2,
        "y": 28.3
    },
    {
        "x": 47.21,
        "y": 28.3
    },
    {
        "x": 47.22,
        "y": 28.3
    },
    {
        "x": 47.23,
        "y": 28.3
    },
    {
        "x": 47.24,
        "y": 28.3
    },
    {
        "x": 47.25,
        "y": 28.3
    },
    {
        "x": 47.26,
        "y": 28.3
    },
    {
        "x": 47.27,
        "y": 28.3
    },
    {
        "x": 47.28,
        "y": 28.3
    },
    {
        "x": 47.29,
        "y": 28.3
    },
    {
        "x": 47.3,
        "y": 28.3
    },
    {
        "x": 47.31,
        "y": 28.3
    },
    {
        "x": 47.32,
        "y": 28.3
    },
    {
        "x": 47.33,
        "y": 28.3
    },
    {
        "x": 47.34,
        "y": 28.3
    },
    {
        "x": 47.35,
        "y": 28.3
    },
    {
        "x": 47.36,
        "y": 28.3
    },
    {
        "x": 47.37,
        "y": 28.3
    },
    {
        "x": 47.38,
        "y": 28.3
    },
    {
        "x": 47.39,
        "y": 28.3
    },
    {
        "x": 47.4,
        "y": 28.3
    },
    {
        "x": 47.41,
        "y": 28.3
    },
    {
        "x": 47.42,
        "y": 28.3
    },
    {
        "x": 47.43,
        "y": 28.3
    },
    {
        "x": 47.44,
        "y": 28.3
    },
    {
        "x": 47.45,
        "y": 28.3
    },
    {
        "x": 47.46,
        "y": 28.3
    },
    {
        "x": 47.47,
        "y": 28.3
    },
    {
        "x": 47.48,
        "y": 28.3
    },
    {
        "x": 47.49,
        "y": 28.3
    },
    {
        "x": 47.5,
        "y": 28.3
    },
    {
        "x": 47.51,
        "y": 28.3
    },
    {
        "x": 47.52,
        "y": 28.3
    },
    {
        "x": 47.53,
        "y": 28.3
    },
    {
        "x": 47.54,
        "y": 28.3
    },
    {
        "x": 47.55,
        "y": 28.3
    },
    {
        "x": 47.56,
        "y": 28.3
    },
    {
        "x": 47.57,
        "y": 28.3
    },
    {
        "x": 47.58,
        "y": 28.3
    },
    {
        "x": 47.59,
        "y": 28.3
    },
    {
        "x": 47.6,
        "y": 28.3
    },
    {
        "x": 47.61,
        "y": 28.3
    },
    {
        "x": 47.62,
        "y": 28.3
    },
    {
        "x": 47.63,
        "y": 28.3
    },
    {
        "x": 47.64,
        "y": 28.3
    },
    {
        "x": 47.65,
        "y": 28.3
    },
    {
        "x": 47.66,
        "y": 28.3
    },
    {
        "x": 47.67,
        "y": 28.3
    },
    {
        "x": 47.68,
        "y": 28.3
    },
    {
        "x": 47.69,
        "y": 28.3
    },
    {
        "x": 47.7,
        "y": 28.3
    },
    {
        "x": 47.71,
        "y": 28.3
    },
    {
        "x": 47.72,
        "y": 28.3
    },
    {
        "x": 47.73,
        "y": 28.3
    },
    {
        "x": 47.74,
        "y": 28.3
    },
    {
        "x": 47.75,
        "y": 28.3
    },
    {
        "x": 47.76,
        "y": 28.3
    },
    {
        "x": 47.77,
        "y": 28.3
    },
    {
        "x": 47.78,
        "y": 28.3
    },
    {
        "x": 47.79,
        "y": 28.31
    },
    {
        "x": 47.8,
        "y": 28.3
    },
    {
        "x": 47.81,
        "y": 28.3
    },
    {
        "x": 47.82,
        "y": 28.31
    },
    {
        "x": 47.83,
        "y": 28.3
    },
    {
        "x": 47.84,
        "y": 28.31
    },
    {
        "x": 47.85,
        "y": 28.31
    },
    {
        "x": 47.86,
        "y": 28.3
    },
    {
        "x": 47.87,
        "y": 28.3
    },
    {
        "x": 47.88,
        "y": 28.3
    },
    {
        "x": 47.89,
        "y": 28.3
    },
    {
        "x": 47.9,
        "y": 28.3
    },
    {
        "x": 47.91,
        "y": 28.3
    },
    {
        "x": 47.92,
        "y": 28.3
    },
    {
        "x": 47.93,
        "y": 28.3
    },
    {
        "x": 47.94,
        "y": 28.3
    },
    {
        "x": 47.95,
        "y": 28.3
    },
    {
        "x": 47.96,
        "y": 28.3
    },
    {
        "x": 47.97,
        "y": 28.3
    },
    {
        "x": 47.98,
        "y": 28.3
    },
    {
        "x": 47.99,
        "y": 28.31
    },
    {
        "x": 48,
        "y": 28.31
    },
    {
        "x": 48.01,
        "y": 28.3
    },
    {
        "x": 48.02,
        "y": 28.31
    },
    {
        "x": 48.03,
        "y": 28.3
    },
    {
        "x": 48.04,
        "y": 28.3
    },
    {
        "x": 48.05,
        "y": 28.3
    },
    {
        "x": 48.06,
        "y": 28.31
    },
    {
        "x": 48.07,
        "y": 28.3
    },
    {
        "x": 48.08,
        "y": 28.3
    },
    {
        "x": 48.09,
        "y": 28.3
    },
    {
        "x": 48.1,
        "y": 28.3
    },
    {
        "x": 48.11,
        "y": 28.3
    },
    {
        "x": 48.12,
        "y": 28.3
    },
    {
        "x": 48.13,
        "y": 28.31
    },
    {
        "x": 48.14,
        "y": 28.3
    },
    {
        "x": 48.15,
        "y": 28.3
    },
    {
        "x": 48.16,
        "y": 28.31
    },
    {
        "x": 48.17,
        "y": 28.31
    },
    {
        "x": 48.18,
        "y": 28.3
    },
    {
        "x": 48.19,
        "y": 28.3
    },
    {
        "x": 48.2,
        "y": 28.31
    },
    {
        "x": 48.21,
        "y": 28.3
    },
    {
        "x": 48.22,
        "y": 28.3
    },
    {
        "x": 48.23,
        "y": 28.3
    },
    {
        "x": 48.24,
        "y": 28.31
    },
    {
        "x": 48.25,
        "y": 28.31
    },
    {
        "x": 48.26,
        "y": 28.3
    },
    {
        "x": 48.27,
        "y": 28.31
    },
    {
        "x": 48.28,
        "y": 28.3
    },
    {
        "x": 48.29,
        "y": 28.31
    },
    {
        "x": 48.3,
        "y": 28.31
    },
    {
        "x": 48.31,
        "y": 28.31
    },
    {
        "x": 48.32,
        "y": 28.31
    },
    {
        "x": 48.33,
        "y": 28.31
    },
    {
        "x": 48.34,
        "y": 28.31
    },
    {
        "x": 48.35,
        "y": 28.31
    },
    {
        "x": 48.36,
        "y": 28.31
    },
    {
        "x": 48.37,
        "y": 28.31
    },
    {
        "x": 48.38,
        "y": 28.31
    },
    {
        "x": 48.39,
        "y": 28.31
    },
    {
        "x": 48.4,
        "y": 28.31
    },
    {
        "x": 48.41,
        "y": 28.31
    },
    {
        "x": 48.42,
        "y": 28.31
    },
    {
        "x": 48.43,
        "y": 28.31
    },
    {
        "x": 48.44,
        "y": 28.31
    },
    {
        "x": 48.45,
        "y": 28.31
    },
    {
        "x": 48.46,
        "y": 28.31
    },
    {
        "x": 48.47,
        "y": 28.31
    },
    {
        "x": 48.48,
        "y": 28.31
    },
    {
        "x": 48.49,
        "y": 28.31
    },
    {
        "x": 48.5,
        "y": 28.31
    },
    {
        "x": 48.51,
        "y": 28.31
    },
    {
        "x": 48.52,
        "y": 28.31
    },
    {
        "x": 48.53,
        "y": 28.31
    },
    {
        "x": 48.54,
        "y": 28.31
    },
    {
        "x": 48.55,
        "y": 28.31
    },
    {
        "x": 48.56,
        "y": 28.31
    },
    {
        "x": 48.57,
        "y": 28.31
    },
    {
        "x": 48.58,
        "y": 28.31
    },
    {
        "x": 48.59,
        "y": 28.31
    },
    {
        "x": 48.6,
        "y": 28.31
    },
    {
        "x": 48.61,
        "y": 28.31
    },
    {
        "x": 48.62,
        "y": 28.31
    },
    {
        "x": 48.63,
        "y": 28.31
    },
    {
        "x": 48.64,
        "y": 28.31
    },
    {
        "x": 48.65,
        "y": 28.31
    },
    {
        "x": 48.66,
        "y": 28.31
    },
    {
        "x": 48.67,
        "y": 28.31
    },
    {
        "x": 48.68,
        "y": 28.31
    },
    {
        "x": 48.69,
        "y": 28.31
    },
    {
        "x": 48.7,
        "y": 28.31
    },
    {
        "x": 48.71,
        "y": 28.31
    },
    {
        "x": 48.72,
        "y": 28.31
    },
    {
        "x": 48.73,
        "y": 28.31
    },
    {
        "x": 48.74,
        "y": 28.31
    },
    {
        "x": 48.75,
        "y": 28.31
    },
    {
        "x": 48.76,
        "y": 28.31
    },
    {
        "x": 48.77,
        "y": 28.31
    },
    {
        "x": 48.78,
        "y": 28.31
    },
    {
        "x": 48.79,
        "y": 28.31
    },
    {
        "x": 48.8,
        "y": 28.31
    },
    {
        "x": 48.81,
        "y": 28.31
    },
    {
        "x": 48.82,
        "y": 28.31
    },
    {
        "x": 48.83,
        "y": 28.31
    },
    {
        "x": 48.84,
        "y": 28.31
    },
    {
        "x": 48.85,
        "y": 28.31
    },
    {
        "x": 48.86,
        "y": 28.31
    },
    {
        "x": 48.87,
        "y": 28.31
    },
    {
        "x": 48.88,
        "y": 28.31
    },
    {
        "x": 48.89,
        "y": 28.31
    },
    {
        "x": 48.9,
        "y": 28.31
    },
    {
        "x": 48.91,
        "y": 28.31
    },
    {
        "x": 48.92,
        "y": 28.31
    },
    {
        "x": 48.93,
        "y": 28.31
    },
    {
        "x": 48.94,
        "y": 28.31
    },
    {
        "x": 48.95,
        "y": 28.31
    },
    {
        "x": 48.96,
        "y": 28.31
    },
    {
        "x": 48.97,
        "y": 28.31
    },
    {
        "x": 48.98,
        "y": 28.31
    },
    {
        "x": 48.99,
        "y": 28.31
    },
    {
        "x": 49,
        "y": 28.31
    },
    {
        "x": 49.01,
        "y": 28.31
    },
    {
        "x": 49.02,
        "y": 28.31
    },
    {
        "x": 49.03,
        "y": 28.31
    },
    {
        "x": 49.04,
        "y": 28.31
    },
    {
        "x": 49.05,
        "y": 28.31
    },
    {
        "x": 49.06,
        "y": 28.31
    },
    {
        "x": 49.07,
        "y": 28.31
    },
    {
        "x": 49.08,
        "y": 28.31
    },
    {
        "x": 49.09,
        "y": 28.31
    },
    {
        "x": 49.1,
        "y": 28.31
    },
    {
        "x": 49.11,
        "y": 28.31
    },
    {
        "x": 49.12,
        "y": 28.31
    },
    {
        "x": 49.13,
        "y": 28.31
    },
    {
        "x": 49.14,
        "y": 28.31
    },
    {
        "x": 49.15,
        "y": 28.31
    },
    {
        "x": 49.16,
        "y": 28.31
    },
    {
        "x": 49.17,
        "y": 28.31
    },
    {
        "x": 49.18,
        "y": 28.31
    },
    {
        "x": 49.19,
        "y": 28.31
    },
    {
        "x": 49.2,
        "y": 28.31
    },
    {
        "x": 49.21,
        "y": 28.31
    },
    {
        "x": 49.22,
        "y": 28.31
    },
    {
        "x": 49.23,
        "y": 28.31
    },
    {
        "x": 49.24,
        "y": 28.33
    },
    {
        "x": 49.25,
        "y": 28.31
    },
    {
        "x": 49.26,
        "y": 28.31
    },
    {
        "x": 49.27,
        "y": 28.31
    },
    {
        "x": 49.28,
        "y": 28.31
    },
    {
        "x": 49.29,
        "y": 28.31
    },
    {
        "x": 49.3,
        "y": 28.31
    },
    {
        "x": 49.31,
        "y": 28.31
    },
    {
        "x": 49.32,
        "y": 28.31
    },
    {
        "x": 49.33,
        "y": 28.31
    },
    {
        "x": 49.34,
        "y": 28.31
    },
    {
        "x": 49.35,
        "y": 28.31
    },
    {
        "x": 49.36,
        "y": 28.31
    },
    {
        "x": 49.37,
        "y": 28.31
    },
    {
        "x": 49.38,
        "y": 28.31
    },
    {
        "x": 49.39,
        "y": 28.31
    },
    {
        "x": 49.4,
        "y": 28.31
    },
    {
        "x": 49.41,
        "y": 28.31
    },
    {
        "x": 49.42,
        "y": 28.33
    },
    {
        "x": 49.43,
        "y": 28.33
    },
    {
        "x": 49.44,
        "y": 28.33
    },
    {
        "x": 49.45,
        "y": 28.33
    },
    {
        "x": 49.46,
        "y": 28.33
    },
    {
        "x": 49.47,
        "y": 28.31
    },
    {
        "x": 49.48,
        "y": 28.31
    },
    {
        "x": 49.49,
        "y": 28.31
    },
    {
        "x": 49.5,
        "y": 28.31
    },
    {
        "x": 49.51,
        "y": 28.33
    },
    {
        "x": 49.52,
        "y": 28.33
    },
    {
        "x": 49.53,
        "y": 28.33
    },
    {
        "x": 49.54,
        "y": 28.33
    },
    {
        "x": 49.55,
        "y": 28.33
    },
    {
        "x": 49.56,
        "y": 28.31
    },
    {
        "x": 49.57,
        "y": 28.31
    },
    {
        "x": 49.58,
        "y": 28.33
    },
    {
        "x": 49.59,
        "y": 28.33
    },
    {
        "x": 49.6,
        "y": 28.33
    },
    {
        "x": 49.61,
        "y": 28.33
    },
    {
        "x": 49.62,
        "y": 28.33
    },
    {
        "x": 49.63,
        "y": 28.33
    },
    {
        "x": 49.64,
        "y": 28.31
    },
    {
        "x": 49.65,
        "y": 28.33
    },
    {
        "x": 49.66,
        "y": 28.33
    },
    {
        "x": 49.67,
        "y": 28.33
    },
    {
        "x": 49.68,
        "y": 28.33
    },
    {
        "x": 49.69,
        "y": 28.33
    },
    {
        "x": 49.7,
        "y": 28.33
    },
    {
        "x": 49.71,
        "y": 28.33
    },
    {
        "x": 49.72,
        "y": 28.33
    },
    {
        "x": 49.73,
        "y": 28.33
    },
    {
        "x": 49.74,
        "y": 28.33
    },
    {
        "x": 49.75,
        "y": 28.33
    },
    {
        "x": 49.76,
        "y": 28.33
    },
    {
        "x": 49.77,
        "y": 28.33
    },
    {
        "x": 49.78,
        "y": 28.33
    },
    {
        "x": 49.79,
        "y": 28.33
    },
    {
        "x": 49.8,
        "y": 28.33
    },
    {
        "x": 49.81,
        "y": 28.33
    },
    {
        "x": 49.82,
        "y": 28.33
    },
    {
        "x": 49.83,
        "y": 28.33
    },
    {
        "x": 49.84,
        "y": 28.33
    },
    {
        "x": 49.85,
        "y": 28.33
    },
    {
        "x": 49.86,
        "y": 28.33
    },
    {
        "x": 49.87,
        "y": 28.33
    },
    {
        "x": 49.88,
        "y": 28.33
    },
    {
        "x": 49.89,
        "y": 28.33
    },
    {
        "x": 49.9,
        "y": 28.33
    },
    {
        "x": 49.91,
        "y": 28.33
    },
    {
        "x": 49.92,
        "y": 28.33
    },
    {
        "x": 49.93,
        "y": 28.33
    },
    {
        "x": 49.94,
        "y": 28.33
    },
    {
        "x": 49.95,
        "y": 28.33
    },
    {
        "x": 49.96,
        "y": 28.33
    },
    {
        "x": 49.97,
        "y": 28.33
    },
    {
        "x": 49.98,
        "y": 28.33
    },
    {
        "x": 49.99,
        "y": 28.33
    },
    {
        "x": 50,
        "y": 28.33
    },
    {
        "x": 50.01,
        "y": 28.33
    },
    {
        "x": 50.02,
        "y": 28.33
    },
    {
        "x": 50.03,
        "y": 28.33
    },
    {
        "x": 50.04,
        "y": 28.33
    },
    {
        "x": 50.05,
        "y": 28.33
    },
    {
        "x": 50.06,
        "y": 28.33
    },
    {
        "x": 50.07,
        "y": 28.33
    },
    {
        "x": 50.08,
        "y": 28.33
    },
    {
        "x": 50.09,
        "y": 28.33
    },
    {
        "x": 50.1,
        "y": 28.33
    },
    {
        "x": 50.11,
        "y": 28.33
    },
    {
        "x": 50.12,
        "y": 28.33
    },
    {
        "x": 50.13,
        "y": 28.33
    },
    {
        "x": 50.14,
        "y": 28.33
    },
    {
        "x": 50.15,
        "y": 28.33
    },
    {
        "x": 50.16,
        "y": 28.33
    },
    {
        "x": 50.17,
        "y": 28.33
    },
    {
        "x": 50.18,
        "y": 28.33
    },
    {
        "x": 50.19,
        "y": 28.33
    },
    {
        "x": 50.2,
        "y": 28.33
    },
    {
        "x": 50.21,
        "y": 28.33
    },
    {
        "x": 50.22,
        "y": 28.33
    },
    {
        "x": 50.23,
        "y": 28.33
    },
    {
        "x": 50.24,
        "y": 28.33
    },
    {
        "x": 50.25,
        "y": 28.33
    },
    {
        "x": 50.26,
        "y": 28.33
    },
    {
        "x": 50.27,
        "y": 28.33
    },
    {
        "x": 50.28,
        "y": 28.33
    },
    {
        "x": 50.29,
        "y": 28.33
    },
    {
        "x": 50.3,
        "y": 28.33
    },
    {
        "x": 50.31,
        "y": 28.33
    },
    {
        "x": 50.32,
        "y": 28.33
    },
    {
        "x": 50.33,
        "y": 28.33
    },
    {
        "x": 50.34,
        "y": 28.33
    },
    {
        "x": 50.35,
        "y": 28.33
    },
    {
        "x": 50.36,
        "y": 28.33
    },
    {
        "x": 50.37,
        "y": 28.33
    },
    {
        "x": 50.38,
        "y": 28.33
    },
    {
        "x": 50.39,
        "y": 28.33
    },
    {
        "x": 50.4,
        "y": 28.33
    },
    {
        "x": 50.41,
        "y": 28.33
    },
    {
        "x": 50.42,
        "y": 28.33
    },
    {
        "x": 50.43,
        "y": 28.33
    },
    {
        "x": 50.44,
        "y": 28.33
    },
    {
        "x": 50.45,
        "y": 28.33
    },
    {
        "x": 50.46,
        "y": 28.33
    },
    {
        "x": 50.47,
        "y": 28.33
    },
    {
        "x": 50.48,
        "y": 28.33
    },
    {
        "x": 50.49,
        "y": 28.33
    },
    {
        "x": 50.5,
        "y": 28.33
    },
    {
        "x": 50.51,
        "y": 28.33
    },
    {
        "x": 50.52,
        "y": 28.33
    },
    {
        "x": 50.53,
        "y": 28.33
    },
    {
        "x": 50.54,
        "y": 28.33
    },
    {
        "x": 50.55,
        "y": 28.33
    },
    {
        "x": 50.56,
        "y": 28.33
    },
    {
        "x": 50.57,
        "y": 28.33
    },
    {
        "x": 50.58,
        "y": 28.33
    },
    {
        "x": 50.59,
        "y": 28.33
    },
    {
        "x": 50.6,
        "y": 28.33
    },
    {
        "x": 50.61,
        "y": 28.33
    },
    {
        "x": 50.62,
        "y": 28.33
    },
    {
        "x": 50.63,
        "y": 28.33
    },
    {
        "x": 50.64,
        "y": 28.33
    },
    {
        "x": 50.65,
        "y": 28.33
    },
    {
        "x": 50.66,
        "y": 28.33
    },
    {
        "x": 50.67,
        "y": 28.33
    },
    {
        "x": 50.68,
        "y": 28.33
    },
    {
        "x": 50.69,
        "y": 28.33
    },
    {
        "x": 50.7,
        "y": 28.33
    },
    {
        "x": 50.71,
        "y": 28.33
    },
    {
        "x": 50.72,
        "y": 28.33
    },
    {
        "x": 50.73,
        "y": 28.34
    },
    {
        "x": 50.74,
        "y": 28.33
    },
    {
        "x": 50.75,
        "y": 28.33
    },
    {
        "x": 50.76,
        "y": 28.33
    },
    {
        "x": 50.77,
        "y": 28.33
    },
    {
        "x": 50.78,
        "y": 28.33
    },
    {
        "x": 50.79,
        "y": 28.34
    },
    {
        "x": 50.8,
        "y": 28.33
    },
    {
        "x": 50.81,
        "y": 28.34
    },
    {
        "x": 50.82,
        "y": 28.33
    },
    {
        "x": 50.83,
        "y": 28.33
    },
    {
        "x": 50.84,
        "y": 28.33
    },
    {
        "x": 50.85,
        "y": 28.34
    },
    {
        "x": 50.86,
        "y": 28.34
    },
    {
        "x": 50.87,
        "y": 28.34
    },
    {
        "x": 50.88,
        "y": 28.33
    },
    {
        "x": 50.89,
        "y": 28.34
    },
    {
        "x": 50.9,
        "y": 28.34
    },
    {
        "x": 50.91,
        "y": 28.34
    },
    {
        "x": 50.92,
        "y": 28.34
    },
    {
        "x": 50.93,
        "y": 28.34
    },
    {
        "x": 50.94,
        "y": 28.34
    },
    {
        "x": 50.95,
        "y": 28.34
    },
    {
        "x": 50.96,
        "y": 28.34
    },
    {
        "x": 50.97,
        "y": 28.34
    },
    {
        "x": 50.98,
        "y": 28.34
    },
    {
        "x": 50.99,
        "y": 28.34
    },
    {
        "x": 51,
        "y": 28.33
    },
    {
        "x": 51.01,
        "y": 28.33
    },
    {
        "x": 51.02,
        "y": 28.34
    },
    {
        "x": 51.03,
        "y": 28.33
    },
    {
        "x": 51.04,
        "y": 28.34
    },
    {
        "x": 51.05,
        "y": 28.34
    },
    {
        "x": 51.06,
        "y": 28.34
    },
    {
        "x": 51.07,
        "y": 28.33
    },
    {
        "x": 51.08,
        "y": 28.34
    },
    {
        "x": 51.09,
        "y": 28.34
    },
    {
        "x": 51.1,
        "y": 28.33
    },
    {
        "x": 51.11,
        "y": 28.33
    },
    {
        "x": 51.12,
        "y": 28.33
    },
    {
        "x": 51.13,
        "y": 28.33
    },
    {
        "x": 51.14,
        "y": 28.33
    },
    {
        "x": 51.15,
        "y": 28.33
    },
    {
        "x": 51.16,
        "y": 28.34
    },
    {
        "x": 51.17,
        "y": 28.34
    },
    {
        "x": 51.18,
        "y": 28.34
    },
    {
        "x": 51.19,
        "y": 28.33
    },
    {
        "x": 51.2,
        "y": 28.34
    },
    {
        "x": 51.21,
        "y": 28.33
    },
    {
        "x": 51.22,
        "y": 28.33
    },
    {
        "x": 51.23,
        "y": 28.34
    },
    {
        "x": 51.24,
        "y": 28.34
    },
    {
        "x": 51.25,
        "y": 28.33
    },
    {
        "x": 51.26,
        "y": 28.34
    },
    {
        "x": 51.27,
        "y": 28.34
    },
    {
        "x": 51.28,
        "y": 28.33
    },
    {
        "x": 51.29,
        "y": 28.33
    },
    {
        "x": 51.3,
        "y": 28.33
    },
    {
        "x": 51.31,
        "y": 28.33
    },
    {
        "x": 51.32,
        "y": 28.33
    },
    {
        "x": 51.33,
        "y": 28.33
    },
    {
        "x": 51.34,
        "y": 28.33
    },
    {
        "x": 51.35,
        "y": 28.33
    },
    {
        "x": 51.36,
        "y": 28.33
    },
    {
        "x": 51.37,
        "y": 28.33
    },
    {
        "x": 51.38,
        "y": 28.33
    },
    {
        "x": 51.39,
        "y": 28.33
    },
    {
        "x": 51.4,
        "y": 28.34
    },
    {
        "x": 51.41,
        "y": 28.34
    },
    {
        "x": 51.42,
        "y": 28.33
    },
    {
        "x": 51.43,
        "y": 28.33
    },
    {
        "x": 51.44,
        "y": 28.34
    },
    {
        "x": 51.45,
        "y": 28.33
    },
    {
        "x": 51.46,
        "y": 28.33
    },
    {
        "x": 51.47,
        "y": 28.33
    },
    {
        "x": 51.48,
        "y": 28.33
    },
    {
        "x": 51.49,
        "y": 28.33
    },
    {
        "x": 51.5,
        "y": 28.33
    },
    {
        "x": 51.51,
        "y": 28.33
    },
    {
        "x": 51.52,
        "y": 28.34
    },
    {
        "x": 51.53,
        "y": 28.34
    },
    {
        "x": 51.54,
        "y": 28.34
    },
    {
        "x": 51.55,
        "y": 28.33
    },
    {
        "x": 51.56,
        "y": 28.33
    },
    {
        "x": 51.57,
        "y": 28.33
    },
    {
        "x": 51.58,
        "y": 28.33
    },
    {
        "x": 51.59,
        "y": 28.33
    },
    {
        "x": 51.6,
        "y": 28.33
    },
    {
        "x": 51.61,
        "y": 28.34
    },
    {
        "x": 51.62,
        "y": 28.34
    },
    {
        "x": 51.63,
        "y": 28.33
    },
    {
        "x": 51.64,
        "y": 28.33
    },
    {
        "x": 51.65,
        "y": 28.33
    },
    {
        "x": 51.66,
        "y": 28.34
    },
    {
        "x": 51.67,
        "y": 28.33
    },
    {
        "x": 51.68,
        "y": 28.34
    },
    {
        "x": 51.69,
        "y": 28.34
    },
    {
        "x": 51.7,
        "y": 28.34
    },
    {
        "x": 51.71,
        "y": 28.34
    },
    {
        "x": 51.72,
        "y": 28.34
    },
    {
        "x": 51.73,
        "y": 28.33
    },
    {
        "x": 51.74,
        "y": 28.34
    },
    {
        "x": 51.75,
        "y": 28.34
    },
    {
        "x": 51.76,
        "y": 28.34
    },
    {
        "x": 51.77,
        "y": 28.34
    },
    {
        "x": 51.78,
        "y": 28.34
    },
    {
        "x": 51.79,
        "y": 28.34
    },
    {
        "x": 51.8,
        "y": 28.34
    },
    {
        "x": 51.81,
        "y": 28.34
    },
    {
        "x": 51.82,
        "y": 28.34
    },
    {
        "x": 51.83,
        "y": 28.34
    },
    {
        "x": 51.84,
        "y": 28.34
    },
    {
        "x": 51.85,
        "y": 28.34
    },
    {
        "x": 51.86,
        "y": 28.34
    },
    {
        "x": 51.87,
        "y": 28.33
    },
    {
        "x": 51.88,
        "y": 28.33
    },
    {
        "x": 51.89,
        "y": 28.34
    },
    {
        "x": 51.9,
        "y": 28.34
    },
    {
        "x": 51.91,
        "y": 28.34
    },
    {
        "x": 51.92,
        "y": 28.34
    },
    {
        "x": 51.93,
        "y": 28.34
    },
    {
        "x": 51.94,
        "y": 28.34
    },
    {
        "x": 51.95,
        "y": 28.34
    },
    {
        "x": 51.96,
        "y": 28.33
    },
    {
        "x": 51.97,
        "y": 28.34
    },
    {
        "x": 51.98,
        "y": 28.34
    },
    {
        "x": 51.99,
        "y": 28.34
    },
    {
        "x": 52,
        "y": 28.34
    },
    {
        "x": 52.01,
        "y": 28.34
    },
    {
        "x": 52.02,
        "y": 28.34
    },
    {
        "x": 52.03,
        "y": 28.34
    },
    {
        "x": 52.04,
        "y": 28.34
    },
    {
        "x": 52.05,
        "y": 28.34
    },
    {
        "x": 52.06,
        "y": 28.34
    },
    {
        "x": 52.07,
        "y": 28.34
    },
    {
        "x": 52.08,
        "y": 28.34
    },
    {
        "x": 52.09,
        "y": 28.34
    },
    {
        "x": 52.1,
        "y": 28.34
    },
    {
        "x": 52.11,
        "y": 28.34
    },
    {
        "x": 52.12,
        "y": 28.34
    },
    {
        "x": 52.13,
        "y": 28.34
    },
    {
        "x": 52.14,
        "y": 28.34
    },
    {
        "x": 52.15,
        "y": 28.34
    },
    {
        "x": 52.16,
        "y": 28.34
    },
    {
        "x": 52.17,
        "y": 28.34
    },
    {
        "x": 52.18,
        "y": 28.34
    },
    {
        "x": 52.19,
        "y": 28.34
    },
    {
        "x": 52.2,
        "y": 28.34
    },
    {
        "x": 52.21,
        "y": 28.34
    },
    {
        "x": 52.22,
        "y": 28.34
    },
    {
        "x": 52.23,
        "y": 28.34
    },
    {
        "x": 52.24,
        "y": 28.34
    },
    {
        "x": 52.25,
        "y": 28.34
    },
    {
        "x": 52.26,
        "y": 28.34
    },
    {
        "x": 52.27,
        "y": 28.34
    },
    {
        "x": 52.28,
        "y": 28.34
    },
    {
        "x": 52.29,
        "y": 28.34
    },
    {
        "x": 52.3,
        "y": 28.34
    },
    {
        "x": 52.31,
        "y": 28.34
    },
    {
        "x": 52.32,
        "y": 28.34
    },
    {
        "x": 52.33,
        "y": 28.34
    },
    {
        "x": 52.34,
        "y": 28.34
    },
    {
        "x": 52.35,
        "y": 28.34
    },
    {
        "x": 52.36,
        "y": 28.34
    },
    {
        "x": 52.37,
        "y": 28.34
    },
    {
        "x": 52.38,
        "y": 28.34
    },
    {
        "x": 52.39,
        "y": 28.34
    },
    {
        "x": 52.4,
        "y": 28.34
    },
    {
        "x": 52.41,
        "y": 28.34
    },
    {
        "x": 52.42,
        "y": 28.34
    },
    {
        "x": 52.43,
        "y": 28.34
    },
    {
        "x": 52.44,
        "y": 28.34
    },
    {
        "x": 52.45,
        "y": 28.34
    },
    {
        "x": 52.46,
        "y": 28.34
    },
    {
        "x": 52.47,
        "y": 28.34
    },
    {
        "x": 52.48,
        "y": 28.34
    },
    {
        "x": 52.49,
        "y": 28.34
    },
    {
        "x": 52.5,
        "y": 28.34
    },
    {
        "x": 52.51,
        "y": 28.34
    },
    {
        "x": 52.52,
        "y": 28.34
    },
    {
        "x": 52.53,
        "y": 28.34
    },
    {
        "x": 52.54,
        "y": 28.34
    },
    {
        "x": 52.55,
        "y": 28.34
    },
    {
        "x": 52.56,
        "y": 28.34
    },
    {
        "x": 52.57,
        "y": 28.34
    },
    {
        "x": 52.58,
        "y": 28.34
    },
    {
        "x": 52.59,
        "y": 28.34
    },
    {
        "x": 52.6,
        "y": 28.34
    },
    {
        "x": 52.61,
        "y": 28.34
    },
    {
        "x": 52.62,
        "y": 28.34
    },
    {
        "x": 52.63,
        "y": 28.34
    },
    {
        "x": 52.64,
        "y": 28.34
    },
    {
        "x": 52.65,
        "y": 28.34
    },
    {
        "x": 52.66,
        "y": 28.34
    },
    {
        "x": 52.67,
        "y": 28.34
    },
    {
        "x": 52.68,
        "y": 28.34
    },
    {
        "x": 52.69,
        "y": 28.34
    },
    {
        "x": 52.7,
        "y": 28.34
    },
    {
        "x": 52.71,
        "y": 28.34
    },
    {
        "x": 52.72,
        "y": 28.34
    },
    {
        "x": 52.73,
        "y": 28.34
    },
    {
        "x": 52.74,
        "y": 28.34
    },
    {
        "x": 52.75,
        "y": 28.34
    },
    {
        "x": 52.76,
        "y": 28.34
    },
    {
        "x": 52.77,
        "y": 28.34
    },
    {
        "x": 52.78,
        "y": 28.34
    },
    {
        "x": 52.79,
        "y": 28.34
    },
    {
        "x": 52.8,
        "y": 28.34
    },
    {
        "x": 52.81,
        "y": 28.34
    },
    {
        "x": 52.82,
        "y": 28.34
    },
    {
        "x": 52.83,
        "y": 28.34
    },
    {
        "x": 52.84,
        "y": 28.34
    },
    {
        "x": 52.85,
        "y": 28.34
    },
    {
        "x": 52.86,
        "y": 28.34
    },
    {
        "x": 52.87,
        "y": 28.34
    },
    {
        "x": 52.88,
        "y": 28.34
    },
    {
        "x": 52.89,
        "y": 28.34
    },
    {
        "x": 52.9,
        "y": 28.34
    },
    {
        "x": 52.91,
        "y": 28.34
    },
    {
        "x": 52.92,
        "y": 28.34
    },
    {
        "x": 52.93,
        "y": 28.34
    },
    {
        "x": 52.94,
        "y": 28.34
    },
    {
        "x": 52.95,
        "y": 28.34
    },
    {
        "x": 52.96,
        "y": 28.34
    },
    {
        "x": 52.97,
        "y": 28.34
    },
    {
        "x": 52.98,
        "y": 28.34
    },
    {
        "x": 52.99,
        "y": 28.34
    },
    {
        "x": 53,
        "y": 28.34
    },
    {
        "x": 53.01,
        "y": 28.34
    },
    {
        "x": 53.02,
        "y": 28.34
    },
    {
        "x": 53.03,
        "y": 28.34
    },
    {
        "x": 53.04,
        "y": 28.34
    },
    {
        "x": 53.05,
        "y": 28.34
    },
    {
        "x": 53.06,
        "y": 28.34
    },
    {
        "x": 53.07,
        "y": 28.34
    },
    {
        "x": 53.08,
        "y": 28.34
    },
    {
        "x": 53.09,
        "y": 28.34
    },
    {
        "x": 53.1,
        "y": 28.34
    },
    {
        "x": 53.11,
        "y": 28.34
    },
    {
        "x": 53.12,
        "y": 28.34
    },
    {
        "x": 53.13,
        "y": 28.34
    },
    {
        "x": 53.14,
        "y": 28.34
    },
    {
        "x": 53.15,
        "y": 28.34
    },
    {
        "x": 53.16,
        "y": 28.34
    },
    {
        "x": 53.17,
        "y": 28.34
    },
    {
        "x": 53.18,
        "y": 28.34
    },
    {
        "x": 53.19,
        "y": 28.34
    },
    {
        "x": 53.2,
        "y": 28.34
    },
    {
        "x": 53.21,
        "y": 28.35
    },
    {
        "x": 53.22,
        "y": 28.35
    },
    {
        "x": 53.23,
        "y": 28.35
    },
    {
        "x": 53.24,
        "y": 28.35
    },
    {
        "x": 53.25,
        "y": 28.35
    },
    {
        "x": 53.26,
        "y": 28.34
    },
    {
        "x": 53.27,
        "y": 28.34
    },
    {
        "x": 53.28,
        "y": 28.34
    },
    {
        "x": 53.29,
        "y": 28.35
    },
    {
        "x": 53.3,
        "y": 28.35
    },
    {
        "x": 53.31,
        "y": 28.34
    },
    {
        "x": 53.32,
        "y": 28.34
    },
    {
        "x": 53.33,
        "y": 28.34
    },
    {
        "x": 53.34,
        "y": 28.35
    },
    {
        "x": 53.35,
        "y": 28.34
    },
    {
        "x": 53.36,
        "y": 28.34
    },
    {
        "x": 53.37,
        "y": 28.34
    },
    {
        "x": 53.38,
        "y": 28.34
    },
    {
        "x": 53.39,
        "y": 28.34
    },
    {
        "x": 53.4,
        "y": 28.34
    },
    {
        "x": 53.41,
        "y": 28.34
    },
    {
        "x": 53.42,
        "y": 28.34
    },
    {
        "x": 53.43,
        "y": 28.34
    },
    {
        "x": 53.44,
        "y": 28.34
    },
    {
        "x": 53.45,
        "y": 28.34
    },
    {
        "x": 53.46,
        "y": 28.34
    },
    {
        "x": 53.47,
        "y": 28.34
    },
    {
        "x": 53.48,
        "y": 28.34
    },
    {
        "x": 53.49,
        "y": 28.34
    },
    {
        "x": 53.5,
        "y": 28.34
    },
    {
        "x": 53.51,
        "y": 28.34
    },
    {
        "x": 53.52,
        "y": 28.34
    },
    {
        "x": 53.53,
        "y": 28.34
    },
    {
        "x": 53.54,
        "y": 28.34
    },
    {
        "x": 53.55,
        "y": 28.34
    },
    {
        "x": 53.56,
        "y": 28.34
    },
    {
        "x": 53.57,
        "y": 28.34
    },
    {
        "x": 53.58,
        "y": 28.34
    },
    {
        "x": 53.59,
        "y": 28.34
    },
    {
        "x": 53.6,
        "y": 28.34
    },
    {
        "x": 53.61,
        "y": 28.34
    },
    {
        "x": 53.62,
        "y": 28.34
    },
    {
        "x": 53.63,
        "y": 28.34
    },
    {
        "x": 53.64,
        "y": 28.34
    },
    {
        "x": 53.65,
        "y": 28.34
    },
    {
        "x": 53.66,
        "y": 28.34
    },
    {
        "x": 53.67,
        "y": 28.34
    },
    {
        "x": 53.68,
        "y": 28.34
    },
    {
        "x": 53.69,
        "y": 28.34
    },
    {
        "x": 53.7,
        "y": 28.34
    },
    {
        "x": 53.71,
        "y": 28.34
    },
    {
        "x": 53.72,
        "y": 28.34
    },
    {
        "x": 53.73,
        "y": 28.34
    },
    {
        "x": 53.74,
        "y": 28.34
    },
    {
        "x": 53.75,
        "y": 28.33
    },
    {
        "x": 53.76,
        "y": 28.34
    },
    {
        "x": 53.77,
        "y": 28.34
    },
    {
        "x": 53.78,
        "y": 28.34
    },
    {
        "x": 53.79,
        "y": 28.34
    },
    {
        "x": 53.8,
        "y": 28.34
    },
    {
        "x": 53.81,
        "y": 28.34
    },
    {
        "x": 53.82,
        "y": 28.34
    },
    {
        "x": 53.83,
        "y": 28.34
    },
    {
        "x": 53.84,
        "y": 28.34
    },
    {
        "x": 53.85,
        "y": 28.34
    },
    {
        "x": 53.86,
        "y": 28.34
    },
    {
        "x": 53.87,
        "y": 28.34
    },
    {
        "x": 53.88,
        "y": 28.34
    },
    {
        "x": 53.89,
        "y": 28.34
    },
    {
        "x": 53.9,
        "y": 28.34
    },
    {
        "x": 53.91,
        "y": 28.34
    },
    {
        "x": 53.92,
        "y": 28.34
    },
    {
        "x": 53.93,
        "y": 28.34
    },
    {
        "x": 53.94,
        "y": 28.34
    },
    {
        "x": 53.95,
        "y": 28.34
    },
    {
        "x": 53.96,
        "y": 28.34
    },
    {
        "x": 53.97,
        "y": 28.34
    },
    {
        "x": 53.98,
        "y": 28.34
    },
    {
        "x": 53.99,
        "y": 28.34
    },
    {
        "x": 54,
        "y": 28.34
    },
    {
        "x": 54.01,
        "y": 28.34
    },
    {
        "x": 54.02,
        "y": 28.34
    },
    {
        "x": 54.03,
        "y": 28.34
    },
    {
        "x": 54.04,
        "y": 28.34
    },
    {
        "x": 54.05,
        "y": 28.34
    },
    {
        "x": 54.06,
        "y": 28.34
    },
    {
        "x": 54.07,
        "y": 28.34
    },
    {
        "x": 54.08,
        "y": 28.34
    },
    {
        "x": 54.09,
        "y": 28.34
    },
    {
        "x": 54.1,
        "y": 28.34
    },
    {
        "x": 54.11,
        "y": 28.34
    },
    {
        "x": 54.12,
        "y": 28.34
    },
    {
        "x": 54.13,
        "y": 28.34
    },
    {
        "x": 54.14,
        "y": 28.34
    },
    {
        "x": 54.15,
        "y": 28.34
    },
    {
        "x": 54.16,
        "y": 28.34
    },
    {
        "x": 54.17,
        "y": 28.34
    },
    {
        "x": 54.18,
        "y": 28.34
    },
    {
        "x": 54.19,
        "y": 28.34
    },
    {
        "x": 54.2,
        "y": 28.34
    },
    {
        "x": 54.21,
        "y": 28.34
    },
    {
        "x": 54.22,
        "y": 28.34
    },
    {
        "x": 54.23,
        "y": 28.34
    },
    {
        "x": 54.24,
        "y": 28.34
    },
    {
        "x": 54.25,
        "y": 28.34
    },
    {
        "x": 54.26,
        "y": 28.34
    },
    {
        "x": 54.27,
        "y": 28.34
    },
    {
        "x": 54.28,
        "y": 28.34
    },
    {
        "x": 54.29,
        "y": 28.34
    },
    {
        "x": 54.3,
        "y": 28.34
    },
    {
        "x": 54.31,
        "y": 28.34
    },
    {
        "x": 54.32,
        "y": 28.34
    },
    {
        "x": 54.33,
        "y": 28.34
    },
    {
        "x": 54.34,
        "y": 28.34
    },
    {
        "x": 54.35,
        "y": 28.34
    },
    {
        "x": 54.36,
        "y": 28.34
    },
    {
        "x": 54.37,
        "y": 28.34
    },
    {
        "x": 54.38,
        "y": 28.34
    },
    {
        "x": 54.39,
        "y": 28.34
    },
    {
        "x": 54.4,
        "y": 28.34
    },
    {
        "x": 54.41,
        "y": 28.34
    },
    {
        "x": 54.42,
        "y": 28.34
    },
    {
        "x": 54.43,
        "y": 28.34
    },
    {
        "x": 54.44,
        "y": 28.34
    },
    {
        "x": 54.45,
        "y": 28.34
    },
    {
        "x": 54.46,
        "y": 28.34
    },
    {
        "x": 54.47,
        "y": 28.34
    },
    {
        "x": 54.48,
        "y": 28.34
    },
    {
        "x": 54.49,
        "y": 28.34
    },
    {
        "x": 54.5,
        "y": 28.34
    },
    {
        "x": 54.51,
        "y": 28.35
    },
    {
        "x": 54.52,
        "y": 28.35
    },
    {
        "x": 54.53,
        "y": 28.34
    },
    {
        "x": 54.54,
        "y": 28.35
    },
    {
        "x": 54.55,
        "y": 28.34
    },
    {
        "x": 54.56,
        "y": 28.34
    },
    {
        "x": 54.57,
        "y": 28.34
    },
    {
        "x": 54.58,
        "y": 28.34
    },
    {
        "x": 54.59,
        "y": 28.34
    },
    {
        "x": 54.6,
        "y": 28.34
    },
    {
        "x": 54.61,
        "y": 28.34
    },
    {
        "x": 54.62,
        "y": 28.34
    },
    {
        "x": 54.63,
        "y": 28.34
    },
    {
        "x": 54.64,
        "y": 28.34
    },
    {
        "x": 54.65,
        "y": 28.34
    },
    {
        "x": 54.66,
        "y": 28.34
    },
    {
        "x": 54.67,
        "y": 28.34
    },
    {
        "x": 54.68,
        "y": 28.34
    },
    {
        "x": 54.69,
        "y": 28.34
    },
    {
        "x": 54.7,
        "y": 28.34
    },
    {
        "x": 54.71,
        "y": 28.34
    },
    {
        "x": 54.72,
        "y": 28.34
    },
    {
        "x": 54.73,
        "y": 28.34
    },
    {
        "x": 54.74,
        "y": 28.34
    },
    {
        "x": 54.75,
        "y": 28.34
    },
    {
        "x": 54.76,
        "y": 28.34
    },
    {
        "x": 54.77,
        "y": 28.34
    },
    {
        "x": 54.78,
        "y": 28.34
    },
    {
        "x": 54.79,
        "y": 28.34
    },
    {
        "x": 54.8,
        "y": 28.34
    },
    {
        "x": 54.81,
        "y": 28.34
    },
    {
        "x": 54.82,
        "y": 28.34
    },
    {
        "x": 54.83,
        "y": 28.34
    },
    {
        "x": 54.84,
        "y": 28.34
    },
    {
        "x": 54.85,
        "y": 28.34
    },
    {
        "x": 54.86,
        "y": 28.34
    },
    {
        "x": 54.87,
        "y": 28.34
    },
    {
        "x": 54.88,
        "y": 28.34
    },
    {
        "x": 54.89,
        "y": 28.34
    },
    {
        "x": 54.9,
        "y": 28.34
    },
    {
        "x": 54.91,
        "y": 28.34
    },
    {
        "x": 54.92,
        "y": 28.34
    },
    {
        "x": 54.93,
        "y": 28.34
    },
    {
        "x": 54.94,
        "y": 28.34
    },
    {
        "x": 54.95,
        "y": 28.34
    },
    {
        "x": 54.96,
        "y": 28.34
    },
    {
        "x": 54.97,
        "y": 28.34
    },
    {
        "x": 54.98,
        "y": 28.34
    },
    {
        "x": 54.99,
        "y": 28.34
    },
    {
        "x": 55,
        "y": 28.34
    },
    {
        "x": 55.01,
        "y": 28.34
    },
    {
        "x": 55.02,
        "y": 28.34
    },
    {
        "x": 55.03,
        "y": 28.34
    },
    {
        "x": 55.04,
        "y": 28.34
    },
    {
        "x": 55.05,
        "y": 28.34
    },
    {
        "x": 55.06,
        "y": 28.34
    },
    {
        "x": 55.07,
        "y": 28.34
    },
    {
        "x": 55.08,
        "y": 28.34
    },
    {
        "x": 55.09,
        "y": 28.34
    },
    {
        "x": 55.1,
        "y": 28.34
    },
    {
        "x": 55.11,
        "y": 28.34
    },
    {
        "x": 55.12,
        "y": 28.34
    },
    {
        "x": 55.13,
        "y": 28.34
    },
    {
        "x": 55.14,
        "y": 28.34
    },
    {
        "x": 55.15,
        "y": 28.34
    },
    {
        "x": 55.16,
        "y": 28.34
    },
    {
        "x": 55.17,
        "y": 28.34
    },
    {
        "x": 55.18,
        "y": 28.34
    },
    {
        "x": 55.19,
        "y": 28.34
    },
    {
        "x": 55.2,
        "y": 28.34
    },
    {
        "x": 55.21,
        "y": 28.34
    },
    {
        "x": 55.22,
        "y": 28.34
    },
    {
        "x": 55.23,
        "y": 28.34
    },
    {
        "x": 55.24,
        "y": 28.34
    },
    {
        "x": 55.25,
        "y": 28.34
    },
    {
        "x": 55.26,
        "y": 28.34
    },
    {
        "x": 55.27,
        "y": 28.34
    },
    {
        "x": 55.28,
        "y": 28.34
    },
    {
        "x": 55.29,
        "y": 28.34
    },
    {
        "x": 55.3,
        "y": 28.34
    },
    {
        "x": 55.31,
        "y": 28.34
    },
    {
        "x": 55.32,
        "y": 28.34
    },
    {
        "x": 55.33,
        "y": 28.34
    },
    {
        "x": 55.34,
        "y": 28.34
    },
    {
        "x": 55.35,
        "y": 28.34
    },
    {
        "x": 55.36,
        "y": 28.34
    },
    {
        "x": 55.37,
        "y": 28.34
    },
    {
        "x": 55.38,
        "y": 28.34
    },
    {
        "x": 55.39,
        "y": 28.34
    },
    {
        "x": 55.4,
        "y": 28.34
    },
    {
        "x": 55.41,
        "y": 28.34
    },
    {
        "x": 55.42,
        "y": 28.34
    },
    {
        "x": 55.43,
        "y": 28.34
    },
    {
        "x": 55.44,
        "y": 28.34
    },
    {
        "x": 55.45,
        "y": 28.34
    },
    {
        "x": 55.46,
        "y": 28.34
    },
    {
        "x": 55.47,
        "y": 28.34
    },
    {
        "x": 55.48,
        "y": 28.34
    },
    {
        "x": 55.49,
        "y": 28.34
    },
    {
        "x": 55.5,
        "y": 28.34
    },
    {
        "x": 55.51,
        "y": 28.34
    },
    {
        "x": 55.52,
        "y": 28.34
    },
    {
        "x": 55.53,
        "y": 28.34
    },
    {
        "x": 55.54,
        "y": 28.34
    },
    {
        "x": 55.55,
        "y": 28.34
    },
    {
        "x": 55.56,
        "y": 28.34
    },
    {
        "x": 55.57,
        "y": 28.34
    },
    {
        "x": 55.58,
        "y": 28.34
    },
    {
        "x": 55.59,
        "y": 28.34
    },
    {
        "x": 55.6,
        "y": 28.34
    },
    {
        "x": 55.61,
        "y": 28.34
    },
    {
        "x": 55.62,
        "y": 28.34
    },
    {
        "x": 55.63,
        "y": 28.34
    },
    {
        "x": 55.64,
        "y": 28.34
    },
    {
        "x": 55.65,
        "y": 28.34
    },
    {
        "x": 55.66,
        "y": 28.34
    },
    {
        "x": 55.67,
        "y": 28.34
    },
    {
        "x": 55.68,
        "y": 28.34
    },
    {
        "x": 55.69,
        "y": 28.34
    },
    {
        "x": 55.7,
        "y": 28.34
    },
    {
        "x": 55.71,
        "y": 28.34
    },
    {
        "x": 55.72,
        "y": 28.34
    },
    {
        "x": 55.73,
        "y": 28.34
    },
    {
        "x": 55.74,
        "y": 28.34
    },
    {
        "x": 55.75,
        "y": 28.34
    },
    {
        "x": 55.76,
        "y": 28.34
    },
    {
        "x": 55.77,
        "y": 28.34
    },
    {
        "x": 55.78,
        "y": 28.34
    },
    {
        "x": 55.79,
        "y": 28.34
    },
    {
        "x": 55.8,
        "y": 28.34
    },
    {
        "x": 55.81,
        "y": 28.34
    },
    {
        "x": 55.82,
        "y": 28.34
    },
    {
        "x": 55.83,
        "y": 28.34
    },
    {
        "x": 55.84,
        "y": 28.34
    },
    {
        "x": 55.85,
        "y": 28.34
    },
    {
        "x": 55.86,
        "y": 28.34
    },
    {
        "x": 55.87,
        "y": 28.34
    },
    {
        "x": 55.88,
        "y": 28.34
    },
    {
        "x": 55.89,
        "y": 28.34
    },
    {
        "x": 55.9,
        "y": 28.34
    },
    {
        "x": 55.91,
        "y": 28.34
    },
    {
        "x": 55.92,
        "y": 28.34
    },
    {
        "x": 55.93,
        "y": 28.34
    },
    {
        "x": 55.94,
        "y": 28.34
    },
    {
        "x": 55.95,
        "y": 28.34
    },
    {
        "x": 55.96,
        "y": 28.34
    },
    {
        "x": 55.97,
        "y": 28.34
    },
    {
        "x": 55.98,
        "y": 28.34
    },
    {
        "x": 55.99,
        "y": 28.34
    },
    {
        "x": 56,
        "y": 28.33
    },
    {
        "x": 56.01,
        "y": 28.34
    },
    {
        "x": 56.02,
        "y": 28.34
    },
    {
        "x": 56.03,
        "y": 28.33
    },
    {
        "x": 56.04,
        "y": 28.34
    },
    {
        "x": 56.05,
        "y": 28.34
    },
    {
        "x": 56.06,
        "y": 28.34
    },
    {
        "x": 56.07,
        "y": 28.34
    },
    {
        "x": 56.08,
        "y": 28.34
    },
    {
        "x": 56.09,
        "y": 28.34
    },
    {
        "x": 56.1,
        "y": 28.34
    },
    {
        "x": 56.11,
        "y": 28.34
    },
    {
        "x": 56.12,
        "y": 28.34
    },
    {
        "x": 56.13,
        "y": 28.34
    },
    {
        "x": 56.14,
        "y": 28.34
    },
    {
        "x": 56.15,
        "y": 28.34
    },
    {
        "x": 56.16,
        "y": 28.33
    },
    {
        "x": 56.17,
        "y": 28.34
    },
    {
        "x": 56.18,
        "y": 28.34
    },
    {
        "x": 56.19,
        "y": 28.34
    },
    {
        "x": 56.2,
        "y": 28.34
    },
    {
        "x": 56.21,
        "y": 28.34
    },
    {
        "x": 56.22,
        "y": 28.34
    },
    {
        "x": 56.23,
        "y": 28.33
    },
    {
        "x": 56.24,
        "y": 28.34
    },
    {
        "x": 56.25,
        "y": 28.34
    },
    {
        "x": 56.26,
        "y": 28.33
    },
    {
        "x": 56.27,
        "y": 28.33
    },
    {
        "x": 56.28,
        "y": 28.34
    },
    {
        "x": 56.29,
        "y": 28.34
    },
    {
        "x": 56.3,
        "y": 28.34
    },
    {
        "x": 56.31,
        "y": 28.34
    },
    {
        "x": 56.32,
        "y": 28.34
    },
    {
        "x": 56.33,
        "y": 28.34
    },
    {
        "x": 56.34,
        "y": 28.34
    },
    {
        "x": 56.35,
        "y": 28.33
    },
    {
        "x": 56.36,
        "y": 28.34
    },
    {
        "x": 56.37,
        "y": 28.34
    },
    {
        "x": 56.38,
        "y": 28.34
    },
    {
        "x": 56.39,
        "y": 28.34
    },
    {
        "x": 56.4,
        "y": 28.34
    },
    {
        "x": 56.41,
        "y": 28.34
    },
    {
        "x": 56.42,
        "y": 28.34
    },
    {
        "x": 56.43,
        "y": 28.33
    },
    {
        "x": 56.44,
        "y": 28.33
    },
    {
        "x": 56.45,
        "y": 28.34
    },
    {
        "x": 56.46,
        "y": 28.33
    },
    {
        "x": 56.47,
        "y": 28.34
    },
    {
        "x": 56.48,
        "y": 28.33
    },
    {
        "x": 56.49,
        "y": 28.33
    },
    {
        "x": 56.5,
        "y": 28.33
    },
    {
        "x": 56.51,
        "y": 28.34
    },
    {
        "x": 56.52,
        "y": 28.34
    },
    {
        "x": 56.53,
        "y": 28.34
    },
    {
        "x": 56.54,
        "y": 28.33
    },
    {
        "x": 56.55,
        "y": 28.33
    },
    {
        "x": 56.56,
        "y": 28.33
    },
    {
        "x": 56.57,
        "y": 28.33
    },
    {
        "x": 56.58,
        "y": 28.33
    },
    {
        "x": 56.59,
        "y": 28.34
    },
    {
        "x": 56.6,
        "y": 28.33
    },
    {
        "x": 56.61,
        "y": 28.34
    },
    {
        "x": 56.62,
        "y": 28.34
    },
    {
        "x": 56.63,
        "y": 28.33
    },
    {
        "x": 56.64,
        "y": 28.34
    },
    {
        "x": 56.65,
        "y": 28.34
    },
    {
        "x": 56.66,
        "y": 28.33
    },
    {
        "x": 56.67,
        "y": 28.33
    },
    {
        "x": 56.68,
        "y": 28.34
    },
    {
        "x": 56.69,
        "y": 28.34
    },
    {
        "x": 56.7,
        "y": 28.34
    },
    {
        "x": 56.71,
        "y": 28.33
    },
    {
        "x": 56.72,
        "y": 28.33
    },
    {
        "x": 56.73,
        "y": 28.33
    },
    {
        "x": 56.74,
        "y": 28.33
    },
    {
        "x": 56.75,
        "y": 28.33
    },
    {
        "x": 56.76,
        "y": 28.34
    },
    {
        "x": 56.77,
        "y": 28.33
    },
    {
        "x": 56.78,
        "y": 28.33
    },
    {
        "x": 56.79,
        "y": 28.34
    },
    {
        "x": 56.8,
        "y": 28.33
    },
    {
        "x": 56.81,
        "y": 28.34
    },
    {
        "x": 56.82,
        "y": 28.34
    },
    {
        "x": 56.83,
        "y": 28.34
    },
    {
        "x": 56.84,
        "y": 28.33
    },
    {
        "x": 56.85,
        "y": 28.34
    },
    {
        "x": 56.86,
        "y": 28.34
    },
    {
        "x": 56.87,
        "y": 28.34
    },
    {
        "x": 56.88,
        "y": 28.34
    },
    {
        "x": 56.89,
        "y": 28.34
    },
    {
        "x": 56.9,
        "y": 28.34
    },
    {
        "x": 56.91,
        "y": 28.34
    },
    {
        "x": 56.92,
        "y": 28.33
    },
    {
        "x": 56.93,
        "y": 28.34
    },
    {
        "x": 56.94,
        "y": 28.34
    },
    {
        "x": 56.95,
        "y": 28.34
    },
    {
        "x": 56.96,
        "y": 28.33
    },
    {
        "x": 56.97,
        "y": 28.34
    },
    {
        "x": 56.98,
        "y": 28.34
    },
    {
        "x": 56.99,
        "y": 28.34
    },
    {
        "x": 57,
        "y": 28.34
    },
    {
        "x": 57.01,
        "y": 28.34
    },
    {
        "x": 57.02,
        "y": 28.34
    },
    {
        "x": 57.03,
        "y": 28.34
    },
    {
        "x": 57.04,
        "y": 28.34
    },
    {
        "x": 57.05,
        "y": 28.34
    },
    {
        "x": 57.06,
        "y": 28.33
    },
    {
        "x": 57.07,
        "y": 28.34
    },
    {
        "x": 57.08,
        "y": 28.33
    },
    {
        "x": 57.09,
        "y": 28.33
    },
    {
        "x": 57.1,
        "y": 28.33
    },
    {
        "x": 57.11,
        "y": 28.33
    },
    {
        "x": 57.12,
        "y": 28.33
    },
    {
        "x": 57.13,
        "y": 28.33
    },
    {
        "x": 57.14,
        "y": 28.34
    },
    {
        "x": 57.15,
        "y": 28.33
    },
    {
        "x": 57.16,
        "y": 28.33
    },
    {
        "x": 57.17,
        "y": 28.33
    },
    {
        "x": 57.18,
        "y": 28.34
    },
    {
        "x": 57.19,
        "y": 28.33
    },
    {
        "x": 57.2,
        "y": 28.34
    },
    {
        "x": 57.21,
        "y": 28.33
    },
    {
        "x": 57.22,
        "y": 28.33
    },
    {
        "x": 57.23,
        "y": 28.33
    },
    {
        "x": 57.24,
        "y": 28.33
    },
    {
        "x": 57.25,
        "y": 28.33
    },
    {
        "x": 57.26,
        "y": 28.33
    },
    {
        "x": 57.27,
        "y": 28.33
    },
    {
        "x": 57.28,
        "y": 28.33
    },
    {
        "x": 57.29,
        "y": 28.33
    },
    {
        "x": 57.3,
        "y": 28.33
    },
    {
        "x": 57.31,
        "y": 28.33
    },
    {
        "x": 57.32,
        "y": 28.33
    },
    {
        "x": 57.33,
        "y": 28.33
    },
    {
        "x": 57.34,
        "y": 28.33
    },
    {
        "x": 57.35,
        "y": 28.33
    },
    {
        "x": 57.36,
        "y": 28.33
    },
    {
        "x": 57.37,
        "y": 28.33
    },
    {
        "x": 57.38,
        "y": 28.33
    },
    {
        "x": 57.39,
        "y": 28.33
    },
    {
        "x": 57.4,
        "y": 28.33
    },
    {
        "x": 57.41,
        "y": 28.33
    },
    {
        "x": 57.42,
        "y": 28.33
    },
    {
        "x": 57.43,
        "y": 28.33
    },
    {
        "x": 57.44,
        "y": 28.33
    },
    {
        "x": 57.45,
        "y": 28.33
    },
    {
        "x": 57.46,
        "y": 28.33
    },
    {
        "x": 57.47,
        "y": 28.33
    },
    {
        "x": 57.48,
        "y": 28.33
    },
    {
        "x": 57.49,
        "y": 28.33
    },
    {
        "x": 57.5,
        "y": 28.33
    },
    {
        "x": 57.51,
        "y": 28.33
    },
    {
        "x": 57.52,
        "y": 28.33
    },
    {
        "x": 57.53,
        "y": 28.33
    },
    {
        "x": 57.54,
        "y": 28.33
    },
    {
        "x": 57.55,
        "y": 28.33
    },
    {
        "x": 57.56,
        "y": 28.33
    },
    {
        "x": 57.57,
        "y": 28.33
    },
    {
        "x": 57.58,
        "y": 28.33
    },
    {
        "x": 57.59,
        "y": 28.33
    },
    {
        "x": 57.6,
        "y": 28.33
    },
    {
        "x": 57.61,
        "y": 28.34
    },
    {
        "x": 57.62,
        "y": 28.33
    },
    {
        "x": 57.63,
        "y": 28.33
    },
    {
        "x": 57.64,
        "y": 28.33
    },
    {
        "x": 57.65,
        "y": 28.33
    },
    {
        "x": 57.66,
        "y": 28.33
    },
    {
        "x": 57.67,
        "y": 28.33
    },
    {
        "x": 57.68,
        "y": 28.33
    },
    {
        "x": 57.69,
        "y": 28.33
    },
    {
        "x": 57.7,
        "y": 28.33
    },
    {
        "x": 57.71,
        "y": 28.33
    },
    {
        "x": 57.72,
        "y": 28.33
    },
    {
        "x": 57.73,
        "y": 28.33
    },
    {
        "x": 57.74,
        "y": 28.33
    },
    {
        "x": 57.75,
        "y": 28.33
    },
    {
        "x": 57.76,
        "y": 28.33
    },
    {
        "x": 57.77,
        "y": 28.33
    },
    {
        "x": 57.78,
        "y": 28.33
    },
    {
        "x": 57.79,
        "y": 28.33
    },
    {
        "x": 57.8,
        "y": 28.33
    },
    {
        "x": 57.81,
        "y": 28.33
    },
    {
        "x": 57.82,
        "y": 28.33
    },
    {
        "x": 57.83,
        "y": 28.33
    },
    {
        "x": 57.84,
        "y": 28.33
    },
    {
        "x": 57.85,
        "y": 28.33
    },
    {
        "x": 57.86,
        "y": 28.33
    },
    {
        "x": 57.87,
        "y": 28.33
    },
    {
        "x": 57.88,
        "y": 28.33
    },
    {
        "x": 57.89,
        "y": 28.33
    },
    {
        "x": 57.9,
        "y": 28.33
    },
    {
        "x": 57.91,
        "y": 28.33
    },
    {
        "x": 57.92,
        "y": 28.33
    },
    {
        "x": 57.93,
        "y": 28.33
    },
    {
        "x": 57.94,
        "y": 28.33
    },
    {
        "x": 57.95,
        "y": 28.33
    },
    {
        "x": 57.96,
        "y": 28.33
    },
    {
        "x": 57.97,
        "y": 28.33
    },
    {
        "x": 57.98,
        "y": 28.33
    },
    {
        "x": 57.99,
        "y": 28.33
    },
    {
        "x": 58,
        "y": 28.33
    },
    {
        "x": 58.01,
        "y": 28.33
    },
    {
        "x": 58.02,
        "y": 28.33
    },
    {
        "x": 58.03,
        "y": 28.33
    },
    {
        "x": 58.04,
        "y": 28.33
    },
    {
        "x": 58.05,
        "y": 28.33
    },
    {
        "x": 58.06,
        "y": 28.33
    },
    {
        "x": 58.07,
        "y": 28.33
    },
    {
        "x": 58.08,
        "y": 28.34
    },
    {
        "x": 58.09,
        "y": 28.33
    },
    {
        "x": 58.1,
        "y": 28.33
    },
    {
        "x": 58.11,
        "y": 28.33
    },
    {
        "x": 58.12,
        "y": 28.33
    },
    {
        "x": 58.13,
        "y": 28.33
    },
    {
        "x": 58.14,
        "y": 28.33
    },
    {
        "x": 58.15,
        "y": 28.33
    },
    {
        "x": 58.16,
        "y": 28.33
    },
    {
        "x": 58.17,
        "y": 28.33
    },
    {
        "x": 58.18,
        "y": 28.33
    },
    {
        "x": 58.19,
        "y": 28.33
    },
    {
        "x": 58.2,
        "y": 28.33
    },
    {
        "x": 58.21,
        "y": 28.33
    },
    {
        "x": 58.22,
        "y": 28.33
    },
    {
        "x": 58.23,
        "y": 28.33
    },
    {
        "x": 58.24,
        "y": 28.33
    },
    {
        "x": 58.25,
        "y": 28.33
    },
    {
        "x": 58.26,
        "y": 28.33
    },
    {
        "x": 58.27,
        "y": 28.33
    },
    {
        "x": 58.28,
        "y": 28.33
    },
    {
        "x": 58.29,
        "y": 28.33
    },
    {
        "x": 58.3,
        "y": 28.33
    },
    {
        "x": 58.31,
        "y": 28.33
    },
    {
        "x": 58.32,
        "y": 28.33
    },
    {
        "x": 58.33,
        "y": 28.33
    },
    {
        "x": 58.34,
        "y": 28.33
    },
    {
        "x": 58.35,
        "y": 28.33
    },
    {
        "x": 58.36,
        "y": 28.33
    },
    {
        "x": 58.37,
        "y": 28.33
    },
    {
        "x": 58.38,
        "y": 28.33
    },
    {
        "x": 58.39,
        "y": 28.31
    },
    {
        "x": 58.4,
        "y": 28.33
    },
    {
        "x": 58.41,
        "y": 28.33
    },
    {
        "x": 58.42,
        "y": 28.33
    },
    {
        "x": 58.43,
        "y": 28.33
    },
    {
        "x": 58.44,
        "y": 28.33
    },
    {
        "x": 58.45,
        "y": 28.33
    },
    {
        "x": 58.46,
        "y": 28.31
    },
    {
        "x": 58.47,
        "y": 28.33
    },
    {
        "x": 58.48,
        "y": 28.33
    },
    {
        "x": 58.49,
        "y": 28.33
    },
    {
        "x": 58.5,
        "y": 28.33
    },
    {
        "x": 58.51,
        "y": 28.33
    },
    {
        "x": 58.52,
        "y": 28.33
    },
    {
        "x": 58.53,
        "y": 28.33
    },
    {
        "x": 58.54,
        "y": 28.33
    },
    {
        "x": 58.55,
        "y": 28.33
    },
    {
        "x": 58.56,
        "y": 28.33
    },
    {
        "x": 58.57,
        "y": 28.33
    },
    {
        "x": 58.58,
        "y": 28.33
    },
    {
        "x": 58.59,
        "y": 28.33
    },
    {
        "x": 58.6,
        "y": 28.33
    },
    {
        "x": 58.61,
        "y": 28.33
    },
    {
        "x": 58.62,
        "y": 28.33
    },
    {
        "x": 58.63,
        "y": 28.33
    },
    {
        "x": 58.64,
        "y": 28.33
    },
    {
        "x": 58.65,
        "y": 28.31
    },
    {
        "x": 58.66,
        "y": 28.33
    },
    {
        "x": 58.67,
        "y": 28.33
    },
    {
        "x": 58.68,
        "y": 28.33
    },
    {
        "x": 58.69,
        "y": 28.33
    },
    {
        "x": 58.7,
        "y": 28.33
    },
    {
        "x": 58.71,
        "y": 28.33
    },
    {
        "x": 58.72,
        "y": 28.33
    },
    {
        "x": 58.73,
        "y": 28.33
    },
    {
        "x": 58.74,
        "y": 28.31
    },
    {
        "x": 58.75,
        "y": 28.33
    },
    {
        "x": 58.76,
        "y": 28.33
    },
    {
        "x": 58.77,
        "y": 28.31
    },
    {
        "x": 58.78,
        "y": 28.31
    },
    {
        "x": 58.79,
        "y": 28.33
    },
    {
        "x": 58.8,
        "y": 28.33
    },
    {
        "x": 58.81,
        "y": 28.33
    },
    {
        "x": 58.82,
        "y": 28.31
    },
    {
        "x": 58.83,
        "y": 28.33
    },
    {
        "x": 58.84,
        "y": 28.33
    },
    {
        "x": 58.85,
        "y": 28.33
    },
    {
        "x": 58.86,
        "y": 28.33
    },
    {
        "x": 58.87,
        "y": 28.33
    },
    {
        "x": 58.88,
        "y": 28.33
    },
    {
        "x": 58.89,
        "y": 28.33
    },
    {
        "x": 58.9,
        "y": 28.33
    },
    {
        "x": 58.91,
        "y": 28.33
    },
    {
        "x": 58.92,
        "y": 28.33
    },
    {
        "x": 58.93,
        "y": 28.33
    },
    {
        "x": 58.94,
        "y": 28.33
    },
    {
        "x": 58.95,
        "y": 28.33
    },
    {
        "x": 58.96,
        "y": 28.33
    },
    {
        "x": 58.97,
        "y": 28.33
    },
    {
        "x": 58.98,
        "y": 28.31
    },
    {
        "x": 58.99,
        "y": 28.33
    },
    {
        "x": 59,
        "y": 28.33
    },
    {
        "x": 59.01,
        "y": 28.33
    },
    {
        "x": 59.02,
        "y": 28.33
    },
    {
        "x": 59.03,
        "y": 28.33
    },
    {
        "x": 59.04,
        "y": 28.33
    },
    {
        "x": 59.05,
        "y": 28.33
    },
    {
        "x": 59.06,
        "y": 28.33
    },
    {
        "x": 59.07,
        "y": 28.33
    },
    {
        "x": 59.08,
        "y": 28.33
    },
    {
        "x": 59.09,
        "y": 28.33
    },
    {
        "x": 59.1,
        "y": 28.33
    },
    {
        "x": 59.11,
        "y": 28.33
    },
    {
        "x": 59.12,
        "y": 28.33
    },
    {
        "x": 59.13,
        "y": 28.33
    },
    {
        "x": 59.14,
        "y": 28.33
    },
    {
        "x": 59.15,
        "y": 28.33
    },
    {
        "x": 59.16,
        "y": 28.33
    },
    {
        "x": 59.17,
        "y": 28.33
    },
    {
        "x": 59.18,
        "y": 28.33
    },
    {
        "x": 59.19,
        "y": 28.33
    },
    {
        "x": 59.2,
        "y": 28.33
    },
    {
        "x": 59.21,
        "y": 28.31
    },
    {
        "x": 59.22,
        "y": 28.33
    },
    {
        "x": 59.23,
        "y": 28.33
    },
    {
        "x": 59.24,
        "y": 28.33
    },
    {
        "x": 59.25,
        "y": 28.33
    },
    {
        "x": 59.26,
        "y": 28.33
    },
    {
        "x": 59.27,
        "y": 28.33
    },
    {
        "x": 59.28,
        "y": 28.33
    },
    {
        "x": 59.29,
        "y": 28.33
    },
    {
        "x": 59.3,
        "y": 28.33
    },
    {
        "x": 59.31,
        "y": 28.33
    },
    {
        "x": 59.32,
        "y": 28.33
    },
    {
        "x": 59.33,
        "y": 28.33
    },
    {
        "x": 59.34,
        "y": 28.33
    },
    {
        "x": 59.35,
        "y": 28.33
    },
    {
        "x": 59.36,
        "y": 28.33
    },
    {
        "x": 59.37,
        "y": 28.33
    },
    {
        "x": 59.38,
        "y": 28.33
    },
    {
        "x": 59.39,
        "y": 28.33
    },
    {
        "x": 59.4,
        "y": 28.31
    },
    {
        "x": 59.41,
        "y": 28.33
    },
    {
        "x": 59.42,
        "y": 28.33
    },
    {
        "x": 59.43,
        "y": 28.33
    },
    {
        "x": 59.44,
        "y": 28.31
    },
    {
        "x": 59.45,
        "y": 28.33
    },
    {
        "x": 59.46,
        "y": 28.31
    },
    {
        "x": 59.47,
        "y": 28.31
    },
    {
        "x": 59.48,
        "y": 28.33
    },
    {
        "x": 59.49,
        "y": 28.33
    },
    {
        "x": 59.5,
        "y": 28.33
    },
    {
        "x": 59.51,
        "y": 28.33
    },
    {
        "x": 59.52,
        "y": 28.33
    },
    {
        "x": 59.53,
        "y": 28.31
    },
    {
        "x": 59.54,
        "y": 28.33
    },
    {
        "x": 59.55,
        "y": 28.33
    },
    {
        "x": 59.56,
        "y": 28.33
    },
    {
        "x": 59.57,
        "y": 28.31
    },
    {
        "x": 59.58,
        "y": 28.33
    },
    {
        "x": 59.59,
        "y": 28.33
    },
    {
        "x": 59.6,
        "y": 28.33
    },
    {
        "x": 59.61,
        "y": 28.31
    },
    {
        "x": 59.62,
        "y": 28.31
    },
    {
        "x": 59.63,
        "y": 28.31
    },
    {
        "x": 59.64,
        "y": 28.33
    },
    {
        "x": 59.65,
        "y": 28.31
    },
    {
        "x": 59.66,
        "y": 28.31
    },
    {
        "x": 59.67,
        "y": 28.31
    },
    {
        "x": 59.68,
        "y": 28.31
    },
    {
        "x": 59.69,
        "y": 28.31
    },
    {
        "x": 59.7,
        "y": 28.31
    },
    {
        "x": 59.71,
        "y": 28.33
    },
    {
        "x": 59.72,
        "y": 28.31
    },
    {
        "x": 59.73,
        "y": 28.31
    },
    {
        "x": 59.74,
        "y": 28.31
    },
    {
        "x": 59.75,
        "y": 28.31
    },
    {
        "x": 59.76,
        "y": 28.31
    },
    {
        "x": 59.77,
        "y": 28.33
    },
    {
        "x": 59.78,
        "y": 28.31
    },
    {
        "x": 59.79,
        "y": 28.33
    },
    {
        "x": 59.8,
        "y": 28.31
    },
    {
        "x": 59.81,
        "y": 28.31
    },
    {
        "x": 59.82,
        "y": 28.33
    },
    {
        "x": 59.83,
        "y": 28.33
    },
    {
        "x": 59.84,
        "y": 28.31
    },
    {
        "x": 59.85,
        "y": 28.31
    },
    {
        "x": 59.86,
        "y": 28.31
    },
    {
        "x": 59.87,
        "y": 28.31
    },
    {
        "x": 59.88,
        "y": 28.31
    },
    {
        "x": 59.89,
        "y": 28.33
    },
    {
        "x": 59.9,
        "y": 28.31
    },
    {
        "x": 59.91,
        "y": 28.31
    },
    {
        "x": 59.92,
        "y": 28.31
    },
    {
        "x": 59.93,
        "y": 28.31
    },
    {
        "x": 59.94,
        "y": 28.31
    },
    {
        "x": 59.95,
        "y": 28.31
    },
    {
        "x": 59.96,
        "y": 28.31
    },
    {
        "x": 59.97,
        "y": 28.31
    },
    {
        "x": 59.98,
        "y": 28.31
    },
    {
        "x": 59.99,
        "y": 28.31
    },
    {
        "x": 60,
        "y": 28.31
    },
    {
        "x": 60.01,
        "y": 28.31
    },
    {
        "x": 60.02,
        "y": 28.31
    },
    {
        "x": 60.03,
        "y": 28.33
    },
    {
        "x": 60.04,
        "y": 28.31
    },
    {
        "x": 60.05,
        "y": 28.31
    },
    {
        "x": 60.06,
        "y": 28.31
    },
    {
        "x": 60.07,
        "y": 28.31
    },
    {
        "x": 60.08,
        "y": 28.31
    },
    {
        "x": 60.09,
        "y": 28.31
    },
    {
        "x": 60.1,
        "y": 28.33
    },
    {
        "x": 60.11,
        "y": 28.31
    },
    {
        "x": 60.12,
        "y": 28.31
    },
    {
        "x": 60.13,
        "y": 28.31
    },
    {
        "x": 60.14,
        "y": 28.31
    },
    {
        "x": 60.15,
        "y": 28.31
    },
    {
        "x": 60.16,
        "y": 28.31
    },
    {
        "x": 60.17,
        "y": 28.31
    },
    {
        "x": 60.18,
        "y": 28.33
    },
    {
        "x": 60.19,
        "y": 28.31
    },
    {
        "x": 60.2,
        "y": 28.31
    },
    {
        "x": 60.21,
        "y": 28.33
    },
    {
        "x": 60.22,
        "y": 28.31
    },
    {
        "x": 60.23,
        "y": 28.31
    },
    {
        "x": 60.24,
        "y": 28.31
    },
    {
        "x": 60.25,
        "y": 28.31
    },
    {
        "x": 60.26,
        "y": 28.31
    },
    {
        "x": 60.27,
        "y": 28.31
    },
    {
        "x": 60.28,
        "y": 28.31
    },
    {
        "x": 60.29,
        "y": 28.31
    },
    {
        "x": 60.3,
        "y": 28.33
    },
    {
        "x": 60.31,
        "y": 28.31
    },
    {
        "x": 60.32,
        "y": 28.31
    },
    {
        "x": 60.33,
        "y": 28.31
    },
    {
        "x": 60.34,
        "y": 28.33
    },
    {
        "x": 60.35,
        "y": 28.31
    },
    {
        "x": 60.36,
        "y": 28.31
    },
    {
        "x": 60.37,
        "y": 28.31
    },
    {
        "x": 60.38,
        "y": 28.31
    },
    {
        "x": 60.39,
        "y": 28.31
    },
    {
        "x": 60.4,
        "y": 28.31
    },
    {
        "x": 60.41,
        "y": 28.31
    },
    {
        "x": 60.42,
        "y": 28.31
    },
    {
        "x": 60.43,
        "y": 28.31
    },
    {
        "x": 60.44,
        "y": 28.31
    },
    {
        "x": 60.45,
        "y": 28.31
    },
    {
        "x": 60.46,
        "y": 28.33
    },
    {
        "x": 60.47,
        "y": 28.31
    },
    {
        "x": 60.48,
        "y": 28.31
    },
    {
        "x": 60.49,
        "y": 28.31
    },
    {
        "x": 60.5,
        "y": 28.31
    },
    {
        "x": 60.51,
        "y": 28.31
    },
    {
        "x": 60.52,
        "y": 28.31
    },
    {
        "x": 60.53,
        "y": 28.31
    },
    {
        "x": 60.54,
        "y": 28.31
    },
    {
        "x": 60.55,
        "y": 28.31
    },
    {
        "x": 60.56,
        "y": 28.31
    },
    {
        "x": 60.57,
        "y": 28.31
    },
    {
        "x": 60.58,
        "y": 28.31
    },
    {
        "x": 60.59,
        "y": 28.31
    },
    {
        "x": 60.6,
        "y": 28.31
    },
    {
        "x": 60.61,
        "y": 28.31
    },
    {
        "x": 60.62,
        "y": 28.31
    },
    {
        "x": 60.63,
        "y": 28.31
    },
    {
        "x": 60.64,
        "y": 28.31
    },
    {
        "x": 60.65,
        "y": 28.31
    },
    {
        "x": 60.66,
        "y": 28.31
    },
    {
        "x": 60.67,
        "y": 28.31
    },
    {
        "x": 60.68,
        "y": 28.31
    },
    {
        "x": 60.69,
        "y": 28.31
    },
    {
        "x": 60.7,
        "y": 28.31
    },
    {
        "x": 60.71,
        "y": 28.31
    },
    {
        "x": 60.72,
        "y": 28.31
    },
    {
        "x": 60.73,
        "y": 28.31
    },
    {
        "x": 60.74,
        "y": 28.31
    },
    {
        "x": 60.75,
        "y": 28.31
    },
    {
        "x": 60.76,
        "y": 28.31
    },
    {
        "x": 60.77,
        "y": 28.31
    },
    {
        "x": 60.78,
        "y": 28.31
    },
    {
        "x": 60.79,
        "y": 28.31
    },
    {
        "x": 60.8,
        "y": 28.31
    },
    {
        "x": 60.81,
        "y": 28.31
    },
    {
        "x": 60.82,
        "y": 28.31
    },
    {
        "x": 60.83,
        "y": 28.31
    },
    {
        "x": 60.84,
        "y": 28.3
    },
    {
        "x": 60.85,
        "y": 28.31
    },
    {
        "x": 60.86,
        "y": 28.3
    },
    {
        "x": 60.87,
        "y": 28.31
    },
    {
        "x": 60.88,
        "y": 28.31
    },
    {
        "x": 60.89,
        "y": 28.31
    },
    {
        "x": 60.9,
        "y": 28.31
    },
    {
        "x": 60.91,
        "y": 28.31
    },
    {
        "x": 60.92,
        "y": 28.3
    },
    {
        "x": 60.93,
        "y": 28.31
    },
    {
        "x": 60.94,
        "y": 28.31
    },
    {
        "x": 60.95,
        "y": 28.31
    },
    {
        "x": 60.96,
        "y": 28.31
    },
    {
        "x": 60.97,
        "y": 28.31
    },
    {
        "x": 60.98,
        "y": 28.31
    },
    {
        "x": 60.99,
        "y": 28.31
    },
    {
        "x": 61,
        "y": 28.31
    },
    {
        "x": 61.01,
        "y": 28.31
    },
    {
        "x": 61.02,
        "y": 28.31
    },
    {
        "x": 61.03,
        "y": 28.31
    },
    {
        "x": 61.04,
        "y": 28.31
    },
    {
        "x": 61.05,
        "y": 28.31
    },
    {
        "x": 61.06,
        "y": 28.31
    },
    {
        "x": 61.07,
        "y": 28.31
    },
    {
        "x": 61.08,
        "y": 28.31
    },
    {
        "x": 61.09,
        "y": 28.31
    },
    {
        "x": 61.1,
        "y": 28.31
    },
    {
        "x": 61.11,
        "y": 28.31
    },
    {
        "x": 61.12,
        "y": 28.31
    },
    {
        "x": 61.13,
        "y": 28.31
    },
    {
        "x": 61.14,
        "y": 28.31
    },
    {
        "x": 61.15,
        "y": 28.31
    },
    {
        "x": 61.16,
        "y": 28.31
    },
    {
        "x": 61.17,
        "y": 28.31
    },
    {
        "x": 61.18,
        "y": 28.31
    },
    {
        "x": 61.19,
        "y": 28.31
    },
    {
        "x": 61.2,
        "y": 28.31
    },
    {
        "x": 61.21,
        "y": 28.31
    },
    {
        "x": 61.22,
        "y": 28.31
    },
    {
        "x": 61.23,
        "y": 28.31
    },
    {
        "x": 61.24,
        "y": 28.31
    },
    {
        "x": 61.25,
        "y": 28.31
    },
    {
        "x": 61.26,
        "y": 28.31
    },
    {
        "x": 61.27,
        "y": 28.31
    },
    {
        "x": 61.28,
        "y": 28.31
    },
    {
        "x": 61.29,
        "y": 28.31
    },
    {
        "x": 61.3,
        "y": 28.31
    },
    {
        "x": 61.31,
        "y": 28.31
    },
    {
        "x": 61.32,
        "y": 28.31
    },
    {
        "x": 61.33,
        "y": 28.31
    },
    {
        "x": 61.34,
        "y": 28.31
    },
    {
        "x": 61.35,
        "y": 28.31
    },
    {
        "x": 61.36,
        "y": 28.31
    },
    {
        "x": 61.37,
        "y": 28.31
    },
    {
        "x": 61.38,
        "y": 28.31
    },
    {
        "x": 61.39,
        "y": 28.31
    },
    {
        "x": 61.4,
        "y": 28.31
    },
    {
        "x": 61.41,
        "y": 28.31
    },
    {
        "x": 61.42,
        "y": 28.31
    },
    {
        "x": 61.43,
        "y": 28.31
    },
    {
        "x": 61.44,
        "y": 28.31
    },
    {
        "x": 61.45,
        "y": 28.31
    },
    {
        "x": 61.46,
        "y": 28.31
    },
    {
        "x": 61.47,
        "y": 28.31
    },
    {
        "x": 61.48,
        "y": 28.31
    },
    {
        "x": 61.49,
        "y": 28.31
    },
    {
        "x": 61.5,
        "y": 28.31
    },
    {
        "x": 61.51,
        "y": 28.31
    },
    {
        "x": 61.52,
        "y": 28.31
    },
    {
        "x": 61.53,
        "y": 28.31
    },
    {
        "x": 61.54,
        "y": 28.31
    },
    {
        "x": 61.55,
        "y": 28.31
    },
    {
        "x": 61.56,
        "y": 28.3
    },
    {
        "x": 61.57,
        "y": 28.31
    },
    {
        "x": 61.58,
        "y": 28.31
    },
    {
        "x": 61.59,
        "y": 28.31
    },
    {
        "x": 61.6,
        "y": 28.3
    },
    {
        "x": 61.61,
        "y": 28.31
    },
    {
        "x": 61.62,
        "y": 28.31
    },
    {
        "x": 61.63,
        "y": 28.31
    },
    {
        "x": 61.64,
        "y": 28.31
    },
    {
        "x": 61.65,
        "y": 28.31
    },
    {
        "x": 61.66,
        "y": 28.31
    },
    {
        "x": 61.67,
        "y": 28.31
    },
    {
        "x": 61.68,
        "y": 28.3
    },
    {
        "x": 61.69,
        "y": 28.3
    },
    {
        "x": 61.7,
        "y": 28.31
    },
    {
        "x": 61.71,
        "y": 28.31
    },
    {
        "x": 61.72,
        "y": 28.31
    },
    {
        "x": 61.73,
        "y": 28.31
    },
    {
        "x": 61.74,
        "y": 28.31
    },
    {
        "x": 61.75,
        "y": 28.31
    },
    {
        "x": 61.76,
        "y": 28.31
    },
    {
        "x": 61.77,
        "y": 28.31
    },
    {
        "x": 61.78,
        "y": 28.31
    },
    {
        "x": 61.79,
        "y": 28.31
    },
    {
        "x": 61.8,
        "y": 28.31
    },
    {
        "x": 61.81,
        "y": 28.31
    },
    {
        "x": 61.82,
        "y": 28.31
    },
    {
        "x": 61.83,
        "y": 28.31
    },
    {
        "x": 61.84,
        "y": 28.31
    },
    {
        "x": 61.85,
        "y": 28.31
    },
    {
        "x": 61.86,
        "y": 28.31
    },
    {
        "x": 61.87,
        "y": 28.31
    },
    {
        "x": 61.88,
        "y": 28.31
    },
    {
        "x": 61.89,
        "y": 28.31
    },
    {
        "x": 61.9,
        "y": 28.31
    },
    {
        "x": 61.91,
        "y": 28.31
    },
    {
        "x": 61.92,
        "y": 28.31
    },
    {
        "x": 61.93,
        "y": 28.31
    },
    {
        "x": 61.94,
        "y": 28.31
    },
    {
        "x": 61.95,
        "y": 28.31
    },
    {
        "x": 61.96,
        "y": 28.31
    },
    {
        "x": 61.97,
        "y": 28.31
    },
    {
        "x": 61.98,
        "y": 28.3
    },
    {
        "x": 61.99,
        "y": 28.31
    },
    {
        "x": 62,
        "y": 28.31
    },
    {
        "x": 62.01,
        "y": 28.31
    },
    {
        "x": 62.02,
        "y": 28.3
    },
    {
        "x": 62.03,
        "y": 28.31
    },
    {
        "x": 62.04,
        "y": 28.31
    },
    {
        "x": 62.05,
        "y": 28.31
    },
    {
        "x": 62.06,
        "y": 28.31
    },
    {
        "x": 62.07,
        "y": 28.31
    },
    {
        "x": 62.08,
        "y": 28.31
    },
    {
        "x": 62.09,
        "y": 28.3
    },
    {
        "x": 62.1,
        "y": 28.3
    },
    {
        "x": 62.11,
        "y": 28.31
    },
    {
        "x": 62.12,
        "y": 28.3
    },
    {
        "x": 62.13,
        "y": 28.31
    },
    {
        "x": 62.14,
        "y": 28.31
    },
    {
        "x": 62.15,
        "y": 28.3
    },
    {
        "x": 62.16,
        "y": 28.3
    },
    {
        "x": 62.17,
        "y": 28.31
    },
    {
        "x": 62.18,
        "y": 28.3
    },
    {
        "x": 62.19,
        "y": 28.3
    },
    {
        "x": 62.2,
        "y": 28.3
    },
    {
        "x": 62.21,
        "y": 28.3
    },
    {
        "x": 62.22,
        "y": 28.3
    },
    {
        "x": 62.23,
        "y": 28.3
    },
    {
        "x": 62.24,
        "y": 28.3
    },
    {
        "x": 62.25,
        "y": 28.3
    },
    {
        "x": 62.26,
        "y": 28.3
    },
    {
        "x": 62.27,
        "y": 28.3
    },
    {
        "x": 62.28,
        "y": 28.3
    },
    {
        "x": 62.29,
        "y": 28.3
    },
    {
        "x": 62.3,
        "y": 28.3
    },
    {
        "x": 62.31,
        "y": 28.3
    },
    {
        "x": 62.32,
        "y": 28.3
    },
    {
        "x": 62.33,
        "y": 28.3
    },
    {
        "x": 62.34,
        "y": 28.3
    },
    {
        "x": 62.35,
        "y": 28.3
    },
    {
        "x": 62.36,
        "y": 28.3
    },
    {
        "x": 62.37,
        "y": 28.31
    },
    {
        "x": 62.38,
        "y": 28.3
    },
    {
        "x": 62.39,
        "y": 28.3
    },
    {
        "x": 62.4,
        "y": 28.3
    },
    {
        "x": 62.41,
        "y": 28.3
    },
    {
        "x": 62.42,
        "y": 28.31
    },
    {
        "x": 62.43,
        "y": 28.3
    },
    {
        "x": 62.44,
        "y": 28.3
    },
    {
        "x": 62.45,
        "y": 28.3
    },
    {
        "x": 62.46,
        "y": 28.3
    },
    {
        "x": 62.47,
        "y": 28.3
    },
    {
        "x": 62.48,
        "y": 28.3
    },
    {
        "x": 62.49,
        "y": 28.3
    },
    {
        "x": 62.5,
        "y": 28.3
    },
    {
        "x": 62.51,
        "y": 28.3
    },
    {
        "x": 62.52,
        "y": 28.3
    },
    {
        "x": 62.53,
        "y": 28.3
    },
    {
        "x": 62.54,
        "y": 28.3
    },
    {
        "x": 62.55,
        "y": 28.3
    },
    {
        "x": 62.56,
        "y": 28.3
    },
    {
        "x": 62.57,
        "y": 28.3
    },
    {
        "x": 62.58,
        "y": 28.3
    },
    {
        "x": 62.59,
        "y": 28.3
    },
    {
        "x": 62.6,
        "y": 28.3
    },
    {
        "x": 62.61,
        "y": 28.3
    },
    {
        "x": 62.62,
        "y": 28.3
    },
    {
        "x": 62.63,
        "y": 28.3
    },
    {
        "x": 62.64,
        "y": 28.3
    },
    {
        "x": 62.65,
        "y": 28.3
    },
    {
        "x": 62.66,
        "y": 28.3
    },
    {
        "x": 62.67,
        "y": 28.3
    },
    {
        "x": 62.68,
        "y": 28.3
    },
    {
        "x": 62.69,
        "y": 28.3
    },
    {
        "x": 62.7,
        "y": 28.3
    },
    {
        "x": 62.71,
        "y": 28.3
    },
    {
        "x": 62.72,
        "y": 28.3
    },
    {
        "x": 62.73,
        "y": 28.3
    },
    {
        "x": 62.74,
        "y": 28.3
    },
    {
        "x": 62.75,
        "y": 28.3
    },
    {
        "x": 62.76,
        "y": 28.3
    },
    {
        "x": 62.77,
        "y": 28.3
    },
    {
        "x": 62.78,
        "y": 28.3
    },
    {
        "x": 62.79,
        "y": 28.3
    },
    {
        "x": 62.8,
        "y": 28.3
    },
    {
        "x": 62.81,
        "y": 28.3
    },
    {
        "x": 62.82,
        "y": 28.3
    },
    {
        "x": 62.83,
        "y": 28.3
    },
    {
        "x": 62.84,
        "y": 28.3
    },
    {
        "x": 62.85,
        "y": 28.3
    },
    {
        "x": 62.86,
        "y": 28.3
    },
    {
        "x": 62.87,
        "y": 28.3
    },
    {
        "x": 62.88,
        "y": 28.3
    },
    {
        "x": 62.89,
        "y": 28.3
    },
    {
        "x": 62.9,
        "y": 28.3
    },
    {
        "x": 62.91,
        "y": 28.3
    },
    {
        "x": 62.92,
        "y": 28.3
    },
    {
        "x": 62.93,
        "y": 28.3
    },
    {
        "x": 62.94,
        "y": 28.3
    },
    {
        "x": 62.95,
        "y": 28.3
    },
    {
        "x": 62.96,
        "y": 28.3
    },
    {
        "x": 62.97,
        "y": 28.3
    },
    {
        "x": 62.98,
        "y": 28.3
    },
    {
        "x": 62.99,
        "y": 28.3
    },
    {
        "x": 63,
        "y": 28.3
    },
    {
        "x": 63.01,
        "y": 28.3
    },
    {
        "x": 63.02,
        "y": 28.3
    },
    {
        "x": 63.03,
        "y": 28.3
    },
    {
        "x": 63.04,
        "y": 28.3
    },
    {
        "x": 63.05,
        "y": 28.3
    },
    {
        "x": 63.06,
        "y": 28.3
    },
    {
        "x": 63.07,
        "y": 28.3
    },
    {
        "x": 63.08,
        "y": 28.3
    },
    {
        "x": 63.09,
        "y": 28.3
    },
    {
        "x": 63.1,
        "y": 28.3
    },
    {
        "x": 63.11,
        "y": 28.3
    },
    {
        "x": 63.12,
        "y": 28.3
    },
    {
        "x": 63.13,
        "y": 28.3
    },
    {
        "x": 63.14,
        "y": 28.3
    },
    {
        "x": 63.15,
        "y": 28.3
    },
    {
        "x": 63.16,
        "y": 28.3
    },
    {
        "x": 63.17,
        "y": 28.3
    },
    {
        "x": 63.18,
        "y": 28.3
    },
    {
        "x": 63.19,
        "y": 28.3
    },
    {
        "x": 63.2,
        "y": 28.3
    },
    {
        "x": 63.21,
        "y": 28.3
    },
    {
        "x": 63.22,
        "y": 28.3
    },
    {
        "x": 63.23,
        "y": 28.3
    },
    {
        "x": 63.24,
        "y": 28.3
    },
    {
        "x": 63.25,
        "y": 28.3
    },
    {
        "x": 63.26,
        "y": 28.3
    },
    {
        "x": 63.27,
        "y": 28.3
    },
    {
        "x": 63.28,
        "y": 28.3
    },
    {
        "x": 63.29,
        "y": 28.3
    },
    {
        "x": 63.3,
        "y": 28.3
    },
    {
        "x": 63.31,
        "y": 28.3
    },
    {
        "x": 63.32,
        "y": 28.3
    },
    {
        "x": 63.33,
        "y": 28.3
    },
    {
        "x": 63.34,
        "y": 28.3
    },
    {
        "x": 63.35,
        "y": 28.3
    },
    {
        "x": 63.36,
        "y": 28.3
    },
    {
        "x": 63.37,
        "y": 28.3
    },
    {
        "x": 63.38,
        "y": 28.3
    },
    {
        "x": 63.39,
        "y": 28.3
    },
    {
        "x": 63.4,
        "y": 28.3
    },
    {
        "x": 63.41,
        "y": 28.3
    },
    {
        "x": 63.42,
        "y": 28.3
    },
    {
        "x": 63.43,
        "y": 28.3
    },
    {
        "x": 63.44,
        "y": 28.3
    },
    {
        "x": 63.45,
        "y": 28.3
    },
    {
        "x": 63.46,
        "y": 28.3
    },
    {
        "x": 63.47,
        "y": 28.3
    },
    {
        "x": 63.48,
        "y": 28.3
    },
    {
        "x": 63.49,
        "y": 28.3
    },
    {
        "x": 63.5,
        "y": 28.3
    },
    {
        "x": 63.51,
        "y": 28.29
    },
    {
        "x": 63.52,
        "y": 28.3
    },
    {
        "x": 63.53,
        "y": 28.3
    },
    {
        "x": 63.54,
        "y": 28.3
    },
    {
        "x": 63.55,
        "y": 28.29
    },
    {
        "x": 63.56,
        "y": 28.3
    },
    {
        "x": 63.57,
        "y": 28.3
    },
    {
        "x": 63.58,
        "y": 28.3
    },
    {
        "x": 63.59,
        "y": 28.3
    },
    {
        "x": 63.6,
        "y": 28.3
    },
    {
        "x": 63.61,
        "y": 28.3
    },
    {
        "x": 63.62,
        "y": 28.3
    },
    {
        "x": 63.63,
        "y": 28.3
    },
    {
        "x": 63.64,
        "y": 28.3
    },
    {
        "x": 63.65,
        "y": 28.3
    },
    {
        "x": 63.66,
        "y": 28.3
    },
    {
        "x": 63.67,
        "y": 28.3
    },
    {
        "x": 63.68,
        "y": 28.3
    },
    {
        "x": 63.69,
        "y": 28.3
    },
    {
        "x": 63.7,
        "y": 28.3
    },
    {
        "x": 63.71,
        "y": 28.3
    },
    {
        "x": 63.72,
        "y": 28.3
    },
    {
        "x": 63.73,
        "y": 28.29
    },
    {
        "x": 63.74,
        "y": 28.3
    },
    {
        "x": 63.75,
        "y": 28.3
    },
    {
        "x": 63.76,
        "y": 28.29
    },
    {
        "x": 63.77,
        "y": 28.29
    },
    {
        "x": 63.78,
        "y": 28.29
    },
    {
        "x": 63.79,
        "y": 28.3
    },
    {
        "x": 63.8,
        "y": 28.3
    },
    {
        "x": 63.81,
        "y": 28.29
    },
    {
        "x": 63.82,
        "y": 28.3
    },
    {
        "x": 63.83,
        "y": 28.3
    },
    {
        "x": 63.84,
        "y": 28.3
    },
    {
        "x": 63.85,
        "y": 28.29
    },
    {
        "x": 63.86,
        "y": 28.29
    },
    {
        "x": 63.87,
        "y": 28.3
    },
    {
        "x": 63.88,
        "y": 28.3
    },
    {
        "x": 63.89,
        "y": 28.3
    },
    {
        "x": 63.9,
        "y": 28.3
    },
    {
        "x": 63.91,
        "y": 28.29
    },
    {
        "x": 63.92,
        "y": 28.3
    },
    {
        "x": 63.93,
        "y": 28.29
    },
    {
        "x": 63.94,
        "y": 28.29
    },
    {
        "x": 63.95,
        "y": 28.29
    },
    {
        "x": 63.96,
        "y": 28.29
    },
    {
        "x": 63.97,
        "y": 28.3
    },
    {
        "x": 63.98,
        "y": 28.3
    },
    {
        "x": 63.99,
        "y": 28.3
    },
    {
        "x": 64,
        "y": 28.29
    },
    {
        "x": 64.01,
        "y": 28.29
    },
    {
        "x": 64.02,
        "y": 28.3
    },
    {
        "x": 64.03,
        "y": 28.29
    },
    {
        "x": 64.04,
        "y": 28.29
    },
    {
        "x": 64.05,
        "y": 28.29
    },
    {
        "x": 64.06,
        "y": 28.29
    },
    {
        "x": 64.07,
        "y": 28.29
    },
    {
        "x": 64.08,
        "y": 28.3
    },
    {
        "x": 64.09,
        "y": 28.29
    },
    {
        "x": 64.1,
        "y": 28.3
    },
    {
        "x": 64.11,
        "y": 28.29
    },
    {
        "x": 64.12,
        "y": 28.3
    },
    {
        "x": 64.13,
        "y": 28.3
    },
    {
        "x": 64.14,
        "y": 28.3
    },
    {
        "x": 64.15,
        "y": 28.3
    },
    {
        "x": 64.16,
        "y": 28.29
    },
    {
        "x": 64.17,
        "y": 28.29
    },
    {
        "x": 64.18,
        "y": 28.3
    },
    {
        "x": 64.19,
        "y": 28.3
    },
    {
        "x": 64.2,
        "y": 28.3
    },
    {
        "x": 64.21,
        "y": 28.29
    },
    {
        "x": 64.22,
        "y": 28.3
    },
    {
        "x": 64.23,
        "y": 28.3
    },
    {
        "x": 64.24,
        "y": 28.3
    },
    {
        "x": 64.25,
        "y": 28.3
    },
    {
        "x": 64.26,
        "y": 28.3
    },
    {
        "x": 64.27,
        "y": 28.3
    },
    {
        "x": 64.28,
        "y": 28.3
    },
    {
        "x": 64.29,
        "y": 28.29
    },
    {
        "x": 64.3,
        "y": 28.3
    },
    {
        "x": 64.31,
        "y": 28.29
    },
    {
        "x": 64.32,
        "y": 28.29
    },
    {
        "x": 64.33,
        "y": 28.29
    },
    {
        "x": 64.34,
        "y": 28.29
    },
    {
        "x": 64.35,
        "y": 28.29
    },
    {
        "x": 64.36,
        "y": 28.29
    },
    {
        "x": 64.37,
        "y": 28.29
    },
    {
        "x": 64.38,
        "y": 28.29
    },
    {
        "x": 64.39,
        "y": 28.29
    },
    {
        "x": 64.4,
        "y": 28.29
    },
    {
        "x": 64.41,
        "y": 28.29
    },
    {
        "x": 64.42,
        "y": 28.29
    },
    {
        "x": 64.43,
        "y": 28.29
    },
    {
        "x": 64.44,
        "y": 28.29
    },
    {
        "x": 64.45,
        "y": 28.29
    },
    {
        "x": 64.46,
        "y": 28.29
    },
    {
        "x": 64.47,
        "y": 28.29
    },
    {
        "x": 64.48,
        "y": 28.29
    },
    {
        "x": 64.49,
        "y": 28.29
    },
    {
        "x": 64.5,
        "y": 28.29
    },
    {
        "x": 64.51,
        "y": 28.29
    },
    {
        "x": 64.52,
        "y": 28.29
    },
    {
        "x": 64.53,
        "y": 28.29
    },
    {
        "x": 64.54,
        "y": 28.29
    },
    {
        "x": 64.55,
        "y": 28.29
    },
    {
        "x": 64.56,
        "y": 28.29
    },
    {
        "x": 64.57,
        "y": 28.29
    },
    {
        "x": 64.58,
        "y": 28.29
    },
    {
        "x": 64.59,
        "y": 28.29
    },
    {
        "x": 64.6,
        "y": 28.29
    },
    {
        "x": 64.61,
        "y": 28.29
    },
    {
        "x": 64.62,
        "y": 28.29
    },
    {
        "x": 64.63,
        "y": 28.29
    },
    {
        "x": 64.64,
        "y": 28.29
    },
    {
        "x": 64.65,
        "y": 28.29
    },
    {
        "x": 64.66,
        "y": 28.3
    },
    {
        "x": 64.67,
        "y": 28.29
    },
    {
        "x": 64.68,
        "y": 28.29
    },
    {
        "x": 64.69,
        "y": 28.29
    },
    {
        "x": 64.7,
        "y": 28.29
    },
    {
        "x": 64.71,
        "y": 28.29
    },
    {
        "x": 64.72,
        "y": 28.29
    },
    {
        "x": 64.73,
        "y": 28.29
    },
    {
        "x": 64.74,
        "y": 28.29
    },
    {
        "x": 64.75,
        "y": 28.29
    },
    {
        "x": 64.76,
        "y": 28.29
    },
    {
        "x": 64.77,
        "y": 28.29
    },
    {
        "x": 64.78,
        "y": 28.29
    },
    {
        "x": 64.79,
        "y": 28.29
    },
    {
        "x": 64.8,
        "y": 28.29
    },
    {
        "x": 64.81,
        "y": 28.29
    },
    {
        "x": 64.82,
        "y": 28.29
    },
    {
        "x": 64.83,
        "y": 28.29
    },
    {
        "x": 64.84,
        "y": 28.29
    },
    {
        "x": 64.85,
        "y": 28.29
    },
    {
        "x": 64.86,
        "y": 28.29
    },
    {
        "x": 64.87,
        "y": 28.29
    },
    {
        "x": 64.88,
        "y": 28.29
    },
    {
        "x": 64.89,
        "y": 28.29
    },
    {
        "x": 64.9,
        "y": 28.29
    },
    {
        "x": 64.91,
        "y": 28.29
    },
    {
        "x": 64.92,
        "y": 28.29
    },
    {
        "x": 64.93,
        "y": 28.29
    },
    {
        "x": 64.94,
        "y": 28.29
    },
    {
        "x": 64.95,
        "y": 28.29
    },
    {
        "x": 64.96,
        "y": 28.29
    },
    {
        "x": 64.97,
        "y": 28.29
    },
    {
        "x": 64.98,
        "y": 28.29
    },
    {
        "x": 64.99,
        "y": 28.29
    },
    {
        "x": 65,
        "y": 28.29
    },
    {
        "x": 65.01,
        "y": 28.29
    },
    {
        "x": 65.02,
        "y": 28.29
    },
    {
        "x": 65.03,
        "y": 28.29
    },
    {
        "x": 65.04,
        "y": 28.29
    },
    {
        "x": 65.05,
        "y": 28.29
    },
    {
        "x": 65.06,
        "y": 28.29
    },
    {
        "x": 65.07,
        "y": 28.29
    },
    {
        "x": 65.08,
        "y": 28.29
    },
    {
        "x": 65.09,
        "y": 28.29
    },
    {
        "x": 65.1,
        "y": 28.29
    },
    {
        "x": 65.11,
        "y": 28.29
    },
    {
        "x": 65.12,
        "y": 28.29
    },
    {
        "x": 65.13,
        "y": 28.28
    },
    {
        "x": 65.14,
        "y": 28.29
    },
    {
        "x": 65.15,
        "y": 28.29
    },
    {
        "x": 65.16,
        "y": 28.29
    },
    {
        "x": 65.17,
        "y": 28.29
    },
    {
        "x": 65.18,
        "y": 28.29
    },
    {
        "x": 65.19,
        "y": 28.29
    },
    {
        "x": 65.2,
        "y": 28.29
    },
    {
        "x": 65.21,
        "y": 28.29
    },
    {
        "x": 65.22,
        "y": 28.29
    },
    {
        "x": 65.23,
        "y": 28.29
    },
    {
        "x": 65.24,
        "y": 28.29
    },
    {
        "x": 65.25,
        "y": 28.29
    },
    {
        "x": 65.26,
        "y": 28.29
    },
    {
        "x": 65.27,
        "y": 28.29
    },
    {
        "x": 65.28,
        "y": 28.29
    },
    {
        "x": 65.29,
        "y": 28.29
    },
    {
        "x": 65.3,
        "y": 28.29
    },
    {
        "x": 65.31,
        "y": 28.29
    },
    {
        "x": 65.32,
        "y": 28.29
    },
    {
        "x": 65.33,
        "y": 28.29
    },
    {
        "x": 65.34,
        "y": 28.29
    },
    {
        "x": 65.35,
        "y": 28.29
    },
    {
        "x": 65.36,
        "y": 28.29
    },
    {
        "x": 65.37,
        "y": 28.29
    },
    {
        "x": 65.38,
        "y": 28.29
    },
    {
        "x": 65.39,
        "y": 28.29
    },
    {
        "x": 65.4,
        "y": 28.29
    },
    {
        "x": 65.41,
        "y": 28.29
    },
    {
        "x": 65.42,
        "y": 28.29
    },
    {
        "x": 65.43,
        "y": 28.29
    },
    {
        "x": 65.44,
        "y": 28.29
    },
    {
        "x": 65.45,
        "y": 28.29
    },
    {
        "x": 65.46,
        "y": 28.28
    },
    {
        "x": 65.47,
        "y": 28.29
    },
    {
        "x": 65.48,
        "y": 28.29
    },
    {
        "x": 65.49,
        "y": 28.29
    },
    {
        "x": 65.5,
        "y": 28.29
    },
    {
        "x": 65.51,
        "y": 28.29
    },
    {
        "x": 65.52,
        "y": 28.29
    },
    {
        "x": 65.53,
        "y": 28.29
    },
    {
        "x": 65.54,
        "y": 28.29
    },
    {
        "x": 65.55,
        "y": 28.29
    },
    {
        "x": 65.56,
        "y": 28.29
    },
    {
        "x": 65.57,
        "y": 28.29
    },
    {
        "x": 65.58,
        "y": 28.29
    },
    {
        "x": 65.59,
        "y": 28.29
    },
    {
        "x": 65.6,
        "y": 28.29
    },
    {
        "x": 65.61,
        "y": 28.29
    },
    {
        "x": 65.62,
        "y": 28.29
    },
    {
        "x": 65.63,
        "y": 28.29
    },
    {
        "x": 65.64,
        "y": 28.29
    },
    {
        "x": 65.65,
        "y": 28.29
    },
    {
        "x": 65.66,
        "y": 28.29
    },
    {
        "x": 65.67,
        "y": 28.29
    },
    {
        "x": 65.68,
        "y": 28.29
    },
    {
        "x": 65.69,
        "y": 28.29
    },
    {
        "x": 65.7,
        "y": 28.29
    },
    {
        "x": 65.71,
        "y": 28.29
    },
    {
        "x": 65.72,
        "y": 28.28
    },
    {
        "x": 65.73,
        "y": 28.29
    },
    {
        "x": 65.74,
        "y": 28.29
    },
    {
        "x": 65.75,
        "y": 28.29
    },
    {
        "x": 65.76,
        "y": 28.28
    },
    {
        "x": 65.77,
        "y": 28.29
    },
    {
        "x": 65.78,
        "y": 28.29
    },
    {
        "x": 65.79,
        "y": 28.29
    },
    {
        "x": 65.8,
        "y": 28.29
    },
    {
        "x": 65.81,
        "y": 28.28
    },
    {
        "x": 65.82,
        "y": 28.28
    },
    {
        "x": 65.83,
        "y": 28.29
    },
    {
        "x": 65.84,
        "y": 28.29
    },
    {
        "x": 65.85,
        "y": 28.29
    },
    {
        "x": 65.86,
        "y": 28.29
    },
    {
        "x": 65.87,
        "y": 28.28
    },
    {
        "x": 65.88,
        "y": 28.28
    },
    {
        "x": 65.89,
        "y": 28.29
    },
    {
        "x": 65.9,
        "y": 28.29
    },
    {
        "x": 65.91,
        "y": 28.28
    },
    {
        "x": 65.92,
        "y": 28.28
    },
    {
        "x": 65.93,
        "y": 28.29
    },
    {
        "x": 65.94,
        "y": 28.29
    },
    {
        "x": 65.95,
        "y": 28.29
    },
    {
        "x": 65.96,
        "y": 28.28
    },
    {
        "x": 65.97,
        "y": 28.29
    },
    {
        "x": 65.98,
        "y": 28.29
    },
    {
        "x": 65.99,
        "y": 28.28
    },
    {
        "x": 66,
        "y": 28.28
    },
    {
        "x": 66.01,
        "y": 28.29
    },
    {
        "x": 66.02,
        "y": 28.29
    },
    {
        "x": 66.03,
        "y": 28.29
    },
    {
        "x": 66.04,
        "y": 28.29
    },
    {
        "x": 66.05,
        "y": 28.29
    },
    {
        "x": 66.06,
        "y": 28.29
    },
    {
        "x": 66.07,
        "y": 28.28
    },
    {
        "x": 66.08,
        "y": 28.29
    },
    {
        "x": 66.09,
        "y": 28.29
    },
    {
        "x": 66.1,
        "y": 28.28
    },
    {
        "x": 66.11,
        "y": 28.28
    },
    {
        "x": 66.12,
        "y": 28.28
    },
    {
        "x": 66.13,
        "y": 28.28
    },
    {
        "x": 66.14,
        "y": 28.28
    },
    {
        "x": 66.15,
        "y": 28.28
    },
    {
        "x": 66.16,
        "y": 28.28
    },
    {
        "x": 66.17,
        "y": 28.28
    },
    {
        "x": 66.18,
        "y": 28.28
    },
    {
        "x": 66.19,
        "y": 28.28
    },
    {
        "x": 66.2,
        "y": 28.28
    },
    {
        "x": 66.21,
        "y": 28.28
    },
    {
        "x": 66.22,
        "y": 28.28
    },
    {
        "x": 66.23,
        "y": 28.28
    },
    {
        "x": 66.24,
        "y": 28.28
    },
    {
        "x": 66.25,
        "y": 28.28
    },
    {
        "x": 66.26,
        "y": 28.28
    },
    {
        "x": 66.27,
        "y": 28.28
    },
    {
        "x": 66.28,
        "y": 28.28
    },
    {
        "x": 66.29,
        "y": 28.28
    },
    {
        "x": 66.3,
        "y": 28.28
    },
    {
        "x": 66.31,
        "y": 28.28
    },
    {
        "x": 66.32,
        "y": 28.28
    },
    {
        "x": 66.33,
        "y": 28.28
    },
    {
        "x": 66.34,
        "y": 28.28
    },
    {
        "x": 66.35,
        "y": 28.28
    },
    {
        "x": 66.36,
        "y": 28.28
    },
    {
        "x": 66.37,
        "y": 28.28
    },
    {
        "x": 66.38,
        "y": 28.28
    },
    {
        "x": 66.39,
        "y": 28.28
    },
    {
        "x": 66.4,
        "y": 28.28
    },
    {
        "x": 66.41,
        "y": 28.28
    },
    {
        "x": 66.42,
        "y": 28.28
    },
    {
        "x": 66.43,
        "y": 28.28
    },
    {
        "x": 66.44,
        "y": 28.28
    },
    {
        "x": 66.45,
        "y": 28.28
    },
    {
        "x": 66.46,
        "y": 28.28
    },
    {
        "x": 66.47,
        "y": 28.28
    },
    {
        "x": 66.48,
        "y": 28.28
    },
    {
        "x": 66.49,
        "y": 28.28
    },
    {
        "x": 66.5,
        "y": 28.28
    },
    {
        "x": 66.51,
        "y": 28.28
    },
    {
        "x": 66.52,
        "y": 28.28
    },
    {
        "x": 66.53,
        "y": 28.28
    },
    {
        "x": 66.54,
        "y": 28.28
    },
    {
        "x": 66.55,
        "y": 28.28
    },
    {
        "x": 66.56,
        "y": 28.28
    },
    {
        "x": 66.57,
        "y": 28.28
    },
    {
        "x": 66.58,
        "y": 28.28
    },
    {
        "x": 66.59,
        "y": 28.28
    },
    {
        "x": 66.6,
        "y": 28.28
    },
    {
        "x": 66.61,
        "y": 28.28
    },
    {
        "x": 66.62,
        "y": 28.28
    },
    {
        "x": 66.63,
        "y": 28.28
    },
    {
        "x": 66.64,
        "y": 28.28
    },
    {
        "x": 66.65,
        "y": 28.28
    },
    {
        "x": 66.66,
        "y": 28.28
    },
    {
        "x": 66.67,
        "y": 28.28
    },
    {
        "x": 66.68,
        "y": 28.28
    },
    {
        "x": 66.69,
        "y": 28.28
    },
    {
        "x": 66.7,
        "y": 28.28
    },
    {
        "x": 66.71,
        "y": 28.28
    },
    {
        "x": 66.72,
        "y": 28.28
    },
    {
        "x": 66.73,
        "y": 28.28
    },
    {
        "x": 66.74,
        "y": 28.28
    },
    {
        "x": 66.75,
        "y": 28.28
    },
    {
        "x": 66.76,
        "y": 28.28
    },
    {
        "x": 66.77,
        "y": 28.28
    },
    {
        "x": 66.78,
        "y": 28.28
    },
    {
        "x": 66.79,
        "y": 28.28
    },
    {
        "x": 66.8,
        "y": 28.28
    },
    {
        "x": 66.81,
        "y": 28.28
    },
    {
        "x": 66.82,
        "y": 28.28
    },
    {
        "x": 66.83,
        "y": 28.28
    },
    {
        "x": 66.84,
        "y": 28.28
    },
    {
        "x": 66.85,
        "y": 28.28
    },
    {
        "x": 66.86,
        "y": 28.28
    },
    {
        "x": 66.87,
        "y": 28.28
    },
    {
        "x": 66.88,
        "y": 28.28
    },
    {
        "x": 66.89,
        "y": 28.28
    },
    {
        "x": 66.9,
        "y": 28.28
    },
    {
        "x": 66.91,
        "y": 28.28
    },
    {
        "x": 66.92,
        "y": 28.28
    },
    {
        "x": 66.93,
        "y": 28.28
    },
    {
        "x": 66.94,
        "y": 28.28
    },
    {
        "x": 66.95,
        "y": 28.28
    },
    {
        "x": 66.96,
        "y": 28.28
    },
    {
        "x": 66.97,
        "y": 28.28
    },
    {
        "x": 66.98,
        "y": 28.28
    },
    {
        "x": 66.99,
        "y": 28.28
    },
    {
        "x": 67,
        "y": 28.28
    },
    {
        "x": 67.01,
        "y": 28.28
    },
    {
        "x": 67.02,
        "y": 28.28
    },
    {
        "x": 67.03,
        "y": 28.28
    },
    {
        "x": 67.04,
        "y": 28.28
    },
    {
        "x": 67.05,
        "y": 28.28
    },
    {
        "x": 67.06,
        "y": 28.28
    },
    {
        "x": 67.07,
        "y": 28.28
    },
    {
        "x": 67.08,
        "y": 28.28
    },
    {
        "x": 67.09,
        "y": 28.28
    },
    {
        "x": 67.1,
        "y": 28.28
    },
    {
        "x": 67.11,
        "y": 28.28
    },
    {
        "x": 67.12,
        "y": 28.28
    },
    {
        "x": 67.13,
        "y": 28.28
    },
    {
        "x": 67.14,
        "y": 28.28
    },
    {
        "x": 67.15,
        "y": 28.28
    },
    {
        "x": 67.16,
        "y": 28.28
    },
    {
        "x": 67.17,
        "y": 28.28
    },
    {
        "x": 67.18,
        "y": 28.28
    },
    {
        "x": 67.19,
        "y": 28.28
    },
    {
        "x": 67.2,
        "y": 28.27
    },
    {
        "x": 67.21,
        "y": 28.28
    },
    {
        "x": 67.22,
        "y": 28.28
    },
    {
        "x": 67.23,
        "y": 28.28
    },
    {
        "x": 67.24,
        "y": 28.28
    },
    {
        "x": 67.25,
        "y": 28.28
    },
    {
        "x": 67.26,
        "y": 28.28
    },
    {
        "x": 67.27,
        "y": 28.28
    },
    {
        "x": 67.28,
        "y": 28.27
    },
    {
        "x": 67.29,
        "y": 28.28
    },
    {
        "x": 67.3,
        "y": 28.28
    },
    {
        "x": 67.31,
        "y": 28.28
    },
    {
        "x": 67.32,
        "y": 28.28
    },
    {
        "x": 67.33,
        "y": 28.28
    },
    {
        "x": 67.34,
        "y": 28.28
    },
    {
        "x": 67.35,
        "y": 28.27
    },
    {
        "x": 67.36,
        "y": 28.28
    },
    {
        "x": 67.37,
        "y": 28.27
    },
    {
        "x": 67.38,
        "y": 28.27
    },
    {
        "x": 67.39,
        "y": 28.28
    },
    {
        "x": 67.4,
        "y": 28.27
    },
    {
        "x": 67.41,
        "y": 28.28
    },
    {
        "x": 67.42,
        "y": 28.28
    },
    {
        "x": 67.43,
        "y": 28.28
    },
    {
        "x": 67.44,
        "y": 28.28
    },
    {
        "x": 67.45,
        "y": 28.28
    },
    {
        "x": 67.46,
        "y": 28.27
    },
    {
        "x": 67.47,
        "y": 28.28
    },
    {
        "x": 67.48,
        "y": 28.28
    },
    {
        "x": 67.49,
        "y": 28.28
    },
    {
        "x": 67.5,
        "y": 28.28
    },
    {
        "x": 67.51,
        "y": 28.28
    },
    {
        "x": 67.52,
        "y": 28.27
    },
    {
        "x": 67.53,
        "y": 28.27
    },
    {
        "x": 67.54,
        "y": 28.28
    },
    {
        "x": 67.55,
        "y": 28.27
    },
    {
        "x": 67.56,
        "y": 28.28
    },
    {
        "x": 67.57,
        "y": 28.28
    },
    {
        "x": 67.58,
        "y": 28.28
    },
    {
        "x": 67.59,
        "y": 28.28
    },
    {
        "x": 67.6,
        "y": 28.27
    },
    {
        "x": 67.61,
        "y": 28.27
    },
    {
        "x": 67.62,
        "y": 28.28
    },
    {
        "x": 67.63,
        "y": 28.27
    },
    {
        "x": 67.64,
        "y": 28.28
    },
    {
        "x": 67.65,
        "y": 28.28
    },
    {
        "x": 67.66,
        "y": 28.28
    },
    {
        "x": 67.67,
        "y": 28.27
    },
    {
        "x": 67.68,
        "y": 28.28
    },
    {
        "x": 67.69,
        "y": 28.28
    },
    {
        "x": 67.7,
        "y": 28.27
    },
    {
        "x": 67.71,
        "y": 28.28
    },
    {
        "x": 67.72,
        "y": 28.28
    },
    {
        "x": 67.73,
        "y": 28.28
    },
    {
        "x": 67.74,
        "y": 28.28
    },
    {
        "x": 67.75,
        "y": 28.27
    },
    {
        "x": 67.76,
        "y": 28.27
    },
    {
        "x": 67.77,
        "y": 28.28
    },
    {
        "x": 67.78,
        "y": 28.28
    },
    {
        "x": 67.79,
        "y": 28.28
    },
    {
        "x": 67.8,
        "y": 28.27
    },
    {
        "x": 67.81,
        "y": 28.28
    },
    {
        "x": 67.82,
        "y": 28.28
    },
    {
        "x": 67.83,
        "y": 28.27
    },
    {
        "x": 67.84,
        "y": 28.28
    },
    {
        "x": 67.85,
        "y": 28.27
    },
    {
        "x": 67.86,
        "y": 28.27
    },
    {
        "x": 67.87,
        "y": 28.28
    },
    {
        "x": 67.88,
        "y": 28.27
    },
    {
        "x": 67.89,
        "y": 28.27
    },
    {
        "x": 67.9,
        "y": 28.27
    },
    {
        "x": 67.91,
        "y": 28.27
    },
    {
        "x": 67.92,
        "y": 28.27
    },
    {
        "x": 67.93,
        "y": 28.27
    },
    {
        "x": 67.94,
        "y": 28.27
    },
    {
        "x": 67.95,
        "y": 28.27
    },
    {
        "x": 67.96,
        "y": 28.27
    },
    {
        "x": 67.97,
        "y": 28.27
    },
    {
        "x": 67.98,
        "y": 28.27
    },
    {
        "x": 67.99,
        "y": 28.27
    },
    {
        "x": 68,
        "y": 28.27
    },
    {
        "x": 68.01,
        "y": 28.27
    },
    {
        "x": 68.02,
        "y": 28.27
    },
    {
        "x": 68.03,
        "y": 28.27
    },
    {
        "x": 68.04,
        "y": 28.27
    },
    {
        "x": 68.05,
        "y": 28.27
    },
    {
        "x": 68.06,
        "y": 28.27
    },
    {
        "x": 68.07,
        "y": 28.27
    },
    {
        "x": 68.08,
        "y": 28.27
    },
    {
        "x": 68.09,
        "y": 28.27
    },
    {
        "x": 68.1,
        "y": 28.27
    },
    {
        "x": 68.11,
        "y": 28.27
    },
    {
        "x": 68.12,
        "y": 28.27
    },
    {
        "x": 68.13,
        "y": 28.27
    },
    {
        "x": 68.14,
        "y": 28.27
    },
    {
        "x": 68.15,
        "y": 28.27
    },
    {
        "x": 68.16,
        "y": 28.27
    },
    {
        "x": 68.17,
        "y": 28.28
    },
    {
        "x": 68.18,
        "y": 28.27
    },
    {
        "x": 68.19,
        "y": 28.27
    },
    {
        "x": 68.2,
        "y": 28.27
    },
    {
        "x": 68.21,
        "y": 28.27
    },
    {
        "x": 68.22,
        "y": 28.27
    },
    {
        "x": 68.23,
        "y": 28.27
    },
    {
        "x": 68.24,
        "y": 28.27
    },
    {
        "x": 68.25,
        "y": 28.27
    },
    {
        "x": 68.26,
        "y": 28.27
    },
    {
        "x": 68.27,
        "y": 28.27
    },
    {
        "x": 68.28,
        "y": 28.27
    },
    {
        "x": 68.29,
        "y": 28.27
    },
    {
        "x": 68.3,
        "y": 28.27
    },
    {
        "x": 68.31,
        "y": 28.27
    },
    {
        "x": 68.32,
        "y": 28.27
    },
    {
        "x": 68.33,
        "y": 28.27
    },
    {
        "x": 68.34,
        "y": 28.27
    },
    {
        "x": 68.35,
        "y": 28.27
    },
    {
        "x": 68.36,
        "y": 28.27
    },
    {
        "x": 68.37,
        "y": 28.27
    },
    {
        "x": 68.38,
        "y": 28.27
    },
    {
        "x": 68.39,
        "y": 28.27
    },
    {
        "x": 68.4,
        "y": 28.27
    },
    {
        "x": 68.41,
        "y": 28.27
    },
    {
        "x": 68.42,
        "y": 28.27
    },
    {
        "x": 68.43,
        "y": 28.27
    },
    {
        "x": 68.44,
        "y": 28.27
    },
    {
        "x": 68.45,
        "y": 28.27
    },
    {
        "x": 68.46,
        "y": 28.27
    },
    {
        "x": 68.47,
        "y": 28.27
    },
    {
        "x": 68.48,
        "y": 28.27
    },
    {
        "x": 68.49,
        "y": 28.27
    },
    {
        "x": 68.5,
        "y": 28.27
    },
    {
        "x": 68.51,
        "y": 28.27
    },
    {
        "x": 68.52,
        "y": 28.27
    },
    {
        "x": 68.53,
        "y": 28.27
    },
    {
        "x": 68.54,
        "y": 28.27
    },
    {
        "x": 68.55,
        "y": 28.27
    },
    {
        "x": 68.56,
        "y": 28.27
    },
    {
        "x": 68.57,
        "y": 28.27
    },
    {
        "x": 68.58,
        "y": 28.27
    },
    {
        "x": 68.59,
        "y": 28.27
    },
    {
        "x": 68.6,
        "y": 28.27
    },
    {
        "x": 68.61,
        "y": 28.27
    },
    {
        "x": 68.62,
        "y": 28.27
    },
    {
        "x": 68.63,
        "y": 28.27
    },
    {
        "x": 68.64,
        "y": 28.27
    },
    {
        "x": 68.65,
        "y": 28.27
    },
    {
        "x": 68.66,
        "y": 28.27
    },
    {
        "x": 68.67,
        "y": 28.27
    },
    {
        "x": 68.68,
        "y": 28.27
    },
    {
        "x": 68.69,
        "y": 28.27
    },
    {
        "x": 68.7,
        "y": 28.27
    },
    {
        "x": 68.71,
        "y": 28.27
    },
    {
        "x": 68.72,
        "y": 28.27
    },
    {
        "x": 68.73,
        "y": 28.27
    },
    {
        "x": 68.74,
        "y": 28.27
    },
    {
        "x": 68.75,
        "y": 28.27
    },
    {
        "x": 68.76,
        "y": 28.27
    },
    {
        "x": 68.77,
        "y": 28.27
    },
    {
        "x": 68.78,
        "y": 28.27
    },
    {
        "x": 68.79,
        "y": 28.27
    },
    {
        "x": 68.8,
        "y": 28.26
    },
    {
        "x": 68.81,
        "y": 28.27
    },
    {
        "x": 68.82,
        "y": 28.27
    },
    {
        "x": 68.83,
        "y": 28.27
    },
    {
        "x": 68.84,
        "y": 28.27
    },
    {
        "x": 68.85,
        "y": 28.27
    },
    {
        "x": 68.86,
        "y": 28.27
    },
    {
        "x": 68.87,
        "y": 28.27
    },
    {
        "x": 68.88,
        "y": 28.27
    },
    {
        "x": 68.89,
        "y": 28.27
    },
    {
        "x": 68.9,
        "y": 28.27
    },
    {
        "x": 68.91,
        "y": 28.27
    },
    {
        "x": 68.92,
        "y": 28.27
    },
    {
        "x": 68.93,
        "y": 28.27
    },
    {
        "x": 68.94,
        "y": 28.27
    },
    {
        "x": 68.95,
        "y": 28.27
    },
    {
        "x": 68.96,
        "y": 28.27
    },
    {
        "x": 68.97,
        "y": 28.27
    },
    {
        "x": 68.98,
        "y": 28.27
    },
    {
        "x": 68.99,
        "y": 28.26
    },
    {
        "x": 69,
        "y": 28.27
    },
    {
        "x": 69.01,
        "y": 28.27
    },
    {
        "x": 69.02,
        "y": 28.27
    },
    {
        "x": 69.03,
        "y": 28.27
    },
    {
        "x": 69.04,
        "y": 28.27
    },
    {
        "x": 69.05,
        "y": 28.27
    },
    {
        "x": 69.06,
        "y": 28.27
    },
    {
        "x": 69.07,
        "y": 28.27
    },
    {
        "x": 69.08,
        "y": 28.27
    },
    {
        "x": 69.09,
        "y": 28.26
    },
    {
        "x": 69.1,
        "y": 28.27
    },
    {
        "x": 69.11,
        "y": 28.27
    },
    {
        "x": 69.12,
        "y": 28.27
    },
    {
        "x": 69.13,
        "y": 28.27
    },
    {
        "x": 69.14,
        "y": 28.27
    },
    {
        "x": 69.15,
        "y": 28.27
    },
    {
        "x": 69.16,
        "y": 28.27
    },
    {
        "x": 69.17,
        "y": 28.27
    },
    {
        "x": 69.18,
        "y": 28.26
    },
    {
        "x": 69.19,
        "y": 28.27
    },
    {
        "x": 69.2,
        "y": 28.27
    },
    {
        "x": 69.21,
        "y": 28.27
    },
    {
        "x": 69.22,
        "y": 28.27
    },
    {
        "x": 69.23,
        "y": 28.27
    },
    {
        "x": 69.24,
        "y": 28.27
    },
    {
        "x": 69.25,
        "y": 28.27
    },
    {
        "x": 69.26,
        "y": 28.27
    },
    {
        "x": 69.27,
        "y": 28.27
    },
    {
        "x": 69.28,
        "y": 28.27
    },
    {
        "x": 69.29,
        "y": 28.26
    },
    {
        "x": 69.3,
        "y": 28.27
    },
    {
        "x": 69.31,
        "y": 28.26
    },
    {
        "x": 69.32,
        "y": 28.26
    },
    {
        "x": 69.33,
        "y": 28.26
    },
    {
        "x": 69.34,
        "y": 28.27
    },
    {
        "x": 69.35,
        "y": 28.27
    },
    {
        "x": 69.36,
        "y": 28.26
    },
    {
        "x": 69.37,
        "y": 28.27
    },
    {
        "x": 69.38,
        "y": 28.27
    },
    {
        "x": 69.39,
        "y": 28.27
    },
    {
        "x": 69.4,
        "y": 28.27
    },
    {
        "x": 69.41,
        "y": 28.27
    },
    {
        "x": 69.42,
        "y": 28.26
    },
    {
        "x": 69.43,
        "y": 28.26
    },
    {
        "x": 69.44,
        "y": 28.27
    },
    {
        "x": 69.45,
        "y": 28.26
    },
    {
        "x": 69.46,
        "y": 28.27
    },
    {
        "x": 69.47,
        "y": 28.26
    },
    {
        "x": 69.48,
        "y": 28.26
    },
    {
        "x": 69.49,
        "y": 28.26
    },
    {
        "x": 69.5,
        "y": 28.26
    },
    {
        "x": 69.51,
        "y": 28.26
    },
    {
        "x": 69.52,
        "y": 28.26
    },
    {
        "x": 69.53,
        "y": 28.26
    },
    {
        "x": 69.54,
        "y": 28.26
    },
    {
        "x": 69.55,
        "y": 28.26
    },
    {
        "x": 69.56,
        "y": 28.26
    },
    {
        "x": 69.57,
        "y": 28.26
    },
    {
        "x": 69.58,
        "y": 28.26
    },
    {
        "x": 69.59,
        "y": 28.26
    },
    {
        "x": 69.6,
        "y": 28.26
    },
    {
        "x": 69.61,
        "y": 28.26
    },
    {
        "x": 69.62,
        "y": 28.27
    },
    {
        "x": 69.63,
        "y": 28.26
    },
    {
        "x": 69.64,
        "y": 28.26
    },
    {
        "x": 69.65,
        "y": 28.26
    },
    {
        "x": 69.66,
        "y": 28.26
    },
    {
        "x": 69.67,
        "y": 28.26
    },
    {
        "x": 69.68,
        "y": 28.26
    },
    {
        "x": 69.69,
        "y": 28.27
    },
    {
        "x": 69.7,
        "y": 28.26
    },
    {
        "x": 69.71,
        "y": 28.26
    },
    {
        "x": 69.72,
        "y": 28.26
    },
    {
        "x": 69.73,
        "y": 28.26
    },
    {
        "x": 69.74,
        "y": 28.26
    },
    {
        "x": 69.75,
        "y": 28.26
    },
    {
        "x": 69.76,
        "y": 28.26
    },
    {
        "x": 69.77,
        "y": 28.26
    },
    {
        "x": 69.78,
        "y": 28.26
    },
    {
        "x": 69.79,
        "y": 28.26
    },
    {
        "x": 69.8,
        "y": 28.26
    },
    {
        "x": 69.81,
        "y": 28.26
    },
    {
        "x": 69.82,
        "y": 28.26
    },
    {
        "x": 69.83,
        "y": 28.26
    },
    {
        "x": 69.84,
        "y": 28.26
    },
    {
        "x": 69.85,
        "y": 28.26
    },
    {
        "x": 69.86,
        "y": 28.26
    },
    {
        "x": 69.87,
        "y": 28.26
    },
    {
        "x": 69.88,
        "y": 28.26
    },
    {
        "x": 69.89,
        "y": 28.26
    },
    {
        "x": 69.9,
        "y": 28.26
    },
    {
        "x": 69.91,
        "y": 28.26
    },
    {
        "x": 69.92,
        "y": 28.26
    },
    {
        "x": 69.93,
        "y": 28.26
    },
    {
        "x": 69.94,
        "y": 28.26
    },
    {
        "x": 69.95,
        "y": 28.26
    },
    {
        "x": 69.96,
        "y": 28.26
    },
    {
        "x": 69.97,
        "y": 28.26
    },
    {
        "x": 69.98,
        "y": 28.26
    },
    {
        "x": 69.99,
        "y": 28.26
    },
    {
        "x": 70,
        "y": 28.27
    },
    {
        "x": 70.01,
        "y": 28.26
    },
    {
        "x": 70.02,
        "y": 28.27
    },
    {
        "x": 70.03,
        "y": 28.27
    },
    {
        "x": 70.04,
        "y": 28.27
    },
    {
        "x": 70.05,
        "y": 28.27
    },
    {
        "x": 70.06,
        "y": 28.27
    },
    {
        "x": 70.07,
        "y": 28.27
    },
    {
        "x": 70.08,
        "y": 28.27
    },
    {
        "x": 70.09,
        "y": 28.27
    },
    {
        "x": 70.1,
        "y": 28.27
    },
    {
        "x": 70.11,
        "y": 28.27
    },
    {
        "x": 70.12,
        "y": 28.26
    },
    {
        "x": 70.13,
        "y": 28.27
    },
    {
        "x": 70.14,
        "y": 28.27
    },
    {
        "x": 70.15,
        "y": 28.27
    },
    {
        "x": 70.16,
        "y": 28.27
    },
    {
        "x": 70.17,
        "y": 28.27
    },
    {
        "x": 70.18,
        "y": 28.26
    },
    {
        "x": 70.19,
        "y": 28.27
    },
    {
        "x": 70.2,
        "y": 28.26
    },
    {
        "x": 70.21,
        "y": 28.26
    },
    {
        "x": 70.22,
        "y": 28.26
    },
    {
        "x": 70.23,
        "y": 28.26
    },
    {
        "x": 70.24,
        "y": 28.27
    },
    {
        "x": 70.25,
        "y": 28.26
    },
    {
        "x": 70.26,
        "y": 28.26
    },
    {
        "x": 70.27,
        "y": 28.26
    },
    {
        "x": 70.28,
        "y": 28.26
    },
    {
        "x": 70.29,
        "y": 28.26
    },
    {
        "x": 70.3,
        "y": 28.26
    },
    {
        "x": 70.31,
        "y": 28.26
    },
    {
        "x": 70.32,
        "y": 28.26
    },
    {
        "x": 70.33,
        "y": 28.26
    }
];

export const toqueDataPoint1 = [
    {
        "indexLabel": "t5",
        "x": 5.7,
        "y": 3.16
    }];
export const toqueDataPoint2 = [
    {
        "indexLabel": "t10",
        "x": 6.71,
        "y": 4.35
    }];
export const torqueDataPoint3 = [
    {
        "indexLabel": "t25",
        "x": 8.33,
        "y": 7.91
    }];
export const torqueDataPoint4 = [
    {
        "indexLabel": "t50",
        "x": 10.35,
        "y": 13.87
    }];
export const torqueDataPoint5 = [
    {
        "indexLabel": "t90",
        "x": 20.31,
        "y": 23.39
    }];
export const torqueDataPoint6 = [
    {
        "indexLabel": "t95",
        "x": 25.45,
        "y": 24.58
    }];
export const torqueDataPoint7 = [
    {
        "indexLabel": "t100",
        "x": 53.21,
        "y": 25.77
    }
];
