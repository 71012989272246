import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import BlockIcon from "@material-ui/icons/Block";
import {DateRangeOutlined} from "@material-ui/icons";
import * as ROUTES from "../../constants/routes";
import {Link} from "react-router-dom";

class DisplayDiagnostic extends Component {
  state = {};

  reportButton(diagnostic, key) {
      let startIcon;
      let buttonValue;
      let disabled;
      let route;
      switch (key) {
          case 'Report':
          case diagnostic[key]:
              startIcon =  <DateRangeOutlined />;
              buttonValue = 'final report';
              disabled = false;
              route = ROUTES.PDF_REPORT;
              break;
          case 'Mixing':
          case 'Vulcameter':
          case 'Uniaxial Tension':
          case diagnostic[key]:
              startIcon = <DateRangeOutlined />;
              buttonValue = 'view report';
              disabled = false;
              route = '/orders/1';
              break;
          default:
              startIcon = <BlockIcon />;
              buttonValue = 'Report not available';
              disabled = true;
              route = '/orders/1';
              break;
      }
      return (
          <Button color="secondary" startIcon={startIcon} disabled={disabled} to={route} component={Link}>
              {buttonValue}
          </Button>
      );
  }

  render() {
    const { diagnostic, status } = this.props;
    const diagnosticList = Object.keys(diagnostic).map((key) => (
      <TableRow key={key}>
        <TableCell component="th" scope="row">
          {diagnostic[key] ? (
            <CheckCircleOutlineIcon style={{ color: "green" }} />
          ) : (
            <QueryBuilderIcon style={{ color: "orange" }} />
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          <strong>{key}</strong>
        </TableCell>
        <TableCell component="th" scope="row">
            {
                this.reportButton(diagnostic, key)
            }
        </TableCell>
      </TableRow>
    ));

    return (
      <Box border={1} borderColor="grey.200" borderRadius="borderRadius" mt={2}>
        {status ? (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Analysis</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableBody>{diagnosticList}</TableBody>
                </Table>
              </TableContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          ""
        )}
      </Box>
    );
  }
}

export default DisplayDiagnostic;
