import React from "react";
import OrderReceiptsDashboard from "./OrderRecipts";
import Pdf from "../../assets/pdf/epdm.pdf";

class PDFOEM extends React.Component {
  render() {
    return (
      <div>
        <OrderReceiptsDashboard file={Pdf} />
      </div>
    );
  }
}

export default PDFOEM;
