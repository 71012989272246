export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const DASHBOARD = "/";
export const COMMODITIES = "/rawmaterials";
export const RECIPES = "/recipes";
export const VIEW_RECIPE = "/recipes/:id";
export const RECIPE_UPLOAD = "/upload";
export const ORDERS = "/orders";
export const WORK_ORDERS = "/workorders";
export const VIEW_ORDER = "/orders/:id";
export const VIEW_WORK_ORDER = "/workorders/:id";
export const WIZARD_ORDER = "/wizardorder/:id/:step";
export const PDF_RECIPE = "/PDF/Recipe";
export const PDF_OEM = "/PDF/oem";
export const PDF_REPORT = "/PDF/report";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const UPLOAD_RECIPE = "/upload/upload-rawmaterial";
